import React from "react";
import { Row, Space } from "antd";
import dayjs from "dayjs";

const GuestCovidPass = () => {
  const params = new URLSearchParams(window.location.search);

  return (
    <Row
      style={{ minHeight: "100vh", backgroundColor: "#08b639" }}
      justify="space-around"
      align="middle"
    >
      <Space direction="vertical" style={{ textAlign: "center" }}>
        <h2 style={{ color: "white" }}>
          {dayjs(new Date(params.get("date")!)).format("MM/DD/YYYY hh:mm A")}
        </h2>
        <h1 style={{ color: "white", fontWeight: "bolder" }}>
          {params.get("name")}
        </h1>
        <h1 style={{ color: "white" }}>PASSED</h1>
        <h1 style={{ color: "white" }}>{params.get("projectName")}</h1>
        <h1 style={{ color: "white" }}>{params.get("company")}</h1>
      </Space>
    </Row>
  );
};

export default GuestCovidPass;
