import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import * as React from "react";
import { Menu, Popconfirm } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { DeleteTaskMutation } from "src/common/types/generated/relay/DeleteTaskMutation.graphql";

interface DeleteTaskProps {
  taskIds: Array<string>;
  refresh: () => void;
}

const DeleteTask = (props: DeleteTaskProps) => {
  const [updateJha] = useAsyncMutation<DeleteTaskMutation>(graphql`
    mutation DeleteTaskMutation($taskIds: [uuid!]!, $_set: task_set_input!) {
      update_task(where: { id: { _in: $taskIds } }, _set: $_set) {
        affected_rows
      }
    }
  `);

  return (
    <div
      className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Popconfirm
        title={`Are you sure you want to delete ${
          props.taskIds.length > 1
            ? props.taskIds.length + ` JHA(s)`
            : ` this JHA`
        } ?`}
        onConfirm={async () => {
          await updateJha({
            variables: {
              taskIds: props.taskIds,
              _set: {
                deleted_at: dayjs().format(),
              },
            },
          }).then(() => props.refresh());
        }}
        okText="Yes"
        cancelText="No"
      >
        {`Delete ${props.taskIds.length > 1 ? `JHAs` : `JHA`}`}
      </Popconfirm>
    </div>
  );
};

export default DeleteTask;
