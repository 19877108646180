import React, { useContext, useEffect, useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { Select } from "antd";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsEmployerProps
  extends SiteOrientationStepProps {
  oacCompanyOptions: Array<{
    name: string;
    id: string;
  }>;
  gcOptions: Array<{
    name: string;
    id: string;
  }>;
  workerOptions: Array<{
    id: string;
    name: string;
    projectIds: Array<string>;
    subcontractorWorkerTitles?: Array<{
      id: string;
      en: string;
      es: string;
    }> | null;
  }>;
}

const SiteOrientationInPersonWorkerStepsEmployer: React.FC<
  SiteOrientationInPersonWorkerStepsEmployerProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();
  const [employer, setEmployer] = useState<string>(
    workerContext.orientee?.company?.id || "",
  );
  const oacOptions = [...props.gcOptions, ...props.oacCompanyOptions].sort(
    (a, b) => a.name.localeCompare(b.name),
  );
  useEffect(() => {
    if (!workerContext.orientee?.company?.id) return;
    if (workerContext.orientee?.userType === "worker") {
      if (
        props.workerOptions.find(
          (s) => s.id === workerContext.orientee?.company?.id,
        )
      )
        setEmployer(workerContext.orientee.company.id);
      else setEmployer("");
    } else {
      if (
        props.oacCompanyOptions.find(
          (s) => s.id === workerContext.orientee?.company?.id,
        )
      )
        setEmployer(workerContext.orientee.company.id);
      else if (
        props.gcOptions.find(
          (s) => s.id === workerContext.orientee?.company?.id,
        )
      )
        setEmployer(workerContext.orientee.company.id);
      else setEmployer("");
    }
  }, [props.workerOptions, props.gcOptions, props.oacCompanyOptions]);
  const langStrings = useCurrentLangStrings();

  const onEnter = (pickedSubId: string) => {
    if (workerContext.orientee?.userType === "oacUser") {
      const selectedOacCompany = props.oacCompanyOptions.find(
        (x) => x.id === (pickedSubId ?? employer),
      );
      const selectedGC = props.gcOptions.find(
        (x) =>
          workerContext.orientee?.userType === "oacUser" &&
          (x.id === (pickedSubId ?? employer) ||
            workerContext.orientee.generalContractorId),
      );
      workerContext.updateOrientee &&
        workerContext.updateOrientee((user) => {
          if (!user || user.userType !== "oacUser") return null;
          //user object must be defined at this point even if it's new user, as his name should already be entered
          return selectedOacCompany
            ? {
                ...user,
                userType: "oacUser",
                oacCompanyId: selectedOacCompany.id,
                company: {
                  id: selectedOacCompany.id,
                  title: selectedOacCompany.name,
                  companyWorkerTitles: [],
                },
                title: user.oacTitleId
                  ? { id: user.oacTitleId, name: "" }
                  : undefined,
              }
            : {
                ...user,
                userType: "oacUser",
                oacCompanyId: undefined,
                // if it's an old user he should not be able to change his gcCompany only oac company can be changed
                generalContractorId: selectedGC?.id,
                title: user.employeeTitleId
                  ? { id: user.employeeTitleId, name: "" }
                  : undefined,
                company: {
                  id: selectedGC?.id ?? "",
                  title: selectedGC?.name ?? "",
                  companyWorkerTitles: [],
                },
              };
        });
    } else {
      const selectedSub = props.workerOptions.find(
        (x) => x.id === (pickedSubId ?? employer),
      );
      if (!selectedSub) return;
      workerContext.updateOrientee &&
        workerContext.updateOrientee((user) => {
          return !user
            ? {
                userType: "worker",
                company: {
                  id: selectedSub.id,
                  title: selectedSub.name,
                  companyWorkerTitles:
                    selectedSub.subcontractorWorkerTitles ?? [],
                },
                projectsToRegister: Object.fromEntries(
                  selectedSub.projectIds.map((p) => [p, true]),
                ),
              }
            : {
                ...user,
                userType: "worker",
                company: {
                  id: selectedSub.id,
                  title: selectedSub.name,
                  companyWorkerTitles:
                    selectedSub.subcontractorWorkerTitles ?? [],
                },
                projectsToRegister: Object.fromEntries(
                  selectedSub.projectIds.map((p) => [p, true]),
                ),
              };
        });
    }
    props.onNext();
  };
  return (
    <SiteFeatureStepLayout
      onNextButtonClick={() => {
        onEnter(employer);
      }}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!employer.length}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.whoDoyYouWorkFor}
      >
        <p className="text-grey text-1">
          {langStrings.strings.ifYouDoNotSeeYourCompanyContactYourGCOrOffice}
        </p>
        <Select
          virtual={false}
          placeholder={langStrings.strings.selectYourCompany}
          onChange={(v) => {
            setEmployer(v.valueOf());
          }}
          value={employer}
        >
          {(workerContext.orientee?.userType === "oacUser"
            ? oacOptions
            : props.workerOptions
          ).map((e: any) => (
            <Select.Option
              disabled={
                workerContext.orientee?.userType === "worker" &&
                workerContext.orientee.isSubAdmin &&
                e.id !== workerContext.orientee?.company?.id
              }
              key={e.id}
              value={e.id}
              className="text-1"
            >
              <span translate={`no`}>{e.name}</span>
            </Select.Option>
          ))}

          {/*
          TODO ADD FOR OAC USER Selection
          <Select.Option
            key={"0"}
            value={"GCUserRegistration"}
            className="text-1"
          >
            Architect, Engineer, Owner, GC
          </Select.Option> */}
        </Select>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerStepsEmployer;
