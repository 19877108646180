import clsx from "clsx";
import React from "react";

interface SiteDeliveryCalendarTimeStampDigitsProps {
  timeStr: string;
}

const SiteDeliveryCalendarTimeStampDigits: React.FC<
  SiteDeliveryCalendarTimeStampDigitsProps
> = (props) => {
  return (
    <div
      className={clsx(
        "h-px relative flex flex-row w-0.25 justify-start items-center",
      )}
    >
      <div className="absolute top-0 left-0.5 right-0 flex flex-row items-center justify-start h-0">
        <div>
          <span className="whitespace-nowrap text-static-secondary text-1">
            {props.timeStr}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SiteDeliveryCalendarTimeStampDigits;
