import React, { FC } from "react";
import CustomSignatureCanvas, {
  CustomSignatureCanvasProps,
} from "src/common/components/general/signature-canvas/CustomSignatureCanvas";

import { CurrentLangStrings } from "../../../../../../utility-features/i18n/language-utils/i18nTypes";

interface SiteInspectionWorkerViewSignatureUIProps {
  userName?: string;
  langStrings: CurrentLangStrings;
  signaturePadProps: CustomSignatureCanvasProps;
}

const SiteInspectionWorkerViewSignatureUI: FC<
  SiteInspectionWorkerViewSignatureUIProps
> = ({ langStrings, userName, signaturePadProps }) => {
  return (
    <div className="flex flex-col gap-1">
      <p className="text-1.5">
        {langStrings.strings.signBelowToCompleteInspection(userName ?? "")}
      </p>
      <div className="flex flex-col gap-0.5">
        <CustomSignatureCanvas {...signaturePadProps} />
      </div>
      <p className="text-0.75 text-static-secondary">
        <span className="text-interactive-primary">
          <a
            target="_blank"
            href="/https://www.termsfeed.com/privacy-policy/4797f669d31b548f81ec1bfb308928a1"
          >
            {langStrings.strings.privacyPolicy}
          </a>
        </span>{" "}
        {langStrings.strings.and}{" "}
        <span className="text-interactive-primary">
          <a
            target="blank"
            href="/https://www.termsfeed.com/terms-conditions/f73fc5afe6a821990e96daebc0b83d39"
          >
            {langStrings.strings.termsOfService}
          </a>
        </span>
      </p>
    </div>
  );
};

export default SiteInspectionWorkerViewSignatureUI;
