import React, { FC } from "react";
import { Modal } from "antd";

interface PdfModalProps {
  visible: boolean;
  onClose: () => void;
  pdfUrl?: string;
  destroyOnClose?: boolean;
}

const PdfModal: FC<PdfModalProps> = ({
  visible,
  onClose,
  pdfUrl,
  destroyOnClose,
}) => {
  return (
    <Modal open={visible} width={1400} onCancel={onClose} onOk={onClose}>
      <iframe width={1320} height={800} src={pdfUrl} title=""></iframe>
    </Modal>
  );
};
export default PdfModal;
