import {
  useInsertDocumentMutation,
  Document_Insert_Input,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  GetIncidentByPkDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import getIncidentTypeName from "../utils/getIncidentTypeName";
import dayjs from "dayjs";
import createIncidentPatch from "./createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";

const useUploadIncidentTypeDocument = () => {
  const [insertDocument] = useInsertDocumentMutation();
  const authUser = useAuthUser();

  const uploadDocument = async (
    objects: Document_Insert_Input[],
    incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
    type: string,
  ) => {
    const incidentTypes = incident.incident_types;
    const incidentType = incidentTypes.find(
      (incidentType) => incidentType.type_value === type,
    );

    if (!incidentType?.documents) return;

    const insertedDocs: Array<{ url: string; name: string }> = [];
    const docsToInsert: {
      __typename?: "document";
      url: string;
      id: string;
      group_id?: string | null;
      document_type?: string | null;
      name?: string | null;
      created_at: string;
      image?: {
        __typename?: "image";
        url: string;
        id: string;
      } | null;
    }[] = [];

    objects.forEach((doc) => {
      if (doc.url && doc.id) {
        docsToInsert.push({
          __typename: "document" as const,
          ...doc,
          created_at: dayjs().format(),
          url: doc.url,
          id: doc.id,
          image:
            doc.document_type === "image" ? { url: doc.url, id: doc.id } : null,
        });

        insertedDocs.push({
          url: doc.url,
          name: doc.name ?? "document",
        });
      }
    });
    const documents = [...incidentType.documents, ...docsToInsert];
    const updatedIncidentTypes = incidentTypes.map((incidentType) => {
      if (incidentType.type_value === type) {
        return { ...incidentType, documents: documents };
      } else {
        return incidentType;
      }
    });
    const updatedIncident = {
      ...incident,
      incident_types: updatedIncidentTypes,
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    const comment = `Uplodaed documents for ${getIncidentTypeName(type)}`;
    await insertDocument({
      variables: {
        objects: objects,
        editObject: {
          comment: comment,
          patch: patch,
          edit_type: "document-upload",
          incident_id: incident.id,
          edited_by_uid: authUser.uid,
          document_edit: [
            {
              field: getIncidentTypeName(type),
              action: "added",
              documents: insertedDocs,
            },
          ],
        },
      },
      update(cache, result) {
        const resultData = result.data?.insert_document?.returning;
        if (!resultData) return;

        const documents = [...incidentType.documents, ...resultData];
        const updatedIncidentTypes = incidentTypes.map((incidentType) => {
          if (incidentType.type_value === type) {
            return { ...incidentType, documents: documents };
          } else {
            return incidentType;
          }
        });

        const updatedIncident = {
          ...incident,
          incident_types: updatedIncidentTypes,
        };
        cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>({
          data: {
            __typename: "query_root",
            incident_by_pk: updatedIncident,
          },
          query: GetIncidentByPkDocument,
        });
      },
    });
  };

  return [uploadDocument] as const;
};

export default useUploadIncidentTypeDocument;
