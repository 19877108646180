import React, { FunctionComponent } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useLazyLoadQuery } from "react-relay/hooks";
import { graphql } from "babel-plugin-relay/macro";
import ReportsTabsGroup, {
  ReportsTabType,
} from "src/common/components/ReportsTabsGroup";
import ToolboxTalkTable from "src/domain-features/sitesafety/components/toolbox-talks/ToolboxTalkTable";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import GCProjectReportsTBTsDetail from "../gcProjectReports/routes/toolboxtalks/components/GCProjectReportsTBTsDetail";
import { GCProjectToolboxTalksQuery } from "src/common/types/generated/relay/GCProjectToolboxTalksQuery.graphql";
import ManageToolboxTalks from "src/domain-features/sitesafety/components/toolbox-talks/ManageToolboxTalks";

const GCProjectToolboxTalks: FunctionComponent = () => {
  const tabs: ReportsTabType[] = [
    { id: "manage", label: "Manage TBTs" },
    { id: "completed", label: "Completed TBTs" },
  ];

  const { subview, projectId } = useParams();
  if (!projectId) throw new Error("Project Id not found");
  const data = useLazyLoadQuery<GCProjectToolboxTalksQuery>(
    graphql`
      query GCProjectToolboxTalksQuery($projectId: uuid!) {
        project_connection(where: { id: { _eq: $projectId } }) {
          edges {
            node {
              id
              general_contractor_id
              project_subcontractors(
                order_by: { subcontractor: { name: asc } }
              ) {
                subcontractor_id
                subcontractor {
                  name
                }
              }
            }
          }
        }
      }
    `,
    { projectId }
  ).project_connection.edges[0].node;
  const navigate = useNavigate();
  const handleReportsTabClick = (tabId: string) => {
    navigate("../" + tabId);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const toolboxTalkId = searchParams.get("toolboxTalkId");

  if (!subview || !projectId) {
    return <></>;
  }
  return toolboxTalkId ? (
    <GCProjectReportsTBTsDetail {...{ toolboxTalkId, projectId }} />
  ) : (
    <BasicWrapper>
      <ReportsTabsGroup
        onTabClick={handleReportsTabClick}
        tabs={tabs}
        tabsType="button"
        selectedTabId={subview}
      />
      <div className={`flex-grow bg-white overflow-auto py-1 w-full`}>
        <div className="flex w-full h-full">
          {subview === "completed" && (
            <ToolboxTalkTable
              showDateRangeFilter={true}
              excludedKeys={["project"]}
              where={{
                project_id: { _eq: projectId },
              }}
              onRowClick={(pk) => setSearchParams({ toolboxTalkId: pk })}
            />
          )}
          {subview === "manage" && (
            <ManageToolboxTalks
              projectId={projectId}
              gcId={data.general_contractor_id}
              subsOption={data.project_subcontractors.map((ps) => ({
                id: ps.subcontractor_id,
                name: ps.subcontractor.name,
              }))}
            />
          )}
        </div>
      </div>
    </BasicWrapper>
  );
};
export default GCProjectToolboxTalks;
