import { useState } from "react";
import usePermanentOnsiteSetting from "../../utils/usePermanentOnsiteSetting";
import usePermanentReportRequireSettings from "../../utils/usePermanentReportRequireSettings";
import { SubOnsiteData } from "./GCDashAppReportsTable";
import { SwitcherProps } from "src/common/components/general/Switcher";
import { IconCheck, IconUserMinus, IconUserPlus, IconX } from "@tabler/icons";
import React from "react";
const useGetSwitchers = (projectId: string) => {
  const { setCrewNotRequire, setCrewRequire, setSubNotRequire, setSubRequire } =
    usePermanentReportRequireSettings();
  const { setCrewOffSite, setCrewOnSite, setSubOffSite, setSubOnSite } =
    usePermanentOnsiteSetting(projectId);
  const [loadingCrew, setLoadingCrew] = useState<string[]>([]);
  const [loadingSub, setLoadingSub] = useState<string[]>([]);
  // const [subData, setSubData] = useState(subOnsiteData);
  const markCrewOnSite = async (crewId: string, subId: string) => {
    setLoadingCrew((prev) => [
      ...prev,
      crewId,
      crewId + "_daily_report",
      crewId + "_toolbox_talk",
      crewId + "_safety_report",
    ]);
    try {
      await setCrewOnSite(crewId, subId);
    } finally {
      setLoadingCrew((prev) => [
        ...prev.filter(
          (p) =>
            p !== crewId &&
            p !== crewId + "_daily_report" &&
            p !== crewId + "_toolbox_talk" &&
            p !== crewId + "_safety_report"
        ),  
      ]);
    }
  };

  const markCrewOffSite = async (crewId: string, subId: string) => {
    setLoadingCrew((prev) => [...prev, crewId]);
    await setCrewOffSite(crewId, subId);
    setLoadingCrew((prev) => [...prev.filter((p) => p !== crewId)]);
  };
  const markSubOnSite = async (subId: string) => {
    setLoadingSub((prev) => [
      ...prev,
      subId,
      subId + "_daily_report",
      subId + "_toolbox_talk",
      subId + "_safety_report",
    ]);
    try {
      await setSubOnSite(subId);
    } finally {
      setLoadingSub((prev) => [
        ...prev.filter(
          (p) =>
            p !== subId &&
            p !== subId + "_daily_report" &&
            p !== subId + "_toolbox_talk" &&
            p !== subId + "_safety_report"
        ),
      ]);  
    }
  };

  const markSubOffSite = async (subId: string) => {
    setLoadingSub((prev) => [...prev, subId]);
    await setSubOffSite(subId);
    setLoadingSub((prev) => [...prev.filter((p) => p !== subId)]);
  };
  const setCrewRequireSettings = async (item: {
    setTo: boolean;
    crewId: string;
    subId: string;
    type: "safety_report" | "daily_report" | "toolbox_talk";
  }) => {
    setLoadingCrew((prev) => [...prev, item.crewId + "_" + item.type]);
    try {
      if (item.setTo) {
        await setCrewRequire({
          subId: item.subId,
          crewId: item.crewId,
          type: item.type,
        });
      } else {
        await setCrewNotRequire({
          subId: item.subId,
          crewId: item.crewId,
          type: item.type,
        });
      }
    } finally {
      setLoadingCrew((prev) => [
        ...prev.filter((p) => p !== item.crewId + "_" + item.type),
      ]);  
    }
  };
  const setSubRequireSettings = async (item: {
    setTo: boolean;
    subId: string;
    type: "safety_report" | "daily_report" | "toolbox_talk";
  }) => {
    setLoadingSub((prev) => [...prev, item.subId + "_" + item.type]);
    try {
      if (item.setTo) {
        await setSubRequire({
          subId: item.subId,
          type: item.type,
          projectId,
        });
      } else {
        await setSubNotRequire({
          subId: item.subId,
          type: item.type,
          projectId,
        });
      }
    } finally {
      setLoadingSub((prev) => [
        ...prev.filter((p) => p !== item.subId + "_" + item.type),
      ]);
    }
  };

  const getSubSwitchersFor = (
    sub: SubOnsiteData
  ): {
    OnsiteSwitcher: SwitcherProps;
    ptpSwitcher: SwitcherProps;
    drSwitcher: SwitcherProps;
    tbtSwitcher: SwitcherProps;
  } => ({
    OnsiteSwitcher: {
      onChange: async (newOptionIndex) => {
        if (newOptionIndex === 1) {
          markSubOnSite(sub.subId);
          if (!sub.toolbox_talks_required)
            setSubRequire({
              projectId,
              subId: sub.subId,
              type: "toolbox_talk",
            });
          if (!sub.daily_reports_required)
            setSubRequire({
              projectId,
              subId: sub.subId,
              type: "daily_report",
            });
          if (!sub.safety_reports_required)
            setSubRequire({
              projectId,
              subId: sub.subId,
              type: "safety_report",
            });
        } else {
          markSubOffSite(sub.subId);
          sub.crews.forEach((crew) => {
            if (crew.onsite) {
              markCrewOffSite(crew.crewId, sub.subId);
            }
          });
        }
      },
      loading: !!loadingSub.find((s) => s === sub.subId),
      optionIndex: sub.onsite ? 1 : 0,
      options: [
        { icon: IconUserMinus, label: "Off" },
        {
          icon: IconUserPlus,
          label: "Onsite",
        },
      ],
      hint: (
        <div className="w-24">
          Setting to “Onsite” will mark a Sub or Crew as working on the project
          indefinitely until they marked “Offsite”. This triggers your scheduled
          push notifications in the mobile app and emails. Onsite status is only
          Monday through Friday. Marking a subcontractor as Onsite on the
          weekends is done under “Dashboard” in the mobile app.
          <br />
          Reports are only required when the Sub or Crew is marked Onsite
        </div>
      ),
    },
    ptpSwitcher: {
      onChange: async (newOptionIndex) => {
        if (newOptionIndex === 1) {
          setSubRequireSettings({
            subId: sub.subId,
            setTo: true,
            type: "safety_report",
          });
        } else {
          setSubRequireSettings({
            subId: sub.subId,
            setTo: false,
            type: "safety_report",
          });
          sub.crews.forEach((crew) => {
            if (crew.safety_reports_required)
              setCrewRequireSettings({
                subId: sub.subId,
                setTo: false,
                crewId: crew.crewId,
                type: "safety_report",
              });
          });
        }
      },
      loading: !!loadingSub.find((s) => s === sub.subId + "_safety_report"),
      optionIndex: sub.safety_reports_required ? 1 : 0,
      options: [
        { icon: IconX, label: "No" },
        { icon: IconCheck, label: "Yes" },
      ],
    },
    drSwitcher: {
      onChange: async (newOptionIndex) => {
        if (newOptionIndex === 1) {
          setSubRequireSettings({
            subId: sub.subId,
            setTo: true,
            type: "daily_report",
          });
        } else {
          setSubRequireSettings({
            subId: sub.subId,
            setTo: false,
            type: "daily_report",
          });
          sub.crews.forEach((crew) => {
            if (crew.daily_reports_required)
              setCrewRequireSettings({
                setTo: false,
                subId: sub.subId,
                crewId: crew.crewId,
                type: "daily_report",
              });
          });
        }
      },
      loading: !!loadingSub.find((s) => s === sub.subId + "_daily_report"),
      optionIndex: sub.daily_reports_required ? 1 : 0,
      options: [
        { icon: IconX, label: "No" },
        { icon: IconCheck, label: "Yes" },
      ],
    },
    tbtSwitcher: {
      onChange: async (newOptionIndex) => {
        if (newOptionIndex === 1) {
          setSubRequireSettings({
            subId: sub.subId,
            setTo: true,
            type: "toolbox_talk",
          });
        } else {
          setSubRequireSettings({
            subId: sub.subId,
            setTo: false,
            type: "toolbox_talk",
          });
          sub.crews.forEach((crew) => {
            if (crew.toolbox_talks_required)
              setCrewRequireSettings({
                setTo: false,
                subId: sub.subId,
                crewId: crew.crewId,
                type: "toolbox_talk",
              });
          });
        }
      },
      loading: !!loadingSub.find((s) => s === sub.subId + "_toolbox_talk"),
      optionIndex: sub.toolbox_talks_required ? 1 : 0,
      options: [
        { icon: IconX, label: "No" },
        { icon: IconCheck, label: "Yes" },
      ],
    },
  });
  const getCrewSwitchersFor = (
    crew: SubOnsiteData["crews"][number],
    sub: SubOnsiteData
  ): {
    OnsiteSwitcher: SwitcherProps;
    ptpSwitcher: SwitcherProps;
    drSwitcher: SwitcherProps;
    tbtSwitcher: SwitcherProps;
  } => {
    return {
      OnsiteSwitcher: {
        onChange: async (newOptionIndex) => {
          if (newOptionIndex === 1) {
            if (!sub.onsite) {
              markSubOnSite(sub.subId);
              if (!sub.toolbox_talks_required)
                setSubRequire({
                  projectId,
                  subId: sub.subId,
                  type: "toolbox_talk",
                });
              if (!sub.daily_reports_required)
                setSubRequire({
                  projectId,
                  subId: sub.subId,
                  type: "daily_report",
                });
              if (!sub.safety_reports_required)
                setSubRequire({
                  projectId,
                  subId: sub.subId,
                  type: "safety_report",
                });
            }
            markCrewOnSite(crew.crewId, sub.subId);
            if (!crew.toolbox_talks_required)
              setCrewRequireSettings({
                setTo: true,
                crewId: crew.crewId,
                subId: sub.subId,
                type: "toolbox_talk",
              });
            if (!crew.daily_reports_required)
              setCrewRequireSettings({
                setTo: true,
                crewId: crew.crewId,
                subId: sub.subId,
                type: "daily_report",
              });
            if (!crew.safety_reports_required)
              setCrewRequireSettings({
                setTo: true,
                crewId: crew.crewId,
                subId: sub.subId,
                type: "safety_report",
              });
          } else {
            markCrewOffSite(crew.crewId, sub.subId);
          }
        },
        loading: !!loadingCrew.find((s) => s === crew.crewId),
        optionIndex: crew.onsite ? 1 : 0,
        options: [
          { icon: IconUserMinus, label: "Off" },
          {
            icon: IconUserPlus,
            label: "Onsite",
          },
        ],
        hint: (
          <div className="w-24">
            Setting to “Onsite” will mark a Sub or Crew as working on the
            project indefinitely until they marked “Offsite”. This triggers your
            scheduled push notifications in the mobile app and emails. Onsite
            status is only Monday through Friday. Marking a subcontractor as
            Onsite on the weekends is done under “Dashboard” in the mobile app.
            <br />
            Reports are only required when the Sub or Crew is marked Onsite
          </div>
        ),
      },
      ptpSwitcher: {
        onChange: async (newOptionIndex) => {
          if (newOptionIndex === 1) {
            if (!sub.safety_reports_required) {
              setSubRequireSettings({
                subId: sub.subId,
                setTo: true,
                type: "safety_report",
              });
            }
            setCrewRequireSettings({
              setTo: true,
              subId: sub.subId,
              crewId: crew.crewId,
              type: "safety_report",
            });
          } else {
            setCrewRequireSettings({
              setTo: false,
              subId: sub.subId,
              crewId: crew.crewId,
              type: "safety_report",
            });
          }
        },
        loading: !!loadingCrew.find(
          (s) => s === crew.crewId + "_safety_report"
        ),
        optionIndex: crew.safety_reports_required ? 1 : 0,
        options: [
          { icon: IconX, label: "No" },
          { icon: IconCheck, label: "Yes" },
        ],
      },
      drSwitcher: {
        onChange: async (newOptionIndex) => {
          if (newOptionIndex === 1) {
            if (!sub.daily_reports_required) {
              setSubRequireSettings({
                subId: sub.subId,
                setTo: true,
                type: "daily_report",
              });
            }
            setCrewRequireSettings({
              setTo: true,
              subId: sub.subId,
              crewId: crew.crewId,
              type: "daily_report",
            });
          } else {
            setCrewRequireSettings({
              setTo: false,
              subId: sub.subId,
              crewId: crew.crewId,
              type: "daily_report",
            });
          }
        },
        loading: !!loadingCrew.find((s) => s === crew.crewId + "_daily_report"),
        optionIndex: crew.daily_reports_required ? 1 : 0,
        options: [
          { icon: IconX, label: "No" },
          { icon: IconCheck, label: "Yes" },
        ],
      },
      tbtSwitcher: {
        onChange: async (newOptionIndex) => {
          if (newOptionIndex === 1) {
            if (!sub.toolbox_talks_required) {
              setSubRequireSettings({
                subId: sub.subId,
                setTo: true,
                type: "toolbox_talk",
              });
            }
            setCrewRequireSettings({
              setTo: true,
              subId: sub.subId,
              crewId: crew.crewId,
              type: "toolbox_talk",
            });
          } else {
            setCrewRequireSettings({
              setTo: false,
              subId: sub.subId,
              crewId: crew.crewId,
              type: "toolbox_talk",
            });
          }
        },
        loading: !!loadingCrew.find((s) => s === crew.crewId + "_toolbox_talk"),
        optionIndex: crew.toolbox_talks_required ? 1 : 0,
        options: [
          { icon: IconX, label: "No" },
          { icon: IconCheck, label: "Yes" },
        ],
      },
    };
  };

  return {
    getSubSwitchersFor,
    getCrewSwitchersFor,
  };
};
export default useGetSwitchers;
