import { Button, message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import PdfModal from "src/common/components/modals/PdfModal";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { SdsTable_sds$data } from "src/common/types/generated/relay/SdsTable_sds.graphql";
import { SubProjectMobilizationStepSds_UpdateSdsMutation } from "src/common/types/generated/relay/SubProjectMobilizationStepSds_UpdateSdsMutation.graphql";
import UploadSDSToSiteFormModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/UploadSdsToSiteformModal";
import AddSdsFromCompanyLibraryModal from "../../../safety-data-sheets/components/AddSdsFromCompanyLibraryModal";
import SdsTable from "../../../safety-data-sheets/components/SdsTable";
import UploadSdsModal from "src/domain-features/sitesafety/safety-data-sheets/components/UploadSdsModal";

export interface SubProjectMobilizationStepSdsProps {
  projectId: string;
  subcontractorId: string;
  onPrev: () => void;
  onNext: (isUploaded: boolean) => void;
}

const SubProjectMobilizationStepSds: React.FC<
  SubProjectMobilizationStepSdsProps
> = (props) => {
  const [updateSds, updating] =
    useAsyncMutation<SubProjectMobilizationStepSds_UpdateSdsMutation>(
      graphql`
        mutation SubProjectMobilizationStepSds_UpdateSdsMutation(
          $_set: safety_data_sheet_set_input
          $where: safety_data_sheet_bool_exp!
        ) {
          update_safety_data_sheet(_set: $_set, where: $where) {
            affected_rows
          }
        }
      `,
    );
  const [openSiteFormUploadModal, setOpenSiteFormUploadModal] = useState(false);
  const projectId = props.projectId;
  const subcontractorId = props.subcontractorId;
  const sdsTableRef = useRef<DataScrollTableRef>(null);
  const [pdfModal, setPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [addSdsFromCompanyLibraryModal, setAddSdsFromCompanyLibraryModal] =
    useState(false);
  const onDelete = async (
    sds: SdsTable_sds$data["safety_data_sheet_connection"]["edges"][number]["node"],
  ) => {
    await updateSds({
      variables: {
        _set: { deleted_at: dayjs().toISOString() },
        where: { id: { _eq: sds.pk } },
      },
    })
      .then(async (d) => {
        sdsTableRef.current?.refetch();
        message.success("Sds Deleted");
      })
      .catch((e) => console.log(e));
  };

  return (
    <div>
      <PdfModal
        visible={pdfModal}
        onClose={() => {
          setPdfModal(false);
        }}
        pdfUrl={pdfUrl}
      />
      <AddSdsFromCompanyLibraryModal
        {...{
          title: "Add SDS from Company",
          projectId: projectId,
          visible: addSdsFromCompanyLibraryModal,
          closeModal: () => setAddSdsFromCompanyLibraryModal(false),
          subcontractorId,
          tableWhere: {
            subcontractor_id: { _eq: subcontractorId },
            project_id: { _is_null: true },
            deleted_at: { _is_null: true },
          },
          refresh: () => {
            sdsTableRef.current?.refetch();
          },
        }}
      />
      <UploadSdsModal
        visible={openSiteFormUploadModal}
        subcontractorId={subcontractorId}
        projectId={projectId}
        onClose={() => setOpenSiteFormUploadModal(false)}
        onSubmit={() => {
          setOpenSiteFormUploadModal(false);
          sdsTableRef.current?.refetch();
          props.onNext(true);
        }}
      />
      <div className="mt-1">
        <Button
          onClick={() => {
            props.onPrev();
          }}
        >
          Back
        </Button>
        <Button
          className="ml-1"
          onClick={() => {
            props.onNext(false);
          }}
        >
          Next
        </Button>
      </div>
      <SdsTable
        title="Project SDS Library"
        ref={sdsTableRef}
        where={{
          subcontractor_id: { _eq: subcontractorId },
          project_id: { _eq: projectId },
          deleted_at: { _is_null: true },
        }}
        onSdsRowClick={(sds) => {
          setPdfUrl(sds.url);
          setPdfModal(true);
        }}
        loading={updating}
        topBarButtons={[
          {
            onClick: () => {
              setOpenSiteFormUploadModal(true);
            },
            label: "+ Upload New",
          },
          {
            onClick: () => {
              setAddSdsFromCompanyLibraryModal(true);
            },
            label: "Add from Company Library",
          },
        ]}
      />
    </div>
  );
};

export default SubProjectMobilizationStepSds;
