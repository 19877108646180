import {
  IconActivity,
  IconAnalyze,
  IconArrowBack,
  IconBuilding,
  IconCalendarTime,
  IconEye,
  IconMoodAngry,
  IconMoodAnnoyed,
  IconBolt,
  IconForklift,
  IconTrees,
  IconFall,
  IconCarCrash,
  IconNote,
  IconFileDots,
} from "@tabler/icons";

const getMenuItemIcon = (type: string, color?: "grey" | "black") => {
  switch (type) {
    case "table":
      return <IconArrowBack />;
    case "project":
      return <IconActivity />;
    case "root_cause":
      return <IconAnalyze />;
    case "witness":
      return <IconEye />;
    case "notes":
      return <IconNote />;
    case "environmental":
      return <IconTrees color={color} />;
    case "equipment":
      return <IconForklift color={color} />;
    case "injury_illness":
      return <IconFall />;
    case "near_miss":
      return <IconMoodAnnoyed color={color} />;
    case "property_damage":
      return <IconBuilding color={color} />;
    case "theft":
      return <IconMoodAngry color={color} />;
    case "utility":
      return <IconBolt color={color} />;
    case "vehicle":
      return <IconCarCrash color={color} />;
    case "incident_detail":
      return <IconCalendarTime />;
    case "logs":
      return <IconFileDots />;
    default:
      <></>;
  }
};

export default getMenuItemIcon;
