import { SlideRecord } from "../siteorientationTypes";
import { Language } from "../../../utility-features/i18n/language-utils/i18nTypes";

export const translateSlide: (
  slide: SlideRecord,
  lang: Language
) => SlideRecord = (slide: SlideRecord, lang: Language) => {
  const langSuffix = lang === "en" ? "" : (`_${lang}` as const);

  return {
    ...slide,
    title: slide[`title${langSuffix}`] || slide.title,
    text: slide[`text${langSuffix}`] || slide.text,
    text_to_speak: slide[`text_to_speak${langSuffix}`] || slide.text_to_speak,
    voice_url: slide[`voice_url${langSuffix}`] || slide.voice_url,
    image_url: slide[`image_url${langSuffix}`] || slide.image_url,
    video_url: slide[`video_url${langSuffix}`] || slide.video_url,
    answer_items: slide[`answer_items${langSuffix}`] || slide.answer_items,
    db_answer_items: slide.answer_items,
    // correct_answers: translateCorrectAnswers(slide, lang) ?? null,
    correct_answer_note: slide[`correct_answer_note${langSuffix}`],
  };
};
export default translateSlide;
