import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useUpdateInspectionTemplateMutation } from "src/common/types/generated/relay/useUpdateInspectionTemplateMutation.graphql";

const useUpdateInspectionTemplate = () => {
  return useAsyncMutation<useUpdateInspectionTemplateMutation>(
    graphql`
      mutation useUpdateInspectionTemplateMutation(
        $id: uuid!
        $_set: inspection_template_set_input!
      ) {
        update_inspection_template_by_pk(pk_columns: { id: $id }, _set: $_set) {
          id
          pk: id @__clientField(handle: "pk")
        }
      }
    `
  );
};
export default useUpdateInspectionTemplate;
