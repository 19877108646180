import React, { useRef, useState } from "react";
import VideoPlayer from "../../../../../../common/components/VideoPlayer/VideoPlayer";
import { VideoPlayerRef } from "../../../../../../common/components/VideoPlayer/utils/VideoPlayerTypes";
import { Alert, Button, Modal } from "antd";
import {
  BackwardOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { CurrentLangStrings } from "../../../../../../utility-features/i18n/language-utils/i18nTypes";

export interface SiteOrientationSlidesViewerSlideVideoProps {
  onWatched?: () => void;
  videSrc: string;
  setStartOfSlide: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
  watchFullVideo: boolean;
  hideRewindAndPause?: boolean;
  startTimeOfSlide: dayjs.Dayjs;
  disableControls: boolean;
  langStrings: CurrentLangStrings;
  showWarningIfRequiredNotComplete: boolean;
  videoMaxHeight?: string;
}

const SiteOrientationSlidesViewerSlideVideo: React.FC<
  SiteOrientationSlidesViewerSlideVideoProps
> = (props) => {
  const [play, setPlay] = useState(false);
  const videoRef = useRef<VideoPlayerRef>(null);
  const [showWarning, setShowWarning] = useState(false);
  return (
    <div className={`flex flex-col gap-0.5`}>
      <Modal
        open={showWarning}
        okText="OK"
        onOk={() => {
          props.setStartOfSlide(dayjs());
          setShowWarning(false);
        }}
        onCancel={() => {
          props.setStartOfSlide(dayjs());
          setShowWarning(false);
        }}
        footer={
          <Button
            type="primary"
            onClick={() => {
              props.setStartOfSlide(dayjs());
              setShowWarning(false);
            }}
          >
            Replay
          </Button>
        }
      >
        <Alert
          className="mt-1"
          type="warning"
          message={
            "Warning: Please watch the full video without skipping ahead"
          }
        />
      </Modal>
      <VideoPlayer
        ref={videoRef}
        videoUrl={props.videSrc}
        disableControls={props.disableControls}
        onPlay={() => setPlay(true)}
        onPause={() => setPlay(false)}
        maxHeight={props.videoMaxHeight}
        onWatched={async () => {
          setPlay(false);
          if (props.watchFullVideo) {
            const endTime = dayjs();
            const videoDuration = await videoRef.current?.getDuration();
            console.log(
              "watched",
              endTime.diff(props.startTimeOfSlide, "second"),
              videoDuration,
            );
            if (
              props.showWarningIfRequiredNotComplete &&
              (endTime.diff(props.startTimeOfSlide, "second") || 0) + 2 <
                (videoDuration || 2)
            ) {
              setShowWarning(true);
              return;
            }
          }
          props.onWatched?.();
        }}
      />
      {!props.hideRewindAndPause && (
        <div className={`flex flex-row items-center gap-0.5 `}>
          <Button
            block
            onClick={() => {
              if (videoRef.current) {
                videoRef.current.rewind();
              }
            }}
            icon={<BackwardOutlined />}
          >
            {props.langStrings.strings.rewindSeconds(30)}
          </Button>
          <Button
            block
            onClick={() => {
              if (videoRef.current) {
                if (play) {
                  videoRef.current.pause();
                } else {
                  videoRef.current.play();
                }
              }
              setPlay(!play);
            }}
            icon={play ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
          >
            {play
              ? props.langStrings.strings.pause
              : props.langStrings.strings.play}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SiteOrientationSlidesViewerSlideVideo;
