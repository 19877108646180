import { Form, Input, message, Modal, notification, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { graphql } from "babel-plugin-relay/macro";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLazyLoadQuery, useRelayEnvironment } from "react-relay/hooks";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { auth } from "src/common/functions/firebase";
import sendInvite from "src/utility-features/invitations/sendInvite";
import ConfirmConvertWorkerToSubadminModal from "src/common/components/dialogs/ConfirmConvertWorkerToSubadminModal";
import validateEmail from "src/common/functions/validateEmail";
import { GCSendInviteModalQuery } from "src/common/types/generated/relay/GCSendInviteModalQuery.graphql";
import { getInvitedUserData } from "src/common/functions/invitedUserEmailData";
import { useCreateSubcontractorEmployeeMutation } from "src/common/types/generated/apollo/graphQLTypes";

type InviteRole = "sub-admin" | "gc-employee";

interface GCSendInviteModalProps {
  projectId: string;
  inviteRole?: InviteRole;
  subcontractorId?: string;
}

const query = graphql`
  query GCSendInviteModalQuery(
    $projectId: uuid!
    $userId: uuid!
    $includeSub: Boolean!
  ) {
    subcontractor_connection(
      order_by: { name: asc }
      where: { subcontractor_projects: { project_id: { _eq: $projectId } } }
    ) @include(if: $includeSub) {
      edges {
        node {
          name
          pk: id @__clientField(handle: "pk")
          id
        }
      }
    }
    user_connection(where: { id: { _eq: $userId } }) {
      edges {
        node {
          name
          pk: id @__clientField(handle: "pk")
          id
          employee {
            general_contractor {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
            employee_projects(where: { project_id: { _eq: $projectId } }) {
              project {
                id
                pk: id @__clientField(handle: "pk")
                name
              }
            }
          }
        }
      }
    }
  }
`;

interface InviteFormFields {
  sendTo: string;
  subcontractorId?: string;
}

// Moved content to separate component because:
//  1. avoid suspension when we render parent with hidden modal
//  2. to refetch and display fresh data on modal open event

type GCSendInviteModalContentRef = {
  submit: (close: () => void) => Promise<void>;
};

interface GCSendInviteModalContentProps {
  projectId: string;
  saving: boolean;
  inviteRole: InviteRole;
  subcontractorId?: string;
}

const GCSendInviteModalContent = forwardRef<
  GCSendInviteModalContentRef,
  GCSendInviteModalContentProps
>(({ projectId, saving, inviteRole, subcontractorId }, ref) => {
  const userId = auth.currentUser?.uid;
  if (!userId) {
    throw new Error("GCSendInviteModal requires authenticated user");
  }
  const includeSub = inviteRole === "sub-admin" && !subcontractorId;
  const data = useLazyLoadQuery<GCSendInviteModalQuery>(
    query,
    {
      projectId,
      userId,
      includeSub,
    },
    {
      fetchPolicy: "store-and-network",
    }
  );
  const environment = useRelayEnvironment();
  const employee = data.user_connection.edges[0]?.node.employee;
  if (!employee) {
    throw new Error("user must be logged in as gc employee");
  }
  const sentFromName = data.user_connection.edges[0].node.name;
  const sentFromCompanyName =
    data.user_connection.edges[0].node.employee?.general_contractor.name;
  const projectName =
    data.user_connection.edges[0].node.employee?.employee_projects[0]?.project
      ?.name;
  const [form] = useForm<InviteFormFields>();
  const [
    showConvertWorkerToSubadminModal,
    setShowConvertWorkerToSubadminModal,
  ] = useState(false);

  const openConvertWorkerModal = () => {
    setShowConvertWorkerToSubadminModal(true);
  };

  const closeConvertWorkerModal = () => {
    setShowConvertWorkerToSubadminModal(false);
  };

  const [createSubEmployee] = useCreateSubcontractorEmployeeMutation();
  const [workerId, setWorkerId] = useState<string | null>(null);

  const submit = async (close: () => void) => {
    const values = await form.validateFields();
    try {
      if (!validateEmail(values.sendTo)) throw new Error("Email is incorrent");
      close(); // should we close it early?
      form.resetFields();
      let sendInviteVariables: {
        sendTo: string;
        sentFromName?: string;
        sentFromCompanyName?: string;
        joinRole?: string;
        joinProjectId?: string;
        joinGeneralContractorId?: string;
        joinSubcontractorId?: string;
        projectName?: string;
        claimingAccount?: boolean;
        inviteType?: string;
      } = {
        sendTo: values.sendTo,
        sentFromName: sentFromName,
        sentFromCompanyName: sentFromCompanyName,
        projectName: projectName,
        joinProjectId: projectId,
        joinGeneralContractorId: employee.general_contractor.pk,
        joinSubcontractorId: values.subcontractorId ?? subcontractorId,
        joinRole: inviteRole, //"subcontractor_employee",  Daksh : Changed Join Role as it was invalid
        inviteType: "subAdminInvite",
      };
      const invitedUserData = await getInvitedUserData(
        values.sendTo,
        environment
      );
      if (invitedUserData && invitedUserData.user_connection.edges[0]) {
        if (
          invitedUserData.user_connection.edges[0].node.role ==
          "subcontractor_employee"
        ) {
          const invitedUserSubId =
            invitedUserData.user_connection.edges[0].node.subcontractor_employee
              ?.subcontractor_id;
          if (invitedUserSubId !== subcontractorId) {
            notification.error({
              message: "User Exists ",
              description:
                " This email is already in use under a different Subcontractor. If you need assistance contact us at support@siteform.io",
              duration: null,
            });
            return;
          } else if (
            invitedUserSubId === subcontractorId &&
            invitedUserData.user_connection.edges[0].node.created_password
          ) {
            notification.info({
              message: "User Exists",
              description:
                "This user has already set up their account. If they are having trouble logging in, select Forgot password at sign-in",
              duration: null,
            });
            sendInviteVariables = {
              ...sendInviteVariables,
              inviteType: "loginInviteSub",
            };
          } else if (
            invitedUserSubId === subcontractorId &&
            !invitedUserData.user_connection.edges[0].node.created_password
          ) {
            sendInviteVariables = {
              ...sendInviteVariables,
              claimingAccount: true,
            };
          }
          await sendInvite({
            ...sendInviteVariables,
          })
            .then(() => {
              message.success("Invite sent");
            })
            .catch((error) => {
              message.error(error);
            });
        } else if (
          invitedUserData.user_connection.edges[0].node.role == "worker"
        ) {
          setWorkerId(invitedUserData.user_connection.edges[0].node.pk);
          openConvertWorkerModal();
        }
      } else {
        sendInviteVariables = {
          ...sendInviteVariables,
          claimingAccount: true,
        };
        await sendInvite({
          ...sendInviteVariables,
        })
          .then(() => {
            message.success("Invite sent");
          })
          .catch((error) => {
            message.error(error);
          });
      }
    } catch (error) {
      console.log("Error:", error);
      notification.error({
        message: "Error: ",
        description:
          error instanceof Error ? error.message : JSON.stringify(error),
        duration: null,
      });
    }
  };

  useImperativeHandle<GCSendInviteModalContentRef, GCSendInviteModalContentRef>(
    ref,
    () => ({
      submit,
    })
  );
  console.log("Render: data", data);

  return (
    <>
      <Form key="form" form={form} layout="vertical">
        <Form.Item
          name="sendTo"
          label="Enter Email"
          rules={[{ required: true, message: "Email required" }]}
        >
          <Input disabled={saving} />
        </Form.Item>
        {data.subcontractor_connection && (
          <Form.Item
            name="subcontractorId"
            label="Subcontractor"
            rules={[{ required: true, message: `Choose the subcontractor` }]}
          >
            <Select style={{ width: "100%" }} disabled={saving}>
              {data.subcontractor_connection.edges?.map((t) => (
                <Select.Option key={t.node.pk} value={t.node.pk}>
                  {t.node.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
      { workerId && <ConfirmConvertWorkerToSubadminModal
        modalClose={closeConvertWorkerModal}
        modalVisible={showConvertWorkerToSubadminModal}
        workerId={workerId}
      />}
    </>
  );
});

export type GCSendInviteModalRef = {
  open: () => void;
  close: () => void;
};

const GCSendInviteModal = forwardRef<
  GCSendInviteModalRef,
  GCSendInviteModalProps
>(({ projectId, inviteRole, subcontractorId }, ref) => {
  const [saving, setSaving] = useState(false);
  const [visible, setVisible] = useState(false);
  const contentRef = useRef<GCSendInviteModalContentRef>(null);
  const close = () => {
    setVisible(false);
  };
  useImperativeHandle<GCSendInviteModalRef, GCSendInviteModalRef>(ref, () => ({
    open: () => {
      setVisible(true);
    },
    close: () => {
      setVisible(false);
    },
  }));
  return (
    (<Modal
      title="Invite People"
      okText="Invite"
      cancelText="Cancel"
      open={visible}
      onCancel={close}
      onOk={() => {
        if (contentRef.current) {
          setSaving(true);
          contentRef.current.submit(close).finally(() => setSaving(false));
        }
      }}
    >
      <CustomSuspense>
        <GCSendInviteModalContent
          ref={contentRef}
          subcontractorId={subcontractorId}
          projectId={projectId}
          inviteRole={inviteRole ?? "sub-admin"}
          saving={saving}
        />
      </CustomSuspense>
    </Modal>)
  );
});

export default withCustomSuspense(GCSendInviteModal);
