import { useEffect, useState } from "react";
import SignaturePad from "signature_pad";
import { CustomSignatureCanvasProps } from "./CustomSignatureCanvas";
import useUploadSignature, {
  UploadSignatureFuncType,
} from "./useUploadSignature";

type SignaturePadHookProps = {
  signaturePadProps: CustomSignatureCanvasProps;
  uploadSignature: UploadSignatureFuncType;
  uploading: boolean;
  isEmpty: boolean;
};

const useSignaturePad: () => SignaturePadHookProps = () => {
  const [signaturePad, setSignaturePad] = useState<SignaturePad | null>(null);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const { uploadSignature, uploading } = useUploadSignature(signaturePad);
  useEffect(() => {
    const onEndStroke = () => {
      setIsEmpty(!signaturePad || signaturePad?.isEmpty());
    };

    signaturePad?.addEventListener("endStroke", onEndStroke);
    return () => {
      signaturePad?.removeEventListener("endStroke", onEndStroke);
    };
  }, [signaturePad]);

  return {
    uploadSignature,
    uploading,
    isEmpty,
    signaturePadProps: {
      signaturePadState: [signaturePad, setSignaturePad],
      loading: uploading,
      isEmptyState: [isEmpty, setIsEmpty],
    },
  };
};

export default useSignaturePad;
