import { IconArrowLeft } from "@tabler/icons";
import React from "react";
import Icon, { IconType } from "./general/Icon";

interface NavigationPlaceholderProps {
  message?: string;
  icon?: IconType;
}

const NavigationPlaceholder: React.FC<NavigationPlaceholderProps> = (props) => {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="flex flex-row items-center gap-1">
        <Icon icon={props.icon ?? IconArrowLeft} color={`static`} />
        <p className="text-2 font-accent">
          {props.message ?? "What can we show you today?"}
        </p>
      </div>
    </div>
  );
};

export default NavigationPlaceholder;
