import React, { useContext, useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { message } from "antd";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import clsx from "clsx";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsUserTypeProps
  extends SiteOrientationStepProps {}

const SiteOrientationInPersonWorkerStepsUserType: React.FC<
  SiteOrientationInPersonWorkerStepsUserTypeProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();
  const [userType, setUserType] = useState(
    workerContext.orientee?.userType || null,
  );

  const langStrings = useCurrentLangStrings();
  const onEnter = (selectedUserType?: string) => {
    const foundUserType = selectedUserType ?? userType;
    if (foundUserType === "oacUser" && !workerContext.orientee?.email) {
      message.error(
        "If you're an OAC User, please go back and enter your email",
      );
      return;
    }
    if (foundUserType === "worker" || foundUserType === "oacUser")
      workerContext.updateOrientee((user) => {
        return !user
          ? // why using selectedUserType here? it's because when user selects a userType we run onEnter and setUserType at the same time due to which userType's value is not set when running onEnter fully
            { userType: foundUserType }
          : { ...user, userType: foundUserType };
      });
    props.onNext();
  };

  const userTypeOptions: {
    value: "oacUser" | "worker";
    title: string;
    body?: React.ReactNode;
    icon: React.ReactNode;
  }[] = [
    {
      value: "worker",
      // title: langStrings.short.workerOrTradesManOrWoman,
      title: langStrings.strings.workerAndTradePartner,
      icon: "🧰 🛠️",
      body: (
        <>
          {langStrings.strings.tradespersonCraftspersonTechnicianMechanic}
          <br />
          {langStrings.strings.employedBySubcontractor}
        </>
      ),
    },
    {
      value: "oacUser",
      title: langStrings.strings.gcOperationsOrDesignTeam,
      icon: "🖊️ 📐",
      body: langStrings.strings.architectEngineerOwnerConsultantGCManagement,
    },
  ];

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={() => {
        !!userType && onEnter(userType);
      }}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!userType}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.whatBestDescribesWhoYouWorkFor}
      >
        <div className={`flex flex-row items-center justify-center`}>
          <div
            className={`w-full flex flex-row items-stretch justify-center gap-0.25 tablet-portrait::gap-0.5`}
          >
            {userTypeOptions.map((userTypeOption) => {
              const isSelected = userTypeOption.value === userType;
              return (
                <div
                  key={userTypeOption.value}
                  onClick={() => {
                    setUserType(userTypeOption.value);
                  }}
                  className={clsx(
                    `flex-1 h-auto cursor-pointer border w-full rounded-0.25 tablet-portrait:rounded-0.5 transition-colors flex flex-col gap-0 p-0.5 tablet-portrait:p-1`,
                    isSelected
                      ? "text-white bg-interactive-primary border-transparent"
                      : "text-static-primary bg-white border-interactive-secondary hover:border-interactive-primary hover:text-interactive-primary",
                  )}
                >
                  <div className={`text-3`}> {userTypeOption.icon}</div>
                  <p className={`font-accent`}>{userTypeOption.title}</p>
                  {userTypeOption.body && <p>{userTypeOption.body}</p>}
                </div>
              );
            })}
          </div>
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerStepsUserType;
