import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import StyledContent from "src/common/components/layouts/StyledContent";
import { useSubAdminJhaApprovalMutation } from "src/common/types/generated/apollo/graphQLTypes";

interface RequestProps {
  requestId: string;
}

const SubAdminEmailReview: React.FC<RequestProps> = ({ requestId }) => {
  const navigate = useNavigate();
  const [subAdminJHAApproval] = useSubAdminJhaApprovalMutation();
  const [result, setResult] = useState<{
    redirectLink: string;
    msgCode: string;
  }>({
    redirectLink: "",
    msgCode: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      await subAdminJHAApproval({
        variables: {
          input: {
            requestId,
          },
        },
      }).then(async ({ data }) => {
        if (data?.subAdminJHAApproval) {
          setResult({
            redirectLink: data.subAdminJHAApproval.redirectLink,
            msgCode: data.subAdminJHAApproval.msgCode,
          });
        }
      });
    };
    fetchData();
  }, []);

  if (result.redirectLink) {
    navigate(result.redirectLink);
  }

  if (
    result.msgCode === "approvedBefore" ||
    result.msgCode === "reviewedBefore"
  ) {
    return (
      <div className="text-center">
        <StyledContent>
          <b>
            {" "}
            You have already
            {result.msgCode === "approvedBefore" ? " approved " : " reviewed "}
            this JHA.
          </b>
          <br />
          <br /> Log in to SiteForm
          <br />
          <br />
          <a
            href="https://app.siteform.net"
            target="_blank"
            className="underline text-interactive-primary"
          >
            SiteForm
          </a>
          <br />
          <br />
          to see this and other JHAs. <br />
          <br />
          <br />
          Contact us with any questions <br />
          <br />
          <a
            href="mailto:hello@siteform.io"
            className="text-interactive-primary"
          >
            support@siteform.io
          </a>
        </StyledContent>
      </div>
    );
  } else if (
    result.msgCode === "approvedNow" ||
    result.msgCode === "reviewedNow"
  ) {
    return (
      <div className="text-center">
        <StyledContent>
          <b>
            {" "}
            Great! Thanks for
            {result.msgCode === "approvedNow"
              ? " approving "
              : " reviewing "}{" "}
            this JHA
          </b>
          🎉 🏗 👷 <br />
          <br />
          You were added to the list of
          {result.msgCode === "approvedNow" ? " Approvers " : " Reviewers "}
          <br />
          <br />
          <br /> Log in to{" "}
          <a
            href="https://app.siteform.net"
            target="_blank"
            className="underline text-interactive-primary"
          >
            SiteForm
          </a>{" "}
          to see this and other JHAs
          <br />
          <br />
          <br />
          Contact us with any questions <br />
          <br />
          <a
            href="mailto:hello@siteform.io"
            className="text-interactive-primary"
          >
            support@siteform.io
          </a>
        </StyledContent>
      </div>
    );
  } else {
    return <></>;
  }
};
export default SubAdminEmailReview;
