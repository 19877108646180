import { IconDeviceMobile, IconInfoCircle, IconMail } from "@tabler/icons";
import React from "react";
import Icon from "src/common/components/general/Icon";
import CopyableToolTip from "../dialogs/CopyableToolTip";
interface ContactLinkProps {
  value: string;
  type: "phone-number" | "email" | "default";
  hideValue?: boolean;
  textSize?: "normal" | "small" | "larger";
}

const ContactLink: React.FunctionComponent<ContactLinkProps> = ({
  value,
  type,
  hideValue,
  textSize,
}) => {
  // TODO: check automatically if the value is email or phone number

  const className =
    "flex flex-row items-center margin-gap-x-0.5 group select-none";
  let icon;
  let href;
  let size = "text-0.75";
  switch (textSize) {
    case "larger":
      size = "text-1.25";
      break;
    case "normal":
      size = "text-1";
      break;
    default:
      size = "text-0.75";
  }
  const color = "interactive";
  switch (type) {
    case "phone-number":
      icon = IconDeviceMobile;
      href = `tel:${value}`;
      break;
    case "email":
      icon = IconMail;
      href = `mailto:${value}`;
      break;
    default:
      icon = IconInfoCircle;
      break;
  }
  const childElements = (
    <>
      <Icon {...{ icon, color }} size="small" />
      {!hideValue && <span className={size}>{value}</span>}
    </>
  );

  return (
    <div className="relative inline-block group">
      <CopyableToolTip textToCopy={value}>
        {href ? (
          <a target="_blank" {...{ href, className }}>
            {childElements}
          </a>
        ) : (
          <div {...{ className }}>{childElements}</div>
        )}
      </CopyableToolTip>
    </div>
  );
};

export default ContactLink;
