import { Language } from "../../../utility-features/i18n/language-utils/i18nTypes";

const translateCorrectAnswers = (
  slide: {
    db_answer_items?: string | null;
    answer_items_es?: string | null;
    answer_items_it?: string | null;
    answer_items_pt?: string | null;
    answer_items_fr?: string | null;
    answer_items_zh_hans?: string | null;
    correct_answers?: string | null;
  },
  lang: Language
) => {
  const { correct_answers, db_answer_items } = slide;
  if (lang === "en") return correct_answers;
  if (
    !correct_answers ||
    !db_answer_items ||
    (!slide.answer_items_es && lang === "es") ||
    (!slide.answer_items_pt && lang === "pt") ||
    (!slide.answer_items_it && lang === "it") ||
    (!slide.answer_items_fr && lang === "fr") ||
    (!slide.answer_items_zh_hans && lang === "zh_hans")
  )
    return correct_answers;

  const ans_en = db_answer_items.split("\n");
  const correct_en = correct_answers.split("\n");

  const ans = slide[`answer_items_${lang}`]?.split("\n");
  const correct_ans: Array<string> = correct_en.map((v) =>
    ans ? ans[ans_en.indexOf(v)] : ""
  );
  return correct_ans.join("\n"); // console.log("HERE", returnAnswer, correct_en, ans_en);
};
export default translateCorrectAnswers;
