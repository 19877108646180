import React, { useState } from "react";
import { Modal, Form, Input, Select, Alert, Button, message } from "antd";

export interface AddNewCompanyFormValues {
  companyName: string;
}

interface Props {
  visible: boolean;
  onCreate: (values: AddNewCompanyFormValues) => Promise<void>;
  onCancel: () => void;
}

const AddNewCompanyModal: React.FC<Props> = ({
  visible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    (<Modal
      open={visible}
      title={"Add New Owner, Architect, Engineer"}
      okText="Create"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={async () => {
        try {
          const values = await form.validateFields();
          await onCreate(values);
        } catch (error) {
          console.log("Validate Failed:", error);
          message.error("Unable to Add Company");
        }
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="companyName"
          label="Company Name"
          rules={[{ required: true, message: "Enter a name" }]}
        >
          <Input />
        </Form.Item>
        <label className="text-grey">
          Do not add Subs or Trade Partners or anyone that will use the SiteForm
          App
        </label>
      </Form>
    </Modal>)
  );
};

export default AddNewCompanyModal;