import React, { useEffect, useState } from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import {
  GetGcDivisionsDocument,
  GetGcDivisionsQuery,
  useInsertGcDivisionMutation,
  GetGcDivisionsQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSuspenseQuery } from "@apollo/client";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import useAuthUser from "src/common/hooks/useAuthUser";
import GCLevelsList from "../component/GCLevelsList";
import { useNavigate } from "react-router-dom";

const GCDivisions: React.FC = () => {
  const { userData } = useUserData();
  const employee = userData.employee;
  if (!employee) throw new Error("Loggedā in user is not employee");

  const authUser = useAuthUser();
  const { data, refetch } = useSuspenseQuery<
    GetGcDivisionsQuery,
    GetGcDivisionsQueryVariables
  >(GetGcDivisionsDocument, {
    variables: {
      where: {
        general_contractor_id: { _eq: employee.general_contractor_id },
        ...(employee.is_corporate_admin
          ? {}
          : {
              gc_division_employees: {
                user_id: { _eq: authUser.uid },
              },
            }),
      },
    },
  });
  const [insertDivision, { loading }] = useInsertGcDivisionMutation();
  const navigate = useNavigate();
  return (
    <GCLevelsList
      insertModalProps={{
        inserting: loading,
        onInsert: async (vals) => {
          const { data: insertedDiv } = await insertDivision({
            variables: {
              object: {
                name: vals.name,
                general_contractor_id: employee.general_contractor_id,
                logo_url: vals.uploadedLogoUrl,
                created_by_uid: authUser.uid,
                address: {
                  data: {
                    line_1: vals.addressLine1,
                    zip_code: vals.zip,
                    city: vals.city,
                    state_code: vals.stateCode,
                  },
                },
              },
            },
          });
          await refetch();
          // if (insertedDiv?.insert_gc_division_one)
          //   navigate(
          //     `/gce/divisions/${insertedDiv.insert_gc_division_one.id}/settings`,
          //   );
        },
      }}
      isCorporateAdmin={employee.is_corporate_admin}
      levelName={employee.general_contractor.hierarchy_division_name || ""}
      selectedIdName="divisionId"
      redirectTo="divisions"
      levels={data.gc_division}
    />
  );
};

export default withCustomSuspense(GCDivisions);
