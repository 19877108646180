import { IconCalendarTime, IconMapPin } from "@tabler/icons";
import React from "react";
import LabelIcon from "src/common/components/general/LabelIcon";
import KeyValuePairDisplayCard from "../../../../../common/components/layouts/KeyValuePairDisplayCard";

export interface ProjectOverviewCardProps {
  projectTitle: string;
  gcTitle: string;
  projectAddress?: string;
  subcontractorTitle?: string;
  createdDate?: string;
}

const ProjectOverviewCard: React.FC<ProjectOverviewCardProps> = (props) => {
  return (
    <div className={`w-full p-1.5 rounded-1 bg-white shadow`}>
      <KeyValuePairDisplayCard
        objectToDisplay={{
          "Project Name": <h1 className={`text-1.5`}>{props.projectTitle}</h1>,
          "General Contractor": <h2>{props.gcTitle}</h2>,
          ...(props.subcontractorTitle && {
            Subcontractor: <h2>{props.subcontractorTitle}</h2>,
          }),
          ...(props.projectAddress && {
            Address: (
              <LabelIcon icon={IconMapPin} label={props.projectAddress} />
            ),
          }),
          ...(props.createdDate && {
            "Created Date": (
              <LabelIcon icon={IconCalendarTime} label={props.createdDate} />
            ),
          }),
        }}
      />
    </div>
  );
};

export default ProjectOverviewCard;
