import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { VideoPlayerProps, VideoPlayerRef } from "../utils/VideoPlayerTypes";
import Player from "@vimeo/player";
import {
  initVideoTrackerState,
  VideoTrackerState,
} from "src/common/components/VideoTracker";
import getVideoFrameUrl from "../utils/getVideoFrameUrl";

const VimeoVideoPlayer = forwardRef<VideoPlayerRef, VideoPlayerProps>(
  ({ videoUrl, disableControls, onWatched, onPlay, onPause }, ref) => {
    const [trackerState, setTrackerState] = useState<VideoTrackerState>(() =>
      initVideoTrackerState(1)
    );
    const vimeoIframeRef = useRef(null);
    const vimeoPlayerRef = useRef<Player>();
    const stateRef = useRef<Boolean>(false);

    useEffect(() => {
      if (!vimeoIframeRef.current) return;
      const player = new Player(vimeoIframeRef.current, { controls: false });
      vimeoPlayerRef.current = player;
      player.ready().then(async (e) => {
        const player = vimeoPlayerRef.current;
        if (!player) return;
        console.log("ready", e);
      });
      return () => {
        player.destroy();
        vimeoPlayerRef.current = undefined;
      };
    }, []);
    useEffect(() => {
      if (!vimeoPlayerRef.current || !onWatched) return;
      const handler = async (e: any) => {
        console.log("ended", e);
        console.log("state", trackerState);
        stateRef.current = false;
        const player = vimeoPlayerRef.current;
        if (!player) return;
        const time = await player.getCurrentTime();
        const duration = await player.getDuration();
        console.log("time = ", time, "duration = ", duration);

        //const stopState = stopWatch(trackerState, e.seconds, e.duration);
        //console.log ("stop stae", stopState);
        //const gaps = getBigGap(stopState, 2);
        //if (gaps.length === 0)
        onWatched?.();
        //else{
        //  console.log("gaps = ", gaps);
        //  vimeoPlayerRef.current?.setCurrentTime(gaps[0]?.start || 0); //rep[0]?.start || 0
        //  vimeoPlayerRef.current?.play();
        //}
      };
      vimeoPlayerRef.current.on("ended", handler);
      return () => {
        vimeoPlayerRef.current?.off("ended", handler);
      };
    }, [onWatched]);

    useEffect(() => {
      if (!vimeoPlayerRef.current || !onPlay) return;
      const handler = async (e: any) => {
        console.log("onPlay", e);
        stateRef.current = true;
        onPlay();
      };
      vimeoPlayerRef.current.on("play", handler);
      return () => {
        vimeoPlayerRef.current?.off("play", handler);
      };
    }, [onPlay]);

    useEffect(() => {
      if (!vimeoPlayerRef.current || !onPause) return;
      const handler = (e: any) => {
        console.log("onPause", e);
        stateRef.current = false;
        //      if (e)
        //        setTrackerState(stopWatch(trackerState, e.seconds, e.duration));
        onPause();
      };
      vimeoPlayerRef.current.on("pause", handler);
      return () => {
        vimeoPlayerRef.current?.off("pause", handler);
      };
    }, [onPause]);

    useImperativeHandle<VideoPlayerRef, VideoPlayerRef>(ref, () => ({
      play: () => {
        vimeoPlayerRef.current?.play();
      },
      pause: () => {
        vimeoPlayerRef.current?.pause();
      },
      getDuration: async () => await vimeoPlayerRef.current?.getDuration(),
      rewind: () => {
        vimeoPlayerRef.current
          ?.getCurrentTime()
          .then((currentTime) => {
            var newTime = currentTime - 30;
            if (newTime < 0) {
              newTime = 0;
            }
            vimeoPlayerRef.current?.setCurrentTime(newTime);
          })
          .catch((error) => {
            console.log("Error:", error);
          });
      },
    }));
    const frame_url = getVideoFrameUrl(videoUrl, true, disableControls);
    return (
      <>
        <iframe
          ref={vimeoIframeRef}
          className="absolute top-0 left-0 w-full h-full mh"
          tabIndex={disableControls ? -1 : undefined}
          style={{ maxHeight: "80vh" }} // must be equal to == maxWidth * 56.25%
          src={frame_url}
          allow="autoplay"
          frameBorder="0"
          data-vimeo-controls="0"
        ></iframe>
        {disableControls && (
          <div
            className="absolute top-0 left-0 w-full h-full mh"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              const player = vimeoPlayerRef.current;
              if (player) {
                if (stateRef.current) {
                  player.pause();
                } else if (player.play) {
                  player.play();
                }
              }
            }}
          ></div>
        )}
      </>
    );
  }
);

export default VimeoVideoPlayer;
