/**
 * @generated SignedSource<<f2fbf941012186765f8a7121f86377b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SiteOrientationSettingAlternateCerts_Delete_Alternate_Certificates_Mutation$variables = {
  certificationId: string;
  projectId: string;
};
export type SiteOrientationSettingAlternateCerts_Delete_Alternate_Certificates_Mutation$data = {
  readonly delete_project_alternate_certification: {
    readonly returning: ReadonlyArray<{
      readonly certification: {
        readonly name: string;
      } | null | undefined;
      readonly id: string;
    }>;
  } | null | undefined;
};
export type SiteOrientationSettingAlternateCerts_Delete_Alternate_Certificates_Mutation = {
  response: SiteOrientationSettingAlternateCerts_Delete_Alternate_Certificates_Mutation$data;
  variables: SiteOrientationSettingAlternateCerts_Delete_Alternate_Certificates_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "certificationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "certificationId"
          }
        ],
        "kind": "ObjectValue",
        "name": "certification_id"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "project_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteOrientationSettingAlternateCerts_Delete_Alternate_Certificates_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "project_alternate_certification_mutation_response",
        "kind": "LinkedField",
        "name": "delete_project_alternate_certification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_alternate_certification",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "certification",
                "kind": "LinkedField",
                "name": "certification",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SiteOrientationSettingAlternateCerts_Delete_Alternate_Certificates_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "project_alternate_certification_mutation_response",
        "kind": "LinkedField",
        "name": "delete_project_alternate_certification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_alternate_certification",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "certification",
                "kind": "LinkedField",
                "name": "certification",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c7e6b069509f2d3dba93a2ba349ddb76",
    "id": null,
    "metadata": {},
    "name": "SiteOrientationSettingAlternateCerts_Delete_Alternate_Certificates_Mutation",
    "operationKind": "mutation",
    "text": "mutation SiteOrientationSettingAlternateCerts_Delete_Alternate_Certificates_Mutation(\n  $projectId: uuid!\n  $certificationId: uuid!\n) {\n  delete_project_alternate_certification(where: {project_id: {_eq: $projectId}, certification_id: {_eq: $certificationId}}) {\n    returning {\n      id\n      certification {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "18dc7afadf406743ad302edc0ef07fc2";

export default node;
