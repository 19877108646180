import { createPatch } from "rfc6902";
import { GetIncidentByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";

const createIncidentPatch = (
  newData: GetIncidentByPkQuery["incident_by_pk"],
  oldData: GetIncidentByPkQuery["incident_by_pk"] | {},
) => {
  return createPatch(newData, oldData);
};
export default createIncidentPatch;
