import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "react-relay/hooks";
import { Environment } from "relay-runtime";
import { hardHatHelpers_GetProjectHardHatNumbersQuery } from "src/common/types/generated/relay/hardHatHelpers_GetProjectHardHatNumbersQuery.graphql";

const getUsedHardHatNumbersQuery = graphql`
  query hardHatHelpers_GetProjectHardHatNumbersQuery($projectId: uuid!) {
    project_worker_connection(
      where: { project_id: { _eq: $projectId }, is_last: { _eq: true } }
    ) {
      edges {
        node {
          hard_hat_number
        }
      }
    }
  }
`;

export const getNextHardHatNumber = async (
  environment: Environment,
  projectId: string,
  minValue?: number
) => {
  const request = fetchQuery<hardHatHelpers_GetProjectHardHatNumbersQuery>(
    environment,
    getUsedHardHatNumbersQuery,
    { projectId },
    { fetchPolicy: "network-only" }
  );
  const data = await request.toPromise();
  const numbers = (data?.project_worker_connection.edges || []).map(
    (v) => v.node.hard_hat_number
  );
  for (let i = minValue || 1; i < 500; i++) {
    if (!numbers.includes(`${i}`)) {
      return i;
    }
  }
  return numbers.length + 1;
};
