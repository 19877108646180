import React, { FC } from "react";
import { Popover } from "antd";

interface OnSiteTagProps {
  orientationCompleted: boolean;
}

const OnSiteTag: FC<OnSiteTagProps> = ({ orientationCompleted }) => (
  <Popover
    trigger={"hover"}
    content={
      orientationCompleted ? (
        <div className="flex justify-center items-center">
          <div>🏢</div>
          <div className="ml-0.5">
            Indicates this worker is or was onsite within the last 14 days
            <br />
            because they were included on a SiteForm report (PTP, Daily
            <br />
            Report, Permit, TBT, etc.).
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <div className="text-semantic-negative text-1 rounded-full hover:text-semantic-negative border-semantic-negative border-0.125 hover:border-semantic-negative">
            &nbsp;⚠️&nbsp;
          </div>
          <div className="ml-0.5">
            Indicates this worker is or was onsite because they were
            <br />
            included in a SiteForm report (PTP, Daily Report, Permit, TBT,
            <br />
            etc.) within the last 14 days but has{" "}
            <span className="font-accent">
              NOT YET COMPLETED
              <br /> ORIENTATION
            </span>
            . This can put you and your company at risk.
          </div>
        </div>
      )
    }
  >
    <div className="ml-0.5" onClick={(e) => e.stopPropagation()}>
      {orientationCompleted ? (
        <span>🏢</span>
      ) : (
        <span className="text-semantic-negative text-1 rounded-full hover:text-semantic-negative hover:border-semantic-negative border-semantic-negative border-0.125">
          &nbsp;⚠️&nbsp;
        </span>
      )}
    </div>
  </Popover>
);

export default OnSiteTag;
