import { FC, PropsWithChildren, useState } from "react";
import SiteOrientationUniversalContext from "./siteOrientationUniversalContext";
import { useSearchParams } from "react-router-dom";
import {
  OacUserType,
  UserType,
  WorkerType,
} from "src/domain-features/siteorientation/utils/siteOrientationTypes";

const SiteOrientationUniversalProvier: FC<PropsWithChildren> = (props) => {
  const [searchParams] = useSearchParams();

  const email64 = searchParams.get("worker");
  const email = email64 ? window.atob(email64) : undefined;

  const [user, setUser] = useState<WorkerType | UserType | OacUserType | null>({
    email,
  });
  return (
    <SiteOrientationUniversalContext.Provider
      value={{
        type: "universal",
        orientee: user,
        updateOrientee: setUser,
      }}
    >
      {props.children}
    </SiteOrientationUniversalContext.Provider>
  );
};

export const withSiteOrientationUniversalProvier =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) =>
    (
      <SiteOrientationUniversalProvier>
        <Component {...props} />
      </SiteOrientationUniversalProvier>
    );
