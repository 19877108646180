import React, { FC, useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { Space, Spin, Button, Select, message, Input } from "antd";


function getPriceTypeDescription(value: string) {
  switch (value) {
    case 'free':  return 'Free';
    case 'fixed':  return 'Fixed price per month (unlimited projects)';
    case 'fixed_per_project':  return 'Fixed price per project per month';
    case 'per_worker': return 'Per user per month';
    case 'stripe': return  'Stripe subscription price';
    default:
      return value;
  }
}

export function getPaymentModelDescription(model: string) {
  switch (model) {
    case 'free':  return 'Free Usage';
    case 'gc':  return 'Billed to General Contractor only';
    case 'sub': return 'Billed to Subcontractors and GC';
    default:
      return model;
  }
}

//type EditState = NonNullable<GetGeneralContractorByPkQuery["general_contractor_by_pk"]>["billing_settings"];
export function formatCurrency(value: number): string {
  return (value / 100).toFixed(2);
}

export function parseCurrency(value: string): number {
  return Math.round(parseFloat(value) * 100) | 0;
}

interface ProjectPrice {
  type: string;
  workerPriceFirst: number;
  workerPriceSecond: number;
  projectFixedPrice: number;
  fixedPrice?: number | null;
}

export interface PriceSelectorProps {
  title: string;
  allowedPriceTypes?: string[];
  price: ProjectPrice;
  editing: boolean;
  saving: boolean;
  savePrice: () => void;
}
const labelAreaWidth = 140
const paddingLeft = `${labelAreaWidth}px`;

const AllPriceTypes = ["free", "fixed", "fixed_per_project", "per_worker"];

//type ProjectPrice = FreePrice | FixedPrice | UserPrice | StripePrice
export interface PriceSelectorRef {
  getEditedPrice: () => ProjectPrice; 
}

const PriceSelector = forwardRef<
PriceSelectorRef,
PriceSelectorProps
>(({ editing, saving, price, title, savePrice, allowedPriceTypes }, ref) => {
  const [priceType, setPriceType] = useState(price.type);
  const [workerPriceFirst, setWorkerPriceFirst] = useState(() => formatCurrency(price.workerPriceFirst));
  const [workerPriceSecond, setWorkerPriceSecond] = useState(() => formatCurrency(price.workerPriceSecond));
  const [projectFixedPrice, setProjectFixedPrice] = useState(() => formatCurrency(price.projectFixedPrice));
  const [fixedPrice, setFixedPrice] = useState(() => formatCurrency(price.fixedPrice ?? 800));
  const priceTypes = allowedPriceTypes ?? AllPriceTypes;

  useImperativeHandle(ref, () => ({
    getEditedPrice: (): ProjectPrice => {
      const res = {...price, type: priceType};
      if (priceType === "per_worker") {
        const priceFirst = parseCurrency(workerPriceFirst);
        const priceSecond = parseCurrency(workerPriceSecond);
        if (isNaN(priceFirst) || isNaN(priceSecond)) {
          throw new Error("Please enter correct price");
        }
        res.workerPriceFirst = priceFirst;
        res.workerPriceSecond = priceSecond;
      } else if (priceType === "fixed_per_project") {
        const price = parseCurrency(projectFixedPrice);
        if (isNaN(price)) {
          throw new Error("Please enter correct price");
        }
        res.projectFixedPrice = price;
      } else if (priceType === "fixed") {
        const price = parseCurrency(fixedPrice);
        if (isNaN(price)) {
          throw new Error("Please enter correct price");
        }
        res.fixedPrice = price;
      }
      return res;
    }
  }))
  return <><div style={{margin: '20px'}}>
      <span style={{display: 'inline-block',padding: '5px', width: `${labelAreaWidth + 10}px`, textAlign: 'right'}}>{title}:</span>
      { editing? 
      <Select value={priceType} onChange={(value) => setPriceType(value)} style={{width: '300px'}}>
        {priceTypes.map(value => (
          <Select.Option key={value} value={value}>{getPriceTypeDescription(value)}</Select.Option>
        ))}
      </Select>: <span>{getPriceTypeDescription(price.type)}</span>}
    </div>
    <div style={{margin: '20px', paddingLeft, display: priceType === "per_worker"?'block': 'none'}}>
      <div>
        <Space direction="horizontal"> {
            editing || saving ? 
              <Input disabled={saving} onKeyDown={(e) => {if (e.key === 'Enter') savePrice()}} style={{width: '100px'}} value={workerPriceFirst} onChange={(e) => setWorkerPriceFirst(e.target.value)}></Input> 
              : workerPriceFirst
            } USD - first user, {
              editing? 
                <Input disabled={saving} onKeyDown={(e) => {if (e.key === 'Enter') savePrice()}} style={{width: '100px'}} value={workerPriceSecond} onChange={(e) => setWorkerPriceSecond(e.target.value)}></Input> 
                : workerPriceSecond 
            } USD for second and others 
          </Space>
        </div>
    </div>
    {priceType === "fixed_per_project" && <div style={{margin: '20px', paddingLeft}}>
      <div>
        <Space direction="horizontal"> {              
            editing || saving ? 
              <Input disabled={saving} onKeyDown={(e) => {if (e.key === 'Enter') savePrice()}} style={{width: '100px'}} value={projectFixedPrice} onChange={(e) => setProjectFixedPrice(e.target.value)}></Input> 
              : projectFixedPrice
            } USD
        </Space>
      </div>
    </div>}
    {priceType === "fixed" && <div style={{margin: '20px', paddingLeft}}>
      <div>
        <Space direction="horizontal"> {              
            editing || saving ? 
              <Input disabled={saving} onKeyDown={(e) => {if (e.key === 'Enter') savePrice()}} style={{width: '100px'}} value={fixedPrice} onChange={(e) => setFixedPrice(e.target.value)}></Input> 
              : fixedPrice
            } USD
        </Space>
      </div>
    </div>}
    {priceType === "stripe" && <div style={{margin: '20px', paddingLeft}}>
      <div>
        <Space direction="horizontal">
            TODO: add UI for stripe price selection
        </Space>
      </div>
    </div>}        
</>
});


export default PriceSelector;