import { auth } from "../functions/firebase";

const useAuthUser = () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("Authenticated user not found please go back and sign in");
  }
  return user;
};
export default useAuthUser;
