import downloadFromUrl from "../../../common/functions/downloadFromUrl";
import { executeWithMessage } from "../../../common/functions/asyncUtility/executeWithMessage";
import {
  GenerateMergedSafetyReportPdfMutationOptions,
  GenerateMergedSafetyReportPdfMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { FetchResult } from "@apollo/client";

export const generateSafetyReportsPdf = (
  reportIds: string[],
  generateSafetyReportsPdfMutation: (
    options?: GenerateMergedSafetyReportPdfMutationOptions,
  ) => Promise<FetchResult<GenerateMergedSafetyReportPdfMutation>>,
) =>
  executeWithMessage({
    type: "loading",
    initialContent: "Download in progress...",
    successContent: "Downloaded successfully",
    asyncFunction: async () => {
      const { data: download } = await generateSafetyReportsPdfMutation({
        variables: {
          input: {
            safetyReportIds: reportIds,
          },
        },
      });
      if (download?.generateMergedSafetyReportPdf) {
        downloadFromUrl(download.generateMergedSafetyReportPdf);
      }
    },
  });
