import React from "react";
import { Drawer, Menu } from "antd";
import { PlayCircleTwoTone } from "@ant-design/icons";
import LanguagePicker from "../../entryRoutes/inPerson/routes/worker/components/LanguagePicker";
import { Language } from "src/utility-features/i18n/language-utils/i18nTypes";
import { useCurrentLanguageState } from "src/utility-features/i18n/context/languageHooks";
import { AVAILABLE_LANGUAGES } from "src/utility-features/i18n/languages";

export interface SiteOrientationSlidesViewerMenuProps {
  visible: boolean;
  collapse: () => void;
  menuItems: {
    viewed: boolean;
    title: string;
  }[];
  selectedPageIndex: number;
  firstNonCompletedPageIndex: number;
  onMenuItemClick: (index: number) => void;
  isTestLink?: boolean;
}

const SiteOrientationSlidesViewerMenu: React.FC<
  SiteOrientationSlidesViewerMenuProps
> = (props) => {
  const [lang, setLang] = useCurrentLanguageState();

  return (
    <Drawer
      placement={`left`}
      onClose={props.collapse}
      open={props.visible}
      extra={
        <LanguagePicker
          size={"md"}
          selectedLang={lang}
          availableLangs={AVAILABLE_LANGUAGES}
          onLangeChange={(newLang) => {
            setLang(newLang);
          }}
        />
      }
    >
      <Menu
        rootClassName={`border-none`}
        selectedKeys={[String(props.selectedPageIndex)]}
        items={props.menuItems.slice(0, -1).map((s, idx) => ({
          key: idx,
          onClick: () => {
            props.collapse();
            props.onMenuItemClick(idx);
          },
          disabled:
            !props.isTestLink &&
            !(s.viewed || idx === props.firstNonCompletedPageIndex),
          icon: idx === props.firstNonCompletedPageIndex && !s.viewed && (
            <PlayCircleTwoTone />
          ),
          label: `${idx + 1}. ${s.title}`,
        }))}
      />
    </Drawer>
  );
};

export default SiteOrientationSlidesViewerMenu;
