import { memo } from "react";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  UpdateUserCsrMutation,
  useUpdateUserCsrMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import WorkerProfileContactLink from "src/root/routes/views/subcontractor/people/workers/worker-profile/WorkerProfileContactLink";

const UserUpdateString: React.FC<{
  row: NonNullable<UpdateUserCsrMutation["update_user_by_pk"]>;
  type: "name" | "email" | "phone_number";
}> = memo(({ row, type }) => {
  const [updateUser, { loading }] = useUpdateUserCsrMutation();
  const authUser = useAuthUser();
  return (
    <WorkerProfileContactLink
      onUpdate={(v) => {
        const _set = { [type]: v };
        updateUser({
          variables: {
            userId: row.id,
            _set,
            csrEdits: [
              {
                change_log: [{ _set, prevVal: row[type] }],
                csr_user_id: authUser.uid,
                edit_text: `changed ${type} to ${v} for ${row.name}`,
                entity_id: row.id,
                name: "user",
                operation_name: "update",
              },
            ],
          },
          optimisticResponse: {
            update_user_by_pk: { ...row, [type]: v },
            insert_csr_edit: { affected_rows: 1 },
          },
        });
      }}
      hideCopy
      currentValue={row[type] || ""}
      type={type}
    />
  );
});
export default UserUpdateString;
