import dayjs from "dayjs";
import React from "react";
import { getLatestDate } from "src/common/components/Project";
import CounterLabel from "src/common/components/general/CounterLabel";
import { GCProjectReportsDailyBodyQuery$data } from "src/common/types/generated/relay/GCProjectReportsDailyBodyQuery.graphql";
import GCDailyReportsDeliveries, {
  GCDailyReportDeliveryType,
} from "./GCProjectReportsDailySummaryDeliveries";
import GCDailyReportsImages, {
  GCDailyReportImageType,
} from "./GCProjectReportsDailySummaryPhotos";
import GCDailyReportsTags, {
  GCDailyReportTagType,
  GCProjectReportsDailySummaryTagProps,
} from "./GCProjectReportsDailySummaryTags";
import GCDailyReportsSubcontractor, {
  GCDailyReportSubcontractorType,
  GCProjectReportsDailySummarySubcontractorProps,
} from "./GCProjectReportsDailySummarySubcontractor";

export interface GCProjectReportsDailySummaryProps {
  entries: number;
  workers: number;
  manHours: number;
  photos: GCDailyReportImageType[];
  tags: GCDailyReportTagType[];
  subcontractorList: GCDailyReportSubcontractorType[];
  subcontractors: number;
  schedule: number;
  weather: number;
  ptps: number;
  permits: number;
  tbts: number;
  workersOriented: number;
  visitors: number;
  deliveries: GCDailyReportDeliveryType[];
  title?: string;
  handleTagFilterChange: GCProjectReportsDailySummaryTagProps["handleTagFilterChange"];
  handleSubcontractorFilterChange: GCProjectReportsDailySummarySubcontractorProps["handleSubcontractorFilterChange"];
  searchQuery: string;
}

const GCProjectReportsDailySummary: React.FC<
  GCProjectReportsDailySummaryProps
> = (props) => {
  return (
    <div className="flex flex-col self-center gap-1 p-1 rounded-1 bg-suplementary-1">
      {!!props.title && (
        <div className="w-full text-center text-1 font-accent">
          {props.title}
        </div>
      )}
      <div className="relative pt-0.5 flex flex-row items-center gap-1">
        <CounterLabel count={props.entries} label={`Entries`} />
        <CounterLabel
          count={props.subcontractors}
          label={`Subcontractor`}
          modalOnClick={
            props.subcontractors
              ? {
                  title: "Subcontractor",
                  width: 25,
                  content: (
                    <GCDailyReportsSubcontractor
                      {...{
                        items: props.subcontractorList,
                        handleSubcontractorFilterChange:
                          props.handleSubcontractorFilterChange,
                      }}
                    ></GCDailyReportsSubcontractor>
                  ),
                }
              : undefined
          }
        />
        <CounterLabel count={props.workers} label={`Workers`} />
        <CounterLabel
          count={props.workersOriented}
          label={`Workers Oriented`}
        />
        <CounterLabel count={props.manHours} label={`Man Hours`} />
        <CounterLabel
          count={props.photos.length}
          label={`Photos`}
          modalOnClick={
            !!props.photos.length
              ? {
                  content: (
                    <GCDailyReportsImages
                      {...{ items: props.photos }}
                    ></GCDailyReportsImages>
                  ),
                  title: "Photos",
                }
              : undefined
          }
        />
        <CounterLabel
          count={props.tags.length}
          label={`Tags`}
          modalOnClick={
            props.tags.length
              ? {
                  title: "Tags",
                  content: (
                    <GCDailyReportsTags
                      {...{
                        items: props.tags,
                        handleTagFilterChange: props.handleTagFilterChange,
                      }}
                    ></GCDailyReportsTags>
                  ),
                }
              : undefined
          }
        />
        <CounterLabel count={props.schedule} label={`Schedule`} />
        <CounterLabel count={props.weather} label={`Weather`} />
        <CounterLabel count={props.ptps} label={`PTPs`} />
        <CounterLabel count={props.permits} label={`Permits`} />
        <CounterLabel count={props.tbts} label={`TBTs`} />
        <CounterLabel
          count={props.deliveries.length}
          label={`Deliveries`}
          modalOnClick={
            props.deliveries.length
              ? {
                  content: (
                    <GCDailyReportsDeliveries deliveries={props.deliveries} />
                  ),
                  title: `Deliveries`,
                }
              : undefined
          }
        />
        <CounterLabel count={props.visitors} label={`Visitors`} />
      </div>
    </div>
  );
};

export const gcMonthlyReportsSummaryProps: (
  data: GCProjectReportsDailyBodyQuery$data,
  hasInPerson: boolean,
  hasSlides: boolean,
  assignHardHat: boolean,
  handleTagFilterChange: GCProjectReportsDailySummaryProps["handleTagFilterChange"],
  handleSubcontractorFilterChange: GCProjectReportsDailySummaryProps["handleSubcontractorFilterChange"],
  selectedTagIds: string[],
  selectedSubcontractorIds: string[],
  searchQuery: string,
) => GCProjectReportsDailySummaryProps = (
  data,
  hasInPerson,
  hasSlides,
  assignHardHat,
  handleTagFilterChange,
  handleSubcontractorFilterChange,
  selectedTagIds,
  selectedSubcontractorIds,
  searchQuery,
) => {
  let tags: GCDailyReportTagType[] = [],
    subcontractorList: GCDailyReportSubcontractorType[] = [],
    manHours = 0,
    workers = 0,
    weather = 0;
  const deliveries: Array<GCDailyReportDeliveryType> = [];
  const photos: Array<GCDailyReportImageType> = [];
  const subs = new Set<string>();
  const workersSubMap = new Map<
    string,
    {
      gcDaysCountMap: Map<string, number>;
      uniqueWorkerIdsSet: Set<string>;
    }
  >();
  let safetyReports = data.daily_safety_report_connection.edges;
  safetyReports.forEach((safetyReport) => {
    safetyReport.node.daily_safety_report_tags.forEach((dailySafetyLogTag) => {
      if (tags.findIndex((tag) => tag.id === dailySafetyLogTag.tag.pk) === -1)
        tags.push({
          id: dailySafetyLogTag.tag.pk,
          name: dailySafetyLogTag.tag.name.en,
          colorHex: dailySafetyLogTag.tag.color_hex,
          checked:
            selectedTagIds.findIndex(
              (item) => item === dailySafetyLogTag.tag.pk,
            ) === -1
              ? false
              : true,
        });
    });

    if (
      safetyReport.node.subcontractor &&
      subcontractorList.findIndex(
        (subcontractor) =>
          subcontractor.id === safetyReport.node.subcontractor?.pk,
      ) === -1
    )
      subcontractorList.push({
        id: safetyReport.node.subcontractor?.pk,
        name: safetyReport.node.subcontractor?.name,
        checked:
          selectedSubcontractorIds.findIndex(
            (item) => item === safetyReport.node.subcontractor?.pk,
          ) === -1
            ? false
            : true,
      });
  });
  if (selectedTagIds && selectedTagIds.length > 0) {
    safetyReports = safetyReports.filter((item) => {
      return item.node.daily_safety_report_tags.some((log) =>
        selectedTagIds.includes(log.tag.pk),
      );
    });
  }

  if (selectedSubcontractorIds && selectedSubcontractorIds.length > 0) {
    safetyReports = safetyReports.filter((item) => {
      return selectedSubcontractorIds.includes(
        item.node.subcontractor?.pk ?? "",
      );
    });
  }

  if (searchQuery && searchQuery.length > 0) {
    safetyReports = safetyReports.filter((item) => {
      let searchString = `${item.node.subcontractor?.name ?? ""} ${
        item.node.created_by_user.name
      }`;
      searchString = `${searchString} ${item.node.daily_safety_report_comments.reduce(
        (tempString: string, s: any) =>
          `${tempString} ${s.text_translation.en}`,
        "",
      )}`;
      searchString = `${searchString} ${item.node.daily_safety_report_tags.reduce(
        (tempString: string, s: any) => `${tempString} ${s.tag.name.en}`,
        "",
      )}`;

      return searchString.toUpperCase().includes(searchQuery.toUpperCase());
    });
  }

  safetyReports.forEach((d) => {
    if (d.node.subcontractor && !subs.has(d.node.subcontractor.pk))
      subs.add(d.node.subcontractor.pk);
    if (d.node.images && d.node.images.length > 0) {
      d.node.images.forEach((image) => {
        photos.push({
          image: { src: image.url },
          subcontractorTitle: d.node.subcontractor?.name ?? "",
          author: d.node.created_by_user.name,
          time: dayjs(d.node.created_at),
        });
      });
    }
  });

  let dailyReports = data.daily_work_log_connection.edges.filter(
    (log) =>
      !!log.node.submitted_at || !!log.node.created_by_project_user.employee,
  );
  dailyReports.forEach((dailyReport) => {
    dailyReport.node.daily_work_log_tags.forEach((dailyWorkLogTag) => {
      if (tags.findIndex((tag) => tag.id === dailyWorkLogTag.tag.pk) === -1)
        tags.push({
          id: dailyWorkLogTag.tag.pk,
          name: dailyWorkLogTag.tag.name.en,
          colorHex: dailyWorkLogTag.tag.color_hex,
          checked:
            selectedTagIds.findIndex(
              (item) => item === dailyWorkLogTag.tag.pk,
            ) === -1
              ? false
              : true,
        });
    });
    if (
      dailyReport.node.subcontractor &&
      subcontractorList.findIndex(
        (subcontractor) =>
          subcontractor.id === dailyReport.node.subcontractor_id,
      ) === -1
    )
      subcontractorList.push({
        id: dailyReport.node.subcontractor_id ?? "",
        name: dailyReport.node.subcontractor.name,
        checked:
          selectedSubcontractorIds.findIndex(
            (item) => item === dailyReport.node.subcontractor_id,
          ) === -1
            ? false
            : true,
      });
  });
  if (selectedTagIds && selectedTagIds.length > 0) {
    dailyReports = dailyReports.filter((item) => {
      return item.node.daily_work_log_tags.some((log) =>
        selectedTagIds.includes(log.tag.pk),
      );
    });
  }

  if (selectedSubcontractorIds && selectedSubcontractorIds.length > 0) {
    dailyReports = dailyReports.filter((item) => {
      return (
        item.node.subcontractor &&
        selectedSubcontractorIds.includes(item.node.subcontractor_id ?? "")
      );
    });
  }

  if (searchQuery && searchQuery.length > 0) {
    dailyReports = dailyReports.filter((item) => {
      let searchString = `${
        item.node.subcontractor?.name ??
        item.node.general_contractor?.name ??
        ""
      } ${item.node.created_by_project_user.employee?.user.name ?? ""} ${
        item.node.description.en
      }`;
      searchString = `${searchString} ${item.node.daily_work_log_comments.reduce(
        (tempString: string, s: any) => `${tempString} ${s.text.en}`,
        "",
      )}`;
      searchString = `${searchString} ${item.node.daily_work_log_tags.reduce(
        (tempString: string, s: any) => `${tempString} ${s.tag.name.en}`,
        "",
      )}`;

      return searchString.toUpperCase().includes(searchQuery.toUpperCase());
    });
  }

  let dailyNotes = data.daily_note_connection.edges;
  dailyNotes.forEach((dailyNote) => {
    dailyNote.node.daily_note_tags.forEach((dailyWorkLogNotes) => {
      if (tags.findIndex((tag) => tag.id === dailyWorkLogNotes.tag.pk) === -1)
        tags.push({
          id: dailyWorkLogNotes.tag.pk,
          name: dailyWorkLogNotes.tag.name.en,
          colorHex: dailyWorkLogNotes.tag.color_hex,
          checked:
            selectedTagIds.findIndex(
              (item) => item === dailyWorkLogNotes.tag.pk,
            ) === -1
              ? false
              : true,
        });
    });
    dailyNote.node.daily_note_subs.forEach((dailyNotesSubcontractor) => {
      if (
        dailyNotesSubcontractor.subcontractor &&
        subcontractorList.findIndex(
          (tag) => tag.id === dailyNotesSubcontractor.subcontractor?.pk ?? "",
        ) === -1
      )
        subcontractorList.push({
          id: dailyNotesSubcontractor.subcontractor.pk,
          name: dailyNotesSubcontractor.subcontractor.name,
          checked: selectedSubcontractorIds.includes(
            dailyNotesSubcontractor.subcontractor.pk,
          ),
        });
    });
  });
  if (selectedTagIds && selectedTagIds.length > 0) {
    dailyNotes = dailyNotes.filter((item) => {
      return item.node.daily_note_tags.some((log) =>
        selectedTagIds.includes(log.tag.pk),
      );
    });
  }

  if (selectedSubcontractorIds && selectedSubcontractorIds.length > 0) {
    dailyNotes = dailyNotes.filter((item) => {
      return item.node.daily_note_subs.some(
        (log) =>
          log.subcontractor &&
          selectedSubcontractorIds.includes(log.subcontractor.pk),
      );
    });
  }

  if (searchQuery && searchQuery.length > 0) {
    dailyNotes = dailyNotes.filter((item) => {
      let searchString = `${item.node.daily_note_subs.reduce(
        (tempString: string, s: any) =>
          `${tempString} ${
            s.subcontractor?.name ?? s.general_contractor?.name
          }`,
        "",
      )} ${item.node.text.en} ${item.node.created_by_employee.user.name}`;
      searchString = `${searchString}
        ${item.node.daily_note_tags.reduce(
          (tempString: string, s: any) => `${tempString} ${s.tag.name.en}`,
          "",
        )}`;

      return searchString.toUpperCase().includes(searchQuery.toUpperCase());
    });
  }

  dailyReports.forEach((d) => {
    const date = dayjs(d.node.date).format("YYYY-MM-DD");
    const curWorkerMapVal = workersSubMap.get(d.node.subcontractor_id ?? "");
    weather += d.node.daily_weather_observations.length;
    d.node.images.forEach((img) =>
      photos.push({
        image: { src: img.url },
        subcontractorTitle:
          d.node.subcontractor?.name ?? d.node.general_contractor?.name ?? "",
        author:
          d.node.created_by_project_user.project_worker?.user?.name ??
          d.node.created_by_project_user.employee?.user.name ??
          "",
        time: d.node.date ? dayjs(d.node.date) : undefined,
      }),
    );
    if (d.node.subcontractor) {
      if (curWorkerMapVal) {
        const uniqueWorkersSet = curWorkerMapVal.uniqueWorkerIdsSet;
        d.node.daily_work_log_workers.forEach((w) =>
          uniqueWorkersSet.add(w.project_worker.worker_id),
        );

        workersSubMap.set(d.node.subcontractor_id ?? "", {
          gcDaysCountMap: curWorkerMapVal.gcDaysCountMap.set(
            date,
            (curWorkerMapVal.gcDaysCountMap.get(date) ?? 0) +
              (d.node.worker_count ?? 0),
          ),
          uniqueWorkerIdsSet: uniqueWorkersSet,
        });
      } else {
        const uniqueWorkersSet = new Set<string>();
        d.node.daily_work_log_workers.forEach((w) =>
          uniqueWorkersSet.add(w.project_worker.worker_id),
        );
        workersSubMap.set(d.node.subcontractor_id ?? "", {
          gcDaysCountMap: new Map().set(date, d.node.worker_count ?? 0),
          uniqueWorkerIdsSet: uniqueWorkersSet,
        });
      }
      if (!subs.has(d.node.subcontractor_id ?? ""))
        subs.add(d.node.subcontractor_id ?? "");
    }
    if (d.node.worker_count) {
      manHours += d.node.worker_count * (d.node.worker_hours || 0);
    } else {
      manHours += d.node.daily_work_log_workers.reduce(
        (s, w) => s + w.hours,
        0,
      );
    }
  });

  dailyNotes.forEach((d) => {
    d.node.daily_note_subs.forEach((sub) => {
      if (sub.subcontractor && !subs.has(sub.subcontractor.pk))
        subs.add(sub.subcontractor.pk);
    });
  });
  const workersOriented = data.project_worker_connection.edges.filter((p) => {
    const wo = p.node.user_orientation;
    if (
      (assignHardHat ? p.node.hard_hat_number : true) &&
      (wo?.completed_at ||
        ((hasInPerson ? wo?.in_person_orientated_at : true) &&
          (hasSlides ? wo?.orientated_at : true)))
    )
      return true;
    return false;
  }).length;

  if (selectedTagIds == null || selectedTagIds.length === 0) {
    let delivery_connection = data.delivery_connection.edges;
    delivery_connection.forEach((log) => {
      if (
        log.node.subcontractor &&
        log.node.subcontractor_id &&
        subcontractorList.findIndex(
          (subcontractor) => subcontractor.id === log.node.subcontractor_id,
        ) === -1
      )
        subcontractorList.push({
          id: log.node.subcontractor_id,
          name: log.node.subcontractor.name,
          checked: selectedSubcontractorIds.includes(log.node.subcontractor_id),
        });
    });
    if (selectedSubcontractorIds && selectedSubcontractorIds.length > 0) {
      delivery_connection = delivery_connection.filter((item) => {
        return selectedSubcontractorIds.includes(
          item.node.subcontractor_id ?? "",
        );
      });
    }
    delivery_connection.forEach((d) => {
      deliveries.push({
        deliveryTime: dayjs(d.node.start_at),
        author: d.node.user?.name ?? "",
        storageLocation: d.node.storage_location?.en ?? "",
        title: d.node.name?.en ?? "",
        detail: d.node.detail?.en ?? "",
      });
      if (d.node.subcontractor_id && !subs.has(d.node.subcontractor_id))
        subs.add(d.node.subcontractor_id);
    });
    let permits = data.permit_connection.edges;
    permits.forEach((permit) => {
      if (
        permit.node.subcontractor &&
        permit.node.subcontractor_id &&
        subcontractorList.findIndex(
          (subcontractor) => subcontractor.id === permit.node.subcontractor_id,
        ) === -1
      )
        subcontractorList.push({
          id: permit.node.subcontractor_id,
          name: permit.node.subcontractor.name,
          checked:
            selectedSubcontractorIds.findIndex(
              (item) => item === permit.node.subcontractor_id,
            ) === -1
              ? false
              : true,
        });
    });
    if (selectedSubcontractorIds && selectedSubcontractorIds.length > 0) {
      permits = permits.filter((item) => {
        return selectedSubcontractorIds.includes(
          item.node.subcontractor_id ?? "",
        );
      });
    }
    permits.forEach((d) => {
      if (d.node.subcontractor_id && !subs.has(d.node.subcontractor_id))
        subs.add(d.node.subcontractor_id);
    });
    let ptps = data.report_connection.edges;
    ptps.forEach((ptp) => {
      if (
        ptp.node.subcontractor &&
        ptp.node.subcontractor_id &&
        subcontractorList.findIndex(
          (subcontractor) => subcontractor.id === ptp.node.subcontractor_id,
        ) === -1
      )
        subcontractorList.push({
          id: ptp.node.subcontractor_id,
          name: ptp.node.subcontractor.name,
          checked:
            selectedSubcontractorIds.findIndex(
              (item) => item === ptp.node.subcontractor_id,
            ) === -1
              ? false
              : true,
        });
    });
    if (selectedSubcontractorIds && selectedSubcontractorIds.length > 0) {
      ptps = ptps.filter((item) => {
        return selectedSubcontractorIds.includes(
          item.node.subcontractor_id ?? "",
        );
      });
    }
    ptps.forEach((d) => {
      if (d.node.subcontractor_id && !subs.has(d.node.subcontractor_id))
        subs.add(d.node.subcontractor_id);
    });
    let tbts = data.toolbox_talk_connection.edges;
    tbts.forEach((tbt) => {
      if (
        tbt.node.subcontractor &&
        tbt.node.subcontractor_id &&
        subcontractorList.findIndex(
          (subcontractor) => subcontractor.id === tbt.node.subcontractor_id,
        ) === -1
      )
        subcontractorList.push({
          id: tbt.node.subcontractor_id,
          name: tbt.node.subcontractor.name,
          checked:
            selectedSubcontractorIds.findIndex(
              (item) => item === tbt.node.subcontractor_id,
            ) === -1
              ? false
              : true,
        });
    });
    if (selectedSubcontractorIds && selectedSubcontractorIds.length > 0) {
      tbts = tbts.filter((item) => {
        return selectedSubcontractorIds.includes(
          item.node.subcontractor_id ?? "",
        );
      });
    }
    tbts.forEach((d) => {
      if (d.node.subcontractor_id && !subs.has(d.node.subcontractor_id))
        subs.add(d.node.subcontractor_id);
    });
    let schedule = data.daily_schedule_event_connection.edges;
    schedule.forEach((log) => {
      log.node.daily_schedule_event_subs.forEach((scheduleSubcontractor) => {
        if (
          scheduleSubcontractor.subcontractor &&
          subcontractorList.findIndex(
            (tag) => tag.id === scheduleSubcontractor.subcontractor_id,
          ) === -1
        )
          subcontractorList.push({
            id: scheduleSubcontractor.subcontractor_id ?? "",
            name: scheduleSubcontractor.subcontractor.name,
            checked:
              selectedSubcontractorIds.findIndex(
                (item) => item === scheduleSubcontractor.subcontractor_id,
              ) === -1
                ? false
                : true,
          });
      });
    });
    if (selectedSubcontractorIds && selectedSubcontractorIds.length > 0) {
      schedule = schedule.filter((item) => {
        return item.node.daily_schedule_event_subs.some(
          (log) =>
            log.subcontractor &&
            selectedSubcontractorIds.includes(log.subcontractor_id ?? ""),
        );
      });
    }
    schedule.forEach((d) => {
      d.node.daily_schedule_event_subs.forEach((p) => {
        if (p.subcontractor_id && !subs.has(p.subcontractor_id))
          subs.add(p.subcontractor_id);
      });
    });
  }

  workersSubMap.forEach((val) => {
    const uniqueSubEnteredWorkers = val.uniqueWorkerIdsSet.size;
    let maxOfGcsEntry = 0;
    val.gcDaysCountMap.forEach(
      (g) => (maxOfGcsEntry = maxOfGcsEntry > g ? maxOfGcsEntry : g),
    );
    workers +=
      maxOfGcsEntry > uniqueSubEnteredWorkers
        ? maxOfGcsEntry
        : uniqueSubEnteredWorkers;
  });
  let visitorCount = data.project_visitor_certification_connection.edges.length;
  data.project_visitor_certification_connection.edges.forEach((visitor) => {
    manHours += visitor.node.hours_planning_to_stay;
  });
  return {
    entries: dailyReports.length + dailyNotes.length + safetyReports.length,
    manHours,
    photos,
    tags,
    weather,
    workers,
    workersOriented,
    subcontractorList,
    subcontractors: subs.size,
    deliveries,
    schedule: data.daily_schedule_event_connection.edges.length,
    permits: data.permit_connection.edges.length,
    ptps: data.report_connection.edges.length,
    tbts: data.toolbox_talk_connection.edges.length,
    title: "Monthly Summary",
    handleTagFilterChange: handleTagFilterChange,
    handleSubcontractorFilterChange: handleSubcontractorFilterChange,
    searchQuery,
    visitors: visitorCount,
  };
};

export const gcDailyReportsSummaryProps: (
  data: GCProjectReportsDailyBodyQuery$data,
  day: dayjs.Dayjs,
  hasInPerson: boolean,
  hasSlides: boolean,
  assignHardHat: boolean,
  handleTagFilterChange: GCProjectReportsDailySummaryProps["handleTagFilterChange"],
  handleSubcontractorFilterChange: GCProjectReportsDailySummaryProps["handleSubcontractorFilterChange"],
  selectedTagIds: string[],
  selectedSubcontractorIds: string[],
  searchQuery: string,
) => GCProjectReportsDailySummaryProps = (
  data,
  day,
  hasInPerson,
  hasSlides,
  assignHardHat,
  handleTagFilterChange,
  handleSubcontractorFilterChange,
  selectedTagIds,
  selectedSubcontractorIds,
  searchQuery,
) => {
  let tags: GCDailyReportTagType[] = [],
    subcontractorList: GCDailyReportSubcontractorType[] = [],
    manHours = 0,
    workers = 0,
    weather = 0;
  const deliveries: Array<GCDailyReportDeliveryType> = [];
  const photos: Array<GCDailyReportImageType> = [];
  const subs = new Set<string>();
  const date = dayjs(day).format("YYYY-MM-DD");
  let safetyReports = data.daily_safety_report_connection.edges.filter(
    (log) => dayjs(log.node.date).format("YYYY-MM-DD") === date,
  );
  safetyReports.forEach((safetyReport) => {
    safetyReport.node.daily_safety_report_tags.forEach((dailySafetyLogTag) => {
      if (tags.findIndex((tag) => tag.id === dailySafetyLogTag.tag.pk) === -1)
        tags.push({
          id: dailySafetyLogTag.tag.pk,
          name: dailySafetyLogTag.tag.name.en,
          colorHex: dailySafetyLogTag.tag.color_hex,
          checked:
            selectedTagIds.findIndex(
              (item) => item === dailySafetyLogTag.tag.pk,
            ) === -1
              ? false
              : true,
        });
    });
    if (
      safetyReport.node.subcontractor &&
      subcontractorList.findIndex(
        (subcontractor) =>
          subcontractor.id === safetyReport.node.subcontractor?.pk,
      ) === -1
    )
      subcontractorList.push({
        id: safetyReport.node.subcontractor?.pk,
        name: safetyReport.node.subcontractor?.name,
        checked: selectedSubcontractorIds.includes(
          safetyReport.node.subcontractor?.pk,
        ),
      });
  });
  if (selectedTagIds && selectedTagIds.length > 0) {
    safetyReports = safetyReports.filter((item) => {
      return item.node.daily_safety_report_tags.some((log) =>
        selectedTagIds.includes(log.tag.pk),
      );
    });
  }
  if (selectedSubcontractorIds && selectedSubcontractorIds.length > 0) {
    safetyReports = safetyReports.filter((item) => {
      return selectedSubcontractorIds.includes(
        item.node.subcontractor?.pk ?? "",
      );
    });
  }
  if (searchQuery && searchQuery.length > 0) {
    safetyReports = safetyReports.filter((item) => {
      let searchString = `${item.node.subcontractor?.name ?? ""} ${
        item.node.created_by_user.name
      }`;
      searchString = `${searchString} ${item.node.daily_safety_report_comments.reduce(
        (tempString: string, s: any) =>
          `${tempString} ${s.text_translation.en}`,
        "",
      )}`;
      searchString = `${searchString} ${item.node.daily_safety_report_tags.reduce(
        (tempString: string, s: any) => `${tempString} ${s.tag.name.en}`,
        "",
      )}`;

      return searchString.toUpperCase().includes(searchQuery.toUpperCase());
    });
  }
  safetyReports.forEach((d) => {
    if (d.node.subcontractor && !subs.has(d.node.subcontractor.pk))
      subs.add(d.node.subcontractor.pk);
    if (d.node.images && d.node.images.length > 0) {
      d.node.images.forEach((image) => {
        photos.push({
          image: { src: image.url },
          subcontractorTitle: d.node.subcontractor?.name ?? "",
          author: d.node.created_by_user.name,
          time: dayjs(d.node.created_at),
        });
      });
    }
  });
  let dailyReports = data.daily_work_log_connection.edges.filter(
    (log) =>
      (!!log.node.submitted_at ||
        !!log.node.created_by_project_user.employee) &&
      dayjs(log.node.date).format("YYYY-MM-DD") === date,
  );
  dailyReports.forEach((dailyReport) => {
    dailyReport.node.daily_work_log_tags.forEach((dailyWorkLogTag) => {
      if (tags.findIndex((tag) => tag.id === dailyWorkLogTag.tag.pk) === -1)
        tags.push({
          id: dailyWorkLogTag.tag.pk,
          name: dailyWorkLogTag.tag.name.en,
          colorHex: dailyWorkLogTag.tag.color_hex,
          checked:
            selectedTagIds.findIndex(
              (item) => item === dailyWorkLogTag.tag.pk,
            ) === -1
              ? false
              : true,
        });
    });
    if (
      dailyReport.node.subcontractor &&
      subcontractorList.findIndex(
        (subcontractor) =>
          subcontractor.id === dailyReport.node.subcontractor_id,
      ) === -1
    )
      subcontractorList.push({
        id: dailyReport.node.subcontractor_id ?? "",
        name: dailyReport.node.subcontractor.name,
        checked:
          selectedSubcontractorIds.findIndex(
            (item) => item === dailyReport.node.subcontractor_id,
          ) === -1
            ? false
            : true,
      });
  });
  if (selectedTagIds && selectedTagIds.length > 0) {
    dailyReports = dailyReports.filter((item) => {
      return item.node.daily_work_log_tags.some((log) =>
        selectedTagIds.includes(log.tag.pk),
      );
    });
  }
  if (selectedSubcontractorIds && selectedSubcontractorIds.length > 0) {
    dailyReports = dailyReports.filter((item) => {
      return (
        item.node.subcontractor &&
        selectedSubcontractorIds.includes(item.node.subcontractor_id ?? "")
      );
    });
  }

  if (searchQuery && searchQuery.length > 0) {
    dailyReports = dailyReports.filter((item) => {
      let searchString = `${
        item.node.subcontractor?.name ??
        item.node.general_contractor?.name ??
        ""
      } ${item.node.created_by_project_user.employee?.user.name ?? ""} ${
        item.node.description.en
      }`;
      searchString = `${searchString} ${item.node.daily_work_log_comments.reduce(
        (tempString: string, s: any) => `${tempString} ${s.text.en}`,
        "",
      )}`;
      searchString = `${searchString} ${item.node.daily_work_log_tags.reduce(
        (tempString: string, s: any) => `${tempString} ${s.tag.name.en}`,
        "",
      )}`;

      return searchString.toUpperCase().includes(searchQuery.toUpperCase());
    });
  }

  let dailyNotes = data.daily_note_connection.edges.filter(
    (log) => dayjs(log.node.date).format("YYYY-MM-DD") === date,
  );
  dailyNotes.forEach((dailyNote) => {
    dailyNote.node.daily_note_tags.forEach((dailyWorkLogNotes) => {
      if (tags.findIndex((tag) => tag.id === dailyWorkLogNotes.tag.pk) === -1)
        tags.push({
          id: dailyWorkLogNotes.tag.pk,
          name: dailyWorkLogNotes.tag.name.en,
          colorHex: dailyWorkLogNotes.tag.color_hex,
          checked:
            selectedTagIds.findIndex(
              (item) => item === dailyWorkLogNotes.tag.pk,
            ) === -1
              ? false
              : true,
        });
    });
    dailyNote.node.daily_note_subs.forEach((dailyNotesSubcontractor) => {
      if (
        dailyNotesSubcontractor.subcontractor &&
        subcontractorList.findIndex(
          (tag) => tag.id === dailyNotesSubcontractor.subcontractor?.pk ?? "",
        ) === -1
      )
        subcontractorList.push({
          id: dailyNotesSubcontractor.subcontractor.pk,
          name: dailyNotesSubcontractor.subcontractor.name,
          checked: selectedSubcontractorIds.includes(
            dailyNotesSubcontractor.subcontractor.pk,
          ),
        });
    });
  });
  if (selectedTagIds && selectedTagIds.length > 0) {
    dailyNotes = dailyNotes.filter((item) => {
      return item.node.daily_note_tags.some((log) =>
        selectedTagIds.includes(log.tag.pk),
      );
    });
  }

  if (selectedSubcontractorIds && selectedSubcontractorIds.length > 0) {
    dailyNotes = dailyNotes.filter((item) => {
      return item.node.daily_note_subs.some(
        (log) =>
          log.subcontractor &&
          selectedSubcontractorIds.includes(log.subcontractor.pk),
      );
    });
  }

  if (searchQuery && searchQuery.length > 0) {
    dailyNotes = dailyNotes.filter((item) => {
      let searchString = `${item.node.daily_note_subs.reduce(
        (tempString: string, s: any) =>
          `${tempString} ${
            s.subcontractor?.name ?? s.general_contractor?.name
          }`,
        "",
      )} ${item.node.text.en} ${item.node.created_by_employee.user.name}`;
      searchString = `${searchString}
        ${item.node.daily_note_tags.reduce(
          (tempString: string, s: any) => `${tempString} ${s.tag.name.en}`,
          "",
        )}`;

      return searchString.toUpperCase().includes(searchQuery.toUpperCase());
    });
  }

  const startOfDay = dayjs(day).startOf("day");
  const endOfDay = dayjs(day).endOf("day");
  dailyReports.forEach((d) => {
    d.node.images.forEach((img) =>
      photos.push({
        image: { src: img.url },
        subcontractorTitle:
          d.node.subcontractor?.name ?? d.node.general_contractor?.name ?? "",
        author:
          d.node.created_by_project_user.project_worker?.user?.name ??
          d.node.created_by_project_user.employee?.user.name ??
          "",
        time: d.node.date ? dayjs(d.node.date) : undefined,
      }),
    );
    weather += d.node.daily_weather_observations.length;

    if (d.node.subcontractor_id && !subs.has(d.node.subcontractor_id))
      subs.add(d.node.subcontractor_id);
    if (d.node.worker_count) {
      workers += d.node.worker_count;
      manHours += d.node.worker_count * (d.node.worker_hours || 0);
    } else {
      workers += d.node.daily_work_log_workers.length;
      manHours += d.node.daily_work_log_workers.reduce(
        (s, w) => s + w.hours,
        0,
      );
    }
  });
  dailyNotes.forEach((d) => {
    d.node.daily_note_subs.forEach((sub) => {
      if (sub.subcontractor && !subs.has(sub.subcontractor.pk))
        subs.add(sub.subcontractor.pk);
    });
  });
  let permits = [],
    workersOriented = 0,
    schedule = [],
    ptps = [],
    tbts = [];
  if (selectedTagIds == null || selectedTagIds.length === 0) {
    let delivery_connection = data.delivery_connection.edges.filter(
      (p) =>
        dayjs(p.node.start_at).isAfter(startOfDay) &&
        dayjs(p.node.start_at).isBefore(endOfDay),
    );
    delivery_connection.forEach((log) => {
      if (
        log.node.subcontractor &&
        log.node.subcontractor_id &&
        subcontractorList.findIndex(
          (subcontractor) => subcontractor.id === log.node.subcontractor_id,
        ) === -1
      )
        subcontractorList.push({
          id: log.node.subcontractor_id,
          name: log.node.subcontractor.name,
          checked:
            selectedSubcontractorIds.findIndex(
              (item) => item === log.node.subcontractor_id,
            ) === -1
              ? false
              : true,
        });
    });
    if (selectedSubcontractorIds && selectedSubcontractorIds.length > 0) {
      delivery_connection = delivery_connection.filter((item) => {
        return selectedSubcontractorIds.includes(
          item.node.subcontractor_id ?? "",
        );
      });
    }
    delivery_connection.map((d) => {
      deliveries.push({
        deliveryTime: dayjs(d.node.start_at),
        author: d.node.user?.name ?? "",
        storageLocation: d.node.storage_location?.en ?? "",
        title: d.node.name?.en ?? "",
        detail: d.node.detail?.en ?? "",
      });
      if (d.node.subcontractor_id && !subs.has(d.node.subcontractor_id))
        subs.add(d.node.subcontractor_id);
      return d;
    });

    workersOriented = data.project_worker_connection.edges.filter((p) => {
      const wo = p.node.user_orientation;
      const latestOrientatedDate = getLatestDate(
        wo?.completed_at,
        getLatestDate(wo?.in_person_orientated_at, wo?.orientated_at),
      );
      if (
        dayjs(latestOrientatedDate).format("YYYY-MM-DD") === date &&
        (assignHardHat ? p.node.hard_hat_number : true) &&
        (wo?.completed_at ||
          ((hasInPerson ? wo?.in_person_orientated_at : true) &&
            (hasSlides ? wo?.orientated_at : true)))
      )
        return true;
      return false;
    }).length;

    schedule = data.daily_schedule_event_connection.edges.filter(
      (p) => dayjs(p.node.date).format("YYYY-MM-DD") === date,
    );
    schedule.forEach((log) => {
      log.node.daily_schedule_event_subs.forEach((scheduleSubcontractor) => {
        if (
          scheduleSubcontractor.subcontractor &&
          subcontractorList.findIndex(
            (tag) => tag.id === scheduleSubcontractor.subcontractor_id,
          ) === -1
        )
          subcontractorList.push({
            id: scheduleSubcontractor.subcontractor_id ?? "",
            name: scheduleSubcontractor.subcontractor.name,
            checked:
              selectedSubcontractorIds.findIndex(
                (item) => item === scheduleSubcontractor.subcontractor_id,
              ) === -1
                ? false
                : true,
          });
      });
    });
    if (selectedSubcontractorIds && selectedSubcontractorIds.length > 0) {
      schedule = schedule.filter((item) => {
        return item.node.daily_schedule_event_subs.some(
          (log) =>
            log.subcontractor &&
            selectedSubcontractorIds.includes(log.subcontractor_id ?? ""),
        );
      });
    }
    schedule.map((d) => {
      d.node.daily_schedule_event_subs.forEach((p) => {
        if (p.subcontractor_id && !subs.has(p.subcontractor_id))
          subs.add(p.subcontractor_id);
      });
      return d;
    });
    permits = data.permit_connection.edges.filter(
      (p) =>
        dayjs(p.node.created_at).isAfter(startOfDay) &&
        dayjs(p.node.created_at).isBefore(endOfDay),
    );
    permits.forEach((permit) => {
      if (
        permit.node.subcontractor &&
        permit.node.subcontractor_id &&
        subcontractorList.findIndex(
          (subcontractor) => subcontractor.id === permit.node.subcontractor_id,
        ) === -1
      )
        subcontractorList.push({
          id: permit.node.subcontractor_id,
          name: permit.node.subcontractor.name,
          checked:
            selectedSubcontractorIds.findIndex(
              (item) => item === permit.node.subcontractor_id,
            ) === -1
              ? false
              : true,
        });
    });
    if (selectedSubcontractorIds && selectedSubcontractorIds.length > 0) {
      permits = permits.filter((item) => {
        return selectedSubcontractorIds.includes(
          item.node.subcontractor_id ?? "",
        );
      });
    }
    permits.map((d) => {
      if (d.node.subcontractor_id && !subs.has(d.node.subcontractor_id))
        subs.add(d.node.subcontractor_id);
      return d;
    });
    ptps = data.report_connection.edges.filter(
      (p) =>
        dayjs(p.node.created_at).isAfter(startOfDay) &&
        dayjs(p.node.created_at).isBefore(endOfDay),
    );
    ptps.forEach((ptp) => {
      if (
        ptp.node.subcontractor &&
        ptp.node.subcontractor_id &&
        subcontractorList.findIndex(
          (subcontractor) => subcontractor.id === ptp.node.subcontractor_id,
        ) === -1
      )
        subcontractorList.push({
          id: ptp.node.subcontractor_id,
          name: ptp.node.subcontractor.name,
          checked:
            selectedSubcontractorIds.findIndex(
              (item) => item === ptp.node.subcontractor_id,
            ) === -1
              ? false
              : true,
        });
    });
    if (selectedSubcontractorIds && selectedSubcontractorIds.length > 0) {
      ptps = ptps.filter((item) => {
        return selectedSubcontractorIds.includes(
          item.node.subcontractor_id ?? "",
        );
      });
    }
    ptps.map((d) => {
      if (d.node.subcontractor_id && !subs.has(d.node.subcontractor_id))
        subs.add(d.node.subcontractor_id);
      return d;
    });
    tbts = data.toolbox_talk_connection.edges.filter(
      (p) =>
        dayjs(p.node.created_at).isAfter(startOfDay) &&
        dayjs(p.node.created_at).isBefore(endOfDay),
    );
    tbts.forEach((tbt) => {
      if (
        tbt.node.subcontractor &&
        tbt.node.subcontractor_id &&
        subcontractorList.findIndex(
          (subcontractor) => subcontractor.id === tbt.node.subcontractor_id,
        ) === -1
      )
        subcontractorList.push({
          id: tbt.node.subcontractor_id,
          name: tbt.node.subcontractor.name,
          checked:
            selectedSubcontractorIds.findIndex(
              (item) => item === tbt.node.subcontractor_id,
            ) === -1
              ? false
              : true,
        });
    });
    if (selectedSubcontractorIds && selectedSubcontractorIds.length > 0) {
      tbts = tbts.filter((item) => {
        return selectedSubcontractorIds.includes(
          item.node.subcontractor_id ?? "",
        );
      });
    }
    tbts.map((d) => {
      if (d.node.subcontractor_id && !subs.has(d.node.subcontractor_id))
        subs.add(d.node.subcontractor_id);
      return d;
    });
  }
  let visitorCount = 0;
  data.project_visitor_certification_connection.edges.forEach((visitor) => {
    if (dayjs(visitor.node.created_at).format("YYYY-MM-DD") === date) {
      manHours += visitor.node.hours_planning_to_stay;
      visitorCount++;
    }
  });

  return {
    entries: dailyReports.length + dailyNotes.length + safetyReports.length,
    manHours,
    tags,
    weather,
    workers,
    workersOriented,
    photos,
    subcontractorList,
    subcontractors: subs.size,
    deliveries,
    schedule: schedule.length,
    permits: permits.length,
    ptps: ptps.length,
    tbts: tbts.length,
    title: "Daily Summary",
    handleTagFilterChange: handleTagFilterChange,
    handleSubcontractorFilterChange: handleSubcontractorFilterChange,
    searchQuery,
    visitors: visitorCount,
  };
};

export default GCProjectReportsDailySummary;
