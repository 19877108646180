import { IconMail } from "@tabler/icons";
import { message } from "antd";
import React, { useRef } from "react";
import SendEmailModal, {
  SendEmailModalRef,
} from "src/common/components/dialogs/SendEmailModal";
import Button from "src/common/components/general/Button";
import { useEmailDailyLogsPdfMutation } from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";

export interface GCProjectReportsDailyDayEmailButtonProps {
  projectId: string;
  day: dayjs.Dayjs;
}

const GCProjectReportsDailyDayEmailButton: React.FC<
  GCProjectReportsDailyDayEmailButtonProps
> = (props) => {
  const [emailLogs] = useEmailDailyLogsPdfMutation();
  const emailModal = useRef<SendEmailModalRef>(null);

  const handleEmailClick = () => {
    emailModal.current?.open();
  };
  return (
    <>
      <SendEmailModal
        ref={emailModal}
        title={"Email Daily Report"}
        onSubmit={async ({ email }) => {
          emailModal.current?.close();
          message.success("Emailed report!");
          await emailLogs({
            variables: {
              input: {
                projectId: props.projectId,
                date: props.day.format(),
                type: "gc",
                emailRecipients: [email],
              },
            },
          });
        }}
      />
      <Button
        icon={IconMail}
        onClick={handleEmailClick}
        label={`Email Daily Report`}
        secondary
      />
    </>
  );
};

export default GCProjectReportsDailyDayEmailButton;
