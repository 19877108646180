import React, { FC, useMemo } from "react";
import TableTitle from "src/common/components/tables/basic/TableTitle";
import useColumnOrderBy from "src/common/hooks/useColumnOrderBy";
import useColumnSearch from "src/common/hooks/useColumnSearch";
import usePaginatedQuery, {
  UsePaginatedQueryParams,
} from "src/common/hooks/usePaginatedQuery";
import {
  General_Contractor,
  General_Contractor_Bool_Exp,
  General_Contractor_Order_By,
  GetGeneralContractorsDocument,
  Order_By,
} from "src/common/types/generated/apollo/graphQLTypes";
import Columns from "./Columns";

type DataType = General_Contractor;

interface TableProps {
  onClickRow: (dr: Partial<DataType>) => void;
  skipKeys?: Array<string>;
  titleComponent?: any;
  loading?: boolean;
  queryRef?: (tableRef: any) => any;
}

const GeneralContractorsTable: FC<TableProps> = ({
  onClickRow,
  queryRef,
  titleComponent,
  skipKeys = [],
  ...props
}) => {
  const { orderBy, setOrderBy } = useColumnOrderBy<General_Contractor_Order_By>(
    {
      name: Order_By.AscNullsLast,
    }
  );
  const topicSearch = useColumnSearch();

  const queryVariables = useMemo(
    (): UsePaginatedQueryParams<General_Contractor_Bool_Exp> => ({
      where: {},
      orderBy: orderBy,
      queryDoc: GetGeneralContractorsDocument,
      dataIndex: "general_contractor",
      aggregateCountIndex: "general_contractor_aggregate",
    }),
    [orderBy]
  );

  const [
    generalContractors,
    { loading, page, pageSize, setPage, count, refetch },
  ] = usePaginatedQuery<Partial<DataType>>(queryVariables);

  if (queryRef) {
    queryRef({
      refetch,
    });
  }

  return (
    <Columns
      sortColumns={{
        name: true,
      }}
      searchColumns={{ topic: topicSearch.setter }}
      title={() => (
        <TableTitle
          titleComponent={titleComponent}
          title="General Contractors"
        />
      )}
      onChange={(_, filters, sorter) => {
        setOrderBy(sorter);
      }}
      loading={loading || props.loading}
      skipKeys={skipKeys || []}
      onRow={(tbt) => ({
        onClick: () => {
          onClickRow(tbt);
        },
      })}
      dataSource={generalContractors}
      pagination={{
        current: page,
        pageSize,
        total: count,
        onChange: setPage,
      }}
    />
  );
};

export default GeneralContractorsTable;
