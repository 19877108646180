import { Modal, Table, message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import {
  AddTasksFromOtherJHALibraryModalQuery,
  AddTasksFromOtherJHALibraryModalQuery$data,
  task_bool_exp,
} from "src/common/types/generated/relay/AddTasksFromOtherJHALibraryModalQuery.graphql";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import useAddTaskToProjectOrCompany from "../../utils/useAddTaskToProjectOrCompany";
import LargeTableSkeleton from "src/common/components/tables/basic/LargeTableSkeletion";

type TableRow =
  AddTasksFromOtherJHALibraryModalQuery$data["task_connection"]["edges"][0]["node"];
const query = graphql`
  query AddTasksFromOtherJHALibraryModalQuery($where: task_bool_exp!) {
    task_connection(where: $where) {
      edges {
        node {
          ...TaskFrag @relay(mask: false)
        }
      }
    }
  }
`;

interface ModalContentProps {
  taskWhere: task_bool_exp;
  setSelectedRows: React.Dispatch<React.SetStateAction<Array<TableRow>>>;
}

const ModalContent: FC<ModalContentProps> = ({
  setSelectedRows,
  taskWhere,
}) => {
  const data = useLazyLoadQuery<AddTasksFromOtherJHALibraryModalQuery>(
    query,
    {
      where: {
        report_id: { _is_null: true },
        deleted_at: { _is_null: true },
        request_state: { _is_null: true },
        is_pending_acceptance: { _eq: false },
        ...taskWhere,
      },
    },
    { fetchPolicy: "network-only" },
  ).task_connection.edges;

  const showProjectColumn = data[0] ? data[0].node.project_id : undefined;
  return (
    <BaseTable
      rowSelection={{
        type: "checkbox",
        onChange: (_, rows) => setSelectedRows(rows),
        getCheckboxProps: (record: any) => ({
          disabled: record.name === "Disabled User", // Column configuration not to be checked
          name: record.name,
        }),
      }}
      rowKey={(t) => t.pk}
      columns={
        showProjectColumn
          ? [
              {
                title: "JHA Name",
                dataIndex: ["description", "en"],
                key: "name",
              },
              {
                title: "Project Name",
                dataIndex: ["project", "name"],
                key: "project",
              },
              {
                title: "Steps",
                dataIndex: ["task_steps"],
                key: "steps",
                render: (a) => a.length,
              },
            ]
          : [
              {
                title: "JHA Name",
                dataIndex: ["description", "en"],
                key: "name",
              },

              {
                title: "Steps",
                dataIndex: ["task_steps"],
                key: "steps",
                render: (a) => a.length,
              },
            ]
      }
      expandable={{
        expandedRowRender: (w: TableRow) => (
          <div>
            <p>Steps ({w.task_steps.length})</p>
            {w.task_steps.map((step, i) => (
              <>
                <p>
                  <strong>Step {i + 1}.</strong> {step.description.en}
                </p>
                {step.task_step_hazards.length > 0 && (
                  <div
                    style={{
                      paddingLeft: 24,
                    }}
                  >
                    <p>
                      <strong>Hazards ({step.task_step_hazards.length})</strong>
                    </p>
                    {step.task_step_hazards.map((h, i) => (
                      <>
                        <p>
                          {i + 1}. {h.description.en}
                        </p>
                        {h.control && <p>Control: {h.control.en}</p>}
                      </>
                    ))}
                  </div>
                )}
              </>
            ))}
          </div>
        ),
      }}
      dataSource={data.map((i) => i.node)}
      className="pt-1"
      bordered
    />
  );
};

interface AddTasksFromOtherJHALibraryModalProps {
  refresh: () => void;
  visible: boolean;
  closeModal: () => void;
  subcontractorId: string;
  projectId: string | null;
  taskWhere?: task_bool_exp;
  addToProjectOrCompany: "project" | "company";
}
const AddTasksFromOtherLibraryModal: FC<
  AddTasksFromOtherJHALibraryModalProps
> = ({
  refresh,
  subcontractorId,
  closeModal,
  projectId,
  visible,
  taskWhere,
  addToProjectOrCompany,
}) => {
  const [curRows, setCurRows] = useState<Array<TableRow>>([]);
  const [insertTasks, inserting] = useAddTaskToProjectOrCompany();
  if (!taskWhere) return null;

  const onOkEvent = async (tasksToAdd: Array<TableRow>) => {
    await insertTasks(
      tasksToAdd,
      subcontractorId,
      projectId,
      addToProjectOrCompany == "project" ? "addToProject" : "addToCompany",
    );
    refresh();
    closeModal();
  };

  return (
    <Modal
      open={visible}
      confirmLoading={inserting}
      onCancel={closeModal}
      onOk={() => {
        onOkEvent(curRows);
      }}
    >
      <CustomSuspense fallback={<LargeTableSkeleton />}>
        <ModalContent setSelectedRows={setCurRows} taskWhere={taskWhere} />
      </CustomSuspense>
    </Modal>
  );
};

export default withCustomSuspense(AddTasksFromOtherLibraryModal);
