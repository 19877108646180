import dayjs from "dayjs";
import React, { useState } from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import getDateStr from "src/common/functions/getDateStr";
import GCProjectReportsDailyTopBar from "./header/GCProjectReportsDailyTopBar";
import { useSearchParams } from "react-router-dom";
import GCProjectReportsDailyMonthRange from "./header/GCProjectReportsDailyMonthRange";
import GCProjectReportsDailyBody from "./GCProjectReportsDailyBody";

export interface GCProjectReportsDailyProps {
  projectId: string;
}

const GCProjectReportsDaily: React.FC<GCProjectReportsDailyProps> = ({
  projectId,
}) => {
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
  const [selectedSubcontractorIds, setSelectedSubcontractorIds] = useState<
    string[]
  >([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const searchParamsRange: {
    startMonth?: string;
    endMonth?: string;
  } = {
    startMonth: searchParams.get("startMonth") ?? undefined,
    endMonth: searchParams.get("endMonth") ?? undefined,
  };
  const startMonth = searchParamsRange.startMonth
    ? dayjs(searchParamsRange.startMonth)
    : dayjs();
  const endMonth = searchParamsRange.endMonth
    ? dayjs(searchParamsRange.endMonth)
    : dayjs();

  const handleMonthChange = (
    newStartMonth: dayjs.Dayjs,
    newEndMonth: dayjs.Dayjs,
  ) => {
    setSelectedTagIds([]);
    setSelectedSubcontractorIds([]);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(
      "startMonth",
      getDateStr(newStartMonth, { format: "numeric" }),
    );
    newSearchParams.set(
      "endMonth",
      getDateStr(newEndMonth, { format: "numeric" }),
    );
    setSearchParams(newSearchParams);
  };

  return (
    <div className="flex flex-col w-full gap-0 mt-3 h-fit">
      <div className="flex flex-col items-center">
        <GCProjectReportsDailyMonthRange
          startMonth={startMonth}
          endMonth={endMonth}
        />
      </div>
      <div
        className={`sticky z-10 flex flex-row justify-center p-0.5 my-0.5 bg-white -top-px`}
      >
        <GCProjectReportsDailyTopBar
          projectId={projectId}
          startMonth={startMonth}
          endMonth={endMonth}
          onMonthChange={handleMonthChange}
          onSearchSubmit={setSearchQuery}
        />
      </div>
      <GCProjectReportsDailyBody
        projectId={projectId}
        selectedTagIds={selectedTagIds}
        onSelectedTagIdsChange={setSelectedTagIds}
        selectedSubIds={selectedSubcontractorIds}
        onSelectedSubIdsChange={setSelectedSubcontractorIds}
        startMonth={startMonth}
        endMonth={endMonth}
        searchQuery={searchQuery}
      />
    </div>
  );
};

export default withCustomSuspense(GCProjectReportsDaily);
