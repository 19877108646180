import { useNavigate } from "react-router-dom";
import React, { useRef } from "react";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import ObservationTable from "../../components/ObservationTable";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";

const SCObservations: React.FunctionComponent = () => {
  const subcontractorId = useSubcontractorId();
  const ref = useRef(null);
  const navigate = useNavigate();
  return (
    <BasicWrapper>
      <ObservationTable
        ref={ref}
        where={{ subcontractor_id: { _eq: subcontractorId } }}
        onRowClick={(item) => {
          navigate(`/sub/observations/${item.id}/${item.project?.id}`);
        }}
        subcontractorId={subcontractorId}
        excludedKeys={["sub_name"]}
      />
    </BasicWrapper>
  );
};
export default SCObservations;
