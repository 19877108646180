import { useState } from "react";
import interpretSorter from "src/common/functions/interpretSorter";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";

function useColumnOrderBy<OrderByType>(defaultOrderBy?: OrderByType) {
  const defaultValue = defaultOrderBy
    ? defaultOrderBy
    : { created_at: Order_By.DescNullsLast };

  const [value, setter] = useState<OrderByType>(defaultValue as any);

  const setOrderBy = (sorter: any) => {
    const val = interpretSorter(sorter);
    if (val) {
      setter(val as any);
    } else {
      setter(defaultValue as any);
    }
  };

  return {
    orderBy: value,
    setOrderBy,
  };
}

export default useColumnOrderBy;
