import React from "react";

interface SeparatorProps {
  dark?: boolean;
}

const Separator: React.FC<SeparatorProps> = (props) => {
  return (
    <div
      className={`w-full h-px ${
        !props.dark ? "bg-suplementary-1" : "bg-suplementary-3"
      }`}
    ></div>
  );
};

export default Separator;
