/**
 * @generated SignedSource<<94f4731daa73b44ef435cb4786b2d585>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IncidentTable_incidents$data = {
  readonly incident_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly attached_files_aggregate: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
        readonly created_at: string;
        readonly description: {
          readonly en: string;
        };
        readonly incident_types: ReadonlyArray<{
          readonly documents_aggregate: {
            readonly aggregate: {
              readonly count: number;
            } | null | undefined;
          };
          readonly incident_type: {
            readonly detail: {
              readonly en: string;
            };
          };
        }>;
        readonly incident_users: ReadonlyArray<{
          readonly injury_detail: {
            readonly attached_files_aggregate: {
              readonly aggregate: {
                readonly count: number;
              } | null | undefined;
            };
          } | null | undefined;
          readonly statement_detail: {
            readonly attached_files_aggregate: {
              readonly aggregate: {
                readonly count: number;
              } | null | undefined;
            };
          } | null | undefined;
        }>;
        readonly injuredUsers: ReadonlyArray<{
          readonly user: {
            readonly employee: {
              readonly general_contractor: {
                readonly name: string;
              };
            } | null | undefined;
            readonly general_person: {
              readonly employer: string | null | undefined;
            } | null | undefined;
            readonly name: string;
            readonly role: string;
            readonly worker: {
              readonly subcontractor: {
                readonly name: string;
              } | null | undefined;
            } | null | undefined;
          };
        }>;
        readonly pk: string;
        readonly root_cause_analysis: {
          readonly document_aggregate: {
            readonly aggregate: {
              readonly count: number;
            } | null | undefined;
          };
        } | null | undefined;
        readonly status: string | null | undefined;
        readonly summary: {
          readonly en: string;
        };
      };
    }>;
  };
  readonly " $fragmentType": "IncidentTable_incidents";
};
export type IncidentTable_incidents$key = {
  readonly " $data"?: IncidentTable_incidents$data;
  readonly " $fragmentSpreads": FragmentRefs<"IncidentTable_incidents">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "incident_connection"
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "en",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "document_aggregate_fields",
    "kind": "LinkedField",
    "name": "aggregate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "document_aggregate",
  "kind": "LinkedField",
  "name": "attached_files_aggregate",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./IncidentTableRefetchableQuery.graphql')
    }
  },
  "name": "IncidentTable_incidents",
  "selections": [
    {
      "alias": "incident_connection",
      "args": null,
      "concreteType": "incidentConnection",
      "kind": "LinkedField",
      "name": "__IncidentTable_incident_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "incidentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "incident",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": "pk",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id_pk",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "text_translation",
                  "kind": "LinkedField",
                  "name": "description",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "text_translation",
                  "kind": "LinkedField",
                  "name": "summary",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "incident_type_link",
                  "kind": "LinkedField",
                  "name": "incident_types",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "incident_type",
                      "kind": "LinkedField",
                      "name": "incident_type",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "text_translation",
                          "kind": "LinkedField",
                          "name": "detail",
                          "plural": false,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "document_aggregate",
                      "kind": "LinkedField",
                      "name": "documents_aggregate",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": "injuredUsers",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "where",
                      "value": {
                        "deleted_at": {
                          "_is_null": true
                        },
                        "type": {
                          "_eq": "injured"
                        }
                      }
                    }
                  ],
                  "concreteType": "incident_user",
                  "kind": "LinkedField",
                  "name": "incident_users",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "user",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "role",
                          "storageKey": null
                        },
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "worker",
                          "kind": "LinkedField",
                          "name": "worker",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "subcontractor",
                              "kind": "LinkedField",
                              "name": "subcontractor",
                              "plural": false,
                              "selections": (v4/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "general_person",
                          "kind": "LinkedField",
                          "name": "general_person",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "employer",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "general_contractor_employee",
                          "kind": "LinkedField",
                          "name": "employee",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "general_contractor",
                              "kind": "LinkedField",
                              "name": "general_contractor",
                              "plural": false,
                              "selections": (v4/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "incident_users(where:{\"deleted_at\":{\"_is_null\":true},\"type\":{\"_eq\":\"injured\"}})"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "incident_user",
                  "kind": "LinkedField",
                  "name": "incident_users",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "injury_detail",
                      "kind": "LinkedField",
                      "name": "injury_detail",
                      "plural": false,
                      "selections": (v6/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "statement_detail",
                      "kind": "LinkedField",
                      "name": "statement_detail",
                      "plural": false,
                      "selections": (v6/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "root_cause_analysis",
                  "kind": "LinkedField",
                  "name": "root_cause_analysis",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "document_aggregate",
                      "kind": "LinkedField",
                      "name": "document_aggregate",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "00aff5fcd322955bd89be39bb8ae6f19";

export default node;
