import React from "react";
import { Progress, Spin } from "antd";
import { blue } from "@ant-design/colors";
import clsx from "clsx";

export interface AudioPlayerProgressBarProps {
  durationSec: number;
  timeProgressSec: number;
  bufferedTime?: number;
  loading?: boolean;
}

const formatTime = (time: number) => {
  if (time && !isNaN(time)) {
    const minutes = Math.floor(time / 60);
    const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(time % 60);
    const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${formatMinutes}:${formatSeconds}`;
  }
  return "00:00";
};

const AudioPlayerProgressBar: React.FC<AudioPlayerProgressBarProps> = (
  props
) => {
  const timeProgressStr = formatTime(props.timeProgressSec);
  const durationStr = formatTime(props.durationSec);
  const progressPercent = (100 / props.durationSec) * props.timeProgressSec;
  const bufferedPercent = props.bufferedTime
    ? (100 / props.durationSec) * props.bufferedTime
    : progressPercent;
  console.log("render progress");
  return (
    <div className={`flex flex-row items-center gap-0.5`}>
      <div
        className={clsx(` text-0.75`, props.loading && "text-suplementary-3")}
      >
        {timeProgressStr}
      </div>

      <div className={`relative flex-1`}>
        <Spin spinning={props.loading} size={`small`}>
          <Progress
            percent={bufferedPercent}
            strokeColor={blue[1]}
            showInfo={false}
            success={{
              percent: progressPercent,
              strokeColor: blue.primary,
            }}
          />
        </Spin>
      </div>
      <div
        className={clsx(`text-0.75`, props.loading && "text-suplementary-3")}
      >
        {durationStr}
      </div>
    </div>
  );
};

export default AudioPlayerProgressBar;
