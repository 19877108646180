import React from "react";

export interface SiteOrientationSlidesViewerSlideImageProps {
  src: string;
}

const SiteOrientationSlidesViewerSlideImage: React.FC<
  SiteOrientationSlidesViewerSlideImageProps
> = (props) => {
  return (
    <div className="flex w-full text-center align-middle">
      <img
        src={props.src}
        className="object-contain w-full h-auto m-auto max-h-48"
      />
    </div>
  );
};

export default SiteOrientationSlidesViewerSlideImage;
