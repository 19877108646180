import { FC } from "react";
import {
  useUpdateEnvironmentalIncidentMutation,
  useGetMonetarySeverityLevelsQuery,
  Environmental_Incident_Set_Input,
  Document_Insert_Input,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  GetIncidentByPkDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import { Card } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import useInsertIncidentType from "../../utils/useInsertIncidentType";
import getIncidentDocuments from "../../utils/getIncidentDocuments";
import getNextPage from "../../utils/getNextPage";
import useUploadIncidentTypeDocument from "../../utils/useUploadIncidentTypeDocument";
import useDeleteIncidentTypeDocument from "../../utils/useDeleteIncidentTypeDocument";
import IncidentTextField from "../basic/IncidentTextField";
import IncidentSelectField from "../basic/IncidentSelectField";
import IncidentDocViewAndUpload from "../basic/IncidentDocViewAndUpload";
import IncidentBottomButtons from "../basic/IncidentBottomButtons";
import dayjs from "dayjs";
import useUpdateMonetarySeverity from "../../utils/useUpdateMonetarySeverity";
import { deletedDocument } from "../basic/IncidentDocViewer";
import { useSuspenseQuery } from "@apollo/client";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";

const EnvironmentalIncident: FC = () => {
  const { incidentId, projectId } = useParams();
  const navigate = useNavigate();
  const authUser = useAuthUser();

  if (!projectId) throw new Error("projectId is missing");
  if (!incidentId) throw new Error("incidentId is missing");

  const { data: incidentData } = useSuspenseQuery<
    GetIncidentByPkQuery,
    GetIncidentByPkQueryVariables
  >(GetIncidentByPkDocument, {
    variables: {
      incidentId: incidentId,
    },
    fetchPolicy: "cache-first",
  });

  const { data: monetarySeverity } = useGetMonetarySeverityLevelsQuery();
  const incident = incidentData?.incident_by_pk;
  const monetarySeverityLevels = monetarySeverity?.incident_severity;
  if (!incident) throw new Error("Incident is missing");

  const [updateMonetarySeverity] = useUpdateMonetarySeverity();
  const [uploadIncidentDocument] = useUploadIncidentTypeDocument();
  const [insertIncidentType] = useInsertIncidentType();
  const [deleteIncidentDocument] = useDeleteIncidentTypeDocument();
  const environmental = incident.environmental_incident;
  const documents = getIncidentDocuments(incident, "environmental");
  if (!environmental) {
    throw new Error("environmental_incident is missing");
  }

  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocument(objects, incident, "environmental");
  };
  const insertIncidentTypeLink = async () => {
    await insertIncidentType(incident, incidentId, "environmental");
  };
  const deleteDocument = async (doc: deletedDocument) => {
    await deleteIncidentDocument(doc, incident, "environmental");
  };
  const [updateEnvironmentalIncident] =
    useUpdateEnvironmentalIncidentMutation();

  const updateEnvironmental = async (
    _set: Omit<Environmental_Incident_Set_Input, "incident_id">,
    comment: string,
  ) => {
    const updatedIncident = {
      ...incident,
      environmental_incident: {
        ...environmental,
        ..._set,
      },
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    await insertIncidentTypeLink();
    await updateEnvironmentalIncident({
      variables: {
        incidentId: incidentId,
        _set: _set,
        object: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incidentId,
          edit_type: "environmental-incident-edit",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_environmental_incident_by_pk: {
          ...environmental,
          incident_id: incidentId,
          ..._set,
        },
      },
    });
  };

  const environmentalIncidentTypeOptions = [
    { label: "Fire/Smoke", value: "fire" },
    {
      label: "Indoor Air Quality",
      value: "indoor_air_quality",
    },
    { label: "Spill/Leak", value: "spill" },
    { label: "SWPPP", value: "swppp" },
    { label: "Waste Disposal", value: "waste_disposal" },
    { label: "Waste Handling", value: "waste_handling" },
    { label: "Other", value: "other" },
  ];

  return (
    <>
      <div className="absolute left-24 top-2 text-2">Environmental</div>
      <div className="w-full mt-6 pl-4 table-fixed overflow-y-auto">
        <Card className="w-4/5">
          <IncidentSelectField
            title="* Select type of environmental incident"
            options={environmentalIncidentTypeOptions}
            value={environmental.type ?? undefined}
            onChange={(option) => {
              if (typeof option.value === "string") {
                updateEnvironmental(
                  {
                    type: option.value,
                  },
                  `Updated Environmental - "Select type of environmental incident" to ${option.label}`,
                );
              }
            }}
          />
        </Card>
        <Card className="w-4/5">
          <IncidentSelectField
            title="Monetary Severity"
            options={
              monetarySeverityLevels?.map((severity) => ({
                label: severity.name.en,
                value: severity.id,
              })) ?? []
            }
            value={incident.monetary_severity_id ?? undefined}
            onChange={(option) => {
              if (typeof option.value === "string") {
                updateMonetarySeverity(incident, option.value, option.label);
              }
            }}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentTextField
            label="* Details of environmental incident"
            text={environmental.nature.en}
            textId={environmental.nature.id}
            saveIncidentField={insertIncidentTypeLink}
            fieldTypeKey="environmental"
            field="nature"
            incident={incident}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentTextField
            label="* Cause of incident"
            text={environmental.cause.en}
            textId={environmental.cause.id}
            saveIncidentField={insertIncidentTypeLink}
            fieldTypeKey="environmental"
            field="cause"
            incident={incident}
          />
        </Card>

        {incident.incident_types.findIndex(
          (indexType) => indexType.type_value === "environmental",
        ) !== -1 && (
          <IncidentDocViewAndUpload
            deleteDocument={deleteDocument}
            documents={documents}
            groupId={incidentId}
            uploadDocument={uploadDocument}
            type="environmental"
          />
        )}

        <IncidentBottomButtons
          saveDisabled={
            !environmental.type ||
            !environmental.nature.en ||
            !environmental.cause.en
          }
          onNextClick={() => {
            const next = getNextPage(incident, "environmental");
            navigate(
              `/gce/projects/${projectId}/incidents/${incidentId}/${next}`,
            );
          }}
          onSave={() => {
            updateEnvironmental(
              { completed_at: dayjs().format() },
              `Environmental Section marked as completed`,
            );
          }}
        />
      </div>
    </>
  );
};

export default EnvironmentalIncident;
