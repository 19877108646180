import * as React from "react";

import GCWorkersTable, {
  Subcontractor,
} from "src/common/components/tables/GCWorkersTable";
import { useGetSubcontractorsQuery } from "src/common/types/generated/apollo/graphQLTypes";

import LoadingContent from "src/common/components/general/LoadingContent";
import StyledContent from "src/common/components/layouts/StyledContent";
import { auth } from "src/common/functions/firebase";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";

const GCEmployeeVerification: React.FunctionComponent = () => {
  const userId = auth.currentUser?.uid;

  if (!userId) {
    throw new Error("User not logged in");
  }

  const { data, loading, error } = useGetSubcontractorsQuery({
    variables: {
      where: {
        project_workers: {
          is_last: { _eq: true },
          deleted_at: { _is_null: true },
          archived_at: { _is_null: true },
          project: {
            project_employees: {
              employee: {
                user: { id: { _eq: userId } },
                general_contractor: { employee_verification: { _eq: true } },
              },
            },
          },
        },
      },
    },
  });
  if (loading) {
    return <LoadingContent />;
  }
  if (!data) {
    throw Error(
      error?.message ?? "An Error occurred while fetching subcontractors",
    );
  }

  const subContractorFilterOptions: Subcontractor[] = data.subcontractor.map(
    (subcontractor) => {
      return {
        id: subcontractor.id,
        name: subcontractor.name,
      };
    },
  );

  return (
    <div className="w-full h-full overflow-y-auto">
      <StyledContent
        style={{ marginTop: 10, minHeight: "90vh" }}
        padding
        backgroundColor="white"
      >
        <GCWorkersTable
          userId={userId}
          title="Employee Verification"
          subContractorFilterOptions={subContractorFilterOptions}
          where={{
            is_last: { _eq: true },
            deleted_at: { _is_null: true },
            archived_at: { _is_null: true },
            project: {
              project_employees: {
                employee: {
                  user: { id: { _eq: userId } },
                  general_contractor: { employee_verification: { _eq: true } },
                },
              },
            },
          }}
        ></GCWorkersTable>
      </StyledContent>
    </div>
  );
};

export default withCustomSuspense(GCEmployeeVerification);
