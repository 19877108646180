import React from "react";
import { Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";

interface IncidentBottomButtonsProps {
  onNextClick: () => void;
  onSave: () => void;
  saveDisabled: boolean;
}

const IncidentBottomButtons: React.FunctionComponent<
  IncidentBottomButtonsProps
> = ({ onNextClick, onSave, saveDisabled }) => {
  return (
    <div className="flex w-4/5 justify-end gap-2 mt-4">
      <Button
        className="w-6"
        type="primary"
        htmlType="submit"
        onClick={onNextClick}
      >
        Next
      </Button>
      <Button
        type="primary"
        icon={<CheckOutlined />}
        htmlType="submit"
        onClick={onSave}
        disabled={saveDisabled}
      >
        Section Complete
      </Button>
    </div>
  );
};

export default IncidentBottomButtons;
