import { useParams } from "react-router-dom";
import { auth } from "src/common/functions/firebase";

export interface BusinessUnitProps {
  businessUnitId: string;
}

const withBusinessUnitId =
  <Props extends object>(Component: React.FC<Props & BusinessUnitProps>) =>
  (props: Props) => {
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error("Undeifned user id");
    const { businessUnitId } = useParams();
    if (!businessUnitId) throw new Error("businessUnitId not found");
    return <Component businessUnitId={businessUnitId} {...props} />;
  };

export default withBusinessUnitId;
