/**
 * @generated SignedSource<<0b23c5bd465f5816d87c9ff1fe0294c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubBlockEditModal_deleteSubBlockMutation$variables = {
  id: string;
};
export type SubBlockEditModal_deleteSubBlockMutation$data = {
  readonly delete_project_delivery_sub_block: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
  readonly delete_sub_block_calendar: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
  readonly delete_sub_block_subcontractor: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type SubBlockEditModal_deleteSubBlockMutation = {
  response: SubBlockEditModal_deleteSubBlockMutation$data;
  variables: SubBlockEditModal_deleteSubBlockMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "id"
  }
],
v2 = [
  {
    "fields": [
      {
        "fields": (v1/*: any*/),
        "kind": "ObjectValue",
        "name": "sub_block_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": (v2/*: any*/),
    "concreteType": "sub_block_subcontractor_mutation_response",
    "kind": "LinkedField",
    "name": "delete_sub_block_subcontractor",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sub_block_subcontractor",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v2/*: any*/),
    "concreteType": "sub_block_calendar_mutation_response",
    "kind": "LinkedField",
    "name": "delete_sub_block_calendar",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sub_block_calendar",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": (v1/*: any*/),
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_delivery_sub_block_mutation_response",
    "kind": "LinkedField",
    "name": "delete_project_delivery_sub_block",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "project_delivery_sub_block",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubBlockEditModal_deleteSubBlockMutation",
    "selections": (v4/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubBlockEditModal_deleteSubBlockMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "07b1556055a92bde68861f5d8d55a7c8",
    "id": null,
    "metadata": {},
    "name": "SubBlockEditModal_deleteSubBlockMutation",
    "operationKind": "mutation",
    "text": "mutation SubBlockEditModal_deleteSubBlockMutation(\n  $id: uuid!\n) {\n  delete_sub_block_subcontractor(where: {sub_block_id: {_eq: $id}}) {\n    returning {\n      id\n    }\n  }\n  delete_sub_block_calendar(where: {sub_block_id: {_eq: $id}}) {\n    returning {\n      id\n    }\n  }\n  delete_project_delivery_sub_block(where: {id: {_eq: $id}}) {\n    returning {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8f8357ceabb552e73a3cc58d9f4f4764";

export default node;
