import React from "react";
import { SiteOrientationStepProps } from "../../../../../utils/siteOrientationTypes";
import SiteOrientationStepsUIProfilePhoto from "src/domain-features/siteorientation/components/stepsUI/profilePhoto/SiteOrientationStepsUIProfilePhoto";
import useSiteOrientationUniversal from "../../../newDesign/utils/siteOrientationUniversalHook";

import { useCurrentLangStrings } from "../../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteOrientationUniveralStepsCertificationsProps
  extends SiteOrientationStepProps {}

const SiteOrientationUniversalStepsProfilePhoto: React.FC<
  SiteOrientationUniveralStepsCertificationsProps
> = (props) => {
  const context = useSiteOrientationUniversal();
  const langStrings = useCurrentLangStrings();
  if (context.orientee) {
    return (
      <SiteOrientationStepsUIProfilePhoto
        user={context.orientee}
        langStrings={langStrings}
        type="universal"
        onNext={props.onNext}
        onBack={props.onBack}
        onNewProfilePhotoUpload={(profilePhoto) => {
          context.updateOrientee((u) =>
            // only existing user can do so
            u
              ? {
                  ...u,
                  profileImg: {
                    ...u.profileImg,
                    new: profilePhoto,
                  },
                }
              : null,
          );
        }}
      />
    );
  } else {
    return null;
  }
};

export default SiteOrientationUniversalStepsProfilePhoto;
