import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useState } from "react";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useGetProjectDeliveryBlockOutQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { useBlockOutDeliveryTimesDeleteBlockOutMutation } from "src/common/types/generated/relay/useBlockOutDeliveryTimesDeleteBlockOutMutation.graphql";
import { useBlockOutDeliveryTimesInsertBlockOutMutation } from "src/common/types/generated/relay/useBlockOutDeliveryTimesInsertBlockOutMutation.graphql";
import { useBlockOutDeliveryTimes_toggleBlockOutSwitch_Mutation } from "src/common/types/generated/relay/useBlockOutDeliveryTimes_toggleBlockOutSwitch_Mutation.graphql";
import { TimeBlockType } from "./type";
import GetFullID from "src/common/functions/GetFullId";

const updateProjectBlockOutMutation = graphql`
  mutation useBlockOutDeliveryTimes_toggleBlockOutSwitch_Mutation(
    $projectId: uuid!
    $value: Boolean!
  ) {
    update_project(
      where: { id: { _eq: $projectId } }
      _set: { is_sitedelivery_block_out_active: $value }
    ) {
      returning {
        id
        is_sitedelivery_block_out_active
      }
    }
  }
`;

const insertBlockOutMutation = graphql`
  mutation useBlockOutDeliveryTimesInsertBlockOutMutation(
    $object: project_delivery_block_out_insert_input!
  ) {
    insert_project_delivery_block_out_one(object: $object) {
      id
      start_time
      end_time
      weekday
    }
  }
`;

const deleteBlockOutMutation = graphql`
  mutation useBlockOutDeliveryTimesDeleteBlockOutMutation($id: uuid!) {
    delete_project_delivery_block_out_by_pk(id: $id) {
      id
    }
  }
`;

const useBlockOutDevlieryTimes: (projectId: string) => {
  timesTable: TimeBlockType[][];
  // updateTimesTable: (newTimesTable: TimeType[][]) => void;
  addBlockOutDelivery: (
    start: dayjs.Dayjs,
    end: dayjs.Dayjs,
    dayNumber: number,
  ) => void;

  removeBlockOutDelivery: (blockOutId: string) => void;
  toggleBlockOutSwitch: (value: boolean) => void;
  loading?: boolean;
} = (projectId) => {
  const [loading, setLoading] = useState(false);
  const {
    data,
    loading: queryLoading,
    refetch,
  } = useGetProjectDeliveryBlockOutQuery({
    variables: {
      projectId: projectId,
    },
  });

  const [updateProjectBlockOut] =
    useAsyncMutation<useBlockOutDeliveryTimes_toggleBlockOutSwitch_Mutation>(
      updateProjectBlockOutMutation,
    );

  const [insertBlockOut] =
    useAsyncMutation<useBlockOutDeliveryTimesInsertBlockOutMutation>(
      insertBlockOutMutation,
    );

  const [deleteBlockOut] =
    useAsyncMutation<useBlockOutDeliveryTimesDeleteBlockOutMutation>(
      deleteBlockOutMutation,
    );

  const timesTable: TimeBlockType[][] = [
    [], // sunday
    [], // monday
    [], // tuesday
    [], // wednesday
    [], // thursday
    [], // friday
    [], // saturday
  ];

  data?.project_delivery_block_out.forEach((bo) => {
    timesTable[bo.weekday].push({
      time: {
        start: dayjs("1990-01-01 " + bo.start_time),
        end: dayjs("1990-01-01 " + bo.end_time),
      },
      id: bo.id,
    });
  });

  const addBlockOutDelivery = async (
    start: dayjs.Dayjs,
    end: dayjs.Dayjs,
    dayNumber: number,
  ) => {
    if (start >= end) return;
    setLoading(true);
    await insertBlockOut({
      variables: {
        object: {
          project_id: projectId,
          start_time: dayjs(start).format("HH:mm"),
          weekday: dayNumber,
          end_time: dayjs(end).format("HH:mm"),
        },
      },
    }).then(async (d) => await refetch());
    setLoading(false);
  };

  const removeBlockOutDelivery = async (blockOutId: string) => {
    setLoading(true);
    await deleteBlockOut({
      variables: {
        id: blockOutId,
      },
    }).then(async (d) => await refetch());
    setLoading(false);
  };

  const toggleBlockOutSwitch = async (value: boolean) => {
    setLoading(true);
    await updateProjectBlockOut({
      variables: {
        projectId,
        value,
      },
      optimisticResponse: {
        update_project: {
          returning: [
            {
              id: GetFullID("project", projectId),
              is_sitedelivery_block_out_active: value,
            },
          ],
        },
      },
    });
    setLoading(false);
  };

  return {
    timesTable: timesTable,
    addBlockOutDelivery,
    removeBlockOutDelivery,
    toggleBlockOutSwitch,
    loading: loading || queryLoading,
  };
};

export default useBlockOutDevlieryTimes;
