import { EditOutlined } from "@ant-design/icons";
import { IconArrowBackUp } from "@tabler/icons";
import { Button, Checkbox, Form, Select, Space, Tabs } from "antd";
import { FC, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import withEmployeeData, {
  EmployeeProps,
} from "src/common/components/EmployeeDataHOC";
import CustomButton from "src/common/components/general/Button";
import ErrorMessage from "src/utility-features/error-handling/components/ErrorMessage";
import LoadingContent from "src/common/components/general/LoadingContent";
import StyledContent from "src/common/components/layouts/StyledContent";
import {
  GetGcPermitChecklistItemsQueryVariables,
  useGetGcPermitChecklistItemsQuery,
  useUpdateGeneralContractorForPermitMutation,
  useUpdatePermitProjectByPkMutation,
  useTransferGcPermitSettingsToProjMutation,
  useUpdateProjectSettingByPkForPermitMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import WorkAboveSettings from "./components/WorkAboveSettings";
import HotWorkSettings from "./components/HotWorkSettings";
import DigSettings from "./components/DigSettings";
import ConfinedSpaceSettings from "./components/ConfinedSpaceSettings";
import WorkingLadderSettings from "./components/WorkingLadderSettings";
import AccessLadderSettings from "./components/AccessLadderSettings";
import HistoricalAccessSettings from "./components/HistoricalAccessSettings";
import InteriorPenetrationSettings from "./components/InteriorPenetrationSettings";
import DigExcavationSettings from "./components/DigExcavationSettings";
import LotoSettings from "./components/LotoSettings";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import SteelErectionSettings from "./components/SteelErectionSettings";
import OutOfBasketSettings from "./components/OutOfBasketSettings";
import { TabsProps } from "antd/lib";

interface GCReportsPermitsSettingsProps {}
// TODO add new item in table while editing
const GCReportsPermitsSettings: FC<
  GCReportsPermitsSettingsProps & EmployeeProps & { corporateLevel?: boolean }
> = ({ userId, employeeData, corporateLevel }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { userData } = useUserData();
  const isCorporateAdmin = !!userData.employee?.is_corporate_admin;
  if (!projectId && !(corporateLevel && isCorporateAdmin)) {
    throw new Error(
      "ProjectID should be present or you do not have access to this page",
    );
  }

  const employee = employeeData.user_by_pk?.employee;

  if (!employee) throw new Error("user is not an employee");

  const gcId = employee.general_contractor.id;

  const queryVariables: GetGcPermitChecklistItemsQueryVariables = useMemo(
    () => ({
      gcId,
      gcWhere: projectId
        ? { gc_projects: { project_id: { _eq: projectId } } }
        : { id: { _eq: gcId } },
      projWhere: projectId
        ? { id: { _eq: projectId } }
        : { id: { _is_null: true } },
    }),
    [gcId, projectId],
  );
  // alert(`query variables = ${gcId} & ${projectId}`);
  const [editable, setEditable] = useState<boolean>(false);
  const gcPermitCheckListItems = useGetGcPermitChecklistItemsQuery({
    variables: queryVariables,
  });

  // const [editing, setEditing] = useState(false);

  const [emergencyContactForm] = Form.useForm();
  const [updateProject, { loading: updatingProject }] =
    useUpdatePermitProjectByPkMutation();
  const [updateProjectSetting, { loading: updatingProjectSettings }] =
    useUpdateProjectSettingByPkForPermitMutation();

  const [updateGc] = useUpdateGeneralContractorForPermitMutation();
  const [transferGcSettingToProj, { loading: transferring }] =
    useTransferGcPermitSettingsToProjMutation();
  if (gcPermitCheckListItems.loading) {
    return <LoadingContent />;
  }

  const data = gcPermitCheckListItems.data;
  if (!data) {
    throw (
      gcPermitCheckListItems.error ||
      new Error("No Data Found for GetGCPermitChecklistItems")
    );
  }
  const project = data.project[0];
  if (projectId && !project)
    return (
      <div className={`w-full flex items-center justify-center`}>
        <ErrorMessage message="project not found for given projectId" />
      </div>
    );
  const settingUseLevel = project?.project_setting?.permit_setting_level;
  const selectedGcId = corporateLevel
    ? gcId
    : project?.permit_checklist_gc_id ?? gcId;
  const checklistGc =
    data.general_contractor.find((g) => g.id === selectedGcId) ??
    data.general_contractor[0];

  const mainGc = projectId
    ? data.general_contractor.find(
        (gc) => gc.id === project.general_contractor_id,
      )
    : data.general_contractor.find((gc) => gc.id === gcId);

  if (!checklistGc || !mainGc) {
    throw new Error("There is no record in general_contractor table.");
  }

  const updateProjectValue = (field_set: {
    permit_checklist_gc_id?: string;
    pemit_contact_project_employee_id?: string;
  }) => {
    if (project) {
      const op = updateProject({
        variables: { _set: field_set, id: project.id },
        optimisticResponse: {
          update_project_by_pk: { ...project, ...field_set },
        },
      });

      return op;
    } else {
      throw new Error("Project Not found to update project permit settings");
    }
  };

  const contactEmployee = project
    ? project.project_employees.find(
        (u) => u.employee.uid === project.pemit_contact_project_employee_id,
      )
    : undefined;
  const projSettings = project?.project_setting;
  const settingsToUse =
    settingUseLevel === "project" && projSettings ? projSettings : mainGc;
  const allowPermitLocationChange =
    settingsToUse.allow_permit_activation_location_change;
  const settingComponentProps = {
    gcPermitCheckListItems,
    queryVariables,
    project,
    mainGc,
    data,
    checklistGc,
  };
  const transferGcSettingToProject = async () => {
    if (!projSettings) return;
    await transferGcSettingToProj({
      variables: {
        project_id: projSettings.project_id,

        projSettingSet: {
          permit_setting_level: "project",
          allow_permit_activation_location_change:
            mainGc.allow_permit_activation_location_change,
          only_submitter_sign_the_confined_space_permit:
            mainGc.only_submitter_sign_the_confined_space_permit,
          only_submitter_sign_the_dig_excavation_permit:
            mainGc.only_submitter_sign_the_dig_excavation_permit,
          only_submitter_sign_the_dig_permit:
            mainGc.only_submitter_sign_the_dig_permit,
          only_submitter_sign_the_historical_access_permit:
            mainGc.only_submitter_sign_the_historical_access_permit,
          only_submitter_sign_the_hot_work_permit:
            mainGc.only_submitter_sign_the_hot_work_permit,
          only_submitter_sign_the_interior_penetration_permit:
            mainGc.only_submitter_sign_the_interior_penetration_permit,
          only_submitter_sign_the_loto_permit:
            mainGc.only_submitter_sign_the_loto_permit,
          only_submitter_sign_the_work_above_permit:
            mainGc.only_submitter_sign_the_work_above_permit,
          only_submitter_sign_the_steel_erection_permit:
            mainGc.only_submitter_sign_the_steel_erection_permit,
          only_submitter_sign_the_out_of_basket_permit:
            mainGc.only_submitter_sign_the_out_of_basket_permit,
          require_workers_to_sign_out_of_basket_permit:
            mainGc.require_workers_to_sign_out_of_basket_permit,
        },
        checklistItems: checklistGc.permit_checklist_items.map((item, i) => {
          return {
            description: { data: { original: item.description.original } },
            yes_enabled: item.yes_enabled,
            no_enabled: item.no_enabled,
            na_enabled: item.na_enabled,
            text_enabled: item.text_enabled,
            text_required: item.text_required,
            sort_index: (i + 1) * 10000,
            item_type: item.item_type,
            permit_type: item.permit_type,
            project_id: projSettings.project_id,
          };
        }),
      },
    });

    gcPermitCheckListItems.refetch();
  };

  const tabItems: TabsProps["items"] = [
    {
      key: "hotWorkPermitPane",
      label: `${
        mainGc.hot_work_permit_name === "burn" ? "Burn" : "Hot Work"
      } Permits`,
      children: <HotWorkSettings {...settingComponentProps} />,
    },
    {
      key: "workAbovePermitPane",
      label: "Work Above Permits",
      children: <WorkAboveSettings {...settingComponentProps} />,
    },
    {
      key: "digPermitPane",
      label: "Dig Permits",
      children: <DigSettings {...settingComponentProps} />,
    },
    {
      key: "confinedSpacePermitPane",
      label: "Confined Space Permits",
      children: <ConfinedSpaceSettings {...settingComponentProps} />,
    },
    {
      key: "workerLadderPermitPane",
      label: "Working Ladder Permits",
      children: <WorkingLadderSettings {...settingComponentProps} />,
    },
    {
      key: "accessLadderPermitPane",
      label: "Access Ladder Permits",
      children: <AccessLadderSettings {...settingComponentProps} />,
    },
    {
      key: "HistoricalAccessPermitPane",
      label: "Historical Access Permits",
      children: <HistoricalAccessSettings {...settingComponentProps} />,
    },
    {
      key: "InteriorPenetrationPermitPane",
      label: "Interior/Concrete Penetration Permit",
      children: <InteriorPenetrationSettings {...settingComponentProps} />,
    },
    {
      key: "DigExcavationPermitPane",
      label: "Dig Excavation Penetration Permit",
      children: <DigExcavationSettings {...settingComponentProps} />,
    },
    {
      key: "LotoPermitPane",
      label: "LOTO Permit",
      children: <LotoSettings {...settingComponentProps} />,
    },
    {
      key: "SteelErectionPane",
      label: "Steel Erection Permit",
      children: <SteelErectionSettings {...settingComponentProps} />,
    },
    {
      key: "OutOfBasketPane",
      label: "Out of Basket Permit",
      children: <OutOfBasketSettings {...settingComponentProps} />,
    },
  ];
  return (
    <div className="w-full h-full overflow-y-auto">
      <div className="flex flex-col w-full gap-1">
        <div className="flex flex-row ">
          {!!project && (
            <StyledContent>
              <span style={{ display: "flex", alignItems: "baseline" }}>
                <h2>Emergency Point of Contact</h2>
                <EditOutlined
                  onClick={() => setEditable(true)}
                  style={{
                    paddingLeft: "8px",
                    color: "#1890ff",
                    cursor: "pointer",
                  }}
                />
              </span>
              {!!contactEmployee && (
                <>
                  <p>{contactEmployee.employee.user.name}</p>
                  {!!contactEmployee.employee.employee_title && (
                    <p>{contactEmployee.employee.employee_title.name.en}</p>
                  )}
                  {!!contactEmployee.employee.user.phone_number && (
                    <p>{contactEmployee.employee.user.phone_number}</p>
                  )}
                </>
              )}
              {(!project.pemit_contact_project_employee_id || editable) && (
                <>
                  <p>Setup a point of contact in case there is an emergency</p>
                  <Form
                    form={emergencyContactForm}
                    layout="vertical"
                    initialValues={{ modifier: "public" }}
                    onSubmitCapture={async (e) => {
                      e.preventDefault();
                      emergencyContactForm.validateFields();

                      const projectEmployeeId =
                        emergencyContactForm.getFieldValue("projectEmployeeId");

                      if (!projectEmployeeId) return;

                      await updateProjectValue({
                        pemit_contact_project_employee_id: projectEmployeeId,
                      });
                      setEditable(false);
                    }}
                  >
                    <Form.Item
                      name="projectEmployeeId"
                      label="Emergency Point of Contact"
                      rules={[{ required: true, message: `Choose employee` }]}
                    >
                      <Select style={{ width: 400 }}>
                        {project.project_employees.map((t) => (
                          <Select.Option key={t.id} value={t.id}>
                            {t.employee?.user.name}
                            {t.employee.employee_title?.name
                              ? `, ${t.employee.employee_title?.name.en}`
                              : ""}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Space>
                      <Button type="primary" htmlType="submit">
                        Done
                      </Button>
                      <Button onClick={() => setEditable(false)}>Cancel</Button>
                    </Space>
                  </Form>
                </>
              )}
            </StyledContent>
          )}

          {projectId && (
            <div>
              <CustomButton
                label={"Back to reports"}
                icon={IconArrowBackUp}
                onClick={() => {
                  navigate(`/gce/projects/${projectId}/reports/permits`);
                }}
                secondary
              />
            </div>
          )}
        </div>
        {projSettings && (
          <div>
            <Checkbox
              disabled={
                settingUseLevel === "project" ||
                (!settingUseLevel && (transferring || updatingProjectSettings))
              }
              checked={settingUseLevel === "project"}
              onChange={async (e) => {
                if (settingUseLevel) {
                  const toSet = { permit_setting_level: "project" };
                  await updateProjectSetting({
                    variables: {
                      project_id: projSettings.project_id,
                      _set: toSet,
                    },
                    optimisticResponse: {
                      update_project_setting_by_pk: {
                        ...projSettings,
                        ...toSet,
                      },
                    },
                  });
                } else {
                  transferGcSettingToProject();
                }
              }}
            >
              Check this box if you want to use Different settings and Checklist
              for permits from GC. <br />
              (NOTE: this will be applied to all permits checklist)
            </Checkbox>
            {!!settingUseLevel && (
              <Checkbox
                disabled={settingUseLevel === "gc"}
                checked={settingUseLevel === "gc"}
                onChange={(e) => {
                  const toSet = { permit_setting_level: "gc" };

                  updateProjectSetting({
                    variables: {
                      project_id: projSettings.project_id,
                      _set: toSet,
                    },
                    optimisticResponse: {
                      update_project_setting_by_pk: {
                        ...projSettings,
                        ...toSet,
                      },
                    },
                  });
                }}
              >
                Check this box if you want to switch back to gc settings and
                checklist <br />( NOTE: if you want to switch back again to
                project setting you can do that to)
              </Checkbox>
            )}
          </div>
        )}
        <StyledContent>
          <h2>Permit Settings</h2>
          {allowPermitLocationChange ? (
            <p>
              If there are no changes in conditions, permits can be resubmitted
              for approval with a change in date and location of work.
            </p>
          ) : (
            <p>
              If there are no changes in conditions, permits can be resubmitted
              for approval with a change in date of work.
            </p>
          )}
          <br />
          <Checkbox
            checked={!!allowPermitLocationChange}
            disabled={settingUseLevel !== "project" && !!projSettings}
            onChange={(e) => {
              const checked = e.target.checked;
              const toSet = {
                allow_permit_activation_location_change: !!checked,
              };
              if (projSettings && settingUseLevel === "project") {
                updateProjectSetting({
                  variables: {
                    project_id: projSettings.project_id,
                    _set: toSet,
                  },
                  optimisticResponse: {
                    update_project_setting_by_pk: {
                      ...projSettings,
                      ...toSet,
                    },
                  },
                });
                console.log({ ...projSettings, ...toSet });
              } else {
                updateGc({
                  variables: { gcId: mainGc.id, _set: toSet },
                  optimisticResponse: {
                    update_general_contractor_by_pk: {
                      ...mainGc,
                      ...toSet,
                    },
                  },
                });
              }
            }}
          >
            Allow workers to resubmit permits with a change in location of work.
          </Checkbox>
        </StyledContent>
        {data.general_contractor.length > 1 && (
          <StyledContent>
            <h2>Select checklist GC:</h2>
            <Select
              style={{ width: 400, marginTop: "20px" }}
              value={project.permit_checklist_gc_id ?? gcId}
              loading={updatingProject}
              onChange={(value) => {
                updateProjectValue({ permit_checklist_gc_id: value });
              }}
            >
              {data.general_contractor.map((gc) => (
                <Select.Option key={gc.id} value={gc.id}>
                  {gc.name}
                </Select.Option>
              ))}
            </Select>
          </StyledContent>
        )}
        <Tabs
          defaultActiveKey="hotWorkPermitPane"
          animated={false}
          items={tabItems}
        ></Tabs>
      </div>
    </div>
  );
};

export default withEmployeeData(GCReportsPermitsSettings);
