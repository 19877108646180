/**
 * @generated SignedSource<<f7d21a426a0aa84159da8c11af9605d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useUpdateHHMutation$variables = {
  hardHat: string;
  projectId: string;
  userId: string;
};
export type useUpdateHHMutation$data = {
  readonly update_project_by_pk: {
    readonly id: string;
    readonly next_hard_hat_number: number | null | undefined;
  } | null | undefined;
  readonly update_project_employee: {
    readonly returning: ReadonlyArray<{
      readonly hard_hat_number: string | null | undefined;
      readonly id: string;
    }>;
  } | null | undefined;
  readonly update_project_worker: {
    readonly returning: ReadonlyArray<{
      readonly hard_hat_number: string | null | undefined;
      readonly id: string;
    }>;
  } | null | undefined;
};
export type useUpdateHHMutation = {
  response: useUpdateHHMutation$data;
  variables: useUpdateHHMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hardHat"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "_inc",
      "value": {
        "next_hard_hat_number": 1
      }
    },
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "projectId"
        }
      ],
      "kind": "ObjectValue",
      "name": "pk_columns"
    }
  ],
  "concreteType": "project",
  "kind": "LinkedField",
  "name": "update_project_by_pk",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "next_hard_hat_number",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "hard_hat_number",
      "variableName": "hardHat"
    }
  ],
  "kind": "ObjectValue",
  "name": "_set"
},
v6 = {
  "fields": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "_eq",
          "variableName": "projectId"
        }
      ],
      "kind": "ObjectValue",
      "name": "orientation_project_id"
    }
  ],
  "kind": "ObjectValue",
  "name": "project"
},
v7 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "userId"
  }
],
v8 = [
  (v5/*: any*/),
  {
    "fields": [
      (v6/*: any*/),
      {
        "fields": (v7/*: any*/),
        "kind": "ObjectValue",
        "name": "worker_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hard_hat_number",
  "storageKey": null
},
v10 = [
  {
    "alias": "id",
    "args": null,
    "kind": "ScalarField",
    "name": "__id_pk",
    "storageKey": null
  },
  (v9/*: any*/)
],
v11 = [
  (v5/*: any*/),
  {
    "fields": [
      {
        "fields": (v7/*: any*/),
        "kind": "ObjectValue",
        "name": "employee_id"
      },
      (v6/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v12 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "filters": null,
    "handle": "pk",
    "key": "",
    "kind": "ScalarHandle",
    "name": "id"
  },
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateHHMutation",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "project_worker_mutation_response",
        "kind": "LinkedField",
        "name": "update_project_worker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_worker",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": (v10/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "project_employee_mutation_response",
        "kind": "LinkedField",
        "name": "update_project_employee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_employee",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": (v10/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useUpdateHHMutation",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "project_worker_mutation_response",
        "kind": "LinkedField",
        "name": "update_project_worker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_worker",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": (v12/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "project_employee_mutation_response",
        "kind": "LinkedField",
        "name": "update_project_employee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_employee",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": (v12/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "13cf309e602aebf72449dd340fb6c239",
    "id": null,
    "metadata": {},
    "name": "useUpdateHHMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateHHMutation(\n  $projectId: uuid!\n  $userId: uuid!\n  $hardHat: String!\n) {\n  update_project_by_pk(pk_columns: {id: $projectId}, _inc: {next_hard_hat_number: 1}) {\n    id\n    next_hard_hat_number\n  }\n  update_project_worker(where: {project: {orientation_project_id: {_eq: $projectId}}, worker_id: {_eq: $userId}}, _set: {hard_hat_number: $hardHat}) {\n    returning {\n      id\n      hard_hat_number\n    }\n  }\n  update_project_employee(where: {project: {orientation_project_id: {_eq: $projectId}}, employee_id: {_eq: $userId}}, _set: {hard_hat_number: $hardHat}) {\n    returning {\n      id\n      hard_hat_number\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "414fad465d591f56f1c9269a37e7a6ce";

export default node;
