import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { graphql } from "babel-plugin-relay/macro";
import { message, notification } from "antd";
import {
  useFlagOrUnflagTaskMutation,
  useFlagOrUnflagTaskMutation$data,
} from "src/common/types/generated/relay/useFlagOrUnflagTaskMutation.graphql";
import dayjs from "dayjs";
import { auth } from "src/common/functions/firebase";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";

const useFlagOrUnFlagJHA = () => {
  const [updateJha] = useAsyncMutation<useFlagOrUnflagTaskMutation>(graphql`
    mutation useFlagOrUnflagTaskMutation(
      $taskId: uuid!
      $_set: task_set_input!
    ) {
      update_task_by_pk(pk_columns: { id: $taskId }, _set: $_set) {
        id
        flagged_at
        flagged_by_uid
        flagged_by_user {
          name
        }
      }
    }
  `);

  const flagOrUnflagJha = async (taskId: string, type: "flag" | "unflag") => {
    try {
      await updateJha({
        variables: {
          taskId: taskId,
          _set: {
            flagged_at: type === "flag" ? dayjs().format() : null,
            flagged_by_uid: type === "flag" ? auth.currentUser?.uid : null,
          },
        },

        updater: (
          store: RecordSourceSelectorProxy<useFlagOrUnflagTaskMutation$data>,
        ) => {
          const updatedTaskRecord = store.getRootField("update_task_by_pk");
          const flaggedAt = updatedTaskRecord.getValue("flagged_at");
          const flaggedByUid = updatedTaskRecord.getValue("flagged_by_uid");
          const flaggedByUser =
            updatedTaskRecord.getLinkedRecord("flagged_by_user");
          const taskConn = ConnectionHandler.getConnection(
            store.getRoot(),
            "ViewAndEditJha_task_connection",
          );

          if (taskConn) {
            const edges = taskConn.getLinkedRecords("edges");
            if (!edges) return;
            edges.forEach((edge) => {
              const node = edge.getLinkedRecord("node");
              if (!node) return;
              node.setValue(flaggedAt, "flagged_at");
              node.setValue(flaggedByUid, "flagged_by_uid");
              if (flaggedByUser) {
                node.setLinkedRecord(flaggedByUser, "flagged_by_user");
              }
            });
          }
        },
      });
      message.success(
        type === "flag"
          ? "JHA has been FLAGGED FOR ACTION"
          : "JHA has been UNFLAGGED",
      );
    } catch (err) {
      notification.error({
        message:
          type === "flag"
            ? "Could not FLAG the JHA " + String(err)
            : "Could not UNFLAG the JHA " + String(err),
      });
    }
  };
  return [flagOrUnflagJha] as const;
};

export default useFlagOrUnFlagJHA;
