import Chart from "chart.js/auto";
import dayjs from "dayjs";
import React, { FC, useEffect } from "react";
import { colorList } from "src/common/components/lists/chartColorList";
import { GCProjectReportsDailyBodyQuery$data } from "src/common/types/generated/relay/GCProjectReportsDailyBodyQuery.graphql";

export interface GCProjectReportsDailyMonthDownloadChartProps {
  data: GCProjectReportsDailyBodyQuery$data;
  pickedMonth: dayjs.Dayjs;
  setWorkerImageUrl: React.Dispatch<React.SetStateAction<string>>;
  setManhourImageUrl: React.Dispatch<React.SetStateAction<string>>;
  selectedSubId?: string;
  setPreparingChart: (val: boolean) => void;
}

const GCProjectReportsDailyMonthDownloadChart: FC<
  GCProjectReportsDailyMonthDownloadChartProps
> = ({
  pickedMonth,
  data,
  setManhourImageUrl,
  setWorkerImageUrl,
  selectedSubId,
  setPreparingChart,
}) => {
  const getDaysArray = (start: Date, end: Date) => {
    const arr = [];
    for (
      const dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(dayjs(new Date(dt)).format("YYYY-MM-DD"));
    }
    return arr;
  };
  const colorListLength = colorList.length;

  const randColor = () => {
    return (
      "#" +
      Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, "0")
        .toUpperCase()
    );
  };
  let workerChart: any;
  let manhourChart: any;

  useEffect(() => {
    setPreparingChart(true);
    let startAt = pickedMonth.startOf("month").format("YYYY-MM-DD");
    let endAt = pickedMonth.endOf("month").format("YYYY-MM-DD");

    let x = 0;
    const subsOrGcsMap = new Map<
      string,
      {
        worker: number | undefined;
        hours: number | undefined;
        name: string;
      }
    >();

    const subsOrGcs: Array<string> = [];
    const workerGraphMapping = new Map<string, Map<string, number>>();
    const manhourGraphMapping = new Map<string, Map<string, number>>();

    data.daily_work_log_connection.edges.forEach((d) => {
      if (
        (!!d.node.submitted_at || !!d.node.created_by_project_user.employee) &&
        (!selectedSubId || selectedSubId === d.node.subcontractor_id)
      ) {
        let x = 0;
        d.node.daily_work_log_workers.forEach((w) => {
          x += w.hours;
        });
        const totalHoursByDate =
          d.node.worker_hours && d.node.worker_count
            ? d.node.worker_hours * d.node.worker_count
            : x;
        const totalWorkersByDate = d.node.worker_count
          ? (d.node.worker_count as number)
          : d.node.daily_work_log_workers.length;

        const subOrGcId =
          d.node.subcontractor_id ?? d.node.general_contractor?.id ?? "";
        if (!subsOrGcsMap.has(subOrGcId)) subsOrGcs.push(subOrGcId);

        workerGraphMapping.set(
          subOrGcId,
          workerGraphMapping.has(subOrGcId)
            ? workerGraphMapping
                .get(subOrGcId)
                ?.set(
                  d.node.date,
                  (workerGraphMapping.get(subOrGcId)?.get(d.node.date) ?? 0) +
                    totalWorkersByDate,
                ) ??
                new Map<string, number>().set(d.node.date, totalWorkersByDate)
            : new Map<string, number>().set(d.node.date, totalWorkersByDate),
        );
        manhourGraphMapping.set(
          subOrGcId,
          manhourGraphMapping.has(subOrGcId)
            ? manhourGraphMapping
                .get(subOrGcId)
                ?.set(
                  d.node.date,
                  (manhourGraphMapping.get(subOrGcId)?.get(d.node.date) ?? 0) +
                    totalHoursByDate,
                ) ??
                new Map<string, number>().set(d.node.date, totalHoursByDate)
            : new Map<string, number>().set(d.node.date, totalHoursByDate),
        );

        subsOrGcsMap.set(subOrGcId, {
          name:
            d.node.subcontractor?.name ?? d.node.general_contractor?.name ?? "",
          worker:
            ((subsOrGcsMap.get(subOrGcId)?.worker
              ? subsOrGcsMap.get(subOrGcId)?.worker
              : 0) ?? 0) + totalWorkersByDate,
          hours:
            ((subsOrGcsMap.get(subOrGcId)?.hours
              ? subsOrGcsMap.get(subOrGcId)?.hours
              : 0) ?? 0) + totalHoursByDate,
        });
      }
    });

    const xValues = getDaysArray(new Date(startAt), new Date(endAt));

    workerChart = new Chart("workerChart", {
      type: "line",
      data: {
        labels: xValues,
        datasets: subsOrGcs.map((s) => {
          // console.log("worker:", colorList[x]);
          const obj = {
            label: subsOrGcsMap.get(s)?.name ?? "",
            data: xValues.map((date) => {
              return workerGraphMapping.get(s)?.get(date) ?? 0;
            }),
            borderColor: x < colorListLength ? colorList[x++] : randColor(),
            fill: false,
          };
          return obj;
        }),
      },
      options: {
        devicePixelRatio: 1.5,
        plugins: {
          legend: {
            display: true,
            labels: {
              boxWidth: 8,
              boxHeight: 8,
              sort: (a, b) => a.text.localeCompare(b.text),
            },
          },
          title: {
            display: true,
            text: "Number of Workers Each Day",
          },
        },
        animation: {
          onComplete: () => {
            // console.log("workercompleted");
            // console.log(workerChart?.toBase64Image());
            setWorkerImageUrl(workerChart?.toBase64Image());
          },
        },
      },
    });
    let y = 0;

    manhourChart = new Chart("manhourChart", {
      type: "line",
      data: {
        labels: xValues,
        datasets: subsOrGcs.map((s) => {
          // console.log("manhour:", colorList[y]);
          const obj = {
            label: subsOrGcsMap.get(s)?.name ?? "",
            data: xValues.map((date) => {
              return manhourGraphMapping.get(s)?.get(date) ?? 0;
            }),
            borderColor: y < colorListLength ? colorList[y++] : randColor(),
            fill: false,
          };
          return obj;
        }),
      },
      options: {
        responsive: true,
        devicePixelRatio: 1.5,
        plugins: {
          legend: {
            display: true,
            labels: {
              boxWidth: 8,
              boxHeight: 8,
              sort: (a, b) => a.text.localeCompare(b.text),
            },
          },
          title: {
            display: true,
            text: "Manhours by Company",
          },
        },
        animation: {
          onComplete: () => {
            // console.log("manhourcompleted");
            // console.log(manhourChart?.toBase64Image());
            setManhourImageUrl(manhourChart?.toBase64Image());
          },
        },
      },
    });
    if (manhourChart?.toBase64Image())
      setManhourImageUrl(manhourChart?.toBase64Image());
    if (workerChart?.toBase64Image())
      setWorkerImageUrl(workerChart?.toBase64Image());
    setPreparingChart(false);

    return () => {
      workerChart.destroy();
      manhourChart.destroy();
    };
  }, [pickedMonth, selectedSubId]);
  return (
    <div className="flex flex-col w-full gap-1">
      <canvas id="manhourChart" className="w-full"></canvas>
      <canvas id="workerChart" className="w-full"></canvas>
    </div>
  );
};
export default GCProjectReportsDailyMonthDownloadChart;
