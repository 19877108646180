import React from "react";
import { Space } from "antd";
import { SpaceProps } from "antd/lib/space";
import { BasicProps } from "antd/lib/layout/layout";
import BContent from "./BContent";

interface Props extends SpaceProps {
  hz?: boolean;
  lg?: boolean;
  wrapContent?: boolean;
  contentProps?: BasicProps;
  children?: Array<any> | any;
}

const BSpace = ({
  hz,
  wrapContent,
  children,
  contentProps,
  ...props
}: Props) => {
  let items: Array<any> = [];

  if (Array.isArray(children)) {
    items = [...children];
  } else {
    items = [children];
  }

  if (wrapContent) {
    items = items.map((child: any, i: number) =>
      typeof child === "object" ? (
        <BContent key={i} {...contentProps}>
          {child}
        </BContent>
      ) : undefined
    );
  }

  return (
    <Space
      size="middle"
      direction={hz ? "horizontal" : "vertical"}
      {...props}
      style={{
        width: "100%",
        ...props.style,
      }}
    >
      {items}
    </Space>
  );
};

export default BSpace;
