import { IconCircleX } from "@tabler/icons";
import React from "react";
import Icon from "../../../common/components/general/Icon";

const texts = {
  defaultErrorMessage: "An Error occurred",
};

interface ErrorMessageProps {
  message?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
  return (
    <div className="select-none px-1 py-0.75 rounded-1.5 flex flex-row items-center justify-center bg-suplementary-1 gap-0.75">
      <Icon color={`static`} icon={IconCircleX} />
      <p className="tetx-1.5 font-accent">
        {props.message ?? texts.defaultErrorMessage}
      </p>
    </div>
  );
};

export default ErrorMessage;
