import React from "react";
import { OrientationType } from "src/common/types/manual/OrientationType";
import ErrorMessage from "src/utility-features/error-handling/components/ErrorMessage";
import UniversalOrientationViewer from "../viewer/UniversalOrientationViewer";
import { SiteOrientationStepProps } from "../../../../../utils/siteOrientationTypes";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import useSiteOrientationUniversal from "../../../newDesign/utils/siteOrientationUniversalHook";

import { useCurrentLanguage } from "../../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteOrientationInPersonWorkerStepsOrientationViewerProps
  extends SiteOrientationStepProps {
  type: OrientationType;
}

const SiteOrientationUniversalStepsViewer: React.FC<
  SiteOrientationInPersonWorkerStepsOrientationViewerProps
> = (props) => {
  const workerContext = useSiteOrientationUniversal();
  const language = useCurrentLanguage();

  return (
    <SiteFeatureStepLayout onBackButtonClick={props.onBack}>
      {workerContext.orientee?.id ? (
        <UniversalOrientationViewer
          handleNav={(s) => props.onNext()}
          userId={workerContext.orientee.id}
          languageCode={language}
        />
      ) : (
        <ErrorMessage message="Please, register first" />
      )}
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationUniversalStepsViewer;
