import { IconX } from "@tabler/icons";
import React, { FC } from "react";
import Pill from "src/common/components/general/Pill";
import { StateFunctions } from "./CustomFilterController";
import dayjs from "dayjs";

type CustomFilterTopSectionProps = {
  dataIndexFilters?: {
    [key: string]: { val: string | boolean | string[]; onCancel: () => void };
  };
  removalFilters: {
    [key: string]: (
      list?: Array<{ name: string; id: string }>,
      listItemToRemove?: string,
    ) => void;
  };
} & StateFunctions;
const CustomFilterTopSection: FC<CustomFilterTopSectionProps> = ({
  dataIndexFilters,
  removalFilters,
  setAppliedFilter,
  setRemovalFilters,
  appliedFiltersfilterMap,
}) => {
  return (
    <>
      {dataIndexFilters &&
        Object.entries({ ...dataIndexFilters }).map(
          ([key, { val, onCancel }], id) => {
            const array = key.split("%");
            if (val !== false) {
              if (key === "search") {
                return (
                  <div>
                    <Pill
                      onClick={onCancel}
                      key={id}
                      // label={`${val[0]}: ${val[1]}`}
                      label={Array.isArray(val) && val[1] ? `${val[1]}` : ""}
                      icon={IconX}
                      darker
                    />
                  </div>
                );
              }
              if (array.length === 1) {
                // when radio
                return (
                  <div key={id}>
                    <Pill
                      onClick={onCancel}
                      key={id}
                      // label={`${key}: ${typeof val === "string" ? val : ""}`}
                      label={`${typeof val === "string" ? val : ""}`}
                      icon={IconX}
                      darker
                    />
                  </div>
                );
              }
              if (array.length === 3) {
                return (
                  <div key={id}>
                    <Pill
                      onClick={onCancel}
                      key={id}
                      // label={`${array[0]}: ${array[1]}`}
                      label={`${array[1]}`}
                      icon={IconX}
                      darker
                    />
                  </div>
                );
              }
            }
          },
        )}
      {Object.entries({ ...appliedFiltersfilterMap }).map(([key, val], id) => {
        const array = key.split("%");
        if (val !== false) {
          if (array.length === 1) {
            // when radio
            return (
              <div key={id}>
                <Pill
                  onClick={removalFilters[key]}
                  key={id}
                  // label={`${key}: ${typeof val === "string" ? val : ""}`}
                  label={`${typeof val === "string" ? val : ""}`}
                  icon={IconX}
                  darker
                />
              </div>
            );
          }
          if (array.length === 2 && array[1] === "select") {
            const options: { name: string; id: string }[] = [];
            if (Array.isArray(val))
              val.forEach((element) => {
                if (element && !dayjs.isDayjs(element)) {
                  options.push(element);
                }
              });
            return (
              <div key={id}>
                {options.length > 0 && (
                  <div>
                    {array[0]}:
                    <br />{" "}
                    {options.map((val, i: number) => (
                      <div key={val.id}>
                        <Pill
                          label={val.name}
                          key={i + val.id}
                          icon={IconX}
                          darker
                          onClick={() => {
                            removalFilters[key]?.(
                              options.filter((p) => p.id !== val.id),
                            );
                            setAppliedFilter((prev) => {
                              const opts: { name: string; id: string }[] = [];
                              const prevArr = prev[key];
                              if (Array.isArray(prevArr)) {
                                prevArr.forEach((element) => {
                                  if (element && !dayjs.isDayjs(element)) {
                                    opts.push(element);
                                  }
                                });
                              }
                              return {
                                ...prev,
                                [array[0] + "%select"]: opts.filter(
                                  (p) => p.id !== val.id,
                                ),
                              };
                            });
                          }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          }
          if (array.length === 2 && array[1] === "dateRange") {
            return (
              <div key={id}>
                {Array.isArray(val) ? (
                  <Pill
                    onClick={removalFilters[key]}
                    key={id}
                    label={
                      val.length === 2 &&
                      dayjs.isDayjs(val[0]) &&
                      dayjs.isDayjs(val[1])
                        ? `${array[0]}: ${val[0]?.format("MM/DD/YYYY")} - 
                      ${val[1]?.format("MM/DD/YYYY")}`
                        : ""
                    }
                    icon={IconX}
                    darker
                  />
                ) : null}
              </div>
            );
          }
          if (array.length === 3) {
            return (
              <div key={id}>
                <Pill
                  onClick={removalFilters[key]}
                  key={id}
                  // label={`${array[0]}: ${array[1]}`}
                  label={`${array[1]}`}
                  icon={IconX}
                  darker
                />
              </div>
            );
          }
        }
      })}
    </>
  );
};
export default CustomFilterTopSection;
