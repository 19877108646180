import React, { useContext, useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { Select } from "antd";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsRoleProps
  extends SiteOrientationStepProps {
  options: Array<{
    id: string;
    en: string;
    es: string;
  }>;
}

const SiteOrientationInPersonWorkerStepsRole: React.FC<
  SiteOrientationInPersonWorkerStepsRoleProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();
  const [role, setRole] = useState(workerContext?.orientee?.role || "");

  const langStrings = useCurrentLangStrings();
  const onEnter = (selectedRole?: string) => {
    workerContext.updateOrientee((user) => {
      return !user
        ? // why using selectedRole here? it's because when user selects a role we run onEnter and setRole at the same time due to which role's value is not set when running onEnter fully
          { role: selectedRole ?? role }
        : { ...user, role: selectedRole ?? role };
    });
    props.onNext();
  };
  return (
    <SiteFeatureStepLayout
      onNextButtonClick={() => {
        !!role && onEnter(role);
      }}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!role.length}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.whatIsYourRole}
      >
        <Select
          className="text-1"
          virtual={false}
          onSelect={(val) => {
            console.log(val.valueOf(), val);

            if (typeof val.valueOf() === "string") {
              setRole(val.valueOf());
              onEnter(val);
            }
          }}
          value={role}
          placeholder={langStrings.strings.selectYourRole}
        >
          {props.options.map((e: any) => (
            <Select.Option key={e.id} value={e.id} className="text-1">
              {langStrings.options.workerRoles[e.id] ?? e.en}
            </Select.Option>
          ))}
        </Select>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerStepsRole;
