import { Button, Input } from "antd";
import React, { useEffect, useRef } from "react";

import { CountrySelector, usePhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import PhoneNumberInputUI from "./PhoneNumberInputUI";
import { PhoneNumberInputCommonProps } from "./phoneNumberInputTypes";

export const PhoneNumberInputInternational: React.FC<
  PhoneNumberInputCommonProps
> = (props) => {
  const phoneInput = usePhoneInput({
    value: props.value,
    disableDialCodePrefill: true,
    onChange: (data) => {
      props.onChange(data.phone);
    },
  });

  const inputRef = useRef<React.ElementRef<typeof Input>>(null);

  // Need to reassign inputRef because antd provides not default ref
  useEffect(() => {
    if (phoneInput.inputRef && inputRef.current?.input) {
      phoneInput.inputRef.current = inputRef.current.input;
    }
  }, [inputRef, phoneInput.inputRef]);

  return (
    <div className={`flex flex-row gap-0.5 items-center`}>
      <CountrySelector
        selectedCountry={phoneInput.country}
        onSelect={(country) => {
          country.iso2 && phoneInput.setCountry(country.iso2);
        }}
        renderButtonWrapper={({ children, rootProps }) => (
          <Button
            {...rootProps}
            style={{
              padding: "4px",
              height: "100%",
              zIndex: 1, // fix focus overlap
            }}
          >
            {children}
          </Button>
        )}
        dropdownStyleProps={{
          style: {
            top: "35px",
          },
        }}
      />
      <PhoneNumberInputUI
        value={phoneInput.phone}
        onChange={phoneInput.handlePhoneValueChange}
        ref={inputRef}
        onEnterPress={props.onEnterPress}
        autoFocus={props.autoFocus}
      />
    </div>
  );
};

export default PhoneNumberInputInternational;
