import { Checkbox, Tag } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React from "react";

export type GCDailyReportSubcontractorType = {
  id?: string;
  name?: string;
  checked: boolean;
};

export interface GCProjectReportsDailySummarySubcontractorProps {
  items: GCDailyReportSubcontractorType[];
  handleSubcontractorFilterChange: (id: string, newValue: boolean) => void;
}

const GCProjectReportsDailySummarySubcontractor: React.FC<
  GCProjectReportsDailySummarySubcontractorProps
> = (props) => {
  return (
    <div className="flex flex-col gap-0.5" >
      {props.items.map((item) => (
        <div id={item.id} key={item.id}>
          <Checkbox
            checked={item.checked}
            onChange={(e: CheckboxChangeEvent) => {
              props.handleSubcontractorFilterChange(item.id?? "", e.target.checked);
            }}
          >
            {item.name}
          </Checkbox>
        </div>
      ))}
    </div>
  );
};

export default GCProjectReportsDailySummarySubcontractor;
