import { IconClipboard, IconClipboardCheck } from "@tabler/icons";
import React, { useRef } from "react";
import Popover from "src/common/components/general/Popover";
import { useCopyToClipboard } from "usehooks-ts";
import Icon from "../general/Icon";

interface ToolTipContentProps {
  text: string;
}

const ToolTipContent: React.FunctionComponent<ToolTipContentProps> = (
  props
) => {
  const [copiedText, copyFn] = useCopyToClipboard();
  const textRef = useRef<HTMLInputElement>(null);
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    copyFn(props.text);
  };

  const isCopied = copiedText === props.text;

  return (
    <div
      onClick={(e) => {
        handleClick(e);
      }}
      className={`flex flex-row items-center gap-0.75 p-1 cursor-pointer select-none group`}
      ref={textRef}
    >
      <Icon
        icon={isCopied ? IconClipboardCheck : IconClipboard}
        size="regular"
        color="interactive"
      />
      <span className="text-1.5 group-hover:text-interactive-primary transition-all">
        {props.text}
      </span>
    </div>
  );
};

export interface CopyableToolTipProps {
  textToCopy: string;
  children: React.ReactElement;
}

const CopyableToolTip: React.FunctionComponent<CopyableToolTipProps> = (
  props
) => {
  return (
    <Popover content={<ToolTipContent text={props.textToCopy} />}>
      {props.children}
    </Popover>
  );
};

export default CopyableToolTip;
