import React, { FC } from "react";
import LoadingContent from "src/common/components/general/LoadingContent";
import ReportDetail from "src/domain-features/sitesafety/components/ReportDetail";
import { useGetReportByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";
import ProjectProps from "src/common/types/manual/ProjectProps";
import ReportProps from "src/common/types/manual/ReportProps";

const GCProjectReportsPTPsDetail: FC<ReportProps & ProjectProps> = ({
  reportId,
  projectId,
}) => {
  const { data } = useGetReportByPkQuery({
    variables: { reportId },
  });
  if (!data?.report_by_pk) {
    return <LoadingContent />;
  }

  return (
    <ReportDetail
      showBackButton
      routeId={{ type: "gce", id: projectId }}
      report={data.report_by_pk as any}
    />
  );
};

export default GCProjectReportsPTPsDetail;
