import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { usePermanentOnsiteSettingInsertSubOnsiteMutation } from "src/common/types/generated/relay/usePermanentOnsiteSettingInsertSubOnsiteMutation.graphql";
import { usePermanentOnsiteSettingUpdateSubOnsiteMutation } from "src/common/types/generated/relay/usePermanentOnsiteSettingUpdateSubOnsiteMutation.graphql";
import { usePermanentOnsiteSettingInsertCrewOnsiteMutation } from "src/common/types/generated/relay/usePermanentOnsiteSettingInsertCrewOnsiteMutation.graphql";
import { usePermanentOnsiteSettingUpdateCrewOnsiteMutation } from "src/common/types/generated/relay/usePermanentOnsiteSettingUpdateCrewOnsiteMutation.graphql";
import { graphql } from "babel-plugin-relay/macro";
import { ConnectionHandler } from "relay-runtime";
import GetFullID from "src/common/functions/GetFullId";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";
import GetFullId from "src/common/functions/GetFullId";

const updateProjectSubcontractorCrewPermanentOnsiteMutation = graphql`
  mutation usePermanentOnsiteSettingUpdateCrewOnsiteMutation(
    $_set: project_subcontractor_crew_onsite_period_set_input!
    $where: project_subcontractor_crew_onsite_period_bool_exp!
  ) {
    update_project_subcontractor_crew_onsite_period(
      where: $where
      _set: $_set
    ) {
      returning {
        id
      }
    }
  }
`;
const insertProjectSubcontractorCrewPermanentOnsiteMutation = graphql`
  mutation usePermanentOnsiteSettingInsertCrewOnsiteMutation(
    $object: project_subcontractor_crew_onsite_period_insert_input!
  ) {
    insert_project_subcontractor_crew_onsite_period_one(object: $object) {
      id
    }
  }
`;
const insertProjectSubcontractorPermanentOnsiteMutation = graphql`
  mutation usePermanentOnsiteSettingInsertSubOnsiteMutation(
    $object: project_subcontractor_onsite_period_insert_input!
  ) {
    insert_project_subcontractor_onsite_period_one(object: $object) {
      id
    }
  }
`;

const updateProjectSubcontractorPermanentOnsiteMutation = graphql`
  mutation usePermanentOnsiteSettingUpdateSubOnsiteMutation(
    $_set: project_subcontractor_onsite_period_set_input!
    $where: project_subcontractor_onsite_period_bool_exp!
  ) {
    update_project_subcontractor_onsite_period(where: $where, _set: $_set) {
      returning {
        id
      }
    }
  }
`;

const usePermanentOnsiteSetting = (projectId: string) => {
  const [insertProjectSubcontractorPermanentOnsite, loading1] =
    useAsyncMutation<usePermanentOnsiteSettingInsertSubOnsiteMutation>(
      insertProjectSubcontractorPermanentOnsiteMutation,
    );

  const [updateProjectSubcontractorPermanentOnsite, loading2] =
    useAsyncMutation<usePermanentOnsiteSettingUpdateSubOnsiteMutation>(
      updateProjectSubcontractorPermanentOnsiteMutation,
    );
  const [insertProjectSubcontractorCrewPermanentOnsite, loading3] =
    useAsyncMutation<usePermanentOnsiteSettingInsertCrewOnsiteMutation>(
      insertProjectSubcontractorCrewPermanentOnsiteMutation,
    );

  const [updateProjectSubcontractorCrewPermanentOnsite, loading4] =
    useAsyncMutation<usePermanentOnsiteSettingUpdateCrewOnsiteMutation>(
      updateProjectSubcontractorCrewPermanentOnsiteMutation,
    );
  const setCrewOnSite = async (crewId: string, subId: string) => {
    try {
      await insertProjectSubcontractorCrewPermanentOnsite({
        variables: {
          object: {
            project_crew_id: crewId,
          },
        },
        updater: (store) => {
          const inserted = store.getRootField(
            "insert_project_subcontractor_crew_onsite_period_one",
          );
          const crewTableConn = ConnectionHandler.getConnection(
            store.getRoot(),
            "CrewTableQuery_project_crew_connection",
          );
          if (crewTableConn) {
            const edges = crewTableConn.getLinkedRecords("edges");
            if (edges) {
              const edgeIndex = edges.findIndex((edge) => {
                const node = edge.getLinkedRecord("node");
                return (
                  node?.getValue("id") === GetFullId("project_crew", crewId)
                );
              });
              if (edges[edgeIndex]) {
                const node = edges[edgeIndex].getLinkedRecord("node");
                if (node) {
                  node.setLinkedRecords(
                    [inserted],
                    "project_subcontractor_crew_onsite_periods",
                    { where: { closed_at: { _is_null: true } } },
                  );
                }
              }
            }
          }
          const conn = ConnectionHandler.getConnection(
            store.getRoot(),
            "GCProjectReportManagementQuery_project_connection",
          );
          if (conn) {
            const edge = (conn.getLinkedRecords("edges") ?? [])[0];

            if (edge) {
              const node = edge.getLinkedRecord("node");

              if (node) {
                const projectSubs =
                  node.getLinkedRecords("project_subcontractors", {
                    order_by: { subcontractor: { name: Order_By.Asc } },
                  }) || [];

                const projSub = projectSubs.find((ps) => {
                  const toReturn = ps.getValue("subcontractor_id") === subId;
                  return toReturn;
                });

                if (projSub) {
                  const projCrews =
                    projSub.getLinkedRecords("project_crews", {
                      where: { deleted_at: { _is_null: true } },
                      order_by: { name: Order_By.Asc },
                    }) || [];
                  const projCrew = projCrews.find(
                    (pc) =>
                      pc.getValue("id") == GetFullID("project_crew", crewId),
                  );
                  if (projCrew) {
                    projCrew.setLinkedRecords(
                      [inserted],
                      `project_subcontractor_crew_onsite_periods`,
                      { where: { closed_at: { _is_null: true } } },
                    );
                  }
                }
              }
            }
          }
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const setCrewOffSite = async (crewId: string, subId: string) => {
    try {
      await updateProjectSubcontractorCrewPermanentOnsite({
        variables: {
          where: {
            closed_at: { _is_null: true },
            project_crew_id: { _eq: crewId },
          },
          _set: {
            closed_at: dayjs().toISOString(),
          },
        },
        updater: (store) => {
          const crewTableConn = ConnectionHandler.getConnection(
            store.getRoot(),
            "CrewTableQuery_project_crew_connection",
          );
          if (crewTableConn) {
            const edges = crewTableConn.getLinkedRecords("edges");
            if (edges) {
              const edgeIndex = edges.findIndex((edge) => {
                const node = edge.getLinkedRecord("node");
                return (
                  node?.getValue("id") === GetFullId("project_crew", crewId)
                );
              });
              if (edges[edgeIndex]) {
                const node = edges[edgeIndex].getLinkedRecord("node");
                if (node) {
                  node.setLinkedRecords(
                    [],
                    "project_subcontractor_crew_onsite_periods",
                    { where: { closed_at: { _is_null: true } } },
                  );
                }
              }
            }
          }
          const conn = ConnectionHandler.getConnection(
            store.getRoot(),
            "GCProjectReportManagementQuery_project_connection",
          );
          if (conn) {
            const edge = (conn.getLinkedRecords("edges") ?? [])[0];
            if (edge) {
              const node = edge.getLinkedRecord("node");

              if (node) {
                const projectSubs =
                  node.getLinkedRecords("project_subcontractors", {
                    order_by: { subcontractor: { name: Order_By.Asc } },
                  }) || [];

                const projSub = projectSubs.find((ps) => {
                  const toReturn = ps.getValue("subcontractor_id") === subId;
                  return toReturn;
                });

                if (projSub) {
                  const projCrews =
                    projSub.getLinkedRecords("project_crews", {
                      where: { deleted_at: { _is_null: true } },
                      order_by: { name: Order_By.Asc },
                    }) || [];
                  const projCrew = projCrews.find(
                    (pc) =>
                      pc.getValue("id") == GetFullID("project_crew", crewId),
                  );
                  if (projCrew) {
                    projCrew.setLinkedRecords(
                      [],
                      `project_subcontractor_crew_onsite_periods`,
                      { where: { closed_at: { _is_null: true } } },
                    );
                  }
                }
              }
            }
          }
        },
      });
    } catch (err) {
      console.error(err);
    }
  };
  const setSubOnSite = async (subId: string) => {
    try {
      await insertProjectSubcontractorPermanentOnsite({
        variables: {
          object: {
            subcontractor_id: subId,
            project_id: projectId,
          },
        },
        updater: (store) => {
          const conn = ConnectionHandler.getConnection(
            store.getRoot(),
            "GCProjectReportManagementQuery_project_connection",
          );
          const subTableConn = ConnectionHandler.getConnection(
            store.getRoot(),
            "GCSubcontractorsQuery_project_subcontractor_connection",
          );
          const inserted = store.getRootField(
            "insert_project_subcontractor_onsite_period_one",
          );
          if (subTableConn) {
            const edges = subTableConn.getLinkedRecords("edges");
            if (edges) {
              const edgeIndex = edges.findIndex((edge) => {
                const node = edge.getLinkedRecord("node");
                return node?.getValue("subcontractor_id") === subId;
              });
              if (edges[edgeIndex]) {
                const node = edges[edgeIndex].getLinkedRecord("node");
                if (node) {
                  node.setLinkedRecords(
                    [inserted],
                    "project_subcontractor_onsite_periods",
                    { where: { closed_at: { _is_null: true } } },
                  );
                }
              }
            }
          }
          if (conn) {
            const edge = (conn.getLinkedRecords("edges") ?? [])[0];

            if (edge) {
              const node = edge.getLinkedRecord("node");

              if (node) {
                const projectSubs =
                  node.getLinkedRecords("project_subcontractors", {
                    order_by: { subcontractor: { name: Order_By.Asc } },
                  }) || [];

                const projSub = projectSubs.find((ps) => {
                  const toReturn = ps.getValue("subcontractor_id") === subId;
                  return toReturn;
                });

                if (projSub) {
                  projSub.setLinkedRecords(
                    [inserted],
                    `project_subcontractor_onsite_periods`,
                    { where: { closed_at: { _is_null: true } } },
                  );
                }
              }
            }
          }
        },
      });
    } catch (err) {
      console.error(err);
    }
  };
  const setSubOffSite = async (subId: string) => {
    try {
      await updateProjectSubcontractorPermanentOnsite({
        variables: {
          where: {
            project_id: { _eq: projectId },
            subcontractor_id: { _eq: subId },
            closed_at: { _is_null: true },
          },
          _set: {
            closed_at: dayjs().toISOString(),
          },
        },
        updater: (store) => {
          const conn = ConnectionHandler.getConnection(
            store.getRoot(),
            "GCProjectReportManagementQuery_project_connection",
          );
          const subTableConn = ConnectionHandler.getConnection(
            store.getRoot(),
            "GCSubcontractorsQuery_project_subcontractor_connection",
          );

          if (subTableConn) {
            const edges = subTableConn.getLinkedRecords("edges");
            if (edges) {
              const edgeIndex = edges.findIndex((edge) => {
                const node = edge.getLinkedRecord("node");
                return node?.getValue("subcontractor_id") === subId;
              });
              if (edges[edgeIndex]) {
                const node = edges[edgeIndex].getLinkedRecord("node");
                if (node) {
                  node.setLinkedRecords(
                    [],
                    "project_subcontractor_onsite_periods",
                    { where: { closed_at: { _is_null: true } } },
                  );
                }
              }
            }
          }
          if (conn) {
            const edge = (conn.getLinkedRecords("edges") ?? [])[0];
            if (edge) {
              const node = edge.getLinkedRecord("node");

              if (node) {
                const projectSubs =
                  node.getLinkedRecords("project_subcontractors", {
                    order_by: { subcontractor: { name: Order_By.Asc } },
                  }) || [];

                const projSub = projectSubs.find((ps) => {
                  const toReturn = ps.getValue("subcontractor_id") === subId;
                  return toReturn;
                });

                if (projSub) {
                  projSub.setLinkedRecords(
                    [],
                    `project_subcontractor_onsite_periods`,
                    { where: { closed_at: { _is_null: true } } },
                  );
                }
              }
            }
          }
        },
      });
    } catch (err) {
      console.error(err);
    }
  };
  return {
    setCrewOffSite,
    setCrewOnSite,
    setSubOnSite,
    setSubOffSite,
    loading: loading1 || loading2 || loading3 || loading4,
  };
};
// setCrewOnOffSite: async ({
//   crewId,
//   subId,
//   turnOnOrOff,
// }: {
//   crewId: string;
//   subId: string;
//   turnOnOrOff: boolean;
// }) => {
//   if (turnOnOrOff) await setCrewOnSite(crewId, subId);
//   else setCrewOffSite(crewId, subId);
// },
// setSubOnOffSite: async ({
//   subId,
//   turnOnOrOff,
// }: {
//   subId: string;
//   turnOnOrOff: boolean;
// }) => {
//   if (turnOnOrOff) await setSubOnSite(subId);
//   else setSubOffSite(subId);
// },
export default usePermanentOnsiteSetting;
