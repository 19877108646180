import React, { useRef, useState, ChangeEvent, useEffect } from "react";
import "@yaireo/ui-range";

// Third-party library doc: https://github.com/yairEO/ui-range

// TODO Clean this shit
// --- There're duplicates of logic

interface NumericalRangePickerProps {
  minValue: number;
  maxValue: number;
  onChange?: (bottomNumber: number, topNumber: number) => void;
}

const NumericalRangePicker: React.FunctionComponent<
  NumericalRangePickerProps
> = ({ minValue, maxValue, onChange }) => {
  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputParentRef = useRef<HTMLDivElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const [value1, setValue1] = useState(minValue);
  const [value2, setValue2] = useState(maxValue);

  useEffect(() => {
    if (!onChange || !inputRef1.current || !inputRef2.current) {
      return;
    }
    inputRef1.current.onchange = () => {
      onChange(value1, value2);
    };
    inputRef2.current.onchange = () => {
      onChange(value1, value2);
    };
  });

  const handleInput = (
    inputRef: React.RefObject<HTMLInputElement>,
    textValuePropertyName: string,
    valuePropertyName: string
  ) => {
    if (!inputParentRef.current || !inputRef.current) {
      return;
    }
    const { value } = inputRef.current;
    inputParentRef.current.style.setProperty(valuePropertyName, value);
    inputParentRef.current.style.setProperty(
      textValuePropertyName,
      JSON.stringify(value)
    );
    inputRef.current.style.setProperty(valuePropertyName, value);
  };

  const handleChange1 = (e: ChangeEvent<HTMLInputElement>) => {
    setValue1(parseFloat(e.currentTarget.value));
  };

  const handleChange2 = (e: ChangeEvent<HTMLInputElement>) => {
    setValue2(parseFloat(e.currentTarget.value));
  };

  const style = {
    "--min": minValue,
    "--max": maxValue,
    "--value-a": minValue,
    "--text-value-a": `"${minValue}"`,
    "--value-b": maxValue,
    "--text-value-b": `"${maxValue}"`,
    "--suffix": `""`,
    "--primary-color": "",
  } as React.CSSProperties;

  return (
    <div className="select-none w-full h-1.5 pt-1 box-content relative flex flex-row items-center">
      <div
        className="w-full range-slider flat"
        data-ticks-position="top"
        style={style}
        ref={inputParentRef}
      >
        <input
          ref={inputRef1}
          type="range"
          min={minValue}
          max={maxValue}
          value={value1}
          onChange={handleChange1}
          onInput={() => {
            handleInput(inputRef1, "--text-value-a", "--value-a");
          }}
        />
        <output></output>
        <input
          ref={inputRef2}
          type="range"
          min={minValue}
          max={maxValue}
          value={value2}
          onChange={handleChange2}
          onInput={() => {
            handleInput(inputRef2, "--text-value-b", "--value-b");
          }}
        />
        <output></output>
        <div className="range-slider__progress"></div>
      </div>
    </div>
  );
};

export default NumericalRangePicker;
