import React from "react";
import { DeliveryType } from "../../utilities/sitedeliveryTypes";
import SitedeliveryCalendarEventDeliveryPopover from "./SitedeliveryCalendarEventDeliveryPopover";
import TimeRangeSpan from "./TimeRangeSpan";

const SitedeliveryCalendarEventDelivery: React.FC<DeliveryType> = (props) => {
  const popoverChild = (
    <div className="w-full h-full">
      <div className="bg-white h-full w-full overflow-hidden border border-suplementary-3 rounded-0.25 p-0.5 shadow-small group-hover:border-static-secondary transition-colors">
        <div className="flex flex-col justify-center w-full h-full">
          <div className="flex flex-col items-center justify-start w-full h-auto max-h-full">
            <p className="text-center font-accent text-0.75">{props.title}</p>
            {"subcontractor" in props && (
              <span className="text-0.75 text-center font-accent">
                {props.subcontractor.title}
              </span>
            )}
            {"generalContractor" in props && (
              <span className="text-0.75 text-center font-accent">
                {props.generalContractor.title}
              </span>
            )}
            {/* <TimeRangeSpan from={props.from} to={props.to} /> */}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <SitedeliveryCalendarEventDeliveryPopover {...props}>
      {popoverChild}
    </SitedeliveryCalendarEventDeliveryPopover>
  );
};

export default SitedeliveryCalendarEventDelivery;
