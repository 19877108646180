import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import { SiteOrientationCertificateUploadQuery } from "src/common/types/generated/relay/SiteOrientationCertificateUploadQuery.graphql";
import { useSearchParams } from "react-router-dom";
import SiteOrientationCertificateUploadUI from "./SiteOrientationCertificateUploadUI";
import SiteOrientationLayoutWrapper from "src/common/components/layouts/steps-navigation-layout/SiteFeatureLayoutWrapper";

const certificationsQuery = graphql`
  query SiteOrientationCertificateUploadQuery(
    $projectId: uuid!
    $workerId: uuid!
  ) {
    project_connection(where: { id: { _eq: $projectId } }, first: 10000) {
      edges {
        node {
          require_certs
          project_certifications {
            id
            certification {
              pk: id @__clientField(handle: "pk")
              name
            }
            alternate_certifications {
              alternate_certification_id
            }
          }
        }
      }
    }
    user_connection(where: { id: { _eq: $workerId } }) {
      edges {
        node {
          name
        }
      }
    }
    worker_certification_connection(where: { worker_id: { _eq: $workerId } }) {
      edges {
        node {
          certification {
            pk: id @__clientField(handle: "pk")
            name
          }
        }
      }
    }
  }
`;

const SiteOrientationCertificateUpload: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get("project");
  const workerId = searchParams.get("worker");
  if (!workerId || !projectId) {
    throw new Error("missing workerId or ProjectId");
  }
  const data = useLazyLoadQuery<SiteOrientationCertificateUploadQuery>(
    certificationsQuery,
    {
      projectId: projectId,
      workerId: workerId,
    }
  );

  const workerName =
    data.user_connection.edges.length > 0
      ? data.user_connection.edges[0].node.name
      : "";

  const pastCertifications = data.worker_certification_connection.edges.map(
    (workerCert) => workerCert.node
  );

  return (
    <SiteOrientationLayoutWrapper>
      <SiteOrientationCertificateUploadUI
        userId={workerId}
        userName={workerName}
        projectId={projectId}
        nextButtonText="Done"
        managerDevice
        onCertificationsUpload={() => {}}
        requireCerts
        pastCertifications={pastCertifications.map((cert) => {
          return {
            certification_id: cert.certification.pk,
            certification_name: cert.certification.name,
          };
        })}
        projectCertifications={data.project_connection.edges[0].node.project_certifications.map(
          (projectCertification) => {
            return {
              certification_id: projectCertification.certification.pk,
              certification_name: projectCertification.certification.name,
              alternate_certification_ids:
                projectCertification.alternate_certifications.map(
                  (alternateCertification) => alternateCertification.alternate_certification_id
                ),
            };
          }
        )}
        onNext={() => {}}
      />
    </SiteOrientationLayoutWrapper>
  );
};

export default SiteOrientationCertificateUpload;
