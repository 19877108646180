import { CameraOutlined, CloseOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import React, { FC, useRef, useState } from "react";
import Webcam from "react-webcam";
import { CurrentLangStrings } from "../../utility-features/i18n/language-utils/i18nTypes";
import useLangStrings from "../../utility-features/i18n/context/languageHooks";

interface WebcamPhotoProps {
  onSubmit: (
    fileList: Array<{ front: string; back: string | undefined }>,
  ) => void;
  lang: string;
  insertingImage: boolean;
  imageTitle: string;
  single?: boolean;
  showDone?: boolean;
  ButtonText?: string;
}

const CertWarn: FC<{ langStrings: CurrentLangStrings }> = ({ langStrings }) => {
  return (
    <div className="text-center">
      <Alert
        type="warning"
        showIcon
        message={
          <div className="font-accent">
            {langStrings.strings.warningOnlyAddPhotosOfActualCerts}
          </div>
        }
      />
    </div>
  );
};
const scrollToRef = (ref: React.RefObject<HTMLDivElement>) =>
  ref.current?.scrollIntoView({ behavior: "smooth" });

const WebcamPhoto: FC<WebcamPhotoProps> = ({
  onSubmit,
  lang,
  insertingImage,
  single,
  imageTitle,
  showDone,
  // setCameraFileListLength,
  ButtonText,
}) => {
  const [isShowVideo, setIsShowVideo] = useState<"front" | "ith" | "none">(
    "none",
  );
  const [usingCameraFor, setUsingCameraFor] = useState<
    "frontRetake" | "back" | "backRetake" | "none"
  >("none");
  const langStrings = useLangStrings(lang);
  const [ithCamera, setIthCamera] = useState<number | undefined>(undefined);
  const webcamRef = useRef<any>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const webcamBackRef = useRef<any>(null);
  const [fileList, setFileList] = useState<
    Array<{ front: string; back: string | undefined }>
  >([]);

  const videoConstraints = {
    width: 1920,
    height: 1080,
    facingMode: "environment",
    screenshotQuality: 1,
  };
  let key = 0;

  //Start Cams
  const startCam = () => {
    setIsShowVideo("front");
  };
  const startIthCam = () => {
    setIsShowVideo("ith");
  };

  //Stop Cams
  const stopCam = () => {
    let stream = webcamRef?.current?.stream;
    if (!stream) return;
    const tracks = stream.getTracks();
    tracks.forEach((track: { stop: () => any }) => track.stop());
    setIsShowVideo("none");
    setUsingCameraFor("none");
  };
  const stopIthCam = () => {
    let stream = webcamBackRef?.current?.stream;
    if (!stream) return;
    const tracks = stream.getTracks();
    tracks.forEach((track: { stop: () => any }) => track.stop());
    setIthCamera(undefined);
    setIsShowVideo("none");
  };
  // useEffect(() => {
  //   setCameraFileListLength && setCameraFileListLength(() => fileList.length);
  // }, [fileList]);
  // Captures
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setFileList((prev) => {
      const newList = [...prev];
      newList.push({ front: imageSrc, back: undefined });
      return [...newList];
    });
    stopCam();
  }, [webcamRef]);

  const ithCameraCapture = React.useCallback(
    (i: number, front = false) => {
      const imageSrc = webcamBackRef.current.getScreenshot();
      //retake is same
      if (front) {
        setFileList((prev) => {
          const newList = [...prev];
          newList[i] = { front: imageSrc, back: newList[i].back };
          return [...newList];
        });
      } else {
        setFileList((prev) => {
          const newList = [...prev];
          newList[i] = { front: newList[i].front, back: imageSrc };
          return [...newList];
        });
      }
      setIthCamera(undefined);
      stopIthCam();
    },
    [webcamRef],
  );

  //   Removes
  const removeFront = (i: number) => {
    setFileList((prev) => {
      const newList = [...prev];
      newList.splice(i, 1);
      return [...newList];
    });
  };

  const removeBack = (i: number) => {
    setFileList((prev) => {
      const newList = [...prev];
      newList[i] = {
        front: newList[i].front,
        back: undefined,
      };
      return [...newList];
    });
  };
  return (
    <>
      <div>
        <div className="flex flex-col items-center justify-center">
          {fileList.map((img, i) => {
            return (
              <div key={key++}>
                <div className="text-center mb-1 text-1.25">
                  {imageTitle} {single ? "" : `${i + 1}`}
                </div>
                <div className="relative">
                  <Button
                    type="link"
                    className=" absolute opacity-80 text-white text-1.5 float-left"
                    onClick={(e) => {
                      e.preventDefault();
                      removeFront(i);
                    }}
                  >
                    X
                  </Button>
                </div>
                <img src={img.front} className="text-center" width="360" />
                <div className="flex flex-row gap-0.5 justify-between last:rounded-0">
                  <Button className="text-left font-accent border-0">
                    {langStrings.strings.front}
                  </Button>
                  {isShowVideo === "none" && (
                    <Button
                      type="primary"
                      className="ml-1 rounded-b-1 font-accent"
                      onClick={(e) => {
                        e.preventDefault();

                        setIthCamera(i);
                        setUsingCameraFor("frontRetake");
                        startIthCam();
                      }}
                    >
                      {langStrings.strings.retake} &nbsp;
                      <CameraOutlined />
                    </Button>
                  )}

                  {!fileList[i].back && isShowVideo === "none" && (
                    <Button
                      type="primary"
                      className="ml-1 rounded-b-1 font-accent"
                      onClick={(e) => {
                        e.preventDefault();
                        setUsingCameraFor("back");
                        setIthCamera(i);
                        startIthCam();
                      }}
                    >
                      {langStrings.strings.addBack} &nbsp;
                      <CameraOutlined />
                    </Button>
                  )}
                </div>
                <br />
                {isShowVideo === "ith" && ithCamera === i && (
                  <div>
                    <div
                      ref={scrollRef}
                      className="flex flex-col-reverse items-center relative m-0"
                    >
                      <Webcam
                        forceScreenshotSourceSize
                        audio={false}
                        ref={webcamBackRef}
                        width="360"
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                      />
                      <div className="flex items-center justify-center m-0 absolute">
                        <Button
                          shape="circle"
                          type="primary"
                          className="w-5 h-5 z-50"
                          onClick={() => {
                            if (usingCameraFor === "backRetake") {
                              ithCameraCapture(i);
                            } else if (usingCameraFor === "frontRetake") {
                              ithCameraCapture(i, true);
                            } else if (usingCameraFor === "back") {
                              ithCameraCapture(i);
                            }
                          }}
                        >
                          <CameraOutlined className="text-3" />
                        </Button>
                        <Button
                          type="text"
                          shape="circle"
                          className="w-5 h-5 bg-static-primary hover:bg-static-primary hover:border-0"
                          onClick={() => {
                            stopIthCam();
                          }}
                        >
                          <CloseOutlined className="text-3 text-white" />
                        </Button>
                      </div>
                    </div>
                    {imageTitle === langStrings.strings.certificate && (
                      <CertWarn {...{ langStrings }} />
                    )}
                  </div>
                )}
                {img.back && (
                  <div className="relative">
                    <Button
                      type="link"
                      className="absolute opacity-80 float-right mr-2 text-white text-1.5"
                      onClick={(e) => {
                        e.preventDefault();
                        removeBack(i);
                      }}
                    >
                      X
                    </Button>
                    <img src={img.back} width="360" />
                    <div className="flex flex-row gap-0.5 justify-between last:rounded-0">
                      <Button className="text-left font-accent border-0">
                        {langStrings.strings.back}
                      </Button>
                      {isShowVideo === "none" && (
                        <Button
                          type="primary"
                          className="ml-1 rounded-b-1 font-accent"
                          onClick={(e) => {
                            e.preventDefault();
                            setUsingCameraFor("backRetake");
                            setIthCamera(i);
                            startIthCam();
                            scrollToRef(scrollRef);
                          }}
                        >
                          {langStrings.strings.retakeBack} &nbsp;
                          <CameraOutlined />
                        </Button>
                      )}
                    </div>
                    <br />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="text-center justify-center h-auto">
          <div className="flex flex-col-reverse items-center relative  m-0">
            {isShowVideo === "front" && (
              <>
                <Webcam
                  forceScreenshotSourceSize
                  audio={false}
                  width="360"
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
                <div className="flex items-center absolute justify-center m-0">
                  {isShowVideo === "front" && (
                    <span>
                      <Button
                        type="primary"
                        shape="circle"
                        className="z-50 w-5 h-5 mr-0.5"
                        onClick={(e) => {
                          e.preventDefault();
                          capture();
                        }}
                      >
                        <CameraOutlined className="text-3" />
                      </Button>
                      <Button
                        type="text"
                        shape="circle"
                        className="w-5 h-5 bg-static-primary hover:bg-static-primary hover:border-0 z-50"
                        onClick={() => {
                          stopCam();
                        }}
                      >
                        <CloseOutlined className="text-3 text-white" />
                      </Button>
                    </span>
                  )}
                </div>
              </>
            )}
          </div>
          {isShowVideo === "front" &&
            imageTitle === langStrings.strings.certificate && (
              <CertWarn {...{ langStrings }} />
            )}
          {isShowVideo === "none" &&
            (!single || (single && fileList.length < 1)) && (
              // we show this Take Photo button when single is undefined/false(multiple photo can be taken)OR when single is true
              // i.e. we want a single photo(front and back) then fileList length should be < 1( no photo in fileList)
              // only then we show this button for taking photo
              <Button
                size="large"
                type="primary"
                onClick={(e) => {
                  e.preventDefault();
                  setIthCamera(undefined);
                  startCam();
                }}
                className="font-accent rounded-2 mt-2 mb-2"
              >
                +{" "}
                {ButtonText ??
                  (fileList.length === 0
                    ? langStrings.strings.takePhoto
                    : langStrings.strings.addAnother)}
                &nbsp;
                <CameraOutlined />
              </Button>
            )}
        </div>
      </div>
      {((fileList.length !== 0 && isShowVideo === "none") || showDone) && (
        <div className="flex flex-col items-center justify-center">
          <Button
            type={"primary"}
            onClick={() => onSubmit(fileList)}
            loading={insertingImage}
            size="large"
            className="font-accent rounded-2 mb-2"
          >
            {langStrings.strings.done}
          </Button>
        </div>
      )}
    </>
  );
};
export default WebcamPhoto;
