import { MailOutlined } from "@ant-design/icons";
import { Button, message, Space } from "antd";
import dayjs from "dayjs";
import React, { FC, useState } from "react";
import SCSendEmailModal from "src/common/components/dialogs/SubAdminSendEmailModal";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BContent from "src/common/components/layouts/BContent";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import {
  useEmailDailyLogsPdfMutation,
  useGenerateDailyLogsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import SubcontractorProps from "src/common/types/manual/SubcontractorProps";
import DailyReportTableAllForSC from "./DailyReportTableAllForSubAdmin";

interface SCReportsDailyReportsProps extends SubcontractorProps {
  openReport: (params: { date: string } | { id: string }) => void;
  projectId?: string;
}

const SCReportsDailyReports: FC<SCReportsDailyReportsProps> = ({
  openReport,
  projectId,
  subcontractorId,
}) => {
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [isEmail, setIsEmail] = useState(true);
  const [emailLogs, { loading: emailingDailyLogs }] =
    useEmailDailyLogsPdfMutation();
  const [generateLogs, { loading: generatingDailyLogs }] =
    useGenerateDailyLogsMutation();
  let date = new Date();
  const dateStr: string = date.toDateString();

  return (
    <BContent>
      {subcontractorId && (
        <SCSendEmailModal
          visible={emailModalOpen}
          isEmail={isEmail}
          subId={subcontractorId}
          projectId={projectId}
          onCancel={() => setEmailModalOpen(false)}
          onCreate={async (values) => {
            setEmailModalOpen(false);
            if (isEmail) {
              await emailLogs({
                variables: {
                  input: {
                    projectId: values.projectId,
                    date: dateStr,
                    type: "sub",
                    emailRecipients: [values.email],
                    subcontractorId: subcontractorId,
                  },
                },
              });

              message.success("Emailed report!");
            } else {
              const { data: downloadedData } = await generateLogs({
                variables: {
                  input: {
                    projectId: values.projectId,
                    date: dayjs(dateStr).format("YYYY-MM-DD"),
                    type: "sub",
                    subcontractorId: subcontractorId,
                  },
                },
              });
              if (downloadedData?.generateDailyLogs) {
                downloadFromUrl(downloadedData?.generateDailyLogs);
              }
            }
          }}
        />
      )}

      <Space direction="vertical" style={{ width: "100%" }}>
        <Space>
          <Button
            icon={<MailOutlined />}
            title="Email Report"
            className="rounded-2"
            loading={emailingDailyLogs}
            onClick={() => {
              setIsEmail(true);
              setEmailModalOpen(true);
            }}
          />
          <Button
            type="primary"
            loading={generatingDailyLogs}
            className="rounded-2"
            onClick={async () => {
              if (projectId) {
                const { data: downloadedData } = await generateLogs({
                  variables: {
                    input: {
                      projectId: projectId,
                      date: dayjs(dateStr).format("YYYY-MM-DD"),
                      type: "sub",
                      subcontractorId: subcontractorId,
                    },
                  },
                });
                if (downloadedData?.generateDailyLogs) {
                  downloadFromUrl(downloadedData?.generateDailyLogs);
                }
              } else {
                setIsEmail(false);
                setEmailModalOpen(true);
              }
            }}
          >
            Download Today's Daily Report
          </Button>
        </Space>
        <DailyReportTableAllForSC
          onRowClick={openReport}
          subcontractorId={subcontractorId}
          projectId={projectId}
        />
      </Space>
    </BContent>
  );
};

export default withCustomSuspense(SCReportsDailyReports);
