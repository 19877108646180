import React from "react";
import CustomSuspense from "../../general/CustomSuspense";
import FallbackSkeletonCentered from "../../FallbackSkeletonCentered";
import clsx from "clsx";

export interface SiteFeatureLayoutWrapperProps {
  children: React.ReactNode;
}

const SiteFeatureLayoutWrapper: React.FC<SiteFeatureLayoutWrapperProps> = (
  props
) => {
  return (
    <div
      className="w-full  h-full bg-suplementary-1 flex flex-row justify-center items-center tablet-landscape:p-2"
    >
      <div
        className="w-full relative overflow-hidden h-full tablet-landscape:rounded-1.5 bg-white shadow p-1 tablet-landscape:max-w-40 tablet-landscape:p-2"
      >
        <CustomSuspense fallback={<FallbackSkeletonCentered />}>
          {props.children}
        </CustomSuspense>
      </div>
    </div>
  );
};

export default SiteFeatureLayoutWrapper;
