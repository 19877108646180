import React from "react";
import { Outlet } from "react-router-dom";
import RequireRoleWrapper from "src/common/components/ReqiureRoleWrapper";
import QuizQR from "./QuizQr";
import QuizCompletionPage from "./QuizCompletionPage";
import QuizQuestionsView from "./QuizQuestionsView";

const quizQrRoute = {
  element: (
    <RequireRoleWrapper
      requiredRole={["employee", "worker", "pseudo_worker", "quiz_qr_user"]}
    >
      <Outlet />
    </RequireRoleWrapper>
  ),
  children: [
    { path: "", element: <QuizQR /> },
    {
      path: ":projectId/question/:userId",
      element: <QuizQuestionsView />,
    },
    {
      path: ":projectId/completion/:userId",
      element: <QuizCompletionPage />,
    },
  ],
};
export default quizQrRoute;
