import React, { useEffect, useState } from "react";
import {
  useGetUserQuery,
  useSlackMessageMutation,
} from "../../types/generated/apollo/graphQLTypes";
import validateEmail from "../../functions/validateEmail";
import dayjs from "dayjs";
import { isProductionEnvironment } from "../../constants/environment";
import { Button, Input, message, Spin } from "antd";
import sendHotjarEvent from "../../../utility-features/event-tracking/hotjar/sendHotjarEvent";

interface SupportContactForm {
  userId: string;
  onDone?: () => void;
}

const SupportContactForm: React.FC<SupportContactForm> = (props) => {
  const [userEmail, setUserEmail] = useState<string>("");
  const [messageText, setMessageText] = useState<string>("");
  const [slackMessageMutation, { loading: slackMessageLoading }] =
    useSlackMessageMutation();

  const userQueryResponse = useGetUserQuery({
    variables: { userId: props.userId, includeEmployee: false },
    onCompleted: (data) => {
      setUserEmail(data?.user_by_pk?.email ?? "");
    },
  });

  const onSendClick = async () => {
    const user = userQueryResponse.data?.user_by_pk;
    if (!validateEmail(userEmail) || !messageText.length || !user) {
      return;
    }

    const { name: userFullName, username: userLogin } = user;
    const pageUrl = window.location.href;

    const additionalMessageProperties: [string, string | undefined | null][] = [
      ["User Email", userEmail],
      ["Page URL", pageUrl],
      ["User ID", "`" + props.userId + "`"],
      ["User Login", "`" + userLogin + "`"],
      ["Time", dayjs().format("MMM D, h:mm A z")],
    ];

    const additionalInfoText = additionalMessageProperties.reduce(
      (prevValue, newValue) =>
        !!newValue[1] ? prevValue + newValue.join(": ") + "\n" : prevValue,
      "",
    );

    const text = `*New Message via Customer Support Form* ${
      isProductionEnvironment ? "`production`" : "`testing`"
    }\n>${messageText
      .split("\n")
      .join(`\n>`)}\n_${userFullName}_\n${additionalInfoText}`;

    const slackMessageResponse = await slackMessageMutation({
      variables: {
        text,
      },
    });

    if (slackMessageResponse.data?.slackPostMessage) {
      message.success(
        "We've received your message and will reply to your email. Thank you!",
      );

      sendHotjarEvent("contact_us_message_sent");
      setMessageText("");
      props.onDone?.();
    }
  };

  useEffect(() => {
    sendHotjarEvent("contact_us_opened");
  }, []);

  return (
    <div className={`flex flex-col gap-1 w-20`}>
      {userQueryResponse.loading}
      <p>If you need help or want to leave a feedback, drop us a message.</p>
      <Input.TextArea
        value={messageText}
        onChange={(e) => setMessageText(e.target.value)}
        placeholder="Message"
      />
      <p>We'll reply to your email as soon as possible.</p>
      <Spin spinning={userQueryResponse.loading}>
        <Input
          type={"email"}
          placeholder="Your Email"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
        />
      </Spin>
      <Button
        disabled={!validateEmail(userEmail) || !messageText.length}
        onClick={onSendClick}
        loading={userQueryResponse.loading || slackMessageLoading}
        type="primary"
      >
        Send
      </Button>
    </div>
  );
};

export default SupportContactForm;
