import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useLazyLoadQuery } from "react-relay/hooks";
import { fetchQuery } from "react-relay/hooks";
import { IEnvironment } from "relay-runtime";
import { useGetOrientationDataQuery } from "src/common/types/generated/relay/useGetOrientationDataQuery.graphql";

export const orientationDataQuery = graphql`
  query useGetOrientationDataQuery(
    $projectId: uuid!
    $userId: uuid!
    $now: timestamptz!
    $universalOrientationValidDate: timestamptz!
  ) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name
          ask_age
          ask_gender
          ask_ethnicity
          ask_residence
          require_dob
          require_phone
          union_labor
          assign_hard_hat
          ask_veteran_type
          perform_drugtest
          next_hard_hat_number
          ask_years_with_employer
          ask_emergency_contact
          ask_number_of_years_in_construction
          add_hard_hat_during_in_person
          agc_universal_orientation
          in_person_orientation
          require_profile_photo
          drugtest_validity_days
          require_drugtest_upload
          require_certification_upload
          automatically_assign_hard_hat
          add_hard_hat_during_registration
          require_drugtest_to_complete_orientation
          general_contractor_id
          require_certs
          project_certifications {
            id
            certification {
              pk: id @__clientField(handle: "pk")
              name
            }
            alternate_certifications {
              alternate_certification_id
            }
          }
          general_contractor {
            name
          }
          general_contractors {
            general_contractor_id
            general_contractor {
              name
            }
          }
        }
      }
    }
    orientation_connection(
      where: {
        deleted_at: { _is_null: true }
        project_orientations: {
          required_by_all_workers: { _eq: true }
          project_id: { _eq: $projectId }
        }
        _or: [
          { project_id: { _eq: $projectId } }
          { project_id: { _is_null: true } }
        ]
        general_contractor: { projects: { id: { _eq: $projectId } } }
      }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          id
          name
          created_at
          type
          duration_valid
          project_orientations(where: { project_id: { _eq: $projectId } }) {
            hide_but_give_credit
            play_during_in_person
          }
          orientation_results(
            where: {
              user_id: { _eq: $userId }
              _and: [
                {
                  _or: [
                    { status: { _eq: "completed" } }
                    { completed_at: { _is_null: false } }
                  ]
                }
                {
                  _or: [
                    { expired_at: { _gte: $now } }
                    { expired_at: { _is_null: true } }
                  ]
                }
              ]
            }
            order_by: { completed_at: desc, expired_at: desc }
          ) {
            status
            expired_at
            completed_at
            orientation_id
            signature_image_id
            signature_url
            created_at
          }
        }
      }
    }
    universal_orientation: orientation_connection(
      where: {
        deleted_at: { _is_null: true }
        project_orientations: {
          required_by_all_workers: { _eq: true }
          project_id: { _is_null: true }
        }
        project_id: { _is_null: true }
        general_contractor_id: { _is_null: true }
      }
    ) {
      edges {
        node {
          id
        }
      }
    }
    project_subcontractor_connection(
      where: { project: { orientation_project_id: { _eq: $projectId } } }
    ) {
      edges {
        node {
          subcontractor_id
          project_id
        }
      }
    }
    user_connection(where: { id: { _eq: $userId } }) {
      edges {
        node {
          name
          role
          lang
          email
          phone_number
          birth_date
          profile_picture {
            url
          }
          subcontractor_employee {
            subcontractor_id
            subcontractor {
              name
            }
          }
          universal_orientations(
            where: {
              universal_orientated_at: { _gte: $universalOrientationValidDate }
            }
            order_by: { universal_orientated_at: desc }
            limit: 1
          ) {
            id
            universal_orientated_at
          }
          employee {
            general_contractor_id
            oac_company_id
            title_id
            oac_title_id
            employee_projects(
              where: {
                project: { orientation_project_id: { _eq: $projectId } }
              }
              order_by: { hard_hat_number: desc_nulls_last }
            ) {
              project_id
              hard_hat_number
              user_orientation {
                drug_test_passed
                orientated_at
                completed_at
              }
            }
          }
          worker {
            gender
            ethnicity
            city_zip_code_id
            worker_title {
              translation {
                en
              }
            }
            labor_union_chapter_id
            labor_union_chapter {
              labor_union_id
            }
            years_in_construction
            years_in_construction_entered_at
            years_with_employer
            city_zip_code {
              zip_code
              city {
                pk: id @__clientField(handle: "pk")
                name
                state_code
                state {
                  name
                }
              }
            }
            veteran_type
            age
            age_entered_at
            subcontractor_id
            current_worker_role
            worker_title_id
            subcontractor {
              name
            }
            worker_projects(
              where: {
                project: { orientation_project_id: { _eq: $projectId } }
                is_last: { _eq: true }
              }
              order_by: { hard_hat_number: desc_nulls_last }
            ) {
              project_id
              receive_text_message
              hard_hat_number
              user_orientation {
                drug_test_passed
                orientated_at
                completed_at
                drug_test {
                  id
                }
              }
            }
            worker_certifications(
              where: {
                _or: [
                  { expires_on: { _is_null: true } }
                  { expires_on: { _gte: $now } }
                ]
              }
            ) {
              expires_on
              created_at
              issued_on
              certification_id
              id @__clientField(handle: "pk")
              images(order_by: { description: desc }, limit: 2) {
                id
              }
              certification {
                name
              }
            }
            pendingCertificates: certificates_to_verify(
              where: {
                document: { _eq: "certificate" }
                status: { _eq: "submitted" }
                verified_at: { _is_null: true }
              }
            ) {
              id
            }
            pendingProfile: certificates_to_verify(
              where: {
                document: { _eq: "profilePhoto" }
                status: { _eq: "submitted" }
                verified_at: { _is_null: true }
              }
              order_by: { created_at: desc }
              limit: 1
            ) {
              front_image {
                url
              }
            }
            pendingDrugtests: certificates_to_verify(
              where: {
                project: { orientation_project_id: { _eq: $projectId } }
                document: { _eq: "drugtest" }
                status: { _eq: "submitted" }
                verified_at: { _is_null: true }
              }
            ) {
              id
            }
            worker_drug_tests(
              where: {
                status: { _eq: "negative" }
                project: {
                  general_contractor: { projects: { id: { _eq: $projectId } } }
                }
              }
              order_by: { drug_test_date: desc }
            ) {
              drug_test_date
              id
              pk: id @__clientField(handle: "pk")
              project_id
              drug_test_passed
            }
          }
        }
      }
    }
  }
`;

export const fetchOrientationData = async (
  environment: IEnvironment,
  userId: string,
  projectId: string,
) => {
  const data = await fetchQuery<useGetOrientationDataQuery>(
    environment,
    orientationDataQuery,
    {
      userId,
      projectId,
      now: dayjs().endOf("d").toISOString(),
      universalOrientationValidDate: dayjs()
        .subtract(11, "months")
        .startOf("d")
        .toISOString(),
    },
  ).toPromise();
  if (!data) {
    throw new Error("FetchOrientationData returned null");
  }
  return data;
};

const useGetOrientationData = (userId: string, projectId: string) => {
  return useLazyLoadQuery<useGetOrientationDataQuery>(orientationDataQuery, {
    userId,
    projectId,
    now: dayjs().endOf("day").toISOString(),
    universalOrientationValidDate: dayjs()
      .subtract(11, "months")
      .startOf("d")
      .toISOString(),
  });
};
export default useGetOrientationData;
