import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";

const StrongPasswordInfo: React.FC = () => (
  <div>
    <div className="text-0.75 ml-0.5 -mt-1 mb-1">
      Password must contain
      <br />
      *At least 8 characters
      <br />
      *At least 1 capital letter (A-Z)
      <br />
      *At least 1 lowercase letter (a-z)
      <br />
      *At least 1 number (0-9)
    </div>
  </div>
);
export default StrongPasswordInfo;
