import { Checkbox, Form, Modal, Table, message } from "antd";
import React, { useMemo, useState } from "react";

import Input from "antd/lib/input/Input";
import { SCWorkersQuery$data } from "../../types/generated/relay/SCWorkersQuery.graphql";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useSubcontractor } from "../SubcontractorContext";
import { useUpdateWorkersMutation } from "src/common/types/generated/apollo/graphQLTypes";

interface WorkerProject {
  id: string;
  workerId: string;
  workerName: string;
  projectName: string;
  createdAt: string;
  children?: Omit<WorkerProject, "verify">[];
  verify: boolean;
}
interface SCEmployeeVerificationModalProps {
  workerProjects: SCWorkersQuery$data["worker_connection"]["edges"][0]["node"]["worker_projects"];
  visible: boolean;
  onCancel: () => void;
}

const SCEmployeeVerificationModal: React.FC<
  SCEmployeeVerificationModalProps
> = ({ workerProjects, visible, onCancel }) => {
  const navigate = useNavigate();
  const subcontractor = useSubcontractor();

  const [form] = Form.useForm();
  const [updateWorkers] = useUpdateWorkersMutation();
  const [isUpdatingWorkers, setIsUpdatingWorkers] = useState(false);
  const [selectedWorkerIds, setSelectedWorkerIds] = useState(new Set<string>());

  const [workerWithWorkerProjects, gcsName] = useMemo(() => {
    const workerWithWorkerProjects: Map<string, WorkerProject> = new Map();
    const gcsName = new Set<string>();
    const workerProjectsFiltered = workerProjects
      .filter((workerProject) => {
        if (
          workerProject.project.general_contractor.employee_verification &&
          workerProject.worker.status === "not-yet-verified" &&
          workerProject.is_last &&
          !workerProject.deleted_at &&
          !workerProject.archived_at
        ) {
          gcsName.add(workerProject.project.general_contractor.name);
          return true;
        }
        return false;
      })
      .sort((a, b) => {
        return a.created_at > b.created_at
          ? -1
          : a.created_at < b.created_at
          ? 1
          : 0;
      });

    workerProjectsFiltered.forEach((workerProject) => {
      let workerProjectWithChildrens = workerWithWorkerProjects.get(
        workerProject.worker.pk,
      );

      if (workerProjectWithChildrens) {
        workerProjectWithChildrens.children =
          workerProjectWithChildrens.children || [];
        workerProjectWithChildrens.children.push({
          id: workerProject.id,
          workerId: workerProject.worker.pk,
          workerName: workerProject.worker.user.name,
          projectName: workerProject.project.name,
          createdAt: workerProject.created_at,
        });
      } else {
        workerWithWorkerProjects.set(workerProject.worker.pk, {
          id: workerProject.id,
          workerId: workerProject.worker.pk,
          workerName: workerProject.worker.user.name,
          projectName: workerProject.project.name,
          createdAt: workerProject.created_at,
          verify: true,
        });
      }
    });

    setSelectedWorkerIds(
      new Set<string>(
        Array.from(workerWithWorkerProjects.values()).map(
          (workerProject) => workerProject.workerId,
        ),
      ),
    );
    return [workerWithWorkerProjects, gcsName];
  }, [workerProjects]);

  const gcNames = Array.from(gcsName).join(", ");

  return (
    <Modal
      centered
      footer={workerWithWorkerProjects.size > 0 ? undefined : <></>}
      closable={!isUpdatingWorkers}
      open={visible}
      title="Employee Verification Required"
      okText="Verify"
      cancelText="Cancel"
      width={"70vw"}
      maskClosable={!isUpdatingWorkers}
      okButtonProps={{
        loading: isUpdatingWorkers,
      }}
      afterClose={() => {
        navigate("..");
      }}
      cancelButtonProps={{
        disabled: isUpdatingWorkers,
      }}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={async () => {
        try {
          if (!selectedWorkerIds.size) {
            message.info("Please select workers to verify", 2);
            return;
          }

          setIsUpdatingWorkers(true);

          const workersToUpdate = Array.from(selectedWorkerIds).map(
            (workerId) => {
              return {
                where: { uid: { _eq: workerId } },
                _set: {
                  verified_by_id: subcontractor.user.id,
                  status: "verified",
                  verified_at: dayjs().format(),
                },
              };
            },
          );
          await updateWorkers({
            variables: {
              updates: workersToUpdate,
            },
          });

          setIsUpdatingWorkers(false);
          form.resetFields();
          onCancel();
          message.success("Employee(s) verified successfully!");
        } catch (error) {
          console.error(error);
          throw new Error("Error in updating workers status");
        }
      }}
    >
      {workerWithWorkerProjects.size > 0 ? (
        <>
          <div className="overflow-scroll max-h-[75vh]">
            This process certifies that each subcontractor and its lower tier
            subcontractors DO NOT employ Independent Contractors.{" "}
            <b>{subcontractor.user.name}</b> further certifies that ALL of its
            on-site employees:
            <br />
            <br />
            <div>
              <ol style={{ listStyleType: "decimal", paddingLeft: 20 }}>
                <li>Receive weekly or bi-weekly payroll checks;</li>
                <li>
                  Are paid at least the state minimum wage for every hour
                  worked;
                </li>
                <li>
                  Are paid time and one half for all hours worked over 40 hours
                  in a work week;
                </li>
                <li>
                  Receive earned sick time and other wage entitlements defined
                  by law;
                </li>
                <li>Do not receive 1099s;</li>
                <li>
                  Have state and federal taxes withheld and paid on their
                  behalf;
                </li>
                <li> Are covered by Worker's Compensation insurance; and</li>
                <li>
                  Are US citizens or otherwise lawfully working in the US and
                  the Subcontractor or Sub-subcontractor has completed and
                  retained I-9 Forms on each employee.
                </li>
              </ol>
            </div>
            <br />
            <p>
              Failure to truthfully complete this Certification or to comply
              with state and federal employment laws may result in the penalties
              set out in the subcontracting agreement between {gcNames} and{" "}
              {subcontractor.name}. {subcontractor.name}, by its Verification
              below, agrees that {gcNames} or its agent shall have the right, at
              any time, to review {subcontractor.name} or Sub-subcontractor's
              employee records to ensure compliance with its contract with{" "}
              {gcNames} and the avowals made in this Labor Certification. If you
              do not recognize a worker or do not meet the requirements listed
              above, <b>contact the person listed on the Verification Email</b>.
            </p>
            <br />
            <Table
              rowKey="id"
              className="w-full"
              dataSource={Array.from(workerWithWorkerProjects.values())}
              pagination={false}
              columns={[
                {
                  title: "",
                  render: (_, __, index) => {
                    return index + 1;
                  },
                },
                {
                  title: "Worker",
                  dataIndex: ["workerName"],
                },
                {
                  title: "Project",
                  dataIndex: ["projectName"],
                },
                {
                  title: "Registration Date",
                  dataIndex: ["createdAt"],
                  render: (value) => {
                    return dayjs(value).format("MM/DD/YYYY");
                  },
                },
                {
                  title: "Verify",
                  dataIndex: ["verify"],
                  render: (value, workerProject) => {
                    return (
                      "verify" in workerProject && (
                        <Checkbox
                          defaultChecked={value}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedWorkerIds((prev) => {
                                return new Set([
                                  ...prev,
                                  workerProject.workerId,
                                ]);
                              });
                            } else {
                              setSelectedWorkerIds((prev) => {
                                const newWorkerIds = new Set([
                                  ...prev,
                                  workerProject.workerId,
                                ]);
                                newWorkerIds.delete(workerProject.workerId);
                                return newWorkerIds;
                              });
                            }
                          }}
                        ></Checkbox>
                      )
                    );
                  },
                },
              ]}
            ></Table>
          </div>
          <br />
          <Form
            form={form}
            layout="inline"
            name="form_in_modal"
            initialValues={{
              name: subcontractor.user.name ?? "",
              title: subcontractor.title?.en ?? "",
              email: subcontractor.user.email ?? "",
            }}
          >
            <Form.Item
              style={{ marginBottom: 12 }}
              name="name"
              label="Your Name"
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item style={{ marginBottom: 12 }} name="title" label="Title">
              <Input readOnly />
            </Form.Item>
            <Form.Item style={{ marginBottom: 12 }} name="email" label="Email">
              <Input readOnly type="email" />
            </Form.Item>
          </Form>

          <i>
            *By Selecting Verify, you confirm you are the person listed here and
            the above information is true and accurate
          </i>
        </>
      ) : (
        <div className="text-center">
          You are caught up! All workers have been verified! 🎉
        </div>
      )}
    </Modal>
  );
};

export default SCEmployeeVerificationModal;
