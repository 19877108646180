import React, { useEffect } from "react";
import { Form, Modal, Select } from "antd";
import getNormalOptionsFilter from "src/common/functions/getNormalOptionsFilter";
import { useGetProjectEmployeesQuery } from "src/common/types/generated/apollo/graphQLTypes";

interface FormValues {
  usersToBeNotified: Array<string>;
}

interface User {
  name: string;
  email: string;
  id: string;
}

interface Props {
  projectId: string;
  visible: boolean;
  onDoneEditing: (values: FormValues) => void;
  onCancel: () => void;
  selectedUserIds: Array<string>;
}

const EditUsersForNotificationModal: React.FC<Props> = ({
  projectId,
  visible,
  onDoneEditing,
  onCancel,
  selectedUserIds,
}) => {
  const { data: projectGcUsers } = useGetProjectEmployeesQuery({
    variables: {
      where: { project_id: { _eq: projectId } },
    },
  });
  //console.log(projectGcUsers.data?.project[0].general_contractor.employees);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ usersToBeNotified: selectedUserIds });
  }, [selectedUserIds]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };
  const employees = projectGcUsers?.project_employee || [];
  return (
    <>
      <Modal
        open={visible}
        title="Edit Users To Be Notified"
        okText="Done"
        cancelText="Cancel"
        cancelButtonProps={{ style: { display: "inline" } }}
        onCancel={handleCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onDoneEditing(values as FormValues);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        {!!employees[0] && (
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{ modifier: "public", userIds: selectedUserIds }}
          >
            <Form.Item name="usersToBeNotified" label="Users">
              <Select
                // loading={loading}
                // disabled={loading}
                style={{ width: "100%" }}
                mode="multiple"
                placeholder="Select users to be notified for this project"
                showSearch
                filterOption={getNormalOptionsFilter}
                options={employees.map((p) => ({
                  value: p.employee.user.id,
                  label: p.employee.user.name,
                }))}
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default EditUsersForNotificationModal;
