import { PermitSettingsComponentProps } from "../../utils/PermitSettingsTypes";
import OnlySubmitterSignPermitCheckbox from "./basic/OnlySubmitterSignPermitCheckbox";
import ShowPermitChecklistsTables from "./basic/ShowPermitChecklistsTables";

const InteriorPenetrationSettings: React.FC<PermitSettingsComponentProps> = ({
  gcPermitCheckListItems,
  queryVariables,
  data,
  project,
  checklistGc,
  mainGc,
}) => {
  return (
    <>
      <OnlySubmitterSignPermitCheckbox
        {...{
          project_setting: project?.project_setting,
          mainGc,
          type: "interior_penetration",
        }}
      />
      <ShowPermitChecklistsTables
        {...{
          checklistGc,
          data,
          project,
          gcPermitCheckListItems,
          queryVariables,
          checklistItemsType: "interior_penetration",
        }}
      />
    </>
  );
};
export default InteriorPenetrationSettings;
