import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import SCReportsPTPSingle from "./components/SCReportsPTPSingle";
import SCReportsPTPTable from "./components/SCReportsPTPTable";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";

interface SCReportsPTPProps {}

const SCReportsPTP: React.FunctionComponent<SCReportsPTPProps> = (props) => {
  const [searchParams] = useSearchParams();
  const { projectId } = useParams();
  const subcontractorId = useSubcontractorId();

  const reportId = searchParams.get("reportId");

  return subcontractorId ? (
    reportId ? (
      <SCReportsPTPSingle {...{ reportId, subcontractorId, projectId }} />
    ) : (
      <SCReportsPTPTable {...{ subcontractorId, projectId }} />
    )
  ) : null;
};

export default SCReportsPTP;
