const compareTwoLists = (list1: Array<string>, list2: Array<string>) => {
  list1.sort(); //values.something   (newIds)
  list2.sort(); //currentIds

  const toBeDeleted = [];
  const toBeInserted = [];
  let i = 0,
    j = 0;

  while (i < list1.length || j < list2.length) {
    if (i >= list1.length) {
      toBeDeleted.push(list2[j]);
      j++;
    } else if (j >= list2.length) {
      toBeInserted.push(list1[i]);
      i++;
    } else {
      let x = list1[i].localeCompare(list2[j]);
      if (x === 0) {
        i++;
        j++;
      } else if (x === -1) {
        toBeInserted.push(list1[i]);
        i++;
      } else {
        toBeDeleted.push(list2[j]);
        j++;
      }
    }
  }

  return [toBeInserted, toBeDeleted];
};
export default compareTwoLists;
