import React, { useContext, useEffect, useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { Select } from "antd";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsTradeProps
  extends SiteOrientationStepProps {
  suggestedTrades: Array<{
    id: string;
    en: string;
    es: string;
  }>;
  otherTrades: Array<{
    id: string;
    en: string;
    es: string;
  }>;
  oacTrades: Array<{
    id: string;
    name: string;
  }>;
  employeeTrades: Array<{
    id: string;
    name: string;
  }>;
}

const SiteOrientationInPersonWorkerStepsTrade: React.FC<
  SiteOrientationInPersonWorkerStepsTradeProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();
  const [trade, setTrade] = useState(workerContext.orientee?.title?.id || "");
  useEffect(() => {
    if (workerContext.orientee?.title?.id) {
      if (
        workerContext.orientee.userType === "worker" &&
        !props.suggestedTrades.find(
          (s) => s.id === workerContext.orientee?.title?.id,
        ) &&
        !props.otherTrades.find(
          (s) => s.id === workerContext.orientee?.title?.id,
        )
      ) {
        setTrade("");
      } else if (
        workerContext.orientee.userType === "worker" &&
        !props.suggestedTrades.find(
          (s) => s.id === workerContext.orientee?.title?.id,
        ) &&
        !props.otherTrades.find(
          (s) => s.id === workerContext.orientee?.title?.id,
        )
      ) {
        setTrade("");
      }
    }
  }, []);
  const langStrings = useCurrentLangStrings();
  const onEnter = (pickedTradeId?: string) => {
    const pickedVal = pickedTradeId ?? trade;
    if (workerContext.orientee?.userType === "worker") {
      const selectedTrade =
        props.suggestedTrades.find((f) => f.id === pickedVal) ??
        props.otherTrades.find((f) => f.id === pickedVal);
      if (selectedTrade) {
        workerContext.updateOrientee((user) =>
          user
            ? {
                ...user,
                title: { id: selectedTrade.id, name: selectedTrade.en },
              }
            : { title: { id: selectedTrade.id, name: selectedTrade.en } },
        );
      }
    } else {
      const selectedOacTrade = props.oacTrades.find((s) => s.id === pickedVal);
      const selectedEmployeeTrade = props.employeeTrades.find(
        (s) => s.id === pickedVal,
      );
      workerContext.updateOrientee((user) => {
        if (!user || user.userType !== "oacUser") return null;
        return {
          ...user,
          title: selectedOacTrade ?? selectedEmployeeTrade,
          oacTitleId: selectedOacTrade?.id,
          employeeTitleId: selectedEmployeeTrade?.id,
        };
      });
    }
    props.onNext();
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={() => {
        !!trade && onEnter(trade);
      }}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!trade.length}
    >
      <SiteFeatureStepsInputLayout
        headline={
          workerContext.orientee?.userType === "oacUser"
            ? langStrings.strings.whatisYourTitle
            : langStrings.strings.whatIsYourTrade
        }
      >
        <Select
          className="text-1"
          virtual={false}
          onSelect={(val) => {
            if (typeof val.valueOf() === "string") {
              setTrade(val.valueOf());
              onEnter(val);
            }
          }}
          value={trade}
          placeholder={langStrings.strings.selectYourTrade}
        >
          {workerContext.orientee?.userType === "oacUser" ? (
            workerContext.orientee.oacCompanyId ? (
              props.oacTrades.map((e) => (
                <Select.Option key={e.id} value={e.id} className="text-1">
                  {e.name}
                </Select.Option>
              ))
            ) : (
              props.employeeTrades.map((e) => (
                <Select.Option key={e.id} value={e.id} className="text-1">
                  {e.name}
                </Select.Option>
              ))
            )
          ) : props.suggestedTrades.length > 0 ? (
            <>
              <Select.OptGroup key={1} label={langStrings.strings.suggested}>
                {props.suggestedTrades.map((e) => (
                  <Select.Option key={e.id} value={e.id} className="text-1">
                    {langStrings.options.workerTrades[e.id] ?? e.en}
                  </Select.Option>
                ))}
              </Select.OptGroup>
              <Select.OptGroup key={2} label={langStrings.strings.otherTrades}>
                {props.otherTrades.map((e) => (
                  <Select.Option key={e.id} value={e.id} className="text-1">
                    {langStrings.options.workerTrades[e.id] ?? e.en}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            </>
          ) : (
            props.otherTrades.map((e) => (
              <Select.Option key={e.id} value={e.id} className="text-1">
                {langStrings.options.workerTrades[e.id] ?? e.en}
              </Select.Option>
            ))
          )}
        </Select>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerStepsTrade;
