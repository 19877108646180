import { Checkbox } from "antd";
import StyledContent from "src/common/components/layouts/StyledContent";
import {
  GetGcPermitChecklistItemsQuery,
  useUpdateGeneralContractorForPermitMutation,
  useUpdateProjectSettingByPkForPermitMutation,
} from "src/common/types/generated/apollo/graphQLTypes";

const OnlySubmitterSignPermitCheckbox: React.FC<{
  project_setting?: GetGcPermitChecklistItemsQuery["project"][number]["project_setting"];
  type:
    | "historical_access"
    | "loto"
    | "confined_space"
    | "work_above"
    | "hot_work"
    | "dig"
    | "dig_excavation"
    | "interior_penetration"
    | "steel_erection"
    | "out_of_basket";
  mainGc: GetGcPermitChecklistItemsQuery["general_contractor"][number];
  label?: string;
}> = ({ mainGc, type, project_setting, label }) => {
  const [updateGeneralContractor] =
    useUpdateGeneralContractorForPermitMutation();
  const field = `only_submitter_sign_the_${type}_permit` as const;
  const [updateProjectSetting] = useUpdateProjectSettingByPkForPermitMutation();
  const settingUseLevel = project_setting?.permit_setting_level;
  return (
    <StyledContent>
      <Checkbox
        disabled={settingUseLevel !== "project" && !!project_setting}
        checked={
          project_setting && settingUseLevel === "project"
            ? project_setting[field]
            : mainGc[field]
        }
        onChange={(e) => {
          const checked = e.target.checked;
          if (project_setting && settingUseLevel === "project") {
            updateProjectSetting({
              variables: {
                project_id: project_setting.project_id,
                _set: { [field]: checked },
              },
              optimisticResponse: {
                update_project_setting_by_pk: {
                  ...project_setting,
                  [field]: checked,
                },
              },
            });
          } else {
            updateGeneralContractor({
              variables: { gcId: mainGc.id, _set: { [field]: checked } },
              optimisticResponse: {
                update_general_contractor_by_pk: {
                  ...mainGc,
                  [field]: checked,
                },
              },
            });
          }
        }}
      >
        {label ||
          "Only require the person submitting the permit (foreman/competent person) to sign. When this setting is OFF all workers involved in the work are required to sign"}
      </Checkbox>
    </StyledContent>
  );
};
export default OnlySubmitterSignPermitCheckbox;
