import React, { useContext, useState } from "react";
import SiteFeatureStepsInputLayout from "../../../../../../../../../common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { Select } from "antd";
import dayjs from "dayjs";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsAgeProps
  extends SiteOrientationStepProps {}

const SiteOrientationInPersonWorkerStepsAge: React.FC<
  SiteOrientationInPersonWorkerStepsAgeProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();
  const user = workerContext.orientee;
  let defaultAge: null | number | undefined = null;
  if (user && user.userType === "worker" && user.age) {
    defaultAge = user.age.new?.val ?? user.age.old?.val;
  }
  const ageOptions: number[] = [];
  const [age, setAge] = useState<number | undefined>(defaultAge ?? undefined);
  for (let i = 14; i <= 100; i++) ageOptions.push(i);
  const langStrings = useCurrentLangStrings();
  const onEnter = async (pickedAge?: number) => {
    workerContext.updateOrientee((u) =>
      u?.userType === "worker"
        ? {
            ...u,
            age: {
              old: u.age?.old,
              new: { val: pickedAge ?? age, enteredAt: dayjs() },
            },
          }
        : null,
    );
    props.onNext();
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!age}
    >
      <SiteFeatureStepsInputLayout headline={langStrings.strings.whatIsYourAge}>
        <Select
          value={age}
          onChange={(e) => {
            setAge(e);
            onEnter(e);
          }}
          className="text-semantic-inactive-dark z-20"
          placeholder={langStrings.strings.selectFromTheList}
        >
          {ageOptions.map((ageOption) => (
            <Select.Option value={ageOption} key={ageOption}>
              {ageOption}{" "}
            </Select.Option>
          ))}
        </Select>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerStepsAge;
