type OrderGetter = (index: number) => number;
export type ChangeOrder = {
  index: number;
  order: number;
};

export function moveItem<T>(
  list: Array<T>,
  srcIndex: number,
  dstIndex: number
): Array<T> {
  const item = list[srcIndex];
  const newItems = list.filter((_, i) => i !== srcIndex);
  newItems.splice(dstIndex, 0, item);
  return newItems;
}

export const updateSortOrder = (
  length: number,
  dstIndex: number,
  getOrder: OrderGetter
): Array<ChangeOrder> => {
  //  console.log("initial order: ", Array(length).fill(0).map((v, i) => getOrder(i)));
  const prev_order = dstIndex > 0 ? getOrder(dstIndex - 1) : 0;
  let p = dstIndex + 1;
  while (p < length && prev_order + p - dstIndex >= getOrder(p)) p++;
  const gaps = p - dstIndex + 1;
  const last_order = p < length ? getOrder(p) : prev_order + 10000 * gaps;
  const result = [];
  for (let i = dstIndex; i < p; i++) {
    const order =
      (prev_order + ((last_order - prev_order) * (i - dstIndex + 1)) / gaps) |
      0;
    result.push({ index: i, order });
  }
  return result;
};
//example
type TestRecord = {
  id?: string;
  sort_index?: number;
};

// type MoveStatistics = {
//   moveCount: number;
//   orderChangeCount: number;
// };

function applySortOrder<T extends TestRecord>(
  items: Array<T>,
  changes: Array<ChangeOrder>
): Array<T> {
  const result = [...items];
  changes.forEach(
    (v) => (result[v.index] = { ...result[v.index], sort_index: v.order })
  );
  return result;
}

export function reorderArrayInt<T extends TestRecord>(
  items: Array<T>,
  fromIndex: number,
  toIndex: number
  //   stat: MoveStatistics
): Array<T> {
  const newItems = moveItem(items, fromIndex, toIndex);
  const orderChanges = updateSortOrder(
    newItems.length,
    toIndex,
    (index) => newItems[index].sort_index ?? 0
  );
  //   stat.moveCount++;
  //   stat.orderChangeCount += orderChanges.length;
  return applySortOrder<T>(newItems, orderChanges);
}
