import * as React from "react";
import { Outlet } from "react-router-dom";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";

export interface GCSettingsProps {}

const GCSettings: React.FunctionComponent<GCSettingsProps> = (props) => {
  return (
    <div className="w-full h-full p-2 bg-white rounded-2">
      <div className="flex flex-col items-center justify-center w-full h-full col-span-1 col-start-1 overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default withCustomSuspense(GCSettings);
