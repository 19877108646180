import React, { useRef } from "react";
import VisitorSpreadsheetModal, {
  VisitorSpreadsheetModalRef,
} from "src/domain-features/sitesafety/entryRoutes/gcProjectReports/routes/visitor/components/VisitorSpreadsheetModal";
import { useGenerateVisitorSpreadsheetMutation } from "src/common/types/generated/apollo/graphQLTypes";
import ProjectProps from "src/common/types/manual/ProjectProps";
import { graphql } from "babel-plugin-relay/macro";
import VisitorsTable from "src/domain-features/sitesafety/entryRoutes/gcProjectReports/routes/visitor/components/VisitorsTable";
import { IconDownload, IconSettings } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import { useLazyLoadQuery } from "react-relay/hooks";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import { GCReportsNavigationQuery } from "src/common/types/generated/relay/GCReportsNavigationQuery.graphql";
import { reportsNavigationQuery } from "../../../GCReportsNavigation";
import useAuthUser from "src/common/hooks/useAuthUser";

const GCProjectReportVisitors: React.FunctionComponent<ProjectProps> = ({
  projectId,
}) => {
  const emailOpen = useRef<VisitorSpreadsheetModalRef>(null);
  const [visitorSpreadsheet] = useGenerateVisitorSpreadsheetMutation();
  const navigate = useNavigate();
  const handelSettingsButtonClick = () => {
    navigate("settings");
  };
  const authUser = useAuthUser();
  const handelDownloadButtonClick = () => {
    emailOpen.current?.open();
  };
  const data = useLazyLoadQuery<GCReportsNavigationQuery>(
    reportsNavigationQuery,
    { projectId: projectId, empId: authUser.uid },
    { fetchPolicy: "store-or-network" },
  );

  //todo put here reports and settings
  return (
    <>
      {data.project_connection.edges[0].node
        .is_visitor_log_and_tracking_active ? (
        <>
          <VisitorSpreadsheetModal
            ref={emailOpen}
            onCancel={() => emailOpen.current?.close()}
            onCreate={async (values) => {
              emailOpen.current?.close();

              const { data: visitorData } = await visitorSpreadsheet({
                variables: {
                  input: {
                    projectId,
                    startAt: values.startDate.startOf("day").toString(),
                    endAt: values.endDate.endOf("day").toString(),
                  },
                },
              });

              if (visitorData?.generateVisitorSpreadsheet) {
                downloadFromUrl(visitorData.generateVisitorSpreadsheet);
              }
            }}
          />

          <VisitorsTable
            topBarButtons={[
              {
                secondary: true,
                onClick: handelSettingsButtonClick,
                icon: IconSettings,
              },
              {
                secondary: true,
                onClick: handelDownloadButtonClick,
                icon: IconDownload,
                label: "Download data in excel",
              },
            ]}
            showDateRangeFilter={true}
            projectId={projectId}
            onRowClick={(pk) => {
              // props.openRow({ reportId: pk });
            }}
            where={{
              project_id: {
                _eq: projectId,
              },
            }}
          />
        </>
      ) : (
        <div className="flex justify-center mt-3">
          <h1>
            Turn on the visitor log and tracking from the project settings
          </h1>
        </div>
      )}
    </>
  );
};

export default GCProjectReportVisitors;
