import React from "react";

import SiteFeatureLayoutBody from "src/common/components/layouts/steps-navigation-layout/SiteFeatureLayoutBody";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import withSiteFeatureNavigationWrapper from "src/common/components/layouts/steps-navigation-layout/withSiteFeatureNavigationWrapper";
import SelectLanguage from "../remote/components/SelectLanguage";
import {
  useSiteFeatureStepsNavigationDirection,
  useSiteFeatureStepsNavigationEffect,
} from "../../utils/siteOrientationRegistrationNavigationHooks";
import QuizQuestions from "./QuizQuestions";
import { withLanguageProvider } from "../../../../utility-features/i18n/context/languageContext";
import { AVAILABLE_LANGUAGES } from "../../../../utility-features/i18n/languages";
import { useCurrentLanguageState } from "../../../../utility-features/i18n/context/languageHooks";

const QuizQuestionsView: React.FunctionComponent = () => {
  const [language, setLanguage] = useCurrentLanguageState();
  const { goToNextStep, goToPrevStep, nextStepDirection, clearStepDirection } =
    useSiteFeatureStepsNavigationDirection();

  const steps = [
    {
      id: "language",
      title: "Select Language",
      body: (
        <SiteFeatureStepLayout onNextButtonClick={goToNextStep}>
          <SelectLanguage
            onLanguageChange={(newLang) => {
              setLanguage(newLang);
            }}
            language={language}
          />
        </SiteFeatureStepLayout>
      ),
    },
    {
      id: "questions",
      title: "Questions",
      body: (
        <SiteFeatureStepLayout onBackButtonClick={goToPrevStep}>
          <QuizQuestions lang={language} />
        </SiteFeatureStepLayout>
      ),
    },
  ];
  const { currentStepId } = useSiteFeatureStepsNavigationEffect(
    nextStepDirection,
    clearStepDirection,
    steps
  );
  return (
    <SiteFeatureLayoutBody
      steps={steps}
      currentStepId={currentStepId || undefined}
      languagePicker={{
        availableLangs: AVAILABLE_LANGUAGES,
        selectedLang: language,
        onLangeChange: setLanguage,
      }}
    ></SiteFeatureLayoutBody>
  );
};
export default withSiteFeatureNavigationWrapper(
  withLanguageProvider(QuizQuestionsView)
);
