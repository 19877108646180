import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useLazyLoadQuery } from "react-relay/hooks";
import {
  useGetSiteOrientationInPersonWorkerListQuery,
  useGetSiteOrientationInPersonWorkerListQuery$variables,
} from "src/common/types/generated/relay/useGetSiteOrientationInPersonWorkerListQuery.graphql";

const useGetSiteOrientationInPersonWorkerList = (
  variables: Omit<
    useGetSiteOrientationInPersonWorkerListQuery$variables,
    "now" | "universalOrientationValidDate"
  >,
) => {
  const data = useLazyLoadQuery<useGetSiteOrientationInPersonWorkerListQuery>(
    graphql`
      query useGetSiteOrientationInPersonWorkerListQuery(
        $projectId: uuid!
        $now: timestamptz!
        $projectEmployeeWhere: project_employee_bool_exp!
        $projectWorkerWhere: project_worker_bool_exp!
        $universalOrientationValidDate: timestamptz!
      ) {
        project_connection(where: { id: { _eq: $projectId } }) {
          edges {
            node {
              pk: id @__clientField(handle: "pk")
              name
              assign_hard_hat
              agc_universal_orientation
              in_person_orientation
              automatically_assign_hard_hat
              next_hard_hat_number
              linked_orientation_projects {
                pk: id @__clientField(handle: "pk")
              }
              general_contractors {
                general_contractor {
                  name
                }
              }
              address {
                city
                line_1
                line_2
                state_code
                zip_code
              }
            }
          }
        }
        orientation_connection(
          where: {
            deleted_at: { _is_null: true }
            general_contractor: { projects: { id: { _eq: $projectId } } }
            _or: [
              { project_id: { _is_null: true } }
              { project_id: { _eq: $projectId } }
            ]
          }
          order_by: { created_at: desc }
        ) {
          edges {
            node {
              id
            }
          }
        }
        universalOrientations: orientation_connection(
          where: {
            deleted_at: { _is_null: true }
            type: { _eq: "universal" }
            project_orientations: {
              required_by_all_workers: { _eq: true }
              project_id: { _is_null: true }
            }
            general_contractor_id: { _is_null: true }
          }
          order_by: { created_at: desc }
        ) {
          edges {
            node {
              id
              created_at
              duration_valid
              projectSpecificSetting: project_orientations(
                where: { project_id: { _eq: $projectId } }
              ) {
                hide_but_give_credit
                play_during_in_person
              }
            }
          }
        }
        corporateOrientation: orientation_connection(
          where: {
            type: { _eq: "corporate" }
            deleted_at: { _is_null: true }
            project_orientations: {
              required_by_all_workers: { _eq: true }
              project_id: { _eq: $projectId }
            }
            general_contractor: { projects: { id: { _eq: $projectId } } }
          }
          order_by: { created_at: desc }
        ) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              created_at
              duration_valid
              project_orientations(where: { project_id: { _eq: $projectId } }) {
                hide_but_give_credit
                play_during_in_person
              }
              orientation_results(
                where: {
                  _and: [
                    {
                      _or: [
                        { status: { _eq: "completed" } }
                        { completed_at: { _is_null: false } }
                      ]
                    }
                    {
                      _or: [
                        { expired_at: { _gte: $now } }
                        { expired_at: { _is_null: true } }
                      ]
                    }
                  ]
                  user: {
                    _or: [
                      {
                        worker: {
                          worker_projects: { project_id: { _eq: $projectId } }
                        }
                      }
                      {
                        employee: {
                          employee_projects: { project_id: { _eq: $projectId } }
                        }
                      }
                    ]
                  }
                }
              ) {
                completed_at
                user_id
                project {
                  name
                }
                status
              }
            }
          }
        }
        project_employee_connection(
          where: $projectEmployeeWhere
          order_by: [
            { employee: { user: { name: asc } } }
            { user_orientation: { in_person_orientated_at: asc_nulls_first } }
          ]
        ) {
          edges {
            node {
              pk: id @__clientField(handle: "pk")
              hard_hat_number
              project_id
              employee_id
              user_orientation {
                selected
                in_person_orientated_at
                orientated_at
                signature_id
                orientation_provided_by_user_id
              }
              employee {
                user {
                  name
                  profile_picture {
                    url
                    sm_url
                  }
                  universal_orientations(
                    where: {
                      universal_orientated_at: {
                        _gte: $universalOrientationValidDate
                      }
                    }
                    order_by: { universal_orientated_at: desc }
                    limit: 1
                  ) {
                    id
                    universal_orientated_at
                  }
                }
                employee_title {
                  name {
                    en
                  }
                }
                general_contractor_id
                general_contractor {
                  name
                }
                title_id
                oac_title_id
                oac_title {
                  name
                }
                oac_company_id
                oac_company {
                  name
                }
              }
            }
          }
        }
        project_worker_connection(
          where: $projectWorkerWhere
          order_by: [
            { user: { name: asc } }
            { user_orientation: { in_person_orientated_at: asc_nulls_first } }
          ]
        ) {
          edges {
            node {
              pk: id @__clientField(handle: "pk")
              worker_id
              project_id
              archived_at
              hard_hat_number
              worker_role
              title {
                translation {
                  en
                }
              }
              user {
                name
                profile_picture {
                  url
                  sm_url
                }
                universal_orientations(
                  where: {
                    universal_orientated_at: {
                      _gte: $universalOrientationValidDate
                    }
                  }
                  order_by: { universal_orientated_at: desc }
                  limit: 1
                ) {
                  id
                  universal_orientated_at
                }
              }
              worker {
                subcontractor_id
                subcontractor {
                  name
                }
                certificates_to_verify(
                  where: {
                    document: { _eq: "profilePhoto" }
                    status: { _eq: "submitted" }
                    verified_at: { _is_null: true }
                  }
                  order_by: { created_at: desc }
                  limit: 1
                ) {
                  front_image {
                    url
                  }
                }
                current_worker_role
                worker_title_id
                worker_title {
                  translation {
                    en
                  }
                }
              }
              user_orientation {
                pk: id @__clientField(handle: "pk")
                orientated_at
                in_person_orientated_at
                signature_id
                orientation_provided_by_user_id
                in_person_signature_id
                selected
              }
            }
          }
        }
      }
    `,
    {
      ...variables,
      now: dayjs().endOf("day").toISOString(),
      universalOrientationValidDate: dayjs()
        .subtract(11, "months")
        .startOf("d")
        .toISOString(),
    },
    { fetchPolicy: "network-only" },
  );
  return data;
};
export default useGetSiteOrientationInPersonWorkerList;
