/**
 * @generated SignedSource<<3e7d8d827b7bf8fa6ed26506da447697>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SCProjectsCreateProjectQuery$variables = {
  subId: string;
};
export type SCProjectsCreateProjectQuery$data = {
  readonly general_contractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly agc_universal_orientation: boolean;
        readonly gc_orientations: ReadonlyArray<{
          readonly pk: string;
        }>;
        readonly gc_projects: ReadonlyArray<{
          readonly project: {
            readonly name: string;
            readonly pk: string;
          };
        }>;
        readonly name: string;
        readonly pk: string;
        readonly require_dob: boolean;
        readonly require_phone: boolean;
      };
    }>;
  };
  readonly state_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly code: string;
        readonly name: string;
      };
    }>;
  };
};
export type SCProjectsCreateProjectQuery = {
  response: SCProjectsCreateProjectQuery$data;
  variables: SCProjectsCreateProjectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subId"
  }
],
v1 = {
  "name": "asc"
},
v2 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": (v1/*: any*/)
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "require_dob",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "require_phone",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agc_universal_orientation",
  "storageKey": null
},
v8 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "type": {
        "_eq": "corporate"
      }
    }
  }
],
v10 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "project": (v1/*: any*/)
    }
  },
  {
    "fields": [
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "_eq",
                        "variableName": "subId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "subcontractor_id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "project_subcontractors"
              }
            ],
            "kind": "ObjectValue",
            "name": "project"
          }
        ],
        "kind": "ObjectValue",
        "name": "_not"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SCProjectsCreateProjectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "stateConnection",
        "kind": "LinkedField",
        "name": "state_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "stateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "state",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "state_connection(order_by:{\"name\":\"asc\"})"
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "general_contractorConnection",
        "kind": "LinkedField",
        "name": "general_contractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "orientation",
                    "kind": "LinkedField",
                    "name": "gc_orientations",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": "gc_orientations(where:{\"type\":{\"_eq\":\"corporate\"}})"
                  },
                  {
                    "alias": null,
                    "args": (v10/*: any*/),
                    "concreteType": "general_contractor_project",
                    "kind": "LinkedField",
                    "name": "gc_projects",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "general_contractor_connection(order_by:{\"name\":\"asc\"})"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SCProjectsCreateProjectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "stateConnection",
        "kind": "LinkedField",
        "name": "state_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "stateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "state",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "state_connection(order_by:{\"name\":\"asc\"})"
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "general_contractorConnection",
        "kind": "LinkedField",
        "name": "general_contractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "orientation",
                    "kind": "LinkedField",
                    "name": "gc_orientations",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": "gc_orientations(where:{\"type\":{\"_eq\":\"corporate\"}})"
                  },
                  {
                    "alias": null,
                    "args": (v10/*: any*/),
                    "concreteType": "general_contractor_project",
                    "kind": "LinkedField",
                    "name": "gc_projects",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "general_contractor_connection(order_by:{\"name\":\"asc\"})"
      }
    ]
  },
  "params": {
    "cacheID": "f910977c54c02ebf5794954d93233540",
    "id": null,
    "metadata": {},
    "name": "SCProjectsCreateProjectQuery",
    "operationKind": "query",
    "text": "query SCProjectsCreateProjectQuery(\n  $subId: uuid!\n) {\n  state_connection(order_by: {name: asc}) {\n    edges {\n      node {\n        name\n        code\n        id\n      }\n    }\n  }\n  general_contractor_connection(order_by: {name: asc}) {\n    edges {\n      node {\n        name\n        require_dob\n        require_phone\n        agc_universal_orientation\n        pk: id\n        gc_orientations(where: {type: {_eq: \"corporate\"}}) {\n          pk: id\n          id\n        }\n        gc_projects(where: {_not: {project: {project_subcontractors: {subcontractor_id: {_eq: $subId}}}}}, order_by: {project: {name: asc}}) {\n          project {\n            name\n            pk: id\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "92a3613b97c8f4ccf42e36d3a44c7d10";

export default node;
