import { useLocation, useResolvedPath } from "react-router-dom";

const useActiveRoute = (href: string) => {
  const resolvePath = useResolvedPath(href);

  const location = useLocation();

  return location.pathname.startsWith(resolvePath.pathname);
};

export default useActiveRoute;
