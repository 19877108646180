import { useContext } from "react";
import SiteOrientationOrienteeContext from "src/domain-features/siteorientation/utils/siteOrientationOrienteeContext";

const useSiteOrientationOrientee = () => {
  const value = useContext(SiteOrientationOrienteeContext);
  if (!value)
    throw new Error(
      "useSiteOrientationOrientee must be wrapped inside SiteOrientationOrienteeContextProvider",
    );
  return value;
};

export default useSiteOrientationOrientee;

export const useSiteOrientationOrienteeUser = () => {
  const context = useSiteOrientationOrientee();
  return [context.orientee, context.updateOrientee] as const;
};
