/**
 * @generated SignedSource<<2806729778bfac8dc6729ef261ee5046>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AGCEmployeeQuery$variables = {
  agcCompanyId: string;
};
export type AGCEmployeeQuery$data = {
  readonly agc_employee: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly uid: string;
      };
    }>;
  };
  readonly general_contractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
        readonly projects: ReadonlyArray<{
          readonly completed: boolean;
          readonly id: string;
        }>;
      };
    }>;
  };
  readonly orientation_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly created_at: string;
        readonly id: string;
        readonly name: string;
        readonly pk: string;
        readonly project_id: string | null | undefined;
        readonly project_orientations: ReadonlyArray<{
          readonly hide_but_give_credit: boolean;
          readonly id: string;
          readonly orientation_id: string;
          readonly pk: string;
          readonly play_during_in_person: boolean;
          readonly play_during_registration: boolean;
          readonly project_id: string | null | undefined;
          readonly required_by_all_workers: boolean;
        }>;
        readonly type: string;
        readonly user: {
          readonly employee: {
            readonly general_contractor: {
              readonly name: string;
            };
          } | null | undefined;
          readonly name: string;
        };
      };
    }>;
  };
  readonly orientation_qr_code_poster: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly orientation_qr_code_poster_background_url: string | null | undefined;
      };
    }>;
  };
};
export type AGCEmployeeQuery = {
  response: AGCEmployeeQuery$data;
  variables: AGCEmployeeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "agcCompanyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "agcCompanyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "agc_employee",
  "args": [
    {
      "fields": [
        {
          "fields": (v1/*: any*/),
          "kind": "ObjectValue",
          "name": "general_contractor_id"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "general_contractor_employeeConnection",
  "kind": "LinkedField",
  "name": "general_contractor_employee_connection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "general_contractor_employeeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "general_contractor_employee",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uid",
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "fields": [
      {
        "fields": (v1/*: any*/),
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orientation_qr_code_poster_background_url",
  "storageKey": null
},
v6 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "kind": "Literal",
  "name": "where",
  "value": {
    "agc_universal_orientation": {
      "_eq": true
    }
  }
},
v9 = {
  "alias": null,
  "args": [
    (v8/*: any*/)
  ],
  "concreteType": "project",
  "kind": "LinkedField",
  "name": "projects",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed",
      "storageKey": null
    }
  ],
  "storageKey": "projects(where:{\"agc_universal_orientation\":{\"_eq\":true}})"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "name": "asc"
},
v14 = {
  "_is_null": true
},
v15 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": [
      {
        "order": "asc"
      },
      (v13/*: any*/)
    ]
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "deleted_at": (v14/*: any*/),
      "general_contractor_id": (v14/*: any*/),
      "project_id": (v14/*: any*/),
      "type": {
        "_eq": "universal"
      }
    }
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v19 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "created_at": "asc"
    }
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "project_id": (v14/*: any*/)
    }
  }
],
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orientation_id",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hide_but_give_credit",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "play_during_in_person",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "play_during_registration",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "required_by_all_workers",
  "storageKey": null
},
v25 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  },
  {
    "kind": "Literal",
    "name": "order_by",
    "value": (v13/*: any*/)
  },
  (v8/*: any*/)
],
v26 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v27 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AGCEmployeeQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": "orientation_qr_code_poster",
        "args": (v4/*: any*/),
        "concreteType": "general_contractorConnection",
        "kind": "LinkedField",
        "name": "general_contractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "general_contractor_connection",
        "args": null,
        "concreteType": "general_contractorConnection",
        "kind": "LinkedField",
        "name": "__AGCEmployeeQuery_general_contractor_connection_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v15/*: any*/),
        "concreteType": "orientationConnection",
        "kind": "LinkedField",
        "name": "orientation_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "orientationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "orientation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_employee",
                        "kind": "LinkedField",
                        "name": "employee",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "general_contractor",
                            "kind": "LinkedField",
                            "name": "general_contractor",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v19/*: any*/),
                    "concreteType": "project_orientation",
                    "kind": "LinkedField",
                    "name": "project_orientations",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v6/*: any*/),
                      (v20/*: any*/),
                      (v18/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/)
                    ],
                    "storageKey": "project_orientations(order_by:{\"created_at\":\"asc\"},where:{\"project_id\":{\"_is_null\":true}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "orientation_connection(order_by:[{\"order\":\"asc\"},{\"name\":\"asc\"}],where:{\"deleted_at\":{\"_is_null\":true},\"general_contractor_id\":{\"_is_null\":true},\"project_id\":{\"_is_null\":true},\"type\":{\"_eq\":\"universal\"}})"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AGCEmployeeQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": "orientation_qr_code_poster",
        "args": (v4/*: any*/),
        "concreteType": "general_contractorConnection",
        "kind": "LinkedField",
        "name": "general_contractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v25/*: any*/),
        "concreteType": "general_contractorConnection",
        "kind": "LinkedField",
        "name": "general_contractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": "general_contractor_connection(first:10000,order_by:{\"name\":\"asc\"},where:{\"agc_universal_orientation\":{\"_eq\":true}})"
      },
      {
        "alias": null,
        "args": (v25/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "AGCEmployeeQuery_general_contractor_connection",
        "kind": "LinkedHandle",
        "name": "general_contractor_connection"
      },
      {
        "alias": null,
        "args": (v15/*: any*/),
        "concreteType": "orientationConnection",
        "kind": "LinkedField",
        "name": "orientation_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "orientationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "orientation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v7/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_employee",
                        "kind": "LinkedField",
                        "name": "employee",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "general_contractor",
                            "kind": "LinkedField",
                            "name": "general_contractor",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v19/*: any*/),
                    "concreteType": "project_orientation",
                    "kind": "LinkedField",
                    "name": "project_orientations",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v20/*: any*/),
                      (v18/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/)
                    ],
                    "storageKey": "project_orientations(order_by:{\"created_at\":\"asc\"},where:{\"project_id\":{\"_is_null\":true}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "orientation_connection(order_by:[{\"order\":\"asc\"},{\"name\":\"asc\"}],where:{\"deleted_at\":{\"_is_null\":true},\"general_contractor_id\":{\"_is_null\":true},\"project_id\":{\"_is_null\":true},\"type\":{\"_eq\":\"universal\"}})"
      }
    ]
  },
  "params": {
    "cacheID": "2bc95d45514e9e5059e658725de04ead",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "general_contractor_connection"
          ]
        }
      ]
    },
    "name": "AGCEmployeeQuery",
    "operationKind": "query",
    "text": "query AGCEmployeeQuery(\n  $agcCompanyId: uuid!\n) {\n  agc_employee: general_contractor_employee_connection(where: {general_contractor_id: {_eq: $agcCompanyId}}) {\n    edges {\n      node {\n        uid\n        id\n      }\n    }\n  }\n  orientation_qr_code_poster: general_contractor_connection(where: {id: {_eq: $agcCompanyId}}) {\n    edges {\n      node {\n        orientation_qr_code_poster_background_url\n        id\n      }\n    }\n  }\n  general_contractor_connection(where: {agc_universal_orientation: {_eq: true}}, order_by: {name: asc}, first: 10000) {\n    edges {\n      node {\n        id\n        pk: id\n        name\n        projects(where: {agc_universal_orientation: {_eq: true}}) {\n          id\n          completed\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  orientation_connection(where: {deleted_at: {_is_null: true}, general_contractor_id: {_is_null: true}, project_id: {_is_null: true}, type: {_eq: \"universal\"}}, order_by: [{order: asc}, {name: asc}]) {\n    edges {\n      node {\n        id\n        pk: id\n        name\n        type\n        created_at\n        project_id\n        user {\n          name\n          employee {\n            general_contractor {\n              name\n              id\n            }\n            id\n          }\n          id\n        }\n        project_orientations(where: {project_id: {_is_null: true}}, order_by: {created_at: asc}) {\n          id\n          pk: id\n          orientation_id\n          project_id\n          hide_but_give_credit\n          play_during_in_person\n          play_during_registration\n          required_by_all_workers\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a000d453f8968e145e8ce82d2bd22294";

export default node;
