const hslToRgb = (h: number, s: number, l: number) => {
  let r, g, b;

  if (s == 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};
const getBgColorFromPercent = (percentage: number) => {
  // Ensure percentage is between 0 and 100
  if (percentage === 0) return "#FF1818";
  percentage = Math.min(Math.max(percentage, 0), 100);
  // Interpolate hue between yellow (60) and lime green (120)
  let hue: number;
  if (percentage < 100) hue = (85 - 60) * (percentage / 100) + 60;
  else {
    hue = 120;
  }
  // Set saturation and lightness to constant values for a darker shade
  const saturation = 100;
  const lightness = 50;

  // Convert HSL to RGB
  const [r, g, b] = hslToRgb(hue / 360, saturation / 100, lightness / 100);

  // Convert RGB to CSS color string
  return `rgb(${r}, ${g}, ${b})`;
};

export default getBgColorFromPercent;
