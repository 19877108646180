import dayjs from "dayjs";

const daysOfMonth: (month: dayjs.Dayjs) => dayjs.Dayjs[] = (month) => {
  let daysInMonth = month.daysInMonth();
  const arrDays = [];

  for (let dayIndex = 1; dayIndex <= daysInMonth; dayIndex++) {
    const current = month.date(dayIndex);
    arrDays.push(dayjs(current));
  }

  return arrDays;
};

export default daysOfMonth;
