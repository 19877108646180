import { Select } from "antd";
import { memo } from "react";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import {
  useGetCrewSightSubcontractorsQuery,
  useGetProjectSubCrewSightQuery,
  useUpsertCrewSightCompanyMutation,
} from "src/common/types/generated/apollo/graphQLTypes";

const SelectCrewSightCompany: React.FC<{
  projectId: string;
  subId: string;
  projectSubcontractorId: string;
  value: string | undefined;
  options: Array<{ name: string; id: string }>;
}> = memo(({ projectId, subId, projectSubcontractorId, options, value }) => {
  const [upsertCrewSightCompany, { loading }] =
    useUpsertCrewSightCompanyMutation();
  return (
    <Select
      loading={loading}
      className="min-w-24"
      showSearch
      options={options.map((option) => ({ label: option.name, value: option.id }))}
      filterOption={(input, option) => {
        return (
          (option &&
            option.label &&
            option.label
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase())) !== -1
        );
      }}
      value={value}
      onChange={(newValue) => {
        const crewsight_company_id = newValue;
        upsertCrewSightCompany({
          variables: {
            object: {
              project_id: projectId,
              subcontractor_id: subId,
              crewsight_company_id,
            },
          },
          optimisticResponse: {
            insert_crewsight_company_subcontractor_one: {
              project_id: projectId,
              subcontractor_id: subId,
              crewsight_company_id,
            },
          },
          update(cache) {
            cache.modify({
              id: cache.identify({id: projectSubcontractorId, __typename: 'project_subcontractor'}),
              fields: {
                crewsight_company(prevValue) {
                  return {...prevValue,  __typename: 'crewsight_company', crewsight_company_id};
                },
              },
            })
          }
      });
      }}
    />
  );
});
const CrewsightProjectSubSetup: React.FC<{ projectId: string }> = ({
  projectId,
}) => {
  // TODO: use single query with suspence
  const { data: projectSubData, loading: mappingLoading, error: mappingError } = useGetProjectSubCrewSightQuery({
    variables: { projectId },
  });
  const { data: crewSightCompaniesData, loading:subLoading, error: companiesError  } = useGetCrewSightSubcontractorsQuery({
    variables: { input: { projectId } },
  });
  if (mappingError || companiesError) {
    throw mappingError || companiesError;
  }
  return (
    <BaseTable
      title={() => "Subcontractors"}
      loading={ mappingLoading || subLoading }
      columns={[
        {
          title: "Subcontractor",
          key: "subcontractor",
          dataIndex: ["subcontractor", "name"],
        },
        {
          title: "CrewSight Company",
          key: "crewsight_company",
          dataIndex: ["project_id"],
          render: (v, r) => (
            <SelectCrewSightCompany
              projectId={projectId}
              subId={r.subcontractor_id}
              projectSubcontractorId={r.id}
              value={r.crewsight_company?.crewsight_company_id}
              options={crewSightCompaniesData?.getCrewSightSubcontractors || []}
            />
          ),
        },
      ]}
      dataSource={projectSubData?.project_subcontractor || []}
    ></BaseTable>
  );
};
export default CrewsightProjectSubSetup;
