import NestedRoute from "../../../../../../common/types/manual/NestedRoute";
import searchParamsProvider from "../../../../../../common/functions/searchParamsProvider";
import QrProjectSubcontractor from "./components/QrProjectSubcontractor";
import QrProjectSubcontractorTask from "./components/QrProjectSubcontractorTask";
import QrProjectSubcontractorTaskSign from "./components/QrProjectSubcontractorTaskSign";
import React from "react";
import { Outlet } from "react-router-dom";

const subRoute: NestedRoute = {
  element: <Outlet />,
  children: [
    {
      path: ":subcontractorId",
      // Used by qrcode
      element: searchParamsProvider(QrProjectSubcontractor),
    },
    {
      path: ":subcontractorId/t/:taskId",
      element: searchParamsProvider(QrProjectSubcontractorTask),
    },
    {
      path: ":subcontractorId/t/:taskId/signature",
      element: searchParamsProvider(QrProjectSubcontractorTaskSign),
    },
  ],
};

export default subRoute;
