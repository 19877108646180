import React, { FC, useEffect, useState } from "react";
import { message } from "antd";
import StyledContent from "src/common/components/layouts/StyledContent";
import logo from "src/common/assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useUnflagFlaggedTaskMutation } from "src/common/types/generated/apollo/graphQLTypes";

interface UnflagJHAConfirmationProps {
  taskId: string;
}

const UnflagJHAConfirmation: React.FC<UnflagJHAConfirmationProps> = ({
  taskId,
}) => {
  const navigate = useNavigate();
  const [unflagTaskMuation] = useUnflagFlaggedTaskMutation();
  const [Mutation$data, setMutation$data] = useState(true);
  const unFlagTask = async () => {
    try {
      await unflagTaskMuation({ variables: { input: { taskId: taskId } } });
    } catch (err) {
      message.error("Could not UNFLAG the JHA");
      setMutation$data(false);
    }
  };

  useEffect(() => {
    unFlagTask();
  }, []);

  return Mutation$data ? (
    <StyledContent>
      <div
        className="flex items-center justify-center p-1 cursor-pointer"
        onClick={() => navigate("/")}
      >
        <div>
          <img src={logo} className="w-12" />
        </div>
      </div>

      <div className="flex items-center justify-center mt-3 pb-1 text-1.5">
        OK! You’ve unflagged this JHA and will no longer receive emails when its
        used in a PTP.
      </div>
      <div className="flex items-center justify-center pb-2 mt-1 text-xl">
        If you ❤️ SiteForm, please consider leaving a ⭐⭐⭐⭐⭐ Review in your
        App Store. Thanks!
      </div>
    </StyledContent>
  ) : (
    <></>
  );
};

export default UnflagJHAConfirmation;
