import { notification, Typography } from "antd";
import { DisconnectOutlined } from "@ant-design/icons";
import React from "react";
import contacts from "../../../common/constants/contacts";
import { ErrorCauseType } from "../errorHandlingTypes";

const { Text, Paragraph } = Typography;

/**
 * This function is used to show error notifications to the user.
 *
 * @param {string} errorType - The type of error. It can be either "network" or "operational".
 * @param {string} errorTitle - The title of the error. This is optional and defaults to "Unexpected Error Occurred".
 * @param {React.ReactNode} userInstructions - Instructions for the user on how to handle the error. This is optional.
 *
 * Depending on the errorType, the function will display a different error message.
 *
 * If the errorType is "network", it will display a message asking the user to check their internet connection.
 * If the errorType is "operational", it will display a message saying that an unexpected error occurred and that the team has been notified.
 *
 * In both cases, the user is given the option to contact support via email.
 */

const { Link } = Typography;
const showErrorNotification: (config?: {
  errorType?: ErrorCauseType;
  errorTitle?: string;
  userInstructions?: React.ReactNode;
  sentryEventId?: string;
}) => void = (config) => {
  const errorType: ErrorCauseType = config?.errorType || "expected";
  const errorTitle = config?.errorTitle;
  const userInstructions = config?.userInstructions;
  const sentryEventId = config?.sentryEventId;

  switch (errorType) {
    case "network":
      notification.error({
        message: "Network Error",
        description: (
          <Paragraph>
            Please check your internet connection and try again.
            <br />
            If your internet connection is fine, please{" "}
            <Link
              href={`mailto:${contacts.supportEmail}?subject=Web App Issue`}
              target="_blank"
            >
              contact us
            </Link>
            .
          </Paragraph>
        ),
        icon: <DisconnectOutlined />,
      });
      break;
    case "expected":
      notification.warning({
        message: errorTitle ?? "Something Went Wrong",
        description: userInstructions ?? "Please, try again.",
      });
      break;
    case "operational":
    default:
      notification.error({
        message: errorTitle ?? "Unexpected Error Occurred",
        description: userInstructions ?? (
          <Typography>
            <Paragraph>
              We got notified about the error and are working on a fix.
              <br />
              Need immediate help?{" "}
              <Link
                href={`mailto:${contacts.supportEmail}?subject=Web App Issue`}
                target="_blank"
              >
                Contact us
              </Link>
              .
            </Paragraph>
            {!!sentryEventId && (
              <Paragraph>
                <Text type={"secondary"}>Error Id: </Text>
                <Text code>{sentryEventId}</Text>
              </Paragraph>
            )}
          </Typography>
        ),
      });
      break;
  }
};

export default showErrorNotification;
