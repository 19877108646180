/**
 * @generated SignedSource<<df07fbbb209a2127bff3fe80acd4ab60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CrewProjectWorkerOptions_project_worker_connection_frag$data = {
  readonly project_worker_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly hard_hat_number: string | null | undefined;
        readonly id: string;
        readonly leading_project_crews_aggregate: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
        readonly pk: string;
        readonly project_crew: {
          readonly id: string;
          readonly lead_foreman_project_worker: {
            readonly id: string;
            readonly pk: string;
            readonly user: {
              readonly name: string;
            } | null | undefined;
          } | null | undefined;
          readonly name: string;
          readonly pk: string;
        } | null | undefined;
        readonly subcontractor: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        };
        readonly title: {
          readonly translation: {
            readonly en: string;
          };
        } | null | undefined;
        readonly user: {
          readonly email: string | null | undefined;
          readonly id: string;
          readonly name: string;
          readonly phone_number: string | null | undefined;
          readonly pk: string;
          readonly username: string | null | undefined;
        } | null | undefined;
      };
    }>;
  };
  readonly " $fragmentType": "CrewProjectWorkerOptions_project_worker_connection_frag";
};
export type CrewProjectWorkerOptions_project_worker_connection_frag$key = {
  readonly " $data"?: CrewProjectWorkerOptions_project_worker_connection_frag$data;
  readonly " $fragmentSpreads": FragmentRefs<"CrewProjectWorkerOptions_project_worker_connection_frag">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "project_worker_connection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 100000,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectWorkerWhere"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./CrewProjectWorkerOptions_project_worker_connection_frag_refetch.graphql')
    }
  },
  "name": "CrewProjectWorkerOptions_project_worker_connection_frag",
  "selections": [
    {
      "alias": "project_worker_connection",
      "args": null,
      "concreteType": "project_workerConnection",
      "kind": "LinkedField",
      "name": "__CrewProjectWorkerOptions_project_worker_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "project_workerEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "project_worker",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hard_hat_number",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "project_crew_aggregate",
                  "kind": "LinkedField",
                  "name": "leading_project_crews_aggregate",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "project_crew_aggregate_fields",
                      "kind": "LinkedField",
                      "name": "aggregate",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "count",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "worker_title",
                  "kind": "LinkedField",
                  "name": "title",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "text_translation",
                      "kind": "LinkedField",
                      "name": "translation",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "en",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "subcontractor",
                  "kind": "LinkedField",
                  "name": "subcontractor",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "project_crew",
                  "kind": "LinkedField",
                  "name": "project_crew",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "project_worker",
                      "kind": "LinkedField",
                      "name": "lead_foreman_project_worker",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "user",
                          "kind": "LinkedField",
                          "name": "user",
                          "plural": false,
                          "selections": [
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "phone_number",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "username",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "400b987cdd8c52e172b82f1d1b3663c5";

export default node;
