import { DatePicker, Form, Input, notification, Radio, Select } from "antd";
import React, { useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import FormStepBlock from "src/common/components/FormStepBlock";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { GCNewProjectStepType } from "../gcNewProjectStepType";
import { GCAddNewProjectQuery$data } from "src/common/types/generated/relay/GCAddNewProjectQuery.graphql";
import { GCNewProjectStepGeneralMutation } from "src/common/types/generated/relay/GCNewProjectStepGeneralMutation.graphql";
import * as uuid from "uuid";
import dayjs from "dayjs";
import SelectLocalCity from "../component/SelectLocalCity";
// import { GCNewProjectStepGeneral_InsertLocalCity_Mutation } from "src/common/types/generated/relay/GCNewProjectStepGeneral_InsertLocalCity_Mutation.graphql";
import handleRequestError from "src/utility-features/error-handling/handleRequestError";
import AddressFormItems from "../../../hierarchy/component/AddressFormItems";

interface NewProjectGeneralData {
  name: string;
  external_id: string;
  line_1: string;
  zip_code: string;
  city: string;
  state_code: string;
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs;
}

interface GCNewProjectStepGeneralProps extends GCNewProjectStepType {
  onProjectCreated: (projectId: string) => void;
  states: GCAddNewProjectQuery$data["state_connection"];
  userId: string;
  gcData: {
    id: string;
    requirePhone: boolean;
    requireDob: boolean;
    agcUniversalOrientation: boolean;
    corporateOrientations: Array<string>;
  };
}

const GCNewProjectStepGeneral: React.FC<GCNewProjectStepGeneralProps> = ({
  states,
  onProjectCreated,
  userId,
  gcData,
  open,
  goToNext,
}) => {
  const [stateCode, setStateCode] = useState<string>();
  // const [insertLocalCity] =
  //   useAsyncMutation<GCNewProjectStepGeneral_InsertLocalCity_Mutation>(graphql`
  //     mutation GCNewProjectStepGeneral_InsertLocalCity_Mutation(

  //     ) {

  //     }
  //   `);
  const [insertProject] = useAsyncMutation<GCNewProjectStepGeneralMutation>(
    graphql`
      mutation GCNewProjectStepGeneralMutation(
        $projectObject: project_insert_input!
        $projectLocalCityobjects: [project_local_city_insert_input!]!
      ) {
        insert_project_one(object: $projectObject) {
          id
          pk: id @__clientField(handle: "pk")
          completed
          name
          project_employees {
            id
          }
        }
        insert_project_local_city(objects: $projectLocalCityobjects) {
          returning {
            city_id
            city {
              name
            }
          }
        }
      }
    `,
  );

  const [form] = Form.useForm();
  const onNext = async () => {
    const id = uuid.v4();
    return form
      .validateFields()
      .then(
        (values) =>
          insertProject({
            variables: {
              projectObject: {
                general_contractor_id: gcData.id,
                general_contractors: {
                  data: [
                    {
                      general_contractor_id: gcData.id,
                    },
                  ],
                },
                id,
                orientation_project_id: id,
                name: values.name,
                external_id: values.externalId,
                start_date: values.startDate,
                union_labor: values.union,
                require_dob: gcData.requireDob,
                require_phone: gcData.requirePhone,
                agc_universal_orientation: gcData.agcUniversalOrientation,
                end_date: values.endDate,
                project_orientation_settings: {
                  data: gcData.corporateOrientations.map((gcoId) => ({
                    orientation_id: gcoId,
                  })),
                },
                address: {
                  data: {
                    line_1: values.addressLine1,
                    zip_code: values.zip,
                    city: values.city,
                    state_code: values.stateCode,
                  },
                },
                project_employees: {
                  data: [
                    {
                      employee_id: userId,
                    },
                  ],
                },
                // project_local_cities: {
                //   data: values.local_cities.map((ci: string) => ({
                //     city_id: ci,
                //     // project_id: id,
                //   })),
                // },
              },
              //Added here because it was failing check on insertion because we're inserting both local cities and prject employee at the same time
              projectLocalCityobjects: (values.local_cities || []).map(
                (ci: string) => ({
                  city_id: ci,
                  project_id: id,
                }),
              ),
            },
            updater: (store, data) => {
              const project = store.getRootField("insert_project_one");
              const proj_data = data?.insert_project_one;
              if (!proj_data) return;
              const conn = store
                .getRoot()
                .getLinkedRecord("general_contractor_employee_connection", {
                  where: { uid: { _eq: userId } },
                });
              if (conn) {
                const edges = conn.getLinkedRecords("edges");
                if (edges && edges.length > 0) {
                  const node = edges[0].getLinkedRecord("node");
                  if (node) {
                    const emp_projects =
                      node.getLinkedRecords("employee_projects");
                    const proj_employee_get = store.get<any>(
                      proj_data.project_employees[0].id,
                    );
                    console.log("proj_emp", proj_employee_get);
                    const proj_employee =
                      proj_employee_get ??
                      store.create(
                        proj_data.project_employees[0].id,
                        "employee_project",
                      );
                    proj_employee.setLinkedRecord(project, "project");
                    node.setLinkedRecords(
                      [...(emp_projects || []), proj_employee],
                      "employee_projects",
                    );
                  }
                }
              }
            },
          }),
        (error) => {
          console.log("field validation failed", error);
        },
      )
      .then((resp) => {
        const projectId = resp?.insert_project_one?.pk;
        if (projectId) {
          onProjectCreated(projectId);
        } else {
          throw new Error("Project creation failed: empy result");
        }
      })
      .then(goToNext)
      .catch((error: any) => {
        handleRequestError(error, { errorTitle: "Failed to Insert Project" });
      });
  };

  return (
    <FormStepBlock
      dataIsValid={true}
      description="Tell us about your project"
      onNext={onNext}
      nextButtonTitle="Create Project"
      title="General information"
      filled={true}
      open={open}
    >
      <div>
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="name"
            label="Project name"
            rules={[{ required: true, message: "Enter a name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="externalId"
            label="ID"
            rules={[{ required: false, message: "Enter an ID" }]}
          >
            <Input />
          </Form.Item>
          Address
          <AddressFormItems
            onStateChange={(v) => {
              form.setFieldValue("local_cities", undefined);
              setStateCode(v);
            }}
            states={states.edges.map((e) => e.node)}
          />
          <Form.Item
            label="Start Date"
            name="startDate"
            getValueFromEvent={(onChange) =>
              dayjs(onChange).format("YYYY-MM-DD")
            }
            getValueProps={(i) => ({
              value: dayjs(i),
            })}
          >
            <DatePicker format="YYYY-MM-DD" className="w-full" />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="endDate"
            getValueFromEvent={(onChange) =>
              dayjs(onChange).format("YYYY-MM-DD")
            }
            getValueProps={(i) => ({ value: dayjs(i) })}
          >
            <DatePicker
              format="YYYY-MM-DD"
              className="w-full"
              disabledDate={(current) =>
                current.isBefore(dayjs().subtract(1, "day"))
              }
            />
          </Form.Item>
          <Form.Item
            label="Will this project mainly use Union labor?"
            name="union"
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {stateCode && (
            <SelectLocalCity
              label="Tracking Local Residents?  Select the City or Cities considered local"
              stateCode={stateCode}
            />
          )}
        </Form>
      </div>
    </FormStepBlock>
  );
};

export default GCNewProjectStepGeneral;
