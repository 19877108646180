import { Skeleton } from "antd";
import { TableProps } from "antd/es/table";
import dayjs from "dayjs";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { GraphQLTaggedNode } from "react-relay";
import {
  fetchQuery,
  useLazyLoadQuery,
  usePaginationFragment,
  useRefetchableFragment,
  useRelayEnvironment,
} from "react-relay/hooks";
import { CustomFilterType } from "src/common/components/filter/CustomFilterComponents/CustomFilterController";
import getConnectionNodes, {
  Connection,
  GetConnectionNode,
} from "src/common/functions/getConnectionNodes";
import {
  project_employee_bool_exp,
  project_worker_bool_exp,
} from "src/common/types/generated/relay/RequestedJHAViewMutation.graphql";
import { Diff } from "utility-types";
import withCustomSuspense from "../../../../../../../common/components/general/withCustomSuspense";
import ScrollTable, {
  DateRangeFilterState,
  DEFAULT_TABLE_HEADER_HEIGHT,
  FilterData,
  FilterRecord,
  FilterSorterData,
  FilterState,
  InFilterState,
  PkType,
  ScrollTableColumn,
  ScrollTableProps,
  SearchData,
  SearchFilterState,
  SorterData,
  SortOrder,
} from "../../../../../../../common/components/tables/basic/ScrollTable";
import { isDevelopmentBuild } from "../../../../../../../common/constants/environment";
import LargeTableSkeleton from "src/common/components/tables/basic/LargeTableSkeletion";

type AnyQuery<R = any, V = any> = {
  readonly response: R;
  readonly variables: V;
};

export type OrientationDataScrollTableRef = {
  refetch: (silent?: boolean) => void;
};

// GrapthQL filter state builders -----------------------

const buildFieldRef = (dataIndex: ReadonlyArray<string>, value: any) => {
  let result = value;
  for (let i = dataIndex.length - 1; i >= 0; i--) {
    const name = dataIndex[i];
    result = { [name]: result };
  }
  return result;
};

const buildFilterConstraint = (filter: FilterState) => {
  switch (filter.type) {
    case "in_list":
      return { _in: filter.values };
    case "date_range":
      return {
        ...(filter.from ? { _gte: filter.from?.format() } : {}),
        ...(filter.to ? { _lt: filter.to?.format?.() } : {}),
      };
    case "search":
      return { _ilike: `%${filter.text.replace(/[\W_]+/g, "%")}%` };
  }
};

const buildGraphQLFilter = (
  filterClauseCreater: (
    dataIndex: ReadonlyArray<string>,
    obj: any,
  ) => project_employee_bool_exp | project_worker_bool_exp,
  filters?: FilterData,
) => {
  if (!filters) return {};
  return {
    _and: filters.map(
      ({ dataIndex, filter }) =>
        filterClauseCreater(dataIndex, buildFilterConstraint(filter)) ??
        buildFieldRef(dataIndex, buildFilterConstraint(filter)),
    ),
  };
};

const buildGraphQLSearchFilter = (
  filterClauseCreater: (
    dataIndex: ReadonlyArray<string>,
    obj: any,
  ) => project_employee_bool_exp | project_worker_bool_exp,
  searchFilter?: SearchData,
) => {
  if (!searchFilter) return {};

  return {
    _or: searchFilter.fields.map(
      ({ dataIndex }) =>
        filterClauseCreater(dataIndex, {
          _ilike: `%${searchFilter.searchValue}%`,
        }) ??
        buildFieldRef(dataIndex, {
          _ilike: `%${searchFilter.searchValue?.replace?.(/[\W_]+/g, "%")}%`,
        }),
    ),
  };
};
// GraphQL sort order builder  ---------------------------------
export type GraphQLSortOrder =
  | "asc"
  | "asc_nulls_first"
  | "asc_nulls_last"
  | "desc"
  | "desc_nulls_first"
  | "desc_nulls_last";

const sortOrderToGrpaphQL = (order: SortOrder): GraphQLSortOrder => {
  return order === "asc" ? "asc" : "desc";
};

const buildGraphQLSorter = (sorter: SorterData) => {
  const { dataIndex, order } = sorter;
  const sortOrder = sortOrderToGrpaphQL(order);

  return buildFieldRef(
    dataIndex.filter((v) => v !== "aggregate"),
    sortOrder,
  );
};

// ------------------------

//---  helper functions to apply filtering on array data (client side filtering)
//  consider moving it to separate file
type FieldValue = string | number | null;

type DataRecord = {
  [key: string]: DataRecord | FieldValue;
};

type FilterFunction = (record: DataRecord) => boolean;

// getFieldValue returns nested field value, for example
//    GetFieldValue({ report: { name: "SafityReport" }}, ["report", "name"])
// returns "SafityReport"

function getFieldValue(
  record: DataRecord,
  dataIndex: ReadonlyArray<string>,
): DataRecord | FieldValue | undefined {
  let val: any = record;
  for (let name of dataIndex) {
    if (!val) return undefined;
    val = val[name];
  }
  return val;
}

const arrayInFilter =
  (dataIndex: ReadonlyArray<string>, filter: InFilterState) =>
  (record: DataRecord) => {
    const value = getFieldValue(record, dataIndex);
    return filter.values.some((item) => item === value);
  };

const arrayDateRangeFilter =
  (dataIndex: ReadonlyArray<string>, filter: DateRangeFilterState) =>
  (record: DataRecord) => {
    const value = getFieldValue(record, dataIndex);
    // TODO what if `value` doesnt' suit the dayjs input type?
    return (
      !!value &&
      (typeof value === "string" || typeof value === "number") &&
      dayjs(value).isBetween(filter.from, filter.to)
    );
  };

const arraySearchFilter =
  (dataIndex: ReadonlyArray<string>, filter: SearchFilterState) =>
  (record: DataRecord) => {
    const value = getFieldValue(record, dataIndex);
    return `${value}`.indexOf(filter.text) >= 0;
  };

const arrayGetFilterFunc = ({
  dataIndex,
  filter,
}: FilterRecord): FilterFunction => {
  switch (filter.type) {
    case "in_list":
      return arrayInFilter(dataIndex, filter);
    case "date_range":
      return arrayDateRangeFilter(dataIndex, filter);
    case "search":
      return arraySearchFilter(dataIndex, filter);
  }
};

const arrayFilterData = (
  data: Array<DataRecord>,
  filters: FilterData,
): Array<DataRecord> => {
  const filterFuncs = filters.map(arrayGetFilterFunc);
  return filterFuncs.reduce((data, fn) => data.filter(fn), data);

  // or the same logic but in another order. (check what is faster)
  // return data.filter(record => filterFuncs.every(fn => fn(record)));
};

const arrayFilterSearchData = (
  data: Array<DataRecord>,
  filter: SearchData,
): Array<DataRecord> => {
  const { searchValue } = filter;
  if (!searchValue) return data;

  return data.filter((record) =>
    filter.fields.some((field) => {
      const value = getFieldValue(record, field.dataIndex);
      return `${value}`.indexOf(searchValue) >= 0;
    }),
  );
};

const arraySortData = (
  data: Array<DataRecord>,
  sorter: SorterData<DataRecord>,
): Array<DataRecord> => {
  const compareFn =
    sorter.clientCompareFn ??
    ((a, b) => {
      const valueA = getFieldValue(a, sorter.dataIndex);
      const valueB = getFieldValue(b, sorter.dataIndex);
      return !valueA || !valueB
        ? !valueA < !valueB
          ? 1
          : !valueA > !valueB
          ? -1
          : 0
        : valueA < valueB
        ? -1
        : valueA > valueB
        ? 1
        : 0;
    });
  return data.sort((a, b) => {
    const res = compareFn(a, b);
    return sorter.order === "desc" ? -res : res;
  });
};

type LazyLoadState = {
  data: ReturnType<typeof useLazyLoadQuery>;
  loading: boolean;
  error?: any;
  variables?: string;
};

type RelayDataLoadHook = (...a: Parameters<typeof useLazyLoadQuery>) => {
  data: ReturnType<typeof useLazyLoadQuery>;
  loading: boolean;
  error?: any;
};

const useLazyLoadWithoutSuspense: RelayDataLoadHook = (
  query,
  variables,
  config,
) => {
  const stateRef = useRef<LazyLoadState>({ data: null, loading: false });
  const environment = useRelayEnvironment();
  const [_, forceUpdate] = useState(0);

  let vars = JSON.stringify(variables);
  const state = stateRef.current;
  if (state.variables != vars) {
    state.variables = vars;
    state.loading = true;
    fetchQuery(environment, query, variables).subscribe({
      error: (error: any) => {
        state.loading = false;
        state.error = error;
        forceUpdate((v) => v + 1);
      },
      next: (payload) => {
        state.data = payload;
        state.loading = false;
        state.error = undefined;
        forceUpdate((v) => v + 1);
      },
    });
  }
  return { data: state.data, loading: state.loading, error: state.error };
};
const useLazyLoadWithSuspense: RelayDataLoadHook = (
  query,
  variables,
  config,
) => ({ data: useLazyLoadQuery<any>(query, variables), loading: false });

const getLoadHook = (disableSuspense: boolean) =>
  disableSuspense ? useLazyLoadWithoutSuspense : useLazyLoadWithSuspense;

interface BaseOrientationDataScrollTableProps<
  Conn extends Connection<N>,
  ColumnKeys extends string,
  Query extends AnyQuery,
  N = any,
> {
  ref: any;
  title?: string;
  queryNode: GraphQLTaggedNode;
  totalCountNode: GraphQLTaggedNode;
  paginationNode: GraphQLTaggedNode;
  connectionName: string;
  // totalCountConnectionName: keyof Query['response'];
  customFilters?: CustomFilterType[];
  headerComponent?: React.ReactElement;
  totalCountConnectionName: string;
  //  setCurrentTypePermit?: any;
  //currentTypePermit?: string;
  where: Query["variables"]["where"];
  extraQueryVariables?: Partial<Query["variables"]>;
  excludedKeys?: Array<ColumnKeys>;
  loadAll?: boolean;
  defaultTableSort?: {
    key: ColumnKeys;
    order: SortOrder;
  };
  headerContol?: () => React.ReactNode;
  onRowClick?: (item: GetConnectionNode<Conn, N>) => void;
  columns: Array<
    ScrollTableColumn<GetConnectionNode<Conn, N>, ColumnKeys> & {
      queryIncludeVarKey?: keyof Query["variables"];
      filterCreater?: (
        obj: any,
      ) => project_employee_bool_exp | project_worker_bool_exp;
    }
  >;
  disableSuspense?: boolean;
}

export type OrientationDataScrollTableProps<
  Conn extends Connection<N>,
  ColumnKeys extends string,
  Query extends AnyQuery,
  N = any,
> = BaseOrientationDataScrollTableProps<Conn, ColumnKeys, Query, N> &
  Diff<
    TableProps<N>,
    BaseOrientationDataScrollTableProps<Conn, ColumnKeys, Query, N>
  > &
  Diff<
    Omit<ScrollTableProps<any>, "dataSource" | "onChange" | "totalCount">,
    BaseOrientationDataScrollTableProps<Conn, ColumnKeys, Query, N>
  > & {
    newCustomTableLook?: boolean; // TODO - ask Seva to remove this. This option is to control whether to display the new table design or now
  };

export interface OrientationDataScrollTableImplementorProps<
  C extends Connection<N>,
  K extends string,
  Q extends AnyQuery<N, V>,
  R extends any,
  N = any,
  V = any,
> extends Pick<
    OrientationDataScrollTableProps<C, K, Q, N>,
    | "excludedKeys"
    | "loadAll"
    | "where"
    | "defaultTableSort"
    | "title"
    | "searchDataIndex"
    | "headerContol"
    | "disableSuspense"
    | "topBarButtons"
  > {
  onRowClick?: (args: R) => void;
}

type DataTableConnection<Node extends PkType> = Connection<Node>;

const OrientationDataScrollTable = <
  Conn extends Connection<N>,
  ColumnKeys extends string,
  Query extends AnyQuery,
  N = any,
>(
  {
    where,
    columns,
    title,
    queryNode,
    paginationNode,
    connectionName,
    extraQueryVariables,
    totalCountNode,
    totalCountConnectionName,
    onRowClick,
    defaultTableSort,
    loadAll = false,
    disableSuspense = false,
    ...props
  }: OrientationDataScrollTableProps<Conn, ColumnKeys, Query>,
  ref: any,
) => {
  const defaultSortCol = columns.find((c) => !!c.defaultSortOrder)!;

  const [filterSortersData, setFilterSorterData] = useState<FilterSorterData>({
    filterData: [],
    sorterData: defaultSortCol
      ? {
          key: defaultSortCol.key,
          dataIndex: defaultSortCol.dataIndex,
          order: defaultSortCol.defaultSortOrder || null,
        }
      : undefined,
  });
  const filterMap: {
    [key: string]: (
      obj: any,
    ) => project_employee_bool_exp | project_worker_bool_exp;
  } = {};
  columns.forEach((c) => {
    let str = "",
      str2 = "",
      str3 = "";
    c.filters?.dataIndex.forEach((p) => (str += p));
    c.searchDataIndex?.forEach((p) => (str2 += p));
    c.dateRangeSearchIndex?.forEach((p) => (str3 += p));
    if (c.filterCreater) {
      filterMap[str] = c.filterCreater;
      filterMap[str2] = c.filterCreater;
      filterMap[str3] = c.filterCreater;
    }
  });
  const hasSortableCols = columns.find((c) => !!c.sortable);
  const hasDefaultSortCol = columns.find((c) => !!c.defaultSortOrder);
  if (!hasSortableCols) {
    throw new Error("Table must have at least 1 sortable column");
  }

  if (!hasSortableCols && hasDefaultSortCol) {
    throw new Error("Default sort column must have sortable column");
  }
  const filterClauseCreater = (dataIndex: ReadonlyArray<string>, obj: any) => {
    let str = "";
    dataIndex.forEach((p) => (str += p));
    return filterMap[str]?.(obj);
  };
  const filters = [
    ...(filterSortersData?.filterData || []),
    ...columns
      .filter((c) => c.searchValue)
      .map((c) => ({
        key: "",
        dataIndex: c.dataIndex!,
        filter: {
          type: "search",
          text: c.searchValue!,
        },
      })),
  ] as FilterData;
  // console.log(filters);

  const [clientFiltering, setClientFiltering] = useState(loadAll);
  // if loadAll is true - fetch all without filter and perform cliend side filtering
  // const whereStatement = clientFiltering
  //   ? where
  //   :
  const whereStatement = {
    _and: [
      where,
      buildGraphQLFilter(filterClauseCreater, filters),
      buildGraphQLSearchFilter(
        filterClauseCreater,
        filterSortersData.searchData,
      ),
    ],
  };

  // and client side sorting
  const orderBy = buildGraphQLSorter(
    clientFiltering || !filterSortersData?.sorterData
      ? { key: "", dataIndex: hasSortableCols.dataIndex, order: "asc" }
      : filterSortersData?.sorterData,
  );

  const variables = {
    where: whereStatement,
    first: clientFiltering
      ? ScrollTable.LOAD_ALL_COUNT
      : ScrollTable.INITIAL_COUNT,
    order_by: orderBy,
    ...columns.reduce((a, c) => {
      return !!c.queryIncludeVarKey
        ? {
            ...a,
            [c.queryIncludeVarKey]:
              a[c.queryIncludeVarKey] || !props.excludedKeys?.includes(c.key),
          }
        : a;
    }, {} as any),
    ...(extraQueryVariables ? extraQueryVariables : {}),
  };

  // if we want change disableSuspense, we must remount component.
  //   otherwise we will have conditional hook problem. (react-hooks/rules-of-hooks)
  // so we copy disableSuspense to state on first render, and use it from state
  const [noSuspense] = useState(disableSuspense || false);
  if (isDevelopmentBuild && noSuspense !== disableSuspense) {
    throw new Error(
      "disableSuspense must remain unchanged during component lifecycle. Remount compoenent using key if you need to change it",
    );
  }
  const useLazyLoadHook = getLoadHook(noSuspense);
  const { data: query, loading } = useLazyLoadHook(queryNode, variables, {
    fetchPolicy: "network-only",
  });

  const [totalData, refetchTotal] = useRefetchableFragment<any, any>(
    totalCountNode,
    query,
  );

  const { data, loadNext, isLoadingNext, hasNext, refetch } =
    usePaginationFragment<any, any>(paginationNode, query);

  useImperativeHandle<
    OrientationDataScrollTableRef,
    OrientationDataScrollTableRef
  >(ref, () => ({
    refetch: () => {
      refetch({}, { fetchPolicy: "network-only" });
      refetchTotal({}, { fetchPolicy: "network-only" });
    },
  }));
  const nodes: N[] =
    (data &&
      data[connectionName] &&
      getConnectionNodes(data[connectionName])) ??
    [];
  const totalItems = totalData?.[totalCountConnectionName]?.edges.length;
  let items = nodes as unknown as Array<DataRecord>;

  // replace this with better code (automatically switch to client mode on small datasets)
  if (!clientFiltering && filters.length === 0 && totalItems < 200) {
    //    setClientFiltering(true);
  }
  ///   -----------

  if (clientFiltering) {
    // // perform client side filtering

    // and sorting
    if (filterSortersData?.sorterData) {
      items = arraySortData(items, filterSortersData?.sorterData);
    }
  }
  const itemsCount = clientFiltering
    ? items.length
    : totalItems ?? items.length;

  return (
    <ScrollTable
      {...props}
      dataSource={items}
      totalCount={itemsCount}
      columns={columns}
      title={title || ""}
      loading={loading}
      filterSorter={filterSortersData}
      onFilterSorterChange={setFilterSorterData}
      interactive={!!onRowClick}
      onRow={(item: any) => ({
        onClick: () => {
          if (onRowClick) onRowClick(item);
        },
      })}
      pagination={{
        hasNext,
        isLoadingNext,
        onLoadMoreClick: () => {
          loadNext(ScrollTable.FETCH_MORE_COUNT);
        },
      }}
    />
  );
};

export default withCustomSuspense(forwardRef(OrientationDataScrollTable), {
  fallback: <LargeTableSkeleton />,
}) as typeof OrientationDataScrollTable;
