import dayjs from "dayjs";
import { OacUserType, WorkerType } from "./siteOrientationTypes";
import ageByBirthDate from "src/common/functions/ageByBirthDate";
import { useGetOrientationDataQuery$data } from "src/common/types/generated/relay/useGetOrientationDataQuery.graphql";
import getUniqueCertsList from "./getUniqueCertsList";
import { GetOacDataQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { OrientationType } from "src/common/types/manual/OrientationType";

const setUserForOrientation = (
  selectedUser: useGetOrientationDataQuery$data["user_connection"]["edges"][number]["node"],
  userId: string,
  projectId: string,
  data: useGetOrientationDataQuery$data,
  oacData: GetOacDataQuery["getOACDataQuery"] | undefined | null,
  type?: OrientationType,
) => {
  let newUser: OacUserType | WorkerType | null = null;
  if (!selectedUser) {
    throw new Error("User not found");
  }
  const projectHasUniversal =
    data.project_connection.edges[0].node.agc_universal_orientation &&
    data.universal_orientation.edges.length > 0;

  const corporateOrientations = data.orientation_connection.edges.filter(
    (p) => p.node.type === "corporate",
  );
  let corporateCompleted: boolean | undefined = undefined;
  corporateOrientations.forEach((o) => {
    const showLate =
      o.node.project_orientations[0] &&
      (o.node.project_orientations[0].hide_but_give_credit ||
        (!o.node.project_orientations[0].play_during_in_person &&
          type === "inpersonComplete"));
    o.node.orientation_results.forEach((or) => {
      const moreThan1HourOldORShown = showLate
        ? or.completed_at
          ? dayjs(or.completed_at).isSameOrBefore(dayjs().subtract(1, "hour"))
          : false
        : true;
      const notExpired = or.expired_at
        ? dayjs(or.expired_at)
            .add(o.node.duration_valid, "M")
            .isSameOrAfter(dayjs())
        : true;
      const x = or.completed_at
        ? moreThan1HourOldORShown && notExpired
        : or.status === "completed";

      corporateCompleted =
        corporateCompleted === undefined ? x : corporateCompleted && x;
    });
  });
  const subEmployee = selectedUser.subcontractor_employee;
  const worker = selectedUser.worker;
  const employee = selectedUser.employee;
  if (userId && (worker || subEmployee)) {
    const projectsToRegister: { [projectId: string]: boolean } = {};
    let hardHatNumber: string | null | undefined = undefined;
    let toReceiveTextMessage: boolean | undefined = undefined;
    const dtPassedProjects: { [projectId: string]: boolean } = {};
    let onlineOrientationCompleted = worker
      ? worker.worker_projects.length > 0
      : false; // so if it doesn't go in loop i.e. worker not ON Any project it will be false then
    (worker?.worker_projects || []).forEach((pw, i) => {
      toReceiveTextMessage = toReceiveTextMessage || pw.receive_text_message;
      onlineOrientationCompleted =
        onlineOrientationCompleted &&
        !!(
          pw.user_orientation?.completed_at ||
          pw.user_orientation?.orientated_at
        );
      dtPassedProjects[pw.project_id] = !!(
        pw.user_orientation && pw.user_orientation.drug_test?.id
      );
      if (!hardHatNumber || i === 0) hardHatNumber = pw.hard_hat_number;
    });
    const curSubId = worker
      ? worker.subcontractor_id
      : subEmployee?.subcontractor_id;
    data.project_subcontractor_connection.edges.forEach((p) => {
      if (p.node.subcontractor_id === curSubId)
        projectsToRegister[p.node.project_id] = true;
    });

    newUser = {
      userType: "worker",
      name: { old: selectedUser.name },
      isSubAdmin: selectedUser.role === "subcontractor_employee",
      email: selectedUser.email,
      prevCompanyId: curSubId,
      universalCompleted: !!(projectHasUniversal
        ? selectedUser.universal_orientations.length &&
          dayjs(
            selectedUser.universal_orientations[0].universal_orientated_at,
          ).isBefore(dayjs().subtract(1, "hour"))
        : false),
      onlineOrientationCompleted,
      corporateOrientationPassed: !projectHasUniversal && corporateCompleted,
      toReceiveTextMessage,
      phoneNumber: selectedUser.phone_number,
      id: userId,
      hardHatNumber,
      projectsToRegister,
      birthDate: {
        old: selectedUser.birth_date
          ? dayjs(selectedUser.birth_date)
          : undefined,
      },
      company: selectedUser.subcontractor_employee
        ? {
            companyWorkerTitles: [],
            id: selectedUser.subcontractor_employee.subcontractor_id,
            title: selectedUser.subcontractor_employee.subcontractor.name,
          }
        : worker?.subcontractor
        ? {
            companyWorkerTitles: [],
            title: worker.subcontractor.name,
            id: worker.subcontractor_id!,
          }
        : undefined,
    };

    if (worker) {
      newUser = {
        ...newUser,
        profileImg: selectedUser.profile_picture
          ? { old: selectedUser.profile_picture.url }
          : worker.pendingProfile.length > 0 &&
            worker.pendingProfile[0].front_image
          ? { old: worker.pendingProfile[0].front_image.url }
          : undefined,
        unionId: worker.labor_union_chapter?.labor_union_id,
        unionChapterId: worker.labor_union_chapter_id ?? undefined,
        role: worker.current_worker_role,
        title: worker.worker_title
          ? {
              name: worker.worker_title.translation.en,
              id: worker.worker_title_id!,
            }
          : undefined,

        ethnicity: worker.ethnicity ? { old: worker.ethnicity } : undefined,
        gender: worker.gender ? { old: worker.gender } : undefined,
        veteranType: worker.veteran_type
          ? { old: worker.veteran_type }
          : undefined,
        yearsWithEmployer: worker.years_with_employer,
        workYears: worker.years_in_construction
          ? {
              old: {
                val: worker.years_in_construction,
                enteredAt: dayjs(worker.years_in_construction_entered_at),
              },
            }
          : undefined,
        age: selectedUser.birth_date
          ? {
              old: {
                val: ageByBirthDate(selectedUser.birth_date),
                enteredAt: dayjs(),
              },
            }
          : worker.age_entered_at && worker.age
          ? {
              old: {
                val:
                  worker.age +
                  dayjs().diff(dayjs(worker.age_entered_at), "years"),
                enteredAt: dayjs(), // current time as we are calculating age just now from the previously entered values
              },
            }
          : undefined,
        residence: worker.city_zip_code
          ? {
              cityZipCode: {
                zipCode: worker.city_zip_code.zip_code,
                id: worker.city_zip_code_id!,
              },
              cityId: worker.city_zip_code.city.pk,
              stateCode: worker.city_zip_code.city.state_code,
            }
          : undefined,
        certifications: {
          old: getUniqueCertsList([
            ...selectedUser.worker.worker_certifications,
          ]).map((p) => ({
            title: p.name,
            id: p.id,
            expiresOn: p.expires_on ? dayjs(p.expires_on) : undefined,
            frontSidePresent: p.images > 0,
            backSidePresent: p.images > 1,
          })),
        },
        pendingCertsLength: worker.pendingCertificates.length,

        drugTests: {
          dtPassedProjects,
          old: worker.worker_drug_tests.map((p) => ({
            id: p.pk,
            projectId: p.project_id || "", //projectId will be available ehre as we're already filtering out those without it in query
            passed: !!p.drug_test_passed,
            date: dayjs(p.drug_test_date),
          })),
        },
      };
    }
  } else if (userId && employee) {
    const projectsToRegister: { [projectId: string]: boolean } = {};
    data.project_subcontractor_connection.edges.forEach((p) => {
      projectsToRegister[p.node.project_id] = true;
    });
    let hardHatNumber: string | null | undefined = undefined;
    let onlineOrientationCompleted = employee.employee_projects.length > 0; // so if it doesn't go in loop i.e. employee not ON Any project it will be false then
    employee.employee_projects.forEach((pw, i) => {
      if (!hardHatNumber || i === 0) hardHatNumber = pw.hard_hat_number;
      onlineOrientationCompleted =
        onlineOrientationCompleted &&
        !!(
          pw.user_orientation?.completed_at ||
          pw.user_orientation?.orientated_at
        );
    });
    projectsToRegister[projectId] = true;
    newUser = {
      userType: "oacUser",
      name: { old: selectedUser.name },
      email: selectedUser.email,
      phoneNumber: selectedUser.phone_number,
      onlineOrientationCompleted,
      universalCompleted: !!(projectHasUniversal
        ? selectedUser.universal_orientations.length &&
          dayjs(
            selectedUser.universal_orientations[0].universal_orientated_at,
          ).isBefore(dayjs().subtract(1, "hour"))
        : false),
      corporateOrientationPassed: !projectHasUniversal && corporateCompleted,
      birthDate: {
        old: selectedUser.birth_date
          ? dayjs(selectedUser.birth_date)
          : undefined,
      },
      profileImg: selectedUser.profile_picture
        ? { old: selectedUser.profile_picture.url }
        : undefined,
      id: userId,
      ...(employee.oac_company_id
        ? {
            oacCompanyId: employee.oac_company_id,
            oacTitleId: employee.oac_title_id ?? undefined,
            employeeTitleId: employee.title_id ?? undefined,
            generalContractorId: employee.general_contractor_id,
            company: {
              companyWorkerTitles: [],
              title:
                (oacData?.oacCompanies || []).find(
                  (o) => o.id == employee.oac_company_id,
                )?.name ?? "", // it will be present here
              id: employee.oac_company_id,
            },
            title: employee.oac_title_id
              ? {
                  name:
                    (oacData?.oacTitles || []).find(
                      (o) => o.id == employee.oac_title_id,
                    )?.name ?? "", // it will be present here
                  id: employee.oac_title_id,
                }
              : undefined,
          }
        : {
            generalContractorId: employee.general_contractor_id,
            employeeTitleId: employee.title_id ?? undefined,
            company: {
              companyWorkerTitles: [],
              title:
                (oacData?.generalContractors || []).find(
                  (gc) => gc.id === employee.general_contractor_id,
                )?.name ?? "", // it will be present here
              id: employee.general_contractor_id, // it will be present here
            },
            title: employee.title_id
              ? {
                  name:
                    (oacData?.employeeTitles || []).find(
                      (et) => et.id === employee.title_id,
                    )?.name ?? "", // it will be present here
                  id: employee.title_id,
                }
              : undefined,
          }),
      projectsToRegister,
      hardHatNumber,
    };
  }
  return newUser;
};
export default setUserForOrientation;
