import { Button, DatePicker, Form, Modal, Space, Spin } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { FC, useRef, useState } from "react";
import EmailCovidSpreadsheetModal, {
  EmailCovidSpreadsheetModalRef,
} from "src/common/components/dialogs/EmailCovidSpreadsheetModal";
import StyledContent from "src/common/components/layouts/StyledContent";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import openInNewTab from "src/common/functions/openInNewTab";
import {
  useEmailCovidSpreadsheetMutation,
  useEmailCovidWorkerAuditSpreadsheetMutation,
  useGetProjectCovidConfigByPkQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import ProjectProps from "src/common/types/manual/ProjectProps";

interface CovidAuditModalValues {
  range: Array<Dayjs>;
}

interface CovidModalProps {
  visible: boolean;

  onCreate(values: CovidAuditModalValues): void;

  onCancel(): void;
}

const CovidAuditModal = ({
  visible,
  onCreate,
  onCancel,
}: CovidModalProps): JSX.Element => {
  const [form] = Form.useForm();
  const [dateRange, setRange] = useState<Array<Dayjs>>([
    dayjs().startOf("week").startOf("d"),
    dayjs().endOf("d"),
  ]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const validateRange = (range: any) => {
    const rangeOk = dayjs(range[1]).diff(dayjs(range[0]), "d") <= 30;
    if (!rangeOk) {
      Modal.warn({
        content: <p>Date Range must be less than 30 days</p>,
      });
      form.resetFields();
    }
    setRange(range);
    return;
  };

  return (
    <Modal
      open={visible}
      title="Export COVID-19 Worker Audit Check Spreadsheet"
      okText="Download"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values as CovidAuditModalValues);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="range"
          label="Select Dates - (Max 30 days)"
          rules={[{ required: true, message: "Choose a date" }]}
        >
          <DatePicker.RangePicker
            style={{ width: "100%" }}
            onChange={validateRange}
            value={[dateRange[0], dateRange[1]]}
            defaultPickerValue={[
              dayjs().startOf("week").startOf("d"),
              dayjs().endOf("d"),
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const GCProjectCovidData: FC<ProjectProps> = ({ projectId }) => {
  //const [emailOpen, setEmailOpen] = useState(false);
  const emailOpen = useRef<EmailCovidSpreadsheetModalRef>(null);
  const [openCovidModal, setOpenCovidModal] = useState(false);
  const { data, loading } = useGetProjectCovidConfigByPkQuery({
    variables: { projectId },
  });
  const [emailSpreadsheet, { loading: inserting }] =
    useEmailCovidSpreadsheetMutation();

  const [getURL, { loading: loadingSpreadsheet }] =
    useEmailCovidWorkerAuditSpreadsheetMutation();

  if (loading) {
    return (
      <StyledContent padding backgroundColor="white">
        <Spin />
      </StyledContent>
    );
  }

  if (!data?.project_covid_config_by_pk) {
    return (
      <StyledContent padding backgroundColor="white">
        Please setup your project's COVID-19 Settings.
      </StyledContent>
    );
  }

  if (!data.project_covid_config_by_pk.contact_project_employee) {
    return (
      <StyledContent padding backgroundColor="white">
        Please add a COVID-19 contact employee in COVID-19 settings.
      </StyledContent>
    );
  }

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <StyledContent padding backgroundColor="white">
        <EmailCovidSpreadsheetModal
          ref={emailOpen}
          onCancel={() => emailOpen.current?.close()}
          onCreate={async (values) => {
            emailOpen.current?.close();
            const restrictedDays = data?.project_covid_config_by_pk
              ? Number(
                  data.project_covid_config_by_pk.worker_restriction_length.match(
                    /\d/,
                  )?.[0],
                )
              : 0;

            const { data: emailData } = await emailSpreadsheet({
              variables: {
                input: {
                  projectId,
                  startAt: values.startDate.startOf("day").toString(),
                  endAt: values.endDate.endOf("day").toString(),
                  restrictedSinceDate: dayjs(values.endDate)
                    .subtract(restrictedDays, "days")
                    .toString(),
                },
              },
            });
            if (emailData?.emailCovidSpreadsheet) {
              downloadFromUrl(emailData.emailCovidSpreadsheet);
            }
          }}
        />
        <CovidAuditModal
          visible={openCovidModal}
          onCancel={() => setOpenCovidModal(false)}
          onCreate={async (values) => {
            setOpenCovidModal(false);

            const { data: covidSpreadsheet } = await getURL({
              variables: {
                input: {
                  projectId,
                  start: dayjs(values.range[0]).startOf("day").toString(),
                  end: dayjs(values.range[1]).endOf("day").toString(),
                },
              },
            });
            covidSpreadsheet?.url && downloadFromUrl(covidSpreadsheet.url);
          }}
        />
        <Space direction="vertical" style={{ width: "50%" }}>
          <div style={{ margin: "24px 0" }}>
            <p>Download COVID-19 Data Spreadsheet</p>
            <Button
              type="primary"
              loading={inserting}
              onClick={() => emailOpen.current?.open()}
            >
              Generate
            </Button>
            <Button
              style={{ marginLeft: "16px" }}
              onClick={() => {
                openInNewTab("https://youtu.be/VmZLKO0UPrg");
              }}
            >
              Help
            </Button>
          </div>
          <div style={{ margin: "24px 0" }}>
            <p>Download COVID-19 Worker Audit Check Spreadsheet</p>
            <div style={{ display: "flex" }}>
              <Button
                type="primary"
                loading={loadingSpreadsheet}
                onClick={() => setOpenCovidModal(true)}
              >
                Generate
              </Button>
              <Button
                style={{ marginLeft: "16px" }}
                onClick={() => {
                  openInNewTab("https://youtu.be/VmZLKO0UPrg");
                }}
              >
                Help
              </Button>
            </div>
          </div>
        </Space>
      </StyledContent>
    </Space>
  );
};

export default GCProjectCovidData;
