import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useInsertSiteOrientationResultMutation } from "src/common/types/generated/relay/useInsertSiteOrientationResultMutation.graphql";

const useInsertSiteOrientationResult = () => {
  return useAsyncMutation<useInsertSiteOrientationResultMutation>(graphql`
    mutation useInsertSiteOrientationResultMutation(
      $workerOrientationObjects: [user_orientation_insert_input!]!
      $workerOrientationUpdateColumns: [user_orientation_update_column!]!
      $userUniversalOrientationObjects: [user_universal_orientation_insert_input!]!
      $orientationResultsObjects: [orientation_result_insert_input!]!
    ) {
      insert_orientation_result(
        objects: $orientationResultsObjects
        on_conflict: {
          constraint: orientation_result_pkey
          update_columns: [
            total_slides
            viewed_slides
            signature_url
            status
            expired_at
            completed_at
            group_id
            result_inserted_by_uid
          ]
        }
      ) {
        affected_rows
      }
      insert_user_universal_orientation(
        objects: $userUniversalOrientationObjects
      ) {
        affected_rows
      }
      insert_user_orientation(
        objects: $workerOrientationObjects
        on_conflict: {
          constraint: user_orientation_user_id_project_id_key
          update_columns: $workerOrientationUpdateColumns
        }
      ) {
        returning {
          id
          user_id
          project_id
          orientated_at
        }
      }
    }
  `);
};
export default useInsertSiteOrientationResult;
