import clsx from "clsx";
import React from "react";

export interface SiteBoardOnsiteSubProps {
  title: string;
  reportsCount: number;
  allReportsSubmitted: boolean;
  bgColor: string;
}

const SiteBoardOnsiteSub: React.FC<SiteBoardOnsiteSubProps> = (props) => {
  return (
    <div
      className={clsx(
        `pl-1 flex-1 flex h-full flex-row rounded-1 bg-${props.bgColor}`
      )}
    >
      <div
        className={clsx(
          `w-full flex items-center justify-start rounded-1 px-1 h-full`,
          props.allReportsSubmitted
            ? "bg-semantic-positive-dark"
            : "bg-semantic-negative-dark"
        )}
      >
        <span
          className={`text-left block text-white text-4 whitespace-nowrap truncate w-full`}
        >
          {`${props.title}`}
        </span>
        {props.reportsCount > 1 && (
          <span className="font-features sups text-white whitespace-nowrap text-2">
            x{props.reportsCount}
          </span>
        )}
      </div>
    </div>
  );
};

export default SiteBoardOnsiteSub;
