import React from "react";
import clsx from "clsx";

interface SiteDeliveryCalendarTimeStampLineProps {
  isHour: boolean;
}

const SiteDeliveryCalendarTimeStampLine: React.FC<
  SiteDeliveryCalendarTimeStampLineProps
> = ({ isHour }) => {
  return (
    <div
      className={clsx(
        "h-px w-full bg-static-primary",
        isHour ? "opacity-20" : "opacity-[5%]"
      )}
    ></div>
  );
};

export default SiteDeliveryCalendarTimeStampLine;
