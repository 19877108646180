import React, { useRef, useState } from "react";
import { Button, Popconfirm, Space, message } from "antd";
import AddSubcontractorProjectTeamModal from "src/domain-features/sitesafety/siteSafetyPlan/components/AddSubcontractorProjectTeamModal";
import ProjectSubcontractorEmployeeTable, {
  ProjectSubcontractorEmployee,
} from "src/domain-features/sitesafety/siteSafetyPlan/components/ProjectSubcontractorEmployeeTable";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import noPropagation from "src/common/functions/noPropagation";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { graphql } from "babel-plugin-relay/macro";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import {
  SubProjectMobilizationStepContactLists_updateProjectSubcontractorEmployeeMutation,
  SubProjectMobilizationStepContactLists_updateProjectSubcontractorEmployeeMutation$data,
} from "src/common/types/generated/relay/SubProjectMobilizationStepContactLists_updateProjectSubcontractorEmployeeMutation.graphql";
import handleRequestError from "src/utility-features/error-handling/handleRequestError";
export interface SubProjectMobilizationStepContactListsProps {
  projectId: string;
  subcontractorId: string;
  onNext: (isUploaded: boolean) => void;
}

const SubProjectMobilizationStepContactLists: React.FC<
  SubProjectMobilizationStepContactListsProps
> = (props) => {
  const [updateProjectSubcontractorEmployee, inserting] =
    useAsyncMutation<SubProjectMobilizationStepContactLists_updateProjectSubcontractorEmployeeMutation>(
      graphql`
        mutation SubProjectMobilizationStepContactLists_updateProjectSubcontractorEmployeeMutation(
          $_set: project_subcontractor_employee_set_input
          $where: project_subcontractor_employee_bool_exp!
        ) {
          update_project_subcontractor_employee(_set: $_set, where: $where) {
            affected_rows
          }
        }
      `
    );
  const [
    openAddSubcontractorProjectTeamModal,
    setOpenAddSubcontractorProjectTeamModal,
  ] = useState(false);
  const projectId = props.projectId;
  const subcontractorId = props.subcontractorId;
  const subcontractorProjectTeamTableRef = useRef<DataScrollTableRef>(null);
  const updateProjectSubcontractorEmployeeUpdater: (
    store: RecordSourceSelectorProxy<SubProjectMobilizationStepContactLists_updateProjectSubcontractorEmployeeMutation$data>,
    id: string,
    markAsValid: boolean
  ) => void = (store, id, markAsValid) => {
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "ProjectSubcontractorEmployeeTable_project_subcontractor_employee_connection"
    );
    if (conn) {
      const edges = conn.getLinkedRecords("edges") || [];
      edges.forEach((edge) => {
        const node = edge.getLinkedRecord("node");
        if (!node) {
          return;
        }

        if (node.getValue("id") == id && markAsValid) {
          node.setValue(true, "emergency_contact");
        } else if (!markAsValid) {
          node.setValue(false, "emergency_contact");
        }
      });
      conn.setLinkedRecords(edges, "edges");
    }
  };
  const makeEmergencyContact = async (
    projectSubcontractorEmployee: ProjectSubcontractorEmployee,
  ) => {
    try {
      await updateProjectSubcontractorEmployee({
        variables: {
          _set: { emergency_contact: false },
          where: { emergency_contact: { _eq: true } },
        },
        updater: (store) => {
          updateProjectSubcontractorEmployeeUpdater(
            store,
            projectSubcontractorEmployee.id,
            false,
          );
        },
      });
      await updateProjectSubcontractorEmployee({
        variables: {
          _set: { emergency_contact: true },
          where: { id: { _eq: projectSubcontractorEmployee.pk } },
        },
        updater: (store) => {
          updateProjectSubcontractorEmployeeUpdater(
            store,
            projectSubcontractorEmployee.id,
            true,
          );
        },
      });

      message.success("Emergency Contact Updated");
    } catch (error) {
      handleRequestError(error, {
        errorTitle: "Unable to update Emergency Contact",
      });
    }
  };
  
  return (
    <div>
      <AddSubcontractorProjectTeamModal
        modalClose={() => {
          setOpenAddSubcontractorProjectTeamModal(false);
        }}
        modalVisible={openAddSubcontractorProjectTeamModal}
        subcontractorId={subcontractorId}
        projectId={projectId ?? ""}
        onSubmit={() => {
          setOpenAddSubcontractorProjectTeamModal(false);
          subcontractorProjectTeamTableRef.current?.refetch();
          props.onNext(true);
        }}
      />
      <div className="mt-1">
        <Button
          onClick={() => {
            props.onNext(false);
          }}
        >
          Next
        </Button>
      </div>
      <div className="mt-2">
        <ProjectSubcontractorEmployeeTable
          title="Project Contact List"
          ref={subcontractorProjectTeamTableRef}
          where={{
            project_id: { _eq: projectId },
            subcontractor_employee: {
              subcontractor_id: { _eq: subcontractorId },
            },
          }}
          loading={false}
          topBarButtons={[
            {
              onClick: () => {
                setOpenAddSubcontractorProjectTeamModal(true);
              },
              label: "+ Add",
            },
          ]}
          extraColumns={[
            {
              title: "",
              dataIndex: [""],
              size: "md",
              key: "emergencyContact",
              render: (_, projectSubcontractorEmployee) => (
                <>
                  <Space>
                    <Popconfirm
                      title="Are you sure?"
                      onConfirm={noPropagation(() => {
                        makeEmergencyContact(projectSubcontractorEmployee);
                      })}
                      onCancel={noPropagation()}
                      okText="Yes"
                      cancelText="Cancel"
                    >
                      <Button type="link" onClick={noPropagation()}>
                        Make Emergency Contact
                      </Button>
                    </Popconfirm>
                  </Space>
                </>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default SubProjectMobilizationStepContactLists;