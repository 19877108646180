import { useSuspenseQuery } from "@apollo/client";
import { Button, Form, Input, Select } from "antd";
import { useState } from "react";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import getAddressText from "src/common/functions/getAddressText";
import {
  GetOfficeDetailQuery,
  GetOfficeDetailQueryVariables,
  GetOfficeDetailDocument,
  useGetStatesQuery,
  useUpdateOfficeDetailMutation,
  useUpdateOfficeLogoMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import AddressFormItems, {
  AddressFormItemsName,
} from "../../component/AddressFormItems";
import { useParams } from "react-router-dom";
import ProfileAvatar from "src/root/routes/views/subcontractor/people/workers/worker-profile/ProfileAvatar";
import LogoExplainerText from "../../component/LogoExplainerText";
import withOfficeId, { OfficeProps } from "../../utils/withOfficeId";
import getNormalOptionsFilter from "src/common/functions/getNormalOptionsFilter";

const GCOfficeDetail: React.FC<OfficeProps> = ({ officeId }) => {
  const { userData } = useUserData();
  const emp = userData.employee;
  if (!emp) throw new Error("Employee not found");

  const { data, refetch } = useSuspenseQuery<
    GetOfficeDetailQuery,
    GetOfficeDetailQueryVariables
  >(GetOfficeDetailDocument, {
    variables: {
      officeId,
      gcId: emp.general_contractor_id,
    },
    fetchPolicy: "network-only",
  });
  const { data: statesData } = useGetStatesQuery();
  const office = data.gc_office_by_pk;

  if (!office) throw new Error("office not found");
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);
  const gc = emp.general_contractor;
  const initialFields = {
    name: office.name,
    division: office.gc_division?.id,
    business_unit: office.gc_business_unit?.id,
    ...(office.address
      ? {
          addressLine1: office.address.line_1,
          city: office.address.city,
          zip: office.address.zip_code,
          stateCode: office.address.state.code,
        }
      : {}),
  };
  const officeProjectIds = office.projects.map((p) => p.id);
  const [selectedDivision, setSelectedDivision] = useState(
    office.gc_division?.id,
  );
  const [updateOfficeDetail, { loading }] = useUpdateOfficeDetailMutation();
  const [updateOfficeLogo] = useUpdateOfficeLogoMutation();
  return (
    <BasicWrapper scrollable>
      {!editing && (
        <div className="absolute right-1 top-1 w-20 h-20">
          <p className="text-center text-1.5 mb-1">
            Upload/Change {gc.hierarchy_office_name} Logo
          </p>
          <ProfileAvatar
            imageUrl={
              office.logo_url ||
              office.gc_business_unit?.logo_url ||
              office.gc_division?.logo_url ||
              office.general_contractor.logo_url ||
              undefined
            }
            onFileUpload={async (imageUrl) => {
              await updateOfficeLogo({
                variables: { officeId, imageUrl },
                optimisticResponse: {
                  update_gc_office_by_pk: { id: officeId, logo_url: imageUrl },
                },
              });
            }}
            onFileRemove={async () => {
              await updateOfficeLogo({
                variables: { officeId, imageUrl: null },
                optimisticResponse: {
                  update_gc_office_by_pk: { id: officeId, logo_url: null },
                },
              });
            }}
          />
          <LogoExplainerText />
        </div>
      )}
      <div className="text-1.25 mb-2">{gc.hierarchy_office_name} Details</div>
      <Form
        form={form}
        initialValues={initialFields}
        onFinish={async (
          vals: AddressFormItemsName & {
            name: string;
            division?: string;
            business_unit?: string;
          },
        ) => {
          const divChange = vals.division !== office.gc_division?.id;
          const oldDivEmps = (
            divChange ? office.gc_division?.gc_division_employees || [] : []
          ).map((g) => g.user_id);
          const newDivEmps = (
            divChange
              ? data.gc_division.find((d) => d.id === vals.division)
                  ?.gc_division_employees || []
              : []
          ).map((g) => g.user_id);
          const buChange = vals.business_unit !== office.gc_business_unit?.id;
          const oldBuEmps = (
            buChange
              ? office.gc_business_unit?.gc_business_unit_employees || []
              : []
          ).map((g) => g.user_id);
          const newBuEmps = (
            buChange
              ? data.gc_business_unit.find((d) => d.id === vals.business_unit)
                  ?.gc_business_unit_employees || []
              : []
          ).map((g) => g.user_id);
          await updateOfficeDetail({
            variables: {
              addressId: office.address.id,
              officeId,
              officeSet: {
                name: vals.name,
                gc_division_id: vals.division || null,
                gc_business_unit_id: vals.business_unit || null,
              },
              projectSet: {
                ...(divChange ? { gc_division_id: vals.division || null } : {}),
                ...(buChange
                  ? { gc_business_unit_id: vals.business_unit || null }
                  : {}),
              },
              projectWhere: { gc_office_id: { _eq: officeId } },
              addressSet: {
                state_code: vals.state_code,
                zip_code: vals.zip,
                city: vals.city,
                line_1: vals.addressLine1,
              },
              deleteProjEmpWhere: {
                direct_project_assign: { _eq: false },
                project: { gc_office_id: { _eq: officeId } },
                employee_id: { _in: [...oldDivEmps, ...oldBuEmps] },
              },
              projEmpObjs: [...newDivEmps, ...newBuEmps].flatMap((empId) =>
                officeProjectIds.map((p) => ({
                  project_id: p,
                  employee_id: empId,
                  status: "Inactive",
                  direct_project_assign: false,
                })),
              ),
            },
          });
          refetch();
          setEditing(false);
        }}
      >
        {editing ? (
          <>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Enter Name" }]}
            >
              <Input />
            </Form.Item>
            Address
            <AddressFormItems
              onStateChange={(v) => {}}
              states={statesData?.state || []}
            />
          </>
        ) : (
          <div>
            <div>Name: {office.name}</div>
            <br />
            Address: {getAddressText(office.address)}
          </div>
        )}

        <div
          className={
            gc.hierarchy_division_name?.trim() && editing ? "" : "hidden"
          }
        >
          <Form.Item
            name="division"
            label={`Change ${gc.hierarchy_division_name}`}
          >
            <Select
              allowClear
              filterOption={getNormalOptionsFilter}
              onChange={(d) => {
                console.log(d, "JHASDHJAS");
                setSelectedDivision(d);
                if (d) form.setFieldValue("business_unit", undefined);
              }}
              options={data.gc_division.map((d) => ({
                label: d.name,
                value: d.id,
              }))}
            />
          </Form.Item>
          {/* <div className="-mt-1 mb-1">
            (NOTE: Changing {gc.hierarchy_division_name} will remove access from
            current {gc.hierarchy_division_name} and grant access to employees
            under new {gc.hierarchy_division_name})
          </div> */}
        </div>
        {!editing && !!gc.hierarchy_division_name ? (
          <div className="mt-1.5">
            {gc.hierarchy_division_name}:{" "}
            {office.gc_division?.name || "No " + gc.hierarchy_division_name}
          </div>
        ) : null}

        <div
          className={
            gc.hierarchy_business_unit_name?.trim() && editing ? "" : "hidden"
          }
        >
          <Form.Item
            name="business_unit"
            label={`Change ${gc.hierarchy_business_unit_name}`}
          >
            <Select
              allowClear
              showSearch
              filterOption={getNormalOptionsFilter}
              options={data.gc_business_unit
                .filter(
                  (bu) =>
                    !selectedDivision || bu.gc_division_id === selectedDivision,
                )
                .map((d) => ({
                  label: d.name,
                  value: d.id,
                }))}
            />
          </Form.Item>
          <div className="-mt-1">
            (NOTE: Changing {gc.hierarchy_business_unit_name} will remove access
            from current {gc.hierarchy_business_unit_name} and grant access to
            employees under new {gc.hierarchy_business_unit_name})
          </div>
        </div>
        {!!gc.hierarchy_business_unit_name?.trim() && !editing ? (
          <div className="mt-1.5">
            {gc.hierarchy_business_unit_name}:{" "}
            {office.gc_business_unit?.name ||
              "No " + gc.hierarchy_business_unit_name}
          </div>
        ) : null}
        <br />
        {emp.is_corporate_admin && (
          <>
            <Button
              type={editing ? "default" : "primary"}
              loading={loading}
              onClick={() => {
                if (editing) form.setFieldsValue(initialFields);
                setEditing((i) => !i);
              }}
            >
              {editing ? "Cancel" : "Edit"}
            </Button>
            {editing && (
              <Button
                loading={loading}
                className="ml-2"
                htmlType="submit"
                type="primary"
              >
                Save
              </Button>
            )}
          </>
        )}
      </Form>
    </BasicWrapper>
  );
};
export default withOfficeId(GCOfficeDetail);
