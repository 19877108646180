import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Form, message, notification, Select } from "antd";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";

import validateEmail from "src/common/functions/validateEmail";
import updateProjectWorkersMutation from "src/common/api/relay/mutations/UpdateProjectWorkersForWorkerRegistrationWithHardHat";
import {
  useAddCorporateOrientationResultsOnNewProjectMutation,
  useEmailGcAboutDtWorkerArrivalMutation,
  useEmailOrientationInviteMutation,
  useSendOrientationLinkTextMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation } from "src/common/types/generated/relay/UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation.graphql";

interface OrientationInviteModalProps {
  visible: boolean;
  reset: () => void;
  projects?: { id: string; name: string }[];
  workers?: {
    id: string;
    name: string;
    email?: string | null;
    phone_number?: string | null;
  }[];
  selectedProject?: { name: string; id: string };
  worker?: {
    id: string;
    titleId: string | undefined;
    worker_role: string;
    subcontractorId: string;
    email?: string;
    phone_number?: string;
    refetch: () => void;
  };
}

interface FormValues {
  projectId: string;
  workerEmails?: [string];
  email?: string;
  phone?: string;
}

export type OrientationInviteModalRef = FModalRef<FormValues> | null;
const OrientationInviteModal = forwardRef<
  OrientationInviteModalRef,
  OrientationInviteModalProps
>(({ visible, projects, reset, workers, selectedProject, worker }, ref) => {
  const modal = useRef<FModalRef<FormValues>>(null);
  const [emailForDT] = useEmailGcAboutDtWorkerArrivalMutation();
  const [updateProjectWorker, isInserting] =
    useAsyncMutation<UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation>(
      updateProjectWorkersMutation,
    );
  const [addCorporateOrientationResultsOnNewProject] =
    useAddCorporateOrientationResultsOnNewProjectMutation();
  const [emailInvite, { loading }] = useEmailOrientationInviteMutation();
  const [sendOrientationLinkText] = useSendOrientationLinkTextMutation();
  useImperativeHandle<OrientationInviteModalRef, OrientationInviteModalRef>(
    ref,
    () => modal.current,
  );
  const handleCancel = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    reset();
  };
  const fullReset = () => {
    reset();
    modal.current?.form.resetFields();
    modal.current?.close();
  };
  const [sendType, setSendType] = useState<undefined | boolean>(undefined); //true for email and false for phone number
  const handleOk = async (
    v: any,
    type: "sendInvite" | "addToProject" | "addToProjectAndSendInvite",
  ) => {
    v.stopPropagation();
    const form = modal.current?.form;
    if (!form) return;

    form
      .validateFields()
      .then(async (v) => {
        console.log(v);
        if (type === "addToProject" || type === "addToProjectAndSendInvite") {
          if (worker?.id) {
            await updateProjectWorker({
              variables: {
                newSubId: worker?.subcontractorId,
                projectIds: [v.projectId],
                workerId: worker.id,
                _set: { is_last: false },
                newUpsertObjects: [
                  {
                    project_id: v.projectId,
                    subcontractor_id: worker?.subcontractorId,
                    title_id: worker?.titleId,
                    worker_role: worker?.worker_role,
                    worker_id: worker.id,
                    deleted_at: null,
                    is_last: true,
                  },
                ],
              },
            });
            emailForDT({
              variables: {
                input: {
                  projectId: v.projectId,
                  userIds: [worker.id],
                  sendForPositive: true,
                },
              },
            }).catch(console.error);
            addCorporateOrientationResultsOnNewProject({
              variables: {
                input: { project_id: v.projectId, user_ids: [worker.id] },
              },
            }).catch(console.error);
          }
          message.success("Added To Project");
        }
        if (type === "sendInvite" || type === "addToProjectAndSendInvite") {
          try {
            if (v.email || worker?.email) {
              emailInvite({
                variables: {
                  input: {
                    projectId: selectedProject?.id ?? v.projectId,
                    emailRecipients: worker?.email
                      ? [worker.email]
                      : v.email
                      ? [v.email]
                      : [],
                  },
                },
              });
              message.success("SUCCESS: Invite sent successfully");
            } else if (v.workerEmails) {
              const emails: Array<string> = [],
                phoneNumbers: Array<string> = [];
              v.workerEmails.forEach((val) => {
                if (validateEmail(val)) {
                  emails.push(val);
                } else {
                  phoneNumbers.push(val);
                }
              });
              if (emails.length > 0)
                emailInvite({
                  variables: {
                    input: {
                      projectId: selectedProject?.id ?? v.projectId,
                      emailRecipients: emails,
                    },
                  },
                });
              if (phoneNumbers.length > 0) {
                sendOrientationLinkText({
                  variables: {
                    input: {
                      toPhoneNumbers: phoneNumbers,
                      projectId: selectedProject?.id ?? v.projectId,
                    },
                  },
                }).catch((err) => {
                  console.log(err);
                  notification.error({
                    message: "Message couldn't be sent",
                    description:
                      "Number couldn't be found, if you think it's correct, please check and try again.",
                  });
                });
              }
              // sendText;
              message.success("SUCCESS: Invite sent successfully");
            } else if (v.phone || worker?.phone_number) {
              sendOrientationLinkText({
                variables: {
                  input: {
                    toPhoneNumbers: v.phone
                      ? [v.phone]
                      : worker?.phone_number
                      ? []
                      : [],
                    projectId: selectedProject?.id ?? v.projectId,
                  },
                },
              }).catch((err) => {
                console.log(err);
                notification.error({
                  message: "Message couldn't be sent",
                  description:
                    "Number couldn't be found, if you think it's correct, please check and try again.",
                });
              });
              message.success("SUCCESS: Invite sent successfully");
            } else {
              message.warning("No Email/Phone Number found to send the invite");
            }
          } catch (err) {
            message.error("Error: Invite not sent");
          }
        }
        worker?.refetch && worker.refetch();

        fullReset();
      })
      .catch((e) => console.log(e));
  };
  return (
    <FModal
      open={visible}
      ref={modal}
      okText={"Send"}
      title={
        "Send Orientation Link" +
        (selectedProject ? " For " + selectedProject.name : "")
      }
      footer={
        worker
          ? [
              <Button>Cancel</Button>,
              <Button
                type="primary"
                onClick={(e) => handleOk(e, "addToProject")}
              >
                Add to Project
              </Button>,
              <Button
                type="primary"
                onClick={(e) => handleOk(e, "addToProjectAndSendInvite")}
              >
                Send Orientation Invite
              </Button>,
            ]
          : undefined
      }
      onCancel={handleCancel}
      onOk={(v) => handleOk(v, "sendInvite")}
    >
      {projects && (
        <FModal.Select
          label={<div className="font-accent">Select the Project</div>}
          requiredMessage="Please Select a Project"
          name="projectId"
          props={{
            placeholder: "Select Project",
            options: projects.map((p) => ({
              key: p.id,
              label: p.name,
              value: p.id,
            })),
          }}
        />
      )}
      {workers ? (
        <FModal.Select
          name="workerEmails"
          label={
            <div className="font-accent">
              Select the workers to send the invite (email or phone number)
              <div className="text-semantic-inactive">
                Don’t see a worker? Type their email or phone number
              </div>
            </div>
          }
          props={{
            mode: "tags",
          }}
        >
          {workers.map((w, i) => (
            <Select.Option
              key={w.id}
              value={w.email || w.phone_number || ""} //either email or phone number will be present
            >
              {w.name}
            </Select.Option>
          ))}
        </FModal.Select>
      ) : (
        <>
          <FModal.RadioGroup
            name="toSendThrough"
            props={{
              onChange: (e) => {
                console.log(e.target.value);
                setSendType(e.target.value);
              },
            }}
            label={"How should the link be sent?"}
            rules={[
              { message: "please select how to send link", required: true },
            ]}
          >
            <div style={{ display: "flex" }}>
              <FModal.Radio props={{ value: true }}>Email </FModal.Radio>
              <FModal.Radio props={{ value: false }}>Phone Number</FModal.Radio>
            </div>
          </FModal.RadioGroup>
          {sendType === true ? (
            <FModal.Text
              name={"email"}
              requiredMessage="Enter a valid Email"
              className="w-full"
              props={{
                placeholder: "example@abc.com",
              }}
              label={
                <div className="font-accent">
                  Email to send the orientation link
                </div>
              }
            />
          ) : (
            sendType === false && (
              <>
                <div className="font-accent">
                  Phone Number to send the orientation link
                </div>
                <Form.Item name="phone">
                  <input
                    type="tel"
                    name="phone"
                    placeholder="e.g. 111-111-1111"
                    className="bg-white border-0.125 border-grey w-full m-0 text-1"
                    pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}|[0-9]{10}|[0-9]{3} [0-9]{3} [0-9]{4}|[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    title="Please enter a 10 digit phone number with no spaces or characters"
                    // inputMode="numeric"
                    onKeyDown={(e) => {}}
                  />
                </Form.Item>
              </>
            )
          )}
        </>
      )}
    </FModal>
  );
});
export default OrientationInviteModal;
