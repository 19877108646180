import { Spin } from "antd";
import React, { FC, ReactNode, Suspense, useEffect, useState } from "react";
import ErrorBoundary from "../../../utility-features/error-handling/components/ErrorBoundary";
import sendHotjarEvent from "src/utility-features/event-tracking/hotjar/sendHotjarEvent";

const SHOW_FALLBACK_DELAY = 500;

export interface CustomSuspenseProps {
  fallback?: any;
  fallbackDelay?: number;
  enableDelay?: boolean;
  children?: ReactNode;
}

const FallbackWrapper: FC<{
  onMount: () => void;
  children?: ReactNode;
}> = ({ children, onMount }) => {
  useEffect(() => {
    onMount();
    // Get notified when loading takes too long
    const mountedAt = Date.now();
    return () => {
      const unmountedAt = Date.now();
      const presenceTimeMs = unmountedAt - mountedAt;
      if (presenceTimeMs > 3000) {
        sendHotjarEvent("load_over_3s");
      } else if (presenceTimeMs > 1000) {
        sendHotjarEvent("load_over_1s");
      }
    };
  }, []);

  return <>{children}</>;
};

const defaultFallback = (
  <div className="flex flex-col items-center justify-center w-full h-full gap-1">
    <Spin />
  </div>
);

const CustomSuspense: FC<CustomSuspenseProps> = ({
  children,
  enableDelay = false,
  fallback = defaultFallback,
  fallbackDelay = SHOW_FALLBACK_DELAY,
}) => {
  const [showFallback, setShowFallback] = useState(!enableDelay);

  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <FallbackWrapper
            onMount={() => {
              if (!enableDelay) return;
              setShowFallback(false);
              setTimeout(() => {
                setShowFallback(true);
              }, fallbackDelay);
            }}
          >
            {showFallback && fallback}
          </FallbackWrapper>
        }
      >
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};

export default CustomSuspense;
