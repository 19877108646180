import SignaturePad from "signature_pad";
import { useUploadBase64FileMutation } from "../../../types/generated/apollo/graphQLTypes";
import { useState } from "react";
import NotifyUserException from "../../../../utility-features/error-handling/NotifyUserException";

// Not for stand-alone use
// This hook is used in the `useSignaturePad` hook

export type UploadSignatureFuncType = () => Promise<string | null>;

const useUploadSignature: (signaturePad: SignaturePad | null) => {
  uploadSignature: UploadSignatureFuncType;
  uploading: boolean;
} = (signaturePad) => {
  const [uploadBase64] = useUploadBase64FileMutation();
  const [uploading, setUploading] = useState<boolean>(false);

  const uploadSignature: UploadSignatureFuncType = async () => {
    if (!signaturePad) {
      throw new NotifyUserException(
        "Cannot Upload Signature",
        "Try to refresh the page",
      );
    }
    setUploading(true);
    const data = signaturePad?.toDataURL();
    const uploadRes = await uploadBase64({
      variables: {
        input: { base64: data },
      },
    });
    const signatureUrl = uploadRes.data?.uploadBase64File;
    setUploading(false);
    return signatureUrl || null;
  };

  return { uploadSignature, uploading };
};

export default useUploadSignature;
