import React, { PropsWithChildren } from "react";
import CustomSuspense from "../general/CustomSuspense";

// This component is usually used as a view conteiner

interface BasicWrapperProps {
  scrollable?: boolean;
}

const BasicWrapper: React.FunctionComponent<
  PropsWithChildren<BasicWrapperProps>
> = (props) => {
  return (
    <div
      className={`w-full relative h-full p-2 bg-white rounded-2 ${
        !props.scrollable ? "flex flex-col items-center justify-center" : ""
      }`}
    >
      <CustomSuspense>
        {props.scrollable ? (
          <div className={`w-full h-full overflow-y-auto`}>
            <div className="flex flex-col justify-center w-full min-h-full">
              {props.children}
            </div>
          </div>
        ) : (
          props.children
        )}
      </CustomSuspense>
    </div>
  );
};

export default BasicWrapper;
