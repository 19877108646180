/**
 * @generated SignedSource<<dbf5883165bbd0eb52e49a5740a96aea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type uuid_comparison_exp = {
  _eq?: string | null | undefined;
  _gt?: string | null | undefined;
  _gte?: string | null | undefined;
  _in?: ReadonlyArray<string> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: string | null | undefined;
  _lte?: string | null | undefined;
  _neq?: string | null | undefined;
  _nin?: ReadonlyArray<string> | null | undefined;
};
export type SCReportsDailyDateQuery$variables = {
  date: string;
  projectBoolExp: uuid_comparison_exp;
  subcontractorId: string;
};
export type SCReportsDailyDateQuery$data = {
  readonly daily_work_log_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly created_at: string;
        readonly created_by_project_user: {
          readonly employee: {
            readonly user: {
              readonly name: string;
              readonly pk: string;
            };
          } | null | undefined;
          readonly project_worker: {
            readonly pk: string;
            readonly subcontractor: {
              readonly name: string;
            };
            readonly user: {
              readonly name: string;
            } | null | undefined;
          } | null | undefined;
        };
        readonly daily_work_log_tags: ReadonlyArray<{
          readonly id: string;
          readonly pk: string;
          readonly tag: {
            readonly color_hex: string;
            readonly id: string;
            readonly name: {
              readonly clientText: string;
              readonly en: string;
              readonly es: string;
              readonly id: string;
              readonly original: string;
              readonly pk: string;
              readonly pt: string;
            };
            readonly pk: string;
          };
        }>;
        readonly daily_work_log_workers: ReadonlyArray<{
          readonly hours: number;
          readonly project_worker: {
            readonly user: {
              readonly name: string;
            } | null | undefined;
          };
        }>;
        readonly description: {
          readonly clientText: string;
          readonly en: string;
          readonly es: string;
          readonly id: string;
          readonly original: string;
          readonly pk: string;
          readonly pt: string;
        };
        readonly id: string;
        readonly images: ReadonlyArray<{
          readonly blurhash: string | null | undefined;
          readonly description: string | null | undefined;
          readonly id: string;
          readonly lg_url: string | null | undefined;
          readonly md_url: string | null | undefined;
          readonly pk: string;
          readonly sm_url: string | null | undefined;
          readonly sort_index: number | null | undefined;
          readonly url: string;
        }>;
        readonly pk: string;
        readonly project: {
          readonly name: string;
        };
        readonly subcontractor: {
          readonly name: string;
          readonly pk: string;
        } | null | undefined;
        readonly submitted_at: string | null | undefined;
        readonly worker_count: number | null | undefined;
        readonly worker_hours: number | null | undefined;
      };
    }>;
  };
};
export type SCReportsDailyDateQuery = {
  response: SCReportsDailyDateQuery$data;
  variables: SCReportsDailyDateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectBoolExp"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subcontractorId"
},
v3 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "date"
          }
        ],
        "kind": "ObjectValue",
        "name": "date"
      },
      {
        "kind": "Variable",
        "name": "project_id",
        "variableName": "projectBoolExp"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "subcontractorId"
          }
        ],
        "kind": "ObjectValue",
        "name": "subcontractor_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "submitted_at",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worker_count",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worker_hours",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = [
  (v10/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "es",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pt",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "original",
  "storageKey": null
},
v16 = [
  (v4/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v5/*: any*/),
  {
    "alias": "clientText",
    "args": null,
    "kind": "ScalarField",
    "name": "__original_clientText",
    "storageKey": null
  }
],
v17 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "sort_index": "asc"
    }
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sm_url",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "md_url",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lg_url",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blurhash",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sort_index",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color_hex",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hours",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "user",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v28 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v29 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v30 = [
  (v10/*: any*/),
  (v4/*: any*/)
],
v31 = [
  (v4/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v28/*: any*/),
  (v29/*: any*/),
  {
    "alias": "clientText",
    "args": null,
    "kind": "ScalarField",
    "name": "original",
    "storageKey": null
  },
  {
    "alias": "clientText",
    "args": null,
    "filters": null,
    "handle": "clientText",
    "key": "",
    "kind": "ScalarHandle",
    "name": "original"
  }
],
v32 = {
  "alias": null,
  "args": null,
  "concreteType": "user",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": (v30/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SCReportsDailyDateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "daily_work_logConnection",
        "kind": "LinkedField",
        "name": "daily_work_log_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "daily_work_logEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "daily_work_log",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "description",
                    "plural": false,
                    "selections": (v16/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v17/*: any*/),
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": "images(order_by:{\"sort_index\":\"asc\"})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "daily_work_log_tag",
                    "kind": "LinkedField",
                    "name": "daily_work_log_tags",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "tag",
                        "kind": "LinkedField",
                        "name": "tag",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v25/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "text_translation",
                            "kind": "LinkedField",
                            "name": "name",
                            "plural": false,
                            "selections": (v16/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "daily_work_log_worker",
                    "kind": "LinkedField",
                    "name": "daily_work_log_workers",
                    "plural": true,
                    "selections": [
                      (v26/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_worker",
                        "kind": "LinkedField",
                        "name": "project_worker",
                        "plural": false,
                        "selections": [
                          (v27/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project_user",
                    "kind": "LinkedField",
                    "name": "created_by_project_user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_worker",
                        "kind": "LinkedField",
                        "name": "project_worker",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "subcontractor",
                            "kind": "LinkedField",
                            "name": "subcontractor",
                            "plural": false,
                            "selections": (v11/*: any*/),
                            "storageKey": null
                          },
                          (v27/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_employee",
                        "kind": "LinkedField",
                        "name": "employee",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "user",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SCReportsDailyDateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "daily_work_logConnection",
        "kind": "LinkedField",
        "name": "daily_work_log_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "daily_work_logEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "daily_work_log",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v28/*: any*/),
                  (v29/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v30/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v28/*: any*/),
                      (v29/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "description",
                    "plural": false,
                    "selections": (v31/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v17/*: any*/),
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v28/*: any*/),
                      (v29/*: any*/)
                    ],
                    "storageKey": "images(order_by:{\"sort_index\":\"asc\"})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "daily_work_log_tag",
                    "kind": "LinkedField",
                    "name": "daily_work_log_tags",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v28/*: any*/),
                      (v29/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "tag",
                        "kind": "LinkedField",
                        "name": "tag",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v25/*: any*/),
                          (v28/*: any*/),
                          (v29/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "text_translation",
                            "kind": "LinkedField",
                            "name": "name",
                            "plural": false,
                            "selections": (v31/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "daily_work_log_worker",
                    "kind": "LinkedField",
                    "name": "daily_work_log_workers",
                    "plural": true,
                    "selections": [
                      (v26/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_worker",
                        "kind": "LinkedField",
                        "name": "project_worker",
                        "plural": false,
                        "selections": [
                          (v32/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project_user",
                    "kind": "LinkedField",
                    "name": "created_by_project_user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_worker",
                        "kind": "LinkedField",
                        "name": "project_worker",
                        "plural": false,
                        "selections": [
                          (v28/*: any*/),
                          (v29/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "subcontractor",
                            "kind": "LinkedField",
                            "name": "subcontractor",
                            "plural": false,
                            "selections": (v30/*: any*/),
                            "storageKey": null
                          },
                          (v32/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_employee",
                        "kind": "LinkedField",
                        "name": "employee",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "user",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v28/*: any*/),
                              (v29/*: any*/),
                              (v10/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "15963839c7b1179d43c66b0d55b040fe",
    "id": null,
    "metadata": {},
    "name": "SCReportsDailyDateQuery",
    "operationKind": "query",
    "text": "query SCReportsDailyDateQuery(\n  $subcontractorId: uuid!\n  $date: date!\n  $projectBoolExp: uuid_comparison_exp!\n) {\n  daily_work_log_connection(where: {subcontractor_id: {_eq: $subcontractorId}, project_id: $projectBoolExp, date: {_eq: $date}}) {\n    edges {\n      node {\n        id\n        pk: id\n        submitted_at\n        created_at\n        worker_count\n        worker_hours\n        project {\n          name\n          id\n        }\n        subcontractor {\n          name\n          pk: id\n          id\n        }\n        description {\n          id\n          en\n          es\n          pt\n          original\n          pk: id\n          clientText: original\n        }\n        images(order_by: {sort_index: asc}) {\n          id\n          url\n          sm_url\n          md_url\n          lg_url\n          blurhash\n          sort_index\n          description\n          pk: id\n        }\n        daily_work_log_tags {\n          id\n          pk: id\n          tag {\n            id\n            color_hex\n            pk: id\n            name {\n              id\n              en\n              es\n              pt\n              original\n              pk: id\n              clientText: original\n            }\n          }\n        }\n        daily_work_log_workers {\n          hours\n          project_worker {\n            user {\n              name\n              id\n            }\n            id\n          }\n          id\n        }\n        created_by_project_user {\n          project_worker {\n            pk: id\n            subcontractor {\n              name\n              id\n            }\n            user {\n              name\n              id\n            }\n            id\n          }\n          employee {\n            user {\n              pk: id\n              name\n              id\n            }\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7dfb3ad072a1cd1fddd4030998060bcb";

export default node;
