import { IconBriefcase, IconCalendar, IconMan } from "@tabler/icons";
import { Button as AntdButton, Input, Popover } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, forwardRef, useEffect, useRef, useState } from "react";
import { useRelayEnvironment } from "react-relay/hooks";
import { commitMutation } from "relay-runtime";
import Tag from "src/common/components/general/Tag";
import capitalize from "src/common/functions/capitalize";
import { useSiteOrientationGcDashboardDataQuery$data } from "src/common/types/generated/relay/useSiteOrientationGcDashboardDataQuery.graphql";
import { OrientationProjectEmployeeTableQuery } from "src/common/types/generated/relay/OrientationProjectEmployeeTableQuery.graphql";
import { CustomFilterType } from "src/common/components/filter/CustomFilterComponents/CustomFilterController";
import Icon from "src/common/components/general/Icon";
import { OrientationProjectEmployeeTable_project_employee$data } from "src/common/types/generated/relay/OrientationProjectEmployeeTable_project_employee.graphql";
import OrientationDataScrollTable, {
  OrientationDataScrollTableImplementorProps,
  OrientationDataScrollTableProps,
  OrientationDataScrollTableRef,
} from "./OrientationDataScrollTable";

import { getLatestDate } from "src/common/components/Project";
import getOrientationCompleted from "../../../utils/getOrientationCompleted";
import QuizResult from "./QuizResults";
import { returnVal } from "./OrientationProjectWorkerTable";
import PartialResultContent from "./PartialResultContent";

export type ColumnKeys =
  | "name"
  | "subcontractor"
  | "hardHatNumber"
  | "title"
  | "role"
  | "orientatedOn"
  | "orientated_at"
  | "project"
  | "registeredDate"
  | "inPersonOrientatedDate"
  | "extra"
  | "status"
  | "archivedAt"
  | "drugTest"
  | "drugtestDate"
  | "trainingAndCertifications";

const CONNECTION_NAME = "project_employee_connection";
export type DConnection =
  OrientationProjectEmployeeTable_project_employee$data[typeof CONNECTION_NAME];

type Props = OrientationDataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  OrientationProjectEmployeeTableQuery,
  { pk: string }
> & {
  projectId: string;
  requiredOrientationIds: Array<string>;
  searchString?: string;
  extraColumns?: OrientationDataScrollTableProps<
    DConnection,
    ColumnKeys,
    OrientationProjectEmployeeTableQuery
  >["columns"];
  markDeleted?: boolean;
  customFilters?: CustomFilterType[];
  headerComponent?: React.ReactElement;
  datePickerDataTitle?: string;
  datePickerDataIndex?: string[];
  showOrientationTag?: boolean;
  onAddHH?: (user_id: string) => void;
  nextHH?: number;
  subs?: useSiteOrientationGcDashboardDataQuery$data["project_connection"]["edges"][0]["node"]["project_subcontractors"];
  titles?: useSiteOrientationGcDashboardDataQuery$data["worker_title_connection"]["edges"];
  showSubAdminTag?: boolean;
  hasInPerson?: boolean;
  hasSlides?: boolean;
  assignHardHat?: boolean;
  allowEditHH?: boolean;
  defaultSortColumnKey?: ColumnKeys;
};

const saveHHMutation2 = graphql`
  mutation OrientationProjectEmployeeTable_SaveHHMutation(
    $projectEmployeeId: uuid!
    $hardHat: String!
  ) {
    update_project_employee_by_pk(
      pk_columns: { id: $projectEmployeeId }
      _set: { hard_hat_number: $hardHat }
    ) {
      pk: id @__clientField(handle: "pk")
      hard_hat_number
    }
  }
`;

interface EditableHHCellProps {
  value: string | null;
  record: DConnection["edges"][number]["node"];
  projectId: string;
  initiallyOpen: boolean;
}

const EditableHHCell: FC<EditableHHCellProps> = ({
  value,
  record,
  projectId,
  ...props
}) => {
  const [editing, setEditing] = useState(false);
  // const [val, setVal] = useState(value);

  const editRef = useRef<React.ElementRef<typeof Input>>(null);
  const environment = useRelayEnvironment();

  useEffect(() => {
    if (editing) {
      if (editRef?.current?.input) editRef.current.input.value = value ?? "";
    }
  }, [editing]);

  const onSave = () => {
    const pk = record["pk"];
    const value = editRef?.current?.input?.value;
    if (!value?.trim?.()) {
      console.log(value, "Value");

      return;
    }
    // console.log(value, "ValueToBeMutated");
    commitMutation(environment, {
      mutation: saveHHMutation2,
      variables: {
        projectId,
        userId: record.employee_id,
        projectEmployeeId: record.pk,
        hardHat: value,
      },
      onCompleted: () => {
        setEditing(false);
      },
    });
  };
  const onCancel = () => {
    setEditing(false);
  };

  if (editing || props.initiallyOpen) {
    return (
      <Input
        ref={editRef}
        size="large"
        onBlur={onSave}
        onPressEnter={onSave}
        onKeyDown={(e) => {
          if (e.key === "Esc" || e.key === "Escape") {
            e.stopPropagation();
            e.preventDefault();
            setEditing(false);
          }
        }}
      />
    );
  }
  return (
    <div className="editable-cell-value-wrap" onClick={() => setEditing(true)}>
      {value}
    </div>
  );
};

// declare getSuffix to avoid depricated substr
function getSuffix(s: string, len: number) {
  return s.substring(Math.max(s.length - len, 0));
}

interface OrientatedDateCellProps {
  record: DConnection["edges"][number]["node"];
  value: any;
  projectId: string;
}

const buttonstyle = {
  borderRadius: "20px",
  fontSize: "14px",
  height: "auto",
  color: `white`,
};
const OrientationProjectEmployeeTable = forwardRef<
  OrientationDataScrollTableRef,
  Props
>(
  (
    {
      title = "Workers",
      onRowClick,
      showOrientationTag = false,
      assignHardHat,
      searchString,
      hasInPerson,
      hasSlides,
      datePickerDataTitle,
      datePickerDataIndex,
      nextHH,
      headerComponent,
      requiredOrientationIds,
      customFilters,
      extraColumns,
      where,
      projectId,
      allowEditHH,
      defaultSortColumnKey,
      ...props
    },
    ref,
  ) => {
    return (
      <OrientationDataScrollTable<
        DConnection,
        ColumnKeys,
        OrientationProjectEmployeeTableQuery
      >
        {...props}
        newCustomTableLook
        defaultCustomInitialSort={true}
        ref={ref}
        title={title}
        onRowClick={
          onRowClick
            ? ({ pk }) => {
                onRowClick({ pk });
              }
            : undefined
        }
        where={{ ...where }}
        customFilters={customFilters}
        headerComponent={headerComponent}
        extraQueryVariables={{ projectId }}
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allProjectEmployeesConnection"}
        datePickerDataIndex={datePickerDataIndex}
        datePickerDataTitle={datePickerDataTitle}
        columns={[
          {
            title: "Status",
            key: "status",
            size: "md",
            defaultSortOrder:
              defaultSortColumnKey === undefined ||
              defaultSortColumnKey === "status"
                ? "asc"
                : undefined,
            sortable: true,
            dataIndex: ["employee", "user", "name"],
            clientCompareFn: (a, b) => {
              let orientationCompletedA: boolean | null =
                getOrientationCompleted(
                  a.user_orientation,
                  hasSlides,
                  hasInPerson,
                );

              let orientationCompletedB: boolean | null =
                getOrientationCompleted(
                  b.user_orientation,
                  hasSlides,
                  hasInPerson,
                );

              const valueA = orientationCompletedA
                ? assignHardHat && a.hard_hat_number === null
                  ? 2
                  : 3
                : 1;
              const valueB = orientationCompletedB
                ? assignHardHat && b.hard_hat_number === null
                  ? 2
                  : 3
                : 1;
              const orientDateA = getOrientationCompleted(
                a.user_orientation,
                hasSlides,
                hasInPerson,
              )
                ? a.user_orientation?.completed_at ??
                  getLatestDate(
                    a.user_orientation?.in_person_orientated_at,
                    a.user_orientation?.orientated_at,
                  )
                : undefined;
              const orientDateB = getOrientationCompleted(
                b.user_orientation,
                hasSlides,
                hasInPerson,
              )
                ? b.user_orientation?.completed_at ??
                  getLatestDate(
                    b.user_orientation?.in_person_orientated_at,
                    b.user_orientation?.orientated_at,
                  )
                : undefined;
              return !valueA || !valueB
                ? !valueA < !valueB
                  ? 1
                  : !valueA > !valueB
                  ? -1
                  : valueA === 3 || valueB === 3 || valueA === 2 || valueB === 2
                  ? -returnVal(orientDateA, orientDateB)
                  : -returnVal(a.created_at, b.created_at)
                : valueA < valueB
                ? -1
                : valueA > valueB
                ? 1
                : valueA === 3 || valueA === 2
                ? -returnVal(orientDateA, orientDateB)
                : -returnVal(a.created_at, b.created_at);
            },
            render: (r, a) => {
              let orientationCompleted: boolean | null =
                getOrientationCompleted(
                  a.user_orientation,
                  hasSlides,
                  hasInPerson,
                );
              const completedModules: Set<string> = new Set();
              const results = a.user_orientation
                ? a.user_orientation.all_user_orientation_results.filter(
                    (p) => {
                      if (
                        p.orientation?.type === "corporate" ||
                        p.project_id === projectId ||
                        (p.project &&
                          p.project.linked_orientation_projects.find(
                            (p) => p.pk === projectId,
                          ))
                      ) {
                        if (p.status === "completed" || p.completed_at)
                          p.orientation_id &&
                            completedModules.add(p.orientation_id);
                        return (
                          p.status === "completed" ||
                          p.completed_at ||
                          requiredOrientationIds.includes(p.orientation_id!)
                        );
                      }
                      return false;
                    },
                  )
                : [];

              return (
                <>
                  {orientationCompleted ? (
                    assignHardHat && a.hard_hat_number === null ? (
                      <AntdButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          props.onAddHH && props.onAddHH(a.pk);
                        }}
                        className="text-0.75 bg-white text-semantic-pending border-0.125 border-semantic-pending hover:text-semantic-pending hover:border-semantic-pending rounded-2 mb-0.25"
                      >
                        Add HH #
                      </AntdButton>
                    ) : (
                      <>
                        {completedModules.size > 0 ? (
                          <Popover
                            placement="right"
                            trigger="hover"
                            title="ORIENTATION COMPLETE"
                            content={
                              <PartialResultContent
                                workerId={a.employee_id}
                                orientationIds={Array.from(
                                  new Set(completedModules),
                                )}
                              />
                            }
                          >
                            <div className="text-0.75 p-0.25 bg-white text-semantic-positive-green border-0.125 border-semantic-positive-green rounded-2 mb-0.25">
                              Orientated
                            </div>
                          </Popover>
                        ) : (
                          <div className="text-0.75 p-0.25 bg-white text-semantic-positive-green border-0.125 border-semantic-positive-green rounded-2 mb-0.25">
                            Orientated
                          </div>
                        )}
                      </>
                    )
                  ) : (
                    <div className="text-0.75 p-0.25 bg-white text-semantic-negative border-0.125 border-semantic-negative rounded-2 mb-0.25">
                      Not Orientated
                    </div>
                  )}
                  {!orientationCompleted && (
                    <div>
                      {/* {a.worker?.worker_projects[0].archived_at && (
                            <div className="text-0.75 p-0.25 bg-white text-warn border-0.125 border-warn rounded-2 mb-0.25">
                              Archived
                            </div>
                          )} */}
                      {a.user_orientation?.in_person_orientated_at &&
                        showOrientationTag && (
                          <Popover
                            placement="right"
                            trigger={"hover"}
                            title="ORIENTATION INCOMPLETE"
                            content={
                              <PartialResultContent
                                notCompleted
                                workerId={a.employee_id}
                                orientationIds={Array.from(
                                  new Set(
                                    results.map((p) => p.orientation_id!),
                                  ),
                                )}
                              />
                            }
                          >
                            <div>
                              <Tag status="positive">✅ InPerson</Tag>
                            </div>
                          </Popover>
                        )}

                      {a.user_orientation &&
                      showOrientationTag &&
                      // (
                      a.user_orientation.orientated_at ? (
                        // ||
                        // element.user_orientation.site_specific_results.some(
                        //   (or) => or.status === "completed"
                        // ))
                        <Popover
                          placement="right"
                          trigger={"hover"}
                          content={"In-Person orientation is remaining"}
                        >
                          <div>
                            <Tag status="positive">✅ Online</Tag>
                          </div>
                        </Popover>
                      ) : (
                        results.length > 0 &&
                        !a.user_orientation?.in_person_orientated_at && (
                          <Popover
                            placement="right"
                            title="ORIENTATION INCOMPLETE"
                            trigger={"hover"}
                            content={
                              <PartialResultContent
                                notCompleted
                                workerId={a.employee_id}
                                orientationIds={Array.from(
                                  new Set(
                                    results.map((p) => p.orientation_id!),
                                  ),
                                )}
                              />
                            }
                          >
                            <div>
                              <Tag status="incomplete">INCOMPLETE</Tag>
                            </div>
                          </Popover>
                        )
                      )}
                    </div>
                  )}
                </>
              );
            },
          },
          {
            title: "Name",
            key: "name",
            size: "md",
            sortable: true,
            contentType: {
              type: "row-title",
            },
            // clientCompareFn: (a, b) => returnVal(a.user?.name, b.user?.name),
            // filterCreater: (obj) => ({ user: { name: obj } }),
            defaultSortOrder:
              defaultSortColumnKey === undefined ||
              defaultSortColumnKey === "name"
                ? "asc"
                : undefined,
            dataIndex: ["employee", "user", "name"],
            searchDataIndex: ["employee", "user", "name"],

            render: (_, a) => {
              return (
                <div className="flex">
                  {a.employee.user.name}
                  <Icon icon={IconMan} />
                </div>
              );
            },
          },
          // {
          //   title: "Project",
          //   key: "project",
          //   size: "md",
          //   dataIndex: ["worker", "worker_projects"],
          //   searchDataIndex: ["worker", "worker_projects", "project", "name"],
          //   includeInMainSearchBox: false,
          //   render: (a, r) => {
          //     if (r.worker) return r.worker.worker_projects[0].project.name;
          //   },
          // },
          {
            title: "Company",
            key: "subcontractor",
            size: "md",
            sortable: true,
            dataIndex: ["oac_company", "title"],
            searchValue: searchString,
            filterCreater: (obj) => ({
              _or: [
                { oac_company_id: { _eq: obj } },
                { employee: { general_contractor_id: { _eq: obj } } },
              ],
            }),
            includeInMainSearchBox: false,
            ...(props.subs
              ? {
                  // filters: {
                  //   type: "checklist",
                  //   initialClose: true,
                  //   dataIndex: ["subcontractor_id"],
                  //   options: props.subs.map((r) => ({
                  //     text: capitalize(r.name),
                  //     value: r.id,
                  //   })),
                  // },
                }
              : {}),
            // contentType: {
            //   type: "with-icon",
            //   renderOptions: (value) => ({ icon: IconBriefcase, label: value }),
            // },
            render: (val, a) => (
              <div className="flex flex-row gap-1">
                <Icon icon={IconBriefcase} />
                {a.employee?.oac_company?.name ??
                  a.employee?.general_contractor.name}
              </div>
            ),
          },
          {
            title: "Hard Hat #",
            key: "hardHatNumber",
            dataIndex: ["hard_hat_number"],
            size: "sm",
            searchDataIndex: ["hard_hat_number"],
            sortable: true,
            clientCompareFn: (a, b) => {
              const sa = getSuffix("0000000000" + a?.hard_hat_number || "", 10);
              const sb = getSuffix("0000000000" + b?.hard_hat_number || "", 10);

              return sa === sb ? 0 : sa > sb ? 1 : -1;
            },
            // filterCreater: (obj) => ({ hard_hat_number: obj }),
            render: (hh, a) => {
              let orientationCompleted = getOrientationCompleted(
                a.user_orientation,
                hasSlides,
                hasInPerson,
              );
              if (!(hasSlides || hasInPerson)) orientationCompleted = true;

              return orientationCompleted && allowEditHH && projectId ? (
                <EditableHHCell
                  value={hh}
                  record={a}
                  projectId={projectId}
                  initiallyOpen={!hh?.trim?.()}
                />
              ) : (
                hh
              );
            },
          },
          {
            title: "Title",
            key: "title",
            sortable: true,
            size: "sm",
            dataIndex: ["title", "translation", "en"],
            // clientCompareFn: (a, b) => {
            //   return returnVal(
            //     a.title?.translation?.en,
            //     b.title?.translation?.en
            //   );
            // },
            filterCreater: (obj) => ({ title_id: obj }),
            includeInMainSearchBox: false,
            ...(props.titles
              ? {
                  filters: {
                    type: "checklist",
                    initialClose: true,
                    dataIndex: ["title_id"],
                    options: props.titles.map((r) => ({
                      text: capitalize(r.node.translation.en),
                      value: r.node.pk,
                    })),
                  },
                }
              : {}),
            render: (a, r) =>
              r.employee?.oac_title?.name ??
              r.employee?.employee_title?.name?.en,
          },
          {
            sortable: true,
            title: "Corporate Video Date",
            key: "inPersonOrientatedDate",
            size: "sm",
            dataIndex: ["user_orientation", "in_person_orientated_at"],
            defaultSortOrder:
              defaultSortColumnKey === "inPersonOrientatedDate"
                ? "desc"
                : undefined,
            // filterCreater: (obj) => {
            //   return {
            //     _or: [
            //       {
            //         worker: {
            //           worker_projects: {
            //             project_id: { _eq: projectId },
            //             is_last: { _eq: true },
            //             user_orientation: { in_person_orientated_at: obj },
            //           },
            //         },
            //       },
            //       {
            //         employee: {
            //           employee_projects: {
            //             project_id: { _eq: projectId },
            //             user_orientation: { in_person_orientated_at: obj },
            //           },
            //         },
            //       },
            //     ],
            //   };
            // },
            clientCompareFn: (a, b) => {
              let valueA = undefined;

              if (a.user_orientation) {
                let corporateCompletedA: boolean | undefined = undefined;
                const corporateOrient =
                  a.user_orientation.all_user_orientation_results.filter(
                    (o) =>
                      o.status === "completed" &&
                      o.orientation?.type === "corporate",
                  );
                corporateOrient.forEach((or) => {
                  corporateCompletedA =
                    corporateCompletedA !== undefined
                      ? corporateCompletedA &&
                        (!!or.completed_at || or.status === "completed")
                      : !!or.completed_at || or.status === "completed";
                });
                valueA = corporateCompletedA
                  ? a.user_orientation.orientated_at ??
                    corporateOrient[0].completed_at
                  : undefined;
              }

              let valueB = undefined;

              if (b.user_orientation) {
                let corporateCompletedB: boolean | undefined = undefined;
                const corporateOrien =
                  b.user_orientation.all_user_orientation_results.filter(
                    (o) =>
                      o.status === "completed" &&
                      o.orientation?.type === "corporate",
                  );
                corporateOrien.forEach((or) => {
                  corporateCompletedB =
                    corporateCompletedB !== undefined
                      ? corporateCompletedB &&
                        (!!or.completed_at || or.status === "completed")
                      : !!or.completed_at || or.status === "completed";
                });
                valueB = corporateCompletedB
                  ? b.user_orientation.orientated_at ??
                    corporateOrien[0].completed_at
                  : undefined;
              }

              return returnVal(valueA, valueB);
            },
            render: (_, a) => {
              if (!a.user_orientation) return <>not complete</>;

              let corporateCompleted: boolean | undefined = undefined;
              const corporateOrient =
                a.user_orientation.all_user_orientation_results.filter(
                  (o) =>
                    o.status === "completed" &&
                    o.orientation?.type === "corporate",
                );
              corporateOrient.forEach((or) => {
                corporateCompleted =
                  corporateCompleted !== undefined
                    ? corporateCompleted &&
                      (!!or.completed_at || or.status === "completed")
                    : !!or.completed_at || or.status === "completed";
              });
              const date = corporateCompleted
                ? a.user_orientation.orientated_at ??
                  corporateOrient[0].completed_at
                : undefined;

              return (
                <div className="flex">
                  {date === undefined ? (
                    "not complete"
                  ) : date === null ? (
                    "not logged"
                  ) : (
                    <>
                      <Icon icon={IconCalendar} />
                      {dayjs(date).format("M/DD/YYYY")}
                    </>
                  )}
                </div>
              );
            },
          },
          {
            sortable: true,
            title: "Orientated Date",
            key: "orientatedOn",
            size: "md",
            dataIndex: ["user_orientation", "orientated_at"],
            defaultSortOrder:
              defaultSortColumnKey === "orientatedOn" ? "desc" : undefined,
            clientCompareFn: (a, b) => {
              let valueA, valueB;
              if (a.user_orientation) {
                valueA = getOrientationCompleted(
                  a.user_orientation,
                  hasSlides,
                  hasInPerson,
                )
                  ? a.user_orientation.completed_at ??
                    getLatestDate(
                      a.user_orientation.in_person_orientated_at,
                      a.user_orientation.orientated_at,
                    )
                  : undefined;
              }
              if (b.user_orientation) {
                valueB = getOrientationCompleted(
                  b.user_orientation,
                  hasSlides,
                  hasInPerson,
                )
                  ? b.user_orientation.completed_at ??
                    getLatestDate(
                      b.user_orientation.in_person_orientated_at,
                      b.user_orientation.orientated_at,
                    )
                  : undefined;
              }

              return returnVal(valueA, valueB);
            },
            render: (_, a) => {
              const date =
                getOrientationCompleted(
                  a.user_orientation,
                  hasSlides,
                  hasInPerson,
                ) && a.user_orientation
                  ? a.user_orientation.completed_at ??
                    getLatestDate(
                      a.user_orientation.in_person_orientated_at,
                      a.user_orientation.orientated_at,
                    )
                  : undefined;
              const orientationMap: {
                [key: string]: NonNullable<
                  DConnection["edges"][number]["node"]["user_orientation"]
                >["all_user_orientation_results"][number];
              } = {};

              const results = (
                a.user_orientation?.all_user_orientation_results || []
              ).filter(
                (or) =>
                  or.status === "completed" &&
                  (or.orientation?.type === "corporate" ||
                    or.project_id === projectId ||
                    //here project will be available as we're not calling universal orientation results
                    or.project?.linked_orientation_projects.find(
                      (p) => p.pk === projectId,
                    )),
              );
              const haveQuizAnswers = !!results.find(
                (or) => or.quiz_results.length,
              );
              results.forEach(
                (or) => (orientationMap[or.orientation_id ?? ""] = or),
              );
              return (
                <div className="flex">
                  {date === undefined ? (
                    "not complete"
                  ) : date === null ? (
                    "not logged"
                  ) : (
                    <>
                      <Icon icon={IconCalendar} />
                      {dayjs(date).format("M/DD/YYYY")}
                    </>
                  )}
                  {date && haveQuizAnswers && (
                    <Popover
                      content={
                        <QuizResult
                          orientationResults={Object.values(
                            orientationMap,
                          ).sort((a, b) => {
                            if (a.orientation.order === b.orientation.order)
                              return a.orientation.name.localeCompare(
                                b.orientation.name,
                              );
                            else
                              return a.orientation.order - b.orientation.order;
                          })}
                          emergencyContact={a.employee.user.emergency_contact}
                        />
                      }
                      placement="left"
                      trigger={"hover"}
                    >
                      <div className="text-1 ml-1">📝</div>
                    </Popover>
                  )}
                </div>
              );
            },
          },
          {
            sortable: true,
            title: "Registered Date",
            key: "registeredDate",
            size: "md",
            defaultSortOrder:
              defaultSortColumnKey === "registeredDate" ? "desc" : undefined,
            dataIndex: ["created_at"],

            render: (value, a) => {
              const date = a.created_at;
              return (
                <div className="flex">
                  {date === undefined ? (
                    "not complete"
                  ) : date === null ? (
                    "not logged"
                  ) : (
                    <>
                      <Icon icon={IconCalendar} />
                      {dayjs(date).format("M/DD/YYYY")}
                    </>
                  )}
                </div>
              );
            },
          },
          ...(extraColumns || []),
        ]}
        queryNode={graphql`
          query OrientationProjectEmployeeTableQuery(
            $first: Int!
            $after: String
            $where: project_employee_bool_exp!
            $order_by: [project_employee_order_by!]!
            $projectId: uuid!
          ) {
            ...OrientationProjectEmployeeTable_project_employee
              @arguments(
                first: $first
                after: $after
                where: $where
                projectId: $projectId
                order_by: $order_by
              )
            ...OrientationProjectEmployeeTable_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment OrientationProjectEmployeeTable_total on query_root
          @argumentDefinitions(where: { type: "project_employee_bool_exp!" })
          @refetchable(
            queryName: "OrientationProjectEmployeeTableTotalRefetchableQuery"
          ) {
            allProjectEmployeesConnection: project_employee_connection(
              where: $where
            ) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment OrientationProjectEmployeeTable_project_employee on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "project_employee_bool_exp!" }
            projectId: { type: "uuid!" }
            order_by: { type: "[project_employee_order_by!]!" }
          )
          @refetchable(
            queryName: "OrientationProjectEmployeeTableRefetchableQuery"
          ) {
            project_employee_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "OrientationProjectEmployeeTable_project_employee_connection"
                filters: ["where", "order_by"]
              ) {
              edges {
                node {
                  created_at
                  hard_hat_number
                  employee_id
                  pk: id @__clientField(handle: "pk")
                  employee {
                    user {
                      name
                      emergency_contact
                      email
                      pk: id @__clientField(handle: "pk")
                    }
                    general_contractor {
                      name
                    }
                    employee_title {
                      name {
                        en
                      }
                    }
                    oac_company {
                      name
                    }
                    oac_title {
                      name
                    }
                  }
                  user_orientation {
                    ...UserOrientationFrag @relay(mask: false)
                    all_user_orientation_results(
                      where: {
                        #status: { _eq: "completed" }
                        project: {
                          general_contractor: {
                            projects: { id: { _eq: $projectId } }
                          }
                        }
                      }
                      order_by: [{ completed_at: asc }, { created_at: asc }]
                    ) {
                      completed_at
                      status
                      orientation_id
                      project_id
                      project {
                        linked_orientation_projects {
                          pk: id @__clientField(handle: "pk")
                        }
                      }
                      orientation {
                        type
                        name
                        order
                      }
                      quiz_results(order_by: { slide: { order: asc } }) {
                        orientation_slide_id
                        answer
                        lang
                        slide {
                          title
                          question_ui_element
                          answer_items
                          answer_items_es
                          answer_items_pt
                          answer_items_it
                          answer_items_fr
                          answer_items_zh_hans
                          check_correct_answer
                          correct_answers
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);

export default OrientationProjectEmployeeTable;
