import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useUpdateUserWorkerMutation } from "src/common/types/generated/relay/useUpdateUserWorkerMutation.graphql";

const updateUserWorker = graphql`
  mutation useUpdateUserWorkerMutation(
    $userId: uuid!
    $userSet: user_set_input!
    $workerSet: worker_set_input!
  ) {
    update_user_by_pk(pk_columns: { id: $userId }, _set: $userSet) {
      id
      name
      email
      phone_number
      emergency_contact
    }
    update_worker_by_pk(pk_columns: { uid: $userId }, _set: $workerSet) {
      uid
      gender
      age
      ethnicity
      veteran_type
    }
  }
`;
const useUpdateUserWorker = () => {
  return useAsyncMutation<useUpdateUserWorkerMutation>(updateUserWorker);
};
export default useUpdateUserWorker;
