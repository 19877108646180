import * as React from "react";
import useFlagOrUnFlagTask from "src/common/api/relay/mutationHooks/useFlagOrUnflagTask";

interface TaskFlagForActionProps {
  status: "flagged" | "unflagged";
  taskId: string;
  onClick: () => void;
}

const TaskFlagForAction = (props: TaskFlagForActionProps) => {
  const [flagOrUnflagJha] = useFlagOrUnFlagTask();
  return (
    <div
      className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
      onClick={async (e) => {
        e.stopPropagation();

        if (props.status === "flagged") {
          flagOrUnflagJha(props.taskId, "unflag");
        } else {
          props.onClick();
        }
      }}
    >
      {props.status === "flagged"
        ? "Remove Flagged Status"
        : "Flag For Action 🚩"}
    </div>
  );
};
export default TaskFlagForAction;
