import React, { PropsWithChildren, FunctionComponent, useMemo } from "react";
import { Layout, Menu, Divider } from "antd";
import styled from "styled-components";
import Link from "src/common/components/general/Link";
import StyledContent from "src/common/components/layouts/StyledContent";
import LayoutBackButton from "src/common/components/LayoutBackButton";
import { useLocation } from "react-router-dom";
import { useGetSfCertsDataQuery } from "src/common/types/generated/apollo/graphQLTypes";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const SideBar = styled(Sider).attrs(() => ({
  theme: "light",
  width: 250,
}))``;

const SFEmployee: React.FC<PropsWithChildren> = ({ children }) => {
  const { data, loading, error } = useGetSfCertsDataQuery({});

  const certsLength = data?.certificates_to_verify_aggregate.aggregate?.count;
  const sdsLength = data?.sds_request_aggregate.aggregate?.count;

  const sideMenuItems = useMemo(
    () => [
      {
        path: `/sfe/verify`,
        title: "Verify Certificates",
        match: /\/sfe\/verify\/certificate/,
      },
      {
        path: `/sfe/verify/drugtest`,
        title: "Verify Drug Test Results",
        match: /\/sfe\/verify\/drugtest/,
      },

      {
        path: "/sfe/verify/profile_photo",
        title: `Verify Profile Photo (${certsLength} )`,
        match: /\/sfe\/verify\/profile_photo/,
      },
      {
        path: "/sfe/verify/rejected_archived",
        title: "Rejected And Archived Documeny",
        match: /\/sfe\/verify\/rejected_archived/,
      },
      {
        path: "/sfe/verify/jha",
        title: "Verify and Add JHAs",
        match: /\/sfe\/verify\/jha/,
      },
      {
        path: "/sfe/verify/subcontractors",
        title: "Subcontractor Verification",
        match: /\/sfe\/verify\/subcontractors/,
      },
      {
        path: "/sfe/verify/sds",
        title: `Veriry and Add SDS (${sdsLength})`,
        match: /\/sfe\/verify\/sds/,
      },
      {
        path: "/sfe/verify/updateCerts",
        title: "Update Certs Data",
        match: /\/sfe\/verify\/updateCerts/,
      },
    ],
    [data],
  );
  const location = useLocation();
  return (
    <Layout>
      <SideBar style={{ overflowY: "scroll", overflowX: "hidden" }}>
        <Content style={{ padding: 16 }}>
          <p style={{ fontWeight: "bold" }}>SF Employee</p>
        </Content>
        <Divider />
        <Menu
          mode="inline"
          selectedKeys={sideMenuItems
            .filter((i) => location.pathname.match(i.match))
            .map((i) => i.title)}
          style={{ height: "100%", borderRight: 0 }}
        >
          {sideMenuItems.map((i) => (
            <Menu.Item key={i.title}>
              <Link href={i.path}>{i.title}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </SideBar>
      <StyledContent
        padding
        backgroundColor="transparent"
        style={{
          overflowY: "scroll",
        }}
      >
        <LayoutBackButton match={/.\/sfe\/verify\/.*\/./} />
        {children}
      </StyledContent>
    </Layout>
  );
};

export default SFEmployee;
