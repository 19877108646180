import { Link } from "react-router-dom";

const DataErasureRequestScreen: React.FC = () => {
  return (
    <div className={`w-full h-full flex items-center justify-center`}>
      <div className="flex flex-col items-center gap-1.5">
        <div className="text-1.5 text-center items-center flex justify-center flex-col">
          Data Erasure Request
          <br />
          <br />
          <div className="w-4/6 font-normal ">
            If you would like to delete your profile and data from SiteForm
            please email{" "}
            <a
              href={"mailto:delete@siteform.io"}
              className="text-interactive-primary"
            >
              delete@siteform.io
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DataErasureRequestScreen;
