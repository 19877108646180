import NestedRoute from "../../../../../../common/types/manual/NestedRoute";
import searchParamsProvider from "../../../../../../common/functions/searchParamsProvider";
import QrProjectPtps from "./components/QrProjectPtps";
import QrProjectPtpReport from "./components/QrProjectPtpReport";
import { Outlet } from "react-router-dom";
import React from "react";

const ptpRoute: NestedRoute = {
  element: <Outlet />,

  children: [
    {
      path: "date/:date",
      element: searchParamsProvider(QrProjectPtps),
    },
    {
      path: "date/:date/reports/:reportId",
      element: searchParamsProvider(QrProjectPtpReport),
    },
  ],
};

export default ptpRoute;
