import downloadFromUrl from "src/common/functions/downloadFromUrl";
import SiteOrientationGcQrCodeRemote from "./SiteOrientationGcQrCodeRemote";
import PosterDownload from "../PosterDownload";
import React, { FC } from "react";
import { useGetOrienatationQrCodePosterMutation,useGenerateOrientationQrCodePosterNonAgcuoMutation } from "src/common/types/generated/apollo/graphQLTypes";

const RemoteQR: FC<{ agcUniversalOrientation: boolean; projectId: string }> = ({
  agcUniversalOrientation,
  projectId,
}) => {
  const [
    getOrientationQRCodePoster,
    { loading: orientationQRCodePosterLoading },
  ] = useGetOrienatationQrCodePosterMutation();
  const [
    getOrientationQRCodePosterNonUO,
    { loading: orientationQRCodePosterNonUOLoading },
  ] = useGenerateOrientationQrCodePosterNonAgcuoMutation();

  const agcPosterBackgroundImageUrl =
    "https://storage.googleapis.com/siteform-3170b.appspot.com/StaticAssets/AGC%20MA%20UNIVERSAL%20ORIENTATION%20(1).png";
  const nonAgcPosterBackgroundImageUrl =
    "https://storage.googleapis.com/siteform-3170b.appspot.com/StaticAssets/TEMPLATE%20-%20QR%20Code%20Orientation%20(1).png";

  return (
    <div className="flex justify-center w-full">
      <SiteOrientationGcQrCodeRemote projectId={projectId} />
      {agcUniversalOrientation ? (
         <PosterDownload
         imageUrl={agcPosterBackgroundImageUrl}
         header="QR Code Poster to Complete Orientation Online"
         description="Download your project specific poster"
         loading={orientationQRCodePosterLoading}
         onDownload={async () => {
           await getOrientationQRCodePoster({
             variables: { input: { projectId } },
           }).then((data) => {
             if (data.data?.generateOrientationQRCodePoster) {
               downloadFromUrl(data.data?.generateOrientationQRCodePoster);
             }
           });
         }}
       />
      ) : (
        <PosterDownload
        imageUrl={nonAgcPosterBackgroundImageUrl}
        header="Orientation QR Code Poster"
        description="Download your project specific poster"
        loading={orientationQRCodePosterNonUOLoading}
        onDownload={async () => {
          await getOrientationQRCodePosterNonUO({
            variables: { input: { projectId } },
          }).then((data) => {
            if (data.data?.generateOrientationQRCodePosterNonAGCUO) {
              downloadFromUrl(data.data?.generateOrientationQRCodePosterNonAGCUO);
            }
          });
        }}
      />
       
      )}
    </div>
  );
};
export default RemoteQR;
