import React, { FC } from "react";
import { Typography, Row, Col } from "antd";
import { ColProps } from "antd/lib/col";
import { RowProps } from "antd/lib/row";

const { Title, Text } = Typography;

type Renderer = (value: any, record?: any) => string | React.ReactNode | null;
export interface SectionFieldProps {
  field: { title: string; body: string | any };
  render?: Renderer;
}
export const SectionField: FC<SectionFieldProps> = ({
  field,
  render,
  ...props
}) => {
  const renderField = (
    data: {
      value: any | any[];
      record: any;
    },
    renderer: Renderer
  ): React.ReactNode | null => {
    const { value, record } = data;
    const rendered = renderer(value, record);
    return typeof rendered === "string" ||
      (React.isValidElement(rendered) && !Array.isArray(rendered)) ? (
      <Text>{rendered}</Text>
    ) : null;
  };
  let fieldValue = null;

  if (typeof render === "function") {
    fieldValue = renderField(field.body, render);
  } else {
    fieldValue = <Text>{field.body.value || ""}</Text>;
  }

  return (
    <Col key={"col-${fieldname}"} span={8} {...props}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Text type="secondary"> {field.title || ""} </Text>
        {fieldValue}
      </div>
    </Col>
  );
};

export interface SectionProps extends RowProps {
  title?: string;
}
export const Section: FC<SectionProps> = ({ title, children, ...props }) => {
  return title ? (
    <section id={title}>
      <Title level={4}>{title || ""}</Title>
      <hr></hr>
      <div style={{ padding: "16px 0" }}>
        <Row gutter={[48, 24]}>{children}</Row>
      </div>
    </section>
  ) : (
    <Row gutter={[48, 24]} {...props}>
      {children}
    </Row>
  );
};

export interface FieldRecord {
  key: string;
  dataIndex: string[] | string;
  displayName: string;
  fieldProps?: ColProps;
  render?: (value: any, record?: any) => string | React.ReactNode;
}
export interface GridFieldSectionProps extends SectionProps {
  fields: FieldRecord[];
  dataSource: any;
  skipKeys?: string[];
}
type DataFieldResolver = (
  path: string | string[],
  dataSource: any
) => string | any;
const fieldResolver: DataFieldResolver = (path, dataSource) => {
  let result: any = { record: dataSource };
  let current = dataSource;

  if (typeof path === "string") {
    result = {
      ...result,
      value: dataSource[path] !== undefined ? dataSource[path] : "",
    };
  } else {
    // Walk the path array for the value
    for (const key of path) {
      current = current[key] ? current[key] : "";
    }
    result = { ...result, value: current };
  }
  return result;
};
export const GridFieldSection: FC<GridFieldSectionProps> = ({
  title,
  fields,
  skipKeys,
  dataSource,
}) => {
  const filteredFields: FieldRecord[] = fields?.filter(
    ({ key }) => !(skipKeys ?? []).includes(key)
  );
  return (
    <Section title={title || ""}>
      {filteredFields?.map(
        ({ key, dataIndex = "", displayName, fieldProps, render }) => {
          return (
            <SectionField
              {...fieldProps}
              render={render}
              key={key}
              field={{
                title: displayName,
                body: fieldResolver(dataIndex, dataSource),
              }}
            />
          );
        }
      )}
    </Section>
  );
};
