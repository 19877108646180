import { graphql } from "babel-plugin-relay/macro";

const viewAndEditJhaQuery = graphql`
  query ViewAndEditJhaQuery(
    $taskId: uuid!
    $projectId: uuid!
    $currentUserId: uuid!
    $subId: uuid!
    $emailRequestWhere: email_request_bool_exp!
  ) {
    subcontractor_employee_connection(
      where: { subcontractor_id: { _eq: $subId } }
    ) {
      edges {
        node {
          user_id
          user {
            name
            email
          }
        }
      }
    }
    project_worker_connection(
      where: {
        subcontractor_id: { _eq: $subId }
        project_id: { _eq: $projectId }
      }
    ) {
      edges {
        node {
          worker_id
          user {
            name
            email
          }
        }
      }
    }

    user_connection(where: { id: { _eq: $currentUserId } }) {
      edges {
        node {
          name
          email
          role
          id
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    ecm_type_connection {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name {
            en
            es
            pt
            original
            id
          }
        }
      }
    }
    project_employee_connection(
      where: {
        project_id: { _eq: $projectId }
        employee: { user: { email: { _is_null: false } } }
      }
      order_by: { employee: { user: { name: asc } } }
    ) {
      edges {
        node {
          employee_id
          employee {
            user {
              name
              email
            }
          }
        }
      }
    }

    task_ppe_type_connection(
      first: 1000
      where: { task_id: { _eq: $taskId } }
      order_by: { ppe_type: { name: { en: asc } } }
    ) @connection(key: "ViewAndEditJha_task_ppe_type_connection", filters: []) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          ppe_type {
            name {
              en
            }
            id
            pk: id @__clientField(handle: "pk")
          }
        }
      }
    }

    task_permit_type_connection(
      first: 1000
      where: { task_id: { _eq: $taskId } }
      order_by: { permit_type: { name: { en: asc } } }
    )
      @connection(
        key: "ViewAndEditJha_task_permit_type_connection"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          permit_type {
            name {
              en
            }
            id
            pk: id @__clientField(handle: "pk")
          }
        }
      }
    }

    task_connection(first: 1000, where: { id: { _eq: $taskId } })
      @connection(key: "ViewAndEditJha_task_connection", filters: []) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          project_id
          reviewed_by_uid
          reviewed_at
          archived_at
          flagged_at
          updated_at
          reviewed_by_user {
            name
          }
          flagged_by_user {
            name
          }
          show_type
          description_id
          description {
            ...TextTranslationFrag @relay(mask: false)
          }
          taskReviewData: task_edit(
            where: { edit_type: { _eq: "Reviewed" } }
            order_by: { edited_at: desc }
            limit: 1
          ) {
            edited_at
          }

          created_by_user {
            name
          }

          project {
            general_contractor_id
            general_contractor {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
          }

          task_creation_request {
            type
            attached_files(order_by: { name: asc }) {
              url
            }
          }

          subAdminApprovals: task_signatures(
            where: {
              user: { role: { _eq: "subcontractor_employee" } }
              signature_image_id: { _is_null: true }
              is_active: { _eq: true }
            }
            order_by: { created_at: desc }
          ) {
            created_at
            id
            pk: id @__clientField(handle: "pk")
            user_id
          }

          workerSignatures: task_signatures(
            where: {
              user: { role: { _eq: "worker" } }
              signature_image_id: { _is_null: false }
              is_active: { _eq: true }
            }
            order_by: { created_at: desc }
          ) {
            created_at
            id
            pk: id @__clientField(handle: "pk")
          }
        }
      }
    }

    flaggedEdit: task_edit_connection(
      first: 1
      where: {
        edit_type: { _eq: "Flagged for action" }
        task_id: { _eq: $taskId }
      }
      order_by: { edited_at: desc }
    ) @connection(key: "ViewAndEditJha_flaggedEdit", filters: []) {
      edges {
        node {
          comment
          email_request_id
        }
      }
    }

    task_step_connection(
      first: 1000
      where: { task_id: { _eq: $taskId } }
      order_by: { sort_index: asc }
    ) @connection(key: "ViewAndEditJha_task_step_connection", filters: []) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          sort_index

          task_step_hazards {
            id
            pk: id @__clientField(handle: "pk")

            description_id
            control_id
            description {
              id
              pk: id @__clientField(handle: "pk")
              en
            }
            control {
              id
              pk: id @__clientField(handle: "pk")
              en
            }
            task_hazard_ecm_types {
              ecm_type_id
              ecm_type {
                id
                pk: id @__clientField(handle: "pk")
                name {
                  en
                }
              }
            }
          }
          description {
            id
            pk: id @__clientField(handle: "pk")
            en
          }
        }
      }
    }

    task_admin_connection(
      first: 1000
      where: { task_id: { _eq: $taskId } }
      order_by: { general_contractor_employee: { user: { name: asc } } }
    ) @connection(key: "ViewAndEditJha_task_admin_connection", filters: []) {
      edges {
        node {
          id
          general_contractor_employee {
            employee_title {
              name {
                en
              }
            }
            user {
              pk: id @__clientField(handle: "pk")
              name
            }
          }
        }
      }
    }

    task_edit_connection(
      first: 1000
      where: { task_id: { _eq: $taskId } }
      order_by: { edited_at: desc }
    ) @connection(key: "ViewAndEditJha_task_edit_connection", filters: []) {
      edges {
        node {
          task_patch
          edited_at
          edit_type
          edited_by_user {
            name
            role
            employee {
              general_contractor {
                name
              }
              employee_title {
                name {
                  en
                }
              }
            }
            subcontractor_employee {
              subcontractor {
                name
              }
              employee_title {
                name {
                  en
                }
              }
            }
            worker {
              current_worker_role
              subcontractor {
                name
              }
            }
          }
          email_request {
            email
          }
        }
      }
    }

    task_signature_connection(
      first: 1000
      where: { task_id: { _eq: $taskId }, is_active: { _eq: true } }
      order_by: { created_at: desc }
    )
      @connection(
        key: "ViewAndEditJha_task_signature_connection"
        filters: []
      ) {
      edges {
        node {
          id
          created_at
          pk: id @__clientField(handle: "pk")
          signature_image_id
          image {
            url
          }
          user_id
          user {
            name
            role
            subcontractor_employee {
              subcontractor {
                name
              }
              employee_title {
                name {
                  en
                }
              }
            }
            worker {
              current_worker_role
            }
          }
          email_request_id
          email_request {
            email
            user_id
          }
        }
      }
    }
    email_request_connection(
      where: $emailRequestWhere
      order_by: { created_at: desc }
    ) {
      edges {
        node {
          created_at
          sent_by_user {
            name
            employee {
              general_contractor {
                name
              }
            }
          }
        }
      }
    }

    ppe_category_connection(order_by: { name: { en: asc } }) {
      edges {
        node {
          id
          name {
            en
          }
          ppe_types(order_by: { name: { en: asc } }) {
            id
            pk: id @__clientField(handle: "pk")
            name {
              en
            }
          }
        }
      }
    }

    permit_type_connection(order_by: { name: { en: asc } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name {
            en
          }
        }
      }
    }
  }
`;

export default viewAndEditJhaQuery;
