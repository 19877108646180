import React from "react";
import PeopleListItemSecondary, {
  PeopleListItemSecondaryProps,
} from "./PeopleListItemSecondary";

export interface PeopleListSecondaryProps {
  items: PeopleListItemSecondaryProps[];
}

const PeopleListSecondary: React.FC<PeopleListSecondaryProps> = (props) => {
  return (
    <div className="flex flex-col gap-0.25">
      {props.items.map((itemProps, i) => (
        <PeopleListItemSecondary {...itemProps} key={i} />
      ))}
    </div>
  );
};

export default PeopleListSecondary;
