import { FilterData } from "../../ScrollTable";
import buildFieldRef from "./buildFieldRef";
import buildFilterConstraint from "./buildFilterConstraint";

const buildGraphQLFilter = (filters?: FilterData) => {
  if (!filters) return {};

  return {
    _and: filters.map(({ dataIndex, filter }) =>
      buildFieldRef(dataIndex, buildFilterConstraint(filter)),
    ),
  };
};
export default buildGraphQLFilter;
