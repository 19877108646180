import React, { useMemo } from "react";
import { InspectionResultType } from "../tables/InspectionResultTable";
import { Modal, Button } from "antd";
import dayjs from "dayjs";
import KeyValuePairDisplayCard from "src/common/components/layouts/KeyValuePairDisplayCard";
import capitalize from "src/common/functions/capitalize";
import clsx from "clsx";
import ShowMultipleImageLayout from "src/common/components/layouts/ShowMultipleImageLayout";
import { generalChecklistType } from "../../utils/generalChecklistType";

interface InspectionResultProps {
  data: InspectionResultType;
  visible: boolean;
  onClose: () => void;
}

const InspectionResult: React.FC<InspectionResultProps> = ({
  data,
  visible,
  onClose,
}) => {
  const objectToDisplay = useMemo(() => {
    const itemMap: { [key: string]: string | undefined } = {
      ["Submitted by"]: data.user_name ?? data.user?.name,
      ["Company"]: data.company_name ?? data.subcontractor?.name,
      ["Submitted on"]: dayjs(data.created_at).format("LLL"),
      ["Inspection Name"]: data.inspection_name.en,
    };
    if (data.model_number) itemMap["Model number"] = data.model_number;
    return itemMap;
  }, [data]);
  const typeMap: {
    [key: string]: InspectionResultType["inspection_answers"][number][];
  } = {};
  data.inspection_answers.forEach((ans) => {
    const type =
      ans.inspection_instance_checklist_item.inspection_checklist_item_type
        ?.text?.en ?? capitalize(generalChecklistType);
    if (typeMap[type]) {
      typeMap[type].push(ans);
    } else typeMap[type] = [ans];
  });
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      cancelText="Close"
      width={800}
      okButtonProps={{ hidden: true }}
    >
      <KeyValuePairDisplayCard objectToDisplay={objectToDisplay} />
      <div className="text-1.25 mt-0.5 flex justify-between">
        Checklist
        <div>Answers</div>
      </div>
      {Object.entries(typeMap).map(([type, inspection_answers]) => (
        <div>
          <div className="text-1.25">{type}</div>
          <div className="ml-0.5">
            {inspection_answers.map((ans, i) => (
              <div key={ans.id}>
                <div className="flex gap-1 justify-between mt-0.25">
                  <div>
                    <span className="font-accent">
                      {i + 1}
                      {".)"}{" "}
                    </span>
                    {ans.inspection_instance_checklist_item.description.en}:{" "}
                  </div>
                  <div
                    className={clsx(
                      "p-0.25 rounded-0.25 text-white",
                      ans.option_response === "na"
                        ? "bg-interactive-primary"
                        : ans.option_response ===
                          ans.inspection_instance_checklist_item.correct_answer
                        ? "bg-semantic-positive-green"
                        : "bg-semantic-negative",
                    )}
                  >
                    {capitalize(ans.option_response)}
                  </div>
                </div>
                {ans.option_response !== "na" &&
                ans.option_response !==
                  ans.inspection_instance_checklist_item.correct_answer ? (
                  <div
                    className={
                      ans.inspection_checklist_item_deficient_type
                        ?.is_immediate_fix
                        ? "text-purple"
                        : "text-semantic-negative"
                    }
                  >
                    <span className="text-grey">Make-safe action taken: </span>
                    {ans.inspection_checklist_item_deficient_type ? (
                      <>
                        <span>
                          {ans.inspection_checklist_item_deficient_type.text.en}
                        </span>
                        {ans.text_response ? (
                          <div>
                            <span className="text-grey">
                              Additional Information Provided:{" "}
                            </span>
                            {ans.text_response.en}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ans.text_response && <span>{ans.text_response.en}</span>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
      {data.inspection_images.length > 0 && (
        <>
          <div className="mt-1 text-1.25">Images</div>
          <ShowMultipleImageLayout
            images={data.inspection_images.map((image) => image)}
          />
        </>
      )}
    </Modal>
  );
};
export default InspectionResult;
