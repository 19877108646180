import React, { FC } from 'react';

interface Props {
  cols: number;
  total?: number;
}

const TableFooterTotal: FC<Props> = ({ cols, total }) => {
  return (
    <tr>
      <th colSpan={!!total ? 1 : cols}>Total</th>
      {!!total && <td colSpan={cols - 1}>{total}</td>}
    </tr>
  );
};

export default TableFooterTotal;
