import Hotjar from "@hotjar/browser";
import { isDevelopmentEnvironment } from "../../../common/constants/environment";

export type HotjarEventType =
  | "load_over_3s"
  | "load_over_1s"
  | "error"
  | "orientation_started"
  | "orientation_completed"
  | "orientation_step_hard_hat_number"
  | "orientation_step_birth_date"
  | "orientation_step_birth_date_entered"
  | "orientation_step_modules_player"
  | "youtube_api_error"
  | "audio_waiting"
  | "audio_stalled"
  | "audio_suspended"
  | "audio_ended"
  | "audio_can_play"
  | "audio_can_play_through"
  | "audio_playing"
  | "audio_reloading"
  | "contact_us_opened"
  | "contact_us_message_sent";

const sendHotjarEvent = (eventName: HotjarEventType) => {
  if (isDevelopmentEnvironment) {
    console.log("Hotjar event", eventName);
  }
  if (Hotjar.isReady()) {
    Hotjar.event(eventName);
  }
};

export default sendHotjarEvent;
