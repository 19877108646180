import { Button, Divider, Modal, message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useEffect, useState } from "react";
import { useLazyLoadQuery, useRelayEnvironment } from "react-relay/hooks";
import { getInvitedUserDataList } from "src/common/functions/invitedUserEmailData";
import useAuthUser from "src/common/hooks/useAuthUser";
import { useEmailSubAdminMobilizationMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { GCProjectSubAdminInvitationModalQuery } from "src/common/types/generated/relay/GCProjectSubAdminInvitationModalQuery.graphql";
import NotifyUserException from "src/utility-features/error-handling/NotifyUserException";
import handleRequestError from "src/utility-features/error-handling/handleRequestError";
import { sendInviteProps, sendInvites } from "../../sendInvite";
import ConfirmedSubAdmins from "./GCProjectSubAdminInvitationModalConfirmedSubAdmins";
import NewSubAdmins from "./GCProjectSubAdminInvitationModalNewSubAdmins";

export interface GCProjectSubAdminInvitationModalProps {
  subcontractorId: string;
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  projectId: string;
}

export const query = graphql`
  query GCProjectSubAdminInvitationModalQuery(
    $subId: uuid!
    $projectId: uuid!
    $userId: uuid!
  ) {
    subcontractor_connection(where: { id: { _eq: $subId } }, first: 1) {
      edges {
        node {
          id @__clientField(handle: "pk")
          name
          subcontractor_employees(order_by: { user: { name: asc } }) {
            user_id
            user {
              name
              email
              created_password
            }
          }
        }
      }
    }
    user_connection(where: { id: { _eq: $userId } }) {
      edges {
        node {
          name
          pk: id @__clientField(handle: "pk")
          id
          employee {
            general_contractor {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
            employee_projects(where: { project_id: { _eq: $projectId } }) {
              project {
                id
                pk: id @__clientField(handle: "pk")
                name
              }
            }
          }
        }
      }
    }
  }
`;

const GCProjectSubAdminInvitationModal: React.FC<
  GCProjectSubAdminInvitationModalProps
> = (props) => {
  const [checkedExistingSubAdminIds, setCheckedExistingSubAdminIds] = useState<
    string[]
  >([]);

  const authUser = useAuthUser();

  const data = useLazyLoadQuery<GCProjectSubAdminInvitationModalQuery>(query, {
    projectId: props.projectId,
    subId: props.subcontractorId,
    userId: authUser.uid,
  });

  const existingSubAdmins: { id: string; name: string; email: string }[] =
    data.subcontractor_connection.edges[0].node.subcontractor_employees
      ?.filter((subcontractorEmployee) => subcontractorEmployee.user.email)
      .map((subcontractorEmployee) => {
        return {
          id: subcontractorEmployee.user_id,
          name: subcontractorEmployee.user.name,
          email: subcontractorEmployee.user.email!,
        };
      }) ?? [];

  useEffect(() => {
    setCheckedExistingSubAdminIds([]);
    setNewSubAdminEmails([]);
  }, [props.visible]);

  const [newSubAdminEmails, setNewSubAdminEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const environment = useRelayEnvironment();
  const employee = data.user_connection.edges[0]?.node.employee;
  if (!employee) {
    throw new Error("user must be logged in as gc employee");
  }
  const sentFromName = data.user_connection.edges[0].node.name;
  const sentFromCompanyName =
    data.user_connection.edges[0].node.employee?.general_contractor.name;
  const projectName =
    data.user_connection.edges[0].node.employee?.employee_projects[0]?.project
      ?.name;
  const [emailSubAdminMobilization] = useEmailSubAdminMobilizationMutation();

  return (
    <Modal
      title={`Request Mobilization Documents`}
      destroyOnClose={true}
      open={props.visible}
      okText={"Send"}
      onCancel={props.onCancel}
      okButtonProps={{
        disabled:
          newSubAdminEmails.length + checkedExistingSubAdminIds.length === 0,
      }}
      footer={
        <div className="flex flex-1 flex-row">
          <div className="flex flex-col items-start flex-1 mr-1">
            Send email requesting documents
          </div>
          <Button onClick={props.onCancel}>{"Cancel"}</Button>
          <Button
            className="text-white bg-interactive-primary border-interactive-primary ml-0.5"
            disabled={
              newSubAdminEmails.length + checkedExistingSubAdminIds.length === 0
            }
            loading={loading}
            onClick={async (e) => {
              setLoading(true);
              try {
                if (
                  checkedExistingSubAdminIds &&
                  checkedExistingSubAdminIds.length > 0
                ) {
                  await emailSubAdminMobilization({
                    variables: {
                      input: {
                        userId: authUser.uid,
                        projectId: props.projectId,
                        subcontractorId: props.subcontractorId,
                        subAdminIds: checkedExistingSubAdminIds,
                      },
                    },
                  });
                }
                if (newSubAdminEmails && newSubAdminEmails.length > 0) {
                  const invitedUserData = await getInvitedUserDataList(
                    newSubAdminEmails,
                    environment,
                  );
                  const sendInviteObjects: Array<sendInviteProps> = [];
                  newSubAdminEmails.forEach((email) => {
                    let sendInviteVariables: sendInviteProps = {
                      sendTo: email,
                      sentFromName: sentFromName,
                      sentFromCompanyName: sentFromCompanyName,
                      projectName: projectName,
                      joinProjectId: props.projectId,
                      joinGeneralContractorId: employee.general_contractor.pk,
                      joinSubcontractorId: props.subcontractorId,
                      joinRole: "sub-admin",
                      inviteType: "subAdminInvite",
                    };
                    const existingUser =
                      invitedUserData &&
                      invitedUserData.user_connection.edges.find(
                        (user) => user.node.email == email,
                      );
                    if (existingUser) {
                      if (existingUser.node.role == "subcontractor_employee") {
                        const invitedUserSubId =
                          existingUser.node.subcontractor_employee
                            ?.subcontractor_id;
                        if (invitedUserSubId !== props.subcontractorId) {
                          const errorTitle = "User Exists",
                            errorDescription =
                              "This email is already in use under a different Subcontractor. If you need assistance contact us at support@siteform.io";
                          handleRequestError(
                            new NotifyUserException(
                              errorTitle,
                              errorDescription,
                            ),
                            {
                              errorTitle: errorTitle,
                              errorUserInstructions: errorDescription,
                            },
                          );
                          return;
                        } else if (
                          invitedUserSubId === props.subcontractorId &&
                          existingUser.node.created_password
                        ) {
                          const errorTitle = "User Exists",
                            errorDescription =
                              "This user has already set up their account. If they are having trouble logging in, select Forgot password at sign-in";

                          handleRequestError(
                            new NotifyUserException(
                              errorTitle,
                              errorDescription,
                            ),
                            {
                              errorTitle: errorTitle,
                              errorUserInstructions: errorDescription,
                            },
                          );

                          sendInviteVariables = {
                            ...sendInviteVariables,
                            inviteType: "loginInviteSub",
                          };
                        } else if (
                          invitedUserSubId === props.subcontractorId &&
                          !existingUser.node.created_password
                        ) {
                          sendInviteVariables = {
                            ...sendInviteVariables,
                            claimingAccount: true,
                          };
                        }
                      }
                    }
                    sendInviteObjects.push(sendInviteVariables);
                  });
                  await sendInvites(sendInviteObjects, true);
                }
                message.success("Request Sent");
                setLoading(false);
                props.onConfirm();
              } catch (error) {
                console.log("Error:", error);
                handleRequestError(error);
              }
            }}
          >
            {"Send"}
          </Button>
        </div>
      }
    >
      <Divider
        orientation={"left"}
      >{`Send Request Emails to Existing Subcontractor Admin(s)`}</Divider>
      <div className={`max-h-16 overflow-auto`}>
        <ConfirmedSubAdmins
          subAdminsList={existingSubAdmins}
          checkedSubAdminIds={checkedExistingSubAdminIds}
          onCheckedSubAdminIdsChange={setCheckedExistingSubAdminIds}
        />
      </div>
      <Divider orientation={"left"}>{`Or ADD new SubAdmin(s)`}</Divider>
      <NewSubAdmins
        emailList={newSubAdminEmails}
        onEmailListChange={setNewSubAdminEmails}
        visible={props.visible}
        existingSubAdminEmails={existingSubAdmins.map(
          (existingSubAdmin) => existingSubAdmin.email,
        )}
      />
    </Modal>
  );
};

export default GCProjectSubAdminInvitationModal;
