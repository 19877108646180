import React from "react";
import clsx from "clsx";

export interface NavigationProgressBarStepProps {
  status: "done" | "in progress" | "to do";
  title: string;
}

const NavigationProgressBarStep: React.FC<NavigationProgressBarStepProps> = (
  props
) => {
  return (
    <div className={`relative w-0.25 h-0`}>
      <div
        className={clsx(
          `rounded-0.5  absolute -left-0.25 bg-interactive-primary -top-0.25 w-0.5 h-0.5 transition-opacity`,
          (() => {
            switch (props.status) {
              case "in progress":
                return "opacity-100";
              case "done":
              case "to do":
              default:
                return "opacity-0";
            }
          })()
        )}
      ></div>
    </div>
  );
};

export default NavigationProgressBarStep;
