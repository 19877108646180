import DataSet from "@antv/data-set";
import { Chart } from "@antv/g2";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { titleCase } from "src/common/functions/stringFormatters";

const { RangePicker } = DatePicker;

export interface StackedColumnChart {
  id: string;
  projectId: string;
  useQuery: any;
}

const defaultRange = [
  dayjs().subtract(7, "day").startOf("day"),
  dayjs().endOf("day"),
];
const StackedChart = ({ id, projectId, useQuery }: any) => {
  // TODO change any
  const [dateRange, setDateRange] = useState<Array<Dayjs>>(defaultRange);
  const { data, error, loading, refetch } = useQuery({
    variables: {
      projectId,
      _lte: dayjs().endOf("d"),
      _gte: dayjs().subtract(7, "d").startOf("d"),
    },
  });
  const [subFilter, setSubFilter] = useState<string | null>(null);
  const [subNames, setSubNames] = useState<Array<string>>([]);
  const [chartInstance, setChartInstance] = useState<Chart | undefined>();

  const chartData = useMemo<any>(() => {
    const subMap = new Map<string, any>();

    data &&
      data.reports.map((report: any) => {
        const roles = ["foreman", "journeyman", "apprentice"];

        const getRoleCount = (role: string, workerList: any): number =>
          workerList.filter(
            (worker: any) => worker.project_worker.worker_role === role,
          ).length;

        // for each role for each report by sub on date
        roles.map((role: string) => {
          const template: {
            name: string;
            date: string;
            title: string;
            count?: number;
          } = {
            name: report.subcontractor.name as string,
            date: dayjs(report.created_at).format("ll").toString(),
            title: role,
          };
          const key = `${report.subcontractor.name}_${report.created_at}_${role}`;

          if (!subMap.has(key)) {
            template.count = getRoleCount(role, report.report_project_workers);
            subMap.set(key, template);
          } else {
            const existing = subMap.get(key);
            existing.count += getRoleCount(role, report.report_project_workers);
          }
        });
      });
    return Array.from(subMap.values());
  }, [data]);

  useEffect(() => {
    // registerInteraction('other-filter', {
    //   showEnable: [
    //     { trigger: 'plot:mouseenter', action: 'cursor:crosshair' },
    //     { trigger: 'mask:mouseenter', action: 'cursor:move' },
    //     { trigger: 'plot:mouseleave', action: 'cursor:default' },
    //     { trigger: 'mask:mouseleave', action: 'cursor:crosshair' },
    //   ],
    //   start: [
    //     {
    //       trigger: 'plot:mousedown',
    //       isEnable(context) {
    //         return !context.isInShape('mask');
    //       },
    //       action: ['x-rect-mask:start', 'x-rect-mask:show'],
    //     },
    //     { trigger: 'mask:dragstart', action: 'x-rect-mask:moveStart' },
    //   ],
    //   processing: [
    //     { trigger: 'plot:mousemove', action: 'x-rect-mask:resize' },
    //     { trigger: 'mask:drag', action: 'x-rect-mask:move' },
    //     { trigger: 'mask:change', action: 'sibling-x-filter:filter' },
    //   ],
    //   end: [
    //     { trigger: 'plot:mouseup', action: 'x-rect-mask:end' },
    //     { trigger: 'mask:dragend', action: 'x-rect-mask:moveEnd' },
    //   ],
    //   rollback: [
    //     {
    //       trigger: 'dblclick',
    //       action: ['x-rect-mask:hide', 'sibling-x-filter:reset'],
    //     },
    //   ],
    // });
    const chart = new Chart({
      container: id,
      autoFit: true,
      height: 400,
      padding: [20, 150, 65, 50],
    });
    setChartInstance(chart);
    const dv = new DataSet().createView().source(chartData);

    // Filter By Date Range
    if (dateRange) {
      dv.transform({
        type: "filter",
        callback: (row) => {
          return dayjs(row.date).isBetween(
            dayjs(dateRange[0]).subtract(1, "d"),
            dateRange[1],
          );
        },
      });
    }
    // Set subcontractor filter with names only on data date range
    setSubNames(Array.from(new Set(dv.rows.flatMap((r: any) => r.name))));

    // Filter By subcontractor
    if (subFilter) {
      dv.transform({
        type: "filter",
        callback: (row) => {
          return row.name === subFilter;
        },
      });
    }

    dv.transform({
      type: "aggregate",
      fields: ["count"],
      operations: ["sum"],
      as: ["total"],
      groupBy: ["date", "title"],
    }).transform({
      type: "map",
      callback: (row) => {
        delete row.count;
        return row;
      },
    });

    chart.clear();

    chart.data(dv.rows);

    chart.scale("total", { nice: true });

    chart.tooltip({
      title: subFilter || "Subcontractors",
      showMarkers: false,
      shared: true,
    });

    chart.legend("title", {
      position: "right",
      itemName: {
        formatter(text) {
          return titleCase(text);
        },
      },
    });

    chart.interaction("brush");
    // chart.interaction('other-filter');

    chart
      .interval()
      .adjust("stack")
      .position("date*total")
      .tooltip({
        fields: ["title", "total"],
        callback: (title, total) => {
          return { name: titleCase(title), value: total };
        },
      })
      .color("title");

    chart.render();
    // This prevents overflow when navigating from another route.
    setTimeout(() => chart.forceFit(), 1000);

    return () => chart.destroy();
  }, [data, dateRange, subFilter]);

  const handleFilterSelect = (event: any) => {
    if (event.item.props.children === "Clear") {
      setSubFilter(null);
      return;
    }
    setSubFilter(event.item.props.children);
  };

  const handleDateRange = (range: any) => {
    if (range === null) {
      setDateRange(defaultRange);
      chartInstance?.clear();
      refetch();
      return;
    }
    refetch({
      _lte: dayjs(range[1]).endOf("d"),
      _gte: range[0].startOf("d"),
    })
      .then(() => {
        setDateRange([range[0].startOf("d"), range[1].endOf("d")]);
      })
      .catch((e: Error) => console.error(e));
  };
  return (
    <div className="min-h-20">
      <div className="flex flex-row justify-between">
        <h3 className="text-1.5">
          Subcontractors {subFilter && `- ${subFilter}`}
        </h3>
        <div className="flex flex-row items-center justify-end gap-1">
          <RangePicker
            onChange={handleDateRange}
            value={[dateRange[0], dateRange[1]]}
          />
        </div>
      </div>
      <div id={id}></div>
    </div>
  );
};

export default StackedChart;
