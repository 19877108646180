class NotifyUserException extends Error {
  userInstructions?: string;

  constructor(errorTitle?: string, userInstructions?: string) {
    super(errorTitle); // Pass the errorTitle to the Error constructor
    this.name = "NotifyUserException"; // Set the name of the error
    this.userInstructions = userInstructions; // Set the userInstructions

    // This line is needed to restore the correct prototype chain.
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export default NotifyUserException;
