import { GetGcPermitChecklistItemsQuery } from "src/common/types/generated/apollo/graphQLTypes";

const buildNewGCData = (
  data: GetGcPermitChecklistItemsQuery,
  gcId: string,
  newGCData: GetGcPermitChecklistItemsQuery["general_contractor"][number],
): GetGcPermitChecklistItemsQuery => ({
  ...data,
  general_contractor: data.general_contractor.map((gc) =>
    gc.id !== gcId ? gc : newGCData,
  ),
});
export default buildNewGCData;
