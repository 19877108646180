import { Button, message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import pluralize from "pluralize";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useMutation } from "react-relay/hooks";
import AddWorkerDrugTestModal, {
  AddWorkerDrugTestModalDataProps,
  AddWorkerDrugTestModalRef,
} from "src/common/components/dialogs/AddWorkerDrugTestModal";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import BSpace from "src/common/components/layouts/BSpace";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import { WorkerDrugTestTableDeleteDrugTestMutation } from "src/common/types/generated/relay/WorkerDrugTestTableDeleteDrugTestMutation.graphql";
import { WorkerDrugTestTableQuery } from "src/common/types/generated/relay/WorkerDrugTestTableQuery.graphql";
import { WorkerDrugTestTable_worker_drug_tests$data } from "src/common/types/generated/relay/WorkerDrugTestTable_worker_drug_tests.graphql";
import Tag from "../general/Tag";
import ImageCarousel from "../general/images/ImageCarousel";

type ColumnKeys = "enteredBy" | "date" | "images" | "project" | "status";
const CONNECTION_NAME = "worker_drug_test_connection";
type DConnection =
  WorkerDrugTestTable_worker_drug_tests$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  WorkerDrugTestTableQuery,
  string
> & {
  insertModalProps?: AddWorkerDrugTestModalDataProps;
  includeImages: boolean;
};

type Ref = DataScrollTableRef | null;
const WorkerDrugTestTable = forwardRef<Ref, Props>(
  ({ title = "Drug tests", onRowClick, insertModalProps, ...props }, ref) => {
    const table = useRef<DataScrollTableRef>(null);
    const addTestModal = useRef<AddWorkerDrugTestModalRef>(null);
    const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);
    // TODO @deleteEdge https://github.com/facebook/relay/pull/3155
    const [deleteWorkerCerts, deletingCerts] =
      useMutation<WorkerDrugTestTableDeleteDrugTestMutation>(graphql`
        mutation WorkerDrugTestTableDeleteDrugTestMutation(
          $where: worker_drug_test_bool_exp!
        ) {
          delete_worker_drug_test(where: $where) {
            returning {
              id
            }
          }
        }
      `);

    useImperativeHandle<Ref, Ref>(ref, () => table.current);

    return (
      <BSpace>
        <BSpace hz>
          {!!insertModalProps && (
            <>
              <AddWorkerDrugTestModal
                {...insertModalProps}
                ref={addTestModal}
                onInserted={() => {
                  addTestModal.current?.close();
                  table.current && table.current.refetch();
                }}
              />
              <Button
                type="primary"
                onClick={() => addTestModal.current?.open()}
                style={{ fontWeight: "bold", borderRadius: "20px" }}
              >
                Add Drug Test
              </Button>
            </>
          )}
          {selectedKeys.length > 0 && (
            <BPopconfirm
              onConfirm={() => {
                deleteWorkerCerts({
                  variables: {
                    where: {
                      id: {
                        _in: selectedKeys,
                      },
                    },
                  },
                  onCompleted: () => {
                    table.current?.refetch();
                    message.success(
                      `Deleted ${pluralize(
                        "drug test",
                        selectedKeys.length,
                        true,
                      )}`,
                    );
                    setSelectedKeys([]);
                  },
                });
              }}
            >
              <Button
                danger
                loading={deletingCerts}
                style={{ fontWeight: "bold", borderRadius: "20px" }}
              >
                Delete selected ({selectedKeys.length})
              </Button>
            </BPopconfirm>
          )}
        </BSpace>
        <DataScrollTable<DConnection, ColumnKeys, WorkerDrugTestTableQuery>
          {...props}
          ref={table}
          newCustomTableLook={!props.includeImages}
          title={title}
          disableRowNumber
          onRowClick={(tbt) => {
            onRowClick && onRowClick(tbt.pk);
          }}
          expandable={
            props.includeImages
              ? {
                  rowExpandable: (cert) => cert.images.length > 0,
                  expandedRowRender: (cert) => (
                    <ImageCarousel images={cert.images} />
                  ),
                }
              : undefined
          }
          extraQueryVariables={{ includeImages: props.includeImages }}
          connectionName={CONNECTION_NAME}
          totalCountConnectionName={"allDrugTestsConnetion"}
          rowSelection={{
            selectedRowKeys: selectedKeys,
            onChange: (keys) => {
              setSelectedKeys(keys.map((k) => k.toString()));
            },
          }}
          columns={[
            {
              title: "Entered by",
              key: "enteredBy",
              sortable: true,
              defaultSortOrder: "asc",
              dataIndex: ["name"],
              render: (a, row) => {
                const enteredUserdata = row?.entered_through_user;
                return (
                  <div>{enteredUserdata ? `${enteredUserdata?.name}` : a}</div>
                );
              },
            },
            {
              title: "Test date",
              key: "date",
              dataIndex: ["drug_test_date"],
              sortable: true,
              contentType: {
                type: "date",
                renderOptions: () => ({
                  format: "mmddyyyy",
                }),
              },
              render: (a) => dayjs(a).format("lll"),
            },
            {
              title: "Submitted on Project",
              key: "project",
              dataIndex: ["project", "name"],
              sortable: true,
              searchDataIndex: ["project", "name"],
              render: (v) => v ?? "",
            },
            {
              title: "Images",
              key: "images",
              dataIndex: ["images"],
              render: (_: any, cert) => `${(cert.images || []).length}`,
            },
            {
              title: "Status",
              key: "status",
              dataIndex: ["status"],
              sortable: true,
              searchDataIndex: ["status"],
              render: (v) => {
                console.log(v);

                return v ? (
                  <Tag
                    status={
                      v == "non-negative"
                        ? "inactive"
                        : v === "positive"
                        ? "negative"
                        : "positive"
                    }
                  >
                    {v}
                  </Tag>
                ) : (
                  ""
                );
              },
            },
          ]}
          queryNode={graphql`
            query WorkerDrugTestTableQuery(
              $first: Int!
              $after: String
              $where: worker_drug_test_bool_exp!
              $order_by: [worker_drug_test_order_by!]!
              $includeImages: Boolean!
            ) {
              ...WorkerDrugTestTable_worker_drug_tests
                @arguments(
                  first: $first
                  after: $after
                  where: $where
                  order_by: $order_by
                  includeImages: $includeImages
                )
              ...WorkerDrugTestTable_total @arguments(where: $where)
            }
          `}
          totalCountNode={graphql`
            fragment WorkerDrugTestTable_total on query_root
            @argumentDefinitions(where: { type: "worker_drug_test_bool_exp!" })
            @refetchable(
              queryName: "WorkerDrugTestTableTotalRefetchableQuery"
            ) {
              allDrugTestsConnetion: worker_drug_test_connection(
                where: $where
              ) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          `}
          paginationNode={graphql`
            fragment WorkerDrugTestTable_worker_drug_tests on query_root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              where: { type: "worker_drug_test_bool_exp!" }
              order_by: { type: "[worker_drug_test_order_by!]!" }
              includeImages: { type: "Boolean", defaultValue: false }
            )
            @refetchable(queryName: "WorkerDrugTestTableRefetchableQuery") {
              worker_drug_test_connection(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
                @connection(
                  key: "WorkerDrugTestTable_worker_drug_test_connection"
                  filters: ["where", "order_by"]
                ) {
                edges {
                  node {
                    pk: id @__clientField(handle: "pk")
                    drug_test_date
                    status
                    name
                    project {
                      name
                    }
                    entered_through_user {
                      name
                    }
                    images(order_by: { description: desc_nulls_last })
                      @include(if: $includeImages) {
                      id
                      url
                    }
                  }
                }
              }
            }
          `}
        />
      </BSpace>
    );
  },
);

export default WorkerDrugTestTable;
