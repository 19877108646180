import NestedRoute from "../../../../../../common/types/manual/NestedRoute";
import searchParamsProvider from "../../../../../../common/functions/searchParamsProvider";
import QrProjectSds from "./components/QrProjectSds";
import React from "react";

const sdsRoute: NestedRoute = {
  element: searchParamsProvider(QrProjectSds),
};

export default sdsRoute;
