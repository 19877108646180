import { Form, Input, Modal, Select } from "antd";
import React from "react";
import { useGetSubcontractorTradesQuery } from "src/common/types/generated/apollo/graphQLTypes";

interface CreateSubcontractorFormValues {
  name: string;
  ein: string;
  tradeId: string;
}

interface CollectionCreateFormProps {
  visible: boolean;
  onCreate: (values: CreateSubcontractorFormValues) => void;
  onCancel: () => void;
}

const CreateSubcontractorModal: React.FC<CollectionCreateFormProps> = ({
  visible,
  onCreate,
  onCancel,
}) => {
  const { data, loading } = useGetSubcontractorTradesQuery();
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    (<Modal
      open={visible}
      title="Create a new subcontractor"
      okText="Create"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values as CreateSubcontractorFormValues);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="name"
          label="Company name"
          rules={[
            {
              required: true,
              message: "Enter the company name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="ein"
          label="EIN (Employer Identification Number)"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="tradeId"
          label="Trade"
          rules={[{ required: true, message: "Enter the trade" }]}
        >
          <Select
            style={{ width: "100%" }}
            disabled={loading}
            loading={loading}
          >
            {data?.subcontractor_trade.map((trade) => (
              <Select.Option key={trade.id} value={trade.id}>
                {trade.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>)
  );
};

export default CreateSubcontractorModal;
