import { ErrorCauseType } from "./errorHandlingTypes";
import { isApolloError } from "apollo-boost";
import { RRNLError, RRNLRequestError } from "react-relay-network-modern";
import NotifyUserException from "./NotifyUserException";

const getErrorCauseType: (error: unknown) => ErrorCauseType = (error) => {
  if (error instanceof NotifyUserException) {
    // Expected Error
    return "expected";
  } else if (error instanceof Error && isApolloError(error)) {
    // Apollo Error
    const isApolloNetworkError = !!error.networkError;
    const isApolloGraphqlError = error.graphQLErrors.length > 0;

    if (isApolloGraphqlError || isApolloNetworkError) {
      if (isApolloGraphqlError) {
        // GraphQl Error
        return "operational";
      }
      if (isApolloNetworkError) {
        // Network Error
        return "network";
      }
    } else {
      // Other Error
      return "operational";
    }
  } else if (error instanceof RRNLError) {
    // Relay Error
    if (error instanceof RRNLRequestError) {
      // Operational Error
      return "operational";
    } else if (error.name === "RRNLBatchMiddlewareError") {
      // Network Error
      return "network";
    } else {
      // Other Error
      return "operational";
    }
  } else {
    // Other Error
    return "operational";
  }
  return "operational";
};

export default getErrorCauseType;
