import produce from "immer";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import {
  GetObservationByPkQuery,
  UnsafeObservationAssigneeFragmentFragment,
  GetObservationOptionsQuery,
  Lang_Code_Enum,
  useUpdateObservationAssigneeMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import createObservationPatch from "./createObservationPatch";
import * as uuid from "uuid";
import useAuthUser from "src/common/hooks/useAuthUser";
const useModifyAssignees = ({
  observation,
  type,
  isSubmitted,
  optionsData,
  currentAssignees,
}: {
  optionsData: GetObservationOptionsQuery;
  observation: NonNullable<GetObservationByPkQuery["observation_by_pk"]>;
  isSubmitted: boolean;
  type: "assignee" | "corrected_by";
  currentAssignees: UnsafeObservationAssigneeFragmentFragment[];
}) => {
  const typeString = type === "assignee" ? "assigned to" : "corrected by users";
  const [updateObservationAssignee] = useUpdateObservationAssigneeMutation();
  const authUser = useAuthUser();
  const onAssigneePress = async (usersSelected: string[]) => {
    const unsafeObservation = observation.unsafe_observation;
    if (!unsafeObservation)
      throw new Error(
        "Unsafe observation required is required for this observation",
      );
    const [toInsert, toDelete] = compareTwoLists(
      usersSelected,
      currentAssignees.map(
        (assignee) =>
          assignee.project_worker?.user?.id || assignee.employee?.user?.id!,
      ),
    );
    const toDeleteAssignees = currentAssignees.filter((assignee) =>
      toDelete.includes(
        assignee.project_worker?.user?.id || assignee.employee?.user?.id!,
      ),
    );
    const expectedReturningNewAssignees: UnsafeObservationAssigneeFragmentFragment[] =
      [];
    optionsData.general_contractor_employee.forEach((emp) => {
      if (toInsert.includes(emp.uid)) {
        expectedReturningNewAssignees.push({
          __typename: "unsafe_observation_assignee" as const,
          type,
          id: uuid.v4(),
          employee: emp,
          project_worker: null,
        });
      }
    });
    optionsData.project_worker.forEach((pw) => {
      if (toInsert.includes(pw.user!.id)) {
        expectedReturningNewAssignees.push({
          __typename: "unsafe_observation_assignee" as const,
          type,
          id: uuid.v4(),
          employee: null,
          project_worker: pw,
        });
      }
    });
    if (!toInsert.length && !toDelete.length) {
      return;
    }
    if (!toInsert.length && !toDelete.length) return;
    const editComment = `${
      expectedReturningNewAssignees.length
        ? `new ${typeString} added: ${expectedReturningNewAssignees.reduce(
            (str, assignee) => {
              const user = (assignee.employee || assignee.project_worker)?.user;
              return str + (user ? user.name + ", " : "");
            },
            "",
          )}`
        : ""
    }${
      toDeleteAssignees.length
        ? `\n ${typeString} removed: ${toDeleteAssignees.reduce(
            (str, assignee) => {
              const user = (assignee.employee || assignee.project_worker)?.user;
              return str + (user ? user.name + ", " : "");
            },
            "",
          )}`
        : ""
    }}`;
    const newData = produce(observation, (draft) => {
      draft.unsafe_observation!.assignees = [
        ...expectedReturningNewAssignees,
        ...draft.unsafe_observation!.assignees.filter(
          (u) => !toDeleteAssignees.some((assignee) => assignee.id === u.id),
        ),
      ];
    });
    const patch = createObservationPatch(newData, observation);
    const variables = {
      todoAssignees:
        unsafeObservation.todo_id && type === "assignee"
          ? expectedReturningNewAssignees.map((a) => ({
              assignee: {
                data: a.project_worker
                  ? { project_worker_id: a.project_worker.id }
                  : { employee_id: a.employee?.user.id },
              },
              assignee_completed_type: "one",
              worker_subcontractor_id:
                a.project_worker?.subcontractor.id || null,
              assignee_project_id: observation.project_id,
              todo_id: unsafeObservation.todo_id,
            }))
          : [],
      editHistoryObjects: isSubmitted
        ? [
            {
              edit_type: `${type}_change`,
              edited_by_uid: authUser.uid,
              patch,
              observation_id: observation.id,
              comment: {
                data: {
                  original: editComment,
                  lang: Lang_Code_Enum.En,
                },
              },
            },
          ]
        : [],
      deleteTodoAssigneeWhere:
        unsafeObservation.todo_id && type === "assignee"
          ? {
              todo_id: { _eq: unsafeObservation.todo_id },
              assignee: {
                _or: [
                  { project_worker: { worker_id: { _in: toDelete } } },
                  { employee_id: { _in: toDelete } },
                ],
              },
            }
          : { id: { _is_null: true } },
      deleteWhere: {
        id: { _in: toDeleteAssignees.map((u) => u.id) },
      },
      objects: expectedReturningNewAssignees.map((a) => ({
        created_by_uid: authUser.uid,
        observation_id: observation.id,
        type,
        id: a.id,
        employee_id: a.employee?.user?.id || null,
        project_worker_id: a.project_worker?.id || null,
      })),
    };
    console.log(variables);
    await updateObservationAssignee({
      variables,
      optimisticResponse: {
        delete_todo_assignee: { affected_rows: toDelete.length },
        insert_todo_assignee: { affected_rows: toInsert.length },
        insert_unsafe_observation_assignee: {
          affected_rows: toInsert.length,
          returning: expectedReturningNewAssignees,
        },
        delete_unsafe_observation_assignee: {
          affected_rows: toInsert.length,
          returning: toDeleteAssignees,
        },
        insert_observation_edit_history: { affected_rows: 1, returning: [] },
      },
      update: (cache, returningData) => {
        const returningEdits =
          returningData.data?.insert_observation_edit_history?.returning || [];
        const returningNewAssingees =
          returningData.data?.insert_unsafe_observation_assignee?.returning ||
          [];
        const returningDeletedIds = (
          returningData.data?.delete_unsafe_observation_assignee?.returning ||
          []
        ).map((u) => u.id);
        if (
          (toDeleteAssignees.length && !returningDeletedIds.length) ||
          (expectedReturningNewAssignees.length &&
            !returningNewAssingees.length)
        )
          throw new Error(
            "server returning empty list or null for both insert and delete on assignees",
          );
        console.log(
          { returningDeletedIds, returningNewAssingees },
          cache.identify(unsafeObservation),
        );
        cache.modify<typeof observation>({
          id: cache.identify(observation),
          fields: {
            observation_edits(existingEdits = [], { toReference }) {
              console.log("PART EDITS");
              return [
                ...existingEdits,
                ...returningEdits.map((edit) => toReference(edit)!),
              ];
            },
          },
        });
        cache.modify<typeof unsafeObservation>({
          id: cache.identify(unsafeObservation),
          fields: {
            assignees(existingAssingees = [], { readField, toReference }) {
              console.log("PART INS Assignee");
              return [
                ...returningNewAssingees.map(
                  (obsUser) => toReference(obsUser)!,
                ),
                ...existingAssingees.filter((obsUser) => {
                  const id = readField("id", obsUser);
                  return (
                    typeof id === "string" && !returningDeletedIds.includes(id)
                  );
                }),
              ];
            },
          },
        });
      },
    });
  };
  return [onAssigneePress] as const;
};
export default useModifyAssignees;
