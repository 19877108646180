/**
 * @generated SignedSource<<1365991f5c849bf22568a5bd823c1377>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GCSendInviteModalQuery$variables = {
  includeSub: boolean;
  projectId: string;
  userId: string;
};
export type GCSendInviteModalQuery$data = {
  readonly subcontractor_connection?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
    }>;
  };
  readonly user_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly employee: {
          readonly employee_projects: ReadonlyArray<{
            readonly project: {
              readonly id: string;
              readonly name: string;
              readonly pk: string;
            };
          }>;
          readonly general_contractor: {
            readonly id: string;
            readonly name: string;
            readonly pk: string;
          };
        } | null | undefined;
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
    }>;
  };
};
export type GCSendInviteModalQuery = {
  response: GCSendInviteModalQuery$data;
  variables: GCSendInviteModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeSub"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "_eq",
        "variableName": "projectId"
      }
    ],
    "kind": "ObjectValue",
    "name": "project_id"
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "name": "asc"
    }
  },
  {
    "fields": [
      {
        "fields": (v3/*: any*/),
        "kind": "ObjectValue",
        "name": "subcontractor_projects"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "userId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v9 = [
  (v7/*: any*/),
  (v6/*: any*/),
  (v5/*: any*/)
],
v10 = [
  {
    "fields": (v3/*: any*/),
    "kind": "ObjectValue",
    "name": "where"
  }
],
v11 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v13 = [
  (v7/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCSendInviteModalQuery",
    "selections": [
      {
        "condition": "includeSub",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "subcontractorConnection",
            "kind": "LinkedField",
            "name": "subcontractor_connection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractorEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor",
                        "kind": "LinkedField",
                        "name": "general_contractor",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v10/*: any*/),
                        "concreteType": "project_employee",
                        "kind": "LinkedField",
                        "name": "employee_projects",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "project",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": (v9/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCSendInviteModalQuery",
    "selections": [
      {
        "condition": "includeSub",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "subcontractorConnection",
            "kind": "LinkedField",
            "name": "subcontractor_connection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractorEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor",
                        "kind": "LinkedField",
                        "name": "general_contractor",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v10/*: any*/),
                        "concreteType": "project_employee",
                        "kind": "LinkedField",
                        "name": "employee_projects",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "project",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": (v13/*: any*/),
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "091a777a44494d7f9db17cb83dcce051",
    "id": null,
    "metadata": {},
    "name": "GCSendInviteModalQuery",
    "operationKind": "query",
    "text": "query GCSendInviteModalQuery(\n  $projectId: uuid!\n  $userId: uuid!\n  $includeSub: Boolean!\n) {\n  subcontractor_connection(order_by: {name: asc}, where: {subcontractor_projects: {project_id: {_eq: $projectId}}}) @include(if: $includeSub) {\n    edges {\n      node {\n        name\n        pk: id\n        id\n      }\n    }\n  }\n  user_connection(where: {id: {_eq: $userId}}) {\n    edges {\n      node {\n        name\n        pk: id\n        id\n        employee {\n          general_contractor {\n            id\n            pk: id\n            name\n          }\n          employee_projects(where: {project_id: {_eq: $projectId}}) {\n            project {\n              id\n              pk: id\n              name\n            }\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a3f4df3a2cd9c4562ccb632141f600b7";

export default node;
