import { FC, useContext } from "react"
import SiteInspectionUserContext from "./siteInspectionUserContext";


const useSiteInspectionUser = () => {
    const value = useContext(SiteInspectionUserContext);
    if (!value)
        throw new Error("useSiteInspectionUser must be wrapped in SiteInspectionUserProvider");
    return value;
}

export default useSiteInspectionUser;