import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useParams } from "react-router-dom";
import { RangeType } from "src/common/components/tables/basic/CustomTableDateRangePicker";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { GCProjectReportManagementQuery } from "src/common/types/generated/relay/GCProjectReportManagementQuery.graphql";
import { GCProjectReportManagementUpdateProjectMutation } from "src/common/types/generated/relay/GCProjectReportManagementUpdateProjectMutation.graphql";
import { GCProjectReportManagement_UpdatePauseDateNotification_Mutation } from "src/common/types/generated/relay/GCProjectReportManagement_UpdatePauseDateNotification_Mutation.graphql";
import GCProjectReportManagementUI, {
  GCProjectReportManagementUIProps,
} from "./components/GCProjectReportManagementUI";
import GCDashAppReportSettings from "src/domain-features/sitesafety/components/dash-app-reports/GCDashAppReportSettings";

const query = graphql`
  query GCProjectReportManagementQuery($projectId: uuid!) {
    project_connection(where: { id: { _eq: $projectId } }, first: 1)
      @connection(
        key: "GCProjectReportManagementQuery_project_connection"
        filters: []
      ) {
      edges {
        node {
          id
          timezone
          dashboard_updated_at
          is_project_reporting_active
          pause_dash_notification_start_date
          pause_dash_notification_end_date
          timezone
          mark_sub_onsite_on_report_submission
          project_subcontractors(order_by: { subcontractor: { name: asc } }) {
            subcontractor_id
            subcontractor {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
            id
            pk: id @__clientField(handle: "pk")
            project_subcontractor_onsite_periods(
              where: { closed_at: { _is_null: true } }
            ) {
              id
            }
            project_subcontractor_safety_report_not_require_periods(
              where: { closed_at: { _is_null: true } }
            ) {
              id
            }
            project_subcontractor_daily_report_not_require_periods(
              where: { closed_at: { _is_null: true } }
            ) {
              id
            }
            project_subcontractor_toolbox_talk_not_require_periods(
              where: { closed_at: { _is_null: true } }
            ) {
              id
            }

            project_crews(
              where: { deleted_at: { _is_null: true } }
              order_by: { name: asc }
            ) {
              id
              name
              pk: id @__clientField(handle: "pk")
              lead_foreman_project_worker {
                user {
                  name
                }
              }
              project_subcontractor_crew_onsite_periods(
                where: { closed_at: { _is_null: true } }
              ) {
                id
              }
              project_subcontractor_crew_safety_report_not_require_periods(
                where: { closed_at: { _is_null: true } }
              ) {
                id
              }
              project_subcontractor_crew_daily_report_not_require_periods(
                where: { closed_at: { _is_null: true } }
              ) {
                id
              }
              project_subcontractor_crew_toolbox_talk_not_require_periods(
                where: { closed_at: { _is_null: true } }
              ) {
                id
              }
            }
          }
        }
      }
    }
  }
`;

const updateProjectMutation = graphql`
  mutation GCProjectReportManagementUpdateProjectMutation(
    $projectId: uuid!
    $set: project_set_input!
  ) {
    update_project_by_pk(pk_columns: { id: $projectId }, _set: $set) {
      id
      is_project_reporting_active
      mark_sub_onsite_on_report_submission
    }
  }
`;

const updatePauseDashNotificationDateMutation = graphql`
  mutation GCProjectReportManagement_UpdatePauseDateNotification_Mutation(
    $projectId: uuid!
    $startDate: timestamptz
    $endDate: timestamptz
  ) {
    update_project_by_pk(
      pk_columns: { id: $projectId }
      _set: {
        pause_dash_notification_start_date: $startDate
        pause_dash_notification_end_date: $endDate
      }
    ) {
      id
      pause_dash_notification_start_date
      pause_dash_notification_end_date
    }
  }
`;

const GCProjectReportManagement: React.FC<{
  type: "performance" | "settings";
  onlyReport?: boolean;
}> = ({ type }) => {
  const { projectId } = useParams();
  if (!projectId) {
    throw new Error("project id does not exists");
  }
  const data = useLazyLoadQuery<GCProjectReportManagementQuery>(query, {
    projectId,
  });
  const [updateProject] =
    useAsyncMutation<GCProjectReportManagementUpdateProjectMutation>(
      updateProjectMutation,
    );
  const [updatePauseDashNotificationDate] =
    useAsyncMutation<GCProjectReportManagement_UpdatePauseDateNotification_Mutation>(
      updatePauseDashNotificationDateMutation,
    );

  const projectData = data.project_connection.edges[0];
  const [pauseNotificationDateLoading, setPauseNotificationDateLoading] =
    useState(false);
  const crewLeads: { [id: string]: string } = {};
  const subData = projectData.node.project_subcontractors.map((ps) => {
    return {
      subId: ps.subcontractor.pk,
      projectSubId: ps.pk,
      subName: ps.subcontractor.name,
      safety_reports_required:
        !ps.project_subcontractor_safety_report_not_require_periods.length,
      toolbox_talks_required:
        !ps.project_subcontractor_toolbox_talk_not_require_periods.length,
      daily_reports_required:
        !ps.project_subcontractor_daily_report_not_require_periods.length,
      onsite: !!ps.project_subcontractor_onsite_periods.length,
      crews: ps.project_crews.map((pcrew) => {
        if (pcrew.lead_foreman_project_worker)
          crewLeads[pcrew.pk] = pcrew.lead_foreman_project_worker.user!.name;
        return {
          crewName: pcrew.name,
          crewLead: pcrew.lead_foreman_project_worker?.user?.name,
          crewId: pcrew.pk,
          safety_reports_required:
            !pcrew.project_subcontractor_crew_safety_report_not_require_periods
              .length,
          toolbox_talks_required:
            !pcrew.project_subcontractor_crew_toolbox_talk_not_require_periods
              .length,
          daily_reports_required:
            !pcrew.project_subcontractor_crew_daily_report_not_require_periods
              .length,
          onsite: !!pcrew.project_subcontractor_crew_onsite_periods.length,
        };
      }),
    };
  });

  const reportManagemtnProps: GCProjectReportManagementUIProps = {
    projectId,
    crewLeads: crewLeads,
    projectTimezone: projectData.node.timezone,
    dashboardStart: dayjs(projectData.node.dashboard_updated_at),
    subOnsiteData: subData,
    reportTracking: {
      active: projectData.node.is_project_reporting_active,
      switch: async (newValue) => {
        await updateProject({
          variables: {
            projectId,
            set: {
              is_project_reporting_active: newValue,
            },
          },
          optimisticResponse: {
            update_project_by_pk: {
              id: projectData.node.id,
              is_project_reporting_active: newValue,
              mark_sub_onsite_on_report_submission:
                projectData.node.mark_sub_onsite_on_report_submission,
            },
          },
        });
      },
    },
    projectPermanentOnsite: {
      active: projectData.node.mark_sub_onsite_on_report_submission,
      switch: async (newValue) => {
        updateProject({
          variables: {
            projectId,
            set: {
              mark_sub_onsite_on_report_submission: newValue,
            },
          },
          optimisticResponse: {
            update_project_by_pk: {
              id: projectData.node.id,
              is_project_reporting_active:
                projectData.node.is_project_reporting_active,
              mark_sub_onsite_on_report_submission: newValue,
            },
          },
        });
      },
    },
    pauseNotificationDate:
      projectData.node.pause_dash_notification_start_date &&
      projectData.node.pause_dash_notification_end_date &&
      dayjs(projectData.node.pause_dash_notification_end_date)
        .tz(projectData.node.timezone)
        .utc(true)
        .local()
        .toDate()
        .getTime() >= dayjs().toDate().getTime()
        ? [
            dayjs(projectData.node.pause_dash_notification_start_date)
              .tz(projectData.node.timezone)
              .utc(true)
              .local(),
            dayjs(projectData.node.pause_dash_notification_end_date)
              .tz(projectData.node.timezone)
              .utc(true)
              .local(),
          ]
        : [null, null],
    onChangePauseNotificationDate: async (range: RangeType | null) => {
      if (!range) {
        setPauseNotificationDateLoading(true);
        await updatePauseDashNotificationDate({
          variables: {
            projectId: projectId,
            startDate: null,
            endDate: null,
          },
          optimisticResponse: {
            update_project_by_pk: {
              id: projectData.node.id,
              pause_dash_notification_start_date: null,
              pause_dash_notification_end_date: null,
            },
          },
        });
        setPauseNotificationDateLoading(false);
        return;
      }
      const [from, to] = range;
      if (!from || !to) {
        return;
      }
      setPauseNotificationDateLoading(true);
      await updatePauseDashNotificationDate({
        variables: {
          projectId: projectId,
          startDate: from
            .startOf("d")
            .tz(projectData.node.timezone, true)
            .toISOString(),
          endDate: to
            .endOf("d")
            .tz(projectData.node.timezone, true)
            .toISOString(),
        },
        optimisticResponse: {
          update_project_by_pk: {
            id: projectId,
            pause_dash_notification_start_date: from
              .startOf("d")
              .tz(projectData.node.timezone, true)
              .toISOString(),
            pause_dash_notification_end_date: to
              .endOf("d")
              .tz(projectData.node.timezone, true)
              .toISOString(),
          },
        },
      });
      setPauseNotificationDateLoading(false);
    },
    pauseNotificationDateLoading,
  };

  return type === "settings" ? (
    <GCDashAppReportSettings
      projectId={projectId}
      crewLeads={crewLeads}
      subOnsiteData={reportManagemtnProps.subOnsiteData}
      projectTimezone={reportManagemtnProps.projectTimezone}
    />
  ) : (
    <GCProjectReportManagementUI {...reportManagemtnProps} />
  );
};

export default GCProjectReportManagement;
