import React from "react";
import useActiveRoute from "src/common/hooks/useActiveRoute";
import Icon, { IconType } from "./general/Icon";

export interface NavigationMenuItemProps {
  label: string;
  rootPath: string; // is used for detecting if the route is active
  icon?: IconType;
  onClick: () => void;
}

const NavigationMenuItem: React.FunctionComponent<NavigationMenuItemProps> = (
  props
) => {
  const isActive = useActiveRoute(props.rootPath);
  return (
    <div
      onClick={props.onClick}
      className={`cursor-pointer flex px-1 justify-center gap-0.5 items-center border transition-all select-none rounded-1 ${
        !isActive
          ? "border-interactive-secondary hover:border-interactive-primary py-0.75 text-1 bg-inherit "
          : "border-suplementary-1 py-1.5 font-accent bg-white shadow pointer-events-none z-10"
      }`}
    >
      {props.icon && <Icon size="small" color="static" icon={props.icon} />}
      <span className={`text-left`}>{props.label}</span>
    </div>
  );
};

export default NavigationMenuItem;
