import React, { useMemo } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Button from "src/common/components/general/Button";
import ErrorMessage from "src/utility-features/error-handling/components/ErrorMessage";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { useStripeCreatePortalSessionMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { InvoiceData } from "src/root/routes/views/general-contractor/billing/types";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import { SCBillingInvoicesWorkerQuery } from "src/common/types/generated/relay/SCBillingInvoicesWorkerQuery.graphql";
import { IconCreditCard } from "@tabler/icons";
import { message } from "antd";
import InvoiceList from "../../general-contractor/billing/InvoiceList";

const query = graphql`
  query SCBillingInvoicesWorkerQuery(
    $subId: uuid!
    $projectId: uuid!
    $startTime: timestamptz!
    $endTime: timestamptz!
  ) {
    invoice_connection(
      where: {
        status: { _in: ["open", "paid"] }
        stripe_account: {
          _or: [
            {
              subcontractor_billing_setting: {
                subcontractor_id: { _eq: $subId }
              }
            }
            {
              project_subcontractor_billing_settings: {
                subcontractor_id: { _eq: $subId }
                project_id: { _eq: $projectId }
              }
            }
          ]
        }
      }
      order_by: { period_start: desc }
      first: 12
    ) {
      edges {
        node {
          id
          due_date
          amount
          status
          created_at
          stripe_invoice_id
          period_start
          period_end
          paid
          number
          payment_url
          invoice_pdf
          lines {
            id
            description
            project_name
            quantity
            amount
            state_json
            price_type
            price_fixed
            unit_price_first
            unit_price_others
            date_from
            date_to
          }
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          general_contractor {
            billing_settings {
              payment_model
            }
          }
          billing_settings {
            use_project_level_price
            payment_model
          }
        }
      }
    }
    subcontractor_billing_settings_connection(
      where: { subcontractor_id: { _eq: $subId } }
    ) {
      edges {
        node {
          id
          stripe_account {
            id
          }
        }
      }
    }
    project_worker_connection(
      where: {
        subcontractor_id: { _eq: $subId }
        _or: [
          {
            submitted_reports: {
              created_at: { _gte: $startTime, _lte: $endTime }
            }
          }
          {
            toolbox_talks_submitted: {
              created_at: { _gte: $startTime, _lte: $endTime }
            }
          }
          {
            submitted_daily_reports: {
              created_at: { _gte: $startTime, _lte: $endTime }
            }
          }
        ]
      }
      order_by: { user: { name: asc } }
    ) {
      edges {
        node {
          id
          project {
            id
            name
          }
          user {
            name
          }
          title {
            translation {
              en
            }
          }
          worker_role
        }
      }
    }
  }
`;

interface CSBillingInvoicesProps {
  subId: string;
  projectId: string;
}

const CSBillingInvoices: React.FC<CSBillingInvoicesProps> = ({
  subId,
  projectId,
}) => {
  const navigate = useNavigate();
  const [createStripeSession, { loading }] =
    useStripeCreatePortalSessionMutation();
  const now = dayjs();
  const startTime = now.startOf("year");
  const endTime = now.endOf("month");
  console.log("sub id = ", subId);
  const data = useLazyLoadQuery<SCBillingInvoicesWorkerQuery>(query, {
    subId,
    projectId,
    startTime: startTime.format(),
    endTime: endTime.format(),
  });

  //const project = data.project_connection.edges.length >= 0?
  //    data.project_connection.edges[0].node: undefined;
  const gc_level = false; //!project?.billing_settings?.use_project_level_price;

  const paymentMethoddsClick = () => {
    createStripeSession({
      variables: {
        return_url: document.location.href,
        project_id: gc_level ? undefined : projectId,
      },
    })
      .then((res) => {
        const url = res.data?.stripeCreatePortalSession.url;
        if (url) document.location.href = url;
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const invoices = useMemo<InvoiceData[]>(() => {
    //const settings = data.subcontractor_billing_settings_connection.edges.map(e => e.node);
    //if (settings.length === 0)
    //      return [];
    //const account = settings[0].stripe_account;
    //if (!account)
    //return [];
    const invoices = data.invoice_connection.edges.map((e) => e.node);
    return invoices.map((invoice) => ({
      id: invoice.id,
      date: dayjs(invoice.period_start),
      paid: invoice.paid,
      period_start: dayjs(invoice.period_start),
      period_end: dayjs(invoice.period_end),
      title: "Invoice",
      detail: "Service detail",
      number: invoice.number ?? "",
      amount: invoice.amount / 100,
      payment_url: invoice.payment_url,
      invoice_pdf: invoice.invoice_pdf,
      lines: invoice.lines.map((line) => {
        const extra_data = line.state_json ? JSON.parse(line.state_json) : null;
        return {
          date: line.date_from || null,
          name:
            line.price_type === "per_worker" && line.project_name
              ? line.project_name
              : line.description,
          description:
            line.price_type === "per_worker"
              ? `${line.quantity} user${line.quantity !== 1 ? "s" : ""}`
              : "",
          hint: Array.isArray(extra_data)
            ? (extra_data.map((v) => v.name) as string[])
            : [],
          amount: line.amount / 100,
        };
      }),
    }));
  }, [data]);

  return (
    <BasicWrapper scrollable>
      <div className="flex flex-col w-48 gap-2">
        <div className={`flex flex-row items-center gap-1`}>
          <p className="flex-1 text-2">{`Invoices`}</p>
          <Button
            loading={loading}
            onClick={paymentMethoddsClick}
            label={`Payment methods`}
            icon={IconCreditCard}
          />
        </div>
        {!!invoices.length ? (
          <InvoiceList invoices={invoices} />
        ) : (
          <ErrorMessage message={`There're no invoices yet`} />
        )}
      </div>
    </BasicWrapper>
  );
};

export default CSBillingInvoices;
