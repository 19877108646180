import React from "react";
import {
  NewCertificationType,
  SiteOrientationStepProps,
  WorkerType,
} from "../../../utils/siteOrientationTypes";
import { OrientationType } from "src/common/types/manual/OrientationType";
import { Language } from "../../../../../utility-features/i18n/language-utils/i18nTypes";
import SiteOrientationCertificateUploadUI from "../../SiteOrientationCertificateUploadUI";

export interface SiteOrientationStepsUICertificationsProps
  extends SiteOrientationStepProps {
  user: WorkerType;
  type?: OrientationType;
  lang: Language;
  projectId?: string;
  managerDevice?: boolean;
  onCertificationsUpload: (newCertifications: NewCertificationType[]) => void;
  requireCerts?: boolean;
  projectCertifications?: Array<{
    certification_id: string;
    certification_name: string;
    alternate_certification_ids: string[];
  }>;
}

const SiteOrientationStepsUICertifications: React.FC<
  SiteOrientationStepsUICertificationsProps
> = (props) => {
  const userId = props.user.id;
  if (!userId)
    throw new Error(
      "userId is required for SiteOrientationCertificateUploadUI",
    );
  return (
    <SiteOrientationCertificateUploadUI
      userId={userId}
      projectId={props.projectId}
      managerDevice
      lang={props.lang}
      onCertificationsUpload={props.onCertificationsUpload}
      requireCerts={props.requireCerts || false}
      pastCertifications={props.user.certifications?.old?.map((cert) => {
        return {
          certification_id: cert.id,
          certification_name: cert.title,
        };
      })}
      projectCertifications={props.projectCertifications}
      onNext={props.onNext}
      onBack={props.onBack}
    />
  );
};

export default SiteOrientationStepsUICertifications;
