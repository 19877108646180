import { ConnectionHandler, SelectorStoreUpdater } from "relay-runtime";
import { useInsertSecretCodeMutation$data } from "src/common/types/generated/relay/useInsertSecretCodeMutation.graphql";

const buildSecretCodeUpdater =
  (
    connection: string,
  ): SelectorStoreUpdater<useInsertSecretCodeMutation$data> =>
  (store, data) => {
    const node = store.getRootField("insert_secret_login_link_one");
    const conn = ConnectionHandler.getConnection(store.getRoot(), connection);
    if (conn) {
      const edge = ConnectionHandler.createEdge(store, conn, node, "edge");
      ConnectionHandler.insertEdgeBefore(conn, edge);
    }
  };

export default buildSecretCodeUpdater;
