import { WarningFilled } from "@ant-design/icons";
import {
  IconCalendar,
  IconCopy,
  IconDotsVertical,
  IconPlus,
  IconTrash,
} from "@tabler/icons";
import { Checkbox, Dropdown, message, notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { forwardRef, useRef, useState } from "react";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import Button from "src/common/components/general/Button";
import CounterLabel from "src/common/components/general/CounterLabel";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import { InspectionInstanceTableQuery } from "src/common/types/generated/relay/InspectionInstanceTableQuery.graphql";
import { InspectionInstanceTable_InsertQR_Mutation, inspection_instance_qr_code_insert_input } from "src/common/types/generated/relay/InspectionInstanceTable_InsertQR_Mutation.graphql";
import { InspectionInstanceTable_inspection_instances$data } from "src/common/types/generated/relay/InspectionInstanceTable_inspection_instances.graphql";
import useUpdateInspectionInstance from "../../utils/useUpdateInspectionInstance";
import Tag from "src/common/components/general/Tag";
import { CustomTableCellWrapper } from "src/common/components/tables/basic/CustomTableCell";
import Icon from "src/common/components/general/Icon";
import getDateStr from "src/common/functions/getDateStr";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import { OptionType } from "../../utils/siteInspectionTypes";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import useDuplicateInspectionInstance from "../../utils/useDuplicateInspectionInstance";
import { auth } from "src/common/functions/firebase";
import inspectionOptionsDeleteUpdater from "../../utils/inspectionOptionsDeleteUpdater";
import newQRCodeInsertionUpdater from "../../utils/newQRCodeInsertionUpdater";

type ColumnKeys =
  | "action"
  | "date"
  | "extra"
  | "name"
  | "completed"
  | "last_completed"
  | "qrs"
  | "model"
  | "project"
  | "labels"
  | "created_by"
  | "check";
const CONNECTION_NAME = "inspection_instance_connection";
type DConnection =
  InspectionInstanceTable_inspection_instances$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  InspectionInstanceTableQuery,
  string
> & {
  projectId: string;
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    InspectionInstanceTableQuery
  >["columns"];
  showDateRangeFilter?: boolean;
  hideFiltersAndSort?: boolean;
  onInspectionsSelected?: {
    label: string;
    loading: boolean;
    onClick: (selectedInspections: InspectionInstanceType[]) => Promise<void>;
  };
  expandedRowRender?: (insp: InspectionInstanceType) => React.ReactElement;
  labelOptions: Array<OptionType>;
  qrOptions: Array<OptionType>;
  headerComponent?: React.ReactElement;
  OnRowItemClick?: (insp: InspectionInstanceType) => void;
};
export type InspectionInstanceType = DConnection["edges"][number]["node"];

const InspectionInstanceTable = forwardRef<DataScrollTableRef, Props>(
  (
    { title = "Inspections", onRowClick, showDateRangeFilter, ...props },
    ref,
  ) => {
    const [selectedInspections, setSelectedInspections] = useState<{
      [key: string]: InspectionInstanceType;
    }>({});
    const [loadingRows, setLoadingRows] = useState<{ [key: string]: boolean }>(
      {},
    );
    const [duplicate, isDuplicating] = useDuplicateInspectionInstance(
      props.projectId,
    );
    const [openModal, setOpenModal] = useState(false);
    const modal =
      useRef<FModalRef<{ selectedQrs?: Array<string>; newQr?: string }>>(null);
    const [updateInspection, isUpdating] = useUpdateInspectionInstance();
    const [insertInspectionQRs, isInserting] =
      useAsyncMutation<InspectionInstanceTable_InsertQR_Mutation>(
        graphql`
          mutation InspectionInstanceTable_InsertQR_Mutation(
            $objects: [inspection_instance_qr_code_insert_input!]!
          ) {
            insert_inspection_instance_qr_code(
              objects: $objects
              on_conflict: {
                constraint: inspection_instance_qr_code_inspection_qr_code_id_inspection_in
                update_columns: []
              }
            ) {
              returning {
                inspection_qr_code_id
                inspection_qr_code {
                  name
                  id
                  pk: id @__clientField(handle: "pk")
                }
              }
            }
          }
        `,
      );
    return (
      <>
        <FModal
          visible={openModal}
          ref={modal}
          confirmLoading={isInserting}
          title="Add to QR(s)"
          onCancel={() => setOpenModal(false)}
          onOk={async () => {
            try {
              const vals = await modal.current?.form.validateFields();
              if (!vals) {
                throw new Error("Form not submitted successfully");
              }
              const inspectionIds = Object.keys(selectedInspections);
              const objects: inspection_instance_qr_code_insert_input[] =
                inspectionIds.flatMap((inspId) =>
                  (vals.selectedQrs || []).map((qrId) => ({
                    inspection_qr_code_id: qrId,
                    inspection_instance_id: inspId,
                  })),
                );
              if (vals.newQr) {
                inspectionIds.forEach((inspectionId) => {
                  objects.push({
                    inspection_instance_id: inspectionId,
                    inspection_qr_code: {
                      data: {
                        created_by_uid: auth.currentUser?.uid,
                        project_id: props.projectId,
                        name: vals.newQr,
                      },
                    },
                  });
                });
              }
              if (objects.length > 0)
                await insertInspectionQRs({
                  variables: { objects },
                  updater: (store) => {
                    const returning = store
                      .getRootField("insert_inspection_instance_qr_code")
                      .getLinkedRecords("returning", {
                        where: {
                          inspection_qr_code: {
                            deleted_at: { _is_null: true },
                          },
                        },
                      });
                    if (vals.newQr) {
                      newQRCodeInsertionUpdater(
                        store,
                        props.qrOptions,
                        returning,
                      );
                    }
                  },
                });
              if (typeof ref !== `function`) ref?.current?.refetch();
              setOpenModal(false);
            } catch (err) {
              console.log(err);
              notification.error({
                message: "Couldn't Add inspections to QR",
                description:
                  err instanceof Error ? err.message : JSON.stringify(err),
              });
            }
          }}
          okText={"Add +"}
        >
          <FModal.Select
            name={"selectedQrs"}
            label="Select QRs you want to add these inspections to"
            props={{
              mode: "multiple",
              options: props.qrOptions,
            }}
          />
          OR/AND
          <FModal.Text
            name={"newQr"}
            label={
              <div>
                Create a new QR and add these inspections to it
                <br />
                NOTE: Keep it empty if you do not want to create a new QR
              </div>
            }
          ></FModal.Text>
        </FModal>
        <DataScrollTable<DConnection, ColumnKeys, InspectionInstanceTableQuery>
          {...props}
          newCustomTableLook={!props.expandedRowRender}
          ref={ref}
          hideFiltersAndSort={props.hideFiltersAndSort}
          title={title}
          defaultTableSort={{ key: "date", order: "desc" }}
          datePickerDataIndex={showDateRangeFilter ? ["created_at"] : undefined}
          onRowClick={(insp) => {
            props.OnRowItemClick && props.OnRowItemClick(insp);
          }}
          expandable={
            props.expandedRowRender
              ? {
                  rowExpandable: (row) => true,
                  expandedRowRender: props.expandedRowRender,
                }
              : undefined
          }
          connectionName={CONNECTION_NAME}
          totalCountConnectionName={"allInstancesConnection"}
          topBarButtons={[
            ...(props.topBarButtons || []),
            ...(Object.keys(selectedInspections).length
              ? props.onInspectionsSelected
                ? [
                    {
                      label: props.onInspectionsSelected.label,
                      loading: props.onInspectionsSelected.loading,
                      secondary: true,
                      icon: IconPlus,
                      onClick: () =>
                        props.onInspectionsSelected?.onClick(
                          Object.values(selectedInspections),
                        ),
                    },
                  ]
                : [
                    {
                      label: "Add to QR",
                      onClick: () => setOpenModal(true),
                      secondary: true,
                    },
                  ]
              : []),
          ]}
          columns={[
            {
              title: "",
              size: "icon",
              width: "10%",
              key: "check",
              dataIndex: ["name"],
              render: (_, row) => {
                return (
                  <div
                    className="flex justify-center"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Checkbox
                      className="hover:cursor-pointer"
                      checked={!!selectedInspections[row.pk]}
                      onChange={(e) => {
                        if (selectedInspections[row.pk]) {
                          setSelectedInspections((prev) => {
                            const newObj = { ...prev };
                            delete newObj[row.pk];
                            return { ...newObj };
                          });
                        } else {
                          setSelectedInspections((prev) => ({
                            ...prev,
                            [row.pk]: row,
                          }));
                        }
                      }}
                    />
                  </div>
                );
              },
            },
            {
              title: "Name",
              key: "name",
              size: "md",
              width: "50%",
              dataIndex: ["name", "en"],
              searchDataIndex: ["name", "en"],
              contentType: { type: "row-title" },
            },
            {
              title: "Model #",
              key: "model",
              size: "sm",
              sortable: true,
              dataIndex: ["model_number"],
              searchDataIndex: ["model_number"],
              render: (val) => val ?? "",
            },
            {
              title: "Completed",
              key: "completed",
              size: "sm",
              sortable: true,
              dataIndex: ["inspection_results_aggregate", "aggregate", "count"],
              contentType: { type: "counter" },
            },
            {
              title: "Last completed",
              key: "last_completed",
              size: "md",
              sortable: true,
              dataIndex: ["inspection_results", "aggregate", "count"],
              render: (_, row) =>
                (row.inspection_results || []).length > 0 ? (
                  <CustomTableCellWrapper>
                    <div className="flex flex-row gap-0.5 items-center ">
                      <Icon {...{ icon: IconCalendar, color: "static" }} />
                      <span className="text-1">
                        {getDateStr(
                          dayjs(row.inspection_results[0].created_at),
                          {
                            format: "full",
                            withTime: true,
                          },
                        )}
                      </span>{" "}
                    </div>
                  </CustomTableCellWrapper>
                ) : (
                  ""
                ),
            },

            {
              title: "Labels",
              key: "labels",
              size: "sm",
              sortable: true,
              dataIndex: ["inspection_instance_labels"],
              // ...(props.labelOptions
              //   ? {
              //       filters: {
              //         type: "checklist",
              //         dataIndex: ["inspection_instance_labels"],
              //         options: props.labelOptions.map((r) => ({
              //           text: r.label,
              //           value: r.value,
              //         })),
              //       },
              //     }
              //   : {}),
              render: (_, row) => {
                return row.inspection_labels.length ? (
                  row.inspection_labels.length === 1 ? (
                    <Tag status="inactive">
                      {row.inspection_labels[0].label.label_name}
                    </Tag>
                  ) : (
                    <CounterLabel
                      count={row.inspection_labels.length}
                      label="Labels"
                      popover={{
                        trigger: "hover",
                        placement: "bottom",
                        content: (
                          <div>
                            {row.inspection_labels.map((qr) => (
                              <div className="mt-0.5" key={qr.label_id}>
                                {qr.label.label_name}
                              </div>
                            ))}
                          </div>
                        ),
                      }}
                    />
                  )
                ) : (
                  ""
                );
              },
            },
            {
              title: "QR",
              key: "qrs",
              size: "sm",
              sortable: true,
              dataIndex: ["inspection_instance_qr_codes"],
              // ...(props.qrOptions
              //   ? {
              //       filters: {
              //         type: "checklist",
              //         initialClose: true,
              //         dataIndex: ["subcontractor_id"],
              //         options: props.qrOptions.map((r) => ({
              //           text: r.label,
              //           value: r.value,
              //         })),
              //       },
              //     }
              //   : {}),
              render: (_, row) => {
                return row.inspection_instance_qr_codes.length ? (
                  row.inspection_instance_qr_codes.length === 1 ? (
                    <Tag status="inactive">
                      {
                        row.inspection_instance_qr_codes[0].inspection_qr_code
                          .name
                      }
                    </Tag>
                  ) : (
                    <CounterLabel
                      count={row.inspection_instance_qr_codes.length}
                      label="QR"
                      popover={{
                        trigger: "hover",
                        placement: "bottom",
                        content: (
                          <div>
                            {row.inspection_instance_qr_codes.map((qr) => (
                              <div
                                className="mt-0.5"
                                key={qr.inspection_qr_code_id}
                              >
                                {qr.inspection_qr_code.name}
                              </div>
                            ))}
                          </div>
                        ),
                      }}
                    />
                  )
                ) : (
                  ""
                );
              },
            },
            {
              title: "Created On",
              key: "date",
              sortable: true,
              contentType: {
                type: "date",
                renderOptions: () => ({ format: "full", withTime: true }),
              },
              size: "sm",
              defaultSortOrder: "desc",
              dataIndex: ["created_at"],
            },
            {
              title: "Created By",
              key: "created_by",
              sortable: true,
              size: "md",
              defaultSortOrder: "asc",
              dataIndex: ["created_by_user", "name"],
              searchDataIndex: ["created_by_user", "name"],
            },
            {
              title: "Project",
              key: "project",
              sortable: true,
              defaultSortOrder: "asc",
              dataIndex: ["project", "name"],
            },
            {
              title: "Action",
              key: "action",
              size: "icon",
              dataIndex: ["pk"],
              render: (pk, row) => (
                <Dropdown
                  placement={"bottomRight"}
                  menu={{
                    items: [
                      {
                        key: "delete",
                        label: (
                          <BPopconfirm
                            cancelText={"No"}
                            icon={<WarningFilled color="red" />}
                            onConfirm={async (e) => {
                              //delete worker
                              e?.stopPropagation();
                              if (pk) {
                                setLoadingRows((prev) => ({
                                  ...prev,
                                  [pk]: true,
                                }));
                                try {
                                  await updateInspection({
                                    variables: {
                                      id: pk,
                                      _set: {
                                        deleted_at: dayjs().toISOString(),
                                      },
                                    },
                                    optimisticResponse: {
                                      update_inspection_instance_by_pk: {
                                        id: row.id,
                                        pk,
                                        deleted_at: dayjs().toISOString(),
                                      },
                                    },
                                    updater: (store) => {
                                      inspectionOptionsDeleteUpdater(
                                        row.id,
                                        store,
                                      );
                                    },
                                  });
                                  message.success(
                                    "Deleted Inspection Successfully",
                                  );
                                } catch (e) {
                                  console.log(e);
                                  notification.error({
                                    message:
                                      "Couldn't delete Inspection: " +
                                      row.name.en,
                                    description:
                                      e instanceof Error
                                        ? e.message
                                        : JSON.stringify(e),
                                  });
                                }
                                setLoadingRows((prev) => ({
                                  ...prev,
                                  [pk]: false,
                                }));
                              }
                            }}
                            placement="topRight"
                            title={
                              <div className="w-24">
                                <div className="text-semantic-negative font-accent">
                                  Delete this Inspection
                                </div>
                                <div>
                                  Do you want to delete this Inspection?
                                  deleting this means you agree to remove this
                                  Inspection from all Inspection QRs present on
                                  your project. <br />
                                  Are you sure?
                                </div>
                              </div>
                            }
                          >
                            <Button
                              label=""
                              delete
                              loading={!!loadingRows[pk]}
                              icon={IconTrash}
                              onClick={() => {}}
                            />
                          </BPopconfirm>
                        ),
                      },
                      {
                        key: "duplicate",
                        label: (
                          <BPopconfirm
                            cancelText={"No"}
                            icon={<WarningFilled color="red" />}
                            onConfirm={async (e) => {
                              //delete worker
                              e?.stopPropagation();
                              if (pk) {
                                setLoadingRows((prev) => ({
                                  ...prev,
                                  [pk]: true,
                                }));
                                try {
                                  const { insert_inspection_instance } =
                                    await duplicate(row);
                                  message.success("Copied Inspection");
                                  if (
                                    insert_inspection_instance &&
                                    insert_inspection_instance.returning[0]
                                  )
                                    props.OnRowItemClick &&
                                      props.OnRowItemClick(
                                        insert_inspection_instance.returning[0],
                                      );
                                } catch (e) {
                                  console.log(e);
                                  notification.error({
                                    message:
                                      "Couldn't Couldn't copy inspection: " +
                                      row.name.en,
                                    description:
                                      e instanceof Error
                                        ? e.message
                                        : JSON.stringify(e),
                                  });
                                }
                                setLoadingRows((prev) => ({
                                  ...prev,
                                  [pk]: false,
                                }));
                              }
                            }}
                            placement="topRight"
                            title={
                              <div className="w-24">
                                <div>
                                  Do you want to create another similar
                                  inspection to this? <br />
                                  Are you sure?
                                </div>
                              </div>
                            }
                          >
                            <Button
                              green
                              icon={IconCopy}
                              loading={!!loadingRows[pk]}
                              onClick={() => {}}
                              label=""
                            />
                          </BPopconfirm>
                        ),
                      },
                    ],
                  }}
                  trigger={["click"]}
                >
                  <Button
                    secondary
                    icon={IconDotsVertical}
                    onClick={() => console.log("")}
                  />
                </Dropdown>
              ),
            },
          ]}
          queryNode={graphql`
            query InspectionInstanceTableQuery(
              $first: Int!
              $after: String
              $where: inspection_instance_bool_exp!
              $order_by: [inspection_instance_order_by!]!
            ) {
              ...InspectionInstanceTable_inspection_instances
                @arguments(
                  first: $first
                  after: $after
                  where: $where
                  order_by: $order_by
                )
              ...InspectionInstanceTable_total @arguments(where: $where)
            }
          `}
          totalCountNode={graphql`
            fragment InspectionInstanceTable_total on query_root
            @argumentDefinitions(
              where: { type: "inspection_instance_bool_exp!" }
            )
            @refetchable(
              queryName: "InspectionInstanceTableTotalRefetchableQuery"
            ) {
              allInstancesConnection: inspection_instance_connection(
                where: $where
              ) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          `}
          paginationNode={graphql`
            fragment InspectionInstanceTable_inspection_instances on query_root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              where: { type: "inspection_instance_bool_exp!" }
              order_by: { type: "[inspection_instance_order_by!]!" }
            )
            @refetchable(queryName: "InspectionInstanceTableRefetchableQuery") {
              inspection_instance_connection(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
                @connection(
                  key: "InspectionInstanceTable_inspection_instance_connection"
                  filters: []
                ) {
                edges {
                  node {
                    ...InspectionInstanceFrag @relay(mask: false)
                  }
                }
              }
            }
          `}
        />
      </>
    );
  },
);

export default InspectionInstanceTable;
