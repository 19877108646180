/**
 * @generated SignedSource<<576a8daeefea544e4057516c962fc565>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QuickLookWorkerQuery$variables = {
  reference: string;
};
export type QuickLookWorkerQuery$data = {
  readonly orientation_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly general_contractor: {
          readonly name: string;
        } | null | undefined;
        readonly general_contractor_id: string | null | undefined;
        readonly orientation_results: ReadonlyArray<{
          readonly completed_at: string | null | undefined;
          readonly expired_at: string | null | undefined;
          readonly id: string;
        }>;
        readonly project_orientations: ReadonlyArray<{
          readonly project_id: string | null | undefined;
        }>;
      };
    }>;
  };
  readonly user_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly universal_orientations: ReadonlyArray<{
          readonly universal_orientated_at: string;
        }>;
        readonly worker: {
          readonly worker_projects: ReadonlyArray<{
            readonly project: {
              readonly general_contractor: {
                readonly name: string;
              };
              readonly general_contractor_id: string;
            };
            readonly project_id: string;
            readonly user_orientation: {
              readonly completed_at: string | null | undefined;
              readonly orientated_at: string | null | undefined;
            } | null | undefined;
          }>;
        } | null | undefined;
      };
    }>;
  };
};
export type QuickLookWorkerQuery = {
  response: QuickLookWorkerQuery$data;
  variables: QuickLookWorkerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reference"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "_eq",
        "variableName": "reference"
      }
    ],
    "kind": "ObjectValue",
    "name": "reference_number"
  }
],
v2 = {
  "fields": (v1/*: any*/),
  "kind": "ObjectValue",
  "name": "user"
},
v3 = {
  "_eq": true
},
v4 = {
  "required_by_all_workers": (v3/*: any*/)
},
v5 = [
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "deleted_at",
        "value": {
          "_is_null": true
        }
      },
      {
        "fields": [
          (v2/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "orientation_results"
      },
      {
        "kind": "Literal",
        "name": "project_orientations",
        "value": (v4/*: any*/)
      },
      {
        "kind": "Literal",
        "name": "type",
        "value": {
          "_eq": "corporate"
        }
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "general_contractor_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "general_contractor",
  "kind": "LinkedField",
  "name": "general_contractor",
  "plural": false,
  "selections": [
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": (v4/*: any*/)
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed_at",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "order_by",
      "value": [
        {
          "completed_at": "desc"
        },
        {
          "created_at": "desc"
        }
      ]
    },
    {
      "fields": [
        {
          "kind": "Literal",
          "name": "status",
          "value": {
            "_eq": "completed"
          }
        },
        (v2/*: any*/)
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "orientation_result",
  "kind": "LinkedField",
  "name": "orientation_results",
  "plural": true,
  "selections": [
    (v11/*: any*/),
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expired_at",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = [
  {
    "fields": (v1/*: any*/),
    "kind": "ObjectValue",
    "name": "where"
  }
],
v15 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "universal_orientated_at": "desc"
    }
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "universal_orientated_at",
  "storageKey": null
},
v17 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "is_last": (v3/*: any*/)
    }
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orientated_at",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "general_contractor",
  "kind": "LinkedField",
  "name": "general_contractor",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuickLookWorkerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "orientationConnection",
        "kind": "LinkedField",
        "name": "orientation_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "orientationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "orientation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "project_orientation",
                    "kind": "LinkedField",
                    "name": "project_orientations",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/)
                    ],
                    "storageKey": "project_orientations(where:{\"required_by_all_workers\":{\"_eq\":true}})"
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": (v15/*: any*/),
                    "concreteType": "user_universal_orientation",
                    "kind": "LinkedField",
                    "name": "universal_orientations",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/)
                    ],
                    "storageKey": "universal_orientations(limit:1,order_by:{\"universal_orientated_at\":\"desc\"})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v17/*: any*/),
                        "concreteType": "project_worker",
                        "kind": "LinkedField",
                        "name": "worker_projects",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "project",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "user_orientation",
                            "kind": "LinkedField",
                            "name": "user_orientation",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v18/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "worker_projects(where:{\"is_last\":{\"_eq\":true}})"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuickLookWorkerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "orientationConnection",
        "kind": "LinkedField",
        "name": "orientation_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "orientationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "orientation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "project_orientation",
                    "kind": "LinkedField",
                    "name": "project_orientations",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": "project_orientations(where:{\"required_by_all_workers\":{\"_eq\":true}})"
                  },
                  (v13/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": (v15/*: any*/),
                    "concreteType": "user_universal_orientation",
                    "kind": "LinkedField",
                    "name": "universal_orientations",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": "universal_orientations(limit:1,order_by:{\"universal_orientated_at\":\"desc\"})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v17/*: any*/),
                        "concreteType": "project_worker",
                        "kind": "LinkedField",
                        "name": "worker_projects",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "project",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v19/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "user_orientation",
                            "kind": "LinkedField",
                            "name": "user_orientation",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v18/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": "worker_projects(where:{\"is_last\":{\"_eq\":true}})"
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "76096b355e3e905111ef3a8d325cd066",
    "id": null,
    "metadata": {},
    "name": "QuickLookWorkerQuery",
    "operationKind": "query",
    "text": "query QuickLookWorkerQuery(\n  $reference: String!\n) {\n  orientation_connection(where: {deleted_at: {_is_null: true}, type: {_eq: \"corporate\"}, project_orientations: {required_by_all_workers: {_eq: true}}, orientation_results: {user: {reference_number: {_eq: $reference}}}}) {\n    edges {\n      node {\n        general_contractor_id\n        general_contractor {\n          name\n          id\n        }\n        project_orientations(where: {required_by_all_workers: {_eq: true}}) {\n          project_id\n          id\n        }\n        orientation_results(where: {user: {reference_number: {_eq: $reference}}, status: {_eq: \"completed\"}}, order_by: [{completed_at: desc}, {created_at: desc}]) {\n          id\n          completed_at\n          expired_at\n        }\n        id\n      }\n    }\n  }\n  user_connection(where: {reference_number: {_eq: $reference}}) {\n    edges {\n      node {\n        name\n        universal_orientations(limit: 1, order_by: {universal_orientated_at: desc}) {\n          universal_orientated_at\n          id\n        }\n        worker {\n          worker_projects(where: {is_last: {_eq: true}}) {\n            project_id\n            project {\n              general_contractor_id\n              general_contractor {\n                name\n                id\n              }\n              id\n            }\n            user_orientation {\n              completed_at\n              orientated_at\n              id\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "23b70dd299671c2e203ee22d52b8272d";

export default node;
