import * as React from "react";

interface TaskRequestSubApprovalProps {
  onClick: () => void;
  title: string;
}

const TaskRequestSubApproval = (props: TaskRequestSubApprovalProps) => {
  return (
    <div
      className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
      onClick={() => props.onClick()}
    >
      {props.title}
    </div>
  );
};

export default TaskRequestSubApproval;
