import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio, Select, AutoComplete } from 'antd';

interface UserEmail {
  name: string;
  email: string;
}
interface Props {
  visible: boolean;
  companyName?: string;
  users: Array<UserEmail>;
  onSelect: (email: string) => void;
  onCancel: () => void;
}

const SelectStripeEmailModal: React.FC<Props> = ({
  visible,
  users,
  companyName,
  onSelect,
  onCancel,
}) => {
  const [form] = Form.useForm();

  return (
    (<Modal
      open={visible}
      title="Enter Stripe account email"
      okText="Add"
      cancelText="Cancel"
      onCancel={ () => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSelect(values.email);
            form.resetFields();
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ email: '' }}
      >
        <Form.Item
          name="email"
          label={"Enter default billing email address for " + companyName}
          rules={[{ required: true, message: 'Stripe email address is required' }]}
        >
          <AutoComplete
            options={users.map(u => ({
              value: u.email,
              label: <div>{ u.name } ({ u.email })</div>
            }))}
            style={{ width: "100%" }}
            placeholder="Enter billing email address"
          />
        </Form.Item>
      </Form>
    </Modal>)
  );
};

export default SelectStripeEmailModal;