import { RcFile } from "antd/es/upload";
import uploadFile from "./uploadFile";

// temporary fix to make it compatible
// remove this method
const filterFailedTasks = async <T,>(tasks: Promise<T>[]) => {
  const waitres = await Promise.allSettled(tasks);
  const res: T[] = [];
  for (const task of waitres) {
    if (task.status === "fulfilled") {
      res.push(task.value);
    } else if (task.status === "rejected") {
      console.log("upload failed", task.reason);
    }
  }
  return res;
};
//all<T extends readonly unknown[] | []>(values: T): Promise<{ -readonly [P in keyof T]: Awaited<T[P]>; }>;

const uploadFiles = (files: Array<File>) =>
  filterFailedTasks(files.map((file) => uploadFile(file)));

export default uploadFiles;
export const uploadFilesWithPath = (
  files: Array<{
    file: File;
    directoryName: string;
  }>,
) => filterFailedTasks(files.map((o) => uploadFile(o.file, o.directoryName)));
