import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import { useUpdateInspectionInstanceMutation$data } from "src/common/types/generated/relay/useUpdateInspectionInstanceMutation.graphql";

const inspectionOptionsDeleteUpdater = (
  id: string,
  store: RecordSourceSelectorProxy<useUpdateInspectionInstanceMutation$data>
) => {
  const optionsConn = ConnectionHandler.getConnection(
    store.getRoot(),
    "GCInspectionsQuery_inspection_instance_connection"
  );
  const conn = ConnectionHandler.getConnection(
    store.getRoot(),
    "InspectionInstanceTable_inspection_instance_connection"
  );
  if (optionsConn) {
    ConnectionHandler.deleteNode(optionsConn, id);
  }
  if (conn) {
    ConnectionHandler.deleteNode(conn, id);
  }
};

export default inspectionOptionsDeleteUpdater;
