import { DatePicker, Image, Input, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, forwardRef, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import BSpace from "src/common/components/layouts/BSpace";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { SFUpdateCertsDataCertificationQuery } from "src/common/types/generated/relay/SFUpdateCertsDataCertificationQuery.graphql";
import { SFUpdateCertsDataMutation } from "src/common/types/generated/relay/SFUpdateCertsDataMutation.graphql";

import { SFUpdateCertsDataQuery } from "src/common/types/generated/relay/SFUpdateCertsDataQuery.graphql";
import { SFUpdateCertsData_worker_certification$data } from "src/common/types/generated/relay/SFUpdateCertsData_worker_certification.graphql";
import GetFullID from "../../../../common/functions/GetFullId";

export type ColumnKeys =
  | "name"
  | "certification"
  | "issueDate"
  | "expireDate"
  | "verified_by"
  | "created_at";
const CONNECTION_NAME = "worker_certification_connection";
type DConnection =
  SFUpdateCertsData_worker_certification$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  SFUpdateCertsDataQuery,
  { worker_id: string | unknown; project_id: string | unknown }
> & {
  onRowClickPK: (pk: string) => void;
  searchString?: string;
  certsData: SFUpdateCertsDataCertificationQuery["response"]["certification_connection"]["edges"];
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    SFUpdateCertsDataQuery
  >["columns"];
  markDeleted?: boolean;
  allowEditHH?: boolean;
};
const SFUpdateCertsDataTable = forwardRef<DataScrollTableRef, Props>(
  (
    {
      title = "Update Certs Data table",
      onRowClickPK,
      searchString,
      where,
      certsData,
      ...props
    },
    ref,
  ) => {
    const [updateCertsData, isUpdating] =
      useAsyncMutation<SFUpdateCertsDataMutation>(graphql`
        mutation SFUpdateCertsDataMutation(
          $id: uuid!
          $_set: worker_certification_set_input!
        ) {
          update_worker_certification_by_pk(
            pk_columns: { id: $id }
            _set: $_set
          ) {
            id
            issued_on
            expires_on
            certification_id
          }
        }
      `);
    return (
      <DataScrollTable<DConnection, ColumnKeys, SFUpdateCertsDataQuery>
        {...props}
        ref={ref}
        title={title}
        onRowClick={({ pk }) => onRowClickPK && onRowClickPK(pk)}
        where={where}
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allSubcontractorsToAddConnection"}
        expandable={{
          rowExpandable: (row) => true,
          expandedRowRender: (row: DConnection["edges"][number]["node"]) => (
            <div className="flex items-center justify-center gap-1">
              {row.images.map((image, i) => (
                <Image src={image.url} key={i} height={500} />
              ))}
            </div>
          ),
        }}
        columns={[
          {
            title: "Worker",
            key: "name",
            size: "md",
            dataIndex: ["worker", "user", "name"],
            searchDataIndex: ["worker", "user", "name"],
            searchValue: searchString,
          },
          {
            title: "Certification Name",
            key: "certification",
            size: "lg",
            dataIndex: ["certification", "name"],
            render: (_, r) => (
              <Select
                loading={isUpdating}
                dropdownMatchSelectWidth={400}
                className="w-12"
                onChange={(a) => {
                  updateCertsData({
                    variables: { id: r.pk, _set: { certification_id: a } },
                    optimisticResponse: {
                      update_worker_certification_by_pk: {
                        id: GetFullID("worker_certification", r.pk),
                        certification_id: a,
                        issued_on: r.issued_on,
                        expires_on: r.expires_on,
                      },
                    },
                  });
                }}
                value={r.certification_id}
              >
                {certsData.map((c) => (
                  <Select.Option value={c.node.pk}>{c.node.name}</Select.Option>
                ))}
              </Select>
            ),
          },
          {
            title: "Issue Date",
            key: "issueDate",
            size: "md",
            dataIndex: ["issued_on"],
            render: (_, r) => (
              <DatePicker
                onChange={(a) => {
                  const newDate = a ? a.format() : null;
                  updateCertsData({
                    variables: {
                      id: r.pk,
                      _set: { issued_on: newDate },
                    },
                    optimisticResponse: {
                      update_worker_certification_by_pk: {
                        id: GetFullID("worker_certification", r.pk),
                        certification_id: r.certification_id,
                        issued_on: newDate,
                        expires_on: r.expires_on,
                      },
                    },
                  });
                }}
                value={r.issued_on ? dayjs(r.issued_on) : null}
              />
            ),
          },
          {
            title: "Expires On",
            key: "expireDate",
            size: "md",
            dataIndex: ["expires_on"],

            render: (_, r) => (
              <DatePicker
                onChange={(a) => {
                  const newDate = a ? a.format() : null;
                  updateCertsData({
                    variables: {
                      id: r.pk,
                      _set: { expires_on: newDate },
                    },
                    optimisticResponse: {
                      update_worker_certification_by_pk: {
                        id: GetFullID("worker_certification", r.pk),
                        certification_id: r.certification_id,
                        issued_on: r.issued_on,
                        expires_on: newDate,
                      },
                    },
                  });
                }}
                value={r.expires_on ? dayjs(r.expires_on) : null}
              />
            ),
          },
          {
            title: "Verified By",
            key: "verified_by",
            size: "md",
            dataIndex: ["verification", "verified_by_user", "name"],
          },
          {
            title: "Created At",
            key: "created_at",
            size: "md",
            dataIndex: ["created_at"],
            sortable: true,
            defaultSortOrder: "desc",
            render: (a, r) => dayjs(a).format("DD MMM, YYYY"),
          },
        ]}
        queryNode={graphql`
          query SFUpdateCertsDataQuery(
            $first: Int!
            $after: String
            $where: worker_certification_bool_exp!
            $order_by: [worker_certification_order_by!]!
          ) {
            ...SFUpdateCertsData_worker_certification
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
            ...SFUpdateCertsData_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment SFUpdateCertsData_total on query_root
          @argumentDefinitions(
            where: { type: "worker_certification_bool_exp!" }
          )
          @refetchable(queryName: "SFUpdateCertsDataTotalRefetchableQuery") {
            allSubcontractorsToAddConnection: worker_certification_connection(
              where: $where
            ) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment SFUpdateCertsData_worker_certification on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "worker_certification_bool_exp!" }
            order_by: { type: "[worker_certification_order_by!]!" }
          )
          @refetchable(queryName: "SFUpdateCertsDataRefetchableQuery") {
            worker_certification_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "SFUpdateCertsData_worker_certification_connection"
                filters: ["where", "order_by"]
              ) {
              edges {
                node {
                  pk: id @__clientField(handle: "pk")
                  id
                  issued_on
                  expires_on
                  created_at
                  certification_id
                  verification {
                    verified_by_user {
                      name
                    }
                  }
                  certification {
                    name
                  }
                  worker {
                    user {
                      name
                    }
                  }
                  images {
                    url
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);

const SFUpdateCertsData: FC = () => {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const onInputKeyDown = (event: any): any => {
    if (event.key === "Enter") {
      setInputValue(event.target.value);
    }
  };
  const CertsData = useLazyLoadQuery<SFUpdateCertsDataCertificationQuery>(
    graphql`
      query SFUpdateCertsDataCertificationQuery {
        certification_connection(
          where: { is_custom: { _eq: false } }
          order_by: { name: asc }
        ) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
          }
        }
      }
    `,
    {},
  );
  return (
    <>
      <BSpace>
        <div className="flex items-center justify-center m-2.5">
          Update Previously Uploaded Certs Data
        </div>
        <div className="flex justify-between float-right">
          <Input
            type="primary"
            onKeyDown={onInputKeyDown}
            className="w-12"
            placeholder="Search"
          />
        </div>
      </BSpace>
      <BSpace>
        <SFUpdateCertsDataTable
          searchString={inputValue}
          certsData={CertsData.certification_connection.edges}
          where={{
            verification_id: { _is_null: false },
          }}
          onRowClickPK={(pk) => {}}
        />
      </BSpace>
    </>
  );
};
export default SFUpdateCertsData;
