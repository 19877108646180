import React, { useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { Form, Modal, Select, message } from "antd";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useParams } from "react-router-dom";
import * as uuid from "uuid";
import { ConnectionHandler } from "relay-runtime";
import dayjs from "dayjs";
import {
  IconCheck,
  IconX,
  IconKey,
  IconLogout,
  IconSettings,
} from "@tabler/icons";
import { gql, useQuery } from "@apollo/client";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import Switcher from "../general/Switcher";
import { TurnOffEmailModal_toggleDailyEmailSwitch_Mutation } from "src/common/types/generated/relay/TurnOffEmailModal_toggleDailyEmailSwitch_Mutation.graphql";
import { TurnOffEmailModalQuery } from "src/common/types/generated/relay/TurnOffEmailModalQuery.graphql";

interface TurnOffEmailModalProps {
  onCancel: () => void;
  onOk: () => void;
  modalVisible: boolean;
}

const query = graphql`
  query TurnOffEmailModalQuery($projectId: uuid!) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          id
          is_siteupdate_daily_email_active
        }
      }
    }
  }
`;

const TurnOffEmailModal: React.FC<TurnOffEmailModalProps> = (props) => {
  const [form] = Form.useForm();
  const { projectId } = useParams();
  const data = useLazyLoadQuery<TurnOffEmailModalQuery>(query, {
    projectId: projectId!,
  }).project_connection.edges[0].node;

  const [toggleDailyEmailSwitch] =
    useAsyncMutation<TurnOffEmailModal_toggleDailyEmailSwitch_Mutation>(graphql`
      mutation TurnOffEmailModal_toggleDailyEmailSwitch_Mutation(
        $projectId: uuid!
        $value: Boolean!
      ) {
        update_project_by_pk(
          pk_columns: { id: $projectId }
          _set: { is_siteupdate_daily_email_active: $value }
        ) {
          id
          is_siteupdate_daily_email_active
        }
      }
    `);

  return (
    <>
      <Modal
        title={`SiteUpdate - Weekly Project Update Emails`}
        open={props.modalVisible}
        onCancel={props.onCancel}
        onOk={props.onOk}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: "public" }}
        >
          <Form.Item>
            <p className="flex flex-row items-center text-1 gap-0.5">
              <Switcher
                options={[
                  { label: "on", icon: IconCheck },
                  { label: "off", icon: IconX },
                ]}
                optionIndex={data.is_siteupdate_daily_email_active ? 0 : 1}
                onChange={(selectedOptionIndex) => {
                  const siteUpdateDailyEmailOn = selectedOptionIndex === 0;
                  toggleDailyEmailSwitch({
                    variables: {
                      projectId: projectId!,
                      value: siteUpdateDailyEmailOn,
                    },
                    optimisticResponse: {
                      update_project_by_pk: {
                        id: projectId,
                        is_siteupdate_daily_email_active:
                          siteUpdateDailyEmailOn,
                      },
                    },
                  }).then(
                    (res) => {
                      const value =
                        res.update_project_by_pk
                          ?.is_siteupdate_daily_email_active;
                      value ? message.success("On") : message.error("Off");
                    },
                    (error) => {
                      message.error(
                        `ERROR: ${
                          error instanceof Error
                            ? error.message
                            : JSON.stringify(error)
                        }`,
                      );
                    },
                  );
                }}
              />
              Turn the Project Update email on or off for your Team
            </p>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TurnOffEmailModal;
