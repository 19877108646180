import React from "react";

export interface QrProjectTableWrapperProps {
  children: React.ReactNode;
}

const QrProjectTableWrapper: React.FC<QrProjectTableWrapperProps> = (props) => {
  return (
    <div className="w-full flex flex-col min-h-[50vh] gap-1 rounded-1 bg-white px-1 py-2 h-auto">
      {props.children}
    </div>
  );
};

export default QrProjectTableWrapper;
