const getOrientationCompleted = (
  element:
    | {
        completed_at: string | null | undefined;
        in_person_orientated_at: string | null | undefined;
        orientated_at: string | null | undefined;
      }
    | null
    | undefined,

  hasSlides?: boolean,
  hasInPerson?: boolean
) => {
  if (!element) return null;
  let orientationCompleted: boolean | null = !!element.completed_at;
  if (hasSlides && hasInPerson) {
    orientationCompleted = !!(
      orientationCompleted ||
      (element.in_person_orientated_at && element.orientated_at)
    );
  } else if (hasSlides) {
    orientationCompleted = !!(orientationCompleted || element.orientated_at);
  } else if (hasInPerson) {
    orientationCompleted = !!(
      orientationCompleted || element.in_person_orientated_at
    );
  } else {
    orientationCompleted = null;
  }
  return orientationCompleted;
};
export default getOrientationCompleted;
