import { IconSettings } from "@tabler/icons";
import { Modal } from "antd";
import React from "react";
import Button from "src/common/components/general/Button";
import { useBoolean } from "usehooks-ts";
import GCProjectReportsDailySettings from "./GCProjectReportsDailySettings";

export interface GCProjectReoprtsDailySettingsButtonProps {
  projectId: string;
}

const GCProjectReoprtsDailySettingsButton: React.FC<
  GCProjectReoprtsDailySettingsButtonProps
> = (props) => {
  const {
    setFalse: hideModal,
    setTrue: showModal,
    value: modalVisible,
  } = useBoolean(false);
  return (<>
    <Button onClick={showModal} small icon={IconSettings} secondary />
    <Modal
      width={`66vw`}
      open={modalVisible}
      footer={null}
      onCancel={hideModal}
    >
      <GCProjectReportsDailySettings projectId={props.projectId} />
    </Modal>
  </>);
};

export default GCProjectReoprtsDailySettingsButton;
