import clsx from "clsx";
import { GetIncidentByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";
import getColorAndCompleted from "./getColorAndCompleted";

const incidentTypeAdded = (
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
  type: string,
) => {
  return (
    incident.incident_types.findIndex(
      (incidentType) => incidentType.type_value === type,
    ) !== -1
  );
};

const environmentalMenuLabel = (
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
) => {
  const reqFieldsComplete =
    !!incident.environmental_incident?.cause?.en &&
    !!incident.environmental_incident?.type &&
    !!incident.environmental_incident?.nature?.en &&
    !!incident.environmental_incident.completed_at;
  const isEnvAdded = incidentTypeAdded(incident, "environmental");
  const { color, extraLabel } = getColorAndCompleted(
    isEnvAdded,
    reqFieldsComplete,
  );
  return <div className={`text-${color}`}>Environmental{extraLabel}</div>;
};

const equipmentMenuLabel = (
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
) => {
  const reqFieldsComplete =
    !!incident.equipment_incident?.damage_detail?.en &&
    incident.equipment_incident.operator_violated_safety_rules === true &&
    !!incident.equipment_incident.rules_violated?.en &&
    !!incident.equipment_incident.completed_at;
  const isEnvAdded = incidentTypeAdded(incident, "equipment");
  const { color, extraLabel } = getColorAndCompleted(
    isEnvAdded,
    reqFieldsComplete,
  );
  return <div className={`text-${color}`}>Equipment{extraLabel}</div>;
};

const nearMissMenuLabel = (
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
) => {
  const reqFieldsComplete =
    !!incident.near_miss_incident?.severity &&
    incident.near_miss_incident?.severity?.length > 0 &&
    !!incident.near_miss_incident?.type &&
    incident.near_miss_incident?.type?.en.length > 0 &&
    !!incident.near_miss_incident.completed_at;
  const isEnvAdded = incidentTypeAdded(incident, "near_miss");
  const { color, extraLabel } = getColorAndCompleted(
    isEnvAdded,
    reqFieldsComplete,
  );
  return <div className={`text-${color}`}>Near Miss{extraLabel}</div>;
};

const propertyDamageMenuLabel = (
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
) => {
  const reqFieldsComplete =
    !!incident.property_damage_incident?.cause.en &&
    !!incident.property_damage_incident.completed_at;
  const isEnvAdded = incidentTypeAdded(incident, "property_damage");
  const { color, extraLabel } = getColorAndCompleted(
    isEnvAdded,
    reqFieldsComplete,
  );
  return <div className={`text-${color}`}>Property Damage{extraLabel}</div>;
};

const utilityMenuLabel = (
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
) => {
  const reqFieldsComplete =
    !!incident.utility_incident?.service_hit &&
    !!incident.utility_incident.completed_at;
  const isEnvAdded = incidentTypeAdded(incident, "utility");
  const { color, extraLabel } = getColorAndCompleted(
    isEnvAdded,
    reqFieldsComplete,
  );
  return <div className={`text-${color}`}>Utility Strike{extraLabel}</div>;
};

const vehicleMenuLabel = (
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
) => {
  const reqFieldsComplete =
    !!incident.vehicle_incident?.cause &&
    !!incident.vehicle_incident.completed_at;

  const isEnvAdded = incidentTypeAdded(incident, "vehicle");
  const { color, extraLabel } = getColorAndCompleted(
    isEnvAdded,
    reqFieldsComplete,
  );
  return <div className={`text-${color}`}>Vehicle{extraLabel}</div>;
};

const injuryMenuLabel = (
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
) => {
  const injuredUsersLen = incident.injured_users.length;
  const isInjuryAdded = incidentTypeAdded(incident, "injury_illness");

  if (isInjuryAdded && injuredUsersLen === 0) {
    return (
      <div className="text-semantic-negative">Injured Persons (Incomplete)</div>
    );
  } else {
    return <div>Injured Persons ({injuredUsersLen})</div>;
  }
};

const theftMenuLabel = (
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
) => {
  const reqFieldsComplete =
    !!incident.theft_incident?.stolen_items &&
    !!incident.theft_incident.completed_at;
  const isEnvAdded = incidentTypeAdded(incident, "theft");
  const { color, extraLabel } = getColorAndCompleted(
    isEnvAdded,
    reqFieldsComplete,
  );
  return <div className={`text-${color}`}>Theft / Vandalism{extraLabel}</div>;
};

const incidentDetailMenuLabel = (
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
) => {
  const reqFieldsComplete =
    !!incident.lighting_conditions &&
    !!incident.summary &&
    !!incident.description.en &&
    !!incident.incident_time &&
    !!incident.detail_completed_at &&
    ((incident.emergency_service_called && !!incident.emergency_service) ||
      !incident.emergency_service_called);

  if (reqFieldsComplete) {
    return <div>Incident Details</div>;
  } else {
    return (
      <div className="text-semantic-negative">
        Incident Details (Incomplete)
      </div>
    );
  }
};

const getMenuItemLabel = (
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
  type: string,
) => {
  switch (type) {
    case "table":
      return <div>All Incidents</div>;
    case "project":
      return <div>Project Details </div>;
    case "root_cause":
      return <div>Root Cause Analysis</div>;
    case "witness":
      return <div>Witness </div>;
    case "notes":
      return <div>Notes </div>;
    case "logs":
      return <div>History</div>;
    case "environmental":
      return environmentalMenuLabel(incident);
    case "equipment":
      return equipmentMenuLabel(incident);
    case "injury_illness":
      return injuryMenuLabel(incident);
    case "near_miss":
      return nearMissMenuLabel(incident);
    case "property_damage":
      return propertyDamageMenuLabel(incident);
    case "theft":
      return theftMenuLabel(incident);
    case "utility":
      return utilityMenuLabel(incident);
    case "vehicle":
      return vehicleMenuLabel(incident);
    case "incident_detail":
      return incidentDetailMenuLabel(incident);
    default:
      <></>;
  }
};

export default getMenuItemLabel;
