import { FC, useState } from "react";
import { Form, Input, Radio, Select, message, notification } from "antd";
import {
  useConvertEmployeeToSubadminMutation,
  useGetSubcontractorEmployeeTitlesQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import { ConnectionHandler, commitLocalUpdate } from "relay-runtime";
import FModal from "../dialogs/FModal";
import hasApolloErrorCode from "src/utility-features/error-handling/hasApolloErrorCode";
import { useRelayEnvironment } from "react-relay";

export type ConvertEmployeeToSubAdminDataType = Array<{
  name: string;
  uid: string;
  email?: string;
  created_password: boolean;
  dataId?: string;
  phoneNumber?: string;
}>;
const { Option } = Select;

const ConvertEmployeeToSubAdminModal: FC<{
  visible: boolean;
  //   refetch: () => void;
  onClose: () => void;
  data: ConvertEmployeeToSubAdminDataType;
  subs?: Array<{ name: string; id: string }>;
}> = ({ data, visible, onClose, subs }) => {
  const [form] = Form.useForm();
  //   const { projectId } = useParams();
  const [convertEmployeeToSubadmin, { loading }] =
    useConvertEmployeeToSubadminMutation();
  const { data: subTitles, loading: subTitleLoading } =
    useGetSubcontractorEmployeeTitlesQuery();
  const relayEnv = useRelayEnvironment();

  const [employeeEmailDisabled, setEmployeeEmailDisabled] = useState(true);
  const [employeePhoneDisabled, setEmployeePhoneDisabled] = useState(true);

  return (
    <FModal
      onCancel={onClose}
      title="Convert a Profile on your Team to a Subcontractor Administrator"
      width={950}
      okText="Convert"
      open={visible}
      confirmLoading={loading}
      onOk={async () => {
        const vals = await form.validateFields();
        // const email = data.find((user) => user.uid === vals.employee)?.email;
        try {
          if (vals.confirm) {
            const emailSent = await convertEmployeeToSubadmin({
              variables: {
                input: {
                  employee_id: vals.employee,
                  subcontractor_id: vals.subcontractor,
                  email: vals.employeeEmail,
                  subTitle: vals.subTitle,
                  phoneNumber: vals.employeePhone,
                },
              },
            }).catch((error) => {
              if (hasApolloErrorCode(error, "email-send-error")) {
                return false;
              } else {
                throw error;
              }
            });
            commitLocalUpdate(relayEnv, (store) => {
              const conn1 = ConnectionHandler.getConnection(
                store.getRoot(),
                "GCProjectTeam_project_employee_connection",
              );

              if (conn1) {
                data.forEach((p) => {
                  if (p.uid === vals.employee) {
                    ConnectionHandler.deleteNode(conn1, p.dataId!);
                  }
                });
              }
            });
            if (emailSent) {
              message.success("convert Employee To Subadmin Successfull");
            } else {
              message.success(
                "convert Employee To Subadmin Successfull, but email could not be sent",
              );
            }
            form.resetFields();
            onClose();
            // refetch();
          } else {
            message.error("Please accept to continue");
          }
        } catch (err) {
          // TODO: this is temporary... we should use global handler to report errors
          console.error(err);
          notification.error({
            message: "Couldn't convert employee to subadmin",
            description: `ERROR: ${
              err instanceof Error ? err.message : String(err)
            }`,
          });
        }
      }}
    >
      <Form form={form} layout="vertical">
        <div>
          Select the person to convert to an Administrator for their
          Subcontractor &nbsp;&nbsp;
        </div>
        <Form.Item
          name="employee"
          label={"Select Profile"}
          rules={[{ required: true, message: "Choose a Profile" }]}
        >
          <Select
            showSearch
            filterOption={(input, option) => {
              if (!option || !option.props.children[0]) return false;
              return (
                option.props.children[0]
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              );
            }}
            onChange={(value) => {
              const selectedEmployee = data.find((u) => u.uid === value);
              if (selectedEmployee) {
                form.setFieldsValue({
                  employeeEmail: selectedEmployee.email || "",
                  employeePhone: selectedEmployee.phoneNumber,
                });
                setEmployeeEmailDisabled(!!selectedEmployee.email);
                setEmployeePhoneDisabled(!!selectedEmployee.phoneNumber);
              }
            }}
          >
            {data.map((u) => {
              const list = [u.name, u.email].filter((s) => s);
              return (
                <Option value={u.uid} key={u.uid}>
                  {list.join(", ")}&nbsp;&nbsp;
                  <span className="font-accent text-interactive-primary">
                    {u.created_password ? " PASSWORD SET" : ""}
                  </span>
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="employeeEmail"
          label="Employee Email"
          rules={[
            {
              required: true,
              message:
                "Please enter the email to be assigned to selected employee",
            },
          ]}
        >
          <Input
            disabled={employeeEmailDisabled}
            // defaultValue={employeeEmail || enteredEmail}
            // value={employeeEmailDisabled ? employeeEmail : enteredEmail}
            onChange={(e) =>
              form.setFieldsValue({ employeeEmail: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item name="employeePhone" label="Phone Number">
          <Input
            disabled={employeePhoneDisabled}
            // defaultValue={workerEmail || enteredEmail}
            // value={employeePhone}
            onChange={(e) =>
              form.setFieldsValue({ employeePhone: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          name="subcontractor"
          label="Subcontractor"
          rules={[{ required: true, message: "Choose a subcontractor" }]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Select the Subcontractor they work for"
          >
            {subs
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((sub) => (
                <Select.Option key={sub.id} value={sub.id}>
                  {sub.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="subTitle"
          label="Title"
          rules={[{ required: true, message: "Choose a title" }]}
        >
          <Select style={{ width: "100%" }}>
            {subTitles?.subcontractor_employee_title.map((w) => (
              <Select.Option key={w.id} value={w.id}>
                {w.name.en}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div>
          By confirming, this profile will be converted to an Administrator for
          this Subcontractor and will have access to their Company’s profile,
          which includes access to all projects and information. They will also
          have access to the mobile app and be able to submit reports. Users may
          need to refresh their mobile app for changes to be updated.
          <br />
          <i>
            Typically this is only required when the Subcontractor’s safety
            director, project manager, etc. completed orientation and chose your
            organization instead of theirs. A foreman or crew leader typically
            does not need this access.
          </i>
        </div>
        <br />
        <br />
        <div>This cannot be undone</div>
        <Form.Item name="confirm">
          <Radio.Group>
            <Radio value={true}>I understand</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </FModal>
  );
};
export default ConvertEmployeeToSubAdminModal;
