const readAsArrayBuffer = (file: File) => new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend =  () => {
      if (reader.result instanceof ArrayBuffer)
        resolve(reader.result)
      else
        reject(new Error("result is not ArrayBuffer"));
    }
    reader.onerror = (ev) => {
      reject(ev);
    }
    reader.readAsArrayBuffer(file);
  });

export default readAsArrayBuffer;
  