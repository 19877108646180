import React, { forwardRef, useState } from "react";
import {
  GetObservationsQuery,
  GetObservationsQueryVariables,
  GetObservationsDocument,
  useDeleteObservationMutation,
  useInsertObservationMutation,
  GetObservationOptionsQuery,
  Observation_Bool_Exp,
} from "src/common/types/generated/apollo/graphQLTypes";
import DataApolloScrollTable, {
  DataApolloScrollTableImplementorProps,
  DataApolloScrollTableProps,
  DataApolloScrollTableRef,
} from "src/common/components/tables/basic/DataApolloScrollTable";
import { IconBriefcase, IconPlus, IconTrash } from "@tabler/icons";
import Icon from "src/common/components/general/Icon";
import { useNavigate } from "react-router-dom";
import useAuthUser from "src/common/hooks/useAuthUser";
import capitalize from "src/common/functions/capitalize";
import Tag, { TagProps } from "src/common/components/general/Tag";
import { Popover, Spin } from "antd";
import dayjs from "dayjs";
import CompleteObservationButton from "./basic/CompleteObservationButton";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
type ColumnKeys =
  | "date"
  | "sub_name"
  | "created_by"
  | "created_at"
  | "risk"
  | "sub_cate"
  | "status"
  | "category"
  | "sub_category"
  | "project_name"
  | "findings"
  | "extra";
type DataType = GetObservationsQuery["observation"][number];
type Props = DataApolloScrollTableImplementorProps<
  GetObservationsQueryVariables,
  ColumnKeys,
  DataType,
  GetObservationsQuery
> & {
  projectId?: string;

  searchString?: string;
  extraColumns?: DataApolloScrollTableProps<
    GetObservationsQueryVariables,
    ColumnKeys,
    DataType,
    GetObservationsQuery
  >["columns"];
  riskLevels?: { name: string; value: string }[];
  subs?: { name: string; id: string }[];
  showInReview?: boolean;
  headerComponent?: React.ReactElement;
  datePickerDataTitle?: string;
  subcontractorId?: string;
  datePickerDataIndex?: string[];
  options?: GetObservationOptionsQuery;

  defaultSortColumnKey?: ColumnKeys;
};

const getIsPendingVerification = (obs: DataType) =>
  obs.status === "closed" &&
  !!obs.risk_level_value &&
  obs.risk_level_value !== "safe" &&
  !obs.complete_marked_at;

const getStatusSortOrder = (obs: DataType, showInReview: boolean) => {
  switch (obs.status) {
    case "draft":
      return 5;
    case "open":
      return 4;
    case "closed":
      const pendingRequiredReview =
        showInReview && getIsPendingVerification(obs);
      return obs.risk_level_value === "safe"
        ? 1
        : pendingRequiredReview
        ? 3
        : 2;
    default:
      return 0;
  }
};

const ObservationTable = forwardRef<DataApolloScrollTableRef, Props>(
  (
    {
      title = "Workers",
      onRowClick,
      searchString,
      showInReview = false,
      subcontractorId,
      datePickerDataTitle,
      datePickerDataIndex,
      projectId,
      headerComponent,
      extraColumns,
      where,
      defaultSortColumnKey,
      ...props
    },
    ref,
  ) => {
    const { userData } = useUserData();
    const [customFilter, setCustomFilter] = useState<Observation_Bool_Exp>({});
    const [insertObservation, { loading: inserting }] =
      useInsertObservationMutation();
    const [deleteObservation, { loading: deleting }] =
      useDeleteObservationMutation();
    const navigate = useNavigate();
    const authUser = useAuthUser();
    return (
      <DataApolloScrollTable<
        GetObservationsQueryVariables,
        ColumnKeys,
        DataType,
        GetObservationsQuery
      >
        {...props}
        newCustomTableLook
        extraQueryVariables={{
          includeProj: !projectId,
          includeSub: !subcontractorId,
        }}
        title="Observations"
        loadAll
        filterNotVisibleByDefault
        onRowClick={(item) => {
          onRowClick && onRowClick(item);
        }}
        extraSearchDataIndexes={[
          [{ index: "status" }],
          [{ index: "risk_level_value" }],
        ]}
        ref={ref}
        where={{ _and: [where, customFilter] }}
        defaultCustomInitialSort
        queryNode={GetObservationsDocument}
        aggregateCountIndex="observation_aggregate"
        queryDataIndex="observation"
        topBarButtons={[
          {
            onClick: async () => {
              const { data } = await insertObservation({
                variables: {
                  object: {
                    project_id: projectId,
                    created_by_uid: authUser.uid,
                    subcontractor_id: subcontractorId,
                    unsafe_observation: {
                      data: { action: { data: { original: "" } } },
                    },
                  },
                },
              });
              if (data?.insert_observation_one?.id)
                navigate(`${data.insert_observation_one.id}`);
              else {
                throw new Error(
                  "InsertObservation Failed: Inserted data not found",
                );
              }
            },
            loading: inserting,
            label: "Create",
            icon: IconPlus,
          },
          ...(props.topBarButtons || []),
        ]}
        customFilters={
          showInReview
            ? [
                {
                  title: "Review Status",
                  removeFilter: () => setCustomFilter({}),
                  type: "radio",
                  options: [
                    {
                      option: "Reviewed",
                      onClick: () => {
                        setCustomFilter({
                          complete_marked_at: { _is_null: false },
                          status: { _eq: "closed" },
                          risk_level_value: { _neq: "safe" },
                        });
                      },
                    },
                    {
                      option: "Pending Review",
                      onClick: () => {
                        setCustomFilter({
                          complete_marked_at: { _is_null: true },
                          status: { _eq: "closed" },
                          risk_level_value: { _neq: "safe" },
                        });
                      },
                    },
                  ],
                },
              ]
            : undefined
        }
        columns={[
          {
            title: "Status",
            key: "status",
            dataIndex: ["status"],
            sortable: true,
            size: "ml",
            defaultSortOrder: "asc",
            filters: {
              dataIndex: ["status"],
              options: [
                { text: "Draft", value: "pending" },
                { text: "Open", value: "open" },
                { text: "Closed", value: "closed" },
              ],
            },
            clientCompareFn: (a1, a2) => {
              let res =
                getStatusSortOrder(a2, showInReview) -
                getStatusSortOrder(a1, showInReview);
              if (res === 0)
                res =
                  dayjs(a2.created_at).valueOf() -
                  dayjs(a1.created_at).valueOf();
              return res;
            },
            render: (v, rec) => {
              const pendingRequiredReview =
                showInReview && getIsPendingVerification(rec);
              let color: TagProps["status"] | "" = "",
                text = "";
              if (v === "draft") {
                color = "inactive";
                text = "Draft";
              } else if (v === "open") {
                color = "negative";
                text = "Open";
              } else if (rec.risk_level_value === "safe") {
                color = "positive";
                text = "Safe";
              } else if (pendingRequiredReview) {
                color = "incomplete";
                text = "Pending Verification";
              } else if (rec.status === "closed") {
                color = "corrected";
                text = "Closed";
              }
              return color ? <Tag status={color}>{text}</Tag> : "";
            },
          },
          {
            title: "Category",
            key: "category",
            dataIndex: ["observation_category", "type", "name", "en"],
            searchDataIndex: ["observation_category", "type", "name", "en"],
            render: (v, row) =>
              row.observation_category ? (
                <Popover
                  placement="bottomLeft"
                  content={
                    <div className="max-w-32">
                      {row.observation_category?.name?.en}
                    </div>
                  }
                >
                  {v}
                </Popover>
              ) : (
                ""
              ),
          },
          {
            title: "Risk Level",
            key: "risk",
            sortable: true,
            dataIndex: ["risk_level", "name"],
            filters: props.riskLevels
              ? {
                  dataIndex: ["risk_level_value"],
                  options: props.riskLevels.map((r) => ({
                    text: r.name,
                    value: r.value,
                  })),
                }
              : undefined,
            render: (v, row) =>
              row.risk_level ? (
                <div style={{ color: row.risk_level.color_hex }}>
                  {row.risk_level.name}
                </div>
              ) : (
                <></>
              ),
          },
          {
            title: "Company",
            key: "sub_name",
            sortable: true,
            dataIndex: ["subcontractor", "name"],
            searchDataIndex: ["subcontractor", "name"],
            filters: props.subs
              ? {
                  dataIndex: ["subcontractor_id"],
                  options: props.subs.map((r) => ({
                    text: r.name,
                    value: r.id,
                  })),
                }
              : undefined,
            render: (v) =>
              v ? (
                <div className="flex font-accent">
                  <Icon icon={IconBriefcase} />
                  {v}
                </div>
              ) : (
                ""
              ),
          },

          {
            title: "Created on",
            key: "created_at",
            sortable: true,
            defaultSortOrder: "desc",
            dataIndex: ["created_at"],
            contentType: {
              type: "date",
              renderOptions: () => ({ format: "full" }),
            },
          },
          {
            title: "Observation Date",
            key: "date",
            sortable: true,
            dataIndex: ["observation_date"],
            contentType: {
              type: "date",
              renderOptions: () => ({ format: "full" }),
            },
          },
          {
            title: "Findings",
            key: "findings",
            sortable: true,
            defaultSortOrder: "asc",
            size: "sm",
            dataIndex: ["number_of_findings"],
          },

          {
            title: "Created By",
            key: "created_by",
            dataIndex: ["created_by_user", "name"],
            searchDataIndex: ["created_by_user", "name"],
          },
          {
            key: "extra",
            title: "Action",
            size: "sm",
            dataIndex: ["id"],
            render: (id, r) => {
              if (deleting) return <Spin />;
              if (r.status !== "draft") {
                const pendingRequiredReview =
                  showInReview && getIsPendingVerification(r);
                if (
                  props.options &&
                  projectId &&
                  (r.status === "open" ||
                    (pendingRequiredReview && userData.employee))
                )
                  return (
                    <div onClick={(e) => e.stopPropagation()}>
                      <CompleteObservationButton
                        {...{
                          observationId: id,
                          projectId,
                          obs: r,
                          pendingRequiredReview,
                          iconButton: true,
                          optionsData: props.options,
                          todoId: r.unsafe_observation?.todo_id,
                          onCompleted: () => {
                            if (ref && typeof ref !== "function") {
                              ref.current?.refetch();
                            }
                          },
                        }}
                      />
                    </div>
                  );
                return null;
              }
              return (
                <div onClick={(e) => e.stopPropagation()}>
                  <Icon
                    icon={IconTrash}
                    color="negative"
                    onClick={async () => {
                      await deleteObservation({ variables: { id } });
                      if (ref && typeof ref !== "function") {
                        ref.current?.refetch();
                      }
                    }}
                  />
                </div>
              );
            },
          },
        ]}
      />
    );
  },
);
export default ObservationTable;
