import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { GCDashAppReportSettingsOnDateInsertMutation } from "src/common/types/generated/relay/GCDashAppReportSettingsOnDateInsertMutation.graphql";
import { graphql } from "babel-plugin-relay/macro";
import { SwitcherProps } from "src/common/components/general/Switcher";
import {
  IconArrowBigRightLines,
  IconCheck,
  IconInfoSquare,
  IconUserMinus,
  IconUserPlus,
  IconX,
} from "@tabler/icons";
import ShowToggleTable, {
  ExtendedRow,
} from "src/common/components/tables/ShowToggleTable/ShowToggleTable";
import { useGetCompletionAuditV3Query } from "src/common/types/generated/apollo/graphQLTypes";
import LoadingContent from "src/common/components/general/LoadingContent";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const insertMutation = graphql`
  mutation GCDashAppReportSettingsOnDateInsertMutation(
    $ProjSubOnsiteUpdateObjects: [project_subcontractor_onsite_update_insert_input!]!
    $ProjSubReportSettingsObjects: [project_subcontractor_report_settings_insert_input!]!
    $ProjSubCrewOnsiteUpdateObjects: [project_subcontractor_crew_onsite_update_insert_input!]!
    $ProjSubCrewReportSettingsObjects: [project_subcontractor_crew_report_settings_insert_input!]!
  ) {
    insert_project_subcontractor_onsite_update(
      objects: $ProjSubOnsiteUpdateObjects
      on_conflict: {
        constraint: project_subcontractor_onsite_update_project_id_subcontractor_id
        update_columns: [type]
      }
    ) {
      affected_rows
    }
    insert_project_subcontractor_crew_onsite_update(
      objects: $ProjSubCrewOnsiteUpdateObjects
      on_conflict: {
        constraint: project_subcontractor_crew_onsite_update_project_crew_id_onsite
        update_columns: [type]
      }
    ) {
      affected_rows
    }
    insert_project_subcontractor_report_settings(
      objects: $ProjSubReportSettingsObjects
      on_conflict: {
        constraint: project_subcontractor_report_settings_project_id_subcontractor_
        update_columns: [
          safety_reports_required
          toolbox_talks_required
          daily_reports_required
        ]
      }
    ) {
      affected_rows
    }
    insert_project_subcontractor_crew_report_settings(
      objects: $ProjSubCrewReportSettingsObjects
      on_conflict: {
        constraint: project_subcontractor_crew_report_settings_project_crew_id_chan
        update_columns: [
          safety_reports_required
          toolbox_talks_required
          daily_reports_required
        ]
      }
    ) {
      affected_rows
    }
  }
`;

{
}

interface GCDashAppReportSettingsOnDateProps {
  projectId: string;
  date: dayjs.Dayjs;
  projectTimezone: string;
  crewLeads: { [id: string]: string };
}

const GCDashAppReportSettingsOnDate: React.FunctionComponent<
  GCDashAppReportSettingsOnDateProps
> = ({ projectId, date, projectTimezone, crewLeads }) => {
  console.log(projectTimezone);

  const { data, loading, refetch } = useGetCompletionAuditV3Query({
    variables: {
      input: {
        startDate: date.clone().tz(projectTimezone).startOf("d").toISOString(),
        endDate: date.clone().tz(projectTimezone).endOf("d").toISOString(),
        projectId,
        timezone: projectTimezone,
      },
    },
  });

  const [onsiteExceptions, setOnsiteExceptions] = useState<{
    [key: string]: boolean;
  }>({});
  const [ptpExceptions, setPtpExceptions] = useState<{
    [key: string]: boolean;
  }>({});
  const [drExceptions, setDrExceptions] = useState<{
    [key: string]: boolean;
  }>({});
  const [tbtExceptions, setTbtExceptions] = useState<{
    [key: string]: boolean;
  }>({});
  useEffect(() => {
    refetch({
      input: {
        startDate: date.clone().tz(projectTimezone).startOf("d").toISOString(),
        endDate: date.clone().tz(projectTimezone).endOf("d").toISOString(),
        timezone: projectTimezone,
        projectId,
      },
    });
    setOnsiteExceptions({});
    setDrExceptions({});
    setPtpExceptions({});
    setTbtExceptions({});
  }, [date]);

  console.log(
    {
      date,
      startDate: date.clone().tz(projectTimezone).startOf("d").toISOString(),
      endDate: date.clone().tz(projectTimezone).endOf("d").toISOString(),
      projectId,
      timezone: projectTimezone,
    },
    data,
  );
  const [upsert] =
    useAsyncMutation<GCDashAppReportSettingsOnDateInsertMutation>(
      insertMutation,
    );
  const [loadingCrew, setLoadingCrew] = useState<string[]>([]);
  const [loadingSub, setLoadingSub] = useState<string[]>([]);
  // const [subData, setSubData] = useState(subOnsiteData);

  const navigate = useNavigate();
  const setProjectCrewSetting = async (
    crewId: string,
    set: {
      daily_reports_required: boolean;
      safety_reports_required: boolean;
      toolbox_talks_required: boolean;
    },
    loadingItem: "ptp" | "dr" | "tbt" | "all",
  ) => {
    const allSetting = loadingItem === "all";
    setLoadingCrew((prev) =>
      allSetting
        ? [...prev, crewId + "_ptp", crewId + "_dr", crewId + "_tbt"]
        : [...prev, crewId + "_" + loadingItem],
    );
    try {
      await upsert({
        variables: {
          ProjSubCrewOnsiteUpdateObjects: [],
          ProjSubCrewReportSettingsObjects: [
            {
              ...set,
              project_crew_id: crewId,
              change_date: dayjs(date).format("YYYY-MM-DD"),
            },
          ],
          ProjSubOnsiteUpdateObjects: [],
          ProjSubReportSettingsObjects: [],
        },
      });
      if (allSetting) {
        setPtpExceptions((prev) => ({
          ...prev,
          [crewId + "_crew"]: set.safety_reports_required,
        }));
        setDrExceptions((prev) => ({
          ...prev,
          [crewId + "_crew"]: set.daily_reports_required,
        }));
        setTbtExceptions((prev) => ({
          ...prev,
          [crewId + "_crew"]: set.toolbox_talks_required,
        }));
      } else if (loadingItem === "ptp") {
        setPtpExceptions((prev) => ({
          ...prev,
          [crewId + "_crew"]: set.safety_reports_required,
        }));
      } else if (loadingItem === "dr") {
        setDrExceptions((prev) => ({
          ...prev,
          [crewId + "_crew"]: set.daily_reports_required,
        }));
      } else if (loadingItem === "tbt") {
        setTbtExceptions((prev) => ({
          ...prev,
          [crewId + "_crew"]: set.toolbox_talks_required,
        }));
      }
    } catch (err) {
      console.error(err);
      message.error("Couldn't update setting");
      if (allSetting) {
        setPtpExceptions((prev) => ({
          ...prev,
          [crewId + "_crew"]: !set.safety_reports_required,
        }));
        setDrExceptions((prev) => ({
          ...prev,
          [crewId + "_crew"]: !set.daily_reports_required,
        }));
        setTbtExceptions((prev) => ({
          ...prev,
          [crewId + "_crew"]: !set.toolbox_talks_required,
        }));
      } else if (loadingItem === "ptp") {
        setPtpExceptions((prev) => ({
          ...prev,
          [crewId + "_crew"]: !set.safety_reports_required,
        }));
      } else if (loadingItem === "dr") {
        setDrExceptions((prev) => ({
          ...prev,
          [crewId + "_crew"]: !set.daily_reports_required,
        }));
      } else if (loadingItem === "tbt") {
        setTbtExceptions((prev) => ({
          ...prev,
          [crewId + "_crew"]: !set.toolbox_talks_required,
        }));
      }
    }
    setLoadingCrew((prev) => [
      ...prev.filter((p) =>
        allSetting
          ? p !== crewId + "_dr" &&
            p !== crewId + "_ptp" &&
            p !== crewId + "_tbt"
          : p !== crewId + "_" + loadingItem,
      ),
    ]);
  };
  const setProjectSubSetting = async (
    subId: string,
    set: {
      daily_reports_required: boolean;
      safety_reports_required: boolean;
      toolbox_talks_required: boolean;
    },
    loadingItem: "ptp" | "dr" | "tbt" | "all",
  ) => {
    const allSetting = loadingItem === "all";
    console.log(set);
    setLoadingSub((prev) =>
      allSetting
        ? [...prev, subId + "_ptp", subId + "_dr", subId + "_tbt"]
        : [...prev, subId + "_" + loadingItem],
    );
    try {
      await upsert({
        variables: {
          ProjSubCrewOnsiteUpdateObjects: [],
          ProjSubCrewReportSettingsObjects: [],
          ProjSubOnsiteUpdateObjects: [],
          ProjSubReportSettingsObjects: [
            {
              ...set,
              project_id: projectId,
              subcontractor_id: subId,
              change_date: dayjs(date).format("YYYY-MM-DD"),
            },
          ],
        },
      });
      if (allSetting) {
        setPtpExceptions((prev) => ({
          ...prev,
          [subId + "_sub"]: set.safety_reports_required,
        }));
        setDrExceptions((prev) => ({
          ...prev,
          [subId + "_sub"]: set.daily_reports_required,
        }));
        setTbtExceptions((prev) => ({
          ...prev,
          [subId + "_sub"]: set.toolbox_talks_required,
        }));
      } else if (loadingItem === "ptp") {
        setPtpExceptions((prev) => ({
          ...prev,
          [subId + "_sub"]: set.safety_reports_required,
        }));
      } else if (loadingItem === "dr") {
        setDrExceptions((prev) => ({
          ...prev,
          [subId + "_sub"]: set.daily_reports_required,
        }));
      } else if (loadingItem === "tbt") {
        setTbtExceptions((prev) => ({
          ...prev,
          [subId + "_sub"]: set.toolbox_talks_required,
        }));
      }
    } catch (err) {
      console.error(err);
      message.error("Couldn't update setting");
      if (allSetting) {
        setPtpExceptions((prev) => ({
          ...prev,
          [subId + "_sub"]: !set.safety_reports_required,
        }));
        setDrExceptions((prev) => ({
          ...prev,
          [subId + "_sub"]: !set.daily_reports_required,
        }));
        setTbtExceptions((prev) => ({
          ...prev,
          [subId + "_sub"]: !set.toolbox_talks_required,
        }));
      } else if (loadingItem === "ptp") {
        setPtpExceptions((prev) => ({
          ...prev,
          [subId + "_sub"]: !set.safety_reports_required,
        }));
      } else if (loadingItem === "dr") {
        setDrExceptions((prev) => ({
          ...prev,
          [subId + "_sub"]: !set.daily_reports_required,
        }));
      } else if (loadingItem === "tbt") {
        setTbtExceptions((prev) => ({
          ...prev,
          [subId + "_sub"]: !set.toolbox_talks_required,
        }));
      }
    }
    setLoadingSub((prev) => [
      ...prev.filter((p) =>
        allSetting
          ? p !== subId + "_dr" && p !== subId + "_ptp" && p !== subId + "_tbt"
          : p !== subId + "_" + loadingItem,
      ),
    ]);
  };
  const setCrewOnOffSite = async (crewId: string, type: boolean) => {
    setLoadingCrew((prev) => [...prev, crewId]);
    try {
      await upsert({
        variables: {
          ProjSubCrewReportSettingsObjects: [],
          ProjSubReportSettingsObjects: [],
          ProjSubCrewOnsiteUpdateObjects: [
            {
              project_crew_id: crewId,
              type: type ? "onsite" : "offsite",
              onsite_date: dayjs(date).format("YYYY-MM-DD"),
            },
          ],
          ProjSubOnsiteUpdateObjects: [],
        },
      });
      setOnsiteExceptions((prev) => ({
        ...prev,
        [crewId + "_crew"]: type,
      }));
    } catch (err) {
      console.error(err);
      message.error("Couldn't update setting");
      setOnsiteExceptions((prev) => ({
        ...prev,
        [crewId + "_crew"]: !type,
      }));
    }
    setLoadingCrew((prev) => [...prev.filter((p) => p !== crewId)]);
  };
  const setSubOnOffSite = async (subId: string, type: boolean) => {
    setLoadingSub((prev) => [...prev, subId]);
    try {
      await upsert({
        variables: {
          ProjSubCrewReportSettingsObjects: [],
          ProjSubReportSettingsObjects: [],
          ProjSubCrewOnsiteUpdateObjects: [],
          ProjSubOnsiteUpdateObjects: [
            {
              project_id: projectId,
              subcontractor_id: subId,
              type: type ? "onsite" : "offsite",
              onsite_date: dayjs(date).format("YYYY-MM-DD"),
            },
          ],
        },
      });
      setOnsiteExceptions((prev) => ({
        ...prev,
        [subId + "_sub"]: type,
      }));
    } catch (err) {
      console.error(err);
      message.error("Couldn't update setting");

      setOnsiteExceptions((prev) => ({
        ...prev,
        [subId + "_sub"]: !type,
      }));
    }
    setLoadingSub((prev) => [...prev.filter((p) => p !== subId)]);
  };
  if (loading) return <LoadingContent />;
  if (!data) {
    return <div>Data Not Found for this Date</div>;
  }
  const returnSettings = (
    item: {
      id: string;
      completion: Array<{
        safety_reports_required: boolean;
        toolbox_talks_required: boolean;
        daily_reports_required: boolean;
      }>;
    },
    type: "sub" | "crew",
  ) => {
    return {
      safety_reports_required:
        typeof ptpExceptions[item.id + "_" + type] === "boolean"
          ? ptpExceptions[item.id + "_" + type]
          : item.completion[0].safety_reports_required,
      daily_reports_required:
        typeof drExceptions[item.id + "_" + type] === "boolean"
          ? drExceptions[item.id + "_" + type]
          : item.completion[0].daily_reports_required,
      toolbox_talks_required:
        typeof tbtExceptions[item.id + "_" + type] === "boolean"
          ? tbtExceptions[item.id + "_" + type]
          : item.completion[0].toolbox_talks_required,
    };
  };
  const rows: ExtendedRow[] = [];
  [...(data.getCompletionAuditV3 || [])]
    .sort((sub1, sub2) => sub1.name.localeCompare(sub2.name))
    .map((sub) => {
      const currentCrews = sub.crewData;
      const subSettings = returnSettings(sub, "sub");
      const subOnsiteExcep = onsiteExceptions[sub.id + "_sub"];
      const showCrewData = currentCrews.length > 1;
      const subOnsiteRequired =
        typeof subOnsiteExcep === "boolean"
          ? subOnsiteExcep
          : sub.completion[0]?.onsite_required;
      rows.push({
        id: sub.id,
        title:
          sub.name + (showCrewData ? ` (${currentCrews.length} Crews) ` : ""),
        button: showCrewData
          ? {
              icon: IconArrowBigRightLines,
              label: "Manage Crew(s)",
              secondary: true,
              onClick: () =>
                navigate(
                  `/gce/projects/${projectId}/subcontractors/${sub.id}/workers-and-crews`,
                ),
            }
          : undefined,
        haveChilds: showCrewData,
        parentItem: true,
        switchers: [
          {
            onChange: async (newOptionIndex) => {
              setSubOnOffSite(sub.id, newOptionIndex === 1);
              if (newOptionIndex === 0) {
                currentCrews.forEach((crew) => {
                  const onsiteExcep = onsiteExceptions[crew.id + "_crew"];
                  if (
                    typeof onsiteExcep === "boolean"
                      ? onsiteExcep
                      : crew.completion[0]?.onsite_required
                  ) {
                    setCrewOnOffSite(crew.id, false);
                  }
                });
              }
            },
            loading: !!loadingSub.find((s) => s === sub.id),
            optionIndex: subOnsiteRequired ? 1 : 0,
            options: [
              { icon: IconUserMinus, label: "Off" },
              { icon: IconUserPlus, label: "Onsite" },
            ],
            hint: (
              <div className="w-24">
                Setting to “Onsite” will mark a Sub or Crew as working on the
                project indefinitely until they marked “Offsite”. This triggers
                your scheduled push notifications in the mobile app and emails.
                Onsite status is only Monday through Friday. Marking a
                subcontractor as Onsite on the weekends is done under
                “Dashboard” in the mobile app.
                <br />
                Reports are only required when the Sub or Crew is marked Onsite
              </div>
            ),
          },
          {
            onChange: async (newOptionIndex) => {
              setProjectSubSetting(
                sub.id,
                {
                  ...subSettings,
                  safety_reports_required: newOptionIndex === 1,
                },
                "ptp",
              );
              if (newOptionIndex === 0) {
                currentCrews.forEach((crew) => {
                  const crewSettings = returnSettings(crew, "crew");
                  if (crewSettings.safety_reports_required) {
                    setProjectCrewSetting(
                      crew.id,
                      {
                        ...crewSettings,
                        safety_reports_required: false,
                      },
                      "ptp",
                    );
                  }
                });
              }
            },
            loading: !!loadingSub.find((s) => s === sub.id + "_ptp"),

            optionIndex: subSettings.safety_reports_required ? 1 : 0,
            options: [
              { icon: IconX, label: "No" },
              { icon: IconCheck, label: "Yes" },
            ],
          },
          {
            onChange: async (newOptionIndex) => {
              setProjectSubSetting(
                sub.id,
                {
                  ...subSettings,
                  daily_reports_required: newOptionIndex === 1,
                },
                "dr",
              );
              if (newOptionIndex === 0) {
                currentCrews.forEach((crew) => {
                  const crewSettings = returnSettings(crew, "crew");
                  if (crewSettings.daily_reports_required) {
                    setProjectCrewSetting(
                      crew.id,
                      {
                        ...crewSettings,
                        daily_reports_required: false,
                      },
                      "dr",
                    );
                  }
                });
              }
            },
            loading: !!loadingSub.find((s) => s === sub.id + "_dr"),

            optionIndex: subSettings.daily_reports_required ? 1 : 0,
            options: [
              { icon: IconX, label: "No" },
              { icon: IconCheck, label: "Yes" },
            ],
          },
          {
            onChange: async (newOptionIndex) => {
              setProjectSubSetting(
                sub.id,
                {
                  ...subSettings,
                  toolbox_talks_required: newOptionIndex === 1,
                },
                "tbt",
              );
              if (newOptionIndex === 0) {
                currentCrews.forEach((crew) => {
                  const crewSettings = returnSettings(crew, "crew");
                  if (crewSettings.toolbox_talks_required) {
                    setProjectCrewSetting(
                      crew.id,
                      {
                        ...crewSettings,
                        toolbox_talks_required: false,
                      },
                      "tbt",
                    );
                  }
                });
              }
            },
            loading: !!loadingSub.find((s) => s === sub.id + "_tbt"),
            optionIndex: subSettings.toolbox_talks_required ? 1 : 0,
            options: [
              { icon: IconX, label: "No" },
              { icon: IconCheck, label: "Yes" },
            ],
          },
        ] as SwitcherProps[],
      });
      if (showCrewData) {
        [...currentCrews]
          .sort((c1, c2) => c1.name.localeCompare(c2.name))
          .forEach((crew) => {
            const onsiteExcep = onsiteExceptions[crew.id + "_crew"];
            const crewSettings = returnSettings(crew, "crew");
            rows.push({
              id: crew.id,
              title:
                crew.name +
                (crewLeads[crew.id] ? `, ${crewLeads[crew.id]}` : ""),

              parentId: sub.id,

              switchers: [
                {
                  onChange: async (newOptionIndex) => {
                    setCrewOnOffSite(crew.id, newOptionIndex === 1);
                    if (newOptionIndex) {
                      setProjectCrewSetting(
                        crew.id,
                        {
                          safety_reports_required: true,
                          toolbox_talks_required: true,
                          daily_reports_required: true,
                        },
                        "all",
                      );
                    }
                    if (newOptionIndex && !subOnsiteRequired) {
                      setSubOnOffSite(sub.id, true);
                      setProjectSubSetting(
                        sub.id,
                        {
                          safety_reports_required: true,
                          toolbox_talks_required: true,
                          daily_reports_required: true,
                        },
                        "all",
                      );
                    }
                  },
                  loading: !!loadingCrew.find((s) => s === crew.id),
                  optionIndex: (
                    typeof onsiteExcep === "boolean"
                      ? onsiteExcep
                      : crew.completion[0]?.onsite_required
                  )
                    ? 1
                    : 0,
                  options: [
                    { icon: IconUserMinus, label: "Off" },
                    { icon: IconUserPlus, label: "Onsite" },
                  ],
                  hint: (
                    <div className="w-24">
                      Setting to “Onsite” will mark a Sub or Crew as working on
                      the project indefinitely until they marked “Offsite”. This
                      triggers your scheduled push notifications in the mobile
                      app and emails. Onsite status is only Monday through
                      Friday. Marking a subcontractor as Onsite on the weekends
                      is done under “Dashboard” in the mobile app.
                      <br />
                      Reports are only required when the Sub or Crew is marked
                      Onsite
                    </div>
                  ),
                },
                {
                  onChange: async (newOptionIndex) => {
                    setProjectCrewSetting(
                      crew.id,
                      {
                        ...crewSettings,
                        safety_reports_required: newOptionIndex === 1,
                      },
                      "ptp",
                    );
                    if (
                      newOptionIndex &&
                      !subSettings.safety_reports_required
                    ) {
                      setProjectSubSetting(
                        sub.id,
                        {
                          ...subSettings,
                          safety_reports_required: true,
                        },
                        "ptp",
                      );
                    }
                  },
                  loading: !!loadingCrew.find((s) => s === crew.id + "_ptp"),
                  optionIndex: crewSettings.safety_reports_required ? 1 : 0,
                  options: [
                    { icon: IconX, label: "No" },
                    { icon: IconCheck, label: "Yes" },
                  ],
                },
                {
                  onChange: async (newOptionIndex) => {
                    setProjectCrewSetting(
                      crew.id,
                      {
                        ...crewSettings,
                        daily_reports_required: newOptionIndex === 1,
                      },
                      "dr",
                    );
                    if (newOptionIndex && !subSettings.daily_reports_required) {
                      setProjectSubSetting(
                        sub.id,
                        {
                          ...subSettings,
                          daily_reports_required: true,
                        },
                        "dr",
                      );
                    }
                  },
                  loading: !!loadingCrew.find((s) => s === crew.id + "_dr"),
                  optionIndex: crewSettings.daily_reports_required ? 1 : 0,
                  options: [
                    { icon: IconX, label: "No" },
                    { icon: IconCheck, label: "Yes" },
                  ],
                },
                {
                  onChange: async (newOptionIndex) => {
                    setProjectCrewSetting(
                      crew.id,
                      {
                        ...crewSettings,
                        toolbox_talks_required: newOptionIndex === 1,
                      },
                      "tbt",
                    );
                    if (newOptionIndex && !subSettings.toolbox_talks_required) {
                      setProjectSubSetting(
                        sub.id,
                        {
                          ...subSettings,
                          toolbox_talks_required: true,
                        },
                        "tbt",
                      );
                    }
                  },
                  loading: !!loadingCrew.find((s) => s === crew.id + "_tbt"),
                  optionIndex: crewSettings.toolbox_talks_required ? 1 : 0,
                  options: [
                    { icon: IconX, label: "No" },
                    { icon: IconCheck, label: "Yes" },
                  ],
                },
              ] as SwitcherProps[],
            });
          });
      }
    });
  return (
    <div className="flex flex-col w-full">
      <ShowToggleTable
        {...{
          columns: [],
          mainColumn: {
            rows: rows,
            switchTitles: [
              {
                title: "Onsite Mngt",
                icon: {
                  icon: IconInfoSquare,
                  hoverContent: {
                    content: (
                      <div className="w-24">
                        Setting to “Onsite” will mark a Sub or Crew as working
                        on the project indefinitely until they marked “Offsite”.
                        This triggers your scheduled push notifications in the
                        mobile app and emails. Onsite status is only Monday
                        through Friday. Marking a subcontractor as Onsite on the
                        weekends is done under “Dashboard” in the mobile app.
                        <br />
                        Reports are only required when the Sub or Crew is marked
                        Onsite
                      </div>
                    ),
                  },
                },
              },
              { title: "Pre-Task Plan" },
              { title: " Daily Report" },
              { title: "Toolbox Talk" },
            ],
            title: "Subcontractors Onsite by Date",
            id: "sub",
          },
        }}
      />
    </div>
  );
};

export default GCDashAppReportSettingsOnDate;
