import { graphql } from "babel-plugin-relay/macro";

const insertTasksMutation = graphql`
  mutation InsertTasksMutation($objects: [task_insert_input!]!) {
    insert_task(objects: $objects) {
      affected_rows
      returning {
        id
        pk: id @__clientField(handle: "pk")
        subcontractor_id
        subcontractor {
          name
        }
        task_creation_request {
          subcontractor_id
          subcontractor {
            name
          }
        }
        show_type

        task_edit(order_by: { edited_at: desc }) {
          task_patch
          edited_at
        }
        task_signatures(order_by: { created_at: desc }) {
          created_at
          signature_image_id
        }
      }
    }
  }
`;
export default insertTasksMutation;
