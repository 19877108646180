import { Select } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import {
  GetObservationSettingsQuery,
  useInsertUserGroupMemberMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import getNormalOptionsFilter from "src/common/functions/getNormalOptionsFilter";

const ShowGCGroup: React.FC<{
  group: GetObservationSettingsQuery["user_group"][number];
  allUsers: GetObservationSettingsQuery["general_contractor_employee"];
}> = ({ group, allUsers }) => {
  const [selectedUsers, setSelectedUsers] = useState(
    [...group.active, ...group.pendingAccount].map(
      (u) => u.general_contractor_employee_id!,
    ),
  );
  const [updateMembers] = useInsertUserGroupMemberMutation();
  const onMembersUpdate = () => {
    const [toInsert, toDelete] = compareTwoLists(
      selectedUsers,
      [...group.active, ...group.pendingAccount].map(
        (u) => u.general_contractor_employee_id!,
      ),
    );
    if (!toInsert.length && !toDelete.length)
      updateMembers({
        variables: {
          projectWorkerObjects: [],
          gcEmployeeObjects: [
            ...toInsert.map((u) => ({
              general_contractor_employee_id: u,
              user_group_id: group.id,
              deleted_at: null,
            })),
            ...toDelete.map((u) => ({
              general_contractor_employee_id: u,
              user_group_id: group.id,
              deleted_at: dayjs().toISOString(),
            })),
          ],
        },
      });
  };
  return (
    <div className="mt-1">
      <div>
        Group Name: <b>{group.name}</b>
      </div>
      <Select
        className="w-42"
        onBlur={onMembersUpdate}
        showSearch
        filterOption={getNormalOptionsFilter}
        value={selectedUsers}
        onSelect={async (v) => {
          setSelectedUsers((prev) => [...prev, v]);
        }}
        onDeselect={async (v) => {
          setSelectedUsers((prev) => prev.filter((u) => u !== v));
        }}
        mode="multiple"
        options={allUsers.map((gc) => ({
          id: gc.uid,
          value: gc.uid,
          label:
            gc.user.name +
            (gc.employee_title ? `, ${gc.employee_title.name.en}` : ""),
        }))}
      />
    </div>
  );
};
export default ShowGCGroup;
