import { RecordSourceSelectorProxy } from "relay-runtime";
import GetFullID from "src/common/functions/GetFullId";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import { InsertOrientationViewedSlideMutation$data } from "src/common/types/generated/relay/InsertOrientationViewedSlideMutation.graphql";

const viewedSlideInsertUpdater = (
  store: RecordSourceSelectorProxy<InsertOrientationViewedSlideMutation$data>
) => {
  const result = store.getRootField("insert_orientation_viewed_slide");
  const items = result.getLinkedRecords("returning") || [];
  items.forEach((item) => {
    const slide_id = item.getValue("slide_id");
    const user_id = item.getValue("user_id");
    const slide = store.get(GetFullID("orientation_slide", slide_id));
    if (slide) {
      slide.setLinkedRecords([item], "viewed_by", {
        where: { user_id: { _eq: user_id } },
        order_by: { created_at: Order_By.Desc },
      });
    }
  });
};
export default viewedSlideInsertUpdater;
