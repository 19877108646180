import { graphql } from "babel-plugin-relay/macro";

const CrewProjectWorkerOptionsFrag = graphql`
  fragment CrewProjectWorkerOptions_project_worker_connection_frag on query_root
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 100000 }
    after: { type: "String" }
    projectWorkerWhere: { type: "project_worker_bool_exp!" }
  )
  @refetchable(
    queryName: "CrewProjectWorkerOptions_project_worker_connection_frag_refetch"
  ) {
    project_worker_connection(
      where: { _and: [$projectWorkerWhere, { deleted_at: { _is_null: true } }] }
      order_by: { user: { name: asc } }
      first: $first
      after: $after
    )
      @connection(
        key: "CrewProjectWorkerOptions_project_worker_connection"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          hard_hat_number
          leading_project_crews_aggregate {
            aggregate {
              count
            }
          }
          title {
            translation {
              en
            }
          }
          subcontractor {
            id
            pk: id @__clientField(handle: "pk")
            name
          }
          project_crew {
            id
            pk: id @__clientField(handle: "pk")
            name
            lead_foreman_project_worker {
              id
              pk: id @__clientField(handle: "pk")
              user {
                name
              }
            }
          }
          user {
            id
            pk: id @__clientField(handle: "pk")
            name
            email
            phone_number
            username
          }
        }
      }
    }
  }
`;
export default CrewProjectWorkerOptionsFrag;
