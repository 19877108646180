import React from "react";
import BaseTable, {
  BaseTableProps,
} from "src/common/components/tables/basic/BaseTable";
import { GetReportByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";
import styled from "styled-components";
import TableFooterTotal from "./TableFooterTotal";

// TODO add this to tables
type DataProp = NonNullable<
  GetReportByPkQuery["report_by_pk"]
>["report_awareness_questions"][number];
type Props = Omit<BaseTableProps<Partial<DataProp>>, "columns"> & {
  onDelete?: (r: DataProp) => any;
};
type ReportAwarenessTable = (p: Props) => JSX.Element;

const ReportAwarenessTable = styled(BaseTable).attrs(
  ({ pagination, onDelete, ...props }: Props) => {
    const columns = [
      {
        title: "Question",
        key: "question",
        dataIndex: ["awareness_question", "question", "clientText"],
      },
      {
        title: "Answer",
        key: "answer",
        dataIndex: ["answer", "clientText"],
      },
    ];

    return {
      ...props,
      columns,
      rowKey: (w: DataProp) => w.awareness_question.id,
      summary: () =>
        pagination && (
          <TableFooterTotal cols={columns.length} total={pagination.total} />
        ),
    };
  },
)``;

export default ReportAwarenessTable as ReportAwarenessTable;
