/**
 * @generated SignedSource<<b6e85f6d0f537bd972a9c74bef1d3c01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LogisticPlan_DeleteDoc_Mutation$variables = {
  docId: string;
};
export type LogisticPlan_DeleteDoc_Mutation$data = {
  readonly delete_document_by_pk: {
    readonly id: string;
  } | null | undefined;
};
export type LogisticPlan_DeleteDoc_Mutation = {
  response: LogisticPlan_DeleteDoc_Mutation$data;
  variables: LogisticPlan_DeleteDoc_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "docId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "docId"
      }
    ],
    "concreteType": "document",
    "kind": "LinkedField",
    "name": "delete_document_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LogisticPlan_DeleteDoc_Mutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LogisticPlan_DeleteDoc_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d06af096a3ccd21e2d88c60a5990a64f",
    "id": null,
    "metadata": {},
    "name": "LogisticPlan_DeleteDoc_Mutation",
    "operationKind": "mutation",
    "text": "mutation LogisticPlan_DeleteDoc_Mutation(\n  $docId: uuid!\n) {\n  delete_document_by_pk(id: $docId) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ebb30d8a172ad5fe83709913526ac1da";

export default node;
