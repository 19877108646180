import { useState } from 'react';

const useColumnSearch = () => {
  const [value, setValue] = useState<string | undefined>(undefined);

  const setter = (val: string | undefined) => {
    if (val) {
      // Replace all non-alpha numerics chars with %
      const searchVal = val.replace(/[\W_]+/g, '%');
      setValue(`%${searchVal}%`);
    } else {
      setValue(undefined);
    }
  };

  return {
    value,
    setter,
  };
};

export default useColumnSearch;
