import { IconArrowLeft, IconArrowRight } from "@tabler/icons";
import clsx from "clsx";
import React from "react";
import Button from "src/common/components/general/Button";

type SitedeliveryCalendarWeekChangeButtonInterface = { onClick: () => void } & (
  | { next: true }
  | { prev: true }
);

const SitedeliveryCalendarWeekChangeButton: React.FC<
  SitedeliveryCalendarWeekChangeButtonInterface
> = (props) => {
  return (
    <div className="relative z-50 flex flex-col items-center justify-start">
      <div
        className={clsx("sticky top-0", "next" in props ? "right-0" : "left-0")}
      >
        {"next" in props ? (
          <Button
            icon={IconArrowRight}
            secondary
            onClick={props.onClick}
            hint={`next week`}
          />
        ) : (
          <Button
            icon={IconArrowLeft}
            secondary
            onClick={props.onClick}
            hint={`week before`}
          />
        )}
      </div>
    </div>
  );
};

export default SitedeliveryCalendarWeekChangeButton;
