import React from "react";
import LabelIcon, { LabelIconProps } from "./LabelIcon";

export interface InfoCardProps {
  title?: string;
  rows: LabelIconProps[];
  footer?: React.ReactElement;
}

const InfoCard: React.FC<InfoCardProps> = (props) => {
  return (
    <div className="flex flex-col gap-1">
      {!!props.title && <div className="w-full font-accent">{props.title}</div>}{" "}
      <div className="flex flex-col gap-0.5 ">
        {props.rows.map((labelIcon, i) => (
          <LabelIcon {...labelIcon} key={i} />
        ))}
      </div>
      {!!props.footer && <div>{props.footer}</div>}
    </div>
  );
};

export default InfoCard;
