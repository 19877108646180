import React, { FC, MutableRefObject, useEffect, useState } from "react";
import {
  OrientationNode,
  OrientationPage,
  SlideAnswersMapType,
} from "../../siteorientationTypes";
import { FormInstance } from "antd/lib/form";
import { OrientationType } from "src/common/types/manual/OrientationType";
import { useEmailGcForQuizFailureMutation } from "src/common/types/generated/apollo/graphQLTypes";
import SiteOrientationSlidesViewerSlideQuestion from "./slides/question/SiteOrientationSlidesViewerSlideQuestion";
import SiteOrientationSlidesViewerSlideImage from "./slides/image/SiteOrientationSlidesViewerSlideImage";
import ErrorMessage from "../../../../utility-features/error-handling/components/ErrorMessage";
import SiteOrientationSlidesViewerSlideText from "./slides/text/SiteOrientationSlidesViewerSlideText";
import SiteOrientationSlidesViewerSlideVideo from "./slides/video/SiteOrientationSlidesViewerSlideVideo";
import SiteOrientationSlidesViewerNavigation from "./SiteOrientationSlidesViewerNavigation";
import dayjs from "dayjs";
import SiteOrientationSlidesViewerSlideSignature from "./slides/SiteOrientationSlidesViewerSlideSignature";
import translateCorrectAnswers from "../../utils/translateCorrectAnswers";
import { Language } from "../../../../utility-features/i18n/language-utils/i18nTypes";
import { languageToLangType } from "../../../../utility-features/i18n/language-utils/LanguageToLangType";
import useLangStrings, {
  useCurrentLanguageState,
} from "../../../../utility-features/i18n/context/languageHooks";
import AudioPlayer from "../../../../common/components/audio-player/AudioPlayer";
import { Button } from "antd";
import LanguagePicker from "../../entryRoutes/inPerson/routes/worker/components/LanguagePicker";
import { AVAILABLE_LANGUAGES } from "src/utility-features/i18n/languages";

interface ViewerProps {
  lang: Language;
  userId: string;
  tvPlayer?: boolean;
  quizSubmitted: boolean;
  setQuizSubmitted: (b: boolean) => void;
  slideAnswers: MutableRefObject<SlideAnswersMapType>;
  page: OrientationPage;
  pageIndex: number;
  setPageIndex: (t: number) => void;
  pages: Array<OrientationPage>;
  form: FormInstance<any>;
  orientations: OrientationNode[];
  type: OrientationType;
  inPersonType: boolean;
  userName: string;
  projectId?: string;
  projectName?: string;
  moveToNextPage: () => void;
  onSubmitQuiz: () => Promise<void>;
  saveResults: (sign: string) => Promise<void>;
  isTestLink?: boolean;
}

const SiteOrientationSlidesViewerUI: FC<ViewerProps> = ({
  pages,
  slideAnswers,
  quizSubmitted,
  setQuizSubmitted,
  setPageIndex,
  page,
  pageIndex,
  saveResults,
  onSubmitQuiz,
  userName,
  form,
  type,
  inPersonType,
  orientations,
  lang,
  userId,
  projectId,
  tvPlayer,
  moveToNextPage,
  isTestLink,
}) => {
  const langStrings = useLangStrings(lang);
  const [openedbutNotCompleted, setOpenedButNotViewed] = useState(0);
  const [allAnswered, setAllAnswered] = useState(true);
  const quesSlides = orientations.flatMap((o) =>
    o.slides.filter((s) => s.content_type === "question"),
  );
  const [emailGCForQuizFailure] = useEmailGcForQuizFailureMutation();
  const [_, setVideoWatched] = useState(false);

  const [autoPlay, setAutoPlay] = useState(true);

  useEffect(() => {
    let x = -1;
    let ind = 0;
    for (const p of pages) {
      for (const s of p.slides) {
        if (s.viewed_by.length === 0) {
          setOpenedButNotViewed(ind);
          x = 0;
        }
      }
      ind++;
      if (x !== -1) break;
    }
  }, []);

  const moveToNext = () => {
    let nextPageIndex = Math.min(pageIndex + 1, pages.length - 1);
    if (!pages[pageIndex].viewed) {
      // skip viewed slides
      while (nextPageIndex < pages.length && pages[nextPageIndex].viewed)
        nextPageIndex++;
    }
    if (pageIndex === pages.length - 2) {
      const unviewed: number[] = [];
      pages.forEach((p, idx) => {
        if (!p.viewed && idx !== pageIndex) unviewed.push(idx);
      });
      if (unviewed.length > 0) {
        nextPageIndex = unviewed[0];
      }
    }

    if (pages[nextPageIndex] && !pages[nextPageIndex].viewed) {
      setOpenedButNotViewed(nextPageIndex);
    }
    setAllAnswered(true);
    setQuizSubmitted(false);
    setPageIndex(nextPageIndex);

    setVideoWatched(false);
    moveToNextPage();
  };

  const moveToPrev = () => {
    let prevPageIndex = Math.max(pageIndex - 1, 0);
    setPageIndex(prevPageIndex);
  };

  const onQuizSubmit = async () => {
    setAllAnswered(true);
    await onSubmitQuiz();
  };
  const [startTimeOfSlide, setStartTimeOfSlide] = useState(dayjs());
  useEffect(() => {
    if (page) {
      setStartTimeOfSlide(dayjs());
      document.title = `${page.orientation?.name ?? ""} ${pageIndex + 1} / ${
        pages.length
      }`;
      // setAllAnswered(true);
      if (page.type === "questions") {
        page.slides.forEach((s) => {
          if (!slideAnswers.current[s.pk] && allAnswered === true) {
            setAllAnswered(false);
          }
        });
      }
    } else {
      document.title = "orientation is not found";
    }
  }, [pageIndex]);
  if (!page) {
    const text =
      orientations.length === 0
        ? "Orinetation does not exist"
        : "Orientation is empy. Please add slides";
    return (
      <div className="w-full border-solid h-fulll border-1 border-grey">
        <h1 className="text-center mt-2 text-1.25">{text}</h1>
      </div>
    );
  }
  const onSign = async (imageUrl: string) => {
    await saveResults(imageUrl);
    const totalQues = quesSlides.filter((p) => p.check_correct_answer);
    const correctOnes = totalQues.filter((s) => {
      if (
        translateCorrectAnswers(
          s,
          slideAnswers.current[s.pk]?.lang || page.pageLang,
        ) === slideAnswers.current[s.pk].answer
      )
        return true;
      return false;
    });
    if (projectId) {
      emailGCForQuizFailure({
        variables: {
          input: {
            userId,
            projectId,
            langCode: languageToLangType(lang),
            correct: correctOnes.length,
            total: totalQues.length,
          },
        },
      });
    }
  };

  const firstAudioPageIndex = pages.findIndex(
    (page) =>
      page.slides.length > 0 &&
      (page.slides[0].content_type === "image" ||
        page.slides[0].content_type === "text") &&
      page.slides[0].text_to_speak &&
      page.slides[0].voice_url,
  );

  return (
    <div className={`relative min-h-full flex flex-col justify-between`}>
      <div className={`flex-1 flex flex-col gap-1 justify-center`}>
        {/* Orientation page content */}
        {page.type === "signature" && (
          <SiteOrientationSlidesViewerSlideSignature
            isProjectSpecific={type !== "universal"}
            lang={lang}
            onSign={onSign}
            userName={userName}
          />
        )}
        {page.type === "questions" && (
          <SiteOrientationSlidesViewerSlideQuestion
            page={page}
            form={form}
            onQuizSubmit={onQuizSubmit}
            slideAnswers={slideAnswers}
            submitted={quizSubmitted}
            allAnswered={allAnswered}
            notLastPage={pageIndex + 1 < pages.length}
            langStrings={langStrings}
          />
        )}
        {page.type !== "questions" &&
          page.slides.map((s, i) => (
            <div key={i}>
              {(!tvPlayer ||
                s.content_type === "text" ||
                s.content_type === "question") && (
                <h1 className="text-center slide-header text-2">{s.title}</h1>
              )}
              {s.content_type === "image" &&
                (s.image_url ? (
                  <SiteOrientationSlidesViewerSlideImage src={s.image_url} />
                ) : (
                  <ErrorMessage message={`Failed to load the image`} />
                ))}
              {s.content_type === "video" && s.video_url && (
                <SiteOrientationSlidesViewerSlideVideo
                  disableControls={tvPlayer ? false : s.watch_full_video}
                  videSrc={s.video_url}
                  showWarningIfRequiredNotComplete={!tvPlayer}
                  hideRewindAndPause={tvPlayer}
                  setStartOfSlide={setStartTimeOfSlide}
                  watchFullVideo={s.watch_full_video}
                  startTimeOfSlide={startTimeOfSlide}
                  key={pageIndex}
                  onWatched={moveToNext}
                  langStrings={langStrings}
                  videoMaxHeight={`calc(100vh - 4.5rem)`} // This is a hot fix. 4.5rem is value that should be based on the UI height, so that the video is not cut off
                />
              )}
              {s.content_type === "text" &&
                (s.text ? (
                  <SiteOrientationSlidesViewerSlideText htmlText={s.text} />
                ) : (
                  <ErrorMessage message={`Failed to load the text`} />
                ))}
            </div>
          ))}
        {(!inPersonType || tvPlayer) &&
          page.slides.length > 0 &&
          (page.slides[0].content_type === "image" ||
            page.slides[0].content_type === "text") &&
          page.slides[0].text_to_speak &&
          page.slides[0].voice_url && (
            <AudioPlayer
              onAudioEnded={() => {
                if (
                  page.slides[0].content_type === "image" ||
                  page.slides[0].content_type === "text"
                ) {
                  moveToNext();
                }
              }}
              autoPlay={autoPlay}
              allowedControls={!tvPlayer ? ["rewind"] : []}
              audioSrc={page.slides[0].voice_url}
              startButtonText={
                pageIndex === firstAudioPageIndex
                  ? langStrings.strings.start
                  : langStrings.strings.play
              }
              key={pageIndex}
              showProgressBar={!tvPlayer}
              onPlayPauseButtonClicked={() => {
                setAutoPlay(!autoPlay);
              }}
            />
          )}
      </div>
      <SiteOrientationSlidesViewerNavigation
        hideNavigationMenu={page.type === "signature"}
        menu={{
          isTestLink,
          menuItems: pages.map((p) => ({
            title: p.title,
            viewed: p.viewed,
          })),
          onMenuItemClick: (idx) => {
            setAllAnswered(true);
            setPageIndex(idx);
          },
          firstNonCompletedPageIndex: openedbutNotCompleted,
          selectedPageIndex: pageIndex,
        }}
        nextButtonHidden={
          !(
            pageIndex + 1 < pages.length &&
            (page.type !== "questions" || quizSubmitted || allAnswered) &&
            (!(
              page.slides.length === 0 ||
              (page.slides[0].content_type === "image" &&
                page.slides[0].text_to_speak &&
                page.slides[0].voice_url) ||
              (page.slides[0].content_type === "text" &&
                page.slides[0].text_to_speak &&
                page.slides[0].voice_url) ||
              page.slides[0].content_type === "video"
            ) ||
              inPersonType)
          )
        }
        nextButtonDisabled={
          type === "slides" &&
          (page.slides.length === 0 ||
            (!!page.slides[0].text_to_speak &&
              !!page.slides[0].voice_url &&
              (page.slides[0].content_type === "image" ||
                page.slides[0].content_type === "text")))
        }
        nextButtonText={
          page.type !== "questions" ||
          page.slides.every(
            (s) =>
              !s.check_correct_answer ||
              (slideAnswers.current[s.pk] &&
                slideAnswers.current[s.pk].answer ===
                  translateCorrectAnswers(
                    s,
                    slideAnswers.current[s.pk].lang || page.pageLang,
                  )),
          )
            ? langStrings.strings.next
            : langStrings.strings.iUnderstand
        }
        onNextButtonClick={moveToNext}
        backButtonHidden={!tvPlayer || pageIndex === 0}
        onBackButtonClick={moveToPrev}
      />
    </div>
  );
};
export default SiteOrientationSlidesViewerUI;
