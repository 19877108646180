import React, { useState } from "react";
import { Select } from "antd";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import SiteFeatureStepLayout from "../../../../../../../common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import useSiteOrientationUniversal from "../../../newDesign/utils/siteOrientationUniversalHook";

import { useCurrentLangStrings } from "../../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteOrientationUniversalUnionProps
  extends SiteOrientationStepProps {
  unions: Array<{
    id: string;
    name: string;
    laborUnionChapters: Array<{
      id: string;
      name: string;
    }>;
  }>;
}

const SiteOrientationUniversalUnion: React.FC<
  SiteOrientationUniversalUnionProps
> = (props) => {
  const workerContext = useSiteOrientationUniversal();
  let defaultUnion: null | {
    union: string;
    unionChapter: string;
  } = null;
  if (
    workerContext.orientee &&
    workerContext.orientee.userType === "worker" &&
    workerContext.orientee.veteranType
  ) {
    defaultUnion =
      workerContext.orientee.unionId && workerContext.orientee.unionChapterId
        ? {
            union: workerContext.orientee.unionId,
            unionChapter: workerContext.orientee.unionChapterId,
          }
        : null;
  }
  const [unionChapter, setUnionChapter] = useState(
    defaultUnion?.unionChapter || "",
  );
  const [union, setUnion] = useState(defaultUnion?.union || "");

  const langStrings = useCurrentLangStrings();
  const onEnter = () => {
    if (union && unionChapter) {
      workerContext.updateOrientee((user) =>
        user
          ? { ...user, unionChapterId: unionChapter, unionId: union }
          : { unionChapterId: unionChapter, unionId: union },
      );
    }
    props.onNext();
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.enterYourUnion}
      >
        {langStrings.strings.selectYourUnion}:{" "}
        <Select
          className="text-1"
          virtual={false}
          onSelect={(val) => {
            if (typeof val.valueOf() === "string") {
              setUnion(val.valueOf());

              const list =
                props.unions.find((p) => p.id === val.valueOf())
                  ?.laborUnionChapters || [];
              if (list.length === 1) setUnionChapter(list[0].id);
              else if (unionChapter) {
                setUnionChapter("");
              }
            }
          }}
          value={union}
          placeholder={langStrings.strings.selectYourUnion}
        >
          {props.unions.map((e) => (
            <Select.Option key={e.id} value={e.id} className="text-1">
              {langStrings.options.laborUnions[e.id] ?? e.name}
            </Select.Option>
          ))}
        </Select>
        {langStrings.strings.selectYourUnionChapter}:
        <Select
          className="text-1"
          virtual={false}
          onSelect={(val) => {
            // if it is called with non string value - we should fix problem in anotehr palce
            // if param is declared as string - it should not be a value of another type.
            if (typeof val !== "string") {
              console.log("WRONG type:", typeof val, val);
            } else {
              setUnionChapter(val);
            }
          }}
          value={unionChapter}
          placeholder={langStrings.strings.selectYourUnionChapter}
        >
          {(
            props.unions.find((p) => p.id === union)?.laborUnionChapters || []
          ).map((e) => (
            <Select.Option key={e.id} value={e.id} className="text-1">
              {langStrings.options.laborUnions[e.id] ?? e.name}
            </Select.Option>
          ))}
        </Select>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationUniversalUnion;
