import { Button, DatePicker, Form, Input, InputNumber, message } from "antd";
import dayjs from "dayjs";
import { forwardRef, useImperativeHandle } from "react";
import {
  GetAdminProjectDocument,
  GetAdminProjectQuery,
  GetAdminProjectQueryVariables,
  useDeleteSiteboardScreenMutation,
  useUpsertSiteboardScreenMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
export interface SiteBoardScreenFormRef {
  onApply: () => void;
}
type ScreenDataType = NonNullable<
  GetAdminProjectQuery["project_by_pk"]
>["siteboard_screens"];
interface SiteBoardScreenFormProps {
  screens: ScreenDataType;
  enabled?: boolean;
  projectId: string;
  editing?: boolean;
}
const SiteBoardScreenForm = forwardRef<
  SiteBoardScreenFormRef,
  SiteBoardScreenFormProps
>(({ screens, enabled, projectId, editing }, ref) => {
  const [upsertScreens, { loading: upserting }] =
    useUpsertSiteboardScreenMutation();
  const [deleteScreens, { loading: deleting }] =
    useDeleteSiteboardScreenMutation();
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    onApply: async () => {
      const vals: { [key: string]: dayjs.Dayjs | number } =
        await form.validateFields();
      if (!vals) {
        message.error("form vals now found");
      }
      const toInsertObjs = screens.map((sc, i) => {
        const created_at = vals["created_at" + i];
        const screen_count = vals["screen_count" + i];
        const raspbery_count = vals["raspbery_count" + i];
        if (
          typeof screen_count !== "number" ||
          typeof raspbery_count !== "number"
        )
          throw new Error("Please enter correct number");

        return {
          id: sc.id,
          ...(dayjs.isDayjs(created_at)
            ? { created_at: created_at.toISOString() }
            : {}),
          project_id: projectId,
          screen_count,
          raspbery_count,
        };
      });
      upsertScreens({ variables: { objects: toInsertObjs } });
    },
  }));
  return (
    <Form form={form}>
      {screens.map((sc, i) => {
        return (
          <div
            key={sc.id}
            style={{
              transition: "transform 0.5s",
              borderLeft: "2px solid #1890ff",
              backgroundColor: "#e6f7ff",
              marginBottom: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <div>
                <Form.Item
                  label="Created On"
                  initialValue={dayjs(sc.created_at)}
                  style={{ marginBottom: -0.4, marginTop: 5 }}
                  name={"created_at" + i}
                  rules={[{ required: true, message: "Enter Created At" }]}
                >
                  {editing ? (
                    <DatePicker
                      disabled={upserting || deleting}
                      disabledDate={(date) => date.isAfter(dayjs().endOf("d"))}
                    />
                  ) : (
                    dayjs(sc.created_at).format("YYYY-MM-DD")
                  )}
                </Form.Item>
                <Form.Item
                  initialValue={sc.raspbery_count}
                  style={{ marginBottom: -0.4, marginTop: 5 }}
                  rules={[{ required: true, message: "Enter Raspbery Count" }]}
                  label="Raspbery Count"
                  name={"raspbery_count" + i}
                >
                  {editing ? (
                    <InputNumber
                      disabled={upserting || deleting}
                      style={{ width: "100px" }}
                    ></InputNumber>
                  ) : (
                    sc.raspbery_count
                  )}
                </Form.Item>
                <Form.Item
                  initialValue={sc.screen_count}
                  style={{ marginBottom: -0.4, marginTop: 5 }}
                  rules={[{ required: true, message: "Enter Screen Count" }]}
                  label="Screen Added Count"
                  name={"screen_count" + i}
                >
                  {editing ? (
                    <InputNumber
                      disabled={upserting || deleting}
                      style={{ width: "100px" }}
                    ></InputNumber>
                  ) : (
                    sc.screen_count
                  )}
                </Form.Item>
              </div>
              {/* <Button type="link" style={{paddingRight: 5}} htmlType="submit"></Button> */}
              {enabled && (
                <Button
                  type="link"
                  style={{ paddingRight: 10, color: "red" }}
                  loading={upserting || deleting}
                  onClick={() => {
                    deleteScreens({
                      variables: { id: sc.id },
                      update: (client) => {
                        const data = client.readQuery<
                          GetAdminProjectQuery,
                          GetAdminProjectQueryVariables
                        >({
                          query: GetAdminProjectDocument,
                          variables: { projectId },
                        });
                        if (!data) {
                          message.error(
                            "Refresh page to see result, if it's happening multiple time, contact Siteform",
                          );
                          return;
                        }
                        const project = data.project_by_pk;
                        if (!project) {
                          message.error(
                            "Refresh page to see result, if it's happening multiple time, contact Siteform",
                          );
                          return;
                        }
                        client.writeQuery({
                          data: {
                            ...data,
                            project_by_pk: {
                              ...(data.project_by_pk || {}),
                              siteboard_screens:
                                data.project_by_pk?.siteboard_screens.filter(
                                  (screen) => screen.id !== sc.id,
                                ),
                            },
                          },
                          query: GetAdminProjectDocument,
                          variables: { projectId: project.id },
                        });
                      },
                    });
                  }}
                >
                  Delete
                </Button>
              )}
            </div>
          </div>
        );
      })}
    </Form>
  );
});
export default SiteBoardScreenForm;
