/**
 * @generated SignedSource<<ec259d60b72cf5dd7e58bac06f25c8b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useSiteOrientationInPersonWorkerUpdateHH_UpdateHHMutation$variables = {
  hardHat: string;
  projectId: string;
  workerId: string;
};
export type useSiteOrientationInPersonWorkerUpdateHH_UpdateHHMutation$data = {
  readonly update_project_worker: {
    readonly returning: ReadonlyArray<{
      readonly hard_hat_number: string | null | undefined;
      readonly id: string;
    }>;
  } | null | undefined;
};
export type useSiteOrientationInPersonWorkerUpdateHH_UpdateHHMutation = {
  response: useSiteOrientationInPersonWorkerUpdateHH_UpdateHHMutation$data;
  variables: useSiteOrientationInPersonWorkerUpdateHH_UpdateHHMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hardHat"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workerId"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "hard_hat_number",
        "variableName": "hardHat"
      }
    ],
    "kind": "ObjectValue",
    "name": "_set"
  },
  {
    "fields": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "projectId"
              }
            ],
            "kind": "ObjectValue",
            "name": "orientation_project_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "project"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "workerId"
          }
        ],
        "kind": "ObjectValue",
        "name": "worker_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hard_hat_number",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useSiteOrientationInPersonWorkerUpdateHH_UpdateHHMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "project_worker_mutation_response",
        "kind": "LinkedField",
        "name": "update_project_worker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_worker",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              {
                "alias": "id",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useSiteOrientationInPersonWorkerUpdateHH_UpdateHHMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "project_worker_mutation_response",
        "kind": "LinkedField",
        "name": "update_project_worker",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_worker",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2ce5c0a05eb74c9f496523fab5b54bd0",
    "id": null,
    "metadata": {},
    "name": "useSiteOrientationInPersonWorkerUpdateHH_UpdateHHMutation",
    "operationKind": "mutation",
    "text": "mutation useSiteOrientationInPersonWorkerUpdateHH_UpdateHHMutation(\n  $workerId: uuid!\n  $projectId: uuid!\n  $hardHat: String!\n) {\n  update_project_worker(where: {project: {orientation_project_id: {_eq: $projectId}}, worker_id: {_eq: $workerId}}, _set: {hard_hat_number: $hardHat}) {\n    returning {\n      id\n      hard_hat_number\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "08824b68bfb076cf1936ec2262639fbf";

export default node;
