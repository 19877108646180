const capitalizePhrase = (phrase?: string) => {
  if (!phrase) {
    return "";
  }
  const words = phrase.split(" ");
  const capitalizedWords = words.map((word) => {
    if (word.length > 0) {
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    } else {
      return word;
    }
  });
  return capitalizedWords.join(" ");
};

export default capitalizePhrase;
