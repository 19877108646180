import { Form, Input, message, notification, Select, Spin } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { Dayjs } from "dayjs";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLazyLoadQuery, useMutation } from "react-relay/hooks";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import capitalize from "src/common/functions/capitalize";
import { GCCreateWorkerModalMutation } from "src/common/types/generated/relay/GCCreateWorkerModalMutation.graphql";
import { GCCreateWorkerModal_Sub_Role_TitleQuery } from "src/common/types/generated/relay/GCCreateWorkerModal_Sub_Role_TitleQuery.graphql";

interface FormValues {
  hardHatNumber?: string;
  name?: string;
  workerRole?: string;
  subcontractor?: string;
  birthDate?: Dayjs;
  titleId?: string;
  phoneNumber?: string;
  email?: string;
  username?: string;
}

interface AddWorkerModalProps {
  visible?: boolean;
  projectId: string;
  onCancel?: () => void;
}

export type AddWorkerModalRef = FModalRef<FormValues> | null;

export const query = graphql`
  query GCCreateWorkerModal_Sub_Role_TitleQuery($projectId: uuid!) {
    worker_role_connection(order_by: { value: asc }) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          value
        }
      }
    }
    worker_title_connection(order_by: { translation: { en: asc } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          translation {
            en
          }
        }
      }
    }
    subcontractor_connection(
      where: {
        subcontractor_projects: { project: { id: { _eq: $projectId } } }
      }
      order_by: { name: asc }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          id
          name
        }
      }
    }
  }
`;

const GCCreateWorkerModal = forwardRef<AddWorkerModalRef, AddWorkerModalProps>(
  ({ projectId }, ref) => {
    const modal = useRef<FModalRef<FormValues>>(null);
    const [loading, setLoading] = useState(false);
    const data = useLazyLoadQuery<GCCreateWorkerModal_Sub_Role_TitleQuery>(
      query,
      {
        projectId,
      },
    );
    const roleData = data.worker_role_connection.edges;
    const titleData = data.worker_title_connection.edges;
    const subcontractorData = data.subcontractor_connection.edges;

    const subOptions = subcontractorData.map((sub) => ({
      id: sub.node.pk,
      name: sub.node.name,
    }));

    subOptions.sort((sub1, sub2) => sub1.name.localeCompare(sub2.name));

    const [gettingUsername, setGettingUsername] = useState(false);
    const [createWorker] = useMutation<GCCreateWorkerModalMutation>(
      graphql`
        mutation GCCreateWorkerModalMutation(
          $objects: [worker_insert_input!]!
        ) {
          insert_worker(objects: $objects) {
            returning {
              id
              pk: id @__clientField(handle: "pk")
            }
          }
        }
      `,
    );

    useImperativeHandle<AddWorkerModalRef, AddWorkerModalRef>(
      ref,
      () => modal.current,
    );
    const reset = () => {
      setLoading(false);
      modal.current?.form.resetFields();
      modal.current?.close();
    };

    return (
      <FModal
        ref={modal}
        // visible={visible}
        title="Create a new worker"
        okText="Add"
        cancelText="Cancel"
        onCancel={() => {
          reset();
        }}
        onOk={() => {
          const form = modal.current?.form;
          if (!form) return;
          form.validateFields().then(async (values) => {
            setLoading(true);
            createWorker({
              variables: {
                objects: [
                  {
                    current_worker_role: values.workerRole,
                    worker_title_id: values.titleId,
                    subcontractor_id: values.subcontractor,
                    user: {
                      data: {
                        role: "worker",
                        name: values.name,
                        allow_reset_password: true,
                        phone_number_privacy_setting: { data: {} },
                        email_privacy_setting: { data: {} },
                        project_privacy_setting: { data: {} },
                      },
                    },
                    worker_projects: {
                      data: [
                        {
                          project_id: projectId,
                          worker_role: values.workerRole,
                          title_id: values.titleId,
                          subcontractor_id: values.subcontractor,
                          hard_hat_number: values.hardHatNumber,
                        },
                      ],
                    },
                  },
                ],
              },
              onCompleted: (data) => {
                reset();
                message.success("Done");
              },
              onError: (e: Error) => {
                notification.error({
                  message: "Error: ",
                  description: e.message,
                  duration: null,
                });
              },
            });
          });
        }}
      >
        {/* <p>
        New workers will be prompted to create a password for themselves on the
        SiteForm app if it is their first time logging in.
      </p> */}
        <Form.Item
          name="name"
          label="First and last name"
          rules={[{ required: true, message: "Enter the name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="subcontractor"
          label="Subcontractor"
          rules={[{ required: true, message: `Choose the subcontractor` }]}
        >
          <Select style={{ width: "100%" }}>
            {subOptions?.map((t) => (
              <Select.Option key={t.id} value={t.id}>
                {t.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item
          name="hardHatNumber"
          label="Hard Hat Number"
          rules={[{ message: "Enter the Hard Hat Number" }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          name="workerRole"
          label="Worker current role"
          rules={[
            { required: true, message: `Choose the worker's current role` },
          ]}
        >
          <Select style={{ width: "100%" }}>
            {roleData?.map((c) => (
              <Select.Option key={c.node.value} value={c.node.value}>
                {capitalize(c.node.value)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="titleId"
          label="Worker trade"
          rules={[
            { required: true, message: `Choose the worker's current trade` },
          ]}
        >
          <Select style={{ width: "100%" }}>
            {titleData?.map((t) => (
              <Select.Option key={t.node.pk} value={t.node.pk}>
                {t.node.translation.en}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {gettingUsername && <Spin />}
      </FModal>
    );
  },
);

export default withCustomSuspense(GCCreateWorkerModal);
