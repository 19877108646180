import { IconArrowLeft } from "@tabler/icons";
import { Button } from "antd";
import React, { FC } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import CrewDetails from "../../components/CrewDetails";

const SCProjectCrew: FC = () => {
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const crewId = searchParams.get("id");
  const navigate = useNavigate();
  const subcontractorId = useSubcontractorId();
  return (
    <BasicWrapper scrollable>
      <div>
        <Button
          icon={<IconArrowLeft />}
          onClick={() =>
            navigate(`/sub/projects/${projectId}/workers-and-crews`)
          }
        >
          Back
        </Button>
      </div>
      <CrewDetails
        crewId={crewId ?? ""}
        subcontractorId={subcontractorId}
        projectId={projectId ?? ""}
        isGCAcc={false}
      />
    </BasicWrapper>
  );
};

export default SCProjectCrew;
