import React from "react";

interface SiteBoardListProps {
  title: string;
  hideCounter?: boolean;
  list: React.ReactNode[];
}

const SiteBoardList: React.FunctionComponent<SiteBoardListProps> = (props) => {
  return (
    <div className={`flex flex-col gap-1 p-2`}>
      <div className={`flex flex-row gap-1 items-baseline text-white`}>
        <span className={`text-4`}>{props.title}</span>
        {props.list.length && !props.hideCounter ? (
          <span className={`text-2`}>{`(${props.list.length})`}</span>
        ) : null}
      </div>
      <div className={`flex flex-col gap-0.5`}>{props.list}</div>
    </div>
  );
};

export default SiteBoardList;
