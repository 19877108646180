import { Outlet } from "react-router-dom";
import searchParamsProvider from "src/common/functions/searchParamsProvider";
import OrientationViewer from "src/domain-features/siteorientation/components/slidesViewer/SiteOrientationSlidesViewer";
import { LanguageProvider } from "src/utility-features/i18n/context/languageContext";
import NestedRoute from "../../../../common/types/manual/NestedRoute";

//Used for test orientation module flow.

const orientationTestRoute: NestedRoute = {
  element: <Outlet />,
  children: [
    {
      path: ":workerId/slides/:type",
      element: (
        <LanguageProvider>
          {searchParamsProvider(OrientationViewer)},
        </LanguageProvider>
      ),
    },
  ],
};

export default orientationTestRoute;
