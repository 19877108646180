import { GraphQLTaggedNode } from "react-relay";
import { useMutation, UseMutationConfig } from "react-relay/hooks";
import { MutationParameters } from "relay-runtime";

const useAsyncMutation = <TMutation extends MutationParameters>(
  mutation: GraphQLTaggedNode
) => {
  const [commit, isInFlight] = useMutation<TMutation>(mutation);

  const asyncCommit = (
    config: Omit<UseMutationConfig<TMutation>, "onCompleted" | "onError">
  ) => {
    return new Promise<TMutation["response"]>((res, rej) => {
      commit({
        ...config,
        onCompleted: res,
        onError: rej,
      });
    });
  };

  return [asyncCommit, isInFlight] as const;
};

export default useAsyncMutation;
