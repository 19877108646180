import React from "react";
import { Button, Dropdown } from "antd";
import { DownOutlined, GlobalOutlined } from "@ant-design/icons";
import { Language } from "../../../../../../../utility-features/i18n/language-utils/i18nTypes";
import getLanguageTitle from "../../../../../../../utility-features/i18n/language-utils/getLanguageTitle";
import isLanguage from "../../../../../../../utility-features/i18n/language-utils/isLanguage";

export interface LanguagePickerProps {
  selectedLang: Language;
  availableLangs: Language[];
  size?: "lg" | "md";
  onLangeChange: (newSelectedLang: Language) => void;
}

const LanguagePicker: React.FC<LanguagePickerProps> = ({
  size = "md",
  ...props
}) => {
  const textSizeClass = size === "lg" ? "text-1.5 text-grey" : "";

  return (
    <Dropdown
      className={textSizeClass}
      trigger={["click", "contextMenu"]}
      menu={{
        selectable: true,
        selectedKeys: [props.selectedLang],
        multiple: false,
        onSelect: ({ key }) => {
          isLanguage(key) && props.onLangeChange(key);
        },
        items: props.availableLangs.map((lang) => ({
          key: lang,
          label: getLanguageTitle(lang),
        })),
      }}
    >
      <Button className={`p-0`} type={`text`} icon={<GlobalOutlined />}>
        {getLanguageTitle(props.selectedLang)}

        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default LanguagePicker;
