import produce from "immer";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  GetObservationByPkQueryVariables,
  GetObservationByPkQuery,
  useUpdateUnsafeObservationMutation,
  Unsafe_Observation_Set_Input,
  GetObservationByPkDocument,
  Lang_Code_Enum,
} from "src/common/types/generated/apollo/graphQLTypes";
import handleRequestError from "src/utility-features/error-handling/handleRequestError";
import createObservationPatch from "./createObservationPatch";

export interface UpdateUnsafeObservationFuncProps {
  observation: NonNullable<GetObservationByPkQuery["observation_by_pk"]>;
  set: Omit<
    Unsafe_Observation_Set_Input,
    "action_id" | "observation_id" | "corrective_action_id" | "root_cause_id"
  >;
  editComment?: string;
}
const useUpdateUnsafeObservationWithCache = () => {
  const [updateUnsafeObservation, { loading: updatingUnsafe }] =
    useUpdateUnsafeObservationMutation();

  const authUser = useAuthUser();
  const updateUnsafe = async ({
    observation,
    editComment,
    set,
  }: UpdateUnsafeObservationFuncProps) => {
    try {
      const isSubmitted =
        observation.status !== "draft" &&
        (!observation.observation_inspection ||
          !!observation.observation_inspection.submitted_on);
      const unsafe = observation.unsafe_observation;
      if (unsafe) {
        const newData: typeof observation = {
          ...observation,
          unsafe_observation: { ...unsafe, ...set },
        };
        const patch = createObservationPatch(newData, observation);

        await updateUnsafeObservation({
          variables: {
            observationId: observation.id,
            _set: set,
            editHistoryObjects: isSubmitted
              ? [
                  {
                    patch,
                    observation_id: observation.id,
                    edit_type: "edited",
                    edited_by_uid: authUser.uid,
                    comment: {
                      data: {
                        original: editComment,
                        en: editComment,
                        lang: Lang_Code_Enum.En,
                      },
                    },
                  },
                ]
              : [],
          },
          optimisticResponse: {
            update_unsafe_observation_by_pk: { ...unsafe, ...set },
            insert_observation_edit_history: { returning: [] },
          },
          update: (cache, returningData) => {
            const returningEdits =
              returningData.data?.insert_observation_edit_history?.returning;
            if (!returningEdits) throw new Error("No returning edits");
            cache.modify<typeof observation>({
              id: cache.identify(observation),
              fields: {
                observation_edits(existingEdits, { toReference }) {
                  return [
                    ...existingEdits,
                    ...returningEdits.map((edit) => toReference(edit)!),
                  ];
                },
              },
            });
          },
        });
      }
    } catch (err) {
      handleRequestError(err, { errorTitle: "Updating data failed" });
    }
  };
  return [updateUnsafe, updatingUnsafe] as const;
};
export default useUpdateUnsafeObservationWithCache;
