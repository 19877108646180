import React, { FunctionComponent as FC } from "react";
import { DatePicker } from "antd";
import getDateRange from "src/common/functions/getDateRange";

interface TableTitleProps {
  title: any;
  titleComponent?: any;
  onDateChange?: (range: Array<Date | undefined>) => void;
}

const TableTitle: FC<TableTitleProps> = (props) => {
  return (
    <React.Fragment>
      <h3 style={{ fontWeight: "bold", fontSize: "16px" }}>{props.title}</h3>
      {props.onDateChange !== undefined && (
        <React.Fragment>
          <br />
          <DatePicker.RangePicker
            onChange={(range) => {
              const dateRange = getDateRange(range);
              if (props.onDateChange) {
                props.onDateChange(dateRange);
              }
            }}
          />
        </React.Fragment>
      )}
      {props.titleComponent && props.titleComponent}
    </React.Fragment>
  );
};

export default TableTitle;
