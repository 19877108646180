import React, { useState } from "react";
import InspectionResultTable, {
  InspectionResultType,
} from "src/domain-features/siteinspection/components/tables/InspectionResultTable";
import { inspection_result_bool_exp } from "src/common/types/generated/relay/InspectionResultTableQuery.graphql";
import InspectionResult from "./item-modals/InspectionResult";
import dayjs from "dayjs";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";

interface ShowInspectionResultsProps {
  projectId: string;
  qrs: Array<{ text: string; value: string }>;
  subs: Array<{ name: string; id: string }>;
  manuallyEnteredCompanies: Array<string>;
  selectedInspectionId?: string;
}

const ShowInspectionResults: React.FC<ShowInspectionResultsProps> = ({
  projectId,
  selectedInspectionId,
  subs,
  manuallyEnteredCompanies,
  qrs,
}) => {
  const [customFilter, setCustomFilter] = useState<inspection_result_bool_exp>(
    {},
  );
  const [selectedSubs, handleSelectSub, setSelectedSubs] =
    useSelectedIndicies<string>();

  const [
    selectedCompanyNames,
    handleSelectCompanyName,
    setSelectedCompanyName,
  ] = useSelectedIndicies<string>();
  const [selectedResult, setSelectedResult] = useState<InspectionResultType>();
  return (
    <>
      {selectedResult && (
        <InspectionResult
          data={selectedResult}
          onClose={() => setSelectedResult(undefined)}
          visible={!!selectedResult}
        />
      )}
      <InspectionResultTable
        projectId={projectId}
        qrs={qrs}
        title="Completed"
        excludedKeys={["inspection_name"]}
        customFilters={[
          // {
          //   title: "Deficient items",
          //   type: "checkbox",
          //   removeFilter: () => {},
          //   options: [
          //     {
          //       option: "Open",
          //       optionType: "checkbox",
          //       onApply: () => {},
          //       onCancel: () => {},
          //     },
          //     {
          //       option: "Resolved",
          //       optionType: "checkbox",
          //       onApply: () => {},
          //       onCancel: () => {},
          //     },
          //   ],
          // },
          {
            title: "Completed Between",
            type: "date-range",
            removeFilter: () => {
              setCustomFilter((prev) => {
                const { created_at, ...toReturn } = prev;
                return toReturn;
              });
            },
            onOk: (
              fromMoment: dayjs.Dayjs | null,
              toMoment: dayjs.Dayjs | null,
            ) => {
              if (fromMoment && toMoment) {
                const from = dayjs(fromMoment).startOf("day");
                const to = dayjs(toMoment).endOf("day");
                setCustomFilter((prev) => ({
                  ...prev,
                  created_at: {
                    _gte: from.toISOString(),
                    _lte: to.toISOString(),
                  },
                }));
              }
            },
          },
          {
            type: "checkbox",
            title: "Company",
            removeFilter: () => {
              setSelectedCompanyName(new Set());
              setSelectedSubs(new Set());
            },

            options: [
              ...subs.map((sub) => ({
                onApply: () => {
                  handleSelectSub(sub.id);
                },
                onCancel: () => {
                  handleSelectSub(sub.id);
                },
                optionType: "checkbox",
                option: sub.name,
              })),
              ...manuallyEnteredCompanies.map((company) => ({
                onApply: () => {
                  handleSelectCompanyName(company);
                },
                onCancel: () => {
                  handleSelectCompanyName(company);
                },
                optionType: "checkbox",
                option: company,
              })),
            ],
          },
        ]}
        OnRowItemClick={(res) => setSelectedResult(res)}
        where={{
          inspection_instance: selectedInspectionId
            ? { id: { _eq: selectedInspectionId } }
            : { project_id: { _eq: projectId } },
          ...customFilter,
          ...(selectedSubs.size > 0 || selectedCompanyNames.size > 0
            ? {
                _or: [
                  { company_name: { _in: [...selectedCompanyNames] } },
                  { subcontractor_id: { _in: [...selectedSubs] } },
                ],
              }
            : {}),
        }}
      />
    </>
  );
};
export default ShowInspectionResults;
