import React from "react";
import NestedRoute from "src/common/types/manual/NestedRoute";
import SiteOrientationInPersonWorkerView from "./SiteOrientationInPersonWorkerView";
import orientationSlidesRoute from "./routes/orientationSlides/orientationSlidesRoute";

const workerRoute: NestedRoute = {
  children: [
    {
      path: "orientationSlides",
      ...orientationSlidesRoute,
    },
    {
      path: "",
      element: <SiteOrientationInPersonWorkerView />,
    },
  ],
};

export default workerRoute;
