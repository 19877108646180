import {
  useInsertInjuryDetailBodyPartsAffectedMutation,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  GetIncidentByPkDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import createIncidentPatch from "./createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import * as uuid from "uuid";

const useUpdateInjuryBodyParts = () => {
  const [insertInjuryDetailBodyParts] =
    useInsertInjuryDetailBodyPartsAffectedMutation();
  const authUser = useAuthUser();

  const updateInjuryBodyParts = async (
    toBeInsertedBodyPart: { value: string; side?: string },
    incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
    injuredUserId: string,
  ) => {
    const injuredUser = incident.injured_users.find(
      (injuredUser) => injuredUser.id === injuredUserId,
    );
    const injuryDetail = injuredUser?.injury_detail;
    if (!injuryDetail) return;

    const id = uuid.v4();

    const updatedIncident = {
      ...incident,
      injured_users: incident.injured_users.map((injuredUser) => {
        if (injuredUser.id === injuredUserId && injuredUser.injury_detail) {
          return {
            ...injuredUser,
            injury_detail: {
              ...injuredUser.injury_detail,
              body_parts_affected: [
                ...injuredUser.injury_detail?.body_parts_affected,
                {
                  id: id,
                  injury_detail_id: injuryDetail.id,
                  value: toBeInsertedBodyPart.value,
                  side: toBeInsertedBodyPart.side,
                },
              ],
            },
          };
        } else return injuredUser;
      }),
    };

    const patch = createIncidentPatch(updatedIncident, incident);
    const comment = ``;

    await insertInjuryDetailBodyParts({
      variables: {
        objects: [
          {
            id: id,
            value: toBeInsertedBodyPart.value,
            side: toBeInsertedBodyPart.side,
            injury_detail_id: injuryDetail.id,
          },
        ],
        editObject: {
          patch: patch,
          comment: comment,
          edited_by_uid: authUser.uid,
          edit_type: "body_parts_affected_update",
          incident_id: incident.id,
        },
      },
      update(cache, result) {
        const insertedResultData =
          result.data?.insert_injury_detail_body_part_affected?.returning;

        const injuredUserIndex = incident.injured_users.findIndex(
          (injuredUser) => injuredUser.id === injuredUserId,
        );
        if (injuredUserIndex === -1) return;

        const injuredUsers = incident.injured_users.map((user, index) => {
          if (index !== injuredUserIndex) {
            return user;
          }
          const injuryDetail = user.injury_detail;
          if (
            injuryDetail &&
            injuryDetail.body_parts_affected &&
            insertedResultData
          ) {
            const newInjuryDetail = {
              ...injuryDetail,
              body_parts_affected: [
                ...injuryDetail.body_parts_affected,
                ...insertedResultData,
              ],
            };
            return {
              ...user,
              injury_detail: newInjuryDetail,
            };
          }
          return user;
        });

        const updatedIncident = {
          ...incident,
          injured_users: injuredUsers,
        };

        cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>({
          query: GetIncidentByPkDocument,
          data: {
            __typename: "query_root",
            incident_by_pk: updatedIncident,
          },
        });
      },
    });
  };

  return [updateInjuryBodyParts];
};

export default useUpdateInjuryBodyParts;
