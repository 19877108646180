import { IconMinus, IconPlus } from "@tabler/icons";
import { graphql } from "babel-plugin-relay/macro";
import React, { useRef, useState } from "react";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import {
  CreateInspectionQRModalMutation,
  inspection_qr_code_insert_input,
} from "src/common/types/generated/relay/CreateInspectionQRModalMutation.graphql";
import { OptionType } from "../../utils/siteInspectionTypes";
import Button from "src/common/components/general/Button";
import * as uuid from "uuid";
import { auth } from "src/common/functions/firebase";
import { Alert, message, notification } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { ConnectionHandler } from "relay-runtime";

const CreateInspectionQRModal: React.FC<{
  inspectionsInstancesOptions: Array<OptionType>;
  visible: boolean;
  onCancel: () => void;
  onFinish: () => void;
  projectId: string;
}> = ({
  inspectionsInstancesOptions,
  onFinish,
  onCancel,
  visible,
  projectId,
}) => {
  const [insertInspectionQR, isInserting] =
    useAsyncMutation<CreateInspectionQRModalMutation>(
      graphql`
        mutation CreateInspectionQRModalMutation(
          $objects: [inspection_qr_code_insert_input!]!
        ) {
          insert_inspection_qr_code(objects: $objects) {
            affected_rows
            returning {
              ...InspectionQRCodeFrag @relay(mask: false)
            }
          }
        }
      `,
    );
  const [createdQRs, setCreatedQRs] = useState<Array<string>>([uuid.v4()]);
  const modal = useRef<FModalRef<{ [key1: string]: string | Array<string> }>>();
  return (
    <FModal
      title="Create an Inspection QR Code"
      open={visible}
      confirmLoading={isInserting}
      onCancel={onCancel}
      okText="Save "
      ref={modal}
      onOk={async () => {
        const values = await modal.current?.form.validateFields();
        if (!values) return;
        const objects: Array<inspection_qr_code_insert_input> = [];
        for (const id of createdQRs) {
          const qrName = values["name_" + id];
          if (typeof qrName !== "string") {
            continue;
          }
          const inspNameVal = values["inspections_" + id];
          const inspections: string[] =
            typeof inspNameVal === "string" ? [inspNameVal] : inspNameVal;
          if (
            typeof qrName === "string" &&
            (!inspections || Array.isArray(inspections))
          ) {
            objects.push({
              created_by_uid: auth.currentUser?.uid,
              name: qrName,
              project_id: projectId,
              inspection_qr_code_instances: {
                data: (inspections || []).map((ins) => ({
                  inspection_instance_id: ins,
                })),
              },
            });
          }
        }
        await insertInspectionQR({
          variables: { objects },
          updater: (store) => {
            const newQRRecords = store
              .getRootField("insert_inspection_qr_code")
              .getLinkedRecords("returning");
            const conn = ConnectionHandler.getConnection(
              store.getRoot(),
              "InspectionQRTable_inspection_qr_code_connection",
            );
            const optionsConn = ConnectionHandler.getConnection(
              store.getRoot(),
              "GCInspectionsQuery_inspection_qr_code_connection",
            );
            newQRRecords.map((newRec) => {
              if (conn) {
                const edge = ConnectionHandler.createEdge(
                  store,
                  conn,
                  newRec,
                  "edge",
                );
                ConnectionHandler.insertEdgeBefore(conn, edge);
              }
              if (optionsConn) {
                const edge = ConnectionHandler.createEdge(
                  store,
                  optionsConn,
                  newRec,
                  "edge",
                );
                ConnectionHandler.insertEdgeBefore(optionsConn, edge);
              }
            });
          },
        })
          .then((d) => {
            message.success("Successfully created QR(s)");
            // console.log(d);
          })
          .catch((err) => {
            console.error(err);
            notification.error({
              message: "Couldn't add QR to project",
              description:
                err instanceof Error ? err.message : JSON.stringify(err),
            });
          });
        onFinish();
        modal.current?.close();
      }}
    >
      <Alert
        type="info"
        showIcon
        className="mb-1"
        icon={<InfoCircleFilled />}
        message={
          "Inspection QR Codes are created to easily access an inspection in the field. One or Multiple inspections can be added to a single QR Code"
        }
      />
      {createdQRs.map((id, i) => (
        <div key={id} className="flex justify-between">
          <div className="w-32">
            <FModal.Text
              required
              name={"name_" + id}
              requiredMessage="Enter a valid name"
              className="w-full"
              label="Inspection QR Code Name"
              props={{
                width: "100%",
                placeholder: "A name that easily identifies its Inspections",
              }}
            />
            <FModal.Select
              name={"inspections_" + id}
              label="Add Inspections"
              className="w-"
              props={{
                placeholder: "Add one or more inspections to this QR Code",
                options: inspectionsInstancesOptions,
                mode: "multiple",
              }}
            />
          </div>
          <div className="">
            {i !== 0 && (
              <Button
                secondary
                small
                icon={IconMinus}
                onClick={() =>
                  setCreatedQRs((prev) => {
                    const newList = [...prev];
                    newList.splice(i, 1);
                    return [...newList];
                  })
                }
              ></Button>
            )}
          </div>
        </div>
      ))}
      <Button
        label="Create Another"
        icon={IconPlus}
        onClick={() => {
          setCreatedQRs((prev) => {
            return [...prev, uuid.v4()];
          });
        }}
      />
    </FModal>
  );
};
export default CreateInspectionQRModal;
