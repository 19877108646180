import React from "react";
import BaseTable, {
  BaseTableProps,
} from "src/common/components/tables/basic/BaseTable";
import { Project_Covid_Tested_Positive_Question } from "src/common/types/generated/apollo/graphQLTypes";
import styled from "styled-components";
import TableFooterTotal from "./TableFooterTotal";

// TODO add this to tables
type DataProp = Project_Covid_Tested_Positive_Question;

type Props = Omit<BaseTableProps<Partial<DataProp>>, "columns"> & {
  onDelete?: (r: DataProp) => any;
};
type CovidTestedPositiveQuestionsTable = (p: Props) => JSX.Element;

const CovidTestedPositiveQuestionsTable = styled(BaseTable).attrs(
  ({ pagination, onDelete, ...props }: Props) => {
    const columns = [
      {
        title: "Question",
        key: "question",
        dataIndex: ["question", "original"],
      },
      // {
      //   title: '',
      //   key: 'remove',
      //   render: (_: any, permit: DataProp) => (
      //     <Popconfirm
      //       title='Are you sure?'
      //       onConfirm={noPropagation(() => {
      //         if (onDelete) onDelete(permit);
      //       })}
      //       onCancel={noPropagation()}
      //       okText='Yes'
      //       cancelText='Cancel'
      //     >
      //       <a onClick={noPropagation()}>
      //         <Typography.Text type='danger'>Remove</Typography.Text>
      //       </a>
      //     </Popconfirm>
      //   ),
      // },
    ];

    return {
      ...props,
      columns,
      rowKey: (w: any) => w.id,
      summary: () =>
        pagination && (
          <TableFooterTotal cols={columns.length} total={pagination.total} />
        ),
    };
  }
)``;

export default CovidTestedPositiveQuestionsTable as CovidTestedPositiveQuestionsTable;
