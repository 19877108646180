import React, { useMemo, useState } from "react";
import AutoComplete from "src/common/components/general/AutoComplete";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import noop from "src/common/functions/noop";
import { SiteInspectionStepProps } from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import useSiteInspectionUser from "src/domain-features/siteinspection/utils/useSiteInspectionUser";

import { useCurrentLangStrings } from "../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteInspectionWorkerStepsModelProps
  extends SiteInspectionStepProps {}

const SiteInspectionWorkerStepsModel: React.FC<
  SiteInspectionWorkerStepsModelProps
> = (props) => {
  const workerContext = useSiteInspectionUser();
  const verifyModel = workerContext.user?.inspection?.mode_number;
  const langStrings = useCurrentLangStrings();
  const modelOptions = useMemo(() => {
    const modelMap = {
      ...(workerContext.currentSessionInspectionsRequiredData[
        workerContext.user!.inspection!.id
      ]?.enteredModelNumbers ?? {}),
    };
    (workerContext.user?.inspection?.prevModelNumbers || []).forEach(
      (s) => (modelMap[s.toUpperCase()] = true),
    );
    return Object.keys(modelMap);
  }, [
    workerContext.currentSessionInspectionsRequiredData,
    workerContext.user?.inspection,
  ]);
  const [model, setModel] = useState(workerContext.user?.model || "");

  const onEnter = (currentModel?: string) => {
    workerContext.updateUser((u) =>
      u
        ? { ...u, model: currentModel ?? model }
        : { model: currentModel ?? model },
    );
    props.onNext();
  };
  return (
    <SiteFeatureStepLayout
      onNextButtonClick={() => onEnter(model)}
      onBackButtonClick={props.onBack}
      nextButtonText={
        verifyModel ? langStrings.strings.confirmed : langStrings.strings.next
      }
      nextButtonDisabled={
        workerContext.user?.inspection?.model_number_required
          ? !model.trim()
          : false
      }
    >
      <SiteFeatureStepsInputLayout
        headline={
          verifyModel
            ? `${langStrings.strings.identificationNameOrNumber}: ${verifyModel}`
            : langStrings.strings.whatIsBeingInspected
        }
      >
        {verifyModel ? (
          <>
            <div>{langStrings.strings.confirmThisIsCorrectModelNumber}</div>
            <div className="mt-0.5 text-grey">
              {
                langStrings.strings
                  .doesNotMatchConfirmSelectedCorrectInspectionAndInspectingCorrectEquipment
              }
            </div>
          </>
        ) : (
          <>
            <div className="text-grey text-1">
              {langStrings.strings.enterModelNumber}
            </div>
            <AutoComplete
              initialText={model}
              placeholder={langStrings.strings.locationOrModelNumber}
              onTextChange={(text) => setModel(text)}
              initialSelectedOptionValue={model}
              options={modelOptions.map((p) => ({ label: p, value: p }))}
              onSelect={(val) => (val ? setModel(val) : noop())}
            />
          </>
        )}
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};
export default SiteInspectionWorkerStepsModel;
