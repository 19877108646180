import gcProjectDashboardRoute from "./entryRoutes/gcProjectDashboard/gcProjectDashboardRoute";
import gcProjectReportsRoute from "./entryRoutes/gcProjectReports/gcProjectReportsRoute";
import qrProjectReportsRoute from "./entryRoutes/qrProjectReports/qrProjectReportsRoute";
import scReportsRoute from "./entryRoutes/scReports/scReportsRoute";
import scToolboxTalksRoute from "./entryRoutes/scToolboxTalks/scToolboxTalksRoute";

const siteSafetyRoutes = {
  gcProjectDashboard: gcProjectDashboardRoute,
  gcProjectReports: gcProjectReportsRoute,
  qrProjectReports: qrProjectReportsRoute,
  scReports: scReportsRoute,
  scTBTs: scToolboxTalksRoute,
};

export default siteSafetyRoutes;
