import React, { useContext, useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import { useGetOrientationDataQuery$data } from "src/common/types/generated/relay/useGetOrientationDataQuery.graphql";
import { IconCheck } from "@tabler/icons";
import Icon from "src/common/components/general/Icon";
import useOrientationRegisterUser from "../../../../../../../utils/useOrientationRegisterUser";
import validatePhoneNumber from "../../../../../../../../../common/functions/validatePhoneNumber";
import getDigitsString from "../../../../../../../../../common/functions/getDigitsString";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import { Button, Popconfirm } from "antd";
import PhoneNumberInput from "../../../../../../../../../common/components/phoneNumberInput/PhoneNumberInput";
import clsx from "clsx";
import { SafetyCertificateOutlined } from "@ant-design/icons";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

// import "react-international-phone/style.css";

export interface SiteOrientationInPersonWorkerStepsPhoneNumberProps
  extends SiteOrientationStepProps {
  onLoginSuccess?: (workerId: string) => Promise<void>;
  orientations?: useGetOrientationDataQuery$data["orientation_connection"]["edges"];
}

const SiteOrientationInPersonWorkerStepsPhoneNumber: React.FC<
  SiteOrientationInPersonWorkerStepsPhoneNumberProps
> = ({ orientations, onLoginSuccess, ...props }) => {
  const workerContext = useSiteOrientationOrientee();
  const phoneRequired = workerContext.registrationOptions.phoneRequired;

  const [phoneNumber, setPhoneNumber] = useState(
    workerContext.orientee?.phoneNumber || "",
  );

  const [registerUser, loading] = useOrientationRegisterUser({
    orientations,
    onLoginSuccess,
    type: workerContext.type!, //it should be present for this component to work properly
  });
  const phoneNumberIsValid = validatePhoneNumber(phoneNumber);
  const [receiveText, setReceiveText] = useState(
    typeof workerContext.orientee?.toReceiveTextMessage === "boolean"
      ? workerContext.orientee.toReceiveTextMessage
      : true,
  );

  const langStrings = useCurrentLangStrings();

  // We make the Next button disabled even if the phone number is not required, to make the user fill the phone number always, unless they do extra steps to skip it
  const nextDisabled = !phoneNumberIsValid;

  const onEnter = async () => {
    const phoneNumberDigits = getDigitsString(phoneNumber);
    // TODO check if there's no need to update the user
    // TODO update user info
    workerContext.updateOrientee &&
      workerContext.updateOrientee((u) =>
        u
          ? {
              ...u,
              phoneNumber: phoneNumberDigits,
              toReceiveTextMessage: receiveText,
            }
          : {
              phoneNumber: phoneNumberDigits,
              toReceiveTextMessage: receiveText,
            },
      );

    const registered = await registerUser(phoneNumberDigits, receiveText);
    if (registered) props.onNext();
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={nextDisabled}
      loading={loading}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.whatIsYourPhoneNumber}
      >
        <p className=" text-1">
          {langStrings.strings.enter10DigitPhoneNumberWithoutSpace}
        </p>
        <PhoneNumberInput
          value={phoneNumber}
          onChange={setPhoneNumber}
          disabled={loading}
          onEnterPress={() => {
            if (phoneNumberIsValid) {
              onEnter();
            }
          }}
        />
        <div
          className={`flex flex-row items-center gap-0.5 text-static-secondary text-1`}
        >
          <SafetyCertificateOutlined />
          <p>
            {
              langStrings.strings
                .siteFormIsCommittedToYourPrivacyAndWillNotSellYourData
            }
          </p>
        </div>

        <div className="flex items-start text-static-secondary text-1">
          <label style={{ display: "inline-block" }}>
            <input
              type="checkbox"
              style={{ display: "none" }}
              checked={receiveText}
              onChange={(v) => setReceiveText(v.target.checked)}
            />
            {/* Hide the default checkbox */}
            <span
              style={{
                height: "14px",
                width: "17px",
                border: "1px solid grey",
                borderRadius: 3,
                display: "flex",
                marginTop: 4,
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                padding: "1px 2px",
              }}
            >
              {receiveText ? (
                <Icon icon={IconCheck} color="static-secondary" size="small" />
              ) : (
                ""
              )}
            </span>{" "}
            {/* Style the artificial checkbox */}
          </label>
          <div className="ml-0.5">
            {langStrings.strings.yourPhoneNumberMayBeUsed}
          </div>
        </div>

        {!phoneRequired && (
          <Popconfirm
            disabled={!!phoneNumber.length || loading}
            title={langStrings.strings.areYouSureYouMayMissLifeSavingMsgs}
            onConfirm={onEnter}
          >
            <Button
              disabled={!!phoneNumber.length || loading}
              type={`text`}
              className="self-end"
            >
              {langStrings.strings.continueWithoutPhoneNumber}
            </Button>
          </Popconfirm>
        )}
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerStepsPhoneNumber;
