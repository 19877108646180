import React from "react";
import { Form, message, Modal, Select } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { ChangeSubcontractorEmployeeTitleModalMutation } from "src/common/types/generated/relay/ChangeSubcontractorEmployeeTitleModalMutation.graphql";
import { ConnectionHandler } from "relay-runtime";

interface ConfirmAddSubcontractorModalProps {
  modalClose: () => void;
  modalVisible: boolean;
  titleId: string;
  userId: string;
  titleData: {
    id: string;
    name: string;
  }[];
}

const changeSubcontractorEmployeeTitleMutation = graphql`
  mutation ChangeSubcontractorEmployeeTitleModalMutation(
    $id: uuid!
    $set: subcontractor_employee_set_input!
  ) {
    update_subcontractor_employee(
      where: { user_id: { _eq: $id } }
      _set: $set
    ) {
      affected_rows
      returning {
        subcontractor_id
        subcontractor {
          id
          name
        }
        id
        title_id
        employee_title {
          id
          name {
            ...TextTranslationFrag @relay(mask: false)
          }
        }
      }
    }
  }
`;

const ChangeSubcontractorEmployeeTitleModal: React.FC<
  ConfirmAddSubcontractorModalProps
> = ({ modalClose, modalVisible, titleId, userId, titleData }) => {
  // const { data: title_data, loading } =
  //   useGetSubcontractorEmployeeTitlesQuery();

  const [form] = Form.useForm();

  const [updateTitle] =
    useAsyncMutation<ChangeSubcontractorEmployeeTitleModalMutation>(
      changeSubcontractorEmployeeTitleMutation,
    );

  return (
    <>
      <Modal
        open={modalVisible}
        title="Change Sub Admin Title"
        okText="Confirm"
        cancelText="Cancel"
        cancelButtonProps={{ style: { display: "inline" } }}
        onCancel={modalClose}
        onOk={() => {
          form.validateFields().then(async (values) => {
            const changedId = values.titleId;
            await updateTitle({
              variables: {
                id: userId,
                set: {
                  title_id: changedId,
                },
                // projectCrewObjects: [],
              },
              updater: (store) => {
                const returnVal = store.getRootField(
                  "update_subcontractor_employee",
                );
                const conn = ConnectionHandler.getConnection(
                  store.getRoot(),
                  "SCProfileGetSubcontractorUserQuery_user_connection",
                );
                if (conn && returnVal) {
                  const updatedRecords =
                    returnVal.getLinkedRecords("returning");
                  const edge = conn.getLinkedRecords("edges")?.[0];
                  if (edge) {
                    const node = edge.getLinkedRecord("node");
                    if (node) {
                      node.setLinkedRecord(
                        updatedRecords[0],
                        "subcontarctor_employee",
                      );
                    }
                  }
                }
              },
            }).catch((e) => {
              console.log(e);
              message.error("Title Not changed");
            });
            form.resetFields();
          });
          modalClose();
        }}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="titleId"
            label="Sub Admin Title"
            rules={[{ required: true, message: "Choose a title" }]}
          >
            <Select
              optionFilterProp="key"
              showSearch={true}
              style={{ width: "100%" }}
            >
              {titleData?.map((title) =>
                title.id === titleId ? (
                  <Select.Option
                    key={title.id}
                    value={title.id}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#e6f7ff",
                    }}
                  >
                    {title.name.toUpperCase()}
                    <CheckOutlined
                      style={{ float: "right", color: "#2a99fc" }}
                    />
                  </Select.Option>
                ) : (
                  <Select.Option key={title.id} value={title.id}>
                    {title.name.toUpperCase()}
                  </Select.Option>
                ),
              )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ChangeSubcontractorEmployeeTitleModal;
