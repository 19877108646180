import { Descriptions } from "antd";
import React, { FC } from "react";

const { Item } = Descriptions;

interface Props {
  user: {
    name: string;
    username?: string | null;
    phone_number?: string | null;
    email?: string | null;
  };
}

const UserDescriptions: FC<Props> = ({ user }) => {
  return (
    <Descriptions size="middle" title="User Info" bordered column={1}>
      <Item label="Name">
        <b>{user.name}</b>
      </Item>
      <Item label="Username">{user.username}</Item>
      <Item label="Phone number">{user.phone_number ?? ""}</Item>
      <Item label="Email">{user.email ?? ""}</Item>
    </Descriptions>
  );
};

export default UserDescriptions;
