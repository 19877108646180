import { IconTrash } from "@tabler/icons";
import { Space, Button, Input, Table, Select, Popover, message } from "antd";
import { memo, useMemo } from "react";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import Icon from "src/common/components/general/Icon";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import useAuthUser from "src/common/hooks/useAuthUser";
import useColumnOrderBy from "src/common/hooks/useColumnOrderBy";
import useColumnSearch from "src/common/hooks/useColumnSearch";
import usePaginatedQuery, {
  UsePaginatedQueryParams,
} from "src/common/hooks/usePaginatedQuery";
import {
  Project_Subcontractor_Order_By,
  Order_By,
  GetCsrProjectSubsQueryVariables,
  Project_Subcontractor_Bool_Exp,
  GetCsrProjectSubsDocument,
  useCsrUpdateSubMutation,
  GetCsrProjectSubsQuery,
  useDeleteProjectSubcontractorMutation,
} from "src/common/types/generated/apollo/graphQLTypes";

const SelectTrade = memo(
  ({
    value,
    sub,
    options,
  }: {
    value: string | undefined | null;
    options: Array<{ label: string; value: string }>;
    sub: { name: string; id: string };
  }) => {
    const authUser = useAuthUser();
    const [updateSub, { loading }] = useCsrUpdateSubMutation();
    return (
      <Select
        className="w-24"
        value={value}
        options={options}
        onChange={(val) => {
          const _set = { trade_id: val };
          updateSub({
            variables: {
              _set,
              subId: sub.id,
              csrEdits: [
                {
                  change_log: [{ _set, prevVal: value }],
                  csr_user_id: authUser.uid,
                  edit_text: `changed subcontractor trade for ${sub.name}`,
                  entity_id: sub.id,
                  name: "subcontractor",
                  operation_name: "update",
                },
              ],
            },
            optimisticResponse: {
              insert_csr_edit: { affected_rows: 1 },
              update_subcontractor_by_pk: {
                __typename: "subcontractor",
                id: sub.id,
                trade_id: val,
                name: sub.name,
              },
            },
          });
        }}
        loading={loading}
      />
    );
  },
);
const CsrProjectSubs: React.FunctionComponent<{
  projectId: string;
  tradeOptions: Array<{ label: string; value: string }>;
}> = ({ projectId, tradeOptions }) => {
  const { orderBy, setOrderBy } =
    useColumnOrderBy<Project_Subcontractor_Order_By>({
      subcontractor: {
        name: Order_By.Asc,
      },
    });
  const [deleteProjectSub, { loading: deleting }] =
    useDeleteProjectSubcontractorMutation();
  const nameSearch = useColumnSearch();
  const queryVariables = useMemo(
    (): UsePaginatedQueryParams<
      Project_Subcontractor_Bool_Exp,
      GetCsrProjectSubsQueryVariables
    > => ({
      where: {
        project_id: { _eq: projectId },
        subcontractor: {
          name: {
            _ilike: nameSearch.value,
          },
        },
      },
      extraQueryVariables: { projectId },
      orderBy: orderBy,
      queryDoc: GetCsrProjectSubsDocument,
      dataIndex: "project_subcontractor",
      aggregateCountIndex: "project_subcontractor_aggregate",
    }),
    [nameSearch.value, orderBy],
  );
  const authUser = useAuthUser();
  const [projectSubs, { loading, page, setPage, count, pageSize, refetch }] =
    usePaginatedQuery<
      GetCsrProjectSubsQuery["project_subcontractor"][number],
      GetCsrProjectSubsQuery
    >(queryVariables);

  return (
    <BaseTable<GetCsrProjectSubsQuery["project_subcontractor"][number]>
      loading={loading}
      columns={[
        {
          title: "Subcontractor Name",
          key: "name",
          dataIndex: ["subcontractor", "name"],
        },
        {
          title: "Trade",
          key: "trade",
          dataIndex: ["subcontractor", "trade_id"],
          render: (v, r) => {
            return (
              <SelectTrade
                value={v}
                sub={r.subcontractor}
                options={tradeOptions}
              />
            );
          },
        },
        {
          title: "Remove",
          key: "remove",
          width: "20%",
          dataIndex: ["remove"],
          render: (_, r) => {
            return (
              <BPopconfirm
                okButtonProps={{ loading: deleting }}
                title={
                  <div>
                    Are you sure? This action is irreversible and may cause
                    <br />
                    issues
                  </div>
                }
                onConfirm={async () => {
                  const ss = await deleteProjectSub({
                    variables: {
                      id: r.id,
                      csrEdits: [
                        {
                          change_log: [{ id: r.id }],
                          csr_user_id: authUser.uid,
                          edit_text: `deleted project subcontractor relation for ${r.subcontractor.name}`,
                          entity_id: r.id,
                          name: "project_subcontractor",
                          operation_name: "delete",
                        },
                      ],
                    },
                  });

                  if (ss.data?.delete_project_subcontractor_by_pk?.id) {
                    message.success(
                      `${r.subcontractor.name} removed from project successfully`,
                    );
                    refetch();
                  } else {
                    message.warning(
                      `${r.subcontractor.name} could not be removed from project because of related data to observations, tasks, reports,daily logs/reports, workers,  `,
                    );
                  }
                }}
              >
                <Icon icon={IconTrash} color="negative" />{" "}
              </BPopconfirm>
            );
          },
        },
      ]}
      sortColumns={{
        name: true,
        workers: true,
      }}
      onChange={(_: any, filters: any, sorter: any) => {
        setOrderBy(sorter);
      }}
      title={() => (
        <Space direction="vertical">
          <h3>Subcontractors</h3>
          <Input
            type="primary"
            style={{ margin: "10px", width: "200px" }}
            placeholder={"Search"}
            onChange={(event) => {
              nameSearch.setter(event.target.value);
            }}
          ></Input>
        </Space>
      )}
      searchColumns={{ name: nameSearch.setter }}
      dataSource={projectSubs}
      pagination={{
        current: page,
        pageSize,
        total: count,
        onChange: setPage,
      }}
    />
  );
};
export default CsrProjectSubs;
