import React, { FC, useState } from "react";
import styled, { StyledComponent } from "styled-components";
import { GetSubctonractorProjectPricesQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { Button, Typography } from "antd";
import { formatCurrency } from "../../../../../../common/components/tables/PriceSelector";
import BaseTable, {
  BaseTableProps,
} from "../../../../../../common/components/tables/basic/BaseTable";
import { stripeDashboardLink } from "src/common/functions/stripeDashboardUrl";

const StripeIdStyle: React.CSSProperties = {
  fontSize: "14px",
  borderRadius: "5px",
  boxShadow: "rgb(235, 238, 241) 0px 0px 0px 1px inset",
  padding: "2px 5px",
  backgroundColor: "rgb(246, 248, 250)",
  fill: "rgb(84, 89, 105)",
  color: "rgb(65, 69, 82)",
};

type SubProjectRecord = NonNullable<
  GetSubctonractorProjectPricesQuery["subcontractor_by_pk"]
>["subcontractor_projects"][number];
type ProjectRecord = SubProjectRecord["project"];

export function getProjectSubBillingSettings(project: ProjectRecord) {
  const gc_settings = project.general_contractor.billing_settings;
  const proj_level = project.billing_settings?.use_project_level_price;
  const settings = proj_level ? project.billing_settings : gc_settings;
  return settings;
}

export function getProjectPriceDescription(project: ProjectRecord) {
  const settings = getProjectSubBillingSettings(project);
  if (!settings) {
    return "Billing is not enabled";
  }
  switch (settings.payment_model) {
    case "gc":
      return "Billed to GC";
    case "free":
      return "Free";
    case "sub":
      {
        switch (settings.sub_price_type) {
          case "per_worker":
            return `Per user: ${formatCurrency(
              settings.sub_worker_price_first ?? 0
            )} USD - first, ${formatCurrency(
              settings.sub_worker_price_others ?? 0
            )} USD - next`;
          case "fixed_per_project":
            return `Fixed per project: ${formatCurrency(
              settings.sub_project_fixed_price ?? 0
            )} USD`;
          case "free":
            return "Free";
          default:
            return `Unknown sub_price_type: ${settings.sub_price_type}`;
        }
      }
      break;
    default:
      return `Unknown payment model: ${settings.payment_model}`;
  }
}

type Props = Omit<BaseTableProps<Partial<SubProjectRecord>>, "columns"> & {
  onCreateCustomer: (project: SubProjectRecord) => Promise<void>;
};

type SubcontractorProjectPricingTable = FC<Props>;

const SubcontractorProjectPricingTable = styled(BaseTable).attrs(
  ({ pagination, skipKeys, onCreateCustomer, ...props }: Props) => {
    const [actionRunning, setActionRunning] = useState(false);
    const columns = [
      {
        title: "Project name",
        key: "name",
        dataIndex: ["name"],
        render: (_: any, item: SubProjectRecord) => (
          <a href={`/admin/data/projects/${item.project.id}#billing`}>
            {item.project.name}
          </a>
        ),
      },
      {
        title: "Pricing",
        key: "pricing",
        render: (_: any, item: SubProjectRecord) => {
          const project = item.project;
          const settings = getProjectSubBillingSettings(project);
          if (!settings) {
            return (
              <a href={"/admin/data/gcs/" + project.general_contractor.id}>
                <Typography.Text type="danger">
                  Not enabled on GC level
                </Typography.Text>
              </a>
            );
          }
          return <div>{getProjectPriceDescription(project)}</div>;
        },
      },
      {
        title: "Price defined at",
        key: "level",
        render: (_: any, item: SubProjectRecord) => {
          const project = item.project;
          const gc_settings = project.general_contractor.billing_settings;
          const proj_level = project.billing_settings?.use_project_level_price;
          const settings = proj_level ? project.billing_settings : gc_settings;
          if (!settings) {
            return (
              <a
                href={`/admin/data/gcs/${project.general_contractor.id}#billing`}
              >
                <Typography.Text type="danger">
                  GC ({project.general_contractor.name})
                </Typography.Text>
              </a>
            );
          }
          if (proj_level) {
            return (
              <a href={`/admin/data/projects/${project.id}#billing`}>
                <div>Project specific</div>
              </a>
            );
          } else {
            return (
              <a
                href={`/admin/data/gcs/${project.general_contractor.id}#billing`}
              >
                <div>GC ({project.general_contractor.name})</div>
              </a>
            );
          }
        },
      },
      {
        title: "Stripe customer",
        key: "customer",
        render: (_: any, item: SubProjectRecord) => {
          const customer_id =
            item.billing_settings?.stripe_account?.customer_id;
          if (customer_id) {
            return (
              <span style={StripeIdStyle}>
                <a
                  target="_blank"
                  href={`${stripeDashboardLink}/customers/${customer_id}`}
                >
                  {customer_id}
                </a>
              </span>
            );
          }
          return (
            onCreateCustomer && (
              <Button
                loading={actionRunning}
                onClick={() => {
                  setActionRunning(true);
                  onCreateCustomer(item).finally(() => setActionRunning(false));
                }}
              >
                Create Stripe Customer
              </Button>
            )
          );
        },
      },
    ].filter(({ key }) => !(skipKeys ?? []).includes(key));

    return {
      ...props,
      columns,
      rowKey: (w: any) => w.id,
      summary: () => (
        <tr>
          <th>Total</th>
          <td colSpan={columns.length - 1}>
            {!!pagination && pagination.total}
          </td>
        </tr>
      ),
    };
  }
)``;

export default SubcontractorProjectPricingTable as StyledComponent<
  SubcontractorProjectPricingTable,
  any,
  Props,
  never
>;
