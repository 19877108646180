import React, { useRef, useState } from "react";
import hexToRgba from "src/common/functions/hexToRgba";
import { useHover } from "usehooks-ts";
import { SubBlockType } from "../../utilities/sitedeliveryTypes";
import SubBlockEditModal from "../SubBlockEditModal";
import TimeRangeSpan from "./TimeRangeSpan";

type SitedeliveryCalendarEVentSubBlockType = SubBlockType & {
  colorHex: string;
};

const SitedeliveryCalendarEventSubBlock: React.FC<
  SitedeliveryCalendarEVentSubBlockType
> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const ref = useRef(null);
  const hover = useHover(ref);

  return (
    <>
      <SubBlockEditModal
        subBlock={props}
        modalClose={() => {
          setVisible(false);
        }}
        modalVisible={visible}
      />
      <div className="relative h-full w-full px-0.25">
        <div
          ref={ref}
          className="bg-white relative h-full w-full rounded-0.25 p-0.5 border cursor-pointer hover:border-interactive-primary transition-colors"
          onClick={() => {
            setVisible(true);
          }}
          style={{ borderColor: hexToRgba(props.colorHex, hover ? 1 : 0.5) }}
        >
          <div
            className="absolute inset-0 z-20 w-full h-full pointer-events-none"
            style={{ backgroundColor: hexToRgba(props.colorHex, 0.05) }}
          ></div>

          <div className="flex flex-col justify-center h-full overflow-hidden">
            <div className="flex flex-col items-center justify-start w-full max-h-full text-center">
              <span className="text-0.75 text-static-secondary">
                <span className="text-0.75 text-static-primary block font-accent">{`${props.subcontractorExpected.title}`}</span>
                {"Sub block"}
              </span>
              <TimeRangeSpan from={props.from} to={props.to} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SitedeliveryCalendarEventSubBlock;
