import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import CustomSuspense from "../general/CustomSuspense";
import NavigationMenu, {
  NavigationMenuItems,
  UtilityMenuItems,
} from "../NavigationMenu";
import NavigationPlaceholder from "../NavigationPlaceholder";

export type NavigationMenuProps = {
  navigationItems: NavigationMenuItems;
  utilityItems?: UtilityMenuItems;
};

interface NavigationWrapperProps {
  menu: NavigationMenuProps;
}

const NavigationWrapper: React.FunctionComponent<
  PropsWithChildren<NavigationWrapperProps>
> = (props) => {
  const [menuHovered, setMenuHovered] = useState(false);
  const [fixedWidth, setFixedWidth] = useState<string>(`100%`);
  const resizeRef = useRef<HTMLDivElement>(null);

  const handleMenuMouseEnter = () => {
    setMenuHovered(true);
  };
  const handleMenuMouseLeave = () => {
    setMenuHovered(false);
  };

  const resize = () => {
    if (resizeRef.current) {
      setFixedWidth(resizeRef.current.getBoundingClientRect().width + "px");
    }
  };

  useEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <div
      className={`flex flex-row items-center w-full h-full bg-suplementary-1`}
    >
      <NavigationMenu
        {...{
          navigationItems: props.menu.navigationItems,
          utilityItems: props.menu.utilityItems,
          onMouseEnter: handleMenuMouseEnter,
          onMouseLeave: handleMenuMouseLeave,
          expanded: menuHovered,
        }}
      />

      <div className={`h-full w-full flex-1 overflow-hidden relative`}>
        <div
          className="absolute inset-0 left-0 invisible block w-full h-full"
          ref={resizeRef}
        ></div>
        <div
          className={`h-full flex w-full items-start absolute left-0 top-0`}
          style={{ width: fixedWidth }}
        >
          <CustomSuspense>
            {props.children ?? <NavigationPlaceholder />}
          </CustomSuspense>
        </div>
      </div>
    </div>
  );
};

export default NavigationWrapper;
