import { Popconfirm, Typography } from "antd";
import React from "react";
import TableFooterTotal from "src/common/components/tables/TableFooterTotal";
import BaseTable, {
  BaseTableProps,
} from "src/common/components/tables/basic/BaseTable";
import noPropagation from "src/common/functions/noPropagation";
import { Awareness_Question } from "src/common/types/generated/apollo/graphQLTypes";
import styled from "styled-components";

// TODO add this to tables
type DataProp = Awareness_Question;

type Props = Omit<BaseTableProps<Partial<DataProp>>, "columns"> & {
  onDelete?: (r: DataProp) => any;
};
type Columns = (p: Props) => JSX.Element;

const Columns = styled(BaseTable).attrs(
  ({ pagination, onDelete, ...props }: Props) => {
    const columns: any = [
      {
        title: "Number",
        key: "number",
        render: (_: any, __: any, i: number) => i + 1,
      },
      {
        title: "Question",
        key: "question",
        dataIndex: ["question", "clientText"],
      },
    ];

    if (onDelete) {
      columns.push({
        title: "",
        key: "remove",
        render: (_: any, permit: DataProp) => (
          <Popconfirm
            title="Are you sure?"
            onConfirm={noPropagation(() => {
              if (onDelete) onDelete(permit);
            })}
            onCancel={noPropagation()}
            okText="Yes"
            cancelText="Cancel"
          >
            <a onClick={noPropagation()}>
              <Typography.Text type="danger">Remove</Typography.Text>
            </a>
          </Popconfirm>
        ),
      });
    }

    return {
      ...props,
      columns,
      rowKey: (w: any) => w.id,
      summary: () =>
        pagination && (
          <TableFooterTotal cols={columns.length} total={pagination.total} />
        ),
    };
  }
)``;

export default Columns as Columns;
