import { IconFileCheck } from "@tabler/icons";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FunctionComponent, useRef } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useParams } from "react-router-dom";
import Icon from "src/common/components/general/Icon";
import { QuizCompletionPageQuery } from "src/common/types/generated/relay/QuizCompletionPageQuery.graphql";

const QuizCompletionPage: FunctionComponent = () => {
  const { projectId, userId } = useParams() as {
    projectId: string;
    userId: string;
  };
  const data = useLazyLoadQuery<QuizCompletionPageQuery>(
    graphql`
      query QuizCompletionPageQuery(
        $projectId: uuid!
        $userId: uuid!
        $time: timestamptz!
      ) {
        project_connection(where: { id: { _eq: $projectId } }) {
          edges {
            node {
              agc_universal_orientation
            }
          }
        }
        orientation_connection(
          where: {
            deleted_at: { _is_null: true }
            slides: {
              content_type: { _eq: "question" }
              deleted_at: { _is_null: true }
              archived_at: { _is_null: true }
            }
            _and: [
              {
                _or: [
                  {
                    general_contractor_id: { _is_null: true }
                    _and: [
                      {
                        project_orientations: {
                          project_id: { _is_null: true }
                          required_by_all_workers: { _eq: true }
                        }
                      }
                      {
                        project_orientations: {
                          project_id: { _eq: $projectId }
                          hide_but_give_credit: { _eq: true }
                        }
                      }
                    ]
                  }
                  {
                    general_contractor: {
                      projects: { id: { _eq: $projectId } }
                    }
                    project_orientations: {
                      required_by_all_workers: { _eq: true }
                      hide_but_give_credit: { _eq: true }
                      project_id: { _eq: $projectId }
                    }
                  }
                ]
              }
              {
                _or: [
                  { project_id: { _is_null: true } }
                  {
                    project: {
                      linked_orientation_projects: { id: { _eq: $projectId } }
                    }
                  }
                ]
              }
            ]
          }
          order_by: [{ order: asc }, { name: asc }]
        ) {
          edges {
            node {
              pk: id @__clientField(handle: "pk")
              type
              name
              duration_valid
              slides(
                where: {
                  content_type: { _eq: "question" }
                  deleted_at: { _is_null: true }
                  archived_at: { _is_null: true }
                }
                order_by: { order: asc }
              ) {
                id
                pk: id @__clientField(handle: "pk")
                orientation_id
                viewed_by(
                  where: { user_id: { _eq: $userId } }
                  order_by: { created_at: desc }
                ) {
                  created_at
                  id
                }
              }
              orientation_results(
                where: {
                  user_id: { _eq: $userId }
                  signature_image_id: { _is_null: false }
                  _or: [
                    { expired_at: { _is_null: true } }
                    { expired_at: { _gte: $time } }
                  ]
                }
                order_by: { completed_at: desc }
              ) {
                pk: id @__clientField(handle: "pk")
                id
                expired_at
                completed_at
                created_at
                orientation_id
                orientation {
                  duration_valid
                }
                group_id
                quiz_results {
                  id
                  answer
                  orientation_result_id
                  orientation_slide_id
                }
              }
            }
          }
        }
        project_worker_connection(
          where: {
            project: { orientation_project_id: { _eq: $projectId } }
            worker_id: { _eq: $userId }
            subcontractor_worker: {}
          }
        ) {
          edges {
            node {
              user {
                name
              }
              project {
                name
              }
            }
          }
        }
        project_employee_connection(
          where: {
            project: { orientation_project_id: { _eq: $projectId } }
            employee_id: { _eq: $userId }
          }
        ) {
          edges {
            node {
              employee {
                user {
                  name
                }
              }
              project {
                name
              }
            }
          }
        }
      }
    `,
    { projectId, userId, time: dayjs().endOf("day").format() },
  );
  const orientations = data.orientation_connection.edges
    .filter((o) =>
      data.project_connection.edges[0].node.agc_universal_orientation
        ? true
        : o.node.type !== "universal",
    )
    .map((e) => e.node);
  const slideAnswers = useRef<{
    [key: string]: string | undefined;
  }>({}); // slide_id and answer
  orientations.forEach((o) => {
    o.orientation_results
      .filter(
        (p) =>
          !p.expired_at ||
          dayjs(p.expired_at).isSameOrAfter(
            dayjs(p.completed_at ?? p.created_at).add(
              o.duration_valid ?? 1, // added ?? 1, so that if a quiz result is expiring in a month user can now answer it again to update the quiz result
              "months",
            ),
          ),
      )
      .forEach((r) => {
        r.quiz_results.forEach((q) => {
          slideAnswers.current[q.orientation_slide_id] = q.answer ?? undefined;
        });
      });
  });
  let latestViewDate = useRef<dayjs.Dayjs | undefined>(undefined);
  const allViewedAndAnswered = orientations.every((o) =>
    o.slides.every((slide) => {
      if (slide.viewed_by[0] && slideAnswers.current[slide.pk]) {
        latestViewDate.current = latestViewDate.current
          ? dayjs(slide.viewed_by[0].created_at)
          : undefined;
        return dayjs().isSameOrBefore(
          dayjs(slide.viewed_by[0].created_at).add(
            o.duration_valid ?? 1,
            "months",
          ),
        );
      } else return false;
    }),
  );

  const project_worker = data.project_worker_connection.edges[0]?.node;
  const project_employee = data.project_employee_connection.edges[0]?.node;
  if (!(project_worker || project_employee))
    return <div>Project Worker not found</div>;
  return (
    <div className="text-center p-2">
      {allViewedAndAnswered ? (
        <div>
          <h1 className="p-0.75 text-1.25">
            {(project_worker || project_employee).project.name}
          </h1>
          <div className="p-0.75 text-1.25">Thank You</div>
          <div className="p-0.75 text-1.5">
            {project_worker?.user?.name ?? project_employee.employee.user.name}
          </div>
          <div className="p-0.75 text-1.5">Quiz Completed</div>
          <div className="p-0.75 ">
            {latestViewDate.current
              ? latestViewDate.current.format("MM/DD/YYYY")
              : dayjs().format("MM/DD/YYYY")}
          </div>
          <div className="items-center flex justify-center">
            <Icon icon={IconFileCheck} size="full" color="interactive" />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default QuizCompletionPage;
