import { IconCirclePlus, IconInfoSquare } from "@tabler/icons";
import {
  Button,
  Form,
  Popconfirm,
  Select,
  Space,
  Tag,
  message,
  notification,
} from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import DataDetailDescriptions, {
  DataDetailItems,
} from "src/common/components/layouts/DataDetailDescriptions";
import StyledContent from "src/common/components/layouts/StyledContent";
import CustomTable from "src/common/components/tables/basic/CustomTable";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import getAddressText from "src/common/functions/getAddressText";
import noPropagation from "src/common/functions/noPropagation";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import { GCSubcontractorDetail_ChangeProjectSubWorkerTitle_Mutation } from "src/common/types/generated/relay/GCSubcontractorDetail_ChangeProjectSubWorkerTitle_Mutation.graphql";
import {
  GCSubcontractorDetail_updateProjectSubcontractorEmployeeMutation,
  GCSubcontractorDetail_updateProjectSubcontractorEmployeeMutation$data,
} from "src/common/types/generated/relay/GCSubcontractorDetail_updateProjectSubcontractorEmployeeMutation.graphql";
import { GCSubcontractorQuery$data } from "src/common/types/generated/relay/GCSubcontractorQuery.graphql";
import { ProjectSubcontractorEmployeeFrag$data } from "src/common/types/generated/relay/ProjectSubcontractorEmployeeFrag.graphql";
import AddSubcontractorProjectTeamModal from "src/domain-features/sitesafety/siteSafetyPlan/components/AddSubcontractorProjectTeamModal";
import ProjectSubcontractorEmployeeTable, {
  ProjectSubcontractorEmployee,
} from "src/domain-features/sitesafety/siteSafetyPlan/components/ProjectSubcontractorEmployeeTable";
import GCSendInviteModal, {
  GCSendInviteModalRef,
} from "src/utility-features/invitations/GCSendInviteModal";
import sendInvite from "src/utility-features/invitations/sendInvite";
import getNormalOptionsFilter from "src/common/functions/getNormalOptionsFilter";

const updateProjectSubWorkrerTitleMutation = graphql`
  mutation GCSubcontractorDetail_ChangeProjectSubWorkerTitle_Mutation(
    $toDeleteWhere: project_subcontractor_worker_title_bool_exp!
    $objects: [project_subcontractor_worker_title_insert_input!]!
  ) {
    delete_project_subcontractor_worker_title(where: $toDeleteWhere) {
      affected_rows
    }
    insert_project_subcontractor_worker_title(objects: $objects) {
      returning {
        id
        project_workers_aggregate(
          where: { is_last: { _eq: true }, deleted_at: { _is_null: true } }
        ) {
          aggregate {
            count
          }
        }
        worker_title {
          id
          translation {
            en
            id
          }
        }
        worker_title_id
      }
    }
  }
`;
const GCSubcontractorDetail: FC<{
  data: GCSubcontractorQuery$data;
  subcontractorId: string;
  projectId: string;
}> = ({ data, subcontractorId, projectId }) => {
  const [updateProjectSubcontractorEmployee, loading] =
    useAsyncMutation<GCSubcontractorDetail_updateProjectSubcontractorEmployeeMutation>(
      graphql`
        mutation GCSubcontractorDetail_updateProjectSubcontractorEmployeeMutation(
          $_set: project_subcontractor_employee_set_input
          $where: project_subcontractor_employee_bool_exp!
        ) {
          update_project_subcontractor_employee(_set: $_set, where: $where) {
            affected_rows
          }
        }
      `,
    );
  const navigate = useNavigate();
  const subcontractorData = data.subcontractor_connection.edges[0].node;
  const [changeProjectSubWorkerTitle] =
    useAsyncMutation<GCSubcontractorDetail_ChangeProjectSubWorkerTitle_Mutation>(
      updateProjectSubWorkrerTitleMutation,
    );

  const sendInviteRef = useRef<GCSendInviteModalRef>(null);
  const subcontractorItems: DataDetailItems = [
    {
      label: "Name",
      value: subcontractorData.name,
      span: 2,
    },
    {
      label: "Company Trade",
      value: subcontractorData.trade?.name ?? "",
    },
    {
      label: "Address",
      value: getAddressText(subcontractorData.address),
    },
  ];

  const projectSubcontractorEmployeeTableRef = useRef<DataScrollTableRef>(null);
  const [
    openAddSubcontractorProjectTeamModal,
    setOpenAddSubcontractorProjectTeamModal,
  ] = useState(false);

  const updateProjectSubcontractorEmployeeUpdater: (
    store: RecordSourceSelectorProxy<GCSubcontractorDetail_updateProjectSubcontractorEmployeeMutation$data>,
    id: string,
    markAsValid: boolean,
  ) => void = (store, id, markAsValid) => {
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "ProjectSubcontractorEmployeeTable_project_subcontractor_employee_connection",
    );
    if (conn) {
      const edges = conn.getLinkedRecords("edges") || [];
      edges.forEach((edge) => {
        const node = edge.getLinkedRecord("node");
        if (!node) {
          return;
        }

        if (node.getValue("id") == id && markAsValid) {
          node.setValue(true, "emergency_contact");
        } else if (!markAsValid) {
          node.setValue(false, "emergency_contact");
        }
      });
      conn.setLinkedRecords(edges, "edges");
    }
  };

  const makeEmergencyContact = async (
    projectSubcontractorEmployee: ProjectSubcontractorEmployee,
  ) => {
    Promise.all([
      updateProjectSubcontractorEmployee({
        variables: {
          _set: { emergency_contact: false },
          where: { emergency_contact: { _eq: true } },
        },
        updater: (store) => {
          updateProjectSubcontractorEmployeeUpdater(
            store,
            projectSubcontractorEmployee.id,
            false,
          );
        },
      }),
      updateProjectSubcontractorEmployee({
        variables: {
          _set: { emergency_contact: true },
          where: { id: { _eq: projectSubcontractorEmployee.pk } },
        },
        updater: (store) => {
          updateProjectSubcontractorEmployeeUpdater(
            store,
            projectSubcontractorEmployee.id,
            true,
          );
        },
      }),
    ])
      .then((d) => {
        message.success("Emergency Contact Updated");
      })
      .catch((error) => {
        message.error("Unable to update Emergency Contact");
      });
  };

  const [editTrade, setEditTrade] = useState(false);
  const resendInvite = async (
    u:
      | GCSubcontractorQuery$data["subcontractor_connection"]["edges"][0]["node"]["subcontractor_employees"][number]
      | ProjectSubcontractorEmployeeFrag$data["subcontractor_employee"],
  ) => {
    console.log(u);

    const sentFromName = data.user_connection.edges[0].node.name;
    const sentFromCompanyName =
      data.user_connection.edges[0].node.employee?.general_contractor.name;
    const projectName =
      data.user_connection.edges[0].node.employee?.employee_projects[0]?.project
        ?.name;
    const joinGeneralContractorId =
      data.user_connection.edges[0].node.employee?.general_contractor.pk;
    if (u.user.email) {
      const { success, error } = await sendInvite({
        sendTo: u.user.email,
        joinSubcontractorId: subcontractorId,
        sentFromCompanyName,
        sentFromName,
        projectName,
        joinGeneralContractorId,
        joinProjectId: projectId,
        joinRole: "sub-admin",
      });
      if (success == false) {
        notification.error({
          message: "Error: ",
          description: error,
          duration: null,
        });
      } else {
        message.success("Invite sent");
      }
    }
  };
  return (
    <StyledContent padding backgroundColor="white">
      <div className="w-full">
        <GCSendInviteModal
          ref={sendInviteRef}
          projectId={projectId}
          subcontractorId={subcontractorId}
        />
        <div className="flex">
          <DataDetailDescriptions
            className="w-1/2"
            title="Subcontractor Info"
            items={subcontractorItems}
          />
          <div className="1/2 mt-1.5">
            <DataDetailDescriptions
              title={" "}
              items={[
                {
                  label: "Worker’s Trade(s)",
                  iconProps: {
                    icon: IconInfoSquare,
                    color: "interactive",
                    hoverContent: {
                      content: (
                        <div>
                          Add Pre-Selected trades for this subcontractor.
                          Instead
                          <br />
                          of searching the entire list of Trades during
                          <br />
                          Orientation registration, workers see these trades
                          <br />
                          first. Workers can still select from the entire list.
                          <br />
                          <br />
                          Any trades that workers select are also shown here.
                        </div>
                      ),
                      title: "HELP EXPEDITE THE SPEED WORKERS REGISTER",
                    },
                  },
                  value: (
                    <>
                      {editTrade ? (
                        <Form
                          onFinish={async (values: {
                            newTrades: Array<string>;
                          }) => {
                            if (editTrade) {
                              changeProjectSubWorkerTitle({
                                variables: {
                                  objects: values.newTrades.map((t) => ({
                                    project_id: projectId,
                                    subcontractor_id: subcontractorId,
                                    worker_title_id: t,
                                  })),
                                  toDeleteWhere: {
                                    project_id: { _eq: projectId },
                                    subcontractor_id: {
                                      _eq: subcontractorId,
                                    },
                                  },
                                },
                                updater: (store) => {
                                  const results = store.getRootField(
                                    "insert_project_subcontractor_worker_title",
                                  );
                                  const conn = ConnectionHandler.getConnection(
                                    store.getRoot(),
                                    "GCSubcontractorQuery_subcontractor_connection",
                                  );
                                  if (conn && results) {
                                    const subEdge =
                                      conn.getLinkedRecords("edges");
                                    if (subEdge?.length) {
                                      const node =
                                        subEdge[0].getLinkedRecord("node");
                                      if (node)
                                        node.setLinkedRecords(
                                          results.getLinkedRecords("returning"),
                                          "subcontractor_project_worker_titles",
                                          {
                                            where: {
                                              project_id: { _eq: projectId },
                                            },
                                            order_by: {
                                              worker_title: {
                                                translation: {
                                                  en: Order_By.Asc,
                                                },
                                              },
                                            },
                                          },
                                        );
                                    }
                                  }
                                },
                              }).catch((e) =>
                                notification.error({
                                  message: "Selection Error",
                                  description: e.message,
                                }),
                              );
                              setEditTrade(false);
                            }
                          }}
                          initialValues={{
                            newTrades:
                              subcontractorData.subcontractor_project_worker_titles.map(
                                (p) => p.worker_title_id,
                              ),
                          }}
                        >
                          <Form.Item name="newTrades">
                            <Select
                              mode="multiple"
                              className="w-20"
                              showSearch
                              filterOption={getNormalOptionsFilter}
                              options={data.worker_title_connection.edges.map(
                                (wt) => ({
                                  value: wt.node.pk,
                                  label: wt.node.translation.en,
                                  disabled:
                                    (subcontractorData.subcontractor_project_worker_titles.find(
                                      (p) => p.worker_title_id === wt.node.pk,
                                    )?.project_workers_aggregate?.aggregate
                                      ?.count ?? 0) > 0,
                                }),
                              )}
                            />
                          </Form.Item>
                          <Button
                            htmlType="submit"
                            type="primary"
                            size="small"
                            className="rounded-2 font-accent"
                          >
                            {editTrade ? "Done" : "+ Trades"}
                          </Button>
                        </Form>
                      ) : (
                        <>
                          {subcontractorData.subcontractor_project_worker_titles.map(
                            (p) => (
                              <Tag
                                key={p.worker_title_id}
                                className="bg-suplementary-3 text-semantic-pending-dark rounded-2"
                              >
                                {p.worker_title.translation.en}
                              </Tag>
                            ),
                          )}
                          <Button
                            onClick={async () => setEditTrade(true)}
                            type="primary"
                            size="small"
                            className="rounded-2 font-accent"
                          >
                            + Trades
                          </Button>
                        </>
                      )}
                    </>
                  ),
                },
              ]}
            />
          </div>
        </div>
        <Space>
          <Button
            type="link"
            onClick={() =>
              navigate(
                `/gce/projects/${projectId}/reports/pretaskplans?subId=${subcontractorId}`,
              )
            }
          >
            Safety Reports
          </Button>
          <Button
            type="link"
            onClick={() =>
              navigate(
                `/gce/projects/${projectId}/reports/daily?subId=${subcontractorId}`,
              )
            }
          >
            Daily Reports
          </Button>
          <Button
            type="link"
            onClick={() =>
              navigate(
                `/gce/projects/${projectId}/reports/toolboxtalks?subId=${subcontractorId}`,
              )
            }
          >
            Toolbox Talks
          </Button>
        </Space>

        <AddSubcontractorProjectTeamModal
          modalClose={() => {
            setOpenAddSubcontractorProjectTeamModal(false);
          }}
          modalVisible={openAddSubcontractorProjectTeamModal}
          subcontractorId={subcontractorId}
          projectId={projectId}
          onSubmit={() => {
            setOpenAddSubcontractorProjectTeamModal(false);
            projectSubcontractorEmployeeTableRef.current?.refetch();
          }}
        />
        <div className="mt-2">
          <ProjectSubcontractorEmployeeTable
            title="Project Contact List"
            ref={projectSubcontractorEmployeeTableRef}
            headerComponent={
              <>
                <br />
                {
                  " Add all of your company’s points of contact (POC) for this project and select the Emergency Contact. Think General Superintendent, PM, Safety Manager, etc. DO NOT ADD WORKERS, CREW LEADS, OR FOREPERSONS (see the next section)"
                }
              </>
            }
            where={{
              project_id: { _eq: projectId },
              subcontractor_employee: {
                subcontractor_id: { _eq: subcontractorId },
              },
            }}
            loading={loading}
            topBarButtons={[
              {
                onClick: () => {
                  setOpenAddSubcontractorProjectTeamModal(true);
                },
                label: "+ Add",
              },
            ]}
            extraColumns={[
              {
                title: "Title",
                dataIndex: [
                  "subcontractor_employee",
                  "employee_title",
                  "name_text",
                ],
                key: "subcontractorEmployeeTitle",
                searchDataIndex: [
                  "subcontractor_employee.employee_title.name_text",
                ],
                size: "sm",
                sortable: true,
              },
              {
                title: "Email",
                dataIndex: ["subcontractor_employee", "user", "email"],
                key: "email",
                defaultSortOrder: "asc",
                searchDataIndex: ["subcontractor_employee", "user", "email"],
                sortable: true,
                size: "sm",
              },
              {
                title: "",
                dataIndex: [""],
                size: "md",
                key: "emergencyContact",
                render: (_, projectSubcontractorEmployee) => (
                  <>
                    <Space>
                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={noPropagation(() => {
                          makeEmergencyContact(projectSubcontractorEmployee);
                        })}
                        onCancel={noPropagation()}
                        okText="Yes"
                        cancelText="Cancel"
                      >
                        <Button type="link" onClick={noPropagation()}>
                          Make Emergency Contact
                        </Button>
                      </Popconfirm>
                    </Space>
                  </>
                ),
              },
              {
                key: "status",
                dataIndex: [
                  "subcontractor_employee",
                  "user",
                  "created_password",
                ],
                title: "Status",
                render: (a, r) => {
                  return a ? (
                    <div className="flex items-center justify-center text-center text-semantic-positive-green">
                      {"Active"}
                    </div>
                  ) : (
                    <div className="text-center">
                      Invited (
                      <span
                        className="text-interactive-primary"
                        onClick={async () => {
                          resendInvite(r.subcontractor_employee);
                        }}
                      >
                        Resend Invite+
                      </span>
                      )
                    </div>
                  );
                },
              },
            ]}
          />
        </div>

        <div className="mt-2">
          {
            <StyledContent padding backgroundColor="white">
              <CustomTable
                title={"Available Subcontractor Administrators"}
                dataSource={[
                  ...data.subcontractor_connection.edges[0].node
                    .subcontractor_employees,
                ]}
                titleIcon={{
                  icon: IconCirclePlus,
                  color: "interactive",
                  onClick: () => {
                    sendInviteRef.current && sendInviteRef.current.open();
                  },
                }}
                columns={[
                  {
                    dataIndex: ["user", "name"],
                    title: "Name",
                  },
                  {
                    dataIndex: ["employee_title", "name_text"],
                    title: "Title",
                  },
                  {
                    dataIndex: ["user", "email"],
                    title: "Email",
                    size: "xl",
                  },
                  {
                    dataIndex: ["user", "phone_number"],
                    title: "Phone #",
                  },
                  {
                    dataIndex: ["user", "created_password"],
                    title: "Status",
                    render: (a, r) => {
                      return a ? (
                        <div className="flex items-center justify-center text-center text-semantic-positive-green">
                          {"Active"}
                        </div>
                      ) : (
                        <div className="text-center">
                          Invited (
                          <span
                            className="text-interactive-primary"
                            onClick={async () => {
                              resendInvite(r);
                            }}
                          >
                            Resend Invite+
                          </span>
                          )
                        </div>
                      );
                    },
                  },
                ]}
              />
            </StyledContent>
          }
        </div>
      </div>
    </StyledContent>
  );
};

export default GCSubcontractorDetail;
