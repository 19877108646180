import { MailOutlined } from "@ant-design/icons";
import { Button, DatePicker, message, Space, Tag } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";

import React, { FC, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import SCSendEmailModal from "src/common/components/dialogs/SubAdminSendEmailModal";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BContent from "src/common/components/layouts/BContent";
import ScrollTable from "src/common/components/tables/basic/ScrollTable";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import getConnectionNodes from "src/common/functions/getConnectionNodes";
import getDateStr from "src/common/functions/getDateStr";
import {
  useEmailDailyLogsPdfMutation,
  useGenerateDailyLogsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { SCReportsDailyDateQuery } from "src/common/types/generated/relay/SCReportsDailyDateQuery.graphql";
import SubcontractorProps from "src/common/types/manual/SubcontractorProps";

const query = graphql`
  query SCReportsDailyDateQuery(
    $subcontractorId: uuid!
    $date: date!
    $projectBoolExp: uuid_comparison_exp!
  ) {
    daily_work_log_connection(
      where: {
        subcontractor_id: { _eq: $subcontractorId }
        project_id: $projectBoolExp
        date: { _eq: $date }
      }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          submitted_at
          created_at
          worker_count
          worker_hours
          project {
            name
          }
          subcontractor {
            name
            pk: id @__clientField(handle: "pk")
          }
          description {
            ...TextTranslationFrag @relay(mask: false)
          }
          images(order_by: { sort_index: asc }) {
            ...ImageFrag @relay(mask: false)
          }
          daily_work_log_tags {
            id
            pk: id @__clientField(handle: "pk")
            tag {
              ...TagFrag @relay(mask: false)
            }
          }
          daily_work_log_workers {
            hours
            project_worker {
              user {
                name
              }
            }
          }
          created_by_project_user {
            project_worker {
              pk: id @__clientField(handle: "pk")
              subcontractor {
                name
              }
              user {
                name
              }
            }
            employee {
              user {
                pk: id @__clientField(handle: "pk")
                name
              }
            }
          }
        }
      }
    }
  }
`;

interface SCReportsDailyDateProps extends SubcontractorProps {
  date: string;
  openReport: (params: { date: string } | { id: string }) => void;
  projectId?: string;
}

const SCReportsDailyDate: FC<SCReportsDailyDateProps> = ({
  date,
  openReport,
  subcontractorId,
  projectId,
}) => {
  const navigate = useNavigate();
  const dateStr: string = dayjs(date).format("YYYY-MM-DD");
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [isEmail, setIsEmail] = useState(true);
  const [emailLogs, { loading: emailingDailyLogs }] =
    useEmailDailyLogsPdfMutation();
  const [generateLogs, { loading: generatingDailyLogs }] =
    useGenerateDailyLogsMutation();
  const data = useLazyLoadQuery<SCReportsDailyDateQuery>(query, {
    subcontractorId: subcontractorId,
    projectBoolExp: projectId ? { _eq: projectId } : { _is_null: false },
    date: dateStr,
  });
  const logs = getConnectionNodes(data.daily_work_log_connection);

  const logsData = logs
    .filter((log) => !!log.submitted_at)
    .map((l) => ({
      pk: l.pk,
      project: l.project,
      workersCount: l.worker_count ?? l.daily_work_log_workers.length,
      manHours: typeof(l.worker_hours) === "number" && typeof(l.worker_count) === "number"
        ? l.worker_hours * l.worker_count +
          (!!(l.created_by_project_user.project_worker)
            ? " (Adjusted)"
            : "")
        : l.daily_work_log_workers.reduce((sum, w) => sum + w.hours, 0),
      description: l.description,
      creator:
        (l.created_by_project_user.employee ??
          l.created_by_project_user.project_worker)!.user!.name +
        ", " +
        dayjs(l.submitted_at ?? l.created_at)
          .tz("America/New_York")
          .format("MMMM Do YYYY, h:mm:ss A z"),
      tags: l.daily_work_log_tags.map(function (l1) {
        return (
          <Tag color={l1.tag.color_hex} key={l1.tag.pk}>
            {l1.tag.name.en}
          </Tag>
        );
      }),
    }));

  return (
    <Space direction="vertical">
      <SCSendEmailModal
        visible={emailModalOpen}
        isEmail={isEmail}
        subId={subcontractorId}
        onCancel={() => setEmailModalOpen(false)}
        onCreate={async (values) => {
          setEmailModalOpen(false);
          if (isEmail) {
            await emailLogs({
              variables: {
                input: {
                  projectId: values.projectId,
                  date: dateStr,
                  type: "sub",
                  emailRecipients: [values.email],
                  subcontractorId: subcontractorId,
                },
              },
            });

            message.success("Emailed report!");
          } else {
            const { data: downloadedData } = await generateLogs({
              variables: {
                input: {
                  projectId: values.projectId,
                  date: dayjs(dateStr).format("YYYY-MM-DD"),
                  type: "sub",
                  subcontractorId,
                },
              },
            });
            if (downloadedData?.generateDailyLogs) {
              downloadFromUrl(downloadedData?.generateDailyLogs);
            }
          }
        }}
      />
      <BContent>
        <Space direction="vertical">
          <Space>
            {
              <DatePicker
                {...{
                  value: dayjs(date),
                  onChange: (e) => {
                    openReport({
                      date: dayjs(e || dayjs()).format("YYYY-MM-DD"),
                    });
                  },
                }}
              />
            }
            <Button
              icon={<MailOutlined />}
              title="Email Report"
              loading={emailingDailyLogs}
              onClick={() => {
                setIsEmail(true);
                setEmailModalOpen(true);
              }}
            />
            <Button
              type="primary"
              loading={generatingDailyLogs}
              onClick={async () => {
                if (projectId) {
                  const { data: downloadedData } = await generateLogs({
                    variables: {
                      input: {
                        projectId,
                        date: dayjs(dateStr).format("YYYY-MM-DD"),
                        type: "sub",
                        subcontractorId,
                      },
                    },
                  });
                  if (downloadedData?.generateDailyLogs) {
                    downloadFromUrl(downloadedData?.generateDailyLogs);
                  }
                } else {
                  setIsEmail(false);
                  setEmailModalOpen(true);
                }
              }}
            >
              Download {dayjs(dateStr).format("D MMM, YYYY")}'s Daily Report
            </Button>
          </Space>
          <ScrollTable
            title="Work Logs"
            totalCount={logsData.length}
            onChange={() => {}}
            dataSource={logsData}
            onRow={(logsData) => ({})}
            columns={[
              {
                title: "Project",
                key: "project",
                dataIndex: ["project", "name"],
                defaultSortOrder: "asc",
              },
              {
                title: "Tags",
                key: "date",
                dataIndex: ["tags"],
              },
              {
                title: "Workers",
                key: "workers",
                dataIndex: ["workersCount"],
              },
              {
                title: "Man hours",
                key: "manHours",
                dataIndex: ["manHours"],
              },
              {
                title: "Comments",
                key: "comments",
                dataIndex: ["description", "en"],
              },
              {
                title: "Created by",
                key: "createdBy",
                dataIndex: ["creator"],
              },
            ]}
          />
        </Space>
      </BContent>
    </Space>
  );
};

export default withCustomSuspense(SCReportsDailyDate);
