import { SearchFilterState } from "../../ScrollTable";
import { DataRecord } from "../dataScrollTableTypes";
import getFieldValue from "../getFieldValue";

const arraySearchFilter =
  (dataIndex: ReadonlyArray<string>, filter: SearchFilterState) =>
  (record: DataRecord) => {
    const value = getFieldValue(record, dataIndex);

    return (
      `${value?.toLocaleString().toLocaleLowerCase()}`.indexOf(
        filter.text.toLocaleLowerCase(),
      ) >= 0
    );
  };
export default arraySearchFilter;
