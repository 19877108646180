/**
 * @generated SignedSource<<40766e7dfdf248b99386ce395b60f8de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TurnOffEmailModalQuery$variables = {
  projectId: string;
};
export type TurnOffEmailModalQuery$data = {
  readonly project_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly is_siteupdate_daily_email_active: boolean;
      };
    }>;
  };
};
export type TurnOffEmailModalQuery = {
  response: TurnOffEmailModalQuery$data;
  variables: TurnOffEmailModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "projectId"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "projectConnection",
    "kind": "LinkedField",
    "name": "project_connection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "projectEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "is_siteupdate_daily_email_active",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TurnOffEmailModalQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TurnOffEmailModalQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "75b517bb4134c539bb2c54ce49a3c913",
    "id": null,
    "metadata": {},
    "name": "TurnOffEmailModalQuery",
    "operationKind": "query",
    "text": "query TurnOffEmailModalQuery(\n  $projectId: uuid!\n) {\n  project_connection(where: {id: {_eq: $projectId}}) {\n    edges {\n      node {\n        id\n        is_siteupdate_daily_email_active\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "75fff446bc5f23b079bccdc8fe699f78";

export default node;
