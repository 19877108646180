import { FilterState } from "../../ScrollTable";

const buildFilterConstraint = (filter: FilterState) => {
  switch (filter.type) {
    case "in_list":
      return { _in: filter.values };
    case "date_range":
      return {
        ...(filter.from ? { _gte: filter.from?.format() } : {}),
        ...(filter.to ? { _lt: filter.to?.format?.() } : {}),
      };
    case "search":
      return { _ilike: `%${filter.text.replace(/[\W_]+/g, "%")}%` };
  }
};
export default buildFilterConstraint;
