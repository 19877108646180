import React from "react";

interface CertificatesProcessingProps {
  count: number;
}

const CertificatesProcessing: React.FC<CertificatesProcessingProps> = (
  props
) => {
  return (
    <p className="text-static-secondary text-0.75 select-none">{`${
      props.count > 0
        ? `${props.count} certificate${
            props.count > 1 ? "s are" : " is"
          } processing ...`
        : "All the certificates've processed"
    }`}</p>
  );
};

export default CertificatesProcessing;
