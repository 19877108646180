import React, { useEffect, useRef } from "react";
import remToPx from "../../functions/remToPx";

type ScrollSelectOption = string;

export interface ScrollSelectProps {
  name: string;
  options: ScrollSelectOption[];
  defaultValue?: ScrollSelectOption;
  onValueChange: (newValue: ScrollSelectOption) => void;
}

const ScrollSelect: React.FC<ScrollSelectProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const optionRefs = useRef<HTMLDivElement[]>([]);
  const getScrollProps = () =>
    ref.current
      ? {
          scrollHeight: ref.current.scrollHeight - remToPx(10),
          scrollTop: ref.current.scrollTop,
        }
      : null;

  useEffect(() => {
    if (ref.current && props.defaultValue) {
      const defaultValueIndex = props.options.indexOf(props.defaultValue);
      const scrollParent = ref.current;
      const scrollChild = optionRefs.current[defaultValueIndex];
      if (scrollChild && scrollParent) {
        const scrollParentRect = scrollParent.getBoundingClientRect();
        const scrollChildRect = scrollChild.getBoundingClientRect();
        const scrollParentCenter = scrollParentRect.height / 2;
        const scrollChildCenter = scrollChildRect.height / 2;
        const scrollParentTop = scrollParentRect.top;
        const scrollChildTop = scrollChildRect.top;
        const scrollParentScrollTop = scrollParent.scrollTop;
        scrollParent.scrollTop =
          scrollChildTop -
          scrollParentTop +
          scrollParentScrollTop -
          scrollParentCenter +
          scrollChildCenter;
      }
    }
  }, []);

  return (
    <div
      key={props.name}
      style={{
        scrollSnapType: "y mandatory",
      }}
      className="h-full px-1 scrollbar-hidden overflow-y-auto overflow-x-hidden"
      onScroll={(e) => {
        const scrollProps = getScrollProps();
        if (!scrollProps) return;
        const scrollPosition = e.currentTarget.scrollTop;
        const focusedOptionIndex = scrollProps.scrollHeight
          ? Math.floor(
              (props.options.length / scrollProps.scrollHeight) *
                scrollPosition,
            )
          : 0;
        props.onValueChange(props.options[focusedOptionIndex]);
      }}
      ref={ref}
    >
      <div className={`my-5 flex items-center flex-col gap-0.5`}>
        {props.options.map((selectorOption) => (
          <div
            key={selectorOption}
            ref={(el) => {
              !!el && optionRefs.current.push(el);
            }}
            style={{
              scrollSnapAlign: "center",
            }}
          >
            {selectorOption}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollSelect;
