import { useNavigate, useParams } from "react-router-dom";
import React, { useRef, useState } from "react";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import ObservationTable from "../../components/ObservationTable";
import useAuthUser from "src/common/hooks/useAuthUser";
import { IconSettings } from "@tabler/icons";
import { Button, Modal } from "antd";
import GCObservationSettings from "./GCObservationSettings";
import {
  GetObservationOptionsDocument,
  GetObservationOptionsQuery,
  GetObservationOptionsQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSuspenseQuery } from "@apollo/client";

const GCProjectObservations: React.FunctionComponent = () => {
  const projectId = useParams().projectId;
  if (!projectId) throw new Error("projectId not found");
  const ref = useRef(null);
  const authUser = useAuthUser();
  const { data: options } = useSuspenseQuery<
    GetObservationOptionsQuery,
    GetObservationOptionsQueryVariables
  >(GetObservationOptionsDocument, {
    variables: {
      projectId,
      userId: authUser.uid,
      projectWorkerWhere: { id: { _is_null: true } },
      subWhere: { subcontractor_projects: { project_id: { _eq: projectId } } },
    },
  });
  const navigate = useNavigate();
  const [settingModal, setSettingModal] = useState(false);
  return (
    <>
      <Modal
        footer={
          <>
            <Button
              onClick={() => {
                setSettingModal(false);
              }}
            >
              Close
            </Button>
          </>
        }
        title="Settings"
        open={settingModal}
        width={800}
        onCancel={() => {
          setSettingModal(false);
        }}
      >
        <GCObservationSettings projectId={projectId} />
      </Modal>
      <ObservationTable
        ref={ref}
        showInReview={
          options?.project_setting_by_pk
            ?.require_gc_to_mark_observation_complete
        }
        riskLevels={options?.risk_level}
        onRowClick={(item) => {
          navigate(
            `/gce/projects/${projectId}/observations/observations/${item.id}`,
          );
        }}
        subs={options?.subcontractor}
        topBarButtons={[
          {
            secondary: true,
            icon: IconSettings,
            onClick: () => setSettingModal(true),
          },
        ]}
        options={options}
        where={{
          project_id: { _eq: projectId },
          _and: [
            {
              _or: [
                { status: { _neq: "draft" } },
                {
                  status: { _eq: "draft" },
                  created_by_uid: { _eq: authUser.uid },
                },
              ],
            },
            {
              _or: [
                {
                  observation_inspection: { submitted_on: { _is_null: false } },
                },
                { observation_inspection_id: { _is_null: true } },
              ],
            },
          ],
        }}
        projectId={projectId}
        excludedKeys={["project_name"]}
      />
    </>
  );
};
export default GCProjectObservations;
