import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import { useMoveInspectionQRToAnotherProjectMutation$data } from "src/common/types/generated/relay/useMoveInspectionQRToAnotherProjectMutation.graphql";
import { useUpdateInspectionQRCodeMutation$data } from "src/common/types/generated/relay/useUpdateInspectionQRCodeMutation.graphql";

const inspectionQROptionsDeleteUpdater = (
  id: string,
  store: RecordSourceSelectorProxy<
    | useUpdateInspectionQRCodeMutation$data
    | useMoveInspectionQRToAnotherProjectMutation$data
  >
) => {
  const optionsConn = ConnectionHandler.getConnection(
    store.getRoot(),
    "GCInspectionsQuery_inspection_qr_code_connection"
  );
  if (optionsConn) {
    ConnectionHandler.deleteNode(optionsConn, id);
  }
  const conn = ConnectionHandler.getConnection(
    store.getRoot(),
    "InspectionQRTable_inspection_qr_code_connection"
  );
  if (conn) {
    ConnectionHandler.deleteNode(conn, id);
  }
};

export default inspectionQROptionsDeleteUpdater;
