import React, { FC } from "react";
import DailyWorkLog from "src/root/routes/views/DailyWorkLog";

interface SCReportsDailySingleProps {
  id: string;
}

const SCReportsDailySingle: FC<SCReportsDailySingleProps> = (props) => {
  return (
    <DailyWorkLog
      {...{
        reportId: props.id,
      }}
    />
  );
};

export default SCReportsDailySingle;
