/**
 * @generated SignedSource<<e271df0045ec7bb0908ae3eba92c9c6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type task_set_input = {
  accepted_at_from_creation_request?: string | null | undefined;
  accepted_by_uid_from_creation_request?: string | null | undefined;
  archived_at?: string | null | undefined;
  archived_by_uid?: string | null | undefined;
  company_task_id?: string | null | undefined;
  created_at?: string | null | undefined;
  created_by_uid?: string | null | undefined;
  creation_request_id?: string | null | undefined;
  deleted_at?: string | null | undefined;
  description_id?: string | null | undefined;
  description_text?: string | null | undefined;
  document_id?: string | null | undefined;
  email_request_id?: string | null | undefined;
  flagged_at?: string | null | undefined;
  flagged_by_uid?: string | null | undefined;
  id?: string | null | undefined;
  incident_id?: string | null | undefined;
  is_pending_acceptance?: boolean | null | undefined;
  original_report_task_id?: string | null | undefined;
  project_id?: string | null | undefined;
  report_id?: string | null | undefined;
  request_state?: string | null | undefined;
  reviewed_at?: string | null | undefined;
  reviewed_by_uid?: string | null | undefined;
  show_type?: string | null | undefined;
  subcontractor_id?: string | null | undefined;
  test_project_was_null?: boolean | null | undefined;
  test_sub_was_null?: boolean | null | undefined;
  updated_at?: string | null | undefined;
  updated_by_uid?: string | null | undefined;
};
export type DeleteTaskMutation$variables = {
  _set: task_set_input;
  taskIds: ReadonlyArray<string>;
};
export type DeleteTaskMutation$data = {
  readonly update_task: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type DeleteTaskMutation = {
  response: DeleteTaskMutation$data;
  variables: DeleteTaskMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskIds"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "taskIds"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "task_mutation_response",
    "kind": "LinkedField",
    "name": "update_task",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteTaskMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeleteTaskMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "680512f7ca2817ccee39382ef7eea4ad",
    "id": null,
    "metadata": {},
    "name": "DeleteTaskMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteTaskMutation(\n  $taskIds: [uuid!]!\n  $_set: task_set_input!\n) {\n  update_task(where: {id: {_in: $taskIds}}, _set: $_set) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "fff7aa43e3687d7dc566be1c67c07f3c";

export default node;
