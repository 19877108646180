import React from "react";

import { TimePicker as TimePickerAntD } from "antd";
import dayjs from "dayjs";

interface TimePickerProps {
  onSelect: (dayjs: dayjs.Dayjs) => void;
  value: dayjs.Dayjs;
}

const TimePicker: React.FunctionComponent<TimePickerProps> = (props) => {
  return (
    <TimePickerAntD
      allowClear={false}
      showNow={false}
      minuteStep={15}
      onChange={props.onSelect}
      showSecond={false}
      value={props.value}
    />
  );
};

export default TimePicker;
