import { SorterData } from "../../ScrollTable";
import { DataRecord } from "../dataScrollTableTypes";
import getFieldValue from "../getFieldValue";

const arraySortData = (
  data: Array<DataRecord>,
  sorter: SorterData<DataRecord>,
): Array<DataRecord> => {
  const compareFn =
    sorter.clientCompareFn ??
    ((a, b) => {
      const valueA = getFieldValue(a, sorter.dataIndex);
      const valueB = getFieldValue(b, sorter.dataIndex);
      return !valueA || !valueB
        ? !valueA < !valueB
          ? 1
          : !valueA > !valueB
          ? -1
          : 0
        : valueA < valueB
        ? -1
        : valueA > valueB
        ? 1
        : 0;
    });
  return [...data].sort((a, b) => {
    const res = compareFn(a, b);
    return sorter.order === "desc" ? -res : res;
  });
};
export default arraySortData;
