import { Button, Form, Input, Select, Space, Spin } from "antd";
import { useState } from "react";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  useGetObservationSettingsQuery,
  useInsertUserGroupOneMutation,
  useUpdateObservationProjectSettingMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import ShowGCGroup from "../../components/basic/ShowGCGroup";
import * as uuid from "uuid";
import dayjs from "dayjs";
import { SwitchWithText } from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/settings/components/SiteOrientationSettingSwitchCards";
import getNormalOptionsFilter from "src/common/functions/getNormalOptionsFilter";

const GCObservationSettings: React.FC<{ projectId: string }> = ({
  projectId,
}) => {
  const [addNewGroup, setAddNewGroup] = useState(false);
  const variables = {
    projectId,
    userGroupWhere: {
      project_id: { _eq: projectId },
      type: { _eq: "observation" },
    },
  };
  const [updateObsProjSetting] = useUpdateObservationProjectSettingMutation();
  const { data, loading, error } = useGetObservationSettingsQuery({
    variables,
  });
  const [form] = Form.useForm<{ name: string; users: Array<string> }>();
  const authUser = useAuthUser();
  const [insertGroup, { loading: inserting }] = useInsertUserGroupOneMutation();
  if (loading) return <Spin />;
  if (error) throw error;
  if (!data) throw new Error("Data not found");

  return (
    <>
      <Form
        form={form}
        layout="horizontal"
        onFinish={async (v: { name: string; users: Array<string> }) => {
          const toInsert = {
            type: "observation",
            created_by_user_id: authUser.uid,
            project_id: projectId,
            name: v.name,
            id: uuid.v4(),
            user_group_members: {
              data: v.users.map((u) => ({
                id: uuid.v4(),
                general_contractor_employee_id: u,
              })),
            },
          };
          const expectedReturningGroup: (typeof data.user_group)[number] = {
            __typename: "user_group" as const,
            ...toInsert,
            pendingAccount: [],
            active: toInsert.user_group_members.data.map((u) => {
              const gcUser = data.general_contractor_employee.find(
                (emp) => emp.uid === u.general_contractor_employee_id,
              );
              if (!gcUser)
                throw new Error(
                  "GC employee relation not found for one of the selected users",
                );
              return {
                deleted_at: null,
                general_contractor_employee_id: gcUser.uid,
                project_worker_id: null,
                project_worker: null,
                created_at: dayjs().toISOString(),
                id: uuid.v4(),
                employee: gcUser,
                __typename: "user_group_member" as const,
              };
            }),
          };
          await insertGroup({
            variables: { object: toInsert },
            optimisticResponse: {
              insert_user_group_one: expectedReturningGroup,
            },
            update: (cache, returningData) => {
              const returningGroup = returningData.data?.insert_user_group_one;
              if (!returningGroup)
                throw new Error("Server returning null for inserted group");
              cache.modify<typeof data>({
                fields: {
                  user_group(groups = [], { toReference }) {
                    const newGroupRef = toReference(returningGroup);
                    if (!newGroupRef) return groups;
                    return [...groups, newGroupRef];
                  },
                },
              });
            },
          });
          form.resetFields();
          setAddNewGroup(false);
        }}
      >
        {addNewGroup && (
          <>
            <Form.Item name={"name"} label={"Group Name"}>
              <Input width={100} />
            </Form.Item>
            <Form.Item
              name={"users"}
              label={"Select Group Members for this Group"}
            >
              <Select
                mode="multiple"
                showSearch
                filterOption={getNormalOptionsFilter}
                options={data.general_contractor_employee.map((gc) => ({
                  id: gc.uid,
                  value: gc.uid,
                  label:
                    gc.user.name +
                    (gc.employee_title ? `, ${gc.employee_title.name.en}` : ""),
                }))}
              />
            </Form.Item>
            {addNewGroup && (
              <div className="flex gap-2">
                <Button loading={inserting} type="primary" htmlType="submit">
                  Create Group
                </Button>
                <Button
                  loading={inserting}
                  onClick={() => {
                    setAddNewGroup(false);
                  }}
                >
                  Cancel{" "}
                </Button>
              </div>
            )}
          </>
        )}
      </Form>
      {!addNewGroup && (
        <SwitchWithText
          checked={
            !!data.project_setting_by_pk
              ?.require_gc_to_mark_observation_complete
          }
          text="Require Verification of observations completed by foremen"
          onChange={(checked) => {
            updateObsProjSetting({
              variables: {
                projectId,
                _set: { require_gc_to_mark_observation_complete: checked },
              },
              optimisticResponse: {
                update_project_setting_by_pk: {
                  project_id: projectId,
                  __typename: "project_setting",
                  require_gc_to_mark_observation_complete: checked,
                },
              },
            });
          }}
        />
      )}
      <br />
      {data.user_group.length > 0 && (
        <div className="text-1.5">Distribution Group(s)</div>
      )}
      {!addNewGroup && (
        <div>
          <Button
            loading={inserting}
            onClick={() => {
              setAddNewGroup(true);
            }}
          >
            Add New Group
          </Button>
        </div>
      )}
      {data.user_group.map((gp) => {
        return (
          <div key={gp.id}>
            <ShowGCGroup
              group={gp}
              allUsers={data.general_contractor_employee}
            />
          </div>
        );
      })}
    </>
  );
};
export default GCObservationSettings;
