import { SiteOrientationInPersonRegistrationOptionsType } from "./siteOrientationTypes";
import { useGetOrientationDataQuery$data } from "src/common/types/generated/relay/useGetOrientationDataQuery.graphql";

const registrationOptionsFromProjectData = (
  data: useGetOrientationDataQuery$data
) => {
  const projectData = data.project_connection.edges[0].node;
  const registrationOptions: SiteOrientationInPersonRegistrationOptionsType = {
    // askAge: projectData.ask_age,
    ethnicityRequired: projectData.ask_ethnicity,
    genderRequired: projectData.ask_gender,
    veteranTypeRequired: projectData.ask_veteran_type,
    residenceRequired: projectData.ask_residence,
    hasInPerson: projectData.in_person_orientation,
    hasUniversal:
      projectData.agc_universal_orientation &&
      data.universal_orientation.edges.length > 0,
    hasSlides: data.orientation_connection.edges.length > 0,
    dobRequired: projectData.require_dob,
    mainGCId: projectData.general_contractor_id,
    phoneRequired: projectData.require_phone,
    unionLabor: projectData.union_labor,
    profilePhotoRequired: projectData.require_profile_photo,
    certificationsRequired: projectData.require_certification_upload,
    drugTest: {
      performingDrugTest: projectData.perform_drugtest,
      completionRequired: projectData.require_drugtest_to_complete_orientation,
      uploadRequired: projectData.require_drugtest_upload,
      validityDays: projectData.drugtest_validity_days,
    },
    hardHatNumber: {
      addHHDuringRegistration: projectData.add_hard_hat_during_registration,
      required: projectData.assign_hard_hat,
      autoAssigned: projectData.automatically_assign_hard_hat,
      nextHH: projectData.next_hard_hat_number || null,
      addHHDuringInPerson: projectData.add_hard_hat_during_in_person,
    },
    ageRequired: projectData.ask_age,
    emergencyContactRequired: projectData.ask_emergency_contact,
    yearsInConstructionRequired:
      projectData.ask_number_of_years_in_construction,
    yearsWithEmployerRequired: projectData.ask_years_with_employer,
    requireCerts: projectData.require_certs,
    projectCertifications: projectData.project_certifications.map(
      (projectCertification) => {
        return {
          certification_id: projectCertification.certification.pk,
          certification_name: projectCertification.certification.name,
          alternate_certification_ids:
            projectCertification.alternate_certifications.map(
              (alternateCertification) =>
                alternateCertification.alternate_certification_id
            ),
        };
      }
    ),
  };
  return registrationOptions;
};

export default registrationOptionsFromProjectData;
