import React, { FC } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import ManageToolboxTalks from "src/domain-features/sitesafety/components/toolbox-talks/ManageToolboxTalks";
import ToolboxTalkTable from "src/domain-features/sitesafety/components/toolbox-talks/ToolboxTalkTable";
import SCToolboxTalk from "src/domain-features/sitesafety/entryRoutes/scToolboxTalks/components/SCToolboxTalk";

const SCProjectToolboxTalks: FC<{ type: "manage" | "completed" }> = ({
  type,
}) => {
  const subcontractorId = useSubcontractorId();

  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const toolboxTalkId = searchParams.get("toolboxTalkId");
  if (!subcontractorId) {
    return <></>;
  }

  return (
    <>
      {toolboxTalkId ? (
        <SCToolboxTalk {...{ subcontractorId, toolboxTalkId }} />
      ) : (
        <div className={`flex-grow bg-white overflow-auto py-1 w-full`}>
          <div className="flex w-full h-full">
            {type === "completed" && (
              <ToolboxTalkTable
                showDateRangeFilter={true}
                excludedKeys={["subcontractor", "project"]}
                where={{
                  subcontractor_id: {
                    _eq: subcontractorId,
                  },
                  project_id: { _eq: projectId },
                }}
                onRowClick={(pk) => setSearchParams({ toolboxTalkId: pk })}
              />
            )}
            {type === "manage" && (
              <ManageToolboxTalks
                subId={subcontractorId}
                projectId={projectId}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SCProjectToolboxTalks;
