/**
 * @generated SignedSource<<ecd102c05eeea10e6889cdafdccbf92e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditWorkAboveElevationModal_work_above_elevation_frag_refetch$variables = {
  id: string;
};
export type EditWorkAboveElevationModal_work_above_elevation_frag_refetch$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"EditWorkAboveElevationModal_work_above_elevation_frag">;
  } | null | undefined;
};
export type EditWorkAboveElevationModal_work_above_elevation_frag_refetch = {
  response: EditWorkAboveElevationModal_work_above_elevation_frag_refetch$data;
  variables: EditWorkAboveElevationModal_work_above_elevation_frag_refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v8 = [
  (v7/*: any*/),
  (v2/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed_at",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditWorkAboveElevationModal_work_above_elevation_frag_refetch",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EditWorkAboveElevationModal_work_above_elevation_frag"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditWorkAboveElevationModal_work_above_elevation_frag_refetch",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "created_at",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "created_by_user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "employee_title",
                        "kind": "LinkedField",
                        "name": "employee_title",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "work_above_elevation_type",
                "kind": "LinkedField",
                "name": "work_above_elevation_type",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "work_above_floor",
                "kind": "LinkedField",
                "name": "work_above_floors",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "order",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "has_netting",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "work_above_elevation",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f993c3efe7c838fbf57fb25dfeea24f3",
    "id": null,
    "metadata": {},
    "name": "EditWorkAboveElevationModal_work_above_elevation_frag_refetch",
    "operationKind": "query",
    "text": "query EditWorkAboveElevationModal_work_above_elevation_frag_refetch(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...EditWorkAboveElevationModal_work_above_elevation_frag\n    id\n  }\n}\n\nfragment EditWorkAboveElevationModal_work_above_elevation_frag on work_above_elevation {\n  id\n  created_at\n  pk: id\n  name_id\n  name {\n    en\n    id\n  }\n  type_id\n  created_by_user {\n    name\n    employee {\n      employee_title {\n        name {\n          en\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n  work_above_elevation_type {\n    name {\n      en\n      id\n    }\n    id\n  }\n  completed_at\n  work_above_floors {\n    id\n    order\n    name_id\n    pk: id\n    completed_at\n    has_netting\n    name {\n      id\n      en\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "41fa9410812babd89c1c1dd7547a7f31";

export default node;
