import { graphql } from "babel-plugin-relay/macro";

const workerAndUserUpdateMutation = graphql`
  mutation UpdateWorkerAndUserMutation(
    $worker_set: worker_set_input!
    $workerId: uuid!
    $user_set: user_set_input!
  ) {
    update_user(where: { id: { _eq: $workerId } }, _set: $user_set) {
      affected_rows
    }
    update_worker_by_pk(pk_columns: { uid: $workerId }, _set: $worker_set) {
      uid
    }
  }
`;
export default workerAndUserUpdateMutation;
