import { Modal, Table } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useState } from "react";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import * as uuid from "uuid";
import {
  AddSafetyPlanFromCompanyLibraryModalMutation,
  safety_plan_insert_input,
} from "src/common/types/generated/relay/AddSafetyPlanFromCompanyLibraryModalMutation.graphql";
import { auth } from "src/common/functions/firebase";
import { safetyPlan } from "./SafetyPlanTable";
import { ConnectionHandler } from "relay-runtime";

const insertSafetyPlanMutation = graphql`
  mutation AddSafetyPlanFromCompanyLibraryModalMutation(
    $objects: [safety_plan_insert_input!]!
  ) {
    insert_safety_plan(objects: $objects) {
      returning {
        ...SafetyPlanFrag @relay(mask: false)
      }
    }
  }
`;

interface AddSafetyPlanFromCompanyLibraryModalProps {
  refresh: () => void;
  visible: boolean;
  closeModal: () => void;
  subcontractorId: string;
  projectId: string;
  data: Array<safetyPlan>;
}

const AddSafetyPlanFromCompanyLibraryModal: FC<
  AddSafetyPlanFromCompanyLibraryModalProps
> = ({ refresh, subcontractorId, closeModal, projectId, data, visible }) => {
  const [curRows, setCurRows] = useState<Array<safetyPlan>>([]);
  const [insertSafetyPlan, loading] =
    useAsyncMutation<AddSafetyPlanFromCompanyLibraryModalMutation>(
      insertSafetyPlanMutation,
    );
  const onOkEvent = async (safetyPlanToAdd: Array<safetyPlan>) => {
    await insertSafetyPlan({
      variables: {
        objects: safetyPlanToAdd.map((safetyPlan) => {
          const safetyPlanInsertInput: safety_plan_insert_input = {
            project_id: projectId,
            created_by_uid: auth.currentUser?.uid,
            subcontractor_id: subcontractorId,
            title: safetyPlan.title,
            url: safetyPlan.url,
            company_safety_plan_id: safetyPlan.pk,
            revision_number: safetyPlan.revision_number,
            revision_date: safetyPlan.revision_date,
          };
          return safetyPlanInsertInput;
        }),
      },
      updater: (store) => {
        const newVals = store.getRootField("insert_safety_plan");
        const conn1 = ConnectionHandler.getConnection(
          store.getRoot(),
          "SCProjectSafetyPlan_CompanySafetyPlanQuery_safety_plan_connection",
        );
        const conn2 = ConnectionHandler.getConnection(
          store.getRoot(),
          "SubProjectMobilizationView_safety_plan_connection",
        );
        if (conn1 && newVals) {
          newVals.getLinkedRecords("returning").forEach((val) => {
            const edge = store.create(uuid.v4(), "edge");
            edge.setLinkedRecord(val, "node");
            ConnectionHandler.insertEdgeAfter(conn1, edge);
          });
        }
        if (conn2 && newVals) {
          newVals.getLinkedRecords("returning").forEach((val) => {
            const edge = store.create(uuid.v4(), "edge");
            edge.setLinkedRecord(val, "node");
            ConnectionHandler.insertEdgeAfter(conn2, edge);
          });
        }
      },
    });
    refresh();
    closeModal();
  };
  return (
    <Modal
      open={visible}
      confirmLoading={loading}
      onCancel={closeModal}
      onOk={() => {
        onOkEvent(curRows);
        console.log("Open");
      }}
    >
      {data && data.length > 0 && (
        <Table
          rowSelection={{
            type: "checkbox",
            onChange: (_, selectedRows) =>
              setCurRows(() => {
                return [...selectedRows];
              }),
            getCheckboxProps: (record: any) => ({
              name: record.name,
            }),
          }}
          rowKey={(t) => t.pk}
          columns={[
            {
              title: "Title",
              dataIndex: ["title"],
              key: "title",
            },
          ]}
          dataSource={data}
          className="pt-1"
          bordered
        />
      )}
      {(!data || data.length === 0) && (
        <div>
          Nothing has been added to your Safety Manual Library. Go to Safety
          Plan in the left-side menu to add your corporate safety manual.
        </div>
      )}
    </Modal>
  );
};

export default AddSafetyPlanFromCompanyLibraryModal;
