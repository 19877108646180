import React from "react";

const onEnterDown = (
  e: React.KeyboardEvent<HTMLInputElement>,
  callback: () => void
) => {
  if (!e.shiftKey && e.key === "Enter") {
    callback();
  }
};

export default onEnterDown;
