import { FC, useState } from "react";
import StyledContent from "src/common/components/layouts/StyledContent";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import { Button } from "antd";
import dayjs from "dayjs";

import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { useSuspenseQuery } from "@apollo/client";
import { GetCrewSightLogsDocument, GetCrewSightLogsQuery, GetCrewSightLogsQueryVariables } from "../../../../../../common/types/generated/apollo/graphQLTypes";

interface Props {
  accountId: string;
}
const CrewSightLogs: FC<Props> = ({accountId}) => {
  const { data } = useSuspenseQuery<GetCrewSightLogsQuery, GetCrewSightLogsQueryVariables>(
    GetCrewSightLogsDocument, {
      variables: {
        accountId,
      }  
    }
  );
  const getStyle = (rec: GetCrewSightLogsQuery["crewsight_integration_log"][number]) => (
    (rec.action_type || "").endsWith("error")? {color: 'red'}: {}
  )
  return (
    <StyledContent padding backgroundColor="white">
      <BaseTable
        columns={[
          { title: 'Time', key: 'created_at', dataIndex: 'created_at', render: (value, rec) => <span style={getStyle(rec)}>{dayjs(value).format('YYYY-MM-DD HH:mm:ss')}</span>},
          { title: "Type", key: "action_type", dataIndex: "action_type", render: (value, rec) => <span style={getStyle(rec)}>{value}</span>},
          { title: 'Message', key: 'message', dataIndex: 'message', render: (value, rec) => <span style={getStyle(rec)}>{value}</span> }
        ]}
        dataSource={data.crewsight_integration_log}
      ></BaseTable>
    </StyledContent>
  );
};

export default withCustomSuspense(CrewSightLogs);
