import { Form, Select } from "antd";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import { Project_Employee } from "src/common/types/generated/apollo/graphQLTypes";

interface Values {
  projectEmployeeId: string;
}

interface Props {
  onUpdate: (values: Values) => void;
  onCancel: () => void;
  projectEmployees: Array<{ id: string; name: string }>;
}

export type SetCovidContactEmployeeModalRef = FModalRef<Values> | null;

const SetCovidContactEmployeeModal = forwardRef<
  SetCovidContactEmployeeModalRef,
  Props
>(({ onUpdate, onCancel, projectEmployees }, ref) => {
  const [form] = Form.useForm();
  const modal = useRef<FModalRef<Values>>(null);
  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };
  useImperativeHandle<
    SetCovidContactEmployeeModalRef,
    SetCovidContactEmployeeModalRef
  >(ref, () => modal.current);
  return (
    <FModal
      ref={modal}
      title="Set project COVID-19 contact employee"
      okText="Update"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then(async (values) => {
            form.resetFields();
            onUpdate(values as Values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="projectEmployeeId"
          label="Contact Project Employee"
          rules={[{ required: true, message: `Choose employee` }]}
        >
          <Select style={{ width: "100%" }}>
            {projectEmployees.map((t) => (
              <Select.Option key={t.id} value={t.id}>
                {t.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </FModal>
  );
});

export default SetCovidContactEmployeeModal;
