import { AntCloudOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Checkbox,
  Form,
  Modal,
  Radio,
  Upload,
  message,
  notification,
  Select,
} from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useRef, useState } from "react";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import showErrorNotification from "src/utility-features/error-handling/user-feedback/showErrorNotification";
import { auth } from "src/common/functions/firebase";
import { uploadFilesWithPath } from "src/common/functions/uploadFiles";
import { UploadSdsToSiteformModalMutation } from "src/common/types/generated/relay/UploadSdsToSiteformModalMutation.graphql";
import dayjs from "dayjs";
import getNormalOptionsFilter from "src/common/functions/getNormalOptionsFilter";

interface UploadSDSToSiteformModalProps {
  visible: boolean;
  subcontractorId: string | null;
  onClose: () => void;
  onSubmit: () => void;
  projectId?: string;
  projectSubcontractorData?: Array<{ id: string; name: string }>;
}
type FormValues = {
  acceptance: [boolean];
  uploads: { fileList: Array<{ originFileObj: File }> };
  subcontractor?: string;
};
const UploadSDSToSiteFormModal: FC<UploadSDSToSiteformModalProps> = ({
  visible,
  subcontractorId,
  onClose,
  onSubmit,
  projectId,
  projectSubcontractorData,
}) => {
  const [loading, setLoading] = useState(false);
  const [uploadSDS, isUploading] =
    useAsyncMutation<UploadSdsToSiteformModalMutation>(
      graphql`
        mutation UploadSdsToSiteformModalMutation(
          $object: sds_request_insert_input!
        ) {
          insert_sds_request_one(object: $object) {
            id
            pk: id @__clientField(handle: "pk")
          }
        }
      `,
    );

  const [form] = Form.useForm<FormValues>();

  return (
    <Modal
      open={visible}
      confirmLoading={loading || isUploading}
      title={"Upload SDS(s) to be Processed and Added to SiteForm"}
      okText="Submit"
      onCancel={() => onClose()}
      onOk={async () => {
        setLoading(true);
        await form
          .validateFields()
          .then(async (v) => {
            const frontFiles = await uploadFilesWithPath(
              v.uploads.fileList.map((o) => ({
                file: o.originFileObj,
                directoryName: "SDS Request",
              })),
            );
            console.log(v.acceptance[0], v);
            if (v.acceptance[0]) {
              await uploadSDS({
                variables: {
                  object: {
                    project_id: projectId,
                    subcontractor_id: subcontractorId
                      ? subcontractorId
                      : v.subcontractor,
                    agree_to_not_hold_siteform_responsible: v.acceptance[0],
                    requested_by_uid: auth.currentUser?.uid,
                    requested_at: dayjs().format(),
                    attached_files: {
                      data: frontFiles.map((file) => ({
                        url: file.url,
                        name: file.name,
                        document_type: file.type,
                        type: "sds_creation_request",
                        author_id: auth.currentUser?.uid,
                        description: "SDS Request's Attached file",
                      })),
                    },
                  },
                },
              });
              message.success({ content: "SDS Uploaded to Siteform" });
            }
            form.resetFields();
            onSubmit();
          })
          .catch((e) => {
            showErrorNotification({
              errorType: "operational",
              errorTitle: "Upload Error",
            });
            console.log(e);
          });
        setLoading(false);
      }}
    >
      <Form form={form} layout="vertical" title="Upload Form">
        <Form.Item
          className="mt-2"
          label={"Add Individual or Multiple Files"}
          name="uploads"
          rules={[{ required: true, message: "Upload a file" }]}
        >
          <Upload.Dragger
            multiple={true}
            iconRender={() => <AntCloudOutlined />}
            customRequest={() => true}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p>Click or drag files here to upload</p>
          </Upload.Dragger>
        </Form.Item>

        {projectSubcontractorData && (
          <Form.Item
            className="mt-2"
            label="Subcontractor"
            name="subcontractor"
          >
            <Select
              placeholder={"Select Subcontractor"}
              className="w-70"
              showSearch
              filterOption={getNormalOptionsFilter}
            >
              {projectSubcontractorData?.map((sub) => (
                <Select.Option key={sub.id} value={sub.id} label={sub.name}>
                  {" "}
                  {sub.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          className="mt-2"
          name="acceptance"
          label={
            "I agree to hold SiteForm harmless from any liability or responsibility due to or from a result of the content of my SDS(s), the content of the SDS(s) entered into SiteForm, or the naming and/or logging of the SDS."
          }
          rules={[
            {
              required: true,
              message: "Please accept above condition to upload the JHAs",
            },
          ]}
        >
          <Checkbox.Group>
            <span>
              <Checkbox value={true} />
              <span className="mt-2"> I Accept</span>
            </span>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default UploadSDSToSiteFormModal;
