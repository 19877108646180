import React, { FC, useRef, useState } from "react";
import FModal, { FModalRef } from "../dialogs/FModal";
import Icon from "../general/Icon";
import {
  IconArrowRight,
  IconUser,
  IconUserCheck,
  IconUserCircle,
} from "@tabler/icons";
import { ConvertWorkerDataType } from "./performWorkerTypes";
import CombineWorkerModal from "./CombineWorkerModal";
import ConvertWorkerToEmployee from "./ConvertWorkerToEmployeeModal";
import ConvertWorkerToSubAdmin from "./ConvertWorkerToSubAdminModal";
interface PerformWorkerFunctionsProps {
  visible: boolean;
  data: Array<ConvertWorkerDataType>;
  onClose: () => void;
  isGC?: boolean;
  refetch: () => void;
  relayQueryConnName?: string;
}

interface Values {
  function: "merge" | "workerToGC" | "workerToSubAdmin";
}

const PerformWorkerFunctions: FC<PerformWorkerFunctionsProps> = ({
  visible,
  onClose,
  refetch,
  data,
  isGC,
  relayQueryConnName,
}) => {
  const modalRef = useRef<FModalRef<Values>>(null);
  const onCancel = () => {
    modalRef.current && modalRef.current.form.resetFields();
    onClose();
  };
  const [selectedFunction, setSelectedFunction] = useState<
    "merge" | "workerToGC" | "workerToSubAdmin" | undefined
  >(undefined);
  return (
    <>
      <FModal
        okText={"Next"}
        ref={modalRef}
        width={650}
        title="What function do you want to perform?"
        open={visible}
        onCancel={onCancel}
        onOk={async () => {
          await modalRef.current?.form.validateFields().then((val) => {
            console.log(val.function, "   val  ");
            setSelectedFunction(val.function);
            // onCancel();
          });
        }}
      >
        <FModal.RadioGroup label="" name="function">
          <div className="flex flex-row">
            <FModal.Radio props={{ value: "merge" }} className="flex">
              Combine two workers
            </FModal.Radio>
            <Icon icon={IconUser} />
            <Icon icon={IconUser} />
            <Icon icon={IconArrowRight} />
            <Icon icon={IconUser} />
          </div>
          {isGC && (
            <>
              <div className="flex flex-row">
                <FModal.Radio props={{ value: "workerToGC" }} className="flex">
                  Worker to GC Team Member
                </FModal.Radio>
                <Icon icon={IconUser} />
                <Icon icon={IconArrowRight} />
                <Icon icon={IconUserCircle} />
              </div>
              <div
                style={{
                  marginTop: "-20px",
                  fontSize: "12px",
                  marginBottom: 25,
                  marginLeft: "20px",
                }}
              >
                Convert a worker to a member of your Operations Team (but not a
                foreman that submits reports)
              </div>
            </>
          )}
          {isGC && (
            <>
              <div className="flex flex-row">
                <FModal.Radio
                  props={{ value: "workerToSubAdmin" }}
                  className="flex"
                >
                  Worker to Subcontractor Administrator
                </FModal.Radio>
                <Icon icon={IconUser} />
                <Icon icon={IconArrowRight} />
                <Icon icon={IconUserCheck} />
              </div>
              <div
                style={{
                  marginTop: "-20px",
                  fontSize: "12px",
                  marginLeft: " 20px",
                }}
              >
                Convert a worker to an administrator of their Sub (this will
                give access to their company’s profile)
              </div>
            </>
          )}
        </FModal.RadioGroup>
      </FModal>
      <CombineWorkerModal
        refetch={refetch}
        type="withData"
        relayQueryConnName={relayQueryConnName}
        onClose={() => {
          setSelectedFunction(undefined);
          // onCancel();
        }}
        visible={selectedFunction === "merge"}
        data={data}
      />
      {isGC && (
        <ConvertWorkerToEmployee
          refetch={refetch}
          relayQueryConnName={relayQueryConnName}
          type="selectWorker"
          onClose={() => {
            setSelectedFunction(undefined);
          }}
          onCancel={() => {
            onCancel();
          }}
          visible={selectedFunction === "workerToGC"}
          data={data}
        />
      )}
      {isGC && (
        <ConvertWorkerToSubAdmin
          relayQueryConnName={relayQueryConnName}
          refetch={refetch}
          type="selectWorker"
          onClose={() => {
            setSelectedFunction(undefined);
            // onCancel();
          }}
          onCancel={() => {
            onCancel();
          }}
          visible={selectedFunction === "workerToSubAdmin"}
          data={data}
        />
      )}
    </>
  );
};
export default PerformWorkerFunctions;
