import { Button, Table } from "antd";
import { useNavigate } from "react-router-dom";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { CheckOutlined, LeftOutlined } from "@ant-design/icons";
import {
  GetCsrProjectWorkerDetailsQuery,
  GetCsrProjectWorkerDetailsQueryVariables,
  GetCsrProjectWorkerDetailsDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSuspenseQuery } from "@apollo/client";
import UserDescriptions from "src/common/components/UserDescriptions";

const CsrProjectWorkerDetail: React.FC<{
  projectId: string;
  workerId: string;
}> = ({ projectId, workerId }) => {
  const { data } = useSuspenseQuery<
    GetCsrProjectWorkerDetailsQuery,
    GetCsrProjectWorkerDetailsQueryVariables
  >(GetCsrProjectWorkerDetailsDocument, { variables: { projectId, workerId } });
  const navigate = useNavigate();
  if (!data.user_by_pk) throw new Error("User not found");
  return (
    <div>
      <Button
        onClick={() => {
          navigate(-1);
        }}
        icon={<LeftOutlined />}
      >
        Back
      </Button>
      <BasicWrapper>
        <UserDescriptions user={data.user_by_pk} />
      </BasicWrapper>
      <Table
        title={() => "Orientation History of worker"}
        dataSource={data.orientation}
        rowKey={(data) => data.id}
        expandable={{
          rowExpandable: () => true,
          expandedRowRender: (row) => {
            return (
              <div>
                {row.slides.map((s) => (
                  <div
                    className={
                      s.viewed_by.length > 0
                        ? "text-semantic-positive-green"
                        : ""
                    }
                  >
                    {s.title}{" "}
                    {s.viewed_by.length > 0 && (
                      <CheckOutlined className="text-semantic-positive-green font-accent" />
                    )}
                  </div>
                ))}
              </div>
            );
          },
        }}
        columns={[
          { title: "Orientation Name", key: "name", dataIndex: ["name"] },
          { title: "Orientation Type", key: "type", dataIndex: ["type"] },
          {
            title: "Completed Slides",
            key: "completed_slides",
            dataIndex: ["id"],
            render: (_, r) => {
              return r.slides.filter((s) => s.viewed_by.length > 0).length;
            },
          },
        ]}
      />
    </div>
  );
};
export default CsrProjectWorkerDetail;
