/**
 * @generated SignedSource<<59abb74981038a498df0e57dcc48d5c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useOrientationRegisterUser_OACUser_InsertOACTitle_Mutation$variables = {
  id: string;
  name: string;
};
export type useOrientationRegisterUser_OACUser_InsertOACTitle_Mutation$data = {
  readonly insert_oac_title_one: {
    readonly id: string;
  } | null | undefined;
};
export type useOrientationRegisterUser_OACUser_InsertOACTitle_Mutation = {
  response: useOrientationRegisterUser_OACUser_InsertOACTitle_Mutation$data;
  variables: useOrientationRegisterUser_OACUser_InsertOACTitle_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          }
        ],
        "kind": "ObjectValue",
        "name": "object"
      }
    ],
    "concreteType": "oac_title",
    "kind": "LinkedField",
    "name": "insert_oac_title_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useOrientationRegisterUser_OACUser_InsertOACTitle_Mutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useOrientationRegisterUser_OACUser_InsertOACTitle_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1bfe67ae409af642381e40b60141d1b9",
    "id": null,
    "metadata": {},
    "name": "useOrientationRegisterUser_OACUser_InsertOACTitle_Mutation",
    "operationKind": "mutation",
    "text": "mutation useOrientationRegisterUser_OACUser_InsertOACTitle_Mutation(\n  $name: String!\n  $id: uuid!\n) {\n  insert_oac_title_one(object: {name: $name, id: $id}) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5b89a339b889cef4adf26ce26705568a";

export default node;
