import { useSuspenseQuery } from "@apollo/client";
import { Button, Form, Input } from "antd";
import { useState } from "react";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import getAddressText from "src/common/functions/getAddressText";
import {
  GetDivisionDetailQuery,
  GetDivisionDetailQueryVariables,
  GetDivisionDetailDocument,
  useGetStatesQuery,
  useUpdateDivDetailMutation,
  useUpdateDivisionLogoMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import AddressFormItems, {
  AddressFormItemsName,
} from "../../component/AddressFormItems";
import ProfileAvatar from "src/root/routes/views/subcontractor/people/workers/worker-profile/ProfileAvatar";
import LogoExplainerText from "../../component/LogoExplainerText";
import withDivisionId, { DivisionProps } from "../../utils/withDivisionId";

const GCDivisionDetail: React.FC<DivisionProps> = ({ divisionId }) => {
  const { userData } = useUserData();
  const emp = userData.employee;
  if (!emp) throw new Error("Employee not found");

  const { data, refetch } = useSuspenseQuery<
    GetDivisionDetailQuery,
    GetDivisionDetailQueryVariables
  >(GetDivisionDetailDocument, {
    variables: {
      divisionId,
    },
    fetchPolicy: "network-only",
  });
  const { data: statesData } = useGetStatesQuery();
  const division = data.gc_division_by_pk;

  if (!division) throw new Error("Business Unit not found");
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);
  const gc = emp.general_contractor;
  const initialFields = {
    name: division.name,
    ...(division.address
      ? {
          addressLine1: division.address.line_1,
          city: division.address.city,
          zip: division.address.zip_code,
          stateCode: division.address.state.code,
        }
      : {}),
  };
  const [updateDivDetail, { loading }] = useUpdateDivDetailMutation();
  const [updateDivLogo] = useUpdateDivisionLogoMutation();
  return (
    <BasicWrapper scrollable>
      {!editing && (
        <div className="absolute right-1 top-1 w-20 h-20">
          <p className="text-center text-1.5 mb-1">
            Upload/Change {gc.hierarchy_division_name} Logo
          </p>
          <ProfileAvatar
            imageUrl={
              division.logo_url ||
              division.general_contractor.logo_url ||
              undefined
            }
            onFileUpload={async (imageUrl) => {
              await updateDivLogo({
                variables: { divisionId, imageUrl },
                optimisticResponse: {
                  update_gc_division_by_pk: {
                    id: divisionId,
                    logo_url: imageUrl,
                  },
                },
              });
            }}
            onFileRemove={async () => {
              await updateDivLogo({
                variables: { divisionId, imageUrl: null },
                optimisticResponse: {
                  update_gc_division_by_pk: {
                    id: divisionId,
                    logo_url: null,
                  },
                },
              });
            }}
          />
          <LogoExplainerText />
        </div>
      )}
      <div className="text-1.25 mb-2">{gc.hierarchy_division_name} Details</div>
      <Form
        form={form}
        initialValues={initialFields}
        onFinish={async (
          vals: AddressFormItemsName & {
            name: string;
            division?: string;
          },
        ) => {
          const newState =
            division.address.state.code !== vals.state_code
              ? statesData?.state.find((s) => s.code === vals.state_code)
              : division.address.state;
          await updateDivDetail({
            variables: {
              addressId: division.address.id,
              divisionId,
              divisionSet: { name: vals.name },
              addressSet: {
                state_code: vals.state_code,
                zip_code: vals.zip,
                city: vals.city,
                line_1: vals.addressLine1,
              },
            },
            optimisticResponse: {
              update_address_by_pk: {
                ...division.address,
                state: newState || division.address.state,
                zip_code: vals.zip,
                city: vals.city,
                line_1: vals.addressLine1,
              },
              update_gc_division_by_pk: {
                id: divisionId,
                name: vals.name,
              },
            },
          });
          // refetch();
          setEditing(false);
        }}
      >
        {editing ? (
          <>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Enter Name" }]}
            >
              <Input />
            </Form.Item>
            Address
            <AddressFormItems
              onStateChange={(v) => {}}
              states={statesData?.state || []}
            />
          </>
        ) : (
          <div>
            <div>Name: {division.name}</div>
            <br />
            Address: {getAddressText(division.address)}
          </div>
        )}

        {emp.is_corporate_admin && (
          <>
            <Button
              type={editing ? "default" : "primary"}
              loading={loading}
              onClick={() => {
                if (editing) form.setFieldsValue(initialFields);
                setEditing((i) => !i);
              }}
            >
              {editing ? "Cancel" : "Edit"}
            </Button>
            {editing && (
              <Button
                loading={loading}
                className="ml-2"
                htmlType="submit"
                type="primary"
              >
                Save
              </Button>
            )}
          </>
        )}
      </Form>
    </BasicWrapper>
  );
};
export default withDivisionId(GCDivisionDetail);
