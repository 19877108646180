import { Form, Input, Modal, Select, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import useUpdateCrewDataForExistingCrew from "src/domain-features/worker-crew/utils/useUpdateCrewDataForExistingCrew";
import { ConnectionHandler } from "relay-runtime";
import * as uuid from "uuid";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import GetFullID from "src/common/functions/GetFullId";
import crewName from "src/common/functions/crewName";
import { CrewDetailsQuery$data } from "src/common/types/generated/relay/CrewDetailsQuery.graphql";
import { CrewProjectWorkerOptions_project_worker_connection_frag$data } from "src/common/types/generated/relay/CrewProjectWorkerOptions_project_worker_connection_frag.graphql";
interface GCEditCrewModalValues {
  name?: string;
  projectWorkerIds: Array<string>;
  leadProjectWorkerId?: string | null;
}
type WorkerOptionsType =
  CrewProjectWorkerOptions_project_worker_connection_frag$data["project_worker_connection"]["edges"][number];

interface EditCrewModalProps {
  visible: boolean;
  onCancel: () => void;
  onFinish: () => void;
  crew: CrewDetailsQuery$data["project_crew_connection"]["edges"][number]["node"];
  workersOnProject: CrewProjectWorkerOptions_project_worker_connection_frag$data;
}

const EditCrewModal: React.FC<EditCrewModalProps> = ({
  visible,
  onCancel,
  crew,
  onFinish,
  workersOnProject,
}) => {
  const [updateProjectCrew] = useUpdateCrewDataForExistingCrew();
  const [form] = Form.useForm();
  const currentWorkersId = crew.project_workers.map((w) => w.pk);
  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const [selectedWorkerIds, setSelectedWorkerIds] = useState<string[]>([]);
  useEffect(() => {
    setSelectedWorkerIds(currentWorkersId);
  }, [visible]);
  const workersNotOnCrew: WorkerOptionsType[] = useMemo(() => {
    return workersOnProject.project_worker_connection.edges.filter((pw) =>
      selectedWorkerIds.every((pwId) => pwId !== pw.node.pk)
    );
  }, [selectedWorkerIds]);

  const workersOnCrew: WorkerOptionsType[] = useMemo(() => {
    return workersOnProject.project_worker_connection.edges.filter((pw) =>
      selectedWorkerIds.some((pwId) => pwId === pw.node.pk)
    );
  }, [selectedWorkerIds]);

  const [loading, setLoading] = useState(false);
  return (
    (<Modal
      open={visible}
      confirmLoading={loading}
      destroyOnClose
      title="Update Crew Information"
      okText="Save"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then(async (values: GCEditCrewModalValues) => {
            try {
              const leadForeman = values.leadProjectWorkerId;
              const crewName = values.name;
              let toAddProjectWorkers: string[] = [];
              let toRemoveProjectWorkers: string[] = [];
              if (values.projectWorkerIds) {
                [toAddProjectWorkers, toRemoveProjectWorkers] = compareTwoLists(
                  values.projectWorkerIds,
                  currentWorkersId
                );
              }
              if (leadForeman) {
                toAddProjectWorkers.push(leadForeman);
              }
              setLoading(true);
              await updateProjectCrew({
                variables: {
                  currentCrewId: crew.pk,
                  _setCrewData: {
                    ...(crewName ? { name: crewName } : {}),
                    ...(leadForeman
                      ? { lead_foreman_project_worker_id: leadForeman }
                      : {}),
                  },
                  removeWorkerWhere: { id: { _in: toRemoveProjectWorkers } },
                  insertWorkerWhere: { id: { _in: toAddProjectWorkers } },
                  // so if now selected lead is part of some other crew then he'll be removed from that
                  projectCrewNewWorkersWhere: {
                    lead_foreman_project_worker_id: {
                      _in: toAddProjectWorkers, // so if now selected lead is lead of some other crew then that's crew's lead will become null now
                    },
                  },
                },
                updater: (store) => {
                  const insertWorkersToCrew =
                    store
                      .getRootField("update_project_worker")
                      ?.getLinkedRecords("returning") || [];
                  const updatedCrew = store.getRootField(
                    "update_project_crew_by_pk"
                  );
                  const addProjectWorkers = insertWorkersToCrew.filter((pw) =>
                    toAddProjectWorkers.some(
                      (p) =>
                        GetFullID("project_worker", p) === pw.getValue("id")
                    )
                  );
                  const crewTableConn = ConnectionHandler.getConnection(
                    store.getRoot(),
                    "CrewTableQuery_project_crew_connection"
                  );
                  const CrewDetailsConn = ConnectionHandler.getConnection(
                    store.getRoot(),
                    "CrewDetailsQuery_project_crew_connection"
                  );
                  if (crewTableConn) {
                    const edges = crewTableConn.getLinkedRecords("edges");
                    if (edges) {
                      const edgeIndex = edges.findIndex((edge) => {
                        const node = edge.getLinkedRecord("node");
                        return node?.getValue("id") === crew.id;
                      });
                      const edge = store.create(uuid.v4(), "edge");
                      edge.setLinkedRecord(updatedCrew, "node");
                      edges[edgeIndex] = edge;
                    }
                    crewTableConn.setLinkedRecords(edges, "edges");
                  }
                  if (CrewDetailsConn) {
                    const edges = CrewDetailsConn.getLinkedRecords("edges");
                    if (edges) {
                      const edgeIndex = edges.findIndex((edge) => {
                        const node = edge.getLinkedRecord("node");
                        return node?.getValue("id") === crew.id;
                      });
                      const edge = store.create(uuid.v4(), "edge");
                      edge.setLinkedRecord(updatedCrew, "node");
                      edges[edgeIndex] = edge;
                    }
                    CrewDetailsConn.setLinkedRecords(edges, "edges");
                  }
                  const conn = ConnectionHandler.getConnection(
                    store.getRoot(),
                    "CrewProjectWorkerOptions_project_worker_connection"
                  );
                  if (conn) {
                    const edges = conn.getLinkedRecords("edges");
                    if (edges) {
                      edges.forEach((pw) => {
                        const node = pw.getLinkedRecord("node");
                        if (node) {
                          if (
                            toRemoveProjectWorkers.find(
                              (rpw) =>
                                GetFullID("project_worker", rpw) ===
                                node.getValue("id")
                            )
                          ) {
                            node
                              .getLinkedRecord("project_crew")
                              ?.invalidateRecord();
                            return;
                          }
                          const insertedOne = addProjectWorkers.find(
                            (rpw) => rpw.getValue("id") === node.getValue("id")
                          );
                          if (insertedOne) {
                            node.setLinkedRecord(
                              insertedOne.getLinkedRecord(
                                "leading_project_crews_aggregate"
                              ),
                              "leading_project_crews_aggregate"
                            );
                            node.setLinkedRecord(
                              insertedOne.getLinkedRecord("project_crew"),
                              "project_crew"
                            );
                          }
                        }
                      });
                    }
                  }
                },
              });
              setLoading(false);
              onFinish();
              message.success("Crew Updated");
            } catch (error) {
              setLoading(false);
              console.log(error);
              message.error("Crew Not Updated");
            }
            form.resetFields();
            onCancel();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          name: crew.name,
          projectWorkerIds: crew.project_workers.map((pw) => pw.pk),
          leadProjectWorkerId: crew.lead_foreman_project_worker
            ?.subcontractor_worker
            ? crew.lead_foreman_project_worker?.pk
            : undefined,
        }}
      >
        <Form.Item
          name="name"
          label="Crew Name"
          rules={[
            {
              required: true,
              message: "Crew name cannot be empty",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* <div>Change the name of Crew.</div>
        <hr className="bg-semantic-inactive-dark" /> */}
        <Form.Item name="projectWorkerIds" label="Add Workers">
          <Select
            className="w-full"
            mode="multiple"
            showSearch
            onChange={(val) => {
              if (Array.isArray(val)) {
                if (val.length > 0) {
                  setSelectedWorkerIds([...val.map((v) => v.toString())]);
                }
              }
            }}
            filterOption={(input, option) => {
              if (!option?.children || !option.children[0]) return false;
              return (
                option.children[0].toLowerCase().indexOf(input.toLowerCase()) >=
                0
              );
            }}
          >
            {workersOnProject.project_worker_connection.edges.map((w) => (
              <Select.Option key={w.node.pk} value={w.node.pk}>
                {w.node.user?.name}
                {w.node.hard_hat_number ? ", #" + w.node.hard_hat_number : ""}
                {w.node.title ? ", " + w.node.title.translation.en : ""}
                {(w.node.leading_project_crews_aggregate.aggregate?.count ||
                  0) > 0 ? (
                  <>
                    ,{" "}
                    <span className="text-interactive-primary">Crew Lead</span>
                  </>
                ) : (
                  ""
                )}

                <span className="italic float-right">
                  &nbsp;&nbsp; {crewName(w.node.project_crew)}
                </span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="leadProjectWorkerId"
          label="Crew Lead"
          rules={[{ required: false, message: "Lead Foreman" }]}
        >
          <Select
            className="w-full"
            showSearch
            filterOption={(input, option) => {
              if (!option?.children || !option.children[0]) return false;
              return (
                option.children[0].toLowerCase().indexOf(input.toLowerCase()) >=
                0
              );
            }}
          >
            {workersOnCrew.length > 0 ? (
              <>
                <Select.OptGroup key={1} label={"Workers on Crew"}>
                  {workersOnCrew.map((w) => (
                    <Select.Option key={w.node.pk} value={w.node.pk}>
                      {w.node.user?.name}
                      {w.node.hard_hat_number
                        ? ", #" + w.node.hard_hat_number
                        : ""}
                      {w.node.title ? ", " + w.node.title.translation.en : ""}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
                <Select.OptGroup key={2} label={"Others"}>
                  {workersNotOnCrew.map((w) => (
                    <Select.Option key={w.node.pk} value={w.node.pk}>
                      {w.node.user?.name}
                      {w.node.hard_hat_number
                        ? ", #" + w.node.hard_hat_number
                        : ""}
                      {w.node.title ? ", " + w.node.title.translation.en : ""}
                      {(w.node.leading_project_crews_aggregate.aggregate
                        ?.count || 0) > 0 ? (
                        <>
                          ,{" "}
                          <span className="text-interactive-primary">
                            Crew Lead
                          </span>
                        </>
                      ) : (
                        ""
                      )}

                      <span className="italic float-right">
                        &nbsp;&nbsp; {crewName(w.node.project_crew)}
                      </span>
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              </>
            ) : (
              workersNotOnCrew.map((w) => (
                <Select.Option key={w.node.pk} value={w.node.pk}>
                  {w.node.user?.name}
                  {w.node.hard_hat_number ? ", #" + w.node.hard_hat_number : ""}
                  {w.node.title ? ", " + w.node.title.translation.en : ""}
                  {(w.node.leading_project_crews_aggregate.aggregate?.count ||
                    0) > 0 ? (
                    <>
                      ,{" "}
                      <span className="text-interactive-primary">
                        Crew Lead
                      </span>
                    </>
                  ) : (
                    ""
                  )}

                  <span className="italic float-right">
                    &nbsp;&nbsp;&nbsp; {crewName(w.node.project_crew)}
                  </span>
                </Select.Option>
              ))
            )}
          </Select>
        </Form.Item>
      </Form>
    </Modal>)
  );
};

export default EditCrewModal;
