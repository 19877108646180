import { useApolloClient } from "@apollo/client";
import { message } from "antd";
import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { procoreProjectRedirectUrl } from "src/common/components/ConnectProcoreUserButton";
import LoadingContent from "src/common/components/general/LoadingContent";
import StyledContent from "src/common/components/layouts/StyledContent";
import {
  ProcoreSetUserAccountDocument,
  ProcoreSetUserAccountMutation,
  ProcoreSetUserAccountMutationVariables,
} from "src/common/types/generated/apollo/graphQLTypes";

// this page is used as callback during procore auth.
const GCProcoreProjectSetup: FC = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const state = params.get("state");
    if (!state) {
      message.error("state is not specified");
      //      navigate("/gce/noData"); // TODO: This is incorrect route, fix it
      return;
    }
    const items = state.split("|");
    const projectId = items[0];

    if (!projectId) {
      message.error("project id is not specified");
      //     navigate("/gce/noData"); // TODO:
      return;
    }
    const sandbox = items.length > 1 && items[1] === "sbx";
    const error = params.get("error");
    const code = params.get("code");

    if (!code || error) {
      const error_description = params.get("error_description") || "";
      navigate(
        `/gce/projects/${projectId}/settings?error=${error}&error_description=${error_description}`
      );
      return;
    }
    client
      .mutate<
        ProcoreSetUserAccountMutation,
        ProcoreSetUserAccountMutationVariables
      >({
        mutation: ProcoreSetUserAccountDocument,
        variables: {
          projectId,
          code,
          sandbox,
          redirect_url: procoreProjectRedirectUrl,
        },
      })
      .then(
        () => {
          navigate(`/gce/projects/${projectId}/settings`);
        },
        (e) => {
          const msg = e instanceof Error ? e.message : JSON.stringify(e);
          navigate(`/gce/projects/${projectId}/settings?error=${msg}`);
        }
      );
  }, []);
  return (
    <StyledContent align="center">
      <LoadingContent />
    </StyledContent>
  );
};

export default GCProcoreProjectSetup;
