/**
 * @generated SignedSource<<20ce059d70213976dacfe5c6e234cb7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectReportManagement_UpdatePauseDateNotification_Mutation$variables = {
  endDate?: string | null | undefined;
  projectId: string;
  startDate?: string | null | undefined;
};
export type GCProjectReportManagement_UpdatePauseDateNotification_Mutation$data = {
  readonly update_project_by_pk: {
    readonly id: string;
    readonly pause_dash_notification_end_date: string | null | undefined;
    readonly pause_dash_notification_start_date: string | null | undefined;
  } | null | undefined;
};
export type GCProjectReportManagement_UpdatePauseDateNotification_Mutation = {
  response: GCProjectReportManagement_UpdatePauseDateNotification_Mutation$data;
  variables: GCProjectReportManagement_UpdatePauseDateNotification_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "pause_dash_notification_end_date",
            "variableName": "endDate"
          },
          {
            "kind": "Variable",
            "name": "pause_dash_notification_start_date",
            "variableName": "startDate"
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "project",
    "kind": "LinkedField",
    "name": "update_project_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pause_dash_notification_start_date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pause_dash_notification_end_date",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectReportManagement_UpdatePauseDateNotification_Mutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCProjectReportManagement_UpdatePauseDateNotification_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "92111bd4b494a391e3453fad8b181554",
    "id": null,
    "metadata": {},
    "name": "GCProjectReportManagement_UpdatePauseDateNotification_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectReportManagement_UpdatePauseDateNotification_Mutation(\n  $projectId: uuid!\n  $startDate: timestamptz\n  $endDate: timestamptz\n) {\n  update_project_by_pk(pk_columns: {id: $projectId}, _set: {pause_dash_notification_start_date: $startDate, pause_dash_notification_end_date: $endDate}) {\n    id\n    pause_dash_notification_start_date\n    pause_dash_notification_end_date\n  }\n}\n"
  }
};
})();

(node as any).hash = "32eca9208b3245794a93898b611f1605";

export default node;
