import { InFilterState } from "../../ScrollTable";
import { DataRecord } from "../dataScrollTableTypes";
import getFieldValue from "../getFieldValue";

const arrayInFilter =
  (dataIndex: ReadonlyArray<string>, filter: InFilterState) =>
  (record: DataRecord) => {
    const value = getFieldValue(record, dataIndex);
    return filter.values.some((item) => item === value);
  };
export default arrayInFilter;
