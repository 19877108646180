import { GetIncidentByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";

const getNextPage = (
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
  currentPage: string,
): string => {
  const allIncidentTypes = [
    "environmental",
    "equipment",
    "near_miss",
    "property_damage",
    "vehicle",
    "utility",
    "theft",
  ];

  const selectedIncidentTypes: Array<string> = [];
  const nonSelectedIncidentTypes: Array<string> = [];

  for (const type of allIncidentTypes) {
    if (
      incident.incident_types.findIndex(
        (incidentType) => incidentType.type_value === type,
      ) !== -1
    ) {
      selectedIncidentTypes.push(type);
    } else {
      nonSelectedIncidentTypes.push(type);
    }
  }
  const incidentTypes = [...selectedIncidentTypes, ...nonSelectedIncidentTypes];
  const index = incidentTypes.findIndex((type) => type === currentPage);
  if (index + 1 < incidentTypes.length) {
    return incidentTypes[index + 1];
  } else return "notes";
};

export default getNextPage;
