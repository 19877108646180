import React, { useState } from "react";
import { RangeType } from "../../tables/basic/CustomTableDateRangePicker";
import { Dayjs } from "dayjs";
import CustomCheckList from "./CustomCheckList";
import CustomRadioFilter from "./CustomRadioFilter";
import CustomSelectFilter from "./CustomSelectFilter";
import CustomDateRangeFilter from "./CustomDateRangeFilter";
import CustomRadioAndCheck from "./CustomRadioAndCheck";

export type FilterType =
  | "checkbox"
  | "select"
  | "radio"
  | "radioAndCheck"
  | "date-range";
export type CustomCommonProps = {
  removeFilter: () => void;
  title: string;
  type: FilterType;
};
export type CheckboxCustomFilter = Omit<CustomCommonProps, "type"> & {
  type: "checkbox";
  options: Array<{
    option: React.ReactNode | string;
    onApply: () => void;
    onCancel: () => void;
  }>;
};
export type RadioCustomFilter = Omit<CustomCommonProps, "type"> & {
  type: "radio";
  options: Array<{
    option: string;
    onClick: () => void;
  }>;
};
export type SelectCustomFilter = Omit<CustomCommonProps, "type"> & {
  type: "select";
  placeholder: string;
  selectOptions: Array<{ name: string; id: string }>;
  onOk: (ids: Array<string>) => void;
};
export type RadioCheckCustomFilter = Omit<CustomCommonProps, "type"> & {
  type: "radioAndCheck";
  options: Array<{
    option: string;
    optionType: "radio" | "checkbox";
    onCheckApply?: () => void;
    onCheckCancel?: () => void;
    onRadioClick?: () => void;
  }>;
};
export type DateRangeCustomFilter = Omit<CustomCommonProps, "type"> & {
  onOk: (from: Dayjs | null, to: Dayjs | null) => void;
  type: "date-range";
};

export type StateFunctions = {
  appliedFiltersfilterMap: {
    [key: string]:
      | boolean
      | string
      | Array<{ name: string; id: string }>
      | RangeType;
  };
  setAppliedFilter: React.Dispatch<
    React.SetStateAction<{
      [key: string]:
        | boolean
        | string
        | Array<{ name: string; id: string }>
        | RangeType;
    }>
  >;
  setRemovalFilters: React.Dispatch<
    React.SetStateAction<{
      [key: string]: (
        list?: Array<{ name: string; id: string }>,
        listItemToRemove?: string,
      ) => void;
    }>
  >;
};
export type CustomFilterType =
  | CheckboxCustomFilter
  | RadioCustomFilter
  | SelectCustomFilter
  | RadioCheckCustomFilter
  | DateRangeCustomFilter;
export type CustomFilterControllerProps = {
  customFilters: CustomFilterType[];
} & StateFunctions;
const CustomFilterController: React.FunctionComponent<
  CustomFilterControllerProps
> = ({
  setAppliedFilter,
  appliedFiltersfilterMap,
  setRemovalFilters,
  ...props
}) => {
  const [visible, setVisible] = useState(true);

  const handleTitleClick = () => {
    setVisible((prevState) => !prevState);
  };

  return (
    <div className="flex flex-col margin-gap-y-1.5  pt-1">
      {props.customFilters.map((filterProps, i) => {
        if (filterProps.type === "checkbox")
          return (
            <CustomCheckList
              key={i}
              {...{
                ...filterProps,
                setAppliedFilter,
                setRemovalFilters,
                appliedFiltersfilterMap,
              }}
            />
          );
        else if (filterProps.type === "radio") {
          return (
            <CustomRadioFilter
              key={i}
              {...{
                ...filterProps,
                setAppliedFilter,
                setRemovalFilters,
                appliedFiltersfilterMap,
              }}
            />
          );
        } else if (filterProps.type === "select") {
          return (
            <CustomSelectFilter
              key={i}
              {...{
                ...filterProps,
                setAppliedFilter,
                setRemovalFilters,
                appliedFiltersfilterMap,
              }}
            />
          );
        } else if (filterProps.type === "radioAndCheck") {
          return (
            <CustomRadioAndCheck
              key={i}
              {...{
                ...filterProps,
                appliedFiltersfilterMap,
                setRemovalFilters,
                setAppliedFilter,
              }}
            />
          );
        } else if (filterProps.type === "date-range") {
          return (
            <CustomDateRangeFilter
              key={i}
              {...{
                ...filterProps,
                appliedFiltersfilterMap,
                setAppliedFilter,
                setRemovalFilters,
              }}
            />
          );
        }
      })}
    </div>
  );
};

export default CustomFilterController;
