import { FilterData } from "../../ScrollTable";
import { DataRecord } from "../dataScrollTableTypes";
import arrayGetFilterFunc from "./arrayGetFilterFunc";

const arrayFilterData = (
  data: Array<DataRecord>,
  filters: FilterData,
): Array<DataRecord> => {
  const filterFuncs = filters.map(arrayGetFilterFunc);
  return filterFuncs.reduce((data, fn) => data.filter(fn), data);

  // or the same logic but in another order. (check what is faster)
  // return data.filter(record => filterFuncs.every(fn => fn(record)));
};
export default arrayFilterData;
