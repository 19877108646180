import {
  Alert,
  Col,
  Form,
  message,
  Modal,
  notification,
  Row,
  Select,
  Switch,
  Tooltip,
} from "antd";
import { FormInstance, useForm } from "antd/es/form/Form";
import { graphql } from "babel-plugin-relay/macro";
import React, {
  FC,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { HtProjectFilterModalQuery } from "src/common/types/generated/relay/HtProjectFilterModalQuery.graphql";
import { HtProjectFilterModal_updateHtAccount_Mutation } from "src/common/types/generated/relay/HtProjectFilterModal_updateHtAccount_Mutation.graphql";
import {
  HtProjectType,
  useHtGetProjectsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import LoadingContent from "src/common/components/general/LoadingContent";
import StyledContent from "src/common/components/layouts/StyledContent";

const query = graphql`
  query HtProjectFilterModalQuery($htAccountId: uuid!) {
    ht_integration_account_connection(where: { id: { _eq: $htAccountId } }) {
      edges {
        node {
          tenant
          pk: id @__clientField(handle: "pk")
          project_filter
        }
      }
    }
  }
`;

type ProjectIdFilter = {
  include: boolean;
  project_ids: string[];
} | null;

function isProjectFilter(filter: unknown): filter is ProjectIdFilter {
  return (
    typeof filter === "object" &&
    filter !== null &&
    "include" in filter &&
    "project_ids" in filter &&
    typeof filter.include === "boolean" &&
    Array.isArray(filter.project_ids) &&
    filter.project_ids.every((item) => typeof item === "string")
  );
}

function getProjectFilter(filter: unknown): ProjectIdFilter {
  return isProjectFilter(filter) ? filter : null;
}

function isProjectIdIncluded(filter: ProjectIdFilter, projectId: string) {
  if (!filter) return true;
  return filter.project_ids.includes(projectId) === filter.include;
}

const NEW_PROJECT_FIELD_ID = "new_project";

export type HtProjectFilterModalProps = {
  htAccountId: string;
  visible: boolean;
  onClose: () => void;
  onSubmit?: () => void;
};

type ModalFormRef<V extends object> = {
  form: FormInstance<V>;
  projects: {
    __typename?: "HtProjectType" | undefined;
  } & Pick<HtProjectType, "name" | "id">[];
};

type HtProjectFilterModalContentProps = {
  htAccountId: string;
  saving: boolean;
};

function compareStrings(a: string, b: string) {
  return a.localeCompare(b);
}

type FormValues = {
  [sub: string]: any;
};

const HtProjectFilterModalContent = forwardRef<
  ModalFormRef<FormValues>,
  HtProjectFilterModalContentProps
>(({ htAccountId, saving }, ref) => {
  const data = useLazyLoadQuery<HtProjectFilterModalQuery>(
    query,
    {
      htAccountId,
    },
    {
      fetchPolicy: "network-only",
    },
  );

  const accounts = data.ht_integration_account_connection.edges || [];
  if (accounts.length == 0)
    throw new Error(`Ht account does not exist, htAccountId = ${htAccountId}`);
  const [formRef] = useForm();

  const account = accounts[0].node;
  const projectFilter = getProjectFilter(account.project_filter);
  console.log("projectFilter", projectFilter);
  const { data: htProjects, loading } = useHtGetProjectsQuery({
    variables: {
      accountId: account.pk,
    },
    fetchPolicy: "cache-and-network",
  });

  const allProjects = [...(htProjects?.htGetProjects?.projects || [])];
  allProjects.sort((proj1, proj2) =>
    compareStrings(proj1.name ?? "", proj2.name ?? ""),
  );

  useImperativeHandle<ModalFormRef<FormValues>, ModalFormRef<FormValues>>(
    ref,
    () => ({
      form: formRef,
      projects: allProjects,
    }),
  );

  const renderProjectRow = (
    projectId: string,
    projectName: string,
    initialValue: boolean,
  ) => {
    //      console.log(`render row: ${projectName} ${projectId}, ${initialValue}`);
    return (
      <Row
        gutter={16}
        style={{ lineHeight: 2 }}
        key={projectId}
        className="ant-row ant-form-item"
      >
        {
          //          <Col span={12}></Col>
        }
        <Col span={24} style={{ paddingLeft: "40px" }}>
          <Form.Item
            name={projectId}
            initialValue={initialValue}
            noStyle
            valuePropName="checked"
          >
            <Switch
              disabled={saving}
              loading={loading || saving}
              title={projectName}
            />
          </Form.Item>
          <span className="ant-form-text ml-1">{projectName}</span>
        </Col>
      </Row>
    );
  };
  console.log("loading", loading);
  if (loading) {
    return (
      <StyledContent align="center">
        <LoadingContent />
      </StyledContent>
    );
  }
  const projectFields = allProjects.map((project) => {
    return renderProjectRow(
      project.id,
      project.name,
      isProjectIdIncluded(projectFilter, project.id),
    );
  });
  console.log(
    "!projectFilter || !projectFilter.include = ",
    !projectFilter || !projectFilter.include,
    projectFilter,
  );
  const newProjField = renderProjectRow(
    NEW_PROJECT_FIELD_ID,
    "Enable for new projects",
    !projectFilter || !projectFilter.include,
  );
  return (
    <Form form={formRef} layout="vertical">
      <Alert
        style={{ margin: "16px 0" }}
        type="info"
        showIcon={false}
        banner
        message={
          <Row gutter={16} style={{ lineHeight: 2 }}>
            <Col span={24}>Project Name</Col>
          </Row>
        }
      />
      {newProjField}
      {projectFields}
    </Form>
  );
});

const HtProjectFilterModal: FC<HtProjectFilterModalProps> = ({
  htAccountId,
  onSubmit,
  visible,
  onClose,
}) => {
  const modal = useRef<ModalFormRef<FormValues>>(null);
  const [saving, setSaving] = useState(false);
  const [updateHtAccount] =
    useAsyncMutation<HtProjectFilterModal_updateHtAccount_Mutation>(
      graphql`
        mutation HtProjectFilterModal_updateHtAccount_Mutation(
          $htAccountId: uuid!
          $_set: ht_integration_account_set_input!
        ) {
          update_ht_integration_account_by_pk(
            pk_columns: { id: $htAccountId }
            _set: $_set
          ) {
            id
            project_filter
          }
        }
      `,
    );

  return (
    <>
      <Modal
        open={visible}
        cancelText="Cancel"
        title="Hammertech Project Setup"
        okText="Save"
        width={750}
        confirmLoading={saving}
        onCancel={() => {
          onClose?.();
          //setVisible(false);
        }}
        onOk={async () => {
          const form = modal.current?.form;
          if (!form) return;

          try {
            const projects = modal.current?.projects ?? [];

            const userId = auth.currentUser?.uid;
            if (!userId) {
              throw new Error("User not logged in");
            }
            const values = await form.validateFields();
            console.log("values", values);

            setSaving(true);
            try {
              const include = !values[NEW_PROJECT_FIELD_ID];
              const newFilter: ProjectIdFilter = {
                include,
                project_ids: projects
                  .map((p) => p.id)
                  .filter((id) => values[id] === include),
              };
              console.log("new filter", newFilter);

              await updateHtAccount({
                variables: {
                  htAccountId,
                  _set: {
                    project_filter: newFilter,
                  },
                },
              });

              if (onSubmit) onSubmit();
              onClose();
              message.success("Done");
            } finally {
              setSaving(false);
            }
          } catch (e) {
            notification.error({
              message: "Error",
              description: e instanceof Error ? e.message : JSON.stringify(e),
              duration: null,
            });
          }
        }}
      >
        <CustomSuspense>
          <HtProjectFilterModalContent
            ref={modal}
            htAccountId={htAccountId}
            saving={saving}
          />
        </CustomSuspense>
      </Modal>
    </>
  );
};

export default HtProjectFilterModal;
