import { IconCheck } from "@tabler/icons";
import { Button } from "antd";
import dayjs from "dayjs";
import { useCallback } from "react";
import Icon from "src/common/components/general/Icon";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  useInsertProjectWorkersMutation,
  useTodoStatusChangeWithObsMutation,
  useCompleteObsFromTodoMutation,
  Lang_Code_Enum,
  GetObservationOptionsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";

const CompleteObservationButton: React.FC<{
  optionsData: GetObservationOptionsQuery;
  observationId: string;
  obs: {
    status: string;
    complete_marked_at?: string | null;
  };
  projectId: string;
  pendingRequiredReview?: boolean | null;
  iconButton?: boolean;
  todoId?: string | null;
  onCompleted: (pwToInsert?: string) => void;
}> = ({
  optionsData,
  todoId,
  projectId,
  observationId,
  obs,
  onCompleted,
  pendingRequiredReview,
  iconButton,
}) => {
  const [insertProjectWorkers, { loading: insertingWorkers }] =
    useInsertProjectWorkersMutation();
  const [todoStatusChange, { loading: todoUpdating }] =
    useTodoStatusChangeWithObsMutation();
  const { userData } = useUserData();
  const [completeObs, { loading: completing }] =
    useCompleteObsFromTodoMutation();
  const authUser = useAuthUser();
  const completeTodo = useCallback((todoId: string) => {
    todoStatusChange({
      variables: {
        todoAssigneesUpdateSet: {
          completed_at: dayjs().toISOString(),
          marked_complete: true,
        },
        todoAssigneesUpdateWhere: {
          todo_id: { _eq: todoId },
        },
        todoUpdateWhere: { id: { _eq: todoId } },
        todoUpdateSet: { completed_at: dayjs().toISOString() },
      },
    });
  }, []);
  const user = optionsData?.userData;
  const openObs = obs.status === "open";
  if (!user) throw new Error("Logged In User not found");
  return openObs || (pendingRequiredReview && userData.employee) ? (
    <Button
      loading={insertingWorkers || todoUpdating || completing}
      type="primary"
      className="flex"
      onClick={async () => {
        let newPwId: string | undefined;
        if (
          user?.subcontractor_employee &&
          (!user.worker || !user.worker.worker_projects.length)
        ) {
          const res = await insertProjectWorkers({
            variables: {
              objects: [
                {
                  project_id: projectId,
                  subcontractor_id:
                    user.subcontractor_employee.subcontractor_id,
                  worker_role: "foreman",
                  ...(user.worker
                    ? { worker_id: authUser.uid }
                    : {
                        worker: {
                          data: {
                            current_worker_role: "foreman",
                            uid: authUser.uid,
                          },
                        },
                      }),
                },
              ],
            },
          });

          newPwId = res.data?.insert_project_worker?.returning
            ? res.data.insert_project_worker.returning[0]?.id
            : undefined;
        }
        const pwToInsert = newPwId || user?.worker?.worker_projects[0].id;
        await completeObs({
          variables: {
            assigneeWorkerObjs:
              (user?.worker || newPwId) && openObs
                ? [
                    {
                      created_by_uid: authUser.uid,
                      type: "corrected_by",
                      observation_id: observationId,
                      project_worker_id: pwToInsert,
                    },
                  ]
                : [],
            assigneeEmployeeObj:
              user?.employee && openObs
                ? [
                    {
                      created_by_uid: authUser.uid,
                      type: "corrected_by",
                      employee_id: authUser.uid,
                      observation_id: observationId,
                    },
                  ]
                : [],
            obsEdits: {
              comment: {
                data: {
                  lang: Lang_Code_Enum.En,
                  original: `observation ${
                    pendingRequiredReview && userData.employee && openObs
                      ? "was corrected and marked closed and reivewed"
                      : openObs
                      ? "corrected and closed"
                      : pendingRequiredReview && userData.employee
                      ? "reviewed"
                      : ""
                  }`,
                },
              },
              observation_id: observationId,
              edit_type: `completed`,
              prev_val: null,
              new_val: null,
              patch: [
                ...(openObs
                  ? [
                      {
                        op: "remove",
                        path: "/unsafe_observation/corrected_by_users/0",
                      },
                      {
                        op: "replace",
                        path: "/unsafe_observation/is_corrected",
                        value: false,
                      },
                      {
                        op: "replace",
                        path: "/unsafe_observation/corrected_on",
                        value: null,
                      },
                      {
                        op: "replace",
                        path: "/status",
                        value: "open",
                      },
                    ]
                  : []),
                ...(pendingRequiredReview
                  ? [
                      {
                        op: "replace",
                        path: "/complete_marked_at",
                        value: null,
                      },
                      {
                        op: "replace",
                        path: "/complete_marked_by_uid",
                        value: null,
                      },
                    ]
                  : []),
              ], // patch is in reverse format so we can go back in past
              created_at: dayjs().format(),
              edited_by_uid: authUser.uid,
            },
            obsSet: {
              status: "closed",
              ...(userData.employee
                ? {
                    complete_marked_at: dayjs().toISOString(),
                    complete_marked_by_uid: authUser.uid,
                  }
                : {}),
            },
            unsafeObsSet: openObs
              ? {
                  corrected_on: dayjs().toISOString(),
                  is_corrected: true,
                }
              : {},
            obsId: observationId,
          },
        });
        if (onCompleted) {
          onCompleted(pwToInsert);
        }
        if (todoId) completeTodo(todoId);
      }}
    >
      <Icon
        icon={IconCheck}
        color="white"
        hoverContent={
          iconButton
            ? {
                content: openObs
                  ? "Mark Complete"
                  : pendingRequiredReview
                  ? "Mark Verified"
                  : "", // this should be non reachable
              }
            : undefined
        }
      />
      {pendingRequiredReview
        ? "Verify"
        : !iconButton && <span>&nbsp; Complete</span>}
    </Button>
  ) : null;
};
export default CompleteObservationButton;
