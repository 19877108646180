import { graphql } from "babel-plugin-relay/macro";

const updatePrivacySettingsByPkMutation = graphql`
  mutation UpdatePrivacySettingsByPkMutation(
    $id: uuid!
    $set: privacy_setting_set_input!
  ) {
    update_privacy_setting_by_pk(pk_columns: { id: $id }, _set: $set) {
      manager
      id
      supervisor
      employer
    }
  }
`;
export default updatePrivacySettingsByPkMutation;
