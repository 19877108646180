import React from "react";
import { Button } from "antd";
import { ButtonProps } from "antd/es/button";

export interface ButtonHugeProps extends ButtonProps {}

const ButtonHuge: React.FC<ButtonHugeProps> = (props) => {
  return (
    <Button
      className={`flex items-center justify-center`}
      {...{
        block: true,
        style: {
          height: `4.5rem`,
          fontSize: `2rem`,
          minWidth: "4.5rem",
        },
        ...props,
      }}
    />
  );
};

export default ButtonHuge;
