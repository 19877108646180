import handleRequestError, { ErrorMessageConfigType } from "./handleRequestError";

/**
 * A higher-order function that wraps an asynchronous function with error handling logic.
 *
 * @param asyncFunc - The asynchronous function to wrap.
 * @param onErrorConfig - Define what should happen when error occurs.
 *
 * @returns A new function that, when invoked, executes the `asyncFunc` within a try-catch block.
 *
 * Example:
 * const safeAsyncOperation = errorHandler<RiskyOperationProps>(riskyAsyncOperation);
 *
 * // Now you can call safeAsyncOperation without worrying about unhandled exceptions
 * safeAsyncOperation(riskyOperationProps);
 *
 */

// Apollo Client Errors: https://www.apollographql.com/docs/react/data/error-handling/

const safeRequestHandler: <AsyncFunctionProps = void>(
  asyncFunc: (props: AsyncFunctionProps) => Promise<void>,
  onErrorConfig?: ErrorMessageConfigType,
) => (props: AsyncFunctionProps) => Promise<void> = (
  asyncFunc,
  onErrorConfig,
) => {
  return async (props) => {
    try {
      await asyncFunc(props);
    } catch (e) {
      handleRequestError(e, onErrorConfig);
    }
  };
};

export default safeRequestHandler;
