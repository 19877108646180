import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import RequireRoleWrapper from "src/common/components/ReqiureRoleWrapper";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import searchParamsProvider from "src/common/functions/searchParamsProvider";
import NestedRoute from "src/common/types/manual/NestedRoute";
import CsrData from "./root/CsrData";
import CsrEmployee from "./root/CsrEmployee";
import CsrProfile from "./root/CsrProfile";
import CsrProjects from "./CsrProjects";
import CsrProjectWorkerDetail from "./project/CsrProjectWorkerDetail";
import CsrProject from "./project/CsrProject";
import GCProjectOrientationDetail from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/slides/GCProjectOrientationDetail";

const csrRoute: NestedRoute = {
  element: (
    <RequireRoleWrapper requiredRole="customer_portal">
      <CustomSuspense>
        <CsrEmployee />
      </CustomSuspense>
    </RequireRoleWrapper>
  ),
  children: [
    {
      path: "profile",
      element: <CsrProfile />,
      children: [],
    },
    {
      path: "",
      element: searchParamsProvider(CsrData),
      children: [
        {
          path: "",
          element: <Navigate to="tasks" />,
        },
        {
          path: "tasks",
          element: <Outlet />,
          children: [
            { path: "", element: <Navigate to={"projects"} /> },
            {
              path: "projects",
              element: searchParamsProvider(CsrProjects),
            },
            {
              path: "projects/:projectId",
              element: <Outlet />,
              children: [
                { path: "", element: <Navigate to={"subs"} /> },
                {
                  path: ":subview",
                  element: searchParamsProvider(CsrProject),
                },
                {
                  path: "workers/:workerId",
                  element: searchParamsProvider(CsrProjectWorkerDetail),
                },
                {
                  path: "orientations/:orientationId",
                  element: searchParamsProvider(GCProjectOrientationDetail),
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default csrRoute;
