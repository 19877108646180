import React from "react";
import SiteOrientationSignatureUI from "../../signature/SiteOrientationSignatureUI";
import useSignaturePad from "../../../../../common/components/general/signature-canvas/useSignaturePad";

export interface SiteOrientationSlidesViewerSlideSignatureProps {
  lang: string;
  userName: string;
  isProjectSpecific?: boolean;
  onSign: (imageUrl: string) => void;
}

const SiteOrientationSlidesViewerSlideSignature: React.FC<
  SiteOrientationSlidesViewerSlideSignatureProps
> = (props) => {
  const { isEmpty, uploadSignature, signaturePadProps } = useSignaturePad();

  return (
    <div className={`flex justify-between min-h-full flex-col gap-1`}>
      <SiteOrientationSignatureUI
        signaturePadProps={signaturePadProps}
        isSignaturePadEmpty={isEmpty}
        uploadSignature={uploadSignature}
        userName={props.userName}
        disclaimerIsProject={props.isProjectSpecific}
        lang={props.lang}
        onSign={props.onSign}
      />
    </div>
  );
};

export default SiteOrientationSlidesViewerSlideSignature;
