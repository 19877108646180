import React, { useState, useMemo } from "react";
import { Select, Card } from "antd";
import { useSuspenseQuery } from "@apollo/client";
import {
  useInsertTaskOneMutation,
  useUpdateTextTranslationByPkMutation,
  useGetTasksForIncidentQuery,
  Task_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import TextArea from "antd/es/input/TextArea";
import {
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  GetTasksForIncidentQuery,
  GetTasksForIncidentQueryVariables,
  GetTasksForIncidentDocument,
  GetIncidentByPkDocument,
} from "src/common/types/generated/apollo/graphQLTypes";

interface Props {
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>;
  projectId: string;
}

const IncidentTaskUploader: React.FC<Props> = ({ incident, projectId }) => {
  const { data: taskData } = useSuspenseQuery<
    GetTasksForIncidentQuery,
    GetTasksForIncidentQueryVariables
  >(GetTasksForIncidentDocument, {
    variables: {
      where: {
        _or: [
          {
            project_id: { _eq: projectId },
            subcontractor_id: { _is_null: false },
            deleted_at: { _is_null: true },
            is_pending_acceptance: { _eq: false },
            request_state: { _is_null: true },
            report_id: { _is_null: true },
          },
          { incident_id: { _is_null: false } },
        ],
      },
    },
    fetchPolicy: "cache-and-network",
  });

  if (!taskData) throw new Error("task_data is missing");

  const { taskStepsMap } = useMemo(() => {
    const taskStepsMap: {
      [taskId: string]: {
        description: string;
        descriptionId: string;
        steps: Array<{
          id: string;
          description: string;
          descriptionId: string;
        }>;
      };
    } = {};

    taskData?.task.forEach((t) => {
      taskStepsMap[t.id] = {
        description: t.description.en,
        descriptionId: t.description.id,
        steps: t.task_steps.map((step) => ({
          id: step.id,
          description: step.description.en,
          descriptionId: step.description.id,
        })),
      };
    });

    return {
      taskStepsMap,
    };
  }, [taskData]);

  const [newTaskVisible, setNewTaskVisible] = useState(false);
  const [newTaskDescription, setNewTaskDescription] = useState<string>("");
  const incidentTask = incident.task;

  const [taskId, setTaskId] = useState<string | undefined>(
    incidentTask?.id ?? undefined,
  );
  const [taskStepId, setTaskStepId] = useState<string | undefined>(
    incidentTask?.task_steps.at(0)?.id ?? undefined,
  );
  const [insertTaskOne] = useInsertTaskOneMutation();
  const [updateText] = useUpdateTextTranslationByPkMutation();

  const insertIncidentTask = async () => {
    if (!incident.task) {
      let newTaskObject: Task_Insert_Input = {};
      if (newTaskVisible && newTaskDescription) {
        newTaskObject = {
          incident_id: incident.id,
          description: {
            data: { en: newTaskDescription, original: newTaskDescription },
          },
        };
      } else {
        if (!taskId || !taskStepId) return;

        const description = taskStepsMap[taskId].description;
        const taskStepDescription = taskStepsMap[taskId].steps.find(
          (step) => step.id === taskStepId,
        )?.description;

        newTaskObject = {
          incident_id: incident.id,
          description: {
            data: { en: description, original: description },
          },
          task_steps: {
            data: [
              {
                description: {
                  data: {
                    en: taskStepDescription,
                    original: taskStepDescription,
                  },
                },
                sort_index: 1,
              },
            ],
          },
        };
      }

      await insertTaskOne({
        variables: {
          object: newTaskObject,
        },
        update(cache, result) {
          const insertedTask = result.data?.insert_task_one;
          const updatedIncident = { ...incident, task: insertedTask };
          if (insertedTask) {
            cache.writeQuery<
              GetTasksForIncidentQuery,
              GetTasksForIncidentQueryVariables
            >({
              query: GetTasksForIncidentDocument,
              data: {
                __typename: "query_root",
                task: [...taskData.task, insertedTask],
              },
            });
          }
          cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>(
            {
              query: GetIncidentByPkDocument,
              data: {
                __typename: "query_root",
                incident_by_pk: updatedIncident,
              },
            },
          );
        },
      });
    } else {
      if (taskId) {
        const description = taskStepsMap[taskId].description;
        const descriptionId = incident.task.description.id;
        const initialTaskDescription = incident.task.description.en;

        if (description !== initialTaskDescription) {
          await updateText({
            variables: {
              _set: { en: description, original: description },
              pk_columns: { id: descriptionId },
            },
          });
        }

        if (taskStepId) {
          const taskStepDescription = taskStepsMap[taskId].steps.find(
            (step) => step.id === taskStepId,
          )?.description;

          const taskStepDescriptionId =
            incident.task.task_steps[0].description.id;
          const initialTaskStepDescription =
            incident.task.task_steps[0].description.en;

          if (taskStepDescription !== initialTaskStepDescription) {
            await updateText({
              variables: {
                pk_columns: { id: taskStepDescriptionId },
                _set: {
                  en: taskStepDescription,
                  original: taskStepDescription,
                },
              },
            });
          }
        }
      }
    }
  };

  const options = [
    ...Object.keys(taskStepsMap).map((taskId) => ({
      value: taskId,
      label: taskStepsMap[taskId].description,
    })),
    { value: "add_new", label: "Add New JHA" },
  ];

  return (
    <Card className="w-4/5">
      <div className="flex flex-row gap-x-2"></div>
      <div className="flex gap-1 items-center">
        <div className="w-10">Job Hazard Analysis</div>
        <Select
          className="w-full"
          options={options}
          value={taskId}
          onChange={(val) => {
            if (val === "add_new") {
              setNewTaskVisible(true);
            } else {
              setTaskId(val);
            }
          }}
          onBlur={insertIncidentTask}
        />
      </div>

      {newTaskVisible && (
        <div className="flex mt-1 gap-1 items-center">
          <div className="w-10">Enter JHA Description</div>
          <TextArea
            value={newTaskDescription}
            onChange={(e) => setNewTaskDescription(e.currentTarget.value)}
            onBlur={insertIncidentTask}
          />
        </div>
      )}

      {taskId &&
        taskStepsMap[taskId]?.steps &&
        taskStepsMap[taskId].steps.length > 0 && (
          <div className="flex mt-1 gap-1 items-center">
            <div className="w-10">JHA Step</div>
            <Select
              className="w-full"
              options={taskStepsMap[taskId].steps.map((step) => ({
                key: step.id,
                value: step.id,
                label: step.description,
              }))}
              value={taskStepId}
              onChange={(id) => {
                setTaskStepId(id);
              }}
              onBlur={insertIncidentTask}
            />
          </div>
        )}
    </Card>
  );
};

export default IncidentTaskUploader;
