/**
 * @generated SignedSource<<679d22bd39bb999fb96dd1d2e64dfc24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type general_contractor_set_input = {
  agc_universal_orientation?: boolean | null | undefined;
  allow_permit_activation_location_change?: boolean | null | undefined;
  employee_verification?: boolean | null | undefined;
  hierarchy_business_unit_name?: string | null | undefined;
  hierarchy_division_name?: string | null | undefined;
  hierarchy_office_name?: string | null | undefined;
  hot_work_permit_name?: string | null | undefined;
  id?: string | null | undefined;
  in_person_registration_poster_background_url?: string | null | undefined;
  incident_enabled?: boolean | null | undefined;
  logo_url?: string | null | undefined;
  name?: string | null | undefined;
  observation_enabled?: boolean | null | undefined;
  only_submitter_sign_the_confined_space_permit?: boolean | null | undefined;
  only_submitter_sign_the_dig_excavation_permit?: boolean | null | undefined;
  only_submitter_sign_the_dig_permit?: boolean | null | undefined;
  only_submitter_sign_the_historical_access_permit?: boolean | null | undefined;
  only_submitter_sign_the_hot_work_permit?: boolean | null | undefined;
  only_submitter_sign_the_interior_penetration_permit?: boolean | null | undefined;
  only_submitter_sign_the_loto_permit?: boolean | null | undefined;
  only_submitter_sign_the_out_of_basket_permit?: boolean | null | undefined;
  only_submitter_sign_the_steel_erection_permit?: boolean | null | undefined;
  only_submitter_sign_the_work_above_permit?: boolean | null | undefined;
  orientation_qr_code_poster_background_url?: string | null | undefined;
  procore_trade_id?: string | null | undefined;
  procore_vendor_id?: string | null | undefined;
  require_dob?: boolean | null | undefined;
  require_phone?: boolean | null | undefined;
  require_workers_to_sign_out_of_basket_permit?: boolean | null | undefined;
  send_weekly_email?: boolean | null | undefined;
  visitor_log_poster_background_url?: string | null | undefined;
  visitor_waiver_text?: string | null | undefined;
};
export type AGCOrientations_UpdateMutation$variables = {
  _set?: general_contractor_set_input | null | undefined;
  id: string;
};
export type AGCOrientations_UpdateMutation$data = {
  readonly update_general_contractor_by_pk: {
    readonly id: string;
  } | null | undefined;
};
export type AGCOrientations_UpdateMutation = {
  response: AGCOrientations_UpdateMutation$data;
  variables: AGCOrientations_UpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "general_contractor",
    "kind": "LinkedField",
    "name": "update_general_contractor_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AGCOrientations_UpdateMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AGCOrientations_UpdateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "24411924d6f97f007a0bb01364164a47",
    "id": null,
    "metadata": {},
    "name": "AGCOrientations_UpdateMutation",
    "operationKind": "mutation",
    "text": "mutation AGCOrientations_UpdateMutation(\n  $id: uuid!\n  $_set: general_contractor_set_input\n) {\n  update_general_contractor_by_pk(pk_columns: {id: $id}, _set: $_set) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d9d2070db063df53167094ce564ed39d";

export default node;
