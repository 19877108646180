import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SiteOrientationInPersonWorkerRegistrationStepsWhoAreYou from "./components/steps/who-are-you/SiteOrientationInPersonWorkerRegistrationStepsWhoAreYou";
import SiteOrientationInPersonWorkerRegistrationStepsSignature from "./components/steps/signature/SiteOrientationInPersonWorkerRegistrationStepsSignature";
import SiteOrientationOrienteeContext from "../../../../utils/siteOrientationOrienteeContext";
import ErrorMessage from "../../../../../../utility-features/error-handling/components/ErrorMessage";
import {
  OacUserType,
  UserType,
  WorkerType,
} from "../../../../utils/siteOrientationTypes";
import SiteOrientationInPersonWorkerRegistrationStepsCompleted from "./components/steps/completed/SiteOrientationInPersonWorkerRegistrationStepsCompleted";
import SiteOrientationInPersonWorkerStepsHardHatNumber from "./components/steps/hard-hat-number/SiteOrientationInPersonWorkerStepsHardHatNumber";
import dayjs from "dayjs";
import { NULL_ID } from "src/common/functions/nullId";
import SiteFeatureLayoutBody, {
  SiteFeatureLayoutBodyProps,
} from "../../../../../../common/components/layouts/steps-navigation-layout/SiteFeatureLayoutBody";
import userLeavesOrStaysNotification from "../../../../utils/userLeavesOrStaysNotification";
import SiteOrientationWorkerRegistrationStepsEmail from "./components/steps/email/SiteOrientationWorkerRegistrationStepsEmail";
import SiteOrientationInPersonWorkerStepsFullName from "./components/steps/fullName/SiteOrientationInPersonWorkerStepsFullName";
import SiteOrientationInPersonWorkerStepsBirthDate from "./components/steps/birthDate/SiteOrientationInPersonWorkerStepsBirthDate";
import SiteOrientationInPersonWorkerStepsEmployer from "./components/steps/employer/SiteOrientationInPersonWorkerStepsEmployer";
import SiteOrientationInPersonWorkerStepsRole from "./components/steps/role/SiteOrientationInPersonWorkerStepsRole";
import SiteOrientationInPersonWorkerStepsTrade from "./components/steps/trade/SiteOrientationInPersonWorkerStepsTrade";
import SiteOrientationInPersonWorkerStepsPhoneNumber from "./components/steps/phoneNumber/SiteOrientationInPersonWorkerStepsPhoneNumber";
import SiteOrientationInPersonWorkerStepsResidence from "./components/steps/residence/SiteOrientationInPersonWorkerStepsResidence";
import SiteOrientationInPersonWorkerStepsGender from "./components/steps/gender/SiteOrientationInPersonWorkerStepsGender";
import SiteOrientationInPersonWorkerStepsEthnicity from "./components/steps/ethnicity/SiteOrientationInPersonWorkerStepsEthnicity";
import SiteOrientationInPersonWorkerStepsVeteranType from "./components/steps/veteranType/SiteOrientationInPersonWorkerStepsVeteranType";
import SiteOrientationInPersonWorkerRegistrationStepsEmergencyContact from "./components/steps/emergencyContact/SiteOrientationInPersonWorkerRegistrationStepsEmergencyContact";
import SiteOrientationInPersonWorkerStepsWorkYears from "./components/steps/workYears/SiteOrientationInPersonWorkerStepsWorkYears";
import SiteOrientationInPersonWorkerStepsEmployerYears from "./components/steps/yearsWithEmployer/SiteOrientationInPersonWorkerStepsEmployerYears";
import {
  useGetOacDataQuery,
  useWorkerFormDataQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import registrationOptionsFromProjectData from "../../../../utils/registrationOptionsFromProjectData";
import SiteOrientationInPersonWorkerStepsUnion from "./components/steps/union/SiteOrientationInPersonWorkerStepsUnion";
import setUserForOrientation from "src/domain-features/siteorientation/utils/setUserForOrientation";
import useGetOrientationData from "src/domain-features/siteorientation/utils/useGetOrientationData";
import OnlineOrientationCompletion from "src/domain-features/siteorientation/components/OnlineOrientationCompletion";
import SiteOrientationInPersonWorkerStepsDrugTests from "./components/steps/drugTests/SiteOrientationInPersonWorkerStepsDrugTests";
import SiteOrientationInPersonWorkerStepsProfilePhoto from "./components/steps/profilePhoto/SiteOrientationInPersonWorkerStepsProfilePhoto";
import SiteOrientationInPersonWorkerStepsOrientationViewer from "./components/steps/oreintationViewer/SiteOrientationInPersonWorkerStepsOrientationViewer";
import { OrientationCompletionTypeType } from "../../../../siteorientationTypes";
import SiteOrientationInPersonWorkerStepsAge from "./components/steps/age/SiteOrientationInPersonWorkerStepsAge";
import SiteOrientationInPersonWorkerStepsUserType from "./components/steps/userType/SiteOrientationInPersonWorkerStepsUserType";
import SiteOrientationInPersonWorkerStepsCertifications from "./components/steps/certifications/SiteOrientationInPersonWorkerStepsCertifications";
import {
  useSiteFeatureStepsNavigationDirection,
  useSiteFeatureStepsNavigationEffect,
} from "../../../../../../common/components/layouts/steps-navigation-layout/siteFeatureRegistrationNavigationHooks";
import withSiteFeatureNavigationWrapper from "../../../../../../common/components/layouts/steps-navigation-layout/withSiteFeatureNavigationWrapper";
import { withLanguageProvider } from "src/utility-features/i18n/context/languageContext";
import SiteOrientationInPersonWorkerRegistrationStepsHello from "./components/steps/hello/SiteOrientationInPersonWorkerRegistrationStepsHello";
import {
  AVAILABLE_LANGUAGES,
  DEFAULT_LANGUAGE,
} from "../../../../../../utility-features/i18n/languages";
import useLangStrings, {
  useCurrentLanguageState,
} from "../../../../../../utility-features/i18n/context/languageHooks";
import identifyUserForTracking from "../../../../../../utility-features/event-tracking/identifyUserForTracking";

const SiteOrientationInPersonWorkerView: React.FC = (props) => {
  // fetching data
  const [userIdSelected, setUserIdSelect] = useState<string | null>(null);
  const [user, setUser] = useState<WorkerType | OacUserType | UserType | null>(
    null,
  );
  const [isNotInList, setIsNotInList] = useState<boolean | null>(null);
  const projectId = useParams().projectId as string;
  const { data: formData } = useWorkerFormDataQuery({
    variables: { input: { projectId } },
  });
  const { data: oacData, refetch } = useGetOacDataQuery({
    variables: { input: { projectId, userId: user?.id } },
  });
  const data = useGetOrientationData(userIdSelected ?? NULL_ID, projectId);
  const projects = data.project_connection.edges;
  const registrationOptions = registrationOptionsFromProjectData(data);
  const [language, setLanguage] = useCurrentLanguageState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const completionType = searchParams.get("completionType");
  const orientationType: OrientationCompletionTypeType =
    completionType === "inpersonComplete" ? completionType : "inperson";
  const resetUser = () => {
    setUser(null);
    setUserIdSelect(NULL_ID);
  };

  useEffect(() => {
    const funcToSetUser = async () => {
      const userFound = data.user_connection.edges[0]?.node;
      if (userIdSelected && userFound) {
        if (userFound.employee) {
          await refetch({ input: { userId: userIdSelected, projectId } });
        }
        const newUser = setUserForOrientation(
          userFound,
          userIdSelected,
          projectId,
          data,
          oacData?.getOACDataQuery,
        );
        if (newUser) {
          setUser((u) =>
            u
              ? {
                  ...newUser,
                  viewerShown: u.id === newUser.id ? u.viewerShown : false,
                  signatureImageUrl:
                    u.id === newUser.id ? u.signatureImageUrl : undefined,
                }
              : newUser,
          );
        }
      } else setUser(null);
    };
    funcToSetUser();
  }, [data, userIdSelected, oacData?.getOACDataQuery]);
  const { clearStepDirection, nextStepDirection, goToPrevStep, goToNextStep } =
    useSiteFeatureStepsNavigationDirection();

  const steps: SiteFeatureLayoutBodyProps["steps"] = [
    {
      title: "Hello",
      body: (
        <SiteOrientationInPersonWorkerRegistrationStepsHello
          onNext={goToNextStep}
          gcLogoUrl={formData?.workerFormQuery?.logoToShow}
          projectName={data.project_connection.edges[0].node.name}
          gcName={data.project_connection.edges[0].node.general_contractors
            .map((p) => p.general_contractor.name)
            .join(", ")}
        />
      ),
      id: "hello",
    },
    {
      title: `Introduction`,
      body: (
        <SiteOrientationInPersonWorkerRegistrationStepsWhoAreYou
          onUserIsNew={(placeholderUser) => {
            console.log(placeholderUser);
            setUser(placeholderUser || null);
            setIsNotInList(true);
            setUserIdSelect(NULL_ID);
          }}
          onUserIdSelected={() => {
            setIsNotInList(false);
          }}
          onNext={goToNextStep}
          onBack={goToPrevStep}
        />
      ),
      id: `introduction`,
    },
    {
      title: "Email",
      id: "email",
      body: (
        <SiteOrientationWorkerRegistrationStepsEmail
          managerDevice
          onNoEmailPress={() => {
            goToNextStep();
            if (isNotInList) {
              resetUser();
            }
          }}
          onNext={goToNextStep}
          onBack={goToPrevStep}
        />
      ),

      disabled: !!user?.email,
    },

    {
      title: "Name",
      id: "full_name",
      body: (
        <SiteOrientationInPersonWorkerStepsFullName
          onNext={goToNextStep}
          onBack={goToPrevStep}
        />
      ),
      disabled: !isNotInList || !!user?.name?.old,
    },
    {
      title: "Birth date",
      id: "birth_date",
      body: (
        <SiteOrientationInPersonWorkerStepsBirthDate
          onNext={goToNextStep}
          onBack={goToPrevStep}
        />
      ),
      disabled: !(user?.email
        ? registrationOptions.dobRequired && !user.birthDate
        : !user?.birthDate),
    },
    //EMAIL-->NAME-->BIRTHDATE-->THEY SHOULD BE IN THIS ORDER
    {
      title: "User Type",
      id: "user_type",
      body: (
        <SiteOrientationInPersonWorkerStepsUserType
          onNext={goToNextStep}
          onBack={goToPrevStep}
        />
      ),
      disabled: !isNotInList || !!user?.id,
    },
    {
      title: "Employer",
      id: "employer",
      body: (
        <SiteOrientationInPersonWorkerStepsEmployer
          onNext={goToNextStep}
          onBack={goToPrevStep}
          workerOptions={formData?.workerFormQuery?.projectSubs || []}
          oacCompanyOptions={oacData?.getOACDataQuery?.oacCompanies ?? []}
          gcOptions={oacData?.getOACDataQuery?.generalContractors ?? []}
        />
      ),
      disabled: !isNotInList,
    },
    {
      title: "Role (level)",
      id: "role",
      body: (
        <SiteOrientationInPersonWorkerStepsRole
          onNext={goToNextStep}
          onBack={goToPrevStep}
          options={formData?.workerFormQuery?.roles || []}
        />
      ),
      disabled: !isNotInList || user?.userType === "oacUser",
    },
    {
      title: "Trade (title)",
      id: "trade",
      body: (
        <SiteOrientationInPersonWorkerStepsTrade
          onNext={goToNextStep}
          onBack={goToPrevStep}
          oacTrades={oacData?.getOACDataQuery?.oacTitles || []}
          employeeTrades={oacData?.getOACDataQuery?.employeeTitles || []}
          suggestedTrades={(formData?.workerFormQuery?.titles || []).filter(
            (p) =>
              user &&
              user.company?.companyWorkerTitles.find((x) => x.id === p.id),
          )}
          otherTrades={(formData?.workerFormQuery?.titles || []).filter(
            (p) =>
              !(
                user &&
                (user.company?.companyWorkerTitles || []).find(
                  (x) => x.id === p.id,
                )
              ),
          )}
        />
      ),
      disabled: !isNotInList,
    },
    {
      title: "Union",
      id: "union",
      body: (
        <SiteOrientationInPersonWorkerStepsUnion
          onNext={goToNextStep}
          onBack={goToPrevStep}
          unions={formData?.workerFormQuery?.laborUnions || []}
        />
      ),
      disabled:
        user?.userType !== "worker" ||
        !registrationOptions.unionLabor ||
        !!user.unionChapterId,
    },
    {
      title: "Phone number",
      id: "phone_number",
      body: (
        <SiteOrientationInPersonWorkerStepsPhoneNumber
          orientations={data.orientation_connection.edges}
          onNext={goToNextStep}
          onBack={goToPrevStep}
        />
      ),
    },
    ...(user?.id
      ? [
          ...(user?.userType === "worker"
            ? [
                {
                  title: "Residence",
                  id: "residence",
                  body: (
                    <SiteOrientationInPersonWorkerStepsResidence
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  disabled: !registrationOptions.residenceRequired,
                },
                {
                  title: `Gender`,
                  id: `gender`,
                  body: (
                    <SiteOrientationInPersonWorkerStepsGender
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                      options={[
                        { name: "Male", value: "M" },
                        { name: "Female", value: "F" },
                        { name: "Other", value: "O" },
                      ]}
                    />
                  ),
                  disabled:
                    !registrationOptions.genderRequired || !!user.gender?.old,
                },
                {
                  title: `Ethnicity`,
                  body: (
                    <SiteOrientationInPersonWorkerStepsEthnicity
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  id: `ethnicity`,
                  disabled:
                    !registrationOptions.ethnicityRequired ||
                    !!user.ethnicity?.old,
                },
                {
                  title: `Veteran type`,
                  body: (
                    <SiteOrientationInPersonWorkerStepsVeteranType
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  id: `veteran_type`,
                  disabled:
                    !registrationOptions.veteranTypeRequired ||
                    !!user?.veteranType?.old,
                },
                {
                  title: `Age`,
                  body: (
                    <SiteOrientationInPersonWorkerStepsAge
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  id: `age`,
                  disabled:
                    !registrationOptions.ageRequired ||
                    !!user.birthDate?.old ||
                    !!user?.age?.old?.val,
                },
                {
                  title: "Emergency contact",
                  body: (
                    <SiteOrientationInPersonWorkerRegistrationStepsEmergencyContact
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  id: `emergency_contact`,
                  disabled: !registrationOptions.emergencyContactRequired,
                },
                {
                  title: "Years in construction",
                  body: (
                    <SiteOrientationInPersonWorkerStepsWorkYears
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  id: `work_years`,
                  disabled:
                    !registrationOptions.yearsInConstructionRequired ||
                    (user.workYears?.old?.enteredAt
                      ? user.workYears.old.enteredAt
                          .add(3, "years")
                          .isAfter(dayjs())
                      : false),
                },
                {
                  title: "Years with employer",
                  body: (
                    <SiteOrientationInPersonWorkerStepsEmployerYears
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  id: `employer_years`,
                  disabled: !registrationOptions.yearsWithEmployerRequired,
                },
              ]
            : []),
          {
            title: "Your photo",
            id: "photo",
            body: (
              <SiteOrientationInPersonWorkerStepsProfilePhoto
                onNext={goToNextStep}
                onBack={goToPrevStep}
              />
            ),
            disabled:
              user?.userType === "oacUser" &&
              !registrationOptions.profilePhotoRequired,
          },
          ...(user?.userType === "worker"
            ? [
                {
                  title: "Certifications",
                  id: "certifications",
                  body: (
                    <SiteOrientationInPersonWorkerStepsCertifications
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                      managerDevice
                    />
                  ),
                  disabled: !registrationOptions.certificationsRequired,
                },
                {
                  title: "Drug test",
                  id: "drug_test",
                  body: (
                    <SiteOrientationInPersonWorkerStepsDrugTests
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  disabled:
                    !(
                      registrationOptions.drugTest.performingDrugTest &&
                      registrationOptions.drugTest.uploadRequired
                    ) ||
                    // if user has DT result present on  all linked projects
                    Object.keys(user.projectsToRegister ?? {}).every(
                      (p) =>
                        user.drugTests && user.drugTests?.dtPassedProjects[p],
                    ),
                },
              ]
            : []),
          {
            title: "Hard hat number",
            body:
              user?.id && projectId ? (
                <SiteOrientationInPersonWorkerStepsHardHatNumber
                  onNext={goToNextStep}
                  onBack={goToPrevStep}
                  userId={user.id}
                  projectId={projectId}
                />
              ) : (
                <ErrorMessage />
              ),
            id: "hard_hat_number",
            disabled:
              !registrationOptions.hardHatNumber?.required ||
              !registrationOptions.hardHatNumber?.addHHDuringInPerson,
          },
          ...((registrationOptions.hasSlides &&
            !user?.onlineOrientationCompleted) ||
          !user.corporateOrientationPassed ||
          (registrationOptions.hasUniversal && !user?.universalCompleted)
            ? [
                {
                  title: "Orientation",
                  body: (
                    <SiteOrientationInPersonWorkerStepsOrientationViewer
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  id: "orientation",
                },
              ]
            : []),
          ...(user?.viewerShown
            ? []
            : [
                {
                  title: `Signature`,
                  body: (
                    <SiteOrientationInPersonWorkerRegistrationStepsSignature
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  id: `signature`,
                },
              ]),
          {
            title: "Completed",
            body: user.viewerShown ? (
              <OnlineOrientationCompletion
                userId={user?.id}
                projectId={projectId}
                hasUniversal={registrationOptions.hasUniversal}
                type={orientationType}
                lang={language}
                canLeaveWhenPrompted={
                  registrationOptions.hasUniversal
                    ? user?.universalCompleted
                    : user?.corporateOrientationPassed
                }
                onDone={() => {
                  const userName = user.name?.new || user.name?.old;
                  if (userName && user?.id) {
                    userLeavesOrStaysNotification(
                      userName,
                      user.id,
                      registrationOptions.hasUniversal,
                      (registrationOptions.hasUniversal
                        ? user?.universalCompleted
                        : user?.corporateOrientationPassed) || false,
                    );
                  }
                  resetUser();
                  setLanguage(DEFAULT_LANGUAGE);
                  goToStepById("hello");
                }}
              />
            ) : (
              <SiteOrientationInPersonWorkerRegistrationStepsCompleted
                managerDevice
                onDoneClick={() => {
                  const userName = user?.name?.new || user?.name?.old;
                  if (userName && user?.id) {
                    userLeavesOrStaysNotification(
                      userName,
                      user.id,
                      registrationOptions.hasUniversal,
                      (registrationOptions.hasUniversal
                        ? user?.universalCompleted
                        : user?.corporateOrientationPassed) || false,
                    );
                  }
                  resetUser();
                  setLanguage(AVAILABLE_LANGUAGES[0]);
                  goToStepById("hello");
                }}
              />
            ),
            id: "completed",
          },
        ]
      : []),
  ];

  const { currentStepId, goToStepById } = useSiteFeatureStepsNavigationEffect(
    nextStepDirection,
    clearStepDirection,
    steps,
  );

  const onCloseClick = () => {
    navigate(
      `/gce/orientation/${projectId}/manager/finished${
        completionType ? "?completionType=inpersonComplete" : ""
      }`,
    );
  };

  useEffect(() => {
    if (userIdSelected) {
      identifyUserForTracking({
        id: userIdSelected,
        fullName: user?.name?.new || user?.name?.old,
        role: user?.userType,
        email: user?.email ?? undefined,
      });
    }
  }, [userIdSelected, user]);

  if (!projectId) {
    return <ErrorMessage message={`Project not found`} />;
  }

  return (
    <>
      <SiteOrientationOrienteeContext.Provider
        value={{
          projectId,
          type: orientationType,
          orientee: user ?? undefined,
          updateSelectedUserId: setUserIdSelect,
          updateOrientee: setUser,
          registrationOptions,
        }}
      >
        <SiteFeatureLayoutBody
          steps={steps}
          currentStepId={currentStepId || undefined}
          onClose={onCloseClick}
          languagePicker={{
            availableLangs: AVAILABLE_LANGUAGES,
            selectedLang: language,
            onLangeChange: setLanguage,
          }}
        />
      </SiteOrientationOrienteeContext.Provider>
    </>
  );
};

export default withSiteFeatureNavigationWrapper(
  withLanguageProvider(SiteOrientationInPersonWorkerView),
);
