/**
 * @generated SignedSource<<d27c9c2d331573aab5a70890c16b76a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type project_set_input = {
  accept_subcontractor_uploaded_drugtest?: boolean | null | undefined;
  add_daily_work_log_during_ptp?: boolean | null | undefined;
  add_hard_hat_during_in_person?: boolean | null | undefined;
  add_hard_hat_during_registration?: boolean | null | undefined;
  address_id?: string | null | undefined;
  agc_universal_orientation?: boolean | null | undefined;
  ask_age?: boolean | null | undefined;
  ask_emergency_contact?: boolean | null | undefined;
  ask_ethnicity?: boolean | null | undefined;
  ask_gender?: boolean | null | undefined;
  ask_number_of_years_in_construction?: boolean | null | undefined;
  ask_residence?: boolean | null | undefined;
  ask_veteran_type?: boolean | null | undefined;
  ask_years_with_employer?: boolean | null | undefined;
  assign_hard_hat?: boolean | null | undefined;
  automatically_assign_hard_hat?: boolean | null | undefined;
  background_check_tracker?: boolean | null | undefined;
  completed?: boolean | null | undefined;
  created_at?: string | null | undefined;
  dashboard_updated_at?: string | null | undefined;
  drug_test_retest_days?: number | null | undefined;
  drugtest_qr_code_url?: string | null | undefined;
  drugtest_validity_days?: number | null | undefined;
  enable_third_party_firewatch?: boolean | null | undefined;
  end_date?: string | null | undefined;
  external_id?: string | null | undefined;
  gc_business_unit_id?: string | null | undefined;
  gc_complete_orientation_in_person_qr_code_url?: string | null | undefined;
  gc_division_id?: string | null | undefined;
  gc_office_id?: string | null | undefined;
  gc_orientation_login_required?: boolean | null | undefined;
  gc_secret_qr_code_url?: string | null | undefined;
  general_contractor_id?: string | null | undefined;
  historical_duration_type?: string | null | undefined;
  id?: string | null | undefined;
  in_person_orientation?: boolean | null | undefined;
  is_dashboard_active?: boolean | null | undefined;
  is_historical_access_permit_enabled?: boolean | null | undefined;
  is_project_reporting_active?: boolean | null | undefined;
  is_sitedelivery_active?: boolean | null | undefined;
  is_sitedelivery_approval_needed?: boolean | null | undefined;
  is_sitedelivery_block_out_active?: boolean | null | undefined;
  is_sitedelivery_on?: boolean | null | undefined;
  is_siteupdate_daily_email_active?: boolean | null | undefined;
  is_visitor_log_and_tracking_active?: boolean | null | undefined;
  is_visitor_log_procore_integration_enabled?: boolean | null | undefined;
  ladder_inspection_notify_time?: string | null | undefined;
  logo_url?: string | null | undefined;
  mark_sub_onsite_on_report_submission?: boolean | null | undefined;
  morning_man_power_report_email_time?: string | null | undefined;
  name?: string | null | undefined;
  next_hard_hat_number?: number | null | undefined;
  only_submitter_sign_the_permit?: boolean | null | undefined;
  orientation_project_id?: string | null | undefined;
  orientation_qr_code_url?: string | null | undefined;
  orientation_tv_player?: boolean | null | undefined;
  pause_dash_notification_end_date?: string | null | undefined;
  pause_dash_notification_start_date?: string | null | undefined;
  pemit_contact_project_employee_id?: string | null | undefined;
  perform_drugtest?: boolean | null | undefined;
  permit_checklist_gc_id?: string | null | undefined;
  prevent_confined_space_submission?: boolean | null | undefined;
  prevent_dig_submission?: boolean | null | undefined;
  prevent_hot_work_submission?: boolean | null | undefined;
  prevent_work_above_submission?: boolean | null | undefined;
  qr_code_url?: string | null | undefined;
  require_certification_upload?: boolean | null | undefined;
  require_certs?: boolean | null | undefined;
  require_dob?: boolean | null | undefined;
  require_drugtest_to_complete_orientation?: boolean | null | undefined;
  require_drugtest_upload?: boolean | null | undefined;
  require_phone?: boolean | null | undefined;
  require_profile_photo?: boolean | null | undefined;
  required_quiz_percentage?: number | null | undefined;
  safety_report_awareness_disabled?: boolean | null | undefined;
  send_corporate_video_completion_report?: boolean | null | undefined;
  send_email_for_drug_test_worker_arrival?: boolean | null | undefined;
  send_verification_invite?: boolean | null | undefined;
  show_onsite_for_not_oriented_workers?: boolean | null | undefined;
  siteupdate_time?: string | null | undefined;
  start_date?: string | null | undefined;
  timezone?: string | null | undefined;
  union_labor?: boolean | null | undefined;
  updated_at?: string | null | undefined;
  visitor_sign_waiver?: boolean | null | undefined;
  visitor_waiver_text?: string | null | undefined;
  worker_scan_in_person_qr_code?: boolean | null | undefined;
};
export type GCProjectLinking_AddOrientationProject_Mutation$variables = {
  _set?: project_set_input | null | undefined;
  newLinkedProjectIds: ReadonlyArray<string>;
};
export type GCProjectLinking_AddOrientationProject_Mutation$data = {
  readonly update_project: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly orientation_project_id: string;
    }>;
  } | null | undefined;
};
export type GCProjectLinking_AddOrientationProject_Mutation = {
  response: GCProjectLinking_AddOrientationProject_Mutation$data;
  variables: GCProjectLinking_AddOrientationProject_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "_set"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "newLinkedProjectIds"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "newLinkedProjectIds"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_mutation_response",
    "kind": "LinkedField",
    "name": "update_project",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "project",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "orientation_project_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectLinking_AddOrientationProject_Mutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCProjectLinking_AddOrientationProject_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "44812bbdccbbfeebfb42f4c93110a9ef",
    "id": null,
    "metadata": {},
    "name": "GCProjectLinking_AddOrientationProject_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectLinking_AddOrientationProject_Mutation(\n  $_set: project_set_input\n  $newLinkedProjectIds: [uuid!]!\n) {\n  update_project(_set: $_set, where: {id: {_in: $newLinkedProjectIds}}) {\n    returning {\n      name\n      id\n      orientation_project_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "904ec63cb7d2543377b5075569f47311";

export default node;
