import { TablerIcon } from "@tabler/icons";
import * as React from "react";
import ReportsTab from "src/common/components/general/ReportsTab";
import useActiveRoute from "src/common/hooks/useActiveRoute";

export type ReportsTabIdType =
  | string
  | "toolbox-talks"
  | "permits"
  | "pretaskplans"
  | "daily"
  | "reports-download"
  | "covid19";

export type ReportsTabType = {
  id: ReportsTabIdType;
  label: string;
  icon?: TablerIcon;
};

interface ReportsTabsGroupProps {
  tabs: ReportsTabType[];
  selectedTabId?: string;
  onTabClick: (id: ReportsTabIdType) => void;
  tabsType: "button" | "link";
}

interface ReportsTabWrapperProps {
  label: string;
  relativePath: string;
  onClick: () => void;
  icon?: TablerIcon;
}

const ReportsTabWrapper: React.FunctionComponent<ReportsTabWrapperProps> = (
  props
) => {
  const isActive = useActiveRoute(props.relativePath);
  return (
    <ReportsTab
      {...{
        isActive,
        label: props.label,
        icon: props.icon,
        onClick: props.onClick,
      }}
    />
  );
};

const ReportsTabsGroup: React.FunctionComponent<ReportsTabsGroupProps> = (
  props
) => {
  return (
    <div className={`w-full flex flex-row items-end`}>
      <div className={`bg-suplementary-3 flex-grow h-px`}></div>
      {props.tabs.map(({ label, id, icon }) =>
        props.tabsType === "link" ? (
          <ReportsTabWrapper
            key={id}
            {...{
              onClick: () => {
                props.onTabClick(id);
              },
              label,
              icon,
              relativePath: id,
            }}
          />
        ) : (
          <ReportsTab
            key={id}
            {...{
              isActive: id === props.selectedTabId,
              label,
              onClick: () => {
                props.onTabClick(id);
              },
            }}
          />
        )
      )}
      <div className={`bg-suplementary-3 w-2 h-px`}></div>
    </div>
  );
};

export default ReportsTabsGroup;
