import { NavigateFunction } from "react-router-dom";
import { NavigationMenuItemProps } from "src/common/components/NavigationMenuItem";

export const getLevelsCommonNavigationItems = (
  navigate: NavigateFunction,
  showIncident?: boolean,
): NavigationMenuItemProps[] => [
  {
    label: "All Projects",
    rootPath: "projects",
    onClick: () => {
      navigate("projects");
    },
  },

  {
    label: "Workers",
    rootPath: "workers",
    onClick: () => {
      navigate("workers");
    },
  },
  ...(showIncident
    ? [
        {
          label: "Incident Reporting",
          rootPath: "incident-reporting",
          onClick: () => {
            navigate("incident-reporting");
          },
        },
      ]
    : []),
  {
    label: "Admins",
    rootPath: "teams",
    onClick: () => {
      navigate("teams");
    },
  },
  {
    label: "Settings",
    rootPath: "settings",
    onClick: () => {
      navigate("settings");
    },
  },
];
