import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useUpsertCrewDataMutation } from "src/common/types/generated/relay/useUpsertCrewDataMutation.graphql";

const upsertCrewDataMutation = graphql`
  mutation useUpsertCrewDataMutation(
    $projectWorkerWhere: project_worker_bool_exp!
    $prevLeadsProjectCrewWhere: project_crew_bool_exp!
    $setProjectWorkers: project_worker_set_input
    $newCrewObject: project_crew_insert_input!
  ) {
    update_project_crew(
      where: $prevLeadsProjectCrewWhere
      _set: { lead_foreman_project_worker_id: null }
    ) {
      affected_rows
    }
    insert_project_crew_one(object: $newCrewObject) {
      ...CrewFrag @relay(mask: false)
    }
    update_project_worker(
      where: $projectWorkerWhere
      _set: $setProjectWorkers
    ) {
      affected_rows
      returning {
        id
        leading_project_crews_aggregate {
          aggregate {
            count
          }
        }
        project_crew {
          id
          pk: id @__clientField(handle: "pk")
          name
          lead_foreman_project_worker {
            id
            pk: id @__clientField(handle: "pk")
            user {
              name
            }
          }
        }
      }
    }
  }
`;
const useUpsertCrewData = () =>
  useAsyncMutation<useUpsertCrewDataMutation>(upsertCrewDataMutation);
export default useUpsertCrewData;
