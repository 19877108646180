import { graphql } from "babel-plugin-relay/macro";

const updateProjectWorkersMutation = graphql`
  mutation UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation(
    $projectIds: [uuid!]!
    $workerId: uuid!
    $newSubId: uuid!
    $_set: project_worker_set_input!
    $newUpsertObjects: [project_worker_insert_input!]!
  ) {
    u1: update_project_worker(
      where: {
        project_id: { _in: $projectIds }
        worker_id: { _eq: $workerId }
        subcontractor_id: { _neq: $newSubId }
      }
      _set: $_set
    ) {
      affected_rows
    }
    u2: insert_project_worker(
      objects: $newUpsertObjects
      on_conflict: {
        constraint: project_worker_project_id_worker_id_subcontractor_id_key
        update_columns: [
          title_id
          worker_role
          archived_at
          is_last
          deleted_at
          hard_hat_number
        ]
      }
    ) {
      returning {
        id
        pk: id @__clientField(handle: "pk")
      }
    }
  }
`;
export default updateProjectWorkersMutation;
