import React from "react";
import BaseTable, {
  BaseTableProps,
} from "src/common/components/tables/basic/BaseTable";
import { GetReportByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";
import styled from "styled-components";
import TableFooterTotal from "./TableFooterTotal";

// TODO add this to tables
type DataProp = NonNullable<
  GetReportByPkQuery["report_by_pk"]
>["report_project_hazards"][number];

type Props = Omit<BaseTableProps<Partial<DataProp>>, "columns"> & {
  onDelete?: (r: DataProp) => any;
};
type ReportHazardsTable = (p: Props) => JSX.Element;

const ReportHazardsTable = styled(BaseTable).attrs(
  ({ pagination, onDelete, ...props }: Props) => {
    const columns = [
      {
        title: "Description",
        key: "description",
        dataIndex: ["project_hazard", "description", "clientText"],
      },
      {
        title: "Image",
        key: "image",
        dataIndex: ["project_hazard", "images"],
        render: (images: Array<any>) =>
          images.length > 0 ? (
            <img style={{ maxHeight: 400 }} src={images[0].url} />
          ) : null,
      },
      {
        title: "Response",
        key: "response",
        dataIndex: ["answer", "clientText"],
      },
    ];

    return {
      ...props,
      columns,
      rowKey: (w: DataProp) => w.project_hazard.id,
      summary: () =>
        pagination && (
          <TableFooterTotal cols={columns.length} total={pagination.total} />
        ),
    };
  },
)``;

export default ReportHazardsTable as ReportHazardsTable;
