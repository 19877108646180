import { ApolloProvider } from "@apollo/client";
import React, { FC, useEffect, useState } from "react";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import { useRoutes } from "react-router-dom";
import { createRelayEnvironment } from "src/common/api/relay/RelayEnvironment";
import apolloClient from "../common/api/apollo/apolloClient";
import rootRoute from "./routes/rootRoute";

import * as Sentry from "@sentry/react";
import useUnhandledException from "../utility-features/error-handling/useUnhandledException";
import {
  useAuth,
  withAuthProvider,
} from "src/utility-features/authorization/AuthProvider";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import CustomSuspense from "src/common/components/general/CustomSuspense";

const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes);

const App: FC = () => {
  const auth = useAuth();

  const [relayEnv, setRelayEnv] = useState<RelayModernEnvironment>();
  useUnhandledException();

  useEffect(() => {
    const res = createRelayEnvironment();
    // console.log('set env', !!res.relayEnvironment, Object.keys(res));
    setRelayEnv(res.relayEnvironment);

    return () => {
      res.subscriptionClient.unsubscribeAll();
      res.subscriptionClient.close();
    };
  }, [auth.uid]);

  const routesElement = useSentryRoutes([
    {
      path: "/",
      ...rootRoute,
    },
  ]);

  if (!relayEnv) return null;

  return (
    <RelayEnvironmentProvider environment={relayEnv}>
      <ApolloProvider client={apolloClient}>
        <CustomSuspense>
          <div className="w-full h-screen">{routesElement}</div>
        </CustomSuspense>
      </ApolloProvider>
    </RelayEnvironmentProvider>
  );
};

/*git-try*/
export default withAuthProvider(App);
