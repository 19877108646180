import React from "react";

export interface CardWrapperProps {
  children: React.ReactNode;
}

const CardWrapper: React.FC<CardWrapperProps> = (props) => {
  return (
    <div className="w-full flex flex-col gap-1 rounded-1 bg-white shadow p-1.5 h-auto">
      {props.children}
    </div>
  );
};

export default CardWrapper;
