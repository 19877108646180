import React, { PropsWithChildren } from "react";
import BaseUserLayout from "src/common/components/layouts/BaseUserLayout";

const AdminView: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <BaseUserLayout
      headerMenuItems={[
        {
          path: "/admin/data",
          title: "Data",
        },
        {
          path: "/admin/profile",
          title: "Your Profile",
        },
      ]}
    >
      {children}
    </BaseUserLayout>
  );
};

export default AdminView;
