import { Button, Card, DatePicker, Form, Image, Select } from "antd";
import Meta from "antd/lib/card/Meta";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import CustomButton from "src/common/components/general/Button";
import StyledContent from "src/common/components/layouts/StyledContent";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { VerifyCertsImageMutation } from "src/common/types/generated/relay/VerifyCertsImageMutation.graphql";
import { VerifyCertsMutation } from "src/common/types/generated/relay/VerifyCertsMutation.graphql";
import { VerifyCertsQuery } from "src/common/types/generated/relay/VerifyCertsQuery.graphql";
import ProfilPicButton from "./components/ProfilePicButton";
import RejectAndArchiveButtons from "./components/RejectAndArchiveButtons";
import VerifyMultipleCertsModal from "./components/VerifyMultipleCertsModal";
import { useEmailWorkerCertsMutation } from "src/common/types/generated/apollo/graphQLTypes";
import getNormalOptionsFilter from "src/common/functions/getNormalOptionsFilter";

interface VerifyCertsProps {
  workerId: string;
  loggedInUserId: string;
  certId?: string;
}

const query = graphql`
  query VerifyCertsQuery($where: certificates_to_verify_bool_exp!) {
    certificates_to_verify_connection(
      where: $where
      order_by: { created_at: desc }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          front_image_id
          front_image {
            url
            md_url
            lg_url
            sm_url
            blurhash
          }
          back_image_id
          back_image {
            url
            md_url
            lg_url
            sm_url
            blurhash
          }
          worker_id
          project_id
          worker {
            user {
              name
            }
          }
        }
      }
    }
    reason_to_reject_connection(order_by: { reason: asc }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          reason
          type
        }
      }
    }
    certification_connection(
      where: { is_custom: { _eq: false } }
      order_by: { name: asc }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name
        }
      }
    }
  }
`;

export const insertVerification = graphql`
  mutation VerifyCertsMutation(
    $certId: uuid!
    $time: timestamptz!
    $verifierId: uuid!
    $certificationId: uuid
    $objects: [worker_certification_insert_input!]!
  ) {
    update_certificates_to_verify(
      where: { id: { _eq: $certId } }
      _set: {
        verified_at: $time
        verified_by_uid: $verifierId
        certification_id: $certificationId
      }
    ) {
      affected_rows
    }
    insert_worker_certification(objects: $objects) {
      affected_rows
      returning {
        id
        pk: id @__clientField(handle: "pk")
      }
    }
  }
`;

const updateImageData = graphql`
  mutation VerifyCertsImageMutation($imageId: uuid!, $parentId: uuid!) {
    update_image(
      where: { id: { _eq: $imageId } }
      _set: { parent_id: $parentId }
    ) {
      affected_rows
    }
  }
`;
const VerifyCerts: FC<VerifyCertsProps> = ({
  workerId,
  loggedInUserId,
  certId,
}) => {
  const data = useLazyLoadQuery<VerifyCertsQuery>(query, {
    where: {
      worker_id: { _eq: workerId },
      ...(!certId ? {} : { id: { _eq: certId } }),
      verified_at: { _is_null: true },
      document: { _eq: "certificate" },
      status: { _eq: "submitted" },
    },
  });
  const [show, setShow] = useState(0);
  const [form] = Form.useForm();
  const [frontPDF, setFrontPDF] = useState(false);
  const [backPDF, setBackPDF] = useState(false);
  if (!data.certificates_to_verify_connection.edges[0]?.node)
    throw new Error(
      "No Certificates exists for the corresponding worker_id = " + workerId,
    );

  const navigate = useNavigate();
  const [openMutlipleCertsModal, setOpenMultipleCertsModal] = useState(false);

  const [verifyMutation, isVerifying] =
    useAsyncMutation<VerifyCertsMutation>(insertVerification);
  const [updateImages, isUpdating] =
    useAsyncMutation<VerifyCertsImageMutation>(updateImageData);
  const [emailWorkerCerts, { loading: isSendingEmail }] =
    useEmailWorkerCertsMutation();
  const totalCerts = data.certificates_to_verify_connection.edges.length;
  const certsData = data.certificates_to_verify_connection.edges[0].node;
  const onSuccess = async () => {
    form.resetFields();
    console.log("SUCCESS");
    if (show < totalCerts - 1) setShow(show + 1);
    else {
      const projectIdSet = new Set(
        data.certificates_to_verify_connection.edges
          .filter((edge) => edge.node.project_id)
          .map((edge) => edge.node.project_id) as string[],
      );
      const promises = [...projectIdSet].map((projectId) => {
        return emailWorkerCerts({
          variables: {
            input: {
              workerId: workerId,
              projectId: projectId,
            },
          },
        });
      });
      await Promise.all(promises);

      navigate("/sfe/verify/certificate");
    }
  };
  const onSubmit = async (
    values: {
      type: string;
      expireDate: dayjs.Dayjs;
      issueDate: dayjs.Dayjs;
    },
    c: {
      node: any;
    },
  ) => {
    await verifyMutation({
      variables: {
        certId: c.node.pk,
        time: dayjs().format(),
        verifierId: loggedInUserId,
        certificationId: values.type,
        objects: [
          {
            certification_id: values.type ?? null,
            expires_on: values.expireDate
              ? dayjs(values.expireDate).format()
              : null,
            issued_on: values.issueDate
              ? dayjs(values.issueDate).format()
              : null,
            worker_id: c.node.worker_id,
            verification_id: c.node.pk,
            privacy_setting: { data: {} },
          },
        ],
      },
    })
      .then(async (data) => {
        if (!data.insert_worker_certification?.returning?.[0]) {
          throw new Error(
            "Certification not verified properly. Try verifying it again",
          );
        }
        if (c.node.front_image?.url) {
          await updateImages({
            variables: {
              imageId: c.node.front_image_id,
              parentId: data.insert_worker_certification?.returning[0].pk,
            },
          }).catch((e) => console.error(e));
        }
        if (c.node.back_image?.url) {
          await updateImages({
            variables: {
              imageId: c.node.back_image_id,
              parentId: data.insert_worker_certification?.returning[0].pk,
            },
          }).catch((e) => console.error(e));
        }
      })
      .catch((e) => console.error(e));
    onSuccess();
  };

  return (
    <StyledContent style={{ textAlign: "center", borderRadius: "20px" }}>
      <div>
        <span className="font-accent">{certsData.worker.user.name}</span>{" "}
        uploaded this certification. Select the type of training or
        certification from the drop down list
      </div>
      <div>
        No. of certs uploaded to be verified:&nbsp;
        {data.certificates_to_verify_connection.edges.length}
      </div>
      <div>
        <br />
        <br />
        {data.certificates_to_verify_connection.edges.map((c, i) => {
          return (
            <div key={c.node.pk}>
              {show === i && (
                <div>
                  <RejectAndArchiveButtons
                    changeType="drugtest"
                    workerId={workerId}
                    reasons={data.reason_to_reject_connection.edges}
                    certId={c.node.pk}
                    projectId={c.node.project_id ?? undefined}
                    loggedInUserId={loggedInUserId}
                    onSuccess={onSuccess}
                  />
                  <div className="inline-block w-6/12 font-accent">
                    Front
                    <Card
                      hoverable
                      className="w-11/12 ml-1"
                      cover={
                        <div>
                          <ProfilPicButton
                            workerId={c.node.worker_id}
                            imageId={c.node.front_image_id}
                            certId={c.node.pk}
                            loggedInUserId={loggedInUserId}
                            onSuccess={onSuccess}
                          />

                          {frontPDF ? (
                            <iframe
                              width={"100%"}
                              height={600}
                              src={c.node.front_image?.url}
                              title=""
                            ></iframe>
                          ) : (
                            <Image
                              src={c.node.front_image?.url}
                              alt="Front Image"
                              preview={{
                                src: c.node.front_image?.url,
                              }}
                              onError={() => setFrontPDF(true)}
                            />
                          )}
                        </div>
                      }
                    >
                      <Meta
                        title="Certificate's front photo"
                        description={c.node.front_image_id}
                      />
                    </Card>
                  </div>

                  {c.node.back_image?.url && (
                    <div className="inline-block w-6/12 font-accent">
                      Back
                      <Card
                        hoverable
                        className="w-11/12"
                        cover={
                          <div>
                            <ProfilPicButton
                              workerId={c.node.worker_id}
                              imageId={c.node.back_image_id}
                              certId={c.node.pk}
                              loggedInUserId={loggedInUserId}
                              onSuccess={onSuccess}
                            />
                            {backPDF ? (
                              <iframe
                                width={"100%"}
                                height={600}
                                src={c.node.back_image.url}
                                title=""
                              ></iframe>
                            ) : (
                              <Image
                                src={c.node.back_image.url}
                                alt="Front Image"
                                preview={{
                                  src: c.node.back_image.url,
                                }}
                                onError={() => setBackPDF(true)}
                              />
                            )}
                          </div>
                        }
                      >
                        <Meta
                          title="Certificate's back photo"
                          description={c.node.back_image_id ?? ""}
                        />
                      </Card>
                    </div>
                  )}
                  <br />
                  <Button
                    onClick={() => setOpenMultipleCertsModal(true)}
                    type="primary"
                    className="font-accent rounded-2 mt-1"
                    size="large"
                  >
                    Multiple Certs
                  </Button>
                  <VerifyMultipleCertsModal
                    Modal={{
                      visible: openMutlipleCertsModal,
                      onCancel: () => setOpenMultipleCertsModal(false),
                    }}
                    loggedInUserId={loggedInUserId}
                    workerId={workerId}
                    onSuccess={() => {
                      setOpenMultipleCertsModal(false);
                      onSuccess();
                    }}
                    certifications={data.certification_connection.edges}
                    certId={c.node.pk}
                    frontImage={c.node.front_image}
                    backImage={c.node.back_image}
                  />
                  <Form
                    layout="horizontal"
                    name="form_in_modal"
                    form={form}
                    className="mt-2"
                    onFinish={(values) => onSubmit(values, c)}
                  >
                    <Form.Item
                      name="type"
                      label={
                        <p className="text-1">Select Type of Certificate</p>
                      }
                      rules={[
                        {
                          required: true,
                          message: `Select the type of Certificate to Continue`,
                        },
                      ]}
                    >
                      {data.certification_connection.edges.length && (
                        <Select
                          showSearch
                          className="w-6/12"
                          size="large"
                          placeholder={"Select type of certificate"}
                          filterOption={getNormalOptionsFilter}
                          options={data.certification_connection.edges.map(
                            (c) => {
                              return {
                                label: c.node.name,
                                value: c.node.pk,
                              };
                            },
                          )}
                        />
                      )}
                    </Form.Item>
                    <br />
                    <Form.Item
                      name="issueDate"
                      label={"When was the training issued?"}
                    >
                      <DatePicker size="large" />
                    </Form.Item>
                    <Form.Item
                      name="expireDate"
                      label={
                        <div>
                          Does this training expire?if yes,
                          <p> Select expire date</p>
                        </div>
                      }
                    >
                      <DatePicker size="large" />
                    </Form.Item>
                    <Form.Item name="submit">
                      <Button
                        className="mt-1 mb-1 font-accent rounded-2"
                        size="large"
                        loading={isVerifying || isUpdating}
                        type="primary"
                        htmlType="submit"
                      >
                        Verify and{" "}
                        {show < totalCerts - 1 ? "go to next" : "Complete"}
                      </Button>
                    </Form.Item>
                    <div className="flex justify-center">
                      <CustomButton
                        loading={isVerifying || isUpdating}
                        onClick={() => onSuccess()}
                        secondary
                        label={`${
                          show < totalCerts - 1 ? "Go to next" : "Complete"
                        } without
                    verifying`}
                      />
                    </div>
                  </Form>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </StyledContent>
  );
};
const VerifyCertsWrapper: React.FunctionComponent = () => {
  const { workerId } = useParams();
  const certId = document.location.hash?.substring(1);
  const loggedInUserId = auth.currentUser?.uid;
  return workerId && loggedInUserId ? (
    <VerifyCerts
      workerId={workerId}
      loggedInUserId={loggedInUserId}
      certId={certId}
    />
  ) : (
    <Navigate to={`/sfe/verify/certificate`} />
  );
};

export default VerifyCertsWrapper;
