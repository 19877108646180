import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useInsertInspectionInstanceAndTemplateMutation } from "src/common/types/generated/relay/useInsertInspectionInstanceAndTemplateMutation.graphql";

const useInsertInspectionInstanceAndTemplate = () => {
  return useAsyncMutation<useInsertInspectionInstanceAndTemplateMutation>(
    graphql`
      mutation useInsertInspectionInstanceAndTemplateMutation(
        $instanceObjects: [inspection_instance_insert_input!]!
        $templateObjects: [inspection_template_insert_input!]!
      ) {
        insert_inspection_template(objects: $templateObjects) {
          returning {
            ...InspectionTemplateFrag @relay(mask: false)
          }
        }
        insert_inspection_instance(objects: $instanceObjects) {
          returning {
            ...InspectionInstanceFrag @relay(mask: false)
          }
        }
      }
    `
  );
};
export default useInsertInspectionInstanceAndTemplate;
