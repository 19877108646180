import clsx from "clsx";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import SiteDeliveryCalendarTimeStampLine from "./SiteDeliveryCalendarTimeStampLine";
import SiteDeliveryCalendarTimeStampDigits from "./SiteDeliveryCalendarTimeStampDigits";

interface SitedeliveryCalendarTimeStampsProps {
  paddingTop?: string;
  type: "lines" | "digits";
  keepTimeLineInView: boolean;
}

const SitedeliveryCalendarTimeStamps: React.FC<
  SitedeliveryCalendarTimeStampsProps
> = (props) => {
  const hour = dayjs().hour();
  const [startTime, setStartTime] = useState<0 | 6 | 17>(
    hour < 5 ? 0 : hour < 17 ? 6 : 17,
  );
  const [endTime, setEndTime] = useState<5 | 17 | 24>(
    hour < 5 ? 5 : hour < 17 ? 17 : 24,
  );

  useEffect(() => {
    if (!props.keepTimeLineInView) {
      const interval = setInterval(() => {
        const currentHour = dayjs().hour();
        currentHour < 5
          ? setStartTime(0)
          : currentHour < 17
          ? setStartTime(6)
          : setStartTime(17);

        currentHour < 5
          ? setEndTime(5)
          : currentHour < 17
          ? setEndTime(17)
          : setEndTime(24);
      }, 60000);

      return () => clearInterval(interval);
    }
  }, []);

  const start = props.keepTimeLineInView ? 0 : startTime;
  const end = props.keepTimeLineInView ? 24 : endTime;

  return (
    <div
      style={{ paddingTop: props.paddingTop }}
      className="sticky z-10 top-0 left-0 items-center h-full flex-flex-col pointer-events-none"
    >
      <div className="flex flex-row h-full">
        <div
          className={clsx(
            "flex flex-col items-start justify-between h-full ",
            props.type === "digits" ? "w-px" : "w-full",
          )}
        >
          {(() => {
            const lines: React.ReactNode[] = [];
            switch (props.type) {
              case "lines":
                for (let i = 0; i < ((end - start) * 60) / 15 + 1; i++) {
                  const hour = i / 4;
                  const isHour = hour === Math.floor(hour);
                  lines.push(
                    <SiteDeliveryCalendarTimeStampLine isHour={isHour} />,
                  );
                }
                break;
              case "digits":
                for (let i = start; i <= end; i++) {
                  const hour = i;
                  const timeToDisplay = dayjs(`${hour}`, ["h"]).format("h a");

                  lines.push(
                    <SiteDeliveryCalendarTimeStampDigits
                      key={i}
                      timeStr={timeToDisplay}
                    />,
                  );
                }
                break;
            }
            return lines;
          })()}
        </div>
      </div>
    </div>
  );
};

export default SitedeliveryCalendarTimeStamps;
