import { Button, notification, Space } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { Navigate, useNavigate } from "react-router-dom";
import UserDescriptions from "src/common/components/UserDescriptions";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import StyledContent from "src/common/components/layouts/StyledContent";
import { auth } from "src/common/functions/firebase";
import { useForgotPasswordMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { SFProfileQuery } from "src/common/types/generated/relay/SFProfileQuery.graphql";
import { roleVar } from "../../../../common/api/apollo/cache";
import SwitchCsrSfeButton from "src/utility-features/customer-portal/components/SwitchCsrSfeButton";

const SFProfile: FC<{ loggedInUserId: string }> = ({ loggedInUserId }) => {
  const navigate = useNavigate();
  const data = useLazyLoadQuery<SFProfileQuery>(
    graphql`
      query SFProfileQuery($uid: uuid!) {
        user_connection(where: { id: { _eq: $uid } }) {
          edges {
            node {
              phone_number
              id
              pk: id @__clientField(handle: "pk")
              name
              email
              username
              role
            }
          }
        }
      }
    `,
    { uid: loggedInUserId },
  );
  const [forgotPassword] = useForgotPasswordMutation();
  const user = data.user_connection.edges[0]?.node;
  if (!user) throw new Error("No User exist");
  return (
    <StyledContent padding backgroundColor="transparent">
      <Space size="large" direction="vertical" className="w-full">
        <StyledContent padding backgroundColor="white">
          <Space direction="vertical" className="w-full">
            <UserDescriptions user={user} />
          </Space>
        </StyledContent>
        <SwitchCsrSfeButton email={user.email!} />

        <Button
          danger
          type="primary"
          onClick={async () => {
            roleVar("none");
            await auth.signOut();
            navigate("/");
          }}
        >
          Log out
        </Button>
        <Button
          onClick={async () => {
            if (user.email) {
              forgotPassword({
                variables: {
                  input: {
                    loginIdentifier: user.email ?? "",
                  },
                },
              });
            }
            notification.open({
              message: "We emailed you a password reset link!",
            });
          }}
        >
          Email update password link
        </Button>
      </Space>
    </StyledContent>
  );
};
const SFProfileWrapper: FC = () => {
  const loggedInUserId = auth.currentUser?.uid;
  return loggedInUserId ? (
    <BasicWrapper scrollable>
      <SFProfile loggedInUserId={loggedInUserId} />
    </BasicWrapper>
  ) : (
    <Navigate to="sfe" replace />
  );
};

export default SFProfileWrapper;
