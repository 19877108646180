import { useContext } from "react";
import SiteOrientationUniversalContext from "./siteOrientationUniversalContext";

const useSiteOrientationUniversal = () => {
  const value = useContext(SiteOrientationUniversalContext);
  if (!value)
    throw new Error(
      "useSiteOrientationUniversal must be wrapped inside SiteOrientationUniversalProvider",
    );
  return value;
};

export default useSiteOrientationUniversal;

export const useSiteOrientationUniversalUser = () => {
  const context = useSiteOrientationUniversal();
  return [context.orientee, context.updateOrientee] as const;
};
