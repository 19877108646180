import * as React from "react";
import GCReportsType from "src/common/types/manual/GCReportsType";
import ToolboxTalkDetail from "./GCProjectReportsTBTsDetail";
import ToolboxTalkTable from "src/domain-features/sitesafety/components/toolbox-talks/ToolboxTalkTable";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";

interface GCProjectReportsTBTsProps extends GCReportsType {}

const GCProjectReportsTBTs: React.FunctionComponent<
  GCProjectReportsTBTsProps
> = (props) => {
  return !props.reportId ? (
    <BasicWrapper>
      <ToolboxTalkTable
        excludedKeys={["project"]}
        where={{
          project_id: {
            _eq: props.projectId,
          },
        }}
        onRowClick={(reportId) => {
          props.openRow({ reportId });
        }}
      />
    </BasicWrapper>
  ) : (
    <ToolboxTalkDetail
      projectId={props.projectId}
      toolboxTalkId={props.reportId}
    />
  );
};

export default GCProjectReportsTBTs;
