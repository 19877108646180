/**
 * @generated SignedSource<<944d6b80b5c095d3534a5b4e308306bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee$data = {
  readonly project_subcontractor_employee_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly emergency_contact: boolean;
        readonly id: string;
        readonly pk: string;
        readonly project: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        };
        readonly subcontractor_employee: {
          readonly employee_title: {
            readonly name_text: string;
          } | null | undefined;
          readonly user: {
            readonly created_password: boolean;
            readonly email: string | null | undefined;
            readonly name: string;
            readonly phone_number: string | null | undefined;
          };
        };
      };
    }>;
  };
  readonly " $fragmentType": "ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee";
};
export type ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee$key = {
  readonly " $data"?: ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "project_subcontractor_employee_connection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./ProjectSubcontractorEmployeeTableRefetchableQuery.graphql')
    }
  },
  "name": "ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee",
  "selections": [
    {
      "alias": "project_subcontractor_employee_connection",
      "args": null,
      "concreteType": "project_subcontractor_employeeConnection",
      "kind": "LinkedField",
      "name": "__ProjectSubcontractorEmployeeTable_project_subcontractor_employee_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "project_subcontractor_employeeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "project_subcontractor_employee",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "project",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "subcontractor_employee",
                  "kind": "LinkedField",
                  "name": "subcontractor_employee",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "user",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "email",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "phone_number",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "created_password",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "subcontractor_employee_title",
                      "kind": "LinkedField",
                      "name": "employee_title",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name_text",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "emergency_contact",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "32cb822fb92cd9c51bb53978f9325e21";

export default node;
