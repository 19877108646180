import React, { FC } from "react";
import {
  GetEmployeeInitialDataQuery,
  useGetEmployeeInitialDataQuery,
} from "src/common/types/generated/apollo/graphQLTypes";

import { auth } from "src/common/functions/firebase";
import LoadingContent from "./general/LoadingContent";

export interface EmployeeProps {
  employeeData: GetEmployeeInitialDataQuery;
  userId: string;
}

const withEmployeeData =
  <Props extends object>(Component: FC<Props & EmployeeProps>) =>
  (props: Props) => {
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error("Undeifned user id");

    const employeeData = useGetEmployeeInitialDataQuery({
      variables: {
        uid: userId,
      },
      fetchPolicy: "cache-first",
    });
    if (employeeData.loading) return <LoadingContent />;
    if (employeeData.data)
      return (
        <Component
          userId={userId}
          employeeData={employeeData.data}
          {...props}
        />
      );
    return <div>{`Error: ${employeeData.error}`}</div>;
  };

export default withEmployeeData;
