import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "react-relay/hooks";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { useCheckAGCUserQuery } from "src/common/types/generated/relay/useCheckAGCUserQuery.graphql";

export const agcCompanyId = "d8344e98-035e-460c-9013-ddeb4d9ebb88";
const checkAGCUser = async (
  uid: string | undefined,
  env: RelayModernEnvironment
) => {
  const req = fetchQuery<useCheckAGCUserQuery>(
    env,
    graphql`
      query useCheckAGCUserQuery($agcCompanyId: uuid!) {
        agc_employee: general_contractor_employee_connection(
          where: { general_contractor_id: { _eq: $agcCompanyId } }
        ) {
          edges {
            node {
              uid
              id
            }
          }
        }
      }
    `,
    { agcCompanyId }
  );
  const data = await req.toPromise();
  if (!data)
    throw new Error("Request failed: could not get data for employee check");
  return !!data.agc_employee.edges.find((p) => p.node.uid === uid);
};
export default checkAGCUser;
