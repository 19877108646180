import React from "react";
import { BlockedOutType } from "../../utilities/sitedeliveryTypes";

const SitedeliveryCalendarEventBlockOut: React.FC<BlockedOutType> = (props) => {
  return (
    <div className="relative flex flex-col h-full bg-suplementary-1">
      <div className="absolute inset-0 z-40 w-full h-full transition-all border border-transparent pointer-events-none group-hover:border-static-secondary"></div>

      <div className="w-full h-0.5 bg-white rounded-b-0.5"></div>
      <div className="flex flex-1 overflow-hidden flex-col items-center justify-center p-0.5">
        <div className="relative flex items-center justify-center">
          <span className="text-center text-0.75">{`Blocked out`}</span>
        </div>
      </div>
      <div className="w-full h-0.5 bg-white rounded-t-0.5"></div>
    </div>
  );
};

export default SitedeliveryCalendarEventBlockOut;
