/**
 * @generated SignedSource<<deb4780c4f5b55941c57712f565045a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type worker_certification_set_input = {
  certification_id?: string | null | undefined;
  created_at?: string | null | undefined;
  expires_on?: string | null | undefined;
  id?: string | null | undefined;
  issued_on?: string | null | undefined;
  privacy_setting_id?: string | null | undefined;
  updated_at?: string | null | undefined;
  verification_id?: string | null | undefined;
  worker_id?: string | null | undefined;
};
export type SFUpdateCertsDataMutation$variables = {
  _set: worker_certification_set_input;
  id: string;
};
export type SFUpdateCertsDataMutation$data = {
  readonly update_worker_certification_by_pk: {
    readonly certification_id: string;
    readonly expires_on: string | null | undefined;
    readonly id: string;
    readonly issued_on: string | null | undefined;
  } | null | undefined;
};
export type SFUpdateCertsDataMutation = {
  response: SFUpdateCertsDataMutation$data;
  variables: SFUpdateCertsDataMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "worker_certification",
    "kind": "LinkedField",
    "name": "update_worker_certification_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "issued_on",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expires_on",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "certification_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SFUpdateCertsDataMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SFUpdateCertsDataMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4cdc84c86684be7500c6abdd2f303984",
    "id": null,
    "metadata": {},
    "name": "SFUpdateCertsDataMutation",
    "operationKind": "mutation",
    "text": "mutation SFUpdateCertsDataMutation(\n  $id: uuid!\n  $_set: worker_certification_set_input!\n) {\n  update_worker_certification_by_pk(pk_columns: {id: $id}, _set: $_set) {\n    id\n    issued_on\n    expires_on\n    certification_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "20aab0e403754d2ec0c55d220b743be2";

export default node;
