import { message, notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, useEffect } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { Navigate, useSearchParams } from "react-router-dom";
import logo from "src/common/assets/logo.png";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { JHAReviewedAndAcceptedQuery } from "src/common/types/generated/relay/JHAReviewedAndAcceptedQuery.graphql";
import { JHAReviewedAndAcceptedMutation } from "src/common/types/generated/relay/JHAReviewedAndAcceptedMutation.graphql";

const query = graphql`
  query JHAReviewedAndAcceptedQuery(
    $taskId: [uuid!]!
    $loggedInUserId: uuid!
    $previousImageTaskWhere: task_bool_exp!
  ) {
    user_connection(where: { id: { _eq: $loggedInUserId } }) {
      edges {
        node {
          role
        }
      }
    }
    previousImageTask: task_connection(where: $previousImageTaskWhere) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          created_at
          created_by_uid
        }
      }
    }
    task_connection(
      where: { id: { _in: $taskId } }
      order_by: { project_id: asc_nulls_first }
    ) {
      edges {
        node {
          creation_request_id
          accepted_at_from_creation_request
          task_creation_request {
            project_id
            subcontractor_id
            task_add_to_projects {
              project_id
            }
          }
          description {
            en
          }
          project_id
          project {
            name
          }
          subcontractor {
            name
          }
        }
      }
    }
  }
`;

const updateTaskStatusMutation = graphql`
  mutation JHAReviewedAndAcceptedMutation(
    $taskId: uuid!
    $_set: task_set_input!
    $deleteTaskWhere: task_bool_exp!
    $updateTaskEditWhere: task_edit_bool_exp!
    $taskEditObjects: [task_edit_insert_input!]!
    $taskSignObjects: [task_signature_insert_input!]!
  ) {
    update_task_edit(where: $updateTaskEditWhere, _set: { task_id: $taskId }) {
      affected_rows
    }
    update_task_by_pk(pk_columns: { id: $taskId }, _set: $_set) {
      id
    }
    insert_task_edit(objects: $taskEditObjects) {
      affected_rows
    }
    delete_task(where: $deleteTaskWhere) {
      affected_rows
    }
    insert_task_signature(objects: $taskSignObjects) {
      affected_rows
    }
  }
`;

const JHAReviewedAndAccepted: FC<{
  taskId: string;
  loggedInUserId: string;
  requestId?: string | null;
  projectId?: string | null;
  additionalTaskId?: string | null;
}> = ({ taskId, loggedInUserId, projectId, requestId, additionalTaskId }) => {
  const data1 = useLazyLoadQuery<JHAReviewedAndAcceptedQuery>(
    query,
    {
      taskId: [
        taskId,
        additionalTaskId ?? "7647ca50-208c-40b2-847f-c4535cf34425",
      ],
      loggedInUserId,
      previousImageTaskWhere: requestId
        ? {
            report_id: { _is_null: true },
            creation_request_id: { _eq: requestId },
            ...(projectId ? { project_id: { _eq: projectId } } : {}),
            task_creation_request: { type: { _eq: "images_for_one" } },
            show_type: { _eq: "images" },
          }
        : {},
    },
    { fetchPolicy: "network-only" },
  );

  const jhaName = data1.task_connection.edges[0].node.description.en;

  const data = data1.task_connection.edges.filter(
    (value) =>
      value.node.accepted_at_from_creation_request == null &&
      value.node.creation_request_id !== null,
  );

  const previousImageTypeTask = data1.previousImageTask.edges[0]
    ? data1.previousImageTask.edges[0].node
    : undefined;
  const [updateTask] = useAsyncMutation<JHAReviewedAndAcceptedMutation>(
    updateTaskStatusMutation,
  );

  const jha = data[0]?.node;
  const additionalJha = data[1]?.node;

  useEffect(() => {
    if (jha) {
      const time = dayjs().format();
      updateTask({
        variables: {
          ...(previousImageTypeTask
            ? {
                deleteTaskWhere: { id: { _eq: previousImageTypeTask.pk } },
                updateTaskEditWhere: {
                  task_id: { _eq: previousImageTypeTask.pk },
                },
              }
            : {
                deleteTaskWhere: { id: { _is_null: true } },
                updateTaskEditWhere: { id: { _is_null: true } },
              }),
          taskId,
          _set: {
            accepted_at_from_creation_request: time,
            accepted_by_uid_from_creation_request: loggedInUserId,
            is_pending_acceptance: false,
            created_by_uid:
              previousImageTypeTask?.created_by_uid ?? loggedInUserId,
            created_at: time,
          },
          taskEditObjects: jha.project_id
            ? [
                {
                  edit_type: "Accepted and Approved",
                  edited_at: dayjs().toISOString(),
                  edited_by_uid: loggedInUserId,
                  task_id: taskId,
                },
              ]
            : [],
          taskSignObjects: jha.project_id
            ? [
                {
                  created_at: dayjs().format(),
                  user_id: loggedInUserId,
                  is_active: true,
                  task_id: taskId,
                },
              ]
            : [],
        },
      }).catch((p) => notification.error({ message: p.message }));
    } else {
      message.warning("JHA has already been accepted");
    }

    if (additionalJha && additionalTaskId) {
      const time = dayjs().format();

      updateTask({
        variables: {
          deleteTaskWhere: { id: { _is_null: true } },
          updateTaskEditWhere: {
            id: { _is_null: true },
          },
          taskId: additionalTaskId,
          _set: {
            accepted_at_from_creation_request: time,
            accepted_by_uid_from_creation_request: loggedInUserId,
            is_pending_acceptance: false,
            created_by_uid:
              previousImageTypeTask?.created_by_uid ?? loggedInUserId,
            created_at: time,
          },
          taskEditObjects: [
            {
              edit_type: "Accepted and Approved",
              edited_at: dayjs().toISOString(),
              edited_by_uid: loggedInUserId,
              task_id: additionalTaskId,
            },
          ],
          taskSignObjects: [
            {
              created_at: dayjs().format(),
              user_id: loggedInUserId,
              is_active: true,
              task_id: additionalTaskId,
            },
          ],
        },
      }).catch((p) => notification.error({ message: p.message }));
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center font-accent text-1 h-3/4">
      <div className="mb-2">
        <img src={logo} width={400} height={400} />
      </div>
      {jha
        ? `You have reviewed and approved this JHA (${
            jha.description.en
          }). It has been added to your ${
            additionalTaskId
              ? "Project and company"
              : jha.project_id
              ? "Project"
              : "Company"
          }'s JHA Library.`
        : `This JHA, ${jhaName} has already been approved! Thank you.`}
      <br />
      <br />
      <div className="flex">
        Email us with any questions:&nbsp;
        <a
          href="mailto:support@siteform.io"
          className="text-interactive-primary"
        >
          support@siteform.io
        </a>
      </div>
    </div>
  );
};
const JHAReviewedAndAcceptedWrapper: React.FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const taskId = searchParams.get("taskId");
  const requestId = searchParams.get("request");
  const projectId = searchParams.get("project");
  const additionalTaskId = searchParams.get("additionalTaskId");
  const loggedInUserId = auth.currentUser?.uid;

  return taskId && loggedInUserId ? (
    <BasicWrapper scrollable>
      <JHAReviewedAndAccepted
        {...{ taskId, loggedInUserId, requestId, projectId, additionalTaskId }}
      />
    </BasicWrapper>
  ) : (
    <Navigate to={"/"} />
  );
};
export default JHAReviewedAndAcceptedWrapper;
