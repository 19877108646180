import React from "react";
import GCReportsType from "src/common/types/manual/GCReportsType";
import GCProjectReportsPTPsDetail from "./GCProjectReportsPTPsDetail";
import GCProjectReportsPTPsTable from "./GCProjectReportsPTPsTable";

interface GCProjectReportsPTPsProps extends GCReportsType {}

const GCProjectReportsPTPs: React.FunctionComponent<
  GCProjectReportsPTPsProps
> = (props) => {
  return !props.reportId ? (
    <GCProjectReportsPTPsTable
      projectId={props.projectId}
      openRow={props.openRow}
    />
  ) : (
    <GCProjectReportsPTPsDetail
      reportId={props.reportId}
      projectId={props.projectId}
    />
  );
};

export default GCProjectReportsPTPs;
