import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import * as uuid from "uuid";
import {
  UpdateTaskMutation,
  UpdateTaskMutation$data,
} from "src/common/types/generated/relay/UpdateTaskMutation.graphql";

const handleUpdateTaskUpdater = (
  store: RecordSourceSelectorProxy<UpdateTaskMutation$data>
) => {
  const deleteTaskSteps = store.getRootField("delete_task_step");
  const deleteTaskPpe = store.getRootField("delete_task_ppe_type");
  const deleteTaskPermit = store.getRootField("delete_task_permit_type");
  const insertTaskPpe = store.getRootField("insert_task_ppe_type");
  const insertTaskSteps = store.getRootField("insert_task_step");
  const insertTaskPermit = store.getRootField("insert_task_permit_type");
  const insertTaskEdit = store.getRootField("insert_task_edit");

  const taskPpeConn = ConnectionHandler.getConnection(
    store.getRoot(),
    "ViewAndEditJha_task_ppe_type_connection"
  );
  const taskStepConn = ConnectionHandler.getConnection(
    store.getRoot(),
    "ViewAndEditJha_task_step_connection"
  );
  const taskPermitConn = ConnectionHandler.getConnection(
    store.getRoot(),
    "ViewAndEditJha_task_permit_type_connection"
  );
  const taskEditConn = ConnectionHandler.getConnection(
    store.getRoot(),
    "ViewAndEditJha_task_edit_connection"
  );

  if (taskStepConn) {
    deleteTaskSteps.getLinkedRecords("returning").forEach((p) => {
      ConnectionHandler.deleteNode(taskStepConn, p.getDataID());
    });
    insertTaskSteps.getLinkedRecords("returning").forEach((p) => {
      const edge = store.create(uuid.v4(), "edge");
      edge.setLinkedRecord(p, "node");
      ConnectionHandler.insertEdgeAfter(taskStepConn, edge);
    });
  }
  if (taskPpeConn) {
    deleteTaskPpe.getLinkedRecords("returning").forEach((s) => {
      ConnectionHandler.deleteNode(taskPpeConn, s.getDataID());
    });
    insertTaskPpe.getLinkedRecords("returning").forEach((p) => {
      const edge = store.create(uuid.v4(), "edge");
      edge.setLinkedRecord(p, "node");
      ConnectionHandler.insertEdgeAfter(taskPpeConn, edge);
    });
  }
  if (taskPermitConn) {
    deleteTaskPermit.getLinkedRecords("returning").forEach((s) => {
      ConnectionHandler.deleteNode(taskPermitConn, s.getDataID());
    });

    insertTaskPermit.getLinkedRecords("returning").forEach((p) => {
      const edge = store.create(uuid.v4(), "edge");
      edge.setLinkedRecord(p, "node");
      ConnectionHandler.insertEdgeAfter(taskPermitConn, edge);
    });
  }
  if (taskEditConn) {
    insertTaskEdit.getLinkedRecords("returning").forEach((p) => {
      const edge = store.create(uuid.v4(), "edge");
      edge.setLinkedRecord(p, "node");
      ConnectionHandler.insertEdgeAfter(taskEditConn, edge);
    });
  }
};

export default handleUpdateTaskUpdater;
