import L from "leaflet";
import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";
import home_location_icon from "src/common/assets/home-location-icon.png";
import "leaflet/dist/leaflet.css";
type RequiredData = {
  id: string;
  latitude: number | string;
  longitude: number | string;
};
type MapProps<DataType extends RequiredData> = {
  iconUrl?: string;
  data: DataType[];
  height: number | string;
  width?: number | string;
  renderPopup: (entry: DataType) => React.ReactNode;
};

const usCenter = {
  lat: 39.8283,
  lng: -98.5795,
};
const MapCoordinatePoints = <DataType extends RequiredData>(
  { data, iconUrl, renderPopup, height, width }: MapProps<DataType>,
  ref: any,
) => {
  const myIcon = L.icon({
    iconUrl: iconUrl || home_location_icon, //"https://i.ibb.co/GJcg5NG/image.png",
    // shadowUrl: "http://leafletjs.com/examples/custom-icons/marker-shadow.png",
    iconSize: [41, 41],
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -41],
  });

  return (
    <MapContainer
      id={"map-coordinates-container"}
      center={usCenter}
      zoom={4}
      attributionControl={false}
      // scrollWheelZoom={false}
      style={{ height: height, width: width }}
    >
      <TileLayer
        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {data.map((entry, i) => {
        return (
          <Marker
            key={entry.id}
            icon={myIcon}
            position={{
              lat: Number(entry.latitude),
              lng: Number(entry.longitude),
            }}
          >
            <Popup>{renderPopup(entry)}</Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
};

export default MapCoordinatePoints;
