import { useGenerateJhaSpreadsheetMutation } from "src/common/types/generated/apollo/graphQLTypes";
import * as React from "react";
import { message, Menu } from "antd";
import downloadFromUrl from "src/common/functions/downloadFromUrl";

interface TaskExcelDownloadProps {
  taskId: string;
  type: "turner" | "siteform";
  jhaNum?: number[];
}
const TaskExcelDownload = (props: TaskExcelDownloadProps) => {
  const [generateJhaExcel, { loading: generatingPdf }] =
    useGenerateJhaSpreadsheetMutation();

  return (
    <div
      className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
      onClick={async () => {
        if (typeof props.taskId !== "string") return;

        message.loading({
          content: "Download in progress",
          duration: 0,
        });

        try {
          const { data: download } = await generateJhaExcel({
            variables: {
              input: {
                taskId: props.taskId,
                type: props.type,
              },
            },
          });

          if (download?.generateJHASpreadsheet) {
            downloadFromUrl(download.generateJHASpreadsheet);
          }
          message.destroy();
        } catch (e) {
          if (e instanceof Error) {
            message.error(e.message);
          }
        }
      }}
    >
      Download JHA as Excel
    </div>
  );
};

export default TaskExcelDownload;
