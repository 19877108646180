import React, { FC, PropsWithChildren } from "react";
import BaseSideLayout from "src/common/components/layouts/BaseSideLayout";
import LayoutBackButton from "src/common/components/LayoutBackButton";

const AdminData: FC<PropsWithChildren> = ({ children }) => {
  return (
    <BaseSideLayout
      sideMenuItems={[
        {
          path: `/admin/data/projects`,
          title: "Projects",
          match: /\/admin\/data\/projects/,
        },
        {
          path: `/admin/data/subcontractors`,
          title: "Subcontractors",
          match: /\/admin\/data\/subcontractors/,
        },
        {
          path: "/admin/data/gcs",
          title: "General Contractors",
          match: /\/admin\/data\/gcs/,
        },
        {
          path: "/admin/data/orientation",
          title: "Poster Images",
          match: /\/admin\/data\/orientation/,
        },
        {
          path: "/admin/data/visitors",
          title: "Visitor Poster",
          match: /\/admin\/data\/visitors/,
        },
        {
          path: "/admin/data/hammertech",
          title: "HammerTech",
          match: /\/admin\/data\/hammertech/,
        },
        {
          path: "/admin/data/crewsight",
          title: "CrewSight",
          match: /\/admin\/data\/crewsight/,
        },
      ]}
    >
      <LayoutBackButton match={/\/admin\/data\/.*\/.*/} />
      {children}
    </BaseSideLayout>
  );
};

export default AdminData;
