import React from "react";
import { Navigate } from "react-router-dom";
import GCReportsNavigation from "./GCReportsNavigation";
import NestedRoute from "../../../../common/types/manual/NestedRoute";
import preTaskPlansRoute from "./routes/preTaskPlans/preTaskPlansRoute";
import dailyRoute from "./routes/daily/dailyRoute";
import reportsDownloadRoute from "./routes/reportsDownload/reportsDownloadRoute";
import permitsRoute from "./routes/permits/permitsRoute";
import toolboxTalksRoute from "./routes/toolboxtalks/toolboxTalksRoute";
import covid19Route from "./routes/covid19/covid19Route";
import gcProjectReportManagementRoute from "./routes/report-settings/gcProjectCalendarReports/gcProjectReportManagementRoute";
import gcProjectReportManagementPerformanceRoute from "./routes/performance/gcProjectReportManagementPerformanceRoute";
import visitors from "./routes/visitor/visitorsRoute";

const gcProjectReportsRoute: NestedRoute = {
  element: <GCReportsNavigation />,
  children: [
    {
      path: "pretaskplans",
      ...preTaskPlansRoute,
    },
    {
      path: "daily",
      ...dailyRoute,
    },
    {
      path: "reports-download",
      ...reportsDownloadRoute,
    },
    {
      path: "permits",
      ...permitsRoute,
    },
    {
      path: "toolboxtalks",
      ...toolboxTalksRoute,
    },
    {
      path: "covid19",
      ...covid19Route,
    },
    {
      path: "settings",
      ...gcProjectReportManagementRoute,
    },
    {
      path: "performance",
      ...gcProjectReportManagementPerformanceRoute,
    },
    {
      path: "visitors",
      ...visitors,
    },
    {
      path: "",
      element: <Navigate replace to="daily" />,
    },
  ],
};

export default gcProjectReportsRoute;
