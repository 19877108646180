import { Column } from "@ant-design/plots";
import React from "react";

const BarChart: React.FC<{
  data: Array<{
    xAxis: string;
    yAxis: number;
    type: "safe" | "unsafe";
    typeColor: string;
  }>;
}> = ({ data }) => {
  const config = {
    data,
    xField: "xAxis",
    yField: "yAxis",
    label: {
      text: "yAxis",
      textBaseline: "bottom",
      position: "inside",
    },
    style: {
      fill: "typeColor",
    },
    colorField: "type",
    percent: false,
    stack: true,
    interaction: {
      tooltip: {
        render: (
          e: string,
          {
            title,
            items,
          }: {
            title: string;
            items: Array<{ name: string; value: string; typeColor?: string }>;
          },
        ) => {
          return (
            <div key={title}>
              <h4>{title}</h4>
              {items.map((item, i) => {
                const { name, value, typeColor } = item;

                return (
                  <div key={i}>
                    <div
                      style={{
                        margin: 0,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <span
                          style={{
                            display: "inline-block",
                            width: 6,
                            height: 6,
                            borderRadius: "50%",
                            backgroundColor: typeColor,
                            marginRight: 6,
                          }}
                        ></span>
                        <span>{name}</span>
                      </div>
                      <b>{value}</b>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        },
      },
    },
    // tooltip: { channel: "y0", valueFormatter: ".0%" },
  };
  return <Column {...config} />;
};
export default BarChart;
