import React, { useEffect } from "react";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import clsx from "clsx";
import { SiteInspectionStepProps } from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import dayjs from "dayjs";
import {
  GetCompletedInspectionResultsOutput,
  InspectionResultStatus,
  useGetCompletedInspectionResultsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import capitalize from "src/common/functions/capitalize";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import CustomTable from "src/common/components/tables/basic/CustomTable";
import { Spin } from "antd";
import useSiteInspectionUser from "src/domain-features/siteinspection/utils/useSiteInspectionUser";
import {
  useCurrentLangStrings,
  useCurrentLanguage,
  useDBLangSelector,
} from "../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteInspectionCompletedResultsProps
  extends SiteInspectionStepProps {
  projectId: string;
  qrCodeId: string;
}

const SiteInspectionCompletedResults: React.FC<
  SiteInspectionCompletedResultsProps
> = ({ projectId, qrCodeId, onBack }) => {
  const workerContext = useSiteInspectionUser();

  const inspection = workerContext.user?.inspection;
  const language = useCurrentLanguage();
  const langStrings = useCurrentLangStrings();
  if (!inspection) throw new Error("Selected Inspection not found");
  const gs = useDBLangSelector(language);
  const { data, loading } = useGetCompletedInspectionResultsQuery({
    variables: {
      input: {
        projectId,
        qrCodeId,
        inspectionId: inspection.id,
      },
    },
  });
  const modelNumberMap: {
    [model: string]: Array<GetCompletedInspectionResultsOutput>;
  } = {};
  const [showModelResults, handleShowModelResults] =
    useSelectedIndicies<string>();
  (data?.getCompletedInspectionResults || []).forEach((p) => {
    const model = p.modelNumber || "noModelNumber";
    if (modelNumberMap[model]) {
      modelNumberMap[model].push(p);
    } else {
      modelNumberMap[model] = [p];
    }
  });
  useEffect(() => {
    const list = Object.keys(modelNumberMap);
    if (list.length === 1) {
      handleShowModelResults(list[0]);
    }
  }, []);
  if (loading) return <Spin />;
  return (
    <SiteFeatureStepLayout onBackButtonClick={onBack}>
      <SiteFeatureStepsInputLayout
        headline={
          data?.getCompletedInspectionResults?.length === 0
            ? "No Results Found"
            : langStrings.strings.inspectionResults + ": " + gs(inspection.name)
        }
      >
        <div className={`flex flex-row items-center justify-center`}>
          <div
            className={`w-full flex flex-col items-stretch justify-center gap-0.25 tablet-portrait::gap-0.5`}
          >
            {Object.entries(modelNumberMap).map(([model, results]) => {
              const isShowing = showModelResults.has(model);
              return (
                <div key={model}>
                  <div
                    className="flex-1 h-auto border border-suplementary-2 w-full justify-between rounded-0.25 tablet-portrait:rounded-0.5 transition-colors flex flex-col gap-0 p-0.5 tablet-portrait:p-1"
                    
                    onClick={() => {
                      handleShowModelResults(model);
                    }}
                  >
                    <div className="flex justify-between text-1.2">
                      <div>
                        Model/ID#: {model}, &nbsp;Last:{" "}
                        {results.length > 0
                          ? dayjs(results[0].inspectionDate).format(
                              "MM-DD-YYYY",
                            )
                          : ""}
                      </div>
                      <div className="flex">
                        ({results.length}){" "}
                        {isShowing ? <DownOutlined /> : <RightOutlined />}
                      </div>
                    </div>
                    {isShowing ? (
                      <CustomTable
                        dataSource={results}
                        columns={[
                          {
                            dataIndex: "status",
                            title: langStrings.strings.result,
                            render: (_, result) => (
                              <span
                                className={clsx(
                                  "font-accent mr-1 w-2/12",
                                  result.status ===
                                    InspectionResultStatus.Corrected
                                    ? "text-purple"
                                    : result.status ===
                                      InspectionResultStatus.Failed
                                    ? "text-semantic-negative"
                                    : "text-semantic-positive-green",
                                )}
                              >
                                {capitalize(result.status)}
                              </span>
                            ),
                          },
                          {
                            title: langStrings.strings.date,
                            dataIndex: "inspectionDate",
                            contentType: {
                              type: "date",
                              renderOptions: () => ({ format: "full" }),
                            },
                          },
                          {
                            title: langStrings.strings.completedBy,
                            dataIndex: "inspectorName",
                            render: (_, result) => (
                              <>
                                {result.inspectorName},{" "}
                                {result.inspectorCompany}
                              </>
                            ),
                          },
                        ]}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteInspectionCompletedResults;
