import React, { FC } from "react";
import { SCProjectQuery$data } from "src/common/types/generated/relay/SCProjectQuery.graphql";
import ProjectCrewWorkerDetails from "../../components/ProjectCrewWorkerDetails";

interface SCProjectCrewsProps {
  projectId: string;
  subcontractorId: string;
  refresh: () => void;
  data: SCProjectQuery$data;
}
const SCProjectCrews: FC<SCProjectCrewsProps> = ({
  subcontractorId,
  projectId,
  data,
}) => {
  return (
    <ProjectCrewWorkerDetails
      projectId={projectId}
      workersDataKey={data}
      subcontractorId={subcontractorId}
      isGcAccount={false}
    ></ProjectCrewWorkerDetails>
  );
};
export default SCProjectCrews;
