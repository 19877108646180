import React from "react";
import ObservationDetail from "../../components/ObservationDetail";
import { useParams } from "react-router-dom";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
const SCObservation: React.FunctionComponent = () => {
  const { observationId, projectId } = useParams();
  const subcontractorId = useSubcontractorId();
  if (!observationId) throw new Error("Observation not found");
  if (!projectId) throw new Error("projectId not found");
  return (
    <>
      <ObservationDetail
        observationId={observationId}
        projectId={projectId}
        subcontractorId={subcontractorId}
      />
    </>
  );
};
export default SCObservation;
