import React from "react";
import SiteBoardBase from "../../../../components/SiteBoardBase";
import SiteBoardList from "../../../../components/SiteBoardList";
import SiteBoardPermit, { SiteBoardPermitProps } from "./SiteBoardPermit";

export type SiteBoardPermitType = SiteBoardPermitProps;

interface SiteBoardPermitsUIProps {
  data: SiteBoardPermitType[];
}

const SiteBoardPermitsUI: React.FunctionComponent<SiteBoardPermitsUIProps> = ({
  data,
}) => {
  const list = data.map((permitData) => (
    <SiteBoardPermit key={permitData.id} {...permitData} />
  ));

  return (
    <SiteBoardBase autoScroll>
      <SiteBoardList list={list} title={"Active permits"} />
    </SiteBoardBase>
  );
};

export default SiteBoardPermitsUI;
