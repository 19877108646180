export enum WorkerStatusEnum {
  NOT_YET_VERIFIED = "not-yet-verified",
  VERIFIED = "verified",
  NOT_ALLOWED_TO_WORK = "not-allowed-to-work",
  ARCHIVED = "archived",
}

export const WorkerStatusUiValues: { [key in WorkerStatusEnum]: string } = {
  [WorkerStatusEnum.NOT_YET_VERIFIED]: "Not Yet Verified",
  [WorkerStatusEnum.VERIFIED]: "Verified",
  [WorkerStatusEnum.NOT_ALLOWED_TO_WORK]: "Not Allowed to Work",
  [WorkerStatusEnum.ARCHIVED]: "Archived",
};

export const toWorkerStatus = (value: string): WorkerStatusEnum => {
  switch (value) {
    case "not-yet-verified":
      return WorkerStatusEnum.NOT_YET_VERIFIED;
    case "verified":
      return WorkerStatusEnum.VERIFIED;
    case "not-allowed-to-work":
      return WorkerStatusEnum.NOT_ALLOWED_TO_WORK;
    case "archived":
      return WorkerStatusEnum.ARCHIVED;
    default:
      throw new Error(`Invalid value received for worker status [${value}]`);
  }
};

export const getWorkerStatusValue = (value: string): string => {
  return WorkerStatusUiValues[toWorkerStatus(value)];
};
