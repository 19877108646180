import React from "react";

export interface SiteBoardOnsiteColumnBodyProps {
  rows: React.ReactNode[];
}

const SiteBoardOnsiteColumnBody: React.FC<SiteBoardOnsiteColumnBodyProps> = (
  props,
) => {
  // console.log(props, "     PROPS");
  return (
    <div className="flex flex-col gap-1">
      {props.rows.map((row, i) => (
        // TODO fix Tailwind JIT mode bug...
        <div className="h-[5.5rem]" key={i}>
          {row}
        </div>
      ))}
    </div>
  );
};

export default SiteBoardOnsiteColumnBody;
