import { memo } from "react";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import {
  GetObservationByPkQuery,
  GetObservationOptionsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import { Card, Select } from "antd";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";
import useModifyAssignees from "../utils/useModifyAssignees";
import getGroupOptionsFromObservationOptionsData from "../utils/getGroupOptionsFromObservationOptionsData";
import getNormalOptionsFilter from "src/common/functions/getNormalOptionsFilter";

const ObservationSelectUsers: React.FC<{
  observation: NonNullable<GetObservationByPkQuery["observation_by_pk"]>;
  type: "assignee" | "corrected_by";
  subcontractorId?: string;
  isSubmitted: boolean;
  notEditing?: boolean;
  optionsData: GetObservationOptionsQuery;
}> = memo(
  ({
    optionsData,
    observation,
    notEditing,
    type,
    subcontractorId,
    isSubmitted,
  }) => {
    const unsafeObservation = observation.unsafe_observation;
    if (!unsafeObservation)
      throw new Error("Unsafe Observation Entry not found");
    const currentAssignees = unsafeObservation.assignees.filter(
      (assignee) => assignee.type === type,
    );
    const [onModifyAssignees] = useModifyAssignees({
      currentAssignees,
      isSubmitted,
      observation,
      optionsData,
      type,
    });

    const options = getGroupOptionsFromObservationOptionsData(
      optionsData,
      subcontractorId,
    );

    const [usersSelected, handleUserSelection, setUsersSelected] =
      useSelectedIndicies<string>(
        observation?.unsafe_observation
          ? new Set(
              currentAssignees.map(
                (u) => (u.employee || u.project_worker)?.user?.id!,
              ),
            )
          : new Set(),
      );

    return (
      <Card className="w-2/5">
        <div className="flex gap-1 items-center">
          <span className="text-semantic-negative">*</span>{" "}
          {type === "assignee" ? "Assigned to" : "Corrected By"}
          {notEditing ? (
            <span className="font-accent">
              {currentAssignees
                .map((a) => (a.employee || a.project_worker)?.user?.name)
                .join(", ")}
            </span>
          ) : (
            <Select
              style={{ width: "100%" }}
              placeholder={
                "Select " + (type === "assignee" ? "Assignees" : "Corrected By")
              }
              mode="multiple"
              showSearch
              filterOption={getNormalOptionsFilter}
              onSelect={(v) => {
                handleUserSelection(v);
              }}
              onDeselect={(v) => {
                handleUserSelection(v);
              }}
              onBlur={() => onModifyAssignees([...usersSelected])}
              dropdownStyle={{ width: 400 }}
              value={[...usersSelected]}
              options={options}
            ></Select>
          )}
        </div>
      </Card>
    );
  },
);

export default ObservationSelectUsers;
