import {
  IconChevronDown,
  IconChevronUp,
  IconCross,
  IconX,
} from "@tabler/icons";
import React, { useState } from "react";
import Icon from "../general/Icon";

export interface FilterControllerInputProps {
  title: string;
  component: React.ReactElement;
  initialClose?: boolean;
  removeFilter?: () => void;
}
const FilterControllerInput: React.FunctionComponent<
  FilterControllerInputProps
> = (props) => {
  const [visible, setVisible] = useState(!props.initialClose);

  const handleTitleClick = () => {
    setVisible((prevState) => !prevState);
  };

  return (
    <div key={props.title} className="flex flex-col gap-1">
      <div
        onClick={handleTitleClick}
        className="flex flex-row items-center gap-0.5 cursor-pointer"
      >
        <Icon
          icon={visible ? IconChevronDown : IconChevronUp}
          color={`interactive`}
        />

        <p className="flex-1 select-none text-1 font-accent">{props.title}</p>
        {props.removeFilter && (
          <Icon icon={IconX} color={"static"} onClick={props.removeFilter} />
        )}
      </div>

      <div className={`${visible ? "" : "hidden"}`}>{props.component}</div>
    </div>
  );
};

export default FilterControllerInput;
