import { Navigate, Outlet } from "react-router-dom";
import RequireRoleWrapper from "src/common/components/ReqiureRoleWrapper";
import NestedRoute from "src/common/types/manual/NestedRoute";
import GCBusinessUnits from "../business-unit/GCBusinessUnits";
import GCBusinessUnitTeam from "./team/GCBusinessUnitTeam";
import GCBusinessUnit from "./GCBusinessUnit";
import GCBusinessUnitProjects from "./projects/GCBusinessUnitProjects";
import GCBusinessUnitDetail from "./detail/GCBusinessUnitDetail";
import GCBusinessUnitIncident from "./incident/GCBusinessUnitIncident";
import GCBusinessUnitWorkers from "./worker/GCBusinessUnitWorkers";
import GCBuOffices from "./sub-levels/GCBuOffices";
const gcBusinessUnitsRoute: NestedRoute = {
  element: (
    <RequireRoleWrapper requiredRole="employee">
      <Outlet />
    </RequireRoleWrapper>
  ),
  children: [
    {
      path: "",
      element: <GCBusinessUnits />,
      children: [
        {
          path: ":businessUnitId",
          element: <GCBusinessUnit />,
          children: [
            { path: "", element: <Navigate to={"projects"} /> },
            // { path: "details", element:  },
            { path: "workers", element: <GCBusinessUnitWorkers /> },
            { path: "teams", element: <GCBusinessUnitTeam /> },
            { path: "projects", element: <GCBusinessUnitProjects /> },
            { path: "incident-reporting", element: <GCBusinessUnitIncident /> },
            { path: "settings", element: <GCBusinessUnitDetail /> },
            { path: "offices", element: <GCBuOffices /> },
          ],
        },
      ],
    },
  ],
};
export default gcBusinessUnitsRoute;
