import { useSuspenseQuery } from "@apollo/client";
import {
  GetGcBusinessUnitsDocument,
  GetGcBusinessUnitsQuery,
  GetGcBusinessUnitsQueryVariables,
  useInsertGcBusinessUnitMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import { useParams } from "react-router-dom";
import useAuthUser from "src/common/hooks/useAuthUser";
import GCAddNewLevelButton from "../../component/GCAddNewLevelButton";
import BusinessUnitTable from "../../component/BusinessUnitTable";
import withDivisionId, { DivisionProps } from "../../utils/withDivisionId";

const GCDivisionBUs: React.FC<DivisionProps> = ({ divisionId }) => {
  const { userData } = useUserData();
  const emp = userData.employee;
  if (!emp) throw new Error("Employee not found");
  const gc = emp.general_contractor;
  const { data, refetch } = useSuspenseQuery<
    GetGcBusinessUnitsQuery,
    GetGcBusinessUnitsQueryVariables
  >(GetGcBusinessUnitsDocument, {
    variables: {
      divisionWhere: { id: { _eq: divisionId } },
      buWhere: { gc_division_id: { _eq: divisionId } },
      includeTableData: true,
    },
    fetchPolicy: "network-only",
  });
  const [insertBu, { loading }] = useInsertGcBusinessUnitMutation();
  const division = data.gc_division[0];
  const authUser = useAuthUser();
  if (!division) throw new Error("No division found");
  return gc.hierarchy_business_unit_name ? (
    <div className="bg-white h-full w-full p-1">
      <BusinessUnitTable
        businessUnits={data.gc_business_unit}
        officeTitle={gc.hierarchy_office_name}
        buTitle={gc.hierarchy_business_unit_name}
        addButton={
          <GCAddNewLevelButton
            {...{
              levelName: gc.hierarchy_business_unit_name,
              inserting: loading,
              divisionId,
              divisionSelectOptions: data.gc_division,
              divisionLevelName: gc.hierarchy_division_name,
              onInsert: async (vals) => {
                await insertBu({
                  variables: {
                    object: {
                      name: vals.name,
                      created_by_uid: authUser.uid,
                      gc_division_id: divisionId || null,
                      general_contractor_id: emp.general_contractor_id,
                      logo_url: vals.uploadedLogoUrl,
                      address: {
                        data: {
                          line_1: vals.addressLine1,
                          zip_code: vals.zip,
                          city: vals.city,
                          state_code: vals.stateCode,
                        },
                      },
                    },
                  },
                });
                refetch();
              },
            }}
          />
        }
      />
    </div>
  ) : null;
};
export default withDivisionId(GCDivisionBUs);
