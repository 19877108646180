/**
 * @generated SignedSource<<03497453cf175c20de776926c878aa43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type order_by = "asc" | "asc_nulls_first" | "asc_nulls_last" | "desc" | "desc_nulls_first" | "desc_nulls_last" | "%future added value";
export type GCWorkerProfileQuery$variables = {
  order: order_by;
  projectId: string;
  projectWorkerId: string;
};
export type GCWorkerProfileQuery$data = {
  readonly project_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly drugtest_validity_days: number;
        readonly general_contractor: {
          readonly name: string;
        };
        readonly general_contractor_id: string;
        readonly id: string;
        readonly name: string;
        readonly perform_drugtest: boolean;
        readonly pk: string;
        readonly require_drugtest_upload: boolean;
      };
    }>;
  };
  readonly project_worker_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly pk: string;
        readonly worker: {
          readonly processing_cert_count: ReadonlyArray<{
            readonly id: string;
          }>;
          readonly subcontractor_id: string | null | undefined;
          readonly user: {
            readonly name: string;
            readonly project_privacy_setting: {
              readonly manager: boolean;
            } | null | undefined;
          };
        };
        readonly worker_id: string;
      };
    }>;
  };
};
export type GCWorkerProfileQuery = {
  response: GCWorkerProfileQuery$data;
  variables: GCWorkerProfileQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectWorkerId"
},
v3 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "perform_drugtest",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "drugtest_validity_days",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "require_drugtest_upload",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "general_contractor_id",
  "storageKey": null
},
v11 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "projectWorkerId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worker_id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manager",
  "storageKey": null
},
v15 = {
  "alias": "processing_cert_count",
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "order"
        }
      ],
      "kind": "ObjectValue",
      "name": "order_by"
    },
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "document": {
          "_eq": "certificate"
        },
        "status": {
          "_eq": "submitted"
        },
        "verified_at": {
          "_is_null": true
        }
      }
    }
  ],
  "concreteType": "certificates_to_verify",
  "kind": "LinkedField",
  "name": "certificates_to_verify",
  "plural": true,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCWorkerProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor",
                    "kind": "LinkedField",
                    "name": "general_contractor",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "project_workerConnection",
        "kind": "LinkedField",
        "name": "project_worker_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_workerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "privacy_setting",
                            "kind": "LinkedField",
                            "name": "project_privacy_setting",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCWorkerProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor",
                    "kind": "LinkedField",
                    "name": "general_contractor",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "project_workerConnection",
        "kind": "LinkedField",
        "name": "project_worker_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_workerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "privacy_setting",
                            "kind": "LinkedField",
                            "name": "project_privacy_setting",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "335f5d40749449189d7cfea79c143fc9",
    "id": null,
    "metadata": {},
    "name": "GCWorkerProfileQuery",
    "operationKind": "query",
    "text": "query GCWorkerProfileQuery(\n  $projectId: uuid!\n  $projectWorkerId: uuid!\n  $order: order_by!\n) {\n  project_connection(where: {id: {_eq: $projectId}}) {\n    edges {\n      node {\n        id\n        pk: id\n        name\n        perform_drugtest\n        drugtest_validity_days\n        require_drugtest_upload\n        general_contractor_id\n        general_contractor {\n          name\n          id\n        }\n      }\n    }\n  }\n  project_worker_connection(where: {id: {_eq: $projectWorkerId}}) {\n    edges {\n      node {\n        id\n        pk: id\n        worker_id\n        worker {\n          subcontractor_id\n          user {\n            name\n            project_privacy_setting {\n              manager\n              id\n            }\n            id\n          }\n          processing_cert_count: certificates_to_verify(where: {verified_at: {_is_null: true}, document: {_eq: \"certificate\"}, status: {_eq: \"submitted\"}}, order_by: {id: $order}) {\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d1e86c9e9b0a177022b55e21f4b1159b";

export default node;
