import React from "react";
import GCReportsType from "src/common/types/manual/GCReportsType";
import GCProjectCovidConfig from "./GCProjectCovidConfig";
import GCProjectCovidData from "./GCProjectCovidData";

interface GCProjectReportCovidProps extends GCReportsType {}

const GCProjectReportCovid: React.FunctionComponent<
  GCProjectReportCovidProps
> = (props) => {
  //todo put here reports and settings
  return (
    <div>
      <p className="mb-1 text-1.5 font-accent">Covid data</p>
      <GCProjectCovidData projectId={props.projectId} />
      <p className="my-1 text-1.5 font-accent">Settings</p>
      <GCProjectCovidConfig projectId={props.projectId} />
    </div>
  );
};

export default GCProjectReportCovid;
