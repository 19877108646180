import React, { useState } from "react";
import { message } from "antd";

import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import clsx from "clsx";
import { SiteInspectionStepProps } from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import Icon from "src/common/components/general/Icon";
import { IconBook, IconChecklist, IconEye } from "@tabler/icons";
import { GetInspectionDataQueryResult } from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";
import useSiteInspectionUser from "src/domain-features/siteinspection/utils/useSiteInspectionUser";
import Button from "src/common/components/general/Button";
import {
  useCurrentDBLangSelector,
  useCurrentLangStrings,
} from "../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteInspectionWorkerInspectionsProps
  extends SiteInspectionStepProps {
  showCompleted: boolean;
  qrName?: string;
  setShowCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  inspectionOptions: NonNullable<
    NonNullable<GetInspectionDataQueryResult["data"]>["getInspectionData"]
  >["inspections"];
}

const SiteInspectionWorkerInspections: React.FC<
  SiteInspectionWorkerInspectionsProps
> = (props) => {
  const workerContext = useSiteInspectionUser();
  const [inspection, setInspection] = useState(
    workerContext.user?.inspection?.id,
  );

  const langStrings = useCurrentLangStrings();
  const gs = useCurrentDBLangSelector();
  const onEnter = (selectedInsp?: string) => {
    const foundInspection = props.inspectionOptions.find(
      (ins) => ins.id === (selectedInsp ?? inspection),
    );
    if (!foundInspection) {
      message.error(langStrings.strings.pleaseSelectAnInspection);
      return;
    }
    workerContext.updateUser((user) => {
      return user
        ? // why using selectedinspection here? it's because when user selects a inspection we run onEnter and setInspection at the same time due to which inspection's value is not set when running onEnter fully
          {
            ...user,
            inspection: foundInspection,
            model: foundInspection.mode_number,
          }
        : { inspection: foundInspection, model: foundInspection.mode_number };
    });
    props.onNext();
  };

  const inspectionOptions: {
    id: string;
    title: string;
    model_number: string;
    previousDeficient?: {
      date: string;
      count: number;
    };
  }[] = props.inspectionOptions.map((p) => ({
    id: p.id,
    title: gs(p.name),
    model_number: p.mode_number ?? "",
    previousDeficient:
      workerContext.currentSessionInspectionsRequiredData[p.id] !== undefined
        ? workerContext.currentSessionInspectionsRequiredData[p.id].defCount > 0
          ? {
              count:
                workerContext.currentSessionInspectionsRequiredData[p.id]
                  .defCount,
              date: dayjs().format("MM/DD/YYYY"),
            }
          : undefined
        : p.lastCompletedInspectionData &&
          p.lastCompletedInspectionData.deficientCount > 0
        ? {
            date: dayjs(p.lastCompletedInspectionData.fullDateAndTime).format(
              "MM/DD/YYYY",
            ),
            count: p.lastCompletedInspectionData.deficientCount,
          }
        : undefined,
  }));

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={() => onEnter()}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!inspection}
    >
      <Button
        secondary
        icon={props.showCompleted ? IconEye : IconBook}
        label={
          props.showCompleted
            ? langStrings.strings.doAnInspection
            : langStrings.strings.viewInspectionResults
        }
        onClick={() => props.setShowCompleted((i) => !i)}
      />
      <div className="text-2 text-suplementary-3 mt-1">
        {props.qrName ? props.qrName + " QR Code" : ""}
      </div>
      <SiteFeatureStepsInputLayout
        heightPercent={90}
        headline={
          props.showCompleted
            ? langStrings.strings.selectToViewInspectionResults
            : langStrings.strings.selectAnInspection
        }
      >
        <div className={`flex flex-row items-center justify-center`}>
          <div
            className={`w-full flex flex-col items-stretch justify-center gap-0.25 tablet-portrait::gap-0.5`}
          >
            {inspectionOptions.map((inspectionOption) => {
              const isSelected = inspectionOption.id === inspection;
              return (
                <div
                  key={inspectionOption.id}
                  onClick={() => {
                    setInspection(inspectionOption.id);
                    onEnter(inspectionOption.id);

                    console.log(inspectionOption.id);
                  }}
                  className={clsx(
                    `flex-1 h-auto cursor-pointer border w-full rounded-0.25 tablet-portrait:rounded-0.5 transition-colors flex flex-col gap-0 p-0.5 tablet-portrait:p-1`,
                    isSelected
                      ? "text-white bg-interactive-primary border-transparent"
                      : `text-static-primary ${
                          inspectionOption.previousDeficient
                            ? "bg-semantic-negative-light"
                            : "bg-white"
                        } border-interactive-secondary hover:border-interactive-primary hover:text-interactive-primary`,
                  )}
                >
                  <div className={`text-3 flex`}>
                    <Icon
                      icon={IconChecklist}
                      color={isSelected ? "white" : undefined}
                    />
                    <p className={`font-accent text-1`}>
                      {inspectionOption.title}
                    </p>
                  </div>
                  <div
                    className={clsx(
                      "ml-1.5 flex justify-between",
                      inspectionOption.previousDeficient
                        ? "flex-row-reverse"
                        : "",
                    )}
                  >
                    {inspectionOption.previousDeficient ? (
                      <div className="flex text-semantic-inactive-dark ">
                        ({inspectionOption.previousDeficient.count}) Def &nbsp;
                        <span>{inspectionOption.previousDeficient.date}</span>
                      </div>
                    ) : (
                      <></>
                    )}
                    {inspectionOption.model_number}{" "}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteInspectionWorkerInspections;
