import React, { useState, useRef } from "react";
import { message, Modal } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { VerifySubcontractorQuery$data } from "src/common/types/generated/relay/VerifySubcontractorQuery.graphql";

interface ConfirmNewSubcontractorModalProps {
  modalClose: () => void;
  modalVisible: boolean;
  form: FormInstance;
  subRequestQueryResult: VerifySubcontractorQuery$data;
  onFinish: (sameEmailPresent: boolean) => Promise<void>;
}

const ConfirmNewSubcontractorModal: React.FC<
  ConfirmNewSubcontractorModalProps
> = ({
  modalClose,
  modalVisible,
  form,
  subRequestQueryResult,
  onFinish,
}) => {
  const queryResult =
    subRequestQueryResult.add_subcontractor_request_connection.edges[0].node;
  const navigate = useNavigate();

  return (
    <Modal
      open={modalVisible}
      title="Add a New Subcontractor or Trade Partner to SiteForm"
      okText="Yes"
      cancelText="Cancel"
      onCancel={() => {
        modalClose();
        navigate(`/sfe/verify/subcontractors/`);
      }}
      onOk={async () => {
        await onFinish(true);
        modalClose();
      }}
    >
      <p>
        This email address already exists, are you sure this is a new
        Subcontractor? Please confirm with your supervisor before processing.
      </p>
    </Modal>
  );
};

export default ConfirmNewSubcontractorModal;
