import { Checkbox, Descriptions, message, notification, Table } from "antd";

import Item from "antd/lib/descriptions/Item";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, FunctionComponent, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Button from "src/common/components/general/Button";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { IconArrowLeft, IconCheck } from "@tabler/icons";
import Image from "src/common/components/general/images/Image";
import Popover from "src/common/components/general/Popover";
import { PendingTaskViewQuery } from "src/common/types/generated/relay/PendingTaskViewQuery.graphql";
import { PendingTaskViewMutation } from "src/common/types/generated/relay/PendingTaskViewMutation.graphql";
import viewAndEditJhaQuery from "src/common/api/relay/queries/ViewAndEditJha";
import { ViewAndEditJhaQuery } from "src/common/types/generated/relay/ViewAndEditJhaQuery.graphql";
import { NULL_ID } from "src/common/functions/nullId";
import ViewAndEditTask from "./ViewAndEditTask";
import ViewAndEditTaskType from "src/common/types/manual/ViewAndEditTaskType";

const mapEcms = (ecmId: string) => {
  const e = "39ab97a5-47ac-4e5d-b287-21c2cb016c16",
    c = "05660c6a-c370-4c82-86a9-e558772d7d56",
    m = "57774dd6-3e26-45aa-a7cd-c3cb518a75f9";
  if (ecmId === e) return "E";
  else if (ecmId === c) return "C";
  else if (ecmId === m) return "M";
  else return "";
};

interface CustomHeaderProps {
  header: string;
  size?: "large" | "small";
}

export const CustomHeader: FC<CustomHeaderProps> = ({ header, size }) => (
  <p
    className={`pl-1.5 pt-1.5 mb-1 ${
      size === "large" ? "text-1.5" : "text-1"
    } font-accent`}
  >
    {header}
  </p>
);
const query = graphql`
  query PendingTaskViewQuery(
    $taskId: uuid!
    $loggedInUserId: uuid!
    $previousImageTaskWhere: task_bool_exp!
  ) {
    user_connection(where: { id: { _eq: $loggedInUserId } }) {
      edges {
        node {
          role
        }
      }
    }
    previousImageTask: task_connection(where: $previousImageTaskWhere) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          created_at
          created_by_uid
        }
      }
    }
    task_connection(where: { id: { _eq: $taskId } }) {
      edges {
        node {
          ...TaskFrag @relay(mask: false)
        }
      }
    }
  }
`;

const updateTaskStatusMutation = graphql`
  mutation PendingTaskViewMutation(
    $taskId: uuid!
    $_set: task_set_input!
    $deleteTaskWhere: task_bool_exp!
    $updateTaskEditWhere: task_edit_bool_exp!
    $taskEditObjects: [task_edit_insert_input!]!
    $taskSignObjects: [task_signature_insert_input!]!
  ) {
    update_task_edit(where: $updateTaskEditWhere, _set: { task_id: $taskId }) {
      affected_rows
    }
    update_task_by_pk(pk_columns: { id: $taskId }, _set: $_set) {
      id
    }
    insert_task_edit(objects: $taskEditObjects) {
      affected_rows
    }
    delete_task(where: $deleteTaskWhere) {
      affected_rows
    }
    insert_task_signature(objects: $taskSignObjects) {
      affected_rows
    }
  }
`;

const RequestedJHAView: FC<{
  taskId: string;
  type: string | null;
  loggedInUserId: string;
  requestId?: string | null;
  projectId?: string;
}> = ({ taskId, type, loggedInUserId, requestId, projectId }) => {
  const data = useLazyLoadQuery<PendingTaskViewQuery>(query, {
    taskId,
    loggedInUserId,
    previousImageTaskWhere: requestId
      ? {
          report_id: { _is_null: true },
          creation_request_id: { _eq: requestId },
          ...(projectId ? { project_id: { _eq: projectId } } : {}),
          task_creation_request: { type: { _eq: "images_for_one" } },
          show_type: { _eq: "images" },
        }
      : { id: { _is_null: true } },
  });

  console.log(loggedInUserId, taskId);

  const queryData = useLazyLoadQuery<ViewAndEditJhaQuery>(viewAndEditJhaQuery, {
    currentUserId: loggedInUserId,
    emailRequestWhere: {},
    projectId: NULL_ID,
    subId: NULL_ID,
    taskId: taskId,
  });

  const taskData = queryData.task_connection.edges[0].node;
  const taskStepsData = queryData.task_step_connection.edges;

  const viewAndEditTaskData: ViewAndEditTaskType = {
    description: taskData.description.en,
    descriptionId: taskData.description_id,
    ecms: queryData.ecm_type_connection.edges.map((ecm) => ({
      id: ecm.node.pk,
      description: ecm.node.name?.original ?? "",
    })),
    id: taskId,
    permits: queryData.permit_type_connection.edges.map((permit) => ({
      id: permit.node.pk,
      description: permit.node.name.en,
    })),
    ppeCategories: queryData.ppe_category_connection.edges.map(
      (ppeCategory) => ({
        id: ppeCategory.node.id,
        description: ppeCategory.node.name.en,
        ppes: ppeCategory.node.ppe_types.map((ppe) => ({
          id: ppe.pk,
          description: ppe.name.en,
        })),
      }),
    ),
    steps: taskStepsData.map((step) => ({
      description: step.node.description.en,
      id: step.node.pk,
      hazards: step.node.task_step_hazards.map((haz) => ({
        id: haz.pk,
        description: haz.description.en,
        control: haz.control.en,
        ecms: haz.task_hazard_ecm_types
          ? haz.task_hazard_ecm_types.map((ecm) => ({
              id: ecm.ecm_type_id,
              description: ecm.ecm_type?.name?.en ?? "",
            }))
          : [],
      })),
    })),

    taskPpes: queryData.task_ppe_type_connection.edges.map((ppe) => ({
      id: ppe.node.pk,
      description: ppe.node.ppe_type.name.en,
    })),

    taskPermits: queryData.task_permit_type_connection.edges.map((permit) => ({
      id: permit.node.pk,
      description: permit.node.permit_type.name.en,
    })),

    queryData: queryData,
  };

  const previousImageTypeTask = data.previousImageTask.edges[0]
    ? data.previousImageTask.edges[0].node
    : undefined;

  const userRole = data.user_connection.edges[0].node.role;
  const [updateTask] = useAsyncMutation<PendingTaskViewMutation>(
    updateTaskStatusMutation,
  );

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const task = data.task_connection.edges[0].node;
  const turnerGCId = "96916668-c816-4c2a-9008-73a6116d4c00";
  const [isNotTurnersProject, setIsNotTurnersProject] = useState(
    task.task_creation_request?.project?.general_contractor_id !== turnerGCId,
  );
  let dataSource: any = [];
  const text = task.task_creation_request ? (
    <>
      <div className="w-full bg-white ml-1.5">
        <Descriptions size="middle" bordered>
          {(task.task_creation_request.project ?? task.project) && (
            <>
              <Item label="Project Name">
                {task.task_creation_request.project?.name ?? task.project?.name}
              </Item>
              <Item label="General Contractor">
                {task.task_creation_request.project?.general_contractor.name ??
                  task.project?.general_contractor.name}
              </Item>
            </>
          )}
          <Item label="Subcontractor">
            {task.task_creation_request.subcontractor.name}
          </Item>
          <Item label="Date Requested">
            {!!(task.task_creation_request.requested_at)
              ? dayjs(task.task_creation_request.requested_at).format(
                  "MMM DD,YYYY h:mm A",
                )
              : ""}
          </Item>
          <Item label="Requested By">
            {task.task_creation_request
              ? task.task_creation_request.requested_by_user.name
              : ""}
          </Item>
          <Item label="Date Sent For Approval">
            {task.document && task.document.jha_creation_request_verified_at
              ? dayjs(task.document.jha_creation_request_verified_at).format(
                  "MMM DD,YYYY h:mm A",
                )
              : task.task_creation_request.attached_files[0] &&
                task.task_creation_request.attached_files[0]
                  .jha_creation_request_verified_at
              ? dayjs(
                  task.task_creation_request.attached_files[0]
                    .jha_creation_request_verified_at,
                ).format("MMM DD,YYYY h:mm A")
              : ""}
          </Item>
          {task.task_creation_request.users_requested_for_task_approval
            .length !== 0 && (
            <Item label="Sent to For Approval">
              {task.task_creation_request.users_requested_for_task_approval.map(
                (p, i) =>
                  p.user.name +
                  (i + 1 !==
                  task.task_creation_request?.users_requested_for_task_approval
                    .length
                    ? ", "
                    : ""),
              )}
            </Item>
          )}
          {task.task_creation_request.type === "images_for_one" && (
            <Item label="Images">
              <div className="flex gap-2 text-interactive-primary">
                {task.task_creation_request.attached_files.map((p, i) => (
                  <Popover
                    showOnClick
                    content={
                      <div className="max-h-32 overflow-y-auto gap-0.5 w-24">
                        <Image src={p.url} preview={true} />
                      </div>
                    }
                  >
                    <div>{`Image ${i + 1}`}</div>
                  </Popover>
                ))}
              </div>
            </Item>
          )}
        </Descriptions>
      </div>
    </>
  ) : (
    <></>
  );
  dataSource = [
    {
      id: task.pk,
      key: task.pk,
      taskName: task.description.en,
      task_steps: task.task_steps?.length,
      task_ppe_types: task.task_ppe_types?.length,
      task_permit_types: task.task_permit_types?.length,
      task_steps_hazards: task.task_steps.reduce(
        (sum, h) => sum + h.task_step_hazards.length,
        0,
      ),
    },
  ];
  const columns: any = [
    {
      title: "JHA (Job Hazard Analysis)",
      key: "taskName",
      dataIndex: "taskName",
      width: "35%",
    },
    {
      title: "Steps",
      key: "task_steps",
      dataIndex: "task_steps",
    },
    {
      title: "Hazards",
      key: "task_steps_hazards",
      dataIndex: "task_steps_hazards",
      width: "18%",
    },
    {
      title: "Permits",
      key: "task_permit_types",
      dataIndex: "task_permit_types",
      width: "17%",
    },
    {
      title: "PPE",
      key: "task_ppe_types",
      dataIndex: "task_ppe_types",
    },
  ];
  let x = 0;
  const onAccept = async () => {
    setLoading(true);
    const time = previousImageTypeTask
      ? previousImageTypeTask.created_at
      : dayjs().toISOString();

    await updateTask({
      variables: {
        ...(previousImageTypeTask
          ? {
              deleteTaskWhere: { id: { _eq: previousImageTypeTask.pk } },
              updateTaskEditWhere: {
                task_id: { _eq: previousImageTypeTask.pk },
              },
            }
          : {
              deleteTaskWhere: { id: { _is_null: true } },
              updateTaskEditWhere: {
                id: { _is_null: true },
              },
            }),
        taskId,
        _set: {
          accepted_at_from_creation_request: dayjs().toISOString(),
          accepted_by_uid_from_creation_request: loggedInUserId,
          is_pending_acceptance: false,
          project_id: task.task_creation_request?.project_id ?? task.project_id,
          subcontractor_id: task.task_creation_request?.subcontractor_id,
          created_by_uid:
            previousImageTypeTask?.created_by_uid ?? loggedInUserId,
          created_at: time,
        },
        taskEditObjects:
          type === "subLib"
            ? []
            : [
                {
                  edit_type:
                    type === "gcProj" ? "Accepted" : "Accepted and Approved",
                  edited_at: dayjs().toISOString(),
                  edited_by_uid: loggedInUserId,
                  task_id: taskId,
                },
              ],
        taskSignObjects:
          type === "subLib" || type === "gcProj"
            ? []
            : [
                {
                  created_at: dayjs().format(),
                  signature_image_id: null,
                  user_id: loggedInUserId,
                  is_active: true,
                  task_id: taskId,
                },
              ],
      },
    })
      .catch((e) =>
        notification.error({
          message: "Error: Couldn't accept the JHA",
          description: e.message,
        }),
      )
      .then(() => {
        message.success("Added JHA to the Library");
        navigate(-1);
      });

    setLoading(false);
  };

  return (
    <>
      <div className="flex flex-row gap-1">
        <Button
          loading={loading}
          icon={IconArrowLeft}
          onClick={() => {
            navigate(-1);
          }}
          secondary
          label="Back"
        />

        <BPopconfirm
          title="Are you sure you want to accept this JHA"
          onConfirm={() => onAccept()}
        >
          <Button
            loading={loading}
            icon={IconCheck}
            label="Accept JHA"
            onClick={() => {}}
          />
        </BPopconfirm>
      </div>
      {type && type === "subLib" && (
        <Checkbox
          className="mt-1"
          value={isNotTurnersProject}
          onChange={(value) => setIsNotTurnersProject(!value.target.checked)}
        >
          Use Turner's Format
        </Checkbox>
      )}
      <CustomHeader header={`JHA: ${task.description.en}`} size="large" />
      {text}
      <div className="ml-1.5">
        <Table
          columns={columns}
          dataSource={dataSource}
          className="pt-2"
          bordered
          scroll={{ x: 50 * 10 }}
          size={"large"}
        />
      </div>

      <ViewAndEditTask
        data={viewAndEditTaskData}
        isNotTurnersProject={isNotTurnersProject}
        taskId={taskId}
        type="pending_jha"
      />

      {/*
      <StyledContent>
        <CustomHeader header={`PPE (Personal Protective Equipment)`} />
        <div className="ml-2">
          {task.task_ppe_types.map((cur: any) => {
            return (
              <Tag
                key={cur.ppe_type.id}
                id={cur.ppe_type.id}
                className="text-semantic-pending mr-0.5 mt-0.5 text-0.9"
                color="#e6f7ff"
              >
                {cur.ppe_type.name.en}
              </Tag>
            );
          })}
        </div>
      </StyledContent>
      <StyledContent>
        <CustomHeader header={`Permits or Tags Required to Perform this JHA`} />
        <div className="ml-2">
          {task.task_permit_types.map((cur: any) => {
            return (
              <Tag
                key={cur.permit_type.id}
                id={cur.permit_type.id}
                color="#e6f7ff"
                className="text-semantic-pending mr-0.5 mt-0.5 text-0.9"
              >
                {cur.permit_type.name.en}
              </Tag>
            );
          })}
        </div>
      </StyledContent>
      <StyledContent>
        <div key={x++} className="mr-0.5 ml-0.25 bg-suplementary-2 pb-2">
          <CustomHeader header={`Steps (${dataSource[0]?.task_steps})`} />
          {task.task_steps.map((step, i) => (
            <div key={step.pk}>
              <p className="pt-0.75 pl-1.5">
                <span className="text-1 font-accent">Step {i + 1}.</span>{" "}
                {step.description.en}
              </p>

              {step.task_step_hazards.length > 0 ? (
                <p className="pl-2 pt-0.75 text-1 font-accent">
                  {" "}
                  Hazards ({step.task_step_hazards.length})
                </p>
              ) : (
                <div key={x++}></div>
              )}

              {step.task_step_hazards.map((h: any, i2: number) => {
                const ecms = (h.task_hazard_ecm_types ||
                  []) as Array<Task_Hazard_Ecm_Type>;
                return (
                  <>
                    <div key={h.pk}>
                      <p className="pt-0.75 pl-2">
                        {i2 + 1}. {h.description.en}
                      </p>
                      <p key={x++} className="pl-2.5 pt-0.5">
                        Control: {h.control.en}
                      </p>
                    </div>
                    {isNotTurnersProject ? (
                      <></>
                    ) : (
                      <div className="pl-2.5 pt-0.5">
                        {ecms.length > 0 || true ? "E,C,M: " : ""}
                        {ecms.map(
                          (ecm, ecmIndex) =>
                            mapEcms(ecm.ecm_type_id ?? "") +
                            (ecmIndex !== ecms.length - 1 ? ", " : "")
                        )}
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          ))}
        </div>
            </StyledContent>*/}
    </>
  );
};
const RequestedJHAViewWrapper: FunctionComponent = () => {
  const { projectId, subcontractorId } = useParams();
  const [searchParams] = useSearchParams();
  const loggedInUserId = auth.currentUser?.uid;
  const idFromSubLib = searchParams.get("idFromSubLib");
  const idFromSubProj = searchParams.get("idFromSubProj");
  const idFromGCProj = searchParams.get("idFromGCProj");
  const requestId = searchParams.get("requestId");
  const taskId = idFromGCProj || idFromSubProj || idFromSubLib;

  console.log("ids.....", taskId, loggedInUserId);
  return taskId && loggedInUserId ? (
    <BasicWrapper scrollable>
      <div>
        <RequestedJHAView
          {...{
            taskId,
            type: idFromSubLib
              ? "subLib"
              : idFromSubProj
              ? "subProj"
              : idFromGCProj
              ? "gcProj"
              : null,
            loggedInUserId,
            requestId,
            projectId,
          }}
        />
      </div>
    </BasicWrapper>
  ) : (
    <Navigate to="/" replace />
  );
};
export default RequestedJHAViewWrapper;
