import { IconBriefcase } from "@tabler/icons";
import { graphql } from "babel-plugin-relay/macro";

import React, { forwardRef, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import { VisitorsTable_CompanyList_Query } from "src/common/types/generated/relay/VisitorsTable_CompanyList_Query.graphql";
import {
  project_visitor_certification_bool_exp,
  VisitorsTableQuery,
} from "src/common/types/generated/relay/VisitorsTableQuery.graphql";
import { VisitorsTable_project_visitor_certification$data } from "src/common/types/generated/relay/VisitorsTable_project_visitor_certification.graphql";
import dayjs from "dayjs";
import { CheckboxCustomFilter } from "src/common/components/filter/CustomFilterComponents/CustomFilterController";

type ColumnKeys =
  | "created_at"
  | "company"
  | "name"
  | "time_in"
  | "time_out"
  | "email"
  | "purpose"
  | "hours";
const CONNECTION_NAME = "project_visitor_certification_connection";
type DConnection =
  VisitorsTable_project_visitor_certification$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  VisitorsTableQuery,
  string
> & {
  showDateRangeFilter?: boolean;
  projectId: string;
};
const query = graphql`
  query VisitorsTable_CompanyList_Query($project_id: uuid!) {
    general_contractor_connection(
      where: { projects: { id: { _eq: $project_id } } }
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
    project_subcontractor_connection(
      where: { project: { id: { _eq: $project_id } } }
    ) {
      edges {
        node {
          subcontractor {
            id
            name
          }
        }
      }
    }
    project_oac_company_connection(
      where: { project: { id: { _eq: $project_id } } }
    ) {
      edges {
        node {
          oac_company {
            id
            name
          }
        }
      }
    }
    visitor_company_connection(
      where: { project: { id: { _eq: $project_id } } }
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
const VisitorsTable = forwardRef<DataScrollTableRef, Props>(
  ({ title = "Visitors", onRowClick, showDateRangeFilter, ...props }, ref) => {
    let data = useLazyLoadQuery<VisitorsTable_CompanyList_Query>(query, {
      project_id: props.projectId,
    });
    let companyList: string[] = [];
    data.general_contractor_connection.edges.forEach((value) => {
      if (value.node?.name) companyList.push(value.node.name);
    });
    data.project_subcontractor_connection.edges.forEach((value) => {
      if (value.node.subcontractor?.name)
        companyList.push(value.node.subcontractor.name);
    });
    data.project_oac_company_connection.edges.forEach((value) => {
      if (value.node.oac_company?.name)
        companyList.push(value.node.oac_company.name);
    });
    data.visitor_company_connection.edges.forEach((value) => {
      if (value.node?.name) companyList.push(value.node.name);
    });
    companyList.sort();
    const [companyFilterWhere, setCompanyFilterWhere] =
      useState<project_visitor_certification_bool_exp>({
        project_id: { _eq: props.projectId },
      });
    let optionList: CheckboxCustomFilter["options"] = [];

    companyList.forEach((value) => {
      optionList.push({
        option: value,
        onApply: () => {
          setCompanyFilterWhere((prev) => {
            if (prev._or?.length)
              return {
                _or: [
                  ...prev._or,
                  {
                    _or: [
                      {
                        general_contractor: {
                          name: { _eq: value },
                        },
                      },
                      {
                        subcontractor: {
                          name: { _eq: value },
                        },
                      },
                      {
                        oac_company: {
                          name: { _eq: value },
                        },
                      },
                      {
                        visitor_company: {
                          name: { _eq: value },
                        },
                      },
                    ],
                  },
                ],
              };
            else
              return {
                _or: [
                  {
                    _or: [
                      {
                        general_contractor: {
                          name: { _eq: value },
                        },
                      },
                      {
                        subcontractor: {
                          name: { _eq: value },
                        },
                      },
                      {
                        oac_company: {
                          name: { _eq: value },
                        },
                      },
                      {
                        visitor_company: {
                          name: { _eq: value },
                        },
                      },
                    ],
                  },
                ],
              };
          });
        },
        onCancel: () => {
          setCompanyFilterWhere((prev) => {
            let newPrev: project_visitor_certification_bool_exp[] = [];
            if (prev._or?.length) {
              prev._or?.forEach((c, i) => {
                if (c._or && c._or[0].general_contractor?._or) {
                  if (c._or[0].general_contractor?._or[0].name?._eq != value) {
                    newPrev.push(c);
                  }
                }

                //  setCompanyFilterWhere()
              });
            }
            if (newPrev.length)
              return {
                _or: [...newPrev],
              };
            else return {};
          });
        },
      });
    });
    return (
      <DataScrollTable<DConnection, ColumnKeys, VisitorsTableQuery>
        {...props}
        newCustomTableLook
        filterNotVisibleByDefault
        ref={ref}
        title={title}
        customFilters={[
          {
            type: "checkbox",
            title: "Company",
            removeFilter: () => {
              setCompanyFilterWhere((prev) => {
                const { _or, ...rest } = prev;
                return rest;
              });
            },
            options: optionList,
          },
        ]}
        extraSearchDataIndexes={[
          [{ index: "visitor_company" }, { index: "name" }],
          [{ index: "subcontractor" }, { index: "name" }],
          [{ index: "general_contractor" }, { index: "name" }],
          [{ index: "oac_company" }, { index: "name" }],
        ]}
        datePickerDataIndex={showDateRangeFilter ? ["created_at"] : undefined}
        onRowClick={(report) => {
          onRowClick && onRowClick(report.pk);
        }}
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allReportsConnection"}
        columns={[
          {
            title: "Date",
            key: "created_at",
            size: "md",
            sortable: true,
            defaultSortOrder: "desc",
            dataIndex: ["created_at"],
            render: (value, record, index) =>
              dayjs(record.created_at)
                .tz("America/New_York")
                .format("MMMM Do, YYYY"),
          },
          {
            title: "Company",
            key: "company",
            size: "md",
            dataIndex: ["visitor_company", "name"],
            searchDataIndex: ["visitor_company", "name"],

            render: (value, record, index) =>
              record.general_contractor
                ? record.general_contractor.name
                : record.subcontractor
                ? record.subcontractor.name
                : record.oac_company
                ? record.oac_company.name
                : record.visitor_company?.name,
            // filters: {
            //   dataIndex: ["company_name"],
            //   options: companyList.map((r) => ({
            //     text: capitalize(r),
            //     value: r,
            //   })),
            // },
          },
          {
            title: "Name",
            key: "name",
            size: "md",
            dataIndex: ["name"],
            searchDataIndex: ["name"],
            contentType: {
              type: "with-icon",
              renderOptions: (value) => ({
                icon: IconBriefcase,
                label: value,
              }),
            },
          },
          {
            title: "Email",
            key: "email",
            size: "md",
            dataIndex: ["email"],
            searchDataIndex: ["email"],
          },
          {
            title: "Time In",
            key: "time_in",

            size: "sm",
            sortable: true,
            dataIndex: ["created_at"],
            render: (value, record, index) =>
              dayjs(record.created_at)
                .tz(record.project.timezone)
                .format("hh:mm A"),
          },
          {
            title: "Est. Time Out",
            key: "time_out",

            size: "sm",
            sortable: true,
            dataIndex: ["created_at"],
            render: (value, record, index) =>
              dayjs(record.created_at)
                .add(record.hours_planning_to_stay, "hours")
                .tz(record.project.timezone)
                .format("hh:mm A"),
          },
          {
            title: "Hours",
            key: "hours",
            size: "sm",
            sortable: true,
            dataIndex: ["hours_planning_to_stay"],
          },
          {
            title: "Purpose",
            key: "purpose",
            size: "md",
            dataIndex: ["comment"],
            searchDataIndex: ["comment"],
          },
        ]}
        where={companyFilterWhere}
        queryNode={graphql`
          query VisitorsTableQuery(
            $first: Int!
            $after: String
            $where: project_visitor_certification_bool_exp!
            $order_by: [project_visitor_certification_order_by!]!
          ) {
            ...VisitorsTable_project_visitor_certification
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
            ...VisitorsTable_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment VisitorsTable_total on query_root
          @argumentDefinitions(
            where: { type: "project_visitor_certification_bool_exp!" }
          )
          @refetchable(queryName: "VisitorsTableTotalRefetchableQuery") {
            allReportsConnection: project_visitor_certification_connection(
              where: $where
            ) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment VisitorsTable_project_visitor_certification on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "project_visitor_certification_bool_exp!" }
            order_by: { type: "[project_visitor_certification_order_by!]!" }
          )
          @refetchable(queryName: "VisitorsTableRefetchableQuery") {
            project_visitor_certification_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "VisitorsTable_project_visitor_certification_connection"
                filters: []
              ) {
              edges {
                node {
                  pk: id @__clientField(handle: "pk")
                  created_at
                  name
                  email
                  hours_planning_to_stay
                  project {
                    timezone
                  }
                  comment
                  general_contractor {
                    id
                    name
                  }
                  subcontractor {
                    id
                    name
                  }
                  oac_company {
                    id
                    name
                  }
                  visitor_company {
                    id
                    name
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);

export default VisitorsTable;
