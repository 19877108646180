import { Layout } from "antd";
import React, { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";

const { Header } = Layout;

interface Props {
  headerMenuItems: Array<{ path: string; title: string }>;
  children?: React.ReactNode;
}

// TODO logo
const BaseUserLayout: FC<Props> = ({ children, headerMenuItems }) => {
  const location = useLocation();
  let x = 0;
  return (
    <Layout style={{ height: "100vh", overflowY: "hidden" }}>
      <Header className="header" style={{ display: "flex" }}>
        {/* <img src={logo} style={{ padding: "20px", height: 70, width: 220 }} /> */}
        <div className="flex flex-row">
          {headerMenuItems.map((item) => (
            <NavLink
              key={x++}
              className={({ isActive }) =>
                `transition-all px-1 text-white opacity-60 hover:text-white hover:opacity-100 ${
                  isActive
                    ? "bg-interactive-primary opacity-100"
                    : "bg-transparent"
                }`
              }
              to={item.path}
            >
              {item.title}
            </NavLink>
          ))}
        </div>
      </Header>
      <div style={{ display: "flex", flex: 1, height: "10%" }}>{children}</div>
    </Layout>
  );
};

export default BaseUserLayout;
