import React from "react";

import { useSearchParams } from "react-router-dom";

import QuickLookWorker from "./components/QuickLookWorker";
import UniversalOrientationCompletionPage from "./components/UniversalOrientationCompletionPage";

export interface WorkerUniversalOrientationViewProps {}

const WorkerUniversalOrientationView: React.FC<
  WorkerUniversalOrientationViewProps
> = (props) => {
  const [searchParams] = useSearchParams();
  const loginCode = searchParams.get("qrc");
  const workerId = searchParams.get("worker");
  const reference = searchParams.get("reference");
  if (typeof workerId !== "string" && typeof reference !== "string") {
    return <h1> Wrong url </h1>;
  }

  return (
    <>
      {workerId && loginCode ? (
        <UniversalOrientationCompletionPage
          workerId={workerId}
          loginCode={loginCode}
        />
      ) : (
        reference && <QuickLookWorker reference={reference} />
      )}
    </>
  );
};

export default WorkerUniversalOrientationView;
