import React from "react";
import { Button, Popover } from "antd";
import CustomSuspense from "../general/CustomSuspense";
import { QuestionOutlined } from "@ant-design/icons";
import { ButtonProps } from "antd/es/button";

// TODO change to FloatButton element when updating to Ant Design v5

interface FloatingButtonProps {
  popoverContent: React.ReactNode;
  popoverTitle?: string;
  icon?: ButtonProps["icon"];
  popoverVisible: boolean;
  onPopoverVisibleChange: (visible: boolean) => void;
}

const FloatingButton: React.FC<FloatingButtonProps> = (props) => {
  return (
    <div className={`fixed z-50 bottom-2 right-2`}>
      <Popover
        content={<CustomSuspense>{props.popoverContent}</CustomSuspense>}
        title={props.popoverTitle}
        trigger="click"
        open={props.popoverVisible}
        onOpenChange={props.onPopoverVisibleChange}
        placement={"topLeft"}
      >
        <Button
          className={`shadow`}
          size={"large"}
          type="default"
          shape="circle"
          icon={<QuestionOutlined />}
        />
      </Popover>
    </div>
  );
};

export default FloatingButton;
