import { InfoCircleOutlined } from "@ant-design/icons";
import { IconArrowLeft, IconCheck, IconX } from "@tabler/icons";
import { Form, Tooltip, Button, notification, Divider } from "antd";
import React, { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import withEmployeeData from "src/common/components/EmployeeDataHOC";
import Switcher from "src/common/components/general/Switcher";
import { graphql } from "babel-plugin-relay/macro";
import { VisitorSettings_UpdateWaiverText_Mutation } from "src/common/types/generated/relay/VisitorSettings_UpdateWaiverText_Mutation.graphql";
import { VisitorSettings_toggleSignWaiverSwitch_Mutation } from "src/common/types/generated/relay/VisitorSettings_toggleSignWaiverSwitch_Mutation.graphql";
import { useLazyLoadQuery } from "react-relay/hooks";
import CustomButton from "src/common/components/general/Button";

import { useGetVisitorQrCodePosterMutation } from "src/common/types/generated/apollo/graphQLTypes";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import PosterDownload from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/settings/components/PosterDownload";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import JoditEditor from "jodit-react";
import { JoditConfig } from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/slides/GCProjectOrientationDetail";
import SiteOrientationGcQrCode from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/settings/components/qr/SiteOrientationGcQrCode";
import { GCReportsNavigationQuery } from "src/common/types/generated/relay/GCReportsNavigationQuery.graphql";
import { reportsNavigationQuery } from "../../../GCReportsNavigation";
import useAuthUser from "src/common/hooks/useAuthUser";

interface VisitorSettingsProps {}
const buttons = [
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "ul",
  "ol",
  "|",
  "outdent",
  "indent",
  "|",
  "paragraph",
  "font",
  "fontsize",
  "brush",
  "\n",
  "align",
  "undo",
  "redo",
  "|",
  "image",
  "video",
  "table",
  "link",
  "|",
  "hr",
  "eraser",
  "|",
  "symbol",
  "fullsize",
];

// TODO add new item in table while editing
const VisitorSettings: FC<VisitorSettingsProps> = ({}) => {
  const { projectId } = useParams();
  const authUser = useAuthUser();
  if (!projectId) throw new Error("ProjectId not found");
  const data = useLazyLoadQuery<GCReportsNavigationQuery>(
    reportsNavigationQuery,
    { projectId: projectId, empId: authUser.uid },
    { fetchPolicy: "store-or-network" },
  );
  const [updateWaiverText] =
    useAsyncMutation<VisitorSettings_UpdateWaiverText_Mutation>(graphql`
      mutation VisitorSettings_UpdateWaiverText_Mutation(
        $projectId: uuid!
        $waiverText: String!
      ) {
        update_project(
          where: { id: { _eq: $projectId } }
          _set: { visitor_waiver_text: $waiverText }
        ) {
          affected_rows
        }
      }
    `);
  const [toggleSignWaiverEnabled] =
    useAsyncMutation<VisitorSettings_toggleSignWaiverSwitch_Mutation>(
      graphql`
        mutation VisitorSettings_toggleSignWaiverSwitch_Mutation(
          $projectId: uuid!
          $_set: project_set_input!
        ) {
          update_project(where: { id: { _eq: $projectId } }, _set: $_set) {
            returning {
              id
              visitor_sign_waiver
            }
          }
        }
      `,
    );

  const navigate = useNavigate();
  const project = data.project_connection.edges[0];
  const gc = data.general_contractor_connection.edges[0];
  const [form] = Form.useForm();
  const [waiverText, updatingWaiverText] = useState(false);
  const [content, setContent] = useState(
    project.node.visitor_waiver_text && project.node.visitor_waiver_text.length
      ? project.node.visitor_waiver_text
      : gc.node.visitor_waiver_text,
  );
  const [getVisitorQRCodePoster, { loading: orientationQRCodePosterLoading }] =
    useGetVisitorQrCodePosterMutation();

  const agcPosterBackgroundImageUrl =
    "https://storage.googleapis.com/siteform-3170b.appspot.com/StaticAssets/scan%20qr%20code%20(4).png";

  return (
    <div>
      <div className="mt-1">
        <CustomButton
          onClick={() => {
            navigate(-1);
          }}
          icon={IconArrowLeft}
          label="Back"
        />
      </div>

      <p className="my-1 text-1.5 font-accent">Visitor Log Settings </p>
      <div className="flex ">
        <SiteOrientationGcQrCode
          title={<>Visitor QR Code</>}
          body={
            <div>
              <ul className="p-1 list-disc text-static-secondary">
                <li>
                  This QR code allows any visitors to your project to check-in
                  and “sign” your visitor log.
                </li>
                <li>
                  Visitors can review and sign your visitor policy, waiver, or
                  other documents.
                </li>
                <li>
                  This will add any visitor's estimated hours to your project
                  man-hour totals.
                </li>
                <li>
                  This can auto-populate visitors into your Daily Log in Procore
                  (Procore settings are listed at the bottom of the Project
                  Settings page).
                </li>
              </ul>
            </div>
          }
          qrCode={{
            destinationUrl: `${document.location.origin}/visitors/${projectId}/form`,
            alwaysVisible: true,
            downloadableFileName: "Visitors_QR_Code",
          }}
        />
        <PosterDownload
          imageUrl={agcPosterBackgroundImageUrl}
          header="Visitor QR Code Poster"
          description="Download your project specific poster"
          loading={orientationQRCodePosterLoading}
          onDownload={async () => {
            await getVisitorQRCodePoster({
              variables: { input: { projectId: projectId ?? "" } },
            }).then((data) => {
              if (data.data?.generateVisitorQrCodePoster) {
                downloadFromUrl(data.data?.generateVisitorQrCodePoster);
              }
            });
          }}
        />
      </div>
      <p className="inline-flex flex-row items-center gap-0.5 mt-2">
        Require visitors to Review & Accept your Waiver Terms{" "}
        <Switcher
          disabled={
            gc.node.visitor_waiver_text && gc.node.visitor_waiver_text.length
              ? true
              : false
          }
          options={[
            {
              icon: IconX,
              label: "off",
            },
            {
              icon: IconCheck,
              label: "on",
            },
          ]}
          initialSelectedOptionIndex={
            project.node.visitor_sign_waiver == true ||
            (gc.node.visitor_waiver_text && gc.node.visitor_waiver_text.length)
              ? 1
              : 0
          }
          onChange={(optionIndex) => {
            const checked =
              optionIndex === 1 ||
              (gc.node.visitor_waiver_text &&
                gc.node.visitor_waiver_text.length) == 1;
            toggleSignWaiverEnabled({
              variables: {
                projectId: projectId ?? "",
                _set: { visitor_sign_waiver: checked },
              },
            });
          }}
        />
        <Tooltip
          placement="top"
          title={
            <div style={{ color: "black" }}>
              <strong>Visitor Waiver</strong>
              <br /> Do you require visitors to review and acknowledge a Visitor
              Waiver? The text below is your Company Standard Waiver. Only make
              EDITS if there are Site-Specific requirements.
            </div>
          }
          color="white"
        >
          <InfoCircleOutlined />
        </Tooltip>
      </p>

      {project.node.visitor_sign_waiver ||
      (gc.node.visitor_waiver_text && gc.node.visitor_waiver_text.length) ? (
        <div className="mt-2 w-2/3">
          <Form
            form={form}
            layout="vertical"
            initialValues={{ modifier: "public" }}
          >
            <Form.Item
              name="waiverText"
              label={
                <div>
                  <strong>Visitor Waiver</strong> (pre-populated text is Company
                  Standard)
                </div>
              }
              initialValue={content}
            >
              <JoditEditor
                value=""
                config={{
                  ...JoditConfig,
                }}
              />

              {/* <HtmlEditor/> */}
            </Form.Item>
          </Form>
          <Button
            type="primary"
            size="middle"
            loading={waiverText}
            onClick={async () => {
              try {
                const value = await form.validateFields();
                console.log("values", value);
                updatingWaiverText(true);
                await updateWaiverText({
                  variables: {
                    projectId: projectId ?? "",
                    waiverText: value.waiverText,
                  },
                }).then((value) => {
                  updatingWaiverText(false);
                  notification.success({
                    message: "Done",
                    duration: 2,
                  });
                });
              } catch (err) {
                console.log("Failed:", err);
                notification.error({
                  message: "Failed",
                  duration: 2,
                });
              }
              // form
              //   .validateFields()
              //   .then(async (values) => {
              //     updatingWaiverText(true);
              //     await updateWaiverText({
              //       variables: {
              //         projectId: projectId ?? "",
              //         waiverText: values.waiverText,
              //       },
              //     }).then((value) => {
              //       updatingWaiverText(false);
              //       notification.success({
              //         message: "Done",
              //         duration: 2,
              //       });
              //     });
              //   })
              //   .catch((info) => {
              //     console.log("Validate Failed:", info);
              //     notification.error({
              //       message: "Failed",
              //       duration: 2,
              //     });
              //   });
            }}
          >
            Save
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default withEmployeeData(VisitorSettings);
