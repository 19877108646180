import React, { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import useYoutubePlayer, { StateChangeEvent } from "./useYoutubePlayer";
import { VideoPlayerProps, VideoPlayerRef } from "../utils/VideoPlayerTypes";

import * as uuid from "uuid";
import getVideoFrameUrl from "../utils/getVideoFrameUrl";

const YoutubeVideoPlayer = forwardRef<VideoPlayerRef, VideoPlayerProps>(
  ({ videoUrl, disableControls, onWatched, onPlay, onPause }, ref) => {
    const tagId = useMemo(() => `video_${uuid.v4()}`, []);
    const { playerRef, callbackRef } = useYoutubePlayer(tagId, {});
    const stateRef = useRef<Boolean>(false);

    callbackRef.current = useMemo(
      () => ({
        onStateChange: (e: StateChangeEvent) => {
          const { data } = e;
          // data 0 - ended (or stopped) - 1 -playing 2- pause
          console.log("video sate change", data, e);
          if (data >= 0 && data <= 2) stateRef.current = data === 1;
          if (data === 2 && onPause) onPause();
          if (data === 1 && onPlay) onPlay();
          if (data === 0 && onWatched) onWatched();
        },
      }),
      [onPause, onPlay, onWatched]
    );
    useImperativeHandle<VideoPlayerRef, VideoPlayerRef>(ref, () => ({
      play: () => {
        if (playerRef.current?.playVideo) playerRef.current.playVideo();
      },
      pause: () => {
        playerRef.current?.pauseVideo();
      },
      getDuration: () => playerRef.current?.getDuration(),
      rewind: async () => {
        console.log("rewind");
        if (!playerRef.current) {
          return;
        }
        const currentTime = await playerRef.current.getCurrentTime();
        const newTime = currentTime - 30;

        playerRef.current?.seekTo(newTime, true);
      },
    }));
    const frame_url = getVideoFrameUrl(videoUrl, true, disableControls);
    return (
      <div className={`absolute inset-0 w-full h-full`}>
        <iframe
          id={tagId}
          tabIndex={disableControls ? -1 : undefined}
          onKeyDown={(e) => {
            console.log(e.code);
          }}
          className="absolute inset-0 w-full h-full"
          src={frame_url}
          allow="autoplay"
        ></iframe>
        {!!disableControls && (
          <div
            className="absolute inset-0 w-full h-full"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              const player = playerRef.current;
              if (player) {
                if (stateRef.current) {
                  player.pauseVideo();
                } else if (player.playVideo) {
                  player.playVideo();
                }
              }
            }}
          ></div>
        )}
      </div>
    );
  }
);

export default YoutubeVideoPlayer;
