import { useLazyQuery } from "@apollo/client";
import { DocumentNode } from "graphql";
import { useEffect, useState } from "react";
import { isDevelopmentBuild } from "../constants/environment";

const FETCH_LIMIT = isDevelopmentBuild ? 10 : 15;

export type UsePaginatedQueryParams<
  Where = any,
  ExtraQueryVariablesType = any,
> = {
  where: Where;
  orderBy: any;
  queryDoc: DocumentNode;
  dataIndex: string;
  aggregateCountIndex: string;
  extraQueryVariables?: Omit<
    ExtraQueryVariablesType,
    "where" | "order_by" | "limit" | "offset"
  >;
};

function usePaginatedQuery<ItemType, DataType = undefined>({
  where,
  orderBy,
  queryDoc,
  dataIndex,
  aggregateCountIndex,
  extraQueryVariables,
}: UsePaginatedQueryParams) {
  const [items, setItems] = useState<Array<ItemType>>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [doQuery, { data, loading }] = useLazyQuery(queryDoc, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  let variables = {
    where,
    order_by: orderBy,
    offset: (page - 1) * FETCH_LIMIT,
    limit: FETCH_LIMIT,
  };

  if (extraQueryVariables) {
    variables = { ...variables, ...extraQueryVariables };
  }

  useEffect(() => {
    if (!data) return;
    setItems(data[dataIndex]);
    setCount(data[aggregateCountIndex].aggregate.count);
  }, [data]);

  const refetch = async () => {
    doQuery({
      variables,
    });
  };

  useEffect(() => {
    // TODO cancel if page change before update
    refetch();
  }, [where, page, orderBy, extraQueryVariables]);

  return [
    items,
    { loading, page, setPage, count, refetch, pageSize: FETCH_LIMIT, data },
  ] as [
    Array<ItemType>,
    {
      refetch: () => void;
      loading: boolean;
      data: DataType | undefined;
      page: typeof page;
      setPage: typeof setPage;
      count: number;
      pageSize: number;
    },
  ];
}

export default usePaginatedQuery;
