import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import NestedRoute from "../../../../common/types/manual/NestedRoute";
import slidesRoute from "../gcDashboard/routes/slides/slidesRoute";
import AGCEmployee from "./routes/AGCEmployee";
import searchParamsProvider from "src/common/functions/searchParamsProvider";

const agcDashboardRoute: NestedRoute = {
  element: <Outlet />,
  children: [
    {
      path: ":subview",
      element: searchParamsProvider(AGCEmployee),
    },
    {
      path: "",
      element: <Navigate replace to="orientation" />,
    },
    {
      path: "orientation/:orientationId",
      ...slidesRoute,
    },
  ],
};

export default agcDashboardRoute;
