import React, { useState } from "react";
import { GCInspectionsQuery$data } from "src/common/types/generated/relay/GCInspectionsQuery.graphql";
import ProjectProps from "src/common/types/manual/ProjectProps";
import InspectionQR from "src/domain-features/siteinspection/components/item-modals/InspectionQR";
import InspectionQRTable, {
  InspectionQRType,
} from "src/domain-features/siteinspection/components/tables/InspectionQRTable";

const GCInspectionQRs: React.FC<
  ProjectProps & { data: GCInspectionsQuery$data }
> = ({ projectId, data }) => {
  const [selectedQR, setSelectedQR] = useState<InspectionQRType>();
  const [edit, setEdit] = useState(false);
  return (
    <>
      {selectedQR && (
        <InspectionQR
          projectId={projectId}
          projectOptions={data.projectOptions.edges.map(({ node }) => ({
            label: node.project.name,
            value: node.project_id,
          }))} // already excluded current project from options
          inspectionsToSelect={data.inspection_instance_connection.edges.map(
            (inst) => ({ label: inst.node.name.en, value: inst.node.pk })
          )}
          editByDefault={edit}
          data={selectedQR}
          visible={!!selectedQR}
          onClose={() => {
            setSelectedQR(undefined);
            setEdit(false);
          }}
        />
      )}
      <InspectionQRTable
        where={{
          project_id: { _eq: projectId },
          deleted_at: { _is_null: true },
        }}
        onEdit={(pk) => {
          setEdit(true);
          setSelectedQR(pk);
        }}
        OnRowItemClick={(qrInst) => setSelectedQR(qrInst)}
        inspectionsInstancesOptions={data.inspection_instance_connection.edges.map(
          (p) => ({ label: p.node.name.en, value: p.node.pk })
        )}
        projectId={projectId}
      />
    </>
  );
};
export default GCInspectionQRs;
