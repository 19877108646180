import { Button, Form, Input, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import capitalize from "src/common/functions/capitalize";
import getConnectionNodes from "src/common/functions/getConnectionNodes";
import { ProjectWorkerDescriptionQuery$data } from "src/common/types/generated/relay/ProjectWorkerDescriptionQuery.graphql";
import { UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation } from "src/common/types/generated/relay/UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation.graphql";
import { UpdateWorkerAndUserMutation } from "src/common/types/generated/relay/UpdateWorkerAndUserMutation.graphql";
import { InsertUserNameEditHistoryMutation } from "src/common/types/generated/relay/InsertUserNameEditHistoryMutation.graphql";
import updateProjectWorkersMutation from "src/common/api/relay/mutations/UpdateProjectWorkersForWorkerRegistrationWithHardHat";
import workerAndUserUpdateMutation from "src/common/api/relay/mutations/UpdateWorkerAndUser";
import useUpsertAndDeletePreviousSub from "src/common/api/relay/mutationHooks/useUpsertAndDeletePreviousSub";
import insertUserNameEditHistoryMutation from "src/common/api/relay/mutations/InsertUserNameEditHistory";
import { auth } from "src/common/functions/firebase";

export interface EditWorkerFormProps {
  projectWorkerId: string;
  data: ProjectWorkerDescriptionQuery$data;
  allowChangeCompany?: boolean;
  allowHHEdit?: boolean | null;
  projectId: string;
  workerId?: string;

  onCancel?: () => void;
  onSubmit?: () => void;
}

const EditWorkerForm: React.FC<EditWorkerFormProps> = ({
  projectWorkerId,
  allowChangeCompany = false,
  projectId,
  allowHHEdit,
  data,
  onCancel,
  workerId,
  onSubmit,
}) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const upsertPreviousSub = useUpsertAndDeletePreviousSub();
  const [workerUpdate, isUpdatingWorker] =
    useAsyncMutation<UpdateWorkerAndUserMutation>(workerAndUserUpdateMutation);
  const [workerNameEditHistoryInsert, isInsertingWorkerNameEditHistory] =
    useAsyncMutation<InsertUserNameEditHistoryMutation>(
      insertUserNameEditHistoryMutation,
    );
  const [updateProjectWorkers, isUpdatingProjectWorkers] =
    useAsyncMutation<UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation>(
      updateProjectWorkersMutation,
    );

  const pw = getConnectionNodes(data.project_worker_connection)[0]!;
  const titles = getConnectionNodes(data.worker_title_connection);
  const subs = data.subcontractor_connection
    ? getConnectionNodes(data.subcontractor_connection)
    : [];
  const showPhone = pw.user!.phone_number_privacy_setting?.manager;
  const showEmail = pw.user!.email_privacy_setting?.manager;
  const getInitEditingData = () => ({
    name: pw.user!.name,
    phone: pw.user!.phone_number,
    hardHat: pw.hard_hat_number,
    role: pw.worker_role,
    email: pw.user!.email,
    titleId: pw.title?.pk,
    subcontractorId: pw.subcontractor_id,
  });

  const [editingData, setEditingData] = useState(getInitEditingData());

  useEffect(() => {
    setEditingData(getInitEditingData());
  }, [JSON.stringify(pw)]);
  return (
    <Form
      className="w-24 max-w-full"
      initialValues={editingData}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      onFinish={async (values: typeof editingData) => {
        const { name, phone, role, email, titleId, subcontractorId, hardHat } =
          values;
        let hard_hat_number = hardHat ?? pw.hard_hat_number;
        console.log(values);

        if (!hard_hat_number) {
          data.pw_con.edges.forEach((e) => {
            hard_hat_number = e.node.hard_hat_number ?? hard_hat_number;
          });
        }
        setLoading(true);
        if (workerId && subcontractorId) {
          const submit = async () => {
            await workerUpdate({
              variables: {
                workerId,
                user_set: {
                  name: name ?? pw.user?.name,
                  phone_number: phone ?? pw.user?.phone_number,
                  email: email ?? pw.user?.email,
                },
                worker_set: {
                  worker_title_id: titleId,
                  subcontractor_id: subcontractorId,
                  current_worker_role: role,
                },
              },
            });
            if (name !== pw.user?.name && name)
              await workerNameEditHistoryInsert({
                variables: {
                  input: [
                    {
                      modifier_user_id: auth.currentUser?.uid,
                      prev_name: pw.user?.name,
                      new_name: name,
                      modified_user_id: pw.user?.pk,
                    },
                  ],
                },
              });
            await updateProjectWorkers({
              variables: {
                newSubId: subcontractorId,
                projectIds: [projectId],
                workerId,
                _set: { hard_hat_number, is_last: false },
                newUpsertObjects: [
                  {
                    project_id: projectId,
                    worker_id: workerId,
                    subcontractor_id: subcontractorId,
                    is_last: true,
                    hard_hat_number,
                    title_id: titleId,
                    worker_role: role,
                    deleted_at: null,
                  },
                ],
              },
            }).then((d) => {
              if (d.u2 && d.u2.returning[0])
                navigate(
                  `/gce/projects/${projectId}/workers/worker/${d.u2.returning[0].pk}`,
                );
            });
            if (pw.worker?.subcontractor_id !== subcontractorId) {
              console.log(`sub Change detected`);

              upsertPreviousSub({
                variables: {
                  deleteWhere: {
                    subcontractor_id: { _eq: subcontractorId },
                    worker_id: { _eq: workerId },
                  },
                  objects: pw.worker?.subcontractor_id
                    ? [
                        {
                          worker_id: workerId,
                          subcontractor_id: pw?.worker?.subcontractor_id,
                        },
                      ]
                    : [],
                },
              });
            }
          };
          await submit().catch((e) => console.log(e));
          onSubmit && onSubmit();
        }
        setLoading(false);
      }}
    >
      <Form.Item label={"Name"} name={`name`}>
        <Input value={pw.user?.name} />
      </Form.Item>
      {allowHHEdit && (
        <Form.Item label={"Hard Hat Number"} name={`hardHat`}>
          <Input value={pw.hard_hat_number ?? undefined} />
        </Form.Item>
      )}
      {!!allowChangeCompany && (
        <Form.Item name="subcontractorId" label={`Subcontractor`}>
          <Select
            // value={pw.worker.subcontractor_id as string | undefined}
            style={{ width: "100%" }}
            options={subs.map((o) => ({
              key: o.pk,
              value: o.pk,
              label: capitalize(o.name),
            }))}
          />
        </Form.Item>
      )}
      {(!pw.user!.phone_number || showPhone) && (
        <Form.Item label={`Phone #`} name="phone">
          <Input value={pw.user!.phone_number || ""} />
        </Form.Item>
      )}
      {(!pw.user!.email || showEmail) && (
        <Form.Item label={`Email`} name={`email`}>
          <Input value={pw.user?.email || ""} />
        </Form.Item>
      )}
      <Form.Item name={`titleId`} label={`Title`}>
        <Select
          value={pw.title?.pk}
          options={titles.map((o) => ({
            key: o.pk,
            value: o.pk,
            label: capitalize(o.translation.en),
          }))}
        ></Select>
      </Form.Item>
      <Form.Item name="role" label={`Role`}>
        <Select
          value={pw.worker_role}
          options={["apprentice", "foreman", "journeyman"].map((o) => ({
            key: o,
            value: o,
            label: capitalize(o),
          }))}
        />
      </Form.Item>
      <Form.Item label={`Birth date`}>
        <Input
          value={
            pw.user!.birth_date
              ? dayjs(pw.user!.birth_date as string).format("lll")
              : ""
          }
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button
          type="primary"
          onClick={form.submit}
          loading={loading}
          htmlType="submit"
        >
          Save
        </Button>
        {onCancel && (
          <Button type="text" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default EditWorkerForm;
