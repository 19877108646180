import { GetOrientationDashboardDataQuery } from "src/common/types/generated/apollo/graphQLTypes";

type DataType = { name: string; count: number; color?: string };
export type MapType = Map<string, DataType>;
export type ZipMapDataType = NonNullable<
  GetOrientationDashboardDataQuery["project_worker"][number]["worker"]["city_zip_code"]
> & {
  count: number;
};
export type StateMapDataType = {
  count: number;
  id: string;
  code: string;
  name: string;
  latitude: number;
  longitude: number;
};
const getDashboardDataMaps = (
  data: GetOrientationDashboardDataQuery | undefined,
) => {
  const project = data?.project_by_pk;
  const veteranTypeMap: MapType = new Map();
  const ethnicityMap: MapType = new Map();
  const genderMap: MapType = new Map();
  const roleMap: MapType = new Map();
  const cityMap: MapType = new Map();
  const yearsInConstructionMap: MapType = new Map();
  const yearsWithEmployerMap: MapType = new Map();

  const zipMap: Map<string, ZipMapDataType> = new Map();
  const stateMap: Map<string, StateMapDataType> = new Map();
  const localCities = (project?.project_local_cities || []).map(
    (c) => c.city_id,
  );

  (data?.project_worker || []).forEach((p) => {
    const currRole = roleMap.get(p.worker_role_detail.value);
    if (currRole) {
      currRole.count += 1;
    } else {
      roleMap.set(p.worker_role_detail.value, {
        name: p.worker_role_detail.translation.en,
        count: 1,
      });
    }

    const genderVal = p.worker.gender;
    if (genderVal) {
      const currGen = genderMap.get(genderVal);
      if (currGen) {
        currGen.count += 1;
      } else {
        genderMap.set(genderVal, {
          name:
            genderVal === "M"
              ? "Male"
              : genderVal === "F"
              ? "Female"
              : genderVal === "O"
              ? "Other"
              : "Undefined val", //non-reachable
          count: 1,
        });
      }
    }

    const veteranType = p.worker.veteran_type;
    if (veteranType) {
      const currVeter = veteranTypeMap.get(veteranType);
      if (currVeter) {
        currVeter.count += 1;
      } else {
        veteranTypeMap.set(veteranType, {
          name:
            veteranType === "veteran"
              ? "Veteran"
              : veteranType === "disabledVeteran"
              ? "Disabled Veteran"
              : veteranType === "nonVeteran"
              ? "Non Veteran"
              : "Undefined Value", // should be non-reachable
          count: 1,
        });
      }
    }

    const years_in_construction = p.worker.years_in_construction;
    if (years_in_construction) {
      const currYearsInConst = yearsInConstructionMap.get(
        years_in_construction,
      );
      if (currYearsInConst) {
        currYearsInConst.count += 1;
      } else {
        yearsInConstructionMap.set(years_in_construction, {
          name: years_in_construction,
          count: 1,
        });
      }
    }
    const years_with_employer = p.worker.years_with_employer;
    if (years_with_employer) {
      const currYearsWithEmployer =
        yearsWithEmployerMap.get(years_with_employer);
      if (currYearsWithEmployer) {
        currYearsWithEmployer.count += 1;
      } else {
        yearsWithEmployerMap.set(years_with_employer, {
          name: years_with_employer,
          count: 1,
        });
      }
    }
    const ethnicity = p.worker.ethnicity;
    if (ethnicity) {
      const currEth = ethnicityMap.get(ethnicity);
      if (currEth) {
        currEth.count += 1;
      } else {
        ethnicityMap.set(ethnicity, {
          name: ethnicity,
          count: 1,
        });
      }
    }
    const cityZip = p.worker.city_zip_code;
    if (cityZip) {
      const currZip = zipMap.get(cityZip.id);
      if (currZip) {
        currZip.count++;
      } else {
        zipMap.set(cityZip.id, {
          count: 1,
          ...cityZip,
        });
      }
      const state = cityZip.city.state;
      const currState = stateMap.get(state.code);

      if (currState) {
        currState.count++;
      } else if (state.latitude && state.longitude) {
        stateMap.set(state.code, {
          count: 1,
          id: state.code,
          latitude: state.latitude,
          longitude: state.longitude,
          name: state.name,
          code: state.code,
        });
      }
      if (localCities?.length) {
        const cityToMap = localCities?.includes(cityZip.city.id)
          ? "Local"
          : "Non Local";

        const currCity = cityMap.get(cityToMap);
        if (currCity) {
          currCity.count += 1;
        } else {
          cityMap.set(cityToMap, {
            name: cityToMap,
            count: 1,
          });
        }
      }
    }
  });
  return {
    roleMap,
    veteranTypeMap,
    ethnicityMap,
    genderMap,
    cityMap,
    zipMap,
    stateMap,
    yearsInConstructionMap,
    yearsWithEmployerMap,
  };
};
export default getDashboardDataMaps;
