import { Card, Select } from "antd";
import React from "react";

export type IncidentSelectOption = {
  label: string;
  value: string | boolean;
};

interface IncidentSelectFieldProps {
  value?: string | boolean;
  title: string;
  options: Array<IncidentSelectOption>;
  onChange: (option: IncidentSelectOption) => void;
}

const IncidentSelectField: React.FC<IncidentSelectFieldProps> = ({
  value,
  title,
  options,
  onChange,
}) => {
  return (
    <div className="flex gap-1 mt-1 items-center">
      <div className="w-10">{title}</div>
      <Select
        className="w-full"
        value={value}
        options={options}
        allowClear
        showSearch
        filterOption={(input, option) => {
          if (option?.label) {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          } else {
            return false;
          }
        }}
        onChange={(_, option) => {
          if (!Array.isArray(option)) {
            onChange(option);
          } else option[0] && onChange(option[0]);
        }}
      />
    </div>
  );
};

export default IncidentSelectField;
