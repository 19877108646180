import { Checkbox, Tag } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React from "react";

export type GCDailyReportTagType = {
  id: string;
  name: string;
  checked: boolean;
  colorHex?: string;
};

export interface GCProjectReportsDailySummaryTagProps {
  items: GCDailyReportTagType[];
  handleTagFilterChange: (id: string, newValue: boolean) => void;
}

const GCProjectReportsDailySummaryTags: React.FC<
  GCProjectReportsDailySummaryTagProps
> = (props) => {
  return (
    <div className="flex flex-col gap-0.5">
      {props.items.map((item) => (
        <div id={item.id} key={item.id}>
          <Checkbox
            checked={item.checked}
            onChange={(e: CheckboxChangeEvent) => {
              props.handleTagFilterChange(item.id, e.target.checked);
            }}
          >
            <Tag color={item.colorHex}>{item.name}</Tag>
          </Checkbox>
        </div>
      ))}
    </div>
  );
};

export default GCProjectReportsDailySummaryTags;
