import { AntCloudOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Radio,
  Select,
  Upload,
  message,
  notification,
} from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useSearchParams } from "react-router-dom";
import logo from "src/common/assets/logo.png";
import TermsOfUseModal from "src/common/components/dialogs/TermsOfUseModal";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { uploadFilesWithPath } from "src/common/functions/uploadFiles";
import { useEmailUserForJhaCreationRequestMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { GCInviteSubAdminsToAddJhasMutation } from "src/common/types/generated/relay/GCInviteSubAdminsToAddJhasMutation.graphql";
import { GCInviteSubAdminsToAddJhasQuery } from "src/common/types/generated/relay/GCInviteSubAdminsToAddJhasQuery.graphql";
import { GCInviteSubAdminsToAddJhasUpdateTaskMutation } from "src/common/types/generated/relay/GCInviteSubAdminsToAddJhasUpdateTaskMutation.graphql";
import * as uuid from "uuid";
import { NULL_ID } from "src/common/functions/nullId";

type FormValues = {
  numberOfJHAs: number;
  readable: boolean;
  acceptance: [boolean];
  subAdmins: Array<string>;
  uploads: { fileList: Array<{ originFileObj: File }> };
};

interface User {
  name: string;
  email: string;
  id: string;
}

const query = graphql`
  query GCInviteSubAdminsToAddJhasQuery(
    $subcontractorId: uuid!
    $projectId: uuid!
  ) {
    subcontractor_employee_connection(
      where: { subcontractor_id: { _eq: $subcontractorId } }
      order_by: { user: { name: asc } }
    ) {
      edges {
        node {
          user_id
          user {
            pk: id @__clientField(handle: "pk")
            name
            email
          }
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          name
        }
      }
    }
  }
`;

const GCInviteSubAdminsToAddJhas: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const projectId = searchParams.get("project") ?? NULL_ID;
  const subcontractorId = searchParams.get("subcontractor") ?? NULL_ID;
  const type = searchParams.get("type");
  const taskId = searchParams.get("task") ?? NULL_ID;

  const [loading, setLoading] = useState(false);

  const data = useLazyLoadQuery<GCInviteSubAdminsToAddJhasQuery>(query, {
    subcontractorId,
    projectId,
  });

  const subAdminUsers = data.subcontractor_employee_connection;
  const projectName = data.project_connection;

  const [uploadTask, isUploading] =
    useAsyncMutation<GCInviteSubAdminsToAddJhasMutation>(
      graphql`
        mutation GCInviteSubAdminsToAddJhasMutation(
          $object: add_task_request_insert_input!
        ) {
          insert_add_task_request_one(object: $object) {
            id
            pk: id @__clientField(handle: "pk")
          }
        }
      `,
    );

  const [updateTask] =
    useAsyncMutation<GCInviteSubAdminsToAddJhasUpdateTaskMutation>(
      graphql`
        mutation GCInviteSubAdminsToAddJhasUpdateTaskMutation(
          $taskId: uuid!
          $_set: task_set_input!
        ) {
          update_task_by_pk(pk_columns: { id: $taskId }, _set: $_set) {
            id
            creation_request_id
          }
        }
      `,
    );
  const [emailUserForJHACreationRequest] =
    useEmailUserForJhaCreationRequestMutation();
  const [viewTermsOfUseModal, setViewTermsOfUseModal] = useState(false);

  const [form] = Form.useForm();

  const availableJhaNumbers = useMemo(
    () => [...Array(50)].map((_, i) => i + 1),
    [],
  );

  if (type == "form_submitted") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h1
          style={{ fontSize: "30px", fontWeight: "bold", marginBottom: "20px" }}
        >
          Thank You!
        </h1>
        <h2
          style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "20px" }}
        >
          We will process the JHA(s) as quickly as we can. Look for an email
          from SiteForm
        </h2>
        <div style={{ marginBottom: "30px", marginTop: "20px" }}>
          <img src={logo} width={400} height={400} />
        </div>
      </div>
    );
  }

  if (!taskId || !projectId || !subcontractorId) return <></>;

  return (
    <>
      <TermsOfUseModal
        visible={viewTermsOfUseModal}
        onCancel={() => setViewTermsOfUseModal(false)}
      />
      <div
        style={{
          marginLeft: "10%",
          marginRight: "10%",
          marginTop: "1%",
        }}
      >
        {" "}
        <span style={{ fontSize: "25px" }}>
          <b>
            Upload your JHA(s) to{" "}
            {type == "add-to-project-and-company"
              ? "your Company JHA Library as well as to"
              : ""}{" "}
            {projectName.edges[0].node.name}
          </b>
        </span>
        <br />
        <br />
        We will process your JHA(s) and add them to SiteForm. To expedite and
        reduce errors, upload an original file like EXCEL or WORD. PDFs can be
        uploaded but take additional time. Make sure PDFs are readable (text is
        copyable).
        <br />
        <br />
        <Form
          form={form}
          layout="vertical"
          initialValues={{ numberOfJHAs: 1 }}
          title="Upload Form"
        >
          <Form.Item
            name="readable"
            label={<span>What type of file(s) are you adding?</span>}
            rules={[
              {
                required: true,
                message: (
                  <span>
                    Select whether the pdfs are readable or not{" "}
                    <span style={{ color: "red" }}>*</span>
                  </span>
                ),
              },
            ]}
          >
            <Radio.Group style={{ marginTop: 0 }}>
              <Radio value={true}>Excel/Word</Radio>
              <Radio value={false}>PDF</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            className="mt-2"
            label="How many JHAs are you uploading?"
            name="numberOfJHAs"
            // initialValue={1}
          >
            <Select placeholder="Select Total no. of JHAs to be uploaded">
              {availableJhaNumbers.map((i) => (
                <Select.Option value={i} key={i}>
                  {i}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="mt-2"
            label={"Upload The Files"}
            name="uploads"
            rules={[{ required: true, message: "Upload at least 1 file." }]}
          >
            <Upload.Dragger
              multiple={true}
              iconRender={() => <AntCloudOutlined />}
              customRequest={() => true}
              maxCount={form.getFieldValue("numberOfJHA")}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p>Click or drag files here to upload</p>
            </Upload.Dragger>
          </Form.Item>
          {!!subAdminUsers.edges && (
            <Form.Item
              name="subAdmins"
              label="Select anyone else in your organization that should Review and Approve the JHA(s) after processing (not required)"
            >
              <Select mode="multiple">
                {subAdminUsers.edges.map((p) => (
                  <Select.Option key={p.node.user_id} value={p.node.user.pk}>
                    {p.node.user.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            className="mt-2"
            name="acceptance"
            label={
              <label style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "4px" }}>I agree to the </span>
                <a
                  href="#"
                  style={{ textDecorationLine: "underline" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setViewTermsOfUseModal(true);
                  }}
                >
                  {" "}
                  Terms of Use
                </a>
              </label>
            }
            rules={[
              {
                required: true,
                message: "Please accept above condition to upload the JHAs",
              },
            ]}
          >
            <Checkbox.Group>
              <span>
                <Checkbox value={true} />
                <span className="mt-2"> I Accept</span>
              </span>
            </Checkbox.Group>
          </Form.Item>
          <div className="mt-2">
            We will email you once your JHA(s) have been processed. Before they
            can be used they need to be approved. You can approve them directly
            in the email we send you or on our website. Email us if you have any
            questions:&nbsp;
            <a
              href="mailto:support@siteform.io"
              className="text-semantic-pending"
            >
              support@siteform.io
            </a>
          </div>
        </Form>
        <div
          className="flex flex-row items-center gap-1"
          style={{ color: "blue", padding: "20px" }}
        >
          <Button
            type="primary"
            loading={loading}
            style={{ marginBottom: "8%" }}
            onClick={async () => {
              setLoading(true);
              await form
                .validateFields()
                .then(async (v: FormValues) => {
                  console.log(v);
                  const frontFiles = await uploadFilesWithPath(
                    v.uploads.fileList.map((o) => ({
                      file: o.originFileObj,
                      directoryName: "JHA Creation Request",
                    })),
                  );
                  console.log(v.acceptance[0], v);
                  if (v.acceptance[0]) {
                    const res = await uploadTask({
                      variables: {
                        object: {
                          ...(type == "add-to-project-only"
                            ? {
                                project_id: projectId,
                              }
                            : {}),

                          requested_by_uid: auth.currentUser?.uid,
                          subcontractor_id: subcontractorId,
                          agree_to_not_hold_siteform_responsible:
                            v.acceptance[0],
                          number_of_task_requested: v.numberOfJHAs,
                          readable: v.readable,
                          ...(!v.subAdmins
                            ? {}
                            : {
                                users_requested_for_task_approval: {
                                  data: v.subAdmins.map((id) => ({
                                    user_id: id,
                                  })),
                                },
                              }),
                          ...(type == "add-to-project-and-company"
                            ? {
                                task_add_to_projects: {
                                  data: [{ project_id: projectId }],
                                },
                              }
                            : {}),
                          attached_files: {
                            data: frontFiles.map((o) => ({
                              url: o.url,
                              name: o.name,
                              document_type: o.type,
                              type: "jha_creation_request",
                              author_id: auth.currentUser?.uid,
                              description: "JHA Request's Attached file",
                            })),
                          },
                        },
                      },
                    });
                    const requestId = res.insert_add_task_request_one?.pk;
                    if (requestId) {
                      await updateTask({
                        variables: {
                          taskId: taskId,
                          _set: {
                            creation_request_id: requestId,
                            request_state: "processing",
                          },
                        },
                      });
                      message.success("Uploaded To SiteForm");
                      await emailUserForJHACreationRequest({
                        variables: {
                          input: {
                            requestId,
                          },
                        },
                      }).catch(() =>
                        notification.error({
                          message: "Couldn't send Email.",
                        }),
                      );
                    }
                  }
                  setLoading(true);
                  form.resetFields();
                  setSearchParams({ type: "form_submitted" });
                })
                .catch((e) => {
                  notification.error({
                    message: "Upload error",
                    description: e.message,
                    duration: null,
                  });
                  console.log(e);
                });
              setLoading(false);
            }}
          >
            SUBMIT
          </Button>
        </div>
      </div>
    </>
  );
};
export default GCInviteSubAdminsToAddJhas;
