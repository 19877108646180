import React, { useRef, useState } from "react";
import AddInspectionChecklistItemModal from "../modals/AddInspectionChecklistItemModal";
import Table, { ColumnsType } from "antd/lib/table";
import {
  Checkbox,
  Input,
  Button as AntdButton,
  Radio,
  Space,
  message,
} from "antd";
import capitalize from "src/common/functions/capitalize";
import noPropagation from "src/common/functions/noPropagation";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import DraggableTableWrapper from "src/common/components/tables/draggable/DraggableTableWrapper";
import { MenuOutlined } from "@ant-design/icons";
import {
  InspectionChecklistItemProps,
  NotifyUserType,
} from "../../utils/siteInspectionTypes";
import * as uuid from "uuid";
import TextAreaAutoSize from "react-textarea-autosize";
import { Overwrite } from "utility-types";

export type CreatedChecklistItemsType = {
  [type: string]: { [key: string]: InspectionChecklistItemProps };
};
type ColumnKeys =
  | "show_na"
  | "notify_on_deficient"
  | "question"
  | "action"
  | "type"
  | "correct_answer";
type ChecklistItemTableColumnType<T, K = string> = Overwrite<
  ColumnsType<T>,
  { key: K }
>;
const InspectionChecklistItemTypeTable: React.FC<{
  type: string;
  editing?: boolean;
  excludedKeys?: Array<ColumnKeys>;
  projectEmployees: Array<NotifyUserType>;
  onDeleteItem: (item: InspectionChecklistItemProps) => void;
  onEditOrEditDoneItem: (
    newItem: InspectionChecklistItemProps | undefined,
    prevItem: InspectionChecklistItemProps
  ) => void;
  dataSource: Array<InspectionChecklistItemProps>;
  onAddNewItem?: (item: InspectionChecklistItemProps, type: string) => void;
  onMoveRow: (dragIndex: number, hoverIndex: number) => void;
}> = ({
  projectEmployees,
  editing,
  excludedKeys,
  type,
  dataSource,
  onDeleteItem,
  onAddNewItem,
  onEditOrEditDoneItem,
  onMoveRow,
}) => {
  const [editingChecklistItem, setEditingChecklistItem] = useState<
    InspectionChecklistItemProps | undefined
  >();
  const [newChecklistItem, setNewChecklistItem] = useState(false);
  const notifyeeModalRef = useRef<FModalRef<{ notifyees: Array<string> }>>();
  const getColumns: (
    type: string
  ) => ChecklistItemTableColumnType<
    InspectionChecklistItemProps,
    ColumnKeys
  > = (type) => [
    {
      title: "Checklist Item",
      key: "question",
      dataIndex: "description",
      width: "50%",
      render: (des, row) =>
        editingChecklistItem?.id === row.id ? (
          <div className="flex items-center">
            <TextAreaAutoSize
              className={`rounded-0.5 border-b-px border-suplementary-3 flex-1 resize-none outline-none p-0.5 whitespace-pre-wrap break-word`}
              value={editingChecklistItem?.description}
              onChange={(e) =>
                setEditingChecklistItem((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
          </div>
        ) : (
          des
        ),
    },
    {
      title: "Correct Answer",
      key: "correct_answer",
      dataIndex: "correct_answer",
      render: (val, row) =>
        editingChecklistItem?.id === row.id ? (
          <>
            <Radio.Group
              value={editingChecklistItem?.correct_answer}
              onChange={(e) => {
                setEditingChecklistItem((prev) => ({
                  ...prev,
                  correct_answer: e.target.value,
                }));
              }}
            >
              <div className="flex">
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </div>
            </Radio.Group>
          </>
        ) : (
          <>{capitalize(val)}</>
        ),
    },
    {
      title: "Allow NA",
      key: "show_na",
      dataIndex: "show_na",
      render: (val, row) =>
        editingChecklistItem?.id === row.id ? (
          <Checkbox
            className="mr-1"
            checked={editingChecklistItem?.show_na}
            onChange={(v) =>
              setEditingChecklistItem((prev) => ({
                ...prev,
                show_na: v.target.checked,
              }))
            }
          />
        ) : (
          <Checkbox checked={val} />
        ),
    },
    {
      title: "Notify if Deficient",
      key: "notify_on_deficient",
      dataIndex: "notify_on_deficient",
      render: (val, row) =>
        editingChecklistItem?.id === row.id ? (
          <div className="flex gap-1">
            <Checkbox
              className="mr-1"
              checked={editingChecklistItem?.notify_on_deficient}
              onChange={(v) =>
                setEditingChecklistItem((prev) => ({
                  ...prev,
                  notify_on_deficient: v.target.checked,
                }))
              }
            />
            {editingChecklistItem?.notify_on_deficient && (
              <>
                {editingChecklistItem.notifyees &&
                editingChecklistItem.notifyees.length === 1
                  ? projectEmployees.find(
                      (p) => p.id === editingChecklistItem.notifyees![0]
                    )?.name ?? ""
                  : ""}{" "}
                <AntdButton
                  onClick={() => {
                    notifyeeModalRef.current && notifyeeModalRef.current.open();
                  }}
                >
                  {editingChecklistItem.notifyees?.length === 1
                    ? " + "
                    : ` Notify ${
                        (editingChecklistItem.notifyees || []).length
                      }`}
                </AntdButton>
              </>
            )}
          </div>
        ) : (
          val &&
          row.notifyees &&
          (row.notifyees.length === 1 ? (
            <span>
              {projectEmployees.find((p) => p.id === row.notifyees![0])?.name ??
                ""}
            </span>
          ) : row.notifyees.length ? (
            `Notify ${row.notifyees.length}`
          ) : (
            ""
          ))
        ),
    },

    // { title: "Type", key: "type", dataIndex: "item_type_label" },
    {
      title: "Action",
      key: "action",
      dataIndex: "question",
      width: "10%",
      render: (_, row) => {
        return editing ? (
          <Space>
            <AntdButton
              type="link"
              onClick={noPropagation(() => {
                if (row?.id === editingChecklistItem?.id) {
                  const description = (editingChecklistItem?.description || "")
                    .trim()
                    .replace(/\s+/g, " ");
                  if (!description) {
                    message.error(
                      "Description of checklist item cannot be empty"
                    );
                    return;
                  }
                  onEditOrEditDoneItem(
                    { ...editingChecklistItem, description },
                    row
                  );
                  setEditingChecklistItem(undefined);
                } else setEditingChecklistItem(row);
              })}
            >
              {editingChecklistItem?.id === row.id ? "Done" : "Edit"}
            </AntdButton>

            <AntdButton
              danger
              type="link"
              onClick={noPropagation(() => onDeleteItem(row))}
            >
              Remove
            </AntdButton>
          </Space>
        ) : (
          ""
        );
      },
    },
  ];
  const columns = getColumns(type).filter(
    (column) => !(excludedKeys || []).find((s) => s === column.key)
  );
  columns.unshift({
    title: "Reorder",
    key: "reorder",
    render: () => <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />,
  });
  return (
    <>
      {editingChecklistItem && (
        <FModal
          zIndex={100}
          okText="Save"
          ref={notifyeeModalRef}
          onCancel={() => notifyeeModalRef.current?.close()}
          onOk={() => {
            notifyeeModalRef.current?.form.validateFields().then((v) => {
              setEditingChecklistItem((prev) => ({
                ...prev,
                notifyees: v.notifyees,
              }));
              notifyeeModalRef.current?.close();
            });
          }}
        >
          <FModal.Select
            name={"notifyees"}
            className="mt-1"
            initialValue={editingChecklistItem.notifyees}
            props={{
              placeholder: "Select who to send the email to",
              options: projectEmployees.map((c) => ({
                value: c.id,
                key: c.id,
                label: `${c.name}, ${c.email}`,
              })),
              mode: "multiple",
            }}
            label="Send an email if a checklist item is Deficient (the BAD option is selected)"
          />
        </FModal>
      )}
      <AddInspectionChecklistItemModal
        projectEmployees={projectEmployees}
        type={type}
        hideNotifyees={!!excludedKeys?.find((x) => x === "notify_on_deficient")}
        checklistItemTypes={[]}
        visible={newChecklistItem}
        onCancel={() => setNewChecklistItem(false)}
        onCreate={(values, creatingAnother) => {
          const checklistItemId = uuid.v4();
          onAddNewItem &&
            onAddNewItem(
              {
                ...values,
                id: checklistItemId,
                sort_index:
                  (dataSource[dataSource.length - 1]?.sort_index ?? 0) + 1,
              },
              type
            );

          if (!creatingAnother) {
            setNewChecklistItem(false);
          }
        }}
      />
      <DraggableTableWrapper
        onMoveRow={onMoveRow}
        enabled={editing}
        children={{
          table: (
            <Table
              bordered
              rowKey={(w) => w.id!}
              title={() => (
                <div className="flex gap-1">
                  {type}
                  {onAddNewItem && editing && (
                    <AntdButton
                      type="primary"
                      onClick={() => {
                        setNewChecklistItem(true);
                      }}
                    >
                      Add New Checklist Item{" "}
                    </AntdButton>
                  )}
                </div>
              )}
              columns={columns}
              dataSource={dataSource}
            />
          ),
        }}
      />
    </>
  );
};
export default InspectionChecklistItemTypeTable;
