import { Button, Table } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, {
  createRef,
  forwardRef,
  RefObject,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  useLazyLoadQuery,
  useRefetchableFragment,
  useRelayEnvironment,
} from "react-relay/hooks";
import {
  commitLocalUpdate,
  ConnectionHandler,
  OperationType,
} from "relay-runtime";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BSpace from "src/common/components/layouts/BSpace";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import getConnectionNodes from "src/common/functions/getConnectionNodes";
import { SCProjectAutoEmailTablesQuery } from "src/common/types/generated/relay/SCProjectAutoEmailTablesQuery.graphql";
import { SCProjectAutoEmailTables_DeleteMutation } from "src/common/types/generated/relay/SCProjectAutoEmailTables_DeleteMutation.graphql";
import { SCProjectAutoEmailTables_InsertMutation } from "src/common/types/generated/relay/SCProjectAutoEmailTables_InsertMutation.graphql";
import { SCProjectAutoEmailTables_auto_email_connection_frag$key } from "src/common/types/generated/relay/SCProjectAutoEmailTables_auto_email_connection_frag.graphql";

type AddEmailsModalRef = FModalRef<{}> | null | undefined;
const AddEmailsModal = forwardRef<
  AddEmailsModalRef,
  {
    eventType: string;
    subcontractorId: string;
    projectId: string;
    onCancel: () => void;
    onFinish: () => void;
  }
>(({ eventType, subcontractorId, projectId, onCancel, onFinish }, ref) => {
  const modal = useRef<AddEmailsModalRef>(null);
  const [emails, setEmails] = useState([""]);
  const [loading, setLoading] = useState(false);
  const [insertAutoEmails] =
    useAsyncMutation<SCProjectAutoEmailTables_InsertMutation>(graphql`
      mutation SCProjectAutoEmailTables_InsertMutation(
        $objects: [auto_email_insert_input!]!
      ) {
        insert_auto_email(
          objects: $objects
          on_conflict: {
            constraint: auto_email_sub_id_event_email_project_id_key
            update_columns: [id]
          }
        ) {
          affected_rows
        }
      }
    `);

  useImperativeHandle<AddEmailsModalRef, AddEmailsModalRef>(
    ref,
    () => modal.current,
  );

  const reset = () => {
    setEmails([]);
    setLoading(false);
    modal.current?.form.resetFields();
  };

  return (
    <FModal
      ref={modal}
      title="Add Emails"
      okText={"Done"}
      confirmLoading={loading}
      onCancel={() => {
        reset();
        onCancel();
      }}
      onOk={async () => {
        setLoading(true);

        await insertAutoEmails({
          variables: {
            objects: emails.map((email) => ({
              email,
              event: eventType,
              sub_id: subcontractorId,
              project_id: projectId,
            })),
          },
        });

        modal.current?.close();

        reset();

        onFinish();
      }}
    >
      <div style={{ width: "80%" }}>
        {emails.map((email, i) => (
          <div key={i} style={{ display: "flex", flexDirection: "row" }}>
            <FModal.Text
              style={{ width: "70%" }}
              props={{
                placeholder: "Enter email",
                value: email,
                onChange: (e) => {
                  setEmails((old) =>
                    old.map((o, oi) => (oi === i ? e.target.value : o)),
                  );
                },
              }}
            />
            {emails.length > 0 && (
              <Button
                danger
                type="link"
                onClick={() => {
                  setEmails((old) => old.filter((_, oi) => oi !== i));
                }}
              >
                Delete
              </Button>
            )}
          </div>
        ))}
        <Button
          type="link"
          onClick={() => {
            setEmails((old) => [...old, ""]);
          }}
          className="rounded-2"
        >
          + Add Email
        </Button>
      </div>
    </FModal>
  );
});

const SCProjectAutoEmailTables = withCustomSuspense(
  ({
    subcontractorId,
    projectId,
  }: {
    subcontractorId: string;
    projectId: string;
  }) => {
    const emailGroups = [
      {
        title: "Daily Reports",
        event: "daily_report_submit",
      },
      {
        title: "Safety Reports",
        event: "safety_report_submit",
      },
      {
        title: "Toolbox Talks",
        event: "toolbox_talk_submit",
      },
      {
        title: "Approved Permits",
        event: "permit_approved",
      },
    ];
    const addEmailsModals = useRef<Array<RefObject<AddEmailsModalRef>>>(
      emailGroups.map(() => createRef()),
    );
    const [deleteAutoEmails] =
      useAsyncMutation<SCProjectAutoEmailTables_DeleteMutation>(graphql`
        mutation SCProjectAutoEmailTables_DeleteMutation(
          $where: auto_email_bool_exp!
        ) {
          delete_auto_email(where: $where) {
            affected_rows
          }
        }
      `);
    const data = useLazyLoadQuery<SCProjectAutoEmailTablesQuery>(
      graphql`
        query SCProjectAutoEmailTablesQuery(
          $projectId: uuid!
          $subcontractorId: uuid!
        ) {
          ...SCProjectAutoEmailTables_auto_email_connection_frag
            @arguments(projectId: $projectId, subcontractorId: $subcontractorId)
        }
      `,
      {
        projectId,
        subcontractorId,
      },
    );
    const [fragData, refetchAutoEmails] = useRefetchableFragment<
      OperationType,
      SCProjectAutoEmailTables_auto_email_connection_frag$key
    >(
      graphql`
        fragment SCProjectAutoEmailTables_auto_email_connection_frag on query_root
        @argumentDefinitions(
          first: { type: "Int", defaultValue: 100000 }
          after: { type: "String" }
          projectId: { type: "uuid!" }
          subcontractorId: { type: "uuid!" }
        )
        @refetchable(
          queryName: "SCProjectAutoEmailTables_auto_email_connection_frag_refetch"
        ) {
          auto_email_connection(
            first: $first
            after: $after
            where: {
              project_id: { _eq: $projectId }
              sub_id: { _eq: $subcontractorId }
            }
          )
            @connection(
              key: "SCProjectAutoEmailTables_auto_email_connection"
              filters: ["where"]
            ) {
            edges {
              node {
                id
                event
                email
                pk: id @__clientField(handle: "pk")
              }
            }
          }
        }
      `,
      data,
    );

    const autoEmails = getConnectionNodes(fragData.auto_email_connection);

    const groupedEmails = emailGroups.map((e) => ({
      ...e,
      emails: autoEmails
        .filter((m) => m.event === e.event)
        .sort((a, b) => (a.email > b.email ? 1 : -1)),
    }));

    const environment = useRelayEnvironment();

    return (
      <>
        <BSpace>
          {groupedEmails.map((g, i) => (
            <BSpace key={g.event}>
              <div>
                <AddEmailsModal
                  ref={addEmailsModals.current[i]}
                  eventType={g.event}
                  subcontractorId={subcontractorId}
                  projectId={projectId}
                  onCancel={() => {
                    addEmailsModals.current[i]?.current?.close();
                  }}
                  onFinish={() => {
                    refetchAutoEmails({}, { fetchPolicy: "network-only" });
                  }}
                />
                <div className="flex flex-row">
                  <div className="text-1">{g.title}</div>
                  <div className="w-1"></div>
                  <Button
                    type="primary"
                    className="rounded-2"
                    onClick={() => {
                      addEmailsModals.current[i]?.current?.open();
                    }}
                  >
                    Add Emails
                  </Button>
                </div>
                <div className="h-1"></div>
              </div>
              {g.emails.length > 0 && (
                <Table
                  rowKey="email"
                  size="small"
                  dataSource={g.emails}
                  pagination={false}
                  columns={[
                    {
                      key: "emails",
                      title: "Emails",
                      dataIndex: ["email"],
                    },
                    {
                      key: "delete",
                      render: (r) => (
                        <Button
                          danger
                          className="rounded-2"
                          type="link"
                          onClick={() => {
                            deleteAutoEmails({
                              variables: {
                                where: {
                                  sub_id: { _eq: subcontractorId },
                                  project_id: { _eq: projectId },
                                  email: { _eq: r.email },
                                },
                              },
                            });

                            commitLocalUpdate(environment, (store) => {
                              const conn = ConnectionHandler.getConnection(
                                store.getRoot(),
                                "SCProjectAutoEmailTables_auto_email_connection",
                                {
                                  where: {
                                    project_id: { _eq: projectId },
                                    sub_id: { _eq: subcontractorId },
                                  },
                                },
                              )!;
                              ConnectionHandler.deleteNode(conn, r.id);
                            });
                          }}
                        >
                          Delete
                        </Button>
                      ),
                    },
                  ]}
                />
              )}
            </BSpace>
          ))}
        </BSpace>
      </>
    );
  },
);
export default SCProjectAutoEmailTables;
