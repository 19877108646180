import { GetVisitorLogDataV2Query } from "src/common/types/generated/apollo/graphQLTypes";
import { VisitorType } from "./visitorOrientationTypes";

const setVisitorFromData = (
  data: GetVisitorLogDataV2Query["getVisitorLogDataV2"],
  email: string | undefined | null,
): VisitorType | null => {
  const visitor = data.user;
  if (!visitor) return email ? { email } : null;
  const company = visitor?.company;
  return visitor
    ? {
        email: email,
        name: visitor?.name,
        hours: visitor?.previouslyStayedHours,
        ...(!company
          ? null
          : company.type === "gc"
          ? { generalContractorId: company.id }
          : company.type === "oac"
          ? { oacCompanyId: company.id }
          : company.type === "sub"
          ? { subcontractorId: company.id }
          : company.type === "vc"
          ? { visitorCompanyId: company.id }
          : null),
      }
    : email
    ? { email }
    : null;
};
export default setVisitorFromData;
