import { graphql } from "babel-plugin-relay/macro";

const insertCertificatesToVerifyMutate = graphql`
  mutation InsertCertificatesToVerifyMutation(
    $objects: [certificates_to_verify_insert_input!]!
  ) {
    insert_certificates_to_verify(objects: $objects) {
      affected_rows
      returning {
        id
        pk: id @__clientField(handle: "pk")
      }
    }
  }
`;
export default insertCertificatesToVerifyMutate;
