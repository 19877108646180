import React, { useState } from "react";
import { Button, Image } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import SiteOrientationDocumentCameraEditModal, {
  SiteOrientationDocumentCameraEditModalProps,
} from "./SiteOrientationDocumentCameraEditModal";
import FullScreenCamera from "../../../../common/components/camera/FullScreenCamera";

export interface SiteOrientationDocumentCameraNewDocumentProps {
  backImageSrc?: string | null;
  frontImageSrc?: string | null;
  showNextCertLabel?: boolean;
  ifFronRequireBack?: boolean;
  onBackImageChange: (image: string | null) => void;
  onFrontImageChange: (image: string | null) => void;
}

export const NewCertificationImage: React.FC<{ img: string }> = (props) => {
  return (
    <Image
      src={props.img}
      height={"16rem"}
      width={"100%"}
      className={`w-full h-16 object-cover`}
      preview={true}
    />
  );
};

const SiteOrientationDocumentCameraNewDocument: React.FC<
  SiteOrientationDocumentCameraNewDocumentProps
> = (props) => {
  const backImage = {
    src: props.backImageSrc,
    id: "back",
  };
  const frontImage = {
    src: props.frontImageSrc,
    id: "front",
  };

  const [cameraOn, setCameraOn] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const modalImages: SiteOrientationDocumentCameraEditModalProps["images"] = [];
  backImage.src &&
    modalImages.push({
      src: backImage.src,
      id: backImage.id,
    });
  frontImage.src &&
    modalImages.push({
      src: frontImage.src,
      id: frontImage.id,
    });

  return (
    <>
      {cameraOn && (
        <FullScreenCamera
          onCapture={([image]) => {
            if (!props.frontImageSrc) {
              props.onFrontImageChange(image);
            } else if (!props.backImageSrc) {
              props.onBackImageChange(image);
            }
            setCameraOn(false);
          }}
          photoDimensions={{
            width: 1080,
            height: 1920,
          }}
          facingMode={"environment"}
          onClose={() => {
            setCameraOn(false);
          }}
        />
      )}
      <SiteOrientationDocumentCameraEditModal
        visible={editModalVisible}
        onClose={() => {
          setEditModalVisible(false);
        }}
        images={modalImages}
        onRemoveImage={(imageId) => {
          if (imageId === frontImage.id) {
            props.onFrontImageChange(null);
          } else if (imageId === backImage.id) {
            props.onBackImageChange(null);
          }
        }}
      />
      <div
        className={`grid relative z-0 grid-cols-[2rem,auto,2rem] grid-rows-[2rem,auto,2rem] h-16`}
      >
        <div className={`absolute top-0 right-0 z-20`}>
          {(frontImage.src || backImage.src) && (
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setEditModalVisible(true);
              }}
              type={`link`}
              className={`w-2 h-2`}
            />
          )}
        </div>
        <div className={`col-start-1 col-end-3 row-start-1 row-end-3 z-10`}>
          <div className={`w-full h-full`}>
            <div className={`w-full h-0 pb-[150%] relative`}>
              <div
                className={`h-16 bg-interactive-primary w-full absolute shadow overflow-hidden border-interactive-secondary group-hover:border-interactive-primary inset-0 border rounded-0.5`}
              >
                {frontImage.src && backImage.src ? (
                  <NewCertificationImage img={backImage.src} />
                ) : (
                  <div
                    className={`flex w-full h-full cursor-pointer flex-col gap-0.5 items-center justify-center text-white`}
                    onClick={() => {
                      setCameraOn(true);
                    }}
                  >
                    <PlusOutlined className={`text-2`} />
                    {props.showNextCertLabel && (
                        <p className={`text-center font-accent`}>Next Cert</p>
                      )}
                    <p className={`text-center font-accent`}>
                      {!frontImage.src
                        ? "Front side"
                        : "Back side" +
                          (props.ifFronRequireBack ? " (Required) " : "")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`col-start-2 col-end-4 row-start-2 row-end-4 z-0`}>
          <div className={`w-full h-full`}>
            <div className={`w-full h-0 pb-[150%] relative`}>
              {frontImage.src || backImage.src ? (
                (() => {
                  const img = frontImage.src || backImage.src;
                  if (!img) {
                    return null;
                  }
                  return (
                    <div
                      className={`w-full absolute shadow overflow-hidden transition-colors group-hover:border-interactive-primary h-16 inset-0 rounded-0.5 border border-interactive-secondary`}
                    >
                      <NewCertificationImage img={img} />
                    </div>
                  );
                })()
              ) : (
                <div
                  className={`absolute transition-colors group-hover:border-interactive-primary w-full h-16 inset-0 rounded-0.5 border border-interactive-secondary`}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteOrientationDocumentCameraNewDocument;
