import { Button, Row } from "antd";
import React from "react";
import getMobileOS from "src/common/functions/getMobileOS";
import getOnAppStoreImage from "src/common/assets/get-on-app-store.png";
import getOnPlayStoreImage from "src/common/assets/get-on-play-store.png";
import logoTextImage from "src/common/assets/logo-text.png";

const InviteSuccessMobile = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const name = urlParams.get("name");
  const email = urlParams.get("email");
  const projectId = urlParams.get("projectId");
  const joinRole = urlParams.get("joinRole");
  const redirectToMobilization = urlParams.get("redirectToMobilization");

  const mobileOS = getMobileOS();
  const iosLink = "https://apps.apple.com/us/app/siteform/id1447766282";
  const androidLink =
    "https://play.google.com/store/apps/details?id=net.siteform.app&hl=en_US";
  const osLink =
    mobileOS === "iOS" ? iosLink : mobileOS === "Android" ? androidLink : null;
  const showIos = mobileOS === "iOS" || mobileOS === "unknown";
  const shownAndroid = mobileOS === "Android" || mobileOS === "unknown";
  let redirectionLink = "https://app.siteform.net/";
  if (
    joinRole == "sub-admin" &&
    projectId &&
    redirectToMobilization &&
    redirectToMobilization === "true"
  ) {
    redirectionLink = `https://app.siteform.net/sub/projects/${projectId}/mobilization`;
  }

  return (
    <Row justify="space-around" align="middle">
      <div
        style={{
          width: 250,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: 96,
        }}
      >
        <img src={logoTextImage} style={{ width: 150 }} />
        <h4 style={{ marginTop: 24 }}>Let's get started, {name}! 🎉</h4>
        <h4 style={{ textAlign: "center" }}>
          Download the SiteForm mobile app from your phone's app store, select
          Apple or Android
        </h4>
        <div style={{ height: 24 }} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {showIos && (
            <a href={iosLink}>
              <img
                src={getOnAppStoreImage}
                style={{ width: 134, marginRight: shownAndroid ? 24 : 0 }}
              />
            </a>
          )}
          {shownAndroid && (
            <a href={androidLink}>
              <img src={getOnPlayStoreImage} style={{ width: 150 }} />
            </a>
          )}
        </div>
        <br />
        {osLink ? (
          <a href={osLink}>
            <h3 style={{ textAlign: "center" }}>
              Then. LOGIN with {email} and your new password!
            </h3>
          </a>
        ) : (
          <h3 style={{ textAlign: "center" }}>
            Then login with your email and password!
          </h3>
        )}
        <br />
        <br />
        <h4 style={{ textAlign: "center" }}>
          And log into the SiteForm website below
        </h4>
        <br />
        <br />
        <Button
          size="large"
          type="primary"
          // className="text-black hover:text-gray-700 focus:outline-none transition-colors duration-300"
          onClick={() => window.open(redirectionLink)}
        >
          Login
        </Button>
      </div>
    </Row>
  );
};

export default InviteSuccessMobile;
