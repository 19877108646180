import { Outlet } from "react-router-dom";
import NestedRoute from "../../../../common/types/manual/NestedRoute";
import SiteOrientationRemoteView from "./newDesign/SiteOrientationRemoteView";

const remoteRoute: NestedRoute = {
  element: <Outlet />,
  children: [{ path: ":type", element: <SiteOrientationRemoteView /> }],
};

export default remoteRoute;
