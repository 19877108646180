import { useEffect, useRef } from "react";
import YoutubePlayer from "youtube-player";
import { YouTubePlayer } from "youtube-player/dist/types";
import sendHotjarEvent from "src/utility-features/event-tracking/hotjar/sendHotjarEvent";

export interface PlayerEvent {
  target: EventTarget | null;
}

export interface StateChangeEvent extends PlayerEvent {
  data: number;
}

export interface PlaybackRateChangeEvent extends PlayerEvent {
  data: number;
}

export interface PlayerCallbacks {
  onPlay?: () => void;
  onReady?: (event: PlayerEvent) => void;

  onStateChange?(event: StateChangeEvent): void;

  onPlaybackRateChange?(event: CustomEvent<any>): void;
}

const useYoutubePlayer = (player_div_id: string, config: any) => {
  const playerRef = useRef<YouTubePlayer | null>(null);
  const callbackRef = useRef<PlayerCallbacks | null>(null);

  useEffect(() => {
    const player = YoutubePlayer(player_div_id);
    player.on("stateChange", (e) => callbackRef.current?.onStateChange?.(e));
    player.on("ready", (e) => callbackRef.current?.onReady?.(e));
    player.on("playbackRateChange", (e) =>
      callbackRef.current?.onPlaybackRateChange?.(e)
    );
    player.on("error", (e) => {
      sendHotjarEvent("youtube_api_error");
    });
    playerRef.current = player;
    return () => {
      playerRef.current?.destroy();
    };
  }, []);
  return { playerRef, callbackRef };
};

export default useYoutubePlayer;
