import { FC } from "react";
import {
  useUpdateEquipmentIncidentMutation,
  Equipment_Incident_Set_Input,
  Document_Insert_Input,
  useGetMonetarySeverityLevelsQuery,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  GetIncidentByPkDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentCommonUser from "../incident-users/IncidentCommonUser";
import { Card } from "antd";
import useInsertIncidentType from "../../utils/useInsertIncidentType";
import useUploadIncidentTypeDocument from "../../utils/useUploadIncidentTypeDocument";
import useDeleteIncidentTypeDocument from "../../utils/useDeleteIncidentTypeDocument";
import getIncidentDocuments from "../../utils/getIncidentDocuments";
import getNextPage from "../../utils/getNextPage";
import IncidentTextField from "../basic/IncidentTextField";
import IncidentSelectField from "../basic/IncidentSelectField";
import IncidentInputField from "../basic/IncidentInputField";
import dayjs from "dayjs";
import IncidentDocViewAndUpload from "../basic/IncidentDocViewAndUpload";
import IncidentBottomButtons from "../basic/IncidentBottomButtons";
import { useParams, useNavigate } from "react-router-dom";
import useUpdateMonetarySeverity from "../../utils/useUpdateMonetarySeverity";
import { deletedDocument } from "../basic/IncidentDocViewer";
import { useSuspenseQuery } from "@apollo/client";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import useUpdateIncidentCommonUser from "../../utils/useUpdateIncidentCommonUser";
import useUpdateIncidentGeneralPerson from "../../utils/useUpdateIncidentGeneralPerson";

const EquipmentIncident: FC = () => {
  const { projectId, incidentId } = useParams();
  const navigate = useNavigate();
  const [updateIncidentCommonUser] = useUpdateIncidentCommonUser();
  const [updateGeneralPerson] = useUpdateIncidentGeneralPerson();

  const authUser = useAuthUser();

  if (!projectId) throw new Error("projectId is missing");
  if (!incidentId) throw new Error("incidentId is missing");

  const { data: incidentData } = useSuspenseQuery<
    GetIncidentByPkQuery,
    GetIncidentByPkQueryVariables
  >(GetIncidentByPkDocument, {
    variables: {
      incidentId: incidentId,
    },
    fetchPolicy: "cache-first",
  });

  const { data: monetarySeverity } = useGetMonetarySeverityLevelsQuery();
  const monetarySeverityLevels = monetarySeverity?.incident_severity;

  const incident = incidentData?.incident_by_pk;

  if (!incident) throw new Error("Incident is missing");
  const [uploadIncidentDocument] = useUploadIncidentTypeDocument();
  const [insertIncidentType] = useInsertIncidentType();
  const equipment = incident.equipment_incident;
  const documents = getIncidentDocuments(incident, "equipment");
  if (!equipment) {
    throw new Error("equipment_incident is missing");
  }

  const [updateMonetarySeverity] = useUpdateMonetarySeverity();
  const [updateEquipment] = useUpdateEquipmentIncidentMutation();
  const [deleteIncidentDocument] = useDeleteIncidentTypeDocument();
  const insertIncidentTypeLink = async () => {
    await insertIncidentType(incident, incidentId, "equipment");
  };
  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocument(objects, incident, "equipment");
  };

  const deleteDocument = async (doc: deletedDocument) => {
    await deleteIncidentDocument(doc, incident, "equipment");
  };

  const updateEquipmentIncident = async (
    _set: Omit<Equipment_Incident_Set_Input, "incident_id">,
    comment: string,
  ) => {
    const updatedIncident = {
      ...incident,
      equipment_incident: { ...equipment, _set },
    };
    const patch = createIncidentPatch(updatedIncident, incident);

    await insertIncidentTypeLink();
    await updateEquipment({
      variables: {
        incidentId: incidentId,
        _set: _set,
        object: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incidentId,
          edit_type: "equipment-incident-update",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_equipment_incident_by_pk: {
          ...equipment,
          incident_id: incidentId,
          ..._set,
        },
      },
    });
  };

  const operator = equipment.operator;

  return (
    <>
      <div className="absolute left-24 top-2 text-2">Equipment</div>
      <div className="w-full mt-6 pl-4 table-fixed overflow-y-auto">
        <IncidentCommonUser
          title="Operator"
          user={equipment.operator ?? undefined}
          onUpdateUser={async (id, name) => {
            updateEquipmentIncident(
              { operator_user_id: id },
              `Updated Equipment - "Operator" to ${name}`,
            );
          }}
          onUpdateUserInfo={async (id, key, val) => {
            if (!!operator) {
              const updatedIncident = {
                ...incident,
                equipment_incident: {
                  ...equipment,
                  operator: {
                    ...operator,
                    [key]: val,
                  },
                },
              };
              const patch = createIncidentPatch(updatedIncident, incident);
              const label = key === "phone_number" ? "phone number" : "email";
              const comment = `Updated Equipment - ${label} of the "Operator" to ${val}`;
              updateIncidentCommonUser(
                id,
                { [key]: val },
                comment,
                incidentId,
                patch,
              );
            }
          }}
          onUpdateGeneralPerson={async (val) => {
            if (operator && operator.general_person) {
              const updatedIncident = {
                ...incident,
                equipment_incident: {
                  ...equipment,
                  operator: {
                    ...operator,
                    general_person: {
                      ...operator.general_person,
                      employer: val,
                    },
                  },
                },
              };
              const patch = createIncidentPatch(updatedIncident, incident);
              const comment = `Updated Equipment - employer of the "Reported by user" to ${val}`;
              updateGeneralPerson(
                operator.id,
                { employer: val },
                comment,
                incidentId,
                patch,
              );
            }
          }}
          projectId={projectId}
        />
        <Card className="w-4/5">
          <IncidentInputField
            label="Equipment / Vehicle Number"
            text={equipment.equipment_number ?? undefined}
            onSave={(val) => {
              if (typeof val === "string")
                updateEquipmentIncident(
                  { equipment_number: val },
                  `Updated Equipment - "Equipment / Vehicle Number" to ${val}`,
                );
            }}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentTextField
            label="Rental Details"
            text={equipment.rented_from.en}
            textId={equipment.rented_from.id}
            autoSize={{ minRows: 1, maxRows: 10 }}
            saveIncidentField={insertIncidentTypeLink}
            fieldTypeKey="equipment"
            field="rented_from"
            incident={incident}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentSelectField
            title="Monetary Severity"
            options={
              monetarySeverityLevels?.map((severity) => ({
                label: severity.name.en,
                value: severity.id,
              })) ?? []
            }
            value={incident.monetary_severity_id ?? undefined}
            onChange={(option) => {
              if (typeof option.value === "string") {
                updateMonetarySeverity(incident, option.value, option.label);
              }
            }}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentInputField
            label="Estimated damage"
            text={equipment.estimated_damage ?? undefined}
            onSave={(val) => {
              if (typeof val === "number")
                updateEquipmentIncident(
                  { estimated_damage: val },
                  `Updated Equipment - "Estimated damage" to ${val}`,
                );
            }}
          />
        </Card>
        <Card className="w-4/5">
          <IncidentInputField
            label="Report / Incident number"
            text={equipment.report_number ?? undefined}
            onSave={(val) => {
              if (typeof val === "string")
                updateEquipmentIncident(
                  { report_number: val },
                  `Updated Equipment - "Report / Incident number" to ${val}`,
                );
            }}
          />
        </Card>
        <Card className="w-4/5">
          <IncidentSelectField
            title="Did operator violate any safety rules"
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
            onChange={(option) => {
              if (typeof option.value === "boolean")
                updateEquipmentIncident(
                  {
                    operator_violated_safety_rules: option.value,
                  },

                  `Updated Equipment - "Did operator violate any safety rules" to ${option.label}`,
                );
            }}
            value={equipment.operator_violated_safety_rules ?? undefined}
          />

          {equipment.operator_violated_safety_rules && (
            <IncidentTextField
              label="* Rules Violated"
              text={equipment.rules_violated.en}
              textId={equipment.rules_violated.id}
              saveIncidentField={insertIncidentTypeLink}
              fieldTypeKey="equipment"
              field="rules_voilated"
              incident={incident}
            />
          )}
        </Card>
        <Card className="w-4/5">
          <IncidentSelectField
            title="Was there property damage"
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
            onChange={(option) => {
              if (typeof option.value === "boolean")
                updateEquipmentIncident(
                  { property_damaged: option.value },
                  `Updated Equipment - "Was there property damage"to ${option.label}`,
                );
            }}
            value={equipment.property_damaged ?? undefined}
          />

          {equipment.property_damaged && (
            <IncidentTextField
              label="* Details of Damage"
              text={equipment.damage_detail.en}
              textId={equipment.damage_detail.id}
              saveIncidentField={insertIncidentTypeLink}
              fieldTypeKey="equipment"
              field="damage_details"
              incident={incident}
            />
          )}
        </Card>

        {incident.incident_types.findIndex(
          (indexType) => indexType.type_value === "equipment",
        ) !== -1 && (
          <IncidentDocViewAndUpload
            deleteDocument={deleteDocument}
            documents={documents}
            groupId={incidentId}
            uploadDocument={uploadDocument}
            type="equipment"
          />
        )}

        <IncidentBottomButtons
          saveDisabled={
            !equipment.damage_detail.en ||
            (equipment.operator_violated_safety_rules === true &&
              !equipment.rules_violated)
          }
          onNextClick={() => {
            const next = getNextPage(incident, "equipment");
            navigate(
              `/gce/projects/${projectId}/incidents/${incidentId}/${next}`,
            );
          }}
          onSave={() => {
            updateEquipmentIncident(
              { completed_at: dayjs().format() },
              `Property Damage section marked as completed`,
            );
          }}
        />
      </div>
    </>
  );
};

export default EquipmentIncident;
