import React, { FC, useContext, useState } from "react";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import SiteOrientationSignatureUI from "src/domain-features/siteorientation/components/signature/SiteOrientationSignatureUI";

import {
  useCurrentLangStrings,
  useCurrentLanguage,
} from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSignaturePad from "../../../../../../../../../common/components/general/signature-canvas/useSignaturePad";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

interface SiteOrientationInPersonWorkerRegistrationStepsSignatureProps
  extends SiteOrientationStepProps {}

const SiteOrientationInPersonWorkerRegistrationStepsSignature: FC<
  SiteOrientationInPersonWorkerRegistrationStepsSignatureProps
> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const workerContext = useSiteOrientationOrientee();
  const langStrings = useCurrentLangStrings();
  const lang = useCurrentLanguage();
  const { isEmpty, uploadSignature, signaturePadProps } = useSignaturePad();
  const onSign = async (signatureImageUrl: string) => {
    // const signatureImageUrl = await signatureCanvas.uploadSignature();
    if (signatureImageUrl) {
      workerContext.updateOrientee((user) =>
        user
          ? {
              ...user,
              signatureImageUrl,
            }
          : user,
      );

      props.onNext();
    } else {
      console.error("Signature not uploaded");
    }
  };

  const onNextClick = async () => {
    setLoading(true);
    try {
      const imageUrl = await uploadSignature();
      if (imageUrl) {
        await onSign(imageUrl);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  console.log(signaturePadProps);

  return (
    <SiteFeatureStepLayout
      loading={loading}
      nextButtonDisabled={isEmpty}
      onNextButtonClick={onNextClick}
      onBackButtonClick={props.onBack}
    >
      <div className={`flex min-h-full flex-col justify-center `}>
        <div className={`flex flex-col gap-1`}>
          <p className={`text-2`}>{langStrings.strings.signBelow}</p>
          <SiteOrientationSignatureUI
            userName={
              (workerContext.orientee?.name?.new ||
                workerContext.orientee?.name?.old) ??
              ""
            }
            isSignaturePadEmpty={isEmpty}
            signaturePadProps={signaturePadProps}
            lang={lang}
            disclaimerIsProject={workerContext.type !== "universal"}
          />
        </div>
      </div>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerRegistrationStepsSignature;
