import { initializeApp } from "firebase/app";
import { indexedDBLocalPersistence, initializeAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import keys from "src/common/constants/keys";

const app = initializeApp(keys.firebase);

const storage = getStorage(app)
const auth = initializeAuth(app, {
    persistence: indexedDBLocalPersistence
});

export { auth, app, storage };
