import React, { useContext, useState } from "react";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import { auth } from "src/common/functions/firebase";
import { usePseudoWorkerSignInMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { roleVar } from "src/common/api/apollo/cache";
import { message, notification } from "antd";
import SiteOrientationStepsUIEmail from "../../../../../../../components/stepsUI/email/SiteOrientationStepsUIEmail";
import useUpdateUserWorker from "src/common/api/relay/mutationHooks/useUpdateUserWorker";
import hasApolloErrorCode from "../../../../../../../../../utility-features/error-handling/hasApolloErrorCode";
import { signInWithCustomToken } from "firebase/auth";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationWorkerRegistrationStepsEmailProps
  extends SiteOrientationStepProps {
  onNoEmailPress: () => void;
  managerDevice?: boolean;
  onLoginSuccess?: (workerId: string) => Promise<void>;
}

const SiteOrientationWorkerRegistrationStepsEmail: React.FC<
  SiteOrientationWorkerRegistrationStepsEmailProps
> = (props) => {
  const [workerUpdate, _] = useUpdateUserWorker();
  const workerContext = useSiteOrientationOrientee();
  const [email, setEmail] = useState<string>(
    workerContext.orientee?.email || "",
  );
  const [pseudoWorkerSignIn] = usePseudoWorkerSignInMutation();
  const [loading, setLoading] = useState(false);

  const pseudoSignIn = async (trimmedEmail: string, employeeLogin: boolean) => {
    await pseudoWorkerSignIn({
      variables: {
        input: {
          returnToken: !employeeLogin,
          email: trimmedEmail,
          projectId: workerContext.projectId,
        },
      },
    })
      .then(async ({ data }) => {
        if (data?.pseudoWorkerSignIn) {
          const { workerId, token, userRole } = data?.pseudoWorkerSignIn;

          if (token) {
            roleVar("none");
            await auth.signOut();
            await signInWithCustomToken(auth, token);
          }
          if (userRole === "employee") {
            workerContext.updateSelectedUserId(workerId);
            props.onLoginSuccess && (await props.onLoginSuccess(workerId));
            console.log("OAC Users");
            props.onNext();
          } else {
            workerContext.updateSelectedUserId(workerId);
            props.onLoginSuccess && (await props.onLoginSuccess(workerId));
            console.log("SOmethings");
            props.onNext();
          }
        }
      })
      .catch((err) => {
        if (hasApolloErrorCode(err, "user-does-not-exist")) {
          workerContext.updateOrientee((u) =>
            u ? { ...u, email: trimmedEmail } : { email: trimmedEmail },
          );
          props.onNext();
        } else {
          message.warning(err.message);
        }
      });
  };
  const onEnter = async () => {
    // Update email if empty
    const trimmedEmail = email.trim();
    setLoading(true);
    const loggedInUser = await auth.currentUser?.getIdTokenResult();
    const employeeLogin = loggedInUser?.claims.role === "employee";
    if (employeeLogin && workerContext.orientee?.id && props.managerDevice) {
      try {
        await workerUpdate({
          variables: {
            userId: workerContext.orientee.id,
            userSet: { email: trimmedEmail },
            workerSet: {},
          },
        });
        workerContext.updateOrientee &&
          workerContext.updateOrientee((u) =>
            u ? { ...u, email: trimmedEmail } : { email: trimmedEmail },
          );
        props.onNext();
      } catch (err) {
        if (
          JSON.stringify(err).toLowerCase().includes("uniqueness violation")
        ) {
          console.log("UniqueNess Violation");
          await pseudoSignIn(trimmedEmail, employeeLogin);
        } else {
          notification.error({
            message: err instanceof Error ? err.message : JSON.stringify(err),
            duration: 5,
          });
        }
      }
    } else {
      await pseudoSignIn(trimmedEmail, employeeLogin);
    }
    setLoading(false);
  };

  return (
    <SiteOrientationStepsUIEmail
      loading={loading}
      value={email}
      onValueChange={setEmail}
      onEnter={onEnter}
      onBack={props.onBack}
      onNoEmailPress={props.onNoEmailPress}
    />
  );
};

export default SiteOrientationWorkerRegistrationStepsEmail;
