import { Form, message, Modal, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC } from "react";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { SafetyPlanTable_safetyPlan$data } from "src/common/types/generated/relay/SafetyPlanTable_safetyPlan.graphql";
import {
  SelectProjectForSafetyPlanModal_InsertSafetyPlanMutation,
  safety_plan_insert_input,
} from "src/common/types/generated/relay/SelectProjectForSafetyPlanModal_InsertSafetyPlanMutation.graphql";
import * as uuid from "uuid";

interface SelectProjectForSafetyPlanModalProps {
  subcontractorId: string;
  onClose: () => void;
  onSubmit: () => void;
  visible: boolean;
  data: Array<{ id: string; name: string }>;
  safetyPlan?: SafetyPlanTable_safetyPlan$data["safety_plan_connection"]["edges"][number]["node"];
}

type FormValues = {
  projectName: string;
};
const SelectProjectForSafetyPlanModal: FC<
  SelectProjectForSafetyPlanModalProps
> = ({ onClose, onSubmit, visible, data, subcontractorId, safetyPlan }) => {
  const [insertSafetyPlan, loading] =
    useAsyncMutation<SelectProjectForSafetyPlanModal_InsertSafetyPlanMutation>(
      graphql`
        mutation SelectProjectForSafetyPlanModal_InsertSafetyPlanMutation(
          $object: safety_plan_insert_input!
        ) {
          insert_safety_plan_one(object: $object) {
            ...SafetyPlanFrag @relay(mask: false)
          }
        }
      `,
    );
  const [form] = Form.useForm();
  const addToProject = async (projectId: string) => {
    if (!safetyPlan) {
      return;
    }
    const safetyPlanInsertInput: safety_plan_insert_input = {
      created_by_uid: auth.currentUser?.uid,
      project_id: projectId,
      subcontractor_id: subcontractorId,
      title: safetyPlan.title,
      url: safetyPlan.url,
      id: uuid.v4(),
      company_safety_plan_id: safetyPlan.pk,
      revision_date: safetyPlan.revision_date,
      revision_number: safetyPlan.revision_number,
    };
    await insertSafetyPlan({
      variables: {
        object: { ...safetyPlanInsertInput },
      },
    })
      .then(async (d) => {
        message.success("Added to Project Library");
      })
      .catch((e) => console.log(e));
  };

  return (
    <Modal
      open={visible}
      confirmLoading={loading}
      title={
        <div>
          Select Project Name
          <br />
        </div>
      }
      okText="ADD"
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={async () => {
        await form
          .validateFields()
          .then((v: FormValues) => {
            console.log(v);
            form.resetFields();
            addToProject(v.projectName);
            onSubmit();
          })
          .catch((e) => {
            console.log(e);
            message.error("Failed to fetch projects");
          });
      }}
    >
      <Form form={form} layout="vertical" title="Upload Project">
        <Form.Item
          className="mt-2"
          label="Project Name"
          name="projectName"
          rules={[{ required: true, message: "Project name required" }]}
        >
          <Select placeholder="Select Project Name">
            {data.map((project) => (
              <Select.Option value={project.id} key={project.id}>
                {project.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default SelectProjectForSafetyPlanModal;
