/**
 * @generated SignedSource<<a0efdb1063a39d50ef0956da5d12a483>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type lang_code_enum = "en" | "es" | "pt" | "%future added value";
export type user_set_input = {
  allow_mobile_login?: boolean | null | undefined;
  allow_reset_password?: boolean | null | undefined;
  birth_date?: string | null | undefined;
  childhood_friend_first_name?: string | null | undefined;
  created_at?: string | null | undefined;
  created_password?: boolean | null | undefined;
  email?: string | null | undefined;
  email_privacy_setting_id?: string | null | undefined;
  emergency_contact?: string | null | undefined;
  gender?: string | null | undefined;
  id?: string | null | undefined;
  incorrect_login_try_count?: number | null | undefined;
  lang?: lang_code_enum | null | undefined;
  last_mobile_login_at?: string | null | undefined;
  marital_status?: string | null | undefined;
  name?: string | null | undefined;
  password_hash?: string | null | undefined;
  phone_number?: string | null | undefined;
  phone_number_privacy_setting_id?: string | null | undefined;
  profile_picture_id?: string | null | undefined;
  project_privacy_setting_id?: string | null | undefined;
  reference_number?: string | null | undefined;
  role?: string | null | undefined;
  timezone?: string | null | undefined;
  updated_at?: string | null | undefined;
  username?: string | null | undefined;
};
export type worker_set_input = {
  age?: number | null | undefined;
  age_entered_at?: string | null | undefined;
  city_id?: string | null | undefined;
  city_zip_code_id?: string | null | undefined;
  created_at?: string | null | undefined;
  current_worker_role?: string | null | undefined;
  email_daily_report_on_submit?: boolean | null | undefined;
  email_safety_report_on_submit?: boolean | null | undefined;
  email_toolbox_talk_on_submit?: boolean | null | undefined;
  ethnicity?: string | null | undefined;
  gender?: string | null | undefined;
  hire_date?: string | null | undefined;
  labor_union_chapter_id?: string | null | undefined;
  status?: string | null | undefined;
  subcontractor_id?: string | null | undefined;
  uid?: string | null | undefined;
  updated_at?: string | null | undefined;
  verified_at?: string | null | undefined;
  verified_by_id?: string | null | undefined;
  veteran_type?: string | null | undefined;
  worker_title_id?: string | null | undefined;
  years_in_construction?: string | null | undefined;
  years_in_construction_entered_at?: string | null | undefined;
  years_with_employer?: string | null | undefined;
  years_with_employer_entered_at?: string | null | undefined;
};
export type useUpdateUserWorkerMutation$variables = {
  userId: string;
  userSet: user_set_input;
  workerSet: worker_set_input;
};
export type useUpdateUserWorkerMutation$data = {
  readonly update_user_by_pk: {
    readonly email: string | null | undefined;
    readonly emergency_contact: string | null | undefined;
    readonly id: string;
    readonly name: string;
    readonly phone_number: string | null | undefined;
  } | null | undefined;
  readonly update_worker_by_pk: {
    readonly age: number | null | undefined;
    readonly ethnicity: string | null | undefined;
    readonly gender: string | null | undefined;
    readonly uid: string;
    readonly veteran_type: string | null | undefined;
  } | null | undefined;
};
export type useUpdateUserWorkerMutation = {
  response: useUpdateUserWorkerMutation$data;
  variables: useUpdateUserWorkerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userSet"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workerSet"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "_set",
      "variableName": "userSet"
    },
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "userId"
        }
      ],
      "kind": "ObjectValue",
      "name": "pk_columns"
    }
  ],
  "concreteType": "user",
  "kind": "LinkedField",
  "name": "update_user_by_pk",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone_number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emergency_contact",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "workerSet"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "uid",
        "variableName": "userId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gender",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "age",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ethnicity",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "veteran_type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateUserWorkerMutation",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "worker",
        "kind": "LinkedField",
        "name": "update_worker_by_pk",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateUserWorkerMutation",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "worker",
        "kind": "LinkedField",
        "name": "update_worker_by_pk",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e2ddd1af06e7ab87c941b1ab7ca62013",
    "id": null,
    "metadata": {},
    "name": "useUpdateUserWorkerMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateUserWorkerMutation(\n  $userId: uuid!\n  $userSet: user_set_input!\n  $workerSet: worker_set_input!\n) {\n  update_user_by_pk(pk_columns: {id: $userId}, _set: $userSet) {\n    id\n    name\n    email\n    phone_number\n    emergency_contact\n  }\n  update_worker_by_pk(pk_columns: {uid: $userId}, _set: $workerSet) {\n    uid\n    gender\n    age\n    ethnicity\n    veteran_type\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6f22e83a2099805d654396cfc00f9ea2";

export default node;
