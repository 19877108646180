import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';

interface Props {
  padding?: boolean;
  backgroundColor?: string;
}

const StyledContent = styled(
  ({ backgroundColor, padding = true, ...props }) => (
    <Layout.Content {...props} />
  ),
)<Props>`
  background-color: ${(p) => p.backgroundColor};
  padding: ${(p) => (p.padding ? 24 : 0)}px;
`;

StyledContent.defaultProps = {
  padding: true,
  backgroundColor: 'white',
};

export default StyledContent;
