import { Checkbox, Modal } from "antd";
import React, { FC, useState } from "react";
import { useSiteOrientationGcDashboardDataQuery$data } from "src/common/types/generated/relay/useSiteOrientationGcDashboardDataQuery.graphql";
import { yearsWithEmployerOptions } from "src/domain-features/siteorientation/utils/demographicQuestionOptions";
import useUpdateProjectSettings, {
  useUpdateProjectSettingsMutationReturningValues,
} from "src/common/api/relay/mutationHooks/useUpdateProjectSettings";

export interface QuestionsToAsk {
  ask_gender: boolean;
  ask_age: boolean;
  ask_ethnicity: boolean;
  ask_veteran_type: boolean;
  ask_residence: boolean;
  ask_emergency_contact: boolean;
  ask_number_of_years_in_construction: boolean;
  ask_years_with_employer: boolean;
}

type project_data =
  useSiteOrientationGcDashboardDataQuery$data["project_connection"]["edges"][number]["node"];

interface WorkerRegistrationQuestionsModalProps {
  visible: boolean;
  projectId: string;
  projectData: project_data;
  params: useUpdateProjectSettingsMutationReturningValues;
  onClose: () => void;
}

const WorkerRegistrationQuestionsModal: FC<
  WorkerRegistrationQuestionsModalProps
> = ({ visible, params, projectId, projectData, onClose }) => {
  const [updateProject, isUpdating] = useUpdateProjectSettings({
    projectId,
    returningFieldsVal: params,
  });
  const [formVal, setFormVal] = useState<QuestionsToAsk>({ ...params });
  return (
    (<Modal
      width={700}
      confirmLoading={isUpdating}
      open={visible}
      title={"Worker Registration Questions"}
      onOk={async () => {
        await updateProject(formVal);
        onClose?.();
      }}
      onCancel={onClose}
    >
      <div className="">
        Select questions all workers are required to answer during their digital
        registration on this project. Each worker Digitally Registers on your
        project either during In-Person Orientation or when they complete Online
        Orientation.
        <br />
        <br />
        Questions are fixed and cannot be edited.
      </div>
      <Checkbox
        className="mt-1"
        checked={formVal.ask_gender}
        onChange={(v) =>
          setFormVal((prev) => ({ ...prev, ask_gender: v.target.checked }))
        }
      >
        Gender
        <div className="ml-1.5 w-full">
          What is your gender?
          <div className="text-static-secondary">
            Possible answers are: Male; Female
          </div>
        </div>
      </Checkbox>
      <br />
      <Checkbox
        checked={formVal.ask_ethnicity}
        onChange={(v) =>
          setFormVal((prev) => ({ ...prev, ask_ethnicity: v.target.checked }))
        }
      >
        Race
        <div className="ml-1.5 w-full">
          What is your race?
          <div className="text-static-secondary">
            Possible answers are: American Indian or Alaska Native; Asian; Black
            or African American; Cape Verdean; Hispanic, Latino or Spanish;
            Native Hawaiian and Pacific Islander; White or Non Hispanic; Some
            Other Race
          </div>
        </div>
      </Checkbox>
      <br />
      <Checkbox
        checked={formVal.ask_veteran_type}
        onChange={(v) =>
          setFormVal((prev) => ({
            ...prev,
            ask_veteran_type: v.target.checked,
          }))
        }
      >
        Veteran
        <div className="ml-1.5 w-full">
          Are you a Veteran?
          <div className="text-static-secondary">
            Possible answers are: Non-Veteran; Veteran, Disabled Veteran
          </div>
        </div>
      </Checkbox>
      <br />
      {!(
        projectData.require_dob || projectData.general_contractor.require_dob
      ) && (
        <>
          <Checkbox
            checked={formVal.ask_age}
            onChange={(v) =>
              setFormVal((prev) => ({ ...prev, ask_age: v.target.checked }))
            }
          >
            Age
            <div className="ml-1.5 w-full">
              What is your age?
              <div className="text-static-secondary">Enter Age</div>
            </div>
          </Checkbox>
          <br />
        </>
      )}
      <Checkbox
        checked={formVal.ask_residence}
        onChange={(v) =>
          setFormVal((prev) => ({ ...prev, ask_residence: v.target.checked }))
        }
      >
        Residence
        <div className="ml-1.5 w-full">
          What city or zip code do you live in?
          <div className="text-static-secondary">Select the city</div>
        </div>
      </Checkbox>
      <br />
      <Checkbox
        checked={formVal.ask_emergency_contact}
        onChange={(v) =>
          setFormVal((prev) => ({
            ...prev,
            ask_emergency_contact: v.target.checked,
          }))
        }
      >
        Emergency Contact
        <div className="ml-1.5 w-full">
          Who should we contact in case of an emergency?
          <div className="text-static-secondary">
            Enter their name and phone number.
          </div>
        </div>
      </Checkbox>
      <br />
      <Checkbox
        checked={formVal.ask_number_of_years_in_construction}
        onChange={(v) =>
          setFormVal((prev) => ({
            ...prev,
            ask_number_of_years_in_construction: v.target.checked,
          }))
        }
      >
        Years of Experience
        <div className="ml-1.5 w-full">
          How many years of experience do you have in construction?
          <div className="text-static-secondary">
            Select the best option for the number of years of construction
            experience.
          </div>
        </div>
      </Checkbox>
      <br />
      <Checkbox
        checked={formVal.ask_years_with_employer}
        onChange={(v) =>
          setFormVal((prev) => ({
            ...prev,
            ask_years_with_employer: v.target.checked,
          }))
        }
      >
        Years with Employer
        <div className="ml-1.5 w-full">
          How many years have you been employed with this company?
          <div className="text-static-secondary">
            Select a time range, e.g.{" "}
            {yearsWithEmployerOptions.map((y, i) => (i === 0 ? y : ", " + y))}
          </div>
        </div>
      </Checkbox>
    </Modal>)
  );
};
export default WorkerRegistrationQuestionsModal;
