/**
 * @generated SignedSource<<ecde92f30ac798fe42b4162f9ccc1cc4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddCompanyModal_Insert_OAC_Company_Mutation$variables = {
  id: string;
  name: string;
};
export type AddCompanyModal_Insert_OAC_Company_Mutation$data = {
  readonly insert_oac_company_one: {
    readonly name: string;
    readonly pk: string;
  } | null | undefined;
};
export type AddCompanyModal_Insert_OAC_Company_Mutation = {
  response: AddCompanyModal_Insert_OAC_Company_Mutation$data;
  variables: AddCompanyModal_Insert_OAC_Company_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "kind": "ObjectValue",
    "name": "object"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddCompanyModal_Insert_OAC_Company_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "oac_company",
        "kind": "LinkedField",
        "name": "insert_oac_company_one",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddCompanyModal_Insert_OAC_Company_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "oac_company",
        "kind": "LinkedField",
        "name": "insert_oac_company_one",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5329c951630f99488821f55d5459fbaa",
    "id": null,
    "metadata": {},
    "name": "AddCompanyModal_Insert_OAC_Company_Mutation",
    "operationKind": "mutation",
    "text": "mutation AddCompanyModal_Insert_OAC_Company_Mutation(\n  $name: String!\n  $id: uuid!\n) {\n  insert_oac_company_one(object: {name: $name, id: $id}) {\n    name\n    pk: id\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d1aa7342cbc6275c9a7fc72cc80b2990";

export default node;
