import { IconX } from "@tabler/icons";
import React from "react";
import Icon, { IconType } from "./Icon";

interface PillProps {
  label: string | React.ReactNode;
  onClick?: () => void;
  darker?: boolean;
  icon?: IconType;
}

const Pill: React.FunctionComponent<PillProps> = (props) => {
  const interactive = !!props.onClick;

  return (
    <div
      {...{ onClick: props.onClick }}
      className={`inline-flex flex-row items-center px-0.5 h-1.5 select-none rounded-1 margin-gap-x-0.25 group border border-transparent transitoin-all ${
        props.darker ? "bg-suplementary-2" : "bg-suplementary-1"
      } ${
        interactive
          ? "cursor-pointer hover:bg-suplementary-1 hover:border-interactive-primary"
          : ""
      }`}
    >
      {!!props.icon && (
        <Icon color="interactive" size={"small"} icon={props.icon} />
      )}
      <span
        className={`text-0.75 transition-all ${
          interactive ? "group-hover:text-interactive-primary " : ""
        }`}
      >
        {props.label}
      </span>
    </div>
  );
};

export default Pill;
