import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "src/common/components/general/BackButton";
import LoadingContent from "src/common/components/general/LoadingContent";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import MobileViewWrapper from "src/common/components/layouts/MobileViewWrapper";
import { useGetReportByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";
import DateProps from "src/common/types/manual/DateProps";
import ProjectProps from "src/common/types/manual/ProjectProps";
import ReportProps from "src/common/types/manual/ReportProps";
import ReportDetail from "../../../../../components/ReportDetail";

const QrProjectPtpReport: FC<ReportProps & ProjectProps & DateProps> = ({
  reportId,
  projectId,
  date,
}) => {
  const navigate = useNavigate();
  const { data } = useGetReportByPkQuery({
    variables: { reportId },
  });

  if (!data?.report_by_pk) {
    return <LoadingContent />;
  }

  return (
    <MobileViewWrapper footer={<BackButton fullWidth />}>
      <ReportDetail
        routeId={{ type: "anonymous", id: projectId }}
        report={data.report_by_pk as any}
      />
    </MobileViewWrapper>
  );
};

export default withCustomSuspense(QrProjectPtpReport);
