import React from "react";
import PhoneNumberInputInternational from "./PhoneNumberInputInternational";
import PhoneNumberInputUS from "./PhoneNumberInputUS";
import { PhoneNumberInputCommonProps } from "./phoneNumberInputTypes";

export interface PhoneNumberInputProps extends PhoneNumberInputCommonProps {
  international?: boolean;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = (props) => {
  return props.international ? (
    <PhoneNumberInputInternational {...props} />
  ) : (
    <PhoneNumberInputUS {...props} />
  );
};

export default PhoneNumberInput;
