import { Input } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BSpace from "src/common/components/layouts/BSpace";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";

import { SFRequestedSubcontractorsQuery } from "src/common/types/generated/relay/SFRequestedSubcontractorsQuery.graphql";
import { SFRequestedSubcontractors_add_subcontractor_request$data } from "src/common/types/generated/relay/SFRequestedSubcontractors_add_subcontractor_request.graphql";

export type ColumnKeys =
  | "subcontractor_name"
  | "subcontractor_ein"
  | "subcontractor_trade"
  | "gc_name"
  | "project_name"
  | "project_city"
  | "project_state"
  | "requested_by"
  | "created_at";
const CONNECTION_NAME = "add_subcontractor_request_connection";
type DConnection =
  SFRequestedSubcontractors_add_subcontractor_request$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  SFRequestedSubcontractorsQuery,
  { worker_id: string | unknown; project_id: string | unknown }
> & {
  onRowClickPK: (pk: string) => void;
  searchString?: string;
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    SFRequestedSubcontractorsQuery
  >["columns"];
  markDeleted?: boolean;
  allowEditHH?: boolean;
};
const SFRequestedSubcontractorsTable = forwardRef<DataScrollTableRef, Props>(
  (
    {
      title = "Requests for New Subcontractors",
      onRowClickPK,
      searchString,
      where,
      ...props
    },
    ref,
  ) => {
    return (
      <DataScrollTable<DConnection, ColumnKeys, SFRequestedSubcontractorsQuery>
        {...props}
        ref={ref}
        title={title}
        onRowClick={({ pk }) => onRowClickPK && onRowClickPK(pk)}
        where={where}
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allSubcontractorsToAddConnection"}
        columns={[
          {
            title: "Subcontractor Name",
            key: "subcontractor_name",
            size: "md",
            dataIndex: ["subcontractor_business_name"],
            searchDataIndex: ["subcontractor_business_name"],
            searchValue: searchString,
          },
          {
            title: "Subcontractor EIN",
            key: "subcontractor_ein",
            size: "md",
            dataIndex: ["subcontractor_ein"],
            searchDataIndex: ["subcontractor_ein"],
            searchValue: searchString,
          },
          {
            title: "Subcontractor Trade",
            key: "subcontractor_trade",
            size: "md",
            dataIndex: ["subcontractor_trade", "name"],
            searchDataIndex: ["subcontractor_trade", "name"],
          },
          {
            title: "GC Name",
            key: "gc_name",
            size: "md",
            dataIndex: ["project", "general_contractor", "name"],
            searchDataIndex: ["project", "general_contractor", "name"],
          },
          {
            title: "Project Name",
            key: "project_name",
            size: "md",
            dataIndex: ["project", "name"],
            searchDataIndex: ["project", "name"],
          },
          {
            title: "Project City",
            key: "project_city",
            size: "md",
            dataIndex: ["project", "address", "city"],
            searchDataIndex: ["project", "address", "city"],
          },
          {
            title: "Project State",
            key: "project_state",
            size: "md",
            dataIndex: ["project", "address", "state", "name"],
            searchDataIndex: ["project", "address", "state", "name"],
          },
          {
            title: "Requested By",
            key: "requested_by",
            size: "md",
            dataIndex: ["requested_by_user", "name"],
            searchDataIndex: ["requested_by_user", "name"],
          },
          {
            title: "Requested on",
            key: "created_at",
            size: "md",
            sortable: true,
            defaultSortOrder: "desc",
            dataIndex: ["created_at"],
            searchDataIndex: ["created_at"],
            render: (a) => dayjs(a).format("LLL"),
          },
        ]}
        queryNode={graphql`
          query SFRequestedSubcontractorsQuery(
            $first: Int!
            $after: String
            $where: add_subcontractor_request_bool_exp!
            $order_by: [add_subcontractor_request_order_by!]!
          ) {
            ...SFRequestedSubcontractors_add_subcontractor_request
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
            ...SFRequestedSubcontractors_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment SFRequestedSubcontractors_total on query_root
          @argumentDefinitions(
            where: { type: "add_subcontractor_request_bool_exp!" }
          )
          @refetchable(
            queryName: "SFRequestedSubcontractorsTotalRefetchableQuery"
          ) {
            allSubcontractorsToAddConnection: add_subcontractor_request_connection(
              where: $where
            ) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment SFRequestedSubcontractors_add_subcontractor_request on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "add_subcontractor_request_bool_exp!" }
            order_by: { type: "[add_subcontractor_request_order_by!]!" }
          )
          @refetchable(queryName: "SFRequestedSubcontractorsRefetchableQuery") {
            add_subcontractor_request_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "SFRequestedSubcontractors_add_subcontractor_request_connection"
                filters: ["where", "order_by"]
              ) {
              edges {
                node {
                  pk: id @__clientField(handle: "pk")
                  subcontractor_business_name
                  subcontractor_trade {
                    name
                  }
                  subcontractor_ein
                  created_at
                  project {
                    name
                    general_contractor {
                      name
                    }
                    address {
                      city
                      state {
                        name
                      }
                    }
                  }
                  requested_by_user {
                    name
                  }
                  created_at
                }
              }
            }
          }
        `}
      />
    );
  },
);

const SFRequestedSubcontractors: FC = () => {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const onInputKeyDown = (event: any): any => {
    if (event.key === "Enter") {
      setInputValue(event.target.value);
    }
  };

  return (
    <>
      <BSpace>
        <div className="flex items-center justify-center m-2.5">
          Subcontractor Verification Table
        </div>
        <div className="flex justify-between float-right">
          <Input
            type="primary"
            onKeyDown={onInputKeyDown}
            className="w-12"
            placeholder="Search"
          />
        </div>
      </BSpace>
      <BSpace>
        <SFRequestedSubcontractorsTable
          searchString={inputValue}
          where={{
            verified_at: { _is_null: true },
          }}
          onRowClickPK={(pk) => {
            navigate(`/sfe/verify/subcontractors/${pk}`);
          }}
        />
      </BSpace>
    </>
  );
};
export default SFRequestedSubcontractors;
