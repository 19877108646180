import { DatePicker } from "antd";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import React, { useState } from "react";

const { RangePicker } = DatePicker;

type RangeValue = [Dayjs | null, Dayjs | null] | null;

interface GCProjectReportsDailyMonthDownloadModalDateRangeProps {
  startMonth: dayjs.Dayjs | null;
  endMonth: dayjs.Dayjs | null;
  onStartMonthChagne: (newValue: dayjs.Dayjs | null) => void;
  onEndMonthChange: (newValue: dayjs.Dayjs | null) => void;
  maxMonthsNumber: number;
}

const GCProjectReportsDailyMonthDownloadModalDateRange: React.FC<
  GCProjectReportsDailyMonthDownloadModalDateRangeProps
> = (props) => {
  const [dates, setDates] = useState<RangeValue>(null);
  const [hackValue, setHackValue] = useState<RangeValue>(null);
  const today = dayjs();

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }

    if (current > today) {
      return true;
    }

    const tooLate =
      dates[0] && current.diff(dates[0], "months") >= props.maxMonthsNumber - 1;
    const tooEarly =
      dates[1] && dates[1].diff(current, "months") >= props.maxMonthsNumber - 1;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };

  return (
    <RangePicker
      className="min-w-12"
      picker="month"
      format={"MMMM YYYY"}
      value={hackValue || [props.startMonth, props.endMonth]}
      disabledDate={disabledDate}
      onCalendarChange={(val) => setDates(val)}
      onChange={(val) => {
        const start = val ? val[0] : null;
        const end = val ? val[1] : null;
        props.onStartMonthChagne(start);
        props.onEndMonthChange(end);
      }}
      onOpenChange={onOpenChange}
    />
  );
};

export default GCProjectReportsDailyMonthDownloadModalDateRange;
