import { IconBriefcase } from "@tabler/icons";
import { Checkbox } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import CustomButton from "src/common/components/general/Button";
import ActionButton from "src/domain-features/sitesafety/job-hazard-analysis/components/action-buttons/ActionButton";
import { forwardRef, useRef, useState } from "react";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import { PreTaskPlansTableQuery } from "src/common/types/generated/relay/PreTaskPlansTableQuery.graphql";
import { PreTaskPlansTable_reports$data } from "src/common/types/generated/relay/PreTaskPlansTable_reports.graphql";
import { IconDotsVertical } from "@tabler/icons";
import {
  useEmailSafetyReportsPdfMutation,
  useGenerateMergedSafetyReportPdfMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { generateSafetyReportsPdf } from "src/domain-features/sitesafety/utils/ptpReportsUtil";
import SendEmailModal, { SendEmailModalRef } from "../dialogs/SendEmailModal";
import { executeWithMessage } from "src/common/functions/asyncUtility/executeWithMessage";

import ActionButtonsPopover from "../popover/ActionButtonsPopover";

export interface Subcontractor {
  id: string;
  name: string;
}

type ColumnKeys =
  | "select"
  | "date"
  | "project"
  | "subcontractor"
  | "workers"
  | "tasks"
  | "injuries"
  | "rowLevelActions";
const CONNECTION_NAME = "report_connection";
type DConnection = PreTaskPlansTable_reports$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  PreTaskPlansTableQuery,
  string
> & {
  showDateRangeFilter?: boolean;
  subContractorFilterOptions?: Subcontractor[];
};

const PreTaskPlansTable = forwardRef<DataScrollTableRef, Props>(
  (
    {
      title = "Pre-Task Plans Reports",
      onRowClick,
      showDateRangeFilter,
      subContractorFilterOptions,
      ...props
    },
    ref,
  ) => {
    const [emailSafetyReportsPdf] = useEmailSafetyReportsPdfMutation();
    const [generateSafetyReportsPdfMutation] =
      useGenerateMergedSafetyReportPdfMutation();
    const [selectedReportIds, setSelectedReportIds] = useState<string[]>([]);
    const [rowActionReportId, setRowActionReportId] = useState<string | null>(
      null,
    ); //Report Id for send email modal which gets opened from row level action

    const emailModal = useRef<SendEmailModalRef>(null);

    return (
      <div className="h-full">
        <SendEmailModal
          ref={emailModal}
          title={"Email safety report PDF"}
          afterClose={() => {
            setRowActionReportId(null);
          }}
          onSubmit={async ({ email }) => {
            emailModal.current?.close();
            const reportLabel =
              !rowActionReportId && selectedReportIds.length > 0
                ? "reports"
                : "report";
            executeWithMessage({
              type: "loading",
              initialContent: `Preparing ${reportLabel}...`,
              successContent: `Emailed ${reportLabel}!`,
              asyncFunction: async () => {
                await emailSafetyReportsPdf({
                  variables: {
                    input: {
                      emailRecipients: [email],
                      safetyReportIds: rowActionReportId
                        ? [rowActionReportId]
                        : selectedReportIds,
                    },
                  },
                });
              },
            });
          }}
        />

        <DataScrollTable<DConnection, ColumnKeys, PreTaskPlansTableQuery>
          {...props}
          filterNotVisibleByDefault
          newCustomTableLook
          ref={ref}
          title={title}
          datePickerDataIndex={showDateRangeFilter ? ["created_at"] : undefined}
          onRowClick={(report) => {
            onRowClick && onRowClick(report.pk);
          }}
          connectionName={CONNECTION_NAME}
          totalCountConnectionName={"allReportsConnection"}
          topBarButtons={
            selectedReportIds.length > 0
              ? [
                  {
                    icon: IconDotsVertical,
                    secondary: true,
                    fake: true,
                    hint: (
                      <div className="w-10">
                        <ActionButton
                          label="Email reports"
                          onClick={() => {
                            emailModal.current?.open();
                          }}
                        ></ActionButton>
                        <ActionButton
                          label="Download reports"
                          onClick={() => {
                            generateSafetyReportsPdf(
                              selectedReportIds,
                              generateSafetyReportsPdfMutation,
                            );
                          }}
                        ></ActionButton>
                      </div>
                    ),
                  },
                ]
              : undefined
          }
          columns={[
            {
              onCell: (e) => ({
                onClick: (e) => {
                  e.stopPropagation();
                },
              }),
              title: "",
              size: "icon",
              align: "right",
              dataIndex: [],
              key: "select",
              render: (_, row) => {
                return (
                  <div className="p-1" onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      key={row.pk}
                      onChange={(e) => {
                        e.stopPropagation();
                        if (e.target.checked)
                          setSelectedReportIds((reportIds) => {
                            return [row.pk, ...reportIds];
                          });
                        else
                          setSelectedReportIds((reportIds) =>
                            reportIds.filter((id) => id !== row.pk),
                          );
                      }}
                    ></Checkbox>
                  </div>
                );
              },
            },
            {
              title: "Date",
              key: "date",
              contentType: {
                type: "date",
                renderOptions: () => ({
                  format: "full",
                  withTime: true,
                  withTimeZone: true,
                }),
              },
              size: "md",
              sortable: true,
              defaultSortOrder: "desc",
              dataIndex: ["created_at"],
            },
            {
              title: "Project",
              key: "project",
              size: "md",
              queryIncludeVarKey: "includeProject",
              dataIndex: ["project", "name"],
              searchDataIndex: ["project", "name"],
            },
            {
              title: "Subcontractor",
              key: "subcontractor",
              size: "md",
              queryIncludeVarKey: "includeSub",
              dataIndex: ["subcontractor", "name"],
              searchDataIndex: ["subcontractor", "name"],
              contentType: {
                type: "with-icon",
                renderOptions: (value) => ({
                  icon: IconBriefcase,
                  label: value,
                }),
              },
              ...(subContractorFilterOptions?.length
                ? {
                    filters: {
                      type: "checklist",
                      dataIndex: ["subcontractor", "id"],
                      options: subContractorFilterOptions.map(
                        (subContractor) => {
                          return {
                            text: subContractor.name,
                            value: subContractor.id,
                          };
                        },
                      ),
                    },
                  }
                : {}),
            },
            {
              title: "Workers",
              key: "workers",
              contentType: {
                type: "counter",
              },
              size: "sm",
              sortable: true,
              dataIndex: [
                "report_project_workers_aggregate",
                "aggregate",
                "count",
              ],
            },
            {
              title: "JHAs",
              key: "tasks",
              contentType: {
                type: "counter",
              },

              size: "sm",
              sortable: true,
              dataIndex: ["tasks_aggregate", "aggregate", "count"],
            },
            {
              title: "Injuries",
              key: "injuries",
              contentType: {
                type: "counter",
              },

              size: "sm",
              sortable: true,
              dataIndex: ["report_injuries_aggregate", "aggregate", "count"],
            },
            {
              title: "",
              dataIndex: [""],
              key: "rowLevelActions",
              size: "xsm",
              fixed: "right",

              render: (_: any, row, i: number) => {
                return (
                  <div
                    className="flex w-4"
                    key={i}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span>
                      <ActionButtonsPopover
                        popoverProps={{
                          trigger: "click",
                          placement: "topLeft",
                        }}
                        actionButtons={[
                          {
                            label: "Email report",
                            onClick: () => {
                              setRowActionReportId(row.pk);
                              emailModal.current?.open();
                            },
                          },
                          {
                            label: "Download report",
                            onClick: () =>
                              generateSafetyReportsPdf(
                                [row.pk],
                                generateSafetyReportsPdfMutation,
                              ),
                          },
                        ]}
                      >
                        <CustomButton
                          icon={IconDotsVertical}
                          secondary
                          onClick={() => {}}
                        />
                      </ActionButtonsPopover>
                    </span>
                  </div>
                );
              },
            },
          ]}
          queryNode={graphql`
            query PreTaskPlansTableQuery(
              $first: Int!
              $after: String
              $where: report_bool_exp!
              $order_by: [report_order_by!]!
              $includeSub: Boolean!
              $includeProject: Boolean!
            ) {
              ...PreTaskPlansTable_reports
                @arguments(
                  first: $first
                  after: $after
                  where: $where
                  order_by: $order_by
                  includeSub: $includeSub
                  includeProject: $includeProject
                )
              ...PreTaskPlansTable_total @arguments(where: $where)
            }
          `}
          totalCountNode={graphql`
            fragment PreTaskPlansTable_total on query_root
            @argumentDefinitions(where: { type: "report_bool_exp!" })
            @refetchable(queryName: "PreTaskPlansTableTotalRefetchableQuery") {
              allReportsConnection: report_connection(where: $where) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          `}
          paginationNode={graphql`
            fragment PreTaskPlansTable_reports on query_root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              where: { type: "report_bool_exp!" }
              order_by: { type: "[report_order_by!]!" }
              includeSub: { type: "Boolean!" }
              includeProject: { type: "Boolean!" }
            )
            @refetchable(queryName: "PreTaskPlansTableRefetchableQuery") {
              report_connection(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
                @connection(
                  key: "PreTaskPlansTable_report_connection"
                  filters: ["where", "order_by"]
                ) {
                edges {
                  node {
                    pk: id @__clientField(handle: "pk")
                    created_at
                    project @include(if: $includeProject) {
                      name
                    }
                    subcontractor @include(if: $includeSub) {
                      id
                      name
                    }
                    report_project_workers_aggregate {
                      aggregate {
                        count
                      }
                    }
                    report_injuries_aggregate {
                      aggregate {
                        count
                      }
                    }
                    tasks_aggregate {
                      aggregate {
                        count
                      }
                    }
                  }
                }
              }
            }
          `}
        />
      </div>
    );
  },
);

export default PreTaskPlansTable;
