import React, { useEffect, useState } from "react";
import BasicWrapper from "../../../../common/components/layouts/BasicWrapper";
import SubProjectMobilizationHeader from "./SubProjectMobilizationHeader";
import { useParams, useSearchParams } from "react-router-dom";
import SubProjectMobilizationSteps from "./SubProjectMobilizationSteps";
import SubProjectMobilizationProgressBar from "./SubProjectMobilizationProgressBar";
import { SubProjectMobilizationStepType } from "../utilities/subProjectMobilizationTypes";
import SubProjectMobilizationStepContactLists from "./steps/SubProjectMobilizationStepContactLists";
import SubProjectMobilizationStepSds from "./steps/SubProjectMobilizationStepSds";
import SubProjectMobilizationStepSssp from "./steps/SubProjectMobilizationStepSssp";
import SubProjectMobilizationStepJha from "./steps/SubProjectMobilizationStepJha";
import SubProjectMobilizationStepComplete from "./steps/SubProjectMobilizationStepComplete";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import { SubProjectMobilizationViewQuery } from "src/common/types/generated/relay/SubProjectMobilizationViewQuery.graphql";
import SubProjectMobilizationStepBilling from "./steps/SubProjectMobilizationStepBilling";

export interface SubProjectMobilizationViewProps {
  projectId: string;
  subcontractorId: string;
  showLoginText?: boolean;
}

const query = graphql`
  query SubProjectMobilizationViewQuery(
    $projectId: uuid!
    $subcontractorId: uuid!
  ) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          name
          billing_settings {
            payment_model
          }
          general_contractor {
            billing_settings {
              payment_model
            }
          }
        }
      }
    }
    project_subcontractor_employee_connection(
      where: {
        project_id: { _eq: $projectId }
        subcontractor_employee: { subcontractor_id: { _eq: $subcontractorId } }
      }
        first: 10000
    )
      @connection(
        key: "SubProjectMobilizationView_project_subcontractor_employee_connection"
        filters: []
      ) {
      edges {
        node {
          id
        }
      }
    }
    safety_data_sheet_connection(
      where: {
        subcontractor_id: { _eq: $subcontractorId }
        project_id: { _eq: $projectId }
        deleted_at: { _is_null: true }
      }
      first: 10000
    )
      @connection(
        key: "SubProjectMobilizationView_safety_data_sheet_connection"
        filters: []
      ) {
      edges {
        node {
          id
        }
      }
    }
    safety_plan_connection(
      where: {
        subcontractor_id: { _eq: $subcontractorId }
        project_id: { _eq: $projectId }
        deleted_at: { _is_null: true }
      }
      first: 10000
    )
      @connection(
        key: "SubProjectMobilizationView_safety_plan_connection"
        filters: []
      ) {
      edges {
        node {
          id
        }
      }
    }
    task_connection(
      where: {
        deleted_at: { _is_null: true }
        project_id: { _eq: $projectId }
        subcontractor_id: { _eq: $subcontractorId }
        report_id: { _is_null: true }
        _not: {
          is_pending_acceptance: { _eq: true }
          task_creation_request: {
            requested_by_user: { role: { _eq: "employee" } }
          }
        }
      }
      first: 10000
    )
      @connection(
        key: "SubProjectMobilizationView_task_connection"
        filters: []
      ) {
      edges {
        node {
          id
        }
      }
    }
    subcontractor_connection(where: { id: { _eq: $subcontractorId } }) {
      edges {
        node {
          billing_settings {
            default_billing_email
          }
          subcontractor_projects(where: { project_id: { _eq: $projectId } }) {
            billing_settings {
              account_id
              stripe_account {
                email
              }
            }
          }
        }
      }
    }
  }
`;

const SubProjectMobilizationView: React.FC<SubProjectMobilizationViewProps> = ({
  projectId,
  subcontractorId,
  showLoginText
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const data = useLazyLoadQuery<SubProjectMobilizationViewQuery>(query, {
    projectId: projectId,
    subcontractorId: subcontractorId,
  });
  const contactListCount =
    data.project_subcontractor_employee_connection.edges.length;
  const sdsCount = data.safety_data_sheet_connection.edges.length;
  const safetyPlanCount = data.safety_plan_connection.edges.length;
  const jhaCount = data.task_connection.edges.length;

  const mobilizationSteps: SubProjectMobilizationStepType[] = [
    {
      title: `Contact Lists (${contactListCount})`,
      description:
        "Add your Point of Contact(s) for the project. Designate an Emergency Contact",
      component: (
        <SubProjectMobilizationStepContactLists
          projectId={projectId}
          subcontractorId={subcontractorId}
          onNext={(isUploaded) => {
            goToNextStep();
            if (isUploaded) {
              updateStepStatus("contactList", "complete");
            }
          }}
        />
      ),
      id: "contactList",
    },
    {
      title: `Safety Data Sheets (${sdsCount})`,
      description:
        "Add and upload the SDS for each material being used on the project",
      component: (
        <SubProjectMobilizationStepSds
          projectId={projectId}
          subcontractorId={subcontractorId}
          onPrev={() => {
            goToPrevStep();
          }}
          onNext={(isUploaded) => {
            goToNextStep();
            if (isUploaded) {
              updateStepStatus("sds", "complete");
            }
          }}
        />
      ),
      id: "sds",
    },
    {
      title: `Safety Plan (${safetyPlanCount})`,
      description:
        "Upload your company safety manual. Add as one file or several easier to navigate files",
      component: (
        <SubProjectMobilizationStepSssp
          projectId={projectId}
          subcontractorId={subcontractorId}
          onPrev={() => {
            goToPrevStep();
          }}
          onNext={(isUploaded) => {
            goToNextStep();
            if (isUploaded) {
              updateStepStatus("sssp", "complete");
            }
          }}
        />
      ),
      id: "sssp",
    },
    {
      title: `Job Hazard Analysis (JHAs) (${jhaCount})`,
      description:
        "Add and upload a JHA/JSA/AHA for each task being performed on the project",
      component: (
        <SubProjectMobilizationStepJha
          projectId={projectId}
          subcontractorId={subcontractorId}
          onPrev={() => {
            goToPrevStep();
          }}
          onNext={(isUploaded) => {
            goToNextStep();
            if (isUploaded) {
              updateStepStatus("jha", "complete");
            }
          }}
        />
      ),
      id: "jha",
    },
  ];

  const subcontractor = data.subcontractor_connection.edges[0].node;
  const projectSubcontractorStripeAccountId =
    subcontractor.subcontractor_projects &&
    subcontractor.subcontractor_projects.length > 0
      ? subcontractor.subcontractor_projects[0].billing_settings?.account_id
      : null;

  const projectSubcontractorBillingEmail =
    subcontractor.subcontractor_projects &&
    subcontractor.subcontractor_projects.length > 0
      ? subcontractor.subcontractor_projects[0].billing_settings?.stripe_account
          ?.email
      : null;

  let paymentModel =
    data.project_connection.edges[0].node.billing_settings?.payment_model;
  if (!paymentModel) {
    paymentModel =
      data.project_connection.edges[0].node.general_contractor.billing_settings
        ?.payment_model;
  }
  if (
    !paymentModel ||
    (paymentModel === "sub" && !projectSubcontractorBillingEmail)
  ) {
    mobilizationSteps.push({
      title: "Setup Billing",
      description:
        "Set up billing so your Foreman does not have any lapse in service",
      component: (
        <SubProjectMobilizationStepBilling
          projectId={projectId}
          subcontractorId={subcontractorId}
          subcontractor={subcontractor}
          onPrev={() => {
            goToPrevStep();
          }}
          onNext={(isUploaded) => {
            goToNextStep();
            if (isUploaded) {
              updateStepStatus("billing", "complete");
            }
          }}
        />
      ),
      id: "billing",
    });
  }
  mobilizationSteps.push({
    title: "Complete",
    description: "",
    component: (
      <SubProjectMobilizationStepComplete
        onPrev={() => {
          goToPrevStep();
        }}
      />
    ),
    id: "complete",
  });


  const [stepsStatuses, setStepsStatuses] = useState<{
    [stepId: string]: "pending" | "complete";
  }>(
    mobilizationSteps.reduce(
      (acc, step) => ({ ...acc, [step.id]: "pending" }),
      {},
    ),
  );
  const stepId = searchParams.get("stepId");

  const updateStepId = (newStepId: string) => {
    const prevSearchParams = searchParams;
    prevSearchParams.set("stepId", newStepId);
    setSearchParams(prevSearchParams);
  };

  const currentStep = mobilizationSteps.find((step) => step.id === stepId);

  const goToNextStep = () => {
    const currentStepIndex = mobilizationSteps.findIndex(
      (step) => step.id === stepId
    );
    const nextStep = mobilizationSteps[currentStepIndex + 1];
    if (nextStep) {
      updateStepId(nextStep.id);
    }
  };
  const goToPrevStep = () => {
    const currentStepIndex = mobilizationSteps.findIndex(
      (step) => step.id === stepId
    );
    const prevStep = mobilizationSteps[currentStepIndex - 1];
    if (prevStep) {
      updateStepId(prevStep.id);
    }
  };
  const updateStepStatus = (
    stepIdToUpdate: string,
    status: "pending" | "complete"
  ) => {
    setStepsStatuses((prev) => ({ ...prev, [stepIdToUpdate]: status }));
  };

  useEffect(() => {
    if (!stepId) {
      updateStepId(mobilizationSteps[0].id);
    }
  }, []);

  const projectTitle = data.project_connection.edges[0].node.name;
  const documentsMissing = mobilizationSteps.filter(
    (step) => step.id != "complete" && stepsStatuses[step.id] == "pending"
  ).length;
  const documentsSubmitted = mobilizationSteps.filter(
    (step) => step.id != "complete" && stepsStatuses[step.id] == "complete"
  ).length;
  const documentsOptional = 0;

  return (
    <BasicWrapper scrollable>
      <div className={`min-h-full flex flex-col gap-2`}>
        <div className={`flex w-full flex-col gap-1`}>
          <SubProjectMobilizationHeader
            projectTitle={projectTitle}
            showLoginText = {showLoginText}
          />
          <SubProjectMobilizationProgressBar
            documentMissing={documentsMissing}
            documentsOptional={documentsOptional}
            documentsSubmitted={documentsSubmitted}
          />
        </div>
        {currentStep && (
          <div className={`grid grid-cols-4 gap-2`}>
            <div className={`col-span-1`}>
              <SubProjectMobilizationSteps
                currentStepId={currentStep.id}
                onStepChange={updateStepId}
                stepStatuses={stepsStatuses}
                steps={mobilizationSteps}
              />
            </div>
            <div className={`col-span-3`}>{currentStep.component}</div>
          </div>
        )}
      </div>
    </BasicWrapper>
  );
};

export default SubProjectMobilizationView;