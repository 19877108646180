import { Descriptions } from "antd";
import React, { FC } from "react";
import { SCProjectQuery$data } from "src/common/types/generated/relay/SCProjectQuery.graphql";

const { Item } = Descriptions;

interface Props {
  project: SCProjectQuery$data["project_connection"]["edges"][0]["node"];
}

const ProjectDescriptions: FC<Props> = ({ project }) => {
  return (
    <Descriptions size="middle" title="Project Info" bordered>
      <Item label="Project Name">
        <b>{project?.name}</b>
      </Item>
      <Item label="ID">{project?.external_id}</Item>
      <Item label="General Contractor">
        {project?.general_contractors
          .map((gc) => gc.general_contractor.name)
          .join(", ")}
      </Item>
      <Item label="Workers">
        {project?.project_workers_aggregate.aggregate?.count}
      </Item>
      {/* {project?.created_at && (
        <Item label="Created On">
          {dayjs(project?.created_at).format('ll')}
        </Item>
      )} */}
      <Item label="Address">
        {project?.address.line_1}
        <br />
        {project?.address.line_2 && (
          <>
            {project?.address.line_2}
            <br />
          </>
        )}
        {project?.address.city}, {project?.address.state.name}{" "}
        {project?.address.zip_code}
      </Item>
    </Descriptions>
  );
};

export default ProjectDescriptions;
