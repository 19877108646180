/**
 * @generated SignedSource<<67a769648a499eda83c6dc68b689aefb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GCProjectReportsDownloadQuery$variables = {
  projectId: string;
};
export type GCProjectReportsDownloadQuery$data = {
  readonly email_project_user_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly pk: string;
        readonly user: {
          readonly email: string | null | undefined;
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        };
      };
    }>;
  };
  readonly project_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly morning_man_power_report_email_time: string;
        readonly project_setting: {
          readonly id: string;
          readonly send_weekly_email: boolean | null | undefined;
        } | null | undefined;
        readonly timezone: string;
      };
    }>;
  };
  readonly project_employee_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly employee: {
          readonly user: {
            readonly email: string | null | undefined;
            readonly id: string;
            readonly name: string;
            readonly pk: string;
          };
        };
      };
    }>;
  };
  readonly project_subcontractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly subcontractor: {
          readonly name: string;
        };
        readonly subcontractor_id: string;
      };
    }>;
  };
};
export type GCProjectReportsDownloadQuery = {
  response: GCProjectReportsDownloadQuery$data;
  variables: GCProjectReportsDownloadQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "projectId"
  }
],
v2 = {
  "fields": (v1/*: any*/),
  "kind": "ObjectValue",
  "name": "project_id"
},
v3 = [
  {
    "fields": [
      (v2/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "user",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/),
    (v5/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = [
  {
    "fields": (v1/*: any*/),
    "kind": "ObjectValue",
    "name": "id"
  }
],
v14 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "employee": {
        "user": {
          "name": "asc"
        }
      }
    }
  },
  {
    "fields": [
      {
        "fields": (v13/*: any*/),
        "kind": "ObjectValue",
        "name": "project"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v15 = {
  "alias": null,
  "args": [
    {
      "fields": (v13/*: any*/),
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "projectConnection",
  "kind": "LinkedField",
  "name": "project_connection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "projectEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "project",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "morning_man_power_report_email_time",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timezone",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "project_setting",
              "kind": "LinkedField",
              "name": "project_setting",
              "plural": false,
              "selections": [
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "send_weekly_email",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  },
  {
    "fields": [
      (v2/*: any*/),
      {
        "kind": "Literal",
        "name": "type",
        "value": {
          "_eq": "morning_man_power_report"
        }
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v17 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v18 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "user",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v17/*: any*/),
    (v18/*: any*/),
    (v5/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectReportsDownloadQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "email_project_user_connection",
        "args": null,
        "concreteType": "email_project_userConnection",
        "kind": "LinkedField",
        "name": "__MorningManPowerReport_email_project_user_connection_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_project_userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "email_project_user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "project_employeeConnection",
        "kind": "LinkedField",
        "name": "project_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v15/*: any*/)
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCProjectReportsDownloadQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v16/*: any*/),
        "concreteType": "email_project_userConnection",
        "kind": "LinkedField",
        "name": "email_project_user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_project_userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "email_project_user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v16/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "MorningManPowerReport_email_project_user_connection",
        "kind": "LinkedHandle",
        "name": "email_project_user_connection"
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "project_employeeConnection",
        "kind": "LinkedField",
        "name": "project_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v15/*: any*/)
    ]
  },
  "params": {
    "cacheID": "22279186b4c7a74599606c8ed66817b2",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "email_project_user_connection"
          ]
        }
      ]
    },
    "name": "GCProjectReportsDownloadQuery",
    "operationKind": "query",
    "text": "query GCProjectReportsDownloadQuery(\n  $projectId: uuid!\n) {\n  project_subcontractor_connection(where: {project_id: {_eq: $projectId}}) {\n    edges {\n      node {\n        subcontractor_id\n        subcontractor {\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n  email_project_user_connection(first: 10000, where: {project_id: {_eq: $projectId}, type: {_eq: \"morning_man_power_report\"}}) {\n    edges {\n      node {\n        id\n        pk: id\n        user {\n          id\n          pk: id\n          name\n          email\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  project_employee_connection(where: {project: {id: {_eq: $projectId}}}, order_by: {employee: {user: {name: asc}}}) {\n    edges {\n      node {\n        employee {\n          user {\n            id\n            pk: id\n            name\n            email\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n  project_connection(where: {id: {_eq: $projectId}}) {\n    edges {\n      node {\n        id\n        morning_man_power_report_email_time\n        timezone\n        project_setting {\n          id\n          send_weekly_email\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9dab31b59d247eeca50b3f5c172834b0";

export default node;
