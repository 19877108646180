import React, { FC, useState } from "react";
import GCProjectCalendarSitedeliveryDeliveries from "src/domain-features/site-delivery/entry-routes/calendar/sitedelivery/deliveries/GCProjectCalendarSitedeliveryDeliveries";
import GCProjectCalendarSitedeliverySubBlocks from "src/domain-features/site-delivery/entry-routes/calendar/sitedelivery/sub-blocks/GCProjectCalendarSitedeliverySubBlocks";

const SCProjectDeliveries: FC<{
  type: "delivery" | "sub-block";
  subcontractorId: string;
}> = ({ type, subcontractorId }) => {
  return (
    <div className="relative h-screen">
      {type == "delivery" ? (
        <GCProjectCalendarSitedeliveryDeliveries
          isUserSubAdmin={true}
          subcontractorId={subcontractorId}
          keepTimelineInView={true}
        />
      ) : (
        <GCProjectCalendarSitedeliverySubBlocks />
      )}
    </div>
  );
};

export default SCProjectDeliveries;
