export const ethnicityOptions = [
  "Asian",
  "Black/African American",
  "Cape Verdean",
  "Hispanic/Latino",
  "Native American Indian",
  "White/Caucasian/non-Hispanic",
  "Other",
];
export const veteranTypeOptions = [
  "nonVeteran",
  "veteran",
  "disabledVeteran",
] as const;

export const genderOptions = ["M", "F", "O"] as const;
type ExperienceYears =
  | "<1"
  | "2-3"
  | "3-5"
  | "5-10"
  | "10-15"
  | "15-20"
  | "20-30"
  | "30+";

export const yearsInConstOptions: Array<ExperienceYears> = [
  "<1",
  "2-3",
  "3-5",
  "5-10",
  "10-15",
  "15-20",
  "20-30",
  "30+",
];
type YearsWithEmployerTypes =
  | "New hire"
  | "< 30 days"
  | "30-60 days"
  | "1 yr"
  | "1-3 yrs"
  | "3-5 yrs"
  | "5-10 yrs"
  | "10+ yrs";
export const yearsWithEmployerOptions: Array<YearsWithEmployerTypes> = [
  "New hire",
  "< 30 days",
  "30-60 days",
  "1 yr",
  "1-3 yrs",
  "3-5 yrs",
  "5-10 yrs",
  "10+ yrs",
];
