import { graphql } from "babel-plugin-relay/macro";

const insertTaskReviewMutation = graphql`
  mutation InsertTaskReviewMutation(
    $_set: task_set_input!
    $id: uuid!
    $objects: [task_edit_insert_input!]!
    $taskReviewObjects: [task_signature_insert_input!]!
  ) {
    update_task_by_pk(_set: $_set, pk_columns: { id: $id }) {
      id
      pk: id @__clientField(handle: "pk")
      reviewed_by_uid
      reviewed_at
      flagged_at
      flagged_by_uid
      reviewed_by_user {
        name
      }
    }

    insert_task_signature(objects: $taskReviewObjects) {
      affected_rows
    }

    insert_task_edit(objects: $objects) {
      returning {
        task_patch
        edited_at
        edit_type
        edited_by_user {
          name
          role
          employee {
            general_contractor {
              name
            }
            employee_title {
              name {
                en
              }
            }
          }
          subcontractor_employee {
            subcontractor {
              name
            }
            employee_title {
              name {
                en
              }
            }
          }
          worker {
            current_worker_role
            subcontractor {
              name
            }
          }
        }
      }
    }
  }
`;

export default insertTaskReviewMutation;
