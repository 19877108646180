import { Spin } from "antd";
import { browserLocalPersistence } from "firebase/auth";
import React, { FC, useContext, useEffect, useState } from "react";
import { auth } from "src/common/functions/firebase";

type AuthData =
  | {
      uid: null;
    }
  | {
      uid: string;
      role: string;
    };

const AuthContext = React.createContext<AuthData | null>(null);

interface EmptyProps {
  children: React.ReactNode;
}

export const AuthProvider: FC<EmptyProps> = ({ children }) => {
  const [authData, setAuthData] = useState<AuthData>();
  useEffect(() => {
    console.log("subscribe on auth", auth.currentUser);
    const unsubAuth = auth.onAuthStateChanged((authUser) => {
      console.log("auth.onAuthStateChanged");
      if (!authUser) setAuthData({ uid: null });
      else {
        authUser.getIdTokenResult().then((res) => {
          if (typeof res.claims.role !== "string") {
            throw new Error("token.claims.role is not a string");
          }
          setAuthData({ uid: authUser.uid, role: res.claims.role });
        });
      }
    });
    return () => {
      unsubAuth();
    };
  }, []);

  if (!authData) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full gap-1">
        <h1>Authorizing</h1>
        <Spin />
      </div>
    );
  } else
    return (
      <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
  const user = useContext(AuthContext);
  if (!user) {
    throw new Error("useAuth requires AuthProvider");
  }
  return user;
};

export const withAuthProvider =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) =>
    (
      <AuthProvider>
        <Component {...props} />
      </AuthProvider>
    );
