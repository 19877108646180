import { Button, message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import PdfModal from "src/common/components/modals/PdfModal";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import { auth } from "src/common/functions/firebase";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { SafetyPlanTable_safetyPlan$data } from "src/common/types/generated/relay/SafetyPlanTable_safetyPlan.graphql";
import { SubProjectMobilizationStepSssp_CompanySafetyPlanQuery } from "src/common/types/generated/relay/SubProjectMobilizationStepSssp_CompanySafetyPlanQuery.graphql";
import {
  safety_plan_insert_input,
  SubProjectMobilizationStepSssp_InsertSafetyPlanMutation,
} from "src/common/types/generated/relay/SubProjectMobilizationStepSssp_InsertSafetyPlanMutation.graphql";
import {
  SubProjectMobilizationStepSssp_UpdateSafetyPlanMutation,
  SubProjectMobilizationStepSssp_UpdateSafetyPlanMutation$data,
} from "src/common/types/generated/relay/SubProjectMobilizationStepSssp_UpdateSafetyPlanMutation.graphql";
import AddSafetyPlanFromCompanyLibraryModal from "src/domain-features/sitesafety/siteSafetyPlan/components/AddSafetyPlanFromCompanyLibraryModal";
import SafetyPlanTable, {
  safetyPlan,
} from "src/domain-features/sitesafety/siteSafetyPlan/components/SafetyPlanTable";
import UploadSafetyPlanModal from "src/domain-features/sitesafety/siteSafetyPlan/components/UploadSafetyPlanModal";
import * as uuid from "uuid";

export interface SubProjectMobilizationStepSsspProps {
  projectId: string;
  subcontractorId: string;
  onPrev: () => void;
  onNext: (isUploaded: boolean) => void;
}

const SubProjectMobilizationStepSssp: React.FC<
  SubProjectMobilizationStepSsspProps
> = (props) => {
  const query = graphql`
    query SubProjectMobilizationStepSssp_CompanySafetyPlanQuery($subId: uuid!) {
      safety_plan_connection(
        first: 10000
        where: {
          subcontractor_id: { _eq: $subId }
          deleted_at: { _is_null: true }
        }
        order_by: { title: asc }
      )
        @connection(
          key: "SubProjectMobilizationStepSssp_CompanySafetyPlanQuery_safety_plan_connection"
          filters: []
        ) {
        edges {
          node {
            ...SafetyPlanFrag @relay(mask: false)
          }
        }
      }
    }
  `;

  const [insertSafetyPlan, loading] =
    useAsyncMutation<SubProjectMobilizationStepSssp_InsertSafetyPlanMutation>(
      graphql`
        mutation SubProjectMobilizationStepSssp_InsertSafetyPlanMutation(
          $object: safety_plan_insert_input!
        ) {
          insert_safety_plan_one(object: $object) {
            ...SafetyPlanFrag @relay(mask: false)
          }
        }
      `,
    );

  const [updateSafetyPlan, updating] =
    useAsyncMutation<SubProjectMobilizationStepSssp_UpdateSafetyPlanMutation>(
      graphql`
        mutation SubProjectMobilizationStepSssp_UpdateSafetyPlanMutation(
          $_set: safety_plan_set_input!
          $id: uuid!
        ) {
          update_safety_plan_by_pk(pk_columns: { id: $id }, _set: $_set) {
            id
            deleted_at
            company_safety_plan_id
          }
        }
      `,
    );
  const [openSiteFormUploadModal, setOpenSiteFormUploadModal] = useState(false);
  const projectId = props.projectId;
  const subcontractorId = props.subcontractorId;
  const safetyPlanTableRef = useRef<DataScrollTableRef>(null);
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfModal, setPdfModal] = useState(false);

  const [selectedSafetyPlan, setSelectedSafetyPlan] = useState<
    safetyPlan | undefined
  >();
  const deleteUpdater: (
    store: RecordSourceSelectorProxy<SubProjectMobilizationStepSssp_UpdateSafetyPlanMutation$data>,
    id: string,
  ) => void = (store, id) => {
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "SafetyPlanTable_safety_plan_connection",
    );
    if (conn) {
      const edges = (conn.getLinkedRecords("edges") || []).filter(
        (r) => r.getLinkedRecord("node")?.getValue("id") != id,
      );
      conn.setLinkedRecords(edges, "edges");
    }
  };
  const [
    addSafetyPlanFromCompanyLibraryModal,
    setAddSafetyPlanFromCompanyLibraryModal,
  ] = useState(false);
  const onDelete = async (
    safetyPlan: SafetyPlanTable_safetyPlan$data["safety_plan_connection"]["edges"][number]["node"],
  ) => {
    await updateSafetyPlan({
      variables: {
        _set: { deleted_at: dayjs().toISOString() },
        id: safetyPlan.pk,
      },
      updater: (store) => {
        deleteUpdater(store, safetyPlan.id);
      },
    })
      .then(async (d) => {
        message.success("Safety Plan Deleted");
      })
      .catch((e) => console.log(e));
  };
  const safetyPlanData =
    useLazyLoadQuery<SubProjectMobilizationStepSssp_CompanySafetyPlanQuery>(
      query,
      {
        subId: subcontractorId,
      },
    ).safety_plan_connection.edges.map((item) => item.node);

  const companySafetyPlanData = safetyPlanData.filter((safetyPlan) => {
    if (safetyPlan.project_id != null) {
      return false;
    }
    const matchingSafetyPlan = safetyPlanData.find(
      (sp) =>
        sp.project_id &&
        sp.project_id == projectId &&
        sp.company_safety_plan_id == safetyPlan.pk,
    );
    return !matchingSafetyPlan;
  });
  const addToCompany = async (safetyPlan: safetyPlan) => {
    if (!safetyPlan) {
      return;
    }
    const companyId = uuid.v4();
    const safetyPlanInsertInput: safety_plan_insert_input = {
      created_by_uid: auth.currentUser?.uid,
      project_id: null,
      subcontractor_id: subcontractorId,
      title: safetyPlan.title,
      url: safetyPlan.url,
      id: companyId,
      revision_date: safetyPlan.revision_date,
      revision_number: safetyPlan.revision_number,
    };
    await insertSafetyPlan({
      variables: {
        object: { ...safetyPlanInsertInput },
      },
    });
    await updateSafetyPlan({
      variables: {
        _set: { company_safety_plan_id: companyId },
        id: safetyPlan.pk,
      },
      optimisticResponse: {
        update_safety_plan_by_pk: {
          id: safetyPlan.id,
          company_safety_plan_id: companyId,
          deleted_at: null,
        },
      },
    })
      .then(async (d) => {
        message.success("Added to Company Library");
      })
      .catch((e) => console.log(e));
  };

  return (
    <div>
      <UploadSafetyPlanModal
        visible={openSiteFormUploadModal}
        subcontractorId={subcontractorId}
        projectId={projectId}
        onClose={() => setOpenSiteFormUploadModal(false)}
        onSubmit={() => {
          setOpenSiteFormUploadModal(false);
          safetyPlanTableRef.current?.refetch();
          props.onNext(true);
        }}
      />
      <div className="mt-1">
        <Button
          onClick={() => {
            props.onPrev();
          }}
        >
          Back
        </Button>
        <Button
          className="ml-1"
          onClick={() => {
            props.onNext(false);
          }}
        >
          Next
        </Button>
      </div>
      <PdfModal
        visible={pdfModal}
        onClose={() => {
          setPdfModal(false);
        }}
        pdfUrl={pdfUrl}
      />
      <AddSafetyPlanFromCompanyLibraryModal
        {...{
          projectId: projectId,
          visible: addSafetyPlanFromCompanyLibraryModal,
          data: companySafetyPlanData,
          closeModal: () => setAddSafetyPlanFromCompanyLibraryModal(false),
          subcontractorId,
          refresh: () => {
            safetyPlanTableRef.current?.refetch();
          },
        }}
      />
      <SafetyPlanTable
        title="Company Safety Manual"
        ref={safetyPlanTableRef}
        where={{
          subcontractor_id: { _eq: subcontractorId },
          project_id: { _eq: projectId },
          deleted_at: { _is_null: true },
        }}
        onSafetyPlanRowClick={(safetyPlan) => {
          setPdfUrl(safetyPlan.url);
          setPdfModal(true);
        }}
        loading={loading || updating}
        topBarButtons={[
          {
            onClick: () => {
              setSelectedSafetyPlan(undefined);
              setOpenSiteFormUploadModal(true);
            },
            label: "Upload New",
          },
          {
            onClick: () => {
              setAddSafetyPlanFromCompanyLibraryModal(true);
            },
            label: "Add from Library",
          },
        ]}
      />
    </div>
  );
};

export default SubProjectMobilizationStepSssp;
