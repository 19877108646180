/**
 * @generated SignedSource<<f58363d652cdd9dd579a7de074e0860c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SiteOrientationCertificateUploadQuery$variables = {
  projectId: string;
  workerId: string;
};
export type SiteOrientationCertificateUploadQuery$data = {
  readonly project_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly project_certifications: ReadonlyArray<{
          readonly alternate_certifications: ReadonlyArray<{
            readonly alternate_certification_id: string;
          }>;
          readonly certification: {
            readonly name: string;
            readonly pk: string;
          };
          readonly id: string;
        }>;
        readonly require_certs: boolean;
      };
    }>;
  };
  readonly user_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
      };
    }>;
  };
  readonly worker_certification_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly certification: {
          readonly name: string;
          readonly pk: string;
        };
      };
    }>;
  };
};
export type SiteOrientationCertificateUploadQuery = {
  response: SiteOrientationCertificateUploadQuery$data;
  variables: SiteOrientationCertificateUploadQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workerId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "require_certs",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "certification",
  "kind": "LinkedField",
  "name": "certification",
  "plural": false,
  "selections": [
    {
      "alias": "pk",
      "args": null,
      "kind": "ScalarField",
      "name": "__id_pk",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "alternate_certification_id",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "workerId"
  }
],
v8 = [
  {
    "fields": [
      {
        "fields": (v7/*: any*/),
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v9 = [
  {
    "fields": [
      {
        "fields": (v7/*: any*/),
        "kind": "ObjectValue",
        "name": "worker_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "certification",
  "kind": "LinkedField",
  "name": "certification",
  "plural": false,
  "selections": [
    {
      "alias": "pk",
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "pk",
      "args": null,
      "filters": null,
      "handle": "pk",
      "key": "",
      "kind": "ScalarHandle",
      "name": "id"
    },
    (v4/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteOrientationCertificateUploadQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project_certification",
                    "kind": "LinkedField",
                    "name": "project_certifications",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_alternate_certification",
                        "kind": "LinkedField",
                        "name": "alternate_certifications",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "worker_certificationConnection",
        "kind": "LinkedField",
        "name": "worker_certification_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_certificationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "worker_certification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteOrientationCertificateUploadQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project_certification",
                    "kind": "LinkedField",
                    "name": "project_certifications",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_alternate_certification",
                        "kind": "LinkedField",
                        "name": "alternate_certifications",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "worker_certificationConnection",
        "kind": "LinkedField",
        "name": "worker_certification_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_certificationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "worker_certification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f0aadce78274906a088e589ba9fe063",
    "id": null,
    "metadata": {},
    "name": "SiteOrientationCertificateUploadQuery",
    "operationKind": "query",
    "text": "query SiteOrientationCertificateUploadQuery(\n  $projectId: uuid!\n  $workerId: uuid!\n) {\n  project_connection(where: {id: {_eq: $projectId}}, first: 10000) {\n    edges {\n      node {\n        require_certs\n        project_certifications {\n          id\n          certification {\n            pk: id\n            name\n            id\n          }\n          alternate_certifications {\n            alternate_certification_id\n            id\n          }\n        }\n        id\n      }\n    }\n  }\n  user_connection(where: {id: {_eq: $workerId}}) {\n    edges {\n      node {\n        name\n        id\n      }\n    }\n  }\n  worker_certification_connection(where: {worker_id: {_eq: $workerId}}) {\n    edges {\n      node {\n        certification {\n          pk: id\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9e0f874c48c29f6d3d3a88a53bdf252e";

export default node;
