import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';

export type BaseModalRef = {
  open: () => void;
  close: () => void;
};

const BaseModal = forwardRef<BaseModalRef, ModalProps & { children: any }>(
  (props, ref) => {
    const [visible, setVisible] = useState(false);

    useImperativeHandle<BaseModalRef, BaseModalRef>(ref, () => ({
      open: () => setVisible(true),
      close: () => setVisible(false),
    }));

    return (<Modal open={visible} cancelText="Cancel" okText="Done" {...props} />);
  },
);

export default BaseModal;
