import React from "react";
import ButtonHuge from "../../../../common/components/general/ButtonHuge";

export interface SiteOrientationSlidesViewerNextButtonProps {
  disabled?: boolean;
  text?: string;
  onClick: () => void;
}

const SiteOrientationSlidesViewerNextButton: React.FC<
  SiteOrientationSlidesViewerNextButtonProps
> = (props) => {
  return (
    <ButtonHuge
      type={`primary`}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.text}
    </ButtonHuge>
  );
};

export default SiteOrientationSlidesViewerNextButton;
