import React from "react";
import SiteOrientationInPersonWorkerRegistrationStepsCompletedHandAnimation, {
  SiteOrientationInPersonWorkerRegistrationStepsCompletedHandAnimationProps,
} from "./SiteOrientationInPersonWorkerRegistrationStepsCompletedHandAnimation";
import { motion } from "framer-motion";
import clsx from "clsx"; // .entry-title{
import { Language } from "../../../../../../../../../utility-features/i18n/language-utils/i18nTypes";
import useLangStrings from "../../../../../../../../../utility-features/i18n/context/languageHooks";

// .entry-title{
//   background: linear-gradient(to right, red 50%, rgba(22, 160, 133,1.0) 50%);
//   background-size: 200%;
//   background-position: 0 0;

//   	.entry-title{
// 			background-position: -100% 0;
// 		}

export interface SiteOrientationInPersonWorkerRegistrationStepsCompletedUIProps {
  workerCanLeaveWhenPrompted: boolean;
  onAnimationFinished?: () => void;
  hasUniversal?: boolean;
  language: Language;
  managerDevice?: boolean;
}

type ContentVarsType = {
  handType: SiteOrientationInPersonWorkerRegistrationStepsCompletedHandAnimationProps["handType"];
  title: React.ReactNode;
  description: React.ReactNode;
  semantic: "positive" | "negative";
};

const SiteOrientationInPersonWorkerRegistrationStepsCompletedUI: React.FC<
  SiteOrientationInPersonWorkerRegistrationStepsCompletedUIProps
> = (props) => {
  const langStrings = useLangStrings(props.language);
  const CONTENT_WORKER_STAYS: ContentVarsType = {
    handType: "waving",
    title: langStrings.strings.stayForEntireOrientation,
    description:
      langStrings.strings.youHaveNeverCompletedThisOrientationOrExpired,
    semantic: "negative",
  };

  const CONTENT_WORKER_LEAVES: (hasUniversal?: boolean) => ContentVarsType = (
    hasUniversal
  ) => ({
    handType: "thumb-up",
    title: langStrings.strings.youCanLeaveWhenPrompted,
    description:
      langStrings.strings.youHaveCompletedTheGivenOrientationInPast(
        hasUniversal
      ),
    semantic: "positive",
  });

  const contentVars: ContentVarsType = props.workerCanLeaveWhenPrompted
    ? CONTENT_WORKER_LEAVES(props.hasUniversal)
    : CONTENT_WORKER_STAYS;
  return (
    <div className={`min-h-full justify-center`}>
      <div className={`flex flex-col gap-0.5`}>
        <div className={`flex flex-col gap-0.5`}>
          <SiteOrientationInPersonWorkerRegistrationStepsCompletedHandAnimation
            handType={contentVars.handType}
          />
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              delay: 1.2,
              duration: 0.6,
            }}
            className={`flex flex-col gap-0.5`}
          >
            <div>
              <p className={`text-2`}>{langStrings.strings.thankYou}!</p>
              <motion.p
                className={clsx(
                  `text-2 bg-gradient-to-r from-transparent inline`,
                  contentVars.semantic === "positive"
                    ? "to-semantic-positive-light"
                    : "to-semantic-negative-light"
                )}
                style={{
                  backgroundSize: `200%`,
                  // @ts-ignore
                  "--tw-gradient-stops":
                    "var(--tw-gradient-from) 50%, currentcolor, var(--tw-gradient-to) 50%",
                }}
                initial={{
                  backgroundPosition: `0 0`,
                }}
                animate={{
                  backgroundPosition: `-100% 0`,
                }}
                transition={{ delay: 1.8, duration: 0.6 }}
                onAnimationComplete={props.onAnimationFinished}
              >
                {contentVars.title}
              </motion.p>
            </div>
            <p>{contentVars.description}</p>
            {props.managerDevice ? (
              <p>{langStrings.strings.pressDoneAndPassDeviceToNeighbor}</p>
            ) : (
              <></>
            )}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SiteOrientationInPersonWorkerRegistrationStepsCompletedUI;
