import React, { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import BaseUserLayout from "src/common/components/layouts/BaseUserLayout";

const SFEmployee: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <BaseUserLayout
      headerMenuItems={[
        {
          path: "/sfe/verify",
          title: "Tasks",
        },
        {
          path: "/sfe/profile",
          title: "Your Profile",
        },
      ]}
    >
      <Outlet />
    </BaseUserLayout>
  );
};

export default SFEmployee;
