import {
  BDTranslationRecord,
  DBLanguage,
  Language,
} from "../language-utils/i18nTypes";

import { useContext, useMemo } from "react";
import { LanguageContext } from "./languageContext";
import { getLangStrings } from "../language-utils/getLangStrings";

// useCurrentLanguageState returns a tuple of [language, setLanguage]
// use it to get and set current language
export const useCurrentLanguageState = () => {
  const langState = useContext(LanguageContext);
  if (!langState) {
    throw new Error("useLanguage must be used inside LanguageProvider");
  }
  return langState;
};

// useCurrentLanguage returns current language. E.g. "en", "es", etc.
export const useCurrentLanguage = () => {
  const [language] = useCurrentLanguageState();
  return language;
};

// useLangStrings returns strings translated to the given language.
const useLangStrings = (lang?: string) => {
  return useMemo(() => getLangStrings(lang), [lang]);
};

// useCurrentLangStrings returns strings translated to current language.
export const useCurrentLangStrings = () => {
  const lang = useCurrentLanguage();
  return useLangStrings(lang);
};

// *** Database translation tools ***
const languageToDbLanguage = (lang: Language): DBLanguage => {
  return lang === "es" || lang === "pt" ? lang : "en";
};

const getDBLangSelector =
  (lang: DBLanguage) =>
  (translations?: BDTranslationRecord | null, defValue?: string | null) =>
    translations?.[lang] ?? defValue ?? "";
export const useDBLangSelector = (lang: Language) => {
  const dbLang = languageToDbLanguage(lang);
  return getDBLangSelector(dbLang);
};
export const useCurrentDBLangSelector = () => {
  const lang = useCurrentLanguage();
  return useDBLangSelector(lang);
};
export default useLangStrings;
