import React from "react";
import NavigationWrapper, {
  NavigationMenuProps,
} from "src/common/components/layouts/NavigationWrapper";
import { Outlet, useNavigate } from "react-router-dom";

interface SCReportsNavigationProps {}

const SCReportsNavigation: React.FunctionComponent<
  SCReportsNavigationProps
> = () => {
  const navigate = useNavigate();
  const menu: NavigationMenuProps = {
    navigationItems: [
      {
        rootPath: "ptp",
        label: "Pre-Task Plans",
        onClick: () => {
          navigate("ptp");
        },
      },
      {
        rootPath: "toolbox-talks",
        label: "Toolbox talks",
        onClick: () => {
          navigate("toolbox-talks");
        },
      },
      {
        rootPath: "daily",
        label: "Daily reports",
        onClick: () => {
          navigate("daily");
        },
      },
    ],
  };
  return (
    <NavigationWrapper {...{ menu }}>
      <Outlet />
    </NavigationWrapper>
  );
};

export default SCReportsNavigation;
