import {
  IconCopy,
  IconDeviceMobileMessage,
  IconDownload,
  IconLockOpen,
  IconRefresh,
} from "@tabler/icons";
import { Divider, message, Modal, Switch } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import capitalize from "src/common/functions/capitalize";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import { auth } from "src/common/functions/firebase";
import getConnectionNodes from "src/common/functions/getConnectionNodes";
import getDateStr from "src/common/functions/getDateStr";
import {
  useForgotPasswordMutation,
  useGetWorkerTranscriptPdfMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { ProjectWorkerDescriptionQuery } from "src/common/types/generated/relay/ProjectWorkerDescriptionQuery.graphql";
import { WorkerProfileUpsertImageMutation } from "src/common/types/generated/relay/WorkerProfileUpsertImageMutation.graphql";
import { upsertProfileImageMutation } from "src/root/routes/views/subcontractor/people/workers/worker-profile/WorkerProfile";
import { useBoolean, useCopyToClipboard } from "usehooks-ts";
import * as uuid from "uuid";
import BusinessCard from "../BusinessCard";
import Button from "../general/Button";
import Icon from "../general/Icon";
import withCustomSuspense from "../general/withCustomSuspense";
import EditWorkerForm from "./EditWorkerForm";
import InviteWorkerModal from "./InviteWorkerModal";
import workerAndUserUpdateMutation from "src/common/api/relay/mutations/UpdateWorkerAndUser";
import { UpdateWorkerAndUserMutation } from "src/common/types/generated/relay/UpdateWorkerAndUserMutation.graphql";
import getOrientationCompleted from "src/domain-features/siteorientation/entryRoutes/gcDashboard/utils/getOrientationCompleted";

interface Props {
  projectWorkerId: string;
  allowChangeCompany?: boolean;
  projectId: string;
  workerId: string;
}

const ProjectWorkerDescription: React.FC<Props> = ({
  projectWorkerId,
  allowChangeCompany = false,
  projectId,
  workerId,
}) => {
  const [copiedValue, copy] = useCopyToClipboard();
  const {
    value: editing,
    setTrue: endableEditing,
    setFalse: disableEditing,
  } = useBoolean();
  const {
    value: inviteModalVisible,
    setFalse: hideInviteModal,
    setTrue: showInviteModal,
  } = useBoolean(false);
  const [fetchKey, setFetchKey] = useState(0);
  const data = useLazyLoadQuery<ProjectWorkerDescriptionQuery>(
    graphql`
      query ProjectWorkerDescriptionQuery(
        $projectWorkerId: uuid!
        $includeProjectSubs: Boolean!
        $projectId: uuid!
        $workerId: uuid!
      ) {
        worker_title_connection(order_by: { translation: { en: asc } }) {
          edges {
            node {
              ...WorkerTitleFrag @relay(mask: false)
            }
          }
        }
        project_connection(where: { id: { _eq: $projectId } }) {
          edges {
            node {
              in_person_orientation
              assign_hard_hat
            }
          }
        }
        orientation_connection(
          where: {
            deleted_at: { _is_null: true }
            project_orientations: {
              required_by_all_workers: { _eq: true }
              project: {
                linked_orientation_projects: { id: { _eq: $projectId } }
              }
            }
            general_contractor: { projects: { id: { _eq: $projectId } } }
            _or: [
              { project_id: { _is_null: true } }
              {
                project: {
                  linked_orientation_projects: { id: { _eq: $projectId } }
                }
              }
            ]
          }
          order_by: [{ order: asc }, { name: asc }]
        ) {
          edges {
            node {
              id
            }
          }
        }
        project_worker_connection(where: { id: { _eq: $projectWorkerId } }) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              worker_role
              subcontractor_id
              hard_hat_number
              user_orientation {
                in_person_orientated_at
                completed_at
                orientated_at
              }
              worker {
                subcontractor_id
                subcontractor {
                  name
                }
              }
              user {
                id
                pk: id @__clientField(handle: "pk")
                name
                created_password
                username
                email
                phone_number
                birth_date
                profile_picture_id
                allow_mobile_login
                phone_number_privacy_setting {
                  manager
                }
                email_privacy_setting {
                  manager
                }
                profile_picture {
                  url
                }
                invites(
                  where: { claiming_account: { _eq: true } }
                  order_by: { created_at: desc }
                  limit: 1
                ) {
                  pk: id @__clientField(handle: "pk")
                  to_email
                  to_phone_number
                  created_at
                  accepted_at
                }
              }
              subcontractor {
                id
                pk: id @__clientField(handle: "pk")
                name
              }
              title {
                ...WorkerTitleFrag @relay(mask: false)
              }
            }
          }
        }
        subcontractor_connection(
          where: {
            subcontractor_projects: {
              project: { project_workers: { id: { _eq: $projectWorkerId } } }
            }
          }
          order_by: { name: asc }
        ) @include(if: $includeProjectSubs) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
          }
        }
        pw_con: project_worker_connection(
          where: {
            project_id: { _eq: $projectId }
            worker_id: { _eq: $workerId }
          }
        ) {
          edges {
            node {
              project_id
              subcontractor_id
              hard_hat_number
              worker_id
              worker {
                current_worker_role
                user {
                  name
                  role
                  lang
                }
              }
            }
          }
        }
        user_connection(where: { id: { _eq: $workerId } }) {
          edges {
            node {
              name
              email
              role
              lang
              phone_number
              worker {
                subcontractor_id
                current_worker_role
                worker_title_id
                subcontractor_workers(
                  order_by: { created_at: desc }
                  limit: 1
                ) {
                  subcontractor_id
                }
              }
            }
          }
        }
        worker_previous_subcontractor_connection(
          where: { worker_id: { _eq: $workerId } }
        ) {
          edges {
            node {
              subcontractor_id
            }
          }
        }
      }
    `,
    {
      projectWorkerId,
      includeProjectSubs: !!allowChangeCompany,
      projectId,
      workerId,
    },
    { fetchKey, fetchPolicy: "network-only" },
  );
  const [upsertProfileImageUrl, isUpsertingImage] =
    useAsyncMutation<WorkerProfileUpsertImageMutation>(
      upsertProfileImageMutation,
    );
  const [forgotPassword, { loading }] = useForgotPasswordMutation();
  const [profileImageLoading, setProfileImageLoading] = useState(false);
  const [getWorkerTranscriptPdf, { loading: workerTranscriptPdfLoading }] =
    useGetWorkerTranscriptPdfMutation();

  const [workerUpdate, isUpdatingWorker] =
    useAsyncMutation<UpdateWorkerAndUserMutation>(workerAndUserUpdateMutation);
  const sendResetPasswordLink = async () => {
    if (pw.user?.email) {
      await forgotPassword({
        variables: {
          input: {
            loginIdentifier: pw.user.email,
          },
        },
      })
        .then(() => message.success("Sent Successfully"))
        .catch(console.log);
    } else {
      message.error(
        "Email not found, Please enter worker's email to send him the Password Reset Link",
      );
    }
  };
  const pw = getConnectionNodes(data.project_worker_connection)[0]!;
  const allowHHEdit =
    data.project_connection.edges[0].node.assign_hard_hat &&
    getOrientationCompleted(
      pw.user_orientation,
      data.orientation_connection.edges.length > 0,
      data.project_connection.edges[0].node.in_person_orientation,
    );
  const { confirm } = Modal;

  const showResetPasswordConfirm = () => {
    confirm({
      title: "Send Reset Password Link?",
      okText: "Yes",
      onOk: sendResetPasswordLink,
    });
  };

  if (!pw.user) throw new Error("User Not Found, please go back and try again");
  const [lastInvitePk, setLastInvitePK] = useState(pw.user.invites[0]?.pk);
  const [invitationState, setInviteState] = useState<
    "not invited" | "sent" | "accepted"
  >(
    pw.user.created_password
      ? "accepted"
      : pw.user.invites.length === 0
      ? "not invited"
      : pw.user.invites.length > 0 && !pw.user.invites[0]?.accepted_at
      ? "sent"
      : "accepted",
  );

    return (
      <>
        {workerId && (
          <InviteWorkerModal
            key={workerId}
            visible={inviteModalVisible}
            onFinish={(inviteId) => {
              hideInviteModal();
              setInviteState("sent");
              setLastInvitePK(inviteId);
            }}
            onClose={hideInviteModal}
            workerId={workerId}
            projectId={projectId}
            email={pw.user.email ?? undefined}
            phone={pw.user.phone_number ?? undefined}
          />

        )}
        {editing ? (
          <div className="flex flex-row justify-center">
            <EditWorkerForm
              {...{
                projectId,
                projectWorkerId,
                allowChangeCompany,
                workerId,
                data,
                allowHHEdit:
                  allowHHEdit ||
                  !(
                    data.orientation_connection.edges.length > 0 ||
                    data.project_connection.edges[0].node.in_person_orientation
                  ),
                onCancel: disableEditing,
                onSubmit: () => {
                  disableEditing();
                  setFetchKey((i) => i + 1);
                },
              }}
            />
          </div>
        ) : (
          <>
            {pw.user ? (
              <div className="flex flex-col gap-1 items-center">
                <div className="flex justify-center">
                  <BusinessCard
                    fullName={pw.user.name}
                    hardHatNumber={pw.hard_hat_number}
                    company={pw.subcontractor.name}
                    email={
                      pw.user.email_privacy_setting?.manager
                        ? pw.user.email || undefined
                        : undefined
                    }
                    phoneNumber={
                      pw.user.phone_number_privacy_setting?.manager
                        ? pw.user.phone_number || undefined
                        : undefined
                    }
                    position={`${capitalize(pw.worker_role)}, ${
                      pw.title?.translation.clientText
                    }`}
                    userName={pw.user.username || undefined}
                    bio={
                      pw.user.birth_date
                        ? `Birth Date: ${getDateStr(dayjs(pw.user.birth_date))}`
                        : undefined
                    }
                    avatar={{
                      loading: profileImageLoading,
                      preview: true,
                      imageUrl: pw.user.profile_picture?.url,
                      onFileUpload: async (imageUrl) => {
                        if (!pw.user) return;
                        const id = pw.user.profile_picture_id ?? uuid.v4();
                        setProfileImageLoading(true);
                        await upsertProfileImageUrl({
                          variables: {
                            object: {
                              url: imageUrl,
                              description: "Profile Picture",
                              created_by_user_id: auth.currentUser?.uid,
                              id: id,
                            },

                          },
                        }).catch((e) => {
                          message.error("Couldn't insert Image");
                          console.log(e);
                        });
                        await workerUpdate({
                          variables: {
                            workerId: pw.user.pk,
                            worker_set: {},
                            user_set: { profile_picture_id: id },
                          },
                        }).catch((e) => {
                          console.log(e);
                          message.error("Couldn't update Profile Picture");
                        });
                        setProfileImageLoading(false);

                      setFetchKey((i) => i + 1);
                    },
                    onFileRemove: async () => {
                      pw.user &&
                        (await workerUpdate({
                          variables: {
                            workerId: pw.user.pk,
                            worker_set: {},
                            user_set: { profile_picture_id: null },
                          },
                        }));
                      setFetchKey((i) => i + 1);
                    },
                  }}
                />
              </div>
              <div className="flex flex-row gap-1 items-center">
                {/* <Separator /> */}
                <div className="flex flex-row items-center gap-1">
                  <Button
                    label={`Worker Transcript`}
                    icon={IconDownload}
                    loading={workerTranscriptPdfLoading}
                    onClick={async () => {
                      if (workerId && projectId) {
                        await getWorkerTranscriptPdf({
                          variables: {
                            input: {
                              projectId: projectId,
                              workerId: workerId,
                            },
                          },
                        }).then((data) => {
                          if (data.data?.generateWorkerTranscript) {
                            downloadFromUrl(
                              data.data?.generateWorkerTranscript,
                            );
                          }
                        });
                      }
                    }}
                  />
                  <Button label={`Edit`} onClick={endableEditing} />
                  <Button
                    icon={IconLockOpen}
                    onClick={showResetPasswordConfirm}
                    hint={`Send Reset Password Link`}
                  />
                </div>
                <Divider type={`vertical`} className={`bg-suplementary-3`} />
                <div className="flex flex-row items-center gap-1">
                  {(() => {
                    switch (invitationState) {
                      case "not invited":
                        return (
                          <Button label={`Invite`} onClick={showInviteModal} />
                        );
                      case "sent":
                        return (
                          <div className="flex flex-row items-center gap-1">
                            <div>Invite Link Sent</div>
                            {lastInvitePk && (
                              <Button
                                icon={IconCopy}
                                onClick={() => {
                                  copy(
                                    `https://app.siteform.net/invite/${lastInvitePk}`,
                                  );
                                }}
                                hint={"Copy Invite Link"}
                              />
                            )}
                            <Button
                              icon={IconRefresh}
                              onClick={showInviteModal}
                              hint={`Resend Invite Link`}
                            />
                          </div>
                        );
                      case "accepted":
                        return (
                          <div className="flex flex-row items-center gap-0.5 select-none rounded-full bg-suplementary-1 py-0.5 px-0.75">
                            <Icon icon={IconDeviceMobileMessage} />
                            <p>Invitation accepted</p>
                          </div>
                        );
                    }
                  })()}
                </div>
              </div>
              {pw.user.created_password && (
                <div className="flex flex-row gap-1 items-center">
                  Allow Worker to login to mobile-app:
                  <Switch
                    defaultChecked={pw.user.allow_mobile_login}
                    onChange={(checked) =>
                      pw.user &&
                      workerUpdate({
                        variables: {
                          user_set: { allow_mobile_login: checked },
                          worker_set: {},
                          workerId: pw.user.pk,
                        },
                      })
                    }
                  />
                </div>
              )}
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default withCustomSuspense(ProjectWorkerDescription);
