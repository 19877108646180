import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import GetFullID from "src/common/functions/GetFullId";
import {
  useUpdateProjectSettingsMutation,
  project_set_input,
} from "src/common/types/generated/relay/useUpdateProjectSettingsMutation.graphql";

export const updateSettingsMutation = graphql`
  mutation useUpdateProjectSettingsMutation(
    $_set: project_set_input
    $projectId: uuid!
  ) {
    update_project(
      where: {
        orientation_project_id: { _eq: $projectId }
        id: { _neq: $projectId }
      }
      _set: $_set
    ) {
      affected_rows
    }
    update_project_by_pk(_set: $_set, pk_columns: { id: $projectId }) {
      union_labor
      ask_age
      ask_ethnicity
      ask_veteran_type
      ask_years_with_employer
      ask_number_of_years_in_construction
      require_phone
      require_dob
      ask_gender
      ask_residence
      assign_hard_hat
      ask_emergency_contact
      orientation_tv_player
      in_person_orientation
      automatically_assign_hard_hat
      add_hard_hat_during_in_person
      worker_scan_in_person_qr_code
      next_hard_hat_number
      require_certification_upload
      perform_drugtest
      required_quiz_percentage
      drug_test_retest_days
      drugtest_validity_days
      require_profile_photo
      background_check_tracker
      show_onsite_for_not_oriented_workers
      send_email_for_drug_test_worker_arrival
      add_hard_hat_during_registration
      send_verification_invite
      require_certs
      require_drugtest_upload # we'll just query it but not update it anytime
    }
  }
`;

export type useUpdateProjectSettingsMutationReturningValues = NonNullable<
  useUpdateProjectSettingsMutation["response"]["update_project_by_pk"]
>;
const useUpdateProjectSettings = ({
  projectId,
  returningFieldsVal,
}: {
  projectId: string;
  returningFieldsVal: useUpdateProjectSettingsMutationReturningValues;
}) => {
  const [updateProject, isUpdating] =
    useAsyncMutation<useUpdateProjectSettingsMutation>(updateSettingsMutation);

  const update = async (_set: project_set_input) => {
    await updateProject({
      variables: {
        projectId,
        _set,
      },
      optimisticResponse: {
        update_project: {
          affected_rows: 1,
          //just a sample number as we do not need it to be accurate
        },
        update_project_by_pk: {
          ...returningFieldsVal,
          id: GetFullID("project", projectId),
          ..._set,
        },
      },
    });
  };
  return [update, isUpdating] as const;
};
export default useUpdateProjectSettings;
