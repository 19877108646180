export type Connection<N> = {
  readonly edges: ReadonlyArray<{
    readonly node: N;
  }>;
};
export type GetConnectionNode<
  T extends Connection<N>,
  N = any
> = T['edges'][0]['node'];
const getConnectionNodes = <N,>(connection: Connection<N>) => {
  return connection.edges.map((e) => e.node);
};

export default getConnectionNodes;
