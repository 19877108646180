/**
 * @generated SignedSource<<4ec15a3794d69af950036bf459fe79e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type add_subcontractor_request_set_input = {
  created_at?: string | null | undefined;
  id?: string | null | undefined;
  poc_email_address?: string | null | undefined;
  poc_name?: string | null | undefined;
  poc_phone_number?: string | null | undefined;
  poc_title_id?: string | null | undefined;
  project_id?: string | null | undefined;
  request_by_uid?: string | null | undefined;
  requested_trade_id?: string | null | undefined;
  subcontractor_business_name?: string | null | undefined;
  subcontractor_ein?: string | null | undefined;
  subcontractor_id?: string | null | undefined;
  verified_at?: string | null | undefined;
  verified_by_uid?: string | null | undefined;
};
export type VerifySubcontractor_UpdateAndInsertMutation$variables = {
  _set?: add_subcontractor_request_set_input | null | undefined;
  id: string;
  project_id: string;
  subcontractor_id: string;
};
export type VerifySubcontractor_UpdateAndInsertMutation$data = {
  readonly insert_project_subcontractor_one: {
    readonly id: string;
  } | null | undefined;
  readonly update_add_subcontractor_request_by_pk: {
    readonly id: string;
  } | null | undefined;
};
export type VerifySubcontractor_UpdateAndInsertMutation = {
  response: VerifySubcontractor_UpdateAndInsertMutation$data;
  variables: VerifySubcontractor_UpdateAndInsertMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "project_id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subcontractor_id"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "add_subcontractor_request",
    "kind": "LinkedField",
    "name": "update_add_subcontractor_request_by_pk",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "project_id",
            "variableName": "project_id"
          },
          {
            "kind": "Variable",
            "name": "subcontractor_id",
            "variableName": "subcontractor_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "object"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "project_subcontractor_subcontractor_id_project_id_key",
          "update_columns": ([]/*: any*/)
        }
      }
    ],
    "concreteType": "project_subcontractor",
    "kind": "LinkedField",
    "name": "insert_project_subcontractor_one",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifySubcontractor_UpdateAndInsertMutation",
    "selections": (v5/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "VerifySubcontractor_UpdateAndInsertMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "211f14e5ebb92327027e8f437e1355c1",
    "id": null,
    "metadata": {},
    "name": "VerifySubcontractor_UpdateAndInsertMutation",
    "operationKind": "mutation",
    "text": "mutation VerifySubcontractor_UpdateAndInsertMutation(\n  $project_id: uuid!\n  $subcontractor_id: uuid!\n  $id: uuid!\n  $_set: add_subcontractor_request_set_input\n) {\n  update_add_subcontractor_request_by_pk(pk_columns: {id: $id}, _set: $_set) {\n    id\n  }\n  insert_project_subcontractor_one(object: {project_id: $project_id, subcontractor_id: $subcontractor_id}, on_conflict: {constraint: project_subcontractor_subcontractor_id_project_id_key, update_columns: []}) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "16e499aecb97abf2668babd440a75f9d";

export default node;
