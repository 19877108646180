import { IconDotsVertical, IconEye } from "@tabler/icons";
import { Popover } from "antd";
import clsx from "clsx";
import dayjs from "dayjs";
import React, { useEffect, useRef } from "react";
import Button from "src/common/components/general/Button";
import Icon from "src/common/components/general/Icon";
import getDateStr from "src/common/functions/getDateStr";
import { useElementSize } from "usehooks-ts";
import { SitedeliveryCalendarType } from "../utilities/sitedeliveryTypes";
import SitedeliveryCalendarDaycolumn from "./SitedeliveryCalendarDayColumn";

interface SitedeliveryCalendarDayProps {
  date: dayjs.Dayjs;
  hiddenCalendars: { id: string; title: string }[];
  shownCalendars: SitedeliveryCalendarType[];
  onCalendarHide: (calendarId: string) => void;
  onCalendarShow: (calendarId: string) => void;
}

const SitedeliveryCalendarDay: React.FC<SitedeliveryCalendarDayProps> = (
  props,
) => {
  const isToday = props.date.isSame(dayjs(), "day");
  const ref = useRef<HTMLDivElement>(null);

  const [dateTextRef, dateTextElementSize] = useElementSize();

  useEffect(() => {
    if (isToday) {
      ref.current?.scrollIntoView();
    }
  }, []);

  return (
    <div
      ref={ref}
      className={clsx(
        "flex flex-col h-full rounded-t-1 bg-white border-l border-r border-b border-suplementary-3",
      )}
    >
      <div
        className={clsx("sticky rounded-t-1 flex flex-row top-0 z-50 bg-white")}
      >
        <div
          className={clsx(
            "flex relative gap-0.5 py-0.5 pr-0.5 rounded-t-1 pl-1 border-t border-suplementary-3 justify-between bg-white items-center w-full",
          )}
        >
          <div className="flex flex-row items-center justify-start flex-1 w-full">
            <div
              ref={dateTextRef}
              className="sticky block text-center truncate"
              style={{
                left: `calc(50% - ${(dateTextElementSize.width || 0) / 2}px)`,
              }}
            >
              <span className="font-accent text-1">
                {`${!isToday ? "" : "Today, "}${props.date.format("dddd")}`}
              </span>{" "}
              <span className="text-0.75">{`${getDateStr(props.date)}`}</span>
            </div>
          </div>

          <div
            className={clsx(
              "flex flex-col justify-center",
              !props.hiddenCalendars.length && "invisible",
            )}
          >
            <Popover
              placement="bottomRight"
              content={
                <div className="flex flex-col gap-0.5">
                  {props.hiddenCalendars.map((calendar) => (
                    <Button
                      onClick={() => {
                        props.onCalendarShow(calendar.id);
                      }}
                      tiny
                      label={calendar.title}
                      icon={IconEye}
                    />
                  ))}
                </div>
              }
            >
              <div>
                <Icon icon={IconDotsVertical} color="interactive" />
              </div>
            </Popover>
          </div>
        </div>
      </div>
      {!!props.shownCalendars.length && (
        <div className="flex flex-row flex-1">
          {props.shownCalendars.map((calendar, i) => (
            <React.Fragment key={calendar.id}>
              <SitedeliveryCalendarDaycolumn
                {...{
                  ...calendar,
                  day: props.date,
                  events: calendar.events.filter(
                    (event) =>
                      event.from.isSame(props.date, "day") ||
                      event.to.isSame(props.date, "day"),
                  ),
                  onHide: () => {
                    props.onCalendarHide(calendar.id);
                  },
                }}
                key={calendar.id}
              />
              {i !== props.shownCalendars.length - 1 && (
                <div className="h-full py-1">
                  <div className="w-px h-full bg-suplementary-3"></div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default SitedeliveryCalendarDay;
