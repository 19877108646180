import React, { forwardRef, useEffect, useRef } from "react";
import { Input } from "antd";

export interface PhoneNumberInputUIProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEnterPress?: () => void;
  disabled?: boolean;
  autoFocus?: boolean;
}

const PhoneNumberInputUI = forwardRef<
  React.ElementRef<typeof Input>,
  PhoneNumberInputUIProps
>((props, fr) => {
  // const ref = forwardedRef as React.MutableRefObject<HTMLInputElement> | null;
  const forwardedRef = fr as
    | React.MutableRefObject<React.ElementRef<typeof Input> | null>
    | undefined;
  const localRef = useRef<React.ElementRef<typeof Input>>(null);

  useEffect(() => {
    if (forwardedRef) {
      forwardedRef.current = localRef.current;
    }

    if (localRef.current && props.autoFocus) {
      localRef.current.focus();
    }
  }, []);

  return (
    <Input
      disabled={props.disabled}
      placeholder="Phone number"
      type="tel"
      value={props.value}
      onChange={props.onChange}
      ref={localRef}
      onPressEnter={props.onEnterPress}
      enterKeyHint={"done"}
    />
  );
});

export default PhoneNumberInputUI;
