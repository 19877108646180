import { Alert, Form, Modal, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs, { weekdays } from "dayjs";
import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useParams } from "react-router-dom";
import Button from "src/common/components/general/Button";
import ErrorMessage from "src/utility-features/error-handling/components/ErrorMessage";
import { TimePicker } from "antd";
import { parseTime } from "src/domain-features/site-delivery/entry-routes/calendar/sitedelivery/utilities/timeEvents";
import { SitedeliveryTimeBlockModalQuery } from "src/common/types/generated/relay/SitedeliveryTimeBlockModalQuery.graphql";
import { TimeRange } from "src/root/routes/views/general-contractor/projects/settings/block-out-delivery-times/type";

const query = graphql`
  query SitedeliveryTimeBlockModalQuery(
    $calendarWhere: calendar_bool_exp!
    $subcontractorWhere: subcontractor_bool_exp!
    $projectId: uuid!
  ) {
    subcontractor_connection(
      where: $subcontractorWhere
      order_by: { name: asc }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          name
        }
      }
    }
    calendar_connection(where: $calendarWhere) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name {
            en
          }
        }
      }
    }
    project_delivery_sub_block_connection(
      where: { project_id: { _eq: $projectId } }
      first: 10000
    )
      @connection(
        key: "SitedeliveryTimeBlockModal_project_delivery_sub_block_connection"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          start_time
          end_time
          weekday
          calendars {
            calendar {
              id
              pk: id @__clientField(handle: "pk")
              name {
                en
              }
              color_hex
            }
          }
          subcontractors {
            subcontractor {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
          }
        }
      }
    }
  }
`;

const defaultTime: TimeRange = {
  start: dayjs().startOf("day"),
  end: dayjs().hour(23).minute(45),
};

const log = <T,>(msg: string, value: T): T => {
  console.log(msg, value);
  return value;
};

const toRangeArray = (range: TimeRange): [dayjs.Dayjs, dayjs.Dayjs] =>
  log("range", [range.start, range.end]);
const timeOfDay = (time: dayjs.Dayjs): number =>
  time.valueOf() - time.startOf("day").valueOf();
// const parseTime = (time: string): number =>
//   timeOfDay(dayjs(`1970-01-01T${time}`));

export interface SitedeliveryTimeBlockModalValues {
  startTime: dayjs.Dayjs;
  endTime: dayjs.Dayjs;
  subcontractorIds: Array<string>;
  calendarIds: Array<string>;
  weekday: number;
}

interface SitedeliveryTimeBlockFormValues {
  timeRange: [dayjs.Dayjs, dayjs.Dayjs];
  subcontractorIds: string;
  calendarIds: Array<string>;
  weekday: Array<number>;
}

interface SitedeliveryTimeBlockValues {
  subBlockId: string;
  calendarsIds: Array<string>;
  subcontractorIds: Array<string>;
  time: TimeRange;
  weekdayMask: number;
}

const weekdayIndexes = [0, 1, 2, 3, 4, 5, 6];
const weekdayNames = dayjs.weekdays(false);

interface SitedeliveryTimeBlockModalProps {
  timeBlock?: SitedeliveryTimeBlockValues;
  onDelete?: (subBlockId: string) => any;
  onSubmit: (values: SitedeliveryTimeBlockModalValues) => any;
  modalClose: () => void;
  modalVisible: boolean;
}

const SitedeliveryTimeBlockModal: React.FC<SitedeliveryTimeBlockModalProps> = (
  props,
) => {
  const [form] = Form.useForm();
  const { projectId } = useParams();
  if (!projectId) {
    throw new Error("ProjectId param is missing");
  }
  const data = useLazyLoadQuery<SitedeliveryTimeBlockModalQuery>(query, {
    calendarWhere: {
      projects: { project_id: { _eq: projectId }, is_archive: { _eq: false } },
    },
    subcontractorWhere: {
      subcontractor_projects: {
        project_id: { _eq: projectId },
      },
    },
    projectId: projectId,
  });

  const subcontractorData = data.subcontractor_connection.edges;
  const calendarData = data.calendar_connection.edges;

  const defaultDays = weekdayIndexes.filter(
    (day) => ((1 << day) & (props.timeBlock?.weekdayMask ?? 0)) !== 0,
  );

  const otherSubBlockData =
    data.project_delivery_sub_block_connection.edges.filter(
      (e) => e.node.pk !== props.timeBlock?.subBlockId,
    );

  const [statement, setStatement] = useState<string[]>([]);
  const handleTimeBlockSubmit = async () => {
    console.log("before validation");
    form
      .validateFields()
      .then(async (values: SitedeliveryTimeBlockFormValues) => {
        console.log("after validation");
        const overlapBlocksStatement: string[] = [];
        const weekdayMask = values.weekday.reduce(
          (a, day) => a + (1 << day),
          0,
        );
        const calendarIds = values.calendarIds;
        const startTime = values.timeRange[0];
        const endTime = values.timeRange[1];
        const startDayTime = parseTime(startTime.format("HH:mm"));
        const endDayTime = parseTime(endTime.format("HH:mm"));

        for (const subBlock of otherSubBlockData) {
          const commonDaysMask = weekdayMask & subBlock.node.weekday;

          if (commonDaysMask === 0) continue;
          const overlapingCalendars = subBlock.node.calendars
            .filter((c) => calendarIds.find((cid) => cid === c.calendar?.pk))
            .map((c) => c.calendar?.name?.en ?? c.calendar?.pk);

          if (overlapingCalendars.length === 0) continue;
          const overlapingWeekdays = weekdayIndexes.filter((day) => {
            const val =
              (commonDaysMask & (1 << day)) !== 0 &&
              parseTime(subBlock.node.start_time) < endDayTime &&
              parseTime(subBlock.node.end_time) > startDayTime;
            return val;
          });

          if (
            subBlock.node.subcontractors.length > 0 &&
            overlapingWeekdays.length > 0
          ) {
            const overlapingSub =
              subBlock.node.subcontractors[0].subcontractor?.name;
            overlapBlocksStatement.push(
              `Sub block overlaping with ${overlapingSub} block, having calendar ${overlapingCalendars.join(
                ", ",
              )} and time ${subBlock.node.start_time} - ${
                subBlock.node.end_time
              } on day ${overlapingWeekdays
                .map((day) => weekdayNames[day])
                .join(", ")}`,
            );
          }
        }
        setStatement(overlapBlocksStatement);

        if (overlapBlocksStatement.length === 0) {
          props.onSubmit({
            startTime: startTime,
            endTime: endTime,
            calendarIds,
            subcontractorIds: [values.subcontractorIds],
            weekday: weekdayMask,
          });
          props.modalClose();
          form.resetFields();
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  console.log("SITE DELIVERY TIME BLOCK MODAL", props);

  return (
    <>
      <Modal
        title={props.timeBlock ? `Edit Sub Block` : `Add Sub Block`}
        open={props.modalVisible}
        onCancel={props.modalClose}
        onOk={handleTimeBlockSubmit}
        //okButtonProps={{ hidden: !timeRangeIsValid }}
      >
        <Form form={form} layout="vertical">
          {statement.length > 0 && (
            <Form.Item>
              {statement.map((e) => (
                <Alert message={e} type="warning" />
              ))}
            </Form.Item>
          )}
          <Form.Item
            name="subcontractorIds"
            label="Subcontractors"
            initialValue={props.timeBlock?.subcontractorIds[0]}
            rules={[{ required: true, message: "Select subcontractor" }]}
          >
            <Select
              listItemHeight={10}
              // mode="multiple"
              style={{
                width: "100%",
              }}
              variant="outlined"
              options={subcontractorData.map((sub, i) => ({
                value: sub.node.pk,
                key: sub.node.pk,
                label: sub.node.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="calendarIds"
            label="Calendars"
            initialValue={props.timeBlock?.calendarsIds ?? []}
            rules={[{ required: true, message: "Select calendars" }]}
          >
            <Select
              listItemHeight={10}
              mode="multiple"
              style={{
                width: "100%",
              }}
              options={calendarData.map((calendar, i) => ({
                value: calendar.node.pk,
                key: calendar.node.pk,
                label: calendar.node.name?.en ?? i,
              }))}
            />
          </Form.Item>
          <Form.Item
            name={"timeRange"}
            label={"Set the time range"}
            trigger="onChange"
            initialValue={toRangeArray(props.timeBlock?.time ?? defaultTime)}
            rules={[
              {
                validator: async (rule, value) => {
                  if (value[0] >= value[1])
                    return `The range must be at least 15 minutes long`;
                },
              },
            ]}
          >
            <TimePicker.RangePicker
              showSecond={false}
              showHour={true}
              showMinute={true}
              allowClear={false}
              showNow={false}
              minuteStep={15}
            />
          </Form.Item>
          <Form.Item
            name="weekday"
            label="Select day"
            initialValue={defaultDays}
            rules={[{ required: true, message: "Select day" }]}
          >
            <Select
              listItemHeight={10}
              style={{
                width: "50%",
              }}
              mode="multiple"
              options={weekdayIndexes.map((day) => ({
                value: day,
                key: day,
                label: weekdayNames[day],
              }))}
            />
          </Form.Item>
          {props.timeBlock && props.onDelete && (
            <Button
              tiny
              secondary
              onClick={() => {
                props.onDelete!(props.timeBlock!.subBlockId);
                props.modalClose();
              }}
              label={`Delete Block`}
            />
          )}
        </Form>
      </Modal>
    </>
  );
};

export default SitedeliveryTimeBlockModal;
