import { graphql } from "babel-plugin-relay/macro";

const updateTaskMutation = graphql`
  mutation UpdateTaskMutation(
    $taskId: uuid!
    $taskStepObjects: [task_step_insert_input!]!
    $taskPpeTypeObjects: [task_ppe_type_insert_input!]!
    $taskPermitTypeObjects: [task_permit_type_insert_input!]!
    $task_set: task_set_input!
    $taskEditobjects: [task_edit_insert_input!]!
    $_set: text_translation_set_input!
    $text_pk_columns: text_translation_pk_columns_input!
    $ecmTypeWhere: task_hazard_ecm_type_bool_exp!
  ) {
    update_text_translation_by_pk(_set: $_set, pk_columns: $text_pk_columns) {
      id
      en
      es
      pt
      original
      clientText: original @__clientField(handle: "clientText")
    }
    delete_task_step(where: { task_id: { _eq: $taskId } }) {
      returning {
        id
      }
    }
    delete_task_ppe_type(where: { task_id: { _eq: $taskId } }) {
      returning {
        id
      }
    }
    delete_task_permit_type(where: { task_id: { _eq: $taskId } }) {
      returning {
        id
      }
    }
    delete_task_hazard_ecm_type(where: $ecmTypeWhere) {
      returning {
        id
      }
    }
    insert_task_step(objects: $taskStepObjects) {
      returning {
        id
        pk: id @__clientField(handle: "pk")
        sort_index

        task_step_hazards {
          id
          pk: id @__clientField(handle: "pk")

          description_id
          control_id
          description {
            id
            pk: id @__clientField(handle: "pk")
            en
          }
          control {
            id
            pk: id @__clientField(handle: "pk")
            en
          }
          task_hazard_ecm_types {
            ecm_type_id
            ecm_type {
              id
              pk: id @__clientField(handle: "pk")
              name {
                en
              }
            }
          }
        }
        description {
          id
          pk: id @__clientField(handle: "pk")
          en
        }
      }
    }
    insert_task_ppe_type(objects: $taskPpeTypeObjects) {
      returning {
        id
        pk: id @__clientField(handle: "pk")
        ppe_type {
          name {
            en
          }
          id
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    insert_task_permit_type(objects: $taskPermitTypeObjects) {
      returning {
        id
        pk: id @__clientField(handle: "pk")
        permit_type {
          name {
            en
          }
          id
          pk: id @__clientField(handle: "pk")
        }
      }
    }

    update_task_by_pk(_set: $task_set, pk_columns: { id: $taskId }) {
      id
    }
    insert_task_edit(objects: $taskEditobjects) {
      returning {
        task_patch
        edited_at
        edit_type
        edited_by_user {
          name
          role
          employee {
            general_contractor {
              name
            }
            employee_title {
              name {
                en
              }
            }
          }
          subcontractor_employee {
            subcontractor {
              name
            }
            employee_title {
              name {
                en
              }
            }
          }
          worker {
            current_worker_role
            subcontractor {
              name
            }
          }
        }
      }
    }
    update_task_signature(
      where: { task_id: { _eq: $taskId } }
      _set: { is_active: false }
    ) {
      affected_rows
    }
  }
`;

export default updateTaskMutation;
