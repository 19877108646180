import { Button, Form } from "antd";
import SelectLocalCity from "../newProject/component/SelectLocalCity";
import { useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { ProjectLocalCitiesMutation } from "src/common/types/generated/relay/ProjectLocalCitiesMutation.graphql";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import { ConnectionHandler } from "relay-runtime";

const ProjectLocalCities: React.FC<{
  currentCities: readonly { city_id: string; city: { name: string } }[];
  stateCode: string;
  projectId: string;
}> = ({ currentCities, stateCode, projectId }) => {
  const [updateLocalCities, loading] =
    useAsyncMutation<ProjectLocalCitiesMutation>(
      graphql`
        mutation ProjectLocalCitiesMutation(
          $toInserObjects: [project_local_city_insert_input!]!
          $toDeleteCityIds: [uuid!]!
          $projectId: uuid!
        ) {
          delete_project_local_city(
            where: {
              project_id: { _eq: $projectId }
              city_id: { _in: $toDeleteCityIds }
            }
          ) {
            affected_rows
          }
          insert_project_local_city(objects: $toInserObjects) {
            returning {
              city_id
              city {
                name
              }
            }
          }
        }
      `,
    );
  const [editingLocalCities, setEditingLocalCities] = useState(false);
  return (
    <div className="flex flex-col gap-1">
      <p className="text-2">Resident Tracking</p>
      <p style={{ marginBottom: 9, fontWeight: 400 }}>
        Track the hours worked by local residents in your Procore Daily Log
      </p>
      {editingLocalCities ? (
        <Form
          layout="vertical"
          initialValues={{
            local_cities: currentCities.map((ci) => ci.city_id),
          }}
          onFinish={async (v) => {
            if (!v.local_cities) return;
            const [toInsert, toDelete] = compareTwoLists(
              v.local_cities,
              currentCities.map((ci) => ci.city_id),
            );
            console.log(v.local_cities, toInsert, toDelete);
            await updateLocalCities({
              variables: {
                projectId,
                toDeleteCityIds: toDelete,
                toInserObjects: toInsert.map((cityId) => ({
                  city_id: cityId,
                  project_id: projectId,
                })),
              },
              updater: (store) => {
                console.log("Updater failed");
                const newRecords = store
                  .getRootField("insert_project_local_city")
                  .getLinkedRecords("returning");
                const projConn = ConnectionHandler.getConnection(
                  store.getRoot(),
                  "GCProjectSettingsOldQuery_project_connection",
                );

                if (projConn) {
                  const edges = projConn.getLinkedRecords("edges") || [];
                  const edge = edges[0];

                  if (!edge) return;
                  const node = edge.getLinkedRecord("node");
                  if (!node) return;
                  const current = (
                    node.getLinkedRecords("project_local_cities", {
                      order_by: { created_at: "desc" },
                    }) || []
                  )?.filter((rec) => {
                    const val = rec.getValue("city_id");
                    if (typeof val === "string") return !toDelete.includes(val);
                    return false;
                  });
                  node.setLinkedRecords(
                    [...current, ...newRecords],
                    "project_local_cities",
                    {
                      order_by: { created_at: "desc" },
                    },
                  );
                }
              },
            });
            setEditingLocalCities(false);
          }}
        >
          {/* <Form.Item
        name="stateCode"
        label="State"
        rules={[{ required: true, message: "Pick a State" }]}
      >
        <Select style={{ width: "100%" }} onChange={(v) => setStateCode(v)}>
          {data.state_connection.edges.map((e) => (
            <Select.Option key={e.node.code} value={e.node.code}>
              {e.node.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item> */}
          {stateCode && (
            <SelectLocalCity
              stateCode={stateCode}
              required
              loading={loading}
              label="Select the City or Cities to consider local"
            />
          )}
          <Button htmlType={"submit"} type="primary" loading={loading}>
            Save
          </Button>
        </Form>
      ) : (
        <>
          <div className="-mx-0.25">
            {currentCities.map((cur, i) => (
              <span
                className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-suplementary-1"
                key={cur.city_id || i}
              >
                {cur.city.name}
              </span>
            ))}{" "}
          </div>
          <div className="flex flex-row justify-start">
            <Button type="primary" onClick={() => setEditingLocalCities(true)}>
              Add Cities{" "}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
export default ProjectLocalCities;
