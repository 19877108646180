import {
  Alert,
  Col,
  Form,
  message,
  Modal,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import { FormInstance, useForm } from "antd/es/form/Form";
import { graphql } from "babel-plugin-relay/macro";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useGetProcoreTradeListQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { ProcoreTradeConfigModal_insertProcoreTrade_Mutation } from "src/common/types/generated/relay/ProcoreTradeConfigModal_insertProcoreTrade_Mutation.graphql";
import { ProcoreTradeConfigModal_Title_Query } from "src/common/types/generated/relay/ProcoreTradeConfigModal_Title_Query.graphql";
import { ProcoreTradeConfigModal_Trade_Query } from "src/common/types/generated/relay/ProcoreTradeConfigModal_Trade_Query.graphql";
import { ProcoreTradeConfigModal_updateProcoreTrade_Mutation } from "src/common/types/generated/relay/ProcoreTradeConfigModal_updateProcoreTrade_Mutation.graphql";
import getNormalOptionsFilter from "src/common/functions/getNormalOptionsFilter";

const query = graphql`
  query ProcoreTradeConfigModal_Trade_Query($projectId: uuid!) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          id
          general_contractor {
            projects {
              procore_project_data {
                procore_project_id
                procore_company_id
                procore_project_name
              }
              procore_trade {
                id
                procore_trade_id
                project_id
                title_id
              }
            }
          }
          procore_project_data {
            project_id
            procore_project_id
            procore_project_name
          }
          procore_trade {
            id
            procore_trade_id
            project_id
            title_id
          }
        }
      }
    }
  }
`;
const titleQuery = graphql`
  query ProcoreTradeConfigModal_Title_Query {
    worker_title_connection {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          translation {
            en
          }
        }
      }
    }
  }
`;

type FormValues = {
  [trade: string]: any;
};

export type ModalFormRef<V extends object> = {
  form: FormInstance<V>;
};

const zeroToNull = (v: string | null | undefined) =>
  v === "0" || !v ? null : v;

export type ProcoreTradeConfigModalRef = {
  open: () => void;
};

export type ProcoreTradeConfigModalProps = {
  projectId: string;
  onSubmit?: () => void;
};

type ProcoreTradeConfigContentModalProps = {
  projectId: string;
  saving: boolean;
  fetchKey: number;
};

interface Trade {
  id: string;
  procore_trade_id: string;
  title_id: string;
}

const stringCompare = (str1: string, str2: string): number => {
  if (str1 === str2) {
    return 0;
  }
  return str1 < str2 ? -1 : 1;
};

const ProcoreTradeConfiModalContent = forwardRef<
  ModalFormRef<FormValues>,
  ProcoreTradeConfigContentModalProps
>(({ projectId, saving, fetchKey }, ref) => {
  const data = useLazyLoadQuery<ProcoreTradeConfigModal_Trade_Query>(
    query,
    {
      projectId,
    },
    {
      fetchPolicy: "network-only",
      fetchKey,
    },
  );
  const [formRef] = useForm();
  useImperativeHandle<ModalFormRef<FormValues>, ModalFormRef<FormValues>>(
    ref,
    () => ({
      form: formRef,
    }),
  );

  const project_edges = data.project_connection.edges || [];
  if (project_edges.length == 0)
    throw new Error(`Project does not exists, project_id = ${projectId}`);

  const project_node = project_edges[0].node;
  const procoreProjectData = project_node.procore_project_data;

  const procore_project_id = procoreProjectData?.procore_project_id;
  if (!procore_project_id)
    throw new Error(
      `Procore project is not selected, project_id = ${projectId}`,
    ); // this usualy means error in parent component logic. (we should not render this dialog if project is not set)

  const titlePredictionData: Array<Trade> = [];
  project_node.general_contractor.projects.map(async (p) => {
    const titleTempData = p.procore_trade
      .filter((t) => !!t.procore_trade_id)
      .map((t) => {
        return {
          id: t.id,
          procore_trade_id: t.procore_trade_id as string,
          title_id: t.title_id,
        };
      });
    titlePredictionData.push(...titleTempData);
  });
  const titleData = useLazyLoadQuery<ProcoreTradeConfigModal_Title_Query>(
    titleQuery,
    {},
    {
      fetchPolicy: "store-or-network",
      fetchKey,
    },
  ).worker_title_connection.edges;

  const { data: procoreTradeList, loading } = useGetProcoreTradeListQuery({
    variables: {
      projectId,
      procore_project_id,
    },
  });

  const procoreTrades = procoreTradeList?.procoreTradeList?.trade
    .filter(
      (t, i) =>
        i ===
        procoreTradeList.procoreTradeList?.trade.findIndex(
          (tt) => tt.trade_id === t.trade_id,
        ),
    )
    .sort((t1, t2) => {
      return t1.trade_name > t2.trade_name ? -1 : 1;
    });

  // const [showPrediction, setShowPrediction] = useState<boolean>(false);
  if (loading)
    return (
      <Spin
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    );
  const tradeModalField = titleData.map((l) => {
    const value =
      project_node.procore_trade.find(
        (t) =>
          t.title_id === l.node.pk &&
          procoreTrades?.find((t1) => t1.trade_id === t.procore_trade_id),
      )?.procore_trade_id ?? "0";
    let predictionValue = "0";
    let predicitonValueCount = 0;
    const possiblePredictionValue = titlePredictionData
      .filter(
        (t) =>
          t.title_id === l.node.pk &&
          procoreTrades?.find((t1) => t1.trade_id === t.procore_trade_id),
      )
      .sort((t1, t2) => (t1.title_id > t2.title_id ? -1 : 1));
    // if (possiblePredictionValue.length)
    //   predictionValue = possiblePredictionValue[0].procore_trade_id;
    // for (var i = 0; i < possiblePredictionValue.length; ) {
    //   for (
    //     var j = i + 1;
    //     possiblePredictionValue[j] === possiblePredictionValue[i];
    //     j++
    //   )
    //     if (predicitonValueCount < j - i) {
    //       predicitonValueCount = j - i;
    //       predictionValue = possiblePredictionValue[i].procore_trade_id;
    //     }
    //   i = j;
    // }
    possiblePredictionValue.sort((a, b) =>
      stringCompare(a.procore_trade_id, b.procore_trade_id),
    );

    predicitonValueCount = 0;
    predictionValue = "0";
    let p1 = 0;
    while (p1 < possiblePredictionValue.length) {
      const p2 = p1;
      const { procore_trade_id } = possiblePredictionValue[p2];
      while (
        p1 < possiblePredictionValue.length &&
        possiblePredictionValue[p1].procore_trade_id === procore_trade_id
      )
        p1++;
      if (predicitonValueCount < p1 - p2) {
        predicitonValueCount = p1 - p2;
        predictionValue = possiblePredictionValue[p2].procore_trade_id;
      }
    }
    return (
      <Row gutter={24} style={{ lineHeight: 2 }} key={`row${l.node.pk}`}>
        <Col span={12}>
          {l.node.translation.en}
          {"   "}
          {value === "0" && predictionValue !== "0" && (
            <>
              <text
                style={{
                  borderColor: "#1890ff",
                  color: "#1890ff",
                  // backgroundColor: "#1890ff",
                  borderWidth: 1,
                  borderRadius: 100,
                  fontWeight: 1,
                  fontSize: 10,
                  padding: 3,
                  paddingRight: 9,
                  paddingLeft: 9,
                }}
              >
                Suggested
              </text>
            </>
          )}
        </Col>
        <Col span={12}>
          <Form.Item
            name={l.node.pk}
            initialValue={value === "0" ? predictionValue : value}
          >
            <Select
              style={{ width: "100%" }}
              disabled={saving}
              loading={loading || saving}
              //searchValue={loading ? "loading...": undefined}
              showSearch
              optionFilterProp="children"
              filterOption={getNormalOptionsFilter}
              options={procoreTrades?.map((t) => ({
                label: t.trade_name,
                value: t.trade_id,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  });

  return (
    <Form form={formRef} layout="vertical">
      <Alert
        style={{ margin: "16px 0" }}
        type="info"
        showIcon={false}
        banner
        message={
          <Row gutter={24} style={{ lineHeight: 2 }}>
            <Col span={12}>SiteForm Trades</Col>
            <Col span={12}>
              {"Procore Trades"}
              {/* <SettingFilled
                onClick={(e) => {
                  setShowPrediction(true);
                }}
              /> */}
            </Col>
          </Row>
        }
      />
      <>{tradeModalField}</>
    </Form>
  );
});

const ProcoreTradeConfigModal = forwardRef<
  ProcoreTradeConfigModalRef,
  ProcoreTradeConfigModalProps
>(({ projectId, onSubmit }, ref) => {
  const modal = useRef<ModalFormRef<FormValues>>(null);
  const [saving, setSaving] = useState(false);
  const [visible, setVisible] = useState(false);
  const [key, setKey] = useState(0);

  const [insertProcoreTrade] =
    useAsyncMutation<ProcoreTradeConfigModal_insertProcoreTrade_Mutation>(
      graphql`
        mutation ProcoreTradeConfigModal_insertProcoreTrade_Mutation(
          $objects: [procore_trade_insert_input!]!
        ) {
          insert_procore_trade(objects: $objects) {
            affected_rows
          }
        }
      `,
    );
  const [updateProcoreTrade] =
    useAsyncMutation<ProcoreTradeConfigModal_updateProcoreTrade_Mutation>(
      graphql`
        mutation ProcoreTradeConfigModal_updateProcoreTrade_Mutation(
          $where: procore_trade_bool_exp!
          $_set: procore_trade_set_input!
        ) {
          update_procore_trade(where: $where, _set: $_set) {
            affected_rows
          }
        }
      `,
    );
  useImperativeHandle<ProcoreTradeConfigModalRef, ProcoreTradeConfigModalRef>(
    ref,
    () => ({
      open: () => {
        setKey((v) => v + 1);
        setVisible(true);
      },
    }),
  );
  return (
    <>
      <Modal
        open={visible}
        cancelText="Cancel"
        title="Trade Setup"
        okText="Save"
        width={550}
        confirmLoading={saving}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={async () => {
          const form = modal.current?.form;
          if (!form) return;
          try {
            const values = await form.validateFields();
            setSaving(true);
            try {
              let updates = Object.entries(values).map(async ([pk, value]) => {
                const trade_id = zeroToNull(value);
                const update_res = await updateProcoreTrade({
                  variables: {
                    where: {
                      project_id: {
                        _eq: projectId,
                      },
                      title_id: {
                        _eq: pk,
                      },
                    },
                    _set: {
                      procore_trade_id: trade_id,
                    },
                  },
                });
                if (update_res.update_procore_trade?.affected_rows !== 1) {
                  await insertProcoreTrade({
                    variables: {
                      objects: [
                        {
                          procore_trade_id: trade_id,
                          project_id: projectId,
                          title_id: pk, //l.subcontractor.pk,
                        },
                      ],
                    },
                  });
                }
              });
              await Promise.allSettled(updates);
              if (onSubmit) onSubmit();
              setVisible(false);
              message.success("Done");
            } finally {
              setSaving(false);
            }
          } catch (e) {
            notification.error({
              message: "Error",
              description: (e as any)?.message ?? JSON.stringify(e),
              duration: null,
            });
          }
        }}
      >
        <CustomSuspense>
          <ProcoreTradeConfiModalContent
            ref={modal}
            key={key}
            projectId={projectId}
            saving={saving}
            fetchKey={key}
          />
        </CustomSuspense>
      </Modal>
    </>
  );
});

export default ProcoreTradeConfigModal;
