import { Button, Checkbox, message, Popconfirm, Space } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useRef, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import noPropagation from "src/common/functions/noPropagation";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import dayjs from "dayjs";
import {
  SCSafetyPlan_UpdateSafetyPlanMutation,
  SCSafetyPlan_UpdateSafetyPlanMutation$data,
} from "src/common/types/generated/relay/SCSafetyPlan_UpdateSafetyPlanMutation.graphql";
import { SCSafetyPlan_UpdateSafetyPlanByPkMutation } from "src/common/types/generated/relay/SCSafetyPlan_UpdateSafetyPlanByPkMutation.graphql";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import { SCSafetyPlan_ProjectQuery } from "src/common/types/generated/relay/SCSafetyPlan_ProjectQuery.graphql";
import SCPdfModal from "src/common/components/pdf/PdfViewer";
import SubcontractorProps from "src/common/types/manual/SubcontractorProps";
import { SafetyPlanTable_safetyPlan$data } from "src/common/types/generated/relay/SafetyPlanTable_safetyPlan.graphql";
import SafetyPlanTable, { safetyPlan } from "./SafetyPlanTable";
import UploadSafetyPlanModal from "./UploadSafetyPlanModal";
import SelectProjectForSafetyPlanModal from "./SelectProjectForSafetyPlanModal";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";

const query = graphql`
  query SCSafetyPlan_ProjectQuery($subcontractorId: uuid!) {
    project_subcontractor_connection(
      where: { subcontractor_id: { _eq: $subcontractorId } }
      order_by: { project: { name: asc } }
    ) {
      edges {
        node {
          project {
            id
            pk: id @__clientField(handle: "pk")
            name
          }
        }
      }
    }
  }
`;

const SubAdminSafetyPlan: FC<SubcontractorProps> = ({ subcontractorId }) => {
  const [openUploadSafetyPlanModal, setOpenUploadSafetyPlanModal] =
    useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [selectedSafetyPlan, setSelectedSafetyPlan] = useState<
    safetyPlan | undefined
  >();
  const [selectProjectModal, setSelectProjectModal] = useState(false);
  const safetyPlanTableRef = useRef<DataScrollTableRef>(null);

  const data = useLazyLoadQuery<SCSafetyPlan_ProjectQuery>(query, {
    subcontractorId: subcontractorId,
  });
  const projectList = data.project_subcontractor_connection.edges.map((itr) => {
    return { id: itr.node.project.pk, name: itr.node.project.name };
  });

  const [updateSafetyPlan, updating] =
    useAsyncMutation<SCSafetyPlan_UpdateSafetyPlanMutation>(
      graphql`
        mutation SCSafetyPlan_UpdateSafetyPlanMutation(
          $_set: safety_plan_set_input
          $where: safety_plan_bool_exp!
        ) {
          update_safety_plan(_set: $_set, where: $where) {
            affected_rows
          }
        }
      `,
    );

  const [updateSafetyPlanByPk, updatingByPk] =
    useAsyncMutation<SCSafetyPlan_UpdateSafetyPlanByPkMutation>(
      graphql`
        mutation SCSafetyPlan_UpdateSafetyPlanByPkMutation(
          $id: uuid!
          $_set: safety_plan_set_input!
        ) {
          update_safety_plan_by_pk(pk_columns: { id: $id }, _set: $_set) {
            id
            add_to_future_projects
          }
        }
      `,
    );

  const deleteUpdater: (
    store: RecordSourceSelectorProxy<SCSafetyPlan_UpdateSafetyPlanMutation$data>,
    id: string,
    pk: string,
  ) => void = (store, id, pk) => {
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "SafetyPlanTable_safety_plan_connection",
    );
    if (conn) {
      const edges = (conn.getLinkedRecords("edges") || []).filter(
        (r) =>
          r.getLinkedRecord("node")?.getValue("id") != id &&
          r.getLinkedRecord("node")?.getValue("company_safety_plan_id") != pk,
      );
      conn.setLinkedRecords(edges, "edges");
    }
  };

  const onDelete = async (
    safetyPlan: SafetyPlanTable_safetyPlan$data["safety_plan_connection"]["edges"][number]["node"],
  ) => {
    await updateSafetyPlan({
      variables: {
        _set: { deleted_at: dayjs().toISOString() },
        where: {
          _or: [
            {
              id: { _eq: safetyPlan.pk },
            },
            {
              company_safety_plan_id: { _eq: safetyPlan.pk },
            },
          ],
        },
      },
      updater: (store) => {
        deleteUpdater(store, safetyPlan.id, safetyPlan.pk);
      },
    })
      .then(async (d) => {
        message.success("Safety Plan Deleted");
      })
      .catch((e) => console.log(e));
  };

  const onAddToFutureProjectsChange = async (
    safetyplan: SafetyPlanTable_safetyPlan$data["safety_plan_connection"]["edges"][number]["node"],
    checked: boolean,
  ) => {
    await updateSafetyPlanByPk({
      variables: {
        _set: { add_to_future_projects: checked },
        id: safetyplan.pk,
      },
      optimisticResponse: {
        update_safety_plan_by_pk: {
          id: safetyplan.pk,
          add_to_future_projects: checked,
        },
      },
    })
      .then(async (d) => {
        message.success("Safety Plan Updated");
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <SelectProjectForSafetyPlanModal
        visible={selectProjectModal}
        subcontractorId={subcontractorId}
        data={projectList}
        safetyPlan={selectedSafetyPlan}
        onClose={() => setSelectProjectModal(false)}
        onSubmit={() => {
          setSelectProjectModal(false);
        }}
      />
      <SCPdfModal
        visible={pdfModal}
        onClose={() => {
          setPdfModal(false);
        }}
        pdfUrl={pdfUrl}
      />

      <UploadSafetyPlanModal
        visible={openUploadSafetyPlanModal}
        safetyPlan={selectedSafetyPlan}
        subcontractorId={subcontractorId}
        onClose={() => setOpenUploadSafetyPlanModal(false)}
        onSubmit={() => {
          setOpenUploadSafetyPlanModal(false);
          safetyPlanTableRef.current?.refetch();
        }}
      />
      <SafetyPlanTable
        title="Company Safety Manual"
        ref={safetyPlanTableRef}
        where={{
          subcontractor_id: { _eq: subcontractorId },
          project_id: { _is_null: true },
          deleted_at: { _is_null: true },
        }}
        onSafetyPlanRowClick={(safetyPlan) => {
          setPdfUrl(safetyPlan.url);
          setPdfModal(true);
        }}
        loading={updating}
        topBarButtons={[
          {
            onClick: () => {
              setSelectedSafetyPlan(undefined);
              setOpenUploadSafetyPlanModal(true);
            },
            label: "+ Add Safety Manual Sections",
          },
        ]}
        extraColumns={[
          {
            title: "Action",
            dataIndex: [""],
            size: "ml",
            key: "action",
            render: (_, safetyPlan) => {
              return (
                <>
                  <Button
                    type="link"
                    onClick={noPropagation(() => {
                      setSelectedSafetyPlan(safetyPlan);
                      setSelectProjectModal(true);
                    })}
                  >
                    Add to Project
                  </Button>
                </>
              );
            },
            onCellClick: (record: any, event: any) => {
              console.log(record);
            },
          },
          {
            title: "Update",
            dataIndex: [""],
            size: "ml",
            key: "replace",
            render: (_, safetyplan) => {
              return (
                <>
                  <Button
                    type="link"
                    onClick={noPropagation(() => {
                      setSelectedSafetyPlan(safetyplan);
                      setOpenUploadSafetyPlanModal(true);
                    })}
                  >
                    Replace
                  </Button>
                </>
              );
            },
            onCellClick: (record: any, event: any) => {
              console.log(record);
            },
          },
          {
            title: "",
            dataIndex: [""],
            size: "lg",
            key: "addToAllProjects",
            render: (_, safetyplan) => {
              return (
                <div onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    className="mt-1"
                    checked={safetyplan.add_to_future_projects}
                    onChange={(value) => {
                      onAddToFutureProjectsChange(
                        safetyplan,
                        value.target.checked,
                      );
                    }}
                  >
                    Add to all New projects
                  </Checkbox>
                </div>
              );
            },
          },
          {
            title: "",
            dataIndex: [""],
            size: "md",
            key: "delete",
            render: (_, safetyPlan) => (
              <>
                <Space>
                  <Popconfirm
                    title={
                      <>
                        <div>
                          {
                            "Permanently Delete this File: This file will be deleted and removed from any linked projects. It cannot be undone."
                          }
                        </div>
                        <div>{"Are you sure?"}</div>
                      </>
                    }
                    onConfirm={noPropagation(() => {
                      onDelete(safetyPlan);
                    })}
                    onCancel={noPropagation()}
                    okText="Yes"
                    cancelText="Cancel"
                  >
                    <Button danger type="link" onClick={noPropagation()}>
                      Delete
                    </Button>
                  </Popconfirm>
                </Space>
              </>
            ),
          },
        ]}
      />
    </>
  );
};
const SafetyPlanWrapper: FC = () => {
  const subcontractorId = useSubcontractorId();
  return (
    <BasicWrapper scrollable>
      <SubAdminSafetyPlan subcontractorId={subcontractorId} />
    </BasicWrapper>
  );
};
export default withCustomSuspense(SafetyPlanWrapper);
