import { notification } from "antd";

const userLeavesOrStaysNotification = (
  userName: string,
  userId: string,
  universal: boolean,
  userMayLeave: boolean
) => {
  if (userMayLeave) {
    notification.success({
      message: `${userName} can leave when prompted`,
      description: `They have previously completed the ${
        universal ? `CARE` : `Company`
      } orientation`,
    });
  } else {
    notification.warning({
      message: `${userName} needs to stay for the entire orientation`,
      description: `They have not previously watched the ${
        universal ? `CARE` : `Company`
      } orientation or it has expired`,
    });
  }
};

export default userLeavesOrStaysNotification;
