import React, { useState } from "react";
import { Select } from "antd";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import SiteFeatureStepsInputLayout from "../../../../../../../common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import SiteFeatureStepLayout from "../../../../../../../common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import useSiteOrientationUniversal from "../../../newDesign/utils/siteOrientationUniversalHook";

import { useCurrentLangStrings } from "../../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteOrientationUniversalRoleProps
  extends SiteOrientationStepProps {
  options: Array<{
    id: string;
    en: string;
    es: string;
  }>;
}

const SiteOrientationUniversalRole: React.FC<
  SiteOrientationUniversalRoleProps
> = (props) => {
  const workerContext = useSiteOrientationUniversal();
  const [role, setRole] = useState(workerContext.orientee?.role || "");

  const langStrings = useCurrentLangStrings();
  const onEnter = (selectedRole?: string) => {
    workerContext.updateOrientee((user) => {
      return !user
        ? { role: selectedRole ?? role, userType: "worker" }
        : { ...user, role: selectedRole ?? role, userType: "worker" }; //for universal currently we are only registering worker
    });
    props.onNext();
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!role.length}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.whatIsYourRole}
      >
        <Select
          className="text-1"
          virtual={false}
          onSelect={(val) => {
            if (typeof val.valueOf() === "string") {
              setRole(val.valueOf());
              onEnter(val.valueOf());
            }
          }}
          value={role}
          placeholder={langStrings.strings.selectYourRole}
        >
          {props.options.map((e: any) => (
            <Select.Option key={e.id} value={e.id} className="text-1">
              {langStrings.options.workerRoles[e.id] ?? e.en}
            </Select.Option>
          ))}
        </Select>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationUniversalRole;
