import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "react-relay/hooks";
import { Environment } from "relay-runtime";
import { GetJHAApprovalModalDataQuery } from "src/common/types/generated/relay/GetJHAApprovalModalDataQuery.graphql";

const getJHAApprovalModalData = graphql`
  query GetJHAApprovalModalDataQuery(
    $projectId: uuid!
    $subId: uuid!
    $taskId: uuid!
  ) {
    project_worker_connection(
      where: {
        project_id: { _eq: $projectId }
        subcontractor_id: { _eq: $subId }
      }
    ) {
      edges {
        node {
          worker_id
          user {
            name
            email
          }
        }
      }
    }

    subcontractor_employee_connection(
      where: { subcontractor_id: { _eq: $subId } }
    ) {
      edges {
        node {
          user_id
          user {
            name
            email
          }
        }
      }
    }

    project_employee_connection(
      where: { project_id: { _eq: $projectId } }
      order_by: { employee: { user: { name: asc } } }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          status
          project {
            id
            name
          }
          employee {
            uid
            employee_title {
              employee_title_category {
                name {
                  en
                }
              }
              id
              name {
                en
              }
            }
            user {
              pk: id @__clientField(handle: "pk")
              id
              name
              email
            }
          }
        }
      }
    }

    email_request_connection(
      where: { task_id: { _eq: $taskId }, opened: { _eq: false } }
    ) {
      edges {
        node {
          email
          user_id
          user {
            name
          }
          type
        }
      }
    }
  }
`;

export const getJHAApprovalData = async (
  environment: Environment,
  taskId: string,
  subId: string,
  projectId: string,
) => {
  const request = fetchQuery<GetJHAApprovalModalDataQuery>(
    environment,
    getJHAApprovalModalData,
    { taskId, projectId, subId },
    { fetchPolicy: "store-or-network" },
  );
  const data = await request.toPromise();

  return data;
};

export default getJHAApprovalModalData;
