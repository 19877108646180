import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useUpdateCrewDataForExistingCrew_Mutation } from "src/common/types/generated/relay/useUpdateCrewDataForExistingCrew_Mutation.graphql";

const updateCrewDataMutation = graphql`
  mutation useUpdateCrewDataForExistingCrew_Mutation(
    $currentCrewId: uuid!
    $insertWorkerWhere: project_worker_bool_exp!
    $removeWorkerWhere: project_worker_bool_exp!
    $projectCrewNewWorkersWhere: project_crew_bool_exp!
    $_setCrewData: project_crew_set_input!
  ) {
    update_project_crew(
      where: $projectCrewNewWorkersWhere
      _set: { lead_foreman_project_worker_id: null }
    ) {
      affected_rows
    }
    removeWorkersFromCrew: update_project_worker(
      where: $removeWorkerWhere
      _set: { project_crew_id: null }
    ) {
      affected_rows
      returning {
        id
        project_crew {
          id
          pk: id @__clientField(handle: "pk")
          name
          lead_foreman_project_worker {
            id
            pk: id @__clientField(handle: "pk")
          }
        }
      }
    }
    insertWorkersToCrew: update_project_worker(
      where: $insertWorkerWhere
      _set: { project_crew_id: $currentCrewId }
    ) {
      affected_rows
      returning {
        id
        leading_project_crews_aggregate {
          aggregate {
            count
          }
        }
        project_crew {
          id
          pk: id @__clientField(handle: "pk")
          name
          lead_foreman_project_worker {
            id
            pk: id @__clientField(handle: "pk")
            user {
              name
            }
          }
        }
      }
    }
    update_project_crew_by_pk(
      pk_columns: { id: $currentCrewId }
      _set: $_setCrewData
    ) {
      ...CrewFrag @relay(mask: false)
    }
  }
`;
const useUpdateCrewDataForExistingCrew = () => {
  return useAsyncMutation<useUpdateCrewDataForExistingCrew_Mutation>(
    updateCrewDataMutation
  );
};
export default useUpdateCrewDataForExistingCrew;
