import React, { useContext } from "react";
import SiteOrientationStepsUIProfilePhoto from "src/domain-features/siteorientation/components/stepsUI/profilePhoto/SiteOrientationStepsUIProfilePhoto";
import siteOrientationOrienteeContext from "src/domain-features/siteorientation/utils/siteOrientationOrienteeContext";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsProfilePhotoProps
  extends SiteOrientationStepProps {}

const SiteOrientationInPersonWorkerStepsProfilePhoto: React.FC<
  SiteOrientationInPersonWorkerStepsProfilePhotoProps
> = (props) => {
  const context = useSiteOrientationOrientee();
  const langStrings = useCurrentLangStrings();

  if (context.orientee) {
    return (
      <SiteOrientationStepsUIProfilePhoto
        user={context.orientee}
        langStrings={langStrings}
        type={context.type}
        onNext={props.onNext}
        onBack={props.onBack}
        onNewProfilePhotoUpload={(profilePhoto) => {
          context.updateOrientee((u) =>
            // only existing user can do so
            u
              ? {
                  ...u,
                  profileImg: {
                    ...u.profileImg,
                    new: profilePhoto,
                  },
                }
              : null,
          );
        }}
      />
    );
  } else {
    return null;
  }
};

export default SiteOrientationInPersonWorkerStepsProfilePhoto;
