import { IconArrowBackUp } from "@tabler/icons";
import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Button from "src/common/components/general/Button";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import GCWorkerProfile from "./GCWorkerProfile";

interface GCprojectWorkerProps {}

const GCprojectWorker: React.FC<GCprojectWorkerProps> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const projectWorkerId = params.projectWorkerId;
  const projectId = params.projectId;
  return projectId && projectWorkerId ? (
    <BasicWrapper>
      <div className="flex flex-row justify-between w-full">
        <Button
          onClick={() => {
            navigate(-1);
          }}
          icon={IconArrowBackUp}
        />
       
      </div>
      <GCWorkerProfile
        projectId={projectId}
        projectWorkerId={projectWorkerId}
      />
    </BasicWrapper>
  ) : (
    <Navigate to={"/gce"} />
  );
};

export default GCprojectWorker;
