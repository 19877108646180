import React, { useState } from "react";
import { Select } from "antd";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import SiteFeatureStepLayout from "../../../../../../../common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import useSiteOrientationUniversal from "../../../newDesign/utils/siteOrientationUniversalHook";

import { useCurrentLangStrings } from "../../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteOrientationUniversalTradeProps
  extends SiteOrientationStepProps {
  trades: Array<{
    id: string;
    en: string;
    es: string;
  }>;
}

const SiteOrientationUniversalTrade: React.FC<
  SiteOrientationUniversalTradeProps
> = (props) => {
  const workerContext = useSiteOrientationUniversal();
  const [trade, setTrade] = useState(workerContext.orientee?.title?.id || "");
  const langStrings = useCurrentLangStrings();

  const onEnter = (pickedTradeId?: string) => {
    const selectedTrade = props.trades.find(
      (f) => f.id === (pickedTradeId ?? trade),
    );
    if (selectedTrade) {
      workerContext.updateOrientee((user) =>
        user
          ? {
              ...user,
              title: { id: selectedTrade.id, name: selectedTrade.en },
            }
          : { title: { id: selectedTrade.id, name: selectedTrade.en } },
      );
    }
    props.onNext();
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!trade.length}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.whatIsYourTrade}
      >
        <Select
          className="text-1"
          virtual={false}
          onSelect={(val) => {
            if (typeof val.valueOf() === "string") {
              setTrade(val.valueOf());
              onEnter(val.valueOf());
            }
          }}
          value={trade}
          placeholder={langStrings.strings.selectYourTrade}
        >
          {props.trades.map((e) => (
            <Select.Option key={e.id} value={e.id} className="text-1">
              {langStrings.options.workerTrades[e.id] ?? e.en}
            </Select.Option>
          ))}
        </Select>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationUniversalTrade;
