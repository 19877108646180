import React, { useEffect } from "react";
import useMeasure from "react-use-measure";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import clsx from "clsx";

export interface CollapseProps {
  head: React.ReactElement;
  hide: () => void;
  expand: () => void;
  expanded: boolean;
  children: React.ReactNode;
}

const Collapse: React.FC<CollapseProps> = (props) => {
  const [bodyRef, bounds] = useMeasure();
  useEffect(() => {
    if (!props.children) {
      props.hide();
    }
  }, [props.children]);
  return (
    <div
      className={clsx(
        `flex flex-col`,
        !props.children && "pointer-events-none"
      )}
    >
      <div
        onClick={(e) => {
          if (!props.expanded) {
            props.expand();
          } else {
            props.hide();
          }
        }}
        className={`cursor-pointer z-10 sticky top-0`}
      >
        {props.head}
      </div>
      <div
        style={{ height: bounds.height }}
        className={`transition-all relative z-0 overflow-hidden`}
      >
        {props.expanded && props.children && (
          <div ref={bodyRef} className="w-full">
            <CustomSuspense>{props.children}</CustomSuspense>
          </div>
        )}
      </div>
    </div>
  );
};

export default Collapse;
