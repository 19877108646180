import clsx from "clsx";
import React from "react";
import InfoCard, { InfoCardProps } from "src/common/components/general/InfoCard";

export type GCDailyReportCardType = InfoCardProps & { onClick?: () => void };

export interface GCProjectReportsDailyDayCardsProps {
  cards: GCDailyReportCardType[];
  title: string;
}

function sliceIntoChunks(arr: any[], chunkSize: number) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

const GCProjectReportsDailyDayCards: React.FC<
  GCProjectReportsDailyDayCardsProps
> = (props) => {
  return !!props.cards.length ? (
    <div className="flex flex-col gap-1">
      <div className="w-full text-center font-accent text-1">{props.title}</div>

      <div className="flex flex-col gap-0.25">
        {sliceIntoChunks(props.cards, 4).map((cardsChunk, index) => (
          <div key={index} className="flex flex-row justify-center gap-0.25 w-full">
            {cardsChunk.map((card, i) => (
              <div
                key={i}
                onClick={card.onClick}
                className={clsx(
                  "p-1 rounded-1 border inline-block w-1/4",
                  card.onClick
                    ? "border-interactive-secondary hover:border-interactive-primary cursor-pointer"
                    : "bg-suplementary-1 border-transparent"
                )}
              >
                <InfoCard {...card} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default GCProjectReportsDailyDayCards;
