import React from "react";
import dayjs from "dayjs";
import isMobile from "../../functions/isMobile";
import MobileBirthDatePicker from "./MobileBirthDatePicker";
import DesktopBirthDatePicker from "./DesktopBirthDatePicker";

interface BirthDatePickerProps {
  value: dayjs.Dayjs | null;
  onValueChange: (newValue: dayjs.Dayjs | null) => void;
  onEnter?: () => void; // for desktop only
}

const BirthDatePicker: React.FC<BirthDatePickerProps> = (props) => {
  if (isMobile()) {
    return (
      <MobileBirthDatePicker
        {...{
          onValueChange: props.onValueChange,
          defaultValue: props.value ? dayjs(props.value) : null,
        }}
      />
    );
  }

  return (
    <DesktopBirthDatePicker
      value={props.value}
      onValueChange={props.onValueChange}
      onEnter={props.onEnter}
    />
  );
};

export default BirthDatePicker;
