import { IconDownload } from "@tabler/icons";
import { DatePicker, Modal, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Button from "src/common/components/general/Button";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import {
  useGenerateMonthlyReportMutation,
  useGenerateMonthlyReportSummaryMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import GCDownloadDailyReportsMonthsRangePicker from "./GCProjectReportsDailyMonthDownloadModalDateRange";

type SubcontractorType = {
  name: string;
  id: string;
};

export type GCProjectReportsDailyMonthDownloadSubcontractorsType = {
  all: SubcontractorType[];
  selected: string | undefined;
  selectSubcontractor: (id?: string) => void;
};

export interface GCProjectReportsDailyMonthDownloadModalProps {
  subcontractors: GCProjectReportsDailyMonthDownloadSubcontractorsType;
  visible: boolean;
  hide: () => void;
  projectId: string;
  defaultMonth: dayjs.Dayjs;
  workerImageUrl: string;
  manhourImageUrl: string;
  preparingChart: boolean;
  onMonthChange: (month: dayjs.Dayjs) => void;
}

const GCProjectReportsDailyMonthDownloadModal: React.FC<
  GCProjectReportsDailyMonthDownloadModalProps
> = (props) => {
  const [fileExtenstion, setFileExtenstion] = useState<"pdf" | "csv">("pdf");
  const [startMonth, setStartMonth] = useState<dayjs.Dayjs | null>(
    props.defaultMonth,
  );
  const [endMonth, setEndMonth] = useState<dayjs.Dayjs | null>(
    props.defaultMonth,
  );

  const [generateMonthlyReport, { loading: generatingMonthlyReport }] =
    useGenerateMonthlyReportMutation();
  const [
    generateMonthlyReportSummary,
    { loading: generatingMonthlyReportSummary },
  ] = useGenerateMonthlyReportSummaryMutation();

  const onDownloadMonthlyReportClick = async () => {
    generateMonthlyReport({
      variables: {
        input: {
          projectId: props.projectId,
          startAt: dayjs(startMonth).startOf("month").format("YYYY-MM-DD"),
          endAt: dayjs(endMonth).endOf("month").format("YYYY-MM-DD"),
          manhourImageUrl: props.manhourImageUrl,
          workerImageUrl: props.workerImageUrl,
          subcontractorId: props.subcontractors.selected,
        },
      },
    })
      .then((data) => {
        if (data.data?.generateMonthlyReport)
          downloadFromUrl(data.data.generateMonthlyReport);
      })
      .catch((e) => console.error(e));
  };
  const onCsvClick = () => {
    generateMonthlyReportSummary({
      variables: {
        input: {
          projectId: props.projectId,
          startAt: dayjs(startMonth).startOf("month").format("YYYY-MM-DD"),
          endAt: dayjs(endMonth).endOf("month").format("YYYY-MM-DD"),
          subcontractorId: props.subcontractors.selected,
        },
      },
    })
      .then((data) => {
        if (data.data?.generateMonthlyReportSummary)
          downloadFromUrl(data.data.generateMonthlyReportSummary);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    setStartMonth(props.defaultMonth);
    setEndMonth(props.defaultMonth);
  }, [props.visible]);

  const changeFileExtenstion = () => {
    setFileExtenstion((prevState) => {
      if (prevState === "csv") {
        return "pdf";
      } else {
        setEndMonth(props.defaultMonth);
        setStartMonth(props.defaultMonth);
        return "csv";
      }
    });
  };

  const today = dayjs();

  return (
    <Modal
      width={`33vw`}
      onCancel={props.hide}
      okText={`Download`}
      open={props.visible}
      footer={
        <div className="flex flex-row justify-center">
          <Button
            {...{
              label: `Download Monthly Report`,
              onClick:
                fileExtenstion === "csv"
                  ? onCsvClick
                  : onDownloadMonthlyReportClick,
              loading:
                generatingMonthlyReport ||
                generatingMonthlyReportSummary ||
                props.preparingChart,
              icon: IconDownload,
            }}
          />
        </div>
      }
    >
      <div className="flex flex-col items-center gap-2">
        <div className="flex flex-col gap-0.5">
          <p className="text-1">
            <span className="uppercase text-1.5">{`.${fileExtenstion} `}</span>-
            file extension
          </p>
          <div className="flex flex-row items-center justify-center ">
            <Button
              secondary
              label={`Change to .${fileExtenstion === "csv" ? "PDF" : "CSV"}`}
              onClick={changeFileExtenstion}
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-0.5">
          <div className="text-1.5 text-center">
            {!!startMonth && !!endMonth && startMonth !== endMonth
              ? `${startMonth.format("MMM YYYY")} → ${endMonth.format(
                  "MMM YYYY",
                )}`
              : (startMonth || endMonth || props.defaultMonth).format(
                  "MMMM YYYY",
                )}
          </div>

          {fileExtenstion === "pdf" ? (
            <>
              <DatePicker
                className="min-w-12"
                disabledDate={(current) => {
                  let customDate = today.format("MMMM YYYY");
                  return (
                    current &&
                    current >= dayjs(customDate, "MMMM YYYY").add(1, "month")
                  );
                }}
                picker="month"
                format={"MMMM YYYY"}
                value={startMonth}
                clearIcon={false}
                onChange={(m) => {
                  if (!!m) {
                    props.onMonthChange(m);
                    setStartMonth(m);
                    setEndMonth(m);
                  }
                }}
                dateRender={() => <div>dateRender</div>}
              />
            </>
          ) : (
            <GCDownloadDailyReportsMonthsRangePicker
              endMonth={endMonth}
              startMonth={startMonth}
              maxMonthsNumber={3}
              onEndMonthChange={setEndMonth}
              onStartMonthChagne={setStartMonth}
            />
          )}
        </div>
        <div className="flex flex-col gap-0.5 w-full items-center">
          <div className="text-1.5 text-center">{`${
            props.subcontractors.selected === undefined
              ? "All Subcontractors"
              : props.subcontractors.all.find(
                  ({ id }) => id === props.subcontractors.selected,
                )?.name || "Subcontractor"
          }`}</div>
          <Select
            className="min-w-12"
            value={props.subcontractors.selected || "all"}
            onChange={(newValue) => {
              props.subcontractors.selectSubcontractor(
                newValue !== "all" ? newValue : undefined,
              );
            }}
          >
            <Select.Option value={"all"}>All</Select.Option>
            {props.subcontractors.all.map((subOption) => (
              <Select.Option key={subOption.id} value={subOption.id}>
                {subOption.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </Modal>
  );
};

export default GCProjectReportsDailyMonthDownloadModal;
