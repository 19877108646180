import dayjs from "dayjs";
import React from "react";
import { InspectionResultType } from "./tables/InspectionResultSummaryTable";
import { Popover } from "antd";
import capitalize from "src/common/functions/capitalize";

type InspectionAnsType = InspectionResultType["inspection_answers"][number];

interface StatusItemType extends InspectionAnsType {
  correctedOn?: dayjs.Dayjs;
  correctedBy?: string;
}

const ShowInspectionResultStatus: React.FC<{
  answers: InspectionResultType["inspection_answers"];
}> = ({ answers }) => {
  let correctedDefs = 0;
  const defItems: StatusItemType[] = [];
  answers.forEach((inspAns) => {
    if (
      inspAns.option_response !== "na" &&
      inspAns.option_response !==
        inspAns.inspection_instance_checklist_item.correct_answer
    ) {
      const correctedItem =
        inspAns.correction_inspection_answer?.[0]?.corrected_inspection_answer;
      if (inspAns.inspection_checklist_item_deficient_type?.is_immediate_fix) {
        correctedDefs++;
        defItems.push(inspAns);
      } else if (
        correctedItem
        // &&
        // dayjs(correctedItem.created_at).isAfter(
        //   dayjs(inspAns.created_at)
        // )  this part is being checked in the function already
      ) {
        correctedDefs++;
        defItems.push({
          ...inspAns,
          correctedOn: dayjs(correctedItem.created_at),
          correctedBy:
            correctedItem.inspection_result.user_name ??
            correctedItem.inspection_result.user?.name,
        });
      } else defItems.push(inspAns);
    }
  });
  return (
    <div className="flex justify-between text-1.25">
      {defItems.length > 0 ? (
        <Popover
          trigger={"hover"}
          placement="right"
          content={defItems.map((def, i) => (
            <div key={def.id} className="max-w-32">
              {i + 1}). {def.inspection_instance_checklist_item.description.en}
              <div className="ml-0.5">
                <div className="text-semantic-negative">
                  <span className="text-grey">Answer: </span>{" "}
                  {capitalize(def.option_response)}
                </div>
                <span className="text-grey">
                  Make-safe or corrective action taken:{" "}
                </span>
                {def.inspection_checklist_item_deficient_type ? (
                  <>
                    <span>
                      {def.inspection_checklist_item_deficient_type.text.en}
                    </span>
                    {def.text_response ? (
                      <div>
                        <span className="text-grey">
                          Additional Information Provided:{" "}
                        </span>
                        {def.text_response.en}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  def.text_response && (
                    <span>Other, {def.text_response.en}</span>
                  )
                )}
                {def.inspection_checklist_item_deficient_type
                  ?.is_immediate_fix ? (
                  <div className="text-purple">Immediate Action was taken</div>
                ) : def.correctedOn ? (
                  <div>
                    <span className="text-grey">
                      Re-Inspection with Correction:{" "}
                    </span>
                    <span className="text-semantic-positive-green font-accent">
                      {def.correctedOn.format("MM/DD/YYYY")} by{" "}
                      {def.correctedBy ?? ""}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
        >
          <div
            className={`p-0.5 rounded-full ${
              defItems.length === correctedDefs
                ? "text-purple"
                : "text-semantic-negative"
            }`}
          >
            {defItems.length === correctedDefs ? "Corrected" : "Failed"}
          </div>
        </Popover>
      ) : (
        <div className="p-0.5 rounded-full text-semantic-positive-green">
          Pass
        </div>
      )}
    </div>
  );
};
export default ShowInspectionResultStatus;
