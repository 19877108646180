import { IconArrowBackUp, IconSettings } from "@tabler/icons";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "src/common/components/general/Button";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import SCReportsDailyDate from "./components/SCReportsDailyDate";
import SCReportsDailySingle from "./components/SCReportsDailySingle";
import SCReportsDailyTable from "./components/SCReportsDailyTable";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";

interface SCReportsDailyProps {
}

const SCReportsDaily: React.FunctionComponent<SCReportsDailyProps> = (
  props
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { projectId } = useParams();

  const subcontractorId = useSubcontractorId();

  const reportId = searchParams.get("reportId");
  const date = searchParams.get("date");

  const handleSettingsButtonClick = () => {
    navigate("settings");
  };

  const handleOpenReport = (params: { date: string } | { id: string }) => {
    if ("date" in params) {
      setSearchParams({ date: params.date });
    } else if ("id" in params) {
      setSearchParams({ reportId: params.id });
    }
  };

  const clearSearchParams = () => {
    setSearchParams({});
  };

  return (
    <BasicWrapper scrollable>
      {subcontractorId && (
        <>
          <div className="flex flex-row-reverse gap-1 justify-between">
            <Button
              onClick={() =>
                projectId
                  ? navigate(`/sub/projects/${projectId}/daily-settings`)
                  : handleSettingsButtonClick()
              }
              label={`Settings`}
              icon={IconSettings}
            />
            {(reportId || date) && (
              <Button
                label="back"
                icon={IconArrowBackUp}
                onClick={clearSearchParams}
              />
            )}
          </div>
          {reportId ? (
            <SCReportsDailySingle id={reportId} />
          ) : date ? (
            <SCReportsDailyDate
              openReport={handleOpenReport}
              {...{ date, subcontractorId, projectId }}
            />
          ) : (
            <SCReportsDailyTable
              openReport={handleOpenReport}
              {...{ subcontractorId, projectId }}
            />
          )}
        </>
      )}
    </BasicWrapper>
  );
};

export default SCReportsDaily;
