import { Select } from "antd";
import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import {
  GetInspectionDataOutput,
  InspectionChecklistItem,
} from "src/common/types/generated/apollo/graphQLTypes";
import {
  CheclistItemAnswerType,
  OptionResponseType,
} from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import TextAreaAutoSize from "react-textarea-autosize";
import useLangStrings, {
  useCurrentLanguage,
  useDBLangSelector,
} from "../../../../../../utility-features/i18n/context/languageHooks";

interface SiteInspectionWorkerChecklistItemUIProps
  extends InspectionChecklistItem {
  deficientTypeOptions: GetInspectionDataOutput["deficientAnswerSelectTypes"];
  onTextChange: (text: string) => void;
  showOptions?: boolean;
  hideIncorrect?: boolean;
  initialValue?: CheclistItemAnswerType;
  onDeficientTypeSelect: (
    deficientTypeId: string | undefined,
    immediateFix: boolean
  ) => void;
  onOptionResponse: (res: OptionResponseType) => void;
}

const SiteInspectionWorkerChecklistItemUI: FC<
  SiteInspectionWorkerChecklistItemUIProps
> = (props) => {
  const language = useCurrentLanguage();
  const gs = useDBLangSelector(language);
  const langStrings = useLangStrings();
  const [selectedResponse, setSelectedResponse] = useState<
    OptionResponseType | undefined
  >(props.initialValue?.option_response);
  const [selectedDeficientType, setSelectedDeficientType] = useState(
    props.initialValue?.deficient_type_id ||
      (props.initialValue?.text_response ? "other" : "")
  );
  const [otherReasonSelected, setOtherReasonSelected] = useState(
    !props.initialValue?.deficient_type_id &&
      !!props.initialValue?.option_response
  );
  const [immediateFixSelected, setImmediateFixSelected] = useState(
    props.initialValue?.deficient_type_id &&
      !!props.deficientTypeOptions.find(
        (def) =>
          def.immediateFix && def.id === props.initialValue?.deficient_type_id
      )
  );
  const [wrongSelected, setWrongSelected] = useState(false);
  const [text, setText] = useState(props.initialValue?.text_response);
  useEffect(() => {
    if (selectedResponse)
      setWrongSelected(
        selectedResponse !== "na" && selectedResponse !== props.correct_answer
      );
    else setWrongSelected(false);
  }, [selectedResponse]);
  const options = [
    {
      title: "Yes",
      type: "yes",
      selectedColor:
        props.correct_answer === "yes"
          ? "semantic-positive-green"
          : "semantic-negative",
    },
    {
      title: "No",
      type: "no",
      selectedColor:
        props.correct_answer === "no"
          ? "semantic-positive-green"
          : "semantic-negative",
    },
  ].filter((option) =>
    props.hideIncorrect ? option.type === props.correct_answer : true
  ) as {
    title: string;
    type: OptionResponseType;
    selectedColor: string;
  }[];
  if (props.show_na)
    options.push({
      title: "N/A",
      type: "na",
      selectedColor: "interactive-primary",
    });
  return (
    <div className="mb-2 mx-0.5">
      <div className="mb-1 text-1">
        {gs(props.description)}
        {wrongSelected && <span className="text-semantic-negative">*</span>}
      </div>
      {props.showOptions ? (
        <div className="flex flex-row items-center">
          {options.map(({ title, selectedColor, type }, i) => (
            <button
              key={type}
              className={clsx(
                `px-1 py-0.5 border-0 mr-px`,
                selectedResponse === type
                  ? `bg-${selectedColor} text-white border-0.125 border-${selectedColor}`
                  : "bg-white text-grey border-0.125 border-suplementary-2",
                i === 0 ? "rounded-l-0.25 " : "",
                i + 1 === options.length ? "rounded-r-0.25" : ""
              )}
              onClick={() => {
                setSelectedResponse(type);
                props.onOptionResponse(type);
              }}
            >
              {title}
            </button>
          ))}
        </div>
      ) : (
        <></>
      )}
      {/* Text Input */}
      {wrongSelected ? (
        <div className="mt-1">
          {langStrings.strings.whatActionDidYouTake}
          <Select
            className="w-full"
            value={selectedDeficientType}
            onSelect={(deficientType) => {
              setSelectedDeficientType(deficientType);
              if (deficientType === "other") {
                setOtherReasonSelected(true);
                setImmediateFixSelected(false);
                props.onDeficientTypeSelect(undefined, false);
              } else {
                const immediateFix =
                  props.deficientTypeOptions.findIndex(
                    (def) => def.immediateFix && def.id === deficientType
                  ) > -1;
                props.onDeficientTypeSelect(deficientType, immediateFix);
                setImmediateFixSelected(immediateFix);
                setOtherReasonSelected(false);
              }
            }}
          >
            {props.deficientTypeOptions.map((deficientType) => (
              <Select.Option value={deficientType.id} key={deficientType.id}>
                {gs(deficientType.name, deficientType.name.en)}
              </Select.Option>
            ))}
            <Select.Option value={"other"} key={"other"}>
              {langStrings.strings.other}
            </Select.Option>
          </Select>
          {selectedDeficientType && (
            <div className="pt-1 ml-1">
              {otherReasonSelected || immediateFixSelected ? (
                <div>
                  {langStrings.strings.whatActionDidYouTake}{" "}
                  <span className="text-semantic-negative">*</span>
                </div>
              ) : (
                langStrings.strings.additionalInformation
              )}

              <div className="flex items-center">
                <TextAreaAutoSize
                  className={`rounded-0.5 border-px border-suplementary-3 flex-1 resize-none outline-none p-0.75 whitespace-pre-wrap break-word`}
                  value={text}
                  placeholder={
                    otherReasonSelected || immediateFixSelected
                      ? langStrings.strings.whatDidYouDoToMakeSafe
                      : langStrings.strings.TypeAnyAdditionalInformationHere
                  }
                  onChange={(e) => {
                    setText(e.target.value);
                    props.onTextChange(e.target.value);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default SiteInspectionWorkerChecklistItemUI;
