import React from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";

const searchParamsProvider = (Component: React.FunctionComponent<any>) => {
  interface WrappedComponentProps {}

  const WrappedComponent: React.FunctionComponent<
    WrappedComponentProps
  > = () => {
    const [searchParams] = useSearchParams();
    const params = useParams();
    return (
      <Component {...{ ...Object.fromEntries(searchParams), ...params }}>
        <Outlet />
      </Component>
    );
  };
  return <WrappedComponent />;
};

export default searchParamsProvider;
