import NestedRoute from "../../../../../common/types/manual/NestedRoute";
import { Outlet } from "react-router-dom";
import React from "react";
import SCSds from "../../components/SCSds";

const scSdsRoute: NestedRoute = {
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <SCSds />,
    },
  ],
};

export default scSdsRoute;
