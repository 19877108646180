import {
  IconCheck,
  IconInfoCircle,
  IconMail,
  IconDeviceMobile,
  IconCopy,
  IconUser,
} from "@tabler/icons";
import { Button as AntdButton } from "antd";
import React, { useEffect, useState } from "react";
import Button from "src/common/components/general/Button";
import Icon from "src/common/components/general/Icon";
import CustomButton from "src/common/components/general/Button";
import { useCopyToClipboard } from "usehooks-ts";
import { message } from "antd";
import clsx from "clsx";

interface WorkerProfileContactLinkProps {
  type: "phone_number" | "email" | "name";
  currentValue?: string; // the initial contact info
  onUpdate: (newValue: string) => void;
  hideIcon?: boolean;
  hideCopy?: boolean;
  disabled?: boolean;
  saveButtonDesign?: "primary" | "save";
}

const WorkerProfileContactLink: React.FunctionComponent<
  WorkerProfileContactLinkProps
> = (props) => {
  const [copiedText, copyFn] = useCopyToClipboard();
  const [value, setValue] = useState(props.currentValue ?? "");
  const icon =
    props.type === "phone_number"
      ? IconDeviceMobile
      : props.type === "email"
      ? IconMail
      : props.type === "name"
      ? IconUser
      : IconInfoCircle;

  const handleSubmit = () => {
    props.onUpdate(value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  useEffect(() => {
    if (!!props.currentValue) setValue(props.currentValue);
  }, [props.currentValue]);

  return (
    <div
      className="flex flex-row items-center w-full mt-0.5"
      onClick={(e) => e.stopPropagation()}
    >
      {!props.hideIcon && <Icon color="static" icon={icon} />}
      {
        <div className="ml-0.5 flex flex-row">
          <input
            disabled={props.disabled}
            value={value}
            type="text"
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            placeholder={`Contact info`}
            className={clsx(
              props.disabled
                ? "w-10"
                : "border-b-px border-suplementary-3 w-10",
              props.type === "name" && "font-accent",
            )}
          />
          <div
            className={`${
              props.currentValue !== value && !!value.length && !props.disabled
                ? "opacity-100 pointer-events-auto"
                : "opacity-0 pointer-events-none"
            } transition-all`}
          >
            {props.saveButtonDesign === "save" ? (
              <AntdButton type="primary" onClick={handleSubmit}>
                Save
              </AntdButton>
            ) : (
              <Button
                onClick={handleSubmit}
                icon={IconCheck}
                small
                secondary={props.saveButtonDesign !== "primary"}
              />
            )}
          </div>

          {!props.hideCopy && (
            <CustomButton
              icon={IconCopy}
              secondary
              hint={"Copy"}
              onClick={() => {
                copyFn(value);
                message.success({
                  content:
                    props.type === "email"
                      ? "email copied"
                      : "phone number copied",
                });
              }}
            />
          )}
        </div>
      }
    </div>
  );
};

export default WorkerProfileContactLink;
