/**
 * @generated SignedSource<<ea2c33bcf8d2bfd1601c6ff640921a84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GCBillingInvoicesWorkerQuery$variables = {
  projectId: string;
  userId: string;
};
export type GCBillingInvoicesWorkerQuery$data = {
  readonly general_contractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly billing_settings: {
          readonly gc_price_type: string;
          readonly payment_model: string;
        } | null | undefined;
        readonly projects: ReadonlyArray<{
          readonly billing_settings: {
            readonly gc_price_type: string;
            readonly payment_model: string;
            readonly use_project_level_price: boolean;
          } | null | undefined;
        }>;
      };
    }>;
  };
  readonly invoice_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly amount: number;
        readonly created_at: string;
        readonly due_date: string | null | undefined;
        readonly id: string;
        readonly invoice_pdf: string | null | undefined;
        readonly lines: ReadonlyArray<{
          readonly amount: number;
          readonly date_from: string | null | undefined;
          readonly date_to: string | null | undefined;
          readonly description: string;
          readonly id: string;
          readonly price_fixed: number | null | undefined;
          readonly price_type: string | null | undefined;
          readonly project_name: string | null | undefined;
          readonly quantity: number;
          readonly state_json: string | null | undefined;
          readonly unit_price_first: number | null | undefined;
          readonly unit_price_others: number | null | undefined;
        }>;
        readonly number: string | null | undefined;
        readonly paid: boolean;
        readonly payment_url: string | null | undefined;
        readonly period_end: string | null | undefined;
        readonly period_start: string | null | undefined;
        readonly status: string;
        readonly stripe_invoice_id: string;
      };
    }>;
  };
};
export type GCBillingInvoicesWorkerQuery = {
  response: GCBillingInvoicesWorkerQuery$data;
  variables: GCBillingInvoicesWorkerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "userId"
          }
        ],
        "kind": "ObjectValue",
        "name": "uid"
      }
    ],
    "kind": "ObjectValue",
    "name": "employees"
  }
],
v3 = [
  {
    "fields": (v2/*: any*/),
    "kind": "ObjectValue",
    "name": "general_contractor"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "projectId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 12
    },
    {
      "kind": "Literal",
      "name": "order_by",
      "value": {
        "period_start": "desc"
      }
    },
    {
      "fields": [
        {
          "kind": "Literal",
          "name": "status",
          "value": {
            "_in": [
              "open",
              "paid"
            ]
          }
        },
        {
          "fields": [
            {
              "items": [
                {
                  "fields": [
                    {
                      "fields": (v3/*: any*/),
                      "kind": "ObjectValue",
                      "name": "general_contractor_billing_settings"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "_or.0"
                },
                {
                  "fields": [
                    {
                      "fields": [
                        {
                          "fields": (v3/*: any*/),
                          "kind": "ObjectValue",
                          "name": "project"
                        },
                        {
                          "fields": (v4/*: any*/),
                          "kind": "ObjectValue",
                          "name": "project_id"
                        }
                      ],
                      "kind": "ObjectValue",
                      "name": "project_billing_settings"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "_or.1"
                }
              ],
              "kind": "ListValue",
              "name": "_or"
            }
          ],
          "kind": "ObjectValue",
          "name": "stripe_account"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "invoiceConnection",
  "kind": "LinkedField",
  "name": "invoice_connection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "invoiceEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "invoice",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "due_date",
              "storageKey": null
            },
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created_at",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stripe_invoice_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "period_start",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "period_end",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "payment_url",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "invoice_pdf",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "invoice_line",
              "kind": "LinkedField",
              "name": "lines",
              "plural": true,
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "project_name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "state_json",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price_type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price_fixed",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unit_price_first",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unit_price_others",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date_from",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date_to",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "fields": (v2/*: any*/),
    "kind": "ObjectValue",
    "name": "where"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payment_model",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gc_price_type",
  "storageKey": null
},
v11 = [
  {
    "fields": [
      {
        "fields": (v4/*: any*/),
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "use_project_level_price",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCBillingInvoicesWorkerQuery",
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "general_contractorConnection",
        "kind": "LinkedField",
        "name": "general_contractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_billing_settings",
                    "kind": "LinkedField",
                    "name": "billing_settings",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "projects",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_billing_settings",
                        "kind": "LinkedField",
                        "name": "billing_settings",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCBillingInvoicesWorkerQuery",
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "general_contractorConnection",
        "kind": "LinkedField",
        "name": "general_contractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_billing_settings",
                    "kind": "LinkedField",
                    "name": "billing_settings",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "projects",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_billing_settings",
                        "kind": "LinkedField",
                        "name": "billing_settings",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33c3d070dc272a08fd57fc96616ec78b",
    "id": null,
    "metadata": {},
    "name": "GCBillingInvoicesWorkerQuery",
    "operationKind": "query",
    "text": "query GCBillingInvoicesWorkerQuery(\n  $userId: uuid!\n  $projectId: uuid!\n) {\n  invoice_connection(where: {status: {_in: [\"open\", \"paid\"]}, stripe_account: {_or: [{general_contractor_billing_settings: {general_contractor: {employees: {uid: {_eq: $userId}}}}}, {project_billing_settings: {project_id: {_eq: $projectId}, project: {general_contractor: {employees: {uid: {_eq: $userId}}}}}}]}}, order_by: {period_start: desc}, first: 12) {\n    edges {\n      node {\n        id\n        due_date\n        amount\n        status\n        created_at\n        stripe_invoice_id\n        period_start\n        period_end\n        paid\n        number\n        payment_url\n        invoice_pdf\n        lines {\n          id\n          description\n          project_name\n          quantity\n          amount\n          state_json\n          price_type\n          price_fixed\n          unit_price_first\n          unit_price_others\n          date_from\n          date_to\n        }\n      }\n    }\n  }\n  general_contractor_connection(where: {employees: {uid: {_eq: $userId}}}) {\n    edges {\n      node {\n        billing_settings {\n          payment_model\n          gc_price_type\n          id\n        }\n        projects(where: {id: {_eq: $projectId}}) {\n          billing_settings {\n            use_project_level_price\n            payment_model\n            gc_price_type\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b5608155101e900e233821724da17bf";

export default node;
