import { Checkbox } from "antd";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableRef,
} from "./basic/DataScrollTable";
import { ScrollTableColumn } from "./basic/ScrollTable";
import {
  useEmailEmployeeVerificationMutation,
  useUpdateWorkerMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { forwardRef, useRef, useState } from "react";

import ActionButton from "src/domain-features/sitesafety/job-hazard-analysis/components/action-buttons/ActionButton";
import ActionButtonsPopover from "../popover/ActionButtonsPopover";
import CustomButton from "src/common/components/general/Button";
import { GCWorkersTableQuery } from "../../types/generated/relay/GCWorkersTableQuery.graphql";
import { GCWorkersTable_project_worker$data } from "../../types/generated/relay/GCWorkersTable_project_worker.graphql";
import { GetConnectionNode } from "src/common/functions/getConnectionNodes";
import { IconBriefcase } from "@tabler/icons";
import { IconDotsVertical } from "@tabler/icons";
import { executeWithMessage } from "src/common/functions/asyncUtility/executeWithMessage";
import { graphql } from "babel-plugin-relay/macro";
import withCustomSuspense from "../general/withCustomSuspense";
import {
  WorkerStatusEnum,
  getWorkerStatusValue,
  toWorkerStatus,
} from "src/common/enums/WorkerStatusEnum";

const CONNECTION_NAME = "project_worker_connection";
export interface Subcontractor {
  id: string;
  name: string;
}
export type ColumnKeys =
  | "name"
  | "subcontractor"
  | "hardHatNumber"
  | "registeredDate"
  | "project"
  | "status"
  | "rowLevelActions"
  | "select";

export type DConnection =
  GCWorkersTable_project_worker$data[typeof CONNECTION_NAME];

type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  GCWorkersTableQuery,
  { pk: string }
> & {
  subContractorFilterOptions?: Subcontractor[];
  userId: string;
};

const STATUS_PRIORITY: { [key in WorkerStatusEnum]: number } = {
  [WorkerStatusEnum.NOT_YET_VERIFIED]: 1,
  [WorkerStatusEnum.VERIFIED]: 2,
  [WorkerStatusEnum.NOT_ALLOWED_TO_WORK]: 3,
  [WorkerStatusEnum.ARCHIVED]: 4,
};

function getSuffix(s: string, len: number) {
  return s.substring(Math.max(s.length - len, 0));
}

const GCWorkersTable = forwardRef<DataScrollTableRef, Props>(
  (
    { where: projectWorkerWhere, subContractorFilterOptions, userId, ...props },
    _ref,
  ) => {
    const dataScrollTableRef = useRef<DataScrollTableRef>();
    const [emailEmployeeVerification] = useEmailEmployeeVerificationMutation();
    const [updateWorker] = useUpdateWorkerMutation();
    const [selectedWorkerIds, setSelectedWorkerIds] = useState<Array<string>>(
      [],
    );
    // #region columns
    const columns: Array<
      ScrollTableColumn<
        GetConnectionNode<DConnection, { pk: string }>,
        ColumnKeys
      > & {
        queryIncludeVarKey?: keyof GCWorkersTableQuery["variables"];
      }
    > = [
      {
        onCell: (e) => ({
          onClick: (e) => {
            e.stopPropagation();
          },
        }),
        cellPadding: "sm",
        title: "",
        size: "icon",
        align: "right",
        dataIndex: [],
        key: "select",
        render: (_, row) => {
          return (
            <div className="p-1" onClick={(e) => e.stopPropagation()}>
              <Checkbox
                disabled={
                  row.worker.status !==
                  WorkerStatusEnum.NOT_YET_VERIFIED.toString()
                }
                key={row.pk}
                onChange={(e) => {
                  e.stopPropagation();
                  if (e.target.checked)
                    setSelectedWorkerIds((reportIds) => {
                      return [row.pk, ...reportIds];
                    });
                  else
                    setSelectedWorkerIds((reportIds) =>
                      reportIds.filter((id) => id !== row.pk),
                    );
                }}
              ></Checkbox>
            </div>
          );
        },
      },
      {
        cellPadding: "sm",
        title: "Subcontractor",
        key: "subcontractor",
        size: "md",
        dataIndex: ["subcontractor", "name"],
        searchDataIndex: ["subcontractor", "name"],
        contentType: {
          type: "with-icon",
          renderOptions: (value) => ({
            icon: IconBriefcase,
            label: value,
          }),
        },
        ...(subContractorFilterOptions?.length
          ? {
              filters: {
                initialClose: true,
                type: "checklist",
                dataIndex: ["subcontractor", "id"],
                options: subContractorFilterOptions.map((subContractor) => {
                  return {
                    text: subContractor.name,
                    value: subContractor.id,
                  };
                }),
              },
            }
          : {}),
      },
      {
        cellPadding: "sm",
        title: "Hard Hat #",
        key: "hardHatNumber",
        sortable: true,
        clientCompareFn: (a, b) => {
          const sa = getSuffix("0000000000" + (a.hard_hat_number || ""), 10);
          const sb = getSuffix("0000000000" + (b.hard_hat_number || ""), 10);

          return sa === sb ? 0 : sa < sb ? 1 : -1;
        },
        dataIndex: ["hard_hat_number"],
        searchDataIndex: ["hard_hat_number"],
      },
      {
        cellPadding: "sm",
        title: "Worker",
        key: "name",
        contentType: {
          type: "row-title",
        },
        dataIndex: ["user", "name"],
        searchDataIndex: ["user", "name"],
      },
      {
        cellPadding: "sm",
        sortable: true,
        title: "Status",
        filters: {
          type: "checklist",
          dataIndex: ["worker", "status"],
          options: [
            {
              text: getWorkerStatusValue(WorkerStatusEnum.VERIFIED),
              value: WorkerStatusEnum.VERIFIED.toString(),
            },
            {
              text: getWorkerStatusValue(WorkerStatusEnum.NOT_YET_VERIFIED),
              value: WorkerStatusEnum.NOT_YET_VERIFIED.toString(),
            },
            {
              text: getWorkerStatusValue(WorkerStatusEnum.NOT_ALLOWED_TO_WORK),
              value: WorkerStatusEnum.NOT_ALLOWED_TO_WORK.toString(),
            },
            {
              text: getWorkerStatusValue(WorkerStatusEnum.ARCHIVED),
              value: WorkerStatusEnum.ARCHIVED.toString(),
            },
          ],
          defaultValues: [WorkerStatusEnum.NOT_YET_VERIFIED.toString()],
        },
        key: "status",
        size: "md",
        dataIndex: ["worker", "status"],
        clientCompareFn: (a, b) => {
          return (
            STATUS_PRIORITY[toWorkerStatus(a.worker.status)] -
            STATUS_PRIORITY[toWorkerStatus(b.worker.status)]
          );
        },
        render: (value) => {
          return getWorkerStatusValue(value);
        },
        includeInMainSearchBox: false,
      },
      {
        sortable: true,
        cellPadding: "sm",
        defaultSortOrder: "desc",
        title: "Registered Date",
        key: "registeredDate",
        size: "md",
        dataIndex: ["created_at"],
        dateRangeSearchIndex: ["created_at"],
        contentType: {
          type: "date",
          renderOptions: () => ({
            format: "mmddyyyy",
          }),
        },
      },
      {
        cellPadding: "sm",
        title: "Project",
        key: "project",
        size: "md",
        dataIndex: ["project", "name"],
        searchDataIndex: ["project", "name"],
        includeInMainSearchBox: false,
      },
      {
        cellPadding: "sm",
        title: "",
        dataIndex: [""],
        key: "rowLevelActions",
        size: "xsm",
        fixed: "right",

        render: (_: any, row, i: number) => {
          return row.worker.status ===
            WorkerStatusEnum.NOT_YET_VERIFIED.toString() ? (
            <div
              className="flex w-4"
              key={i}
              onClick={(e) => e.stopPropagation()}
            >
              <span>
                <ActionButtonsPopover
                  popoverProps={{
                    trigger: "click",
                    placement: "topLeft",
                  }}
                  actionButtons={[
                    {
                      label: "Resend request to verify",
                      onClick: () => {
                        executeWithMessage({
                          type: "loading",
                          initialContent: `Preparing...`,
                          successContent: `Email sent successfully!`,
                          errorMessageDuration: 10,
                          asyncFunction: async () => {
                            await emailEmployeeVerification({
                              variables: {
                                input: {
                                  projectWorkerIds: [row.pk],
                                  userId: userId,
                                },
                              },
                            });
                          },
                        });
                      },
                    },
                    {
                      label: "Archive",
                      onClick: () => {
                        executeWithMessage({
                          type: "loading",
                          initialContent: `Archiving...`,
                          successContent: `Archived successfully!`,
                          asyncFunction: async () => {
                            await updateWorker({
                              variables: {
                                where: { uid: { _eq: row.worker.uid } },
                                _set: {
                                  status: WorkerStatusEnum.ARCHIVED.toString(),
                                },
                              },
                            });
                            dataScrollTableRef?.current?.refetch();
                          },
                        });
                      },
                    },
                    {
                      label: "Not Allowed to Work",
                      onClick: () => {
                        executeWithMessage({
                          type: "loading",
                          initialContent: `Updating status...`,
                          successContent: `Updated successfully!`,
                          asyncFunction: async () => {
                            await updateWorker({
                              variables: {
                                where: { uid: { _eq: row.worker.uid } },
                                _set: {
                                  status:
                                    WorkerStatusEnum.NOT_ALLOWED_TO_WORK.toString(),
                                },
                              },
                            });
                            dataScrollTableRef?.current?.refetch();
                          },
                        });
                      },
                    },
                  ]}
                >
                  <CustomButton
                    icon={IconDotsVertical}
                    secondary
                    onClick={() => {}}
                  />
                </ActionButtonsPopover>
              </span>
            </div>
          ) : (
            <></>
          );
        },
      },
    ];
    // #endregion

    return (
      <DataScrollTable<DConnection, ColumnKeys, GCWorkersTableQuery>
        newCustomTableLook
        filterNotVisibleByDefault
        {...props}
        ref={dataScrollTableRef}
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allProjectWorkersConnection"}
        where={{
          ...projectWorkerWhere,
        }}
        columns={columns}
        topBarButtons={
          selectedWorkerIds.length > 0
            ? [
                {
                  icon: IconDotsVertical,
                  secondary: true,
                  fake: true,
                  hint: (
                    <div className="w-10">
                      <ActionButton
                        label="Resend requests to verify"
                        onClick={() => {
                          executeWithMessage({
                            type: "loading",
                            initialContent: `Preparing ...`,
                            successContent: `Email sent successfully!`,
                            errorMessageDuration: 10,
                            asyncFunction: async () => {
                              await emailEmployeeVerification({
                                variables: {
                                  input: {
                                    projectWorkerIds: selectedWorkerIds,
                                    userId: userId,
                                  },
                                },
                              });
                            },
                          });
                        }}
                      ></ActionButton>
                    </div>
                  ),
                },
              ]
            : undefined
        }
        //TODO: Update query to get data from project_subcontractor
        //Check firebase-functions GET_PROJECT_SUBCONTRACTORS_WITH_SUB_EMPLOYEES_AND_WORKERS
        queryNode={graphql`
          query GCWorkersTableQuery(
            $first: Int!
            $after: String
            $where: project_worker_bool_exp!
            $order_by: [project_worker_order_by!]!
          ) {
            ...GCWorkersTable_project_worker
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
            ...GCWorkersTable_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment GCWorkersTable_total on query_root
          @argumentDefinitions(where: { type: "project_worker_bool_exp!" })
          @refetchable(queryName: "GCWorkerTableTotalRefetchableQuery") {
            allProjectWorkersConnection: project_worker_connection(
              where: $where
            ) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment GCWorkersTable_project_worker on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "project_worker_bool_exp!" }
            order_by: { type: "[project_worker_order_by!]!" }
          )
          @refetchable(queryName: "GCWorkerTableRefetchableQuery") {
            project_worker_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "GCWorkersTable_project_worker_connection"
                filters: ["where", "order_by"]
              ) {
              edges {
                node {
                  pk: id @__clientField(handle: "pk")
                  hard_hat_number
                  created_at
                  worker {
                    uid
                    status
                  }
                  subcontractor {
                    id
                    name
                    subcontractor_employees(
                      where: { user: { created_password: { _eq: true } } }
                    ) {
                      user {
                        email
                      }
                    }
                  }
                  project {
                    name
                  }
                  user {
                    name
                  }
                }
              }
            }
          }
        `}
      ></DataScrollTable>
    );
  },
);

export default withCustomSuspense(GCWorkersTable);
