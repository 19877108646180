/**
 * @generated SignedSource<<e6f737109500899aad67e954208bb3c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddLogoModalSubInsertLogoMutation$variables = {
  Id: string;
  logoUrl?: string | null | undefined;
};
export type AddLogoModalSubInsertLogoMutation$data = {
  readonly update_subcontractor: {
    readonly returning: ReadonlyArray<{
      readonly logo_url: string | null | undefined;
    }>;
  } | null | undefined;
};
export type AddLogoModalSubInsertLogoMutation = {
  response: AddLogoModalSubInsertLogoMutation$data;
  variables: AddLogoModalSubInsertLogoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "Id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "logoUrl"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "logo_url",
        "variableName": "logoUrl"
      }
    ],
    "kind": "ObjectValue",
    "name": "_set"
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "Id"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo_url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddLogoModalSubInsertLogoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "subcontractor_mutation_response",
        "kind": "LinkedField",
        "name": "update_subcontractor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractor",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddLogoModalSubInsertLogoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "subcontractor_mutation_response",
        "kind": "LinkedField",
        "name": "update_subcontractor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractor",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6c95eff1aa46c0b40a3e9e97a75d27c6",
    "id": null,
    "metadata": {},
    "name": "AddLogoModalSubInsertLogoMutation",
    "operationKind": "mutation",
    "text": "mutation AddLogoModalSubInsertLogoMutation(\n  $Id: uuid!\n  $logoUrl: String\n) {\n  update_subcontractor(where: {id: {_eq: $Id}}, _set: {logo_url: $logoUrl}) {\n    returning {\n      logo_url\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "afbd9685c5db761db87033720408e7cb";

export default node;
