import React from "react";
import BaseTable, {
  BaseTableProps,
} from "src/common/components/tables/basic/BaseTable";
import styled from "styled-components";
import TableFooterTotal from "../TableFooterTotal";

// TODO add this to tables
type DataProp = {
  url: string | undefined;
  description: string | null;
  type?: string;
};

type Props = Omit<BaseTableProps<Partial<DataProp>>, "columns"> & {
  onDelete?: (r: DataProp) => any;
};
type ImagesTable = (p: Props) => JSX.Element;

const ImagesTable = styled(BaseTable).attrs(
  ({ pagination, onDelete, ...props }: Props) => {
    const columns = [
      {
        title: "Image",
        key: "image",
        dataIndex: ["url"],
        render: (url: string) => <img style={{ maxHeight: 400 }} src={url} />,
      },
      {
        title: "Description",
        key: "description",
        dataIndex: ["description"],
        render: (_: string, row: { type: string; description: any }) =>
          row.type === "signIn" ? <>Sign In Image</> : <>{row.description}</>,
      },
    ];

    return {
      ...props,
      columns,
      rowKey: (w: any) => w.id,
      summary: () =>
        pagination && (
          <TableFooterTotal cols={columns.length} total={pagination.total} />
        ),
    };
  }
)``;

export default ImagesTable as ImagesTable;
