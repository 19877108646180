import React, { useContext } from "react";
import siteOrientationOrienteeContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import SiteOrientationStepsUICertifications from "../../../../../../../components/stepsUI/certifications/SiteOrientationStepsUICertifications";

import { useCurrentLanguage } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsCertificationsProps
  extends SiteOrientationStepProps {
  managerDevice?: boolean;
}

const SiteOrientationInPersonWorkerStepsCertifications: React.FC<
  SiteOrientationInPersonWorkerStepsCertificationsProps
> = (props) => {
  const context = useSiteOrientationOrientee();
  const lang = useCurrentLanguage();
  if (context.orientee?.userType === "worker") {
    return (
      <SiteOrientationStepsUICertifications
        user={context.orientee}
        managerDevice={props.managerDevice}
        lang={lang}
        type={context.type}
        projectId={context.projectId}
        onNext={props.onNext}
        requireCerts={context.registrationOptions.requireCerts}
        projectCertifications={
          context.registrationOptions.projectCertifications
        }
        onBack={props.onBack}
        onCertificationsUpload={(newCerts) => {
          context?.updateOrientee((prevUserState) =>
            prevUserState?.userType === "worker"
              ? {
                  ...prevUserState,
                  certifications: {
                    ...prevUserState.certifications,
                    new: [
                      ...(prevUserState.certifications?.new || []),
                      ...newCerts,
                    ],
                  },
                }
              : prevUserState,
          );
        }}
      />
    );
  } else {
    return null;
  }
};

export default SiteOrientationInPersonWorkerStepsCertifications;
