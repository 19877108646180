import { Checkbox, Radio } from "antd";
import React, { FC } from "react";
import FilterControllerInput from "../FilterControllerInput";
import { StateFunctions } from "./CustomFilterController";

export type CustomRadioFilterProps = {
  title: string;
  removeFilter: () => void;
  options: Array<{
    option: string;
    optionType: "radio" | "checkbox";
    onCheckApply?: () => void;
    onCheckCancel?: () => void;
    onRadioClick?: () => void;
  }>;
} & StateFunctions;
const CustomRadioFilter: FC<CustomRadioFilterProps> = ({
  options,
  removeFilter,
  title,
  appliedFiltersfilterMap,
  setRemovalFilters,
  setAppliedFilter,
}) => {
  return (
    <>
      <FilterControllerInput
        title={title}
        removeFilter={() => {
          setAppliedFilter((prev) => {
            return {
              ...prev,
              [title]: false,
            };
          });

          removeFilter();
        }}
        component={
          <>
            {options.map(
              (
                {
                  option,
                  onRadioClick,
                  onCheckApply,
                  onCheckCancel,
                  optionType,
                },
                id
              ) => {
                return (
                  <div className="-m-0.25 w-full flex">
                    {optionType === "radio" ? (
                      <Radio
                        onClick={() => {
                          setAppliedFilter((prev) => ({
                            ...prev,
                            [title]: prev[title] === option ? false : option,
                          }));
                          onRadioClick && onRadioClick();
                          setRemovalFilters((prev) => ({
                            ...prev,
                            [title]: () => {
                              setAppliedFilter((prev) => {
                                return {
                                  ...prev,
                                  [title]: false,
                                };
                              });

                              removeFilter();
                            },
                          }));
                        }}
                        type="primary"
                        className={`text-1 ${id === 0 ? "" : "mt-0.5"}`}
                        checked={appliedFiltersfilterMap[title] === option}
                      >
                        {option}
                      </Radio>
                    ) : (
                      <Checkbox
                        className={`text-1 ${id === 0 ? "" : "mt-0.5"}`}
                        onChange={(val) => {
                          setAppliedFilter((prev) => ({
                            ...prev,
                            [title + "%" + option + "%" + id]:
                              val.target.checked,
                          }));
                          if (val.target.checked) {
                            onCheckApply && onCheckApply();
                          } else {
                            onCheckCancel && onCheckCancel();
                          }
                          setRemovalFilters((prev) => ({
                            ...prev,
                            [title + "%" + option + "%" + id]: () => {
                              onCheckCancel && onCheckCancel();
                              setAppliedFilter((prev) => ({
                                ...prev,
                                [title + "%" + option + "%" + id]: false,
                              }));
                            },
                          }));
                        }}
                        checked={
                          !!appliedFiltersfilterMap[
                            title + "%" + option + "%" + id
                          ]
                        }
                      >
                        {option}
                      </Checkbox>
                    )}
                  </div>
                );
              }
            )}
          </>
        }
      />
    </>
  );
};
export default CustomRadioFilter;
