import React from "react";

interface AgeProps {
  large?: boolean;
  value: number;
}

const Age: React.FunctionComponent<AgeProps> = ({ large, value }) => {
  return (
    <div className={`flex flex-row items-baseline`}>
      <span className={`font-accent ${large ? "text-1.5" : "text-1"}`}>
        {value}
      </span>
      <span className={`text-0.75`}>yo</span>
    </div>
  );
};

export default Age;
