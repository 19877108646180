import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import FloatingContactButton from "src/common/components/contact-button/FloatingContactButton";
import NavigationWrapper from "src/common/components/layouts/NavigationWrapper";
import { NavigationMenuProps } from "src/common/components/NavigationMenu";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";

const GCCorporateSettingsNavigation: React.FunctionComponent<
  CorporateAdminWrapperProps
> = ({ employee }) => {
  const navigate = useNavigate();

  const gc = employee.general_contractor;
  const menu: NavigationMenuProps = {
    navigationItems: [
      {
        label: "Orientations",
        rootPath: "orientations",
        onClick: () => {
          navigate("orientations");
        },
      },
      {
        label: "Permits",
        rootPath: "permits",
        onClick: () => {
          navigate("permits");
        },
      },
      ...(gc.incident_enabled
        ? [
            {
              label: "Incident Reporting",
              rootPath: "incident-reporting",
              onClick: () => {
                navigate("incident-reporting");
              },
            },
          ]
        : []),
      {
        label: "Corporate Settings",
        rootPath: "corporate-settings",
        onClick: () => {
          navigate("corporate-settings");
        },
      },
    ],
  };

  return (
    <div className="w-full h-full py-0.5 pr-0.5 bg-suplementary-1">
      <NavigationWrapper menu={menu}>
        <Outlet />
      </NavigationWrapper>
      <FloatingContactButton />
    </div>
  );
};

export default withCorporateAdminWrapper(GCCorporateSettingsNavigation);
