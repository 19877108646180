import React from "react";

export interface SubProjectMobilizationHeaderProps {
  projectTitle: string;
  showLoginText?: boolean;
}

const SubProjectMobilizationHeader: React.FC<
  SubProjectMobilizationHeaderProps
> = ({ projectTitle, showLoginText }) => {
  return (
    <div>
      <div className={`text-2`}>{projectTitle}</div>
      <div style = {{ fontWeight: "bold", marginTop : 3}}>Pre-Mobilization Documentation</div>
      {showLoginText && (
        <div className="mt-0.5">
          <a
            href="https://siteform-dev.web.app"
            className="underline text-interactive-primary"
          >
            Log in
          </a>{" "}
          to see all information and details
        </div>
      )}
    </div>
  );
};

export default SubProjectMobilizationHeader;
