import { signInWithCustomToken } from "firebase/auth";
import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { roleVar } from "src/common/api/apollo/cache";
import { auth } from "src/common/functions/firebase";
import { mergeUrlParams } from "src/common/functions/mergeUrlParams";
import { useSignInBySecretCodeMutation } from "src/common/types/generated/apollo/graphQLTypes";

interface SecretCodeSignInProps {
  code: string;
}

const SecretCodeSignInLink: FC<SecretCodeSignInProps> = ({ code }) => {
  const [SingInByCode, { loading, error }] = useSignInBySecretCodeMutation();
  const navigate = useNavigate();

  const signInUser = async () => {
    const { data } = await SingInByCode({ variables: { secretCode: code } });
    const reply = data?.signInBySecretCode;
    if (!reply) return;
    const { userId, projectId, role, token, finalUrl } = reply;

    const linkToOpen = finalUrl ? mergeUrlParams(finalUrl) : null;
    if (!linkToOpen) {
      return;
    }
    const user = auth.currentUser;
    if (user) {
      const res = await user.getIdTokenResult();
      if (res.claims.user_id === userId && role === res.claims.role) {
        navigate(linkToOpen, { replace: true });
        return;
      }
      roleVar("none");
      await auth.signOut();
    }
    await signInWithCustomToken(auth, token);
    navigate(linkToOpen, { replace: true });
  };

  useEffect(() => {
    signInUser();
  }, []);

  return (
    <div style={{ height: "100%", display: "flex", justifyContent: "center" }}>
      {loading && <p>loading...</p>}
      {error && <p>Error: {error.toString()}</p>}
    </div>
  );
};

export default SecretCodeSignInLink;
