import { graphql } from "babel-plugin-relay/macro";
import React, { FC } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import CreateProcessingContent from "src/common/components/CreateProcessingContent";
import { ProcessingCertCardQuery } from "src/common/types/generated/relay/ProcessingCertCardQuery.graphql";

const ProcessingCertCard: FC<{ requestIds: Array<string> }> =
  withCustomSuspense(({ requestIds }) => {
    const data = useLazyLoadQuery<ProcessingCertCardQuery>(
      graphql`
        query ProcessingCertCardQuery($requestIds: [uuid!]!) {
          certificates_to_verify_connection(
            where: { id: { _in: $requestIds } }
            first: 10000
          )
            @connection(
              key: "ProcessingCertCard_certificates_to_verify_connection"
              filters: ["where"]
            ) {
            edges {
              node {
                id
                pk: id @__clientField(handle: "pk")
                worker_id
                created_at
                project {
                  name
                  general_contractor {
                    name
                  }
                }
                front_image_id
                front_image {
                  url
                }
                uploaded_by_user {
                  ...UserTitleFrag @relay(mask: false)
                }
                back_image_id
                back_image {
                  url
                }
              }
            }
          }
        }
      `,
      { requestIds }
    );
    return (
      <CreateProcessingContent
        docsToVerify={data.certificates_to_verify_connection.edges.map(
          (p) => p.node
        )}
      />
    );
  });

export default ProcessingCertCard;
