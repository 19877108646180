import { Button } from "antd";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateGeneralContractorModal from "src/common/components/dialogs/CreateGeneralContractorModal";
import StyledContent from "src/common/components/layouts/StyledContent";
import GeneralContractorsTable from "src/root/routes/views/admin/data/general-contractors/GeneralContractorsTable/Table";
import { useInsertGeneralContractorsMutation } from "src/common/types/generated/apollo/graphQLTypes";

const AdminGeneralContractors = () => {
  const [createGcVisible, setCreateGcVisible] = useState(false);
  const [insertGcs, { loading }] = useInsertGeneralContractorsMutation();
  let tableRef = useRef<any>().current;
  const navigate = useNavigate();

  return (
    <StyledContent padding backgroundColor="white">
      <CreateGeneralContractorModal
        visible={createGcVisible}
        onCreate={async (values) => {
          setCreateGcVisible(false);

          await insertGcs({
            variables: {
              objects: [
                {
                  name: values.name,
                },
              ],
            },
          });

          tableRef && tableRef.refetch();
        }}
        onCancel={() => setCreateGcVisible(false)}
      />
      <GeneralContractorsTable
        loading={loading}
        onClickRow={(gc) => navigate(`/admin/data/gcs/${gc.id}`)}
        queryRef={(ref) => (tableRef = ref)}
        titleComponent={
          <Button
            loading={loading}
            onClick={() => {
              setCreateGcVisible(true);
            }}
            type="primary"
          >
            Create GC
          </Button>
        }
      />
    </StyledContent>
  );
};

export default AdminGeneralContractors;
