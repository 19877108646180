import React from "react";
import SiteBoardOnsiteColumn from "./SiteBoardOnsiteColumn";
import clsx from "clsx";

export interface ReportStatusProps {
  icon: string;
  type: "hasCrews" | "onlySub";
  amountSubmitted: number;
  total: number;
  color: string;
  header: string;
}

const ReportStatus: React.FC<ReportStatusProps> = ({
  icon,
  type,
  amountSubmitted,
  total,
  color,
  header,
}) => {
  const hasCrews = type === "hasCrews";
  return (
    <>
      {header == "TBT" ? (
        <>
          {
            <div
              className="px-1.5 relative rounded-1 h-full flex gap-1 flex-row items-center justify-between"
              style={{
                backgroundColor: total > 0 ? color : "#1B1B1B",
              }}
            >
              <div className="w-5 h-5 ">
                {total && (
                  <div
                    className={`flex items-center justify-center w-full h-full rounded-full ${
                      total
                        ? "bg-semantic-positive-dark"
                        : "bg-semantic-negative-dark"
                    }`}
                  >
                    <span className={`text-4 block`}>{icon}</span>
                  </div>
                )}
              </div>
            </div>
          }
        </>
      ) : (
        <div
          className={clsx(
            hasCrews && total > 1 ? "pr-1.5 pl-0.25" : "px-1.5",
            `relative rounded-1 h-full flex gap-1 flex-row items-center justify-between`,
          )}
          style={{
            backgroundColor: total > 0 ? color : "#1B1B1B",
          }}
        >
          <div className="w-5 h-5 ">
            {total > 0 && (
              <div
                className={`flex items-center justify-center w-full h-full rounded-full ${
                  amountSubmitted === total
                    ? "bg-semantic-positive-dark"
                    : "bg-semantic-negative-dark"
                }`}
              >
                <span className={`text-4 block`}>{icon}</span>
              </div>
            )}
          </div>
          {total > 0 && (
            <div
              className="absolute flex flex-col items-center justify-end bottom-0.5 right-0.5"
            >
              <span
                className="text-1.5 font-accent text-semantic-positive-dark"
              >
                {total === 1 || !hasCrews ? (
                  amountSubmitted > 0 ? (
                    amountSubmitted
                  ) : (
                    ""
                  )
                ) : (
                  <>
                    {amountSubmitted}
                    <span className="font-normal">/</span>
                    {total}
                  </>
                )}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export interface SiteBoardOnsiteReportColumnProps {
  reportsSubmitted: Omit<ReportStatusProps, "icon">[];
  shortTitle: string;
  longTitle: string;
  reportIcon: string;
  totalSubmitted: number;
  totalRequired: number;
}

const SiteBoardOnsiteReportColumn: React.FC<
  SiteBoardOnsiteReportColumnProps
> = (props) => {
  console.log(props, "props");
  return (
    <SiteBoardOnsiteColumn
      header={
        <div className={` text-white flex flex-col items-center w-full `}>
          <p className="block w-full text-center text-1.5 whitespace-nowrap">
            {props.longTitle}
          </p>
          <p className="text-1.5 font-accent">
            {`${props.totalSubmitted}`} <span className="font-normal">/</span>{" "}
            {`${props.totalRequired}`}
          </p>
        </div>
      }
      bodyRows={props.reportsSubmitted.map((report) => (
        <ReportStatus
          {...{ ...report, icon: props.reportIcon, header: props.longTitle }}
        />
      ))}
    />
  );
};

export default SiteBoardOnsiteReportColumn;
