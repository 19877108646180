import { Button, Form, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useState } from "react";
import { useSiteOrientationGcDashboardDataQuery$data } from "src/common/types/generated/relay/useSiteOrientationGcDashboardDataQuery.graphql";
import { SiteOrientationSettingAlternateCerts_Insert_Alternate_Certificates_Mutation } from "src/common/types/generated/relay/SiteOrientationSettingAlternateCerts_Insert_Alternate_Certificates_Mutation.graphql";
import { SiteOrientationSettingAlternateCerts_Delete_Alternate_Certificates_Mutation } from "src/common/types/generated/relay/SiteOrientationSettingAlternateCerts_Delete_Alternate_Certificates_Mutation.graphql";
import { ConnectionHandler } from "relay-runtime";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import getNormalOptionsFilter from "src/common/functions/getNormalOptionsFilter";

interface Props {
  projectId: string;
  certification: useSiteOrientationGcDashboardDataQuery$data["project_connection"]["edges"][0]["node"]["project_certifications"][0];
  allCertificates: useSiteOrientationGcDashboardDataQuery$data["certification_connection"]["edges"];
}

const mutationToAddAlternateCertificates = graphql`
  mutation SiteOrientationSettingAlternateCerts_Insert_Alternate_Certificates_Mutation(
    $objects: [project_alternate_certification_insert_input!]!
  ) {
    insert_project_alternate_certification(objects: $objects) {
      returning {
        id
        certification {
          name
        }
        alternate_certification {
          name
        }
      }
    }
  }
`;
const mutationToRemoveAlternateCertificates = graphql`
  mutation SiteOrientationSettingAlternateCerts_Delete_Alternate_Certificates_Mutation(
    $projectId: uuid!
    $certificationId: uuid!
  ) {
    delete_project_alternate_certification(
      where: {
        project_id: { _eq: $projectId }
        certification_id: { _eq: $certificationId }
      }
    ) {
      returning {
        id
        certification {
          name
        }
      }
    }
  }
`;

const SiteOrientationSettingAlternateCerts: FC<Props> = ({
  projectId,
  certification,
  allCertificates,
}) => {
  const [editCertificates, setEditCertificates] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [addAlternateCertificates] =
    useAsyncMutation<SiteOrientationSettingAlternateCerts_Insert_Alternate_Certificates_Mutation>(
      mutationToAddAlternateCertificates,
    );
  const [deleteAlternateCertificates] =
    useAsyncMutation<SiteOrientationSettingAlternateCerts_Delete_Alternate_Certificates_Mutation>(
      mutationToRemoveAlternateCertificates,
    );

  return (
    <div className="flex flex-row my-0.25">
      <span
        className="h-2 mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-interactive-light text-interactive-primary"
        key={certification.id}
      >
        {`${certification.certification.name} - required`}
      </span>
      {editCertificates ? (
        <Form
          form={form}
          className="w-2/5 h-2"
          initialValues={{
            selectedCertificates:
              certification.alternate_certifications &&
              certification.alternate_certifications.map(
                (alternateCert) => alternateCert.alternate_certification?.pk,
              ),
          }}
        >
          <Form.Item name="selectedCertificates">
            <Select
              mode="multiple"
              disabled={loading}
              showSearch
              filterOption={getNormalOptionsFilter}
              options={allCertificates.map((u) => ({
                label: u.node.name,
                value: u.node.pk,
              }))}
            />
          </Form.Item>
        </Form>
      ) : (
        <div className="mx-1">
          {certification.alternate_certifications &&
            certification.alternate_certifications.map((alternateCert) => (
              <span
                className="h-2 mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-interactive-light text-interactive-primary"
                key={alternateCert.id}
              >
                {`${
                  alternateCert.alternate_certification?.name ?? ""
                } - acceptable alternate`}
              </span>
            ))}
        </div>
      )}
      <Button
        className="ml-1 rounded-2"
        size="small"
        loading={loading}
        onClick={async () => {
          if (editCertificates) {
            const newVals = form.getFieldValue("selectedCertificates");
            setLoading(true);
            await deleteAlternateCertificates({
              variables: {
                projectId,
                certificationId: certification.certification.pk,
              },
              updater: (store) => {
                const conn = ConnectionHandler.getConnection(
                  store.getRoot(),
                  "useSiteOrientationGcDashboardDataQuery_project_connection",
                );
                if (conn) {
                  const edges = conn.getLinkedRecords("edges");
                  if (edges?.length) {
                    const node = edges[0].getLinkedRecord("node");
                    if (node) {
                      const projectCertification = node
                        .getLinkedRecords("project_certifications")
                        ?.find((projectCertification) => {
                          const cert =
                            projectCertification.getLinkedRecord(
                              "certification",
                            );
                          return (
                            cert?.getDataID() == certification.certification.id
                          );
                        });
                      projectCertification?.setLinkedRecords(
                        [],
                        "alternate_certifications",
                      );
                    }
                  }
                }
              },
            });
            await addAlternateCertificates({
              variables: {
                objects: newVals.map((certification_id: string) => ({
                  certification_id: certification.certification.pk,
                  alternate_certification_id: certification_id,
                  project_id: projectId,
                })),
              },
              updater: (store) => {
                const newVals = store.getRootField(
                  "insert_project_alternate_certification",
                );
                const conn = ConnectionHandler.getConnection(
                  store.getRoot(),
                  "useSiteOrientationGcDashboardDataQuery_project_connection",
                );
                if (conn) {
                  const edges = conn.getLinkedRecords("edges");
                  if (edges?.length) {
                    const node = edges[0].getLinkedRecord("node");
                    if (node) {
                      const projectCertification = node
                        .getLinkedRecords("project_certifications")
                        ?.find((projectCertification) => {
                          const cert =
                            projectCertification.getLinkedRecord(
                              "certification",
                            );
                          return (
                            cert?.getDataID() == certification.certification.id
                          );
                        });
                      projectCertification?.setLinkedRecords(
                        newVals.getLinkedRecords("returning"),
                        "alternate_certifications",
                      );
                    }
                  }
                }
              },
            }).catch(console.log);
            setLoading(false);
            setEditCertificates(false);
          } else {
            setEditCertificates(true);
          }
        }}
      >
        {editCertificates ? "Done" : "select any acceptable alternates"}
      </Button>
      {editCertificates && (
        <Button
          loading={loading}
          className="ml-1 rounded-2"
          size="small"
          onClick={() => {
            setEditCertificates(false);
          }}
        >
          Cancel
        </Button>
      )}
    </div>
  );
};

export default SiteOrientationSettingAlternateCerts;
