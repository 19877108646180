import {
  IconHelp,
  IconSettings,
  IconTable,
  IconUser,
  IconUserPlus,
} from "@tabler/icons";
import React, { useRef, useState } from "react";
import SearchBar, { SearchBarProps } from "src/common/components/SearchBar";
import AddWorkerSpreadsheetModal, {
  AddWorkerSpreadsheetModalRef,
} from "src/common/components/dialogs/AddWorkerSpreadsheetModal";
import Button from "src/common/components/general/Button";
import openInNewTab from "src/common/functions/openInNewTab";
import { useInsertWorkersMutation } from "src/common/types/generated/apollo/graphQLTypes";
import CreateWorkerModal, { AddWorkerValues } from "./CreateWorkerModal";
import PerformWorkerFunctions from "src/common/components/modals/PerformWorkerFunctions";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import { ConvertWorkerDataType } from "src/common/components/modals/performWorkerTypes";

const helpButtons = [
  {
    label: "Adding a worker",
    onClick: () => {
      openInNewTab("https://youtu.be/2AHOe5y9OY0");
    },
  },
  {
    label: "Managing workers",
    onClick: () => {
      openInNewTab("https://youtu.be/sh6hwI71abs");
    },
  },
];

interface TopBarProps {
  onFilterButtonClick: () => void;
  showFilterButton?: boolean;
  onWorkerAdded?: () => void;
  refetch?: () => void;
  workersData: ConvertWorkerDataType[];
  searchBar: SearchBarProps;
}

const TopBar: React.FunctionComponent<TopBarProps> = (props) => {
  const subcontractorId = useSubcontractorId();
  const [createWorkerModalVisible, setCreateWorkerModalVisible] =
    useState(false);
  const addWorkerSpreadsheetModalRef =
    useRef<AddWorkerSpreadsheetModalRef>(null);
  const [showFunctions, setShowFunctions] = useState(false);

  const [insertWorkers, { loading: insertingWorkers }] =
    useInsertWorkersMutation();
  // const [inputValue, setInputValue] = useState("");

  const handleAddWorkerClick = () => {
    setCreateWorkerModalVisible(true);
  };

  const handleSpreadsheetUpload = () => {
    addWorkerSpreadsheetModalRef.current?.open();
  };

  const handleCreateWorker = async (values: AddWorkerValues) => {
    setCreateWorkerModalVisible(false);
    // TODO check if password not provided, allow_reset_pass has to be true
    await insertWorkers({
      awaitRefetchQueries: true,
      variables: {
        objects: [
          {
            worker_title_id: values.titleId,
            current_worker_role: values.workerRole,
            subcontractor_id: subcontractorId,
            user: {
              data: {
                role: "worker",
                username: values.username,
                name: values.name,
                phone_number: values.phoneNumber,
                email: values.email,
                allow_reset_password: true,
                birth_date: values.birthDate?.toISOString(),
                phone_number_privacy_setting: { data: {} },
                email_privacy_setting: { data: {} },
                project_privacy_setting: { data: {} },
              },
            },
          },
        ],
      },
    });

    if (props.onWorkerAdded) props.onWorkerAdded();
  };

  return (
    <div className="flex flex-row items-center w-full gap-1 desktop-large:gap-2">
      <div className="flex flex-row items-center gap-0.5">
        {subcontractorId && (
          <AddWorkerSpreadsheetModal
            ref={addWorkerSpreadsheetModalRef}
            subcontractorId={subcontractorId}
          />
        )}
        <CreateWorkerModal
          visible={createWorkerModalVisible}
          onCancel={() => setCreateWorkerModalVisible(false)}
          onCreate={handleCreateWorker}
        />
        <Button onClick={handleAddWorkerClick} icon={IconUserPlus} />
        <Button
          onClick={handleSpreadsheetUpload}
          icon={IconTable}
          label={`Spreadsheet upload`}
        />
        <PerformWorkerFunctions
          visible={showFunctions}
          relayQueryConnName="SCWorkersQuery_worker_connection"
          onClose={() => setShowFunctions(false)}
          refetch={() => props.refetch && props.refetch()}
          data={props.workersData}
        />
      </div>
      <SearchBar {...props.searchBar} />

      <div className="flex flex-row items-center gap-0.5">
        {helpButtons.map((helpButtonProps, index) => (
          <Button
            key={index}
            onClick={helpButtonProps.onClick}
            icon={IconHelp}
            hint={helpButtonProps.label}
            secondary
            small
          />
        ))}
        <div className="mr-1 ml-2">
          <Button
            secondary
            onClick={() => setShowFunctions(true)}
            label=""
            icon={IconUser}
            large
            secondaryIcon={IconSettings}
          />
        </div>
      </div>

      <div
        className={`transition-opacity ${
          props.showFilterButton
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
      >
        <Button label="filter" onClick={props.onFilterButtonClick} />
      </div>
    </div>
  );
};

export default TopBar;
