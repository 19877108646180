import React from "react";
import { useOutlet, useParams } from "react-router-dom";
import CSBillingInvoices from "./SCBillingInvoices";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";

interface GCBillingProps {}

const SCBilling: React.FC<GCBillingProps> = (props) => {
  const outlet = useOutlet();
  const subId = useSubcontractorId();
  const projectId = useParams().projectId;

  if (!projectId) {
    return  <div>project id is undefined</div>
  }

  return outlet ?? <CSBillingInvoices subId={subId} projectId={projectId}/>;
};

export default SCBilling;
