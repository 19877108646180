import dayjs from "dayjs";
import React, { useEffect } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import { QuickLookWorkerQuery } from "src/common/types/generated/relay/QuickLookWorkerQuery.graphql";
import { auth } from "src/common/functions/firebase";
import { browserSessionPersistence } from "firebase/auth";

export const query = graphql`
  query QuickLookWorkerQuery($reference: String!) {
    orientation_connection(
      where: {
        deleted_at: { _is_null: true }
        type: { _eq: "corporate" }
        project_orientations: { required_by_all_workers: { _eq: true } }
        orientation_results: { user: { reference_number: { _eq: $reference } } }
      }
    ) {
      edges {
        node {
          general_contractor_id
          general_contractor {
            name
          }
          project_orientations(
            where: { required_by_all_workers: { _eq: true } }
          ) {
            project_id
          }
          orientation_results(
            where: {
              user: { reference_number: { _eq: $reference } }
              status: { _eq: "completed" }
            }
            order_by: [{ completed_at: desc }, { created_at: desc }]
          ) {
            id
            completed_at
            expired_at
          }
        }
      }
    }
    user_connection(where: { reference_number: { _eq: $reference } }) {
      edges {
        node {
          name
          universal_orientations(
            limit: 1
            order_by: { universal_orientated_at: desc }
          ) {
            universal_orientated_at
          }
          worker {
            worker_projects(where: { is_last: { _eq: true } }) {
              project_id
              project {
                general_contractor_id
                general_contractor {
                  name
                }
              }
              user_orientation {
                completed_at
                orientated_at
              }
            }
          }
        }
      }
    }
  }
`;

type QuickLookWorkerProps = {
  reference: string;
};
const QuickLookWorker: React.FC<QuickLookWorkerProps> = ({ reference }) => {
  const data = useLazyLoadQuery<QuickLookWorkerQuery>(query, { reference });

  useEffect(() => {
    auth.setPersistence(browserSessionPersistence);
  }, []);

  const gcMap: {
    [gcId: string]: {
      name: string;
      date: string | undefined;
      corporatePresentForWorker: boolean;
      projects: Array<string>;
    };
  } = {};
  (data.user_connection.edges[0].node.worker?.worker_projects || []).forEach(
    (p) => {
      if (p.user_orientation?.orientated_at || p.user_orientation?.completed_at)
        if (gcMap[p.project.general_contractor_id]) {
          gcMap[p.project.general_contractor_id].projects.push(p.project_id);
        } else
          gcMap[p.project.general_contractor_id] = {
            name: "test",
            date: undefined,
            corporatePresentForWorker: false,
            projects: [p.project_id],
          };
    },
  );
  data.orientation_connection.edges.forEach((o) => {
    if (
      o.node.general_contractor_id &&
      o.node.general_contractor &&
      gcMap[o.node.general_contractor_id]
    ) {
      const arr: dayjs.Dayjs[] = [];
      o.node.orientation_results.forEach((p) =>
        p.completed_at ? arr.push(dayjs(p.completed_at)) : "",
      );
      const latestCompletionDate = dayjs.max(arr);
      const co = gcMap[o.node.general_contractor_id].projects.some((p) =>
        o.node.project_orientations.find((ps) => ps.project_id == p),
      );
      gcMap[o.node.general_contractor_id] = {
        name: o.node.general_contractor.name,
        corporatePresentForWorker:
          co || gcMap[o.node.general_contractor_id].corporatePresentForWorker,
        projects: gcMap[o.node.general_contractor_id].projects,
        date:
          gcMap[o.node.general_contractor_id]?.date && latestCompletionDate
            ? arr.length > 0
              ? dayjs
                  .max([
                    dayjs(gcMap[o.node.general_contractor_id]?.date),
                    latestCompletionDate,
                  ])
                  ?.format("LL")
              : dayjs(gcMap[o.node.general_contractor_id]?.date).format("LL")
            : arr.length > 0
            ? latestCompletionDate?.format("LL")
            : undefined,
      };
    }
  });

  return data.user_connection.edges[0].node?.universal_orientations[0]
    ?.universal_orientated_at ? (
    <div className="bg-semantic-positive-green h-screen text-1.25 gap-1 text-white flex flex-col justify-center text-center items-center">
      <div>Name: {data.user_connection.edges[0].node.name}</div>
      AGC Universal Orientation:{" "}
      {dayjs(
        data.user_connection.edges[0].node?.universal_orientations[0]
          .universal_orientated_at,
      ).format("LL")}
    </div>
  ) : (
    <div className="bg-semantic-negative h-screen text-1.25 gap-1 text-white flex flex-col justify-center text-center items-center">
      {" "}
      <div>Name: {data.user_connection.edges[0].node.name}</div>
      AGC Universal Orientation: not complete
    </div>
  );
};

export default QuickLookWorker;
