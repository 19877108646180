import React, { useState } from "react";
import * as uuid from "uuid";
import { Button, Modal, Form, Input, Radio, Select } from "antd";
import { GCTasksQuery$data } from "src/common/types/generated/relay/GCTasksQuery.graphql";

interface AddTaskValues {
  id: string;
  description: string;
  subcontractorId?: string;
}

interface AddTaskModalProps {
  visible: boolean;
  onCreate: (values: AddTaskValues) => void;
  onCancel: () => void;
  subcontractorOptions?: GCTasksQuery$data["project_subcontractor_connection"]["edges"];
}

const AddTaskModal: React.FC<AddTaskModalProps> = ({
  visible,
  onCreate,
  onCancel,
  subcontractorOptions,
}) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    (<Modal
      open={visible}
      title="Create a new Task"
      okText="Add"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values: AddTaskValues) => {
            form.resetFields();
            onCreate({
              description: values.description,
              id: uuid.v1(),
              subcontractorId: values.subcontractorId,
            } as AddTaskValues);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="description"
          label="Task description"
          rules={[{ required: true, message: "Enter a task description" }]}
        >
          <Input />
        </Form.Item>

        {subcontractorOptions && (
          <Form.Item
            name="subcontractorId"
            label="Select Subcontractor/Trade Partner you want to upload JHA to"
            rules={[{ required: true, message: "" }]}
          >
            <Select
              className="w-full mr-2 mb-2"
              options={subcontractorOptions.map((sub) => ({
                key: sub.node.subcontractor_id,
                value: sub.node.subcontractor_id,
                label: sub.node.subcontractor.name,
              }))}
              placeholder={"Select subcontractor"}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>)
  );
};

export default AddTaskModal;
