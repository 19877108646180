import { Button, Form, Input, message, notification, Popover, Row } from "antd";
import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "src/common/types/generated/apollo/graphQLTypes";
import ForgotPasswordKeyProps from "src/common/types/manual/ForgotPasswordKeyProps";
import hasApolloErrorCode from "../error-handling/hasApolloErrorCode";
import checkStrongPassword from "./utils/checkStrongPassword";
import StrongPasswordInfo from "./utils/StrongPasswordInfo";

const ResetPassword: FC<ForgotPasswordKeyProps> = ({ forgotPasswordKey }) => {
  const navigate = useNavigate();
  const [resetPassword, { loading, error, data }] = useResetPasswordMutation();

  const onSubmit = async (data: any) => {
    await resetPassword({
      variables: {
        input: {
          newPassword: data.newPassword,
          forgotPasswordKey,
        },
      },
    }).catch(console.log);
  };

  useEffect(() => {
    if (error) {
      // alert(error);
      notification.error({ message: error.message, duration: 1000 });
      if (hasApolloErrorCode(error, "invalid-or-expired-link")) {
        navigate(`/forgotPassword?expiredLink=true`);
      }
    }
    if (data?.resetPassword) {
      message.success("Success: Please use you cred and new password to login");
      navigate("/");
    }
  }, [error, data]);

  return (
    <Row style={{ height: "100vh" }} justify="space-around" align="middle">
      <Form
        layout="vertical"
        name="normal_login"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        style={{ width: 250 }}
      >
        <h3>Reset Password</h3>
        <Form.Item
          name="newPassword"
          label={<div className="flex gap-1">Your new password</div>}
          rules={[
            {
              required: true,
              message: "Please enter your new password.",
            },
            {
              validator(rule, value) {
                if (typeof value === "string" && checkStrongPassword(value)) {
                  return Promise.resolve();
                }
                return Promise.reject("Password is not strong enough.");
              },
            },
          ]}
          hasFeedback
        >
          <Input.Password visibilityToggle />
        </Form.Item>
        <StrongPasswordInfo />
        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["newPassword"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Passwords do not match.");
              },
            }),
          ]}
        >
          <Input.Password visibilityToggle />
        </Form.Item>
        <Form.Item>
          <Button
            style={{ width: "100%" }}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default ResetPassword;
