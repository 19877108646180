import { FC, useState, useRef } from "react";
import IncidentTable from "../../components/table/IncidentTable";
import { useInsertIncidentMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { useNavigate, useParams } from "react-router-dom";
import AddIncidentModal from "../../components/modals/AddIncidentModal";
import useAuthUser from "src/common/hooks/useAuthUser";
import { IconPlus } from "@tabler/icons";

const GCProjectIncidents: FC = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [addNewIncident, setAddNewIncident] = useState(false);
  const [insertIncident] = useInsertIncidentMutation();
  const incidentTableRef = useRef<DataScrollTableRef>(null);
  const authUser = useAuthUser();

  const addIncident = async (values: Array<string>) => {
    const comment = `Incident Report created`;
    const res = await insertIncident({
      variables: {
        object: {
          project_id: projectId,
          incident_types: { data: values.map((val) => ({ type_value: val })) },
          submitted_by_uid: authUser.uid,
          equipment_incident: { data: {} },
          theft_incident: { data: {} },
          near_miss_incident: { data: {} },
          environmental_incident: { data: {} },
          vehicle_incident: { data: {} },
          property_damage_incident: { data: {} },
          utility_incident: { data: {} },
          root_cause_analysis: { data: {} },
          status: "open",
          incident_edits: {
            data: [
              {
                comment: comment,
                edited_by_uid: authUser.uid,
                patch: [],
                edit_type: "log",
              },
            ],
          },
        },
      },
    });
    const updatedIncident = res.data?.insert_incident_one;
    if (updatedIncident?.id) {
      navigate(`${updatedIncident?.id}/project_info`);
    }
  };

  return (
    <BasicWrapper>
      <AddIncidentModal
        onCancel={() => setAddNewIncident(false)}
        onCreate={async (values) => {
          addIncident(values.types);
          setAddNewIncident(false);
        }}
        visible={addNewIncident}
      />
      <div className="flex flex-col items-start gap-0.5 h-full w-full pl-2 pr-2  bg-white rounded-2">
        <div className="w-full h-full">
          <IncidentTable
            topBarButtons={[
              {
                label: "New Incident",
                onClick: () => setAddNewIncident(true),
                icon: IconPlus,
              },
            ]}
            ref={incidentTableRef}
            onRowClick={(item) => navigate(`${item}/project_info`)}
            where={{
              project_id: { _eq: projectId },
              deleted_at: { _is_null: true },
            }}
          />
        </div>
      </div>
    </BasicWrapper>
  );
};

export default GCProjectIncidents;
