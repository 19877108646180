import React from "react";
import { Outlet } from "react-router-dom";
import RequireRoleWrapper from "src/common/components/ReqiureRoleWrapper";
import NestedRoute from "src/common/types/manual/NestedRoute";
import Worker from "./Worker";

const workerEntryRoutes: NestedRoute = {
  element: (
    <RequireRoleWrapper requiredRole={["worker", "employee"]}>
      <Outlet />
    </RequireRoleWrapper>
  ),
  children: [{ path: "", element: <Worker /> }],
};
export default workerEntryRoutes;
