import { Button, Input, Space, Switch } from "antd";
import { useState } from "react";
import * as uuid from "uuid";
import {
  GetProjectLocationsQuery,
  useUpdateTextTranslationByPkMutation,
  useInsertProjectLocationMutation,
  Project_Location_Set_Input,
  useDeleteProjectLocationMutation,
  useUpdateProjectLocationMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { HeatMapOutlined } from "@ant-design/icons";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import dayjs from "dayjs";
import useAuthUser from "src/common/hooks/useAuthUser";
import Switcher from "src/common/components/general/Switcher";
import { IconCheck, IconX } from "@tabler/icons";

const ProjectLocationName: React.FC<{
  projLocation: GetProjectLocationsQuery["project_location"][number];
}> = ({ projLocation }) => {
  const [currText, setCurrText] = useState(projLocation.name.en);
  const [updateText] = useUpdateTextTranslationByPkMutation();
  const handleUpdate = () => {
    updateText({
      variables: {
        pk_columns: { id: projLocation.name.id },
        _set: { original: currText, en: currText },
      },
      optimisticResponse: {
        update_text_translation_by_pk: {
          clientText: currText,
          en: currText,
          es: currText,
          pt: currText,
          original: currText,
          id: projLocation.name.id,
          lang: "en",
          __typename: "text_translation",
        },
      },
    });
  };
  return (
    <div className="flex justify-between">
      <Input
        size="large"
        style={{ border: 0 }}
        onBlur={handleUpdate}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleUpdate();
        }}
        value={currText}
        onChange={(e) => setCurrText(e.target.value)}
      />
      {currText.trim() !== projLocation.name.en.trim() && (
        <Button type="text" className="text-grey" onClick={handleUpdate}>
          Save
        </Button>
      )}
    </div>
  );
};

const InputNumberWithSave: React.FC<{
  onSave: (val: number) => void;
  disabled: boolean;
  currVal: number;
}> = ({ onSave, currVal, disabled }) => {
  const [val, setVal] = useState<number>(currVal);
  const handleUpdate = () => {
    onSave(val);
  };
  return (
    <div className="flex justify-between">
      <Input
        type="number"
        disabled={disabled}
        // onBlur={handleUpdate}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleUpdate();
        }}
        style={{ width: "100px" }}
        value={val || undefined}
        onChange={(e) => setVal(Number(e.target.value))}
      ></Input>
      {currVal !== val && (
        <Button type="text" className="text-grey" onClick={handleUpdate}>
          Save
        </Button>
      )}
    </div>
  );
};

const ProjectLocations: React.FC<{
  projectId: string;
  data: GetProjectLocationsQuery;
  projectSubs: Array<string>;
  showPricing?: boolean; // only For Admin
}> = ({ projectId, projectSubs, showPricing, data }) => {
  const [insertProjectLocation] = useInsertProjectLocationMutation();
  const [updateProjectLocation] = useUpdateProjectLocationMutation();
  const authUser = useAuthUser();
  const [deleteProjectLocation] = useDeleteProjectLocationMutation();
  const onInsertPress = () => {
    const newLocationId = uuid.v4();
    const nameId = uuid.v4();
    insertProjectLocation({
      variables: {
        object: {
          id: newLocationId,
          name: {
            data: {
              en: "New Location",
              id: nameId,
              original: "New Location",
            },
          },
          project_id: projectId,
          project_location_subcontractors: {
            data: projectSubs.map((subId) => ({
              is_sub_active: true,
              project_id: projectId,
              subcontractor_id: subId,
            })),
          },
        },
      },
      optimisticResponse: {
        insert_project_location_one: {
          __typename: "project_location",
          id: newLocationId,
          name: {
            en: "New Location",
            id: nameId,
            __typename: "text_translation",
          },
          default_payment_model: "gc",
          archived_at: null,
          billing_enabled: false,
          archived_by_uid: null,
          worker_price_first: null,
          worker_price_others: null,
          reports_aggregate: { aggregate: { count: 0 } },
        },
      },
      update: (cache, returningData) => {
        const newLocation = returningData.data?.insert_project_location_one;
        if (!newLocation)
          throw new Error(
            "server returned null for new project location resulting in failure to insert",
          );
        cache.modify<typeof data>({
          fields: {
            project_location(existingLocations = [], { toReference }) {
              const newLocationRef = toReference(newLocation);
              if (!newLocationRef) return existingLocations;
              return [newLocationRef, ...existingLocations];
            },
          },
        });
      },
    });
  };
  const onDelete = (locId: string) =>
    deleteProjectLocation({
      variables: { id: locId },
      optimisticResponse: {
        delete_project_location_by_pk: {
          __typename: "project_location",
          id: locId,
        },
      },
      update: (cache, returningData) => {
        const deletedLocationId =
          returningData.data?.delete_project_location_by_pk?.id;
        if (!deletedLocationId)
          throw new Error("No deleted project_location id returned by server");
        cache.modify<typeof data>({
          fields: {
            project_location(existingLocations = [], { readField }) {
              return existingLocations.filter((loc) => {
                const id = readField("id", loc);
                return typeof id === "string" && id !== deletedLocationId;
              });
            },
          },
        });
      },
    });
  const updateLocation = (
    location: GetProjectLocationsQuery["project_location"][number],
    set: Omit<
      Project_Location_Set_Input,
      "id" | "name_id" | "default_payment_model"
    >,
  ) => {
    updateProjectLocation({
      variables: { id: location.id, _set: set },
      optimisticResponse: {
        update_project_location_by_pk: {
          ...location,
          ...set,
          billing_enabled:
            typeof set.billing_enabled === "boolean"
              ? set.billing_enabled
              : location.billing_enabled,
        },
      },
    });
  };
  return (
    <div className="flex flex-col gap-1">
      <div>
        <div className="text-2">Project Locations</div>
        <Button type="primary" onClick={onInsertPress}>
          Add new location
        </Button>
      </div>

      <div className="-mx-0.25">
        {data.project_location.map((cur, i) => (
          <div className="flex items-center mt-1 gap-2" key={cur.id}>
            📍
            <div className="mt-0.5 mb-0.5">
              <ProjectLocationName projLocation={cur} />
            </div>
            {showPricing && (
              <div>
                <Space direction="horizontal">
                  <Switcher
                    options={[
                      { icon: IconX, label: "Billing OFF" },
                      { icon: IconCheck, label: "Billing ON" },
                    ]}
                    optionIndex={cur.billing_enabled ? 1 : 0}
                    onChange={(optionIndex) =>
                      updateLocation(cur, { billing_enabled: !!optionIndex })
                    }
                  />
                  <InputNumberWithSave
                    disabled={
                      !cur.billing_enabled || data.project_location.length <= 1
                    }
                    currVal={Number(cur.worker_price_first) / 100}
                    onSave={(val) =>
                      updateLocation(cur, { worker_price_first: val * 100 })
                    }
                  />{" "}
                  USD - first user{" "}
                  <InputNumberWithSave
                    disabled={
                      !cur.billing_enabled || data.project_location.length <= 1
                    }
                    currVal={Number(cur.worker_price_others) / 100}
                    onSave={(val) =>
                      updateLocation(cur, { worker_price_others: val * 100 })
                    }
                  />{" "}
                  USD for second and others
                </Space>
              </div>
            )}
            &nbsp;&nbsp;
            <div className="flex justify-center items-center">
              <BPopconfirm
                title="This will mark this location completed/archived and it will not show for users to select"
                onConfirm={() => {
                  updateLocation(
                    cur,
                    cur.archived_at
                      ? { archived_at: null, archived_by_uid: authUser.uid }
                      : {
                          archived_at: dayjs().toISOString(),
                          archived_by_uid: authUser.uid,
                        },
                  );
                }}
              >
                <Button
                  type="primary"
                  className="bg-semantic-pending hover:bg-semantic-pending border-0 ml-1"
                >
                  {cur.archived_at
                    ? "Mark it Ongoing"
                    : "Mark Completed/Archived"}
                </Button>
              </BPopconfirm>
              <BPopconfirm
                className={
                  cur.reports_aggregate.aggregate?.count ? "hidden" : ""
                }
                title="Are you sure? This will permanently delete this location along with it's settings."
                onConfirm={() => onDelete(cur.id)}
              >
                <Button
                  type="primary"
                  className="bg-semantic-negative hover:bg-semantic-negative border-0 ml-1"
                >
                  Delete Location
                </Button>
              </BPopconfirm>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default withCustomSuspense(ProjectLocations);
