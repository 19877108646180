import Tippy from "@tippyjs/react";
import React from "react";

interface HoverHintProps {
  text: string;
  inline?: boolean;
  children?: React.ReactNode;
}

const HoverHint: React.FC<HoverHintProps> = (props) => {
  return (
    <Tippy
      delay={250}
      placement={"bottom"}
      content={
        <div className="shadow bg-white rounded-0.25 p-0.5">
          <p className="text-center text-static-secondary"> {props.text}</p>
        </div>
      }
    >
      <div className={`${props.inline ? "inline-block" : ""}`}>
        {props.children}
      </div>
    </Tippy>
  );
};

export default HoverHint;
