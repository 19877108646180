import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "react-relay/hooks";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { sameEmailUsers_GetSameEmailUsersQuery } from "src/common/types/generated/relay/sameEmailUsers_GetSameEmailUsersQuery.graphql";

export const getSameEmailUser = async (
  email: string,
  environment: RelayModernEnvironment
) => {
  const response = fetchQuery<sameEmailUsers_GetSameEmailUsersQuery>(
    environment,
    graphql`
      query sameEmailUsers_GetSameEmailUsersQuery($email: String!) {
        user_connection(where: { email: { _eq: $email } }) {
          edges {
            node {
              pk: id @__clientField(handle: "pk")
              email
              name
              role
            }
          }
        }
      }
    `,
    { email }
  );
  const data = await response.toPromise();
  if (!data) throw new Error("data to compare email not found");
  return data;
  // if (!data) throw new Error("data to compare email not found");
};
