import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Input, Table } from "antd";
import dayjs from "dayjs";
import React, { FC, useState } from "react";
import { auth } from "src/common/functions/firebase";

const { TextArea } = Input;

interface PropsInt {
  subcontractorId: string;
  taskId: string;
}

const query1 = gql`
  query MyQuery($taskId: uuid!) {
    task_note(
      where: { task_id: { _eq: $taskId } }
      order_by: { created_at: desc }
    ) {
      task_id
      text
      subcontractor_id
      subcontractor {
        name
      }
      id
      created_at
      created_by_user {
        name
        role
      }
    }
  }
`;

const query = gql`
  mutation MyMutation(
    $taskId: uuid!
    $subcontractorId: uuid!
    $text: String!
    $createrId: uuid!
  ) {
    insert_task_note(
      objects: {
        task_id: $taskId
        text: $text
        subcontractor_id: $subcontractorId
        created_by_uid: $createrId
      }
    ) {
      affected_rows
    }
  }
`;

const TaskNotesTable: FC<PropsInt> = ({ subcontractorId, taskId }) => {
  const [textAreaValue, setTextAreaValue] = useState(``);
  const [onAddHandler] = useMutation(query);
  const taskNotes = useQuery(query1, {
    variables: {
      taskId,
    },
  });
  //console.log(taskNotes);
  const dataSource = taskNotes.data?.task_note.map((current: any) => {
    let obj = {
      createdAt: current.created_at,
      createdBy: current.created_by_user?.name,
      text: current.text,
      key: current.id,
    };
    return obj;
  });
  const onChange = (e: any) => {
    setTextAreaValue(e.target.value);
  };
  const addNote = async () => {
    if (textAreaValue.trim() !== "") {
      onAddHandler({
        awaitRefetchQueries: true,
        variables: {
          taskId: taskId,
          subcontractorId: subcontractorId,
          createrId: auth.currentUser?.uid,
          text: textAreaValue,
        },
        refetchQueries: [
          {
            query: query1,
            variables: {
              taskId,
            },
          },
        ],
      });
    }
    setTextAreaValue("");
  };
  const columns = [
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "25%",
      render: (d: string | number | Date) =>
        d
          ? dayjs
              .tz(dayjs(new Date(d)).format("LLL"), dayjs.tz.guess())
              .format("MMMM DD YYYY, hh:mm a")
          : "",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",

      width: "25%",
    },
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
      width: "50%",
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={dataSource} bordered />
      <TextArea
        value={textAreaValue}
        onChange={onChange}
        placeholder="You can document any JHA activity here, like a JHA review meeting you had with the Crew Lead or PM for the Sub. This text will not be seen on the actual JHA."
      />
      <Button onClick={addNote} type="primary" style={{ marginTop: "20px" }}>
        Add note
      </Button>
    </>
  );
};

export default TaskNotesTable;
