import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Button, DatePicker, Input, InputRef } from "antd";
import datePickerFormatsList from "../../constants/datePickerFormatsList";
import { CalendarOutlined } from "@ant-design/icons";
import onEnterDown from "../../../domain-features/siteorientation/utils/onEnterDown";

const dateFormat = "MM-DD-YYYY";

interface DesktopBirthDatePickerProps {
  value: dayjs.Dayjs | null;
  onValueChange: (newValue: dayjs.Dayjs | null) => void;
  onEnter?: () => void;
  disabled?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

const DesktopBirthDatePicker: React.FC<DesktopBirthDatePickerProps> = (
  props,
) => {
  const [textValue, setTextValue] = useState<string>(
    props.value?.format(dateFormat) ?? "",
  );
  const inputRef = React.useRef<InputRef>(null);
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [moveCursorToEnd, setMoveCursorToEnd] = useState(false);

  useEffect(() => {
    if (moveCursorToEnd) {
      setMoveCursorToEnd(false);
      inputRef.current?.setSelectionRange(textValue.length, textValue.length);
    }
  }, [moveCursorToEnd, textValue]);

  const maskInput = (input: string) => {
    let output = input;
    // return masked value in format of "99-99-9999"
    // if input is "1234567890" then return "12-34-5678"
    output = output.replace(/\D/g, "");
    output = output.replace(/^(\d{2})(\d)/g, "$1-$2");
    output = output.replace(/^(\d{2})-(\d{2})(\d)/g, "$1-$2-$3");
    output = output.slice(0, 10);
    return output;
  };

  return (
    <div className={`flex flex-col gap-0.5`}>
      <div className={`flex flex-row items-center gap-0.5`}>
        <Input
          ref={inputRef}
          disabled={props.disabled}
          value={textValue}
          onChange={(e) => {
            const newValue = maskInput(e.target.value);
            const newValueMoment = dayjs(newValue, dateFormat);
            if (newValue.length === 10 && newValueMoment.isValid()) {
              props.onValueChange(newValueMoment);
            } else {
              props.onValueChange(null);
            }
            setTextValue(newValue);
          }}
          onKeyDown={(e) => {
            props.onEnter && onEnterDown(e, props.onEnter);
            // check if the key is "-" or "/" or " "
            if (e.key === "-" || e.key === "/" || e.key === " ") {
              if (textValue.length === 1) {
                setTextValue((prevState) => {
                  // add zero in front of the input
                  // "1" -> "01-"
                  const newValue = `0${prevState}-`;
                  setMoveCursorToEnd(true);
                  return newValue;
                });
              }
              if (textValue.length === 4) {
                setTextValue((prevState) => {
                  //add a 0 in front of the last digit
                  // "01-1" -> "01-01-"
                  const newValue = `${prevState.slice(0, 3)}0${prevState.slice(
                    3,
                  )}-`;
                  setMoveCursorToEnd(true);
                  return newValue;
                });
              }
            }
          }}
          placeholder={`Month-Day-Year (${dayjs().format(dateFormat)})`}
        />

        <div className={"relative"}>
          <DatePicker
            className={"invisible absolute top-0"}
            value={props.value}
            minDate={props.minDate}
            maxDate={props.maxDate}
            format={datePickerFormatsList}
            onChange={(newDate) => {
              props.onValueChange(newDate);
              setTextValue(newDate ? newDate.format(dateFormat) : "");
            }}
            open={datePickerOpen}
            onOpenChange={setDatePickerOpen}
          />
          <Button
            icon={<CalendarOutlined />}
            onClick={() => {
              setDatePickerOpen((prevState) => !prevState);
            }}
          />
        </div>
      </div>

      <p className={`text-static-secondary`}>
        {`${props.value?.format("MMMM Do YYYY") ?? ""}`}
      </p>
    </div>
  );
};

export default DesktopBirthDatePicker;
