import { IconUserCircle } from "@tabler/icons";
import React from "react";
import Image, { ImageProps } from "src/common/components/general/images/Image";

export interface PeopleListItemProps {
  onClick?: () => void;
  active?: boolean;
  content: React.ReactNode[];
  avatarImage?: ImageProps;
}

const PeopleListItem: React.FunctionComponent<PeopleListItemProps> = (
  props
) => {
  return (
    <div
      {...{ onClick: props.onClick }}
      className={`relative  group transition-all ${
        props.active ? "pr-0.5" : props.onClick ? "pr-0 cursor-pointer" : "p-0"
      }`}
    >
      <div
        className={`relative z-10 border rounded-1 transition-all overflow-hidden h-3 ${
          props.active
            ? "bg-suplementary-1 border-suplementary-1"
            : props.onClick
            ? "bg-white border-interactive-secondary group-hover:border-interactive-primary"
            : "bg-white border-suplementary-1"
        }`}
      >
        <div className="flex flex-row items-center h-full gap-2 w-max">
          {props.avatarImage && (
            <div
              className={`w-3 h-full overflow-hidden rounded-1 ${
                props.active && !props.avatarImage.src
                  ? "border-r border-suplementary-2"
                  : ""
              }`}
            >
              <Image
                src={props.avatarImage.src}
                alt="profile"
                icon={IconUserCircle}
              />
            </div>
          )}
          {props.content}
        </div>
        <div
          className={`absolute top-0 w-2 h-full right-0 bg-gradient-to-l transition-opacity from-white ${
            props.active ? "opacity-0" : "opacity-100"
          }`}
        ></div>
        <div
          className={`absolute top-0 w-2 h-full right-0 bg-gradient-to-l transition-opacity from-suplementary-1 ${
            props.active ? "opacity-100" : "opacity-0"
          }`}
        ></div>
      </div>
      <svg
        fill="0"
        viewBox="0 0 8 16"
        xmlns="http://www.w3.org/2000/svg"
        className="text-suplementary-1 w-0.5 absolute right-0 top-0 bottom-0 my-auto"
      >
        <path d="M8 8l-8 8V0l8 8z" className="fill-current" />
      </svg>
    </div>
  );
};

export default PeopleListItem;
