import { Button, Divider, Form, Input, notification } from "antd";
import { GraphQLError } from "graphql";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, storage } from "src/common/functions/firebase";
import {
  GetPasswordHashDocument,
  useUpdateUserMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import apolloClient from "../../../../../common/api/apollo/apolloClient";
import { roleVar } from "../../../../../common/api/apollo/cache";
import { getDownloadURL, ref } from "firebase/storage";

const AdminProfile = () => {
  const [updatingPass, setUpdatingPass] = useState(false);
  const [gettingToken, setGettingToken] = useState(false);
  const [updateUser] = useUpdateUserMutation();
  const [userToken, setUserToken] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const navigate = useNavigate();

  return (
    <div style={{ overflow: "scroll", padding: 32 }}>
      <Button
        danger
        type="primary"
        onClick={async () => {
          roleVar("none");
          await auth.signOut();
          navigate("/");
        }}
      >
        Log out
      </Button>
      <br />
      <br />
      Set user password
      <Form
        onFinish={async (values) => {
          setUpdatingPass(true);
          const { data } = await apolloClient.query({
            query: GetPasswordHashDocument,
            variables: {
              string: values.password,
            },
          });
          if (data.getPasswordHash) {
            const r = await updateUser({
              variables: {
                _set: {
                  password_hash: data.getPasswordHash,
                  created_password: true,
                },
                where: {
                  _or: [
                    {
                      username: { _ilike: values.username },
                    },
                    {
                      email: { _ilike: values.username },
                    },
                  ],
                },
              },
            });
            if (r.data?.update_user?.affected_rows === 0) {
              notification.open({
                message: `Email or username does not exist: ${values.username} `,
                duration: null,
              });
            } else {
              notification.open({
                message: `Updated ${values.username} password!`,
                duration: null,
              });
            }
          }
          setUpdatingPass(false);
        }}
        layout="vertical"
        name="form_in_modal"
      >
        <Form.Item
          name="username"
          label="User email or username"
          style={{ width: 300 }}
          rules={[{ required: true, message: "Enter the emai or username" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="New user password"
          style={{ width: 300 }}
          rules={[{ required: true, message: "Enter the password" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item style={{ width: 300 }}>
          <Button type="primary" htmlType="submit" loading={updatingPass}>
            Submit
          </Button>
        </Form.Item>
      </Form>
      <br />
      <br />
      <Divider />
      <br />
      <br />
      <Form
        onFinish={async (values) => {
          const storageRef = ref(storage, values.filePath);
          const url = await getDownloadURL(storageRef);
          setDownloadUrl(url);
        }}
        layout="vertical"
        name="form_in_modal"
      >
        <Form.Item
          name="filePath"
          label="Firebase storage path"
          style={{ width: 300 }}
          rules={[{ required: true, message: "Enter firebase storage path" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item style={{ width: 300 }}>
          <Button type="primary" htmlType="submit" loading={false}>
            Submit
          </Button>
        </Form.Item>
      </Form>
      {downloadUrl && (
        <a style={{ width: 200 }} target="_blank" href={downloadUrl}>
          {downloadUrl}
        </a>
      )}
      <br />
      <br />
      <Divider />
      <br />
      <br />
      Get user auth token
      <Form
        onFinish={async (values: any) => {
          setGettingToken(true);
          setUserToken("");
          if (values.credential) {
            const r = await apolloClient
              .query({
                query: gql`
                  query AdminU($u: String!) {
                    adminu(u: $u)
                  }
                `,
                variables: {
                  u: values.credential,
                },
                fetchPolicy: "network-only",
              })
              .catch((err: GraphQLError) => {
                if (err) {
                  notification.open({
                    type: "error",
                    message: "Error",
                    description: `Error details: ${err.name} ${err.message}`,
                    duration: null,
                  });
                }
              });
            if (r && r.data.adminu) {
              setUserToken(r.data.adminu);
            }
          }
          setGettingToken(false);
        }}
        layout="vertical"
        name="form_in_modal"
      >
        <Form.Item
          name="credential"
          label="Username or Email"
          style={{ width: 300 }}
          rules={[{ required: true, message: "Enter the username" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item style={{ width: 300 }}>
          <Button type="primary" htmlType="submit" loading={gettingToken}>
            Submit
          </Button>
        </Form.Item>
      </Form>
      <a
        style={{ width: 200 }}
        onClick={() => {
          try {
            navigator.clipboard.writeText(userToken);
            notification.open({ message: "Token copied" });
          } catch (err) {
            alert("Error copying to clipboard");
          }
        }}
      >
        {userToken}
      </a>
    </div>
  );
};

export default AdminProfile;
