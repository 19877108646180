import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { QRCodeCanvas } from "qrcode.react";
import React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { OrientationCompletionMsgQuery } from "src/common/types/generated/relay/OrientationCompletionMsgQuery.graphql";
import { OrientationType } from "src/common/types/manual/OrientationType";
import { Language } from "../../../../../utility-features/i18n/language-utils/i18nTypes";
import { DEFAULT_LANGUAGE } from "../../../../../utility-features/i18n/languages";
import stringToLanguage from "../../../../../utility-features/i18n/language-utils/stringToLanguage";
import useLangStrings from "../../../../../utility-features/i18n/context/languageHooks";

export const query = graphql`
  query OrientationCompletionMsgQuery(
    $projectWhere: project_bool_exp!
    $workerId: uuid!
  ) {
    user_connection(where: { id: { _eq: $workerId } }) {
      edges {
        node {
          reference_number
          name
          role
        }
      }
    }
    secret_login_link_connection(
      where: {
        user_id: { _eq: $workerId }
        project_id: { _is_null: true }
        sign_in_role: { _eq: "pseudo_worker" }
      }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    project_connection(where: $projectWhere) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          name
          agc_universal_orientation
          in_person_orientation
          assign_hard_hat
          add_hard_hat_during_registration
          general_contractor {
            pk: id @__clientField(handle: "pk")
            name
          }
        }
      }
    }
  }
`;

// reg - after registration
// slides - atter slides
// inp - after inperson signature (should add button to return back)
type CompletionType = "reg" | "universal" | OrientationType;

export type OrientationCompletionMsgProps = {
  type: CompletionType;
  userId: string;
  lang: Language;
  projectId?: string;
};
const OrientationCompletionMsg: React.FC<OrientationCompletionMsgProps> = ({
  projectId,
  type,
  userId,
  lang,
}) => {
  const [searchParams] = useSearchParams();
  const lang1 = searchParams.get("lang");
  const data = useLazyLoadQuery<OrientationCompletionMsgQuery>(query, {
    workerId: userId,
    projectWhere: projectId
      ? { id: { _eq: projectId } }
      : { id: { _is_null: true } },
  });
  const loginCode = data.secret_login_link_connection.edges[0]?.node?.pk;
  const selectedLang = stringToLanguage(lang ?? lang1) ?? DEFAULT_LANGUAGE;
  const userData = data.user_connection.edges[0].node;
  const projectData =
    data.project_connection.edges.length > 0
      ? data.project_connection.edges[0].node
      : undefined;

  const navigate = useNavigate();
  const langStrings = useLangStrings(selectedLang);
  //projectData CAN BE UNDEFINED OR NULL FOR Universal orientation only QR
  const projectGC = projectData?.general_contractor?.name;
  const projectName = projectData?.name;

  const registration = type === "reg";
  // TODO: we can move logic from OrinationCompleted (inplace orientation) here and leave only one page.

  return (
    <>
      <div className="text-center font-accent ml-1 mt-1.5 text-1">
        {projectGC ?? ""}
        <br />
        <br />
        {projectName ?? ""}
        <br />
        <br />
        <br />
        {registration
          ? langStrings.strings.registrationCompleted
          : langStrings.strings.orientationCompleted}
        <br />
        <br />
        {dayjs().tz("America/New_York").format("MM/DD/YYYY hh:mm A z")}
        <br />
        <br />
      </div>
      <div className="p-1.5 text-center">
        {registration
          ? langStrings.strings.thankYouForCompletingRegistration
          : !projectData?.assign_hard_hat ||
            projectData.add_hard_hat_during_registration
          ? langStrings.strings.thankYouForCompletingThisOrientation
          : langStrings.strings.orientationCompletionThankYou}
        <br />
        <br />
        <br />
        {!registration &&
          projectData?.assign_hard_hat &&
          !projectData.add_hard_hat_during_registration && (
            <div className="font-accent text-center">
              {langStrings.strings.beforeStartingWorkContactGC}
            </div>
          )}
        {registration && (
          <div>
            {langStrings.strings.whenYouArriveOnProject}{" "}
            <span className="font-accent">
              {langStrings.strings.beforeStartingWorkCheckInWithGC}
            </span>{" "}
            {langStrings.strings.toFindOutWhenTheyWillHoldTheNextInPerson}
          </div>
        )}
        {(type === "universal" ||
          (projectData?.agc_universal_orientation && type === "slides")) &&
          userData.reference_number &&
          userData.role !== "employee" &&
          loginCode && (
            <>
              <div
                className={
                  "inline-block items-center mt-2 justify-center border-0.125 text-center border-semantic-negative-dark border-solid bg-white"
                }
              >
                <QRCodeCanvas
                  className="w-full h-full inset-0 cursor-pointer"
                  value={`${document.location.origin}/singInLink/${loginCode}?reference=${userData.reference_number}`}
                  // id={downloadableFileName}
                  bgColor={`transparent`}
                  style={{
                    width: `100%`,
                    height: `100%`,
                  }}
                />
              </div>
              <div>
                <div className="text-semantic-inactive-dark text-center">
                  {userData.reference_number}
                </div>
              </div>
            </>
          )}
      </div>
    </>
  );
};

export default OrientationCompletionMsg;
