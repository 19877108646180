import * as React from "react";
import { Menu, Image } from "antd";
import { TasksTableType } from "src/domain-features/sitesafety/job-hazard-analysis/components/tables/TasksTable";
import CounterLabel from "src/common/components/general/CounterLabel";
import Separator from "src/common/components/general/Separator";
import downloadFromUrl from "src/common/functions/downloadFromUrl";

interface TaskViewFileProps {
  row: TasksTableType;
}

const TaskViewFile = (props: TaskViewFileProps) => {
  return (
    <div className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer">
      {props.row.task_creation_request?.type === "images_for_one" ? (
        <CounterLabel
          count={props.row.task_creation_request?.attached_files.length ?? 0}
          label={`Photos`}
          popover={{
            placement: "leftTop",
            content: (
              <div className="flex flex-col gap-1">
                <div className="font-accent">Photos:</div>
                <div className="flex flex-col h-48 overflow-y-auto gap-0.5 w-24">
                  {props.row.task_creation_request?.attached_files.map(
                    (item, i) => (
                      <div key={i}>
                        <Separator />
                        <div className="flex flex-col gap-1">
                          <Image src={item.url} preview={true} />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            ),
          }}
        />
      ) : (
        <div
          onClick={(e) => {
            e.stopPropagation();
            props.row.document?.url &&
              downloadFromUrl(props.row.document.url, true);
          }}
        >
          View Original File
        </div>
      )}
    </div>
  );
};

export default TaskViewFile;
