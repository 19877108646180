import { IconArrowBadgeLeft, IconArrowBadgeRight } from "@tabler/icons";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import React from "react";
import Button from "src/common/components/general/Button";

export interface DatesNavigationProps {
  date: dayjs.Dayjs | undefined;
  onDateChange: (newDate: dayjs.Dayjs) => void;
  disabledDate?: (date: dayjs.Dayjs) => boolean;
  disabledForward?: boolean;
}

const DatesNavigation: React.FC<DatesNavigationProps> = (props) => {
  return (
    <div className="flex flex-row items-center justify-center gap-1">
      <Button
        icon={IconArrowBadgeLeft}
        secondary
        onClick={() => {
          props.onDateChange(dayjs(props.date).subtract(1, "days"));
        }}
      />
      <DatePicker
        disabledDate={props.disabledDate}
        value={dayjs(props.date)}
        format={(val) =>
          val.clone().startOf("d").isSame(dayjs().startOf("d"))
            ? "Today"
            : val.format("YYYY-MM-DD")
        }
        onChange={(newDate) => newDate && props.onDateChange(newDate)}
        inputReadOnly
      />
      {!props.disabledForward && (
        <Button
          secondary
          icon={IconArrowBadgeRight}
          onClick={() => {
            props.onDateChange(dayjs(props.date).add(1, "days"));
          }}
        />
      )}
    </div>
  );
};

export default DatesNavigation;
