import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { Chart } from "@antv/g2";
import DataSet from "@antv/data-set";
import { ChartCfg, TextOption, TooltipCfg } from "@antv/g2/lib/interface";

export interface PieChartProps {
  id: string;
  title: string | React.ReactNode;
  dataSource: any;
  children?: React.ReactNode;
  label?: false | string | [string, (...args: any[]) => any];
  tooltip?:
    | false
    | {
        config: TooltipCfg;
        displayAs: [string, (...args: any[]) => any];
      };
  color: {
    field: string;
    colors: string[];
  };
  chartCfg?: Omit<ChartCfg, "container">;
  legend?: {
    show: boolean;
    options?: any;
  };
  annotations?: TextOption[];
  singleMetric?: boolean;
  interactions?: string[];
}

const PieChart = ({
  id,
  title,
  dataSource,
  children,
  ...props
}: PieChartProps) => {
  const {
    singleMetric,
    color,
    label = false,
    tooltip = false,
    legend = { show: false },
    interactions = [],
    annotations = [],
    chartCfg = {},
  } = props;

  useEffect(() => {
    const chart = new Chart({
      container: id,
      ...chartCfg,
    });
    const view = chart.createView({
      region: {
        start: { x: 0, y: 0 },
        end: { x: 1, y: 1 },
      },
    });

    let data: any[];
    if (singleMetric) {
      // Add dummy to represent second half of pie
      data = [
        ...dataSource,
        { name: "other", value: 100 - dataSource[0].value },
      ];
      color.colors = ["#95DE64", "#eceef100"];
    } else {
      const dataSet = new DataSet();
      const dataView = dataSet.createView();
      dataView.source(dataSource).transform({
        type: "percent",
        field: "value",
        dimension: "name",
        as: "percent",
      });
      data = dataView.rows;
    }

    // register data
    view.data(data);

    // define ploting as circular
    view
      .coordinate("theta", { radius: 0.75, innerRadius: 0.5 })
      .rotate(Math.PI * 0.4);

    // View configurations
    legend.show ? chart.legend(legend.options) : chart.legend(false);

    tooltip && view.tooltip(tooltip.config);

    interactions.length > 0 &&
      interactions.map((interaction) => view.interaction(interaction));

    annotations.length > 0 &&
      annotations.map((textOpts) => view.annotation().text(textOpts));

    // Generate view params
    const geometry = view
      .interval()
      .adjust("stack")
      .position("value")
      .color(color.field, color.colors)
      .style({
        shadowColor: "rgb(0 0 0 / 25%)",
        shadowOffsetY: 8,
        shadowBlur: 16,
      });

    // Configure Geometry object
    Array.isArray(label)
      ? geometry.label(label[0], label[1])
      : geometry.label(label);

    tooltip && geometry.tooltip(tooltip.displayAs[0], tooltip.displayAs[1]);

    // Render the chart to the dom
    chart.render();
    setTimeout(() => chart.forceFit(), 200);

    return () => chart.destroy();
  }, [dataSource, dataSource]);

  return (
    <Card
      style={{ overflow: "hidden" }}
      styles={{
        body: { height: "100%" },
      }}
    >
      <h3>{title}</h3>
      <div style={{ position: "relative" }}>
        <div id={id} style={{ width: "100%", display: "inline-block" }}></div>
        {children}
      </div>
    </Card>
  );
};

export default PieChart;
