import React, { FC } from "react";
import { Modal, Form, message, Select } from "antd";
import { useRelayEnvironment } from "react-relay/hooks";
import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import {
  AddSdsToProjectModalMutation,
  safety_data_sheet_insert_input,
} from "src/common/types/generated/relay/AddSdsToProjectModalMutation.graphql";
import { SDS } from "src/domain-features/sitesafety/safety-data-sheets/components/SdsTable";
import { auth } from "src/common/functions/firebase";

interface AddSdsToProjectOrCompanyProps {
  sds: SDS;
  visible: boolean;
  handleCancel: () => void;
  projectSubcontractors: Array<{ id: string; name: string }>;
  projectId: string;
}

const insertSdsMutation = graphql`
  mutation AddSdsToProjectModalMutation(
    $objects: [safety_data_sheet_insert_input!]!
  ) {
    insert_safety_data_sheet(objects: $objects) {
      affected_rows
    }
  }
`;

const AddSdsToProjectModal: FC<AddSdsToProjectOrCompanyProps> = ({
  handleCancel,
  sds,
  projectSubcontractors,
  visible,
  projectId,
}: AddSdsToProjectOrCompanyProps) => {
  const [insertSds] =
    useAsyncMutation<AddSdsToProjectModalMutation>(insertSdsMutation);
  const environment = useRelayEnvironment();
  const [form] = Form.useForm();
  return (
    <Modal
      open={visible}
      cancelText="Cancel"
      okText="Save"
      onCancel={handleCancel}
      title={`Select Subcontractor(s) to add this SDS`}
      // confirmLoading={loading}
      onOk={() => {
        // setLoading(true);
        form
          .validateFields()
          .then(async (values: { subs: Array<string> }) => {
            form.resetFields();
            const sdsToInsert: safety_data_sheet_insert_input[] = [];

            values.subs.forEach((sub) =>
              sdsToInsert.push({
                project_id: projectId,
                created_by_uid: auth.currentUser?.uid,
                subcontractor_id: sub,
                product_name: sds.product_name,
                company_name: sds.company_name,
                file_type: sds.file_type,
                reference_number: sds.reference_number,
                issue_date: sds.issue_date,
                url: sds.url,
                company_safety_data_sheet_id: sds.pk,
              }),
            );

            await insertSds({
              variables: {
                objects: sdsToInsert,
              },
            });
          })
          .then((data) => message.success("SDS has been added to the project"))
          .catch((error) => {
            message.error(
              `ERROR: ${
                error instanceof Error ? error.message : JSON.stringify(error)
              }`,
            );
            console.log("Validate Failed:", error);
          });
        handleCancel();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="subs"
          label="Subcontractor(s)"
          rules={[{ required: true, message: "select subcontractors" }]}
          style={{ width: "65%", marginTop: "40px" }}
        >
          <Select
            listItemHeight={10}
            mode="multiple"
            style={{
              width: "100%",
            }}
            showArrow
            bordered
            options={projectSubcontractors.map((sub) => ({
              value: sub.id,
              key: sub.id,
              label: sub.name,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddSdsToProjectModal;
