import React from "react";
import { Button } from "antd";

export interface SubProjectMobilizationStepCompleteProps {
  onPrev: () => void;
}

const SubProjectMobilizationStepComplete: React.FC<
  SubProjectMobilizationStepCompleteProps
> = (props) => {
  return (
    <div className ="flex flex-row ml-10">
      <div className="mt-0.5">
        <Button
          onClick={() => {
            props.onPrev();
          }}
        >
          Back
        </Button>
      </div>
      <div className={`text-2 ml-2 mt-0.125`}>Thank you 🎉</div>
    </div>
  );
};

export default SubProjectMobilizationStepComplete;
