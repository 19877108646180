/**
 * @generated SignedSource<<ff58648f258883e56138955f5e422d3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ladder_permit_set_input = {
  applicant_project_worker_id?: string | null | undefined;
  created_at?: string | null | undefined;
  duration?: number | null | undefined;
  has_special_precautions?: boolean | null | undefined;
  is_access_ladder?: boolean | null | undefined;
  is_inspecting?: boolean | null | undefined;
  is_working_over_x_feet?: boolean | null | undefined;
  ladder_capcity?: string | null | undefined;
  ladder_height?: string | null | undefined;
  ladder_identification?: string | null | undefined;
  last_inspection_at?: string | null | undefined;
  necessity_description_id?: string | null | undefined;
  permissible_ladder_height?: number | null | undefined;
  permit_id?: string | null | undefined;
  returned_at?: string | null | undefined;
  returned_marked_by_uid?: string | null | undefined;
  special_precautions_description_id?: string | null | undefined;
  submitted_at?: string | null | undefined;
  type_of_ladder?: string | null | undefined;
  updated_at?: string | null | undefined;
  work_description_id?: string | null | undefined;
  work_location_id?: string | null | undefined;
  work_start_at?: string | null | undefined;
};
export type PermitTable_UpdateLadder_Mutation$variables = {
  _set: ladder_permit_set_input;
  permitId: string;
};
export type PermitTable_UpdateLadder_Mutation$data = {
  readonly update_ladder_permit_by_pk: {
    readonly id: string;
    readonly ladder_identification: string | null | undefined;
    readonly returned_at: string | null | undefined;
    readonly returned_marked_by_uid: string | null | undefined;
    readonly returned_marked_by_user: {
      readonly name: string;
    } | null | undefined;
  } | null | undefined;
};
export type PermitTable_UpdateLadder_Mutation = {
  response: PermitTable_UpdateLadder_Mutation$data;
  variables: PermitTable_UpdateLadder_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "permitId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "permit_id",
        "variableName": "permitId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ladder_identification",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "returned_at",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "returned_marked_by_uid",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PermitTable_UpdateLadder_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ladder_permit",
        "kind": "LinkedField",
        "name": "update_ladder_permit_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "returned_marked_by_user",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PermitTable_UpdateLadder_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ladder_permit",
        "kind": "LinkedField",
        "name": "update_ladder_permit_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "returned_marked_by_user",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "39d7199f9da3f2cbebb07b845c108e5a",
    "id": null,
    "metadata": {},
    "name": "PermitTable_UpdateLadder_Mutation",
    "operationKind": "mutation",
    "text": "mutation PermitTable_UpdateLadder_Mutation(\n  $permitId: uuid!\n  $_set: ladder_permit_set_input!\n) {\n  update_ladder_permit_by_pk(pk_columns: {permit_id: $permitId}, _set: $_set) {\n    ladder_identification\n    id\n    returned_at\n    returned_marked_by_uid\n    returned_marked_by_user {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e7582663cd0464e95f562047d7bef5ef";

export default node;
