import { FC, useState } from "react";
import { Form, Input, Select, Switch, message, notification } from "antd";
import { useConvertEmployeeToWorkerMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { useGetWorkerTitlesAndRolesQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { commitLocalUpdate, ConnectionHandler } from "relay-runtime";
import { useRelayEnvironment } from "react-relay";
import FModal from "../dialogs/FModal";
import hasApolloErrorCode from "src/utility-features/error-handling/hasApolloErrorCode";

export type ConvertEmployeeToWorkerDataType = Array<{
  name: string;
  uid: string;
  email?: string;
  created_password: boolean;
  dataId?: string;
  phoneNumber?: string;
}>;
const { Option } = Select;

const ConvertEmployeeToWorkerModal: FC<{
  visible: boolean;
  //   refetch: () => void;
  onClose: () => void;
  data: ConvertEmployeeToWorkerDataType;
  subs?: Array<{ name: string; id: string }>;
}> = ({ data, visible, onClose, subs }) => {
  const [form] = Form.useForm();
  const [convertEmployeeToWorker, { loading }] =
    useConvertEmployeeToWorkerMutation();
  const { data: titlesAndRolesData, loading: workerTitleAndRoleLoading } =
    useGetWorkerTitlesAndRolesQuery();
  const relayEnv = useRelayEnvironment();
  const [employeeEmailDisabled, setEmployeeEmailDisabled] = useState(true);
  const [employeePhoneDisabled, setEmployeePhoneDisabled] = useState(true);

  return (
    <FModal
      onCancel={onClose}
      title=" Convert a Profile on your Team to a Worker or Foreman"
      width={950}
      okText="Convert"
      open={visible}
      confirmLoading={loading}
      onOk={async () => {
        const vals = await form.validateFields();
        try {
          await convertEmployeeToWorker({
            variables: {
              input: {
                employee_id: vals.employee,
                titleId: vals.title,
                role: vals.role,
                email: vals.employeeEmail,
                subcontractor_id: vals.subcontractor,
                mobileAccess: vals.mobileAccess,
                phoneNumber: vals.employeePhone,
              },
            },
          })
            .then((a) => {
              message.success(" Employee converted to Worker successful");
            })
            .catch((error) => {
              if (hasApolloErrorCode(error, "email-send-error")) {
                return false;
              } else {
                throw error;
              }
            });
          commitLocalUpdate(relayEnv, (store) => {
            const conn1 = ConnectionHandler.getConnection(
              store.getRoot(),
              "GCProjectTeam_project_employee_connection",
            );
            const conn2 = ConnectionHandler.getConnection(
              store.getRoot(),
              "GCProjectWorkersTableQuery_project_worker_connection",
            );
            const conn3 = ConnectionHandler.getConnection(
              store.getRoot(),
              "SCWorkersQuery_worker_connection",
            );
            if (conn1) {
              data.forEach((p) => {
                if (p.uid === vals.employee) {
                  ConnectionHandler.deleteNode(conn1, p.dataId!);
                }
              });
            }
            if (conn2) {
              data.forEach((p) => {
                if (p.uid === vals.employee) {
                  ConnectionHandler.deleteNode(conn2, p.dataId!);
                }
              });
            }
            if (conn3) {
              data.forEach((p) => {
                if (p.uid === vals.employee) {
                  ConnectionHandler.deleteNode(conn3, p.dataId!);
                }
              });
            }
          });
          //   message.success("Conversion finished succesfully");
          form.resetFields();
          onClose();
          // setToKeep(undefined);
        } catch (err) {
          console.error(err);
          notification.error({
            message: "Couldn't Convert Employee to Worker",
            description: `ERROR: ${
              err instanceof Error ? err.message : String(err)
            }`,
          });
        }
      }}
    >
      <Form form={form} layout="vertical">
        <div>Select the person to convert to a Worker &nbsp;&nbsp;</div>
        <Form.Item
          name="employee"
          label={"Select Profile"}
          rules={[{ required: true, message: "Choose a User" }]}
        >
          <Select
            // onChange={(val) => setToKeep(val.toLocaleString())}
            showSearch
            filterOption={(input, option) => {
              if (!option || !option.props.children[0]) return false;
              return (
                option.props.children[0]
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              );
            }}
            onChange={(value) => {
              const selectedEmployee = data.find((u) => u.uid === value);
              if (selectedEmployee) {
                form.setFieldsValue({
                  employeeEmail: selectedEmployee.email || "",
                  employeePhone: selectedEmployee.phoneNumber,
                });
                setEmployeeEmailDisabled(!!selectedEmployee.email);
                setEmployeePhoneDisabled(!!selectedEmployee.phoneNumber);
              }
            }}
          >
            {data.map((u) => {
              const list = [u.name, u.email].filter((s) => s);
              return (
                <Option value={u.uid} key={u.uid}>
                  {list.join(", ")}&nbsp;&nbsp;
                  <span className="font-accent text-interactive-primary">
                    {u.created_password ? " PASSWORD SET" : ""}
                  </span>
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="employeeEmail"
          label="Employee Email"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                const isOtherFieldTrue = getFieldValue("mobileAccess"); // Replace 'otherFieldName' with the actual name of the other field
                if (isOtherFieldTrue && !value) {
                  return Promise.reject(
                    "Please enter the email to be assigned to the selected employee",
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            disabled={employeeEmailDisabled}
            // defaultValue={employeeEmail || enteredEmail}
            // value={employeeEmailDisabled ? employeeEmail : enteredEmail}
            onChange={(e) =>
              form.setFieldsValue({ employeeEmail: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          name="mobileAccess"
          label="Allow Mobile App access"
          initialValue={false}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <div style={{ marginTop: "-23px" }}>
          Only invite the worker to the mobile app if they will be submitting
          PTPs, DRs, TBTs, etc. Typically this is a Foreman or Crew Leader
          <br />
          <br />
        </div>
        <Form.Item name="employeePhone" label="Phone Number">
          <Input
            disabled={employeePhoneDisabled}
            // defaultValue={workerEmail || enteredEmail}
            // value={employeePhone}
            onChange={(e) =>
              form.setFieldsValue({ employeePhone: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          name="title"
          label="Select Worker’s Trade"
          rules={[{ required: true, message: "Choose a title" }]}
        >
          <Select style={{ width: "100%" }}>
            {titlesAndRolesData?.worker_title.map((w) => (
              <Select.Option key={w.id} value={w.id}>
                {w.translation.en}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="role"
          label="Select Worker’s Role"
          rules={[{ required: true, message: "Choose a title" }]}
        >
          <Select style={{ width: "100%" }}>
            {titlesAndRolesData?.worker_role.map((w) => (
              <Select.Option key={w.value} value={w.value}>
                {w.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="subcontractor"
          label="Subcontractor"
          rules={[{ required: true, message: "Choose a subcontractor" }]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Select the subcontractor they work for"
          >
            {subs
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((sub) => (
                <Select.Option key={sub.id} value={sub.id}>
                  {sub.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <div>
          By confirming, this person will be converted to a Worker or Foreman
          for this Subcontractor. You can give them access to the SiteForm
          mobile app to submit reports. .
          <br />
          <i>
            Typically this is required when a Worker (possibly on your Self
            Perform crew) selected GC/Manager during orientation instead of
            Worker.
          </i>
        </div>
        <br />
      </Form>
    </FModal>
  );
};
export default ConvertEmployeeToWorkerModal;
