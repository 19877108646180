import React, { cloneElement, FC } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableBodyRow from "./DraggableBodyRow";

interface Props {
  children: {
    table: JSX.Element;
  };
  onMoveRow: (dragIndex: number, hoverIndex: number) => any;
  enabled?: boolean;
}

const DraggableTableWrapper: FC<Props> = ({
  children,
  onMoveRow,
  enabled = true,
}) => {
  if (!enabled) {
    return children.table;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      {cloneElement(children.table, {
        components: {
          body: {
            row: DraggableBodyRow,
          },
        },
        onRow: (record: any, index: number) => ({
          index,
          moveRow: onMoveRow,
        }),
      })}
    </DndProvider>
  );
};

export default DraggableTableWrapper;
