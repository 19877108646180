import { IconMail, IconMessage } from "@tabler/icons";
import { Button, Form, Input, Modal, message, notification } from "antd";
import React, { FC } from "react";
import CustomButton from "src/common/components/general/Button";

import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import updateProjectWorkersMutation from "src/common/api/relay/mutations/UpdateProjectWorkersForWorkerRegistrationWithHardHat";
import { UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation } from "src/common/types/generated/relay/UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation.graphql";
import {
  useEmailOrientationInviteMutation,
  useSendOrientationLinkTextMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import workerAndUserUpdateMutation from "src/common/api/relay/mutations/UpdateWorkerAndUser";
import { UpdateWorkerAndUserMutation } from "src/common/types/generated/relay/UpdateWorkerAndUserMutation.graphql";
import { useForm } from "antd/lib/form/Form";

const SelectHowToSendOrientationInvite: FC<{
  onClose: () => void;
  visible: boolean;
  worker: {
    name: string;
    id: string;
    email?: string | null;
    phone_number?: string | null;
  };
  projectId: string;
  hasSlides: boolean;
  refetch?: () => void;
}> = ({ onClose, visible, worker, hasSlides, projectId, refetch }) => {
  const [emailInvite, { loading }] = useEmailOrientationInviteMutation();
  const [textInvite] = useSendOrientationLinkTextMutation();
  const [workerUpdate, isInserting] =
    useAsyncMutation<UpdateWorkerAndUserMutation>(workerAndUserUpdateMutation);
  const [textForm] = useForm();
  const [emailForm] = useForm();
  return (
    <Modal
      open={visible}
      footer={<Button onClick={onClose}>Cancel</Button>}
      onCancel={onClose}
      title={`Send orientation link to ${worker?.name}`}
    >
      <>
        <div className="flex justify-between mt-2">
          <div>
            {worker.email ? (
              <div className="ml-4 ">
                <CustomButton
                  label="Email"
                  icon={IconMail}
                  onClick={() => {
                    console.log(worker.email);

                    try {
                      if (worker.email) {
                        emailInvite({
                          variables: {
                            input: {
                              projectId,
                              emailRecipients: [worker.email],
                            },
                          },
                        }).catch((err) => {
                          notification.error({
                            description:
                              err instanceof Error
                                ? err.message
                                : JSON.stringify(err),
                            message: "Email Couldn't be sent",
                          });
                        });
                        message.success(
                          `${
                            hasSlides ? "Orientation" : "Registration"
                          } Invite sent to ` + worker.name,
                        );
                        onClose();
                      } else {
                        message.warning("Please enter Worker's Email first");
                      }
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                />
              </div>
            ) : (
              <>
                <Form
                  className=" ml-2"
                  form={emailForm}
                  onFinish={async (e) => {
                    if (e.email) {
                      try {
                        await workerUpdate({
                          variables: {
                            workerId: worker.id,
                            user_set: { email: e.email },
                            worker_set: {},
                          },
                        }).then(() => refetch && refetch());
                        emailInvite({
                          variables: {
                            input: {
                              projectId,
                              emailRecipients: [e.email],
                            },
                          },
                        });
                        message.success(
                          `${
                            hasSlides ? "Orientation" : "Registration"
                          } Invite sent to ` + worker.name,
                        );
                      } catch (e) {
                        console.log(e);
                        message.error("Invite Failed");
                      }
                      emailForm.resetFields();
                      onClose();
                    } else {
                      message.warning("Please enter Worker's Email first");
                    }
                  }}
                >
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: "Enter email" }]}
                  >
                    <Input placeholder="Enter Email" />
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit" type="primary">
                      Send
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
          </div>
          <div className=" mt-0.5">OR</div>
          <div className="">
            {worker.phone_number ? (
              <div className="mr-4">
                <CustomButton
                  label="Text"
                  icon={IconMessage}
                  onClick={() => {
                    console.log(worker.phone_number, "HHHH");
                    if (worker.phone_number) {
                      textInvite({
                        variables: {
                          input: {
                            projectId,
                            toPhoneNumbers: [worker.phone_number],
                          },
                        },
                      }).catch((err) => {
                        notification.error({
                          message: "Message couldn't be sent",
                          description:
                            "Number couldn't be found, if you think it's correct, please check and try again.",
                        });
                      });
                      message.success(
                        `${
                          hasSlides ? "Orientation" : "Registration"
                        } Invite sent to ` + worker.name,
                      );
                      onClose();
                    } else
                      message.warning(
                        "Please enter Worker's phone number first",
                      );
                  }}
                />
              </div>
            ) : (
              <>
                <Form
                  form={textForm}
                  className=" mr-2"
                  onFinish={async (e) => {
                    console.log(e.phone);
                    if (e.phone) {
                      try {
                        await workerUpdate({
                          variables: {
                            workerId: worker.id,
                            user_set: { phone_number: e.phone },
                            worker_set: {},
                          },
                        }).then(() => refetch && refetch());
                        textInvite({
                          variables: {
                            input: {
                              projectId,
                              toPhoneNumbers: [e.phone],
                            },
                          },
                        }).catch((err) => {
                          notification.error({
                            message: "Message couldn't be sent",
                            description:
                              "Number couldn't be found, if you think it's correct, please check and try again.",
                          });
                        });
                        message.success(
                          `${
                            hasSlides ? "Orientation" : "Registration"
                          } Invite sent to ` + worker.name,
                        );
                      } catch (e) {
                        console.log(e);
                        message.error("Invite Failed");
                      }
                      textForm.resetFields();
                      onClose();
                    } else {
                      message.warning(
                        "Please enter Worker's Phone number first",
                      );
                    }
                  }}
                >
                  <Form.Item
                    name="phone"
                    rules={[{ required: true, message: "Enter phone number" }]}
                  >
                    <input
                      type="tel"
                      placeholder="e.g. 111-111-1111"
                      className="bg-white border-0.125 border-grey w-full m-0 text-1"
                      pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}|[0-9]{10}|[0-9]{3} [0-9]{3} [0-9]{4}|[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      title="Please enter a 10 digit phone number with no spaces or characters"
                      // inputMode="numeric"
                      onKeyDown={(e) => {}}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit" type="primary">
                      Send
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
          </div>
        </div>
        {/* <div className="font-accent text-center">
            Send orientation link to {worker.name}
          </div> */}
      </>
    </Modal>
  );
};
export default SelectHowToSendOrientationInvite;
