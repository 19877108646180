import React from "react";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import SiteOrientationStepsUIFullName from "../../../../../components/stepsUI/fullName/SiteOrientationStepsUIFullName";
import useSiteOrientationUniversal from "../../../newDesign/utils/siteOrientationUniversalHook";

import { useCurrentLangStrings } from "../../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteOrientationUniversalFullNameProps
  extends SiteOrientationStepProps {}

const SiteOrientationUniversalFullName: React.FC<
  SiteOrientationUniversalFullNameProps
> = (props) => {
  const workerContext = useSiteOrientationUniversal();
  const langStrings = useCurrentLangStrings();
  const handleNameSubmit = (newFullName: string) => {
    workerContext?.updateOrientee &&
      workerContext.updateOrientee((u) =>
        u
          ? {
              ...u,
              name: { old: u.name?.old, new: newFullName },
            }
          : { name: { new: newFullName } },
      );
    props.onNext();
  };
  return (
    <SiteOrientationStepsUIFullName
      onNameSubmit={handleNameSubmit}
      langStrings={langStrings}
      defaultName={workerContext.orientee?.name?.new}
      onBack={props.onBack}
      onNext={props.onNext}
    />
  );
};

export default SiteOrientationUniversalFullName;
