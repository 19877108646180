import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import IncidentSeverityMapping from "../../component/IncidentSeverityMapping";
import withBusinessUnitId, {
  BusinessUnitProps,
} from "../../utils/withBusinessUnitId";

const GCBusinessUnitIncident: React.FC<BusinessUnitProps> = ({
  businessUnitId,
}) => {
  const { userData } = useUserData();
  return (
    <IncidentSeverityMapping
      severityUserFilter={{ business_unit_id: { _eq: businessUnitId } }}
      toInsertWith={{
        gc_id: userData.employee?.general_contractor_id,
        business_unit_id: businessUnitId,
      }}
      gcEmployeeWhere={{
        gc_business_units: { gc_business_unit_id: { _eq: businessUnitId } },
      }}
    />
  );
};
export default withBusinessUnitId(GCBusinessUnitIncident);
