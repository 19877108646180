import Icon from "src/common/components/general/Icon";
import { IconCheck, IconSettings, IconX } from "@tabler/icons";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import React, { FC, useState } from "react";
import TurnOffEmailModal from "src/common/components/dialogs/TurnOffEmailModal";
import Switcher from "src/common/components/general/Switcher";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { RangeType } from "src/common/components/tables/basic/CustomTableDateRangePicker";
import GCDashAppReports from "src/domain-features/sitesafety/components/dash-app-reports/GCDashAppReports";
import { SubOnsiteData } from "src/domain-features/sitesafety/components/dash-app-reports/GCDashAppReportsTable";

export interface GCProjectReportManagementUIProps {
  projectId: string;
  projectTimezone: string;
  dashboardStart: dayjs.Dayjs;
  subOnsiteData: SubOnsiteData[];
  crewLeads: { [key: string]: string };
  reportTracking: {
    switch: (newValue: boolean) => void;
    active: boolean;
    loading?: boolean;
  };
  projectPermanentOnsite: {
    switch: (newValue: boolean) => void;
    active: boolean;
    loading?: boolean;
  };
  pauseNotificationDate: RangeType;
  pauseNotificationDateLoading: boolean;
  onChangePauseNotificationDate: (range: RangeType | null) => void;
  onlyReport?: boolean;
}

const GCProjectReportManagementUI: FC<GCProjectReportManagementUIProps> = (
  props,
) => {
  const [showTurnOffEmailModal, setShowTurnOffEmailModal] = useState(false);
  console.log(props.crewLeads);

  const modalOpen = () => {
    setShowTurnOffEmailModal(true);
  };

  const modalClose = () => {
    setShowTurnOffEmailModal(false);
  };

  return (
    <BasicWrapper scrollable>
      <div className="flex flex-col gap-1">
        {!props.onlyReport && (
          <>
            <div className="absolute top-2 right-2">
              <div className="opacity-20" onClick={() => modalOpen()}>
                <Icon color="static" icon={IconSettings} size="small" />
              </div>
            </div>
            <TurnOffEmailModal
              onCancel={modalClose}
              onOk={modalClose}
              modalVisible={showTurnOffEmailModal}
            />
            <div className="flex flex-row items-center text-1 gap-0.5">
              <Switcher
                loading={props.reportTracking.loading}
                optionIndex={props.reportTracking.active ? 0 : 1}
                onChange={(newIndex) => {
                  props.reportTracking.switch(newIndex === 0);
                }}
                options={[
                  {
                    icon: IconCheck,
                    label: "On",
                  },
                  {
                    icon: IconX,
                    label: "Off",
                  },
                ]}
              />
              Report Tracking and Auto-Notifications? (controls are in the
              mobile app)
            </div>

            <div className="flex flex-row items-center text-1 gap-0.5">
              <Switcher
                loading={props.projectPermanentOnsite.loading}
                optionIndex={props.projectPermanentOnsite.active ? 0 : 1}
                onChange={(newIndex) => {
                  props.projectPermanentOnsite.switch(newIndex === 0);
                }}
                options={[
                  {
                    icon: IconCheck,
                    label: "On",
                  },
                  {
                    icon: IconX,
                    label: "Off",
                  },
                ]}
              />
              Automatically set a company ONSITE when they submit a report in
              SiteForm
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex flex-row items-center text-1 gap-0.5">
                Pause Automatic Notifications: Select the date range to pause
                notifications :
                <DatePicker.RangePicker
                  style={{ float: "right" }}
                  value={props.pauseNotificationDate || undefined}
                  allowEmpty={[true, true]}
                  allowClear
                  disabled={props.pauseNotificationDateLoading}
                  disabledDate={(date) => date < dayjs().startOf("d")}
                  onChange={props.onChangePauseNotificationDate}
                />
              </div>
            </div>
          </>
        )}

        <GCDashAppReports
          subOnsiteData={props.subOnsiteData}
          projectTimezone={props.projectTimezone}
          projectId={props.projectId}
          crewLeads={props.crewLeads}
          dashboardStart={props.dashboardStart}
          onsiteSwitcher={true}
        />
      </div>
    </BasicWrapper>
  );
};

export default GCProjectReportManagementUI;
