import { Descriptions, Space, Tag } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";

import React, { FC } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import BSpace from "src/common/components/layouts/BSpace";
import StyledContent from "src/common/components/layouts/StyledContent";
import getConnectionNodes from "src/common/functions/getConnectionNodes";
import ReportProps from "src/common/types/manual/ReportProps";
import ImageCarousel from "src/common/components/general/images/ImageCarousel";
import { GCProjectReportsSafetyReportEntryQuery } from "src/common/types/generated/relay/GCProjectReportsSafetyReportEntryQuery.graphql";

const query = graphql`
  query GCProjectReportsSafetyReportEntryQuery($safetyReportId: uuid!) {
    daily_safety_report_connection(where: { id: { _eq: $safetyReportId } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          created_at
          date
          project {
            name
          }
          created_by_user {
            id
            name
            pk: id @__clientField(handle: "pk")
          }
          subcontractor {
            name
            pk: id @__clientField(handle: "pk")
          }
          images(order_by: { sort_index: asc }) {
            ...ImageFrag @relay(mask: false)
          }
          daily_safety_report_tags {
            id
            pk: id @__clientField(handle: "pk")
            tag {
              ...TagFrag @relay(mask: false)
            }
          }
          daily_safety_report_comments {
            id
            pk: id @__clientField(handle: "pk")
            created_at
            created_by_user {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
            text_translation {
              en
            }
          }
        }
      }
    }
  }
`;
const GCProjectReportsSafetyReportEntry: FC<ReportProps> = ({ reportId }) => {
  const data = useLazyLoadQuery<GCProjectReportsSafetyReportEntryQuery>(query, {
    safetyReportId: reportId,
  });
  const log = getConnectionNodes(data.daily_safety_report_connection).map(
    (l) => ({
      pk: l.pk,
      date: dayjs(l.date as string).format("ll"),
      project: l.project.name,
      submitter: l.created_by_user.name,
      submitted_at: dayjs(l.created_at)
        .tz("America/New_York")
        .format("MMMM Do YYYY, h:mm:ss A z"),
      subcontractor: l.subcontractor?.name ?? "",

      tags: l.daily_safety_report_tags.map((l1) => {
        return <Tag color={l1.tag.color_hex}>{l1.tag.name.en}</Tag>;
      }),
      images: !(l.images && l.images.length > 0)
        ? null
        : l.images.map((image) => {
            return {
              url: image?.url ?? "",
              description: image?.description ?? "",
            };
          }),
      comment: l.daily_safety_report_comments.map((l1) => {
        return {
          pk: l1.pk,
          comment: l1.text_translation.en,
        };
      }),
    }),
  );
  const report = log[0];
  let x = 0;
  return (
    <>
      <Space
        size="large"
        direction="vertical"
        style={{ width: "100%", minWidth: 850 }}
      >
        <StyledContent padding backgroundColor="white">
          <BSpace>
            <Descriptions
              title="Safety Log"
              bordered
              size="middle"
              layout="horizontal"
            >
              <Descriptions.Item label="Date" key={x++}>
                {report.date}
              </Descriptions.Item>
              <Descriptions.Item label="Project">
                {report.project}
              </Descriptions.Item>
              <Descriptions.Item label="Subcontractor" key={x++}>
                {report.subcontractor}
              </Descriptions.Item>
              <Descriptions.Item label="Tags" key={x++}>
                {report.tags}
              </Descriptions.Item>
              <Descriptions.Item label="Submitter" key={x++}>
                {report.submitter}
              </Descriptions.Item>
              <Descriptions.Item
                label="Submitted at"
                key={x++}
                style={{ minWidth: 150 }}
              >
                {report.submitted_at}
              </Descriptions.Item>
            </Descriptions>
          </BSpace>
        </StyledContent>

        {report.images && report.images.length > 0 && (
          <StyledContent padding backgroundColor="white">
            <BSpace>
              <h3>Images</h3>
              <ImageCarousel
                images={report.images.map((image) => {
                  return { url: image.url ?? "" };
                })}
              />
            </BSpace>
          </StyledContent>
        )}

        {
          <StyledContent padding backgroundColor="white">
            <Descriptions title="Details" size="middle" layout="horizontal" />
            <div style={{ whiteSpace: "pre-wrap" }}>
              {report.comment[0].comment}
            </div>
          </StyledContent>
        }
      </Space>
    </>
  );
};
export default GCProjectReportsSafetyReportEntry;
