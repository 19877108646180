import React, { useContext, useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { Select } from "antd";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import dayjs from "dayjs";
import { yearsInConstOptions } from "src/domain-features/siteorientation/utils/demographicQuestionOptions";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsWorkYearsProps
  extends SiteOrientationStepProps {}

const SiteOrientationInPersonWorkerStepsWorkYears: React.FC<
  SiteOrientationInPersonWorkerStepsWorkYearsProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();
  const user = workerContext.orientee;
  let defaulVal: string | undefined = undefined;
  if (user && user.userType === "worker" && user.workYears) {
    defaulVal = user.workYears.new?.val ?? user.workYears.old?.val ?? undefined;
  }
  const [workYears, setWorkYears] = useState(defaulVal);
  const langStrings = useCurrentLangStrings();
  const onEnter = async (pickedVal?: string) => {
    if (user?.id) {
      workerContext.updateOrientee((u) =>
        u?.userType === "worker"
          ? {
              ...u,
              workYears: {
                old: u.workYears?.old,
                new: { val: pickedVal ?? workYears, enteredAt: dayjs() },
              },
            }
          : null,
      );
      props.onNext();
    }
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={() => {
        !!workYears && onEnter(workYears);
      }}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!workYears}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.yearsInConstruction}
      >
        <div className={`flex flex-col gap-0.5`}>
          <p className={`text-1.5`}>
            {langStrings.strings.howManyYearsHaveYouWorkedInConstruction}
          </p>
          <Select
            className="text-1"
            virtual={false}
            value={workYears}
            onChange={(e) => {
              setWorkYears(e.valueOf());
              onEnter(e);
            }}
          >
            {yearsInConstOptions.map((start) => (
              <Select.Option value={start} key={start} className="text-1">
                {start}
              </Select.Option>
            ))}
          </Select>
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerStepsWorkYears;
