import { IconDownload } from "@tabler/icons";
import React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import ReportsTabsGroup, {
  ReportsTabType,
} from "src/common/components/ReportsTabsGroup";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import { GCReportsNavigationQuery } from "src/common/types/generated/relay/GCReportsNavigationQuery.graphql";
import useAuthUser from "src/common/hooks/useAuthUser";

interface GCReportsNavigationProps {}
export const reportsNavigationQuery = graphql`
  query GCReportsNavigationQuery($projectId: uuid!, $empId: uuid!) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          is_visitor_log_and_tracking_active
          visitor_sign_waiver
          visitor_waiver_text
        }
      }
    }
    general_contractor_connection(
      where: { employees: { uid: { _eq: $empId } } }
    ) {
      edges {
        node {
          visitor_waiver_text
        }
      }
    }
  }
`;
const GCReportsNavigation: React.FunctionComponent<
  GCReportsNavigationProps
> = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const authUser = useAuthUser();
  if (!projectId) throw new Error("ProjectId not found");
  const data = useLazyLoadQuery<GCReportsNavigationQuery>(
    reportsNavigationQuery,
    { projectId: projectId, empId: authUser.uid },
    //refreshedQueryOptions ?? {}
  );
  const handleReportsTabClick = (tabId: string) => {
    navigate(tabId);
  };
  const tabs: ReportsTabType[] = [
    {
      id: "performance",
      label: "Performance",
    },
    {
      id: "daily",
      label: "Daily Reports",
    },
    {
      id: "permits",
      label: "Permits",
    },
    {
      id: "pretaskplans",
      label: "Pre-Task Plans",
    },
    {
      id: "toolboxtalks",
      label: "Toolbox Talks",
    },
    ...(data.project_connection.edges &&
    data.project_connection.edges[0].node.is_visitor_log_and_tracking_active
      ? [
          {
            id: "visitors",
            label: "Visitors",
          },
        ]
      : []),
    {
      id: "reports-download",
      label: "Reports",
      icon: IconDownload,
    },
    {
      id: "covid19",
      label: "Covid 19",
    },
    {
      id: "settings",
      label: "Settings",
    },
  ];
  return (
    <BasicWrapper scrollable>
      <ReportsTabsGroup
        onTabClick={handleReportsTabClick}
        tabs={tabs}
        tabsType="link"
      />
      <div className="flex flex-1 w-full overflow-scroll ">
        <div className={`min-h-min flex w-full h-full`}>
          <Outlet />
        </div>
      </div>
    </BasicWrapper>
  );
};

export default GCReportsNavigation;
