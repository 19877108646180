/**
 * @generated SignedSource<<ce81d5d8c7454925904378052b85e350>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type general_contractor_set_input = {
  agc_universal_orientation?: boolean | null | undefined;
  allow_permit_activation_location_change?: boolean | null | undefined;
  employee_verification?: boolean | null | undefined;
  hierarchy_business_unit_name?: string | null | undefined;
  hierarchy_division_name?: string | null | undefined;
  hierarchy_office_name?: string | null | undefined;
  hot_work_permit_name?: string | null | undefined;
  id?: string | null | undefined;
  in_person_registration_poster_background_url?: string | null | undefined;
  incident_enabled?: boolean | null | undefined;
  logo_url?: string | null | undefined;
  name?: string | null | undefined;
  observation_enabled?: boolean | null | undefined;
  only_submitter_sign_the_confined_space_permit?: boolean | null | undefined;
  only_submitter_sign_the_dig_excavation_permit?: boolean | null | undefined;
  only_submitter_sign_the_dig_permit?: boolean | null | undefined;
  only_submitter_sign_the_historical_access_permit?: boolean | null | undefined;
  only_submitter_sign_the_hot_work_permit?: boolean | null | undefined;
  only_submitter_sign_the_interior_penetration_permit?: boolean | null | undefined;
  only_submitter_sign_the_loto_permit?: boolean | null | undefined;
  only_submitter_sign_the_out_of_basket_permit?: boolean | null | undefined;
  only_submitter_sign_the_steel_erection_permit?: boolean | null | undefined;
  only_submitter_sign_the_work_above_permit?: boolean | null | undefined;
  orientation_qr_code_poster_background_url?: string | null | undefined;
  procore_trade_id?: string | null | undefined;
  procore_vendor_id?: string | null | undefined;
  require_dob?: boolean | null | undefined;
  require_phone?: boolean | null | undefined;
  require_workers_to_sign_out_of_basket_permit?: boolean | null | undefined;
  send_weekly_email?: boolean | null | undefined;
  visitor_log_poster_background_url?: string | null | undefined;
  visitor_waiver_text?: string | null | undefined;
};
export type ProcoreSubConfigModal_updateProcoreGC_Mutation$variables = {
  _set: general_contractor_set_input;
  id: string;
};
export type ProcoreSubConfigModal_updateProcoreGC_Mutation$data = {
  readonly update_general_contractor: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type ProcoreSubConfigModal_updateProcoreGC_Mutation = {
  response: ProcoreSubConfigModal_updateProcoreGC_Mutation$data;
  variables: ProcoreSubConfigModal_updateProcoreGC_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_eq",
                    "variableName": "id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "uid"
              }
            ],
            "kind": "ObjectValue",
            "name": "employees"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "general_contractor_mutation_response",
    "kind": "LinkedField",
    "name": "update_general_contractor",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "general_contractor",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProcoreSubConfigModal_updateProcoreGC_Mutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProcoreSubConfigModal_updateProcoreGC_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "81fcc72ca414c35b2166f2c6ae790563",
    "id": null,
    "metadata": {},
    "name": "ProcoreSubConfigModal_updateProcoreGC_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProcoreSubConfigModal_updateProcoreGC_Mutation(\n  $id: uuid!\n  $_set: general_contractor_set_input!\n) {\n  update_general_contractor(where: {employees: {uid: {_eq: $id}}}, _set: $_set) {\n    returning {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "24cefd493fe9c3d91f7e52ad985297ae";

export default node;
