import React from "react";
import HandThumbUp from "./hand-thumb-up.png";
import HandStop from "./hand-waving.png";
import { motion } from "framer-motion";

export interface SiteOrientationInPersonWorkerRegistrationStepsCompletedHandAnimationProps {
  handType: "waving" | "thumb-up";
}

const SiteOrientationInPersonWorkerRegistrationStepsCompletedHandAnimation: React.FC<
  SiteOrientationInPersonWorkerRegistrationStepsCompletedHandAnimationProps
> = (props) => {
  const imageSrc = (() => {
    switch (props.handType) {
      case "waving":
        return HandStop;
      case "thumb-up":
        return HandThumbUp;
      default:
        return HandStop;
    }
  })();

  return (
    <div>
      <motion.img
        key={imageSrc}
        src={imageSrc}
        alt={`hand ${props.handType}`}
        style={{
          opacity: 0,
          scale: 0.5,
          rotate: "-30deg",
          translateX: "-50%",
        }}
        animate={{
          opacity: 1,
          scale: 1,
          rotate: "0deg",
          translateX: "0%",
        }}
        transition={{ duration: 1.2, type: "spring", bounce: 0.5, delay: 0.6 }}
      />
    </div>
  );
};

export default SiteOrientationInPersonWorkerRegistrationStepsCompletedHandAnimation;
