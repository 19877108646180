import React, { useState } from "react";
import { auth } from "src/common/functions/firebase";
import { usePseudoWorkerSignInMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { roleVar } from "src/common/api/apollo/cache";
import { message } from "antd";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import SiteOrientationStepsUIEmail from "../../../../../components/stepsUI/email/SiteOrientationStepsUIEmail";
import useSiteOrientationUniversal from "../../../newDesign/utils/siteOrientationUniversalHook";
import hasApolloErrorCode from "../../../../../../../utility-features/error-handling/hasApolloErrorCode";
import { signInWithCustomToken } from "firebase/auth";

export interface SiteOrientationUniversalEmailProps
  extends SiteOrientationStepProps {
  onNoEmailPress: () => void;
  onLoginSuccess?: (workerId: string) => Promise<void>;
}

const SiteOrientationUniversalEmail: React.FC<
  SiteOrientationUniversalEmailProps
> = (props) => {
  const workerContext = useSiteOrientationUniversal();
  const [email, setEmail] = useState<string>(
    workerContext.orientee?.email || "",
  );
  const [pseudoWorkerSignIn] = usePseudoWorkerSignInMutation();
  const [loading, setLoading] = useState(false);

  const onEnter = async () => {
    // Update email if empty
    setLoading(true);
    const loggedInUser = await auth.currentUser?.getIdTokenResult();
    await pseudoWorkerSignIn({
      variables: {
        input: {
          returnToken: loggedInUser?.claims.role !== "employee",
          email: email,
        },
      },
    })
      .then(async ({ data }) => {
        if (data?.pseudoWorkerSignIn) {
          const { workerId, token, userRole } = data?.pseudoWorkerSignIn;
          if (token) {
            roleVar("none");
            await auth.signOut();
            await signInWithCustomToken(auth, token);
          }
          if (userRole === "employee") {
            // Navigate to OAC USER
            // navigate(
            //   `/orientations/${projectId}/${type}/${lang}/gcRegistration?userId=${workerId}&email64=${window.btoa(
            //     e.email
            //   )}`
            // )
          } else {
            props.onLoginSuccess && (await props.onLoginSuccess(workerId));
            props.onNext();
          }
        }
      })
      .catch((err) => {
        if (hasApolloErrorCode(err, "user-does-not-exist")) {
          workerContext.updateOrientee((u) =>
            u ? { ...u, email: email } : { email },
          );
          props.onNext();
        } else {
          message.warning(err.message);
        }
      });
    setLoading(false);
  };

  return (
    <SiteOrientationStepsUIEmail
      value={email}
      onValueChange={setEmail}
      onEnter={onEnter}
      onBack={props.onBack}
      onNoEmailPress={props.onNoEmailPress}
      loading={loading}
    />
  );
};

export default SiteOrientationUniversalEmail;
