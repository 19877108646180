import { Checkbox } from "antd";
import React, { FC, useEffect, useState } from "react";

type CheckboxItemType = {
  value: string;
  text: React.ReactNode;
};

export interface CheckboxGroupProps {
  preCheckedValues: string[];
  onCheckedValuesChange?: (newValueList: string[]) => void;
  plainItems: CheckboxItemType[];
  style?: React.CSSProperties;
  readOnly?: boolean;
  title?: string;
}

const CheckboxGroup: FC<CheckboxGroupProps> = (props) => {
  const [checkedValues, setCheckedValues] = useState<string[]>(
    props.preCheckedValues,
  );

  const plainValues = props.plainItems.map((item) => item.value);

  const indeterminate =
    !!checkedValues.length && checkedValues.length < props.plainItems.length;
  const checkAll = checkedValues.length === props.plainItems.length;

  const onChange = (list: string[]) => {
    setCheckedValues(list);
    if (!props.onCheckedValuesChange) return;
    props.onCheckedValuesChange(list);
  };

  const onCheckAllChange = (checked: boolean) => {
    const newCheckedValues = checked ? plainValues : [];
    setCheckedValues(newCheckedValues);
    if (!props.onCheckedValuesChange) return;
    props.onCheckedValuesChange(newCheckedValues);
  };

  return (
    <div>
      {!!props.title && (
        <Checkbox
          indeterminate={indeterminate}
          onChange={(e) => {
            onCheckAllChange(e.target.checked);
          }}
          checked={checkAll}
          className={`font-accent`}
          disabled={props.readOnly}
        >
          {props.title}
        </Checkbox>
      )}
      <div style={props.style}>
        {props.plainItems.map((checkboxItem, i) => (
          <div key={checkboxItem.value}>
            <Checkbox
              disabled={props.readOnly}
              style={{ fontSize: "1em" }}
              checked={checkedValues.includes(checkboxItem.value)}
              onChange={(e) => {
                if (props.readOnly) return;
                const newValues = checkedValues.filter(
                  (x) => x !== checkboxItem.value,
                );
                if (e.target.checked) {
                  newValues.push(checkboxItem.value);
                }
                onChange(newValues);
              }}
            >
              {checkboxItem.text}
            </Checkbox>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckboxGroup;
