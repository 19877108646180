import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useUpdateInspectionInstanceMutation } from "src/common/types/generated/relay/useUpdateInspectionInstanceMutation.graphql";

const useUpdateInspectionInstance = () => {
  return useAsyncMutation<useUpdateInspectionInstanceMutation>(
    graphql`
      mutation useUpdateInspectionInstanceMutation(
        $id: uuid!
        $_set: inspection_instance_set_input!
      ) {
        update_inspection_instance_by_pk(pk_columns: { id: $id }, _set: $_set) {
          id
          pk: id @__clientField(handle: "pk")
          deleted_at
        }
      }
    `
  );
};
export default useUpdateInspectionInstance;
