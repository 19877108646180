
import { Outlet } from "react-router-dom";
import React from "react";
import NestedRoute from "src/common/types/manual/NestedRoute";
import SafetyPlan from "../components/SCSafetyPlan";

const SafetyPlanRoute: NestedRoute = {
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <SafetyPlan />,
    },
  ],
};

export default SafetyPlanRoute;
