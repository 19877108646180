import { useContext, useState } from "react";
import siteOrientationOrienteeContext from "./siteOrientationOrienteeContext";
import { message, notification } from "antd";
import dayjs from "dayjs";
import { roleVar } from "src/common/api/apollo/cache";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import ageByBirthDate from "src/common/functions/ageByBirthDate";
import {
  useCreateNewGcUserAndPseudoSignInMutation,
  useCreateNewWorkerAndPseudoSignInMutation,
  useEmailGcAboutDtWorkerArrivalMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useOrientationRegisterUser_OACUser_Mutation } from "src/common/types/generated/relay/useOrientationRegisterUser_OACUser_Mutation.graphql";
import { useOrientationRegisterUser_OACUser_InsertOACTitle_Mutation } from "src/common/types/generated/relay/useOrientationRegisterUser_OACUser_InsertOACTitle_Mutation.graphql";
import { auth } from "src/common/functions/firebase";
import { useGetOrientationDataQuery$data } from "src/common/types/generated/relay/useGetOrientationDataQuery.graphql";
import {
  useOrientationRegisterUser_Register_Mutation,
  worker_update_column,
} from "src/common/types/generated/relay/useOrientationRegisterUser_Register_Mutation.graphql";
import { graphql } from "babel-plugin-relay/macro";
import * as uuid from "uuid";
import { OrientationType } from "src/common/types/manual/OrientationType";
import { signInWithCustomToken } from "firebase/auth";

export const registerWorkerMutation = graphql`
  mutation useOrientationRegisterUser_Register_Mutation(
    $userOrientationObjs: [user_orientation_insert_input!]!
    $projectIds: [uuid!]!
    $workerId: uuid!
    $newSubId: uuid!
    $userSet: user_set_input!
    $workerUpsertObjs: [worker_insert_input!]!
    $workerUpdateColumns: [worker_update_column!]!
    $prjectWorkerSet: project_worker_set_input!
    $newUpsertProjWorkerObjs: [project_worker_insert_input!]!
    $workerPrevSubObjs: [worker_previous_subcontractor_insert_input!]!
    $deleteWorkerPrevSubWhere: worker_previous_subcontractor_bool_exp!
    $projectSubcontractorWorkerTitleObjects: [project_subcontractor_worker_title_insert_input!]!
  ) {
    insert_worker(
      objects: $workerUpsertObjs
      on_conflict: {
        constraint: worker_pkey
        update_columns: $workerUpdateColumns
      }
    ) {
      affected_rows
      returning {
        id
        uid
        gender
        age
        ethnicity
        veteran_type
      }
    }
    update_user_by_pk(pk_columns: { id: $workerId }, _set: $userSet) {
      id
      name
      email
      phone_number
      emergency_contact
    }
    u1: update_project_worker(
      where: {
        project_id: { _in: $projectIds }
        worker_id: { _eq: $workerId }
        subcontractor_id: { _neq: $newSubId }
      }
      _set: $prjectWorkerSet
    ) {
      affected_rows
    }
    u2: insert_project_worker(
      objects: $newUpsertProjWorkerObjs
      on_conflict: {
        constraint: project_worker_project_id_worker_id_subcontractor_id_key
        update_columns: [
          title_id
          worker_role
          archived_at
          is_last
          receive_text_message
          deleted_at
          hard_hat_number
        ]
      }
    ) {
      returning {
        id
        pk: id @__clientField(handle: "pk")
      }
    }
    insert_project_subcontractor_worker_title(
      objects: $projectSubcontractorWorkerTitleObjects
      on_conflict: {
        constraint: project_subcontractor_worker_title_project_id_subcontractor_id_
        update_columns: []
      }
    ) {
      affected_rows
    }
    insert_user_orientation(
      objects: $userOrientationObjs
      on_conflict: {
        constraint: user_orientation_user_id_project_id_key
        update_columns: [orientated_at, signature_id, selected]
      }
    ) {
      returning {
        id @__clientField(handle: "pk")
        project_id
        user_id
        orientated_at
        selected
      }
    }
    delete_worker_previous_subcontractor(where: $deleteWorkerPrevSubWhere) {
      affected_rows
    }
    insert_worker_previous_subcontractor(
      objects: $workerPrevSubObjs
      on_conflict: {
        constraint: worker_previous_subcontractor_subcontractor_id_worker_id_key
        update_columns: []
      }
    ) {
      affected_rows
    }
  }
`;
const useOrientationRegisterUser = (props: {
  onLoginSuccess?: (workerId: string) => Promise<void>;
  orientations?: useGetOrientationDataQuery$data["orientation_connection"]["edges"];
  type: OrientationType;
}) => {
  const workerContext = useContext(siteOrientationOrienteeContext);
  const user = workerContext?.orientee;
  const [registerUpdates] =
    useAsyncMutation<useOrientationRegisterUser_Register_Mutation>(
      registerWorkerMutation,
    );
  const [emailForDT] = useEmailGcAboutDtWorkerArrivalMutation();
  const [loading, setLoading] = useState(false);
  const [updateOACInfo, isUpdating] =
    useAsyncMutation<useOrientationRegisterUser_OACUser_Mutation>(
      graphql`
        mutation useOrientationRegisterUser_OACUser_Mutation(
          $userId: uuid!
          $userSet: user_set_input!
          $projectId: uuid!
          $_set: general_contractor_employee_set_input!
        ) {
          update_general_contractor_employee_by_pk(
            pk_columns: { uid: $userId }
            _set: $_set
          ) {
            uid
          }
          insert_project_employee_one(
            object: { project_id: $projectId, employee_id: $userId }
            on_conflict: {
              constraint: project_employee_project_id_employee_id_key
              update_columns: []
            }
          ) {
            id
          }
          update_user_by_pk(pk_columns: { id: $userId }, _set: $userSet) {
            id
          }
        }
      `,
    );
  const [insertTitle, isInserting] =
    useAsyncMutation<useOrientationRegisterUser_OACUser_InsertOACTitle_Mutation>(
      graphql`
        mutation useOrientationRegisterUser_OACUser_InsertOACTitle_Mutation(
          $name: String!
          $id: uuid!
        ) {
          insert_oac_title_one(object: { name: $name, id: $id }) {
            id
          }
        }
      `,
    );

  const [createNewWorkerAndPseudoSignIn] =
    useCreateNewWorkerAndPseudoSignInMutation();
  const [createNewGCUserAndPseudoSignIn] =
    useCreateNewGcUserAndPseudoSignInMutation();
  const onExistingOACUserRegistration = async (phoneNumber?: string) => {
    const projectId = workerContext?.projectId;
    if (!user || !user.id || !(user.userType === "oacUser")) {
      console.log("no oac user has been set");
      throw new Error("no oac user has been set");
    }
    if (!projectId) {
      throw new Error("Project not found");
    }
    if (!user.company) {
      message.error("No Company found");
      throw new Error("No Company found");
    }
    const titleId = user?.title?.id !== "other" ? user.title?.id : uuid.v4();
    if (user?.title?.id === "other" && user.title.name && titleId) {
      await insertTitle({
        variables: { id: titleId, name: user.title.name },
      });
    }
    await updateOACInfo({
      variables: {
        userId: user.id,
        projectId: workerContext?.projectId,
        _set: user.oacCompanyId
          ? {
              oac_company_id: user.oacCompanyId,
              ...(user.oacCompanyId
                ? { oac_title_id: titleId }
                : { title_id: user.employeeTitleId }),
            }
          : {},
        userSet: {
          phone_number: phoneNumber,
          ...(workerContext.orientee?.birthDate?.new
            ? {
                birth_date:
                  workerContext.orientee.birthDate.new.format("YYYY-MM-DD"),
              }
            : {}),
        },
      },
    }).catch((e) => console.log(e));
  };
  const onExistingWorkerRegistration = async (
    phoneNumber?: string,
    receiveText?: boolean,
  ) => {
    if (props.orientations === undefined) {
      message.error("Insufficient data to register existing worker");
      throw new Error("Insufficient data to register existing worker");
    }
    const projectId = workerContext?.projectId;
    if (!user || !user.id || !(user.userType === "worker")) {
      console.log("no worker has been set");
      throw new Error("no worker has been set");
    }
    if (!projectId) {
      throw new Error("Project not found");
    }
    if (!user.company) {
      message.error("No Company found");
      throw new Error("No Company found");
    }
    const allProjectsToRegister = Object.keys(
      user.projectsToRegister ?? { [projectId]: true },
    );
    const dobEntry = user.birthDate?.new || user.birthDate?.old;
    const dob = dobEntry ? dayjs(dobEntry) : undefined;

    const requiredOrienations = props.orientations;
    const passed =
      requiredOrienations.length > 0 &&
      requiredOrienations.every((o) => o.node.orientation_results.length > 0);
    //this result has completed status
    const result =
      passed && requiredOrienations[0].node.orientation_results.length > 0
        ? requiredOrienations[0].node.orientation_results[0]
        : null;
    const signatureUrl = result?.signature_url;
    const signatureImage = signatureUrl
      ? {
          data: {
            url: signatureUrl,
            lg_url: signatureUrl,
            created_by_user_id: user.id,
          },
        }
      : null;
    const orientatedAt = result
      ? result?.completed_at
        ? dayjs(result?.completed_at).format()
        : dayjs(result?.created_at).format()
      : null;
    const age = dob ? ageByBirthDate(dob) : undefined;

    const curTimeForUpdate = dayjs().toISOString();
    const workerUpdateColumns: worker_update_column[] = [
      "subcontractor_id",
      "current_worker_role",
    ];
    if (user.title) workerUpdateColumns.push("worker_title_id");
    if (user.unionChapterId) workerUpdateColumns.push("labor_union_chapter_id");
    if (age) workerUpdateColumns.push("age", "age_entered_at");

    await registerUpdates({
      variables: {
        newSubId: user.company.id,
        projectIds: allProjectsToRegister,
        workerId: user.id,
        prjectWorkerSet: {
          ...(user.hardHatNumber
            ? { hard_hat_number: user.hardHatNumber }
            : {}),
          is_last: false,
        },
        userSet: {
          ...(phoneNumber ? { phone_number: phoneNumber } : {}),
          ...(dob ? { birth_date: dayjs(dob).format("YYYY-MM-DD") } : {}),
          lang: "en",
        },
        workerUpsertObjs: [
          {
            uid: user.id,
            subcontractor_id: user.company.id,
            current_worker_role: user.role,
            worker_title_id: user.title?.id,
            ...(user.unionChapterId
              ? { labor_union_chapter_id: user.unionChapterId }
              : {}),
            ...(age ? { age, age_entered_at: curTimeForUpdate } : {}),
          },
        ],
        workerUpdateColumns,
        newUpsertProjWorkerObjs: allProjectsToRegister.map((projectId) => ({
          project_id: projectId,
          worker_id: user.id,
          subcontractor_id: user.company?.id,
          title_id: user.title?.id,
          updated_at: curTimeForUpdate,
          receive_text_message: receiveText,
          worker_role: user.role,
          deleted_at: null,
          is_last: true,
          archived_at: null,
          hard_hat_number: user.hardHatNumber || null,
        })),
        projectSubcontractorWorkerTitleObjects:
          user.title?.id && user.company?.id
            ? allProjectsToRegister.map((projectId) => ({
                project_id: projectId,
                subcontractor_id: user.company?.id,
                worker_title_id: user.title?.id,
              }))
            : [],
        userOrientationObjs: allProjectsToRegister.map((projectId) => ({
          project_id: projectId,
          user_id: user.id,
          orientated_at: orientatedAt,
          signature_image: signatureImage,
          selected: props.type !== "slides", //it isn't called for UO only Qr
        })),

        // delete will only run when worker worked with subA before currently in subB and now wants to go to subA again
        // so it will delete worker-subA entry and after that we can insert a new worker-subB as previous sub of worker
        ...(!user.isSubAdmin && user.prevCompanyId !== user.company.id
          ? {
              deleteWorkerPrevSubWhere: {
                subcontractor_id: { _eq: user.company.id },
                worker_id: { _eq: user.id },
              },
              workerPrevSubObjs: user.prevCompanyId
                ? [
                    {
                      worker_id: user.id,
                      subcontractor_id: user.prevCompanyId,
                    },
                  ]
                : [],
            }
          : {
              deleteWorkerPrevSubWhere: { id: { _is_null: true } },
              workerPrevSubObjs: [],
            }),
      },
    });

    emailForDT({
      variables: {
        input: {
          projectId,
          userIds: [user.id],
          sendForNegative: true,
          sendForPositive: true,
        },
      },
    })
      .then(console.log)
      .catch(console.error);
  };

  const registerUser: (
    phoneNumber: string,
    receiveText: boolean,
  ) => Promise<boolean> = async (phoneNumber, receiveText) => {
    try {
      const userName = user?.name?.new || user?.name?.old;
      const dobEntry = user?.birthDate?.new || user?.birthDate?.old;
      setLoading(true);
      if (user?.id) {
        if (user.userType === "oacUser")
          await onExistingOACUserRegistration(phoneNumber);
        else await onExistingWorkerRegistration(phoneNumber, receiveText);
      } else if (
        user &&
        !user.id &&
        userName &&
        user.company?.id &&
        user.title &&
        (user.email || user.birthDate)
      ) {
        const loggedInUser = await auth.currentUser?.getIdTokenResult();
        if (user.userType === "worker" && user.role) {
          const { data } = await createNewWorkerAndPseudoSignIn({
            variables: {
              input: {
                name: userName,
                email: user.email,
                returnToken:
                  loggedInUser?.claims?.role === "employee" ? false : true,
                titleId: user.title.id,
                workerRole: user.role,
                lang: "en",
                subcontractorId: user.company.id,
                toReceiveTextMessage: receiveText,
                phone: phoneNumber,
                workerDob: dobEntry ? dobEntry.format("YYYY-MM-DD") : undefined,
                projectId: workerContext.projectId,
                laborUnionChapterId: user.unionChapterId,
              },
            },
          });
          if (data?.createNewWorkerAndPseudoSignIn) {
            const { token, workerId } = data.createNewWorkerAndPseudoSignIn;
            if (token) {
              roleVar("none");
              await auth.signOut();
              await signInWithCustomToken(auth, token);
            }
            workerContext.updateSelectedUserId(workerId);
            props.onLoginSuccess && (await props.onLoginSuccess(workerId));
          }
        } else if (user.userType === "oacUser" && user.email) {
          const { data } = await createNewGCUserAndPseudoSignIn({
            variables: {
              input: {
                email: user.email,
                phone_number: phoneNumber,
                name: userName,
                ...(workerContext.orientee?.birthDate?.new
                  ? {
                      birthDate:
                        workerContext.orientee.birthDate.new.format(
                          "YYYY-MM-DD",
                        ),
                    }
                  : {}),
                projectId: workerContext.projectId,
                returnToken:
                  loggedInUser?.claims?.role === "employee" ? false : true,
                oac_company_id: user.oacCompanyId,
                selected_general_contractor_id: user.generalContractorId,
                employee_title_id: user.employeeTitleId,
                ...(user.oacCompanyId
                  ? user.title.id === "other"
                    ? { oac_new_title_name: user.title.name }
                    : { oac_title_id: user.title.id }
                  : {}),
              },
            },
          });
          if (data?.createNewGCUserAndPseudoSignIn) {
            const { token, userId } = data?.createNewGCUserAndPseudoSignIn;
            if (token) {
              roleVar("none");
              await auth.signOut();
              await signInWithCustomToken(auth, token);
            }
            workerContext.updateSelectedUserId(userId);
            props.onLoginSuccess && (await props.onLoginSuccess(userId));
          }
        } else {
          throw new Error(
            "Some information is missing, please select the Back button and complete each screen",
          );
        }
      } else {
        throw new Error(
          "Some information is missing, please select the Back button and complete each screen",
        );
      }
      setLoading(false);
      return true;
    } catch (e) {
      console.log(e);
      setLoading(false);
      notification.error({
        message: "ERROR: couldn't register user",
        description: e instanceof Error ? e.message : JSON.stringify(e),
      });
      return false;
    }
  };
  return [registerUser, loading] as const;
};
export default useOrientationRegisterUser;
