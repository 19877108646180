import { createContext, useContext } from "react";

interface Subcontractor {
  id: string;
  name: string;
  user: {
    id: string;
    name: string;
    email?: string | null;
  };
  title?: {
    en?: string;
  };
}

const SubcontractorContext = createContext<Subcontractor | null>(null);

export const SubcontractorProvider = SubcontractorContext.Provider;

export const useSubcontractorId = () => {
  const subId = useContext(SubcontractorContext)?.id;
  if (!subId) {
    throw new Error(
      "SubcontractorId is null, Please use Subcontractor provider in one of parent component.",
    );
  }
  return subId;
};

export const useSubcontractor = () => {
  const subContractor = useContext(SubcontractorContext);
  if (!subContractor) {
    throw new Error(
      "Subcontractor is null, Please use Subcontractor provider in one of parent component.",
    );
  }
  return subContractor;
};
