import { IconArrowBackUp } from "@tabler/icons";
import React, { FC } from "react";
import { useSearchParams } from "react-router-dom";
import Button from "src/common/components/general/Button";
import LoadingContent from "src/common/components/general/LoadingContent";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { useGetReportByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";
import ReportDetail from "../../../../../components/ReportDetail";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";

interface SCReportsPTPSingleProps {
  reportId: string;
  subcontractorId: string;
  projectId?: string;
}

const SCReportsPTPSingle: FC<SCReportsPTPSingleProps> = ({
  reportId,
  subcontractorId,
  projectId,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data } = useGetReportByPkQuery({
    variables: { reportId },
  });
  if (!data?.report_by_pk) {
    return <LoadingContent />;
  }
  const end = searchParams.get("end");
  const start = searchParams.get("start");
  const handleBackButtonClick = () => {
    setSearchParams(end && start ? { end, start } : {});
  };

  return (
    <BasicWrapper scrollable>
      <div className="flex flex-row justify-start">
        <Button
          onClick={handleBackButtonClick}
          icon={IconArrowBackUp}
          label={`Back`}
        />
      </div>
      <ReportDetail
        routeId={{ type: "sub", id: subcontractorId }}
        report={data.report_by_pk as any}
      />
    </BasicWrapper>
  );
};

export default withCustomSuspense(SCReportsPTPSingle);
