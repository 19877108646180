const getLogoToShowFromProjectData = (projectData: {
  logo_url?: string | null;
  gc_office?: {
    logo_url?: string | null;
  } | null;
  gc_business_unit?: {
    logo_url?: string | null;
  } | null;
  gc_division?: {
    logo_url?: string | null;
  } | null;
  general_contractor: {
    logo_url?: string | null;
  };
}) => {
  return (
    projectData.logo_url ||
    projectData.gc_office?.logo_url ||
    projectData.gc_business_unit?.logo_url ||
    projectData.gc_division?.logo_url ||
    projectData.general_contractor.logo_url
  );
};
export default getLogoToShowFromProjectData;
