/**
 * @generated SignedSource<<6f6ba7a09ada5d959a3409dde2169cdf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SFVerifyProfilePhotoTable_total$data = {
  readonly allCertifcatesToVerifyConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  };
  readonly " $fragmentType": "SFVerifyProfilePhotoTable_total";
};
export type SFVerifyProfilePhotoTable_total$key = {
  readonly " $data"?: SFVerifyProfilePhotoTable_total$data;
  readonly " $fragmentSpreads": FragmentRefs<"SFVerifyProfilePhotoTable_total">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./SFVerifyProfilePhotoTableTotalRefetchableQuery.graphql')
    }
  },
  "name": "SFVerifyProfilePhotoTable_total",
  "selections": [
    {
      "alias": "allCertifcatesToVerifyConnection",
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "certificates_to_verifyConnection",
      "kind": "LinkedField",
      "name": "certificates_to_verify_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "certificates_to_verifyEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "certificates_to_verify",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};

(node as any).hash = "abf92a92d887223772331aafb1963f5c";

export default node;
