import { Form, Modal, Radio, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";

interface AddCovidQuestionValues {
  question: string;
  correct_answer: string;
}

interface AddCovidQuestionModalProps {
  visible: boolean;
  onCreate: (values: AddCovidQuestionValues) => void;
  onCancel: () => void;
}

const { Text } = Typography;

const AddCovidQuestionModal: React.FC<AddCovidQuestionModalProps> = ({
  visible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    (<Modal
      open={visible}
      title="Add a COVID-19 Self-Certify Question"
      okText="Add"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values as AddCovidQuestionValues);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <p>
        <b>
          Make sure the question can be answered with a{" "}
          <Text type="danger">
            <b>YES</b>
          </Text>{" "}
          or <b>NO</b>.
        </b>
      </p>
      <p>
        Answering{" "}
        <Text type="danger">
          <b>YES</b>
        </Text>{" "}
        should indicate that the individual may have COVID-19, and will be
        restricted from the jobsite for the duration specified in the project.
      </p>
      <p>
        Answering <b>NO</b> should indicate that the individual does not show
        any signs of sickness, and is certified to work on the jobsite.
      </p>
      <p>
        <b>
          Questions should be written in a way that they are addressed to an
          individual. These questions will be presented to workers and any
          guests on the jobsite.
        </b>
      </p>
      <br />
      <p>
        <b>
          Ex. Have you had "close contact" with an individual diagnosed with
          COVID-19 or exhibiting flu-like symptoms in the past 48 hours?
        </b>
      </p>
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public", correct_answer: "no" }}
      >
        <Form.Item
          style={{ marginBottom: 12 }}
          name="question"
          label="New question"
          rules={[{ required: true, message: "Enter your question" }]}
        >
          <TextArea style={{ minHeight: 200 }} />
        </Form.Item>
        <Form.Item noStyle name="correct_answer">
          <Radio.Group style={{ textAlign: "right", width: "100%" }}>
            <label style={{ paddingRight: 8 }}>Correct answer:</label>
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>)
  );
};

export default AddCovidQuestionModal;
