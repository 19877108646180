import React, { PropsWithChildren, FunctionComponent, useMemo } from "react";
import { Layout, Menu, Divider } from "antd";
import styled from "styled-components";
import Link from "src/common/components/general/Link";
import StyledContent from "src/common/components/layouts/StyledContent";
import LayoutBackButton from "src/common/components/LayoutBackButton";
import { Outlet, useLocation } from "react-router-dom";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const SideBar = styled(Sider).attrs(() => ({
  theme: "light",
  width: 250,
}))``;

const CsrData: React.FC<PropsWithChildren> = ({ children }) => {
  const sideMenuItems = useMemo(
    () => [
      {
        path: `/csr/tasks/projects`,
        title: "Projects",
        match: /\/csr\/tasks\/projects/,
      },
    ],
    [],
  );
  const location = useLocation();
  return (
    <Layout>
      <SideBar style={{ overflowY: "scroll", overflowX: "hidden" }}>
        <Content style={{ padding: 16 }}>
          <p style={{ fontWeight: "bold" }}>Customer Portal</p>
        </Content>
        <Divider />
        <Menu
          mode="inline"
          selectedKeys={sideMenuItems
            .filter((i) => location.pathname.match(i.match))
            .map((i) => i.title)}
          style={{ height: "100%", borderRight: 0 }}
        >
          {sideMenuItems.map((i) => (
            <Menu.Item key={i.title}>
              <Link href={i.path}>{i.title}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </SideBar>
      <StyledContent
        padding
        backgroundColor="transparent"
        style={{
          overflowY: "scroll",
        }}
      >
        <LayoutBackButton match={/.\/cp\/tasks\/.*\/./} />
        {children}
      </StyledContent>
    </Layout>
  );
};

export default CsrData;
