import React from "react";
import { followCursor } from "tippy.js";

import Tippy from "@tippyjs/react/headless"; // different import path!
import clsx from "clsx";
import { useBoolean } from "usehooks-ts";

interface PopoverProps {
  children: React.ReactElement;
  content: React.ReactElement;
  showOnClick?: boolean;
  delay?: boolean;
  className?: string;
  notInteractive?: boolean;
}

const Popover: React.FunctionComponent<PopoverProps> = (props) => {
  const { value: visible, setTrue: show, setFalse: hide } = useBoolean(false);

  return (
    <Tippy
      arrow={false}
      visible={props.notInteractive ? visible : undefined}
      duration={[0, 0]}
      trigger={props.showOnClick ? "click" : "mouseenter"}
      delay={props.delay ? [900, 0] : [0, 0]}
      placement={"bottom-start"}
      hideOnClick={props.notInteractive}
      interactive={!props.notInteractive}
      plugins={[followCursor]}
      followCursor={!!props.notInteractive}
      appendTo={document.body}
      render={(attr, content, instance) => {
        // console.group("instance render");
        // console.log(instance?.state.isShown || instance?.state.isVisible);
        // console.groupEnd();
        return (
          <div
            {...attr}
            className="overflow-y-auto animate-reveal shadow bg-white rounded-0.25 p-0.5"
          >
            {props.content}
          </div>
        );
      }}
    >
      <div
        {...(props.showOnClick
          ? {
              onClick: (e) => {
                e.stopPropagation();
                visible ? hide() : show();
              },
            }
          : props.notInteractive && {
              onMouseEnter: show,
              onMouseLeave: hide,
            })}
        className={props.className}
      >
        {props.children}
      </div>
    </Tippy>
  );
};
export default Popover;
