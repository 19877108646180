import {
  compareTimeEvents,
  parseTime,
} from "src/domain-features/site-delivery/entry-routes/calendar/sitedelivery/utilities/timeEvents";

type BaseBlockOutNode = {
  weekday: number;
  start_time: string;
  end_time: string;
};

type BlockOutEvent<T extends BaseBlockOutNode> = {
  type: "block_out_start" | "block_out_end";
  time: number;
  block: T;
};

type SubBlockNode = {
  weekday: number;
  start_time: string;
  end_time: string;
};

type SubBlockEvent<T extends SubBlockNode> = {
  type: "sub_block_start" | "sub_block_end";
  time: number;
  block: T;
};

export type TimeInterval = {
  start: number;
  end: number;
};

type ReadOnlyEdges<T> = readonly { readonly node: T }[];

export const groupBlockOutIntervalsByDay = <T extends BaseBlockOutNode>(
  edges: ReadOnlyEdges<T>,
) => {
  const blocks_per_day: Array<BlockOutEvent<T>[]> = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];

  for (const edge of edges) {
    blocks_per_day[edge.node.weekday].push({
      type: "block_out_start",
      time: parseTime(edge.node.start_time),
      block: edge.node,
    });
    blocks_per_day[edge.node.weekday].push({
      type: "block_out_end",
      time: parseTime(edge.node.end_time),
      block: edge.node,
    });
  }
  for (const day of blocks_per_day) day.sort(compareTimeEvents);
  //    console.log('block_out_per_day', block_per_day);
  return blocks_per_day;
};

export const groupSubBlockIntervalsByDay = <T extends SubBlockNode>(
  edges: ReadOnlyEdges<T>,
) => {
  const blocks_per_day: Array<SubBlockEvent<T>[]> = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];

  for (const edge of edges) {
    const startTime = parseTime(edge.node.start_time);
    const endTime = parseTime(edge.node.end_time);
    for (let day = 0; day < 7; day++) {
      if ((edge.node.weekday & (1 << day)) === 0) continue;
      blocks_per_day[day].push({
        type: "sub_block_start",
        time: startTime,
        block: edge.node,
      });
      blocks_per_day[day].push({
        type: "sub_block_end",
        time: endTime,
        block: edge.node,
      });
    }
  }
  for (const day of blocks_per_day) day.sort(compareTimeEvents);
  return blocks_per_day;
};
