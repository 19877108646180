import React, { FC } from "react";
import dayjs from "dayjs";
import { Typography, Space, Row, Col } from "antd";

const { Title, Text } = Typography;

export interface DigTicketProps {
  tickets: any[];
}

export const DigTicket: FC<DigTicketProps> = ({ tickets }) => {
  return (
    <Row>
      {tickets.map((ticket: any) => (
        <Col key={ticket?.ticket_number} span={8}>
          <div
            style={{
              backgroundColor: "#e8e8e8",
              borderRadius: "8px",
              padding: "16px",
              minHeight: "200px",
              marginRight: "20px",
            }}
          >
            <Title level={4}>Utility Locate Ticket</Title>
            <Space size="small" direction="vertical">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text type="secondary">Ticket#</Text>
                <Text strong>{ticket?.ticket_number}</Text>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text type="secondary">Utility Type</Text>
                <Text strong>{ticket?.public_or_private}</Text>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text type="secondary">Date Called In</Text>
                <Text strong>
                  {dayjs(ticket?.date_locate_performed).format("lll")}
                </Text>
              </div>
            </Space>
          </div>
        </Col>
      ))}
    </Row>
  );
};
