import { graphql } from "babel-plugin-relay/macro";

const InsertOrientationViewedSlide = graphql`
  mutation InsertOrientationViewedSlideMutation(
    $objects: [orientation_viewed_slide_insert_input!]!
  ) {
    insert_orientation_viewed_slide(
      objects: $objects
      on_conflict: {
        constraint: orientation_viewed_slide_slide_id_user_id_key
        update_columns: [created_at]
      }
    ) {
      returning {
        id
        slide_id
        user_id
        created_at
        orientation_result_id
      }
    }
  }
`;

export default InsertOrientationViewedSlide;
