import { Descriptions, notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import BusinessCard from "src/common/components/BusinessCard";
import Button from "src/common/components/general/Button";
import ChangeSubcontractorEmployeeTitleModal from "src/common/components/dialogs/ChangeSubcontractorEmployeeTitleModal";
import LoadingContent from "src/common/components/general/LoadingContent";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { auth } from "src/common/functions/firebase";
import {
  useForgotPasswordMutation,
  useGetSubcontractorUserByPkQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import { roleVar } from "../../../../../common/api/apollo/cache";
import { SCProfileGetSubcontractorUserQuery } from "src/common/types/generated/relay/SCProfileGetSubcontractorUserQuery.graphql";

const profileUserQuery = graphql`
  query SCProfileGetSubcontractorUserQuery($uid: uuid!) {
    user_connection(where: { id: { _eq: $uid } }, first: 10000)
      @connection(
        key: "SCProfileGetSubcontractorUserQuery_user_connection"
        filters: []
      ) {
      edges {
        node {
          id
          name
          email
          username
          role
          phone_number
          subcontractor_employee {
            subcontractor_id
            subcontractor {
              id
              name
            }
            id
            title_id
            employee_title {
              id
              name {
                ...TextTranslationFrag @relay(mask: false)
              }
            }
          }
        }
      }
    }
    subcontractor_employee_title_connection(order_by: { name: { en: asc } }) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          name {
            ...TextTranslationFrag @relay(mask: false)
          }
        }
      }
    }
  }
`;

const { Item } = Descriptions;

const SCProfile = () => {
  const navigate = useNavigate();
  const [
    showChangeSubcontractorEmployeeTitleModal,
    setShowChangeSubcontractorEmployeeTitleModal,
  ] = useState(false);

  const modalOpen = () => {
    setShowChangeSubcontractorEmployeeTitleModal(true);
  };

  const modalClose = () => {
    setShowChangeSubcontractorEmployeeTitleModal(false);
  };
  // const { data, loading } = useGetSubcontractorUserByPkQuery({
  //   variables: {
  //     uid: auth.currentUser?.uid,
  //   },
  // });

  const data = useLazyLoadQuery<SCProfileGetSubcontractorUserQuery>(
    profileUserQuery,
    {
      uid: auth.currentUser?.uid ?? "",
    }
  );

  const userData = data.user_connection.edges[0];
  const userId = auth.currentUser?.uid ?? "";
  const title_data = data.subcontractor_employee_title_connection.edges.map(
    (item) => ({
      id: item.node.pk,
      name: item.node.name.en,
    })
  );
  console.log(title_data, "  title data  ");
  //console.log(auth.currentUser?.uid);
  const [forgotPassword] = useForgotPasswordMutation();

  // if (loading || !data?.user_by_pk) {
  const titleId = userData.node.subcontractor_employee?.title_id ?? "";

  if (!userData) {
    return <LoadingContent />;
  }

  // if (data.user_by_pk.role !== "subcontractor_employee")
  if (userData.node.role !== "subcontractor_employee")
    return (
      <div className="flex items-center justify-center">
        No Valid Login Found
      </div>
    );
  return (
    <BasicWrapper>
      <div className="flex flex-col items-center gap-1">
        {data && (
          <BusinessCard
            {...{
              // fullName: data.user_by_pk.name,
              // userName: data.user_by_pk.username ?? undefined,
              // company:
              //   data.user_by_pk.subcontractor_employee?.subcontractor.name,
              // email: data.user_by_pk.email ?? undefined,
              // phoneNumber: data.user_by_pk.phone_number ?? undefined,
              fullName: userData.node.name,
              userName: userData.node.username ?? undefined,
              company: userData.node.subcontractor_employee?.subcontractor.name,
              email: userData.node.email ?? undefined,
              phoneNumber: userData.node.phone_number ?? undefined,
              position:
                userData.node.subcontractor_employee?.employee_title?.name
                  .original,
            }}
          />
        )}
        <div className="flex flex-row gap-1">
          <Button
            onClick={async () => {
              modalOpen();
            }}
            label={`Change Title`}
          ></Button>
          <Button
            onClick={async () => {
              roleVar("none");
              await auth.signOut();
              navigate("/");
            }}
            label={`Log out`}
          ></Button>
          <Button
            onClick={async () => {
              // if (data.user_by_pk?.email) {
              if (userData.node.email) {
                forgotPassword({
                  variables: {
                    input: {
                      loginIdentifier: userData.node.email,
                    },
                  },
                });
              }
              notification.open({
                message: "We emailed you a password reset link!",
              });
            }}
            label={`Email update password link`}
          ></Button>
          <ChangeSubcontractorEmployeeTitleModal
            titleData={title_data}
            modalClose={modalClose}
            modalVisible={showChangeSubcontractorEmployeeTitleModal}
            titleId={titleId}
            userId={userId}
          ></ChangeSubcontractorEmployeeTitleModal>
        </div>
      </div>
    </BasicWrapper>
  );
};

export default SCProfile;
