import { graphql } from "babel-plugin-relay/macro";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import ReportsTabsGroup, {
  ReportsTabType,
} from "src/common/components/ReportsTabsGroup";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
// import AGCOrientations from "./subview/AGCOrientations";
import { auth } from "src/common/functions/firebase";
import { useLazyLoadQuery } from "react-relay/hooks";
import { AGCEmployeeQuery } from "src/common/types/generated/relay/AGCEmployeeQuery.graphql";
import AGCOrientations from "./subview/AGCOrientations";
import Button from "src/common/components/general/Button";
import { IconLogout } from "@tabler/icons";
import { roleVar } from "src/common/api/apollo/cache";
import { agcCompanyId } from "src/common/hooks/useCheckAGCUser";
import AGCGeneralContractors from "./subview/AGCGeneralContractors";

const query = graphql`
  query AGCEmployeeQuery($agcCompanyId: uuid!) {
    agc_employee: general_contractor_employee_connection(
      where: { general_contractor_id: { _eq: $agcCompanyId } }
    ) {
      edges {
        node {
          uid
          id
        }
      }
    }
    orientation_qr_code_poster: general_contractor_connection(
      where: { id: { _eq: $agcCompanyId } }
    ) {
      edges {
        node {
          orientation_qr_code_poster_background_url
        }
      }
    }
    general_contractor_connection(
      where: { agc_universal_orientation: { _eq: true } }
      order_by: { name: asc }
      first: 10000
    )
      @connection(
        key: "AGCEmployeeQuery_general_contractor_connection"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name
          projects(where: { agc_universal_orientation: { _eq: true } }) {
            id
            completed
          }
        }
      }
    }
    orientation_connection(
      where: {
        deleted_at: { _is_null: true }
        general_contractor_id: { _is_null: true }
        project_id: { _is_null: true }
        type: { _eq: "universal" }
      }
      order_by: [{ order: asc }, { name: asc }]
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name
          type
          created_at
          project_id
          user {
            name
            employee {
              general_contractor {
                name
              }
            }
          }
          project_orientations(
            where: { project_id: { _is_null: true } }
            order_by: { created_at: asc }
          ) {
            id
            pk: id @__clientField(handle: "pk")
            orientation_id
            project_id
            hide_but_give_credit
            play_during_in_person
            play_during_registration
            required_by_all_workers
          }
        }
      }
    }
  }
`;

const AGCEmployee: FC<{ subview: string }> = ({ subview }) => {
  const navigate = useNavigate();
  const handleReportsTabClick = (tabId: string) => {
    navigate("../" + tabId);
  };
  const data = useLazyLoadQuery<AGCEmployeeQuery>(query, { agcCompanyId });

  const posterUrl =
    data.orientation_qr_code_poster.edges[0].node
      .orientation_qr_code_poster_background_url ?? "";
  const uid = auth.currentUser?.uid;
  const tabs: ReportsTabType[] = [
    { id: "orientation", label: "Orientation" },
    { id: "general-contractors", label: "GC/CMs" },
    // { id: "settings", label: "Settings" },
  ];
  return uid ? (
    data.agc_employee.edges.find((s) => s.node.uid === uid) ? (
      <>
        <BasicWrapper scrollable>
          <div className="items-end flex">
            <Button
              label="Log out"
              delete
              icon={IconLogout}
              onClick={async () => {
                roleVar("none");
                await auth.signOut();
                navigate("/");
              }}
            ></Button>
          </div>
          <ReportsTabsGroup
            onTabClick={handleReportsTabClick}
            tabs={tabs}
            tabsType="button"
            selectedTabId={subview}
          />
          {subview === "general-contractors" && (
            <AGCGeneralContractors
              data={data.general_contractor_connection.edges}
            />
          )}
          {subview === "orientation" && (
            <AGCOrientations
              data={data.orientation_connection.edges}
              posterUrl={posterUrl}
              agcCompanyId={agcCompanyId}
            />
          )}
        </BasicWrapper>
      </>
    ) : (
      <></>
    )
  ) : (
    <div className="flex justify-center items-center">Authorization failed</div>
  );
};
export default AGCEmployee;
