import React, { useContext, useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import {
  GenderType,
  SiteOrientationStepProps,
} from "../../../../../../../utils/siteOrientationTypes";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import { Select } from "antd";
import { genderOptions } from "src/domain-features/siteorientation/utils/demographicQuestionOptions";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsGenderProps
  extends SiteOrientationStepProps {
  options: Array<{
    name: string;
    value: string;
  }>;
}

const SiteOrientationInPersonWorkerStepsGender: React.FC<
  SiteOrientationInPersonWorkerStepsGenderProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();
  const user = workerContext?.orientee;
  let defaultGender: GenderType = null;
  if (user && user.userType === "worker" && user.gender) {
    defaultGender = user.gender.new ?? user.gender.old;
  }
  const [gender, setGender] = useState<GenderType>(defaultGender);
  const langStrings = useCurrentLangStrings();
  const onEnter = async (pickedVal?: string) => {
    workerContext.updateOrientee((u) =>
      u?.userType === "worker"
        ? {
            ...u,
            gender: { old: u.gender?.old, new: pickedVal ?? gender },
          }
        : null,
    );
    props.onNext();
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={() => {
        !!gender && onEnter(gender);
      }}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!gender}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.whatIsYourGender}
      >
        <Select
          value={gender ?? undefined}
          onChange={(v) => {
            setGender(v);
            onEnter(v);
          }}
          placeholder={langStrings.strings.selectFromTheList}
        >
          {genderOptions.map((g) => (
            <Select.Option value={g} key={g}>
              {langStrings.options.genderOptions[g + "_Gender"]}
            </Select.Option>
          ))}
        </Select>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerStepsGender;
