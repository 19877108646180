import React from "react";
import ProjectOverviewCard from "../../../../../../sitesafety/entryRoutes/qrProjectReports/components/ProjectOverviewCard";
import Icon, {
  IconType,
} from "../../../../../../../common/components/general/Icon";

type SiteOrientationInPersonManagerHintType = {
  icon: IconType;
  title: React.ReactNode;
  body?: React.ReactNode;
};

export interface SiteOrientationInPersonManagerLayoutProps {
  children: React.ReactNode;
  projectInfo: {
    name: string;
    address: string;
    gcNames: string;
  };
  footer?: React.ReactNode;
  hints?: SiteOrientationInPersonManagerHintType[];
}

const SiteOrientationInPersonManagerLayout: React.FC<
  SiteOrientationInPersonManagerLayoutProps
> = (props) => {
  const orientationTitle = `In-Person Orientation`;

  return (
    <div className={`flex w-full h-full flex-row justify-center p-2`}>
      <div className={`flex w-full max-w-64 flex-row gap-4`}>
        <div className={`relative z-0 flex-1 flex flex-col `}>
          <div className={`flex flex-col gap-1 w-full`}>
            <div className={`flex flex-col gap-1`}>
              <ProjectOverviewCard
                {...{
                  projectAddress: props.projectInfo.address,
                  gcTitle: props.projectInfo.gcNames,
                  projectTitle: props.projectInfo.name,
                }}
              />
              <p className={`font-accent`}>{orientationTitle}</p>
            </div>
            <div className={`flex flex-col gap-2 pt-4 pb-2 relative`}>
              <div
                className={`absolute z-0 top-0 left-1 bg-transparent rounded-bl-1.5 border-b-px border-l-px border-suplementary-3 h-full w-[calc(100%+4rem)]`}
              ></div>
              {props.hints?.map((hint, index) => (
                <div
                  key={index}
                  className={`flex z-10 relative flex-row items-center gap-1`}
                >
                  <div className={`p-1 -m-1 bg-suplementary-1`}>
                    <Icon size={`xl`} icon={hint.icon} />
                  </div>
                  <div className={`flex flex-col gap-0`}>
                    <p className={`font-accent text-2`}>{hint.title}</p>
                    {hint.body && (
                      <p className={`text-static-secondary`}>{hint.body}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className={`relative flex gap-1 flex-col z-10 flex-1 shadow p-2 rounded-1.5 bg-white`}
        >
          <div className={`overflow-y-auto flex-1`}>{props.children}</div>
          {props.footer && <div className={``}>{props.footer}</div>}
        </div>
      </div>
    </div>
  );
};

export default SiteOrientationInPersonManagerLayout;
