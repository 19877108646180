import React, { useContext, useEffect, useState } from "react";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import useSiteOrientationInPersonWorkerUpdateHH from "../../../../../../../utils/useSiteOrientationInPersonWorkerUpdateHH";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { Input } from "antd";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import sendHotjarEvent from "src/utility-features/event-tracking/hotjar/sendHotjarEvent";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsHardHatNumberProps
  extends SiteOrientationStepProps {
  projectId: string;
  userId: string;
}

const SiteOrientationInPersonWorkerStepsHardHatNumber: React.FC<
  SiteOrientationInPersonWorkerStepsHardHatNumberProps
> = (props) => {
  const context = useSiteOrientationOrientee();
  const [hardHatNumber, setHardHatNumber] = useState<string | null>(
    context.orientee?.hardHatNumber || null,
  );
  const { updateHH, loading: hardHatNumberUpdating } =
    useSiteOrientationInPersonWorkerUpdateHH();
  const langStrings = useCurrentLangStrings();
  const onEnter = async () => {
    const userId = context.orientee?.id;
    const projectId = context.projectId;
    if (userId && projectId && hardHatNumber) {
      const response = await updateHH(userId, projectId, hardHatNumber);
      if (response) {
        context.updateOrientee((user) =>
          // for entering hh user must be present either a new worker or an existing one
          user ? { ...user, hardHatNumber } : null,
        );
        props.onNext();
      }
    }
  };

  useEffect(() => {
    sendHotjarEvent("orientation_step_hard_hat_number");
  }, []);

  const noHardHatNumber = !hardHatNumber;

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
      nextButtonText={langStrings.strings.enter}
      nextButtonDisabled={noHardHatNumber}
      loading={hardHatNumberUpdating}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.enterYourHardHatNumber}
      >
        <Input
          inputMode="numeric"
          enterKeyHint="next"
          value={hardHatNumber || ""}
          placeholder={langStrings.strings.enterYourHardHatThatWasGivenToYou}
          onChange={(e) => setHardHatNumber(e.target.value)}
          onPressEnter={() => {
            if (!noHardHatNumber) onEnter();
          }}
        />
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerStepsHardHatNumber;
