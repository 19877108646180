import { IconSquareCheck } from "@tabler/icons";
import { Alert, Popover } from "antd";
import dayjs from "dayjs";
import * as React from "react";
import { useState } from "react";
import Icon from "src/common/components/general/Icon";

import durationBetweenDates from "src/common/functions/durationBetweenDates";
import getDateStr from "src/common/functions/getDateStr";
import HoverHint from "./HoverHint";
import CounterLabel from "./general/CounterLabel";
import SelectHowToSendOrientationInvite from "src/root/routes/views/subcontractor/people/workers/components/SelectHowToSendOrientationInvite";

export const getLatestDate = (
  firstDate: string | null | undefined,
  secondDate: string | null | undefined,
) => {
  if (!secondDate) return firstDate;
  if (!firstDate) return secondDate;
  if (dayjs(firstDate).isAfter(dayjs(secondDate))) return firstDate;
  else return secondDate;
};

export interface ProjectProps {
  orientationDates?: {
    in_person_orientated_at: string | null | undefined;
    orientated_at: string | null | undefined;
    completed_at: string | null | undefined;
  };
  refetch: () => void;
  name: string;
  id: string;
  endDate?: Date;
  hasInPerson: boolean;
  hasSlides: boolean;
  worker: {
    email?: string | null;
    name: string;
    phone_number?: string | null;
    id: string;
  };
  toolBoxTalks?: { description: string }[];
  archivedAt?: string;
  archivedBy?: string;
  startDate?: Date;
  hardHat?: { number?: string; date?: Date };
}

const Project: React.FunctionComponent<ProjectProps> = (props) => {
  // TODO: project duration counter
  const [openInviteModal, setOpenInviteModal] = useState(false);

  const OrientationDate = getLatestDate(
    props.orientationDates?.completed_at,
    getLatestDate(
      props.orientationDates?.in_person_orientated_at,
      props.orientationDates?.orientated_at,
    ),
  );
  let workerFullyOrientated = !!(props.orientationDates?.completed_at);
  const inPersonDate = !!(
    props.orientationDates?.in_person_orientated_at
  );
  const isOrientated = !!(props.orientationDates?.orientated_at);
  if (props.hasSlides && props.hasInPerson)
    workerFullyOrientated =
      workerFullyOrientated || (inPersonDate && isOrientated);
  else if (props.hasInPerson)
    workerFullyOrientated = workerFullyOrientated || inPersonDate;
  else if (props.hasSlides)
    workerFullyOrientated = workerFullyOrientated || isOrientated;

  return (
    <div className="flex flex-col margin-gap-y-0.5">
      <SelectHowToSendOrientationInvite
        visible={openInviteModal}
        onClose={() => setOpenInviteModal(false)}
        worker={props.worker}
        projectId={props.id}
        hasSlides={props.hasSlides}
        refetch={props.refetch}
      />
      <span className="font-accent">
        {props.name}
        {props.archivedAt && (
          <span>
            <Popover
              trigger={"click"}
              title="Worker Archived By GC on this Project"
              content={
                <Alert
                  type="warning"
                  message={
                    <div>
                      An archived worker is a worker that was added <br />
                      to the project by a foreman, subcontractor,
                      <br />
                      or your team but never worked on the project. <br />
                      An archived worker has not completed orientation.
                      <br />
                      Archived By: {props.archivedBy ?? ""}
                      <br /> Archived Date: {props.archivedAt ?? ""}
                    </div>
                  }
                />
              }
            >
              <span className="text-0.75 border rounded-1 border-warn text-warn hover:text-warn px-0.75 py-0.25 mx-0.5">
                Archived
              </span>
            </Popover>
          </span>
        )}
      </span>

      {(props.startDate || props.endDate) && (
        <span className="text-static-secondary">{`${
          props.startDate ? props.startDate.toLocaleDateString() : "..."
        } → ${props.endDate ? props.endDate.toLocaleDateString() : "..."} ${
          props.startDate && props.endDate
            ? "(" + durationBetweenDates(props.startDate, props.endDate) + ")"
            : ""
        }`}</span>
      )}
      {!!props.toolBoxTalks && (
        <CounterLabel
          count={props.toolBoxTalks.length}
          label={`ToolBox Talks`}
          modalOnClick={
            props.toolBoxTalks.length
              ? {
                  content: (
                    <div className="flex flex-col gap-0.25">
                      <div className="font-accent">Toolbox Talks:</div>
                      {props.toolBoxTalks.map((tbt, i) => (
                        <div key={i}>{tbt.description}</div>
                      ))}
                    </div>
                  ),
                }
              : undefined
          }
        />
      )}
      {props.hardHat && (props.hardHat.number || props.hardHat.date) && (
        <div className="flex flex-row margin-gap-x-0.5">
          {props.hardHat.number && (
            <>
              <Icon icon={IconSquareCheck} color="static" />
              <span>{`HH #${props.hardHat.number}`}</span>
            </>
          )}
          {props.hardHat.date && (
            <span className="text-static-secondary">
              {props.hardHat.date.toLocaleDateString()}
            </span>
          )}
        </div>
      )}
      {workerFullyOrientated && OrientationDate ? (
        <div className="text-static-secondary">
          {`Orientation on ${getDateStr(new Date(OrientationDate))}`}
        </div>
      ) : props.hasInPerson || props.hasSlides ? (
        <div>
          {props.hasInPerson &&
          !props.orientationDates?.in_person_orientated_at ? (
            <div>
              In-Person Orientation:{" "}
              <span className="text-semantic-negative">Not Complete</span>
            </div>
          ) : (
            ""
          )}
          {props.hasSlides &&
          !(
            props.orientationDates?.completed_at ||
            props.orientationDates?.orientated_at
          )
            ? `Digital Orientation: `
            : `Project Registration: `}
          <HoverHint
            inline
            text={`${props.worker.name} has not completed this project’s ${
              props.hasSlides &&
              !(
                props.orientationDates?.completed_at ||
                props.orientationDates?.orientated_at
              )
                ? "orientation"
                : "registration"
            }. Email them a link to complete it`}
          >
            <span
              className={`font-accent text-interactive-primary select-none cursor-pointer`}
              onClick={() => setOpenInviteModal(true)}
            >
              Invite
            </span>
          </HoverHint>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Project;
