import { Table } from "antd";
import React, { FC } from "react";
import { GetReportByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";

interface Props {
  data: NonNullable<
    NonNullable<GetReportByPkQuery["report_by_pk"]>["report_fatal_four"]
  >;
}

const FatalFourReports: FC<Props> = ({ data }) => {
  const dataSource = [
    {
      key: "1",
      type: "Electrocutions",
      discussion: data.electrocute_control?.en ?? "N/A",
    },
    {
      key: "2",
      type: "Falls",
      discussion: data.fall_control?.en ?? "N/A",
    },
    {
      key: "3",
      type: "Struck-By",
      discussion: data.struck_control?.en ?? "N/A",
    },
    {
      key: "4",
      type: "Caught-In / Between",
      discussion: data.caught_control?.en ?? "N/A",
    },
  ];

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Discussion",
      dataIndex: "discussion",
      key: "discussion",
    },
  ];

  return (
    <div>
      <h3>Fatal Four Reports</h3>
      <Table dataSource={dataSource} columns={columns} />
    </div>
  );
};

export default FatalFourReports;
