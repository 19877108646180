import { Button, message, Modal, Select } from "antd";
import dayjs from "dayjs";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useEmailRejectedDocumentMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { VerifyCertsQuery } from "src/common/types/generated/relay/VerifyCertsQuery.graphql";
import { VerifyMultipleCertsModal_UpdateMutation } from "src/common/types/generated/relay/VerifyMultipleCertsModal_UpdateMutation.graphql";
import { updateCertsToVerifyMutation } from "./VerifyMultipleCertsModal";
import GetFullID from "../../../../../common/functions/GetFullId";

interface RejectAndArchiveButtonProps {
  certId: string;
  changeType: "certificate" | "drugtest" | "profilePhoto";
  workerId: string;
  reasons: VerifyCertsQuery["response"]["reason_to_reject_connection"]["edges"];
  loggedInUserId: string;
  onSuccess: () => void;
  projectId?: string;
}

const RejectAndArchiveButtons: FC<RejectAndArchiveButtonProps> = ({
  certId,
  changeType,
  loggedInUserId,
  workerId,
  reasons,
  onSuccess,
  projectId,
}) => {
  const [reasonToReject, setReasonToReject] = useState<string | undefined>();
  const navigate = useNavigate();
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [reasonToArchive, setReasonToArchive] = useState<string | undefined>();
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [emailForRejection] = useEmailRejectedDocumentMutation();
  const [updateMutation, updatingStatus] =
    useAsyncMutation<VerifyMultipleCertsModal_UpdateMutation>(
      updateCertsToVerifyMutation,
    );

  const rejectReasons = reasons.filter((r) => r.node.type === "reject");
  const archiveReasons = reasons.filter((r) => r.node.type === "archive");
  return (
    <div
      style={{
        textAlign: "right",
        marginBottom: changeType !== "profilePhoto" ? "50px" : "0px",
      }}
      className={`text-right ${
        changeType !== "profilePhoto" ? "mb-5" : "mb-0"
      }`}
    >
      {changeType !== "profilePhoto" && (
        <span>
          Cert Id: <span className="font-accent">{certId}</span>
        </span>
      )}
      <div className="flex space-x-1">
        <Modal
          confirmLoading={updatingStatus}
          open={rejectModalOpen}
          title="Reject Certificate"
          onCancel={() => setRejectModalOpen(false)}
          onOk={async () => {
            // console.log(reasonToReject);
            const time = dayjs().format();
            await updateMutation({
              variables: {
                certId: certId,
                _set: {
                  verified_at: time,
                  verified_by_uid: loggedInUserId,
                  reason_to_reject_or_archive_id: reasonToReject,
                  status: "rejected",
                },
              },
              optimisticResponse: {
                update_certificates_to_verify_by_pk: {
                  verified_at: time,
                  id: GetFullID("certificates_to_verify", certId),
                },
              },
            }).catch((e) => console.log(e));

            if (changeType === "drugtest" && reasonToReject)
              emailForRejection({
                variables: {
                  input: { certId, reason: reasonToReject, projectId },
                },
              }).catch((e) => message.error(e));

            //TODO send email
            message.success("Rejected");
            setReasonToReject(undefined);
            setRejectModalOpen(false);
            onSuccess();
          }}
        >
          <div className="text-1">Reason To Reject</div>
          <br />
          <Select
            placeholder="Select the reason to reject the certification"
            onChange={(v) => setReasonToReject(v.toLocaleString())}
            className="w-full"
          >
            {rejectReasons.map((r) => (
              <Select.Option key={r.node.pk} value={r.node.pk}>
                {r.node.reason}
              </Select.Option>
            ))}
          </Select>
        </Modal>

        <Modal
          confirmLoading={updatingStatus}
          open={archiveModalOpen}
          title="Archive Certificate"
          onCancel={() => setArchiveModalOpen(false)}
          onOk={async () => {
            // console.log(reasonToArchive);
            const time = dayjs().format();
            await updateMutation({
              variables: {
                certId: certId,
                _set: {
                  verified_at: time,
                  verified_by_uid: loggedInUserId,
                  reason_to_reject_or_archive_id: reasonToArchive,
                  status: "archived",
                },
              },
              optimisticResponse: {
                update_certificates_to_verify_by_pk: {
                  verified_at: time,
                  id: certId,
                },
              },
            }).catch((e) => console.log(e));

            //TODO send email
            message.success("Archived");
            setReasonToArchive(undefined);
            setArchiveModalOpen(false);
            onSuccess();
          }}
        >
          <div className="text-1">Reason To Archive</div>
          <br />
          <Select
            placeholder="Select the reason to reject the certification"
            onChange={(v) => setReasonToArchive(v.toLocaleString())}
            className="w-full"
          >
            {archiveReasons.map((r) => (
              <Select.Option key={r.node.pk} value={r.node.pk}>
                {r.node.reason}
              </Select.Option>
            ))}
          </Select>
        </Modal>

        <Button
          loading={updatingStatus}
          type="primary"
          className="border-0 bg-semantic-negative rounded-2 hover:bg-semantic-negative"
          onClick={() => setRejectModalOpen(true)}
        >
          Reject
        </Button>
        <Button
          type="primary"
          loading={updatingStatus}
          onClick={() => setArchiveModalOpen(true)}
          className="border-0 bg-warn rounded-2 hover:bg-warn"
        >
          Archive
        </Button>
        {changeType !== "profilePhoto" && (
          <BPopconfirm
            onConfirm={async () => {
              await updateMutation({
                variables: {
                  certId: certId,
                  _set: {
                    document: changeType,
                  },
                },
              });
              if (changeType === "drugtest")
                navigate(`/sfe/verify/drugtest/${certId}`);
              else {
                navigate(`/sfe/verify/certificate/${workerId}#${certId}`);
              }
            }}
          >
            <Button
              loading={updatingStatus}
              className="border-0 rounded-2"
              style={{ backgroundColor: "navy", color: "white" }}
            >
              Change to {changeType}
            </Button>
          </BPopconfirm>
        )}
      </div>
    </div>
  );
};
export default RejectAndArchiveButtons;
