import React, { useEffect } from "react";
import {
  OptionType,
  useCreateMultipleInspectionResultsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { OptionResponseType } from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import useSiteInspectionUser from "src/domain-features/siteinspection/utils/useSiteInspectionUser";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";

export interface CompleteInPersonOrientationProps {
  onFinish: () => void;
}

const optionResponseTypeToOptionType = (
  value: OptionResponseType,
): OptionType => {
  switch (value) {
    case "yes":
      return OptionType.Yes;
    case "no":
      return OptionType.No;
    case "na":
      return OptionType.Na;
    default:
      throw new Error(`Unknow OptionResponseType value ${value}`);
  }
};

const CompleteInPersonOrientation: React.FC<
  CompleteInPersonOrientationProps
> = (props) => {
  const [createMultipleInspectionResults, { loading }] =
    useCreateMultipleInspectionResultsMutation();
  const langStrings = useCurrentLangStrings();
  const workerContext = useSiteInspectionUser();
  const completeInPersonOrientation = async () => {
    try {
      const vals = workerContext.user;
      if (vals) {
        if (!vals.signatureImageUrl)
          throw new Error(langStrings.strings.signatureImageNotFoundMessage);
        if (!vals.inspection?.id)
          throw new Error(
            langStrings.strings.selectedInspectionNotFoundPleaseGoBackAndTryAgain,
          );
        await createMultipleInspectionResults({
          variables: {
            input: {
              qrCodeId: workerContext.qrCodeId,
              signatureUrl: vals.signatureImageUrl,
              inspectionId: vals.inspection?.id,

              ...(vals.company?.id
                ? { companyId: vals.company?.id }
                : { companyName: vals.company?.name }),
              ...(vals.id ? { userId: vals.id } : { userName: vals.name }),
              modelAnswersAndImages: vals.inspection?.is_multi_inspection
                ? (vals.modelAnswersAndImages || []).map((p) => {
                    const answers =
                      (p.result === "fail"
                        ? p.checklistItemAnswers
                        : vals.checklistItemAnswers) || {};
                    return {
                      images: p.images.map((img) => img.url),
                      modelNumber: p.modelNumber,
                      checklistAnswers: Object.values(answers).map((val) => ({
                        deficientText: val.text_response,
                        deficientTypeId: val.deficient_type_id,
                        checklistItemId: val.id,
                        answer:
                          val.option_response === "yes"
                            ? OptionType.Yes
                            : val.option_response === "no"
                            ? OptionType.No
                            : OptionType.Na,
                      })),
                    };
                  })
                : [
                    {
                      images: (vals.uploadedImages || []).map((img) => img.url),
                      modelNumber: vals.model,
                      checklistAnswers: Object.values(
                        vals.checklistItemAnswers ?? {},
                      ).map((val) => ({
                        deficientText: val.text_response,
                        deficientTypeId: val.deficient_type_id,
                        checklistItemId: val.id,
                        answer:
                          val.option_response === "yes"
                            ? OptionType.Yes
                            : val.option_response === "no"
                            ? OptionType.No
                            : OptionType.Na,
                      })),
                    },
                  ],
            },
          },
        });
      }
    } catch (err) {
      console.log(err);
      throw new Error(err instanceof Error ? err.message : JSON.stringify(err));
    } finally {
      props.onFinish();
    }
  };

  useEffect(() => {
    completeInPersonOrientation();
  }, []);

  return null;
};

export default CompleteInPersonOrientation;
