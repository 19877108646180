import { useSuspenseQuery } from "@apollo/client";
import clsx from "clsx";
import { useRef, useState } from "react";
import { useRelayEnvironment } from "react-relay";
import { ConnectionHandler, commitLocalUpdate } from "relay-runtime";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import GetFullID from "src/common/functions/GetFullId";
import {
  GetChangeProjectHierarchyDataQuery,
  GetChangeProjectHierarchyDataQueryVariables,
  GetChangeProjectHierarchyDataDocument,
  useUpdateProjectHierarchyMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";

const ChangeProjectHierarchyModal: React.FC<{
  projectId: string;
  divisionId?: string | null;
  businessUnitId?: string | null;
  officeId?: string | null;
  showLevels: Array<"division" | "business-unit" | "office">;
  onCancel: () => void;
  onSuccess: () => void;
}> = ({
  projectId,
  showLevels,
  divisionId,
  businessUnitId,
  officeId,
  onCancel,
  onSuccess,
}) => {
  const { userData } = useUserData();
  const emp = userData.employee;
  if (!projectId) throw new Error("ProjectId not found");
  if (!emp) throw new Error("No logged in gc user found");
  const [selectedDivision, setSelectedDivision] = useState<
    string | null | undefined
  >(divisionId);
  const [selectedBu, setSelectedBu] = useState<string | null | undefined>(
    businessUnitId,
  );

  const { data, refetch } = useSuspenseQuery<
    GetChangeProjectHierarchyDataQuery,
    GetChangeProjectHierarchyDataQueryVariables
  >(GetChangeProjectHierarchyDataDocument, {
    variables: {
      projectId,
      officeWhere: {
        general_contractor_id: { _eq: emp.general_contractor_id },
      },
      buWhere: {
        general_contractor_id: { _eq: emp.general_contractor_id },
      },
      divisionWhere: {
        general_contractor_id: { _eq: emp.general_contractor_id },
      },
    },
    fetchPolicy: "network-only",
  });
  const proj = data.project_by_pk;
  if (!proj) {
    throw new Error("project not found for given Id");
  }
  const gc = emp.general_contractor;
  const fmodalRef = useRef<
    FModalRef<{
      divisionId?: string | null;
      businessUnitId?: string | null;
      officeId?: string | null;
      name: string;
    }>
  >();
  const relayEnv = useRelayEnvironment();
  const [updateProjectHierarchy, { loading }] =
    useUpdateProjectHierarchyMutation();
  return (
    <FModal
      ref={fmodalRef}
      open={true}
      title="Edit Project Details"
      onCancel={onCancel}
      destroyOnClose
      confirmLoading={loading}
      onOk={async () => {
        const vals = await fmodalRef.current?.form.validateFields();
        if (!vals) return;

        const divChange = vals.divisionId !== divisionId;
        const buChange = vals.businessUnitId !== businessUnitId;
        const officeChange = vals.officeId !== officeId;

        const deleteOfficeEmp = officeChange
          ? data.gc_office.find((o) => o.id === officeId)
              ?.gc_office_employees || []
          : [];
        const deleteBuEmp = buChange
          ? data.gc_business_unit.find((b) => b.id === businessUnitId)
              ?.gc_business_unit_employees || []
          : [];
        const deleteDivEmp = divChange
          ? data.gc_division.find((d) => d.id === divisionId)
              ?.gc_division_employees || []
          : [];

        const newOffEmp = officeChange
          ? data.gc_office.find((o) => o.id === vals.officeId)
              ?.gc_office_employees || []
          : [];
        const newBuEmp = buChange
          ? data.gc_business_unit.find((b) => b.id === vals.businessUnitId)
              ?.gc_business_unit_employees || []
          : [];
        const newDivEmp = divChange
          ? data.gc_division.find((d) => d.id === vals.divisionId)
              ?.gc_division_employees || []
          : [];

        const deleteEmployeeIds: string[] = [
          ...deleteOfficeEmp,
          ...deleteBuEmp,
          ...deleteDivEmp,
        ].map((p) => p.user_id);

        const toAddEmployeeIds: string[] = [
          ...newOffEmp,
          ...newBuEmp,
          ...newDivEmp,
        ].map((p) => p.user_id);

        await updateProjectHierarchy({
          variables: {
            projectId,
            projectSet: {
              gc_office_id: vals.officeId || null,
              gc_business_unit_id: vals.businessUnitId || null,
              gc_division_id: vals.divisionId || null,
              name: vals.name.trim() || proj.name,
            },

            deleteProjEmpWhere: {
              direct_project_assign: { _eq: false },
              employee_id: { _in: deleteEmployeeIds },
              project_id: { _eq: projectId },
            },
            projEmpObjs: toAddEmployeeIds.map((empId) => ({
              direct_project_assign: false,
              project_id: projectId,
              employee_id: empId,
              status: "Inactive",
            })),
          },
        });
        commitLocalUpdate(relayEnv, (store) => {
          const conn = ConnectionHandler.getConnection(
            store.getRoot(),
            "GCProjectSettingsOldQuery_project_connection",
          );
          if (conn) {
            const projNode = conn
              .getLinkedRecords("edges")
              ?.find((e) => {
                return (
                  e.getLinkedRecord("node")?.getDataID() ===
                  GetFullID("project", projectId)
                );
              })
              ?.getLinkedRecord("node");
            if (projNode) {
              projNode.setValue(vals.name || proj.name, "name");
              projNode.setValue(vals.officeId || null, "gc_office_id");
              projNode.setValue(
                vals.businessUnitId || null,
                "gc_business_unit_id",
              );
              projNode.setValue(vals.divisionId || null, "gc_division_id");
            }
          }
        });
        onSuccess();
        refetch();
      }}
      okText={"Confirm"}
      form={{
        initialValues: {
          businessUnitId,
          divisionId,
          officeId,
          name: proj.name,
        },
      }}
    >
      <FModal.Text
        name="name"
        label="Project Name"
        rules={[{ required: true, message: "Name is required" }]}
      />
      {/* Address
      <AddressFormItems states={data?.state || []} onStateChange={() => {}} /> */}
      <FModal.Select
        className={clsx(
          showLevels.includes("division") &&
            data.gc_division.length &&
            gc.hierarchy_division_name
            ? ""
            : "hidden", //WARN: DO not change it
        )}
        name="divisionId"
        label={gc.hierarchy_division_name}
        props={{
          allowClear: true,
          onChange: (v) => {
            if (typeof v === "string" || typeof v === "undefined")
              setSelectedDivision(v);
            setSelectedBu(undefined);
            fmodalRef.current?.form.setFieldsValue({
              businessUnitId: undefined,
              officeId: undefined,
            });
          },
          filterOption: (input, option) =>
            option?.label &&
            typeof option.label === "string" &&
            option.label.toLowerCase().includes(input.toLowerCase()),
          style: { width: "100%" },
          options: data.gc_division.map((s) => ({
            label: s.name,
            value: s.id,
          })),
        }}
      />
      <FModal.Select
        name="businessUnitId"
        className={clsx(
          showLevels.includes("business-unit") &&
            gc.hierarchy_business_unit_name &&
            data.gc_business_unit.length
            ? ""
            : "hidden", //WARN: DO not change it
        )}
        label={
          <div>
            {gc.hierarchy_business_unit_name}
            {gc.hierarchy_division_name && (
              <div>
                (You can select a {gc.hierarchy_business_unit_name} even without
                selecting a {gc.hierarchy_division_name})
              </div>
            )}
          </div>
        }
        props={{
          filterOption: (input, option) =>
            option?.label &&
            typeof option.label === "string" &&
            option.label.toLowerCase().includes(input.toLowerCase()),
          allowClear: true,
          onChange: (v) => {
            if (typeof v === "string") {
              const div = data.gc_business_unit.find(
                (b) => b.id === v,
              )?.gc_division_id;
              setSelectedDivision(div);
              setSelectedBu(v);
              fmodalRef.current?.form.setFieldsValue({
                divisionId: div || undefined,
                officeId: undefined,
              });
            }
          },
          style: { width: "100%" },
          options: data.gc_business_unit
            .filter(
              (bu) =>
                !selectedDivision || bu.gc_division_id === selectedDivision,
            )
            .map((s) => ({
              label: s.name,
              value: s.id,
            })),
        }}
      />
      <FModal.Select
        name="officeId"
        className={clsx(
          showLevels.includes("office") &&
            gc.hierarchy_office_name &&
            data.gc_office.length
            ? ""
            : "hidden", //WARN: DO not change it
        )}
        label={
          <div>
            {gc.hierarchy_office_name}
            {!!(
              gc.hierarchy_division_name || gc.hierarchy_business_unit_name
            ) && (
              <div>
                (You can select a {gc.hierarchy_office_name} even without
                selecting a{" "}
                {gc.hierarchy_business_unit_name && gc.hierarchy_division_name
                  ? `${gc.hierarchy_division_name} or ${gc.hierarchy_business_unit_name}`
                  : gc.hierarchy_division_name ||
                    gc.hierarchy_business_unit_name}
                )
              </div>
            )}
          </div>
        }
        props={{
          filterOption: (input, option) =>
            option?.label &&
            typeof option.label === "string" &&
            option.label.toLowerCase().includes(input.toLowerCase()),
          allowClear: true,
          onChange: (v) => {
            if (typeof v === "string") {
              const newOffice = data.gc_office.find((o) => o.id === v);
              const div = newOffice?.gc_division_id;
              const bu = newOffice?.gc_business_unit_id;
              setSelectedDivision(div);
              setSelectedBu(bu);
              fmodalRef.current?.form.setFieldsValue({
                divisionId: div || undefined,
                businessUnitId: bu || undefined,
              });
            }
          },
          style: { width: "100%" },
          options: data.gc_office
            .filter((of) =>
              selectedBu
                ? of.gc_business_unit_id === selectedBu
                : selectedDivision
                ? of.gc_division_id === selectedDivision
                : true,
            )
            .map((s) => ({
              label: s.name,
              value: s.id,
            })),
        }}
      />
    </FModal>
  );
};
export default ChangeProjectHierarchyModal;
