import { nanoid } from "nanoid/non-secure";
import apolloClient from "src/common/api/apollo/apolloClient";
import validateEmail from "src/common/functions/validateEmail";
import {
  CreateInviteDataObject,
  CreateInviteDocument,
  CreateInviteMutation,
  CreateInviteMutationVariables,
} from "src/common/types/generated/apollo/graphQLTypes";

export type sendInviteProps = {
  toUid?: string;
  sendTo: string;
  sendToName?: string;
  sentFromName?: string;
  sentFromCompanyName?: string;
  joinRole?: string;
  joinProjectId?: string;
  joinGeneralContractorId?: string;
  joinSubcontractorId?: string;
  projectName?: string;
  claimingAccount?: boolean;
  automaticSend?: boolean;
  disableToast?: boolean;
  inviteType?: string;
};

const createInvite = async (data: CreateInviteDataObject) => {
  const res = await apolloClient.mutate<
    CreateInviteMutation,
    CreateInviteMutationVariables
  >({
    mutation: CreateInviteDocument,
    variables: {
      objects: [data],
    },
  });

  return res;
};

const createInvites = async (data: CreateInviteDataObject[]) => {
  const res = await apolloClient.mutate<
    CreateInviteMutation,
    CreateInviteMutationVariables
  >({
    mutation: CreateInviteDocument,
    variables: {
      objects: data,
    },
  });

  return res;
};

const sendInvite = async (
  data: sendInviteProps,
): Promise<
  | { success: true; error: undefined }
  | { success: false; error: string }
  | { success: false; error: undefined }
> => {
  if (!validateEmail(data.sendTo)) {
    return { success: false, error: "Invalid Email" };
  }

  const inviteId = nanoid(12);
  const inviteLink = `https://app.siteform.net/invite/${inviteId}`;
  let inviteMessage = `${data.sentFromName} with ${data.sentFromCompanyName} invited you to access and review reports on ${data.projectName}. Please follow the link below!\n\n${inviteLink}`;
  const createInviteData: CreateInviteDataObject = {
    inviteId: inviteId,
    joinProjectId: data.joinProjectId,
    joinSubcontractorId: data.joinSubcontractorId,
    joinGeneralContractorId: data.joinGeneralContractorId,
    automaticSend: false,
    joinRole: data.joinRole,
  };
  try {
    if (data.sendTo.includes("@")) {
      createInviteData.toEmail = data.sendTo;
    }
    if (data.claimingAccount) {
      createInviteData.claimingAccount = data.claimingAccount;
    }
    if (data.inviteType) {
      createInviteData.inviteType = data.inviteType;
    }
    if (data.toUid) {
      createInviteData.toUid = data.toUid;
    }
    const res = createInvite({
      ...createInviteData,
      serverContact: true,
    }).then(console.log);
  } catch (err) {
    return {
      success: false,
      error: err instanceof Error ? err.message : JSON.stringify(err),
    };
  }
  return { success: true, error: undefined };
};

export const sendInvites = async (
  data: sendInviteProps[],
  redirectToMobilization?: boolean,
): Promise<
  | { success: true; error: undefined }
  | { success: false; error: string }
  | { success: false; error: undefined }
> => {
  const createInviteData: CreateInviteDataObject[] = data.map((obj) => {
    const inviteId = nanoid(12);
    const inviteData: CreateInviteDataObject = {
      inviteId: inviteId,
      joinProjectId: obj.joinProjectId,
      joinSubcontractorId: obj.joinSubcontractorId,
      joinGeneralContractorId: obj.joinGeneralContractorId,
      automaticSend: false,
      joinRole: obj.joinRole,
      serverContact: true,
      redirectToMobilization: redirectToMobilization ?? false,
    };
    if (obj.sendTo.includes("@")) {
      inviteData.toEmail = obj.sendTo;
    }
    if (obj.claimingAccount) {
      inviteData.claimingAccount = obj.claimingAccount;
    }
    if (obj.inviteType) {
      inviteData.inviteType = obj.inviteType;
    }
    return inviteData;
  });
  try {
    await createInvites(createInviteData);
  } catch (err) {
    return {
      success: false,
      error: err instanceof Error ? err.message : JSON.stringify(err),
    };
  }
  return { success: true, error: undefined };
};

export default sendInvite;
