/**
 * @generated SignedSource<<323e674b917df0a2af16a35318a65f16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SCTasksQuery$variables = {
  subcontractorId: string;
};
export type SCTasksQuery$data = {
  readonly permit_type_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: {
          readonly en: string;
        };
        readonly pk: string;
      };
    }>;
  };
  readonly ppe_type_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: {
          readonly en: string;
        };
        readonly pk: string;
      };
    }>;
  };
  readonly project_subcontractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly project: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        };
      };
    }>;
  };
  readonly subcontractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly subcontractor_projects: ReadonlyArray<{
          readonly project: {
            readonly general_contractor: {
              readonly name: string;
            };
            readonly general_contractor_id: string;
            readonly name: string;
          };
          readonly project_id: string;
        }>;
      };
    }>;
  };
};
export type SCTasksQuery = {
  response: SCTasksQuery$data;
  variables: SCTasksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subcontractorId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "name": {
        "en": "asc"
      }
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": [
      (v4/*: any*/)
    ],
    "storageKey": null
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "subcontractorId"
  }
],
v7 = [
  {
    "fields": [
      {
        "fields": (v6/*: any*/),
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v8 = {
  "name": "asc"
},
v9 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "project": {
        "general_contractor": (v8/*: any*/)
      }
    }
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "general_contractor_id",
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "project": (v8/*: any*/)
    }
  },
  {
    "fields": [
      {
        "fields": (v6/*: any*/),
        "kind": "ObjectValue",
        "name": "subcontractor_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v14 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v16 = [
  (v2/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SCTasksQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ppe_typeConnection",
        "kind": "LinkedField",
        "name": "ppe_type_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ppe_typeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ppe_type",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "ppe_type_connection(order_by:{\"name\":{\"en\":\"asc\"}})"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "permit_typeConnection",
        "kind": "LinkedField",
        "name": "permit_type_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "permit_typeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "permit_type",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "permit_type_connection(order_by:{\"name\":{\"en\":\"asc\"}})"
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "subcontractorConnection",
        "kind": "LinkedField",
        "name": "subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "project_subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor_projects",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "general_contractor",
                            "kind": "LinkedField",
                            "name": "general_contractor",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "subcontractor_projects(order_by:{\"project\":{\"general_contractor\":{\"name\":\"asc\"}}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SCTasksQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ppe_typeConnection",
        "kind": "LinkedField",
        "name": "ppe_type_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ppe_typeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ppe_type",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v16/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "ppe_type_connection(order_by:{\"name\":{\"en\":\"asc\"}})"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "permit_typeConnection",
        "kind": "LinkedField",
        "name": "permit_type_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "permit_typeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "permit_type",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v16/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "permit_type_connection(order_by:{\"name\":{\"en\":\"asc\"}})"
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "subcontractorConnection",
        "kind": "LinkedField",
        "name": "subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "project_subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor_projects",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "general_contractor",
                            "kind": "LinkedField",
                            "name": "general_contractor",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": "subcontractor_projects(order_by:{\"project\":{\"general_contractor\":{\"name\":\"asc\"}}})"
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v2/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e29c70b781bdc7d4b88bc580f5ac89b6",
    "id": null,
    "metadata": {},
    "name": "SCTasksQuery",
    "operationKind": "query",
    "text": "query SCTasksQuery(\n  $subcontractorId: uuid!\n) {\n  ppe_type_connection(order_by: {name: {en: asc}}) {\n    edges {\n      node {\n        id\n        pk: id\n        name {\n          en\n          id\n        }\n      }\n    }\n  }\n  permit_type_connection(order_by: {name: {en: asc}}) {\n    edges {\n      node {\n        id\n        pk: id\n        name {\n          en\n          id\n        }\n      }\n    }\n  }\n  subcontractor_connection(where: {id: {_eq: $subcontractorId}}) {\n    edges {\n      node {\n        subcontractor_projects(order_by: {project: {general_contractor: {name: asc}}}) {\n          project_id\n          project {\n            name\n            general_contractor_id\n            general_contractor {\n              name\n              id\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n  project_subcontractor_connection(where: {subcontractor_id: {_eq: $subcontractorId}}, order_by: {project: {name: asc}}) {\n    edges {\n      node {\n        project {\n          name\n          id\n          pk: id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d6ab2b5b67ebdc166f9a28393330c579";

export default node;
