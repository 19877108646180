import React, { FC, useMemo } from "react";
import useColumnOrderBy from "src/common/hooks/useColumnOrderBy";
import useColumnSearch from "src/common/hooks/useColumnSearch";
import usePaginatedQuery, {
  UsePaginatedQueryParams,
} from "src/common/hooks/usePaginatedQuery";
import {
  Awareness_Question,
  Awareness_Question_Bool_Exp,
  Awareness_Question_Order_By,
  GetAwarenessQuestionsDocument,
  Order_By,
} from "src/common/types/generated/apollo/graphQLTypes";
import TableTitle from "../../../../../../common/components/tables/basic/TableTitle";
import Columns from "./Columns";

interface TableProps {
  onClickRow: (dr: Partial<Awareness_Question>) => void;
  skipKeys?: Array<string>;
  titleComponent?: any;
  loading?: boolean;
  title?: string;
  queryInfo: { value?: string; type: "subcontractor" | "global" };
  onDelete?: (r: Partial<Awareness_Question>) => any;
  queryRef?: (tableRef: any) => any;
}

const AwarenessQuestionsTable: FC<TableProps> = ({
  onClickRow,
  skipKeys = [],
  titleComponent,
  queryInfo,
  title,
  onDelete,
  queryRef,
  ...props
}) => {
  const { orderBy, setOrderBy } = useColumnOrderBy<Awareness_Question_Order_By>(
    {
      // TODO order lang
      question: {
        en: Order_By.AscNullsLast,
      },
    }
  );
  const nameSearch = useColumnSearch();

  const whereObject = useMemo(() => {
    const where: Awareness_Question_Bool_Exp = {};
    if (queryInfo.type === "subcontractor") {
      where["subcontractor_id"] = { _eq: queryInfo.value };
    } else {
      where["subcontractor_id"] = { _is_null: true };
    }
    return where;
  }, [nameSearch.value]);

  const queryVariables = useMemo(
    (): UsePaginatedQueryParams<Awareness_Question_Bool_Exp> => ({
      where: whereObject,
      orderBy: orderBy,
      queryDoc: GetAwarenessQuestionsDocument,
      dataIndex: "awareness_question",
      aggregateCountIndex: "awareness_question_aggregate",
    }),
    [whereObject, orderBy]
  );

  const [employees, { loading, page, setPage, count, pageSize, refetch }] =
    usePaginatedQuery<Partial<Awareness_Question>>(queryVariables);

  if (queryRef) {
    queryRef({
      refetch,
    });
  }

  return (
    <Columns
      onDelete={onDelete}
      skipKeys={skipKeys}
      // searchColumns={{ name: nameSearch.setter }}
      sortColumns={{
        name: true,
      }}
      title={() => (
        <TableTitle
          titleComponent={titleComponent}
          title={title ? title : "Awareness Questions"}
        />
      )}
      dataSource={employees}
      loading={loading || props.loading}
      onChange={(_, filter, sorter) => {
        setOrderBy(sorter);
      }}
      onRow={(dr) => ({
        onClick: () => onClickRow(dr),
      })}
      pagination={{
        current: page,
        pageSize,
        total: count,
        onChange: setPage,
      }}
    />
  );
};

export default AwarenessQuestionsTable;
