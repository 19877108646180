import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio, Select } from 'antd';

interface Values {
  question: string;
}

interface Props {
  visible: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
}

const AddAwarenessQuestionModal: React.FC<Props> = ({
  visible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    (<Modal
      open={visible}
      title="Add Awareness Question"
      okText="Add"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values as Values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: 'public' }}
      >
        <Form.Item
          name="question"
          label="Question to add"
          rules={[{ required: true, message: 'Enter your question' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>)
  );
};

export default AddAwarenessQuestionModal;
