import { ColumnsType } from "antd/lib/table";
import BaseTable, {
  BaseTableProps,
} from "src/common/components/tables/basic/BaseTable";
import { General_Contractor } from "src/common/types/generated/apollo/graphQLTypes";
import styled from "styled-components";

// TODO add this to tables
type DataProp = General_Contractor;

type Props = Omit<BaseTableProps<Partial<DataProp>>, "columns"> & {
  onDelete?: (r: DataProp) => any;
};
type GeneralContractorsTableColumns = (p: Props) => JSX.Element;

const GeneralContractorsTableColumns = styled(BaseTable).attrs(
  ({ pagination, onDelete, ...props }: Props) => {
    const columns: ColumnsType = [
      {
        title: "Name",
        key: "name",
        dataIndex: ["name"],
      },
      {
        title: "Employees",
        key: "employees",
        dataIndex: ["employees_aggregate", "aggregate", "count"],
      },
      {
        title: "Active Projects",
        key: "active",
        dataIndex: ["projects_aggregate", "aggregate", "count"],
      },
    ];

    return {
      ...props,
      columns,
      rowKey: (w: any) => w.id,
    };
  }
)`` as GeneralContractorsTableColumns;

export default GeneralContractorsTableColumns;
