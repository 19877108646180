import dayjs, { OpUnitType } from "dayjs";
import {
  GetWorkerStatsByRangeQuery,
  GetWorkerStatsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";

export type WorkerTally = {
  total: number;
  subs: string[];
  foreman: any[];
  journeyman: any[];
  apprentice: any[];
};

export type WorkerManHourStats = GetWorkerStatsQuery["worker_manhour_stats"];
export type WorkerManHourStat = WorkerManHourStats[number];
export type WorkerManHourRecord = Omit<
  WorkerManHourStat,
  "worker_manhour_stats_table"
>;
export type WorkerManHourKey =
  | "man_hours_today"
  | "man_hours_this_wk"
  | "man_hours_last_wk"
  | "man_hours_this_mo"
  | "man_hours_last_mo"
  | "man_hours_two_mo_ago"
  | "man_hours_ytd"
  | "man_hours_total";
export type WorkerStats = GetWorkerStatsQuery["worker_onsite_stats"];
export type WorkerStat = WorkerStats[number];
export type WorkerStatRecord = Omit<WorkerStat, "worker_stats_table">;
export type WorkerStatsKey =
  | "onsite_today"
  | "onsite_last7_days"
  | "onsite_this_wk"
  | "onsite_last_wk"
  | "onsite_this_mo"
  | "onsite_last_mo"
  | "onsite_two_mo_ago"
  | "onsite_ytd"
  | "onsite_total";
export type WorkerAggregateByRangeStats =
  GetWorkerStatsByRangeQuery["workersByRange"];
export type WorkerManhourAggregateByRangeStats =
  GetWorkerStatsByRangeQuery["manHoursByRange"];
export type WorkerAggregateByRangeStat = WorkerAggregateByRangeStats[number];
export type WorkerManhourAggregateByRangeStat =
  WorkerManhourAggregateByRangeStats[number];
export type WorkerAggregateRecord = Omit<
  WorkerAggregateByRangeStat,
  "worker_stats_by_range_table"
>;
export type WorkerManHourAggregateRecord = Omit<
  WorkerManhourAggregateByRangeStat,
  "worker_manhour_stats_by_range_table"
>;
export type WorkerRangeStatKey =
  | "report_created_at"
  | "foreman"
  | "journeyman"
  | "apprentice";
export type WorkerAveragesSchema = {
  average30: number;
  averageThisWeek: number;
  averageLastWeek: number;
  averageLastMonth: number;
};
export type WorkerStatsSchema = {
  subs: Array<{ id: string; name: string }>;
  today: number;
  thisWeek: number;
  lastWeek: number;
  thisMonth: number;
  lastMonth: number;
  twoMonthsAgo: number;
  YTD: number;
  lifetime: number;
};

interface OptionalFilterFn {
  (filterBySub?: string): any;
}

export interface WorkerStatHelpers {
  onSite: OptionalFilterFn;
  manHours: OptionalFilterFn;
  average: (
    type: "onsite" | "manhours",
    range?: Array<string> | number,
    filter?: OpUnitType,
  ) => number;
}

type StatRecordReducer<Schema, Type> = (
  stats: Schema,
  record: Type,
  idx: number,
  arr: any[],
) => WorkerStatsSchema;

export const DefaultStats = (): WorkerStatsSchema => ({
  subs: [],
  today: 0,
  thisWeek: 0,
  thisMonth: 0,
  lastWeek: 0,
  lastMonth: 0,
  twoMonthsAgo: 0,
  YTD: 0,
  lifetime: 0,
});

class WorkerStatistics implements WorkerStatHelpers {
  constructor(
    private onsite: WorkerStats,
    private manhours: WorkerManHourStats,
    private onsiteAggregates?: WorkerAggregateByRangeStats,
    private manhourAggregates?: WorkerManhourAggregateByRangeStats,
  ) {
    this.onsite = onsite;
    this.manhours = manhours;
    this.onsiteAggregates = onsiteAggregates;
    this.manhourAggregates = manhourAggregates;
  }

  // Public Methods
  public average(
    type: "onsite" | "manhours",
    range?: Array<string> | number,
    filter?: OpUnitType,
  ): number {
    if (!this.manhourAggregates || !this.onsiteAggregates) return 0;

    let records: any =
      type === "manhours" ? this.manhourAggregates : this.onsiteAggregates;

    if (range) records = this.filterRange(records, range, filter);

    const result = records
      .map((r: any) => r.total)
      .reduce(this.averageReducer, 0);

    return Math.round(result);
  }

  public manHours(filterBySub?: string): WorkerStatsSchema {
    let filtered = this.manhours;
    if (filterBySub !== undefined) {
      filtered = this.filterStatsBySubcontractor(filtered, filterBySub);
    }

    const statReducer: StatRecordReducer<
      WorkerStatsSchema,
      WorkerManHourRecord
    > = (stats, record, idx, arr) => {
      const onsiteTotal =
        record.foreman_today +
        record.journeymen_today +
        record.apprentice_today;
      if (onsiteTotal !== 0) {
        stats.subs.push({ id: record.id, name: record.sub_name });
      }
      stats.today += record.man_hours_today;
      stats.thisWeek += record.man_hours_this_wk;
      stats.thisMonth += record.man_hours_this_mo;
      stats.lastWeek += record.man_hours_last_wk;
      stats.lastMonth += record.man_hours_last_mo;
      stats.twoMonthsAgo += record.man_hours_two_mo_ago;
      stats.YTD += record.man_hours_ytd;
      stats.lifetime += record.man_hours_total;

      // if (idx === arr.length - 1) {
      //   console.log('ManHours from Table:', stats.thisMonth);
      // }

      return stats;
    };

    return filtered.reduce(statReducer, DefaultStats());
  }

  public onSite(filterBySub?: string): WorkerStatsSchema {
    let filtered = this.onsite;
    if (filterBySub !== undefined) {
      filtered = this.filterStatsBySubcontractor(filtered, filterBySub);
    }
    const statReducer: StatRecordReducer<
      WorkerStatsSchema,
      WorkerStatRecord
    > = (stats, record, idx, arr) => {
      const onsiteTotal =
        record.foreman_today +
        record.journeymen_today +
        record.apprentice_today;
      if (onsiteTotal !== 0) {
        stats.subs.push({ id: record.id, name: record.sub_name });
      }
      stats.today += record.onsite_today;
      stats.thisWeek += record.onsite_last_wk;
      stats.thisMonth += record.onsite_this_mo;
      stats.lastWeek += record.onsite_last_wk;
      stats.lastMonth += record.onsite_last_mo;
      stats.twoMonthsAgo += record.onsite_two_mo_ago;
      stats.YTD += record.onsite_ytd;
      stats.lifetime += record.onsite_total;

      // if (idx === arr.length - 1) {
      //   console.log(stats);
      // }

      return stats;
    };
    return filtered.reduce(statReducer, DefaultStats());
  }

  private averageReducer(
    sum: number,
    val: number,
    idx: number,
    arr: any[],
  ): number {
    const calculated = sum + val;
    if (idx === arr.length - 1) return calculated / arr.length;
    return calculated;
  }

  private filterStatsBySubcontractor(stats: Array<any>, subName: string) {
    return stats.filter((s) => s.sub_name === subName);
  }

  private filterRange(
    records: Array<any>,
    range: any,
    filter?: OpUnitType,
  ): any {
    if (range && Array.isArray(range)) {
      const [start, end] = range;
      return records.filter(
        (r: any) =>
          dayjs(r.report_created_at) >= dayjs(start).startOf("d") &&
          dayjs(r.report_created_at) <= dayjs(end).endOf("d"),
      );
    } else if (typeof range === "number") {
      return records.filter(
        (r: any) =>
          dayjs().diff(dayjs(r["report_created_at"]), filter) <= range,
      );
    }
  }
}

export default WorkerStatistics;
