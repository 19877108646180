import { FC } from "react";

import { auth } from "src/common/functions/firebase";
import {
  useUserData,
  UserContextDataType,
} from "src/utility-features/authorization/UserDataProvider";

export interface CorporateAdminWrapperProps {
  employee: NonNullable<UserContextDataType["employee"]>;
}

const withCorporateAdminWrapper =
  <Props extends object>(Component: FC<Props & CorporateAdminWrapperProps>) =>
  (props: Props) => {
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error("Undeifned user id");
    const { userData } = useUserData();
    if (!userData.employee?.is_corporate_admin)
      throw new Error("No Logged in gc corporate admin found");

    return <Component employee={userData.employee} {...props} />;
  };

export default withCorporateAdminWrapper;
