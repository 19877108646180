import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";
import IncidentSeverityMapping from "../../component/IncidentSeverityMapping";

const GCCompanyIncidentReporting: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => {
  return (
    <IncidentSeverityMapping
      severityUserFilter={{
        gc_id: { _eq: employee.general_contractor_id },
        user: { employee: { is_corporate_admin: { _eq: true } } },
      }}
      toInsertWith={{ gc_id: employee.general_contractor_id }}
      gcEmployeeWhere={{
        is_corporate_admin: { _eq: true },
        general_contractor_id: { _eq: employee.general_contractor_id },
      }}
    />
  );
};
export default withCorporateAdminWrapper(GCCompanyIncidentReporting);
