import React, { FC, useRef, useState } from "react";
import { ViewAndEditJhaQuery$data } from "../types/generated/relay/ViewAndEditJhaQuery.graphql";
import { auth } from "src/common/functions/firebase";
import { Checkbox, notification, Popover } from "antd";
import * as uuid from "uuid";
import insertTaskReviewMutation from "src/common/api/relay/mutations/InsertTaskReview";
import {
  InsertTaskReviewMutation,
  InsertTaskReviewMutation$data,
} from "src/common/types/generated/relay/InsertTaskReviewMutation.graphql";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import CustomButton from "src/common/components/general/Button";
import { IconMail } from "@tabler/icons";
import { getJHAApprovalData } from "src/common/api/relay/queries/GetJHAApprovalModalData";
import { GetJHAApprovalModalDataQuery$data } from "src/common/types/generated/relay/GetJHAApprovalModalDataQuery.graphql";
import { useRelayEnvironment } from "react-relay/hooks";
import { graphql } from "babel-plugin-relay/macro";
import { JhaGceReview_update_review_Mutation } from "src/common/types/generated/relay/JhaGceReview_update_review_Mutation.graphql";
import dayjs from "dayjs";
import SendTaskForApprovalModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/SendTaskForApprovalModal";

interface JhaGceReviewProps {
  taskReviewedData: ViewAndEditJhaQuery$data["task_signature_connection"]["edges"];
  taskId: string;
  isNotTurnersProject: boolean;
  checked: boolean;
  subcontractorId: string;
  projectId: string;
  setChecked: (state: boolean) => void;
}

const JhaGceReview: FC<JhaGceReviewProps> = ({
  taskReviewedData,
  taskId,
  subcontractorId,
  projectId,
  isNotTurnersProject,
  checked,
  setChecked,
}) => {
  const [removeTaskReview] =
    useAsyncMutation<JhaGceReview_update_review_Mutation>(
      graphql`
        mutation JhaGceReview_update_review_Mutation(
          $_set: task_signature_set_input!
          $_taskReviewWhere: task_signature_bool_exp!
        ) {
          update_task_signature(where: $_taskReviewWhere, _set: $_set) {
            affected_rows
          }
        }
      `,
    );

  const [jhaApprovalModalData, setJHAApprovalModalData] = useState<
    GetJHAApprovalModalDataQuery$data | undefined
  >(undefined);
  const environment = useRelayEnvironment();
  const [insertTaskReview] = useAsyncMutation<InsertTaskReviewMutation>(
    insertTaskReviewMutation,
  );
  const handleInsertTaskReviewUpdater = (
    store: RecordSourceSelectorProxy<InsertTaskReviewMutation$data>,
  ) => {
    const updateTask = store.getRootField("update_task_by_pk");
    const insertTaskEdit = store.getRootField("insert_task_edit");
    const taskConn = ConnectionHandler.getConnection(
      store.getRoot(),
      "ViewAndEditJha_task_connection",
    );
    const taskEditConn = ConnectionHandler.getConnection(
      store.getRoot(),
      "ViewAndEditJha_task_edit_connection",
    );
    if (taskEditConn) {
      insertTaskEdit.getLinkedRecords("returning").forEach((p) => {
        const edge = store.create(uuid.v4(), "edge");
        edge.setLinkedRecord(p, "node");
        ConnectionHandler.insertEdgeAfter(taskEditConn, edge);
      });
    }
    if (taskConn) {
      const edges = taskConn.getLinkedRecords("edges");
      if (edges && updateTask) {
        const reviewedByUser = updateTask.getLinkedRecord("reviewed_by_user");
        const reviewedAt = updateTask.getValue("reviewed_at");
        const flaggedAt = updateTask.getValue("flagged_at");
        const reviewedByUid = updateTask.getValue("reviewed_by_uid");
        edges.forEach((edge) => {
          const node = edge.getLinkedRecord("node");
          if (!node) return;
          node.setLinkedRecord(reviewedByUser, "reviewed_by_user");
          node.setValue(reviewedAt, "reviewed_at");
          node.setValue(flaggedAt, "flagged_at");
          node.setValue(reviewedByUid, "reviewed_by_uid");
        });
      }
    }
  };

  const gcReviews = taskReviewedData.map((review) => review.node.user?.name);

  const reviewCheck = async (e: any) => {
    if (e.target.checked) {
      setChecked(true);

      await insertTaskReview({
        variables: {
          id: taskId,
          _set: {
            reviewed_at: dayjs().format(),
            reviewed_by_uid: auth.currentUser?.uid,
            flagged_at: null,
            flagged_by_uid: null,
          },
          objects: [
            {
              task_id: taskId,
              edit_type: "Reviewed",
              edited_at: dayjs().format(),
              edited_by_uid: auth.currentUser?.uid,
            },
          ],
          taskReviewObjects: [
            {
              user_id: auth.currentUser?.uid,
              created_at: dayjs().format(),
              task_id: taskId,
              is_active: true,
            },
          ],
        },
        updater: handleInsertTaskReviewUpdater,
      }).catch((e) =>
        notification.error({
          message: "Task Review Failed",
          description: e.message,
        }),
      );
    } else {
      // if current user's id and reviewed_by_user.id matches then he can cancel the reviewed tick else he cannot
      setChecked(false);
      await removeTaskReview({
        variables: {
          _taskReviewWhere: {
            task_id: { _eq: taskId },
            user_id: { _eq: auth.currentUser?.uid },
            is_active: { _eq: true },
          },
          _set: { is_active: false },
        },
      }).catch((e) =>
        notification.error({
          message: "Error occured while removing review",
          description: e.message,
        }),
      );
    }
  };

  return (
    <>
      {jhaApprovalModalData && (
        <SendTaskForApprovalModal
          taskId={taskId}
          data={jhaApprovalModalData}
          isNotTurnersProject={isNotTurnersProject}
          onClose={() => setJHAApprovalModalData(undefined)}
          type="approval-request"
          visible={!!jhaApprovalModalData}
        />
      )}

      <div className="flex justify-end items-center text-18 font-normal">
        <span className="text-1.2">
          <div>
            <Popover
              content={gcReviews.map((gc, i) => (
                <div key={i}>{gc} </div>
              ))}
            >
              {checked ? `Reviewed` : `Log as Reviewed`}
            </Popover>
          </div>
        </span>

        <span className="ml-0.5 mr-1">
          <Checkbox onChange={reviewCheck} checked={checked} />
        </span>

        {(checked || gcReviews.length > 0) && (
          <span className="mr-0.5 ml-0.5 mb-0.5">
            <CustomButton
              onClick={async () => {
                const data = await getJHAApprovalData(
                  environment,
                  taskId,
                  subcontractorId,
                  projectId,
                );
                setJHAApprovalModalData(data);
              }}
              label="Request Subcontractor Approval"
              icon={IconMail}
              large
            />
          </span>
        )}
      </div>
    </>
  );
};

export default JhaGceReview;
