import React, { useEffect } from "react";
import logo from "src/common/assets/logo.png";
import { useCounter } from "usehooks-ts";

export interface MobileViewWrapperProps {
  children: React.ReactNode;
  project?: { name: string; gcName: string };
  footer?: React.ReactNode;
}

const MobileViewWrapper: React.FC<MobileViewWrapperProps> = (props) => {
  const { count, increment } = useCounter(0);
  useEffect(() => {
    if (!!props.footer) {
      window.addEventListener("resize", increment);
      return () => {
        window.removeEventListener("resize", increment);
      };
    }
  }, [increment, props.footer]);
  
  return (
    <div className="flex flex-col items-center w-full h-screen min-h-screen bg-suplementary-1">
      <div className="flex flex-col justify-center flex-1 w-full gap-1 px-1 pt-2 pb-1">
        <img src={logo} className={`flex w-12`} alt={`SiteForm logo`} />
        {props.project && (
          <div>
            {props.project.name}, {props.project.gcName}
          </div>
        )}
        <div className="flex-1 w-full">
          <div className="flex flex-col justify-center w-full h-full py-0.25">
            {props.children}
          </div>
        </div>
      </div>
      {!!props.footer && (
        <div
          key={count}
          className="sticky bottom-0 w-full flex flex-col gap-0.5 z-20 bg-white px-1 py-0.5 "
        >
          {props.footer}
        </div>
      )}
    </div>
  );
};

export default MobileViewWrapper;
