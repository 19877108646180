import React, { useContext, useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { Input } from "antd";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import validatePhoneNumber from "../../../../../../../../../common/functions/validatePhoneNumber";
import getDigitsString from "../../../../../../../../../common/functions/getDigitsString";
import PhoneNumberInput from "../../../../../../../../../common/components/phoneNumberInput/PhoneNumberInput";
import SiteFeatureStepLayout from "../../../../../../../../../common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerRegistrationStepsEmergencyContactProps
  extends SiteOrientationStepProps {}

const SiteOrientationInPersonWorkerRegistrationStepsEmergencyContact: React.FC<
  SiteOrientationInPersonWorkerRegistrationStepsEmergencyContactProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();
  const user = workerContext.orientee;
  let defaultVals:
    | undefined
    | {
        phone: string;
        name: string;
      } = undefined;
  if (
    user &&
    user.userType === "worker" &&
    user.emergencyPersonPhone &&
    user.emergencyPersonName
  ) {
    defaultVals = {
      phone: user.emergencyPersonPhone,
      name: user.emergencyPersonName,
    };
  }
  const [emergencyContact, setEmergencyContact] = useState(
    defaultVals?.phone ?? "",
  );
  const [emergencyPerson, setEmergencyPerson] = useState<string | undefined>(
    defaultVals?.name,
  );
  const phoneNumberIsValid = validatePhoneNumber(emergencyContact);
  const langStrings = useCurrentLangStrings();
  const onEnter = async () => {
    const phoneNumberDigits = getDigitsString(emergencyContact);
    workerContext.updateOrientee((u) =>
      u
        ? {
            ...u,
            emergencyPersonName: emergencyPerson,
            emergencyPersonPhone: phoneNumberDigits,
          }
        : null,
    );
    props.onNext();
  };

  const nameValid = !!emergencyPerson?.length;

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!phoneNumberIsValid || !nameValid}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.pleaseEnterYourEmergencyContact}
      >
        <div className={`flex flex-col gap-1`}>
          <div>
            <p className={`font-accent text-`}>{langStrings.strings.name}:</p>
            <Input
              placeholder={langStrings.strings.name}
              value={emergencyPerson}
              onChange={(e) => setEmergencyPerson(e.target.value)}
              onKeyDown={(e) => {
                const input = e.target as HTMLInputElement;
                input.value = input.value.replace(/(\b[a-z](?!\s))/g, (c) =>
                  c.toUpperCase(),
                );
                if (e.key === "Enter" && !e.shiftKey) {
                  //
                }
                // if (input.value) setNamePresent(() => input.value);
              }}
              enterKeyHint={"done"}
            />
          </div>
          <div className={``}>
            <p className={`font-accent text-1`}>
              {langStrings.strings.phoneNumber}:
            </p>
            <PhoneNumberInput
              value={emergencyContact}
              onChange={setEmergencyContact}
            />
          </div>
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerRegistrationStepsEmergencyContact;
