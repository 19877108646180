import React, { FC, useLayoutEffect, useRef } from "react";

export interface VideoTrackerState {
  duration: number;
  startPos: number;
  startTime: number;
  blocks: Array<WatchedBlock>;
}

export interface WatchedBlock {
  startPos: number; // time relative to start of video
  endPos: number;
}

export function initVideoTrackerState(duration: number): VideoTrackerState {
  return { duration, startPos: 0, startTime: 0, blocks: [] };
}

function removeOverlaps(blocks: Array<WatchedBlock>): Array<WatchedBlock> {
  blocks.sort((a, b) => a.startPos - b.startPos);
  // console.log('sorted blocks', blocks);
  let i = 0;
  let res = [];
  while (i < blocks.length) {
    let startPos = blocks[i].startPos;
    let endPos = blocks[i].endPos;
    i++;
    while (i < blocks.length && blocks[i].startPos <= endPos) {
      endPos = Math.max(endPos, blocks[i].endPos);
      i++;
    }
    res.push({ startPos, endPos });
  }
  return res;
}

export function stopWatch(
  info: VideoTrackerState,
  endPos: number,
  duration: number
): VideoTrackerState {
  if (info.startTime === 0) {
    console.error(
      "stopWatch must be called after startWatch: " + JSON.stringify(info)
    );
    return info;
  }
  //const passed = (Date.now() - info.startTime) / 1000.0;
  //const endPos = info.startPos + passed;
  const newBlock: WatchedBlock = {
    startPos: info.startPos,
    endPos,
  };
  return {
    ...info,
    duration,
    startTime: 0,
    startPos: endPos,
    blocks: removeOverlaps([...info.blocks, newBlock]),
  };
}

export function startWatch(
  info: VideoTrackerState,
  startPos: number,
  duration: number
): VideoTrackerState {
  return {
    ...info,
    duration,
    startTime: Date.now(),
    startPos: Math.max(0, startPos - 0.3),
  }; // allow 300 ms gap between blocks
}

export function getBigGap(
  info: VideoTrackerState,
  minLen: number
): [{ start: number; end: number }?] {
  const blocks = removeOverlaps(info.blocks);
  blocks.sort((a, b) => a.startPos - b.startPos);
  let pos = 0;
  for (const block of blocks) {
    if (block.startPos - pos > minLen) {
      return [{ start: pos, end: block.startPos }];
    }
    pos = block.endPos;
  }
  if (info.duration - pos < minLen) return [{ start: pos, end: info.duration }];
  return [];
}

export function isFullWatched(
  info: VideoTrackerState,
  minGapLen: number = 1.0
) {
  return getBigGap(info, minGapLen).length === 0;
  /*
  const blocks = removeOverlaps(info.blocks);
  let len = 0;
  for (const block of blocks) {
    len += block.endPos - block.startPos;
  }
  return len >= info.duration * 0.95;*/
}

function getBlock(
  color: string,
  startPos: number,
  endPos: number,
  duration: number
) {
  return (
    <div
      key={startPos}
      style={{
        position: "absolute",
        backgroundColor: "blue",
        border: "0px",
        bottom: "-0.5px",
        top: "0px",
        left: `${(startPos * 100) / duration}%`,
        width: `${((endPos - startPos) * 100) / duration}%`,
        margin: 0,
      }}
    ></div>
  );
}
let uniqueId = 1;

export const VideoTracker: FC<{ trackerState: VideoTrackerState }> = ({
  trackerState,
}) => {
  const { duration, startTime, startPos } = trackerState;
  // console.log('rener WatchInfo  startTIme = ', startTime);
  const items = trackerState.blocks.map((b) =>
    getBlock("blue", b.startPos, b.endPos, duration)
  );
  const currentDiv = useRef<HTMLDivElement | null>(null);
  let curBlock = null;
  const curBlockId = `id_${uniqueId++}`; // this shuuld be changed on each rerender
  if (startTime != 0) {
    const passed = (Date.now() - startTime) / 1000.0;
    const endPos = Math.min(startPos + passed, duration);
    // console.log(`passed = ${passed} endPos = ${endPos}`);
    curBlock = (
      <div
        key={curBlockId}
        ref={currentDiv}
        style={{
          position: "absolute",
          backgroundColor: "blue",
          top: "0px",
          bottom: "0px",
          width: "100%",
          transformOrigin: "0 0",
          transform: `scaleX(${(endPos - startPos) / duration})`,
          left: `${(startPos * 100) / duration}%`,
          //width:`${(endPos - startPos) * 100 / duration}%`,
          transition: `transform ${duration - endPos}s linear`,
          margin: 0,
        }}
      ></div>
    );
  }
  useLayoutEffect(() => {
    if (currentDiv.current) {
      /* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
      currentDiv.current.scrollTop; // this is needed to trigger force reflow in browser, so next change in transition will be animated
      currentDiv.current.style.transform = `scaleX(${
        (duration - startPos) / duration
      })`;

      // width animation cause constant reflow and animation is not so smooth, so do not use it (use transform animation)
      //                const width = (duration - startPos) * 100 / duration;
      //                currentDiv.current.style.width = `${width}%`;
    }
  });
  return (
    <div
      style={{
        position: "relative",
        boxSizing: "content-box",
        height: "5px",
        width: "100%",
        padding: "0px",
        outline: "1px gray solid",
      }}
    >
      {items}
      {curBlock}
    </div>
  );
};
