import React, { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomSignatureCanvas from "src/common/components/general/signature-canvas/CustomSignatureCanvas";
import { useCreateVisitorMutation } from "src/common/types/generated/apollo/graphQLTypes";

import VisitorOrientationOrienteeContext from "src/domain-features/sitesafety/entryRoutes/gcProjectReports/routes/visitor/utils/visitorOrientationOrienteeContext";
import { Button } from "antd";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLangStrings } from "../../../../../../../../utility-features/i18n/context/languageHooks";
import useSignaturePad from "../../../../../../../../common/components/general/signature-canvas/useSignaturePad";
import NotifyUserException from "../../../../../../../../utility-features/error-handling/NotifyUserException";
import useSiteVisitor from "../../utils/siteVisitorHook";

interface VisitorOrientationStepsSignatureProps
  extends SiteOrientationStepProps {}

const VisitoOrientationStepsSignature: FC<
  VisitorOrientationStepsSignatureProps
> = (props) => {
  const visitorContext = useSiteVisitor();

  const langStrings = useCurrentLangStrings();
  const { signaturePadProps, isEmpty, uploadSignature } = useSignaturePad();

  const [createVisitor] = useCreateVisitorMutation();

  const navigate = useNavigate();
  const onSignButtonClick = async (signatureImageUrl: string) => {
    // const signatureImageUrl = await signatureCanvas.uploadSignature();
    if (signatureImageUrl) {
      visitorContext.updateVisitor((visitor) =>
        visitor
          ? {
              ...visitor,
              signatureImageUrl,
            }
          : visitor,
      );
      const visitor = visitorContext.visitor;
      if (!visitor) throw new Error("visitor not found");
      if (!visitor.name) throw new Error("Name not found");
      if (!visitor.email) throw new Error("Email not found");
      if (!visitor.hours) throw new Error("hours not found");
      await createVisitor({
        variables: {
          input: {
            name: visitor.name,
            email: visitor.email,
            hours: visitor.hours,
            generalContractorId: visitor.generalContractorId,
            subcontractorId: visitor.subcontractorId,
            OACCompanyId: visitor.oacCompanyId,
            projectId: visitorContext.projectId,
            visitorCompanyId: visitor.visitorCompanyId,
            visitorCompanyName: visitor.visitorCompanyName,
            signatureUrl: signatureImageUrl,
            comment: visitor.comment?.trim() || undefined,
          },
        },
      });

      navigate("success");
      props.onNext();
    } else {
      throw new NotifyUserException("Signature not Uploaded");
    }
  };
  const [loading, setLoading] = useState(false);

  return (
    <SiteFeatureStepLayout
      nextButtonDisabled={isEmpty}
      onBackButtonClick={props.onBack}
    >
      <div className={`flex min-h-full flex-col justify-center `}>
        <div className={`flex flex-col gap-1`}>
          <p className={`text-2`}>Sign below</p>
          <div className="flex flex-col gap-1">
            <p className="text-1.5">
              {visitorContext.visitor?.name + ", please sign"}
            </p>
            <div className="flex flex-col gap-0.5">
              <CustomSignatureCanvas {...signaturePadProps} />
            </div>
            {onSignButtonClick ? (
              <Button
                block
                disabled={isEmpty}
                loading={loading}
                size="large"
                type={`primary`}
                onClick={async () => {
                  setLoading(true);
                  try {
                    const imageUrl = await uploadSignature();
                    if (imageUrl && onSignButtonClick) {
                      await onSignButtonClick(imageUrl);
                    }
                    setLoading(false);
                  } catch (err) {
                    setLoading(false);
                  }
                }}
              >
                {langStrings.strings.save}
              </Button>
            ) : (
              <></>
            )}
            <p className="text-0.75 text-static-secondary">
              By signing you agree to SiteForm’s{" "}
              <span className="text-interactive-primary">
                <a
                  target="_blank"
                  href="/https://www.termsfeed.com/privacy-policy/4797f669d31b548f81ec1bfb308928a1"
                >
                  {langStrings.strings.privacyPolicy}
                </a>
              </span>{" "}
              and{" "}
              <span className="text-interactive-primary">
                <a
                  target="blank"
                  href="/https://www.termsfeed.com/terms-conditions/f73fc5afe6a821990e96daebc0b83d39"
                >
                  {langStrings.strings.termsOfService}
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </SiteFeatureStepLayout>
  );
};

export default VisitoOrientationStepsSignature;
