import React from "react";
import { InspectionInstanceType } from "../tables/InspectionInstanceTable";
import { Dropdown, message, notification } from "antd";
import { IconCopy, IconDotsVertical, IconTrash } from "@tabler/icons";
import { WarningFilled } from "@ant-design/icons";
import row from "antd/lib/row";
import useUpdateInspectionInstance from "../../utils/useUpdateInspectionInstance";
import { ConnectionHandler } from "relay-runtime";
import useDuplicateInspectionInstance from "../../utils/useDuplicateInspectionInstance";
import inspectionOptionsDeleteUpdater from "../../utils/inspectionOptionsDeleteUpdater";
import dayjs from "dayjs";
import Button from "../../../../common/components/general/Button";
import BPopconfirm from "../../../../common/components/dialogs/BPopconfirm";

const InspectionInstanceDropdown: React.FC<{
  data: InspectionInstanceType;
  projectId: string;
  onClose: () => void;
  loading: boolean;
  setLoading: (t: boolean) => void;
  onOpenNew: (data: InspectionInstanceType) => void;
}> = ({ data, projectId, onOpenNew, setLoading, onClose, loading }) => {
  const [duplicate, isDuplicating] = useDuplicateInspectionInstance(projectId);
  const [updateInspection, isUpdating] = useUpdateInspectionInstance();
  return (
    <Dropdown
      placement={"bottomRight"}
      menu={{
        items: [
          {
            key: "delete",
            label: (
              <BPopconfirm
                cancelText={"No"}
                icon={<WarningFilled color="red" />}
                onConfirm={async () => {
                  if (data.pk) {
                    await updateInspection({
                      variables: {
                        id: data.pk,
                        _set: { deleted_at: dayjs().toISOString() },
                      },
                      optimisticResponse: {
                        update_inspection_instance_by_pk: {
                          id: data.id,
                          pk: data.pk,
                          deleted_at: dayjs().toISOString(),
                        },
                      },
                      updater: (store) => {
                        const conn = ConnectionHandler.getConnection(
                          store.getRoot(),
                          "InspectionInstanceTable_inspection_instance_connection",
                        );
                        inspectionOptionsDeleteUpdater(data.id, store);
                        if (conn) {
                          const edges = conn.getLinkedRecords("edges");
                          if (edges) {
                            const newEdges = edges.filter((edge) => {
                              const node = edge.getLinkedRecord("node");
                              return node?.getValue("id") !== data.id;
                            });
                            conn.setLinkedRecords(newEdges, "edges");
                          }
                        }
                      },
                    })
                      .then(() => {
                        message.success("Deleted Inpsection Successfully");
                        onClose();
                      })
                      .catch((e) =>
                        notification.error({
                          message: "Couldn't delete QR: " + row.name,
                          description: e.message,
                        }),
                      );
                  }
                }}
                placement="topRight"
                title={
                  <div className="w-24">
                    <div className="text-semantic-negative font-accent">
                      Delete this Inspection
                    </div>
                    <div>
                      Do you want to delete this Inspection? deleting this means
                      you agree to remove this Inspection from all Inspection
                      QRs present on your project. <br />
                      Are you sure?
                    </div>
                  </div>
                }
              >
                <Button label="" delete icon={IconTrash} onClick={() => {}} />
              </BPopconfirm>
            ),
          },
          {
            key: "duplicate",
            label: (
              <BPopconfirm
                cancelText={"No"}
                icon={<WarningFilled color="red" />}
                onConfirm={async (e) => {
                  //delete worker
                  e?.stopPropagation();
                  setLoading(true);
                  try {
                    const data2 = await duplicate(data);
                    message.success("Copied Inspection");
                    if (
                      data2.insert_inspection_instance &&
                      data2.insert_inspection_instance.returning[0]
                    )
                      onOpenNew(data2.insert_inspection_instance.returning[0]);
                  } catch (e) {
                    console.log(e);
                    notification.error({
                      message: "Couldn't copy inspection: " + data.name.en,
                      description:
                        e instanceof Error ? e.message : JSON.stringify(e),
                    });
                  }
                  setLoading(false);
                }}
                placement="topRight"
                title={
                  <div className="w-24">
                    <div>
                      Do you want to create another similar inspection to this?{" "}
                      <br />
                      Are you sure?
                    </div>
                  </div>
                }
              >
                <Button
                  green
                  icon={IconCopy}
                  loading={loading}
                  onClick={() => {}}
                  label=""
                />
              </BPopconfirm>
            ),
          },
        ],
      }}
      trigger={["click"]}
    >
      <Button icon={IconDotsVertical} onClick={() => console.log("")} />
    </Dropdown>
  );
};
export default InspectionInstanceDropdown;
