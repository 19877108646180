import { Alert, message, notification } from "antd";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useMutation } from "react-relay/hooks";
import insertCertificatesToVerifyMutate from "src/common/api/relay/mutations/InsertCertificatesToVerify";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import useProjectWorkerWorkerId from "src/common/hooks/useProjectWorkerWorkerId";
import { auth } from "src/common/functions/firebase";
import uploadFiles from "src/common/functions/uploadFiles";
import { InsertCertificatesToVerifyMutation } from "src/common/types/generated/relay/InsertCertificatesToVerifyMutation.graphql";
import FModal, { FModalRef } from "./FModal";

import useLangStrings from "../../../utility-features/i18n/context/languageHooks";

type FormValues = {
  workerId: string;
  certificationFrontImage: Array<{ originFileObj: File }>;
  certificationBackImage: Array<{ originFileObj: File }>;
};

// TODO enforce only 1 of 2 ids
export type AddWorkerCertModalDataProps = {
  byWorker: boolean;
  workerName?: string;
  workerId?: string;
  lang?: string;
  projectId?: string;
  projectWorkerId?: string;
  setProcessingOrder?: () => void;
};

export type AddWorkerCertModalProps = {
  onInserted: () => void;
} & AddWorkerCertModalDataProps;

export type AddWorkerCertModalRef = FModalRef<FormValues> | null;
const AddWorkerCertModal = forwardRef<
  AddWorkerCertModalRef,
  AddWorkerCertModalProps
>(
  (
    {
      workerId,
      projectWorkerId,
      byWorker,
      lang = "en",
      projectId,
      workerName,
      onInserted,
      setProcessingOrder,
    },
    ref
  ) => {
    const modal = useRef<FModalRef<FormValues>>(null);
    const [inserting, setInserting] = useState(false);
    const [insertCert] = useMutation<InsertCertificatesToVerifyMutation>(
      insertCertificatesToVerifyMutate
    );

    useImperativeHandle<AddWorkerCertModalRef, AddWorkerCertModalRef>(
      ref,
      () => modal.current
    );
    const ModalCurrentByProjectWorkerId = (props: {
      projectWorkerId: string;
    }) => {
      const insertWorkerId = useProjectWorkerWorkerId(props.projectWorkerId);
      if (insertWorkerId)
        modal.current?.form.setFieldsValue({ workerId: insertWorkerId });
      else modal.current?.form.setFieldsValue({ workerId: undefined });
      return null;
    };
    const langStrings = useLangStrings(lang);

    const ModalCurrentByWorkerId = (props: {
      workerId: string | undefined;
    }) => {
      modal.current?.form.setFieldsValue({ workerId: props.workerId });
      return null;
    };
    const FormContent = withCustomSuspense(() => {
      return (
        <>
          {/* workerName not given when pseudo_worker is uploading the images */}
          {!byWorker && (
            <Alert
              message={
                <p>
                  {langStrings.strings.dragAndDropCertFor(workerName ?? "")}
                </p>
              }
              type="info"
              showIcon
            />
          )}
          <br />
          <FModal.Text
            // setFieldsValue doesn't work without element?
            name="workerId"
            style={{ display: "none" }}
          />
          <FModal.SingleImgDragUpload
            required
            lang={lang}
            requiredMessage="Add Front Image"
            label={langStrings.strings.frontImage}
            name="certificationFrontImage"
          />
          <FModal.SingleImgDragUpload
            label={langStrings.strings.backImage}
            lang={lang}
            name="certificationBackImage"
          />
        </>
      );
    });

    const reset = () => {
      setInserting(false);
      modal.current?.form.resetFields();
      modal.current?.close();
    };

    return (
      <FModal
        ref={modal}
        title={
          byWorker
            ? langStrings.strings.addYourCertsAndTrainings
            : "Add a certification to worker"
        }
        okText={langStrings.strings.add}
        confirmLoading={inserting}
        form={{ initialValues: { doesExpire: false } }}
        onCancel={() => {
          reset();
        }}
        onOk={() => {
          const form = modal.current?.form;
          if (!form) return;
          form
            .validateFields()
            .then(async (v) => {
              setInserting(true);
              const frontFile = await uploadFiles(
                v.certificationFrontImage.map((o) => o.originFileObj)
              );
              const backFile = v.certificationBackImage
                ? await uploadFiles(
                    v.certificationBackImage.map((o) => o.originFileObj)
                  )
                : undefined;
              // TODO promisify
              const fileUrl = frontFile[0]?.url;
              if (v.workerId && fileUrl) {
                insertCert({
                  variables: {
                    objects: [
                      {
                        document: "certificate",
                        worker_id: v.workerId,
                        uploaded_by_uid: auth.currentUser?.uid,
                        project_id: projectId,
                        front_image: {
                          data: {
                            url: fileUrl,
                            description: "Front Image",
                            created_by_user_id: auth.currentUser?.uid,
                          },
                        },
                        back_image: !backFile?.[0]
                          ? null
                          : {
                              data: {
                                url: backFile[0].url,
                                description: "Back Image",
                                created_by_user_id: auth.currentUser?.uid,
                              },
                            },
                      },
                    ],
                  },
                  onCompleted: () => {
                    reset();
                    modal.current?.close();
                    message.success("Added certification");
                    onInserted();
                    setProcessingOrder && setProcessingOrder();
                    setInserting(false);
                  },
                  onError: (e) => {
                    setInserting(false);
                    notification.error({
                      message: "Add certification error",
                      description: e.message,
                      duration: null,
                    });
                    setProcessingOrder && setProcessingOrder();
                  },
                });
              } else {
                message.error(
                  "Try refreshing the page or go back and select this worker again to add Certificate test"
                );
                reset();
                setInserting(false);
                modal.current?.close();
              }
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        {projectWorkerId ? (
          <ModalCurrentByProjectWorkerId {...{ projectWorkerId }} />
        ) : (
          <ModalCurrentByWorkerId {...{ workerId }} />
        )}
        <FormContent />
      </FModal>
    );
  }
);

export default AddWorkerCertModal;
