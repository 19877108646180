/**
 * @generated SignedSource<<5ff8394be12a40614c54a535b48d18b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PartialResultContentQuery$variables = {
  orientationIds: ReadonlyArray<string>;
  workerId: string;
};
export type PartialResultContentQuery$data = {
  readonly orientation_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly orientation_results: ReadonlyArray<{
          readonly completed_at: string | null | undefined;
          readonly created_at: string;
          readonly status: string;
        }>;
        readonly slides: ReadonlyArray<{
          readonly viewed_by: ReadonlyArray<{
            readonly created_at: string;
            readonly id: string;
          }>;
        }>;
      };
    }>;
  };
};
export type PartialResultContentQuery = {
  response: PartialResultContentQuery$data;
  variables: PartialResultContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orientationIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workerId"
},
v2 = {
  "order": "asc"
},
v3 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": [
      (v2/*: any*/),
      {
        "name": "asc"
      }
    ]
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_in",
            "variableName": "orientationIds"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v4 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "_eq",
      "variableName": "workerId"
    }
  ],
  "kind": "ObjectValue",
  "name": "user_id"
},
v5 = [
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "_or",
        "value": [
          {
            "status": {
              "_eq": "completed"
            }
          },
          {
            "completed_at": {
              "_is_null": false
            }
          }
        ]
      },
      (v4/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed_at",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "_is_null": true
},
v11 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": (v2/*: any*/)
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "archived_at": (v10/*: any*/),
      "deleted_at": (v10/*: any*/)
    }
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "limit",
      "value": 1
    },
    {
      "kind": "Literal",
      "name": "order_by",
      "value": {
        "created_at": "desc"
      }
    },
    {
      "fields": [
        (v4/*: any*/)
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "orientation_viewed_slide",
  "kind": "LinkedField",
  "name": "viewed_by",
  "plural": true,
  "selections": [
    (v12/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PartialResultContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "orientationConnection",
        "kind": "LinkedField",
        "name": "orientation_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "orientationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "orientation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v5/*: any*/),
                    "concreteType": "orientation_result",
                    "kind": "LinkedField",
                    "name": "orientation_results",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  {
                    "alias": "id",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id_pk",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "concreteType": "orientation_slide",
                    "kind": "LinkedField",
                    "name": "slides",
                    "plural": true,
                    "selections": [
                      (v13/*: any*/)
                    ],
                    "storageKey": "slides(order_by:{\"order\":\"asc\"},where:{\"archived_at\":{\"_is_null\":true},\"deleted_at\":{\"_is_null\":true}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PartialResultContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "orientationConnection",
        "kind": "LinkedField",
        "name": "orientation_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "orientationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "orientation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v5/*: any*/),
                    "concreteType": "orientation_result",
                    "kind": "LinkedField",
                    "name": "orientation_results",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "concreteType": "orientation_slide",
                    "kind": "LinkedField",
                    "name": "slides",
                    "plural": true,
                    "selections": [
                      (v13/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": "slides(order_by:{\"order\":\"asc\"},where:{\"archived_at\":{\"_is_null\":true},\"deleted_at\":{\"_is_null\":true}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b3395d12afb63974274696af17e5513a",
    "id": null,
    "metadata": {},
    "name": "PartialResultContentQuery",
    "operationKind": "query",
    "text": "query PartialResultContentQuery(\n  $workerId: uuid!\n  $orientationIds: [uuid!]!\n) {\n  orientation_connection(order_by: [{order: asc}, {name: asc}], where: {id: {_in: $orientationIds}}) {\n    edges {\n      node {\n        orientation_results(where: {user_id: {_eq: $workerId}, _or: [{status: {_eq: \"completed\"}}, {completed_at: {_is_null: false}}]}) {\n          completed_at\n          created_at\n          status\n          id\n        }\n        name\n        id\n        slides(where: {deleted_at: {_is_null: true}, archived_at: {_is_null: true}}, order_by: {order: asc}) {\n          viewed_by(where: {user_id: {_eq: $workerId}}, order_by: {created_at: desc}, limit: 1) {\n            id\n            created_at\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5199cfa64cb1e4d567be35bde2d2c710";

export default node;
