import React from "react";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  match: RegExp;
}

const LayoutBackButton: React.FC<Props> = ({ match }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  if (currentPath.match(match)) {
    return (
      <Button
        icon={<ArrowLeftOutlined />}
        onClick={() => {
          navigate(-1);
        }}
        style={{ marginBottom: 24 }}
      >
        Back
      </Button>
    );
  } else {
    return null;
  }
};

export default LayoutBackButton;
