import React from "react";
import SiteOrientationGcQrCode from "./SiteOrientationGcQrCode";

export interface SiteOrientationGcQrCodeInPersonProps {
  projectId: string;
}

const SiteOrientationGcQrCodeRemote: React.FC<
  SiteOrientationGcQrCodeInPersonProps
> = (props) => {
  return (
    <SiteOrientationGcQrCode
      title={`Registration & Orientation Slides`}
      body={
        <ul className="list-disc">
          <li>
            This QR code allows new workers to register for your project. Do you
            have slides or a video? If yes, they will watch them on their device
            after they register.
          </li>
          <li>
            If you do not have slides, workers can pre-register for your
            in-person orientation with this QR code. This will ONLY register
            workers, it will not provide any slides
          </li>
          <li>
            All new workers need to register. To assist, the QR code can be
            shared with your trade partners and foremen. It can be posted on
            your job board or jobsite office.
          </li>
        </ul>
      }
      qrCode={{
        destinationUrl: `${document.location.origin}/orientations/${props.projectId}/slides`,
        alwaysVisible: true,
        downloadableFileName: "Registration_Orientation_Slides",
      }}
    />
  );
};

export default SiteOrientationGcQrCodeRemote;
