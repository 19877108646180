import React, { useState } from "react";
import capitalize from "src/common/functions/capitalize";
import Icon, { IconType } from "./Icon";
import Popover from "./Popover";

type SwitcherOption = {
  label?: string;
  icon: IconType;
};

type OptionIndexType = 0 | 1;

export interface SwitcherProps {
  initialSelectedOptionIndex?: OptionIndexType;
  optionIndex?: number;
  options: [SwitcherOption, SwitcherOption];
  onChange: (optionIndex: OptionIndexType) => void;
  loading?: boolean;
  disabled?: boolean;
  hint?: string | JSX.Element;
}

const Switcher: React.FunctionComponent<SwitcherProps> = (props) => {
  const [seletedOptionIndex, setSelectedOptionIndex] =
    useState<OptionIndexType>(props.initialSelectedOptionIndex ?? 0);
  // console.log("Switch is rendered");

  const index = props.optionIndex ?? seletedOptionIndex;

  const handleClick = () => {
    if (typeof props.optionIndex === "number") {
      const newIndex = props.optionIndex ? 0 : 1;
      props.onChange(newIndex);
    } else {
      setSelectedOptionIndex((prevState) => {
        let newState: OptionIndexType;
        if (prevState) {
          newState = 0;
        } else {
          newState = 1;
        }
        props.onChange(newState);
        return newState;
      });
    }
  };

  const element = (
    <span
      onClick={!(props.disabled || props.loading) ? handleClick : undefined}
      className={`relative select-none px-0.5 items-center inline-flex flex-row rounded rounded-2 transition-all bg-suplementary-1 group ${
        props.loading || props.disabled ? "gap-0.5" : "gap-0.25"
      } ${
        props.loading
          ? "cursor-wait"
          : props.disabled
          ? "cursor-not-allowed"
          : "cursor-pointer"
      }`}
    >
      <Icon icon={props.options[0].icon} color={`interactive`} size={`small`} />
      <div
        style={{
          left:
            !props.loading && !props.disabled
              ? `${(index - 0.5) * 1.5}rem`
              : "0rem",
        }}
        className={`relative grid grid-cols-1  grid-rows-1 transition-all bg-white border rounded-2 border-interactive-secondary ${
          props.loading || props.disabled
            ? "-mx-2 px-1"
            : `group-hover:border-interactive-primary px-0.25 ${
                index === 0 ? "-ml-1 -mr-0.75" : "-mr-1 -ml-0.75"
              }`
        }`}
      >
        {props.options.map(({ label }, i) => (
          <div
            key={label}
            className={`relative flex flex-row gap-0.5 justify-center items-center px-0.5 py-0.25 col-start-1 col-end-2 row-start-1 row-end-2 transition-all ${
              i === 1 ? "flex-row-reverse" : ""
            } ${
              i !== index
                ? `opacity-0 ${i === 0 ? "-left-3" : "left-3"}`
                : "opacity-100 left-0"
            }`}
          >
            <Icon
              icon={props.options[i].icon}
              color={!props.disabled ? `interactive` : "static"}
              size={`small`}
            />
            {label && (
              <span key={i} className={`text-center text-0.75`}>
                {capitalize(label)}
              </span>
            )}
          </div>
        ))}
      </div>
      <Icon
        icon={props.options[1].icon}
        color={!props.disabled ? `interactive` : "static"}
        size={`small`}
      />
    </span>
  );

  return !!props.hint ? (
    <Popover delay content={<p className="max-w-32">{props.hint}</p>}>
      {element}
    </Popover>
  ) : (
    element
  );
};

export default Switcher;
