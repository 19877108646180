import { IconDownload, IconInfoCircle, IconSettings } from "@tabler/icons";
import {
  Button,
  DatePicker,
  Form,
  message,
  Modal,
  notification,
  Select,
} from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import Icon from "src/common/components/general/Icon";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import {
  useGeneratePayrollComparisonReportMutation,
  useManPowerReportDownloadMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { GCProjectReportsDownload_updateProjects_Mutation } from "src/common/types/generated/relay/GCProjectReportsDownload_updateProjects_Mutation.graphql";
import { GCProjectReportsDownloadQuery } from "src/common/types/generated/relay/GCProjectReportsDownloadQuery.graphql";
import MorningManPowerReportAuto from "../MorningManPowerReportDownloadAuto";
import MorningManPowerEmail from "src/common/components/MorningManPowerReportEmail";
import { SwitchWithText } from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/settings/components/SiteOrientationSettingSwitchCards";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import GetFullID from "src/common/functions/GetFullId";

interface GCProjectReportDownloadProps {
  projectId: string;
}

type RangeValue = [Dayjs | null, Dayjs | null] | null;
const GCProjectReportDownload: React.FunctionComponent<
  GCProjectReportDownloadProps
> = ({ projectId }) => {
  const [form] = Form.useForm();
  const data = useLazyLoadQuery<GCProjectReportsDownloadQuery>(
    graphql`
      query GCProjectReportsDownloadQuery($projectId: uuid!) {
        project_subcontractor_connection(
          where: { project_id: { _eq: $projectId } }
        ) {
          edges {
            node {
              subcontractor_id
              subcontractor {
                name
              }
            }
          }
        }
        email_project_user_connection(
          first: 10000
          where: {
            project_id: { _eq: $projectId }
            type: { _eq: "morning_man_power_report" }
          }
        )
          @connection(
            key: "MorningManPowerReport_email_project_user_connection"
            filters: []
          ) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              user {
                id
                pk: id @__clientField(handle: "pk")
                name
                email
              }
            }
          }
        }
        project_employee_connection(
          where: { project: { id: { _eq: $projectId } } }
          order_by: { employee: { user: { name: asc } } }
        ) {
          edges {
            node {
              employee {
                user {
                  id
                  pk: id @__clientField(handle: "pk")
                  name
                  email
                }
              }
            }
          }
        }
        project_connection(where: { id: { _eq: $projectId } }) {
          edges {
            node {
              id
              morning_man_power_report_email_time
              timezone
              project_setting {
                id
                send_weekly_email
              }
            }
          }
        }
      }
    `,
    {
      projectId,
    },
  );

  const [start, setStart] = useState<dayjs.Dayjs | null>(
    dayjs().startOf("month"),
  );
  const [form2] = Form.useForm();
  const [end, setEnd] = useState<dayjs.Dayjs | null>(dayjs());
  const [loading, setLoading] = useState<boolean>(false);
  const [dates, setDates] = useState<RangeValue>(null);
  const [date, setDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [hackValue, setHackValue] = useState<RangeValue>(null);
  const [isExpanded, setIsExpanded] = useState<Boolean>(false);
  const today = dayjs();
  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }

    if (current > today) {
      return true;
    }
    const tooLate = dates[0] && current.diff(dates[0], "months") >= 1;
    const tooEarly = dates[1] && dates[1].diff(current, "months") >= 1;
    return !!tooEarly || !!tooLate;
  };
  const [updateProject] =
    useAsyncMutation<GCProjectReportsDownload_updateProjects_Mutation>(graphql`
      mutation GCProjectReportsDownload_updateProjects_Mutation(
        $id: uuid!
        $_set: project_setting_set_input!
      ) {
        update_project_setting_by_pk(
          pk_columns: { project_id: $id }
          _set: $_set
        ) {
          id
          send_weekly_email
        }
      }
    `);
  const defDate = dayjs(undefined);
  const onOpenChange = (open: boolean) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
      setDate(null);
    } else {
      setHackValue(null);
    }
  };
  const subcontractorData = data.project_subcontractor_connection.edges;
  const subIds = new Array<string>();
  subcontractorData.map((t) => {
    subIds.push(t.node.subcontractor_id);
  });
  const selectedUsers = data.email_project_user_connection.edges;
  const projectEmployees = data.project_employee_connection.edges;
  const projectData = data.project_connection.edges[0].node;
  const [generatePayrollComparisonReport] =
    useGeneratePayrollComparisonReportMutation();
  const [manPowerReportDownload, { loading: manPowerReportLoading }] =
    useManPowerReportDownloadMutation();
  const [openSettings, setOpenSettings] = useState(false);
  return (
    <div>
      <Modal
        open={openSettings}
        onCancel={() => setOpenSettings(false)}
        footer={() => (
          <Button onClick={() => setOpenSettings(false)}>Close</Button>
        )}
      >
        <SwitchWithText
          //disabled={!has_auth_data}
          text="Turn ON weekly project email"
          onChange={(checked) => {
            updateProject({
              variables: {
                id: projectId,
                _set: { send_weekly_email: checked },
              },
              optimisticResponse: {
                update_project_setting_by_pk: {
                  id: GetFullID("project_setting", projectId),
                  send_weekly_email: checked,
                },
              },
            })
              .then(() => message.success(checked ? "ON" : "OFF"))
              .catch((e) => {
                notification.error({
                  message: "Error: Please try again",
                  description: e?.message,
                });
              });
          }}
          checked={!!projectData.project_setting?.send_weekly_email}
        />
      </Modal>

      <div className="-mx-0.25" style={{ marginBottom: "20px" }}></div>

      <div className="relative flex flex-col items-start justify-start w-full gap-2 rounded">
        <h1 className="text-1.5">Morning ManPower Report</h1>
        <div className="absolute right-2">
          <div
            className="opacity-20"
            onClick={() => {
              setOpenSettings(true);
            }}
          >
            <Icon color="static" icon={IconSettings} size="small" />
          </div>
        </div>
      </div>
      <MorningManPowerEmail />
      <br />
      <MorningManPowerReportAuto
        defaultUsers={selectedUsers.map((user) => {
          return {
            userId: user.node.user?.pk ?? "No user id",
            emailProjectUserId: user.node.pk,
            name: user.node.user?.name,
          };
        })}
        projectEmployee={projectEmployees.map((employee) => ({
          value: employee.node.employee.user.pk,
          key: employee.node.employee.user.pk,
          label: employee.node.employee.user.name ?? "UNKNOWN",
        }))}
        projectData={projectData.morning_man_power_report_email_time}
      />
      <h2 className="text-1.25">Download Report</h2>
      <Form
        form={form2}
        onFinish={async (values) => {
          let subIdsToSend = new Array<string>();
          if (values.subId === "all") subIdsToSend = [...subIds];
          else {
            subIdsToSend.push(values.subId);
          }
          if (values.downloadDate) {
            await manPowerReportDownload({
              variables: {
                input: {
                  projectId,
                  subcontractorIds: subIdsToSend,
                  onsiteDate: values.downloadDate,
                },
              },
            })
              .then((d) => {
                if (d.data?.manPowerReportDownload)
                  downloadFromUrl(d.data?.manPowerReportDownload);
              })
              .catch((error) => {
                message.error("error occured, Please try again", error);
              });
          }
        }}
        initialValues={{
          subId: "all",
          downloadDate: dayjs(),
        }}
      >
        <Form.Item
          name="downloadDate"
          label={
            <div className="text-1">Select a Date to download Report.</div>
          }
          rules={[
            { required: true, message: "Select a Date to Download Report" },
          ]}
        >
          <DatePicker
            className="min-w-12"
            value={date}
            disabledDate={disabledDate}
            onChange={(e) => {
              setDate(e);
            }}
            onOpenChange={onOpenChange}
          />
        </Form.Item>
        <Form.Item
          name="subId"
          label={
            <div className="text-1">
              Select Subcontractor to Download Report
            </div>
          }
        >
          <Select className="w-full">
            <Select.Option key="all" value="all">
              All
            </Select.Option>
            {(subcontractorData || []).map((t) => (
              <Select.Option
                key={t.node.subcontractor_id}
                value={t.node.subcontractor_id}
              >
                {t.node.subcontractor.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <div className="ml-2" style={{ marginBottom: "20px" }}>
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            className="rounded-2 font-accent flex"
            loading={manPowerReportLoading}
          >
            Download Report
            <div className="ml-0.5">
              <Icon icon={IconDownload} color="white" />
            </div>
          </Button>
        </div>
      </Form>
      {/* )} */}
      <div className="flex items-start justify-between mt-2">
        <div className="text-1.25 font-accent">
          Worker Hour Count and Days Onsite
        </div>
        <Icon
          icon={IconInfoCircle}
          hoverContent={{
            content: (
              <div>
                This report shows the subcontractors and their workers that were
                <br />
                onsite for a selected period of time. You can use this to
                compare
                <br />
                to certified payroll reports or see when a subcontractor did not
                <br />
                complete a daily report. The # shown on each day is the number
                of
                <br />
                hours. "Yes" indicates the worker was onsite but was not
                included
                <br />
                in a daily report or the daily report was not submitted.
              </div>
            ),
          }}
        />
      </div>
      <Form
        form={form}
        onFinish={async (values) => {
          if (
            values.onsiteDateRange &&
            values.onsiteDateRange[0] &&
            values.onsiteDateRange[1]
          ) {
            setLoading(true);
            await generatePayrollComparisonReport({
              variables: {
                input: {
                  projectId,
                  onsiteStartDate: values.onsiteDateRange[0].format(),
                  onsiteEndDate: values.onsiteDateRange[1].format(),
                  subcontractorId:
                    values.subcontractorId === "all"
                      ? undefined
                      : values.subcontractorId,
                },
              },
            })
              .then((d) => {
                if (d.data?.generatePayrollComparisonReport)
                  downloadFromUrl(d.data?.generatePayrollComparisonReport);
              })
              .catch(console.log);
            setLoading(false);
          }
        }}
        initialValues={{
          subcontractorId: "all",
          onsiteDateRange: [dayjs().startOf("month"), dayjs()],
        }}
      >
        <Form.Item
          name="onsiteDateRange"
          label={<div className="text-1">Select Onsite Date Range</div>}
          rules={[
            { required: true, message: "Select a date range for Onsite" },
          ]}
        >
          <DatePicker.RangePicker
            className="min-w-12"
            value={hackValue || [start, end]}
            disabledDate={disabledDate}
            onCalendarChange={(val) => setDates(val)}
            onChange={(val) => {
              const start = val ? val[0] : null;
              const end = val ? val[1] : null;
              setStart(start);
              setEnd(end);
            }}
            presets={[
              {
                label: "This week",
                value: [dayjs().startOf("week"), dayjs().endOf("week")],
              },
              {
                label: "Last week",
                value: [
                  dayjs().subtract(1, "week").startOf("week"),
                  dayjs().subtract(1, "week").endOf("week"),
                ],
              },
              {
                label: "This month",
                value: [dayjs().startOf("month"), dayjs().endOf("month")],
              },
              {
                label: "Last month",
                value: [
                  dayjs().subtract(1, "month").startOf("month"),
                  dayjs().subtract(1, "month").endOf("month"),
                ],
              },
            ]}
            onOpenChange={onOpenChange}
          />
        </Form.Item>
        <Form.Item
          name="subcontractorId"
          label={
            <div className="text-1">Select Companies and Trade Partners</div>
          }
        >
          <Select className="w-full">
            <Select.Option key={0} value={"all"}>
              ALL Subs
            </Select.Option>
            {subcontractorData?.map((t) => (
              <Select.Option
                key={t.node.subcontractor_id}
                value={t.node.subcontractor_id}
              >
                {t.node.subcontractor.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div className="ml-2 mb-2">
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            className="rounded-2 font-accent flex"
            loading={loading}
          >
            Download Report
            <Icon icon={IconDownload} color="white" />
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default withCustomSuspense(GCProjectReportDownload);
