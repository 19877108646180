import React, { FC, useRef, useState } from "react";
import FModal, { FModalRef } from "../dialogs/FModal";
import Icon from "../general/Icon";
import {
  IconArrowRight,
  IconUser,
  IconUserCheck,
  IconUserCircle,
  IconUserPlus,
} from "@tabler/icons";
import ConvertEmployeeToWorker from "./ConvertEmployeeToWorkerModal";
import ConvertEmployeeToSubAdmin from "./ConvertEmployeeToSubAdminModal";
interface PerformEmployeeChangeFunctionModalProps {
  visible: boolean;
  data: Array<{
    name: string;
    uid: string;
    email?: string;
    created_password: boolean;
    dataId?: string;
    phoneNumber?: string;
  }>;
  subs?: Array<{
    name: string;
    id: string;
  }>;
  onClose: () => void;
  // refetch: () => void;
}
interface Values {
  function: "toWorker" | "toSubAdmin";
}
const PerformEmployeeChangeFunction: FC<
  PerformEmployeeChangeFunctionModalProps
> = ({ visible, onClose, data, subs }) => {
  const modalRef = useRef<FModalRef<Values>>(null);
  const onCancel = () => {
    modalRef.current && modalRef.current.form.resetFields();
    onClose();
  };
  const [selectedFunction, setSelectedFunction] = useState<
    "toWorker" | "toSubAdmin" | undefined
  >(undefined);
  return (
    <>
      <FModal
        okText={"Next"}
        ref={modalRef}
        width={650}
        title="Profile Management"
        open={visible}
        onCancel={onCancel}
        onOk={async () => {
          await modalRef.current?.form.validateFields().then((val) => {
            setSelectedFunction(val.function);
            onCancel();
          });
        }}
      >
        <FModal.RadioGroup label="" name="function">
          <div className="flex flex-row">
            <FModal.Radio props={{ value: "toWorker" }} className="flex">
              GC Team Member to Worker
            </FModal.Radio>
            <Icon icon={IconUserCircle} />
            <Icon icon={IconArrowRight} />
            <Icon icon={IconUser} />
          </div>
        </FModal.RadioGroup>
        <div
          style={{
            marginTop: "-50px",
            fontSize: "11px",
            marginLeft: " 20px",
            marginBottom: "20px",
          }}
        >
          Remove a worker from your company and convert them to a Worker or
          Foreman
        </div>
        <FModal.RadioGroup label="" name="function">
          <div className="flex flex-row">
            <FModal.Radio props={{ value: "toSubAdmin" }} className="flex">
              GC Team Member to Subcontractor Administrator
            </FModal.Radio>
            <Icon icon={IconUserCircle} />
            <Icon icon={IconArrowRight} />
            <Icon icon={IconUserCheck} />
          </div>
        </FModal.RadioGroup>
        <div
          style={{
            marginTop: "-50px",
            fontSize: "11px",
            marginLeft: " 20px",
          }}
        >
          Convert someone on this list to an administrator of their
          Subcontractor (this will remove them from your company and give higher
          access to their company’s profile)
        </div>
      </FModal>
      <ConvertEmployeeToWorker
        // refetch={refetch}
        onClose={() => {
          setSelectedFunction(undefined);
          // onCancel();
        }}
        visible={selectedFunction === "toWorker"}
        data={data}
        subs={subs}
      />
      <ConvertEmployeeToSubAdmin
        // refetch={refetch}
        onClose={() => {
          setSelectedFunction(undefined);
          // onCancel();
        }}
        visible={selectedFunction === "toSubAdmin"}
        data={data}
        subs={subs}
      />
    </>
  );
};
export default PerformEmployeeChangeFunction;
