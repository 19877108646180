import { IconSettings } from "@tabler/icons";
import { Drawer, Radio, Checkbox, DatePicker } from "antd";
import { ApolloQueryResult } from "@apollo/client";
import dayjs, { Dayjs } from "dayjs";
import { useState, useEffect } from "react";
import Icon from "src/common/components/general/Icon";
import useIsFirstRender from "src/common/hooks/useIsFirstRender";
import {
  GetOrientationDashboardDataQuery,
  GetOrientationDashboardDataQueryVariables,
  useUpdateProjectSettingOrientationDashboardMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import Button from "src/common/components/general/Button";
const { RangePicker } = DatePicker;
export const chartSettingsFields = {
  veteran: "exclude_veteran_type",
  gender: "exclude_gender",
  ethnicity: "exclude_ethnicity",
  residency: "exclude_residency",
  years_in_construction: "exclude_years_in_construction",
  years_with_employer: "exclude_years_with_employer",
} as const;
type DataRangeType = "all" | "this_year" | "onsite_today" | "custom";
const DashboardChartSettings: React.FC<{
  projectId: string;
  chartSettings: NonNullable<
    NonNullable<
      GetOrientationDashboardDataQuery["project_by_pk"]
    >["project_setting"]
  >["orientation_chart_settings_json"];
  refetch: (
    variables?: Partial<GetOrientationDashboardDataQueryVariables>,
  ) => Promise<ApolloQueryResult<GetOrientationDashboardDataQuery>>;
}> = ({ refetch, projectId, chartSettings }) => {
  const [updateDashboardSettings] =
    useUpdateProjectSettingOrientationDashboardMutation();
  const [openSettings, setOpenSettings] = useState(false);
  const [dataRangeType, setDataRangeType] = useState<DataRangeType>("all");
  const firstRender = useIsFirstRender();
  const [startDate, setStartDate] = useState<Dayjs>();
  const [endDate, setEndDate] = useState<Dayjs>();

  useEffect(() => {
    if (
      !firstRender &&
      (dataRangeType !== "custom" ||
        (startDate && endDate && startDate < endDate))
    ) {
      const todayStart = dayjs().startOf("d").toISOString();

      refetch({
        projectId,
        where: {
          project_id: { _eq: projectId },
          is_last: { _eq: true },
          deleted_at: { _is_null: true },
          ...(dataRangeType === "this_year"
            ? { created_at: { _gte: dayjs().startOf("year").toISOString() } }
            : dataRangeType === "onsite_today"
            ? {
                project_workers: {
                  _or: [
                    {
                      reports: {
                        report: { created_at: { _gte: todayStart } },
                      },
                    },
                    {
                      report_injuries: {
                        report: { created_at: { _gte: todayStart } },
                      },
                    },
                    {
                      toolbox_talks: {
                        toolbox_talk: {
                          created_at: { _gte: todayStart },
                        },
                      },
                    },
                    {
                      daily_work_log_workers: {
                        daily_work_log: {
                          date: { _gte: dayjs().format("YYYY-MM-DD") },
                        },
                      },
                    },
                    {
                      permits: {
                        permit: {
                          type: { _neq: "ladder" },
                          created_at: { _gte: todayStart },
                          current_status: { _in: ["submitted", "resubmitted"] },
                          is_historical: { _eq: true },
                        },
                      },
                    },
                  ],
                },
              }
            : dataRangeType === "custom" && startDate && endDate
            ? {
                created_at: {
                  _gte: startDate.toISOString(),
                  _lte: endDate.toISOString(),
                },
              }
            : {}),
        },
      });
    }
  }, [dataRangeType, endDate, startDate]);
  const onChartSettingsChange = (set: { [key: string]: string | boolean }) => {
    const newSettings = {
      ...(chartSettings || {}),
      ...set,
    };
    updateDashboardSettings({
      variables: {
        projectId,
        _set: {
          orientation_chart_settings_json: newSettings,
        },
      },
      optimisticResponse: {
        update_project_setting_by_pk: {
          __typename: "project_setting",
          project_id: projectId,
          orientation_chart_settings_json: newSettings,
        },
      },
    });
  };
  const chartsSettingsChecksList = [
    { label: "Show Veteran", exclude_field_name: chartSettingsFields.veteran },
    { label: "Show Gender", exclude_field_name: chartSettingsFields.gender },
    {
      label: "Show Ethnicity",
      exclude_field_name: chartSettingsFields.ethnicity,
    },
    {
      label: "Show Residency",
      exclude_field_name: chartSettingsFields.residency,
    },
    {
      label: "Show Years in Construction",
      exclude_field_name: chartSettingsFields.years_in_construction,
    },
    {
      label: "Show Years with Employer",
      exclude_field_name: chartSettingsFields.years_with_employer,
    },
  ];
  const dateRangeOptions: Array<{ label: string; value: DataRangeType }> = [
    { label: "All Time", value: "all" },
    { label: "This Year", value: "this_year" },
    { label: "Onsite Today", value: "onsite_today" },
    { label: "Custom Range", value: "custom" },
  ];
  return (
    <div className="flex flex-col justify-center items-center mb-1">
      <div className="flex flex-row gap-1 mt-1">
        {dateRangeOptions.map((op) => (
          <Button
            key={op.value}
            label={op.label}
            onClick={() => {
              setDataRangeType(op.value);
              if (op.value !== "custom") {
                setStartDate(undefined);
                setEndDate(undefined);
              }
            }}
            secondary={op.value !== dataRangeType}
          />
        ))}
        {dataRangeType === "custom" && (
          <RangePicker
            disabledDate={(current: Dayjs) =>
              current && current >= dayjs().add(1, "day")
            }
            picker="date"
            format={"YYYY-MM-DD"}
            value={[startDate, endDate]}
            clearIcon={false}
            onChange={(newMonthRange) => {
              if (!newMonthRange) return;
              if (newMonthRange[0]?.isValid()) {
                setStartDate(newMonthRange[0]);
              }
              if (newMonthRange[1]?.isValid()) {
                setEndDate(newMonthRange[1]);
              }
            }}
          />
        )}
      </div>

      <div className="absolute right-2 z-50">
        <div
          className="opacity-20"
          onClick={() => {
            setOpenSettings(true);
          }}
        >
          <Icon color="static" icon={IconSettings} size="small" />
        </div>
      </div>

      <Drawer
        open={openSettings}
        width={"20%"}
        onClose={() => setOpenSettings(false)}
      >
        {/* <div className="mt-1 text-1">Select a date Range</div>
        <Radio.Group
          className="mt-1 flex flex-col"
          onChange={(e) => setDataRangeType(e.target.value)}
          value={dataRangeType}
        >
          <Radio value="all">All Time</Radio>
          <Radio value="this_year">This Year</Radio>
          <Radio value="onsite_today">Onsite Today</Radio>
          <Radio value="custom">Custom Range</Radio>
        </Radio.Group>
        <br /> */}

        <div className="flex flex-col mb-1">
          <div>Change which charts to show</div>
          {chartsSettingsChecksList.map(({ label, exclude_field_name }) => (
            <Checkbox
              key={exclude_field_name}
              checked={!chartSettings || !chartSettings[exclude_field_name]}
              onChange={(e) => {
                onChartSettingsChange({
                  [exclude_field_name]: !e.target.checked, //setting not as we use opposite of exclude here
                });
              }}
            >
              {label}
            </Checkbox>
          ))}
        </div>
        <br />
        <div>Change what to show on Map</div>
        <Radio.Group
          className="flex flex-col"
          value={chartSettings?.residency_map || "zip_code"}
          onChange={(e) => {
            onChartSettingsChange({ residency_map: e.target.value });
          }}
        >
          <Radio value="state">State</Radio>
          <Radio value="zip_code">Zip Code</Radio>
        </Radio.Group>
      </Drawer>
    </div>
  );
};
export default DashboardChartSettings;
