import React from "react";
import IncidentDocUploader from "./IncidentDocUploader";
import IncidentDocViewer from "./IncidentDocViewer";
import {
  Document_Insert_Input,
  DocumentFragmentFragment,
} from "src/common/types/generated/apollo/graphQLTypes";
import { deletedDocument } from "./IncidentDocViewer";

interface IncidentDocViewAndUploadProps {
  groupId: string;
  type: string;
  uploadDocument: (objects: Document_Insert_Input[]) => Promise<void>;
  deleteDocument: (doc: deletedDocument) => Promise<void>;
  documents?: Array<DocumentFragmentFragment>;
}

const IncidentDocViewAndUpload: React.FunctionComponent<
  IncidentDocViewAndUploadProps
> = ({ groupId, uploadDocument, deleteDocument, documents, type }) => {
  return (
    <>
      <div className="w-4/5">
        <IncidentDocUploader
          acceptedFiles=".png,.jpg,.jpeg"
          insertDoc={uploadDocument}
          docType="image"
          type={type}
          title="Upload Images (area of incident, property damage, etc.)"
          groupId={groupId}
        />
        {documents && (
          <IncidentDocViewer
            documents={documents.filter((file) => !!file.image)}
            deleteDocument={deleteDocument}
          />
        )}
      </div>
      <div className="mt-3 mb-2 w-4/5">
        <IncidentDocUploader
          acceptedFiles="video/*, audio/*, .xlsx, application/pdf"
          insertDoc={uploadDocument}
          docType="other"
          type={type}
          title="Add Any Related Documents (police/incident reports, etc.)"
          groupId={groupId}
        />

        {documents && (
          <IncidentDocViewer
            documents={documents.filter((file) => !file.image)}
            deleteDocument={deleteDocument}
          />
        )}
      </div>
    </>
  );
};

export default IncidentDocViewAndUpload;
