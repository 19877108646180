import React, { FC, useCallback, useRef, useState } from "react";
import { message, notification, Popover } from "antd";
import CustomButton from "src/common/components/general/Button";
import { auth } from "src/common/functions/firebase";
import { cloneDeep } from "@apollo/client/utilities";
import { getPreviousJHA } from "src/common/components/CompareTwoJHAs";
import { IconEdit, IconFlag } from "@tabler/icons";
import dayjs from "dayjs";
import JhaGceReview from "src/common/components/JhaGceReview";
import JhaSubAdminApproval from "src/common/components/JhaSubAdminApproval";
import StyledContent from "src/common/components/layouts/StyledContent";
import TaskNotesTable from "src/common/components/tables/TaskNotesTable";
import TaskEditsTable from "src/common/components/tables/TaskEditsTable";
import TaskApprovalsTable from "src/common/components/tables/TaskSubAdminApprovalsTable";
import TaskSignaturesTable from "src/common/components/tables/TaskWorkerSignature";
import TaskType from "src/common/types/manual/TaskType";
import ViewAndEditTaskType from "src/common/types/manual/ViewAndEditTaskType";
import TaskEditPage from "./TaskEditPage";
import TaskVersionsView from "./TaskVersionsView";
import useOnSaveTaskAfterEdit from "../../utils/useOnSaveTask";
import {
  useEmailFlaggedJhatoUnflagMutation,
  useUpdateFlaggedTaskMutation,
} from "src/common/types/generated/apollo/graphQLTypes";

interface ViewAndEditTaskProps {
  taskId?: string;
  projectId?: string;
  subcontractorId?: string;
  type:
    | "gce_project_jha"
    | "gce_company_jha"
    | "sub_project_jha"
    | "sub_company_jha"
    | "pending_jha"
    | "sfe_uploaded_jha"
    | "anonymous";
  isNotTurnersProject: boolean;
  data: ViewAndEditTaskType;
  onSfeTaskSave?: (task: TaskType) => void;
  requestId?: string;
}

const ViewAndEditTask: FC<ViewAndEditTaskProps> = ({
  taskId,
  projectId,
  subcontractorId,
  type,
  isNotTurnersProject,
  data,
  requestId,
  onSfeTaskSave,
}) => {
  const [editState, setEditState] = useState(false);
  const taskEditDataTemp = data.queryData?.task_edit_connection.edges ?? [];
  const dataForPpe = data.ppeCategories;
  const dataForPermit = data.permits;
  const dataForEcm = data.ecms;
  const [updateFlaggedTask] = useUpdateFlaggedTaskMutation();
  const [onSaveTaskAfterEdit] = useOnSaveTaskAfterEdit();
  const [emailToUnflag, { loading: emailingJHAForReview }] =
    useEmailFlaggedJhatoUnflagMutation();
  const taskEditData = [...taskEditDataTemp];
  taskEditData.sort((a, b) => {
    const t1 = dayjs(a.node.edited_at);
    const t2 = dayjs(b.node.edited_at);
    return t1.isBefore(t2) ? 1 : -1;
  });
  const lastApprovalRequestToSub =
    data.queryData?.email_request_connection.edges[0]?.node;
  const taskReviewedByLoggedInUser =
    data.queryData?.task_signature_connection.edges.findIndex(
      (edge) => edge.node.user_id === auth.currentUser?.uid,
    ) !== -1
      ? true
      : false;

  const [checked, setChecked] = useState(taskReviewedByLoggedInUser);
  const taskSignaturesData = data.queryData?.task_signature_connection.edges;
  const subAdminApproval =
    data.queryData?.task_connection.edges[0].node.subAdminApprovals;
  const showEditWarning =
    taskSignaturesData && taskSignaturesData?.length > 0 ? true : false;

  const [currentPersonApproved, setCurrentPersonApproved] = useState(
    subAdminApproval?.findIndex(
      (sub) => sub.user_id === auth.currentUser?.uid,
    ) === -1
      ? false
      : true,
  );

  const currentUserData = data.queryData?.user_connection.edges[0].node;
  const subAdmins = data.queryData?.subcontractor_employee_connection.edges;
  const workers = data.queryData?.project_worker_connection.edges;
  const currentTaskObject: TaskType = {
    id: data.id,
    description: data.description,
    descriptionId: data.descriptionId,
    permits: data.taskPermits,
    ppes: data.taskPpes,
    steps: data.steps,
  };

  const [currentTaskObjectTest, setCurrentTaskObjectTest] =
    useState(currentTaskObject);
  const currentTaskObjectTestCopy = useRef(currentTaskObjectTest);
  const editableTaskObject = cloneDeep(currentTaskObject);
  const jhaHistory: Array<{
    currentTask: typeof editableTaskObject;
    previousTask: typeof editableTaskObject;
    editedBy: string;
    editedAt: string;
    editType: string;
  }> = [];

  taskEditData.forEach((edit) => {
    if ((edit.node.task_patch as Array<string>).length > 0) {
      const editedAt = edit.node.edited_at;
      const editedBy = edit.node.edited_by_user?.name;
      const editType = edit.node.edit_type;
      const tempTaskObject = cloneDeep(editableTaskObject);
      getPreviousJHA(editableTaskObject, edit.node.task_patch);

      const currentTaskObjectCopy = cloneDeep(tempTaskObject);
      const previousTaskObjectCopy = cloneDeep(editableTaskObject);
      jhaHistory.push({
        currentTask: currentTaskObjectCopy,
        previousTask: previousTaskObjectCopy,
        editedBy: editedBy ?? "",
        editedAt,
        editType,
      });
    }
  });

  jhaHistory.push({
    currentTask: editableTaskObject,
    previousTask: editableTaskObject,
    editedBy: "",
    editedAt: "",
    editType: "",
  });

  const updateCurrentTaskObject = useCallback(
    (newTaskStepObject: typeof currentTaskObjectTest) => {
      currentTaskObjectTestCopy.current = newTaskStepObject;
    },
    [],
  );
  const [stepsOrderChanged, setStpesOrderChanged] = useState(false);

  const jhaFormEdited =
    JSON.stringify(currentTaskObjectTest) !==
    JSON.stringify(currentTaskObjectTestCopy.current);

  const [loading, setLoading] = useState(false);
  const [currentJhaIndex, setCurrentJhaIndex] = useState(0);
  const isTaskEditable = type !== "gce_company_jha" && type !== "pending_jha";

  return (
    <>
      {isTaskEditable && (
        <div className="ml-3 mr-2 flex justify-between items-center">
          <div className="flex">
            <span className="inline-block mr-1">
              <CustomButton
                loading={editState ? loading : false}
                label={editState ? "Save" : "Edit"}
                icon={!editState ? IconEdit : undefined}
                secondary={type === "anonymous"}
                large
                onClick={async () => {
                  if (editState) setLoading(true);
                  if (!editState && showEditWarning) {
                    notification.warning({
                      message: `This JHA has been reviewed and/or signed. By editing it, the "Reviewed" status will be removed and any workers signatures will be cleared. Everyone will need to review and sign again`,
                      duration: 15,
                    });
                  }

                  try {
                    if (editState) {
                      if (
                        JSON.stringify(currentTaskObjectTest) !==
                        JSON.stringify(currentTaskObjectTestCopy.current)
                      ) {
                        if (type === "anonymous" && requestId) {
                          await updateFlaggedTask({
                            variables: {
                              input: {
                                currentTaskObject: currentTaskObjectTest,
                                previousTaskObject:
                                  currentTaskObjectTestCopy.current,
                                requestId: requestId,
                              },
                            },
                          });
                          message.destroy();
                          updateCurrentTaskObject(currentTaskObjectTest);
                        } else if (type === "sfe_uploaded_jha") {
                          onSfeTaskSave && onSfeTaskSave(currentTaskObjectTest);
                        } else {
                          if (!taskId) {
                            throw new Error("TaskId is not specified");
                          } 
                          onSaveTaskAfterEdit(
                            currentTaskObjectTest,
                            currentTaskObjectTestCopy.current,
                            taskId,
                            data.descriptionId,
                            stepsOrderChanged,
                          ).then((data) => {
                            setCurrentPersonApproved(false);
                            setChecked(false);
                          });

                          setCurrentJhaIndex(0);
                        }
                      }
                    } else {
                      updateCurrentTaskObject(currentTaskObjectTest);
                      setStpesOrderChanged(false);
                      setLoading(false);
                    }

                    editState ? setEditState(false) : setEditState(true);
                  } catch (err) {
                    console.error(err);
                    notification.error({
                      description: "Task could not be updated",
                      message:
                        err instanceof Error
                          ? err.message
                          : JSON.stringify(err),
                    });
                  }
                }}
              />
            </span>
            <span>
              {editState && jhaFormEdited && (
                <CustomButton
                  small
                  secondary={type === "anonymous"}
                  label="Cancel"
                  onClick={async () => {
                    setCurrentTaskObjectTest(currentTaskObjectTestCopy.current);
                    setStpesOrderChanged(false);
                  }}
                />
              )}
            </span>
          </div>

          {type === "gce_project_jha" &&
            subAdmins &&
            workers &&
            currentUserData &&
            taskId &&
            data.queryData?.task_signature_connection && (
              <JhaGceReview
                taskId={taskId}
                projectId={projectId ?? ""}
                subcontractorId={subcontractorId ?? ""}
                taskReviewedData={data.queryData?.task_signature_connection.edges.filter(
                  (edge) => edge.node.user?.role === "employee",
                )}
                isNotTurnersProject={isNotTurnersProject}
                checked={checked}
                setChecked={(state: boolean) => setChecked(state)}
              />
            )}

          {type === "sub_project_jha" && lastApprovalRequestToSub && taskId && (
            <JhaSubAdminApproval
              currentPersonApproved={currentPersonApproved}
              lastApprovalRequestToSub={lastApprovalRequestToSub}
              taskId={taskId}
              setApproved={() => setCurrentPersonApproved(true)}
            />
          )}
          {type === "anonymous" && requestId && (
            <div className="flex">
              <span className="inline-block mr-1">
                <CustomButton
                  icon={IconFlag}
                  delete
                  onClick={() => console.log("")}
                />
              </span>

              <span className="inline-block mr-0.5">
                <CustomButton
                  label="Request to unflag"
                  secondary
                  onClick={async () => {
                    await emailToUnflag({
                      variables: { input: { requestId: requestId } },
                    });
                    message.success("Request sent to UNFLAG the JHA");
                  }}
                  large
                />
              </span>

              <span className="inline-block mr-0.5">
                <Popover
                  trigger={"hover"}
                  placement="topLeft"
                  content={
                    <div className="text-1.2">
                      Once the JHA has been edited, select{" "}
                      <b>Request to unflag </b>
                      to let the GC know it has been updated{" "}
                    </div>
                  }
                >
                  ℹ️
                </Popover>
              </span>
            </div>
          )}
        </div>
      )}
      <br />
      {!editState && (
        <TaskVersionsView
          currentTask={
            type === "sfe_uploaded_jha" || type === "anonymous"
              ? currentTaskObjectTest
              : jhaHistory[currentJhaIndex].currentTask
          }
          previousTask={
            type === "sfe_uploaded_jha" || type === "anonymous"
              ? currentTaskObjectTest
              : jhaHistory[currentJhaIndex].previousTask
          }
          editedBy={jhaHistory[currentJhaIndex].editedBy}
          editedAt={dayjs(jhaHistory[currentJhaIndex].editedAt).format("LLL")}
          editType={jhaHistory[currentJhaIndex].editType}
          previousJhaVersions={jhaHistory.length}
          currentJHAIndex={currentJhaIndex}
          isTurner={!isNotTurnersProject}
          handleClick={(type) => {
            if (type === "left") {
              setCurrentJhaIndex((i) => i + 1);
            } else if (type === "right") {
              setCurrentJhaIndex((i) => i - 1);
            } else if (type === "currentJha") {
              setCurrentJhaIndex(0);
            }
          }}
        />
      )}

      {editState && dataForPpe && dataForPermit && dataForEcm && (
        <TaskEditPage
          currentTaskObject={currentTaskObjectTest}
          isNotTurnersProject={isNotTurnersProject}
          dataForPpe={dataForPpe}
          dataForPermit={dataForPermit}
          dataForEcm={dataForEcm}
          setCurrentTaskObject={(task) => setCurrentTaskObjectTest(task)}
          stepsOrderChanged={() => setStpesOrderChanged(true)}
          showCancel={jhaFormEdited}
          onCancel={() => {
            setCurrentTaskObjectTest(currentTaskObjectTestCopy.current);
            setStpesOrderChanged(false);
          }}
          onSave={async () => {
            if (
              JSON.stringify(currentTaskObjectTest) !==
              JSON.stringify(currentTaskObjectTestCopy.current)
            ) {
              message.loading({
                content: "Saving the JHA",
                duration: 0,
              });
              if (type === "sfe_uploaded_jha") {
                onSfeTaskSave && onSfeTaskSave(currentTaskObjectTest);
                message.destroy();
                setEditState(false);
                updateCurrentTaskObject(currentTaskObjectTest);
              } else if (type === "anonymous" && requestId) {
                await updateFlaggedTask({
                  variables: {
                    input: {
                      currentTaskObject: currentTaskObjectTest,
                      previousTaskObject: currentTaskObjectTestCopy.current,
                      requestId: requestId,
                    },
                  },
                });
                message.destroy();
                setEditState(false);
                updateCurrentTaskObject(currentTaskObjectTest);
              } else {
                if (!taskId)
                  throw new Error("TaskId is not specified");
                await onSaveTaskAfterEdit(
                  currentTaskObjectTest,
                  currentTaskObjectTestCopy.current,
                  taskId,
                  data.descriptionId,
                  stepsOrderChanged,
                ).then((data) => {
                  setCurrentPersonApproved(false);
                  setChecked(false);
                });
                message.destroy();
                setCurrentJhaIndex(0);
                setEditState(false);
              }
            } else {
              updateCurrentTaskObject(currentTaskObjectTest);
              setStpesOrderChanged(false);
              setEditState(false);
            }
          }}
          isAnonymous={type === "anonymous"}
        />
      )}

      <br />

      {type === "gce_project_jha" && (
        <StyledContent padding backgroundColor="white">
          <h3 className="text-16 mb-1 font-bold">JHA notes</h3>
          {taskId && subcontractorId && (
            <TaskNotesTable subcontractorId={subcontractorId} taskId={taskId} />
          )}
        </StyledContent>
      )}

      {(type === "sub_project_jha" || type === "gce_project_jha") &&
        taskSignaturesData && (
          <StyledContent padding backgroundColor="white">
            <h3 className="text-16 mb-1 font-bold">
              SubAdmins who Approved this JHA
            </h3>
            <TaskApprovalsTable
              subApprovalData={taskSignaturesData.filter(
                (sign) => sign.node.user?.role === "subcontractor_employee",
              )}
            />
          </StyledContent>
        )}

      {(type === "sub_project_jha" || type === "gce_project_jha") && (
        <StyledContent padding backgroundColor="white">
          <h3 className="text-16 mb-1 font-bold">JHA Activity</h3>
          <TaskEditsTable taskEditData={taskEditData} />
        </StyledContent>
      )}

      {(type === "gce_project_jha" || type === "sub_project_jha") &&
        taskSignaturesData &&
        projectId && (
          <StyledContent padding backgroundColor="white">
            <h1 className="text-16 mb-1 font-bold">Worker Signatures</h1>
            <TaskSignaturesTable
              workerSignaturesData={taskSignaturesData}
              projectId={projectId}
            />
          </StyledContent>
        )}
    </>
  );
};
export default ViewAndEditTask;
