import React, { useState } from "react";
import * as uuid from "uuid";
import { graphql } from "babel-plugin-relay/macro";
import { Form, message, Modal, Select, Tag } from "antd";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import {
  AddCompanyTaskToMultipleProjectsModalMutation,
  task_insert_input,
} from "src/common/types/generated/relay/AddCompanyTaskToMultipleProjectsModalMutation.graphql";
import { AddJHAToProjectOrCompanyQuery$data } from "src/common/types/generated/relay/AddJHAToProjectOrCompanyQuery.graphql";
import { auth } from "src/common/functions/firebase";
import dayjs from "dayjs";

interface AddProjectValue {
  id: string;
  description: string;
}

interface AddCompanyTaskToMultipleProjectsModalProps {
  tasks:
    | AddJHAToProjectOrCompanyQuery$data["task_connection"]["edges"]
    | undefined;
  multiple?: boolean;
  subcontractorId: string;
  projectsOfSub: Array<{ id: string; name: string }>;
  visible: boolean;
  onCancel: () => void;
}

const AddCompanyTaskToMultipleProjectsModal: React.FC<
  AddCompanyTaskToMultipleProjectsModalProps
> = ({
  tasks,
  projectsOfSub,
  subcontractorId,
  onCancel,
  visible,
  multiple,
}) => {
  const [insertTasks] =
    useAsyncMutation<AddCompanyTaskToMultipleProjectsModalMutation>(graphql`
      mutation AddCompanyTaskToMultipleProjectsModalMutation(
        $objects: [task_insert_input!]!
      ) {
        insert_task(objects: $objects) {
          affected_rows
        }
      }
    `);

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  if (!tasks) return <></>;

  const jhaProjects: Array<{ id: string; name: string }> = [];

  if (!multiple && tasks[0].node.project_tasks) {
    const projectTasks = tasks[0].node.project_tasks;
    projectTasks.forEach((value) => {
      if (
        jhaProjects.findIndex((project) => project.id === value.project_id) ===
          -1 &&
        value.project?.name &&
        value.project_id
      )
        jhaProjects.push({ id: value.project_id, name: value.project?.name });
    });
  }
  const projectsToSelect = projectsOfSub.filter(
    (ps) =>
      ps.id && jhaProjects.findIndex((project) => project.id === ps.id) === -1,
  );

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const tasksToInsert: task_insert_input[] = [];

  const funct = async (value: string) => {
    tasks.forEach((task) => {
      let curId: any = uuid.v1();

      const taskInsertInput: task_insert_input = {
        company_task_id: task.node.pk,
        id: curId,
        description: {
          data: {
            en: task.node.description.en,
            original: task.node.description.en,
          },
        },
        created_by_uid: task.node.created_by_uid,
        project_id: value,
        subcontractor_id: subcontractorId,
        creation_request_id: task.node.creation_request_id,
        accepted_at_from_creation_request:
          task.node.accepted_at_from_creation_request,
        accepted_by_uid_from_creation_request:
          task.node.accepted_by_uid_from_creation_request,
        document_id: task.node.document_id,
        task_steps: {
          data: task.node.task_steps.map((s, i) => ({
            sort_index: 10000 * (i + 1),
            description: {
              data: {
                en: s.description.en,
                original: s.description.en,
              },
            },
            task_step_hazards: {
              data: s.task_step_hazards.map((h, x) => ({
                sort_index: (x + 1) * 1000,
                description: {
                  data: {
                    en: h.description.en,
                    original: h.description.en,
                  },
                },
                control: {
                  data: {
                    en: h.control.en,
                    original: h.control.en,
                  },
                },
                task_hazard_ecm_types: {
                  data: h.task_hazard_ecm_types.map((e) => ({
                    ecm_type_id: e.ecm_type?.pk,
                  })),
                },
              })),
            },
          })),
        },
        task_permit_types: {
          data: task.node.task_permit_types.map((permit) => ({
            permit_type_id: permit.permit_type.pk,
          })),
        },
        task_ppe_types: {
          data: task.node.task_ppe_types.map((ppe) => ({
            ppe_type_id: ppe.ppe_type.pk,
          })),
        },
        task_edit: {
          data: [
            {
              edit_type: "Added to the Project",
              edited_by_uid: auth.currentUser?.uid,
              edited_at: dayjs().format(),
            },
          ],
        },
      };

      tasksToInsert.push(taskInsertInput);
    });
  };

  return (
    <Modal
      open={visible}
      cancelText="Cancel"
      okText="Save"
      onCancel={handleCancel}
      title={`Select Project(s) to add ${
        multiple ? `these JHAs` : ` this JHA`
      }`}
      confirmLoading={loading}
      onOk={() => {
        setLoading(true);
        form
          .validateFields()
          .then(async (values: { project: Array<string> }) => {
            form.resetFields();

            values.project.forEach(async (value) => {
              await funct(value);
            });

            await insertTasks({
              variables: {
                objects: tasksToInsert,
              },
            });

            setLoading(false);
            onCancel();
          })
          .then((data) => message.success("Task has been added to projects"))
          .catch((error) => {
            message.error(
              `ERROR: ${
                error instanceof Error ? error.message : JSON.stringify(error)
              }`,
            );
            console.log("Validate Failed:", error);
          });
      }}
    >
      {!multiple && (
        <>
          <p>
            {" "}
            {jhaProjects.length == 0
              ? "JHA not available on any projects"
              : tasks[0].node.description.en +
                " is currently available on the following project(s)"}{" "}
          </p>

          {jhaProjects.map((project, index) => (
            <Tag
              key={index}
              id={project.name}
              color="#e6f7ff"
              style={{
                color: "#1890FF",
                marginRight: "7px",
                marginTop: "7px",
              }}
            >
              {project.name}
            </Tag>
          ))}
        </>
      )}
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="project"
          label="Project(s)"
          rules={[{ required: true, message: "select projects" }]}
          style={{ width: "65%", marginTop: "40px" }}
        >
          <Select
            listItemHeight={10}
            mode="multiple"
            style={{
              width: "100%",
            }}
            options={projectsToSelect.map((projects) => ({
              value: projects.id,
              key: projects.id,
              label: projects.name,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCompanyTaskToMultipleProjectsModal;
