import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useRef, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import TasksTable from "src/domain-features/sitesafety/job-hazard-analysis/components/tables/TasksTable";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import { task_bool_exp } from "src/common/types/generated/relay/SiteOrientationSlidesViewerQuery.graphql";
import { SCTasksQuery } from "src/common/types/generated/relay/SCTasksQuery.graphql";
import SubcontractorProps from "src/common/types/manual/SubcontractorProps";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import UploadTaskModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/UploadTaskModal";
import UploadTaskToSiteFormModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/UploadTaskToSiteformModal";
import AddTaskModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/AddTaskModal";
import getTranslations from "src/common/functions/getTranslations";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import {
  SCTasksMutation,
  task_insert_input,
} from "src/common/types/generated/relay/SCTasksMutation.graphql";
import AddTasksFromOtherProjectLibraryModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/AddTasksFromOtherJHALibraryModal";
import topBarButtons from "src/domain-features/sitesafety/job-hazard-analysis/utils/topBarButtons";
import dayjs from "dayjs";

export const query = graphql`
  query SCTasksQuery($subcontractorId: uuid!) {
    ppe_type_connection(order_by: { name: { en: asc } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name {
            en
          }
        }
      }
    }
    permit_type_connection(order_by: { name: { en: asc } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name {
            en
          }
        }
      }
    }
    subcontractor_connection(where: { id: { _eq: $subcontractorId } }) {
      edges {
        node {
          subcontractor_projects(
            order_by: { project: { general_contractor: { name: asc } } }
          ) {
            project_id
            project {
              name
              general_contractor_id
              general_contractor {
                name
              }
            }
          }
        }
      }
    }

    project_subcontractor_connection(
      where: { subcontractor_id: { _eq: $subcontractorId } }
      order_by: { project: { name: asc } }
    ) {
      edges {
        node {
          project {
            name
            id
            pk: id @__clientField(handle: "pk")
          }
        }
      }
    }
  }
`;

const SubAdminTasks: FC<SubcontractorProps> = ({ subcontractorId }) => {
  const navigate = useNavigate();
  const data1 = useLazyLoadQuery<SCTasksQuery>(query, {
    subcontractorId,
  });
  const [insertTasks] = useAsyncMutation<SCTasksMutation>(graphql`
    mutation SCTasksMutation($objects: [task_insert_input!]!) {
      insert_task(objects: $objects) {
        affected_rows
      }
    }
  `);
  const [taskWhere, setTaskWhere] = useState<task_bool_exp | undefined>(
    undefined,
  );
  const [isOpenUploadTaskModal, setIsOpenUploadTaskModal] = useState(false);
  const [isOpenUploadTaskToSiteformModal, setIsOpenUploadTaskToSiteformModal] =
    useState(false);
  const [isAddTaskOpen, setIsAddTaskOpen] = useState(false);
  const jhaTableRef = useRef<DataScrollTableRef>(null);
  const ppeOptions = data1.ppe_type_connection.edges;
  const permitOptions = data1.permit_type_connection.edges;
  const projectsOfSub = data1.project_subcontractor_connection.edges.map(
    (proj) => ({ id: proj.node.project.pk, name: proj.node.project.name }),
  );
  const [othersAsGC, setOthersAsGC] = useState(false);
  const [turnerAsGC, setTurnerAsGC] = useState(false);
  const [statusJhaFilter, setStatusJhaFilter] = useState<task_bool_exp>({});
  const [selectedPpe, setSeletedPpe] = useState<Array<string>>([]);
  data1.subcontractor_connection.edges[0].node.subcontractor_projects.map(
    (cur) => {
      if (
        cur.project.general_contractor_id ===
          "96916668-c816-4c2a-9008-73a6116d4c00" &&
        turnerAsGC == false
      ) {
        setTurnerAsGC(true);
      }
      if (
        cur.project.general_contractor_id !==
          "96916668-c816-4c2a-9008-73a6116d4c00" &&
        othersAsGC == false
      )
        setOthersAsGC(true);
    },
  );
  const [notTurnersSheetUpload, setNotTurnersSheetUpload] = useState(true);

  return (
    <>
      <AddTaskModal
        visible={isAddTaskOpen}
        onCreate={async (values) => {
          setIsAddTaskOpen(false);
          const translations = await getTranslations([values.description]);
          if (!translations) return;
          const taskInsertInput: task_insert_input = {
            id: values.id,
            description: {
              data: translations[0],
            },
            created_by_uid: auth.currentUser?.uid,
            subcontractor_id: subcontractorId,
          };
          await insertTasks({
            variables: {
              objects: [{ ...taskInsertInput }],
            },
          })
            .then((data) => message.success("New Task has been added"))
            .catch((error) => {
              message.error(
                `ERROR: ${
                  error instanceof Error ? error.message : JSON.stringify(error)
                }`,
              );
            });
          navigate(`${values.id}`);
        }}
        onCancel={() => setIsAddTaskOpen(false)}
      />
      <UploadTaskModal
        visible={isOpenUploadTaskModal}
        isNotTurnersProject={notTurnersSheetUpload}
        onCancel={() => setIsOpenUploadTaskModal(false)}
        refetch={() => jhaTableRef.current?.refetch()}
        ppeData={ppeOptions.map((p) => ({
          name: p.node.name.en,
          id: p.node.pk,
        }))}
        permitData={permitOptions.map((p) => ({
          name: p.node.name.en,
          id: p.node.pk,
        }))}
        subcontractorId={subcontractorId}
        setIsOpenUploadModal={setIsOpenUploadTaskModal}
      />

      <AddTasksFromOtherProjectLibraryModal
        closeModal={() => setTaskWhere(undefined)}
        refresh={() => jhaTableRef.current?.refetch()}
        projectId={null}
        subcontractorId={subcontractorId}
        visible={!!taskWhere}
        taskWhere={taskWhere}
        addToProjectOrCompany="company"
      />

      <UploadTaskToSiteFormModal
        visible={isOpenUploadTaskToSiteformModal}
        subcontractorId={subcontractorId}
        onClose={() => setIsOpenUploadTaskToSiteformModal(false)}
        onSubmit={() => setIsOpenUploadTaskToSiteformModal(false)}
      />

      <TasksTable
        title={"Company JHAs"}
        ref={jhaTableRef}
        excludedKeys={[
          "subcontractor",
          "internalReview",
          "subcontractorApproval",
          "lastUsed",
          "firstUsed",
          "workersSigned",
          "addToProject",
        ]}
        onRowItemClick={(row) => {
          if (row.is_pending_acceptance) {
            navigate(
              `requested-task?idFromSubLib=${
                row.pk
              }${`&requestId=${row.creation_request_id}`}`,
            );
          } else {
            navigate(`${row.pk}`);
          }
        }}
        actionsAllowed={[
          "acceptJHA",
          "delete",
          "excelDownload",
          "pdfDownload",
          "viewFile",
          "addToMultipleProjects",
        ]}
        topBarButtons={topBarButtons({
          onInstantTaskUploadClick: () => setIsOpenUploadTaskModal(true),
          onNonInstantTaskUploadClick: () =>
            setIsOpenUploadTaskToSiteformModal(true),
          onAddJHAFromOtherProjectClick: () =>
            setTaskWhere({
              subcontractor_id: { _eq: subcontractorId },
              project_id: { _is_null: false },
              deleted_at: { _is_null: true },
              report_id: { _is_null: true },
              is_pending_acceptance: { _eq: false },
              request_state: { _is_null: true },
            }),

          onNewJHAClick: () => setIsAddTaskOpen(true),
          isSubAdminCompanyJHA: true,
        })}
        projectOfSub={projectsOfSub}
        isNotTurnersProject={notTurnersSheetUpload}
        subcontractorId={subcontractorId}
        where={{
          deleted_at: { _is_null: true },
          project_id: { _is_null: true },
          subcontractor_id: { _eq: subcontractorId },
          report_id: { _is_null: true },
          ...statusJhaFilter,
          ...(selectedPpe.length
            ? {
                task_ppe_types: { ppe_type_id: { _in: selectedPpe } },
              }
            : {}),
        }}
        extraColumns={[
          {
            title: "Created",
            dataIndex: ["created_at"],
            key: "created",
            defaultSortOrder: "asc",
            size: "md",
            sortable: true,
            searchDataIndex: ["created_at"],
            align: "right",
            render: (d: any, row) =>
              row.created_at ? (
                <div>
                  <span>{row.created_by_user?.name} </span> <br />
                  <span>{dayjs(row.created_at).format("MMM DD, YYYY")}</span>
                </div>
              ) : (
                ""
              ),
          },
        ]}
        customFilters={[
          {
            type: "checkbox",
            title: "Status",
            removeFilter: () => {
              setStatusJhaFilter({});
            },
            options: [
              {
                option: "Active",

                onApply: () => {
                  setStatusJhaFilter((prev) => {
                    return {
                      ...prev,
                      is_pending_acceptance: { _eq: false },
                    };
                  });
                },
                onCancel: () => {
                  setStatusJhaFilter((prev) => {
                    const { is_pending_acceptance, ...rest } = prev;
                    return rest;
                  });
                },
              },

              {
                option: "Pending Acceptance",
                onApply: () => {
                  setStatusJhaFilter((prev) => {
                    return {
                      ...prev,
                      _and: [{ is_pending_acceptance: { _eq: true } }],
                    };
                  });
                },
                onCancel: () => {
                  setStatusJhaFilter((prev) => {
                    const { _and, ...rest } = prev;
                    return rest;
                  });
                },
              },
            ],
          },

          {
            type: "checkbox",
            title: "PPE",
            removeFilter: () => setSeletedPpe([]),
            options: ppeOptions.map((ppe) => ({
              option: ppe.node.name.en,
              optionType: "checkbox",
              onApply: () => setSeletedPpe((prev) => [...prev, ppe.node.pk]),
              onCancel: () =>
                setSeletedPpe(selectedPpe.filter((id) => id !== ppe.node.pk)),
            })),
          },
        ]}
      />
    </>
  );
};
const SCTasksWrapper: FC = () => {
  const subcontractorId = useSubcontractorId();
  return (
    <div className="flex flex-col items-start gap-0.5 h-full w-full p-2 bg-white rounded-2">
      <div className="w-full h-full">
        <SubAdminTasks subcontractorId={subcontractorId} />
      </div>
    </div>
  );
};
export default withCustomSuspense(SCTasksWrapper);
