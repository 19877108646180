import React, { FC } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ReportsTabsGroup, {
  ReportsTabType,
} from "src/common/components/ReportsTabsGroup";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import useSiteOrientationGcDashboardData from "./utils/useSiteOrientationGcDashboardData";
import GCProjectOrientationStatus from "./routes/status/GCProjectOrientationStatus";
import GCProjectOrientationDrugTest from "./routes/drugTest/GCProjectOrientationDrugTest";
import GCProjectOrientationSettings from "./routes/settings/GCProjectOrientationSettings";
import GCProjectOrientationBackgroundCheck from "./routes/status/components/GCProjectOrientationBackgroundCheck";
import GCProjectOrientationDashboard from "./routes/dashboard/GCProjectOrientationDashboard";

interface GCProjectOrientationsProps {
  projectId: string;
  subview: string;
}

const GCProjectOrientations: FC<GCProjectOrientationsProps> = ({
  projectId,
  subview,
}) => {
  // $projectId: uuid!
  const data = useSiteOrientationGcDashboardData(projectId);
  const navigate = useNavigate();

  const handleReportsTabClick = (tabId: string) => {
    navigate("../" + tabId);
  };
  if (!data.project_connection.edges?.[0]) {
    return <>Unknown Project Id</>;
  }
  const performDrugtest =
    data.project_connection.edges[0].node.perform_drugtest;
  const backgroundTracker =
    data.project_connection.edges[0].node.background_check_tracker;

  const tabs: ReportsTabType[] = [
    { id: "dashboard", label: "Dashboard" },
    { id: "status", label: "Orientation" },
    { id: "drugtest", label: "Drug Tests" },
    { id: "backgroundcheck", label: "Background Check" },
    { id: "settings", label: "Settings" },
  ].filter((t) => {
    if (!performDrugtest && t.id === "drugtest") return false;
    if (!backgroundTracker && t.id === "backgroundcheck") return false;
    return true;
  }) as ReportsTabType[];
  return (
    <BasicWrapper>
      <ReportsTabsGroup
        onTabClick={handleReportsTabClick}
        tabs={tabs}
        tabsType="button"
        selectedTabId={subview}
      />
      <div className={`flex-grow bg-white overflow-auto py-1 w-full`}>
        <div className="flex w-full h-full">
          {subview === "status" && (
            <GCProjectOrientationStatus projectId={projectId} data={data} />
          )}
          {subview === "dashboard" && (
            <GCProjectOrientationDashboard projectId={projectId} />
          )}
          {subview === "drugtest" && performDrugtest && (
            <GCProjectOrientationDrugTest projectId={projectId} data={data} />
          )}
          {subview === "backgroundcheck" && backgroundTracker && (
            <GCProjectOrientationBackgroundCheck
              projectId={projectId}
              data={data}
            />
          )}
          {subview === "settings" && (
            <GCProjectOrientationSettings projectId={projectId} data={data} />
          )}
        </div>
        <Outlet />
      </div>
    </BasicWrapper>
  );
};

export default withCustomSuspense(GCProjectOrientations);
