import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { customAlphabet as nanoid } from "nanoid";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Order_By,
  useEmailFailedCovidCertificationMutation,
  useGetProjectCovidConfigByPkQuery,
  useGetSubcontractorsQuery,
  useInsertProjectCovidGuestCertificationsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import ProjectProps from "src/common/types/manual/ProjectProps";

const { Text } = Typography;

function preventDefault(e: any) {
  e.preventDefault();
}

const GuestCovidQuestions: FC<ProjectProps> = ({ projectId }) => {
  const navigate = useNavigate();
  const { data, loading, error } = useGetProjectCovidConfigByPkQuery({
    variables: {
      projectId,
    },
  });
  const { data: subsData, loading: loadingSubs } = useGetSubcontractorsQuery({
    variables: {
      where: {
        subcontractor_projects: {
          project_id: { _eq: projectId },
        },
      },
      order_by: [
        {
          name: Order_By.Asc,
        },
      ],
    },
  });
  const [insertCovidGuestCert, { loading: inserting }] =
    useInsertProjectCovidGuestCertificationsMutation();

  const [emailFailedCertification, { loading: sendingEmail }] =
    useEmailFailedCovidCertificationMutation();

  const [showReq, setShowReq] = useState(false);
  const [subcontractorId, setSubcontractorId] = useState<string>();

  const setBodyScroll = (enable: boolean) => {
    const b = document.getElementsByTagName("body")[0];
    b.style.overflow = enable ? "scroll" : "hidden";

    if (enable) {
      document.body.removeEventListener("touchmove", preventDefault);
    } else {
      document.body.addEventListener("touchmove", preventDefault, {
        passive: false,
      });
    }
  };

  if (loading || loadingSubs || inserting || sendingEmail) {
    return (
      <Row style={{ height: "100vh" }} justify="space-around" align="middle">
        <Spin />
      </Row>
    );
  }

  return (
    (<Row justify="space-around" align="middle">
      {data && (
        <Modal
          open={showReq}
          title={data?.project_by_pk?.name + " COVID-19 Requirements"}
          okText="OK"
          cancelText="Cancel"
          onOk={() => setShowReq(false)}
          onCancel={() => setShowReq(false)}
        >
          <p style={{ whiteSpace: "pre-line" }}>
            {data?.project_covid_config_by_pk?.requirements_text}
          </p>
        </Modal>
      )}
      <Form
        layout="vertical"
        name="normal_login"
        initialValues={{ agree: false }}
        onFinish={async (values) => {
          const passed = data?.project_covid_yes_no_question.every(
            (q) => values[q.id] === q.answer_is_yes
          );

          const externalId = "G" + nanoid("0123456789", 7)();

          const insertTask = insertCovidGuestCert({
            variables: {
              objects: [
                {
                  project_id: projectId,
                  name: values.name,
                  subcontractor_id:
                    subcontractorId === "custom" ? null : subcontractorId,
                  company_name:
                    subcontractorId === "custom" ? values.companyName : null,
                  external_id: !passed ? externalId : null,
                  passed,
                  project_covid_guest_certification_answers: {
                    data:
                      data?.project_covid_yes_no_question.map((q, i) => ({
                        project_covid_yes_no_question_id: q.id,
                        answered_yes: values[q.id],
                      })) ?? [],
                  },
                },
              ],
            },
          });
          const emailTask = !passed
            ? emailFailedCertification({
                variables: {
                  input: {
                    projectId,
                    userName: values.name,
                  },
                },
              })
            : null;
          await Promise.all([insertTask, emailTask]);

          const params = new URLSearchParams([
            ["name", values.name],
            ["projectName", data?.project_by_pk?.name],
            ["externalId", externalId],
            [
              "company",
              subcontractorId === "custom"
                ? values.companyName
                : subsData?.subcontractor.find((s) => s.id === subcontractorId)!
                    .name,
            ],
            ["date", new Date().toString()],
          ]);

          if (passed) {
            navigate(`/covid/${projectId}/pass?${params}`);
          } else {
            navigate(`/covid/${projectId}/fail?${params}`);
          }
        }}
        style={{ width: 250 }}
      >
        <h2 style={{ marginTop: 32 }}>{data?.project_by_pk?.name}</h2>
        <h2>{data?.project_by_pk?.general_contractor.name}</h2>
        <h3>COVID-19 Self-Certification</h3>
        <Divider />
        <br />
        <br />
        <Form.Item
          name="name"
          label="Your name"
          rules={[{ required: true, message: "Please input your name." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="subcontractorId"
          label="Company"
          rules={[{ required: true, message: "Choose a company" }]}
        >
          <Select
            onChange={(subId) => setSubcontractorId(subId as any)}
            style={{ width: "100%" }}
            disabled={loading}
            loading={loading}
            onFocus={() => {
              setBodyScroll(false);
            }}
            onBlur={() => {
              setBodyScroll(true);
            }}
            onSelect={() => {
              setBodyScroll(true);
            }}
          >
            {[
              { id: "custom", name: "OTHER COMPANY" },
              ...(subsData?.subcontractor ?? []),
            ].map((c) => (
              <Select.Option key={c.id} value={c.id}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {subcontractorId === "custom" && (
          <Form.Item
            name="companyName"
            label="Company name"
            rules={[{ required: true, message: "Enter a company name" }]}
          >
            <Input />
          </Form.Item>
        )}
        {data?.project_covid_yes_no_question.map((q, i) => (
          <Form.Item
            key={q.id}
            name={q.id}
            label={i + 1 + ". " + q.question.original}
            rules={[{ required: true, message: "Please input your answer." }]}
          >
            <Radio.Group>
              <Radio value={false}>No</Radio>
              <Radio value={true}>Yes</Radio>
            </Radio.Group>
          </Form.Item>
        ))}
        <br />
        <br />
        <Form.Item noStyle name="agree" valuePropName="checked">
          <Checkbox>
            I agree to the{" "}
            <a
              href=""
              onClick={(e) => {
                setShowReq(true);
                e.preventDefault();
              }}
            >
              Project's Covid-19 Requirements
            </a>
          </Checkbox>
        </Form.Item>
        <br />
        <br />
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => (
            <Button
              disabled={
                data?.project_covid_yes_no_question.some(
                  (q) => getFieldValue(q.id) === undefined
                ) || !getFieldValue("agree")
              }
              style={{ width: "100%", marginBottom: 64 }}
              loading={inserting}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </Row>)
  );
};

export default GuestCovidQuestions;
