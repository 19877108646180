import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import GCProjectCalendarSitedeliveryDeliveries from "../../../../../site-delivery/entry-routes/calendar/sitedelivery/deliveries/GCProjectCalendarSitedeliveryDeliveries";
import SiteBoardBase from "../../../../components/SiteBoardBase";
import { useSearchParams, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useLazyLoadQuery } from "react-relay";
import { SiteBoardDeliveriesCalendarQuery } from "src/common/types/generated/relay/SiteBoardDeliveriesCalendarQuery.graphql";

export interface SiteBoardDeliveriesCalendarProps {}

const SiteBoardDeliveriesCalendar: React.FC<
  SiteBoardDeliveriesCalendarProps
> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { projectId } = useParams();

  const data = useLazyLoadQuery<SiteBoardDeliveriesCalendarQuery>(
    graphql`
      query SiteBoardDeliveriesCalendarQuery($where: project_bool_exp!) {
        project_connection(where: $where) {
          edges {
            node {
              timezone
            }
          }
        }
      }
    `,
    { where: { id: { _eq: projectId } } },
  );

  const timezone = data.project_connection.edges.at(0)?.node.timezone;
  const dayDifferenceStr = searchParams.get("dayDifference");
  let dateToShow = dayjs().tz(timezone).startOf("day");

  if (dayDifferenceStr !== null) {
    const dayDifferenceNum = +dayDifferenceStr;
    if (!isNaN(dayDifferenceNum)) {
      dateToShow = dateToShow.add(dayDifferenceNum, "d");
    }
  }

  return (
    <SiteBoardBase>
      <div className={`w-full h-full py-0.5 bg-white`}>
        <GCProjectCalendarSitedeliveryDeliveries
          deliveryContentShownOnCards={true}
          staticDate={dateToShow}
        />
      </div>
    </SiteBoardBase>
  );
};

export default SiteBoardDeliveriesCalendar;
