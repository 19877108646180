import React, { PropsWithChildren } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ErrorOrLoading from "src/common/components/ErrorOrLoading";
import { SubcontractorProvider } from "src/common/components/SubcontractorContext";
import NavigationWrapper, {
  NavigationMenuProps,
} from "src/common/components/layouts/NavigationWrapper";
import { auth } from "src/common/functions/firebase";
import { useGetSubInitialDataQuery } from "src/common/types/generated/apollo/graphQLTypes";

const Subcontractor: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const userId = auth.currentUser?.uid;
  if (!userId) {
    throw new Error("User is not authenticated");
  }
  const { data, loading, error } = useGetSubInitialDataQuery({
    variables: { user_id: userId },
    fetchPolicy: "network-only",
  });
  if (loading || error) {
    return <ErrorOrLoading error={error} loading={loading} />;
  }
  const user = data?.user_by_pk;
  if (!user) {
    throw new Error("User does not exist, please relogin");
  }
  const subcontractor_employee = user.subcontractor_employee;
  if (!subcontractor_employee) {
    throw new Error("User is not subcontractor employee");
  }
  const subcontractor = subcontractor_employee.subcontractor;

  const menu: NavigationMenuProps = {
    navigationItems: [
      {
        rootPath: "/sub/people",
        label: `People`,
        onClick: () => {
          navigate(`/sub/people`);
        },
      },
      {
        rootPath: "/sub/projects",
        label: "Projects",
        onClick: () => {
          navigate("/sub/projects");
        },
      },
      {
        rootPath: "/sub/tasks",
        label: "JHAs",
        onClick: () => {
          navigate("/sub/tasks");
        },
      },
      // {
      //   rootPath: "/sub/observations",
      //   label: "Observations",
      //   onClick: () => {
      //     navigate("/sub/observations");
      //   },
      // },
      {
        rootPath: "/sub/toolbox-talks",
        label: "Toolbox Talks",
        onClick: () => {
          navigate("/sub/toolbox-talks");
        },
      },
      {
        rootPath: "/sub/sds",
        label: "SDS",
        onClick: () => {
          navigate("/sub/sds");
        },
      },
      {
        rootPath: "/sub/safety-plan",
        label: "Safety Plan",
        onClick: () => {
          navigate("/sub/safety-plan");
        },
      },
      {
        rootPath: "/sub/reports",
        label: "Reports",
        onClick: () => {
          navigate("/sub/reports");
        },
      },
      ...(subcontractor.subcontractor_projects_aggregate.aggregate?.count
        ? [
            {
              // hide billing if all projects are free
              rootPath: "/sub/billing",
              label: "Billing",
              onClick: () => {
                navigate("/sub/billing");
              },
            },
          ]
        : []),
      {
        rootPath: "/sub/feedback",
        label: "Feedback",
        onClick: () => {
          navigate("/sub/feedback");
        },
      },
      {
        rootPath: "/sub/profile",
        label: "Your Profile",
        onClick: () => {
          navigate("/sub/profile");
        },
      },
    ],
  };

  return (
    <SubcontractorProvider
      value={{
        id: subcontractor.id,
        name: subcontractor.name,
        user: { id: userId, name: user.name, email: user.email },
        title: {
          ...(subcontractor_employee.employee_title
            ? subcontractor_employee.employee_title?.name
            : {}),
        },
      }}
    >
      <NavigationWrapper {...{ menu }}>
        <Outlet />
      </NavigationWrapper>
    </SubcontractorProvider>
  );
};

export default Subcontractor;
