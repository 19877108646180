import {
  Alert,
  Col,
  Form,
  message,
  Modal,
  notification,
  Row,
  Select,
} from "antd";
import { FormInstance, useForm } from "antd/es/form/Form";
import { graphql } from "babel-plugin-relay/macro";
import React, {
  FC,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import {
  HtLicenseType,
  useHtGetLicenseTypesQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { HtLicenseTypesModalQuery } from "src/common/types/generated/relay/HtLicenseTypesModalQuery.graphql";
import { HtLicenseTypesModal_upsertLicenseType_Mutation } from "src/common/types/generated/relay/HtLicenseTypesModal_upsertLicenseType_Mutation.graphql";
import { HtLicenseTypesModal_deleteLicenseType_Mutation } from "src/common/types/generated/relay/HtLicenseTypesModal_deleteLicenseType_Mutation.graphql";
import { HtLicenseTypesModal_updateHtAccount_Mutation } from "src/common/types/generated/relay/HtLicenseTypesModal_updateHtAccount_Mutation.graphql";
import getNormalOptionsFilter from "src/common/functions/getNormalOptionsFilter";

const query = graphql`
  query HtLicenseTypesModalQuery($htAccountId: uuid!) {
    ht_integration_account_connection(where: { id: { _eq: $htAccountId } }) {
      edges {
        node {
          tenant
          pk: id @__clientField(handle: "pk")
          agc_license_type_id
          agc_license_type_name

          license_types {
            pk: id @__clientField(handle: "pk")
            certification_id
            ht_license_type_id
            ht_license_type_name
          }
        }
      }
    }
    certification_connection(where: { is_custom: { _eq: false } }) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          name
        }
      }
    }
  }
`;

export type HtLicenseTypesModalProps = {
  htAccountId: string;
  visible: boolean;
  onClose: () => void;
  onSubmit?: () => void;
};

type ModalFormRef<V extends object> = {
  form: FormInstance<V>;
  licenseTypes: {
    __typename?: "HtLicenseType" | undefined;
  } & Pick<HtLicenseType, "name" | "id" | "code">[];
  initialMapping: {
    readonly certification_id: string;
    readonly ht_license_type_id: string;
    readonly ht_license_type_name: string;
    readonly pk: string;
  }[];
};

type HtLicenseTypesModalContentProps = {
  htAccountId: string;
  saving: boolean;
};

function compareStrings(a: string, b: string) {
  return a.localeCompare(b);
}

type FormValues = {
  [sub: string]: any;
};

const HtLincenseTypesModalContent = forwardRef<
  ModalFormRef<FormValues>,
  HtLicenseTypesModalContentProps
>(({ htAccountId, saving }, ref) => {
  const data = useLazyLoadQuery<HtLicenseTypesModalQuery>(
    query,
    {
      htAccountId,
    },
    {
      fetchPolicy: "network-only",
    },
  );
  const [formRef] = useForm();

  const accounts = data.ht_integration_account_connection.edges || [];
  if (accounts.length == 0)
    throw new Error(`Ht account does not exist, htAccountId = ${htAccountId}`);

  const account = accounts[0].node;
  const certifications = data.certification_connection.edges.map((e) => e.node);
  certifications.sort((a, b) => compareStrings(a.name, b.name));
  const licence_mapping = account.license_types.filter((license) =>
    certifications.some((cert) => cert.pk === license.certification_id),
  );

  const { data: htLicenseTypesData, loading } = useHtGetLicenseTypesQuery({
    variables: {
      tenant: account.tenant,
    },
  });

  const allLicenseTypes = [
    ...(htLicenseTypesData?.htGetLicenseTypes?.licenseTypes || []),
  ];
  allLicenseTypes.sort((lic1, lic2) =>
    compareStrings(lic1.name ?? "", lic2.name ?? ""),
  );

  useImperativeHandle<ModalFormRef<FormValues>, ModalFormRef<FormValues>>(
    ref,
    () => ({
      form: formRef,
      licenseTypes: allLicenseTypes,
      initialMapping: licence_mapping,
    }),
  );

  const renderTypeRow = (
    certName: string,
    certId: string,
    licenseId?: string | null,
    licenseName?: string | null,
  ) => {
    return (
      <Row gutter={16} style={{ lineHeight: 2 }} key={certId}>
        <Col span={12}>{certName}</Col>
        <Col span={12}>
          <Form.Item name={certId} initialValue={licenseId ?? ""}>
            <Select
              style={{ width: "100%" }}
              disabled={saving}
              loading={loading || saving}
              showSearch
              optionFilterProp="children"
              filterOption={getNormalOptionsFilter}
              options={allLicenseTypes.map((t) => ({
                label: t.name,
                value: t.id,
              }))}
            ></Select>
          </Form.Item>
        </Col>
      </Row>
    );
  };
  const typeFields = certifications.map((cert) => {
    const certId = cert.pk;
    const htMapping = licence_mapping.find(
      (m) => m.certification_id === certId,
    );

    return renderTypeRow(
      cert.name,
      cert.pk,
      htMapping?.ht_license_type_id,
      htMapping?.ht_license_type_name,
    );
  });

  const agcLicenseId = account.agc_license_type_id;
  const agcLicenseName = account.agc_license_type_name;
  console.log("loading", loading);
  const agcField = renderTypeRow(
    "CARE Orientation",
    "agc_id",
    agcLicenseId,
    agcLicenseName,
  );

  return (
    <Form form={formRef} layout="vertical">
      <Alert
        style={{ margin: "16px 0" }}
        type="info"
        showIcon={false}
        banner
        message={
          <Row gutter={16} style={{ lineHeight: 2 }}>
            <Col span={12}>SF Certifcate</Col>
            <Col span={12}>HT Licence</Col>
          </Row>
        }
      />
      {/*loading && (
        <StyledContent align="center">
          <LoadingContent />
        </StyledContent>
      )*/}
      <>{agcField}</>
      <>{typeFields}</>
    </Form>
  );
});

const HtLincenseTypesModal: FC<HtLicenseTypesModalProps> = ({
  htAccountId,
  onSubmit,
  visible,
  onClose,
}) => {
  const modal = useRef<ModalFormRef<FormValues>>(null);
  const [saving, setSaving] = useState(false);

  const [upsertLicenseType] =
    useAsyncMutation<HtLicenseTypesModal_upsertLicenseType_Mutation>(
      graphql`
        mutation HtLicenseTypesModal_upsertLicenseType_Mutation(
          $objects: [ht_license_type_insert_input!]!
        ) {
          insert_ht_license_type(
            objects: $objects
            on_conflict: {
              constraint: ht_license_type_ht_account_id_certification_id_key
              update_columns: [ht_license_type_id, ht_license_type_name]
            }
          ) {
            affected_rows
          }
        }
      `,
    );
  const [deleteLicenseType] =
    useAsyncMutation<HtLicenseTypesModal_deleteLicenseType_Mutation>(
      graphql`
        mutation HtLicenseTypesModal_deleteLicenseType_Mutation(
          $htAccountId: uuid!
          $certId: uuid!
        ) {
          delete_ht_license_type(
            where: {
              ht_account_id: { _eq: $htAccountId }
              certification_id: { _eq: $certId }
            }
          ) {
            affected_rows
            returning {
              pk: id @__clientField(handle: "pk")
            }
          }
        }
      `,
    );
  const [updateHtAccount] =
    useAsyncMutation<HtLicenseTypesModal_updateHtAccount_Mutation>(
      graphql`
        mutation HtLicenseTypesModal_updateHtAccount_Mutation(
          $htAccountId: uuid!
          $_set: ht_integration_account_set_input!
        ) {
          update_ht_integration_account_by_pk(
            pk_columns: { id: $htAccountId }
            _set: $_set
          ) {
            id
            access_token
          }
        }
      `,
    );

  return (
    <>
      <Modal
        open={visible}
        cancelText="Cancel"
        title="Hammertech License Setup"
        okText="Save"
        width={750}
        confirmLoading={saving}
        onCancel={() => {
          onClose?.();
          //setVisible(false);
        }}
        onOk={async () => {
          const form = modal.current?.form;
          if (!form) return;

          try {
            const licenseTypes = modal.current?.licenseTypes ?? [];
            const initialMapping = modal.current?.initialMapping ?? [];

            const userId = auth.currentUser?.uid;
            if (!userId) {
              throw new Error("User not logged in");
            }
            const values = await form.validateFields();

            const licenseFields = Object.entries(values);

            setSaving(true);
            try {
              let updates = licenseFields.map(([fieldName, value]) => {
                const licenseId = value || null;
                //                const licenceName = licenceId && all

                const licenseName =
                  licenseId &&
                  licenseTypes.find((lt) => lt.id === licenseId)?.name;
                if (fieldName === "agc_id") {
                  return updateHtAccount({
                    variables: {
                      htAccountId,
                      _set: {
                        agc_license_type_id: licenseId,
                        agc_license_type_name: licenseName,
                      },
                    },
                  });
                } else {
                  const certId = fieldName;
                  const prevValue = initialMapping.find(
                    (im) => im.certification_id === certId,
                  );
                  if (licenseId) {
                    return !prevValue ||
                      prevValue.ht_license_type_id !== licenseId
                      ? upsertLicenseType({
                          variables: {
                            objects: [
                              {
                                ht_account_id: htAccountId,
                                certification_id: certId,
                                ht_license_type_id: licenseId,
                                ht_license_type_name: licenseName,
                              },
                            ],
                          },
                        })
                      : null;
                  } else {
                    return prevValue
                      ? deleteLicenseType({
                          variables: {
                            htAccountId,
                            certId: certId,
                          },
                        })
                      : null;
                  }
                }
              });

              await Promise.all(updates);

              if (onSubmit) onSubmit();
              onClose();
              message.success("Done");
            } finally {
              setSaving(false);
            }
          } catch (e) {
            notification.error({
              message: "Error",
              description: e instanceof Error ? e.message : JSON.stringify(e),
              duration: null,
            });
          }
        }}
      >
        <CustomSuspense>
          <HtLincenseTypesModalContent
            ref={modal}
            htAccountId={htAccountId}
            saving={saving}
          />
        </CustomSuspense>
      </Modal>
    </>
  );
};

export default HtLincenseTypesModal;
