import React, { useState } from "react";
import ButtonHuge from "../../../../common/components/general/ButtonHuge";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import SiteOrientationSlidesViewerMenu, {
  SiteOrientationSlidesViewerMenuProps,
} from "./SiteOrientationSlidesViewerMenu";
import remToPx from "../../../../common/functions/remToPx";
import SiteOrientationSlidesViewerNextButton from "./SiteOrientationSlidesViewerNextButton";
import SiteOrientationSlidesViewerBackButton from "./SiteOrientationSlidesViewerBackButton";
import useMeasure from "react-use-measure";

export interface SiteOrientationSlidesViewerNavigationProps {
  menu: Pick<
    SiteOrientationSlidesViewerMenuProps,
    | "menuItems"
    | "firstNonCompletedPageIndex"
    | "selectedPageIndex"
    | "onMenuItemClick"
    | "isTestLink"
  >;
  hideNavigationMenu?: boolean;
  nextButtonHidden?: boolean;
  nextButtonDisabled?: boolean;
  nextButtonText?: string;
  onNextButtonClick: () => void;
  backButtonHidden?: boolean;
  onBackButtonClick: () => void;
}

const SiteOrientationSlidesViewerNavigation: React.FC<
  SiteOrientationSlidesViewerNavigationProps
> = (props) => {
  const [menuCollapsed, setMenuCollapsed] = useState(true);
  const toggleCollapsed = () => {
    setMenuCollapsed(!menuCollapsed);
  };
  const [menuButtonWrapperRef, { width: menuButtonWrapperWidth }] =
    useMeasure();
  return (
    <>
      {!props.hideNavigationMenu && (
        <div
          className={`absolute inset-0 pointer-events-none justify-end h-full flex flex-col`}
        >
          <div
            className={`sticky pointer-events-auto w-min bottom-0 z-10`}
            ref={menuButtonWrapperRef}
          >
            <ButtonHuge
              icon={
                menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
              }
              onClick={toggleCollapsed}
            />
          </div>
        </div>
      )}
      <SiteOrientationSlidesViewerMenu
        {...{
          ...props.menu,
          visible: !menuCollapsed,
          collapse: toggleCollapsed,
        }}
      />
      <div
        className="flex flex-row"
        style={{ paddingLeft: `${menuButtonWrapperWidth + remToPx(0.5)}px` }}
      >
        {!props.backButtonHidden && (
          <div className="w-9 mr-0.5">
            <SiteOrientationSlidesViewerBackButton
              onClick={props.onBackButtonClick}
            />
          </div>
        )}
        {!props.nextButtonHidden && (
          <SiteOrientationSlidesViewerNextButton
            disabled={props.nextButtonDisabled}
            text={props.nextButtonText || "Next"}
            onClick={props.onNextButtonClick}
          />
        )}
      </div>
    </>
  );
};

export default SiteOrientationSlidesViewerNavigation;
