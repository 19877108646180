/**
 * @generated SignedSource<<972a086a58352b496c79df3f1067fec5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HtLicenseTypesModalQuery$variables = {
  htAccountId: string;
};
export type HtLicenseTypesModalQuery$data = {
  readonly certification_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly pk: string;
      };
    }>;
  };
  readonly ht_integration_account_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly agc_license_type_id: string | null | undefined;
        readonly agc_license_type_name: string | null | undefined;
        readonly license_types: ReadonlyArray<{
          readonly certification_id: string;
          readonly ht_license_type_id: string;
          readonly ht_license_type_name: string;
          readonly pk: string;
        }>;
        readonly pk: string;
        readonly tenant: string;
      };
    }>;
  };
};
export type HtLicenseTypesModalQuery = {
  response: HtLicenseTypesModalQuery$data;
  variables: HtLicenseTypesModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "htAccountId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "htAccountId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tenant",
  "storageKey": null
},
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agc_license_type_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agc_license_type_name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "certification_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ht_license_type_id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ht_license_type_name",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "is_custom": {
        "_eq": false
      }
    }
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HtLicenseTypesModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ht_integration_accountConnection",
        "kind": "LinkedField",
        "name": "ht_integration_account_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ht_integration_accountEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ht_integration_account",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ht_license_type",
                    "kind": "LinkedField",
                    "name": "license_types",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "certificationConnection",
        "kind": "LinkedField",
        "name": "certification_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "certificationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "certification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "certification_connection(where:{\"is_custom\":{\"_eq\":false}})"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HtLicenseTypesModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ht_integration_accountConnection",
        "kind": "LinkedField",
        "name": "ht_integration_account_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ht_integration_accountEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ht_integration_account",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ht_license_type",
                    "kind": "LinkedField",
                    "name": "license_types",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "certificationConnection",
        "kind": "LinkedField",
        "name": "certification_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "certificationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "certification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v10/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "certification_connection(where:{\"is_custom\":{\"_eq\":false}})"
      }
    ]
  },
  "params": {
    "cacheID": "0f4d3e191479a2390fcb6319a871c402",
    "id": null,
    "metadata": {},
    "name": "HtLicenseTypesModalQuery",
    "operationKind": "query",
    "text": "query HtLicenseTypesModalQuery(\n  $htAccountId: uuid!\n) {\n  ht_integration_account_connection(where: {id: {_eq: $htAccountId}}) {\n    edges {\n      node {\n        tenant\n        pk: id\n        agc_license_type_id\n        agc_license_type_name\n        license_types {\n          pk: id\n          certification_id\n          ht_license_type_id\n          ht_license_type_name\n          id\n        }\n        id\n      }\n    }\n  }\n  certification_connection(where: {is_custom: {_eq: false}}) {\n    edges {\n      node {\n        pk: id\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff5a5f999da0b85c6df25ee36fe5a031";

export default node;
