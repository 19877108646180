import { createPatch } from "rfc6902";
import { GetObservationByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";

const createObservationPatch = (
  newData: GetObservationByPkQuery["observation_by_pk"],
  oldData: GetObservationByPkQuery["observation_by_pk"],
) => {
  const newToPatch = { ...newData };
  const oldToPatch = { ...oldData };
  if (newToPatch) newToPatch.observation_edits = [];
  if (oldToPatch) oldToPatch.observation_edits = [];
  return createPatch(newToPatch, oldToPatch);
};
export default createObservationPatch;
