import { ButtonProps } from "src/common/components/general/Button";
import * as React from "react";
import { IconDownload, IconUpload } from "@tabler/icons";

interface topBarButtonProps {
  onInstantTaskUploadClick?: () => void;
  onNonInstantTaskUploadClick?: () => void;
  onAddJHAFromCompanyLibraryClick?: () => void;
  onAddJHAFromOtherProjectClick?: () => void;
  onRequestJHAFromSubClick?: () => void;
  onNewJHAClick?: () => void;
  isSubAdminCompanyJHA?: boolean;
  // handelDownloadButtonClick: () => void;
  // JHASpreadSheetLoading:boolean;
}

const topBarButtons = ({
  onInstantTaskUploadClick,
  onAddJHAFromCompanyLibraryClick,
  onAddJHAFromOtherProjectClick,
  onNewJHAClick,
  onNonInstantTaskUploadClick,
  onRequestJHAFromSubClick,
  isSubAdminCompanyJHA = false,
}: topBarButtonProps) => {
  const buttons: Array<ButtonProps> = [
    {
      label: "Add",
      fake: true,
      icon: IconUpload,
      hint: (
        <div>
          {onNewJHAClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              key={1}
              onClick={(e) => {
                e.stopPropagation();
                onNewJHAClick();
              }}
            >
              New JHA
            </div>
          )}
          {onNonInstantTaskUploadClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              key={3}
              onClick={(e) => {
                e.stopPropagation();
                onNonInstantTaskUploadClick();
              }}
            >
              Upload Your JHAs in Your Format - NOT INSTANT
            </div>
          )}

          {onInstantTaskUploadClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              key={2}
              onClick={(e) => {
                e.stopPropagation();
                onInstantTaskUploadClick();
              }}
            >
              Upload JHAs with SiteForm Excel Template - INSTANT
            </div>
          )}

          {onAddJHAFromCompanyLibraryClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              key={5}
              onClick={(e) => {
                e.stopPropagation();
                onAddJHAFromCompanyLibraryClick();
              }}
            >
              Add JHA from Company Library
            </div>
          )}

          {onAddJHAFromOtherProjectClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              key={6}
              onClick={(e) => {
                e.stopPropagation();
                onAddJHAFromOtherProjectClick();
              }}
            >
              {isSubAdminCompanyJHA
                ? "Add JHA from Project"
                : "Add JHA from Another Project"}
            </div>
          )}
          {onRequestJHAFromSubClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              key={4}
              onClick={(e) => {
                e.stopPropagation();
                onRequestJHAFromSubClick();
              }}
            >
              Request JHAs from sub
            </div>
          )}
        </div>
      ),
    },
    // {
    //   secondary: true,
    //   onClick: handelDownloadButtonClick,
    //   icon: IconDownload,
    //   loading:JHASpreadSheetLoading,
    //   label: "Download data in excel",
    // },
  ];

  return buttons;
};

export default topBarButtons;
