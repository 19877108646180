import {
  IconChevronDown,
  IconChevronRight,
  IconLogout,
  IconPhone,
} from "@tabler/icons";
import { Checkbox, Form, Input, message, Table } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import { roleVar } from "src/common/api/apollo/cache";
import BusinessCard from "src/common/components/BusinessCard";
import StyledContent from "src/common/components/layouts/StyledContent";
import WorkerCertificationTable from "src/common/components/tables/WorkerCertificationTable";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import capitalize from "src/common/functions/capitalize";
import getDateStr from "src/common/functions/getDateStr";
import noop from "src/common/functions/noop";
import * as uuid from "uuid";
import { ProfileAvatarProps } from "src/root/routes/views/subcontractor/people/workers/worker-profile/ProfileAvatar";
import MobileBusinessCard from "../siteorientation/components/MobileBusinessCard";
import { auth } from "src/common/functions/firebase";
import { InsertCertificatesToVerifyMutation } from "src/common/types/generated/relay/InsertCertificatesToVerifyMutation.graphql";
import insertCertificatesToVerifyMutate from "src/common/api/relay/mutations/InsertCertificatesToVerify";
import { WorkerLoginProfileQuery } from "src/common/types/generated/relay/WorkerLoginProfileQuery.graphql";
import { UpdateWorkerAndUserMutation } from "src/common/types/generated/relay/UpdateWorkerAndUserMutation.graphql";
import { Worker_UpdateReceiveTextSetting_Mutation } from "src/common/types/generated/relay/Worker_UpdateReceiveTextSetting_Mutation.graphql";
import workerAndUserUpdateMutation from "src/common/api/relay/mutations/UpdateWorkerAndUser";
import Button from "src/common/components/general/Button";
import Icon from "src/common/components/general/Icon";
import GetFullID from "src/common/functions/GetFullId";
import useUpdatePermission from "src/common/api/relay/mutationHooks/useUpdatePermission";
import useAuthUser from "src/common/hooks/useAuthUser";

const query = graphql`
  query WorkerLoginProfileQuery($workerId: uuid!) {
    worker_connection(where: { uid: { _eq: $workerId } }) {
      edges {
        node {
          subcontractor_id
          current_worker_role
          worker_title_id
          worker_title {
            translation {
              en
            }
          }
          worker_projects(
            where: { is_last: { _eq: true } }
            order_by: { project: { name: asc } }
          ) {
            project_id
            id
            pk: id @__clientField(handle: "pk")
            project {
              name
            }
            receive_text_message
          }
          subcontractor {
            name
          }
          processing_cert_count: certificates_to_verify(
            where: {
              verified_at: { _is_null: true }
              document: { _eq: "certificate" }
              status: { _eq: "submitted" }
            }
          ) {
            id
          }
          profile_pictures_to_verify: certificates_to_verify(
            where: {
              document: { _eq: "profilePhoto" }
              status: { _eq: "submitted" }
              verified_at: { _is_null: true }
            }
            order_by: { created_at: desc }
            limit: 1
          ) {
            id
            front_image {
              url
            }
          }
          subcontractor_workers(order_by: { created_at: desc }, limit: 1) {
            subcontractor_id
          }
          user {
            name
            email
            role
            id
            pk: id @__clientField(handle: "pk")
            username
            lang
            phone_number
            birth_date
            profile_picture_id
            profile_picture {
              url
            }
            phone_number_privacy_setting_id
            phone_number_privacy_setting {
              manager
              id
              supervisor
              employer
            }
            email_privacy_setting_id
            email_privacy_setting {
              manager
              supervisor
              id
              employer
            }
            project_privacy_setting_id
            project_privacy_setting {
              manager
              id
              supervisor
              employer
            }
          }
        }
      }
    }
  }
`;

interface WorkerProps {}

const Worker: React.FunctionComponent<WorkerProps> = ({}) => {
  const authUser = useAuthUser(); //"2df276f6-f850-4420-bf0e-dfb09e7b37e6";
  const workerId = authUser.uid;
  const data = useLazyLoadQuery<WorkerLoginProfileQuery>(query, {
    workerId,
  });
  const [updatePermission] = useUpdatePermission();
  const [updateReceiveTextSetting] =
    useAsyncMutation<Worker_UpdateReceiveTextSetting_Mutation>(
      graphql`
        mutation Worker_UpdateReceiveTextSetting_Mutation(
          $value: Boolean!
          $projectId: uuid!
          $workerId: uuid!
          $projectWorkerId: uuid!
        ) {
          update_project_worker_by_pk(
            pk_columns: { id: $projectWorkerId }
            _set: { receive_text_message: $value }
          ) {
            id
            receive_text_message
          }
          update_project_worker(
            where: {
              project_id: { _eq: $projectId }
              worker_id: { _eq: $workerId }
              id: { _neq: $projectWorkerId }
            }
            _set: { receive_text_message: $value }
          ) {
            affected_rows
          }
        }
      `,
    );
  const regexp = /android|iphone|kindle|ipad/i;
  const isMobileDevice = regexp.test(navigator.userAgent);

  const worker = data.worker_connection.edges[0].node;
  const [showCerts, setShowCerts] = useState(!isMobileDevice);
  const [showPrivacySettings, setShowPrivacySettings] = useState(false);
  const [phone_number, setPhoneNumber] = useState(worker.user.phone_number);
  const [profileImageLoading, setProfileImageLoading] = useState(false);
  const [editPhoneNumber, setEditPhoneNumber] = useState(false);
  const [insertCertsToVerify] =
    useAsyncMutation<InsertCertificatesToVerifyMutation>(
      insertCertificatesToVerifyMutate,
    );

  const [workerUpdate, isUpdatingWorker] =
    useAsyncMutation<UpdateWorkerAndUserMutation>(workerAndUserUpdateMutation);
  const [processingPic, setProcessingPic] = useState<string | undefined>(
    worker.profile_pictures_to_verify[0]?.front_image?.url,
  );
  const navigate = useNavigate();
  if (!worker) return <h1>The worker wasn't found</h1>;
  const subcontractorId = worker.subcontractor_id;
  const avatar: ProfileAvatarProps = {
    loading: profileImageLoading,
    preview: true,
    imageUrl: worker.user.profile_picture?.url || processingPic,
    onFileUpload: async (imageUrl) => {
      if (!worker.user) return;
      const id = worker.user.profile_picture_id ?? uuid.v4();
      setProfileImageLoading(true);

      await insertCertsToVerify({
        variables: {
          objects: [
            {
              document: "profilePhoto",
              status: "submitted",
              worker_id: workerId,
              uploaded_by_uid: auth.currentUser?.uid,
              front_image: {
                data: {
                  url: imageUrl,
                  description: "Profile Photo",
                  created_by_user_id: auth.currentUser?.uid,
                },
              },
            },
          ],
        },
      });
      setProcessingPic(imageUrl);
      setProfileImageLoading(false);

      //   setFetchKey((i) => i + 1);
    },
    onFileRemove: async () => {
      if (
        !worker.user.profile_picture?.url &&
        worker.profile_pictures_to_verify.length > 0
      ) {
        message.error("This photo is still being verified");
        return;
      }
      worker.user &&
        (await workerUpdate({
          variables: {
            workerId: worker.user.pk,
            worker_set: {},
            user_set: { profile_picture_id: null },
          },
        }));
      //   setFetchKey((i) => i + 1);
    },
  };
  return (
    <StyledContent>
      <div className="w-full h-full overflow-y-auto">
        {editPhoneNumber ? (
          <div className="flex flex-col items-center justify-center">
            <Form.Item label="Phone Number">
              <Input
                className="w-12"
                value={phone_number ?? undefined}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Item>
            <div className="flex gap-1 m-2 items-center justify-center">
              <Button
                label="Save"
                onClick={async () => {
                  if (workerId) {
                    await workerUpdate({
                      variables: {
                        worker_set: {},
                        user_set: { phone_number },
                        workerId,
                      },
                      optimisticResponse: {
                        update_worker_by_pk: {
                          id: GetFullID("user", workerId),
                        },
                        update_user_by_pk: {
                          id: GetFullID("user", workerId),
                          email: worker.user.email,
                          phone_number: worker.user.phone_number,
                        },
                      },
                    }).catch(() =>
                      message.error("Update unsuccessful, please try again"),
                    );
                  }
                  setEditPhoneNumber(false);
                }}
              />
              <Button
                label="Cancel"
                secondary
                onClick={async () => {
                  setEditPhoneNumber(false);
                }}
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            <div>
              <Button
                label="Log out"
                icon={IconLogout}
                onClick={async () => {
                  roleVar("none");
                  await auth.signOut();
                  navigate("/");
                }}
              ></Button>
            </div>
            <div className="flex flex-col gap-1">
              {isMobileDevice ? (
                <MobileBusinessCard
                  avatar={avatar}
                  fullName={worker.user.name}
                  company={worker.subcontractor?.name}
                  email={worker.user.email || undefined}
                  phoneNumber={worker.user.phone_number || undefined}
                  position={`${capitalize(worker.current_worker_role)}, ${
                    worker.worker_title?.translation.en
                  }`}
                  userName={worker.user.username || undefined}
                  bio={
                    worker.user.birth_date
                      ? `Birth Date: ${getDateStr(
                          dayjs(worker.user.birth_date),
                        )}`
                      : undefined
                  }
                />
              ) : (
                <div className="flex justify-center">
                  <BusinessCard
                    fullName={worker.user.name}
                    //   hardHatNumber={worker.hard_hat_number}
                    company={worker.subcontractor?.name}
                    email={worker.user.email || undefined}
                    phoneNumber={worker.user.phone_number || undefined}
                    position={`${capitalize(worker.current_worker_role)}, ${
                      worker.worker_title?.translation.en
                    }`}
                    userName={worker.user.username || undefined}
                    bio={
                      worker.user.birth_date
                        ? `Birth Date: ${getDateStr(
                            dayjs(worker.user.birth_date),
                          )}`
                        : undefined
                    }
                    avatar={avatar}
                  />
                </div>
              )}

              <div className="flex flex-row gap-1 items-center justify-center m-1">
                <Button
                  label={`Update Phone Number`}
                  icon={IconPhone}
                  onClick={async () => {
                    setEditPhoneNumber(true);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <Table
          bordered
          dataSource={worker.worker_projects.map((p) => p)}
          columns={[
            {
              width: "75%",
              title: "Project",
              key: "project",
              dataIndex: ["project", "name"],
            },
            {
              width: "25%",
              title: "Project Communication",
              key: "project-communication",
              dataIndex: "receive_text_message",
              render: (v, row) => (
                <Checkbox
                  checked={v}
                  onChange={(e) => {
                    updateReceiveTextSetting({
                      variables: {
                        projectId: row.project_id,
                        workerId: workerId,
                        projectWorkerId: row.pk,
                        value: e.target.checked,
                      },
                      optimisticResponse: {
                        update_project_worker_by_pk: {
                          id: row.id,
                          receive_text_message: e.target.checked,
                        },
                        update_project_worker: {
                          affected_rows: 1,
                        },
                      },
                    })
                      .then(() => {
                        if (e.target.checked) message.success("ON");
                        else message.warning("OFF");
                      })
                      .catch((err) => {
                        message.error("ERROR: Permission could not be set");
                        console.error(err);
                      });
                  }}
                />
              ),
            },
          ]}
        />
        {isMobileDevice && (
          <div className="flex text-1.25">
            <Icon
              icon={showCerts ? IconChevronDown : IconChevronRight}
              onClick={() => setShowCerts((i) => !i)}
            />
            Certifications & Trainings
          </div>
        )}
        {showCerts && (
          <WorkerCertificationTable
            loadAll
            isMobileDevice={isMobileDevice}
            isWorkerLogin
            headerContol={() => (
              <div>
                Processing Certificates: {worker.processing_cert_count.length}
              </div>
            )}
            extraColumns={[]}
            where={{
              worker: { uid: { _eq: workerId } },
            }}
            insertModalProps={{
              byWorker: true,
              workerId: workerId,
              workerName: worker.user.name,
              setProcessingOrder: noop,
            }}
            onRowClick={noop}
          />
        )}
        <div className="flex mb-2 text-1.25 mt-1">
          <Icon
            icon={showPrivacySettings ? IconChevronDown : IconChevronRight}
            onClick={() => setShowPrivacySettings((i) => !i)}
          />{" "}
          Privacy Settings
        </div>
        {showPrivacySettings && (
          <div className="mb-2">
            <div className="font-accent">Who can see you phone number?</div>
            <div
              className={`flex flex-row ${
                isMobileDevice ? "justify-between" : "justify-start"
              }`}
            >
              <div className={isMobileDevice ? "" : "ml-2"}>
                General Contractor
                <br />
                <Checkbox
                  checked={worker.user.phone_number_privacy_setting?.manager}
                  onChange={(e) => {
                    updatePermission(
                      e.target.checked,
                      { manager: e.target.checked },
                      worker.user.phone_number_privacy_setting_id,
                      worker.user.phone_number_privacy_setting,
                    );
                  }}
                />
              </div>
              <div className={isMobileDevice ? "" : "ml-2"}>
                Employer
                <br />
                <Checkbox
                  checked={worker.user.phone_number_privacy_setting?.employer}
                  onChange={(e) => {
                    updatePermission(
                      e.target.checked,
                      { employer: e.target.checked },
                      worker.user.phone_number_privacy_setting_id,
                      worker.user.phone_number_privacy_setting,
                    );
                  }}
                />
              </div>
              <div className={isMobileDevice ? "" : "ml-2"}>
                Supervisor
                <br />
                <Checkbox
                  checked={worker.user.phone_number_privacy_setting?.supervisor}
                  onChange={(e) => {
                    updatePermission(
                      e.target.checked,
                      { supervisor: e.target.checked },
                      worker.user.phone_number_privacy_setting_id,
                      worker.user.phone_number_privacy_setting,
                    );
                  }}
                />
              </div>
            </div>
            <div className="font-accent">Who can see you email?</div>
            <div
              className={`flex flex-row ${
                isMobileDevice ? "justify-between" : "justify-start"
              }`}
            >
              <div className={isMobileDevice ? "" : "ml-2"}>
                General Contractor
                <br />
                <Checkbox
                  checked={worker.user.email_privacy_setting?.manager}
                  onChange={(e) => {
                    updatePermission(
                      e.target.checked,
                      { manager: e.target.checked },
                      worker.user.email_privacy_setting_id,
                      worker.user.email_privacy_setting,
                    );
                  }}
                />
              </div>
              <div className={isMobileDevice ? "" : "ml-2"}>
                Employer
                <br />
                <Checkbox
                  checked={worker.user.email_privacy_setting?.employer}
                  onChange={(e) => {
                    updatePermission(
                      e.target.checked,
                      { employer: e.target.checked },
                      worker.user.email_privacy_setting_id,
                      worker.user.email_privacy_setting,
                    );
                  }}
                />
              </div>
              <div className={isMobileDevice ? "" : "ml-2"}>
                Supervisor
                <br />
                <Checkbox
                  checked={worker.user.email_privacy_setting?.supervisor}
                  onChange={(e) => {
                    updatePermission(
                      e.target.checked,
                      { supervisor: e.target.checked },
                      worker.user.email_privacy_setting_id,
                      worker.user.email_privacy_setting,
                    );
                  }}
                />
              </div>
            </div>
            <div className="font-accent">
              Who can see your previous project History?
            </div>
            <div
              className={`flex flex-row ${
                isMobileDevice ? "justify-between" : "justify-start"
              }`}
            >
              <div className={isMobileDevice ? "" : "ml-2"}>
                General Contractor
                <br />
                <Checkbox
                  checked={worker.user.project_privacy_setting?.manager}
                  onChange={(e) => {
                    updatePermission(
                      e.target.checked,
                      { manager: e.target.checked },
                      worker.user.project_privacy_setting_id,
                      worker.user.project_privacy_setting,
                    );
                  }}
                />
              </div>
              <div className={isMobileDevice ? "" : "ml-2"}>
                Employer
                <br />
                <Checkbox
                  checked={worker.user.project_privacy_setting?.employer}
                  onChange={(e) => {
                    updatePermission(
                      e.target.checked,
                      { employer: e.target.checked },
                      worker.user.project_privacy_setting_id,
                      worker.user.project_privacy_setting,
                    );
                  }}
                />
              </div>
              <div className={isMobileDevice ? "" : "ml-2"}>
                Supervisor
                <br />
                <Checkbox
                  checked={worker.user.project_privacy_setting?.supervisor}
                  onChange={(e) => {
                    updatePermission(
                      e.target.checked,
                      { supervisor: e.target.checked },
                      worker.user.project_privacy_setting_id,
                      worker.user.project_privacy_setting,
                    );
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </StyledContent>
  );
};

export default Worker;
