import { Button } from "antd";
import { ColumnFilterItem } from "antd/lib/table/interface";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, forwardRef, useState } from "react";
import ImageCarousel from "src/common/components/general/images/ImageCarousel";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import capitalize from "src/common/functions/capitalize";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { RejectedOrArchivedCertsTableQuery } from "src/common/types/generated/relay/RejectedOrArchivedCertsTableQuery.graphql";
import { RejectedOrArchivedCertsTable_certificates_to_verify$data } from "src/common/types/generated/relay/RejectedOrArchivedCertsTable_certificates_to_verify.graphql";
import { VerifyMultipleCertsModal_UpdateMutation } from "src/common/types/generated/relay/VerifyMultipleCertsModal_UpdateMutation.graphql";
import { updateCertsToVerifyMutation } from "./components/VerifyMultipleCertsModal";
import GetFullID from "src/common/functions/GetFullId";
import { useNavigate } from "react-router-dom";
import noop from "src/common/functions/noop";

export type ColumnKeys =
  | "worker"
  | "project"
  | "date"
  | "reason"
  | "status"
  | "action"
  | "images"
  | "verifiedAt"
  | "verifiedBy"
  | "type";
const allStatusFilterList: Array<ColumnFilterItem> = [
  {
    text: "Rejected",
    value: "rejected",
  },
  {
    text: "Archived",
    value: "archived",
  },
];
const allTypeFilterList: Array<ColumnFilterItem> = [
  {
    text: "Certificate",
    value: "certificate",
  },
  {
    text: "Drugtest",
    value: "drugtest",
  },
];
const CONNECTION_NAME = "certificates_to_verify_connection";
type DConnection =
  RejectedOrArchivedCertsTable_certificates_to_verify$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  RejectedOrArchivedCertsTableQuery,
  { worker_id: string | unknown; project_id: string | unknown }
> & {
  onRowClickPK: (pk: string) => void;
  searchString?: string;
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    RejectedOrArchivedCertsTableQuery
  >["columns"];
  markDeleted?: boolean;
  allowEditHH?: boolean;
};
const RejectedOrArchivedCertsTable = forwardRef<DataScrollTableRef, Props>(
  ({ title = "Workers", onRowClickPK, searchString, where, ...props }, ref) => {
    const navigate = useNavigate();
    const [updateMutation, updatingStatus] =
      useAsyncMutation<VerifyMultipleCertsModal_UpdateMutation>(
        updateCertsToVerifyMutation,
      );
    return (
      <DataScrollTable<
        DConnection,
        ColumnKeys,
        RejectedOrArchivedCertsTableQuery
      >
        {...props}
        ref={ref}
        title={title}
        expandable={{
          rowExpandable: (row) => true,
          expandedRowRender: (row) => (
            <ImageCarousel
              images={
                row.back_image?.url
                  ? [
                      { url: row.front_image?.url },
                      { url: row.back_image?.url },
                    ]
                  : [{ url: row.front_image?.url }]
              }
            />
          ),
        }}
        onRowClick={({ pk }) => onRowClickPK && onRowClickPK(pk)}
        where={where}
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allCertifcatesToVerifyConnection"}
        columns={[
          {
            title: "Worker Name",
            key: "worker",
            size: "md",
            sortable: true,
            // defaultSortOrder: "asc",
            dataIndex: ["worker", "user", "name"],
            searchDataIndex: ["worker", "user", "name"],
            searchValue: searchString,
          },
          {
            title: "Project",
            key: "project",
            size: "md",
            dataIndex: ["project", "name"],
            searchDataIndex: ["project", "name"],
          },

          {
            title: "Date Submitted",
            key: "date",
            size: "md",
            sortable: true,
            // defaultSortOrder: "asc",
            dataIndex: ["created_at"],
            render: (a) => dayjs(a).format("MMM DD, YYYY, h:mm a"),
          },
          {
            title: "Rejected/Archived On",
            key: "date",
            size: "md",
            sortable: true,
            defaultSortOrder: "desc",
            dataIndex: ["verified_at"],
            render: (a) => dayjs(a).format("MMM DD, YYYY, h:mm a"),
          },

          {
            title: "Images",
            key: "images",
            size: "sm",
            dataIndex: [""],
            render: (_: any, row) => `${row.back_image ? 2 : 1}`,
          },

          {
            title: "Type",
            key: "type",
            size: "sm",
            dataIndex: ["document"],
            // filters: {
            //   dataIndex: ["document"],
            //   options: allTypeFilterList,
            // },
            render: (t) => capitalize(t),
          },
          {
            title: "Status",
            key: "status",
            dataIndex: ["status"],
            size: "sm",
            // filters: {
            //   dataIndex: ["status"],
            //   options: allStatusFilterList,
            // },
            render: (s) => capitalize(s),
          },
          {
            title: "Reason",
            key: "reason",
            size: "lg",
            dataIndex: ["reason_to_reject_or_archive", "reason"],
          },
          {
            title: "Action",
            key: "action",
            size: "sm",
            dataIndex: [""],
            render: (_, row) => {
              return (
                <Button
                  type="primary"
                  loading={updatingStatus}
                  onClick={async () => {
                    await updateMutation({
                      variables: {
                        certId: row.pk,
                        _set: {
                          verified_at: null,
                          verified_by_uid: null,
                          reason_to_reject_or_archive_id: null,
                          status: "submitted",
                        },
                      },
                      // optimisticResponse: {
                      //   update_certificates_to_verify_by_pk: {
                      //     verified_at: null,
                      //     id: row.id,
                      //     reason_to_reject_or_archive_id: null,
                      //     status: "submitted",
                      //   },
                      // },
                    });
                    row.document === "certificate"
                      ? navigate(
                          `/sfe/verify/certificate/${row.worker_id}#${row.pk}`,
                        )
                      : row.document === "drugtest"
                      ? navigate(
                          `/sfe/verify/drugtest/${row.pk}/${row.worker_id}`,
                        )
                      : navigate(`/sfe/verify/profile_photo`);
                  }}
                >
                  Undo Rejection
                </Button>
              );
            },
          },
        ]}
        queryNode={graphql`
          query RejectedOrArchivedCertsTableQuery(
            $first: Int!
            $after: String
            $where: certificates_to_verify_bool_exp!
            $order_by: [certificates_to_verify_order_by!]!
          ) {
            ...RejectedOrArchivedCertsTable_certificates_to_verify
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
            ...RejectedOrArchivedCertsTable_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment RejectedOrArchivedCertsTable_total on query_root
          @argumentDefinitions(
            where: { type: "certificates_to_verify_bool_exp!" }
          )
          @refetchable(
            queryName: "RejectedOrArchivedCertsTableTotalRefetchableQuery"
          ) {
            certificatesToVerifyConnection: certificates_to_verify_connection(
              where: $where
            ) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment RejectedOrArchivedCertsTable_certificates_to_verify on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "certificates_to_verify_bool_exp!" }
            order_by: { type: "[certificates_to_verify_order_by!]!" }
          )
          @refetchable(
            queryName: "RejectedOrArchivedCertsTableRefetchableQuery"
          ) {
            certificates_to_verify_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "RejectedOrArchivedCertsTable_certificates_to_verify_connection"
                filters: ["where", "order_by"]
              ) {
              edges {
                node {
                  pk: id @__clientField(handle: "pk")
                  id
                  verified_at
                  created_at
                  worker_id
                  document
                  status
                  reason_to_reject_or_archive {
                    reason
                  }
                  verified_by_user {
                    name
                  }
                  worker {
                    user {
                      name
                    }
                  }
                  front_image {
                    url
                    pk: id @__clientField(handle: "pk")
                  }
                  back_image {
                    url
                    pk: id @__clientField(handle: "pk")
                  }
                  project {
                    name
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);
const RejectedOrArchivedCerts: FC = () => {
  return (
    <>
      <RejectedOrArchivedCertsTable
        where={{
          _or: [
            { status: { _eq: "archived" } },
            { status: { _eq: "rejected" } },
          ],
        }}
        onRowClickPK={(pk) => {}}
      />
    </>
  );
};
export default RejectedOrArchivedCerts;
