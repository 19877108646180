import { Form, Input, Modal, message } from "antd";
import { FC, useEffect, useState } from "react";
import validateEmail from "src/common/functions/validateEmail";
import { useStripeCreateAccountSubMutation } from "src/common/types/generated/apollo/graphQLTypes";
import NotifyUserException from "src/utility-features/error-handling/NotifyUserException";
import handleRequestError from "src/utility-features/error-handling/handleRequestError";

interface SubcontractorBillingDetailsModalProps {
  visible: boolean;
  subcontractorId: string;
  projectId: string;
  defaultEmail?: string | null;
  onClose: () => void;
  onSubmit: () => void;
}

const SubcontractorBillingDetailsModal: FC<
  SubcontractorBillingDetailsModalProps
> = ({
  visible,
  subcontractorId,
  projectId,
  defaultEmail,
  onClose,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const [createStripeAccountSub] = useStripeCreateAccountSubMutation();

  const [form] = Form.useForm();

  useEffect(() => {
    if (defaultEmail) {
      form.setFieldsValue({ email: defaultEmail });
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      confirmLoading={loading}
      title={
        <div>
          Enter Billing Information
          <br />
        </div>
      }
      okText="OK"
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={async () => {
        setLoading(true);
        const val = await form.validateFields();

        if (!validateEmail(val.email)) {
          const errorTitle = "Invalid email",
            errorDescription = "Please enter a valid email address";
          handleRequestError(
            new NotifyUserException(errorTitle, errorDescription),
            {
              errorTitle: errorTitle,
              errorUserInstructions: errorDescription,
            },
          );
          setLoading(false);
          return;
        }

        try {
          const res = await createStripeAccountSub({
            variables: {
              input: {
                sub_id: subcontractorId,
                project_id: projectId,
                account_email: val.email,
              },
            },
          });

          if (res.data?.stripeCreateAccountSub) {
            message.success("Uploaded To SiteForm");
          }

          form.resetFields();
          onSubmit();
        } catch (error) {
          handleRequestError(error);
        }

        setLoading(false);
      }}
    >
      <Form form={form} layout="vertical" title="Billing Details Form">
        <Form.Item
          className="mt-2"
          label="Billing Email Address"
          name="email"
          rules={[{ required: true, message: "Email address required" }]}
        >
          <Input placeholder="Enter email address"></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default SubcontractorBillingDetailsModal;
