/**
 * @generated SignedSource<<1faf7724bb05c635c2df8b1b4793c1af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type hardHatHelpers_GetProjectHardHatNumbersQuery$variables = {
  projectId: string;
};
export type hardHatHelpers_GetProjectHardHatNumbersQuery$data = {
  readonly project_worker_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly hard_hat_number: string | null | undefined;
      };
    }>;
  };
};
export type hardHatHelpers_GetProjectHardHatNumbersQuery = {
  response: hardHatHelpers_GetProjectHardHatNumbersQuery$data;
  variables: hardHatHelpers_GetProjectHardHatNumbersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "is_last",
        "value": {
          "_eq": true
        }
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "project_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hard_hat_number",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "hardHatHelpers_GetProjectHardHatNumbersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_workerConnection",
        "kind": "LinkedField",
        "name": "project_worker_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_workerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "hardHatHelpers_GetProjectHardHatNumbersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_workerConnection",
        "kind": "LinkedField",
        "name": "project_worker_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_workerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33ed7f21f03782cc271c4f883551b222",
    "id": null,
    "metadata": {},
    "name": "hardHatHelpers_GetProjectHardHatNumbersQuery",
    "operationKind": "query",
    "text": "query hardHatHelpers_GetProjectHardHatNumbersQuery(\n  $projectId: uuid!\n) {\n  project_worker_connection(where: {project_id: {_eq: $projectId}, is_last: {_eq: true}}) {\n    edges {\n      node {\n        hard_hat_number\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7cd35053966f58339c8e2248c8df0dc5";

export default node;
