import { Outlet } from "react-router-dom";
import React from "react";
import SCObservations from "./SCObservations";
import SCObservation from "./SCObservation";
const gcObservationRoute = {
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <SCObservations />,
    },
    {
      path: ":observationId",
      element: <SCObservation />,
    },
  ],
};
export default gcObservationRoute;
