import React, { useContext, useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { Select } from "antd";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsTradeProps
  extends SiteOrientationStepProps {
  unions: Array<{
    id: string;
    name: string;
    laborUnionChapters: Array<{
      id: string;
      name: string;
    }>;
  }>;
}

const SiteOrientationInPersonWorkerStepsTrade: React.FC<
  SiteOrientationInPersonWorkerStepsTradeProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();
  let defaultUnion: null | {
    union: string;
    unionChapter: string;
  } = null;
  if (
    workerContext.orientee &&
    workerContext.orientee.userType === "worker" &&
    workerContext.orientee.veteranType
  ) {
    defaultUnion =
      workerContext.orientee.unionId && workerContext.orientee.unionChapterId
        ? {
            union: workerContext.orientee.unionId,
            unionChapter: workerContext.orientee.unionChapterId,
          }
        : null;
  }
  const [unionChapter, setUnionChapter] = useState(
    defaultUnion?.unionChapter || "",
  );
  const [union, setUnion] = useState(defaultUnion?.union || "");

  const onEnter = () => {
    if (union && unionChapter) {
      workerContext.updateOrientee((user) =>
        user
          ? { ...user, unionChapterId: unionChapter, unionId: union }
          : { unionChapterId: unionChapter, unionId: union },
      );
    }
    props.onNext();
  };
  const langStrings = useCurrentLangStrings();
  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.enterYourUnion}
      >
        <div className={`flex flex-col gap-1`}>
          <div className={`flex flex-col gap-0.5`}>
            <p className={`text-1.5`}>
              {langStrings.strings.selectYourUnion}:{" "}
            </p>
            <Select
              className="text-1"
              virtual={false}
              onSelect={(val) => {
                if (typeof val.valueOf() === "string") {
                  setUnion(val.valueOf());

                  const list =
                    props.unions.find((p) => p.id === val.valueOf())
                      ?.laborUnionChapters || [];
                  if (list.length === 1) setUnionChapter(list[0].id);
                  else if (unionChapter) {
                    setUnionChapter("");
                  }
                }
              }}
              value={union}
              placeholder={langStrings.strings.selectYourUnion}
            >
              {props.unions.map((e) => (
                <Select.Option key={e.id} value={e.id} className="text-1">
                  {langStrings.options.laborUnions[e.id] ?? e.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className={`flex flex-col gap-0.5`}>
            <p className={`text-1.5`}>
              {langStrings.strings.selectYourUnionChapter}:
            </p>
            <Select
              className="text-1"
              virtual={false}
              onSelect={(val) => {
                if (typeof val.valueOf() === "string") {
                  setUnionChapter(val.valueOf());
                }
              }}
              value={unionChapter}
              placeholder={langStrings.strings.selectYourUnionChapter}
            >
              {(
                props.unions.find((p) => p.id === union)?.laborUnionChapters ||
                []
              ).map((e) => (
                <Select.Option key={e.id} value={e.id} className="text-1">
                  {langStrings.options.laborUnions[e.id] ?? e.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerStepsTrade;
