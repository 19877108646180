import * as React from "react";

interface TaskAdminInfoProps {
  onClick: () => void;
  title: string;
}

const TaskAdminInfo = (props: TaskAdminInfoProps) => {
  return (
    <div
      className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
      onClick={() => props.onClick()}
    >
      {props.title}
    </div>
  );
};

export default TaskAdminInfo;
