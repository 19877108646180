import { useState } from "react";
import { message, notification } from "antd";
import dayjs from "dayjs";
import { roleVar } from "src/common/api/apollo/cache";
import useUpdateUserWorker from "src/common/api/relay/mutationHooks/useUpdateUserWorker";
import useUpsertWorkerOne from "src/common/api/relay/mutationHooks/useUpsertWorkerOne";
import ageByBirthDate from "src/common/functions/ageByBirthDate";
import {
  useCreateNewWorkerAndPseudoSignInMutation,
  useHtLinkWorkerMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { auth } from "src/common/functions/firebase";
import { useSiteOrientationUniversalUser } from "./siteOrientationUniversalHook";
import { signInWithCustomToken } from "firebase/auth";

const useUniversalOrientationRegisterUser = (props: {
  onLoginSuccess?: (workerId: string) => Promise<void>;
}) => {
  const [user] = useSiteOrientationUniversalUser();
  const insertWorker = useUpsertWorkerOne();
  const [workerUpdate, _] = useUpdateUserWorker();
  const [htLinkWorker] = useHtLinkWorkerMutation();

  const [loading, setLoading] = useState(false);

  const [createNewWorkerAndPseudoSignIn] =
    useCreateNewWorkerAndPseudoSignInMutation();
  // const [createNewGCUserAndPseudoSignIn] =
  //   useCreateNewGcUserAndPseudoSignInMutation();
  const onExistingWorkerRegistration = async (phoneNumber: string) => {
    if (!user || !user.id || !(user.userType === "worker")) {
      console.log("no worker has been set");
      throw new Error("no worker has been set");
    }
    const dobEntry = user.birthDate?.new || user.birthDate?.old;
    const dob = dobEntry ? dayjs(dobEntry) : undefined;

    const age = dob ? ageByBirthDate(dob) : undefined;

    const promises: Array<Promise<any>> = [];
    const curTimeForUpdate = dayjs().format();
    if (user.isSubAdmin) {
      await insertWorker({
        variables: {
          object: {
            uid: user.id,
            subcontractor_id: user.prevCompanyId, // here in prevCompanyId we have stored company Id of subadmin's company as this function will run for subadmin only and he can register as worker on his own company
            current_worker_role: user.role,
            worker_title_id: user.title?.id,
          },
          updateColumns: ["worker_title_id", "current_worker_role"],
        },
      });
      promises.push(
        workerUpdate({
          variables: {
            userId: user.id,
            workerSet: user.unionChapterId
              ? { labor_union_chapter_id: user.unionChapterId }
              : {},
            userSet: {
              phone_number: phoneNumber,
              // lang: workerContext. === "es" || lang === "pt" ? lang : "en",
            },
          },
        }),
      );
    } else {
      promises.push(
        workerUpdate({
          variables: {
            userId: user.id,
            workerSet: {
              worker_title_id: user.title?.id, // title is necessary to be selected during orientation
              current_worker_role: user.role,
              ...(user.unionChapterId
                ? { labor_union_chapter_id: user.unionChapterId }
                : {}),
            },
            userSet: {
              phone_number: phoneNumber,
              // lang: lang === "es" || lang === "pt" ? lang : "en",
            },
          },
        }),
      );
    }
    if (user.htWorkerCode) {
      promises.push(
        htLinkWorker({
          variables: {
            htWorkerCode: user.htWorkerCode,
            userId: user.id,
          },
        }).catch((error) => console.log("link worker fails", error)),
      );
    }

    console.log(`worker update`);
    await Promise.all(promises);
  };

  const registerUser: (phoneNumber: string) => Promise<boolean> = async (
    phoneNumber,
  ) => {
    try {
      const userName = user?.name?.new || user?.name?.old;
      const dobEntry = user?.birthDate?.new || user?.birthDate?.old;
      setLoading(true);
      if (user?.id) {
        await onExistingWorkerRegistration(phoneNumber);
      } else if (
        user &&
        userName &&
        user.title &&
        user.role &&
        (user.email || dobEntry)
      ) {
        if (user.userType === "worker") {
          const loggedInUser = await auth.currentUser?.getIdTokenResult();
          await createNewWorkerAndPseudoSignIn({
            variables: {
              input: {
                name: userName,
                email: user.email,
                returnToken:
                  loggedInUser?.claims?.role === "employee" ? false : true,
                titleId: user.title.id,
                workerRole: user.role,
                lang: "en", // need to change to language user selected but if it's it, fr or chinese we set it as english
                phone: phoneNumber,
                workerDob: dobEntry ? dobEntry.format("YYYY-MM-DD") : undefined,
                laborUnionChapterId: user.unionChapterId,
                htWorkerCode: user.htWorkerCode,
              },
            },
          })
            .then(async ({ data }) => {
              if (data?.createNewWorkerAndPseudoSignIn) {
                const { token, workerId } =
                  data?.createNewWorkerAndPseudoSignIn;
                if (token) {
                  roleVar("none");
                  await auth.signOut();
                  await signInWithCustomToken(auth, token);
                }
                props.onLoginSuccess && (await props.onLoginSuccess(workerId));
              }
            })
            .catch((err) => {
              console.error("Registration Error: ", err);
              message.error(err.message);
            });
        } else {
          //  no registration for OAC
        }
      } else {
        throw new Error(
          "Insufficient data to register new user, please go back and try again",
        );
      }
      setLoading(false);
      return true;
    } catch (e) {
      console.log(e);
      setLoading(false);
      notification.error({
        message: "ERROR: couldn't register worker",
        description: e instanceof Error ? e.message : JSON.stringify(e),
      });
      return false;
    }
  };
  return [registerUser, loading] as const;
};
export default useUniversalOrientationRegisterUser;
