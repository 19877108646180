// import "antd/dist/antd.css";

import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import App from "src/root/App";
import "src/root/styles/index.css";
import { BrowserRouter } from "react-router-dom";
import sentryInit from "./utility-features/error-handling/sentry/sentryInit";
import hotjarInit from "./utility-features/event-tracking/hotjar/hotjarInit";
import "src/common/functions/dayjsExtendPlugins";
import resizeObserverPolyfillInit from "./common/functions/resizeObserverPolyfillInit";
import { createRoot } from "react-dom/client";
import { ConfigProvider } from "antd";
import {
  antDesignConfigProviderProps,
  antDesignStaticConfig,
} from "./common/functions/antDesignConfig";

// Polyfill for ResizeObserver
resizeObserverPolyfillInit();

// Error & Events Tracking
sentryInit();
hotjarInit();

// Ant Design
antDesignStaticConfig();

//
document.title = "SiteForm";

const container = document.getElementById("root");
if (!container) {
  throw new Error("Page does not contain root element");
}
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <ConfigProvider {...antDesignConfigProviderProps}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ConfigProvider>,
);
