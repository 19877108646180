/**
 * @generated SignedSource<<5fd3fc748aab73d4d2e3e6634daf33b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HammerTechAccountsGetHtAccountsQuery$variables = Record<PropertyKey, never>;
export type HammerTechAccountsGetHtAccountsQuery$data = {
  readonly ht_integration_account_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly active: boolean;
        readonly agc_license_type_id: string | null | undefined;
        readonly created_at: string;
        readonly id: string;
        readonly last_sync_at: string | null | undefined;
        readonly last_sync_status: string | null | undefined;
        readonly login: string;
        readonly pk: string;
        readonly skip_labor_union: boolean;
        readonly tenant: string;
      };
    }>;
  };
};
export type HammerTechAccountsGetHtAccountsQuery = {
  response: HammerTechAccountsGetHtAccountsQuery$data;
  variables: HammerTechAccountsGetHtAccountsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tenant",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "login",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_sync_at",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_sync_status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "skip_labor_union",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agc_license_type_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HammerTechAccountsGetHtAccountsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ht_integration_accountConnection",
        "kind": "LinkedField",
        "name": "ht_integration_account_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ht_integration_accountEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ht_integration_account",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id_pk",
                    "storageKey": null
                  },
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HammerTechAccountsGetHtAccountsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ht_integration_accountConnection",
        "kind": "LinkedField",
        "name": "ht_integration_account_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ht_integration_accountEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ht_integration_account",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "02808233fd1996ccb7e136b0a2fc5ae4",
    "id": null,
    "metadata": {},
    "name": "HammerTechAccountsGetHtAccountsQuery",
    "operationKind": "query",
    "text": "query HammerTechAccountsGetHtAccountsQuery {\n  ht_integration_account_connection {\n    edges {\n      node {\n        pk: id\n        id\n        tenant\n        login\n        active\n        created_at\n        last_sync_at\n        last_sync_status\n        skip_labor_union\n        agc_license_type_id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c25a80bab6aa5409703c4bbfe0f7eecb";

export default node;
