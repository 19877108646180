import React from "react";
import { Outlet } from "react-router-dom";
import searchParamsProvider from "src/common/functions/searchParamsProvider";
import QrProject from "./routes/QrProject";
import NestedRoute from "../../../../common/types/manual/NestedRoute";
import subRoute from "./routes/sub/subRoute";
import permitsRoute from "./routes/permits/permitsRoute";
import ptpRoute from "./routes/ptp/ptpRoute";
import sdsRoute from "./routes/sds/sdsRoute";

const qrProjectReportsRoute: NestedRoute = {
  //example: http://localhost:3000/qr/p/944334ea-32e7-4246-9693-e7247e4c1afd
  element: <Outlet />,
  children: [
    {
      path: "p/:projectId",
      element: <Outlet />,
      children: [
        {
          path: "",
          // Used by qrcode
          element: searchParamsProvider(QrProject),
        },
        {
          path: "sub",
          ...subRoute,
        },
        {
          path: "permits",
          ...permitsRoute,
        },
        {
          path: "ptp",
          ...ptpRoute,
        },
        {
          path: "sds",
          ...sdsRoute,
        },
      ],
    },
  ],
};

export default qrProjectReportsRoute;
