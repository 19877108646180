import { useState } from "react";

function useSelectedIndicies<T extends string | number = number>(
  initialSelectedIndices: Set<T> = new Set<T>()
) {
  const [selectedIndices, setSelectedIndices] = useState<Set<T>>(
    initialSelectedIndices
  );

  const handleIndexSelect = (index: T) => {
    setSelectedIndices((prev) => {
      const newIndices = new Set([...prev]);
      if (newIndices.has(index)) {
        newIndices.delete(index);
      } else {
        newIndices.add(index);
      }
      return newIndices;
    });
  };

  return [selectedIndices, handleIndexSelect, setSelectedIndices] as const;
}

export default useSelectedIndicies;
