import React, { FunctionComponent } from "react";
import { Link as ReactLink } from "react-router-dom";

interface LinkProps {
  href: string;
  children: any;
}

const Link: FunctionComponent<LinkProps> = ({ href, children }) => {
  return <ReactLink to={href}>{children}</ReactLink>;
};

export default Link;
