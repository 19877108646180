import React from "react";
import Button from "../../../../../../../../common/components/general/Button";
import dayjs from "dayjs";
import { IconDownload } from "@tabler/icons";
import GCProjectReportsDailyDay, {
  GCProjectReportsDailyDayProps,
} from "../day/GCProjectReportsDailyDay";

interface GCProjectReportsDailyMonth {
  month: dayjs.Dayjs;
  days: {
    summary: GCProjectReportsDailyDayProps["summary"];
    day: dayjs.Dayjs;
  }[];
  searchQuery: string;
  onDownloadMonthClick: () => void;
  projectId: string;
  selectedTagIds: string[];
  selectedSubcontractorIds: string[];
}

const GCProjectReportsDailyMonth: React.FC<GCProjectReportsDailyMonth> = (
  props,
) => {
  return (
    <div className={`flex flex-col gap-1`}>
      <div className="flex flex-col gap-0.5 items-center justify-center w-full">
        <p className={`text-1.5`}>{props.month.format("MMMM YYYY")}</p>
        <Button
          onClick={props.onDownloadMonthClick}
          icon={IconDownload}
          label={`Download Monthly Report`}
        />
      </div>
      <div className={`flex flex-col gap-0.5`}>
        {(() =>
          props.days.map((day) => (
            <GCProjectReportsDailyDay
              key={day.day.format()}
              searchQuery={props.searchQuery}
              day={day.day}
              projectId={props.projectId}
              selectedTagIds={props.selectedTagIds}
              selectedSubcontractorIds={props.selectedSubcontractorIds}
              summary={day.summary}
            />
          )))()}
      </div>
    </div>
  );
};

export default GCProjectReportsDailyMonth;
