import { Button, Input } from "antd";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateSubcontractorModal from "src/common/components/dialogs/CreateSubcontractorModal";
import StyledContent from "src/common/components/layouts/StyledContent";
import SubcontractorsTable from "src/common/components/tables/SubcontractorsTable";
import useColumnOrderBy from "src/common/hooks/useColumnOrderBy";
import useColumnSearch from "src/common/hooks/useColumnSearch";
import usePaginatedQuery, {
  UsePaginatedQueryParams,
} from "src/common/hooks/usePaginatedQuery";
import {
  GetSubcontractorsDocument,
  Order_By,
  Subcontractor,
  Subcontractor_Bool_Exp,
  Subcontractor_Order_By,
  useInsertSubcontractorsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";

// TODO add num of reports as column
const AdminSubcontractors = () => {
  const navigate = useNavigate();
  const [createSubOpen, setCreateSubOpen] = useState(false);
  const { orderBy, setOrderBy } = useColumnOrderBy<Subcontractor_Order_By>({
    name: Order_By.Asc,
  });
  const nameSearch = useColumnSearch();
  const tradeSearch = useColumnSearch();
  const searchByName = useColumnSearch();

  const queryVariables = useMemo(
    (): UsePaginatedQueryParams<Subcontractor_Bool_Exp> => ({
      where: {
        name: {
          _ilike: searchByName.value,
        },
        _or: [
          {
            trade: {
              name: {
                _ilike: tradeSearch.value,
              },
            },
          },
          {
            trade_id: {
              _is_null: true,
            },
          },
        ],
      },
      orderBy: orderBy,
      queryDoc: GetSubcontractorsDocument,
      dataIndex: "subcontractor",
      aggregateCountIndex: "subcontractor_aggregate",
    }),
    [searchByName.value, tradeSearch.value, orderBy]
  );

  const [subcontractors, { loading, page, setPage, count, pageSize, refetch }] =
    usePaginatedQuery<Partial<Subcontractor>>(queryVariables);
  const [insertSub, { loading: insertingSub }] =
    useInsertSubcontractorsMutation();

  const changeHandler = (event: any): void => {
    searchByName.setter(event.target.value);
  };

  return (
    <StyledContent padding backgroundColor="white">
      <CreateSubcontractorModal
        onCreate={async (values) => {
          insertSub({
            variables: {
              objects: [
                {
                  name: values.name,
                  subcontractor_ein: values.ein,
                  trade_id: values.tradeId,
                },
              ],
            },
          });
          refetch();
          setPage(1);
          setCreateSubOpen(false);
        }}
        onCancel={() => setCreateSubOpen(false)}
        visible={createSubOpen}
      />
      <SubcontractorsTable
        sortColumns={{
          name: true,
          ein: true,
          trade: true,
          workers: true,
          projects: true,
        }}
        searchColumns={{ name: nameSearch.setter, trade: tradeSearch.setter }}
        dataSource={subcontractors}
        title={() => (
          <>
            <h3>Subcontractors</h3>
            <br />
            <Button
              type="primary"
              loading={insertingSub}
              onClick={() => setCreateSubOpen(true)}
            >
              New Subcontractor
            </Button>
            <Input
              type="primary"
              style={{ margin: "10px", width: "200px" }}
              placeholder={"Search"}
              onChange={changeHandler}
            ></Input>
          </>
        )}
        onChange={(_: any, filters: any, sorter: any) => {
          setOrderBy(sorter);
        }}
        onRow={(sub: any) => ({
          onClick: () => {
            navigate(`/admin/data/subcontractors/${sub.id}`);
          },
        })}
        loading={loading || insertingSub}
        pagination={{
          current: page,
          pageSize,
          total: count,
          onChange: setPage,
        }}
      />
    </StyledContent>
  );
};

export default AdminSubcontractors;
