import { SelectProps } from "antd/lib";
const getNormalOptionsFilter: SelectProps["filterOption"] = (input, option) => {
  if (option?.label) {
    return (
      option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  } else {
    return false;
  }
};
export default getNormalOptionsFilter;
