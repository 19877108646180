/**
 * @generated SignedSource<<bece7f0dedfac6e33f6c7aae6fab2d8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type lang_code_enum = "en" | "es" | "pt" | "%future added value";
export type user_set_input = {
  allow_mobile_login?: boolean | null | undefined;
  allow_reset_password?: boolean | null | undefined;
  birth_date?: string | null | undefined;
  childhood_friend_first_name?: string | null | undefined;
  created_at?: string | null | undefined;
  created_password?: boolean | null | undefined;
  email?: string | null | undefined;
  email_privacy_setting_id?: string | null | undefined;
  emergency_contact?: string | null | undefined;
  gender?: string | null | undefined;
  id?: string | null | undefined;
  incorrect_login_try_count?: number | null | undefined;
  lang?: lang_code_enum | null | undefined;
  last_mobile_login_at?: string | null | undefined;
  marital_status?: string | null | undefined;
  name?: string | null | undefined;
  password_hash?: string | null | undefined;
  phone_number?: string | null | undefined;
  phone_number_privacy_setting_id?: string | null | undefined;
  profile_picture_id?: string | null | undefined;
  project_privacy_setting_id?: string | null | undefined;
  reference_number?: string | null | undefined;
  role?: string | null | undefined;
  timezone?: string | null | undefined;
  updated_at?: string | null | undefined;
  username?: string | null | undefined;
};
export type general_contractor_employee_set_input = {
  created_at?: string | null | undefined;
  daily_submittal_summary_enabled?: boolean | null | undefined;
  general_contractor_id?: string | null | undefined;
  is_corporate_admin?: boolean | null | undefined;
  oac_company_id?: string | null | undefined;
  oac_title_id?: string | null | undefined;
  title_id?: string | null | undefined;
  uid?: string | null | undefined;
  updated_at?: string | null | undefined;
};
export type useOrientationRegisterUser_OACUser_Mutation$variables = {
  _set: general_contractor_employee_set_input;
  projectId: string;
  userId: string;
  userSet: user_set_input;
};
export type useOrientationRegisterUser_OACUser_Mutation$data = {
  readonly insert_project_employee_one: {
    readonly id: string;
  } | null | undefined;
  readonly update_general_contractor_employee_by_pk: {
    readonly uid: string;
  } | null | undefined;
  readonly update_user_by_pk: {
    readonly id: string;
  } | null | undefined;
};
export type useOrientationRegisterUser_OACUser_Mutation = {
  response: useOrientationRegisterUser_OACUser_Mutation$data;
  variables: useOrientationRegisterUser_OACUser_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userSet"
},
v4 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "uid",
        "variableName": "userId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "employee_id",
          "variableName": "userId"
        },
        {
          "kind": "Variable",
          "name": "project_id",
          "variableName": "projectId"
        }
      ],
      "kind": "ObjectValue",
      "name": "object"
    },
    {
      "kind": "Literal",
      "name": "on_conflict",
      "value": {
        "constraint": "project_employee_project_id_employee_id_key",
        "update_columns": ([]/*: any*/)
      }
    }
  ],
  "concreteType": "project_employee",
  "kind": "LinkedField",
  "name": "insert_project_employee_one",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "_set",
      "variableName": "userSet"
    },
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "userId"
        }
      ],
      "kind": "ObjectValue",
      "name": "pk_columns"
    }
  ],
  "concreteType": "user",
  "kind": "LinkedField",
  "name": "update_user_by_pk",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useOrientationRegisterUser_OACUser_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "general_contractor_employee",
        "kind": "LinkedField",
        "name": "update_general_contractor_employee_by_pk",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useOrientationRegisterUser_OACUser_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "general_contractor_employee",
        "kind": "LinkedField",
        "name": "update_general_contractor_employee_by_pk",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/),
      (v9/*: any*/)
    ]
  },
  "params": {
    "cacheID": "f39ce4656c0cee31f58528f851535a6e",
    "id": null,
    "metadata": {},
    "name": "useOrientationRegisterUser_OACUser_Mutation",
    "operationKind": "mutation",
    "text": "mutation useOrientationRegisterUser_OACUser_Mutation(\n  $userId: uuid!\n  $userSet: user_set_input!\n  $projectId: uuid!\n  $_set: general_contractor_employee_set_input!\n) {\n  update_general_contractor_employee_by_pk(pk_columns: {uid: $userId}, _set: $_set) {\n    uid\n    id\n  }\n  insert_project_employee_one(object: {project_id: $projectId, employee_id: $userId}, on_conflict: {constraint: project_employee_project_id_employee_id_key, update_columns: []}) {\n    id\n  }\n  update_user_by_pk(pk_columns: {id: $userId}, _set: $userSet) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "54f46b502418fdbb9de3fb258690d403";

export default node;
