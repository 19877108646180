import {
  translationStringOptions,
  translationStrings,
  TranslationValue,
} from "../translationStrings";
import { CurrentLangStrings, Language } from "./i18nTypes";
import { DEFAULT_LANGUAGE } from "../languages";
import stringToLanguage from "./stringToLanguage";

const stringCache: { [key in Language]?: CurrentLangStrings } = {};

export const getLangStrings: (langCode?: string) => CurrentLangStrings = (
  langCode = DEFAULT_LANGUAGE
) => {
  const lang = stringToLanguage(langCode) ?? DEFAULT_LANGUAGE;
  const res = stringCache[lang];
  if (res) return res;

  const long: { [k: string]: string | Function } = {};
  const options: { [k: string]: { [l: string]: string } } = {};

  Object.entries(translationStringOptions).forEach(([key, optionTypes], j) => {
    if (typeof optionTypes === "function") {
      throw new Error(
        `Error useLangStrings: langStrings short key ${key} is a function. short values should only be objects of type { en: string, es: string, pt: string,it:string,fr:string,zh_hans:string }.`
      );
    }
    options[key] = {};
    Object.entries(optionTypes).forEach(([type, val]) => {
      options[key][type] = val[lang];
    });
  });
  Object.entries<TranslationValue | ((...arg: any[]) => TranslationValue)>(
    translationStrings
  ).forEach(([key, val]) => {
    if (typeof val === "function") {
      long[key] = (...args: Parameters<typeof val>) => {
        return val(...args)[lang];
      };
    } else {
      long[key] = val[lang];
    }
  });
  const newRes = {
    strings: long,
    options,
  } as CurrentLangStrings;
  stringCache[lang] = newRes;
  return newRes;
};
