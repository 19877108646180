import React from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";
import GCReportsPermitsSettings from "src/domain-features/sitesafety/entryRoutes/gcProjectReports/routes/permits/components/settings/GCReportsPermitsSettings";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
const GCCorporateSettingsPermits: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => {
  return (
    <BasicWrapper>
      <GCReportsPermitsSettings corporateLevel />
    </BasicWrapper>
  );
};

export default withCustomSuspense(
  withCorporateAdminWrapper(GCCorporateSettingsPermits),
);
