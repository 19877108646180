import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { CertificationCardQuery } from "src/common/types/generated/relay/CertificationCardQuery.graphql";
import CertificationCardLayout from "./CertificationCardLayout";

const CertificationCard: FC<{ worker_cert_id: string }> = withCustomSuspense(
  ({ worker_cert_id }) => {
    const [fetchKey, setFetchKey] = useState(0);
    const data = useLazyLoadQuery<CertificationCardQuery>(
      graphql`
        query CertificationCardQuery($worker_cert_id: uuid!) {
          worker_certification_connection(
            where: { id: { _eq: $worker_cert_id } }
            first: 1
          )
            @connection(
              key: "CertificationCard_worker_certification_connection"
              filters: ["where"]
            ) {
            edges {
              node {
                id
                pk: id @__clientField(handle: "pk")
                issued_on
                expires_on
                created_at
                certification {
                  name
                }
                images(order_by: { description: desc_nulls_last }) {
                  url
                  description
                }
                verification {
                  created_at
                  uploaded_by_user {
                    ...UserTitleFrag @relay(mask: false)
                  }
                  front_image {
                    url
                  }
                  back_image {
                    url
                  }
                  project {
                    name
                    general_contractor {
                      name
                    }
                  }
                  verified_at
                  verified_by_uid
                }
              }
            }
          }
        }
      `,
      { worker_cert_id },
      { fetchKey, fetchPolicy: "network-only" }
    );
    return (
      <CertificationCardLayout
        cert={data.worker_certification_connection.edges[0].node}
        refetch={() => setFetchKey((i) => i + 1)}
      />
    );
  }
);

export default CertificationCard;
