/**
 * @generated SignedSource<<eef7c039ba45dbf1a06a900ce27898fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InspectionInstanceTable_inspection_instances$data = {
  readonly inspection_instance_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly created_at: string;
        readonly created_by_uid: string;
        readonly created_by_user: {
          readonly name: string;
        };
        readonly id: string;
        readonly image_required: boolean;
        readonly inspection_instance_checklist_items: ReadonlyArray<{
          readonly correct_answer: string;
          readonly description: {
            readonly en: string;
            readonly id: string;
          };
          readonly description_id: string;
          readonly id: string;
          readonly inspection_checklist_emails: ReadonlyArray<{
            readonly user: {
              readonly name: string;
            };
            readonly user_id: string;
          }>;
          readonly inspection_checklist_item_type: {
            readonly id: string;
            readonly pk: string;
            readonly text: {
              readonly en: string;
              readonly id: string;
            };
          } | null | undefined;
          readonly inspection_template_checklist_item_id: string | null | undefined;
          readonly item_id: string;
          readonly notify_on_deficient: boolean;
          readonly pk: string;
          readonly show_na: boolean;
          readonly sort_index: number;
        }>;
        readonly inspection_instance_qr_codes: ReadonlyArray<{
          readonly inspection_qr_code: {
            readonly id: string;
            readonly name: string;
            readonly pk: string;
          };
          readonly inspection_qr_code_id: string;
        }>;
        readonly inspection_labels: ReadonlyArray<{
          readonly id: string;
          readonly label: {
            readonly label_name: string;
          };
          readonly label_id: string;
        }>;
        readonly inspection_results: ReadonlyArray<{
          readonly created_at: string;
          readonly user: {
            readonly name: string;
          } | null | undefined;
        }>;
        readonly inspection_results_aggregate: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
        readonly inspection_template: {
          readonly general_contractor_id: string | null | undefined;
        } | null | undefined;
        readonly inspection_template_id: string | null | undefined;
        readonly is_multi_inspection: boolean;
        readonly model_number: string | null | undefined;
        readonly model_number_required: boolean;
        readonly name: {
          readonly en: string;
          readonly id: string;
        };
        readonly name_id: string;
        readonly pk: string;
        readonly project: {
          readonly name: string;
        };
      };
    }>;
  };
  readonly " $fragmentType": "InspectionInstanceTable_inspection_instances";
};
export type InspectionInstanceTable_inspection_instances$key = {
  readonly " $data"?: InspectionInstanceTable_inspection_instances$data;
  readonly " $fragmentSpreads": FragmentRefs<"InspectionInstanceTable_inspection_instances">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "inspection_instance_connection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  (v1/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v8 = {
  "deleted_at": {
    "_is_null": true
  }
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "user",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./InspectionInstanceTableRefetchableQuery.graphql')
    }
  },
  "name": "InspectionInstanceTable_inspection_instances",
  "selections": [
    {
      "alias": "inspection_instance_connection",
      "args": null,
      "concreteType": "inspection_instanceConnection",
      "kind": "LinkedField",
      "name": "__InspectionInstanceTable_inspection_instance_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "inspection_instanceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "inspection_instance",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "inspection_template_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "text_translation",
                  "kind": "LinkedField",
                  "name": "name",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "project",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "is_multi_inspection",
                  "storageKey": null
                },
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "model_number",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "model_number_required",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "image_required",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_by_uid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user",
                  "kind": "LinkedField",
                  "name": "created_by_user",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "inspection_template",
                  "kind": "LinkedField",
                  "name": "inspection_template",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "general_contractor_id",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "where",
                      "value": {
                        "inspection_qr_code": (v8/*: any*/)
                      }
                    }
                  ],
                  "concreteType": "inspection_instance_qr_code",
                  "kind": "LinkedField",
                  "name": "inspection_instance_qr_codes",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "inspection_qr_code_id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "inspection_qr_code",
                      "kind": "LinkedField",
                      "name": "inspection_qr_code",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/),
                        (v1/*: any*/),
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "inspection_instance_qr_codes(where:{\"inspection_qr_code\":{\"deleted_at\":{\"_is_null\":true}}})"
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "order_by",
                      "value": {
                        "label": {
                          "label_name": "asc"
                        }
                      }
                    }
                  ],
                  "concreteType": "inspection_label",
                  "kind": "LinkedField",
                  "name": "inspection_labels",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "inspection_label_type",
                      "kind": "LinkedField",
                      "name": "label",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "label_name",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "label_id",
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": "inspection_labels(order_by:{\"label\":{\"label_name\":\"asc\"}})"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "inspection_result_aggregate",
                  "kind": "LinkedField",
                  "name": "inspection_results_aggregate",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "inspection_result_aggregate_fields",
                      "kind": "LinkedField",
                      "name": "aggregate",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "count",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "limit",
                      "value": 1
                    },
                    {
                      "kind": "Literal",
                      "name": "order_by",
                      "value": {
                        "created_at": "desc"
                      }
                    }
                  ],
                  "concreteType": "inspection_result",
                  "kind": "LinkedField",
                  "name": "inspection_results",
                  "plural": true,
                  "selections": [
                    (v7/*: any*/),
                    (v9/*: any*/)
                  ],
                  "storageKey": "inspection_results(limit:1,order_by:{\"created_at\":\"desc\"})"
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "order_by",
                      "value": {
                        "sort_index": "asc"
                      }
                    },
                    {
                      "kind": "Literal",
                      "name": "where",
                      "value": (v8/*: any*/)
                    }
                  ],
                  "concreteType": "inspection_instance_checklist_item",
                  "kind": "LinkedField",
                  "name": "inspection_instance_checklist_items",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "notify_on_deficient",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sort_index",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "show_na",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "item_id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description_id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "correct_answer",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "text_translation",
                      "kind": "LinkedField",
                      "name": "description",
                      "plural": false,
                      "selections": (v4/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "inspection_template_checklist_item_id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "order_by",
                          "value": {
                            "user": {
                              "name": "asc"
                            }
                          }
                        }
                      ],
                      "concreteType": "inspection_checklist_email",
                      "kind": "LinkedField",
                      "name": "inspection_checklist_emails",
                      "plural": true,
                      "selections": [
                        (v9/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "user_id",
                          "storageKey": null
                        }
                      ],
                      "storageKey": "inspection_checklist_emails(order_by:{\"user\":{\"name\":\"asc\"}})"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "inspection_checklist_item_type",
                      "kind": "LinkedField",
                      "name": "inspection_checklist_item_type",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "text_translation",
                          "kind": "LinkedField",
                          "name": "text",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "inspection_instance_checklist_items(order_by:{\"sort_index\":\"asc\"},where:{\"deleted_at\":{\"_is_null\":true}})"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "8f3482447a12c1929612374fb62344ad";

export default node;
