import { Navigate, Outlet } from "react-router-dom";
import RequireRoleWrapper from "src/common/components/ReqiureRoleWrapper";
import NestedRoute from "src/common/types/manual/NestedRoute";
import GCCorporate from "./GCCorporate";
import GCCorporateTeam from "./team/GCCorporateTeam";
import gcSettingsNavigationRoute from "./settings/gcSettingsNavigationRoute";
import GCCorporateProjects from "./projects/GCCorporateProjects";
import GCCorporateWorkers from "./worker/GCCorporateWorkers";

const gcCorporateRoute: NestedRoute = {
  element: (
    <RequireRoleWrapper requiredRole="employee">
      <Outlet />
    </RequireRoleWrapper>
  ),
  children: [
    {
      path: "",
      element: <GCCorporate />,
      children: [
        { path: "", element: <Navigate to="settings" /> },
        { path: "projects", element: <GCCorporateProjects /> },
        { path: "teams", element: <GCCorporateTeam /> },
        { path: "workers", element: <GCCorporateWorkers /> },
        {
          path: "settings",
          ...gcSettingsNavigationRoute,
        },
      ],
    },
  ],
};
export default gcCorporateRoute;
