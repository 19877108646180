import React from "react";
import SiteBoardBase from "../../../../components/SiteBoardBase";
import SiteBoardList from "../../../../components/SiteBoardList";
import SiteBoardDelivery, { SiteBoardDeliveryProps } from "./SiteBoardDelivery";
import dayjs from "dayjs";

interface RelativeDateLabelProps {
  label?: string;
  count: number;
}

const RelativeDateLabel: React.FunctionComponent<RelativeDateLabelProps> = (
  props,
) => {
  return props.count <= 0 ? null : props.label ? (
    <div className="flex -mb-1.5 flex-row justify-start">
      <span
        className={`text-2 font-accent text-white pb-0.75 px-1.5 z-10 rounded-br-1.5 inline capitalize`}
        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        {props.label}
        <span className="font-normal">{` (${props.count})`}</span>
      </span>
    </div>
  ) : (
    <div className="h-0.5"></div>
  );
};

export type SiteBoardDeliveryType = SiteBoardDeliveryProps;

interface SiteBoardDeliveriesUIProps {
  data: SiteBoardDeliveryType[];
}

const SiteBoardDeliveriesUI: React.FunctionComponent<
  SiteBoardDeliveriesUIProps
> = ({ data }) => {
  const today = dayjs().startOf("day");
  data = data.filter(({ date }) => date.getTime() > today.valueOf());
  data.sort((a, b) => (a.date.getTime() -  b.date.getTime()));

  const todayDeilveries: React.ReactNode[] = [];
  const thisWeekDeliveries: React.ReactNode[] = [];
  const thisMonthDeliveries: React.ReactNode[] = [];
  const laterDeliveries: React.ReactNode[] = [];  

  data.forEach((deliveryData) => {
    if (today.isSame(deliveryData.date, "day")) {
      todayDeilveries.push(<SiteBoardDelivery {...deliveryData} />);
    } else if (today.isSame(deliveryData.date, "week")) {
      thisWeekDeliveries.push(<SiteBoardDelivery {...deliveryData} />);      
    } else if (today.isSame(deliveryData.date, "month")) {
      thisMonthDeliveries.push(<SiteBoardDelivery {...deliveryData} />);
    } else {
      laterDeliveries.push(<SiteBoardDelivery {...deliveryData} />);
    }
  });

  const list = [
    <RelativeDateLabel count={todayDeilveries.length} label={`today`} />,
    ...todayDeilveries,
    <RelativeDateLabel label="this week" count={thisWeekDeliveries.length} />,
    ...thisWeekDeliveries,
    <RelativeDateLabel label="this month" count={thisMonthDeliveries.length} />,
    ...thisMonthDeliveries,
    <RelativeDateLabel count={laterDeliveries.length} />,
    ...laterDeliveries,
  ];

  //today, this week, this month

  list.unshift();
  return (
    <SiteBoardBase>
      <SiteBoardList hideCounter {...{ list, title: "🚚 Deliveries" }} />
    </SiteBoardBase>
  );
};

export default SiteBoardDeliveriesUI;
