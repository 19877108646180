import React from "react";
import PeopleListItem, { PeopleListItemProps } from "./PeopleListItem";
import { SortOrderType } from "../../../components/SortingTypePicker";
import PeopleListSecondary, {
  PeopleListSecondaryProps,
} from "../people-list-secondary/PeopleListSecondary";

export type SortingOrder = SortOrderType;

type Item = Omit<PeopleListItemProps, "active"> & { id: string };

/** @property items[list item][content]  */
interface PeopleListProps {
  items: Item[];
  selectedItemId?: string;
  secondaryList?: PeopleListSecondaryProps;
  secondaryListTitle?: string;
}

const PeopleList: React.FunctionComponent<PeopleListProps> = (props) => {
  return (
    <div className="flex flex-col flex-1 overflow-y-auto flex-column margin-gap-y-2">
      <div className="flex flex-col gap-1">
        <div className="grid grid-cols-1 margin-gap-y-0.25">
          {props.items.map(({ content, id, onClick, avatarImage }) => (
            <PeopleListItem
              key={id}
              {...{
                onClick,
                content,
                active: props.selectedItemId === id,
                avatarImage,
              }}
            />
          ))}
        </div>
        {!!props.secondaryList?.items.length && (
          <div className="flex flex-col gap-0.5">
            {props.secondaryListTitle && (
              <p className="font-accent">{`${props.secondaryListTitle}:`}</p>
            )}
            <PeopleListSecondary
              {...{
                items: props.secondaryList.items,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PeopleList;
