import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { graphql } from "babel-plugin-relay/macro";
import { useSiteOrientationInPersonWorkerUpdateHH_UpdateHHMutation } from "src/common/types/generated/relay/useSiteOrientationInPersonWorkerUpdateHH_UpdateHHMutation.graphql";

const useSiteOrientationInPersonWorkerUpdateHH = () => {
  const [updateHHMutation, loading] =
    useAsyncMutation<useSiteOrientationInPersonWorkerUpdateHH_UpdateHHMutation>(graphql`
      mutation useSiteOrientationInPersonWorkerUpdateHH_UpdateHHMutation(
        $workerId: uuid!
        $projectId: uuid!
        $hardHat: String!
      ) {
        update_project_worker(
          where: {
            project: { orientation_project_id: { _eq: $projectId } }
            worker_id: { _eq: $workerId }
          }
          _set: { hard_hat_number: $hardHat }
        ) {
          returning {
            id @__clientField(handle: "pk")
            hard_hat_number
          }
        }
      }
    `);

  const updateHH = async (
    workerId: string,
    projectId: string,
    hardHatNumber: string,
  ) => {
    return await updateHHMutation({
      variables: {
        workerId,
        projectId,
        hardHat: hardHatNumber + "",
      },
    });
  };

  return {
    updateHH,
    loading,
  };
};

export default useSiteOrientationInPersonWorkerUpdateHH;
