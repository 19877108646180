import React, { useEffect } from "react";
import OrientationCompletionMsg, {
  OrientationCompletionMsgProps,
} from "../entryRoutes/remote/components/OrientationCompletionMsg";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import SiteOrientationInPersonWorkerRegistrationStepsCompletedUI from "../entryRoutes/inPerson/routes/worker/components/steps/completed/SiteOrientationInPersonWorkerRegistrationStepsCompletedUI";
import sendHotjarEvent from "src/utility-features/event-tracking/hotjar/sendHotjarEvent";

export interface OnlineOrientationCompletionProps
  extends OrientationCompletionMsgProps {
  canLeaveWhenPrompted?: boolean;
  hasUniversal?: boolean;
  onDone?: () => void;
}

const OnlineOrientationCompletion: React.FC<
  OnlineOrientationCompletionProps
> = (props) => {
  useEffect(() => {
    sendHotjarEvent("orientation_completed");
  }, []);
  return (
    <SiteFeatureStepLayout
      nextButtonText={`Done`}
      onNextButtonClick={props.onDone}
    >
      {props.type === "inperson" || props.type === "inpersonComplete" ? (
        <div className={`flex flex-col justify-center min-h-full`}>
          <SiteOrientationInPersonWorkerRegistrationStepsCompletedUI
            language={props.lang || "en"}
            workerCanLeaveWhenPrompted={props.canLeaveWhenPrompted || false}
            hasUniversal={props.hasUniversal}
            onAnimationFinished={() => {}}
          />
        </div>
      ) : (
        <OrientationCompletionMsg {...props} />
      )}
    </SiteFeatureStepLayout>
  );
};

export default OnlineOrientationCompletion;
