const getIncidentTypeName = (type: string) => {
  switch (type) {
    case "incident_detail":
      return "Incident Detail";
    case "injury_detail":
      return "Injury Detail";
    case "root_cause":
      return "Root Cause Analysis";
    case "environmental":
      return "Environmental ";
    case "equipment":
      return "Equipment";
    case "near_miss":
      return "Near Miss";
    case "property_damage":
      return "Property Damage";
    case "theft":
      return "Theft/Vandalism";
    case "utility":
      return "Utility/Service Strike";
    default:
      return "Vehicle/Road Incident";
  }
};

export default getIncidentTypeName;
