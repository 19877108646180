import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";
import SiteBoardOnsiteColumn from "./SiteBoardOnsiteColumn";

export interface SiteBoardOnsiteWorkersColumnProps {
  headers: {
    date: dayjs.Dayjs;
    totalAmount: number;
  }[];
  workerAmountRows: [number, number][];
}

const NumberEl: React.FC<{
  children: number;
  secondary?: boolean;
}> = (props) => (
  <div
    className={clsx(
      `flex-1 justify-center relative z-10 w-full flex items-center text-3 h-full`,
      props.secondary ? "text-2 px-0.25" : "text-3 px-0.5",
    )}
  >
    <p className={clsx(props.children === 0 && "text-white opacity-40")}>
      {props.children}
    </p>
  </div>
);

const SiteBoardOnsiteWorkersColumn: React.FC<
  SiteBoardOnsiteWorkersColumnProps
> = (props) => {
  const today = dayjs();
  return (
    <SiteBoardOnsiteColumn
      header={
        <div className={` text-white flex flex-col gap-0 w-full`}>
          <div
            className={`flex flex-row justify-between text-center items-end`}
          >
            <div className={`w-[calc(44%-1.5rem)] flex flex-col items-center`}>
              <p className={``}>{`Workers on ${props.headers[0].date.format(
                "dddd",
              )}`}</p>
              <p className={`text-2`}>{`${props.headers[0].totalAmount}`}</p>
            </div>

            <div className={`w-[calc(56%-1.5rem)]`}>
              <p>
                {`Workers ${
                  props.headers[1].date.isSame(today, "day")
                    ? "Today"
                    : `${props.headers[1].date.format("ddd")}`
                }`}
              </p>
              <p className={`text-2`}>{`${props.headers[1].totalAmount}`}</p>
            </div>
          </div>
          <div
            className={`flex text-1 font-accent whitespace-nowrap w-full justify-between flex-row gap-0.5 items-center`}
          >
            <div
              className={`flex-1 flex text-1.5 flex-col items-center justify-center`}
            ></div>
            <div
              className={`flex-1 flex text-2 flex-col items-center justify-center`}
            ></div>
          </div>
        </div>
      }
      bodyRows={props.workerAmountRows.map((workersAmountRow) => {
        const delta = workersAmountRow[1] - workersAmountRow[0];
        return (
          <div
            className={`text-white relative h-full w-full flex flex-row items-center `}
          >
            <div className={`w-6`}>
              <NumberEl secondary>{workersAmountRow[0]}</NumberEl>
            </div>
            <div
              className={`h-full z-0 w-3 relative flex flex-col items-center justify-center`}
            >
              <div
                className={clsx(
                  "relative z-10 text-1.5 font-accent px-0.5 rounded-full",
                  delta > 0
                    ? "bg-semantic-positive-dark text-semantic-positive"
                    : delta === 0
                    ? `bg-semantic-inactive-dark text-semantic-inactive`
                    : `bg-semantic-negative-dark   text-semantic-negative `,
                )}
              >
                {delta !== 0 ? `${delta > 0 ? "+" : ""}${delta}` : `=`}
              </div>
              <div
                className={clsx(
                  delta > 0
                    ? "bg-semantic-positive-light"
                    : delta === 0
                    ? "bg-semantic-inactive-light"
                    : "bg-semantic-negative-light",
                  `absolute inset-y-0 z-0 w-px h-full `,
                )}
              ></div>
            </div>
            <div className={`w-8`}>
              <NumberEl>{workersAmountRow[1]}</NumberEl>
            </div>
          </div>
        );
      })}
    />
  );
};

export default SiteBoardOnsiteWorkersColumn;
