import React, { FC } from "react";
import { Popconfirm } from "antd";
import styled from "styled-components";
import noPropagation from "src/common/functions/noPropagation";

export default styled(Popconfirm).attrs((props) => ({
  ...(props.title ? {} : { title: "Are you sure?" }),
  okText: "Yes",
  cancelText: "Cancel",
  onConfirm: noPropagation(props.onConfirm),
  onCancel: noPropagation(props.onCancel),
  ...props,
}))``;
