import React, { useCallback, FC, useEffect, useRef, useState } from "react";
import { Button, Descriptions, message, Select, Space, Spin } from "antd";
import dayjs from "dayjs";
import LoadingContent from "src/common/components/general/LoadingContent";
import StyledContent from "src/common/components/layouts/StyledContent";
import {
  GetAdminProjectDocument,
  Project_Billing_Settings_Insert_Input,
  Project_Billing_Settings_Update_Column,
  useCreateCustomGcProjectInvoiceMutation,
  useGetAdminProjectQuery,
  useGetProjectLocationsQuery,
  Project_Setting_Set_Input,
  GetAdminProjectQueryVariables,
  useGetGcSubsQuery,
  useGetGeneralContractorEmployeesQuery,
  useInsertProjectEmployeesMutation,
  useInsertProjectSubcontractorsMutation,
  useUpdateAdminProjectSettingMutation,
  useUpsertProjectBillingSettingsMutation,
  GetAdminProjectQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import ProjectProps from "src/common/types/manual/ProjectProps";

import { useNavigate } from "react-router-dom";
import PriceSelector, {
  getPaymentModelDescription,
  PriceSelectorRef,
} from "src/common/components/tables/PriceSelector";
import SiteAlertPriceSelector, {
  SiteAlertPriceSelectorRef,
} from "../components/SiteAlertPriceSelector";
import CreateCustomInvoiceModal from "../subcontractors/CreateCustomInvoiceModal";
import { getProjectPriceDescription } from "../general-contractors/AdminGeneralContractorBillingSection";
import { stripeDashboardLink } from "src/common/functions/stripeDashboardUrl";
import SiteBoardPriceSelector, {
  SiteBoardPriceSelectorRef,
} from "../components/SiteBoardPriceSelector";
import ProjSubsTable from "./components/ProjSubsTable";
import ProjEmployeesTable from "./components/ProjEmployeesTable";
import ProjectLocations from "../../../general-contractor/projects/settings/ProjectLocations";
import ProjBillableWorkers from "./components/ProjBillableWorkers";
import { SwitchWithText } from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/settings/components/SiteOrientationSettingSwitchCards";
import { useSuspenseQuery } from "@apollo/client";

const { Item } = Descriptions;

function getPriceLevelDescription(useProjectPrice: boolean): string {
  if (useProjectPrice) {
    return "Use project secific price";
  } else {
    return "Use default general contractor price";
  }
}

// TODO add page size
// MARK when project_sub gets removed, associated project_crews dont, cause no relation on project_sub
const AdminProject: FC<ProjectProps> = ({ projectId }) => {
  const { data, loading } = useGetAdminProjectQuery({
    variables: { projectId },
  });
  const {
    data: locationsData,
    loading: locationsLoading,
    error,
  } = useGetProjectLocationsQuery({
    variables: { where: { project_id: { _eq: projectId } } },
  });
  const [upsertProjectBillingSettings, { loading: updatingBillingSettings }] =
    useUpsertProjectBillingSettingsMutation();

  const gcPriceSelectorRef = useRef<PriceSelectorRef>(null);
  const subPriceSelectorRef = useRef<PriceSelectorRef>(null);
  const siteAlertPriceSelectorRef = useRef<SiteAlertPriceSelectorRef>(null);
  const siteBoardPriceSelectorRef = useRef<SiteBoardPriceSelectorRef>(null);
  const [createInvoiceVisible, setCreateInvoiceVisible] = useState(false);
  const [updateAdminProjectSetting] = useUpdateAdminProjectSettingMutation();
  const [createStripeGcProjectInvoice, { loading: creatingProjectInvoice }] =
    useCreateCustomGcProjectInvoiceMutation();

  const [priceEditing, setPriceEditing] = useState(false);
  const [isProjectPrice, setIsProjectPrice] = useState(false);
  const [paymentModel, setPaymentModel] = useState("");

  const project = data?.project_by_pk;
  const gc_billing_settings = project?.general_contractor.billing_settings;
  const project_billing_settings = project?.billing_settings;
  const use_project_price = !!project_billing_settings?.use_project_level_price;
  type NonNullableType<T> = {
    [P in keyof T]: T[P] extends infer R | null | undefined ? R : T[P];
  };
  useEffect(() => {
    if (!gc_billing_settings) return;
    setIsProjectPrice(use_project_price);
    setPaymentModel(
      use_project_price
        ? project_billing_settings.payment_model
        : gc_billing_settings.payment_model,
    );
  }, [project_billing_settings, gc_billing_settings, data]);

  if (loading) {
    return <LoadingContent />;
  }

  if (!project) {
    return (
      <StyledContent backgroundColor="transparent">
        Project is not found
      </StyledContent>
    );
  }

  const projSetting = project.project_setting;
  if (!projSetting) throw new Error("Project setting not found for project");
  const updateProjectSetting = (
    _set: NonNullableType<Project_Setting_Set_Input>,
  ) =>
    updateAdminProjectSetting({
      variables: { projectId: projSetting.project_id, _set },
      optimisticResponse: {
        update_project_setting_by_pk: {
          ...projSetting,
          ..._set,
        },
      },
    });

  const billing_settings = isProjectPrice // change this to state variable so when changed during editing it shows correct price
    ? project_billing_settings
    : gc_billing_settings;

  const invoiceSettings = {
    description: gc_billing_settings?.invoice_description,
    footer: gc_billing_settings?.invoice_footer,
    default_billing_email: gc_billing_settings?.default_billing_email,
  };
  const applyPrice = async () => {
    try {
      const object: Project_Billing_Settings_Insert_Input = {
        project_id: projectId,
        use_project_level_price: isProjectPrice,
        payment_model: paymentModel,
        gc_price_type: "per_worker",
        sub_price_type: "per_worker",
      };
      console.log("save price mode", paymentModel);
      const columns: Array<Project_Billing_Settings_Update_Column> = [
        Project_Billing_Settings_Update_Column.UseProjectLevelPrice,
        Project_Billing_Settings_Update_Column.PaymentModel,
      ];
      if (siteBoardPriceSelectorRef.current) {
        const currentsiteboardPrices =
          siteBoardPriceSelectorRef.current.getEditedPrice();
        object.siteboard_enabled = currentsiteboardPrices.enabled || false;
        columns.push(Project_Billing_Settings_Update_Column.SiteboardEnabled);
        if (isProjectPrice) {
          object.siteboard_price = currentsiteboardPrices.price;
          object.raspbery_pi_price =
            currentsiteboardPrices.raspberyDeviceOneTimePrice;
          object.siteboard_price_type = currentsiteboardPrices.priceType;
          columns.push(
            Project_Billing_Settings_Update_Column.RaspberyPiPrice,
            Project_Billing_Settings_Update_Column.SiteboardPrice,
            Project_Billing_Settings_Update_Column.SiteboardPriceType,
          );
        }
      }
      if (siteAlertPriceSelectorRef.current) {
        const currentSiteAlertPrices =
          siteAlertPriceSelectorRef.current.getEditedPrice();
        object.text_messaging_enabled = currentSiteAlertPrices.enabled || false;
        columns.push(
          Project_Billing_Settings_Update_Column.TextMessagingEnabled,
        );
        if (isProjectPrice) {
          object.base_sms_pack_count = currentSiteAlertPrices.baseCount;
          object.base_sms_pack_price = currentSiteAlertPrices.basePrice;
          object.over_base_sms_count = currentSiteAlertPrices.overCount;
          object.over_base_sms_price = currentSiteAlertPrices.overPrice;
          object.text_messaging_price_type = currentSiteAlertPrices.priceType;
          columns.push(
            Project_Billing_Settings_Update_Column.OverBaseSmsCount,
            Project_Billing_Settings_Update_Column.OverBaseSmsPrice,
            Project_Billing_Settings_Update_Column.BaseSmsPackCount,
            Project_Billing_Settings_Update_Column.BaseSmsPackPrice,
            Project_Billing_Settings_Update_Column.TextMessagingPriceType,
          );
        }
      }

      if (isProjectPrice) {
        if (paymentModel !== "free") {
          if (gcPriceSelectorRef.current) {
            const gcPrice = gcPriceSelectorRef.current.getEditedPrice();
            object.gc_price_type = gcPrice.type;
            object.gc_project_fixed_price = gcPrice.projectFixedPrice;
            object.gc_worker_price_first = gcPrice.workerPriceFirst;
            object.gc_worker_price_others = gcPrice.workerPriceSecond;
            columns.push(
              Project_Billing_Settings_Update_Column.GcPriceType,
              Project_Billing_Settings_Update_Column.GcProjectFixedPrice,
              Project_Billing_Settings_Update_Column.GcWorkerPriceFirst,
              Project_Billing_Settings_Update_Column.GcWorkerPriceOthers,
            );
          }
          if (subPriceSelectorRef.current) {
            const subPrice = subPriceSelectorRef.current.getEditedPrice();
            object.sub_price_type = subPrice.type;
            object.sub_project_fixed_price = subPrice.projectFixedPrice;
            object.sub_worker_price_first = subPrice.workerPriceFirst;
            object.sub_worker_price_others = subPrice.workerPriceSecond;
            columns.push(
              Project_Billing_Settings_Update_Column.SubPriceType,
              Project_Billing_Settings_Update_Column.SubProjectFixedPrice,
              Project_Billing_Settings_Update_Column.SubWorkerPriceFirst,
              Project_Billing_Settings_Update_Column.SubWorkerPriceOthers,
            );
          }
        }
      }
      await upsertProjectBillingSettings({
        variables: {
          objects: [object],
          columns,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GetAdminProjectDocument,
            variables: { projectId },
          },
        ],
      }).then(() => {
        setPriceEditing(false);
      });
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  };
  const subslocationsPaymentModelMap = new Map(
    (data.project_subcontractor || []).map((ps) => [
      ps.subcontractor.id,
      new Map(
        ps.project_subcontractor_locations.map((psl) => [
          psl.project_location_id,
          psl.billing_payment_model,
        ]),
      ),
    ]),
  );
  return (
    <StyledContent backgroundColor="transparent">
      {createInvoiceVisible && (
        <CreateCustomInvoiceModal
          companyName={project.general_contractor.name}
          isProjectInvoiceModal
          visible={createInvoiceVisible}
          isGC={true}
          hideProjects={
            project.general_contractor.billing_settings?.gc_price_type ===
            "fixed"
          }
          projects={[project]
            .filter((p) => {
              return (
                billing_settings &&
                (billing_settings.gc_price_type !== "free" ||
                  (project.billing_settings?.text_messaging_enabled &&
                    billing_settings.text_messaging_price_type !== "free" &&
                    project.project_twilio_numbers.filter(
                      (p) => p.verification_status === "twilio_approved",
                    ).length > 0))
              );
            })
            .map((p) => ({
              id: p.id,
              name: p.name,
              gc: project.general_contractor.name,
              price: getProjectPriceDescription(
                p,
                project.general_contractor.billing_settings,
              ),
            }))}
          invSettings={invoiceSettings}
          onSelect={async (
            startDate,
            monthCount,
            projectIds,
            finalize,
            description,
            footer,
            excludeZeroLines,
          ) => {
            if (projectIds.length == 1 && projectIds[0] === projectId)
              return createStripeGcProjectInvoice({
                variables: {
                  input: {
                    gcId: project.general_contractor.id,
                    startDate: startDate.format("YYYY-MM-DD"),
                    monthCount,
                    projectId,
                    finalize,
                    description,
                    footer,
                    excludeZeroLines,
                  },
                },
              }).then(
                (result) => {
                  const invoiceId =
                    result.data?.stripeCreateGCProjectInvoice.stripeInvoiceId;
                  setCreateInvoiceVisible(false);
                  if (invoiceId)
                    message.success(
                      <span>
                        <a
                          target="_blank"
                          href={`${stripeDashboardLink}/invoices/${invoiceId}`}
                        >
                          Invoice(s)
                        </a>{" "}
                        has been created successfully
                      </span>,
                      30,
                    );
                },
                (error) => {
                  message.error(String(error));
                },
              );
          }}
          onCancel={() => {
            setCreateInvoiceVisible(false);
          }}
        />
      )}
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <StyledContent padding backgroundColor="white">
          <Descriptions size="middle" title="Project Info" bordered>
            <Item label="Project Name">
              <b>{project.name}</b>
            </Item>
            <Item label="ID">{project.external_id}</Item>
            <Item label="General Contractor">
              {project.general_contractor.name}
            </Item>
            <Item label="Workers">
              {project.project_workers_aggregate.aggregate?.count ?? 0}
            </Item>
            <Item label="Created On">
              {dayjs(project.created_at).format("ll")}
            </Item>
            <Item label="Address">
              {project.address.line_1}
              <br />
              {project.address.line_2 && (
                <>
                  {project.address.line_2}
                  <br />
                </>
              )}
              {project.address.city}, {project.address.state.name}{" "}
              {project.address.zip_code}
            </Item>
          </Descriptions>
        </StyledContent>

        <StyledContent padding backgroundColor="white">
          {locationsLoading ? (
            <Spin />
          ) : locationsData ? (
            <ProjectLocations
              showPricing
              data={locationsData}
              projectId={projectId}
              projectSubs={data.project_subcontractor.map(
                (s) => s.subcontractor.id,
              )}
            />
          ) : error ? (
            <div className="text-semantic-negative">
              {JSON.stringify(error)}
            </div>
          ) : (
            "Locations Data not found"
          )}
        </StyledContent>
        <ProjSubsTable
          locations={locationsData?.project_location || []}
          projSubs={data.project_subcontractor || []}
          projectId={projectId}
          loading={loading}
        />
        <ProjEmployeesTable
          projEmployees={data.project_employee || []}
          projectId={projectId}
          loading={loading}
        />
        <StyledContent padding backgroundColor="white" id="billing">
          <h3
            style={{
              fontWeight: "bold",
              fontSize: "17px",
              marginBottom: "20px",
            }}
          >
            Billing settings
          </h3>
          {!project.general_contractor.billing_settings || !billing_settings ? (
            <div>
              Billing is not enabled for {project.general_contractor.name} GC
            </div>
          ) : (
            <>
              <Button
                onClick={() => {
                  setCreateInvoiceVisible(true);
                }}
              >
                Create custom invoice
              </Button>
              <br />
              <br />
              {priceEditing ? (
                <Select
                  value={isProjectPrice ? 1 : 0}
                  onChange={(value) => setIsProjectPrice(value != 0)}
                  style={{ width: "300px" }}
                >
                  <Select.Option value={0}>
                    {getPriceLevelDescription(false)}
                  </Select.Option>
                  <Select.Option value={1}>
                    {getPriceLevelDescription(true)}
                  </Select.Option>
                </Select>
              ) : (
                getPriceLevelDescription(isProjectPrice)
              )}
              <SwitchWithText
                text="Turn ON if Site Observation is used on this project"
                onChange={(e) =>
                  updateProjectSetting({ observation_enabled: e })
                }
                checked={projSetting.observation_enabled}
              />
              <SwitchWithText
                text="Turn ON if Incident Management is used on this project"
                onChange={(e) => updateProjectSetting({ incident_enabled: e })}
                checked={projSetting.incident_enabled}
              />
              <SwitchWithText
                text="Turn ON if Site Inspection is used on this project"
                onChange={(e) =>
                  updateProjectSetting({ site_inspection_enabled: e })
                }
                checked={projSetting.site_inspection_enabled}
              />

              <SiteBoardPriceSelector
                ref={siteBoardPriceSelectorRef}
                title={"Project SiteBoard Prices"}
                key={109 + (priceEditing ? 98 : 0) + (isProjectPrice ? 104 : 0)}
                settings={{
                  enabled: project.billing_settings?.siteboard_enabled || false,
                  priceType: billing_settings.siteboard_price_type || "default",
                  raspberyDeviceOneTimePrice:
                    billing_settings.raspbery_pi_price || 34900,
                  price: billing_settings.siteboard_price || 3900,
                }}
                data={data}
                priceEditing={priceEditing}
                editing={priceEditing && isProjectPrice}
                saving={updatingBillingSettings}
                savePrice={applyPrice}
                project={{
                  id: projectId,
                  siteboardScreens: project.siteboard_screens,
                }}
              />
              <SiteAlertPriceSelector
                ref={siteAlertPriceSelectorRef}
                title={"Project SiteAlert Prices"}
                key={8 + (priceEditing ? 2 : 0) + (isProjectPrice ? 1 : 0)}
                project={{
                  name: project.name,
                  id: project.id,
                  twilio_phone: project.project_twilio_numbers[0],
                }}
                settings={{
                  enabled:
                    project.billing_settings?.text_messaging_enabled || false,
                  priceType:
                    billing_settings.text_messaging_price_type || "default",
                  baseCount: billing_settings.base_sms_pack_count || 2500,
                  basePrice: billing_settings.base_sms_pack_price || 3900,
                  overCount: billing_settings.over_base_sms_count || 500,
                  overPrice: billing_settings.over_base_sms_price || 1000,
                }}
                priceEditing={priceEditing}
                editing={priceEditing && isProjectPrice}
                saving={updatingBillingSettings}
                savePrice={applyPrice}
              />
              <div style={{ margin: "20px" }}>
                <span
                  style={{
                    display: "inline-block",
                    padding: "5px",
                    width: "150px",
                    textAlign: "right",
                  }}
                >
                  Billing model:{" "}
                </span>
                {priceEditing && isProjectPrice ? (
                  <Select
                    value={paymentModel}
                    onChange={(value) => setPaymentModel(value)}
                    style={{ width: "300px" }}
                  >
                    {["gc", "sub"].map((model) => (
                      <Select.Option key={model} value={model}>
                        {getPaymentModelDescription(model)}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <span>
                    {getPaymentModelDescription(billing_settings.payment_model)}
                  </span>
                )}
              </div>
              {paymentModel !== "free" && (
                <PriceSelector
                  ref={gcPriceSelectorRef}
                  key={4 + (priceEditing ? 2 : 0) + (isProjectPrice ? 1 : 0)}
                  title={
                    paymentModel === "gc" ? "Project price" : "GC project price"
                  }
                  price={{
                    type: billing_settings.gc_price_type,
                    projectFixedPrice:
                      billing_settings.gc_project_fixed_price ?? 14900,
                    workerPriceFirst:
                      billing_settings.gc_worker_price_first ?? 9900,
                    workerPriceSecond:
                      billing_settings.gc_worker_price_others ?? 20000,
                  }}
                  editing={priceEditing && isProjectPrice}
                  saving={updatingBillingSettings}
                  savePrice={applyPrice}
                />
              )}
              {paymentModel === "sub" && (
                <PriceSelector
                  ref={subPriceSelectorRef}
                  key={(priceEditing ? 2 : 0) + (isProjectPrice ? 1 : 0)}
                  title="Sub project price"
                  price={{
                    type: billing_settings.sub_price_type,
                    projectFixedPrice:
                      billing_settings.sub_project_fixed_price ?? 14900,
                    workerPriceFirst:
                      billing_settings.sub_worker_price_first ?? 9900,
                    workerPriceSecond:
                      billing_settings.sub_worker_price_others ?? 14900,
                  }}
                  editing={priceEditing && isProjectPrice}
                  saving={updatingBillingSettings}
                  savePrice={applyPrice}
                />
              )}
              <div style={{ paddingTop: "10px" }}>
                {priceEditing ? (
                  <Space direction="horizontal">
                    <Button onClick={applyPrice}>Apply</Button>
                    <Button
                      onClick={() => {
                        setIsProjectPrice(use_project_price);
                        setPaymentModel(billing_settings.payment_model);
                        setPriceEditing(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Space>
                ) : (
                  <Button
                    onClick={() => {
                      setPriceEditing(true);
                    }}
                  >
                    Edit Price
                  </Button>
                )}
              </div>
            </>
          )}
        </StyledContent>
      </Space>
      {project.timezone && billing_settings && (
        <ProjBillableWorkers
          projectPrices={{
            paymentModel: paymentModel,
            gc: {
              first: billing_settings.gc_worker_price_first ?? 9900,
              others: billing_settings.gc_worker_price_others ?? 20000,
            },
            sub: {
              first: billing_settings.sub_worker_price_first ?? 9900,
              others: billing_settings.sub_worker_price_others ?? 14900,
            },
          }}
          loading={loading}
          subslocationsPaymentModelMap={subslocationsPaymentModelMap}
          projectId={projectId}
          projectTimezone={project.timezone}
          locations={locationsData?.project_location || []}
        />
      )}
    </StyledContent>
  );
};

export default AdminProject;
