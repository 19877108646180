import React, { FunctionComponent } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLazyLoadQuery } from "react-relay/hooks";
import { graphql } from "babel-plugin-relay/macro";
import { SCToolboxTalksQuery } from "src/common/types/generated/relay/SCToolboxTalksQuery.graphql";
import ReportsTabsGroup, {
  ReportsTabType,
} from "src/common/components/ReportsTabsGroup";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import ToolboxTalkTable from "src/domain-features/sitesafety/components/toolbox-talks/ToolboxTalkTable";
import ManageToolboxTalks from "src/domain-features/sitesafety/components/toolbox-talks/ManageToolboxTalks";
import SCToolboxTalk from "./components/SCToolboxTalk";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";

const SCToolboxTalks: FunctionComponent = () => {
  const tabs: ReportsTabType[] = [
    { id: "manage", label: "Manage Company TBTs" },
    { id: "completed", label: "Completed TBTs" },
  ];

  const subcontractorId = useSubcontractorId();

  const data = useLazyLoadQuery<SCToolboxTalksQuery>(
    graphql`
      query SCToolboxTalksQuery($subcontractorId: uuid!) {
        project_subcontractor_connection(
          where: { subcontractor_id: { _eq: $subcontractorId } }
          order_by: { project: { name: asc } }
        ) {
          edges {
            node {
              project_id
              project {
                name
              }
            }
          }
        }
      }
    `,
    { subcontractorId }
  ).project_subcontractor_connection.edges;
  const { subview } = useParams();
  const navigate = useNavigate();
  const handleReportsTabClick = (tabId: string) => {
    navigate("../" + tabId);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const toolboxTalkId = searchParams.get("toolboxTalkId");
  if (!subview || !subcontractorId) {
    return <></>;
  }

  return (
    <BasicWrapper>
      {toolboxTalkId ? (
        <SCToolboxTalk {...{ subcontractorId, toolboxTalkId }} />
      ) : (
        <>
          <ReportsTabsGroup
            onTabClick={handleReportsTabClick}
            tabs={tabs}
            tabsType="button"
            selectedTabId={subview}
          />
          <div className={`flex-grow bg-white overflow-auto py-1 w-full`}>
            <div className="flex w-full h-full">
              {subview === "completed" && (
                <ToolboxTalkTable
                  showDateRangeFilter={true}
                  excludedKeys={["subcontractor"]}
                  where={{
                    subcontractor_id: {
                      _eq: subcontractorId,
                    },
                  }}
                  onRowClick={(pk) => setSearchParams({ toolboxTalkId: pk })}
                />
              )}
              {subview === "manage" && (
                <ManageToolboxTalks
                  subId={subcontractorId}
                  projectsOption={data.map((ps) => ({
                    name: ps.node.project.name,
                    id: ps.node.project_id,
                  }))}
                />
              )}
            </div>
          </div>
        </>
      )}
    </BasicWrapper>
  );
};

export default SCToolboxTalks;
