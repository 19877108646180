import React from "react";
import Icon, { IconProps, IconType } from "./Icon";
// import Popover from "./Popover";
import { Modal } from "antd";
import Popover, { PopoverProps } from "antd/lib/popover";
import remToPx from "src/common/functions/remToPx";
import { useBoolean } from "usehooks-ts";

interface CounterBallProps {
  count: number;
  interactive: boolean;
}

type CounterIconProps = Pick<IconProps, "color" | "icon">;

const CounterBall: React.FC<CounterBallProps> = (props) => {
  return (
    <span
      className={`select-none absolute flex items-center justify-center leading-fit text-white text-0.75 h-1 min-w-1 px-0.25 rounded-0.5 -top-0.75 -right-0.5 ${
        props.interactive
          ? "bg-interactive-primary"
          : props.count > 0
          ? "bg-static-primary"
          : "bg-static-secondary"
      }`}
    >
      {props.count}
    </span>
  );
};

const CounterIcon: React.FC<CounterIconProps> = (props) => {
  if (!props.icon) return <></>;

  const style = {
    left: "5.5rem",
  } as React.CSSProperties;

  return (
    <span
      className={`absolute flex items-center h-1 px-0.25 -top-0.75`}
      style={style}
    >
      <Icon icon={props.icon} color={props.color} size="small" />
    </span>
  );
};

interface CounterLabelProps {
  count: number;
  label: string;
  icon?: IconType;
  popover?: Pick<PopoverProps, "content" | "placement" | "trigger">;
  modalOnClick?: {
    content: React.ReactNode;
    title?: string;
    width?: number;
  };
  iconColor?:
    | "interactive"
    | "static"
    | "white"
    | "static-secondary"
    | "warn"
    | "negative"
    | "positive"
    | "suplementary-3"
    | "low-severity"
    | "high-severity";
  widthCssClass?: string;
}

const CounterLabel: React.FunctionComponent<CounterLabelProps> = ({
  count,
  label,
  icon,
  popover,
  iconColor,
  widthCssClass,
  modalOnClick,
}) => {
  const {
    setTrue: showModal,
    setFalse: hideModal,
    value: modalVisible,
  } = useBoolean(false);
  const element = (
    <span
      onClick={(e) => {
        showModal();
        e.stopPropagation();
      }}
      className={`relative ${
        !!popover ? "group cursor-pointer select-none" : ""
      } `}
    >
      <span
        className={`${
          !!popover
            ? "group-hover:text-interactive-primary transition-all "
            : ""
        }`}
      >
        {label}
      </span>
      <CounterBall count={count} interactive={!!popover} />
      {icon && <CounterIcon icon={icon} color={iconColor} />}
    </span>
  );

  return popover ? (
    <Popover
      trigger={popover.trigger ?? "click"}
      placement={popover.placement}
      content={popover.content}
    >
      {element}
    </Popover>
  ) : (
    <div className={`flex ${widthCssClass}`}>
      <span
        className={`relative ${
          !!modalOnClick ? "group cursor-pointer select-none" : ""
        } `}
        onClick={(e) => {
          showModal();
          e.stopPropagation();
        }}
      >
        <span
          className={`${
            !!modalOnClick
              ? "group-hover:text-interactive-primary transition-all "
              : ""
          }`}
        >
          {label}
        </span>
        <CounterBall count={count} interactive={!!modalOnClick} />
        {icon && <CounterIcon icon={icon} color={iconColor} />}
      </span>
      {!!modalOnClick && (
        <Modal
          onCancel={(e) => {
            e.stopPropagation();
            hideModal();
          }}
          centered
          title={modalOnClick.title}
          width={remToPx(modalOnClick.width ?? 16)}
          open={modalVisible}
          footer={null}
        >
          <div className="max-h-full overflow-auto">{modalOnClick.content}</div>
        </Modal>
      )}
    </div>
  );
};

export default CounterLabel;
