/**
 * @generated SignedSource<<3887ddfbba0677af813fb101753edb76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type checklist_item_notify_email_insert_input = {
  checklist_item_id?: string | null | undefined;
  email?: string | null | undefined;
  id?: string | null | undefined;
};
export type GCProjectReportsDailySettingsUpdateItemNotifyeesMutation$variables = {
  checklistItemId: string;
  objects: ReadonlyArray<checklist_item_notify_email_insert_input>;
};
export type GCProjectReportsDailySettingsUpdateItemNotifyeesMutation$data = {
  readonly delete_checklist_item_notify_email: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_checklist_item_notify_email: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type GCProjectReportsDailySettingsUpdateItemNotifyeesMutation = {
  response: GCProjectReportsDailySettingsUpdateItemNotifyeesMutation$data;
  variables: GCProjectReportsDailySettingsUpdateItemNotifyeesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "checklistItemId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "checklistItemId"
              }
            ],
            "kind": "ObjectValue",
            "name": "checklist_item_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "checklist_item_notify_email_mutation_response",
    "kind": "LinkedField",
    "name": "delete_checklist_item_notify_email",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      }
    ],
    "concreteType": "checklist_item_notify_email_mutation_response",
    "kind": "LinkedField",
    "name": "insert_checklist_item_notify_email",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectReportsDailySettingsUpdateItemNotifyeesMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCProjectReportsDailySettingsUpdateItemNotifyeesMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "cd6e3320108ad96a1b56602d0cd0a557",
    "id": null,
    "metadata": {},
    "name": "GCProjectReportsDailySettingsUpdateItemNotifyeesMutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectReportsDailySettingsUpdateItemNotifyeesMutation(\n  $checklistItemId: uuid!\n  $objects: [checklist_item_notify_email_insert_input!]!\n) {\n  delete_checklist_item_notify_email(where: {checklist_item_id: {_eq: $checklistItemId}}) {\n    affected_rows\n  }\n  insert_checklist_item_notify_email(objects: $objects) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "842a5154f5970449ee74c679a8cff348";

export default node;
