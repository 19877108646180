import React, { useState } from "react";
import dayjs from "dayjs";
import ScrollMultiSelect from "./ScrollMultiSelect";

// Years array

const currentYear = dayjs().year();
const years = Array.from(Array(100).keys()).map(
  (number) => currentYear + number - 100 + "",
);
// Months array

const months = dayjs.months();

// Days array
let fullMonth = dayjs("2000-01-01", "YYYY-MM-DD");
const days: string[] = [];
for (let i = 0; i < 31; i++) {
  days.push(fullMonth.format("Do"));
  fullMonth = fullMonth.add(1, "day");
}

interface MobileBirthDatePickerProps {
  defaultValue: dayjs.Dayjs | null;
  onValueChange: (newValue: dayjs.Dayjs | null) => void;
}

const MobileBirthDatePicker: React.FC<MobileBirthDatePickerProps> = (props) => {
  const [year, setYear] = useState<string>(
    props.defaultValue?.format("YYYY") ?? years[years.length - 40],
  );
  const [month, setMonth] = useState<string>(
    props.defaultValue?.format("MMMM") ?? months[0],
  );
  const [day, setDay] = useState<string>(
    props.defaultValue?.format("Do") ?? days[0],
  );

  const submitCombinedValues = (values: {
    year?: string;
    month?: string;
    day?: string;
  }) => {
    const newValue = dayjs(
      `${values.month ?? month} ${values.day ?? day} ${values.year ?? year}`,
      "MMMM Do YYYY",
    );
    props.onValueChange(newValue.isValid() ? newValue : null);
  };

  return (
    <ScrollMultiSelect
      selectors={[
        {
          name: "Month",
          options: months,
          defaultValue: month,
          onValueChange: (newValue) => {
            setMonth(newValue);
            submitCombinedValues({ month: newValue });
          },
        },
        {
          name: "Day",
          options: days,
          defaultValue: day,
          onValueChange: (newValue) => {
            setDay(newValue);
            submitCombinedValues({ day: newValue });
          },
        },
        {
          name: "Year",
          options: years,
          defaultValue: year,
          onValueChange: (newValue) => {
            setYear(newValue);
            submitCombinedValues({ year: newValue });
          },
        },
      ]}
    />
  );
};

export default MobileBirthDatePicker;
