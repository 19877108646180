import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useUpdateInspectionQRCodeMutation } from "src/common/types/generated/relay/useUpdateInspectionQRCodeMutation.graphql";

const useUpdateInspectionQRCode = () => {
  return useAsyncMutation<useUpdateInspectionQRCodeMutation>(
    graphql`
      mutation useUpdateInspectionQRCodeMutation(
        $id: uuid!
        $_set: inspection_qr_code_set_input!
      ) {
        update_inspection_qr_code_by_pk(pk_columns: { id: $id }, _set: $_set) {
          id
          pk: id @__clientField(handle: "pk")
        }
      }
    `
  );
};
export default useUpdateInspectionQRCode;
