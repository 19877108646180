import { Button as AntDButton, DatePicker, Input, Space } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { useRef, useState } from "react";
import { EyeInvisibleOutlined, SearchOutlined } from "@ant-design/icons";
import GCProjectReportsDailySettingsButton from "../settings/GCProjectReportsDailySettingsButton";
import clsx from "clsx";

const { Search } = Input;

export interface GCProjectReportsDailyMonthProps {
  projectId: string;
  startMonth: dayjs.Dayjs;
  endMonth: dayjs.Dayjs;
  onMonthChange: (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => void;
  onSearchSubmit: (searchValue: string) => void;
}

const { RangePicker } = DatePicker;

const GCProjectReportsDailyTopBar: React.FC<
  GCProjectReportsDailyMonthProps
> = ({ projectId, startMonth, endMonth, onMonthChange, onSearchSubmit }) => {
  const searchRef = useRef<React.ElementRef<typeof Input>>(null);
  const [searchVisible, setSearchVisible] = useState(false);
  const [localMonthRange, setLocalMonthRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null]
  >([startMonth, endMonth]);

  const toggleSearchVisibility = () => {
    if (searchVisible) {
      if (!!searchRef?.current?.input?.value)
        searchRef.current.input.value = "";
      onSearchSubmit("");
      setSearchVisible(false);
    } else {
      searchRef?.current?.focus();
      setSearchVisible(true);
    }
  };

  return (
    <div className="sticky flex flex-row items-center gap-1">
      <RangePicker
        disabledDate={(current: Dayjs) => {
          const customDate = dayjs().format("MMMM YYYY");
          return (
            current && current >= dayjs(customDate, "MMMM YYYY").add(1, "month")
          );
        }}
        picker="month"
        format={"MMMM YYYY"}
        value={[startMonth, endMonth]}
        clearIcon={false}
        onChange={(newMonthRange) => {
          setLocalMonthRange(newMonthRange ?? [null, null]);
        }}
        onOpenChange={(open) => {
          if (!open) {
            const start = dayjs(localMonthRange[0]);
            const end = dayjs(localMonthRange[1]);
            start.isValid() && end.isValid() && onMonthChange(start, end);
          }
        }}
      />
      <Space.Compact className={"gap-0.25"} style={{ width: "auto" }}>
        <div
          className={clsx(
            `overflow-hidden transition-all`,
            searchVisible ? "w-12" : "w-0",
          )}
        >
          <Search
            ref={searchRef}
            placeholder="Search"
            allowClear
            onSearch={onSearchSubmit}
          />
        </div>
        <AntDButton
          onClick={toggleSearchVisibility}
          icon={searchVisible ? <EyeInvisibleOutlined /> : <SearchOutlined />}
        ></AntDButton>
      </Space.Compact>
      <GCProjectReportsDailySettingsButton projectId={projectId} />
    </div>
  );
};

export default GCProjectReportsDailyTopBar;
