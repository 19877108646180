import { Button, notification, Space } from "antd"
import { graphql } from "babel-plugin-relay/macro"
import React, { FC, useRef, useState } from "react"
import { useSubcontractorId } from "src/common/components/SubcontractorContext"
import AddAwarenessQuestionModal from "src/common/components/dialogs/AddAwarenessQuestionModal"
import StyledContent from "src/common/components/layouts/StyledContent"
import useAsyncMutation from "src/common/hooks/useAsyncMutation"
import getTranslations from "src/common/functions/getTranslations"
import openInNewTab from "src/common/functions/openInNewTab"
import { useInsertAwarenessQuestionsMutation } from "src/common/types/generated/apollo/graphQLTypes"
import {
  SCAwarenessQuestionsDeleteMutation
} from "src/common/types/generated/relay/SCAwarenessQuestionsDeleteMutation.graphql"
import AwarenessQuestionsTable from "src/root/routes/views/subcontractor/awareness-questions/AwarenessQuestionsTable"

// TODO add delete questions
const SubAdminAwarenessQuestions: FC = () => {
  const subcontractorId = useSubcontractorId();
  const [addQuestionOpen, setAddQuestionOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [insertQuestion, { loading: insertingQuestion }] =
    useInsertAwarenessQuestionsMutation();
  const tableRef = useRef<any>();
  const [deleteQuestion, isDeleteing] =
    useAsyncMutation<SCAwarenessQuestionsDeleteMutation>(
      graphql`
        mutation SCAwarenessQuestionsDeleteMutation(
          $where: awareness_question_bool_exp!
        ) {
          delete_awareness_question(where: $where) {
            affected_rows
          }
        }
      `
    );

  return (
    <Space size="large" direction="vertical" style={{ width: "100%" }}>
      <StyledContent padding backgroundColor="white">
        <AwarenessQuestionsTable
          title="SiteForm Awareness Questions"
          queryInfo={{
            type: "global",
          }}
          onClickRow={() => {}}
        />
      </StyledContent>
      <StyledContent padding backgroundColor="white">
        <AddAwarenessQuestionModal
          visible={addQuestionOpen}
          onCancel={() => setAddQuestionOpen(false)}
          onCreate={async (values) => {
            setAddQuestionOpen(false);
            setLoading(true);
            // TODO show loading
            const [translatedQuestion] = await getTranslations([
              values.question,
            ]);

            await insertQuestion({
              variables: {
                objects: [
                  {
                    subcontractor_id: subcontractorId,
                    question: {
                      data: translatedQuestion,
                    },
                    question_text: translatedQuestion.en,
                  },
                ],
              },
            }).catch((e) =>
              notification.error({
                message: e?.message,
                duration: null,
                description: "Question Not Inserted",
              })
            );
            setLoading(false);
            tableRef.current?.refetch();
          }}
        />
        <AwarenessQuestionsTable
          title="Company Awareness Questions"
          loading={insertingQuestion || isDeleteing || loading}
          queryRef={(ref) => (tableRef.current = ref)}
          queryInfo={{
            type: "subcontractor",
            value: subcontractorId,
          }}
          titleComponent={
            <Space>
              <Button
                type="primary"
                loading={insertingQuestion}
                disabled={insertingQuestion}
                onClick={() => setAddQuestionOpen(true)}
              >
                Add Question
              </Button>
              <Button
                onClick={() => {
                  openInNewTab("https://youtu.be/9UZZrPI5zsA");
                }}
              >
                Help
              </Button>
            </Space>
          }
          onClickRow={() => {}}
          onDelete={async (q) => {
            setLoading(true);
            await deleteQuestion({
              variables: {
                where: {
                  id: { _eq: q.id },
                },
              },
            });
            setLoading(false);
            tableRef.current?.refetch();
          }}
        />
      </StyledContent>
    </Space>
  );
};

export default SubAdminAwarenessQuestions;
