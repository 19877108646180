import { FC, useState, useMemo } from "react";
import { Button, Card, Image, message } from "antd";
import {
  useInsertIncidentUserMutation,
  useGetProjectEmployeesQuery,
  useInsertDocumentMutation,
  useUpdateStatementDetailMutation,
  Statement_Detail_Set_Input,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  GetIncidentByPkDocument,
  Document_Insert_Input,
  useInsertImageMutation,
  useGenerateIncidentWitnessPdfMutation,
  useUpdateIncidentUserMutation,
  useGetIncidentByPkQuery,
  useUpdateIncidentEditByPkMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import * as uuid from "uuid";
import useSignaturePad from "src/common/components/general/signature-canvas/useSignaturePad";
import IncidentTextField from "../basic/IncidentTextField";
import CustomSignatureCanvas from "src/common/components/general/signature-canvas/CustomSignatureCanvas";
import IncidentDocUploader from "../basic/IncidentDocUploader";
import IncidentDocViewer from "../basic/IncidentDocViewer";
import { IconDownload } from "@tabler/icons";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import IncidentDatepicker from "../basic/IncidentDatepicker";
import IncidentSelectField from "../basic/IncidentSelectField";
import IncidentUser from "../incident-users/IncidentUser";
import getNextPage from "../../utils/getNextPage";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import dayjs from "dayjs";
import produce from "immer";

const WitnessDetail: FC = () => {
  const { incidentId, projectId } = useParams();
  if (!projectId) throw new Error("projectId is missing");
  if (!incidentId) throw new Error("incidentId is missing");

  const { data: incidentData, refetch } = useGetIncidentByPkQuery({
    variables: {
      incidentId: incidentId,
    },
    fetchPolicy: "cache-first",
  });
  const incident = incidentData?.incident_by_pk;
  if (!incident) throw new Error("Incident not found");
  const [searchParams] = useSearchParams();
  const witnessUserId = searchParams.get("id");
  const authUser = useAuthUser();
  const [updateIncidentEdit] = useUpdateIncidentEditByPkMutation();
  const [updateIncidentUser] = useUpdateIncidentUserMutation();
  const [insertImage] = useInsertImageMutation();
  const [insertDocument] = useInsertDocumentMutation();
  const [insertWitness] = useInsertIncidentUserMutation();
  const navigate = useNavigate();
  const [updateStatementDetail] = useUpdateStatementDetailMutation();
  const [generateIncidentWitnessPdf, { loading: generating }] =
    useGenerateIncidentWitnessPdfMutation();

  const [providerSignatureEditable, setProviderSignatureEditable] =
    useState(true);
  const [takenBySignatureEditable, setTakenBySignatureEditable] =
    useState(true);

  const {
    signaturePadProps: providingSatatentSignProps,
    isEmpty: providingStatementIsEmpty,
    uploadSignature: providingStatementUploadSign,
  } = useSignaturePad();
  const {
    signaturePadProps: takingStatementSignProps,
    isEmpty: takingStatementIsEmpty,
    uploadSignature: takingStatementUploadSign,
  } = useSignaturePad();

  const witnessData = useMemo(() => {
    const witnessData = incident.witnesses.find(
      (witness) => witness.id === witnessUserId,
    );
    return witnessData;
  }, [incident.witnesses, witnessUserId]);

  const uploadSign = async (
    imgUrl: string,
    type: "statement_provider_signature_id" | "statement_taken_by_signature_id",
  ) => {
    await insertImage({ variables: { object: { url: imgUrl } } }).then(
      (res) => {
        const imageId = res.data?.insert_image_one?.id;
        if (imageId) {
          updateStatement(
            type,
            imageId,
            `Signature added by ${
              type === "statement_provider_signature_id"
                ? `person providing witness statement`
                : `person taking witness statement`
            }`,
          );
        }
      },
    );
  };

  const statementDetail = witnessData?.statement_detail;

  const { data: projectEmployeeData } = useGetProjectEmployeesQuery({
    variables: {
      where: { project_id: { _eq: projectId } },
    },
  });
  const projectEmployeeOptions = useMemo(() => {
    const employeeOptions = projectEmployeeData?.project_employee.map((pe) => ({
      label: pe.employee.user.name,
      value: pe.employee.user.id,
    }));
    return employeeOptions;
  }, [projectEmployeeData?.project_employee]);

  const statementTypes = [
    { label: "Injured Person", value: "injured" },
    { label: "Foreman/Supervisor", value: "foreman" },
    { label: "Employee", value: "employee" },
    { label: "Witness", value: "witness" },
  ];

  const insertWitnessUser = async (id: string, name: string, role: string) => {
    if (
      incident.witnesses.findIndex(
        (witness) => witness.user.id === id && !witness.deleted_at,
      ) !== -1
    ) {
      return;
    }

    if (
      incident.witnesses.findIndex(
        (witness) => witness.user.id === id && !!witness.deleted_at,
      ) !== -1
    ) {
      const witnessUserId = incident.witnesses.find(
        (witness) => witness.user.id === id && !!witness.deleted_at,
      )?.id;

      const updatedIncident = {
        ...incident,
        witnesses: incident.witnesses.map((witness) => {
          if (witness.id === witnessUserId)
            return {
              ...witness,
              deleted_at: null,
            };
          else return witness;
        }),
      };
      const patch = createIncidentPatch(updatedIncident, incident);

      const comment = `Add witness user "${name}"`;
      if (witnessUserId) {
        await updateIncidentUser({
          variables: {
            id: witnessUserId,
            set: { deleted_at: null },
            object: {
              patch: patch,
              comment: comment,
              edit_type: "witness-edit",
              incident_id: incidentId,
            },
          },
        });
        refetch();
        navigate(`?id=${witnessUserId}`);
      }
    } else if (witnessUserId) {
      const prevWitness = incident.witnesses.find(
        (witness) => witness.id === witnessUserId,
      )?.user.name;
      const comment = `Updated the witness ${prevWitness} to ${name}`;

      const { data: updatedIncidentUser } = await updateIncidentUser({
        variables: {
          id: witnessUserId,
          set: { user_id: id },
          object: {
            comment: comment,
            incident_id: incidentId,
            edited_by_uid: authUser.uid,
            patch: [],
            edit_type: "witness-update",
          },
        },
      });

      const updatedIncident = {
        ...incident,
        witnesses: incident.witnesses.map((witness) => {
          if (
            witness.user.id === id &&
            updatedIncidentUser?.update_incident_user_by_pk
          ) {
            return updatedIncidentUser.update_incident_user_by_pk;
          }
          return witness;
        }),
      };
      const patch = createIncidentPatch(updatedIncident, incident);
      const incidentEditId = updatedIncidentUser?.insert_incident_edit_one?.id;
      if (incidentEditId) {
        await updateIncidentEdit({
          variables: { id: "", _set: { patch: patch } },
        });
      }

      refetch();
    } else {
      const incidentUserId = uuid.v4();
      const comment = `Add witness user "${name}"`;
      let updatedIncident = incident;
      const { data: insertedIncidentUserData } = await insertWitness({
        variables: {
          object: {
            id: incidentUserId,
            user_id: id,
            project_id: projectId,
            type: "witness",
            incident_id: incident.id,
            statement_detail: { data: {} },
          },
          editObject: {
            comment: comment,
            incident_id: incidentId,
            edited_by_uid: authUser.uid,
            edit_type: "witness-insertion",
            patch: [],
          },
        },
        update(cache, result) {
          const res = result.data?.insert_incident_user_one;
          if (!res) return;

          updatedIncident = {
            ...incident,
            witnesses: [...incident.witnesses, res],
          };

          cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>(
            {
              data: {
                __typename: "query_root",
                incident_by_pk: updatedIncident,
              },
              query: GetIncidentByPkDocument,
            },
          );
        },
      });
      const patch = createIncidentPatch(updatedIncident, incident);
      const userId = insertedIncidentUserData?.insert_incident_user_one?.id;
      const incidentEditId =
        insertedIncidentUserData?.insert_incident_edit_one?.id;

      if (userId) {
        navigate(`?id=${userId}`);
      }

      if (incidentEditId) {
        await updateIncidentEdit({
          variables: { id: incidentEditId, _set: { patch: patch } },
        });
      }
    }
  };

  const updateStatement = async (
    key: keyof Statement_Detail_Set_Input,
    value: number | string | boolean,
    comment: string,
  ) => {
    if (statementDetail) {
      const updatedWitnesses = incident.witnesses.map((witness) => {
        if (witness.id === witnessUserId) {
          return { ...witness, [key]: value };
        } else {
          return witness;
        }
      });
      const updatedIncident = { ...incident, witnesses: updatedWitnesses };
      const patch = createIncidentPatch(updatedIncident, incident);
      await updateStatementDetail({
        variables: {
          id: statementDetail.id,
          _set: { [key]: value },
          object: {
            patch: patch,
            comment: comment,
            edited_by_uid: authUser.uid,
            edit_type: "witness-statement-edit",
            incident_id: incidentId,
          },
        },
        optimisticResponse: {
          update_statement_detail_by_pk: {
            ...statementDetail,
            id: statementDetail.id,
            [key]: value,
          },
        },
      });
    }
  };

  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    const insertedDocs: Array<{ url: string; name: string }> = [];

    const docsToInsert: {
      __typename?: "document";
      url: string;
      id: string;
      group_id?: string | null;
      document_type?: string | null;
      name?: string | null;
      created_at: string;
      image?: {
        __typename?: "image";
        url: string;
        id: string;
      } | null;
    }[] = [];

    objects.forEach((doc) => {
      if (doc.url && doc.id) {
        docsToInsert.push({
          __typename: "document" as const,
          ...doc,
          created_at: dayjs().format(),
          url: doc.url,
          id: doc.id,
          image:
            doc.document_type === "image" ? { url: doc.url, id: doc.id } : null,
        });

        insertedDocs.push({
          url: doc.url,
          name: doc.name ?? "document",
        });
      }
    });

    const updatedIncident = produce(incident, (draft) => {
      draft.witnesses = draft.witnesses.map((witness) => {
        if (witness.id === witnessUserId) {
          return {
            ...witness,
            attached_files: [...witness.attached_files, ...docsToInsert],
          };
        } else return witness;
      });
    });
    const patch = createIncidentPatch(updatedIncident, incident);
    const comment = `Uploaded documents for ${witnessUserName}`;

    await insertDocument({
      variables: {
        objects: objects,
        editObject: {
          patch: patch,
          comment: comment,
          document_edit: [
            {
              field: "Witness Detail",
              action: "added",
              documents: insertedDocs,
            },
          ],
          incident_id: incidentId,
          edited_by_uid: authUser.uid,
          edit_type: "witness-doc-insertion",
        },
      },
      update(cache, result) {
        const insertedDocs = result.data?.insert_document?.returning;
        if (!insertedDocs) return;

        const updatedIncident = {
          ...incident,
          witnesses: incident.witnesses.map((witness) => {
            if (witness.id === witnessUserId) {
              return {
                ...witness,
                attached_files: [...witness.attached_files, ...insertedDocs],
              };
            }
            return witness;
          }),
        };

        cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>({
          data: {
            __typename: "query_root",
            incident_by_pk: updatedIncident,
          },
          query: GetIncidentByPkDocument,
        });
      },
    });
  };

  const witnessUserName =
    incident.witnesses.find((witness) => witness.id === witnessUserId)?.user
      .name ?? "";

  return (
    <>
      <div className="absolute left-24 top-2 text-2">Witness Details</div>
      <div className="w-full mt-6 pl-4 table-fixed overflow-y-auto">
        <IncidentUser
          key={witnessUserId}
          projectId={projectId}
          title="Witness"
          incident={incident}
          incidentUserId={witnessUserId ?? undefined}
          onUpdateUser={async (id: string, name: string, role: string) => {
            insertWitnessUser(id, name, role);
          }}
          userType="witness"
        />

        {statementDetail && witnessData && witnessUserId && (
          <>
            <Card className="w-4/5">
              <IncidentSelectField
                title="Select who is providing the Statement"
                onChange={(option) => {
                  updateStatement(
                    "type",
                    option.value,
                    `Updated Insurance Detail for Injured User ${witnessUserName} - "Select who is providing the Statement" to ${option.label}`,
                  );
                }}
                options={statementTypes}
                value={statementDetail.type ?? undefined}
              />
            </Card>
            <Card className="w-4/5">
              <IncidentDatepicker
                label="Date of statement"
                onChange={(date) => {
                  updateStatement(
                    "statement_date",
                    date,
                    `Updated Insurance Detail for Injured User ${witnessUserName} - "Date of statement" to ${date}`,
                  );
                }}
                value={statementDetail.statement_date ?? undefined}
              />
            </Card>
            <Card className="w-4/5">
              <IncidentSelectField
                title="Employee Collecting the statement"
                onChange={(option) => {
                  updateStatement(
                    "employee_collecting_statement_user_id",
                    option.value,
                    `Updated Insurance Detail for Injured User ${witnessUserName} - "Employee Collecting the statement" to ${option.label}`,
                  );
                }}
                value={
                  statementDetail.employee_collecting_statement?.id ?? undefined
                }
                options={projectEmployeeOptions ?? []}
              />
            </Card>

            <Card className="w-4/5">
              <IncidentTextField
                label={"In your own words, explain what happened"}
                text={witnessData?.statement_description.en}
                textId={witnessData?.statement_description.id}
                fieldTypeKey={"witness_detail"}
                field={"statement_description"}
                incidentUserId={witnessUserId}
                incident={incident}
              />
              <div className="mt-1">
                Consider the following details as you provide your statement
                <div>
                  - What happened? Tell a story answering who, what, where,
                  when, how and why
                </div>
                <div> - Where were you when the incident took place? </div>
                <div>
                  - What activity was being performed prior to the event?
                </div>
                <div> - What do you believe happened? </div>
                <div> - Who else was there? </div>
              </div>
            </Card>

            <Card className="w-4/5">
              <IncidentTextField
                label="Do you recall anything unusual or unexpected that happened?"
                text={statementDetail.unusual_happenings.en}
                textId={statementDetail.unusual_happenings.id}
                fieldTypeKey={"witness_detail"}
                field={"unusual_happenings"}
                incidentUserId={witnessUserId}
                incident={incident}
              />
            </Card>

            <Card className="w-4/5">
              <IncidentTextField
                label="How would you prevent this incident from happening in the future?"
                text={statementDetail.future_prevention.en}
                textId={statementDetail.future_prevention.id}
                fieldTypeKey={"witness_detail"}
                field={"future_prevention"}
                incidentUserId={witnessUserId}
                incident={incident}
              />
            </Card>

            <Card className="w-4/5">
              <IncidentSelectField
                title="If you were injured, have you ever injured this body part
                  before?"
                onChange={(option) => {
                  updateStatement(
                    "body_part_injured_before",
                    option.value,
                    `Updated Insurance Detail for Injured User ${witnessUserName} - "If you were injured, have you ever injured this body part
                  before?" to ${option.label}`,
                  );
                }}
                value={statementDetail.body_part_injured_before ?? undefined}
                options={[
                  { value: false, label: "No" },
                  { value: true, label: "Yes" },
                ]}
              />
            </Card>

            <Card className="w-4/5">
              <IncidentTextField
                label="Explain"
                text={statementDetail.prior_injury_detail.en}
                textId={statementDetail.prior_injury_detail.id}
                fieldTypeKey={"witness_detail"}
                field={"prior_injury_detail"}
                incidentUserId={witnessUserId}
                incident={incident}
              />
            </Card>

            <Card className="w-4/5">
              <div className="flex gap-1 items-center">
                <div className="w-10">
                  Signature of person providing statement{" "}
                </div>

                {!providerSignatureEditable ? (
                  <Image
                    src={statementDetail.statement_provider_signature?.url}
                  />
                ) : (
                  <CustomSignatureCanvas {...providingSatatentSignProps} />
                )}
              </div>
              <Button
                className="mt-1"
                disabled={
                  providingStatementIsEmpty && providerSignatureEditable
                }
                size="middle"
                type="primary"
                onClick={async () => {
                  if (!providerSignatureEditable) {
                    setProviderSignatureEditable(true);
                  } else {
                    const imgUrl = await providingStatementUploadSign();
                    if (imgUrl) {
                      await uploadSign(
                        imgUrl,
                        "statement_provider_signature_id",
                      );
                      setProviderSignatureEditable(false);
                    }
                  }
                }}
              >
                {providerSignatureEditable ? "Save" : "Edit"}
              </Button>
            </Card>

            <Card className="w-4/5">
              <div className="flex gap-1 items-center">
                <div className="w-10">
                  Signature of person taking statement{" "}
                </div>

                {!takenBySignatureEditable ? (
                  <Image
                    src={statementDetail.statement_taken_by_signature?.url}
                  />
                ) : (
                  <CustomSignatureCanvas {...takingStatementSignProps} />
                )}
              </div>
              <Button
                className="mt-1"
                disabled={takingStatementIsEmpty && takenBySignatureEditable}
                size="middle"
                type="primary"
                onClick={async () => {
                  if (!takenBySignatureEditable) {
                    setTakenBySignatureEditable(true);
                  } else {
                    const imgUrl = await takingStatementUploadSign();
                    if (imgUrl) {
                      await uploadSign(
                        imgUrl,
                        "statement_taken_by_signature_id",
                      );
                      setTakenBySignatureEditable(false);
                    }
                  }
                }}
              >
                {takenBySignatureEditable ? "Save" : "Edit"}
              </Button>
            </Card>

            <div className="w-4/5">
              <IncidentDocUploader
                insertDoc={uploadDocument}
                acceptedFiles=".png,.jpg,.jpeg"
                docType="image"
                groupId={witnessData.id}
                type="witness"
              />
              {witnessData?.attached_files && (
                <IncidentDocViewer
                  documents={witnessData?.attached_files.filter(
                    (file) => !!file.image,
                  )}
                  deleteDocument={async () => {}}
                />
              )}
            </div>

            <div className="w-4/5">
              <IncidentDocUploader
                insertDoc={uploadDocument}
                acceptedFiles="video/*, audio/*, .xlsx, application/pdf"
                docType="other"
                groupId={witnessData.id}
                type="witness"
              />
              {witnessData?.attached_files && (
                <IncidentDocViewer
                  documents={witnessData?.attached_files.filter(
                    (file) => !file.image,
                  )}
                  deleteDocument={async () => {}}
                />
              )}
            </div>

            <div className="mt-2 inline-flex">
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  const index =
                    incident.witnesses.findIndex(
                      (witnessUser) => witnessUser.id === witnessUserId,
                    ) ?? 0;
                  if (index + 1 < incident.witnesses.length) {
                    const next = incident.witnesses[index + 1].id;
                    if (next) {
                      navigate(
                        `/gce/projects/${projectId}/incidents/${incidentId}/witness?id=${next}`,
                      );
                    }
                  } else {
                    const next = getNextPage(incident, "witness");
                    navigate(
                      `/gce/projects/${projectId}/incidents/${incidentId}/${next}`,
                    );
                  }
                }}
              >
                Save
              </Button>

              <div className="ml-2">
                <Button
                  type="primary"
                  loading={generating}
                  icon={<IconDownload />}
                  onClick={async () => {
                    const { data } = await generateIncidentWitnessPdf({
                      variables: {
                        input: {
                          incidentId: incident.id,
                          userId: witnessData.user.id,
                        },
                      },
                    });
                    if (data?.generateIncidentWitnessPdf) {
                      downloadFromUrl(data.generateIncidentWitnessPdf);
                    }
                    message.success("Download complete");
                  }}
                >
                  Download This Witness Statement
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WitnessDetail;
