import React from "react";
import { Spin } from "antd";

interface LoadingContentProps {
  fullSize?: boolean;
}

const LoadingContent: React.FC<LoadingContentProps> = ({ fullSize }) => {
  return (
    <div
      className={`flex items-center justify-center ${
        fullSize ? "w-full h-full" : ""
      }`}
    >
      <Spin />
    </div>
  );
};

export default LoadingContent;
