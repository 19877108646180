import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

interface CarouselProps {
  carouselCurrentStep: {
    index: number;
    body: React.ReactNode;
  };
}

const Carousel: React.FC<CarouselProps> = (props) => {
  const stepIndex = props.carouselCurrentStep.index;
  const [currentIndex, setCurrentIndex] = useState(stepIndex);

  //const prev = usePrevious(stepIndex);
  //const direction = prev !== null && stepIndex > prev ? 1 : -1;
  const direction = stepIndex > currentIndex ? 1 : -1;
  const [width, setWidth] = useState<number>();
  const ref = useRef<HTMLDivElement>(null);

  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const { width: newWidth } = ref.current.getBoundingClientRect();
      setWidth(newWidth);
    }
  }, []);

  return (
    <div ref={ref} className={clsx(isTransitioning && "pointer-events-none")}>
      <AnimatePresence custom={{ direction, width }}>
        <motion.div
          key={stepIndex}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          custom={{ direction, width }}
          className={`absolute w-full h-full inset-0`}
          transition={{ type: "spring", bounce: 0 }}
          onAnimationStart={() => {
            setIsTransitioning(true);
          }}
          onAnimationComplete={() => {
            setIsTransitioning(false);
            setCurrentIndex(stepIndex);
          }}
        >
          {props.carouselCurrentStep.body}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

const variants = {
  enter: (props: { direction: number; width: number }) => ({
    x: props.direction * props.width,
  }),
  center: { x: 0 },
  exit: (props: { direction: number; width: number }) => ({
    x: props.direction * -props.width,
  }),
};

// function usePrevious(state: number) {
//   const [tuple, setTuple] = useState([null, state]);
//
//   if (tuple[1] !== state) {
//     setTuple([tuple[1], state]);
//   }
//
//   return tuple[0];
// }

export default Carousel;
