import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Checkbox, CheckboxProps, Select, SelectProps, Space } from "antd";
import FModal, { FModalRef } from "./FModal";

interface ModalValues {
  subs: string[];
}

interface UploadJHAModalProps {
  onCancel: () => void;
  onInstantTaskUploadClick?: () => void;
  onNonInstantTaskUploadClick?: () => void;
  onRequestJHAFromSubClick?: () => void;
}

export type UploadJHAModalRef = FModalRef<ModalValues> | null;
const UploadJHAModal = forwardRef<UploadJHAModalRef, UploadJHAModalProps>(
  (
    {
      onCancel,
      onInstantTaskUploadClick,
      onNonInstantTaskUploadClick,
      onRequestJHAFromSubClick,
    },
    ref,
  ) => {
    const modal = useRef<FModalRef<ModalValues>>(null);

    const handleCancel = () => {
      onCancel();
    };

    useImperativeHandle<UploadJHAModalRef, UploadJHAModalRef>(
      ref,
      () => modal.current,
    );
    return (
      <FModal
        ref={modal}
        okText="Download"
        cancelText="Cancel"
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancel}
        
      >
        <div>
          {onNonInstantTaskUploadClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              key={3}
              onClick={(e) => {
                e.stopPropagation();
                onNonInstantTaskUploadClick();
              }}
            >
              Upload JHAs in Your Format - NOT INSTANT
            </div>
          )}

          {onInstantTaskUploadClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              key={2}
              onClick={(e) => {
                e.stopPropagation();
                onInstantTaskUploadClick();
              }}
            >
              Upload JHAs with SiteForm Excel Template - INSTANT
            </div>
          )}
          {onRequestJHAFromSubClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              key={4}
              onClick={(e) => {
                e.stopPropagation();
                onRequestJHAFromSubClick();
              }}
            >
              Request JHAs from Subcontractor
            </div>
          )}
        </div>
      </FModal>
    );
  },
);

export default UploadJHAModal;
