import React, { useState } from "react";
import {
  Collapse,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Switch,
} from "antd";
import dayjs from "dayjs";
import Table, { ColumnsType } from "antd/lib/table";
import {
  DefaultFooter,
  GCDefaultDescription,
  SubDefaultDescription,
} from "./InvoiceDefaultSettings";
import { InvoiceSettings } from "./EditInvoiceSettingsModal";

interface ProjectRecord {
  id: string;
  name: string;
  gc: string;
  price: string;
}

interface Props {
  visible: boolean;
  projects: Array<ProjectRecord>;
  isProjectInvoiceModal?: boolean;
  invSettings: InvoiceSettings;
  companyName?: string;
  isGC?: boolean;
  hideProjects?: boolean;
  onSelect: (
    startDate: dayjs.Dayjs,
    monthCount: number,
    projecs: string[],
    finalize: boolean,
    header: string,
    footer: string,
    excludeEmptyLines: boolean,
  ) => Promise<void>;
  onCancel: () => void;
}

const CreateCustomInvoiceModal: React.FC<Props> = ({
  visible,
  companyName,
  invSettings,
  projects,
  onSelect,
  onCancel,
  isGC,
  isProjectInvoiceModal,
  hideProjects,
}) => {
  const createOnlySingleProjectInvoice =
    isProjectInvoiceModal && projects.length === 1;
  const [selectedProjectIds, setSelectedProjectIds] = useState<React.Key[]>(
    createOnlySingleProjectInvoice
      ? [projects[0].id]
      : [] /*() => projects.map(p => p.id)*/,
  );
  const [saving, setSaving] = useState(false);

  const columns: ColumnsType<ProjectRecord> = [
    {
      title: "Project name",
      dataIndex: "name",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedProjectIds(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedProjectIds,
    onChange: onSelectChange,
  };
  const [form] = Form.useForm();

  return (
    <Modal
      width={"60%"}
      open={visible}
      title={"Enter custom invoice parameters, " + companyName ?? ""}
      okText="Create"
      okButtonProps={{
        loading: saving,
        disabled: !hideProjects && selectedProjectIds.length === 0,
      }}
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        setSaving(true);
        form
          .validateFields()
          .then((values) => {
            const ids = selectedProjectIds.filter(
              (value): value is string => typeof value === "string",
            );
            const startDate = values.dates[0].clone().startOf("month");
            const endDate = values.dates[1]
              .clone()
              .startOf("month")
              .add(1, "month");
            const monthCount = endDate.diff(startDate, "month");
            console.log("monthCount: " + monthCount);
            return onSelect(
              startDate,
              monthCount,
              ids,
              values.finalize,
              values.description,
              values.footer,
              values.excludeEmptyLines,
            );
          })
          .catch((info) => {
            message.error(info.message);
          })
          .finally(() => {
            setSaving(false);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          dates: [
            dayjs().subtract(4, "month").startOf("month"),
            dayjs().subtract(1, "month").endOf("month"),
          ],
          finalize: true,
          description:
            invSettings.description ??
            (isGC ? GCDefaultDescription : SubDefaultDescription),
          footer: invSettings.footer ?? DefaultFooter,
          excludeEmptyLines: true, //invSettings.excludeEmptyLines,
        }}
      >
        <Form.Item
          name="dates"
          label="Select invoice period"
          rules={[{ required: true, message: "Invoice period is required" }]}
        >
          <DatePicker.RangePicker
            picker="month"
            style={{ width: "100%", maxWidth: "450px", margin: "12px 0px" }}
            disabledDate={(date) =>
              date.year() < 2022 || date.endOf("month") > dayjs()
            }
          />
        </Form.Item>
        {!hideProjects && (
          <Table
            rowSelection={rowSelection}
            rowKey="id"
            columns={columns}
            dataSource={projects}
            size="small"
          />
        )}
        <Collapse bordered={false}>
          <Collapse.Panel header="Additional parameters..." key="1">
            <Form.Item>
              <Form.Item name="finalize" valuePropName="checked" noStyle>
                <Switch></Switch>
              </Form.Item>
              <span className="ant-form-text ml-1">
                Finalize and send invoice immediately after creation
              </span>
            </Form.Item>
            <Form.Item>
              <Form.Item
                name="excludeEmptyLines"
                valuePropName="checked"
                noStyle
              >
                <Switch></Switch>
              </Form.Item>
              <span className="ant-form-text ml-1">
                Exclude lines with zero workers
              </span>
            </Form.Item>
            <Form.Item name="description" label="Description:">
              <Input.TextArea rows={7}></Input.TextArea>
            </Form.Item>
            <Form.Item name="footer" label="Footer:">
              <Input.TextArea></Input.TextArea>
            </Form.Item>
          </Collapse.Panel>
        </Collapse>
      </Form>
    </Modal>
  );
};

export default CreateCustomInvoiceModal;
