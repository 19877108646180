import { SearchData } from "../../ScrollTable";
import { DataRecord } from "../dataScrollTableTypes";
import getFieldValue from "../getFieldValue";

const arrayFilterSearchData = (
  data: Array<DataRecord>,
  filter: SearchData,
): Array<DataRecord> => {
  const { searchValue } = filter;
  if (!searchValue) return data;

  return data.filter((record) =>
    filter.fields.some((field) => {
      const value = getFieldValue(record, field.dataIndex);
      return (
        `${value?.toLocaleString().toLocaleLowerCase()}`.indexOf(
          searchValue.toLocaleLowerCase(),
        ) >= 0
      );
    }),
  );
};
export default arrayFilterSearchData;
