import React, { useState } from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import {
  GetGcTeamDocument,
  GetGcTeamQuery,
  GetGcTeamQueryVariables,
  useUpdateGcDivisionEmployeesMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSuspenseQuery } from "@apollo/client";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import GCHierarchyTeam from "../../component/GCHierarchyTeam";
import useAuthUser from "src/common/hooks/useAuthUser";
import { useParams } from "react-router-dom";
import withDivisionId, { DivisionProps } from "../../utils/withDivisionId";

const GCDivisionTeam: React.FC<DivisionProps> = ({ divisionId }) => {
  const { userData } = useUserData();
  const employee = userData.employee;
  if (!employee) throw new Error("Loggedā in user is not employee");
  const { data, refetch } = useSuspenseQuery<
    GetGcTeamQuery,
    GetGcTeamQueryVariables
  >(GetGcTeamDocument, {
    variables: {
      gcId: employee.general_contractor_id,
      projectsToInviteWhere: {
        _or: [
          { gc_division_id: { _eq: divisionId } },
          { gc_business_unit: { gc_division_id: { _eq: divisionId } } },
          {
            gc_office: {
              gc_business_unit: { gc_division_id: { _eq: divisionId } },
            },
          },
        ],
      },
      gcEmployeeWhere: {
        gc_divisions: { gc_division_id: { _eq: divisionId } },
        general_contractor_id: { _eq: employee.general_contractor_id },
      },
    },
  });
  const [updateDivisionEmp] = useUpdateGcDivisionEmployeesMutation();
  const authUser = useAuthUser();
  const gc = data.general_contractor_by_pk;
  if (!gc) throw new Error("GC not found for logged in user");
  const projectsToInvite = gc.gc_projects.map((p) => p.project.id);
  return (
    <BasicWrapper>
      <GCHierarchyTeam
        {...{
          projectsToInvite,
          title: "Division",
          onInviteSuccess: async (userIds) => {
            await updateDivisionEmp({
              variables: {
                deleteProjEmpWhere: { id: { _is_null: true } },
                //IF added to higher level remove from lower level
                deleteBuEmpWhere: {
                  gc_business_unit: { gc_division_id: { _eq: divisionId } },
                  user_id: { _in: userIds },
                },
                deleteOfficeEmpWhere: {
                  gc_office: { gc_division_id: { _eq: divisionId } },
                  user_id: { _in: userIds },
                },
                deleteDivEmpWhere: { id: { _is_null: true } },
                insertDivObjs: userIds.map((u) => ({
                  gc_division_id: divisionId,
                  created_by_uid: authUser.uid,
                  user_id: u,
                })),
              },
            });
            refetch();
          },
          onRemoveFromTeam: async (userId) => {
            await updateDivisionEmp({
              variables: {
                deleteDivEmpWhere: {
                  user_id: { _eq: userId },
                  gc_division_id: { _eq: divisionId },
                },
                deleteBuEmpWhere: { id: { _is_null: true } },
                deleteOfficeEmpWhere: { id: { _is_null: true } },
                deleteProjEmpWhere: {
                  project_id: { _in: projectsToInvite },
                  employee_id: { _eq: userId },
                  direct_project_assign: { _eq: false },
                },
                insertDivObjs: [],
              },
            });
            refetch();
          },
          excludeEmployeeWhere: {
            _or: [
              { is_corporate_admin: { _eq: true } },
              { gc_divisions: { gc_division_id: { _eq: divisionId } } },
            ],
          },
          gcEmployees: data.general_contractor_employee,
        }}
      />
    </BasicWrapper>
  );
};

export default withCustomSuspense(withDivisionId(GCDivisionTeam));
