import { MenuOutlined } from "@ant-design/icons";
import { IconCheck, IconX } from "@tabler/icons";
import { Button, message, Popconfirm, Space } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { graphql } from "babel-plugin-relay/macro";
import { nanoid } from "nanoid";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLazyLoadQuery, useRelayEnvironment } from "react-relay/hooks";
import { ConnectionHandler, commitLocalUpdate, fetchQuery } from "relay-runtime";
import AddChecklistItemModal, {
  AddChecklistItemModalRef,
} from "src/common/components/dialogs/AddChecklistItemModal";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import SelectProjectSubcontractorModal from "src/common/components/dialogs/SelectProjectSubcontractorModal";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import Switcher from "src/common/components/general/Switcher";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import TableTitle from "src/common/components/tables/basic/TableTitle";
import DraggableTableWrapper from "src/common/components/tables/draggable/DraggableTableWrapper";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import getConnectionNodes from "src/common/functions/getConnectionNodes";
import noPropagation from "src/common/functions/noPropagation";
import { ChecklistItemFrag$data } from "src/common/types/generated/relay/ChecklistItemFrag.graphql";
import { GCProjectReportsDailySettingsDeleteConfigMutation } from "src/common/types/generated/relay/GCProjectReportsDailySettingsDeleteConfigMutation.graphql";
import { GCProjectReportsDailySettingsDeleteItemMutation } from "src/common/types/generated/relay/GCProjectReportsDailySettingsDeleteItemMutation.graphql";
import { GCProjectReportsDailySettingsInsertConfigMutation } from "src/common/types/generated/relay/GCProjectReportsDailySettingsInsertConfigMutation.graphql";
import { GCProjectReportsDailySettingsInsertItemMutation } from "src/common/types/generated/relay/GCProjectReportsDailySettingsInsertItemMutation.graphql";
import { GCProjectReportsDailySettingsQuery } from "src/common/types/generated/relay/GCProjectReportsDailySettingsQuery.graphql";
import { GCProjectReportsDailySettingsUpdateItemMutation } from "src/common/types/generated/relay/GCProjectReportsDailySettingsUpdateItemMutation.graphql";
import { GCProjectReportsDailySettingsUpdateItemNotifyeesMutation } from "src/common/types/generated/relay/GCProjectReportsDailySettingsUpdateItemNotifyeesMutation.graphql";
import { GCProjectReportsDailySettings_UpdateProjectSub_Mutation } from "src/common/types/generated/relay/GCProjectReportsDailySettings_UpdateProjectSub_Mutation.graphql";
import { GCProjectReportsDailySettings_toggleAddDailyWorkLogWithPTP_Mutation } from "src/common/types/generated/relay/GCProjectReportsDailySettings_toggleAddDailyWorkLogWithPTP_Mutation.graphql";
import ProjectProps from "src/common/types/manual/ProjectProps";
import * as uuid from "uuid";

interface Props extends ProjectProps {}

const CHECKLIST_CONFIGS = [
  {
    value: "option_yes",
    label: "Yes",
  },
  {
    value: "option_no",
    label: "No",
  },
  {
    value: "option_na",
    label: "N/A",
  },
  // {
  //   value: 'response_required',
  //   label: 'Response Required',
  // },
  {
    value: "response_on_yes",
    label: "When Yes, response required",
  },
  {
    value: "response_on_no",
    label: "When No, response required",
  },
  {
    value: "notify_yes",
    label: "When Yes, Notify",
  },
  {
    value: "notify_no",
    label: "When No, Notify",
  },
];

const dailyReportSettingsQuery = graphql`
  query GCProjectReportsDailySettingsQuery($projectId: uuid!) {
    checklist_item_connection(
      first: 10000
      where: { project_id: { _eq: $projectId }, data_type: { _eq: "base" } }
    )
      @connection(
        key: "GCProjectReportsDailySettings_checklist_item_connection"
        filters: ["where"]
      ) {
      edges {
        node {
          ...ChecklistItemFrag @relay(mask: false)
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          add_daily_work_log_during_ptp
          project_subcontractors {
            id
            pk: id @__clientField(handle: "pk")
            subcontractor {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
            add_daily_work_log_during_ptp
          }
        }
      }
    }
    general_contractor_employee_connection(
      where: {
        user: { email: { _is_null: false } }
        employee_projects: { project_id: { _eq: $projectId } }
      }
      order_by: { user: { name: asc } }
    ) {
      edges {
        node {
          user {
            name
            email
          }
        }
      }
    }
  }
`;

const insertChecklistItemMutation = graphql`
  mutation GCProjectReportsDailySettingsInsertItemMutation(
    $object: checklist_item_insert_input!
  ) {
    insert_checklist_item_one(object: $object) {
      ...ChecklistItemFrag @relay(mask: false)
    }
  }
`;

const updateChecklistItemMutation = graphql`
  mutation GCProjectReportsDailySettingsUpdateItemMutation(
    $id: uuid!
    $_set: checklist_item_set_input
  ) {
    update_checklist_item_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

const deleteChecklistItemMutation = graphql`
  mutation GCProjectReportsDailySettingsDeleteItemMutation($id: uuid!) {
    delete_checklist_item_by_pk(id: $id) {
      id
    }
  }
`;

const insertChecklistItemConfigMutation = graphql`
  mutation GCProjectReportsDailySettingsInsertConfigMutation(
    $object: checklist_item_config_insert_input!
  ) {
    insert_checklist_item_config_one(object: $object) {
      id
    }
  }
`;

const deleteChecklistItemConfigMutation = graphql`
  mutation GCProjectReportsDailySettingsDeleteConfigMutation(
    $where: checklist_item_config_bool_exp!
  ) {
    delete_checklist_item_config(where: $where) {
      affected_rows
    }
  }
`;

const updateChecklistItemNotifyeesMutation = graphql`
  mutation GCProjectReportsDailySettingsUpdateItemNotifyeesMutation(
    $checklistItemId: uuid!
    $objects: [checklist_item_notify_email_insert_input!]!
  ) {
    delete_checklist_item_notify_email(
      where: { checklist_item_id: { _eq: $checklistItemId } }
    ) {
      affected_rows
    }
    insert_checklist_item_notify_email(objects: $objects) {
      affected_rows
    }
  }
`;

const updateProjectSubMutation = graphql`
  mutation GCProjectReportsDailySettings_UpdateProjectSub_Mutation(
    $where: project_subcontractor_bool_exp!
    $_set: project_subcontractor_set_input!
  ) {
    update_project_subcontractor(where: $where, _set: $_set) {
      returning {
        id
        add_daily_work_log_during_ptp
      }
    }
  }
`;

type SelectEmailsModalValues = { notifyees: Array<string> };
type SelectEmailsModalRef = FModalRef<SelectEmailsModalValues> | null;
const SelectEmailsModal = forwardRef<
  SelectEmailsModalRef,
  {
    contacts: Array<{
      email: string;
      name: string;
    }>;

    onCancel: () => void;
    onCreate: (values: SelectEmailsModalValues) => void;
  }
>(({ contacts, onCreate, onCancel }, ref) => {
  const modal = useRef<SelectEmailsModalRef>(null);
  const [loading, setLoading] = useState(false);

  useImperativeHandle<SelectEmailsModalRef, SelectEmailsModalRef>(
    ref,
    () => modal.current
  );

  return (
    <FModal
      title="Select Notifyees"
      ref={modal}
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        modal.current?.form.resetFields();
      }}
      onOk={() => {
        const form = modal.current?.form;
        if (!form) return;
        form
          .validateFields()
          .then(async (v) => {
            setLoading(true);
            await onCreate(v);
            modal.current?.close();
            modal.current?.form.resetFields();
            setLoading(false);
          })
          .catch((e) => {
            console.log("Validate Failed:", e);
          });
      }}
    >
      <FModal.Select
        name="notifyees"
        props={{
          mode: "tags",
          placeholder: "Select notifyees",
          options: [...contacts].map((c) => ({
            key: c.email + c.name,
            value: c.email,
            label: `${c.name}, ${c.email}`,
          })),
        }}
      />
    </FModal>
  );
});

interface PermitChecklistTableProps {
  checklistItems: Array<ChecklistItemFrag$data>;
  onReorderItem: (args: {
    item: ChecklistItemFrag$data;
    newSortIndex: number;
  }) => any;
  editing: boolean;
  onUpdateItem: (args: {
    key: string;
    val: boolean;
    item: ChecklistItemFrag$data;
  }) => any;
  onDeleteItem: (args: { item: ChecklistItemFrag$data }) => any;
  loading: boolean;
  onAddItemPress: () => any;
  onNotifyeesPress: (args: { item: ChecklistItemFrag$data }) => any;
  title: string;
}

const PermitChecklistTable = ({
  checklistItems,
  onReorderItem,
  editing,
  onUpdateItem,
  onDeleteItem,
  onAddItemPress,
  loading,
  onNotifyeesPress,
  title,
}: PermitChecklistTableProps) => {
  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <DraggableTableWrapper
        onMoveRow={async (dragIndex: number, hoverIndex: number) => {
          if (dragIndex === hoverIndex) {
            return;
          }

          const dragStep = checklistItems[dragIndex];
          const hoverStep = checklistItems[hoverIndex];

          let newSortIndex = 0;
          if (hoverIndex === 0) {
            newSortIndex = (hoverStep.sort_index as any) / 2;
          } else if (hoverIndex === checklistItems.length - 1) {
            newSortIndex = ((dragStep.sort_index as any) +
              hoverStep?.sort_index) as any;
          } else {
            const nextHoverStep =
              dragIndex > hoverIndex
                ? checklistItems[hoverIndex - 1]
                : checklistItems[hoverIndex + 1];
            newSortIndex =
              ((hoverStep.sort_index as any) + nextHoverStep.sort_index) / 2;
          }

          onReorderItem({
            item: dragStep,
            newSortIndex,
          });
        }}
      >
        {{
          table: (() => {
            const columns: Array<any> = [];

            if (editing) {
              columns.push({
                title: "Reorder",
                key: "reorder",
                width: 80,
                render: () => (
                  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
                ),
              });
            }

            columns.push(
              ...[
                {
                  title: "Description",
                  key: "description",
                  size: "sm",
                  dataIndex: ["description", "clientText"],
                },
                {
                  title: "Item Options",
                  key: "options",
                  size: "md",
                  render: (item: ChecklistItemFrag$data) => {
                    const availableOptions = [
                      {
                        value: "option_yes",
                        label: "Yes",
                      },
                      {
                        value: "option_no",
                        label: "No",
                      },
                      {
                        value: "option_na",
                        label: "N/A",
                      },
                    ];

                    if (
                      !!item.checklist_item_configs.find(
                        (c) => c.config === "option_yes"
                      )
                    ) {
                      availableOptions.push(
                        {
                          value: "response_on_yes",
                          label: "When Yes, response required",
                        },
                        { value: "notify_yes", label: "When Yes, Notify" }
                      );
                    }

                    if (
                      !!item.checklist_item_configs.find(
                        (c) => c.config === "option_no"
                      )
                    ) {
                      availableOptions.push(
                        {
                          value: "response_on_no",
                          label: "When No, response required",
                        },
                        { value: "notify_no", label: "When No, Notify" }
                      );
                    }

                    return editing ? (
                      <Space>
                        {availableOptions.map((o) => (
                          <Checkbox
                            key={o.value}
                            checked={
                              !!item.checklist_item_configs.find(
                                (c) => c.config === o.value
                              )
                            }
                            onChange={(e) => {
                              onUpdateItem({
                                key: o.value,
                                val: !!e.target.checked,
                                item,
                              });
                            }}
                          >
                            {o.label}
                          </Checkbox>
                        ))}
                        {!!item.checklist_item_configs.find(
                          (c) =>
                            c.config === "notify_yes" ||
                            c.config === "notify_no"
                        ) && (
                          <>
                            {editing ? (
                              <Button
                                onClick={() => {
                                  onNotifyeesPress({ item });
                                }}
                              >
                                Notify{" "}
                                {item.checklist_item_notify_emails.length}
                              </Button>
                            ) : (
                              <span>
                                Notify{" "}
                                {item.checklist_item_notify_emails.length}
                              </span>
                            )}
                          </>
                        )}
                      </Space>
                    ) : (
                      <Space size="large">
                        {[...item.checklist_item_configs]
                          .sort((a, b) =>
                            CHECKLIST_CONFIGS.findIndex(
                              (c) => c.value === a.config
                            ) >
                            CHECKLIST_CONFIGS.findIndex(
                              (c) => c.value === b.config
                            )
                              ? 1
                              : -1
                          )
                          .map((o) => (
                            <span key="o.name">
                              {
                                CHECKLIST_CONFIGS.find(
                                  (c) => c.value === o.config
                                )!.label
                              }
                            </span>
                          ))}
                      </Space>
                    );
                  },
                },
              ]
            );

            if (editing) {
              columns.push({
                title: "Actions",
                key: "actions",
                width: 100,
                render: (item: ChecklistItemFrag$data) => (
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={noPropagation(() => {
                      onDeleteItem({
                        item,
                      });
                    })}
                    onCancel={noPropagation()}
                    okText="Yes"
                    cancelText="Cancel"
                  >
                    <Button danger type="link" onClick={noPropagation()}>
                      Remove
                    </Button>
                  </Popconfirm>
                ),
              });
            }

            return (
              <BaseTable
                title={() => (
                  <TableTitle
                    title={title}
                    titleComponent={
                      <>
                        {editing ? (
                          <Button
                            type="primary"
                            loading={loading}
                            onClick={() => {
                              onAddItemPress();
                            }}
                          >
                            Add Item
                          </Button>
                        ) : undefined}
                      </>
                    }
                  />
                )}
                dataSource={[...checklistItems].sort((a, b) =>
                  (a.sort_index as any) > (b.sort_index as any) ? 1 : -1
                )}
                rowKey={(item) => item.id}
                loading={loading}
                columns={columns}
                pagination={false}
              />
            );
          })(),
        }}
      </DraggableTableWrapper>
    </Space>
  );
};

interface GCProjectReportsDailySettingsProps {
  projectId: string;
}

const GCProjectReportsDailySettings: React.FC<
  GCProjectReportsDailySettingsProps
> = ({ projectId }) => {
  const [editing, setEditing] = useState(false);
  const [addingItemType, setAddingItemType] = useState<"worker" | "gc">();
  const [
    showSelectProjectSubcontractorModal,
    setShowSelectProjectSubcontractorModal,
  ] = useState(false);
  const selectProjectSubcontractorModalOpen = () => {
    setShowSelectProjectSubcontractorModal(true);
  };
  const selectProjectSubcontractorModalClose = () => {
    setShowSelectProjectSubcontractorModal(false);
  };
  const environment = useRelayEnvironment();

  const data = useLazyLoadQuery<GCProjectReportsDailySettingsQuery>(
    dailyReportSettingsQuery,
    { projectId }
    // { networkCacheConfig: { force: true } },
  );
  const [updateChecklistItem] =
    useAsyncMutation<GCProjectReportsDailySettingsUpdateItemMutation>(
      updateChecklistItemMutation
    );
  const [deleteChecklistItem] =
    useAsyncMutation<GCProjectReportsDailySettingsDeleteItemMutation>(
      deleteChecklistItemMutation
    );
  const [insertChecklistItem] =
    useAsyncMutation<GCProjectReportsDailySettingsInsertItemMutation>(
      insertChecklistItemMutation
    );
  const [insertChecklistItemConfig] =
    useAsyncMutation<GCProjectReportsDailySettingsInsertConfigMutation>(
      insertChecklistItemConfigMutation
    );
  const [deleteChecklistItemConfig] =
    useAsyncMutation<GCProjectReportsDailySettingsDeleteConfigMutation>(
      deleteChecklistItemConfigMutation
    );
  const [updateChecklistItemNotifyees] =
    useAsyncMutation<GCProjectReportsDailySettingsUpdateItemNotifyeesMutation>(
      updateChecklistItemNotifyeesMutation
    );
  const [updateProjectSub] =
    useAsyncMutation<GCProjectReportsDailySettings_UpdateProjectSub_Mutation>(
      updateProjectSubMutation
    );
  const checklistItems = getConnectionNodes(data.checklist_item_connection);
  const employees = getConnectionNodes(
    data.general_contractor_employee_connection
  );
  const gcChecklistItems = checklistItems.filter((c) => c.type === "gc_daily");
  const workerChecklistItems = checklistItems.filter(
    (c) => c.type === "gc_worker_daily_log"
  );
  const addItemModal = useRef<AddChecklistItemModalRef>(null);
  const selectEmailsModal = useRef<SelectEmailsModalRef>(null);
  const [selectingEmailsItemId, setSelectingEmailsItemId] = useState<string>();

  const tableProps: Omit<
    PermitChecklistTableProps,
    "title" | "checklistItems" | "onAddItemPress"
  > = {
    loading: false,
    editing,
    onNotifyeesPress: ({ item }) => {
      setSelectingEmailsItemId(item.pk);
      selectEmailsModal.current?.form.setFieldsValue({
        notifyees: item.checklist_item_notify_emails.map((e) => e.email),
      });
      selectEmailsModal.current?.open();
    },
    onUpdateItem: ({ item, key, val }) => {
      if (val) {
        const id = uuid.v4();

        insertChecklistItemConfig({
          variables: {
            object: {
              id,
              checklist_item_id: item.pk,
              config: key as any,
            },
          },
        });

        commitLocalUpdate(environment, (store) => {
          const record = store.get(item.id)!;
          const configs = record.getLinkedRecords("checklist_item_configs")!;
          const newId = uuid.v4();
          const configRecord = store.create(newId, "__");
          configRecord.setValue(newId, "pk");
          configRecord.setValue(key, "config");

          record.setLinkedRecords(
            [...configs, configRecord],
            "checklist_item_configs"
          );
        });
      } else {
        const deletingKeys = [key];

        if (key === "option_yes") {
          deletingKeys.push("response_on_yes", "notify_yes");
        } else if (key === "option_no") {
          deletingKeys.push("response_on_no", "notify_no");
        }

        deleteChecklistItemConfig({
          variables: {
            where: {
              config: { _in: deletingKeys as any },
              checklist_item_id: {
                _eq: item.pk,
              },
            },
          },
        });

        commitLocalUpdate(environment, (store) => {
          const record = store.get(item.id)!;
          const configs = record.getLinkedRecords("checklist_item_configs")!;
          record.setLinkedRecords(
            configs.filter(
              (c) => !deletingKeys.includes(c.getValue("config") as string)
            ),
            "checklist_item_configs"
          );
        });
      }
    },
    onDeleteItem: ({ item }) => {
      commitLocalUpdate(environment, (store) => {
        const conn = ConnectionHandler.getConnection(
          store.getRoot(),
          "GCProjectReportsDailySettings_checklist_item_connection",
          {
            where: {
              project_id: { _eq: projectId },
              data_type: { _eq: "base" },
            },
          }
        )!;

        conn.setLinkedRecords(
          conn
            .getLinkedRecords("edges")!
            .filter((e) => e.getLinkedRecord("node")!.getDataID() !== item.id),
          "edges"
        );
      });

      deleteChecklistItem({
        variables: {
          id: item.pk,
        },
      });
    },
    onReorderItem: ({ item, newSortIndex }) => {
      commitLocalUpdate(environment, (store) => {
        const record = store.get(item.id)!;
        record.setValue(newSortIndex, "sort_index");
      });
      updateChecklistItem({
        variables: {
          id: item.pk,
          _set: { sort_index: newSortIndex },
        },
      });
    },
  };

  const [toggleAddDailyWorkLogWithPTP] =
    useAsyncMutation<GCProjectReportsDailySettings_toggleAddDailyWorkLogWithPTP_Mutation>(
      graphql`
        mutation GCProjectReportsDailySettings_toggleAddDailyWorkLogWithPTP_Mutation(
          $projectId: uuid!
          $value: Boolean!
        ) {
          update_project_by_pk(
            pk_columns: { id: $projectId }
            _set: { add_daily_work_log_during_ptp: $value }
          ) {
            id
            add_daily_work_log_during_ptp
          }
        }
      `
    );

  return (
    <div className="flex flex-col w-full">
      <div>
        <PermitChecklistTable
          {...tableProps}
          checklistItems={gcChecklistItems}
          title={"GC Checklist"}
          onAddItemPress={() => {
            setAddingItemType("gc");
            addItemModal.current?.open();
          }}
        />
        <PermitChecklistTable
          {...tableProps}
          checklistItems={workerChecklistItems}
          title={"Subcontractor Checklist"}
          onAddItemPress={() => {
            setAddingItemType("worker");
            addItemModal.current?.open();
          }}
        />
        <Space>
          {editing ? (
            <Button type={"link"} onClick={() => setEditing(false)}>
              Done
            </Button>
          ) : (
            <Button
              type={"primary"}
              onClick={noPropagation(() => {
                setEditing(true);
              })}
            >
              Edit Checklists
            </Button>
          )}
        </Space>
      </div>

      <SelectEmailsModal
        ref={selectEmailsModal}
        // TODO FIX, make update with selected employees
        contacts={employees
          .map((e) => ({
            name: e.user.name,
            email: e.user.email!,
          }))
          .filter(
            (e) =>
              ![...gcChecklistItems, ...workerChecklistItems]
                .find((item) => item.pk === selectingEmailsItemId)
                ?.checklist_item_notify_emails.find((n) => n.email === e.email)
          )}
        onCancel={() => selectEmailsModal.current?.close()}
        onCreate={async (values) => {
          if (selectingEmailsItemId)
            await updateChecklistItemNotifyees({
              variables: {
                checklistItemId: selectingEmailsItemId,
                objects: values.notifyees.map((email) => ({
                  email,
                  checklist_item_id: selectingEmailsItemId,
                })),
              },
            });
          else {
            throw new Error("Id not found to be updated");
          }
          await fetchQuery<GCProjectReportsDailySettingsQuery>(
            environment,
            dailyReportSettingsQuery,
            { projectId },
            { networkCacheConfig: { force: true } }
          ).toPromise();

          selectEmailsModal.current?.close();
        }}
      />
      <AddChecklistItemModal
        ref={addItemModal}
        contacts={employees.map((e) => ({
          name: e.user.name,
          email: e.user.email!,
        }))}
        onCreate={async (values) => {
          const checklistItems =
            addingItemType === "gc" ? gcChecklistItems : workerChecklistItems;

          await insertChecklistItem({
            variables: {
              object: {
                type:
                  addingItemType === "gc" ? "gc_daily" : "gc_worker_daily_log",
                data_type: "base",
                project_id: projectId,
                checklist_item_configs: {
                  data: values.selectedOptions.map((o) => ({
                    config: o as any,
                  })),
                },
                checklist_item_notify_emails: {
                  data: (values.notifyees ?? []).map((email) => ({
                    email,
                  })),
                },
                sort_index:
                  (checklistItems.length > 0
                    ? Math.max(
                        ...checklistItems.map((c) => c.sort_index as number)
                      )
                    : 0) + 1,
                description: {
                  data: {
                    original: values.description,
                  },
                },
              },
            },
            updater: (e) => {
              const newData = e.getRootField("insert_checklist_item_one");
              const newEdge = e.create(nanoid(), "edge");
              newEdge.setLinkedRecord(newData, "node");

              const conn = ConnectionHandler.getConnection(
                e.getRoot(),
                "GCProjectReportsDailySettings_checklist_item_connection",
                {
                  where: {
                    project_id: { _eq: projectId },
                    data_type: { _eq: "base" },
                  },
                }
              )!;

              conn.setLinkedRecords(
                [...conn.getLinkedRecords("edges")!, newEdge],
                "edges"
              );
            },
          });
        }}
        onCancel={() => {
          setAddingItemType(undefined);
          addItemModal.current?.close();
        }}
      />
      {data.project_connection.edges.length > 0 && (
        <div className="flex flex-col gap-1 mt-2">
          <p className="inline-flex flex-row items-center gap-0.5">
            Foremen will submit their Daily Report at the same time as their
            Pre-Task Plan
            <Switcher
              options={[
                { label: "on", icon: IconCheck },
                { label: "off", icon: IconX },
              ]}
              optionIndex={
                data.project_connection.edges[0].node
                  .add_daily_work_log_during_ptp
                  ? 0
                  : 1
              }
              onChange={(selectedOptionIndex) => {
                const ptpDailyReportOn = selectedOptionIndex === 0;
                toggleAddDailyWorkLogWithPTP({
                  variables: {
                    projectId: projectId!,
                    value: ptpDailyReportOn,
                  },
                  optimisticResponse: {
                    update_project_by_pk: {
                      id: projectId,
                      add_daily_work_log_during_ptp: ptpDailyReportOn,
                    },
                  },
                }).then(
                  (res) => {
                    const value =
                      res.update_project_by_pk?.add_daily_work_log_during_ptp;
                    value ? message.success("On") : message.error("Off");
                  },
                  (error) => {
                    message.error(
                      `ERROR: ${
                        error instanceof Error
                          ? error.message
                          : JSON.stringify(error)
                      }`
                    );
                  }
                );
              }}
            />
          </p>
          {data.project_connection.edges[0].node
            .add_daily_work_log_during_ptp && (
            <div className="flex flex-col gap-1">
              <p className="text-1.5">Select Subcontractors</p>
              <p style={{ marginBottom: 9, fontWeight: 400 }}>
                You can require all or some Subcontractors to submit their Daily
                Report with their Pre-Task Plan
              </p>
              <div className="-mx-0.25">
                {data.project_connection.edges[0].node.project_subcontractors
                  .filter((sub) => sub.add_daily_work_log_during_ptp)
                  .map((sub) => (
                    <span
                      className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-suplementary-1"
                      key={sub.pk}
                    >
                      {sub.subcontractor.name}
                    </span>
                  ))}
              </div>

              <div className="flex flex-row justify-start">
                <Button
                  type="primary"
                  style={{ borderRadius: 100 }}
                  onClick={() => selectProjectSubcontractorModalOpen()}
                  loading={false}
                  // label={}
                >{`Select Subcontractor(s)`}</Button>
              </div>
              <SelectProjectSubcontractorModal
                defaultProjectSubs={data.project_connection.edges[0].node.project_subcontractors
                  .filter((sub) => sub.add_daily_work_log_during_ptp)
                  .map((sub) => sub.pk)}
                modalClose={selectProjectSubcontractorModalClose}
                modalVisible={showSelectProjectSubcontractorModal}
                onSubmit={async (projectSubIds: string[]) => {
                  const projectSubs =
                    data.project_connection.edges[0].node
                      .project_subcontractors;
                  const selectedProjectSubs = projectSubs.filter((sub) =>
                    projectSubIds.find((id) => id === sub.pk)
                  );
                  const unselectProjectSubs = projectSubs.filter(
                    (sub) => !projectSubIds.find((id) => id === sub.pk)
                  );
                  updateProjectSub({
                    variables: {
                      where: {
                        id: { _in: selectedProjectSubs.map((sub) => sub.pk) },
                      },
                      _set: {
                        add_daily_work_log_during_ptp: true,
                      },
                    },
                    optimisticResponse: {
                      update_project_subcontractor: {
                        returning: selectedProjectSubs.map((sub) => ({
                          id: sub.id,
                          add_daily_work_log_during_ptp: true,
                        })),
                      },
                    },
                  });
                  updateProjectSub({
                    variables: {
                      where: {
                        id: { _in: unselectProjectSubs.map((sub) => sub.pk) },
                      },
                      _set: {
                        add_daily_work_log_during_ptp: false,
                      },
                    },
                    optimisticResponse: {
                      update_project_subcontractor: {
                        returning: unselectProjectSubs.map((sub) => ({
                          id: sub.id,
                          add_daily_work_log_during_ptp: false,
                        })),
                      },
                    },
                  });
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withCustomSuspense(GCProjectReportsDailySettings);
