import React, { useEffect, useState, FC } from 'react';
import { Input, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/lib/table/interface';

interface SearchDropdownProps extends FilterDropdownProps {
  setter: (val: string | undefined) => void;
}

const SearchDropdown: FC<SearchDropdownProps> = ({ confirm, setter }) => {
  const [inputValue, setInputValue] = useState('');

  const handleSearch = () => {
    if (inputValue) {
      let val = inputValue.toString().replace(' ', '%');
      val += '%';
      setter(val);
    } else {
      setter(undefined);
    }
    confirm();
  };
  const handleReset = () => {
    setInputValue('');
    setter(undefined);
    confirm();
  };

  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder="Search..."
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onPressEnter={handleSearch}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={handleSearch}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={handleReset} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  );
};

const getColumnSearchProps = (setter: (val: string | undefined) => void) => {
  return {
    filterDropdown: (props: FilterDropdownProps) => (
      <SearchDropdown {...props} setter={setter} />
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  };
};

export default getColumnSearchProps;
