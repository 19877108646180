import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useUpsertWorkerOneMutation } from "src/common/types/generated/relay/useUpsertWorkerOneMutation.graphql";

const useUpsertWorkerOne = () => {
  const [insert] = useAsyncMutation<useUpsertWorkerOneMutation>(
    graphql`
      mutation useUpsertWorkerOneMutation(
        $object: worker_insert_input!
        $updateColumns: [worker_update_column!]!
      ) {
        insert_worker_one(
          object: $object
          on_conflict: {
            constraint: worker_pkey
            update_columns: $updateColumns
          }
        ) {
          id
        }
      }
    `
  );
  return insert;
};
export default useUpsertWorkerOne;
