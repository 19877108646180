import { Language } from "./language-utils/i18nTypes";

export const DEFAULT_LANGUAGE: Language = "en";
export const AVAILABLE_LANGUAGES: Language[] = [
  "en",
  "es",
  "pt",
  "it",
  "fr",
  "zh_hans",
];
export const AVAILABLE_DB_LANGUAGES: Language[] = ["en", "es", "pt"];
//do not change this order

