import { Table } from "antd";
import dayjs from "dayjs";
import React, { FC } from "react";
import { ViewAndEditJhaQuery$data } from "src/common/types/generated/relay/ViewAndEditJhaQuery.graphql";

type TaskSubAdminApprovalDataType =
  ViewAndEditJhaQuery$data["task_signature_connection"]["edges"];

interface Props {
  workerSignaturesData: TaskSubAdminApprovalDataType;
  projectId: string;
}

const TaskSignaturesTable: FC<Props> = ({
  workerSignaturesData,
  projectId,
}) => {
  let dataSource: any[];
  dataSource = workerSignaturesData
    .filter((x) => !!(x.node.signature_image_id))
    .map((c: any) => {
      const user = c.node.user;
      const hardHat = !!(user?.worker?.worker_projects)
        ? c.node.user?.worker?.worker_projects.filter(
            (x: any) => x.project_id == projectId,
          )
        : [];
      return {
        key: c.node.pk,
        date: dayjs(c.node.created_at).format("MMM DD, YYYY h:mm A"),

        sign: <img src={c.node?.image?.url}></img>,
        name: [
          user?.name,
          user?.worker?.current_worker_role,
          user?.worker.worker_title?.translation.en
        ].filter(v => !!v).join(", "),
      };
    });

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      width: "50%",
      defaultSordOrder: "ascend",
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      sorter: (a: any, b: any) => (!!a.date ? a.date.localeCompare(b.date) : 1),
    },
    {
      title: "Signature",
      key: "sign",
      dataIndex: "sign",
      width: "30%",
    },
  ];

  let x = 1;

  return (
    <>
      <Table columns={columns} dataSource={dataSource} bordered />
    </>
  );
};

export default TaskSignaturesTable;
