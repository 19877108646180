import { graphql } from "babel-plugin-relay/macro"

const upsertOrientationResultsMutation = graphql`
  mutation upsertOrientationResultsMutation_UpsertOrientationResult_Mutation(
    $objects: [orientation_result_insert_input!]!
  ) {
    insert_orientation_result(
      objects: $objects
      on_conflict: {
        constraint: orientation_result_pkey
        update_columns: [
          total_slides
          viewed_slides
          signature_url
          status
          expired_at
          completed_at
          group_id
          result_inserted_by_uid
        ]
      }
    ) {
      affected_rows
    }
  }
`

export default upsertOrientationResultsMutation
