import { Button, message, notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import pluralize from "pluralize";
import React, { FC, useRef, useState } from "react";
import AddProjectWorkerModal from "src/common/components/dialogs/AddProjectWorkerModal";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import BSpace from "src/common/components/layouts/BSpace";
import StyledContent from "src/common/components/layouts/StyledContent";
import ProjectWorkerTable from "src/common/components/tables/ProjectWorkerTable";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import {
  useAddCorporateOrientationResultsOnNewProjectMutation,
  useEmailGcAboutDtWorkerArrivalMutation,
  useEmailOrientationInviteMutation,
  useSendOrientationLinkTextMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { ProjectWorkerTable_project_worker$data } from "src/common/types/generated/relay/ProjectWorkerTable_project_worker.graphql";
import { SCProjectQuery$data } from "src/common/types/generated/relay/SCProjectQuery.graphql";
import { SCProjectWorkersMutation } from "src/common/types/generated/relay/SCProjectWorkersMutation.graphql";
import SelectHowToSendOrientationInvite from "../people/workers/components/SelectHowToSendOrientationInvite";

interface SCProjectWorkersProps {
  projectId: string;
  subcontractorId: string;
  data: SCProjectQuery$data;
  refresh: () => void;
}

const SCProjectWorkers: FC<SCProjectWorkersProps> = ({
  projectId,
  subcontractorId,
  refresh,
  data,
}) => {
  const [emailInvite, { loading: emailing }] =
    useEmailOrientationInviteMutation();
  const [addWorkerModalOpen, setAddWorkerModalOpen] = useState(false);
  const [insertProjectWorkers, loading] =
    useAsyncMutation<SCProjectWorkersMutation>(graphql`
      mutation SCProjectWorkersMutation(
        $objects: [project_worker_insert_input!]!
      ) {
        insert_project_worker(objects: $objects) {
          affected_rows
        }
      }
    `);
  const [addCorporateOrientationResultsOnNewProject] =
    useAddCorporateOrientationResultsOnNewProjectMutation();
  const projectWorkerTable = useRef<DataScrollTableRef>(null);
  const modalRef = useRef<FModalRef<any>>(null);
  const [textInvite, { loading: textLoading }] =
    useSendOrientationLinkTextMutation();

  const hasInPerson =
    data.project_connection.edges[0]?.node.in_person_orientation;
  const hasSlides = data.orientation_connection.edges.length > 0;
  const [selectedWorker, setSelectedWorker] = useState<
    | ProjectWorkerTable_project_worker$data["project_worker_connection"]["edges"][number]["node"]["user"]
    | undefined
  >(undefined);
  const [emailForDT] = useEmailGcAboutDtWorkerArrivalMutation();
  const workers = data.worker_connection.edges;
  return (
    <StyledContent padding backgroundColor="white">
      {selectedWorker && (
        <SelectHowToSendOrientationInvite
          onClose={() => setSelectedWorker(undefined)}
          visible={!!selectedWorker}
          projectId={projectId}
          hasSlides={hasSlides}
          worker={selectedWorker}
          refetch={() => projectWorkerTable.current?.refetch()}
        />
      )}
      <AddProjectWorkerModal
        workers={workers ?? []}
        visible={addWorkerModalOpen}
        confirmLoading={loading}
        onCancel={() => setAddWorkerModalOpen(false)}
        onCreate={async (values) => {
          await insertProjectWorkers({
            variables: {
              objects: values.workerUids.map((workerUid) => {
                const worker = workers.find((w) => w.node.pk === workerUid)!;
                return {
                  project_id: projectId,
                  worker_id: workerUid,
                  // project_crew_id: values.crewId,
                  worker_role: worker.node.current_worker_role,
                  title_id: worker.node.worker_title?.pk,
                  subcontractor_id: subcontractorId,
                  can_insert_report: true,
                };
              }),
            },
          });
          refresh();
          emailForDT({
            variables: {
              input: {
                projectId: projectId,
                userIds: values.workerUids,
                sendForPositive: true,
              },
            },
          }).catch(console.error);
          addCorporateOrientationResultsOnNewProject({
            variables: {
              input: { project_id: projectId, user_ids: values.workerUids },
            },
          }).catch(console.log);
          projectWorkerTable.current?.refetch();
          setAddWorkerModalOpen(false);
          message.success(
            `Added ${pluralize("Worker", values.workerUids.length, true)} to ${
              data.project_connection.edges[0].node.name ?? "undefined"
            }!`,
          );
        }}
      />

      <BSpace>
        <ProjectWorkerTable
          ref={projectWorkerTable}
          hasInPerson={hasInPerson}
          hasSlides={hasSlides}
          hasUniversal={
            data.project_connection.edges[0].node.agc_universal_orientation &&
            data.universalRequired.edges.length > 0
          }
          excludedKeys={["subcontractor", "project", "dateLogged"]}
          where={{
            project_id: {
              _eq: projectId,
            },
            subcontractor_id: {
              _eq: subcontractorId,
            },
          }}
          projectId={projectId}
          onRowClick={() => {
            // TODO
          }}
          topBarButtons={[
            {
              loading: loading,
              label: "Add Worker to Project",
              onClick: () => {
                setAddWorkerModalOpen(true);
              },
            },
          ]}
          extraColumns={
            hasSlides || hasInPerson
              ? [
                  {
                    title: "Send Orientation Link",
                    size: "md",
                    key: "extra",
                    dataIndex: ["user_orientation"],
                    queryIncludeVarKey: "includeOrienation",
                    render: (a, r) => {
                      let workerFullyOrientated = !!(
                        a?.completed_at
                      );
                      const inPersonOrientated = !!(
                        a?.in_person_orientated_at
                      );
                      const isOrientated = !!(a?.orientated_at);
                      if (hasSlides && hasInPerson)
                        workerFullyOrientated =
                          workerFullyOrientated || (inPersonOrientated && isOrientated);
                      else if (hasInPerson)
                        workerFullyOrientated =
                          workerFullyOrientated || inPersonOrientated;
                      else if (hasSlides)
                        workerFullyOrientated =
                          workerFullyOrientated || isOrientated;

                      return !workerFullyOrientated ? (
                        <Button
                          size="small"
                          type="primary"
                          className="rounded-2"
                          onClick={() => {
                            if (r.user) setSelectedWorker(r.user);
                          }}
                        >
                          {hasSlides && !(a?.completed_at || a?.orientated_at)
                            ? "Orientation +"
                            : "Registration +"}
                        </Button>
                      ) : (
                        ""
                      );
                    },
                  },
                ]
              : []
          }
        />
      </BSpace>
    </StyledContent>
  );
};
export default SCProjectWorkers;
