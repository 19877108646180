import { Table } from "antd";
import dayjs from "dayjs";
import React, { FC } from "react";
import { ViewAndEditJhaQuery$data } from "src/common/types/generated/relay/ViewAndEditJhaQuery.graphql";

type TaskEditDataType =
  ViewAndEditJhaQuery$data["task_edit_connection"]["edges"];

interface Props {
  taskEditData: TaskEditDataType;
}

export const RoleConvert = (
  current:
    | TaskEditDataType[number]["node"]["edited_by_user"]
    | null
    | undefined,
): Array<string | undefined> => {
  let company: string | undefined = undefined;
  let role: string | undefined = undefined;
  let roleType = current?.role;
  if (roleType == "worker") {
    company = current?.worker?.subcontractor?.name;
    role = current?.worker?.current_worker_role;
  } else if (roleType == "employee") {
    company = current?.employee?.general_contractor?.name;
    role = current?.employee?.employee_title?.name?.en;
  } else if (roleType == "subcontractor_employee") {
    company = current?.subcontractor_employee?.subcontractor?.name;
    role = current?.subcontractor_employee?.employee_title?.name?.en;
  } else {
    role = "";
    company = "";
  }
  // console.log(role, company, roleType, current);
  role = !role ? "" : " - " + role;
  company =  company ?? "";
  return [role, company];
};
const TaskEditsTable: FC<Props> = ({ taskEditData }) => {
  let x = 1;
  const dataSource = taskEditData.map((current) => {
    const [employeeTitle, workingCompany] = RoleConvert(
      current.node.edited_by_user,
    );
    let obj = {
      key: x++,
      editedAt: current.node.edited_at,
      editedBy: current.node.edited_by_user
        ? current.node.edited_by_user.name + employeeTitle
        : current.node.email_request?.email,
      editType: current.node.edit_type,
      company: workingCompany,
    };
    return obj;
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "editedAt",
      key: "editedAt",
      width: "22%",
      render: (d: any) => (d ? dayjs(d).format("LLL") : ""),
    },
    {
      title: "Action",
      dataIndex: "editType",
      key: "editType",
      width: "26%",
    },
    {
      title: "User",
      dataIndex: "editedBy",
      key: "editedBy",
      width: "30%",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: "22%",
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={dataSource} bordered />
    </>
  );
};

export default TaskEditsTable;
