import NestedRoute from "src/common/types/manual/NestedRoute";
import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import SCToolboxTalks from "./SCToolboxTalks";

const scToolboxTalksRoute: NestedRoute = {
  element: <Outlet />,
  children: [
    {
      path: ":subview",
      element: <SCToolboxTalks />,
    },
    {
      path: "",
      element: <Navigate replace to="completed" />,
    },
  ],
};

export default scToolboxTalksRoute;
