import {
  useUpdateInjuryDetailPpeTypesMutation,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  GetIncidentByPkDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import createIncidentPatch from "./createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";

const useUpdateInjuryDetailPpeTypes = () => {
  const [updateInjuryDetailPpeTypes] = useUpdateInjuryDetailPpeTypesMutation();
  const authUser = useAuthUser();

  const updateInjuryPpeTypes = async (
    type: string,
    toBeInsertedPpeTypeIds: Array<string>,
    toBeDeletedPpeTypeIds: Array<string>,
    incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
    injuredUserId: string,
    comment: string,
  ) => {
    const injuredUser = incident.injured_users.find(
      (injuredUser) => injuredUser.id === injuredUserId,
    );
    const injuryDetail = injuredUser?.injury_detail;
    if (!injuryDetail) return;

    const filteredPpeTypes =
      injuryDetail?.injury_detail_ppe_types.filter((ppeType) =>
        toBeDeletedPpeTypeIds.includes(ppeType.ppe_type_id),
      ) ?? [];

    const updatedIncident: typeof incident = {
      ...incident,
      injured_users: incident.injured_users.map((injuredUser) => {
        if (injuredUser.id === injuredUserId) {
          return {
            ...injuredUser,
            injury_detail: {
              ...injuryDetail!,
              injury_detail_ppe_types: [
                ...filteredPpeTypes,
                ...toBeInsertedPpeTypeIds.map((id) => ({
                  type: type,
                  ppe_type_id: id,
                })),
              ],
            },
          };
        }
        return injuredUser;
      }),
    };

    const patch = createIncidentPatch(updatedIncident, incident);

    await updateInjuryDetailPpeTypes({
      variables: {
        objects: toBeInsertedPpeTypeIds.map((ppeType) => ({
          ppe_type_id: ppeType,
          type: type,
          injury_detail_id: injuryDetail.id,
        })),
        deleteWhere: { ppe_type_id: { _in: toBeDeletedPpeTypeIds } },
        editObject: {
          comment: comment,
          incident_id: incident.id,
          edited_by_uid: authUser.uid,
          patch: patch,
          edit_type: "injury-detail-ppe-type-update",
        },
      },
      update(cache, result) {
        const insertedPpe =
          result.data?.insert_injury_detail_ppe_type?.returning;
        const deletedPpe =
          result.data?.delete_injury_detail_ppe_type?.returning;
        const filteredPpeTypes = incident.injured_users
          .find((user) => user.id === injuredUserId)
          ?.injury_detail?.injury_detail_ppe_types.filter(
            (ppeType) =>
              deletedPpe?.findIndex(
                (ppe) =>
                  ppe.ppe_type_id === ppeType.ppe_type_id &&
                  ppeType.type === type,
              ) === -1,
          );

        const injuredUserIndex = incident.injured_users.findIndex(
          (injuredUser) => injuredUser.id === injuredUserId,
        );
        if (injuredUserIndex === -1) return;
        const injuredUsers = incident.injured_users.map((user, index) => {
          if (index !== injuredUserIndex) {
            return user;
          }
          const injuryDetail = user.injury_detail;
          if (injuryDetail && insertedPpe && filteredPpeTypes) {
            const newInjuryDetail = {
              ...injuryDetail,
              injury_detail_ppe_types: [...filteredPpeTypes, ...insertedPpe],
            };
            return {
              ...user,
              injury_detail: newInjuryDetail,
            };
          }
          return user;
        });
        const updatedIncident = {
          ...incident,
          injured_users: injuredUsers,
        };
        cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>({
          query: GetIncidentByPkDocument,
          data: {
            __typename: "query_root",
            incident_by_pk: updatedIncident,
          },
        });
      },
    });
  };

  return [updateInjuryPpeTypes];
};

export default useUpdateInjuryDetailPpeTypes;
