import React from "react";
import ScrollSelect, { ScrollSelectProps } from "./ScrollSelect";

interface ScrollMultiSelectProps {
  selectors: ScrollSelectProps[];
}

const ScrollMultiSelect: React.FC<ScrollMultiSelectProps> = (props) => {
  return (
    <div
      className={`w-full bg-suplementary-1 relative h-10 rounded-0.5 overflow-x-hidden`}
    >
      <div className="flex flex-row h-full gap-0 justify-center relative">
        <div
          className={`h-[calc(50%-0.75rem)] absolute w-full bottom-0 left-0 bg-gradient-to-t from-suplementary-1 to-suplementary-1/60 border-t border-suplementary-3 pointer-events-none`}
        ></div>
        <div
          className={`h-[calc(50%-0.75rem)] absolute w-full top-0 left-0 bg-gradient-to-b from-suplementary-1 to-suplementary-1/60 border-b border-suplementary-3 pointer-events-none`}
        ></div>
        {props.selectors.map((selector) => (
          <ScrollSelect key={selector.name} {...selector} />
        ))}
      </div>
    </div>
  );
};

export default ScrollMultiSelect;
