import { Input } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BSpace from "src/common/components/layouts/BSpace";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";

import { SFDrugtestTableQuery } from "src/common/types/generated/relay/SFDrugtestTableQuery.graphql";
import { SFDrugtestTable_certificates_to_verify$data } from "src/common/types/generated/relay/SFDrugtestTable_certificates_to_verify.graphql";

export type ColumnKeys = "worker" | "project" | "date";
const CONNECTION_NAME = "certificates_to_verify_connection";
type DConnection =
  SFDrugtestTable_certificates_to_verify$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  SFDrugtestTableQuery,
  { worker_id: string | unknown; project_id: string | unknown }
> & {
  onRowItemClick: (row: DConnection["edges"][number]["node"]) => void;
  searchString?: string;
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    SFDrugtestTableQuery
  >["columns"];
  markDeleted?: boolean;
  allowEditHH?: boolean;
};
const SFWorkerToVerifyTable = forwardRef<DataScrollTableRef, Props>(
  (
    { title = "Workers", onRowItemClick, searchString, where, ...props },
    ref,
  ) => {
    return (
      <DataScrollTable<DConnection, ColumnKeys, SFDrugtestTableQuery>
        {...props}
        // newCustomTableLook
        ref={ref}
        title={title}
        onRowClick={(row) => onRowItemClick && onRowItemClick(row)}
        where={where}
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allCertifcatesToVerifyConnection"}
        columns={[
          {
            title: "Worker Name",
            key: "worker",
            size: "md",
            sortable: true,
            defaultSortOrder: "asc",
            dataIndex: ["worker", "user", "name"],
            searchDataIndex: ["worker", "user", "name"],
            searchValue: searchString,
            contentType: { type: "row-title" },
          },
          {
            title: "Project",
            key: "project",
            size: "md",
            dataIndex: ["project", "name"],
            searchDataIndex: ["project", "name"],
          },
          {
            title: "Date Submitted",
            key: "date",
            size: "md",
            sortable: true,
            defaultSortOrder: "asc",
            dataIndex: ["created_at"],
            contentType: {
              type: "date",
              renderOptions: () => ({
                format: "full",
                withTime: true,
              }),
            },
            render: (a) => dayjs(a).format("MMM DD, YYYY, h:mm a"),
          },
        ]}
        queryNode={graphql`
          query SFDrugtestTableQuery(
            $first: Int!
            $after: String
            $where: certificates_to_verify_bool_exp!
            $order_by: [certificates_to_verify_order_by!]!
          ) {
            ...SFDrugtestTable_certificates_to_verify
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
            ...SFDrugtestTable_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment SFDrugtestTable_total on query_root
          @argumentDefinitions(
            where: { type: "certificates_to_verify_bool_exp!" }
          )
          @refetchable(queryName: "SFDrugtestTableTotalRefetchableQuery") {
            allCertifcatesToVerifyConnection: certificates_to_verify_connection(
              where: $where
            ) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment SFDrugtestTable_certificates_to_verify on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "certificates_to_verify_bool_exp!" }
            order_by: { type: "[certificates_to_verify_order_by!]!" }
          )
          @refetchable(queryName: "SFDrugtestTableRefetchableQuery") {
            certificates_to_verify_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "SFDrugtestTable_certificates_to_verify_connection"
                filters: ["where", "order_by"]
              ) {
              edges {
                node {
                  pk: id @__clientField(handle: "pk")
                  worker_id
                  project_id
                  created_at
                  worker {
                    user {
                      name
                    }
                  }
                  project {
                    name
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);

const SFDrugtestTable: FC = () => {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const onInputKeyDown = (event: any): any => {
    if (event.key === "Enter") {
      setInputValue(event.target.value);
    }
  };

  return (
    <>
      <BSpace>
        <div className="flex justify-center items-center m-1">
          Drugtest Verification Table
        </div>
        <div className="flex justify-between float-right">
          <Input
            type="primary"
            onKeyDown={onInputKeyDown}
            className="w-18"
            placeholder="Search"
          />
        </div>
      </BSpace>
      <div className="p-1 bg-white">
        <SFWorkerToVerifyTable
          searchString={inputValue}
          where={{
            verified_at: { _is_null: true },
            document: { _eq: "drugtest" },
            status: { _eq: "submitted" },
          }}
          onRowItemClick={(row) => {
            navigate(
              `/sfe/verify/drugtest/${row.pk}/${row.worker_id}${
                row.project_id ? `?projectId=${row.project_id}` : ""
              }`,
            );
          }}
        />
      </div>
    </>
  );
};
export default SFDrugtestTable;
