import React, { useContext, useEffect, useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { Button, Input, message, Select } from "antd";
import {
  SiteOrientationStepProps,
  WorkerResidenceType,
} from "../../../../../../../utils/siteOrientationTypes";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import { NULL_ID } from "src/common/functions/nullId";
import { SiteOrientationInPersonWorkerStepsResidenceQuery } from "src/common/types/generated/relay/SiteOrientationInPersonWorkerStepsResidenceQuery.graphql";
import useUpdateUserWorker from "src/common/api/relay/mutationHooks/useUpdateUserWorker";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import ErrorMessage from "../../../../../../../../../utility-features/error-handling/components/ErrorMessage";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export const residenceQuery = graphql`
  query SiteOrientationInPersonWorkerStepsResidenceQuery(
    $stateCode: String!
    $zipCode: String!
    $cityId: uuid!
  ) {
    city_zip_code_connection(
      where: { zip_code: { _eq: $zipCode } }
      order_by: { city: { name: asc } }
    ) {
      edges {
        node {
          id
          zip_code
          pk: id @__clientField(handle: "pk")
          city {
            name
            state_code
            state {
              name
            }
          }
        }
      }
    }
    state_connection(order_by: { name: asc }) {
      edges {
        node {
          code
          name
        }
      }
    }
    city_connection(
      where: { state_code: { _eq: $stateCode } }
      order_by: { name: asc }
    ) {
      edges {
        node {
          name
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    cityAssociatedZipCodes: city_zip_code_connection(
      where: { city_id: { _eq: $cityId } }
      order_by: { zip_code: asc }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          zip_code
          city_id
        }
      }
    }
  }
`;

export interface SiteOrientationInPersonWorkerStepsResidenceProps
  extends SiteOrientationStepProps {}

const SiteOrientationInPersonWorkerStepsResidence: React.FC<
  SiteOrientationInPersonWorkerStepsResidenceProps
> = (props) => {
  const defaultZip = "00000";
  const defaultState = "ZZ";
  const [params, setParams] = useState({
    stateCode: defaultState,
    zipCode: defaultZip,
    cityId: NULL_ID,
  });
  const data =
    useLazyLoadQuery<SiteOrientationInPersonWorkerStepsResidenceQuery>(
      residenceQuery,
      params,
    );
  const context = useSiteOrientationOrientee();
  const user = context.orientee;
  let defaultResidence: null | WorkerResidenceType = null;
  if (user && user.userType === "worker" && user.residence) {
    defaultResidence = user.residence;
  }
  const [zipCodeInput, setZipCodeInput] = useState("");
  const [cityZipCodeId, setcityZipCodeId] = useState(
    defaultResidence?.cityZipCode?.id,
  );
  const [cityZipCodeIdByCity, setcityZipCodeIdByCity] = useState(
    defaultResidence?.cityZipCode?.id,
  );
  const [searchByCityOrZip, setSearchByCityOrZip] = useState(false); //false for zip and true for city

  const onEnter = async () => {
    if (!context.orientee?.id) {
      message.error("User Not found, please go back and try again");
      return;
    }
    // if (
    //   defaultResidence?.cityZipCode?.id !== cityZipCodeId ||
    //   defaultResidence?.cityZipCode?.id !== cityZipCodeIdByCity
    // ) {
    //   // await update({
    //   //   variables: {
    //   //     userId: context.user.id,
    //   //     workerSet: cityZipCodeId ? { city_zip_code_id: cityZipCodeId } : {},
    //   //     userSet: {},
    //   //   },
    //   // });
    context.updateOrientee((u) =>
      u
        ? {
            ...u,
            residence: {
              stateCode: params.stateCode,
              cityId: params.cityId,
              cityZipCode: searchByCityOrZip
                ? {
                    id: cityZipCodeIdByCity ?? "",
                    zipCode:
                      data.cityAssociatedZipCodes.edges.find(
                        (c) => c.node.pk === cityZipCodeIdByCity,
                      )?.node.zip_code ?? defaultZip,
                  }
                : { id: cityZipCodeId ?? "", zipCode: params.zipCode },
            },
          }
        : null,
    );
    // }
    props.onNext();
  };
  useEffect(() => {
    if (defaultResidence) {
      setParams({
        cityId: defaultResidence.cityId,
        stateCode: defaultResidence.stateCode,
        zipCode: defaultResidence.cityZipCode.zipCode,
      });
      setZipCodeInput(defaultResidence.cityZipCode.zipCode);
    }
  }, []);
  useEffect(() => {
    if (cityZips.length === 1) setcityZipCodeId(cityZips[0].node.pk);
    else if (
      params.zipCode !== defaultResidence?.cityZipCode?.zipCode &&
      params.zipCode !== "00000"
    ) {
      setcityZipCodeId(undefined);
    }
    if (data.cityAssociatedZipCodes.edges.length === 1)
      setcityZipCodeIdByCity(data.cityAssociatedZipCodes.edges[0].node.pk);
    else if (
      (params.stateCode !== "ZZ" &&
        params.stateCode !== defaultResidence?.stateCode) ||
      (params.cityId !== NULL_ID && params.cityId !== defaultResidence?.cityId)
    ) {
      setcityZipCodeIdByCity(undefined);
    }
  }, [data, searchByCityOrZip]);
  const langStrings = useCurrentLangStrings();
  const cityZips = data.city_zip_code_connection.edges;
  if (!user || user.userType !== "worker") {
    return <>No Valid user found</>;
  }
  if (!context) throw new Error("No context found to be used");
  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={
        (!cityZipCodeId && !cityZipCodeIdByCity) ||
        (searchByCityOrZip && data.cityAssociatedZipCodes.edges.length === 0) ||
        (!searchByCityOrZip && data.city_zip_code_connection.edges.length === 0)
      }
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.cityOfResidence}
      >
        {searchByCityOrZip ? (
          <div className={`flex flex-col gap-1`}>
            <div className={`flex flex-col gap-0.5`}>
              <p className={`text-1.5`}>
                {langStrings.strings.whichStateDoYouLiveIn}
              </p>

              <Select
                virtual={false}
                size="large"
                value={
                  params.stateCode !== defaultState
                    ? params.stateCode
                    : undefined
                }
                placeholder={langStrings.strings.selectYourState}
                onChange={(e) => {
                  setcityZipCodeIdByCity(undefined);

                  setParams((prev) => ({
                    ...prev,
                    cityId: NULL_ID,
                    stateCode: e.toString(),
                  }));
                }}
              >
                {data.state_connection.edges.map((s) => (
                  <Select.Option
                    value={s.node.code}
                    key={s.node.code}
                    className="text-1"
                  >
                    {s.node.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            {(params.stateCode != defaultState || user?.residence) && (
              <>
                <div className={`flex flex-col gap-0.5`}>
                  <p className={`text text-1.5`}>
                    {langStrings.strings.whichCityDoYouLiveIn}
                  </p>
                  <Select
                    size="large"
                    virtual={false}
                    value={
                      params.cityId === NULL_ID ? undefined : params.cityId
                    }
                    placeholder={langStrings.strings.selectYourCity}
                    onChange={(e) => {
                      const val = e.valueOf();
                      setcityZipCodeIdByCity(undefined);
                      typeof val === "string" &&
                        setParams((prev) => ({ ...prev, cityId: val }));
                    }}
                  >
                    {data.city_connection.edges.map((c) => (
                      <Select.Option
                        value={c.node.pk}
                        key={c.node.pk}
                        className="text-1"
                      >
                        {c.node.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                {params.cityId !== NULL_ID && (
                  <div className={`flex flex-col gap-0.5`}>
                    <p className={`text-1.5`}>
                      {langStrings.strings.whatIsYourZipCode}
                    </p>
                    <Select
                      size="large"
                      virtual={false}
                      placeholder={langStrings.strings.selectYourZipCode}
                      value={cityZipCodeIdByCity}
                      onChange={(e) => setcityZipCodeIdByCity(e.valueOf())}
                    >
                      {data.cityAssociatedZipCodes.edges.map((c) => (
                        <Select.Option
                          value={c.node.pk}
                          key={c.node.pk}
                          className="text-1"
                        >
                          {c.node.zip_code}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                )}
              </>
            )}
            <div className={`self-end`}>
              <Button
                // type={`text`}
                onClick={() => setSearchByCityOrZip(false)}
              >
                {langStrings.strings.searchByZipCode}
              </Button>
            </div>
          </div>
        ) : (
          <div className={`flex flex-col gap-1`}>
            <div className={`flex flex-col gap-0.5`}>
              <p className={`text-1.5`}>
                {langStrings.strings.whatIsYourZipCode}
              </p>
              <Input
                // type="number"
                value={zipCodeInput}
                inputMode="numeric"
                // className="bg-white border-0.125 border-grey w-full m-0 text-1"
                pattern="[0-9]+"
                placeholder={langStrings.strings.enterYourZipCode}
                // title={langStrings.short.pleaseEnterA5DigitNumber}
                onChange={(e) => {
                  const input = e.currentTarget.value;
                  setZipCodeInput(input);
                  if (input.length === 5 && !isNaN(Number(input))) {
                    setParams((prev) => ({
                      ...prev,
                      zipCode: input,
                    }));
                  }
                }}
                enterKeyHint={"done"}
              />
            </div>
            {(params.zipCode !== defaultZip ||
              defaultResidence?.cityZipCode?.id) &&
              (cityZips.length > 0 ? (
                <div className={`flex flex-col gap-0.5`}>
                  <p className={`text-1.5`}>
                    {langStrings.strings.whichCityDoYouLiveIn}
                  </p>
                  <Select
                    size="large"
                    virtual={false}
                    value={cityZipCodeId}
                    placeholder={langStrings.strings.selectYourZipCode}
                    onChange={(e) => setcityZipCodeId(e.valueOf())}
                  >
                    {cityZips.map((c) => (
                      <Select.Option
                        value={c.node.pk}
                        key={c.node.pk}
                        className="text-1"
                      >
                        {c.node.city.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              ) : (
                <ErrorMessage
                  message={langStrings.strings.noCitiesFoundForThisZipCode}
                />
              ))}
            <Button
              className={`self-end`}
              // type={`text`}
              onClick={() => setSearchByCityOrZip(true)}
            >
              {langStrings.strings.searchByCity}
            </Button>
          </div>
        )}
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerStepsResidence;
