import { Button, Form, Select, message } from "antd";
import { memo, useRef, useState, useMemo } from "react";
import {
  GetCsrProjectWorkersQueryVariables,
  GetCsrProjectWorkersDocument,
  GetCsrProjectWorkersQuery,
  useCsrUpdateProjectWorkerByPkMutation,
  useGetCsrProjectSubsQuery,
  Order_By,
  GetCsrProjectDataQuery,
  CsrChangeWorkerSubMutationVariables,
  useCsrChangeWorkerSubMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import UserUpdateString from "../components/UserUpdateString";

import { useNavigate } from "react-router-dom";
import ProjectWorkersActionPopover from "../components/CsrProjectWorkersActionPopover";
import DataApolloScrollTable, {
  DataApolloScrollTableRef,
} from "src/common/components/tables/basic/DataApolloScrollTable";
import { IconUser, IconSettings } from "@tabler/icons";
import PerformWorkerFunctions from "src/common/components/modals/PerformWorkerFunctions";
import CombineWorkerModal from "src/common/components/modals/CombineWorkerModal";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import Icon from "src/common/components/general/Icon";
import useAuthUser from "src/common/hooks/useAuthUser";
type ColumnKeys =
  | "sub_name"
  | "name"
  | "email"
  | "profile_icon"
  | "phone_number"
  | "title"
  | "role"
  | "action";
type DataType = GetCsrProjectWorkersQuery["project_worker"][number];
const SelectForProjWorker = memo(
  ({
    projWorker,
    options,
    updateField,
  }: {
    updateField: "title_id" | "worker_role";
    options: Array<{ label: string; value: string }>;
    projWorker: {
      id: string;
      title_id?: string | null;
      worker_role: string;
      user?: { name: string } | null;
    };
  }) => {
    const authUser = useAuthUser();
    const [updateProjWorker, { loading }] =
      useCsrUpdateProjectWorkerByPkMutation();
    return (
      <div onClick={(e) => e.stopPropagation()} className="p-0.5">
        <Select
          className="w-16"
          value={projWorker[updateField]}
          options={options}
          onChange={(val) => {
            const _set = { [updateField]: val };
            updateProjWorker({
              variables: {
                _set,
                pk_columns: { id: projWorker.id },
                csrEdits: [
                  {
                    change_log: [{ _set, prevVal: projWorker[updateField] }],
                    csr_user_id: authUser.uid,
                    edit_text: `changed ${updateField} to ${val} for ${projWorker.user?.name}`,
                    entity_id: projWorker.id,
                    name: "project_worker",
                    operation_name: "update",
                  },
                ],
              },
              optimisticResponse: {
                update_project_worker_by_pk: {
                  ...projWorker,
                  [updateField]: val,
                },
              },
            });
          }}
          loading={loading}
        />
      </div>
    );
  },
);
const ChangeAndShowSub: React.FC<{
  currSubId: string;
  currSubName: string;
  isSubadmin?: boolean;
  changing: boolean;
  changeSub: (newSubId: string) => Promise<void>;
  refetch: () => void;
  subOptions: Array<{ label: string; value: string }>;
}> = ({
  currSubId,
  subOptions,
  currSubName,
  changeSub,
  changing,
  isSubadmin,
}) => {
  const [editing, setEditing] = useState(false);

  const [form] = Form.useForm<{ subId: string }>();
  const onEditCancel = () => {
    form.resetFields();
    setEditing(false);
  };
  return (
    <div className="p-1" onClick={(e) => e.stopPropagation()}>
      {editing && !isSubadmin ? (
        <Form form={form} initialValues={{ subId: currSubId }}>
          <Form.Item name="subId">
            <Select
              options={subOptions}
              loading={changing}
              disabled={changing}
            />
          </Form.Item>
          <div className="flex gap-1 mt-1">
            <BPopconfirm
              title="Please make sure you are changing to correct sub? and it is necessary to do so."
              onCancel={onEditCancel}
              onConfirm={async () => {
                const vals = await form.validateFields();
                console.log(vals);
                if (!vals) return;
                await changeSub(vals.subId);
                setEditing(false);
              }}
            >
              <Button size="small" type="primary" loading={changing}>
                Save
              </Button>
            </BPopconfirm>
            <Button size="small" loading={changing} onClick={onEditCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      ) : (
        <div className="min-w-12 flex gap-0.5">
          {currSubName}{" "}
          {!isSubadmin && (
            <Button
              loading={changing}
              size="small"
              onClick={() => {
                setEditing(true);
              }}
            >
              {"Edit"}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
const CsrProjectWorkers: React.FunctionComponent<{
  projectData: NonNullable<GetCsrProjectDataQuery["project_by_pk"]>;
  titleOptions: Array<{ label: string; value: string }>;
  roleOptions: Array<{ label: string; value: string }>;
  subTitles: Array<{ label: string; value: string }>;
}> = ({ projectData, titleOptions, roleOptions, subTitles }) => {
  const navigate = useNavigate();
  const ref = useRef<DataApolloScrollTableRef>(null);
  const [updatingWorkerSub, { loading }] = useCsrChangeWorkerSubMutation();
  const refetch = () => {
    if (ref.current) ref.current.refetch();
  };
  const projectId = projectData.id;
  const { data: projSubs } = useGetCsrProjectSubsQuery({
    variables: {
      limit: 10000,
      offset: 0,
      order_by: { subcontractor: { name: Order_By.Asc } },
      where: { project_id: { _eq: projectId } },
    },
  });
  const authUser = useAuthUser();
  const [showCombineModal, setShowCombineModal] = useState(false);

  const allProjectsToRegister = useMemo(() => {
    const list =
      projectData.orientation_project.linked_orientation_projects.map(
        (p) => p.id,
      );
    // if (list.includes(projectId)) {
    //   return list;
    // }
    // list.push(projectId);// BUT LIST WILL ALWAYS CONTAIN PROJECTID so it's not needed
    return list;
  }, [projectData]);
  return (
    <>
      <CombineWorkerModal
        refetch={refetch}
        type="noData"
        insertCsrEdit
        projWorkerWhere={{
          project_id: { _eq: projectId },
          is_last: { _eq: true },
        }}
        onClose={() => {
          setShowCombineModal(false);
          // onCancel();
        }}
        visible={showCombineModal}
      />
      <DataApolloScrollTable<
        GetCsrProjectWorkersQueryVariables,
        ColumnKeys,
        DataType,
        GetCsrProjectWorkersQuery
      >
        ref={ref}
        topBarButtons={[
          {
            label: "Combine Workers",
            icon: IconUser,
            secondaryIcon: IconUser,
            secondary: true,
            onClick: () => setShowCombineModal(true),
          },
        ]}
        title={"Workers"}
        newCustomTableLook
        queryDataIndex="project_worker"
        aggregateCountIndex="project_worker_aggregate"
        queryNode={GetCsrProjectWorkersDocument}
        onRowClick={(r) => {
          navigate(r.user!.id);
        }}
        where={{
          project_id: { _eq: projectId },
          is_last: { _eq: true },
        }}
        columns={[
          {
            title: "",
            key: "profile_icon",
            size: "icon",
            dataIndex: ["user", "name"],
            render: () => <Icon icon={IconUser} size="small" />,
          },
          {
            title: "Subcontactor Name",
            key: "sub_name",
            size: "xl",
            sortable: true,
            dataIndex: ["subcontractor", "name"],
            render: (v, r) => (
              <ChangeAndShowSub
                changing={loading}
                refetch={() => {
                  ref.current?.refetch();
                }}
                isSubadmin={!!r.user?.subcontractor_employee}
                currSubId={r.subcontractor.id}
                currSubName={r.subcontractor.name}
                subOptions={(projSubs?.project_subcontractor || []).map(
                  (p) => ({
                    label: p.subcontractor.name,
                    value: p.subcontractor.id,
                  }),
                )}
                changeSub={async (newSubId) => {
                  const user = r.user;
                  if (!user || !newSubId || r.subcontractor.id === newSubId)
                    return;

                  const variablesExceptCsrEdits: Omit<
                    CsrChangeWorkerSubMutationVariables,
                    "csrEdits"
                  > = {
                    projectIds: allProjectsToRegister,
                    newSubId,
                    workerId: user.id,
                    workerSet: { subcontractor_id: newSubId },
                    prjectWorkerSet: {
                      ...(r.hard_hat_number
                        ? { hard_hat_number: r.hard_hat_number }
                        : {}),
                      is_last: false,
                    },
                    newUpsertProjWorkerObjs: allProjectsToRegister.map(
                      (projectId) => ({
                        project_id: projectId,
                        worker_id: user.id,
                        subcontractor_id: newSubId,
                        title_id: r.title_id,
                        worker_role: r.worker_role,
                        can_insert_report: r.can_insert_report,
                        deleted_at: null,
                        is_last: true,
                        archived_at: null,
                        hard_hat_number: r.hard_hat_number || null,
                      }),
                    ),
                    ...(!user.subcontractor_employee
                      ? {
                          deleteWorkerPrevSubWhere: {
                            subcontractor_id: { _eq: newSubId },
                            worker_id: { _eq: user.id },
                          },
                          workerPrevSubObjs: [
                            {
                              worker_id: user.id,
                              subcontractor_id: r.subcontractor.id,
                            },
                          ],
                        }
                      : {
                          deleteWorkerPrevSubWhere: {
                            id: { _is_null: true },
                          },
                          workerPrevSubObjs: [],
                        }),
                  };
                  await updatingWorkerSub({
                    variables: {
                      ...variablesExceptCsrEdits,
                      csrEdits: {
                        change_log: [variablesExceptCsrEdits],
                        csr_user_id: authUser.uid,
                        edit_text: `changed subcontractor for ${r.user?.name}`,
                        entity_id: r.id,
                        name: "worker",
                        operation_name: "update",
                      },
                    },
                  });
                  ref.current?.refetch();
                }}
              />
            ),
          },
          {
            title: "Name",
            key: "name",
            sortable: true,
            size: "lg",
            searchDataIndex: ["user", "name"],
            defaultSortOrder: "asc",
            dataIndex: ["user", "name"],
            render: (_, r) => (
              <div>
                <UserUpdateString type="name" row={r.user!} />
                {r.user?.subcontractor_employee && <div>SubAdmin</div>}
              </div>
            ),
          },
          {
            title: "Email",
            key: "email",
            searchDataIndex: ["user", "email"],
            size: "lg",
            dataIndex: ["user", "email"],
            render: (_, r) => <UserUpdateString type="email" row={r.user!} />,
          },
          {
            title: "Phone",
            key: "phone_number",
            searchDataIndex: ["user", "phone_number"],
            dataIndex: ["user", "phone_number"],
            render: (_, r) => (
              <UserUpdateString type="phone_number" row={r.user!} />
            ),
          },
          {
            title: "Title",
            key: "title",
            sortable: true,
            size: "lg",
            dataIndex: ["title_id"],
            render: (_, r) => (
              <SelectForProjWorker
                options={titleOptions}
                projWorker={r}
                updateField="title_id"
              />
            ),
          },
          {
            title: "Role",
            key: "role",
            sortable: true,
            size: "lg",
            dataIndex: ["worker_role"],
            render: (_, r) => (
              <SelectForProjWorker
                options={roleOptions}
                projWorker={r}
                updateField="worker_role"
              />
            ),
          },
          {
            title: "Action",
            key: "action",
            dataIndex: ["user", "id"],
            render: (v, r) => (
              <ProjectWorkersActionPopover
                pw={r}
                projectId={projectId}
                refetch={refetch}
                subTitles={subTitles}
              />
            ),
          },
        ]}
      />
    </>
  );
};
export default CsrProjectWorkers;
