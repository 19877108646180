import * as Sentry from "@sentry/react";
import setHotjarUser from "./hotjar/setHotjarUser";

export type TrackingUserType = {
  id: string;
  role?: string;
  email?: string;
  fullName?: string;
  userName?: string;
};

const identifyUserForTracking = (userProperties: TrackingUserType) => {
  Sentry.setUser(userProperties);
  // hotjar doesn't accept email as a user property
  const { email, ...hotjarUserProperties } = userProperties;
  setHotjarUser(hotjarUserProperties);
};

export default identifyUserForTracking;
