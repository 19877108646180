import CrewsightProjectSubSetup from "./CrewSightProjectSubSetup";
import CrewsightProjectTradeSetup from "./CrewSightProjectTradeSetup";

const CrewsightProjectSettings: React.FC<{ projectId: string }> = ({
  projectId,
}) => {
  return (
    <>
      <CrewsightProjectTradeSetup projectId={projectId} />
      <CrewsightProjectSubSetup projectId={projectId} />
    </>
  );
};
export default CrewsightProjectSettings;
