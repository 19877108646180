import { Skeleton } from "antd";
import ScrollTable, { DEFAULT_TABLE_HEADER_HEIGHT } from "./ScrollTable";

const LargeTableSkeleton: React.FC = () => (
  <div
    className="w-full"
    style={{
      height: `${ScrollTable.DEFAULT_HEIGHT + DEFAULT_TABLE_HEADER_HEIGHT}px`,
    }}
  >
    <div style={{ height: 50 }} />
    <Skeleton active />
    <div style={{ height: 50 }} />
    <Skeleton active />
    <div style={{ height: 50 }} />
    <Skeleton active />
  </div>
);
export default LargeTableSkeleton;
