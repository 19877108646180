import { Button, Form, Modal, Select, Spin } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import GCAddNewSubModal, {
  GCAddNewSubModalRef,
} from "src/root/routes/views/general-contractor/projects/subcontractors/sub-info/GCAddNewSubModal";
import ConfirmAddSubcontractorModal from "./ConfirmAddSubcontractorModal";
import { GetGcSubsQuery } from "src/common/types/generated/apollo/graphQLTypes";

interface SelectSubcontractorModalValues {
  subcontractorIds: Array<string>;
}

interface Props {
  onSubcontractorInserted?: () => void;
  title: string;
  onSubmit: (values: SelectSubcontractorModalValues) => void;
  subsOnProject: Array<string>;
  subsUnderGc: GetGcSubsQuery["subcontractor"];
}

interface SubSelectFormItemProps {
  subsOnProject: Array<string>;
  subsUnderGc: GetGcSubsQuery["subcontractor"];
}

export type SelectSubcontractorModalRef = {
  open: () => void;
};

const SubSelectFormItem: React.FC<SubSelectFormItemProps> = ({
  subsOnProject,
  subsUnderGc,
}) => {
  return (
    <Form.Item
      name="subcontractorIds"
      label="Subcontractors"
      rules={[{ required: true, message: "Select subcontractors" }]}
    >
      <Select
        optionFilterProp="key"
        mode="multiple"
        style={{ width: "80%" }}
        showSearch={true}
      >
        {subsUnderGc.map((sub, i) =>
          subsOnProject.includes(sub.id) ? (
            <Select.Option
              key={sub.id}
              value={sub.id}
              style={{ pointerEvents: "none", backgroundColor: "#e6f7ff" }}
            >
              {sub.name}
              <CheckOutlined style={{ float: "right", color: "#2a99fc" }} />
            </Select.Option>
          ) : (
            <Select.Option key={sub.name} value={sub.id}>
              {sub.name}
            </Select.Option>
          ),
        )}
      </Select>
    </Form.Item>
  );
};

const SelectSubcontractorModal = forwardRef<SelectSubcontractorModalRef, Props>(
  (
    { onSubcontractorInserted, onSubmit, subsOnProject, subsUnderGc, title },
    ref,
  ) => {
    const [form] = Form.useForm();
    const [showSelectSubcontractorModal, setShowSelectSubcontractorModal] =
      useState(false);
    const addNewSub = useRef<GCAddNewSubModalRef>(null);
    const { projectId } = useParams();

    useImperativeHandle<
      SelectSubcontractorModalRef,
      SelectSubcontractorModalRef
    >(ref, () => ({
      open: () => setShowSelectSubcontractorModal(true),
    }));
    const [
      showConfirmAddSubcontractorModal,
      setShowConfirmAddSubcontractorModal,
    ] = useState(false);

    const confirmationModalOpen = () => {
      setShowConfirmAddSubcontractorModal(true);
    };

    const confirmationModalClose = () => {
      setShowConfirmAddSubcontractorModal(false);
    };

    return (
      <Modal
        open={showSelectSubcontractorModal}
        title={title}
        onCancel={() => {
          form.resetFields();
          setShowSelectSubcontractorModal(false);
        }}
        footer={
          <div className="flex" style={{ justifyContent: "space-between" }}>
            <Button
              style={{
                backgroundColor: "white",
                color: "#2494fc",
                borderColor: "#2494fc",
                borderRadius: "25px",
              }}
              onClick={() => {
                const isAnyFieldValueTruthy = Object.values(
                  form.getFieldsValue(),
                ).some((value) => value);

                if (isAnyFieldValueTruthy) {
                  confirmationModalOpen();
                } else {
                  addNewSub.current?.open();
                }
              }}
            >
              <strong>+ Create New</strong>
            </Button>
            <div className="flex">
              <Button
                style={{
                  backgroundColor: "#2494fc",
                  color: "white",
                  borderColor: "#2494fc",
                  borderRadius: "25px",
                }}
                onClick={() => {
                  form.resetFields();
                  setShowSelectSubcontractorModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "#2494fc",
                  color: "white",
                  borderColor: "#2494fc",
                  borderRadius: "25px",
                }}
                onClick={() => {
                  // TODO handle async onSubmit
                  form
                    .validateFields()
                    .then((values) => {
                      form.resetFields();
                      onSubmit(values as SelectSubcontractorModalValues);
                      setShowSelectSubcontractorModal(false);
                    })
                    .catch((info) => {
                      console.log("Validate Failed:", info);
                    });
                }}
              >
                Save
              </Button>
            </div>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: "public" }}
        >
          <SubSelectFormItem
            subsOnProject={subsOnProject}
            subsUnderGc={subsUnderGc}
          />
          <div className="flex" style={{ color: "red" }}>
            <div>
              If your Sub or Trade Partner is not available, select
              <strong> + Create New</strong>
            </div>
          </div>
        </Form>
        <ConfirmAddSubcontractorModal
          onSubcontractorInserted={onSubcontractorInserted}
          modalClose={confirmationModalClose}
          modalVisible={showConfirmAddSubcontractorModal}
          onSubmit={async () => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onSubmit(values as SelectSubcontractorModalValues);
                setShowSelectSubcontractorModal(false);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        ></ConfirmAddSubcontractorModal>
        <GCAddNewSubModal
          ref={addNewSub}
          projectId={projectId}
          onSubcontractorInserted={onSubcontractorInserted}
          onSubmit={async () => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onSubmit(values as SelectSubcontractorModalValues);
                setShowSelectSubcontractorModal(false);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        />
      </Modal>
    );
  },
);

export default SelectSubcontractorModal;
