import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import NavigationWrapper from "src/common/components/layouts/NavigationWrapper";
import { NavigationMenuProps } from "src/common/components/NavigationMenu";
import FloatingContactButton from "../../../../common/components/contact-button/FloatingContactButton";
import { useUserData } from "../../../../utility-features/authorization/UserDataProvider";

export interface GCNavigationProps {}

const GCNavigation: React.FunctionComponent<GCNavigationProps> = () => {
  const navigate = useNavigate();
  const { userData } = useUserData();
  const employee = userData.employee;

  if (!employee) throw new Error("Logged in user is not employee");
  const gc = employee.general_contractor;
  const menu: NavigationMenuProps = {
    navigationItems: [
      ...(employee.is_corporate_admin
        ? [
            {
              label: "Company",
              rootPath: "corporate",
              onClick: () => {
                navigate("corporate");
              },
            },
          ]
        : []),
      ...(gc.hierarchy_division_name &&
      (employee.is_corporate_admin ||
        employee.gc_divisions_aggregate.aggregate?.count)
        ? [
            {
              label: gc.hierarchy_division_name,
              rootPath: "divisions",
              onClick: () => {
                navigate("divisions");
              },
            },
          ]
        : []),
      ...(gc.hierarchy_business_unit_name &&
      (employee.is_corporate_admin ||
        employee.gc_divisions_aggregate.aggregate?.count ||
        employee.gc_business_units_aggregate.aggregate?.count)
        ? [
            {
              label: gc.hierarchy_business_unit_name,
              rootPath: "business-units",
              onClick: () => {
                navigate("business-units");
              },
            },
          ]
        : []),
      ...(gc.hierarchy_office_name &&
      (employee.is_corporate_admin ||
        employee.gc_business_units_aggregate.aggregate?.count ||
        employee.gc_divisions_aggregate.aggregate?.count ||
        employee.gc_offices_aggregate.aggregate?.count)
        ? [
            {
              label: gc.hierarchy_office_name,
              rootPath: "offices",
              onClick: () => {
                navigate("offices");
              },
            },
          ]
        : []),
      {
        label: "Projects",
        rootPath: "projects",
        onClick: () => {
          navigate("projects");
        },
      },
      ...(gc.employee_verification
        ? [
            {
              label: "Employee Verification",
              rootPath: "empver",
              onClick: () => {
                navigate("empver");
              },
            },
          ]
        : []),
      {
        label: "My Profile",
        rootPath: "settings",
        onClick: () => {
          navigate("settings");
        },
      },
    ],
  };

  return (
    <div className="w-full h-full py-0.5 pr-0.5 bg-suplementary-1">
      <NavigationWrapper menu={menu}>
        <Outlet />
      </NavigationWrapper>
      <FloatingContactButton />
    </div>
  );
};

export default GCNavigation;
