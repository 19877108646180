import { Project_Crew } from "src/common/types/generated/apollo/graphQLTypes";
import RecursivePartial from "src/common/types/manual/RecursivePartial";

const crewName = (crew?: RecursivePartial<Project_Crew> | null): string =>
  crew
    ? crew.name ??
      (crew.lead_foreman_project_worker
        ? `${crew.lead_foreman_project_worker.user?.name!}'s crew`
        : "")
    : "No Crew";

export default crewName;
