import { useParams } from "react-router-dom";
import ObservationInspectionTable from "../../components/ObservationInspectionTable";
import { useRef } from "react";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import useAuthUser from "src/common/hooks/useAuthUser";

const GCProjectAudits: React.FunctionComponent = () => {
  const projectId = useParams().projectId as string;
  const ref = useRef(null);
  const authUser = useAuthUser();
  return (
    <BasicWrapper>
      <ObservationInspectionTable
        ref={ref}
        where={{
          project_id: { _eq: projectId },
          _or: [
            { submitted_on: { _is_null: false } },
            {
              submitted_on: { _is_null: true },
              created_by_uid: { _eq: authUser.uid },
            },
          ],
        }}
        projectId={projectId}
        excludedKeys={["project_name"]}
      />
    </BasicWrapper>
  );
};
export default GCProjectAudits;
