import { Table } from "antd";
import { TableProps } from "antd/lib/table";
import { ColumnFilterItem } from "antd/lib/table/interface";
import React, { FC } from "react";
import getColumnSearchProps from "src/common/functions/getColumnSearchProps";
import TableFooterTotal from "../TableFooterTotal";

export type BaseTableProps<T> = TableProps<T> & {
  columns: NonNullable<TableProps<T>["columns"]>;
  skipKeys?: Array<string>;
  hideTotalRow?: boolean;
  filterColumns?: { [key: string]: Array<ColumnFilterItem> };
  searchColumns?: {
    [key: string]: (val: string | undefined) => void;
  };
  sortColumns?: { [key: string]: boolean };
  pointerOnRow?: boolean;
};

type BaseTable<RecordType extends object = any> = FC<
  BaseTableProps<RecordType>
>;

const BaseTable = <RecordType extends object = any>({
  searchColumns,
  filterColumns,
  sortColumns,
  skipKeys,
  columns,
  pointerOnRow = true,
  ...props
}: BaseTableProps<RecordType>) => {
  // Filter out skip columns
  let newColumns = columns?.filter(
    ({ key }) => !(skipKeys ?? []).includes(key as string),
  );
  // Add filter column props

  if (filterColumns) {
    for (let i = 0; i < newColumns.length; i++) {
      const col = newColumns[i];
      if (
        (typeof col.key === "string" || typeof col.key === "number") &&
        filterColumns[col.key]
      ) {
        newColumns[i] = { ...col, filters: filterColumns[col.key!] };
      }
    }
  }
  // Add search column props
  if (searchColumns) {
    for (let i = 0; i < newColumns.length; i++) {
      const col = newColumns[i];
      if (
        (typeof col.key === "string" || typeof col.key === "number") &&
        searchColumns[col.key]
      ) {
        newColumns[i] = {
          ...col,
          ...getColumnSearchProps(searchColumns[col.key!]),
        };
      }
    }
  }
  // Add sort column props
  if (sortColumns) {
    for (let i = 0; i < newColumns.length; i++) {
      const col = newColumns[i];
      if (
        (typeof col.key === "string" || typeof col.key === "number") &&
        sortColumns[col.key!]
      ) {
        // console.log(col);
        newColumns[i] = { sorter: true, ...col };
      }
    }
  }

  //console.log(columns);
  //console.log(props);
  return (
    <Table
      {...props}
      columns={newColumns}
      bordered
      rowKey={props.rowKey ?? "id"}
      size="middle"
      summary={() =>
        props.pagination &&
        props.hideTotalRow !== true && (
          <TableFooterTotal
            cols={columns.length}
            total={props.pagination.total}
          />
        )
      }
      rowClassName={pointerOnRow === true ? "clickable-row" : ""}
      // pagination={
      //   props.pagination !== false
      //     ? {
      //         simple: true,
      //         size: 'default',
      //         ...props.pagination,
      //       }
      //     : false
      // }
    />
  );
};

// MARK: props not passsed down to base table if use as styled, styled on same level
// const BaseTabled: BaseTable = styled(Table as BaseTable).attrs(
//    => {

//     return {
//       ...props,
//       bordered: true,
//       rowKey: ,
//       size: 'middle',
//       columns: [],
//       pagination:,
//     };
//   }
// )``;

export default BaseTable;
