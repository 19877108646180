import {
  User_Set_Input,
  useUpdateGeneralPersonMutation,
  General_Person_Set_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import useAuthUser from "src/common/hooks/useAuthUser";
import { Operation } from "rfc6902";

const useUpdateIncidentGeneralPerson = () => {
  const authUser = useAuthUser();
  const [updateGeneralPerson] = useUpdateGeneralPersonMutation();

  const updateUserInfo = async (
    userId: string,
    set: General_Person_Set_Input,
    comment: string,
    incidentId: string,
    patch: Operation[],
  ) => {
    await updateGeneralPerson({
      variables: {
        uid: userId,
        object: {
          patch: patch,
          comment: comment,
          edit_type: "common-user-update",
          incident_id: incidentId,
          edited_by_uid: authUser.uid,
        },
        _set: set,
      },
    });
  };
  return [updateUserInfo] as const;
};

export default useUpdateIncidentGeneralPerson;
