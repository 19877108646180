/**
 * @generated SignedSource<<52456ea7398722f0a5a63022892eb010>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SCSds_ProjectQuery$variables = {
  subcontractorId: string;
};
export type SCSds_ProjectQuery$data = {
  readonly project_subcontractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly project: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        };
      };
    }>;
  };
  readonly safety_data_sheet_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly company_name: string;
        readonly company_safety_data_sheet_id: string | null | undefined;
        readonly created_at: string;
        readonly created_by_user: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        };
        readonly file_type: string;
        readonly id: string;
        readonly issue_date: string | null | undefined;
        readonly parent_sds: {
          readonly project_id: string | null | undefined;
          readonly subcontractor_id: string | null | undefined;
        } | null | undefined;
        readonly pk: string;
        readonly product_name: string;
        readonly project: {
          readonly name: string;
        } | null | undefined;
        readonly project_id: string | null | undefined;
        readonly reference_number: string | null | undefined;
        readonly subcontractor: {
          readonly name: string;
        } | null | undefined;
        readonly subcontractor_id: string | null | undefined;
        readonly url: string;
      };
    }>;
  };
};
export type SCSds_ProjectQuery = {
  response: SCSds_ProjectQuery$data;
  variables: SCSds_ProjectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subcontractorId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "project": {
        "name": "asc"
      }
    }
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "subcontractorId"
          }
        ],
        "kind": "ObjectValue",
        "name": "subcontractor_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "product_name": "asc"
    }
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "deleted_at": {
        "_is_null": true
      },
      "project_id": {
        "_is_null": false
      }
    }
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "product_name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "company_name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v11 = [
  (v4/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reference_number",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issue_date",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "company_safety_data_sheet_id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "file_type",
  "storageKey": null
},
v18 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v19 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v20 = [
  (v2/*: any*/),
  (v18/*: any*/),
  (v19/*: any*/),
  (v4/*: any*/)
],
v21 = [
  (v4/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SCSds_ProjectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "safety_data_sheetConnection",
        "kind": "LinkedField",
        "name": "safety_data_sheet_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "safety_data_sheetEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "safety_data_sheet",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "created_by_user",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "safety_data_sheet",
                    "kind": "LinkedField",
                    "name": "parent_sds",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "safety_data_sheet_connection(order_by:{\"product_name\":\"asc\"},where:{\"deleted_at\":{\"_is_null\":true},\"project_id\":{\"_is_null\":false}})"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SCSds_ProjectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v20/*: any*/),
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "safety_data_sheetConnection",
        "kind": "LinkedField",
        "name": "safety_data_sheet_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "safety_data_sheetEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "safety_data_sheet",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v21/*: any*/),
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "created_by_user",
                    "plural": false,
                    "selections": (v20/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": (v21/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "safety_data_sheet",
                    "kind": "LinkedField",
                    "name": "parent_sds",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v12/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "safety_data_sheet_connection(order_by:{\"product_name\":\"asc\"},where:{\"deleted_at\":{\"_is_null\":true},\"project_id\":{\"_is_null\":false}})"
      }
    ]
  },
  "params": {
    "cacheID": "2b153c28e8f8c76f5eb97c830e708e8f",
    "id": null,
    "metadata": {},
    "name": "SCSds_ProjectQuery",
    "operationKind": "query",
    "text": "query SCSds_ProjectQuery(\n  $subcontractorId: uuid!\n) {\n  project_subcontractor_connection(where: {subcontractor_id: {_eq: $subcontractorId}}, order_by: {project: {name: asc}}) {\n    edges {\n      node {\n        project {\n          id\n          pk: id\n          name\n        }\n        id\n      }\n    }\n  }\n  safety_data_sheet_connection(where: {project_id: {_is_null: false}, deleted_at: {_is_null: true}}, order_by: {product_name: asc}) {\n    edges {\n      node {\n        id\n        pk: id\n        product_name\n        company_name\n        created_at\n        project_id\n        project {\n          name\n          id\n        }\n        subcontractor_id\n        reference_number\n        issue_date\n        url\n        company_safety_data_sheet_id\n        file_type\n        created_by_user {\n          id\n          pk: id\n          name\n        }\n        subcontractor {\n          name\n          id\n        }\n        parent_sds {\n          project_id\n          subcontractor_id\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "64fcee62eee9cabc8f3bd4d37b6b96ad";

export default node;
