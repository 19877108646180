import React from "react";
import CheckboxGroup from "../../../../common/components/general/CheckboxGroup";

export interface ConfirmedSubAdminsProps {
  subAdminsList: {id: string; name: string; email: string }[];
  checkedSubAdminIds: string[];
  onCheckedSubAdminIdsChange: (newCheckedSubAdminIds: string[]) => void;
}

const ConfirmedSubAdmins: React.FC<ConfirmedSubAdminsProps> = (props) => {
  const subAdminCheckboxItems = props.subAdminsList.map((subAdmin) => {
    return {
      value: subAdmin.id,

      text: (
        <span>
          <span className={`font-accent`}>{subAdmin.name}</span>{" "}
          <span className={`text-static-secondary`}>{`${subAdmin.email}`}</span>
        </span>
      ),
    };
  });

  return (
    <CheckboxGroup
      plainItems={subAdminCheckboxItems}
      preCheckedValues={props.checkedSubAdminIds}
      onCheckedValuesChange={props.onCheckedSubAdminIdsChange}
    />
  );
};

export default ConfirmedSubAdmins;