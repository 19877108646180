import { IconPhoto } from "@tabler/icons";
import { Image as ImageAntD, Space } from "antd";
import React, { useState, useEffect } from "react";
import Icon, { IconType } from "../Icon";
import clsx from "clsx";

export interface ImageProps {
  src?: string;
  onClick?: () => void;
  icon?: IconType;
  alt?: string;
  transparentBackground?: boolean;
  rounded?: boolean;
  height?: number;
  width?: number;
  preview?: boolean;
}

const Image: React.FunctionComponent<ImageProps> = ({
  src,
  rounded,
  onClick,
  icon = IconPhoto,
  transparentBackground,
  alt = "",
  height,
  width,
  preview,
}) => {
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  let className = "";
  if (onClick) {
    className += "cursor-pointer hover:border-interactive-primary";
  }

  return (
    <div
      {...{ onClick }}
      className={clsx(
        "border h-full w-full flex items-center justify-center transition-all overflow-hidden",
        rounded ? "rounded-1" : "",
        transparentBackground ? "bg-transparent" : "bg-suplementary-2",
        !!src && !transparentBackground ? "border-white" : "border-transparent",
        className,
      )}
    >
      {!!src ? (
        <>
          {preview ? (
            <ImageAntD
              width={height ?? `100%`}
              height={height ?? `100%`}
              style={{ objectFit: "cover" }}
              onClick={(e) => e && e.stopPropagation()}
              src={src}
              preview={{
                visible: previewVisible,
                onVisibleChange: (value) => {
                  setPreviewVisible(value);
                },
              }}
            />
          ) : (
            <img
              className={clsx(
                loading ? "opacity-0" : "opacity-100",
                `h-full w-full transition-opacity object-cover`,
              )}
              height={height}
              width={width}
              src={src}
              alt={alt}
              onLoad={() => {
                setLoading(false);
              }}
            />
          )}
        </>
      ) : (
        <div className="opacity-20">
          <Icon color="static" {...{ icon }} />
        </div>
      )}
    </div>
  );
};

export default Image;
