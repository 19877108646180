import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Checkbox, CheckboxProps, Select, SelectProps, Space } from "antd";
import FModal, { FModalRef } from "./FModal";

interface ModalValues {
  subs: string[];
}

interface DownloadJHAModalProps {
  onCreate: (values: string[], includeArchieved: boolean) => void;
  onCancel: () => void;
  subs: Array<{ id: string; name: string }>;
}

export type DownloadJHAModalRef = FModalRef<ModalValues> | null;
const DownloadJHAModal = forwardRef<DownloadJHAModalRef, DownloadJHAModalProps>(
  ({ onCreate, onCancel, subs }, ref) => {
    const modal = useRef<FModalRef<ModalValues>>(null);
    const options: SelectProps["options"] = [];
    options.push({
      label: "All",
      value: "0",
    });
    for (let i = 0; i < subs.length; i++) {
      options.push({
        label: subs[i].name,
        value: subs[i].id,
      });
    }

    const handleCancel = () => {
      onCancel();
    };
    const [values, setValues] = useState(["0"]);
    const [includeArchieved, setIncludeArchieved] = useState(false);
    const handleChange = (value: string[]) => {
      console.log(`selected ${value}`);
      if (value.length > 1 && value[0] === "0") {
        const [_, ...remainingValues] = value;
        setValues(remainingValues);
      } else if (value.length && value[value.length - 1] === "0") {
        setValues(["0"]);
      } else if (value.length === 0) {
        setValues(["0"]);
      } else setValues(value);
    };
    const onChange: CheckboxProps["onChange"] = (e) => {
      console.log(`checked = ${e.target.checked}`);
      setIncludeArchieved(e.target.checked);
    };
    useImperativeHandle<DownloadJHAModalRef, DownloadJHAModalRef>(
      ref,
      () => modal.current,
    );
    return (
      <FModal
        ref={modal}
        title="Download JHA Log"
        okText="Download"
        cancelText="Cancel"
        onCancel={handleCancel}
        onOk={() => {
          onCreate(
            values.length === 1 && values[0] === "0" ? [] : values,
            includeArchieved,
          );
        }}
      >
        <div>
          <div className="mb-0.5">Select Subcontractors</div>
          <Space style={{ width: "100%" }} direction="vertical">
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              value={values}
              onChange={handleChange}
              options={options}
            />
          </Space>
          <div className="mt-0.5">
            <Checkbox onChange={onChange}>Include Archived JHAs</Checkbox>
          </div>
        </div>
      </FModal>
    );
  },
);

export default DownloadJHAModal;
