import React, { useContext, useEffect, useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import dayjs from "dayjs";
import { message } from "antd";
import { auth } from "src/common/functions/firebase";
import { usePseudoWorkerSignInThroughSecurityQuestionsMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { roleVar } from "src/common/api/apollo/cache";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import BirthDatePicker from "../../../../../../../../../common/components/birth-date-picker/BirthDatePicker";
import sendHotjarEvent from "src/utility-features/event-tracking/hotjar/sendHotjarEvent";
import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import hasApolloErrorCode from "../../../../../../../../../utility-features/error-handling/hasApolloErrorCode";
import { signInWithCustomToken } from "firebase/auth";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsBirthDateProps
  extends SiteOrientationStepProps {
  onLoginSuccess?: (workerId: string) => Promise<void>;
}

const SiteOrientationInPersonWorkerStepsBirthDate: React.FC<
  SiteOrientationInPersonWorkerStepsBirthDateProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();

  const [birthDate, setBirthDate] = useState<dayjs.Dayjs | null>(
    workerContext.orientee?.birthDate?.new ||
      workerContext.orientee?.birthDate?.old ||
      null,
  );
  const [loading, setLoading] = useState(false);
  const [pseudoWorkerSignInThroughSecurityQuestions] =
    usePseudoWorkerSignInThroughSecurityQuestionsMutation();
  const langStrings = useCurrentLangStrings();
  useEffect(() => {
    sendHotjarEvent("orientation_step_birth_date");
  }, []);

  const goNext = () => {
    props.onNext();
    sendHotjarEvent("orientation_step_birth_date_entered");
  };

  const submit = async () => {
    const workerName =
      workerContext.orientee?.name?.new || workerContext.orientee?.name?.old;
    if (!birthDate) {
      message.error(langStrings.strings.pleaseEnterYourDateOfBirth);
      return;
    }
    if (!workerName) {
      message.error(langStrings.strings.pleaseGoBackAndEnterYourName);
      return;
    }
    if (!workerContext.orientee?.id && !workerContext.orientee?.email) {
      // we run these functions only if someone presses i'm not in list and then on email screen he presses i don't have email address
      setLoading(true);
      const loggedInUser = await auth.currentUser?.getIdTokenResult();
      await pseudoWorkerSignInThroughSecurityQuestions({
        variables: {
          input: {
            returnToken: loggedInUser?.claims.role !== "employee",
            workerDob: birthDate.format("YYYY-MM-DD"),
            projectId: workerContext.projectId,
            name: workerName,
          },
        },
      })
        .then(async ({ data }) => {
          if (data?.pseudoWorkerSignInThroughSecurityQuestions) {
            const { workerId, token, userRole } =
              data?.pseudoWorkerSignInThroughSecurityQuestions;
            if (token) {
              roleVar("none");
              await auth.signOut();
              await signInWithCustomToken(auth, token);
            }
            if (userRole !== "employee") {
              workerContext.updateSelectedUserId(workerId);
              props.onLoginSuccess && (await props.onLoginSuccess(workerId));
            }
            setLoading(false);
            goNext();
          }
        })
        .catch((err) => {
          // changed to depend on code (instead of message)
          setLoading(false);
          if (hasApolloErrorCode(err, "unique-user-not-found")) {
            //Nothing, we'll just set name and birthdate acc. in context and at phone number we'll enter data in db and register new worker
            workerContext.updateOrientee((u) =>
              u
                ? {
                    ...u,
                    birthDate: { old: u.birthDate?.old, new: birthDate },
                  }
                : { birthDate: { new: birthDate } },
            );
            goNext();
            // message.error(langStrings.short.sorryWecannotIdentifyYou);
          } else {
            message.error(
              err instanceof Error ? err.message : JSON.stringify(err),
            );
            console.error(err.message);
          }
        });
    } else {
      workerContext.updateOrientee((u) =>
        u
          ? {
              ...u,
              birthDate: { old: u.birthDate?.old, new: birthDate },
            }
          : { birthDate: { new: birthDate } },
      );
      goNext();
    }
  };
  return (
    <SiteFeatureStepLayout
      onNextButtonClick={submit}
      nextButtonDisabled={!birthDate}
      loading={loading}
      onBackButtonClick={props.onBack}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.enterYourDateOfBirth}
      >
        <BirthDatePicker
          value={birthDate}
          onValueChange={setBirthDate}
          onEnter={submit}
        />
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerStepsBirthDate;
