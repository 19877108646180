import { graphql } from "babel-plugin-relay/macro";
import React, { FC } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import NavigationWrapper, {
  NavigationMenuProps,
} from "src/common/components/layouts/NavigationWrapper";
import { SCProjectsNavigationQuery } from "src/common/types/generated/relay/SCProjectsNavigationQuery.graphql";
export const subProjectsQuery = graphql`
  query SCProjectsNavigationQuery($where: project_subcontractor_bool_exp!) {
    project_subcontractor_connection(where: $where) {
      edges {
        node {
          project_id
          project {
            name
            completed
            general_contractor {
              name
            }
          }
        }
      }
    }
  }
`;
const SCProjectsNavigation: FC = () => {
  const subId = useSubcontractorId();
  const navigate = useNavigate();
  const selectedProjectId = useParams().projectId;
  const data = useLazyLoadQuery<SCProjectsNavigationQuery>(subProjectsQuery, {
    where: subId
      ? { subcontractor_id: { _eq: subId } }
      : { id: { _is_null: true } },
  });
  const projects = data.project_subcontractor_connection.edges.map((p) => ({
    name: p.node.project.name,
    completed: p.node.project.completed,
    id: p.node.project_id,
    selected: p.node.project_id === selectedProjectId,
  }));
  const menu: NavigationMenuProps = {
    navigationItems: projects
      .sort(
        (
          { name: name1, completed: completed1 },
          { name: name2, completed: completed2 }
        ) => {
          const complrassion =
            completed1 !== completed2
              ? +completed1 * 2 - 1
              : name1 > name2
              ? 1
              : name2 > name1
              ? -1
              : 0;

          return complrassion;
        }
      )
      .map((p) => {
        return {
          label: (p.completed ? "✓ " : "") + p.name,
          rootPath: "/sub/projects/" + p.id,
          onClick: () => {
            navigate(`/sub/projects/${p.id}`);
          },
        };
      }),
    utilityItems: [
      {
        component: (
          <div className="font-accent text-1 text-center">Projects</div>
        ),
      },
    ],
  };
  return (
    <NavigationWrapper menu={menu}>
      <Outlet />
    </NavigationWrapper>
  );
};
export default SCProjectsNavigation;
