import { IconArrowLeft } from "@tabler/icons";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/common/components/general/Button";
import LoadingContent from "src/common/components/general/LoadingContent";
import StyledContent from "src/common/components/layouts/StyledContent";
import { useGetToolboxTalkByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";
import ToolboxTalkDetail from "./ToolboxTalkDetail";
interface SCToolboxTalkProps {
  toolboxTalkId: string;
  subcontractorId: string;
  projectId?: string;
}
const SCToolboxTalk: FC<SCToolboxTalkProps> = ({
  toolboxTalkId,
  subcontractorId,
  projectId,
}) => {
  const navigate = useNavigate();
  const { data } = useGetToolboxTalkByPkQuery({
    variables: { toolboxTalkId },
  });

  if (!data?.toolbox_talk_by_pk) {
    return <LoadingContent />;
  }

  return (
    <StyledContent>
      <div>
        <Button
          label="Back"
          icon={IconArrowLeft}
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>
      <ToolboxTalkDetail
        routeId={{ type: "sub", id: subcontractorId }}
        toolboxTalk={data.toolbox_talk_by_pk as any}
      />
    </StyledContent>
  );
};

export default SCToolboxTalk;
