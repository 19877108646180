import { InboxOutlined } from "@ant-design/icons";
import { Form, Upload, Button } from "antd";
import { useState, useEffect } from "react";
import normalizeUploadFile from "src/common/functions/normalizeUploadFile";
import { uploadFilesWithPath } from "src/common/functions/uploadFiles";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  Document_Use_Type,
  Document_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import handleRequestError from "src/utility-features/error-handling/handleRequestError";
import * as uuid from "uuid";
const IncidentDocUploader: React.FunctionComponent<{
  docType: "image" | "other";
  acceptedFiles: string;
  groupId: string;
  type: Document_Use_Type["value"];
  title?: string;
  insertDoc: (objects: Document_Insert_Input[]) => Promise<void>;
}> = ({ docType, acceptedFiles, groupId, type, title, insertDoc }) => {
  const authUser = useAuthUser();
  const [form] = Form.useForm();
  const [uploadingDoc, setUploadingDoc] = useState(false);
  const [currentFilesCount, setCurrentFilesCount] = useState(0);

  return (
    <Form
      className="mt-2"
      layout="vertical"
      form={form}
      onFinish={async (v) => {
        if (!v || !v[docType]) return;
        const files = v[docType].map(
          (file: { originFileObj: File; name: string; type: string }) => ({
            file: file.originFileObj,
            directoryName: "Incident Document",
          }),
        );
        let res: ({
          id: string;
          url: string;
          name?: string;
          type?: string;
        } | null)[] = [];
        try {
          setUploadingDoc(true);
          res = await uploadFilesWithPath(files);
          setUploadingDoc(false);
        } catch (err) {
          setUploadingDoc(false);
          handleRequestError(err, {
            errorTitle: "File Upload Failed for Images",
          });
          return;
        }
        const objectsToInsert: {
          id: string;
          url: string;
          document_type: string | undefined;
          type: string;
          image: { data: { id: string; url: string } } | null;
          group_id: string;
          author_id: string;
          name?: string;
        }[] = [];
        res.forEach(
          (doc) =>
            doc &&
            objectsToInsert.push({
              group_id: groupId,
              author_id: authUser.uid,
              document_type: doc.type,
              type: type,
              name: doc.name,
              id: docType === "image" ? uuid.v4() : doc.id,
              url: doc.url,
              image:
                docType === "image"
                  ? { data: { id: doc.id, url: doc.url } }
                  : null,
            }),
        );
        await insertDoc(objectsToInsert);
        form.resetFields();
        setCurrentFilesCount(0);
      }}
    >
      <Form.Item
        label={
          !!title
            ? title
            : docType === "image"
            ? "Upload images"
            : "Upload any related documents "
        }
        name={docType}
        getValueFromEvent={normalizeUploadFile}
        key={docType}
      >
        <Upload.Dragger
          accept={acceptedFiles}
          onChange={(t) => setCurrentFilesCount(t.fileList.length)}
          disabled={uploadingDoc}
          maxCount={4}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p>
            Click or drag any
            {docType === "image"
              ? " Image(s)"
              : " Document, Video, or Audio files"}{" "}
            here to upload
            {docType === "image" ? ` (.jpg, .jpeg, .png)` : ``}
          </p>
        </Upload.Dragger>
      </Form.Item>
      {currentFilesCount > 0 && (
        <Button type="primary" htmlType="submit" loading={uploadingDoc}>
          {docType === "image" ? "Save Images" : "Add Documents"}
        </Button>
      )}
    </Form>
  );
};
export default IncidentDocUploader;
