import dayjs from "dayjs";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProjectJHALibrary from "src/common/components/ProjectJHALibrary";
import CustomButton from "src/common/components/general/Button";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import StyledContent from "src/common/components/layouts/StyledContent";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import { GCSubcontractorQuery$data } from "src/common/types/generated/relay/GCSubcontractorQuery.graphql";
import TasksTable from "src/domain-features/sitesafety/job-hazard-analysis/components/tables/TasksTable";

const GCSubcontractorJHAs: FC<{
  data: GCSubcontractorQuery$data;
  subcontractorId: string;
  projectId: string;
}> = ({ data, subcontractorId, projectId }) => {
  const [order, setOrder] = useState(Order_By.Asc);
  const navigate = useNavigate();
  const [areCompanyTasksVisible, setAreCompanyTasksVisible] = useState(false);

  const projectOfSub =
    data.general_contractor_employee_connection.edges[0].node.employee_projects.map(
      (proj) => ({ id: proj.project.pk, name: proj.project.name }),
    );

  return (
    <StyledContent padding backgroundColor="white">
      {areCompanyTasksVisible ? (
        <TasksTable
          onRowItemClick={(row) => {
            navigate(
              `/gce/projects/${projectId}/subcontractors/${subcontractorId}/task/${row.pk}`,
            );
          }}
          actionsAllowed={[
            "acceptJHA",
            "addToProject",
            "excelDownload",
            "pdfDownload",
            "viewFile",
            "resendForAcceptance",
            "acceptJHA",
          ]}
          title="Company JHA Library"
          isNotTurnersProject={false}
          projectId={projectId}
          subcontractorId={subcontractorId}
          headerComponent={
            <CustomButton
              secondary
              label="Go to Project JHA Library"
              onClick={() => setAreCompanyTasksVisible(false)}
            />
          }
          where={{
            project_id: { _is_null: true },
            subcontractor_id: { _eq: subcontractorId },
            is_pending_acceptance: { _eq: false },
            report_id: { _is_null: true },
            deleted_at: { _is_null: true },
          }}
          projectOfSub={projectOfSub}
          extraColumns = {[
            {
              title: "Created",
              dataIndex: ["created_at"],
              key: "created",
              defaultSortOrder: "asc",
              size: "md",
              sortable: true,
              searchDataIndex: ["created_at"],
              align: "right",
              render: (d: any, row) =>
                row.created_at ? (
                  <div>
                    <span>{row.created_by_user?.name} </span> <br />
                    <span>{dayjs(row.created_at).format("MMM DD, YYYY")}</span>
                  </div>
                ) : (
                  ""
                ),
            },
          ]}
          excludedKeys={[
            "subcontractor",
            "internalReview",
            "subcontractorApproval",
            "lastUsed",
            "firstUsed",
            "workersSigned",
            "addToMulipleProjects",
            "delete",
          ]}
        />
      ) : (
        <ProjectJHALibrary
          onClick={() => setAreCompanyTasksVisible(true)}
          projectId={projectId}
          subcontractorId={subcontractorId}
          order={order}
          setOrder={setOrder}
        />
      )}
    </StyledContent>
  );
};

export default withCustomSuspense(GCSubcontractorJHAs);
