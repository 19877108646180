import { graphql } from "babel-plugin-relay/macro";

const insertUserNameEditHistoryMutation = graphql`
  mutation InsertUserNameEditHistoryMutation(
    $input: [user_edit_history_insert_input!]!
  ) {
    insert_user_edit_history(objects: $input) {
      affected_rows
    }
  }
`;

export default insertUserNameEditHistoryMutation;