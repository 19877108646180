import React, { useEffect, useRef, useState } from "react";
import SiteBoardBase from "../../../../components/SiteBoardBase";
import SiteBoardOnsiteColumnBody from "./SiteBoardOnsiteColumnBody";
import SiteBoardOnsiteReportColumn, {
  SiteBoardOnsiteReportColumnProps,
} from "./SiteBoardOnsiteReportColumn";
import SiteBoardOnsiteSub, {
  SiteBoardOnsiteSubProps,
} from "./SiteBoardOnsiteSub";
import SiteBoardOnsiteWorkersColumn, {
  SiteBoardOnsiteWorkersColumnProps,
} from "./SiteBoardOnsiteWorkersColumn";
import dayjs from "dayjs";

export interface SiteBoardOnsiteUIProps {
  subcontractors: SiteBoardOnsiteSubProps[];
  reports: SiteBoardOnsiteReportColumnProps[];
  totalReports: number;
  workersAmounts?: SiteBoardOnsiteWorkersColumnProps;
}

const DELAY_S = 10;
const SCROLL_SUBS_STEP = 5;

const SiteBoardOnsiteUI: React.FC<SiteBoardOnsiteUIProps> = (props) => {
  const scrollItemsRef = useRef<HTMLDivElement[]>([]);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  // you can access the elements with itemsRef.current[n]
  useEffect(() => {
    scrollItemsRef.current = scrollItemsRef.current.slice(
      0,
      props.subcontractors.length,
    );
  }, [props.subcontractors]);

  const keepScrolling = () => {
    if (scrollItemsRef.current === null || scrollItemsRef.current.length === 0)
      return;
    console.log("scroll");
    setScrollPosition((prevState) => {
      let newState = prevState;
      if (prevState + 1 === props.subcontractors.length) {
        newState = 0;
      } else if (prevState + 1 < props.subcontractors.length) {
        newState = prevState + SCROLL_SUBS_STEP;
        if (newState + 1 > props.subcontractors.length) {
          newState = props.subcontractors.length - 1;
        }
      }
      scrollItemsRef.current[newState].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      return newState;
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      keepScrolling();
    }, DELAY_S * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <SiteBoardBase defaultWidthPx={1280}>
      <div className="flex flex-row gap-0.5 w-full">
        <div className="flex flex-col items-start flex-1 w-0 gap-1">
          <p
            style={{ background: "black" }}
            className="sticky top-0 z-10 w-full h-5 text-white text-4"
          >
            OnSite <span className="text-1.5">{`${props.totalReports}`}</span>{" "}
          </p>
          <div className="flex flex-col w-full gap-1">
            <SiteBoardOnsiteColumnBody
              rows={props.subcontractors.map((subcontractorProps, i) => (
                <div className="relative h-full">
                  <div
                    className="absolute invisible -top-5"
                    ref={(el) => {
                      if (el) scrollItemsRef.current[i] = el;
                    }}
                  ></div>
                  <SiteBoardOnsiteSub {...subcontractorProps} />
                </div>
              ))}
            />
          </div>
        </div>
        <div className="flex flex-row gap-0.5">
          {!!props.workersAmounts && (
            <SiteBoardOnsiteWorkersColumn {...props.workersAmounts} />
          )}
          {props.reports.map((report) => (
            <SiteBoardOnsiteReportColumn key={report.longTitle} {...report} />
          ))}
        </div>
      </div>
    </SiteBoardBase>
  );
};

export default SiteBoardOnsiteUI;
