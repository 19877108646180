import React, { FC, useRef, useState } from "react";
import { GCSubcontractorQuery$data } from "src/common/types/generated/relay/GCSubcontractorQuery.graphql";
import StyledContent from "src/common/components/layouts/StyledContent";
import { message, Button } from "antd";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import PdfModal from "src/common/components/modals/PdfModal";
import {
  GCSubcontractorSSSP_InsertSsspMutation,
  safety_plan_insert_input,
} from "src/common/types/generated/relay/GCSubcontractorSSSP_InsertSsspMutation.graphql";
import { graphql } from "babel-plugin-relay/macro";
import * as uuid from "uuid";
import noPropagation from "src/common/functions/noPropagation";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { SafetyPlanTable_safetyPlan$data } from "src/common/types/generated/relay/SafetyPlanTable_safetyPlan.graphql";
import UploadSafetyPlanModal from "src/domain-features/sitesafety/siteSafetyPlan/components/UploadSafetyPlanModal";
import SafetyPlanTable, {
  safetyPlan,
} from "src/domain-features/sitesafety/siteSafetyPlan/components/SafetyPlanTable";
import AddSafetyPlanFromCompanyLibraryModal from "src/domain-features/sitesafety/siteSafetyPlan/components/AddSafetyPlanFromCompanyLibraryModal";
const insertSafetyPlanMutation = graphql`
  mutation GCSubcontractorSSSP_InsertSsspMutation(
    $object: safety_plan_insert_input!
  ) {
    insert_safety_plan_one(object: $object) {
      ...SafetyPlanFrag @relay(mask: false)
    }
  }
`;

const GCSubcontractorSSSP: FC<{
  data: GCSubcontractorQuery$data;
  subcontractorId: string;
  projectId: string;
}> = ({ data, subcontractorId, projectId }) => {
  let companySsspdata = [
    ...data.subcontractor_connection.edges[0].node.safety_plans,
  ];

  const companySsspTableRef = useRef<DataScrollTableRef>(null);
  const projectSsspTableRef = useRef<DataScrollTableRef>(null);
  const [scSsspPdfModal, setSCSsspPdfModal] = useState(false);
  const [selectedSafetyPlan, setSelectedSafetyPlan] = useState<
    safetyPlan | undefined
  >();
  const [pdfUrl, setPdfUrl] = useState("");
  const [
    addSafetyPlanFromCompanyLibraryModal,
    setAddSafetyPlanFromCompanyLibraryModal,
  ] = useState(false);
  const [openSiteFormUploadModal, setOpenSiteFormUploadModal] = useState(false);
  const [insertSafetyPlan, loading] =
    useAsyncMutation<GCSubcontractorSSSP_InsertSsspMutation>(
      insertSafetyPlanMutation,
    );

  const addToProject = async (
    safetyPlan: SafetyPlanTable_safetyPlan$data["safety_plan_connection"]["edges"][number]["node"],
  ) => {
    const safetyPlanInsertInput: safety_plan_insert_input = {
      created_by_uid: auth.currentUser?.uid,
      project_id: projectId,
      subcontractor_id: subcontractorId,
      title: safetyPlan.title,
      url: safetyPlan.url,
      id: uuid.v4(),
      revision_date: safetyPlan.revision_date,
      revision_number: safetyPlan.revision_number,
    };
    await insertSafetyPlan({
      variables: {
        object: { ...safetyPlanInsertInput },
      },
    })
      .then(async (d) => {
        message.success("Added to Project Library");
        projectSsspTableRef.current?.refetch();
      })
      .catch((e) => console.log(e));
  };

  return (
    <StyledContent padding backgroundColor="white">
      <UploadSafetyPlanModal
        visible={openSiteFormUploadModal}
        subcontractorId={subcontractorId}
        projectId={projectId}
        safetyPlan={selectedSafetyPlan}
        onClose={() => setOpenSiteFormUploadModal(false)}
        onSubmit={() => {
          setOpenSiteFormUploadModal(false);
          projectSsspTableRef.current?.refetch();
        }}
      />
      <PdfModal
        visible={scSsspPdfModal}
        onClose={() => {
          setSCSsspPdfModal(false);
        }}
        pdfUrl={pdfUrl}
      />
      <AddSafetyPlanFromCompanyLibraryModal
        {...{
          projectId: projectId,
          visible: addSafetyPlanFromCompanyLibraryModal,
          data: companySsspdata,
          closeModal: () => setAddSafetyPlanFromCompanyLibraryModal(false),
          subcontractorId,
          refresh: () => {
            projectSsspTableRef.current?.refetch();
          },
        }}
      />
      <StyledContent padding backgroundColor="white">
        <SafetyPlanTable
          title="Project Library"
          ref={projectSsspTableRef}
          where={{
            subcontractor_id: { _eq: subcontractorId },
            project_id: { _eq: projectId },
            deleted_at: { _is_null: true },
          }}
          topBarButtons={[
            {
              onClick: () => {
                setOpenSiteFormUploadModal(true);
              },
              label: "+ Add Safety Plan",
            },
            {
              onClick: () => {
                setAddSafetyPlanFromCompanyLibraryModal(true);
              },
              label: "+ Add Safety Plan from Company Library",
            },
          ]}
          onSafetyPlanRowClick={(sssp) => {
            setPdfUrl(sssp.url);
            setSCSsspPdfModal(true);
          }}
          loading={loading}
        />
      </StyledContent>
      <StyledContent padding backgroundColor="white">
        <SafetyPlanTable
          title="Company Library"
          headerComponent={<>{"Add safety manuals or sections to project"}</>}
          ref={companySsspTableRef}
          where={{
            subcontractor_id: { _eq: subcontractorId },
            project_id: { _is_null: true },
            deleted_at: { _is_null: true },
          }}
          onSafetyPlanRowClick={(sssp) => {
            setPdfUrl(sssp.url);
            setSCSsspPdfModal(true);
          }}
          loading={loading}
          extraColumns={[
            {
              title: "Action",
              dataIndex: [""],
              size: "ml",
              key: "action",
              render: (_, safetyPlan) => {
                return (
                  <>
                    <Button
                      type="link"
                      onClick={noPropagation(() => {
                        addToProject(safetyPlan);
                      })}
                    >
                      Add to Project
                    </Button>
                  </>
                );
              },
              onCellClick: (record: any, event: any) => {
                console.log(record);
              },
            },
          ]}
        />
      </StyledContent>
    </StyledContent>
  );
};

export default GCSubcontractorSSSP;
