import React from "react";
import { Button, Form, Input } from "antd";
import clsx from "clsx";

const EnterInputStringWithDoneAndCancel: React.FC<{
  onCancel: () => void;
  placeholder: string;
  savedVal?: string;
  openFailedInspect?: boolean;
  onDone: (val: string) => void;
}> = ({ onCancel, onDone, placeholder, savedVal, openFailedInspect }) => {
  const [form] = Form.useForm();
  return (
    <div className="flex flex-row gap-1 w-full">
      <Form
        form={form}
        className="flex flex-row justify-between"
        onFinish={(values) => {
          if (typeof values.model === "string" && values.model.trim()) {
            onDone(values.model.trim());
          }
        }}
      >
        {savedVal ? (
          <span
            className={clsx("text-1", openFailedInspect && "font-accent" )}
          >
            {savedVal}
          </span>
        ) : (
          <Form.Item name={"model"}>
            <Input placeholder={placeholder} className="" />
          </Form.Item>
        )}
        <div className="ml-0.75 flex gap-0.25">
          {!savedVal && (
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          )}
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default EnterInputStringWithDoneAndCancel;
