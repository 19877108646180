import { DataRecord, FieldValue } from "./dataScrollTableTypes";

function getFieldValue(
  record: DataRecord,
  dataIndex: ReadonlyArray<string>,
): DataRecord | FieldValue | undefined {
  let val: any = record;
  for (let name of dataIndex) {
    if (!val) return undefined;
    val = val[name];
  }
  return val;
}
export default getFieldValue;
