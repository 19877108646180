import React from "react";
import ObservationDetail from "../../components/ObservationDetail";
import { useParams } from "react-router-dom";
const GCObservation: React.FunctionComponent = () => {
  const { observationId, projectId } = useParams();

  if (!observationId) throw new Error("ObservationId not found");
  if (!projectId) throw new Error("ProjectId not found");
  return (
    <>
      <ObservationDetail observationId={observationId} projectId={projectId} />
    </>
  );
};
export default GCObservation;
