import React, { useMemo } from "react";
import {
  OptionResponseType,
  SiteInspectionStepProps,
} from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import {
  GetInspectionDataOutput,
  InspectionChecklistItem,
} from "src/common/types/generated/apollo/graphQLTypes";
import { generalChecklistType } from "src/domain-features/siteinspection/utils/generalChecklistType";
import SiteInspectionWorkerChecklistItemListUI from "./SiteInspectionWorkerStepChecklistitemListUI";
import getFullyCompletedChecklistitemAnswers from "src/domain-features/siteinspection/utils/getFullyCompletedChecklistitemAnswers";
import ButtonHuge from "src/common/components/general/ButtonHuge";
import useSiteInspectionUser from "src/domain-features/siteinspection/utils/useSiteInspectionUser";
import {
  useCurrentDBLangSelector,
  useCurrentLangStrings,
  useCurrentLanguage,
} from "../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteInspectionWorkerStepChecklistItemListProps
  extends SiteInspectionStepProps {
  deficientTypeOptions: GetInspectionDataOutput["deficientAnswerSelectTypes"];
}

const SiteInspectionWorkerStepChecklistItemList: React.FC<
  SiteInspectionWorkerStepChecklistItemListProps
> = ({ onNext, onBack, deficientTypeOptions }) => {
  const workerContext = useSiteInspectionUser();

  const gs = useCurrentDBLangSelector();
  const language = useCurrentLanguage();
  const langStrings = useCurrentLangStrings();
  const checklistItems = useMemo(
    () => workerContext.user?.inspection?.checklistItems || [],
    [workerContext.user?.inspection],
  );
  const checklistItemTypeMap = useMemo(() => {
    const itemMap: {
      [type: string]: InspectionChecklistItem[];
    } = {};
    (workerContext.user?.inspection?.checklistItems || []).forEach((item) => {
      const typeId = item.type?.name?.en ?? generalChecklistType;
      const list = [...(itemMap[typeId] ?? [])];
      list.push(item);
      itemMap[typeId] = list;
    });
    return itemMap;
  }, [workerContext.user?.inspection]);
  const onTextChange = (text: string, id: string) => {
    workerContext.updateUser((u) =>
      u?.checklistItemAnswers?.[id]
        ? {
            ...u,
            checklistItemAnswers: {
              ...u.checklistItemAnswers,
              [id]: { ...u.checklistItemAnswers[id], text_response: text },
            },
          }
        : u,
    );
  };
  const onDeficientTypeSelect = (
    deficientTypeId: string | undefined,
    immediateFix: boolean,
    id: string,
  ) => {
    workerContext.updateUser((u) =>
      u?.checklistItemAnswers?.[id]
        ? {
            ...u,
            checklistItemAnswers: {
              ...u.checklistItemAnswers,
              [id]: {
                ...u.checklistItemAnswers[id],
                immediate_fix: immediateFix,
                deficient_type_id: deficientTypeId,
              },
            },
          }
        : u,
    );
  };
  const onOptionResponse = (
    option_response: OptionResponseType,
    correct_answer: "yes" | "no",
    id: string,
  ) => {
    workerContext.updateUser((u) =>
      u
        ? {
            ...u,
            checklistItemAnswers: u.checklistItemAnswers
              ? {
                  ...u.checklistItemAnswers,
                  [id]: u.checklistItemAnswers[id]
                    ? {
                        ...u.checklistItemAnswers[id],
                        option_response,
                        correct_answer,
                      }
                    : {
                        id,
                        option_response,
                        correct_answer,
                      },
                }
              : {
                  [id]: {
                    id,
                    option_response,
                    correct_answer,
                  },
                },
          }
        : {},
    );
  };
  return (
    <SiteFeatureStepLayout onBackButtonClick={onBack}>
      {workerContext.user?.inspection?.is_multi_inspection ? (
        <div className="my-0.5 font-accent">
          {langStrings.strings.completeThisChecklistForSelecteditems}
        </div>
      ) : (
        ""
      )}
      <SiteInspectionWorkerChecklistItemListUI
        {...{
          checklistItemTypeMap,
          deficientTypeOptions,
          showOptions: true,
          hideIncorrect: !!workerContext.user?.inspection?.is_multi_inspection,
          checklistItemAnswers: workerContext.user?.checklistItemAnswers,
          onOptionResponse,
          onDeficientTypeSelect,
          onTextChange,
        }}
      />
      <ButtonHuge
        onClick={() => onNext()} // do not write as onClick={props.onNextButtonClick} as that provides optional argument eventHandler which sometimes we do not want so better to exclude
        disabled={
          getFullyCompletedChecklistitemAnswers(
            workerContext.user?.checklistItemAnswers ?? {},
          ).length !== checklistItems.length
        }
        type={"primary"}
      >
        {langStrings.strings.next}
      </ButtonHuge>
    </SiteFeatureStepLayout>
  );
};

export default SiteInspectionWorkerStepChecklistItemList;
