import { Form, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import FModal, { FModalRef } from "./FModal";

interface AddCovidTestedPositiveQuestionValues {
  question: string;
}

interface AddCovidTestedPositiveQuestionModalProps {
  onCreate: (values: AddCovidTestedPositiveQuestionValues) => void;
  onCancel: () => void;
}

const { Text } = Typography;
export type AddCovidTestedPositiveQuestionModalRef =
  FModalRef<AddCovidTestedPositiveQuestionValues> | null;
const AddCovidTestedPositiveQuestionModal = forwardRef<
  AddCovidTestedPositiveQuestionModalRef,
  AddCovidTestedPositiveQuestionModalProps
>(({ onCreate, onCancel }, ref) => {
  const [form] = Form.useForm();
  const modal = useRef<FModalRef<AddCovidTestedPositiveQuestionValues>>(null);
  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };
  useImperativeHandle<
    AddCovidTestedPositiveQuestionModalRef,
    AddCovidTestedPositiveQuestionModalRef
  >(ref, () => modal.current);

  return (
    <FModal
      ref={modal}
      title="Add a COVID-19 Tested Positive Question"
      okText="Add"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values as AddCovidTestedPositiveQuestionValues);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <p>Add a question to ask workers when they test positive for COVID-19.</p>
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="question"
          label="New question"
          rules={[{ required: true, message: "Enter your question" }]}
        >
          <TextArea style={{ minHeight: 200 }} />
        </Form.Item>
      </Form>
    </FModal>
  );
});

export default AddCovidTestedPositiveQuestionModal;
