import { graphql } from "babel-plugin-relay/macro";

export const insertImages = graphql`
  mutation InsertImagesMutation($objects: [image_insert_input!]!) {
    insert_image(objects: $objects) {
      affected_rows
    }
  }
`;
export default insertImages;
