import React, { useState } from "react";
import { auth } from "../../functions/firebase";
import FloatingButton from "./FloatingButton";
import SupportContactForm from "./SupportContactForm";

const FloatingContactButton: React.FC = () => {
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const userId = auth.currentUser?.uid;
  if (!userId) return null;
  return (
    <FloatingButton
      popoverVisible={popoverVisible}
      onPopoverVisibleChange={setPopoverVisible}
      popoverContent={
        <SupportContactForm
          onDone={() => {
            setPopoverVisible(false);
          }}
          userId={userId}
        />
      }
      popoverTitle={"Contact Us"}
    />
  );
};

export default FloatingContactButton;
