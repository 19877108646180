import { Button, Form, Input, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { NewSubForm_InsertSubMutation } from "src/common/types/generated/relay/NewSubForm_InsertSubMutation.graphql";
import { VerifySubcontractorQuery$data } from "src/common/types/generated/relay/VerifySubcontractorQuery.graphql";
import ConfirmNewSubcontractorModal from "src/common/components/dialogs/ConfirmNewSubcontractorModal";
import { getSameEmailUser } from "src/common/functions/sameEmailUsers";
import { useRelayEnvironment } from "react-relay/hooks";

interface NewSubFormProps {
  subRequestQueryResult: VerifySubcontractorQuery$data;
  onSuccess: (subId: string, sameEmailPresent: boolean) => Promise<void>;
}

const insertSubMutation = graphql`
  mutation NewSubForm_InsertSubMutation(
    $name: String
    $trade_id: uuid!
    $subcontractor_ein: String
  ) {
    insert_subcontractor_one(
      object: {
        name: $name
        trade_id: $trade_id
        subcontractor_ein: $subcontractor_ein
      }
    ) {
      id
      pk: id @__clientField(handle: "pk")
    }
  }
`;

const NewSubForm: FC<NewSubFormProps> = ({
  subRequestQueryResult,
  onSuccess,
}) => {
  const navigate = useNavigate();
  const environment = useRelayEnvironment();
  const [insertSub, isAddingSub] =
    useAsyncMutation<NewSubForm_InsertSubMutation>(insertSubMutation);
  const queryResult =
    subRequestQueryResult.add_subcontractor_request_connection.edges[0].node;
  const subcontractorName = queryResult.subcontractor_business_name;
  const subcontractorTrade = queryResult.subcontractor_trade.name;
  const subcontractorEIN = queryResult.subcontractor_ein;
  const subcontractorLocation =
    queryResult.project.address.city +
    ", " +
    queryResult.project.address.state.name;
  const subAdminPhoneNumber = queryResult.poc_phone_number;
  const subAdminDetails =
    "Name: " +
    queryResult.poc_name +
    "\nEmail: " +
    queryResult.poc_email_address +
    (subAdminPhoneNumber ? "\nPhone Number: " + subAdminPhoneNumber : "") +
    "\nTitle: " +
    queryResult.subcontractor_employee_title.name_text;

  const [
    showConfirmNewSubcontractorModal,
    setShowConfirmNewSubcontractorModal,
  ] = useState(false);

  const openConfirmationModal = () => {
    setShowConfirmNewSubcontractorModal(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmNewSubcontractorModal(false);
  };

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const onFinish = async (sameEmailPresent: boolean) => {
    setLoading(true);
    const subInserted = await insertSub({
      variables: {
        name: form.getFieldValue("subBusinessName"),
        trade_id: queryResult.requested_trade_id,
        subcontractor_ein: queryResult.subcontractor_ein,
      },
    }).catch((error) => {
      message.error(error?.message);
    });
    if (subInserted && subInserted.insert_subcontractor_one?.pk) {
      await onSuccess(
        subInserted.insert_subcontractor_one?.pk,
        sameEmailPresent
      )
        .then(() => {
          message.success(
            `New Sub successfully added to the SiteForm database and the project`
          );
          navigate(`/sfe/verify/subcontractors/`);
        })
        .catch((e) => {
          console.log(e);
          message.error(e?.message);
          navigate(`/sfe/verify/subcontractors/`);
        });
    }
    setLoading(false);
  };

  return (
    <Form
      initialValues={{
        subBusinessName: subcontractorName,
        subEin: subcontractorEIN,
        subTrade: subcontractorTrade,
        projectLocation: subcontractorLocation,
        subAdminDetails: subAdminDetails,
      }}
      name="NewSubForm"
      form={form}
      title="Add New Subcontractor to SiteForm"
    >
      <Form.Item
        name="subBusinessName"
        label="Subcontractor Name"
        rules={[
          {
            required: true,
            message: "Suncontractor Name required",
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>
      <Form.Item name="subEin" label="Subcontractor EIN">
        <Input readOnly disabled type="text" />
      </Form.Item>
      <Form.Item name="subTrade" label="Subcontractor Trade">
        <Input readOnly disabled type="text" />
      </Form.Item>
      <Form.Item name="projectLocation" label="Project location">
        <Input readOnly disabled type="text" />
      </Form.Item>
      <Form.Item name="subAdminDetails" label="SubAdmin Details">
        <TextArea readOnly disabled rows={4} />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={async () => {
            const email = queryResult.poc_email_address;
            setLoading(true);
            const emailUserData = await getSameEmailUser(email, environment);
            let isSameEmailUserPresent = false;
            if (emailUserData) {
              isSameEmailUserPresent = emailUserData.user_connection.edges.some(
                (v) => v.node.email === email
              );
            } else {
              throw new Error("data to compare email not found");
            }
            setLoading(false);
            if (isSameEmailUserPresent) {
              openConfirmationModal();
            } else {
              // onFinish(isSameEmailUserPresent);
              onFinish(false);
            }
          }}
        >
          Create New Subcontractor to SiteForm and the Project
        </Button>
        <ConfirmNewSubcontractorModal
          modalClose={closeConfirmationModal}
          modalVisible={showConfirmNewSubcontractorModal}
          form={form}
          subRequestQueryResult={subRequestQueryResult}
          onFinish={onFinish}
        ></ConfirmNewSubcontractorModal>
      </Form.Item>
    </Form>
  );
};

export default withCustomSuspense(NewSubForm);
