import React, { FC, useEffect, useState } from "react";
import SiteBoardDeliveries from "./deliveries/list/SiteBoardDeliveriesList";
import SiteBoardPermitsView from "./permits/SiteBoardPermitsView";

interface SiteBoardMainEntryViewProps {
  projectId: string;
}

const SiteBoardMainEntryView: FC<SiteBoardMainEntryViewProps> = (props) => {
  const projectId = props.projectId;
  const siteboardScreens = [
    {
      key: "permit",
      time: 20000, //in miliseconds
      screen: <SiteBoardPermitsView projectId={projectId} />,
    },
    {
      key: "delivery",
      time: 10000, //in miliseconds
      screen: <SiteBoardDeliveries />,
    },
  ];
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev: number) => {
        return (prev + 1) % siteboardScreens.length;
      });
    }, siteboardScreens[index].time);
    return () => clearInterval(interval);
  }, [index]);

  return siteboardScreens[index].screen;
  // return <>{props.children}</>;
};

export default SiteBoardMainEntryView;
