import { useSuspenseQuery } from "@apollo/client";
import {
  GetGcOfficesDocument,
  GetGcOfficesQuery,
  GetGcOfficesQueryVariables,
  useInsertGcOfficeMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import { useParams } from "react-router-dom";
import GCAddNewLevelButton from "../../component/GCAddNewLevelButton";
import useAuthUser from "src/common/hooks/useAuthUser";
import OfficeTable from "../../component/OfficeTable";
import withDivisionId, { DivisionProps } from "../../utils/withDivisionId";

const GCDivisionOffices: React.FC<DivisionProps> = ({ divisionId }) => {
  const { userData } = useUserData();
  const emp = userData.employee;
  if (!emp) throw new Error("Employee not found");
  const gc = emp.general_contractor;
  const { data, refetch } = useSuspenseQuery<
    GetGcOfficesQuery,
    GetGcOfficesQueryVariables
  >(GetGcOfficesDocument, {
    variables: {
      divisionWhere: { id: { _eq: divisionId } },
      buWhere: { gc_division_id: { _eq: divisionId } },
      officeWhere: { gc_division_id: { _eq: divisionId } },
      includeTableData: true,
    },
  });
  const division = data.gc_division[0];
  const authUser = useAuthUser();
  if (!division) throw new Error("No division found");
  const [insertOffice, { loading }] = useInsertGcOfficeMutation();

  return gc.hierarchy_office_name ? (
    <div className="bg-white h-full w-full p-1">
      <OfficeTable
        buTitle={gc.hierarchy_business_unit_name}
        officeTitle={gc.hierarchy_office_name}
        offices={data.gc_office}
        addButton={
          <GCAddNewLevelButton
            {...{
              levelName: gc.hierarchy_office_name,
              inserting: loading,
              divisionId,
              buSelectOptions: data.gc_business_unit,
              buLevelName: gc.hierarchy_business_unit_name,
              divisionLevelName: gc.hierarchy_division_name,
              divisionSelectOptions: data.gc_division,
              onInsert: async (vals) => {
                await insertOffice({
                  variables: {
                    object: {
                      name: vals.name,
                      ...(vals.businessUnitId
                        ? { gc_business_unit_id: vals.businessUnitId }
                        : {}),
                      gc_division_id: divisionId,
                      logo_url: vals.uploadedLogoUrl,
                      general_contractor_id: emp.general_contractor_id,
                      created_by_uid: authUser.uid,
                      address: {
                        data: {
                          line_1: vals.addressLine1,
                          zip_code: vals.zip,
                          city: vals.city,
                          state_code: vals.stateCode,
                        },
                      },
                    },
                  },
                });
                refetch();
              },
            }}
          />
        }
      />
    </div>
  ) : null;
};
export default withDivisionId(GCDivisionOffices);
