import React from "react";
import SiteOrientationStepsUICertifications from "../../../../../components/stepsUI/certifications/SiteOrientationStepsUICertifications";
import { SiteOrientationStepProps } from "../../../../../utils/siteOrientationTypes";
import useSiteOrientationUniversal from "../../../newDesign/utils/siteOrientationUniversalHook";

import { useCurrentLanguage } from "../../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteOrientationUniveralStepsCertificationsProps
  extends SiteOrientationStepProps {}

const SiteOrientationUniversalStepsCertifications: React.FC<
  SiteOrientationUniveralStepsCertificationsProps
> = (props) => {
  const context = useSiteOrientationUniversal();
  const lang = useCurrentLanguage();
  if (!!context && context.orientee?.userType === "worker") {
    return (
      <SiteOrientationStepsUICertifications
        user={context.orientee}
        onNext={props.onNext}
        onBack={props.onBack}
        onCertificationsUpload={(newCerts) => {
          context.updateOrientee((prevUserState) =>
            prevUserState?.userType === "worker"
              ? {
                  ...prevUserState,
                  certifications: {
                    ...prevUserState.certifications,
                    new: [
                      ...(prevUserState.certifications?.new || []),
                      ...newCerts,
                    ],
                  },
                }
              : prevUserState,
          );
        }}
        lang={lang}
      />
    );
  } else {
    return null;
  }
};

export default SiteOrientationUniversalStepsCertifications;
