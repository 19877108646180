import apolloClient from "src/common/api/apollo/apolloClient";
import {
  TranslateDocument,
  TranslateQuery,
  TranslateQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";

export const getAllTranslations = async (
  toTranslate: Array<string>,
  additionalLangs: boolean = false
) => {
  // TODO writequery locally when recieve text_translation object for future translation query?
  const res = await apolloClient.query<TranslateQuery, TranslateQueryVariables>(
    {
      fetchPolicy: "cache-first",
      query: TranslateDocument,
      variables: {
        input: {
          suggestedFrom: "en",
          toTranslate,
          additionalLangs,
        },
      },
    }
  );

  const translations = res.data?.translate?.translations.map(
    ({ __typename, ...t }) => t
  );

  if (!translations) {
    throw new Error(`Error getTranslations: Error getting translations`);
  }

  return translations;
};
const getTranslations = async (
  toTranslate: Array<string>,
  additionalLangs: boolean = false
) => {
  const translations = await getAllTranslations(toTranslate, additionalLangs);
  const dbTranslations = translations.map(({ it, fr, zh_hans, ...t }) => t);
  if (!dbTranslations) {
    throw new Error(`Error getTranslations: Error getting translations`);
  }

  return dbTranslations;
};
export default getTranslations;
