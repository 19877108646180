import React, { FC } from "react";
import { Descriptions, Typography } from "antd";
import Icon, { IconProps } from "../general/Icon";

export type DataDetailItem = {
  label?: string;
  value?: any | null;
  span?: number;
  iconProps?: IconProps;
};

export type DataDetailItems = Array<DataDetailItem>;

type Props = {
  items: DataDetailItems;
  title?: string;
  columns?: number;
  maxWidth?: number | string;
  className?: string;
};

const DataDetailDescriptions: FC<Props> = ({
  items,
  title,
  columns = 3,
  maxWidth = 600,
  className,
}) => {
  return (
    <Descriptions
      title={title}
      className={className}
      bordered
      layout="vertical"
      column={columns}
      size="middle"
      style={{ maxWidth: maxWidth }}
    >
      {items.map((item, index) => (
        <Descriptions.Item
          style={{ textAlign: "left" }}
          key={index}
          label={
            <Typography.Text strong>
              <div className="flex felx-row">
                {item.label} {item.iconProps && <Icon {...item.iconProps} />}
              </div>
            </Typography.Text>
          }
          span={item.span ?? 1}
        >
          {item.value ?? "n/a"}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};

export default DataDetailDescriptions;
