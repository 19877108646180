import { ConfigProviderProps } from "antd/es/config-provider";
import { notification } from "antd";

// antDesignConfig is to be used in ConfigProvider from "antd"
export const antDesignConfigProviderProps: ConfigProviderProps = {
  notification: {},
};

// antDesignStaticConfig is for static methods like notification.success()
export const antDesignStaticConfig = () => {
  notification.config({
    maxCount: 3,
  });
};
