import React from "react";
import { motion } from "framer-motion";
import clsx from "clsx"; // .entry-title{
import { CurrentLangStrings } from "../../../../../../utility-features/i18n/language-utils/i18nTypes";

export interface SiteInspectionWorkerViewStepsCompletedUIProps {
  onAnimationFinished?: () => void;
  langStrings: CurrentLangStrings;
  deficientItemFound: boolean;
}

type ContentVarsType = {
  header: string;
  title: React.ReactNode;
  description: React.ReactNode;
  semantic: "positive" | "negative";
};

const SiteInspectionWorkerViewStepsCompletedUI: React.FC<
  SiteInspectionWorkerViewStepsCompletedUIProps
> = (props) => {
  const langStrings = props.langStrings;

  const contentVars: ContentVarsType = props.deficientItemFound
    ? {
        header: langStrings.strings.inspectionCompleted,
        title: langStrings.strings.checklistItemDeficient,
        description: langStrings.strings.deficientItemExplainer,
        semantic: "negative",
      }
    : {
        header: langStrings.strings.thankYou,
        title: langStrings.strings.inspectionCompleted,
        description: "",
        semantic: "positive",
      };

  return (
    <div className={`min-h-full justify-center`}>
      <div className={`flex flex-col gap-0.5`}>
        <div className={`flex flex-col gap-0.5`}>
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              delay: 1.2,
              duration: 0.6,
            }}
            className={`flex flex-col gap-0.5`}
          >
            <div>
              <p className={`text-2`}>{contentVars.header}!</p>
              <motion.p
                className={clsx(
                  `text-2 bg-gradient-to-r from-transparent inline`,
                  contentVars.semantic === "positive"
                    ? "to-semantic-positive-light"
                    : "to-semantic-negative-light"
                )}
                style={{
                  backgroundSize: `200%`,
                  // @ts-ignore
                  "--tw-gradient-stops":
                    "var(--tw-gradient-from) 50%, currentcolor, var(--tw-gradient-to) 50%",
                }}
                initial={{
                  backgroundPosition: `0 0`,
                }}
                animate={{
                  backgroundPosition: `-100% 0`,
                }}
                transition={{ delay: 1.8, duration: 0.6 }}
                onAnimationComplete={props.onAnimationFinished}
              >
                {contentVars.title}
              </motion.p>
            </div>
            <p>{contentVars.description}</p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SiteInspectionWorkerViewStepsCompletedUI;
