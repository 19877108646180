import { Address } from "src/common/types/generated/apollo/graphQLTypes";

type GetAddressText = (
  address: Partial<Address> | undefined | null
) => string | undefined;

const getAddressText: GetAddressText = (address) => {
  if (address === undefined || address === null) {
    return undefined;
  }
  let ret = "";
  ret += address.line_1;
  if (address.line_2) {
    ret += `\n${address.line_2}`;
  }
  ret += `\n${address.city}, ${address.state?.code} ${address.zip_code}`;
  return ret;
};

export default getAddressText;
