import dayjs from "dayjs";

const getUniqueCertsList = (
  certifications: Array<{
    readonly certification_id: string;
    readonly expires_on: string | null | undefined;
    readonly issued_on: string | null | undefined;
    readonly created_at: string;
    readonly images: readonly { readonly id: string }[];
    readonly certification: { readonly name: string };
  }>,
) => {
  const certificationMap: {
    [certId: string]: {
      name: string;
      id: string;
      expires_on: string | null | undefined;
      issued_on: string | null | undefined;
      created_at: string;
      images: number;
    };
  } = {};
  certifications.forEach((cert) => {
    const cur = certificationMap[cert.certification_id];
    if (cur) {
      if (cert.expires_on && cur.expires_on) {
        if (dayjs(cur.expires_on).isBefore(dayjs(cert.expires_on)))
          certificationMap[cert.certification_id] = {
            ...cert,
            id: cert.certification_id,
            name: cert.certification.name,
            images: cert.images.length,
          };
        return;
      }
      if (cur.expires_on) {
        certificationMap[cert.certification_id] = {
          ...cert,
          id: cert.certification_id,
          name: cert.certification.name,
          images: cert.images.length,
        };
        return;
      }
      if (cert.expires_on) {
        return;
      }
      if (cert.issued_on && cur.issued_on) {
        if (dayjs(cur.issued_on).isBefore(dayjs(cert.issued_on)))
          certificationMap[cert.certification_id] = {
            ...cert,
            id: cert.certification_id,
            name: cert.certification.name,
            images: cert.images.length,
          };
        return;
      }
      if (cur.issued_on) {
        certificationMap[cert.certification_id] = {
          ...cert,
          id: cert.certification_id,
          name: cert.certification.name,
          images: cert.images.length,
        };
        return;
      }
      if (cert.issued_on) {
        return;
      }

      if (dayjs(cur.created_at).isBefore(dayjs(cert.created_at)))
        certificationMap[cert.certification_id] = {
          ...cert,
          id: cert.certification_id,
          images: cert.images.length,
          name: cert.certification.name,
        };
    } else {
      certificationMap[cert.certification_id] = {
        name: cert.certification.name,
        id: cert.certification_id,
        created_at: cert.created_at,
        images: cert.images.length,
        expires_on: cert.expires_on,
        issued_on: cert.issued_on,
      };
    }
  });
  return Object.values(certificationMap);
};
export default getUniqueCertsList;
