import * as React from "react";
import { Menu, Popconfirm } from "antd";
import { getJHAData } from "src/common/api/relay/queries/AddJHAToProjectOrCompany";
import { useRelayEnvironment } from "react-relay/hooks";
import useAddTaskToProjectOrCompany from "../../utils/useAddTaskToProjectOrCompany";

interface AddTaskToProjectOrCompanyProps {
  taskId: string;
  projectId: string | null;
  subcontractorId: string;
  type: "addToProject" | "addToCompany";
}

const AddTaskToProjectOrCompany = (props: AddTaskToProjectOrCompanyProps) => {
  const environment = useRelayEnvironment();
  const [addTaskToProjectOrCompany] = useAddTaskToProjectOrCompany();
  return (
    <div
      className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Popconfirm
        title="Are you sure?"
        onConfirm={async () => {
          const jhaData = await getJHAData(environment, [props.taskId]);

          if (jhaData) {
            addTaskToProjectOrCompany(
              jhaData.map((jha) => jha.node),
              props.subcontractorId,
              props.projectId,
              props.type,
            );
          }
        }}
        okText="Yes"
        cancelText="No"
      >
        {props.type === "addToProject"
          ? "Add To Project"
          : "Add To Company Library"}
      </Popconfirm>
    </div>
  );
};

export default AddTaskToProjectOrCompany;
