import { IconArrowDown, IconCirclePlus, IconX } from "@tabler/icons";
import { Tooltip } from "antd";
import React from "react";
import Icon from "src/common/components/general/Icon";
import ProgressBar, {
  ProgressBarProps,
} from "src/common/components/general/ProgressBar";
import getDateStr from "src/common/functions/getDateStr";

export type ReportType = {
  submitted: number;
  total: number;
  missingReportsDates: Date[];
  hideRow?: boolean;
  parentId?: string;
};

export type ColumnType = {
  rows: ReportType[];

  onSortingClick?: () => void;

  title: string;
  additionalInfo?: string;
};

interface WrappedProgressBarProps extends ProgressBarProps {
  missingReportsDates: Date[];
}

const WrappedProgressBar: React.FunctionComponent<WrappedProgressBarProps> = (
  props
) => {
  return (
    <div className="relative">
      <Tooltip
        placement="left"
        trigger={props.value ? ["hover"] : []}
        overlay={
          <p className="overflow-auto max-h-24">
            {props.missingReportsDates.map((date) => (
              <span key={date.toString()} className={`block pr-0.5`}>
                {getDateStr(date)}
              </span>
            ))}
          </p>
        }
      >
        <div>
          <ProgressBar total={props.total} value={props.value} />
        </div>
      </Tooltip>
    </div>
  );
};

interface ShowToggleTableColumnProps extends ColumnType {
  onCloseClick?: () => void;
  onOpenClick: () => void;
  visible: boolean;
}

const ShowToggleTableColumn: React.FunctionComponent<
  ShowToggleTableColumnProps
> = (props) => {
  let columnTotal = 0;
  let columnSubmitted = 0;
  props.rows.forEach(({ submitted, total }) => {
    columnTotal += total;
    columnSubmitted += submitted;
  });

  const totalColumnPercentage: number = Math.round(
    (100 / columnTotal) * columnSubmitted
  );

  const additionalTitleInfo = `${columnSubmitted} of ${columnTotal}`;

  return (
    <div className="relative flex flex-col gap-0.5 h-full w-full">
      <div
        className={`realtive sticky bg-white top-0 z-10 flex flex-row items-center overflow-hidden`}
      >
        <div
          className={`w-full relative justify-center flex flex-row items-center transition-opacity gap-0.5  ${
            props.visible
              ? "opacity-100 z-10 "
              : "opacity-0 z-0 pointer-events-none"
          }`}
        >
          {props.onSortingClick && (
            <div onClick={props.onSortingClick} className={`cursor-pointer`}>
              <Icon color="interactive" size="small" icon={IconArrowDown} />
            </div>
          )}
          <p className="truncate whitespace-nowrap">
            <span className="font-accent">
              {`${props.title}, ${
                !isNaN(totalColumnPercentage) ? totalColumnPercentage + "%" : ""
              } `}
            </span>
            {additionalTitleInfo && (
              <span className="font-normal text-0.75">
                {additionalTitleInfo}
              </span>
            )}
          </p>
          {props.onCloseClick && (
            <div onClick={props.onCloseClick} className={`cursor-pointer`}>
              <Icon size={`small`} color="interactive" icon={IconX} />
            </div>
          )}
        </div>
        <div
          onClick={props.onOpenClick}
          className={`cursor-pointer absolute inset-0 flex items-center justify-center transition-opacity ${
            props.visible ? "opacity-0 pointer-events-none" : "opacity-100 z-10"
          }`}
        >
          <Icon size={`small`} icon={IconCirclePlus} color="interactive" />
        </div>
      </div>
      <div className="relative w-full">
        <div
          className={`transition-opacity flex flex-col justify-center w-full gap-px overflow-hidden ${
            props.visible ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          {props.rows
            .filter((c) => !c.hideRow)
            .map(({ submitted, total, missingReportsDates }, i) => (
              <WrappedProgressBar
                key={i}
                {...{ total, missingReportsDates, value: submitted }}
              />
            ))}
        </div>
        <div
          className={`pointer-events-none absolute inset-0 flex flex-row justify-center transition-opacity ${
            props.visible ? "opacity-0" : "opacity-100"
          }`}
        >
          <div className="w-px h-full bg-suplementary-3"></div>
        </div>
      </div>
    </div>
  );
};

export default ShowToggleTableColumn;
