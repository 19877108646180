import { Popconfirm, Typography } from "antd";
import { ColumnType } from "antd/lib/table";
import React from "react";
import BaseTable, {
  BaseTableProps,
} from "src/common/components/tables/basic/BaseTable";
import noPropagation from "src/common/functions/noPropagation";
import { GetProjectCovidConfigByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";
import styled from "styled-components";
import TableFooterTotal from "./TableFooterTotal";

// TODO add this to tables
type DataProp = GetProjectCovidConfigByPkQuery["project_covid_yes_no_question"][number];

type Props = Omit<BaseTableProps<Partial<DataProp>>, "columns"> & {
  onDelete?: (r: DataProp) => any;
};
type CovidQuestionsTable = (p: Props) => JSX.Element;

const CovidQuestionsTable = styled(BaseTable).attrs(
  ({ pagination, onDelete, ...props }: Props) => {
    // TODO: find out if size is really supported??
    const columns: Array<ColumnType<DataProp> & { size?: string }> = [
      {
        title: "Question",
        key: "question",
        size: "md",
        dataIndex: ["question", "original"],
      },
      {
        title: "Correct Answer",
        key: "answer_is_yes",
        align: "center",
        render: (value: DataProp) => (value.answer_is_yes ? "Yes" : "No"),
      },
      {
        title: "",
        size: "sd",
        key: "remove",
        render: (_: any, permit: DataProp) => (
          <Popconfirm
            title="Are you sure?"
            onConfirm={noPropagation(() => {
              if (onDelete) onDelete(permit);
            })}
            onCancel={noPropagation()}
            okText="Yes"
            cancelText="Cancel"
          >
            <a onClick={noPropagation()}>
              <Typography.Text type="danger">Remove</Typography.Text>
            </a>
          </Popconfirm>
        ),
      },
    ];

    return {
      ...props,
      columns,
      rowKey: (w: any) => w.id,
      summary: () =>
        pagination && (
          <TableFooterTotal cols={columns.length} total={pagination.total} />
        ),
    };
  }
)``;

export default CovidQuestionsTable as CovidQuestionsTable;
