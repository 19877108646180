import { PlusOutlined } from "@ant-design/icons";
import { IconPlus } from "@tabler/icons";
import { Button } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";
import CreateNewOrientationModal from "src/common/components/dialogs/CreateNewOrientationModal";
import { GetCsrProjectDataQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { CsrProjectOrientationsQuery } from "src/common/types/generated/relay/CsrProjectOrientationsQuery.graphql";
import OrientationTable from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/settings/components/OrientationTable";
const CsrProjectOrientations: React.FC<{
  projectId: string;
  projectData: NonNullable<GetCsrProjectDataQuery["project_by_pk"]>;
}> = ({ projectId, projectData }) => {
  const data = useLazyLoadQuery<CsrProjectOrientationsQuery>(
    graphql`
      query CsrProjectOrientationsQuery($projectId: uuid!) {
        orientation_connection(
          where: {
            deleted_at: { _is_null: true }
            _and: [
              {
                _or: [
                  {
                    general_contractor_id: { _is_null: true }
                    project_orientations: {
                      project_id: { _is_null: true }
                      required_by_all_workers: { _eq: true }
                    }
                  }
                  {
                    general_contractor: {
                      projects: { id: { _eq: $projectId } }
                    }
                  }
                ]
              }
              {
                _or: [
                  { project_id: { _is_null: true } }
                  {
                    project: {
                      linked_orientation_projects: { id: { _eq: $projectId } }
                    }
                  }
                ]
              }
            ]
          }
          order_by: [{ order: asc }, { name: asc }]
          first: 10000
        )
          @connection(
            key: "CsrProjectOrientationsQuery_orientation_connection"
            filters: []
          ) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              name
              type
              created_at
              project_id
              user {
                name
                employee {
                  general_contractor {
                    name
                  }
                }
              }
              project_orientations(
                where: {
                  project: {
                    linked_orientation_projects: { id: { _eq: $projectId } }
                  }
                }
                order_by: { created_at: asc }
              ) {
                id
                pk: id @__clientField(handle: "pk")
                orientation_id
                project_id
                hide_but_give_credit
                play_during_in_person
                play_during_registration
                required_by_all_workers
              }
            }
          }
        }
      }
    `,
    { projectId },
  );
  const navigate = useNavigate();
  const orientations = (data.orientation_connection.edges || []).map((v) => ({
    ...v.node,
    key: v.node.pk,
  }));
  const [showCreateModal, setShowCreateModal] = useState(false);
  return (
    <>
      <CreateNewOrientationModal
        visible={showCreateModal}
        projectId={projectId}
        gcProjectIds={projectData.general_contractor.gc_projects.map(
          (p) => p.project_id,
        )}
        gcId={projectData.general_contractor.id}
        insertCsrHistory
        onSuccess={(orientationId) => {
          setShowCreateModal(false);
          navigate(
            `/csr/tasks/projects/${projectId}/orientations/${orientationId}`,
          );
        }}
        onCancel={() => setShowCreateModal(false)}
      />

      <OrientationTable
        dataSource={orientations}
        projectId={projectId}
        makeAllSettingDisabled
        isHigherLevelLogin={false}
        hasInPerson={projectData.in_person_orientation}
        hasUniversal={projectData.agc_universal_orientation}
        onRow={({ pk }) => {
          navigate(`${pk}?csr=true`);
        }}
        title={() => (
          <div className="flex gap-1">
            <div>
              Orientations (<span>{orientations.length}</span>)
            </div>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setShowCreateModal(true)}
            >
              Add New Orientation
            </Button>
          </div>
        )}
      />
    </>
  );
};
export default CsrProjectOrientations;
