import { Radio } from "antd";
import React, { FC } from "react";
import FilterControllerInput from "../FilterControllerInput";
import { StateFunctions } from "./CustomFilterController";

export type CustomRadioFilterProps = {
  title: string;
  removeFilter: () => void;
  options: Array<{
    option: string;
    onClick: () => void;
  }>;
} & StateFunctions;
const CustomRadioFilter: FC<CustomRadioFilterProps> = ({
  options,
  removeFilter,
  appliedFiltersfilterMap,
  setRemovalFilters,
  setAppliedFilter,
  title,
}) => {
  return (
    <>
      <FilterControllerInput
        title={title}
        removeFilter={() => {
          setAppliedFilter((prev) => {
            return {
              ...prev,
              [title]: false,
            };
          });

          removeFilter?.();
        }}
        component={
          <>
            {options.map(({ option, onClick }, id) => {
              return (
                <div className="m-0.25 w-full flex" key={id}>
                  <Radio
                    onClick={(val) => {
                      setAppliedFilter((prev) => ({
                        ...prev,
                        [title]: prev[title] === option ? false : option,
                      }));
                      onClick();
                      setRemovalFilters((prev) => ({
                        ...prev,
                        [title]: () => {
                          setAppliedFilter((prev) => {
                            return {
                              ...prev,
                              [title]: false,
                            };
                          });

                          removeFilter?.();
                        },
                      }));
                    }}
                    type="primary"
                    className={`text-1 ${id === 0 ? "" : "mt-0.5"}`}
                    checked={appliedFiltersfilterMap[title] === option}
                  >
                    {option}
                  </Radio>
                </div>
              );
            })}
          </>
        }
      />
    </>
  );
};
export default CustomRadioFilter;
