import { Button, Space } from "antd";
import dayjs from "dayjs";
import React, { FC, useRef, useState } from "react";
import ProjectCovidDescriptions from "src/common/components/ProjectCovidDescriptions";
import AddCovidQuestionModal from "src/common/components/dialogs/AddCovidQuestionModal";
import AddCovidTestedPositiveQuestionModal, {
  AddCovidTestedPositiveQuestionModalRef,
} from "src/common/components/dialogs/AddCovidTestedPositiveQuestionModal";
import AddProjectCovidNotifyEmployeeValuesModal from "src/common/components/dialogs/AddProjectCovidNotifyEmployeeModal";
import EditCovidRequirementsModal from "src/common/components/dialogs/EditCovidRequirementsModal";
import SetCovidContactEmployeeModal, {
  SetCovidContactEmployeeModalRef,
} from "src/common/components/dialogs/SetCovidContactEmployeeModal";
import SetupCovidModal from "src/common/components/dialogs/SetupCovidModal";
import LoadingContent from "src/common/components/general/LoadingContent";
import StyledContent from "src/common/components/layouts/StyledContent";
import CovidQuestionsTable from "src/common/components/tables/CovidQuestionsTable";
import CovidTestedPositiveQuestionsTable from "src/common/components/tables/CovidTestedPositiveQuestionsTable";
import EmployeesTable from "src/common/components/tables/EmployeesTable";
import openInNewTab from "src/common/functions/openInNewTab";
import updateTextTranslation from "src/common/functions/updateTextTranslation";
import {
  GetProjectCovidConfigByPkDocument,
  GetProjectEmployeesDocument,
  useDeleteProjectCovidYesNoQuestionsMutation,
  useGetProjectCovidConfigByPkQuery,
  useGetProjectEmployeesQuery,
  useGetQrCodeMutation,
  useInsertProjectCovidConfigsMutation,
  useInsertProjectCovidNotifyEmployeesMutation,
  useInsertProjectCovidTestedPositiveQuestionsMutation,
  useInsertProjectCovidYesNoQuestionsMutation,
  useUpdateCovidConfigMutation,
  useUpdateProjectCovidYesNoQuestionByPkMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import ProjectProps from "src/common/types/manual/ProjectProps";
import * as uuid from "uuid";
// import UpdatePointOfContactModal from 'src/common/views/UpdatePointOfContactModal';

const GCProjectCovidConfig: FC<ProjectProps> = ({ projectId }) => {
  const [setupOpen, setSetupOpen] = useState(false);
  const [settingUp, setSettingUp] = useState(false);
  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false);
  const [questionOpen, setQuestionOpen] = useState(false);
  const [requirementsOpen, setRequirementsOpen] = useState<string>();
  const contactEmployeeOpen = useRef<SetCovidContactEmployeeModalRef>(null);
  const testedPositiveQuestionOpen =
    useRef<AddCovidTestedPositiveQuestionModalRef>(null);
  // const [pointOfContactOpen, setPointOfContact] = useState(false);

  const { data, loading, refetch } = useGetProjectCovidConfigByPkQuery({
    variables: {
      projectId,
    },
  });
  const [insertCovidEmployee, { loading: insertingEmployee }] =
    useInsertProjectCovidNotifyEmployeesMutation();
  const [updateCovidQuestion] =
    useUpdateProjectCovidYesNoQuestionByPkMutation();
  const [updateProjectCovid, { loading: updatingConfig }] =
    useUpdateCovidConfigMutation();
  const { data: potentialContactEmployeesData } = useGetProjectEmployeesQuery({
    variables: {
      where: {
        project_id: { _eq: projectId },
      },
    },
  });
  const { data: newNotifyEmployeesData } = useGetProjectEmployeesQuery({
    variables: {
      where: {
        project_id: { _eq: projectId },
        _or: [
          {
            project_covid_notify_employees: {
              project_id: { _neq: projectId },
            },
          },
          {
            _not: { project_covid_notify_employees: {} },
          },
        ],
      },
    },
  });
  const [getQrCode] = useGetQrCodeMutation();
  const [insertCovid, { loading: insertingCovid }] =
    useInsertProjectCovidConfigsMutation();
  const [insertCovidQuestions, { loading: insertingQuestions }] =
    useInsertProjectCovidYesNoQuestionsMutation();
  const [
    insertTestedPositiveQuestions,
    { loading: insertingTestedPositiveQuestions },
  ] = useInsertProjectCovidTestedPositiveQuestionsMutation();
  const [, { loading: deletingCovidQuestions }] =
    useDeleteProjectCovidYesNoQuestionsMutation();
  const { data: employeeData, loading: employeeLoading } =
    useGetProjectEmployeesQuery({
      variables: {
        where: {
          project_id: {
            _eq: projectId,
          },
        },
      },
    });

  if (loading || employeeLoading) {
    return <LoadingContent />;
  }

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ width: "100%", minWidth: 850 }}
    >
      <StyledContent padding backgroundColor="white">
        <AddCovidTestedPositiveQuestionModal
          ref={testedPositiveQuestionOpen}
          onCancel={() => testedPositiveQuestionOpen.current?.close()}
          onCreate={async (values) => {
            testedPositiveQuestionOpen.current?.close();
            await insertTestedPositiveQuestions({
              awaitRefetchQueries: true,
              variables: {
                objects: [
                  {
                    sort_id: uuid.v1(),
                    question: {
                      data: {
                        original: values.question,
                      },
                    },
                    project_id: projectId,
                  },
                ],
              },
              refetchQueries: [
                {
                  query: GetProjectCovidConfigByPkDocument,
                  variables: { projectId },
                },
              ],
            });
          }}
        />
        <AddCovidQuestionModal
          visible={questionOpen}
          onCancel={() => setQuestionOpen(false)}
          onCreate={async (values) => {
            setQuestionOpen(false);
            await insertCovidQuestions({
              variables: {
                objects: [
                  {
                    project_id: projectId,
                    answer_is_yes: values.correct_answer === "yes",
                    question: {
                      data: {
                        original: values.question,
                      },
                    },
                  },
                ],
              },
              refetchQueries: [
                {
                  query: GetProjectCovidConfigByPkDocument,
                  variables: { projectId },
                },
              ],
            });
          }}
        />
        {potentialContactEmployeesData && (
          <SetCovidContactEmployeeModal
            ref={contactEmployeeOpen}
            projectEmployees={(
              potentialContactEmployeesData.project_employee || []
            ).map((employee) => ({
              id: employee.id,
              name: employee.employee.user.name,
            }))}
            onUpdate={(values) => {
              updateProjectCovid({
                awaitRefetchQueries: true,
                variables: {
                  _set: {
                    contact_project_employee_id: values.projectEmployeeId,
                  },
                  where: {
                    project_id: {
                      _eq: projectId,
                    },
                  },
                },
                refetchQueries: [
                  {
                    query: GetProjectCovidConfigByPkDocument,
                    variables: {
                      projectId,
                    },
                  },
                ],
              });
              contactEmployeeOpen.current?.close();
            }}
            onCancel={() => contactEmployeeOpen.current?.close()}
          />
        )}
        <SetupCovidModal
          // TODO
          projectEmployees={(employeeData?.project_employee ?? []).map(
            (emp) => ({
              id: emp.id,
              name: emp.employee.user.name,
              title: emp.employee.employee_title?.name?.clientText,
            }),
          )}
          visible={setupOpen}
          onCancel={() => setSetupOpen(false)}
          onCreate={async (values) => {
            setSetupOpen(false);
            setSettingUp(true);
            const { data: qrCodeData } = await getQrCode({
              variables: {
                input: {
                  data: `https://app.siteform.net/covid/${projectId}/form`,
                },
              },
            });
            if (qrCodeData) {
              await insertCovid({
                awaitRefetchQueries: true,
                variables: {
                  objects: [
                    {
                      worker_restriction_length: `${values.workerRestrictionDaysLength} days`,
                      requirements: {
                        data: {
                          original: values.requirements,
                        },
                      },
                      requirements_text: values.requirements,
                      contact_project_employee_id:
                        values.contactProjectEmployeeId,
                      project_id: projectId,
                      qr_code_url: qrCodeData.getQrCode,
                    },
                  ],
                },
                refetchQueries: [
                  {
                    query: GetProjectCovidConfigByPkDocument,
                    variables: {
                      projectId,
                    },
                  },
                ],
              });
            }
            setSettingUp(false);
          }}
        />
        {data?.project_covid_config_by_pk ? (
          <>
            <EditCovidRequirementsModal
              visible={!!requirementsOpen}
              initialValues={{
                requirements:
                  data?.project_covid_config_by_pk?.requirements.original,
              }}
              onCancel={() => setRequirementsOpen(undefined)}
              onFinish={async (values) => {
                setRequirementsOpen(undefined);

                // TODO loading
                await updateTextTranslation(
                  data.project_covid_config_by_pk?.requirements.id!,
                  values.requirements,
                );

                await refetch();
              }}
            />
            <Space direction="vertical" size="large">
              {!data.project_covid_config_by_pk.contact_project_employee && (
                <Button
                  loading={updatingConfig}
                  disabled={updatingConfig}
                  onClick={() => contactEmployeeOpen.current?.open()}
                >
                  Set Contact Employee
                </Button>
              )}
              <ProjectCovidDescriptions
                // TODO
                projectCovidConfig={data.project_covid_config_by_pk}
              />
              {/* <Button
                onClick={() => {
                  setRequirementsOpen(
                    data.project_covid_config_by_pk?.requirements.original,
                  );
                }}
              >
                Update Requirements
              </Button> */}
            </Space>
          </>
        ) : (
          <Button
            type="primary"
            loading={insertingCovid || settingUp}
            disabled={insertingCovid}
            onClick={() => setSetupOpen(true)}
          >
            Setup COVID-19 Requirements
          </Button>
        )}
      </StyledContent>
      {data?.project_covid_config_by_pk && (
        <>
          <StyledContent padding backgroundColor="white">
            <EmployeesTable
              loading={insertingEmployee}
              title={() => (
                <Space direction="vertical">
                  <h3>Employees to be Notified</h3>
                  <Button
                    type="primary"
                    onClick={() => setAddEmployeeOpen(true)}
                    loading={insertingEmployee}
                  >
                    Add Employee
                  </Button>
                </Space>
              )}
              dataSource={data.project_covid_config_by_pk.project_covid_notify_employees.map(
                (e) => e.project_employee.employee,
              )}
            />
            <AddProjectCovidNotifyEmployeeValuesModal
              visible={addEmployeeOpen}
              projectEmployees={newNotifyEmployeesData?.project_employee ?? []}
              onCancel={() => setAddEmployeeOpen(false)}
              onCreate={async (values) => {
                setAddEmployeeOpen(false);
                await insertCovidEmployee({
                  awaitRefetchQueries: true,
                  variables: {
                    objects: [
                      {
                        project_id: projectId,
                        project_employee_id: values.projectEmployeeId,
                      },
                    ],
                  },
                  refetchQueries: [
                    {
                      query: GetProjectEmployeesDocument,
                      variables: {
                        where: {
                          project_id: { _eq: projectId },
                          _or: [
                            {
                              project_covid_notify_employees: {
                                project_id: { _neq: projectId },
                              },
                            },
                            {
                              _not: { project_covid_notify_employees: {} },
                            },
                          ],
                        },
                      },
                    },
                    {
                      query: GetProjectCovidConfigByPkDocument,
                      variables: {
                        projectId,
                      },
                    },
                  ],
                });
              }}
            />
          </StyledContent>
          <StyledContent padding backgroundColor="white">
            <CovidQuestionsTable
              loading={insertingQuestions || deletingCovidQuestions}
              title={() => (
                <Space direction="vertical">
                  <h3>COVID-19 Self-Certify Questions</h3>
                  <Button
                    type="primary"
                    loading={insertingQuestions}
                    onClick={() => setQuestionOpen(true)}
                  >
                    Add Question
                  </Button>
                </Space>
              )}
              onDelete={async (question) => {
                updateCovidQuestion({
                  awaitRefetchQueries: true,
                  variables: {
                    _set: {
                      deleted_at: dayjs().toISOString(),
                    },
                    pk_columns: { id: question.id },
                  },
                  refetchQueries: [
                    {
                      query: GetProjectCovidConfigByPkDocument,
                      variables: {
                        projectId,
                      },
                    },
                  ],
                });
              }}
              dataSource={data?.project_covid_yes_no_question}
            />
          </StyledContent>
          <StyledContent padding backgroundColor="white">
            <CovidTestedPositiveQuestionsTable
              loading={insertingTestedPositiveQuestions}
              title={() => (
                <Space direction="vertical">
                  <h3>COVID-19 Tested Positive Questions</h3>
                  <Button
                    type="primary"
                    loading={insertingTestedPositiveQuestions}
                    onClick={() => testedPositiveQuestionOpen.current?.open()}
                  >
                    Add Question
                  </Button>
                </Space>
              )}
              dataSource={
                data.project_covid_config_by_pk
                  .project_covid_tested_positive_questions
              }
            />
          </StyledContent>
          <StyledContent padding backgroundColor="white">
            <h3>Point of Contact</h3>
            <Space direction="vertical">
              {/* {potentialContactEmployeesData?.} */}
              <div>
                {
                  data.project_covid_config_by_pk.contact_project_employee
                    ?.employee.user.name
                }
              </div>
              <Button
                type="primary"
                onClick={() => contactEmployeeOpen.current?.open()}
              >
                Update Contact
              </Button>
            </Space>
          </StyledContent>
          <StyledContent padding backgroundColor="white">
            <h3>Guest COVID-19 Self-Certification QR Code</h3>
            <Space direction="vertical">
              <img src={data.project_covid_config_by_pk.qr_code_url} />
              <Button
                type="primary"
                onClick={() => {
                  if (data.project_covid_config_by_pk) {
                    openInNewTab(data.project_covid_config_by_pk.qr_code_url);
                  }
                  // fetch(
                  //   'https://firebasestorage.googleapis.com/v0/b/siteform-3170b.appspot.com/o/images%2F14ee953b-9f13-45a8-b8e9-061ba96acf4a?alt=media&token=74a79650-9486-11ea-abac-270293af6a68',
                  //   {
                  //     mode: 'no-cors',
                  //     headers: {
                  //       'Access-Control-Allow-Origin': '*',
                  //       'Content-Type': 'application/json',
                  //       credentials: 'include',
                  //     },
                  //     credentials: 'include',
                  //   }
                  // )
                  //   .then((r) => r.blob())
                  //   .then((data) => {
                  //     console.log(data);
                  //   });
                  // // var element = document.createElement('a');
                  // // var file = new Blob(
                  // //   [data.project_covid_config_by_pk!.qr_code_url]
                  // //   // { type: 'image/*' }
                  // // );
                  // // element.href = URL.createObjectURL(file);
                  // // element.download = 'qrcode';
                  // // element.click();
                }}
              >
                Open Image
              </Button>
            </Space>
          </StyledContent>
        </>
      )}
    </Space>
  );
};

export default GCProjectCovidConfig;
