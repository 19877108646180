import clsx from "clsx";
import React from "react";
import dayjs from "dayjs";

interface BorderTimeProps {
  time: dayjs.Dayjs;
}

const BorderTime: React.FC<
  BorderTimeProps & ({ top: boolean } | { bottom: boolean })
> = (props) => {
  return (
    <div
      className={clsx(
        "absolute left-0 z-40 flex flex-row items-center w-full h-px transition-opacity opacity-0 pointer-events-none group-hover:opacity-100",
        "top" in props ? "top-0" : "bottom-0",
      )}
    >
      <div className="flex flex-row items-center justify-center w-full">
        <div className="px-0.5 bg-white border border-static-secondary rounded-1">
          <span className="whitespace-nowrap text-0.75">
            {props.time.format("h:mm a")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BorderTime;
