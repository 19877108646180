import { Layout } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';
import React from 'react';

interface Props extends BasicProps {
  nb?: boolean;
  ph?: boolean;
  pv?: boolean;
  children?: any;
}

const BContent = ({ nb, ph = true, pv = true, children, ...props }: Props) => {
  return (
    <Layout.Content
      style={{
        backgroundColor: nb ? 'transparent' : 'white',
        paddingLeft: ph ? 24 : 0,
        paddingRight: ph ? 24 : 0,
        paddingTop: pv ? 24 : 0,
        paddingBottom: pv ? 24 : 0,
        ...props.style,
      }}
    >
      {children}
    </Layout.Content>
  );
};

export default BContent;
