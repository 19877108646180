import { IconCircleX, IconClock, IconClockOff } from "@tabler/icons";
import React, { useState } from "react";
import Switcher from "src/common/components/general/Switcher";
import BlockOutDeliveryTimesTable from "./BlockOutDeliveryTimesTable";
import { TimeRange } from "./type";
import useBlockOutDevlieryTimes from "./useBlockOutDeliveryTimes";

interface BlockOutDeliveryTimesProps {
  projectId: string;
  featureOn: boolean;
  loading?: boolean;
}

const BlockOutDeliveryTimes: React.FunctionComponent<
  BlockOutDeliveryTimesProps
> = (props) => {
  const {
    timesTable,
    addBlockOutDelivery,
    removeBlockOutDelivery,
    loading,
    toggleBlockOutSwitch,
  } = useBlockOutDevlieryTimes(props.projectId);

  const handleSwitcherChange = () => {
    toggleBlockOutSwitch(!props.featureOn);
  };

  const handleCreateNewTimeBlock = (time: TimeRange, weekDayNumber: number) => {
    addBlockOutDelivery(time.start, time.end, weekDayNumber);
  };

  const handleRemoveTimeBlock = removeBlockOutDelivery;

  return (
    <div className="flex flex-col gap-1">
      <p className="inline-flex flex-row items-center gap-0.5">
        Block-Out Times: restrict deliveries on certain days and times
        <Switcher
          optionIndex={props.featureOn ? 1 : 0}
          onChange={handleSwitcherChange}
          options={[
            {
              icon: IconClockOff,
              label: "Off",
            },
            {
              icon: IconClock,
              label: "On",
            },
          ]}
          loading={props.loading}
        />
      </p>
      {props.featureOn && (
        <BlockOutDeliveryTimesTable
          loading={loading}
          addTimeBlock={handleCreateNewTimeBlock}
          removeTimeBlock={handleRemoveTimeBlock}
          timesTable={timesTable}
        />
      )}
    </div>
  );
};

export default BlockOutDeliveryTimes;
