import React, { useEffect, useState } from "react";
import useMeasure from "react-use-measure";
import { useEffectOnce } from "usehooks-ts";

const AUTO_SCROLL_DELAY_MS = 10000;
const SCREEN_PART_PER_SCROLL = 0.5;

interface SiteBoardBaseProps {
  children: React.ReactElement;
  defaultWidthPx?: number; // 1920 by default
  autoScroll?: boolean;
}

const SiteBoardBase: React.FunctionComponent<SiteBoardBaseProps> = (props) => {
  const [zoom, setZoom] = useState<number>(1);
  const defaultWidthPx = props.defaultWidthPx ?? 1920;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [contentRef, contentElementSize] = useMeasure();
  const [wrapperRef, wrapperElementSize] = useMeasure();

  const scrollNext = () => {
    setScrollPosition((prevScrollPos) =>
      prevScrollPos <
      contentElementSize.height - wrapperElementSize.height / zoom
        ? prevScrollPos +
          wrapperElementSize.height / (1 / SCREEN_PART_PER_SCROLL) / zoom
        : 0
    );
  };

  useEffectOnce(() => {
    if (props.autoScroll) {
      const interval = setInterval(scrollNext, AUTO_SCROLL_DELAY_MS);
      return () => {
        clearInterval(interval);
      };
    }
  });

  useEffect(() => {
    const resetScreenWidth = () => {
      if (wrapperElementSize.width) {
        setZoom(1 / (defaultWidthPx / wrapperElementSize.width));
      }
    };
    resetScreenWidth();
    window.addEventListener("resize", resetScreenWidth);
    return () => {
      window.removeEventListener("resize", resetScreenWidth);
    };
  }, [defaultWidthPx, wrapperElementSize.width]);

  return (
    <div
      className="flex flex-col w-full h-full overflow-hidden outline-white"
      style={{
        backgroundColor: "black",
      }}
      ref={wrapperRef}
    >
      <div
        className="relative h-full transition-all duration-1000"
        style={{
          zoom: zoom,
          top: `-${Math.round(scrollPosition)}px`,
        }}
        ref={contentRef}
      >
        {props.children}
      </div>
    </div>
  );
};

export default SiteBoardBase;
