import React, { useEffect } from "react";
import validateEmail from "../../../../common/functions/validateEmail";
import { Button, Input, message } from "antd";
import { UserAddOutlined } from "@ant-design/icons";

interface NewSubAdminInputProps {
  emailsToCompare?: string[];
  onEmailAdd: (newEmail: string) => void;
  visible: boolean;
}

const NewSubAdminInput: React.FC<NewSubAdminInputProps> = (props) => {
  const [newAdminEmail, setNewAdminEmail] = React.useState<string>("");
  const isEmailValid = validateEmail(newAdminEmail);

  const submit = () => {
    // TODO check if email already exists
    if (props.emailsToCompare?.includes(newAdminEmail)) {
      message.error("Email is already added");
      return;
    }

    if (!isEmailValid) return;
    props.onEmailAdd(newAdminEmail);
    setNewAdminEmail("");
  };
  
  return (
    <div className={`flex flex-row items-center gap-0.5`}>
      <div className={`flex-1`}>
        <Input
          className={`w-full`}
          onChange={(v) => setNewAdminEmail(v.target.value)}
          value={newAdminEmail}
          placeholder={`Enter email address`}
          onPressEnter={submit}
        />
      </div>
      <div>
        <Button
          className="bg-interactive-primary"
          style={{"color": "white"}}
          disabled={!isEmailValid}
          onClick={submit}
          icon={<UserAddOutlined />}
        />
      </div>
    </div>
  );
};

export default NewSubAdminInput;