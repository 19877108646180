import { FirebaseOptions } from "firebase/app";

const config = {
  hasuraUrl: process.env.REACT_APP_HASURA_URL,
  hasuraWebSocket: process.env.REACT_APP_HASURA_WEB_SOCKET,
  firebaseFunctionsUrl: process.env.REACT_APP_FUNCTIONS_URL
};

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MSG_SENDER_ID,

  ...(process.env.REACT_APP_FB_APP_ID ? {
    appId: process.env.REACT_APP_FB_APP_ID
  } : {}),

  ...(process.env.REACT_APP_FB_MEASUREMENT_ID ? {
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
  } : {})
};

// Hotjar
const hotjarSiteIdStr = process.env.REACT_APP_HOTJAR_SITE_ID;
const hotjarVersionStr = process.env.REACT_APP_HOTJAR_VERSION;
const hotjarSiteId = hotjarSiteIdStr ? parseInt(hotjarSiteIdStr) : undefined;
const hotjarVersion = hotjarVersionStr ? parseInt(hotjarVersionStr) : undefined;

const hotjarConfig =
  typeof hotjarSiteId === "number"
  && typeof hotjarVersion === "number"
  && !isNaN(hotjarSiteId)
  && !isNaN(hotjarVersion) ?
    {
      siteId: hotjarSiteId,
      hotjarVersion: hotjarVersion
    } : undefined;

const keys = {
  hasuraUrl: `${config.hasuraUrl}/v1/graphql`,
  hasuraRelayUrl: `${config.hasuraUrl}/v1beta1/relay`,
  hasuraWebSocket: `${config.hasuraWebSocket}/v1/graphql`,
  hasuraRelayWebSocket: `${config.hasuraWebSocket}/v1beta1/relay`,
  firebaseFunctionsUrl: config.firebaseFunctionsUrl,
  firebase: firebaseConfig,
  hotjar: hotjarConfig
};


export default keys;
