import { Divider, Layout, Menu } from "antd";
import React, { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import Link from "src/common/components/general/Link";
import StyledContent from "src/common/components/layouts/StyledContent";
import styled from "styled-components";

const { Sider } = Layout;

const SideBar = styled(Sider).attrs(() => ({
  theme: "light",
  width: 235,
}))``;

interface Props {
  Header?: any;
  sideMenuItems: Array<{
    path: string;
    title: string;
    match: RegExp;
  }>;
  children?: React.ReactNode;
}

const BaseSideLayout: FunctionComponent<Props> = ({
  children,
  Header,
  sideMenuItems,
}) => {
  const location = useLocation();
  return (
    <Layout>
      <SideBar style={{ overflowY: "scroll", overflowX: "hidden" }}>
        {Header && (
          <>
            {Header}
            <Divider />
          </>
        )}
        <Menu
          mode="inline"
          selectedKeys={sideMenuItems
            .filter((i) => location.pathname.match(i.match))
            .map((i) => i.title)}
          style={{ borderRight: 0 }}
          items={sideMenuItems.map((i) => ({
            key: i.title,
            label: <Link href={i.path}>{i.title}</Link>,
          }))}
        />
      </SideBar>
      <StyledContent
        padding
        backgroundColor="transparent"
        style={{
          overflowY: "scroll",
        }}
      >
        {children}
      </StyledContent>
    </Layout>
  );
};

export default BaseSideLayout;
