import {
  IconAlertCircle,
  IconTrafficCone,
  IconBriefcase,
  IconCalendar,
  IconCash,
  IconChecklist,
  IconChartAreaLine,
  IconHammer,
  IconReportAnalytics,
  IconSettings,
  IconTie,
  IconUserCheck,
  IconUsers,
  IconMessage,
  IconFirstAidKit,
  IconFlask,
  IconReportSearch,
} from "@tabler/icons";
import React from "react";
import { Outlet, useNavigate, useOutlet, useParams } from "react-router-dom";
import NavigationWrapper, {
  NavigationMenuProps,
} from "src/common/components/layouts/NavigationWrapper";
import NavigationPlaceholder from "src/common/components/NavigationPlaceholder";
import { useGetProjectDisabledFeaturesQuery } from "src/common/types/generated/apollo/graphQLTypes";

interface GCProjectDetailProps {}

const GCProjectDetail: React.FC<GCProjectDetailProps> = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const { projectId } = useParams();
  if (!projectId) throw new Error("projectId not found");
  const { data } = useGetProjectDisabledFeaturesQuery({
    variables: { projectId },
  });

  const setting = data?.project_setting_by_pk;
  const menu: NavigationMenuProps = {
    navigationItems: [
      {
        label: "Dashboard",
        rootPath: `dashboard`,
        onClick: () => {
          navigate(`dashboard`);
        },
        icon: IconChartAreaLine,
      },
      {
        label: "Orientation",
        rootPath: `orientation`,
        icon: IconUserCheck,
        onClick: () => {
          navigate("orientation");
        },
      },
      {
        label: "Deliveries",
        rootPath: `calendar`,
        icon: IconCalendar,
        onClick: () => {
          navigate("calendar");
        },
      },

      ...(setting?.site_inspection_enabled
        ? [
            {
              label: "Inspections",
              rootPath: "inspections",
              onClick: () => {
                navigate("inspections");
              },
              icon: IconChecklist,
            },
          ]
        : []),
      ...(setting?.observation_enabled
        ? [
            {
              label: "Observations",
              rootPath: `observations`,
              onClick: () => {
                navigate(`observations`);
              },
              icon: IconReportSearch,
            },
          ]
        : []),
      {
        label: "Toolbox Talks",
        rootPath: "toolbox-talks",
        onClick: () => {
          navigate("toolbox-talks");
        },
        icon: IconHammer,
      },
      {
        label: "Job Hazard Analysis Log",
        rootPath: `jha`,
        onClick: () => {
          navigate("jha");
        },
        icon: IconTrafficCone,
      },
      ...(setting?.incident_enabled
        ? [
            {
              label: "Incident Management",
              rootPath: `incident`,
              onClick: () => {
                navigate("incidents");
              },
              icon: IconFirstAidKit,
            },
          ]
        : []),
      {
        icon: IconFlask,
        label: "SDS",
        rootPath: "sds",
        onClick: () => {
          navigate("sds/project");
        },
      },
      {
        label: "Reports",
        rootPath: "reports",
        onClick: () => {
          navigate("reports");
        },
        icon: IconReportAnalytics,
      },
      {
        label: "Subcontractors",
        rootPath: `subcontractors`,
        onClick: () => {
          navigate(`subcontractors`);
        },
        icon: IconBriefcase,
      },
      {
        label: "Workers",
        rootPath: `workers`,
        onClick: () => {
          navigate("workers");
        },
        icon: IconUsers,
      },
      {
        label: "SiteAlerts",
        rootPath: `alerts`,
        onClick: () => {
          navigate(`alerts`);
        },
        icon: IconMessage,
      },
      {
        label: "Team",
        rootPath: `team`,
        onClick: () => {
          navigate(`team`);
        },
        icon: IconTie,
      },
      {
        label: "Settings",
        rootPath: `settings`,
        onClick: () => {
          navigate(`settings`);
        },
        icon: IconSettings,
      },
      {
        label: "Billing",
        rootPath: "billing",
        onClick: () => {
          navigate("billing");
        },
        icon: IconCash,
      },
    ],
  };

  return (
    <NavigationWrapper menu={menu}>
      <Outlet />
      {!outlet && <NavigationPlaceholder />}
    </NavigationWrapper>
  );
};

export default GCProjectDetail;
