import * as React from "react";
import useArchiveOrUnarchiveJHA from "src/common/api/relay/mutationHooks/useArchiveOrUnarchiveJHA";

interface ArchiveOrActivateTaskProps {
  status: "active" | "archived";
  taskId: string;
}

const ArchiveOrActivateTask = (props: ArchiveOrActivateTaskProps) => {
  const [archiveOrUnarchiveJha] = useArchiveOrUnarchiveJHA();
  return (
    <div
      className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
      onClick={async (e) => {
        e.stopPropagation();
        archiveOrUnarchiveJha(
          props.taskId,
          props.status === "archived" ? "unarchive" : "archive"
        );
      }}
    >
      {props.status === "active" ? "Archive" : "Activate"}
    </div>
  );
};
export default ArchiveOrActivateTask;
