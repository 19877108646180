import React, { useState } from "react";
import { Modal, Button } from "antd";

interface Props {
  projectName: string;
  gcName: string;
  visible: boolean;
  onCancel: () => void;
}

const ViewEmailToBeSentToBeSentToSubAdminsToAddJHAsModal: React.FC<Props> = ({
  projectName,
  visible,
  gcName,
  onCancel,
}) => {
  const handleCancel = () => {
    onCancel();
  };

  return (<>
    <Modal
      open={visible}
      title="Example Email to be sent to Subcontractor Administrator"
      cancelText="CANCEL"
      cancelButtonProps={{ style: { display: "inline" } }}
      onCancel={handleCancel}
      onOk={handleCancel}
      footer={
        <Button type="primary" onClick={handleCancel}>
          Close
        </Button>
      }
    >
      <html>
        <div>
          <div>
            Hi
            <b> John</b>
            <br />
            <b>Frank</b> from <b> {gcName}</b> requests you add a JHA(s) to
            <b> {projectName}</b>.
            <br />
            Message from Frank:
            <b> We need a JHA for setting curtainwall at the south stair</b>
            <br />
            <br />
            It's super easy, just click one of these links and drag-and-drop
            your JHAs into the upload portal (see below for more support)
            <br />
            <br />
            <div>
              <Button type="primary">
                Add JHA to the Project and your Company JHA Library
              </Button>

              <br />

              <div style={{ marginLeft: "100px" }}>
                <b>- OR -</b>
              </div>

              <Button type="primary">Only Add to this Project</Button>
            </div>
            <br />
            <b>What does this mean and what is it for? </b>
            <br />
            Great question! First, welcome to SiteForm. We work hard to make
            your life easier. We streamline processes, making it very easy to
            do what is required every day, like Pre-Task Planning and daily
            reporting.
            <br />
            Your foreperson(s) or crew lead(s) will use the SiteForm mobile
            app to capture their PTP or safety huddle each day. In order to do
            this, a Job Hazard Analysis (JHA or AHA) for each definable
            feature of work or task needs to be added to SiteForm. Many times
            your company already has this information created, but it is not
            available to your foreperson until it is added to SiteForm. When
            you upload your JHAs, your crew lead can select each JHA they are
            performing today, giving them the information they need to perform
            this work safely.
            <br />
            <br />
            <b>How do I upload my JHA(s)?</b>
            <br />
            Simply click one of the links above, which opens a website to add
            your JHA(s). Then drag-and-drop your JHA(s) and select "Upload"
            <br />
            <br />
            <b>How long does it take?</b>
            <br />
            It depends on how many JHAs you add and if the file is readable,
            meaning, someone is able to copy and paste the text. Help us help
            you by uploading original files, like Word docs or Excel files.
            Make sure any PDFs are not password protected or are scanned
            images. We will work as quickly as we can to process them.
            <br />
            <br />
            <b>What's next?</b>
            <br />
            Once we process your JHA(s) you will receive an email with the JHA
            in it. We want to make sure the information was copied correctly.
            Open the email, review the JHA, and if everything looks good,
            Accept It by clicking the link in the email. If you need to make
            any changes you can do that on our
            <a href="{{linkOfWebsite}}"> Website</a>
            . Log in and go to JHAs to access your Company JHA Library or go
            to the Project and select JHAs at the top to see the list of your
            Project JHAs. Click the JHA to edit it.
            <br />
            <br />
            <b>What's a Company and Project JHA Library?</b>
            <br />
            Another great question! Your
            <b> Company JHA Library </b>
            is where your global JHAs live. If you perform many of the same
            tasks on all of your projects then adding these JHAs to your
            Company Library makes the JHAs available to each Project. Your
            Foreperson or Crew Lead simply adds them to the Project JHA
            Library by clicking the "+" in the top right corner of the mobile
            app. Only you can edit or modify a Company Library JHA.
            <br />
            <br />
            Your
            <b> Project JHA Library </b>
            includes the JHAs that are specific to that project. You or your
            Foreperson is able to customize it to be project-specific. And you
            can add a Project JHA to your Company Library on our
            <a href="{{linkOfWebsite}}"> Website</a>.
            <br />
            <br />
            We want this to greatly minimize the upfront work you and your
            team complete on each project. We hope it helps!
            <br />
            <br />
            Email us at
            <a href="{{emailForSupport}}"> support@siteform.io </a>
            with any questions or suggestions.
          </div>
        </div>
      </html>
    </Modal>
  </>);
};

export default ViewEmailToBeSentToBeSentToSubAdminsToAddJHAsModal;
