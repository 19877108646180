/**
 * @generated SignedSource<<7fb29c1d6da1839a29091b508b3e6cfc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type privacy_setting_set_input = {
  created_at?: string | null | undefined;
  employer?: boolean | null | undefined;
  id?: string | null | undefined;
  manager?: boolean | null | undefined;
  supervisor?: boolean | null | undefined;
};
export type UpdatePrivacySettingsByPkMutation$variables = {
  id: string;
  set: privacy_setting_set_input;
};
export type UpdatePrivacySettingsByPkMutation$data = {
  readonly update_privacy_setting_by_pk: {
    readonly employer: boolean;
    readonly id: string;
    readonly manager: boolean;
    readonly supervisor: boolean;
  } | null | undefined;
};
export type UpdatePrivacySettingsByPkMutation = {
  response: UpdatePrivacySettingsByPkMutation$data;
  variables: UpdatePrivacySettingsByPkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "set"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "privacy_setting",
    "kind": "LinkedField",
    "name": "update_privacy_setting_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "manager",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "supervisor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employer",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePrivacySettingsByPkMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePrivacySettingsByPkMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2976c52e012a95a7c77883e84ae13731",
    "id": null,
    "metadata": {},
    "name": "UpdatePrivacySettingsByPkMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePrivacySettingsByPkMutation(\n  $id: uuid!\n  $set: privacy_setting_set_input!\n) {\n  update_privacy_setting_by_pk(pk_columns: {id: $id}, _set: $set) {\n    manager\n    id\n    supervisor\n    employer\n  }\n}\n"
  }
};
})();

(node as any).hash = "c7d841c76ab386161173a3930af25f55";

export default node;
