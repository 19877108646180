import React, { useContext } from "react";
import {
  CheclistItemAnswerType,
  OptionResponseType,
} from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import SiteInspectionUserContext from "src/domain-features/siteinspection/utils/siteInspectionUserContext";
import {
  GetInspectionDataOutput,
  InspectionChecklistItem,
} from "src/common/types/generated/apollo/graphQLTypes";
import { generalChecklistType } from "src/domain-features/siteinspection/utils/generalChecklistType";
import SiteInspectionWorkerChecklistItemUI from "./SiteInspectionWorkerStepChecklistItemUI";
import capitalize from "src/common/functions/capitalize";
import {
  useCurrentDBLangSelector,
  useCurrentLanguage,
} from "../../../../../../utility-features/i18n/context/languageHooks";
import useSiteInspectionUser from "src/domain-features/siteinspection/utils/useSiteInspectionUser";

export interface SiteInspectionWorkerStepChecklistItemListUIProps {
  deficientTypeOptions: GetInspectionDataOutput["deficientAnswerSelectTypes"];
  checklistItemTypeMap: { [type: string]: InspectionChecklistItem[] };
  showOptions?: boolean;
  hideIncorrect?: boolean;
  checklistItemAnswers?: { [id: string]: CheclistItemAnswerType };
  onDeficientTypeSelect: (
    deficientTypeId: string | undefined,
    immediateFix: boolean,
    id: string,
  ) => void;
  onTextChange: (text: string, id: string) => void;
  onOptionResponse: (
    option_response: OptionResponseType,
    correct_answer: "yes" | "no",
    id: string,
  ) => void;
}

const SiteInspectionWorkerStepChecklistItemListUI: React.FC<
  SiteInspectionWorkerStepChecklistItemListUIProps
> = ({
  deficientTypeOptions,
  showOptions,
  hideIncorrect,
  checklistItemAnswers,
  checklistItemTypeMap,
  onDeficientTypeSelect,
  onOptionResponse,
  onTextChange,
}) => {
  const workerContext = useSiteInspectionUser();

  const gs = useCurrentDBLangSelector();
  const language = useCurrentLanguage();

  return (
    <div>
      {Object.entries(checklistItemTypeMap).map(([type, items]) => {
        return (
          <div key={type}>
            <div className="text-1.25">
              {items[0].type?.name
                ? gs(items[0].type?.name)
                : capitalize(generalChecklistType)}
            </div>
            <div className="ml-0.5 mt-1">
              {items.map((item) => (
                <div key={item.id}>
                  <SiteInspectionWorkerChecklistItemUI
                    {...{
                      ...item,
                      deficientTypeOptions,
                      initialValue: checklistItemAnswers?.[item.id],
                      language,
                      showOptions,
                      hideIncorrect,
                      onDeficientTypeSelect: (
                        deficientTypeId,
                        isImmediateFix,
                      ) =>
                        onDeficientTypeSelect(
                          deficientTypeId,
                          isImmediateFix,
                          item.id,
                        ),
                      onOptionResponse: (response) =>
                        onOptionResponse(
                          response,
                          item.correct_answer === "no" ? "no" : "yes",
                          item.id,
                        ),
                      onTextChange: (text) => onTextChange(text, item.id),
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default SiteInspectionWorkerStepChecklistItemListUI;
