import dayjs from "dayjs";
import { useGetSiteOrientationInPersonWorkerListQuery$data } from "src/common/types/generated/relay/useGetSiteOrientationInPersonWorkerListQuery.graphql";
import { OrientationType } from "src/common/types/manual/OrientationType";

const getCorporateCompletionMap = (
  data: useGetSiteOrientationInPersonWorkerListQuery$data,
  completionType: string | null,
) => {
  const allUserCorporateMap: {
    [userId: string]: { [orientationId: string]: boolean };
  } = {};

  data.corporateOrientation.edges.forEach((o) => {
    const showLate =
      o.node.project_orientations[0] &&
      (o.node.project_orientations[0].hide_but_give_credit ||
        (!o.node.project_orientations[0].play_during_in_person &&
          completionType === "inpersonComplete"));
    o.node.orientation_results.forEach((or) => {
      if (or.user_id) {
        const moreThan1HourOldORShown = showLate
          ? dayjs(or.completed_at).isSameOrBefore(dayjs().subtract(1, "hour"))
          : true;
        //true as we the corporates palyed in siteform are still valid
        const notExpired = dayjs(or.completed_at)
          .add(o.node.duration_valid, "M")
          .isSameOrAfter(dayjs());
        //above we are using or.completed_at in dayjs so if it is undefined it will take current time but
        //doesn't matter as we'll check whether the or.completed_at is present before using these  values
        if (!allUserCorporateMap[or.user_id])
          allUserCorporateMap[or.user_id] = {
            [o.node.pk]: or.completed_at
              ? moreThan1HourOldORShown && notExpired
              : or.status === "completed",
          };
        else if (
          allUserCorporateMap[or.user_id][o.node.pk] === null ||
          allUserCorporateMap[or.user_id][o.node.pk] === undefined
        ) {
          allUserCorporateMap[or.user_id][o.node.pk] = or.completed_at
            ? moreThan1HourOldORShown && notExpired
            : or.status === "completed";
        } else
          allUserCorporateMap[or.user_id][o.node.pk] =
            allUserCorporateMap[or.user_id][o.node.pk] &&
            (or.completed_at
              ? moreThan1HourOldORShown && notExpired
              : or.status === "completed");
      }
    });
  });
  const corporateCompletionMap: { [userId: string]: boolean } = {};
  Object.entries(allUserCorporateMap).forEach(([userId, orientationMap]) => {
    corporateCompletionMap[userId] =
      Object.values(orientationMap).filter((passed) => passed).length ===
      data.corporateOrientation.edges.length;
  });

  return corporateCompletionMap;
};
export default getCorporateCompletionMap;
