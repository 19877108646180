import React, { useState } from "react";
import { SiteInspectionStepProps } from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import AutoComplete from "src/common/components/general/AutoComplete";
import useSiteInspectionUser from "src/domain-features/siteinspection/utils/useSiteInspectionUser";

import { useCurrentLangStrings } from "../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteInspectionWorkerViewStepsEmployerProps
  extends SiteInspectionStepProps {
  subAdmin?: boolean;
  subOptions: Array<{
    name: string;
    id: string;
  }>;
}

const SiteInspectionWorkerViewStepsEmployer: React.FC<
  SiteInspectionWorkerViewStepsEmployerProps
> = (props) => {
  const workerContext = useSiteInspectionUser();
  const langStrings = useCurrentLangStrings();

  const prevInsEmployerId = localStorage.getItem("inspectionEmployerId");
  const prevInsEmployerName = prevInsEmployerId
    ? undefined
    : localStorage.getItem("inspectionEmployerName");
  const [selectOptionVal, setSelectOptionVal] = useState(
    props.subOptions.find(
      (w) =>
        w.id ===
        (workerContext.user?.company
          ? workerContext.user.company.id
          : prevInsEmployerId),
    )?.id,
  );
  const [employer, setEmployer] = useState(
    workerContext.user?.company?.name || prevInsEmployerName || "",
  );

  const onEnter = () => {
    const formattedEmployerName = employer.trim().replace(/\s\s+/g, " ");
    if (selectOptionVal) {
      onSelect(selectOptionVal);
    } else if (employer) {
      const foundSub = props.subOptions.find(
        (w) => w.name === formattedEmployerName,
      );
      if (foundSub) {
        onSelect(foundSub.id);
      } else {
        localStorage.setItem("inspectionEmployerName", formattedEmployerName);
        localStorage.removeItem("inspectionEmployerId");
        workerContext.updateUser((u) =>
          u
            ? { ...u, company: { name: employer } }
            : { company: { name: employer } },
        );
        props.onNext();
      }
    }
  };
  const onSelect = (pickedId: string) => {
    localStorage.setItem("inspectionEmployerId", pickedId);
    localStorage.removeItem("inspectionEmployerName");
    workerContext.updateUser((u) =>
      u
        ? {
            ...u,
            company: {
              id: pickedId,
              name: props.subOptions.find((s) => s.id === pickedId)?.name || "",
            },
          }
        : {
            company: {
              id: pickedId,
              name: props.subOptions.find((s) => s.id === pickedId)?.name || "",
            },
          },
    );
    props.onNext();
  };

  return (
    <SiteFeatureStepLayout
      onBackButtonClick={props.onBack}
      onNextButtonClick={onEnter}
      nextButtonDisabled={!(employer.length || selectOptionVal)}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.whoDoyYouWorkFor}
      >
        <p className="text-grey text-1">
          {langStrings.strings.ifYouDoNotSeeYourCompanyContactYourGCOrOffice}
        </p>
        <div translate={`no`}>
          <AutoComplete
            initialText={employer}
            placeholder={langStrings.strings.selectOrTypeYourCompanyName}
            onTextChange={(text) => {
              setSelectOptionVal(undefined);
              setEmployer(text);
            }}
            initialSelectedOptionValue={selectOptionVal}
            options={props.subOptions.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onSelect={(val) => {
              setSelectOptionVal(val);
              if (val) onSelect(val);
            }}
          />
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteInspectionWorkerViewStepsEmployer;
