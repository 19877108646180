import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
// import './styles.css';

const type = "DraggableBodyRow";

interface Props {
  index?: number;
  moveRow?: any;
  className?: any;
  style?: any;
}

const styles = {
  dropOverDownward: {
    backgroundColor: "#1890ff1f",
  },
  dropOverUpward: {
    backgroundColor: "#1890ff1f",
  },
};

const DraggableBodyRow: React.FC<Props & any> = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = React.useRef();
  const [dropStyle, setDropStyle] = useState({});
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const item = monitor.getItem();
      // TODO remove ts ignores and debug draggable components...
      // @ts-ignore
      const dragIndex = item?.index;
      if (dragIndex === index) {
        return {};
      }
      setDropStyle(
        dragIndex < index ? styles.dropOverDownward : styles.dropOverUpward,
      );
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      // MARK sometimes move row doesn't exist? if move hazard item in step
      // @ts-ignore
      if (moveRow && item.index !== undefined) {
        // @ts-ignore
        moveRow(item.index, index);
      }
    },
  });
  const [, drag] = useDrag({
    type,
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  let extraStyle = isOver ? dropStyle : {};
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", transition: "0.2s", ...extraStyle, ...style }}
      {...restProps}
    />
  );
};

export default DraggableBodyRow;
