import { IconTrash } from "@tabler/icons";
import React from "react";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import Button from "src/common/components/general/Button";
import FileUploader from "src/common/components/general/FileUploader";
import Image from "src/common/components/general/images/Image";

export interface ProfileAvatarProps {
  imageUrl?: string;
  loading?: boolean;
  preview?: boolean;
  onFileUpload: (imageUrl: string) => Promise<void>;
  onFileRemove: () => Promise<void>;
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = (props) => {
  // const [imageFile, setImageFile] = useState<File>()
  //TODO add a update Profile pic button if there's a profile pic you can use same onFileupload option
  return props.imageUrl ? (
    <div className="relative h-full w-full overflow-hidden rounded-1.5 group">
      <Image {...{ src: props.imageUrl, preview: props.preview }} />
      <div className="absolute flex flex-row gap-0.25 bottom-0 right-0 p-0.25 transition-opacity opacity-0 group-hover:opacity-100 bg-suplementary-1 rounded-tl-1">
        <FileUploader
          onFileUpload={props.onFileUpload}
          loading={props.loading}
        />
        <BPopconfirm
          onConfirm={props.onFileRemove}
          cancelText="No"
          title={
            <div>
              Are you sure you want to delete this photo/logo?
              <br />
              It cannot be recovered.
            </div>
          }
        >
          <Button
            icon={IconTrash}
            small
            secondary
            loading={props.loading}
            onClick={() => {}}
          />
        </BPopconfirm>
      </div>
    </div>
  ) : (
    <div className="flex items-center bg-suplementary-2 rounded-1.5 justify-center w-full h-full">
      <FileUploader
        onFileUpload={props.onFileUpload}
        loading={props.loading}
        accept="image/png, image/jpeg, image/jpg"
      />
    </div>
  );
};

export default ProfileAvatar;
