import React, { useState, useEffect } from 'react';
import { Modal, Form, Input } from 'antd';

interface FormValues {
  requirements: string;
}

interface Props {
  visible: boolean;
  onFinish: (values: FormValues) => void;
  onCancel: () => void;
  initialValues: FormValues;
}

const EditCovidRequirementsModal: React.FC<Props> = ({
  visible,
  onFinish,
  onCancel,
  initialValues,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ requirements: initialValues.requirements });
  }, [initialValues]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      form.resetFields();
      onFinish(values as FormValues);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    (<Modal
      open={visible}
      title="Edit Step"
      okText="Done"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
          ...initialValues,
        }}
      >
        <Form.Item
          name="requirements"
          label="Requirements"
          rules={[
            {
              required: true,
              message: 'Requirements must be specified',
            },
          ]}
        >
          <Input.TextArea
            placeholder="Describe the project COVID-19 requirements."
            autoSize={{ minRows: 4, maxRows: 12 }}
          />
        </Form.Item>
      </Form>
    </Modal>)
  );
};

export default EditCovidRequirementsModal;
