import { Button, Input, Space, Spin } from "antd";
import React, { FC, useRef, useState } from "react";
import CreateGCEmployeeModal from "src/common/components/dialogs/CreateGCEmployeeModal";
import StyledContent from "src/common/components/layouts/StyledContent";
import GeneralContractorEmployeesTable from "./GeneralContractorEmployeesTable/Table";
import {
  GetNameUsernameDocument,
  GetNameUsernameQuery,
  GetNameUsernameQueryVariables,
  useCreateGeneralContractorEmployeeMutation,
  useGetGeneralContractorByPkQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import GeneralContractorProps from "src/common/types/manual/GeneralContractorProps";
import useColumnSearch from "src/common/hooks/useColumnSearch";
import AdminGeneralContractorBillingSection from "src/root/routes/views/admin/data/general-contractors/AdminGeneralContractorBillingSection";

const AdminGeneralContractor: FC<GeneralContractorProps> = ({ gcId }) => {
  const [createEmployeeOpen, setCreateEmployeeOpen] = useState(false);
  const { data, loading, client } = useGetGeneralContractorByPkQuery({
    variables: {
      id: gcId,
    },
  });
  const [createEmployee] = useCreateGeneralContractorEmployeeMutation();
  const [creatingEmployee, setCreatingEmployee] = useState(false);

  // const [
  //   getUsername,
  //   { loading: loadingUsername },
  // ] = useGetNameUsernameLazyQuery();
  let tableRef = useRef<any>().current;
  const searchByName = useColumnSearch();

  const changeHandler = (event: any): void => {
    searchByName.setter(event.target.value);
    console.log("hello");
  };

  if (loading || !data) {
    return (
      <StyledContent padding backgroundColor="white">
        <Spin />
      </StyledContent>
    );
  }

  const { general_contractor_by_pk: gc } = data;
  if (!gc) {
    return (
      <StyledContent padding backgroundColor="white">
        General Contractor does not exist
      </StyledContent>
    );
  }

  return (
    <Space size="large" direction="vertical" style={{ width: "100%" }}>
      <StyledContent padding backgroundColor="white">
        General Contractor
        <h2>{gc.name}</h2>
      </StyledContent>
      <StyledContent padding backgroundColor="white">
        <CreateGCEmployeeModal
          visible={createEmployeeOpen}
          onCancel={() => setCreateEmployeeOpen(false)}
          onCreate={async (values) => {
            setCreateEmployeeOpen(false);
            setCreatingEmployee(true);
            const { data } = await client.query<
              GetNameUsernameQuery,
              GetNameUsernameQueryVariables
            >({
              query: GetNameUsernameDocument,
              variables: {
                name: values.name,
              },
            });
            await createEmployee({
              variables: {
                name: values.name,
                email: values.email,
                generalContractorId: gcId,
                titleId: values.titleId,
                username: data!.getNewUsername!,
                inviteType: "adminInvite",
                // phoneNumber: un
              },
            });
            tableRef && tableRef.refetch();
            setCreatingEmployee(false);
          }}
        />
        <GeneralContractorEmployeesTable
          queryRef={(ref) => (tableRef = ref)}
          titleComponent={
            <>
              <Button
                loading={creatingEmployee}
                disabled={creatingEmployee}
                type="primary"
                onClick={() => setCreateEmployeeOpen(true)}
              >
                Create Employee
              </Button>
              <Input
                type="primary"
                style={{ margin: "10px", width: "200px" }}
                placeholder={"Search"}
                onChange={changeHandler}
              ></Input>
            </>
          }
          gcId={gcId}
          onClickRow={() => {}}
          onFirstNameSearch={changeHandler}
          checkProp={searchByName.value}
        />
      </StyledContent>
      <AdminGeneralContractorBillingSection gc={gc} />
    </Space>
  );
};

export default AdminGeneralContractor;
