import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import AdminView from "./AdminView";
import AdminData from "./data/AdminData";
import AdminGeneralContractor from "./data/general-contractors/AdminGeneralContractor";
import AdminGeneralContractors from "./data/general-contractors/AdminGeneralContractors";
import AdminProfile from "./profile/AdminProfile";
import AdminProject from "./data/projects/AdminProject";
import AdminProjectSubcontractor from "./data/projects/AdminProjectSubcontractor";
import AdminSubcontractor from "./data/subcontractors/AdminSubcontractor";
import AdminSubcontractors from "./data/subcontractors/AdminSubcontractors";
import searchParamsProvider from "src/common/functions/searchParamsProvider";
import RequireRoleWrapper from "src/common/components/ReqiureRoleWrapper";
import SCProjectCrew from "src/domain-features/worker-crew/entry-routes/sub/SCProjectCrew";
import NestedRoute from "src/common/types/manual/NestedRoute";
import AdminProjects from "./data/projects/AdminProjects";
import OrientationPosterUpload from "./data/poster/OrientationPosterUpload";
import VisitorPosterUpload from "./data/visitors/VisitorPosterUpload";
import HammerTechAccounts from "./data/hammertech/HammerTechAccounts";
import CrewSightAccounts from "./data/crewsight/CrewSightAccounts";
import CrewSightProjectSettings from "./data/crewsight/CrewSightProjectSettings";
import CrewSightLogs from "./data/crewsight/CrewSightLogs";

const adminRoute: NestedRoute = {
  element: (
    <RequireRoleWrapper requiredRole="admin">
      {/* <Admin /> */}
      <Outlet />
    </RequireRoleWrapper>
  ),
  children: [
    {
      path: "",
      element: searchParamsProvider(AdminView),
      children: [
        {
          path: "",
          element: <Navigate to={`data`} />,
        },
        {
          path: "profile",
          element: searchParamsProvider(AdminProfile),
        },
        {
          path: "data",
          element: searchParamsProvider(AdminData),
          children: [
            {
              path: "",
              element: <Navigate to="projects" />,
            },
            {
              path: "projects",
              element: searchParamsProvider(AdminProjects),
              // element: <Outlet />,
            },
            {
              path: "projects/:projectId",
              element: searchParamsProvider(AdminProject),
              children: [
                {
                  path: ":subcontractorId",
                  element: searchParamsProvider(AdminProjectSubcontractor),
                  children: [
                    {
                      path: "crew/:crewId",
                      element: <SCProjectCrew />,
                    },
                  ],
                },
              ],
            },
            {
              path: "projects/:projectId/:subcontractorId",
              element: searchParamsProvider(AdminProjectSubcontractor),
            },
            {
              path: "projects/:projectId/:subcontractorId/crew/:crewId",
              element: searchParamsProvider(SCProjectCrew),
            },
            {
              path: "subcontractors",
              element: searchParamsProvider(AdminSubcontractors),
            },
            {
              path: "subcontractors/:subcontractorId",
              element: searchParamsProvider(AdminSubcontractor),
            },
            {
              path: "gcs",
              element: searchParamsProvider(AdminGeneralContractors),
            },
            {
              path: "orientation",
              element: searchParamsProvider(OrientationPosterUpload),
            },
            {
              path: "gcs/:gcId",
              element: searchParamsProvider(AdminGeneralContractor),
            },
            {
              path: "visitors",
              element: searchParamsProvider(VisitorPosterUpload),
            },
            {
              path: "hammertech",
              element: searchParamsProvider(HammerTechAccounts),
            },
            {
              path: "crewsight",
              element: searchParamsProvider(CrewSightAccounts),
            },
            {
              path: "crewsight/:projectId/settings",
              element: searchParamsProvider(CrewSightProjectSettings),
            },
            {
              path: "crewsight/:accountId/logs",
              element: searchParamsProvider(CrewSightLogs),
            },
          ],
        },
      ],
    },
  ],
};

export default adminRoute;
