import { useNavigate, useParams } from "react-router-dom";
import MobileViewWrapper from "src/common/components/layouts/MobileViewWrapper";
import LargeTableSkeleton from "src/common/components/tables/basic/LargeTableSkeletion";
import { useGetQrLotoPermitLocksQuery } from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import capitalize from "src/common/functions/capitalize";
import StyledContent from "src/common/components/layouts/StyledContent";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";
import clsx from "clsx";
import Icon from "src/common/components/general/Icon";
import { IconLock, IconLockOpen } from "@tabler/icons";
import getPermitBackgroundColor from "src/common/functions/getPermitBackgroundColor";

const QrLotoPermitLocks: React.FunctionComponent = () => {
  const { projectId } = useParams();
  console.log("jhjvhcs̄");
  const date = dayjs().toISOString();
  if (!projectId) throw new Error("projectId not found");
  const { data, loading, refetch } = useGetQrLotoPermitLocksQuery({
    variables: {
      locksWhere: { completed_at: { _is_null: false } },
      permitWhere: {
        project_id: { _eq: projectId },
        type: { _eq: "loto" },
        loto_permit: {},
        current_status: { _nin: ["closed", "pending"] },
        is_historical: { _eq: false },
      },
      projectId,
    },
    // fetchPolicy: "cache-first",
  });
  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 60000);
    return () => clearInterval(interval);
  }, []);
  const permits = data?.permit || [];
  if (loading) return <LargeTableSkeleton />;
  return (
    <MobileViewWrapper
      project={
        data?.project_by_pk
          ? {
              gcName: data.project_by_pk.general_contractor.name,
              name: data.project_by_pk.name,
            }
          : undefined
      }
    >
      <StyledContent>
        {!!permits.length && (
          <div className={`flex flex-col gap-0.25`}>
            {permits.map((permit) => {
              const loto = permit.loto_permit;
              const locks = loto?.loto_permit_lock_details || [];
              const singleLock = locks.length === 1 ? locks[0] : undefined;
              const { semanticColorClass, semanticDarkColorClass } =
                getPermitBackgroundColor(permit.current_status);
              return (
                <div
                  key={permit.id}
                  className={`${semanticColorClass} rounded-0.5 pl-1`}
                >
                  <div
                    className={`relative flex flex-row items-center gap-0.5 px-1 py-0.5 border border-interactive-secondary hover:border-interactive-primary text-white ${semanticDarkColorClass} cursor-pointer rounded-0.5`}
                    onClick={(e) => {
                      navigate(
                        `/qr/p/${projectId}/permits/date/${date}/loto/${
                          permit.id
                        }${singleLock ? `?$lotoLockId=${singleLock.id}}` : ""}`,
                      );
                    }}
                  >
                    <div
                      className={`flex flex-row flex-1 items-center gap-0.5 justify-between`}
                    >
                      <div className={`relative z-10 flex flex-col gap-0`}>
                        <p className={clsx(`text-1 font-accent flex`)}>
                          {permit.work_location.en}
                          {singleLock ? (
                            <>
                              ,{" "}
                              {singleLock.removed_at ? (
                                <Icon icon={IconLockOpen} color="white" />
                              ) : (
                                <Icon icon={IconLock} color="white" />
                              )}
                              {singleLock.lock_name.en}
                            </>
                          ) : (
                            ""
                          )}
                        </p>

                        <p className={`text-1 font-normal`}>
                          {dayjs(permit.updated_at).format(
                            "MMM Do, YYYY, hh:mm a",
                          )}
                        </p>

                        {loto && (
                          <>
                            <p className={` text-0.75`}>
                              {singleLock &&
                              singleLock.permit_project_workers.length === 1
                                ? singleLock.permit_project_workers[0]
                                    .project_worker.user!.name +
                                  (singleLock.permit_project_workers[0]
                                    .project_worker.hard_hat_number
                                    ? `, #${singleLock.permit_project_workers[0].project_worker.hard_hat_number}`
                                    : "")
                                : singleLock &&
                                  singleLock.permit_project_workers.length > 1
                                ? `Workers (${singleLock.permit_project_workers.length})`
                                : `Added By ${
                                    permit.applicant_project_worker?.user!.name
                                  }${
                                    permit.applicant_project_worker
                                      ?.hard_hat_number
                                      ? `, #${permit.applicant_project_worker
                                          ?.hard_hat_number!}`
                                      : ""
                                  }`}
                              , {permit.subcontractor.name}
                            </p>
                            {singleLock &&
                              singleLock.removed_by_user &&
                              singleLock.removed_at && (
                                <p className={` text-0.75`}>
                                  Removed by {singleLock.removed_by_user.name}
                                  {singleLock.created_by_project_worker
                                    .hard_hat_number
                                    ? `, #${singleLock.created_by_project_worker.hard_hat_number}`
                                    : ""}
                                  , on{" "}
                                  {dayjs(singleLock.removed_at).format(
                                    "MMM Do, YYYY, hh:mm a",
                                  )}
                                </p>
                              )}
                          </>
                        )}
                      </div>
                      <div
                        className={`relative w-0 h-0 flex flex-row items-center whitespace-nowrap`}
                      >
                        <div
                          className={clsx(
                            `absolute flex flex-col items-end my-auto right-0 z-0`,
                            permit.current_status === "approved"
                              ? "text-semantic-positive-green"
                              : permit.current_status === "rejected"
                              ? "text-semantic-negative"
                              : "text-interactive-primary",
                          )}
                        >
                          {capitalize(permit.current_status)}
                        </div>
                      </div>
                    </div>
                  </div>
                  {(locks.length <= 1 ? [] : locks).map((lock) => {
                    const lockWorker =
                      lock.permit_project_workers.length === 1
                        ? lock.permit_project_workers[0].project_worker
                        : undefined;
                    return (
                      <div
                        key={lock.id}
                        className={`ml-1 relative flex flex-row items-center gap-0.5 px-1 py-0.5 border border-interactive-secondary hover:border-interactive-primary  ${semanticDarkColorClass} text-white cursor-pointer rounded-0.5`}
                        onClick={(e) => {
                          navigate(
                            `/qr/p/${projectId}/permits/date/${date}/loto/${permit.id}?lotoLockId=${lock.id}`,
                          );
                        }}
                      >
                        <div
                          className={`flex flex-row flex-1 items-center gap-0.5 justify-between`}
                        >
                          <div className={`relative z-10 flex flex-col gap-0`}>
                            <p className="text-1 font-accent flex">
                              {lock.removed_at ? (
                                <Icon icon={IconLockOpen} color="white" />
                              ) : (
                                <Icon icon={IconLock} color="white" />
                              )}{" "}
                              {lock.lock_name.en}
                            </p>

                            <p className="text-1 font-normal">
                              Added On{" "}
                              {dayjs(lock.created_at).format(
                                "MMM Do, YYYY, hh:mm a",
                              )}
                            </p>

                            <p className={` text-0.75`}>
                              {lockWorker
                                ? lockWorker.user!.name +
                                  (lockWorker.hard_hat_number
                                    ? `, #${lockWorker.hard_hat_number}`
                                    : "")
                                : lock.permit_project_workers.length > 1
                                ? `Workers (${lock.permit_project_workers.length})`
                                : ""}
                            </p>
                            {lock.removed_by_user && lock.removed_at && (
                              <p className={` text-0.75`}>
                                Removed by {lock.removed_by_user.name}
                                {lock.created_by_project_worker.hard_hat_number
                                  ? `, #${lock.created_by_project_worker.hard_hat_number}`
                                  : ""}
                                , on{" "}
                                {dayjs(lock.removed_at).format(
                                  "MMM Do, YYYY, hh:mm a",
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </StyledContent>
    </MobileViewWrapper>
  );
};
export default QrLotoPermitLocks;
