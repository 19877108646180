import showErrorNotification from "./user-feedback/showErrorNotification";
import React from "react";
import getErrorCauseType from "./getErrorCauseType";
import { isDevelopmentEnvironment } from "../../common/constants/environment";
import { notification } from "antd";
import ErrorCodeBlock from "./components/ErrorCodeBlock";
import { ErrorCauseType } from "./errorHandlingTypes";
import sendHotjarEvent from "../event-tracking/hotjar/sendHotjarEvent";
import * as Sentry from "@sentry/react";

export type ErrorMessageConfigType = {
  errorTitle?: string;
  errorUserInstructions?: React.ReactNode;
};

const handleRequestError: (
  e: unknown,
  errorMessageConfig?: ErrorMessageConfigType,
) => void = (e, errorMessageConfig) => {
  // Define error cause type
  const errorCauseType: ErrorCauseType = getErrorCauseType(e);
  
  // Report errors
  let sentryEventId: string | undefined = undefined;
  if (errorCauseType !== "expected") {
    // report unexpected errors to sentry
    sentryEventId = Sentry.captureException(e);
  }
  // report all errors to hotjar
  sendHotjarEvent("error");

  // Show notification to user
  switch (errorCauseType) {
    case "expected":
      showErrorNotification({
        errorType: "expected",
        errorTitle: errorMessageConfig?.errorTitle,
        userInstructions: errorMessageConfig?.errorUserInstructions,
      });
      break;
    case "network":
      showErrorNotification({ errorType: "network" });
      break;
    case "operational":
    default:
      showErrorNotification({
        errorType: "operational",
        errorTitle: errorMessageConfig?.errorTitle,
        userInstructions: errorMessageConfig?.errorUserInstructions,
        sentryEventId,
      });
  }
  // Show notification to developer
  if (isDevelopmentEnvironment) {
    notification.error({
      message: "On Dev Environment Only",
      description: (
        <ErrorCodeBlock
          showCopyButton={true}
          errorMessage={e instanceof Error ? e.message : String(e)}
        />
      ),
    });
  }
};

export default handleRequestError;
