import dayjs from "dayjs";
import React, { FC, useEffect, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useSearchParams } from "react-router-dom";
import CustomImage from "src/common/components/general/images/Image";
import Icon from "src/common/components/general/Icon";
import {
  IconArrowDown,
  IconArrowRight,
  IconCertificate,
  IconCheck,
} from "@tabler/icons";
import { UniversalOrientationCompletionPageQuery } from "src/common/types/generated/relay/UniversalOrientationCompletionPageQuery.graphql";
import getUniqueCertsList from "../../../utils/getUniqueCertsList";
import { QRCodeCanvas } from "qrcode.react";
import { DEFAULT_LANGUAGE } from "../../../../../utility-features/i18n/languages";
import stringToLanguage from "../../../../../utility-features/i18n/language-utils/stringToLanguage";
import useLangStrings from "../../../../../utility-features/i18n/context/languageHooks";
import firebase from "firebase/compat/app";
import { auth } from "src/common/functions/firebase";
import { browserSessionPersistence } from "firebase/auth";

export const query = graphql`
  query UniversalOrientationCompletionPageQuery($workerId: uuid!) {
    user_connection(where: { id: { _eq: $workerId } }) {
      edges {
        node {
          reference_number
          name
          lang
          profile_picture {
            url
          }
          universal_orientations(
            limit: 1
            order_by: { universal_orientated_at: desc }
          ) {
            universal_orientated_at
          }
          worker {
            subcontractor {
              name
            }
            worker_title {
              translation {
                en
              }
            }
            worker_role {
              value
            }
            worker_certifications(order_by: { certification: { name: asc } }) {
              certification_id
              issued_on
              created_at
              certification {
                name
              }
              images(order_by: { description: desc }, limit: 2) {
                id
              }
              expires_on
            }
          }
        }
      }
    }
  }
`;

// reg - after registration
// slides - atter slides
// inp - after inperson signature (should add button to return back)
type CompletionType = "reg" | OrientationType;

const CertName: FC<{
  cert: {
    name: string;
    expires_on?: string | null;
    created_at: string;
    issued_on?: string | null;
  };
  certId: string;
}> = ({ cert, certId }) => {
  return (
    <div className="flex mt-0.5" key={certId}>
      {cert.name}
      <div>
        {cert.expires_on && (
          <>
            {dayjs(cert.expires_on).isBefore(dayjs()) ? (
              <>
                {" "}
                - <span className="text-semantic-negative">expired</span>
              </>
            ) : dayjs(cert.expires_on).isBefore(dayjs().add(30, "days")) ? (
              <>
                {" "}
                - <span className="text-orange-dark">expiring soon</span>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </div>
  );
};
type UniversalOrientationCompletionPageProps = {
  workerId: string;
  loginCode: string;
};
const UniversalOrientationCompletionPage: React.FC<
  UniversalOrientationCompletionPageProps
> = ({ workerId, loginCode }) => {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    auth.setPersistence(browserSessionPersistence);
  }, []);
  console.log(workerId);
  const data = useLazyLoadQuery<UniversalOrientationCompletionPageQuery>(
    query,
    {
      workerId: workerId,
    },
  );

  const userData = data.user_connection.edges[0]?.node;
  if (!userData) {
    throw new Error("Worker does not exists");
  }
  const langStrings = useLangStrings(
    stringToLanguage(userData.lang) ?? DEFAULT_LANGUAGE,
  );
  const workerData = userData.worker;
  const [expand, setExpand] = useState(false);
  if (!userData || !workerData)
    return <div className="text-center mt-4">Worker Not Found</div>;
  // TODO: we can move logic from OrinationCompleted (inplace orientation) here and leave only one page.
  const orientationValid =
    userData.universal_orientations[0]?.universal_orientated_at &&
    dayjs(userData.universal_orientations[0].universal_orientated_at)
      .add(1, "year")
      .isSameOrAfter(dayjs());

  const certs = getUniqueCertsList([...workerData.worker_certifications]);
  const first2Certs: typeof certs = [];
  if (certs[0]) first2Certs.push(certs[0]);
  if (certs[1]) first2Certs.push(certs[1]);
  return (
    <div
      className={`p-1.5 ${
        orientationValid ? "bg-semantic-positive-green" : "bg-semantic-negative"
      } text-white min-h-full`}
    >
      <div className="w-8 h-8">
        <CustomImage
          transparentBackground
          src={
            "https://firebasestorage.googleapis.com/v0/b/siteform-3170b.appspot.com/o/assets%2Fthe-associated-general-contractors-of-america-agc-logo.png?alt=media&token=8f003458-4383-4a1a-b0aa-a9322d3adfd0"
          }
        />
      </div>
      <div className={`flex flex-col justify-center items-center`}>
        <p className="text-3">Universal Orientation</p>
        <div className="flex flex-row">
          {userData.profile_picture && (
            <div className="w-12 h-12 border-white border-1 border-solid rounded-0.25">
              <CustomImage src={userData.profile_picture.url} />
            </div>
          )}
          <div className="ml-0.75">
            <div className="text-2">{userData.name}</div>
            <div className="text-1.5">
              {workerData.subcontractor?.name ?? ""}
            </div>
            <div className="text-1">
              {workerData.worker_title?.translation?.en ?? ""}
            </div>
          </div>
        </div>
        <div className="flex flex-row mt-1">
          <div className="ml-0.75 text-white mt-0.5">
            {orientationValid && (
              <Icon icon={IconCheck} color="white" size="xl" />
            )}
          </div>

          <div className="text-1.5 ml-2">
            Universal Orientation Video(12 mo)
            {!!userData.universal_orientations[0]?.universal_orientated_at && (
              <div className="text-1">
                valid through{" "}
                {dayjs(
                  userData.universal_orientations[0]?.universal_orientated_at,
                ).format("MMMM D, YYYY")}
              </div>
            )}
          </div>
        </div>
        {workerData.worker_certifications.length > 0 && (
          <div className="flex flex-row mt-1">
            <div className="ml-0.75 text-white mt-0.5">
              <Icon icon={IconCertificate} color="white" size="xl" />
            </div>

            <div className="ml-2">
              <div className="text-1.25">
                {first2Certs.map((cert, i) => (
                  <CertName {...{ certId: cert.id, cert }} />
                ))}
              </div>
              {certs.length > 2 && (
                <span
                  className="flex text-white"
                  onClick={() => setExpand((i) => !i)}
                >
                  <Icon icon={expand ? IconArrowDown : IconArrowRight} />{" "}
                  {expand ? "-" : "+"} {certs.length - 2} more
                </span>
              )}
              <div className="text-1.25">
                {expand && (
                  <>
                    {certs.map(
                      (cert, i) =>
                        i >= 2 && <CertName {...{ certId: cert.id, cert }} />,
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        <br />
        {userData.reference_number && (
          <>
            <div
              className={
                "inline-block items-center mt-2 justify-center border-0.125 text-center border-semantic-negative-dark border-solid bg-white"
              }
            >
              <QRCodeCanvas
                className="w-full h-full inset-0 cursor-pointer"
                value={`${document.location.origin}/singInLink/${loginCode}?reference=${userData.reference_number}`}
                // id={downloadableFileName}
                bgColor={`transparent`}
                style={{
                  width: `100%`,
                  height: `100%`,
                }}
              />
            </div>
            <div>
              <div className="text-semantic-inactive-dark text-center">
                {userData.reference_number}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UniversalOrientationCompletionPage;
