import { useEffect } from "react";
import handleRequestError from "./handleRequestError";
import { isDevelopmentBuild } from "../../common/constants/environment";

/**
 * This hook is used to handle unhandled exceptions and promise rejections.
 *
 * It's based on useEffect.
 *
 * It tracks the error and shows a notification to the user.
 */

const useUnhandledException = () => {
  useEffect(() => {
    const unhandledRejectionHandler = (event: PromiseRejectionEvent) => {
      handleRequestError(event.reason);
      console.error(
        "Unhandled rejection (promise: ",
        event.promise,
        ", reason: ",
        event.reason,
        ").",
      );
    };
    const errorHandler = (event: ErrorEvent) => {
      if (isDevelopmentBuild) {
        const exceptionStack = new Error().stack;
        const ignoreError =
          exceptionStack &&
          exceptionStack.indexOf("invokeGuardedCallbackDev") > 0;
        if (ignoreError) {
          return;
        }
      }
      handleRequestError(event.error);
      console.error(
        "Unhandled Error (message: ",
        event.message,
        ", filename: ",
        event.filename,
        ", line: ",
        event.lineno,
        ", col: ",
        event.colno,
        ").",
      );
    };

    window.addEventListener("unhandledrejection", unhandledRejectionHandler);
    window.addEventListener("error", errorHandler);
    return () => {
      window.removeEventListener(
        "unhandledrejection",
        unhandledRejectionHandler,
      );
      window.removeEventListener("error", errorHandler);
    };
  }, []);
};

export default useUnhandledException;
