import React, { Suspense, useContext, useState } from "react";
import CompleteInPersonOrientation from "./CompleteInPersonOrientation";
import ErrorMessage from "../../../../../../../../../utility-features/error-handling/components/ErrorMessage";
import SiteOrientationInPersonWorkerRegistrationStepsCompletedUI from "./SiteOrientationInPersonWorkerRegistrationStepsCompletedUI";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLanguage } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerRegistrationStepsCompletedProps {
  onDoneClick?: () => void;
  managerDevice?: boolean;
}

const SiteOrientationInPersonWorkerRegistrationStepsCompleted: React.FC<
  SiteOrientationInPersonWorkerRegistrationStepsCompletedProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();
  const projectId = workerContext.projectId;
  const userId = workerContext.orientee?.id;
  const signatureImageUrl = workerContext.orientee?.signatureImageUrl;
  const [completeOrientationLoading, setCompleteOrientationLoading] =
    useState<boolean>(true);
  const language = useCurrentLanguage();
  const [animationFinished, setAnimationFinished] = useState(false);
  const workerCanLeaveWhenPrompted =
    (workerContext.registrationOptions.hasUniversal
      ? workerContext.orientee?.universalCompleted
      : workerContext.orientee?.corporateOrientationPassed) || false;
  if (!projectId || !userId || !signatureImageUrl) {
    return <ErrorMessage />;
  }

  return (
    <SiteFeatureStepLayout
      loading={completeOrientationLoading}
      nextButtonDisabled={!animationFinished}
      onNextButtonClick={props.onDoneClick}
      nextButtonText={`Done`}
    >
      <>
        <Suspense fallback={null}>
          <CompleteInPersonOrientation
            {...{
              userId,
              projectId,
              workerContext,
              signatureImageUrl,
              onFinish: () => {
                setCompleteOrientationLoading(false);
              },
            }}
          />
        </Suspense>

        <div className={`flex flex-col justify-center min-h-full`}>
          <SiteOrientationInPersonWorkerRegistrationStepsCompletedUI
            workerCanLeaveWhenPrompted={workerCanLeaveWhenPrompted}
            language={language}
            managerDevice={props.managerDevice}
            hasUniversal={workerContext.registrationOptions.hasUniversal}
            onAnimationFinished={() => {
              setAnimationFinished(true);
            }}
          />
        </div>
      </>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerRegistrationStepsCompleted;
