import React, { useState } from "react";
import Invoice from "./Invoice";
import { InvoiceData } from "./types";

interface InvoiceListProps {
  invoices: InvoiceData[];
}

const InvoicesList: React.FC<InvoiceListProps> = (props) => {
  const [selectedInvoice, setSelectedInvoice] = useState<string | null>(null);
  return (
    <div className="flex flex-col gap-1">
      {props.invoices.map((invoice) => (
        <Invoice
          key={invoice.id}
          expanded={selectedInvoice === invoice.id}
          onClick={() => {
            setSelectedInvoice((prevState) => {
              if (prevState === invoice.id) {
                return null;
              } else {
                return invoice.id;
              }
            });
          }}
          invoice={invoice}
        />
      ))}
    </div>
  );
};

export default InvoicesList;
