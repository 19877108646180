export interface ActionButtonProps {
  label: string;
  onClick: () => void;
}

const ActionButton = (props: ActionButtonProps) => {
  return (
    <div
      className="hover:text-interactive-primary py-0.25 px-0.75 text-1 cursor-pointer"
      onClick={() => props.onClick()}
    >
      {props.label}
    </div>
  );
};

export default ActionButton;
