import React from "react";
import Separator from "src/common/components/general/Separator";
import ImageAutomaticSize from "src/common/components/general/images/ImageAutomaticSize";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import remToPx from "src/common/functions/remToPx";
import { SiteInspectionStepProps } from "src/domain-features/siteinspection/utils/siteInspectionTypes";

import { useCurrentLangStrings } from "../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteInspectionWorkerViewStepsHelloProps
  extends SiteInspectionStepProps {
  showLangSelector?: boolean;
  projectName?: string;
  gcName?: string;
  gcLogoUrl?: string | null;
}

const SiteInspectionWorkerViewStepsHello: React.FC<
  SiteInspectionWorkerViewStepsHelloProps
> = (props) => {
  const langStrings = useCurrentLangStrings();
  return (
    <SiteFeatureStepLayout
      onNextButtonClick={props.onNext}
      nextButtonText={langStrings.strings.begin}
    >
      <div className={`flex min-h-full flex-col justify-center`}>
        <div className={`flex flex-col gap-2`}>
          <div>
            <p className={`text-3 tablet-portrait:text-4 font-accent block`}>
              {langStrings.strings.inspection}
            </p>
            <p className={`text-1.5 tablet-portrait:text-2 font-accent block`}>
              {langStrings.strings.startAnInspection}
            </p>
          </div>
          <div className={`flex flex-col gap-0.5`}>
            <div className={`flex flex-col gap-0.25`}>
              {props.gcLogoUrl ? (
                <ImageAutomaticSize
                  perfectImageSidePx={remToPx(6)}
                  imageProps={{ src: props.gcLogoUrl }}
                />
              ) : (
                <p className={`text-1.5`}>{props.gcName}</p>
              )}
            </div>
            <Separator />
            <p className={`font-accent`}>{props.projectName}</p>
          </div>
        </div>
      </div>
    </SiteFeatureStepLayout>
  );
};

export default SiteInspectionWorkerViewStepsHello;
