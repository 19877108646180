import NestedRoute from "../../../../common/types/manual/NestedRoute";
import { Outlet } from "react-router-dom";
import searchParamsProvider from "../../../../common/functions/searchParamsProvider";
import SiteBoardMainEntryView from "./SiteBoardMainEntryView";
import React from "react";
import permitsRoute from "./permits/permitsRoute";
import onsiteRoute from "./onsite/onsiteRoute";
import deliveriesRoute from "./deliveries/deliveriesRoute";

const mainEntryRoute: NestedRoute = {
  element: <Outlet />,
  children: [
    {
      path: "permits",
      ...permitsRoute,
    },
    {
      path: "deliveries",
      ...deliveriesRoute,
    },
    {
      path: "onsite",
      ...onsiteRoute,
    },
    {
      path: "",
      element: searchParamsProvider(SiteBoardMainEntryView),
    },
  ],
};

export default mainEntryRoute;
