import { Form, Modal, Select } from "antd";
import React, { useMemo } from "react";
import { GetGeneralContractorEmployeesQuery } from "src/common/types/generated/apollo/graphQLTypes";

interface AddProjectEmployeeValues {
  employeeUid: string;
}

interface CollectionCreateFormProps {
  visible: boolean;
  onCreate: (values: AddProjectEmployeeValues) => void;
  onCancel: () => void;
  gcEmployees: GetGeneralContractorEmployeesQuery["general_contractor_employee"];
}

const AddProjectEmployeeModal: React.FC<CollectionCreateFormProps> = ({
  visible,
  onCreate,
  onCancel,
  gcEmployees,
}) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const showGC = useMemo(() => {
    return gcEmployees.some(
      (v) => v.general_contractor.id !== gcEmployees[0]?.general_contractor.id,
    );
  }, [gcEmployees]);

  return (
    <Modal
      open={visible}
      title="Add an employee to project"
      okText="Add"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values as AddProjectEmployeeValues);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="employeeUid"
          label="Employee"
          rules={[{ required: true, message: "Choose an employee" }]}
        >
          <Select style={{ width: "100%" }}>
            {gcEmployees.map((gce) => (
              <Select.Option key={gce.uid} value={gce.uid}>
                {gce.user.name}, {gce.employee_title?.name.en}
                {showGC ? ` [${gce.general_contractor.name}]` : ""}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddProjectEmployeeModal;
