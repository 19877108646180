import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import ProjectWorkerDescription from "src/common/components/projectWorkerDescription/ProjectWorkerDescription";
import AnonProjectWorkerTable from "src/common/components/tables/AnonProjectWorkerTable";
import WorkerCertificationTable from "src/common/components/tables/WorkerCertificationTable";
import WorkerDrugTestTable from "src/common/components/tables/WorkerDrugTestTable";
import noop from "src/common/functions/noop";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";

import { GCWorkerProfileQuery } from "src/common/types/generated/relay/GCWorkerProfileQuery.graphql";
const query = graphql`
  query GCWorkerProfileQuery(
    $projectId: uuid!
    $projectWorkerId: uuid!
    $order: order_by!
  ) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name
          perform_drugtest
          drugtest_validity_days
          require_drugtest_upload
          general_contractor_id
          general_contractor {
            name
          }
        }
      }
    }
    project_worker_connection(where: { id: { _eq: $projectWorkerId } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          worker_id
          worker {
            subcontractor_id
            user {
              name
              project_privacy_setting {
                manager
              }
            }
            processing_cert_count: certificates_to_verify(
              where: {
                verified_at: { _is_null: true }
                document: { _eq: "certificate" }
                status: { _eq: "submitted" }
              }
              order_by: { id: $order }
            ) {
              id
            }
          }
        }
      }
    }
  }
`;
interface GCWorkerProfileProps {
  projectId: string;
  projectWorkerId: string;
}

const GCWorkerProfile: React.FunctionComponent<GCWorkerProfileProps> = ({
  projectId,
  projectWorkerId,
}) => {
  const [order, setOrder] = useState(Order_By.Asc);
  const data = useLazyLoadQuery<GCWorkerProfileQuery>(query, {
    projectId,
    projectWorkerId,
    order,
  });
  const projectData = data.project_connection.edges[0].node;
  const gcId = projectData.general_contractor_id;
  const project_worker = data.project_worker_connection.edges[0].node;
  const user = project_worker.worker.user;
  if (typeof projectId != "string") {
    return <>Wrong Url</>;
  }
  const setProcessingOrder = () => {
    if (order === Order_By.Asc) setOrder(Order_By.AscNullsLast);
    else setOrder(Order_By.Asc);
  };
  const subcontractorId = project_worker.worker.subcontractor_id;
  return projectWorkerId &&
    data.project_worker_connection.edges?.[0].node?.worker_id ? (
    <div className="w-full h-full overflow-y-auto">
      <div className="flex flex-col gap-2">
        <ProjectWorkerDescription
          allowChangeCompany
          projectId={projectId}
          workerId={project_worker.worker_id}
          projectWorkerId={projectWorkerId}
        />
        <WorkerCertificationTable
          loadAll
          editable
          title="Certifications"
          headerContol={() => (
            <div>
              Processing Certificates:{" "}
              {project_worker.worker.processing_cert_count.length}
            </div>
          )}
          where={{
            worker: {
              worker_projects: {
                id: {
                  _eq: projectWorkerId,
                },
              },
            },
          }}
          insertModalProps={{
            projectWorkerId: projectWorkerId,
            byWorker: false,
            projectId,
            workerName: project_worker.worker.user.name,
            setProcessingOrder,
          }}
          onRowClick={noop}
        />
        <WorkerDrugTestTable
          loadAll
          where={{
            project_id: { _eq: projectId },
            worker: { worker_projects: { id: { _eq: projectWorkerId } } },
          }}
          includeImages={
            projectData.perform_drugtest && projectData.require_drugtest_upload
          }
          insertModalProps={{
            workerId: project_worker.worker_id,
            subcontractorId,
            gcProps: {
              projectId: projectId,
              includeImages:
                projectData.perform_drugtest &&
                projectData.require_drugtest_upload,
              performingDrugtest: projectData.perform_drugtest,
              projectDrugtestValidityDays: projectData.drugtest_validity_days,
            },
          }}
          onRowClick={noop}
        />
        {(!user.project_privacy_setting ||
          user.project_privacy_setting.manager) && (
          <>
            <AnonProjectWorkerTable
              loadAll
              title={"Worker projects under Current Subcontractor"}
              excludedKeys={["subcontractor", "name", "crew", "corporateDate"]}
              where={{
                project: { general_contractor_id: { _eq: gcId } },
                deleted_at: {
                  _is_null: true,
                },
                worker: {
                  worker_projects: {
                    id: {
                      _eq: projectWorkerId,
                    },
                  },
                },
                subcontractor_worker: {},
              }}
              onRowClick={noop}
            />
            <AnonProjectWorkerTable
              loadAll
              title={"Total Worker Projects"}
              excludedKeys={["name", "crew", "corporateDate"]}
              where={{
                project: { general_contractor_id: { _eq: gcId } },
                worker: {
                  worker_projects: {
                    id: {
                      _eq: projectWorkerId,
                    },
                  },
                },
              }}
              onRowClick={noop}
            />
          </>
        )}
      </div>
    </div>
  ) : (
    <h1>The worker wasn't found</h1>
  );
};

export default GCWorkerProfile;
