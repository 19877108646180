import React, { useContext, useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import {
  EthnicityType,
  SiteOrientationStepProps,
} from "../../../../../../../utils/siteOrientationTypes";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import { Select } from "antd";
import { ethnicityOptions } from "src/domain-features/siteorientation/utils/demographicQuestionOptions";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsEthnicityProps
  extends SiteOrientationStepProps {}

const SiteOrientationInPersonWorkerStepsEthnicity: React.FC<
  SiteOrientationInPersonWorkerStepsEthnicityProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();
  const user = workerContext.orientee;
  const defaultEthnicity: EthnicityType =
    user && user.userType === "worker" && user.ethnicity
      ? user.ethnicity.new ?? user.ethnicity.old
      : null;

  const [ethnicity, setEthnicity] = useState<EthnicityType>(defaultEthnicity);
  const langStrings = useCurrentLangStrings();
  const onEnter = async (pickedVal?: string) => {
    workerContext.updateOrientee((u) =>
      u?.userType === "worker"
        ? {
            ...u,
            ethnicity: { old: u.ethnicity?.old, new: pickedVal ?? ethnicity },
          }
        : null,
    );
    props.onNext();
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={() => {
        !!ethnicity && onEnter(ethnicity);
      }}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!ethnicity}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.whatIsYourethnicity}
      >
        <Select
          value={ethnicity ?? undefined}
          onChange={(e) => {
            setEthnicity(e);
            onEnter(e);
          }}
          placeholder={langStrings.strings.selectFromTheList}
        >
          {ethnicityOptions.map((ethnicity) => (
            <Select.Option value={ethnicity} key={ethnicity}>
              {langStrings.options.ethnicityOptions[ethnicity]}
            </Select.Option>
          ))}
        </Select>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerStepsEthnicity;
