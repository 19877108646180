import NestedRoute from "src/common/types/manual/NestedRoute";
import { Outlet } from "react-router-dom";
import SCReportsDaily from "./SCReportsDaily";
import React from "react";
import settingsRoute from "./routes/settings/settingsRoute";

const dailyRoute: NestedRoute = {
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <SCReportsDaily />
    },
    {
      path: "settings",
      ...settingsRoute
    }
  ]
};

export default dailyRoute;