import { InfoCircleTwoTone } from "@ant-design/icons";
import { Button, Card, Modal, Space } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateNewOrientationModal from "src/common/components/dialogs/CreateNewOrientationModal";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import StyledContent from "src/common/components/layouts/StyledContent";
import { auth } from "src/common/functions/firebase";
import { useSiteOrientationGcDashboardDataQuery$data } from "src/common/types/generated/relay/useSiteOrientationGcDashboardDataQuery.graphql";
import SettingsQrCodes from "./components/SettingsQrCodes";
import OrientationTable from "./components/OrientationTable";
import { yearsWithEmployerOptions } from "src/domain-features/siteorientation/utils/demographicQuestionOptions";
import GCUsersToBeNotifiedForRejectedCerts from "./components/GCUsersToBeNotifiedForRejectedCerts";
import SiteOrientationSettingSwithCards from "./components/SiteOrientationSettingSwitchCards";
import GCUserOACCompanyTable from "./components/GCUserOACCompanyTable";
import WorkerRegistrationQuestionsModal from "./components/WorkerRegistrationQuestionsModal";
import GCProjectLinking from "./components/GCProjectLinking";
import { useUpdateProjectSettingsMutationReturningValues } from "src/common/api/relay/mutationHooks/useUpdateProjectSettings";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";

interface GCProjectOrientationSettingsProps {
  projectId: string;
  data: useSiteOrientationGcDashboardDataQuery$data;
}

const GCProjectOrientationSettings: FC<GCProjectOrientationSettingsProps> = ({
  projectId,
  data,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#table") {
      scrollRef.current?.scrollIntoView(true);
    }
  }, [location]);
  const { userData } = useUserData();
  const [showCreateModal, setShowCreateModal] = useState(false);
  // const [insertingOrientation, setInsertingOrientation] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [openQuestions, setOpenQuestions] = useState(false);

  const projectData = (data.project_connection.edges || [])[0]?.node;
  if (!projectData) {
    throw new Error(`Project is not found`);
  }
  const userId = auth.currentUser?.uid;
  if (!userId) {
    throw new Error(`Orientation Settings user is not signed in`);
  }

  const gcId = projectData.general_contractor_id;

  const orientations = (data.orientation_connection.edges || []).map((v) => ({
    ...v.node,
    key: v.node.pk,
  }));
  const allProjectOACCompanies: { id: string; name: string }[] = [];
  data.project_oac_company_connection.edges.forEach((poc) => {
    if (
      allProjectOACCompanies.findIndex((allPoc) => {
        return allPoc.id == poc.node.oac_company_id;
      }) == -1
    ) {
      allProjectOACCompanies.push({
        id: poc.node.oac_company_id,
        name: poc.node.oac_company.name,
      });
    }
  });

  const currentProjectOACCompanies: string[] = [];
  data.project_oac_company_connection.edges
    .filter((poc) => {
      return poc.node.project_id == projectId;
    })
    .forEach((poc) => {
      if (
        currentProjectOACCompanies.findIndex((currPoc) => {
          return currPoc == poc.node.oac_company_id;
        }) == -1
      ) {
        currentProjectOACCompanies.push(poc.node.oac_company_id);
      }
    });
  const params: useUpdateProjectSettingsMutationReturningValues = {
    ask_gender: projectData.ask_gender,
    ask_age: projectData.ask_age,
    ask_ethnicity: projectData.ask_ethnicity,
    ask_veteran_type: projectData.ask_veteran_type,
    ask_residence: projectData.ask_residence,
    ask_emergency_contact: projectData.ask_emergency_contact,
    ask_number_of_years_in_construction:
      projectData.ask_number_of_years_in_construction,
    ask_years_with_employer: projectData.ask_years_with_employer,
    next_hard_hat_number: projectData.next_hard_hat_number,
    worker_scan_in_person_qr_code: projectData.worker_scan_in_person_qr_code,
    in_person_orientation: projectData.in_person_orientation,
    assign_hard_hat: projectData.assign_hard_hat,
    require_certification_upload: projectData.require_certification_upload,
    perform_drugtest: projectData.perform_drugtest,
    automatically_assign_hard_hat: projectData.automatically_assign_hard_hat,
    require_profile_photo: projectData.require_profile_photo,
    drugtest_validity_days: projectData.drugtest_validity_days ?? undefined,
    require_drugtest_upload: projectData.require_drugtest_upload,
    drug_test_retest_days: projectData.drug_test_retest_days,
    add_hard_hat_during_registration:
      projectData.add_hard_hat_during_registration,
    send_email_for_drug_test_worker_arrival:
      projectData.send_email_for_drug_test_worker_arrival,
    show_onsite_for_not_oriented_workers:
      projectData.show_onsite_for_not_oriented_workers,
    send_verification_invite: projectData.send_verification_invite,
    require_phone: projectData.require_phone,
    require_dob: projectData.require_dob,
    background_check_tracker: projectData.background_check_tracker,
    required_quiz_percentage: projectData.required_quiz_percentage,
    union_labor: projectData.union_labor,
    orientation_tv_player: projectData.orientation_tv_player,
    add_hard_hat_during_in_person: projectData.add_hard_hat_during_in_person,
    require_certs: projectData.require_certs,
  };
  const isHigherLevelAdmin = !!(
    projectData.gc_business_unit?.gc_business_unit_employees_aggregate.aggregate
      ?.count ||
    projectData.gc_office?.gc_office_employees_aggregate.aggregate?.count ||
    projectData.gc_division?.gc_division_employees_aggregate.aggregate?.count ||
    userData.employee?.is_corporate_admin
  );
  return (
    <div>
      {projectData.orientation_project_id === projectId ? (
        <div className="w-full h-full overflow-y-auto">
          <Modal
            open={openInfo}
            onCancel={() => setOpenInfo(false)}
            onOk={() => setOpenInfo(false)}
          >
            <div className="ml-0.5 mr-0.5">
              Understand the Diversity and Demographic of your workers by
              selecting from a list of questions. During registration, workers
              will be required to answer each question.
              <br />
              <br />
              Note: this information is private and will only be made available
              to you and your team at a macro level. Think charts, graphs, and
              percentages.
              <br />
              <br />
              These questions are not editable. Please contact your SiteForm
              representative for more information
            </div>
          </Modal>
          <WorkerRegistrationQuestionsModal
            key={`qm_${openQuestions}`} // use key to reset state in modal
            visible={openQuestions}
            projectData={projectData}
            projectId={projectId}
            params={params}
            onClose={() => setOpenQuestions(false)}
          />
          {/* <Card
        title={"QR Codes"}
        bodyStyle={{ display: "flex", columnGap: "10px" }}
      > */}

          <SettingsQrCodes projectId={projectId} data={data} params={params} />

          {/* </Card> */}
          <Card
            title={
              <div className="font-accent">
                Worker Diversity questions during Registration &nbsp;&nbsp;
                <InfoCircleTwoTone
                  size={200}
                  onClick={() => setOpenInfo(true)}
                />
              </div>
            }
          >
            <StyledContent>
              <div className="font-accent text-1 mb-1.5">
                Selected Registration Questions
              </div>
              {projectData.ask_gender && (
                <div className="mb-1 text-0.9">
                  <span className="font-accent">Gender</span>
                  <div className="w-full ml-1">
                    What is your gender?
                    <div className="text-static-secondary">
                      Possible answers are: Male; Female
                    </div>
                  </div>
                </div>
              )}
              {projectData.ask_ethnicity && (
                <div className="mb-1 text-0.9">
                  <span className="font-accent">Race</span>
                  <div className="w-full ml-1">
                    What is your race?
                    <div className="text-static-secondary">
                      Possible answers are: American Indian or Alaska Native;
                      Asian; Black or African American; Cape Verdean; Hispanic,
                      Latino or Spanish; Native Hawaiian and Pacific Islander;
                      White or Non Hispanic; Some Other Race
                    </div>
                  </div>
                </div>
              )}
              {projectData.ask_veteran_type && (
                <div className="mb-1 text-0.9">
                  <span className="font-accent">Veteran</span>
                  <div className="w-full ml-1">
                    Are you a Veteran?
                    <div className="text-static-secondary">
                      Possible answers are: Non-Veteran; Veteran, Disabled
                      Veteran
                    </div>
                  </div>
                </div>
              )}
              {!(
                projectData.require_dob ||
                projectData.general_contractor.require_dob
              ) &&
                projectData.ask_age && (
                  <div className="mb-1 text-0.9">
                    <span className="font-accent">Age</span>
                    <div className="w-full ml-1">
                      What is your age?
                      <div className="text-static-secondary">Enter Age</div>
                    </div>
                  </div>
                )}
              {projectData.ask_residence && (
                <div className="mb-1 text-0.9">
                  <span className="font-accent">Residence</span>
                  <div className="w-full ml-1">
                    What city or zip code do you live in?
                    <div className="text-static-secondary">Select the city</div>
                  </div>
                </div>
              )}
              {projectData.ask_emergency_contact && (
                <div className="mb-1 text-0.9">
                  <span className="font-accent">Emergency Contact</span>
                  <div className="w-full ml-1">
                    What is your emergency contact?
                    <div className="text-static-secondary">
                      Enter Emergency contact
                    </div>
                  </div>
                </div>
              )}
              {projectData.ask_number_of_years_in_construction && (
                <div className="mb-1 text-0.9">
                  <span className="font-accent">Years In Construction</span>
                  <div className="w-full ml-1">
                    How many years have you been in Construction work?
                    <div className="text-static-secondary">
                      Select number of years you have been in construction work
                    </div>
                  </div>
                </div>
              )}
              {projectData.ask_years_with_employer && (
                <div className="mb-1 text-0.9">
                  <span className="font-accent">Years with Employer</span>
                  <div className="w-full ml-1">
                    How many years have you been employed with this company?
                    <div className="text-static-secondary">
                      Select a time range, e.g.{" "}
                      {yearsWithEmployerOptions.map((y, i) =>
                        i === 0 ? y : ", " + y,
                      )}
                    </div>
                  </div>
                </div>
              )}
              <Button
                type="primary"
                className="font-accent rounded-2"
                onClick={() => setOpenQuestions(true)}
              >
                Add Registration Questions +{" "}
              </Button>
            </StyledContent>
          </Card>

          <CreateNewOrientationModal
            visible={showCreateModal}
            projectId={projectId}
            gcProjectIds={projectData.general_contractor.gc_projects.map(
              (p) => p.project_id,
            )}
            gcId={gcId}
            onSuccess={(orientationId) => {
              setShowCreateModal(false);
              navigate(
                `/gce/projects/${projectId}/orientation/slides/${orientationId}`,
              );
            }}
            onCancel={() => setShowCreateModal(false)}
          />
          <div ref={scrollRef} className="p-1" id="table">
            <OrientationTable
              isHigherLevelLogin={isHigherLevelAdmin}
              hasUniversal={projectData.agc_universal_orientation}
              title={() => (
                <Space direction="vertical" className="text-1.25">
                  <h3>Orientations</h3>
                  <Button
                    type="primary"
                    className="rounded-2 font-accent"
                    onClick={() => {
                      setShowCreateModal(true);
                    }}
                  >
                    Add Orientation
                  </Button>
                </Space>
              )}
              hasInPerson={projectData.in_person_orientation}
              projectId={projectId}
              dataSource={orientations}
              onRow={(item: any) => {
                const orientationId = item.pk;
                navigate(
                  `/gce/projects/${projectId}/orientation/slides/${orientationId}`,
                );
              }}
            />
          </div>
          <Card title={"Orientation Settings"}>
            <SiteOrientationSettingSwithCards
              projectId={projectId}
              params={params}
              allGCUsers={projectData.project_employees}
              currentlySelectedUserToSendEmailForFailedQuiz={data.quiz_failure_email_project_user.edges.map(
                (epu) => ({ id: epu.node.user_id, name: epu.node.user.name }),
              )}
              currentlySelectedUserToSendEmailForDTWorker={data.worker_with_drug_test_arrival_email_project_user.edges.map(
                (epu) => ({ id: epu.node.user_id, name: epu.node.user.name }),
              )}
              currentlySelectedUsersToVerifyNewUsers={
                data.user_to_verify_new_users_connection.edges
              }
              allCertificates={data.certification_connection.edges}
              currentlySelectedCertificates={projectData.project_certifications}
            />
          </Card>
          <GCUsersToBeNotifiedForRejectedCerts
            userIdsToBeNotified={data.user_notification_connection.edges}
            projectId={projectId}
            allGCUsers={
              data.project_connection.edges[0]?.node.project_employees
            }
          />
          <GCUserOACCompanyTable
            projectId={projectId}
            gcId={projectData.general_contractor_id}
            allProjectOACCompanies={allProjectOACCompanies}
            currentProjectOACCompanies={currentProjectOACCompanies}
          />
          <GCProjectLinking
            projectId={projectId}
            params={params}
            currentLinkedProjects={data.orientation_project_options.edges
              .filter((p) => p.node.orientation_project_id === projectId)
              .map((p) => ({
                name: p.node.name,
                id: p.node.pk,
              }))}
            orientationProjectOptions={data.orientation_project_options.edges
              .filter((p) => p.node.linked_orientation_projects.length <= 1)
              .map((p) => ({
                name: p.node.name,
                id: p.node.pk,
              }))}
          />
        </div>
      ) : (
        <div className="flex flex-col gap-1 mb-1">
          <div className="text-2">Orientation Settings</div>
          <div className="mb-1">
            This project's orientation is linked to another project. To change
            or update Orientation Settings follow this{" "}
            <a
              className="text-interactive-primary font-accent"
              href={`${document.location.origin}/gce/projects/${projectData.orientation_project_id}/orientation/settings`}
            >
              link
            </a>{" "}
            to access the other project. If you do not have access to the Linked
            Project, see your project SiteForm Champion for help.
          </div>
        </div>
      )}
    </div>
  );
};

export default withCustomSuspense(GCProjectOrientationSettings);
