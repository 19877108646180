import { IconArrowLeft } from "@tabler/icons";
import { Button } from "antd";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "src/common/components/general/Button";
import VisitorOrientationOrienteeContext from "src/domain-features/sitesafety/entryRoutes/gcProjectReports/routes/visitor/utils/visitorOrientationOrienteeContext";
import useSiteVisitor from "../utils/siteVisitorHook";

export interface VisitorTnCProps {
  gcName: string;
  onBack?: () => void;
}
const VisitorTnC: React.FC<VisitorTnCProps> = (props) => {
  const visitorContext = useSiteVisitor();
  return (
    <div className="flex justify-center mt-4 flex-col items-center">
      <div>
        <h2>{props.gcName + " Visitor Waiver"}</h2>
      </div>
      <div className="my-2">
        <div
          dangerouslySetInnerHTML={{ __html: visitorContext.waiverText ?? "" }}
        ></div>
      </div>
      <div className="absolute bottom-1 w-full pl-2 pr-2">
        <Button onClick={props.onBack} block>
          Back
        </Button>
      </div>
    </div>
  );
};

export default VisitorTnC;
