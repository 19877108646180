import React from "react";
import { Modal } from "antd";
import { Link } from "react-router-dom";

interface Props {
  visible: boolean;
  onCancel: () => void;
}

const TermsOfUseModal: React.FC<Props> = ({ visible, onCancel }) => {
  const handleCancel = () => {
    onCancel();
  };
  return (<>
    <Modal open={visible} onCancel={handleCancel} footer={null}>
      <html>
        <div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "25px",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            Terms of Use
          </div>
          1.) I agree to SiteForm Inc's{" "}
          <a
            href="https://www.termsfeed.com/terms-conditions/f73fc5afe6a821990e96daebc0b83d39"
            type="primary"
            style={{ color: "#1890FF", textDecorationLine: "underline" }}
            target="_blank"
          >
            Terms Of Service
          </a>
          <br />
          2.) I agree to hold SiteForm, Inc. harmless from any and all
          liability, responsibility, claims, actions, suits, charges and
          judgements whatsoever that arise out of Siteform, Inc.'s
          performance, nonperformance, errors and inaccuracies in the process
          of transposing content from your JHA to SiteForm, Inc. database and
          into your JHA Library.
          <br />
          3.) It is my responsibility to review each JHA for accuracy and make
          any necessary changes to my JHA before using them to Pre-Task Plan.
          <br />
          4.) I am authorized by my company to accept these terms and
          conditions.
          <br />
          5.) I agree that SiteForm is simply a software to submit my
          information and is not responsible for validating the accuracy of
          any information I enter into it.
        </div>
      </html>
    </Modal>
  </>);
};

export default TermsOfUseModal;
