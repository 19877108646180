import { FilterRecord } from "../../ScrollTable";
import arrayDateRangeFilter from "./arrayDateRangeFilter";
import arrayInFilter from "./arrayInFilter";
import arraySearchFilter from "./arraySearchFilter";
import { FilterFunction } from "../dataScrollTableTypes";

const arrayGetFilterFunc = ({
  dataIndex,
  filter,
}: FilterRecord): FilterFunction => {
  switch (filter.type) {
    case "in_list":
      return arrayInFilter(dataIndex, filter);
    case "date_range":
      return arrayDateRangeFilter(dataIndex, filter);
    case "search":
      return arraySearchFilter(dataIndex, filter);
  }
};
export default arrayGetFilterFunc;
