import { IconArrowBack } from "@tabler/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import Button, { ButtonProps } from "./Button";

export interface BackButtonProps extends Pick<ButtonProps, "fullWidth"> {
  url?: string;
}

const BackButton: React.FC<BackButtonProps> = (props) => {
  const navigate = useNavigate();
  return (
    <Button
      {...{
        label: "Back",
        onClick: () => {
          if (props.url) {
            navigate(props.url);
          } else {
            navigate(-1);
          }
        },
        icon: IconArrowBack,
        ...props,
      }}
    />
  );
};

export default BackButton;
