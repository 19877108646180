import { DatePicker } from "antd";
import React, { useRef } from "react";
import dayjs from "dayjs";

export type RangeType = [dayjs.Dayjs | null, dayjs.Dayjs | null];

export interface CustomTableDateRangePickerProps {
  rangeValue?: RangeType;
  onChange: (range: RangeType | null) => void;
}

const CustomTableDateRangePicker: React.FC<CustomTableDateRangePickerProps> = (
  props,
) => {
  const datePickerRef = useRef<any>(null);

  return (
    <DatePicker.RangePicker
      ref={datePickerRef}
      style={{ float: "right" }}
      value={props.rangeValue || undefined}
      allowEmpty={[true, true]}
      presets={[
        {
          label: "Today",
          value: [dayjs().startOf("day"), dayjs().endOf("day")],
        },
        {
          label: "This week",
          value: [dayjs().startOf("week"), dayjs().endOf("week")],
        },
        {
          label: "This month",
          value: [dayjs().startOf("month"), dayjs().endOf("month")],
        },
        {
          label: "Last month",
          value: [
            dayjs().subtract(1, "month").startOf("month"),
            dayjs().subtract(1, "month").endOf("month"),
          ],
        },
      ]}
      onChange={(newRange) => {
        props.onChange(newRange);
        datePickerRef.current && datePickerRef.current.blur();
      }}
    />
  );
};

export default CustomTableDateRangePicker;
