import React, { FC, useMemo } from "react";
import TableTitle from "src/common/components/tables/basic/TableTitle";
import useColumnOrderBy from "src/common/hooks/useColumnOrderBy";
import useColumnSearch from "src/common/hooks/useColumnSearch";
import usePaginatedQuery, {
  UsePaginatedQueryParams,
} from "src/common/hooks/usePaginatedQuery";
import {
  General_Contractor_Employee,
  General_Contractor_Employee_Bool_Exp,
  General_Contractor_Employee_Order_By,
  GetGeneralContractorEmployeesDocument,
  Order_By,
} from "src/common/types/generated/apollo/graphQLTypes";
import GeneralContractorEmployeesColumns from "./Columns";

interface TableProps {
  onClickRow: (dr: Partial<General_Contractor_Employee>) => void;
  skipKeys?: Array<string>;
  titleComponent?: any;
  gcId: string;
  queryRef?: (tableRef: any) => any;
  onFirstNameSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkProp?: string;
}

const GeneralContractorEmployeesTable: FC<TableProps> = ({
  onClickRow,
  skipKeys = [],
  titleComponent,
  gcId,
  queryRef,
  checkProp,
}) => {
  const { orderBy, setOrderBy } =
    useColumnOrderBy<General_Contractor_Employee_Order_By>({
      user: {
        name: Order_By.AscNullsLast,
      },
    });
  const nameSearch = useColumnSearch();

  const whereObject = useMemo(() => {
    const where: General_Contractor_Employee_Bool_Exp = {
      general_contractor_id: { _eq: gcId },
      user: {
        name: {
          _ilike: checkProp,
        },
      },
    };
    return where;
  }, [checkProp]);

  const queryVariables = useMemo(
    (): UsePaginatedQueryParams<General_Contractor_Employee_Bool_Exp> => ({
      where: whereObject,
      orderBy: orderBy,
      queryDoc: GetGeneralContractorEmployeesDocument,
      dataIndex: "general_contractor_employee",
      aggregateCountIndex: "general_contractor_employee_aggregate",
    }),
    [whereObject, orderBy]
  );

  const [employees, { loading, page, setPage, count, pageSize, refetch }] =
    usePaginatedQuery<Partial<General_Contractor_Employee>>(queryVariables);

  if (queryRef) {
    queryRef({
      refetch,
    });
  }

  return (
    <GeneralContractorEmployeesColumns
      skipKeys={skipKeys}
      // searchColumns={{ name: nameSearch.setter }}
      sortColumns={{
        name: true,
      }}
      title={() => (
        <TableTitle titleComponent={titleComponent} title="Employees" />
      )}
      dataSource={employees}
      loading={loading}
      onChange={(_, filter, sorter) => {
        setOrderBy(sorter);
      }}
      onRow={(dr) => ({
        onClick: () => onClickRow(dr),
      })}
      pagination={{
        current: page,
        pageSize,
        total: count,
        onChange: setPage,
      }}
    />
  );
};

export default GeneralContractorEmployeesTable;
