import { AntCloudOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Form,
  Modal,
  Upload,
  notification,
  message,
  Input,
  DatePicker,
  Select,
} from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, useState } from "react";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { uploadFilesWithPath } from "src/common/functions/uploadFiles";
import useAuthUser from "src/common/hooks/useAuthUser";
// import {
//   UploadToSiteFormSdsModalMutation,
//   UploadToSiteFormSdsModalMutation$data,
// } from "src/common/types/generated/relay/UploadToSiteFormSdsModalMutation.graphql";

import {
  UploadSdsModalMutation,
  UploadSdsModalMutation$data,
} from "src/common/types/generated/relay/UploadSdsModalMutation.graphql";
import * as uuid from "uuid";
import { safety_data_sheet_insert_input } from "src/common/types/generated/relay/VerifyAndAddSDS_Insert_SDS_Mutation.graphql";
import getNormalOptionsFilter from "src/common/functions/getNormalOptionsFilter";

interface UploadToSiteFormSdsModalProps {
  visible: boolean;
  subcontractorId: string | null;
  onClose: () => void;
  subAdmins?: Array<{ name: string; id: string }>;
  onSubmit: () => void;
  projectId?: string | null;
  workers?: Array<{ name: string | undefined; id: string }>;
  setSdsList?: React.Dispatch<
    React.SetStateAction<safety_data_sheet_insert_input[]>
  >;
  requestId?: string;
  projectSubcontractorData?: Array<{ id: string; name: string }>;
  uploadedByUserId?: string;
}

type FormValues = {
  numberOfSds: number;
  productName: string;
  companyName: string;
  issueDate: dayjs.Dayjs;
  referenceNumber: string;
  upload: { fileList: Array<{ originFileObj: File }> };
  subcontractor?: string;
};
const UploadSdsModal: FC<UploadToSiteFormSdsModalProps> = ({
  visible,
  subcontractorId,
  onClose,
  onSubmit,
  projectId,
  setSdsList,
  requestId,
  projectSubcontractorData,
  uploadedByUserId,
}) => {
  const authUser = useAuthUser();
  const [loading, setLoading] = useState(false);
  const [uploadSds, isUploading] = useAsyncMutation<UploadSdsModalMutation>(
    graphql`
      mutation UploadSdsModalMutation(
        $object: safety_data_sheet_insert_input!
      ) {
        insert_safety_data_sheet_one(object: $object) {
          ...SdsFrag @relay(mask: false)
        }
      }
    `,
  );
  const [form] = Form.useForm();
  const handleInsertSafetyDataSheetsUpdater = (
    store: RecordSourceSelectorProxy<UploadSdsModalMutation$data>,
  ) => {
    const insertSafetyDataSheets = store.getRootField(
      "insert_safety_data_sheet_one",
    );
    const conn1 = ConnectionHandler.getConnection(
      store.getRoot(),
      "SCSds_safety_data_sheet_connection",
    );
    const conn2 = ConnectionHandler.getConnection(
      store.getRoot(),
      "ProjectSubcontractorSdsTable_safety_data_sheet_connection",
    );
    const conn3 = ConnectionHandler.getConnection(
      store.getRoot(),
      "SubProjectMobilizationView_safety_data_sheet_connection",
    );
    if (conn1 && insertSafetyDataSheets) {
      const edge = store.create(uuid.v4(), "edge");
      edge.setLinkedRecord(insertSafetyDataSheets, "node");
      ConnectionHandler.insertEdgeAfter(conn1, edge);
    }
    if (conn2 && insertSafetyDataSheets) {
      const edge = store.create(uuid.v4(), "edge");
      edge.setLinkedRecord(insertSafetyDataSheets, "node");
      ConnectionHandler.insertEdgeAfter(conn2, edge);
    }
    if (conn3 && insertSafetyDataSheets) {
      const edge = store.create(uuid.v4(), "edge");
      edge.setLinkedRecord(insertSafetyDataSheets, "node");
      ConnectionHandler.insertEdgeAfter(conn3, edge);
    }
  };

  return (
    <Modal
      open={visible}
      confirmLoading={loading}
      title={
        <div>
          Upload your SDS to SiteForm
          <br />
        </div>
      }
      okText="ADD"
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={async () => {
        setLoading(true);
        await form
          .validateFields()
          .then(async (v: FormValues) => {
            console.log(v);
            const frontFiles = await uploadFilesWithPath(
              v.upload.fileList.map((o) => ({
                file: o.originFileObj,
                directoryName: "SDS File",
              })),
            );
            !!setSdsList
              ? setSdsList((prev) => [
                  ...prev,
                  {
                    id: uuid.v4(),
                    project_id: projectId,
                    created_by_uid: uploadedByUserId,
                    subcontractor_id: subcontractorId ?? v.subcontractor,
                    product_name: v.productName,
                    company_name: v.companyName,
                    request_id: requestId,
                    file_type: "pdf",
                    url: frontFiles[0]?.url,
                    issue_date: v.issueDate
                      ? dayjs(v.issueDate).toISOString()
                      : null,
                    reference_number: v.referenceNumber,
                  },
                ])
              : await uploadSds({
                  variables: {
                    object: {
                      id: uuid.v4(),
                      project_id: projectId,
                      created_by_uid: authUser.uid,
                      subcontractor_id: subcontractorId
                        ? subcontractorId
                        : v.subcontractor,
                      product_name: v.productName,
                      request_id: requestId,
                      company_name: v.companyName,
                      file_type: "pdf",
                      url: frontFiles[0]?.url,
                      issue_date: v.issueDate
                        ? dayjs(v.issueDate).toISOString()
                        : null,
                      reference_number: v.referenceNumber,
                    },
                  },
                  updater: handleInsertSafetyDataSheetsUpdater,
                }).then((d) => {
                  if (d.insert_safety_data_sheet_one?.pk) {
                    message.success("Uploaded To SiteForm");
                  }
                });
            form.resetFields();
            onSubmit();
          })
          .catch((e) => {
            notification.error({
              message: "Upload error",
              description: e.message,
              duration: null,
            });
            console.log(e);
          });
        setLoading(false);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ numberOfSds: 1 }}
        title="Upload Form"
      >
        <Form.Item
          className="mt-2"
          label={"Upload the File"}
          name="upload"
          rules={[{ required: true, message: "File is required" }]}
        >
          <Upload.Dragger
            accept=".pdf"
            maxCount={1}
            iconRender={() => <AntCloudOutlined />}
            customRequest={() => true}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p>Click or drag file here to upload</p>
            {/* <div style={{ position: "absolute" ,bottom: "0px"}}>Only upload one SDS at a time</div> */}
          </Upload.Dragger>
        </Form.Item>

        <label className="text-grey">Only upload one SDS at a time</label>

        {projectSubcontractorData && (
          <Form.Item
            className="mt-2"
            label="Subcontractor"
            name="subcontractor"
          >
            <Select
              placeholder={"Select Subcontractor"}
              className="w-70"
              showSearch
              filterOption={getNormalOptionsFilter}
              options={(projectSubcontractorData || []).map((u) => ({
                label: u.name,
                value: u.id,
              }))}
            />
          </Form.Item>
        )}

        <Form.Item
          className="mt-2"
          label="Product Name"
          name="productName"
          rules={[{ required: true, message: "Product name required" }]}
        >
          <Input placeholder="Enter Product Name"></Input>
        </Form.Item>

        <Form.Item
          className="mt-2"
          label="Manufacturer Name"
          name="companyName"
          rules={[{ required: true, message: "Company name required" }]}
        >
          <Input placeholder=" Enter Manufacturer"></Input>
        </Form.Item>

        <Form.Item
          className="mt-2"
          label="Revision or Issue Date"
          name="issueDate"
        >
          <DatePicker
            format="ll"
            disabledDate={(current) => current.isAfter(dayjs().endOf("day"))}
            onChange={async (e) => {}}
          />
        </Form.Item>

        <Form.Item
          className="mt-2"
          label="Reference / Revision / Version #"
          name="referenceNumber"
        >
          <Input placeholder="Enter Reference / Revision #"></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default UploadSdsModal;
