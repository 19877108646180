import * as React from "react";

interface JHAAcceptProps {
  onRowItemClick: () => void;
}

const AcceptTask = (props: JHAAcceptProps) => {
  return (
    <div
      className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
      onClick={() => props.onRowItemClick()}
    >
      Accept JHA
    </div>
  );
};

export default AcceptTask;
