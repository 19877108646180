import {
  ConnectionHandler,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import GetFullID from "src/common/functions/GetFullId";
import * as uuid from "uuid";

const newQRCodeInsertionUpdater = (
  store: RecordSourceProxy,
  prevQROptions: Array<{ value: string }>,
  prevInspectionInstanceQRCodeRecords: RecordProxy<{
    readonly inspection_qr_code_id: string;
    readonly inspection_qr_code: {
      readonly name: string;
      readonly id: string;
      readonly pk: string;
    };
  }>[]
) => {
  const optionsConn = ConnectionHandler.getConnection(
    store.getRoot(),
    "GCInspectionsQuery_inspection_qr_code_connection"
  );
  if (optionsConn) {
    const edge = store.create(uuid.v4(), "edge");
    // when new QR is created update QR options also from query GCInspections
    const newQRRecord = prevInspectionInstanceQRCodeRecords.filter((rec) =>
      prevQROptions.every(
        (qr) =>
          GetFullID("inspection_qr_code", qr.value) !=
          rec.getLinkedRecord("inspection_qr_code").getValue("id")
      )
    );
    if (newQRRecord[0]) {
      edge.setLinkedRecord(
        newQRRecord[0].getLinkedRecord("inspection_qr_code"),
        "node"
      );
      ConnectionHandler.insertEdgeBefore(optionsConn, edge);
    }
  }
};
export default newQRCodeInsertionUpdater;
