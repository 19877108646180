import { gql, InMemoryCache, makeVar } from "@apollo/client";
import langConditional from "src/common/functions/langConditional";
import {
  ObservationEditHistoryFragmentFragment,
  ObservationEditHistoryFragmentFragmentDoc,
  TextTranslationFragFragment,
} from "src/common/types/generated/apollo/graphQLTypes";

const cache = new InMemoryCache({
  // dataIdFromObject: (object) => {
  //   switch (object.__typename) {
  //     case 'daily_report':
  //       return `daily_report:${object.id}`;
  //     default:
  //       return defaultDataIdFromObject(object);
  //   }
  // },
  // cacheRedirects: {
  //   Query: {
  //     daily_report_by_pk: (_, { id }, { getCacheKey }) =>
  //       getCacheKey({ __typename: 'daily_report', id }),
  //     report_by_pk: (_, { id }, { getCacheKey }) =>
  //       getCacheKey({ __typename: 'report', id }),
  //   },
  // },
  typePolicies: {
    worker_role: {
      keyFields: ["value"],
    },
    project_setting: {
      keyFields: ["project_id"],
    },
    observation: {
      fields: {
        observation_edits: {
          merge(
            existing = [],
            incoming: Array<ObservationEditHistoryFragmentFragment>,
          ) {
            const getEditHistory = (cacheId: string) => {
              return cache.readFragment(
                {
                  id: cacheId,
                  fragmentName: "ObservationEditHistoryFragment",
                  fragment: ObservationEditHistoryFragmentFragmentDoc,
                },
                true,
              ) as ObservationEditHistoryFragmentFragment;
            };

            const itemIds = new Set<string>();
            const items = [...existing, ...incoming]
              .filter(({ __ref }) => {
                if (itemIds.has(__ref)) return false;
                itemIds.add(__ref);
                return true;
              })
              .sort((a, b) =>
                new Date(getEditHistory(a.__ref).created_at).getTime() >
                new Date(getEditHistory(b.__ref).created_at).getTime()
                  ? -1
                  : 1,
              );

            return items;
          },
        },
      },
    },
    project_covid_config: {
      keyFields: ["project_id"],
    },
    unsafe_observation: {
      keyFields: ["observation_id"],
    },
    general_contractor_employee: {
      keyFields: ["uid"],
      fields: {
        employee_title: {
          merge: false,
        },
      },
    },
    confined_space_permit: {
      keyFields: ["permit_id"],
    },
    dig_permit: {
      keyFields: ["permit_id"],
    },
    hot_work_permit: {
      keyFields: ["permit_id"],
    },
    work_above_permit: {
      keyFields: ["permit_id"],
    },
    ladder_permit: {
      keyFields: ["permit_id"],
    },
    dig_excavation_permit: {
      keyFields: ["permit_id"],
    },
    interior_penetration_permit: {
      keyFields: ["permit_id"],
    },
    historical_access_permit: {
      keyFields: ["permit_id"],
    },
    loto_permit: {
      keyFields: ["permit_id"],
    },
    steel_erection_permit: {
      keyFields: ["permit_id"],
    },
    out_of_basket_permit: {
      keyFields: ["permit_id"],
    },
    equipment_incident: {
      keyFields: ["incident_id"],
    },
    environmental_incident: {
      keyFields: ["incident_id"],
    },
    near_miss_incident: {
      keyFields: ["incident_id"],
    },
    utility_incident: {
      keyFields: ["incident_id"],
    },
    vehicle_incident: {
      keyFields: ["incident_id"],
    },
    theft_incident: {
      keyFields: ["incident_id"],
    },
    property_damage_incident: {
      keyFields: ["incident_id"],
    },
    worker: {
      keyFields: ["uid"],
    },

    project_employee: {
      fields: {
        employee: {
          merge: false,
        },
      },
    },

    text_translation: {
      fields: {
        clientText: {
          read(_, { readField }) {
            const textTranslation =
              cache.readFragment<TextTranslationFragFragment>(
                {
                  id: `text_translation:${
                    readField({ fieldName: "id" }) as string
                  }`,
                  fragmentName: "TextTranslationGetClientText",
                  fragment: gql`
                    fragment TextTranslationGetClientText on text_translation {
                      id
                      es
                      en
                      pt
                      original
                    }
                  `,
                },
                true,
              );

            if (!textTranslation) return "";

            // TODO
            let clientText = langConditional("en", textTranslation);

            if (textTranslation.original !== "" && clientText === "") {
              clientText = textTranslation.original;
            }

            return clientText;
          },
        },
      },
    },
    Query: {
      fields: {
        userData() {
          return userDataVar();
        },
        role() {
          return roleVar();
        },
        procoreProjectFolders: {
          read(existing = {}, { args }) {
            const key = `${args?.input.general_contractor_id}_${args?.input.procore_project_id}`;
            console.log("CACHE READ ", existing, "p2 = ", args, "key= ", key);
            return existing[key];
          },
          keyArgs: ["procore_project_id", "general_contractor_id"],
          merge(existing = {}, incoming, { args }) {
            const key = `${args?.input.general_contractor_id}_${args?.input.procore_project_id}`;
            console.log(
              "CACHE MERGE ",
              existing,
              incoming,
              "args = ",
              args,
              "key=",
              key,
            );
            const prev = existing[key];
            if (!prev) return { ...existing, [key]: incoming };
            const newChildren = incoming.folders; //.slice(1);
            const newRefs = new Set(
              newChildren.map((x: { __ref: string }) => x.__ref),
            );

            let merged = {
              __typename: incoming.__typename,
              folders: [
                ...prev.folders.filter((item: any) => !newRefs.has(item.__ref)),
                ...newChildren,
              ],
            };
            console.log("merged = ", merged);
            return { ...existing, [key]: merged };
          },
        },
      },
    },
  },
});

export const userDataVar = makeVar<{
  id?: string | null;
  name?: string | null;
}>({
  // MARK cant be undefined initially?
  id: null,
  name: null,
});

export const roleVar = makeVar<string | undefined | null>("none");

// cache.writeData({
//   data: {
//     role: 'none',
//     employeeData: {
//       __typename: 'EmployeeData',
//       user: undefined,
//     },
//     subcontractorData: {
//       __typename: 'SubAdminData',
//       user: undefined,
//     },
//     userData: {
//       __typename: 'user',
//       id: null,
//       name: null,
//     },
//   },
// });

export default cache;
