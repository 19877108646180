import React, { useMemo, useState } from "react";
import { InspectionQRType } from "../tables/InspectionQRTable";
import { Modal, Table, Form, Input, Select, message, notification } from "antd";
import dayjs from "dayjs";
import KeyValuePairDisplayCard from "src/common/components/layouts/KeyValuePairDisplayCard";
import OpenQrCode from "src/common/components/OpenQrCode";
import { InspectionQREditMutation } from "src/common/types/generated/relay/InspectionQREditMutation.graphql";
import iconLogo from "src/common/assets/icon-logo.png";
import Button from "src/common/components/general/Button";
import { IconPencil, IconPlus } from "@tabler/icons";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { graphql } from "babel-plugin-relay/macro";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import { ConnectionHandler } from "relay-runtime";
import useMoveInspectionQRToAnotherProject from "../../utils/useMoveInspectionQRToAnotherProject";

interface InspectionQRProps {
  data: InspectionQRType;
  visible: boolean;
  editByDefault?: boolean;
  projectOptions: Array<{ label: string; value: string }>;
  inspectionsToSelect: Array<{ label: string; value: string }>;
  projectId: string;
  onClose: () => void;
}

const InspectionQR: React.FC<InspectionQRProps> = ({
  data,
  editByDefault,
  projectOptions,
  inspectionsToSelect,
  visible,
  projectId,
  onClose,
}) => {
  const objectToDisplay = useMemo(() => {
    const itemMap: { [key: string]: string | undefined } = {
      ["Created by"]: data.created_by_user.name,
      ["Created on"]: dayjs(data.created_at).format("LLL"),
      ["QR Code Name"]: data.name,
    };
    return itemMap;
  }, [data]);
  const [editQR] = useAsyncMutation<InspectionQREditMutation>(
    graphql`
      mutation InspectionQREditMutation(
        $qrId: uuid!
        $newName: String!
        $toInsert: [inspection_instance_qr_code_insert_input!]!
        $toDeleteInstanceQRs: [uuid!]
      ) {
        delete_inspection_instance_qr_code(
          where: {
            inspection_qr_code_id: { _eq: $qrId }
            inspection_instance_id: { _in: $toDeleteInstanceQRs }
          }
        ) {
          returning {
            id
          }
        }
        insert_inspection_instance_qr_code(
          objects: $toInsert
          on_conflict: {
            constraint: inspection_instance_qr_code_inspection_qr_code_id_inspection_in
            update_columns: []
          }
        ) {
          affected_rows
        }
        update_inspection_qr_code_by_pk(
          pk_columns: { id: $qrId }
          _set: { name: $newName }
        ) {
          name
          id
          inspection_qr_code_instances(
            where: { inspection_instance: { deleted_at: { _is_null: true } } }
          ) {
            id
            inspection_instance {
              pk: id @__clientField(handle: "pk")
              deleted_at
              id
              inspection_results_aggregate {
                aggregate {
                  count
                }
              }
              name {
                en
              }
            }
          }
        }
      }
    `,
  );
  const [editing, setEditing] = useState(editByDefault);
  const [move, setMove] = useState(false);
  const [moveQRToAnotherProject] = useMoveInspectionQRToAnotherProject();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onSave = async () => {
    if (editing) {
      setLoading(true);
      try {
        const values = await form.validateFields();
        if (!values?.name) {
          throw new Error("name cannot be empty");
        }
        const [toInsertInstanceQRs, toDeleteInstanceQRs] = compareTwoLists(
          values.inspections ?? [],
          data.inspection_qr_code_instances.map(
            (p) => p.inspection_instance.pk,
          ),
        );
        await editQR({
          variables: {
            newName: values.name,
            qrId: data.pk,
            toInsert: toInsertInstanceQRs.map((instId) => ({
              inspection_instance_id: instId,
              inspection_qr_code_id: data.pk,
            })),
            toDeleteInstanceQRs,
          },
          updater: (store) => {
            const conn = ConnectionHandler.getConnection(
              store.getRoot(),
              "InspectionQRTable_inspection_qr_code_connection",
            );
            const updatedqr = store.getRootField(
              "update_inspection_qr_code_by_pk",
            );

            if (conn) {
              const edges = conn.getLinkedRecords("edges");

              if (edges) {
                const edge = edges.find((edge) => {
                  const node = edge.getLinkedRecord("node");
                  return node?.getValue("id") === data.id;
                });
                if (edge) {
                  const node = edge.getLinkedRecord("node");
                  if (node) {
                    node.setValue(updatedqr.getValue("name"), "name");
                    node.setLinkedRecords(
                      updatedqr.getLinkedRecords(
                        "inspection_qr_code_instances",
                        {
                          where: {
                            inspection_instance: {
                              deleted_at: { _is_null: true },
                            },
                          },
                        },
                      ),
                      "inspection_qr_code_instances",
                      {
                        where: {
                          inspection_instance: {
                            deleted_at: { _is_null: true },
                          },
                        },
                      },
                    );
                  }
                }
              }
            }
          },
        });
        onClose();

        message.success("Successfully Edited QR");
      } catch (err) {
        console.error(err);
        notification.error({
          description: "Inspection could not be edited",
          message: err instanceof Error ? err.message : JSON.stringify(err),
        });
      }
      setLoading(false);
    }
    setEditing(false);
  };
  const onMove = async () => {
    if (move) {
      setLoading(true);
      try {
        const values = await form.validateFields();
        if (!values?.project) {
          throw new Error("select a valid project");
        }
        await moveQRToAnotherProject(data.pk, values.project);

        message.success("QR moved successfully");
      } catch (err) {
        console.error(err);
        notification.error({
          description: "QR couldn't be moved",
          message: err instanceof Error ? err.message : JSON.stringify(err),
        });
      }
      setLoading(false);
    }
    setMove(false);
    onClose();
  };
  return (
    <Modal
      title={
        move
          ? "Move Inspection QR Code to Another Project"
          : "Inspection QR Code - " + data.name
      }
      open={visible}
      width={800}
      onCancel={onClose}
      footer={
        <div className="flex justify-between flex-row-reverse">
          <div className="flex gap-1">
            <Button
              label={editing || move ? "Cancel" : "Close"}
              secondary
              onClick={() => {
                if (editing || move) {
                  setEditing(false);
                  setMove(false);
                } else {
                  onClose();
                }
              }}
            />
            <div className={editing || move ? "" : "hidden"}>
              <Button
                label={move ? "Move" : "Save"}
                loading={loading}
                onClick={async () => {
                  if (move) await onMove();
                  else if (editing) await onSave();
                }}
              />
            </div>
          </div>
          {!editing && !move && (
            <Button
              label={move ? "Done" : "Move to Another Project"}
              loading={loading}
              onClick={async () => setMove(true)}
            />
          )}
        </div>
      }
    >
      <div className="items-end flex justify-between mb-1">
        {!move && !editing && (
          <Button
            label={"Edit"}
            loading={loading}
            icon={IconPencil}
            onClick={() => setEditing(true)}
          />
        )}
      </div>
      {editing || move ? (
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            name: data.name,
            inspections: data.inspection_qr_code_instances.map(
              (qrInst) => qrInst.inspection_instance.pk,
            ),
          }}
        >
          {move ? (
            <Form.Item
              name={"project"}
              label="Select the project to move this Inspection QR Code to"
            >
              <Select options={projectOptions}></Select>
            </Form.Item>
          ) : (
            <>
              <Form.Item
                name="name"
                label="QR Code Name"
                rules={[
                  { required: true, message: "Please enter a valid name" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={"inspections"}
                label="Select or remove the inspections you want to be shown when this QR code is scanned"
              >
                <Select mode="multiple" options={inspectionsToSelect}></Select>
              </Form.Item>
            </>
          )}
        </Form>
      ) : (
        <>
          <div className="flex justify-between">
            <KeyValuePairDisplayCard objectToDisplay={objectToDisplay} />
            <div>
              <OpenQrCode
                imageSettings={{
                  src: iconLogo,
                  excavate: true,
                  height: 100,
                  width: 100,
                }}
                alwaysVisible
                downloadableFileName={data.name}
                destinationUrl={`${document.location.origin}/site-inspection/${data.pk}/${projectId}`}
              />
            </div>
          </div>
          <Table
            title={(data) => (
              <div className="text-1 font-accent flex gap-1">
                <div>Linked Inspection(s)</div>
              </div>
            )}
            columns={[
              {
                title: "Name",
                dataIndex: ["inspection_instance", "name", "en"],
                key: "name",
              },
              {
                title: "Time Completed",
                dataIndex: [
                  "inspection_instance",
                  "inspection_results_aggregate",
                  "aggregate",
                  "count",
                ],
                key: "completed",
              },
            ]}
            dataSource={[...data.inspection_qr_code_instances]}
            footer={() => {
              return (
                <Button
                  label={"Add"}
                  secondary
                  loading={loading}
                  icon={IconPlus}
                  onClick={() => setEditing(true)}
                />
              );
            }}
          />
        </>
      )}
    </Modal>
  );
};
export default InspectionQR;
