import React from "react";
export const colorList = [
  "#0087ff",
  "#FF7D5E",
  "#45FFBE",
  "#87C6FA",
  "#FFF266",
  "#FA5044",
  "#FAD7A0",
  "#DCA7FA",
  "#FF5F5D",
  "#3F7C85",
  "#00CCBF",
  "#72F2EB",
  "#747E7E",
  "#662400",
  "#B33F00",
  "#006663",
  "#00B3AD",
  "#FF6B1A",
  "#146152",
  "#A584FF",
  "#214001",
  "#D96941",
  "#193C40",
  "#A62B1F",
  "#2E5902",
  "#F23030",
  "#F29F05",
  "#267365",
  "#F2CB05",
  "#F28705",
  "#730202",
  "#BF7069",
  "#BF1304",
  "#025159",
  "#F23005",
  "#383416",
  "#634A00",
  "#0C7D74",
  "#D9B29C",
  "#04BF8A",
  "#BF5A36",
  "#D9C45B",
  "#F2BB13",
  "#D94423",
  "#0D0D0D",
  "#1FAFBF",
  "#3876F2",
  "#3084F2",
  "#B6CAF2",
  "#D7D7D9",
  "#BF7839",
  "#9ABBD9",
  "#8FAFD9",
  "#735114",
  "#517360",
  "#8C8984",
  "#732F29",
  "#8C2727",
  "#2C4001",
  "#D9863D",
  "#888C18",
  "#8C3B0D",
  "#26150B",
  "#37A65E",
  "#735848",
  "#025920",
  "#2168A6",
  "#D9CF43",
  "#6FA8BF",
  "#A61C4F",
  "#A9C6D9",
  "#BF913B",
];
export default colorList;
