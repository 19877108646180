/**
 * @generated SignedSource<<9a89eb5ceee02df442917d9d0bf948be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NewSubForm_InsertSubMutation$variables = {
  name?: string | null | undefined;
  subcontractor_ein?: string | null | undefined;
  trade_id: string;
};
export type NewSubForm_InsertSubMutation$data = {
  readonly insert_subcontractor_one: {
    readonly id: string;
    readonly pk: string;
  } | null | undefined;
};
export type NewSubForm_InsertSubMutation = {
  response: NewSubForm_InsertSubMutation$data;
  variables: NewSubForm_InsertSubMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subcontractor_ein"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "trade_id"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "subcontractor_ein",
        "variableName": "subcontractor_ein"
      },
      {
        "kind": "Variable",
        "name": "trade_id",
        "variableName": "trade_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "object"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewSubForm_InsertSubMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "subcontractor",
        "kind": "LinkedField",
        "name": "insert_subcontractor_one",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewSubForm_InsertSubMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "subcontractor",
        "kind": "LinkedField",
        "name": "insert_subcontractor_one",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2f4ec8b64c86417dae2139d5ce288ac8",
    "id": null,
    "metadata": {},
    "name": "NewSubForm_InsertSubMutation",
    "operationKind": "mutation",
    "text": "mutation NewSubForm_InsertSubMutation(\n  $name: String\n  $trade_id: uuid!\n  $subcontractor_ein: String\n) {\n  insert_subcontractor_one(object: {name: $name, trade_id: $trade_id, subcontractor_ein: $subcontractor_ein}) {\n    id\n    pk: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "674b6fe9b858463347c2523669d6b108";

export default node;
