import React, { forwardRef } from "react";
import * as Sentry from "@sentry/react";
import ErrorBoundary from "./ErrorBoundary";

const withCustomErrorFallback = <Props,>(
  Component: React.ComponentType<Props> | React.FC<Props>,
  fallback: Sentry.ErrorBoundaryProps["fallback"]
) =>
  forwardRef((props: Props, ref) => {
    return (
      <ErrorBoundary fallback={fallback}>
        <Component {...props} ref={ref} />
      </ErrorBoundary>
    );
  });

export default withCustomErrorFallback;
