import React from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import {
  GetGcOfficesDocument,
  GetGcOfficesQuery,
  useInsertGcOfficeMutation,
  GetGcOfficesQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSuspenseQuery } from "@apollo/client";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import useAuthUser from "src/common/hooks/useAuthUser";
import GCLevelsList from "../component/GCLevelsList";
import { useNavigate } from "react-router-dom";

const GCOffices: React.FC = () => {
  const { userData } = useUserData();
  const employee = userData.employee;
  if (!employee) throw new Error("Loggedā in user is not employee");

  const authUser = useAuthUser();
  const { data, refetch } = useSuspenseQuery<
    GetGcOfficesQuery,
    GetGcOfficesQueryVariables
  >(GetGcOfficesDocument, {
    variables: {
      includeTableData: false,
      officeWhere: {
        general_contractor_id: { _eq: employee.general_contractor_id },
        ...(employee.is_corporate_admin
          ? {}
          : {
              _or: [
                { gc_office_employees: { user_id: { _eq: authUser.uid } } },
                {
                  gc_business_unit: {
                    gc_business_unit_employees: {
                      user_id: { _eq: authUser.uid },
                    },
                  },
                },
                {
                  gc_division: {
                    gc_division_employees: { user_id: { _eq: authUser.uid } },
                  },
                },
              ],
            }),
      },
      buWhere: {
        general_contractor_id: { _eq: employee.general_contractor_id },
        ...(employee.is_corporate_admin
          ? {}
          : {
              _or: [
                {
                  gc_division: {
                    gc_division_employees: { user_id: { _eq: authUser.uid } },
                  },
                },
                {
                  gc_business_unit_employees: {
                    user_id: { _eq: authUser.uid },
                  },
                },
              ],
            }),
      },
      divisionWhere: {
        general_contractor_id: { _eq: employee.general_contractor_id },
        ...(employee.is_corporate_admin
          ? {}
          : { gc_division_employees: { user_id: { _eq: authUser.uid } } }),
      },
    },
  });
  const [insertOffice, { loading }] = useInsertGcOfficeMutation();
  const navigate = useNavigate();
  return (
    <GCLevelsList
      insertModalProps={{
        inserting: loading,
        buSelectOptions: data.gc_business_unit,
        buLevelName: employee.general_contractor.hierarchy_business_unit_name,
        divisionLevelName: employee.general_contractor.hierarchy_division_name,
        divisionSelectOptions: data.gc_division,
        onInsert: async (vals) => {
          const { data: insertedOff } = await insertOffice({
            variables: {
              object: {
                name: vals.name,
                ...(vals.businessUnitId
                  ? { gc_business_unit_id: vals.businessUnitId }
                  : {}),
                ...(vals.divisionId ? { gc_division_id: vals.divisionId } : {}),
                logo_url: vals.uploadedLogoUrl,
                general_contractor_id: employee.general_contractor_id,
                created_by_uid: authUser.uid,
                address: {
                  data: {
                    line_1: vals.addressLine1,
                    zip_code: vals.zip,
                    city: vals.city,
                    state_code: vals.stateCode,
                  },
                },
              },
            },
          });
          await refetch();
          // if (insertedOff?.insert_gc_office_one)
          //   navigate(
          //     `/gce/offices/${insertedOff.insert_gc_office_one.id}/settings`,
          //   );
        },
      }}
      isCorporateAdmin={employee.is_corporate_admin}
      levelName={employee.general_contractor.hierarchy_office_name || ""}
      selectedIdName="officeId"
      redirectTo="offices"
      levels={data.gc_office}
    />
  );
};

export default withCustomSuspense(GCOffices);
