import { Language } from "./i18nTypes";
import { LangType } from "../../../common/types/generated/apollo/graphQLTypes";

export const languageToLangType = (lang: Language): LangType => {
  switch (lang) {
    case "en":
      return LangType.En;
    case "es":
      return LangType.Es;
    case "fr":
      return LangType.Fr;
    case "it":
      return LangType.It;
    case "pt":
      return LangType.Pt;
    case "zh_hans":
      return LangType.ZhHans;
    default:
      throw new Error(`Unknow lang value = ${lang}`);
  }
};
