import { IconChevronLeft } from "@tabler/icons";
import React from "react";
import Icon from "src/common/components/general/Icon";
import ButtonHuge from "../../../../common/components/general/ButtonHuge";

export interface SiteOrientationSlidesViewerBackButtonProps {
  onClick: () => void;
}

const SiteOrientationSlidesViewerBackButton: React.FC<
  SiteOrientationSlidesViewerBackButtonProps
> = (props) => {
  return (
    <ButtonHuge
      type={`primary`}
      icon={<Icon icon={IconChevronLeft} color="white" />}
      onClick={props.onClick}
    >
      Back
    </ButtonHuge>
  );
};

export default SiteOrientationSlidesViewerBackButton;
