import {
  IconX,
  IconLockOpen,
  IconEyeOff,
  IconUserMinus,
  IconUserPlus,
  IconDownload,
  IconCopy,
  IconRefresh,
  IconDeviceMobileMessage,
  IconPaperBag,
  IconChefHat,
  IconHammer,
  IconHandGrab,
  IconChefHatOff,
  IconUser,
  IconBriefcase,
  IconCalendar,
} from "@tabler/icons";
import { DatePicker, Divider, Modal, message, Switch, Button } from "antd";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import Project, { ProjectProps } from "src/common/components/Project";
import AddWorkerCertModal, {
  AddWorkerCertModalRef,
} from "src/common/components/dialogs/AddWorkerCertModal";
import AddWorkerDrugTestModal, {
  AddWorkerDrugTestModalDataProps,
  AddWorkerDrugTestModalRef,
} from "src/common/components/dialogs/AddWorkerDrugTestModal";
import Age from "src/common/components/general/Age";
import LoadingContent from "src/common/components/general/LoadingContent";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import ageByBirthDate from "src/common/functions/ageByBirthDate";
import capitalize from "src/common/functions/capitalize";
import getDateStr, { dateFormat } from "src/common/functions/getDateStr";
import Certificate from "./subcontractor/people/workers/worker-profile/Certificate";
import CertificatesProcessing from "./subcontractor/people/workers/worker-profile/CertificatesProcessing";
import ProfileAvatar from "./subcontractor/people/workers/worker-profile/ProfileAvatar";
import WorkerProfileContactLink from "./subcontractor/people/workers/worker-profile/WorkerProfileContactLink";
import WorkerProfileInfoBlock from "./subcontractor/people/workers/worker-profile/WorkerProfileInfoBlock";
import WorkerProfileRole from "./subcontractor/people/workers/worker-profile/WorkerProfileRole";
import WorkerProfileTitle from "./subcontractor/people/workers/worker-profile/WorkerProfileTitle";
import WorkerProfileSubcontractor from "./subcontractor/people/workers/worker-profile/WorkerProfileSubcontractor";
import OrientationInviteModal from "./subcontractor/people/workers/components/OrientationInviteModal";
import { ProjectWorkerTableType } from "src/common/components/tables/ProjectWorkerTable";
import CustomButton from "src/common/components/general/Button";
import { useNavigate, useParams } from "react-router-dom";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import {
  useForgotPasswordMutation,
  useGetWorkerTranscriptPdfMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useBoolean, useCopyToClipboard } from "usehooks-ts";
import Icon from "src/common/components/general/Icon";
import InviteWorkerModal from "src/common/components/projectWorkerDescription/InviteWorkerModal";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { UpdateWorkerAndUserMutation } from "src/common/types/generated/relay/UpdateWorkerAndUserMutation.graphql";
import workerAndUserUpdateMutation from "src/common/api/relay/mutations/UpdateWorkerAndUser";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { EditableHHCell } from "src/common/components/tables/ProjectWorkerTable";

export interface WorkerProfileUIProps {
  type: "gc" | "sub";
  onCrossClick?: () => void;
  workerData: {
    id: string;
    orientationCompleted?: boolean;
    dataId?: string;
    projectWorkerId?: string;
    birthDate?: Date;
    processingCerts: number;
    orientations: { name: string; date: string | undefined }[];
    certificates: {
      expirationDate?: Date;
      imageUrl?: URL;
      name: string;
      id: string;
    }[];
    createdPassword?: boolean;
    allowMobileLogin?: boolean;
    dailyWorkLog: {
      days: number;
      hours: number;
      lastDayOnProject?: string;
    };
    permitsCount?: number;
    reportsCount?: number;
    hardHatNumber?: string | null;
    roleId: string;
    imageUrl?: string;
    isTerminated: boolean;
    name: string;
    showEmail: boolean;
    showPhoneNumber: boolean;
    showProjects: boolean;
    projects: ProjectProps[];
    drugTests: {
      enteredBy: string;
      fromDate: Date;
      status: string;
    }[];
    subcontractorId?: string;
    titleId?: string;
    email?: string;
    phoneNumber?: string;
    hireDate?: Date;
    workerOfAnotherSub?: boolean;
    invites?: { id: string; acceptedAt: string | null }[];
  };
  updateData: {
    loadingStatus: boolean;
    contacts: {
      phoneNumber: (newValue: string) => void;
      email: (newValue: string) => void;
    };
    name: (newName: string) => void;
    title: (newTittleId: string) => void;
    role: (newRoleId: string) => void;
    birthDate: (newBirthDate: dayjs.Dayjs) => void;
    hireDate: (newHireDate: dayjs.Dayjs) => void;
    subcontractor: (subcontractorId: string) => void;
    terminate: (workerId: string) => void; // to terminate a worker
    addBackTerminatedWorker: (workerId: string) => void; //to add back a worker
    refetchProfile: () => void;
    avatarImage: (newUrl: string | null) => void;
    certificate: {
      remove: (certificateId: string) => void;
    };
  };
  workerRoleOptions: { label: string; value: string }[];
  workerTitleOptions: { label: string; value: string }[];
  workerSubOptions?: { label: string; value: string }[];
  drugtestGCProps?: AddWorkerDrugTestModalDataProps["gcProps"];
  tasksSigned: Array<{ id: string; description: string }>;
  loading: boolean;
  subcontractorId: string;
  gcName?: string;
}

const WorkerProfileUI: React.FunctionComponent<
  WorkerProfileUIProps & { projectsToBeAddedTo: { id: string; name: string }[] }
> = ({
  workerData,
  updateData,
  onCrossClick,
  workerRoleOptions,
  workerTitleOptions,
  workerSubOptions,
  loading,
  subcontractorId,
  projectsToBeAddedTo,
  tasksSigned,
  type,
  drugtestGCProps,
  gcName,
}) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const addCertModalRef = useRef<AddWorkerCertModalRef>(null);
  const addDrugTestModalRef = useRef<AddWorkerDrugTestModalRef>(null);
  const [terminateWarningVisible, setTerminateWarningVisible] =
    useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const terminateWorker = () => {
    updateData.terminate(workerData.id);
  };
  const addBackTerminatedWorker = () => {
    updateData.addBackTerminatedWorker(workerData.id);
  };
  const isTerminated = workerData.isTerminated;
  const [forgotPassword] = useForgotPasswordMutation();
  const [editMode, setEditMode] = useState(false);
  const [copiedValue, copy] = useCopyToClipboard();
  const {
    value: inviteModalVisible,
    setFalse: hideInviteModal,
    setTrue: showInviteModal,
  } = useBoolean(false);
  const [lastInvitePk, setLastInvitePK] = useState(
    workerData.invites?.at(0)?.id,
  );
  const sendResetPasswordLink = async () => {
    if (workerData.email) {
      await forgotPassword({
        variables: {
          input: {
            loginIdentifier: workerData.email,
          },
        },
      })
        .then(() => message.success("Sent Successfully"))
        .catch(console.log);
    } else {
      message.error(
        "Email not found, Please enter worker's email to send him the Password Reset Link",
      );
    }
  };

  const [getWorkerTranscriptPdf, { loading: workerTranscriptPdfLoading }] =
    useGetWorkerTranscriptPdfMutation();
  const { confirm } = Modal;

  const showResetPasswordConfirm = () => {
    confirm({
      title: "Send Reset Password Link?",
      okText: "Yes",
      onOk: sendResetPasswordLink,
    });
  };
  const [workerUpdate, isUpdatingWorker] =
    useAsyncMutation<UpdateWorkerAndUserMutation>(workerAndUserUpdateMutation);

  const hideTerminateModal = () => {
    setTerminateWarningVisible(false);
  };
  const openTerminateModal = () => {
    setTerminateWarningVisible(true);
  };
  // const numberOfHours = workerData.dailyWorkWorkers?.reduce(
  //   (sum, hour) => sum + hour.hours,
  //   0,
  // );
  // const lastDayForWorkerOnProject = workerData.dailyWorkWorkers?.length
  //   ? workerData.dailyWorkWorkers?.at(0)?.created_at
  //   : "";

  return loading ? (
    <LoadingContent />
  ) : (
    <div className="flex flex-col p-1 pb-2 margin-gap-y-1 overflow-y-auto min-h-full justify-center">
      <div className="w-full mb-1 h-24">
        <div className="float-right">
          <CustomButton
            icon={IconX}
            tiny
            onClick={() => onCrossClick && onCrossClick()}
          />{" "}
        </div>
        <ProfileAvatar
          imageUrl={workerData.imageUrl}
          onFileUpload={async (imageUrl) =>
            await updateData.avatarImage(imageUrl)
          }
          onFileRemove={async () => {
            await updateData.avatarImage(null);
          }}
        />
      </div>

      <div className="mt-2">
        <CustomButton
          label={editMode ? "Save" : "Edit"}
          tiny
          onClick={() => setEditMode((prev) => !prev)}
        />
      </div>

      <div className="flex flex-row">
        {
          <WorkerProfileContactLink
            currentValue={workerData.name}
            hideCopy
            hideIcon
            type="name"
            disabled={!editMode}
            onUpdate={updateData.name}
          />
        }

        {workerData.orientationCompleted && workerData.projectWorkerId && (
          <span>
            <EditableHHCell
              projectId={projectId ?? ""}
              value={workerData.hardHatNumber ?? null}
              initiallyOpen={!workerData.hardHatNumber || editMode}
              record={{
                id: workerData.projectWorkerId,
                workerId: workerData.id,
              }}
              tableView={false}
            />
          </span>
        )}
      </div>
      <div>
        <WorkerProfileContactLink
          currentValue={workerData.phoneNumber}
          disabled={!editMode}
          type="phone_number"
          onUpdate={updateData.contacts.phoneNumber}
        />

        <WorkerProfileContactLink
          currentValue={workerData.email}
          disabled={!editMode}
          type="email"
          onUpdate={updateData.contacts.email}
        />
      </div>

      <div className="flex flex-col margin-gap-y-0.5">
        <div className="flex flex-col margin-gap-y-1">
          <div className="flex flex-col items-baseline gap-1">
            {workerData.birthDate !== undefined && (
              <Age value={ageByBirthDate(dayjs(workerData.birthDate))} />
            )}
          </div>

          <div className="flex flex-col gap-0.25">
            <div className="text-0.75 text-static-secondary"> Birth date:</div>

            <div className="flex flex-row gap-x-0.5">
              <Icon icon={IconCalendar} color="static" />
              {editMode ? (
                <DatePicker
                  defaultValue={
                    workerData.birthDate
                      ? dayjs(workerData.birthDate)
                      : undefined
                  }
                  maxDate={dayjs()}
                  placeholder={dateFormat.numeric}
                  onChange={(date) => updateData.birthDate(date)}
                />
              ) : (
                dayjs(workerData.birthDate).format("YYYY-MM-DD")
              )}
            </div>
          </div>

          {workerData.projectWorkerId && projectId && (
            <InviteWorkerModal
              key={workerData.projectWorkerId}
              visible={inviteModalVisible}
              onFinish={(inviteId) => {
                hideInviteModal();
                setLastInvitePK(inviteId);
              }}
              onClose={hideInviteModal}
              workerId={workerData.id}
              dataId={workerData.dataId}
              projectId={projectId}
              email={workerData.email}
              phone={workerData.phoneNumber}
            />
          )}

          {workerSubOptions && (
            <WorkerProfileSubcontractor
              options={workerSubOptions}
              editMode={editMode}
              icon={IconBriefcase}
              onOptionSubmit={updateData.subcontractor}
              selectedOptionId={workerData.subcontractorId ?? undefined}
              placeholderText={`Select Subcontractor`}
            />
          )}
          <WorkerProfileRole
            options={workerRoleOptions}
            editMode={editMode}
            icon={IconUser}
            onOptionSubmit={updateData.role}
            selectedOptionId={workerData.roleId}
            placeholderText={`Select a role`}
          />
          <WorkerProfileTitle
            options={workerTitleOptions}
            editMode={editMode}
            icon={IconHammer}
            onOptionSubmit={updateData.title}
            selectedOptionId={workerData.titleId ?? undefined}
            placeholderText={`Select a title`}
          />

          {type == "sub" && (
            <div className="flex flex-col items-start gap-0.25">
              <span className="text-0.75 text-static-secondary">
                Hire date:
              </span>

              {editMode ? (
                <DatePicker
                  placeholder={dateFormat.numeric}
                  defaultValue={
                    workerData.hireDate ? dayjs(workerData.hireDate) : undefined
                  }
                  onChange={(date) => updateData.hireDate(date)}
                />
              ) : (
                dayjs(workerData.hireDate).format("YYYY-MM-DD")
              )}
            </div>
          )}
        </div>

        <div className="flex flex-wrap items-center mt-1 space-x-2">
          <div>
            {(() => {
              if (workerData.createdPassword) {
                return (
                  <div className="flex flex-row items-center gap-0.5 select-none rounded-full bg-suplementary-1 py-0.5">
                    <Icon icon={IconDeviceMobileMessage} />
                    <p>Invitation accepted</p>

                    <CustomButton
                      secondary
                      icon={IconLockOpen}
                      onClick={showResetPasswordConfirm}
                      hint={`Send Reset Password Link`}
                    />
                  </div>
                );
              } else if (workerData.invites?.length === 0) {
                return (
                  <CustomButton
                    secondary
                    label={`Invite`}
                    onClick={showInviteModal}
                  />
                );
              } else if (
                workerData.invites?.length &&
                workerData.invites?.length > 0 &&
                !workerData.invites.at(0)?.acceptedAt
              ) {
                return (
                  <div className="flex flex-row items-center gap-0.5">
                    <div>Invite Link Sent</div>
                    {lastInvitePk && (
                      <CustomButton
                        secondary
                        icon={IconCopy}
                        onClick={() => {
                          copy(
                            `https://app.siteform.net/invite/${lastInvitePk}`,
                          );
                        }}
                        hint={"Copy Invite Link"}
                      />
                    )}
                    <CustomButton
                      secondary
                      icon={IconRefresh}
                      onClick={showInviteModal}
                      hint={`Resend Invite Link`}
                    />
                  </div>
                );
              } else {
                return (
                  <div className="flex flex-row items-center gap-0.5 select-none rounded-full bg-suplementary-1 py-0.5">
                    <Icon icon={IconDeviceMobileMessage} />
                    <p>Invitation accepted</p>
                  </div>
                );
              }
            })()}
          </div>
        </div>
        {workerData?.createdPassword && (
          <div className="flex flex-row gap-x-1 items-center">
            Allow Access to Mobile App:
            <Switch
              defaultChecked={workerData.allowMobileLogin}
              onChange={(checked) =>
                workerUpdate({
                  variables: {
                    user_set: { allow_mobile_login: checked },
                    worker_set: {},
                    workerId: workerData.id,
                  },
                })
              }
            />
          </div>
        )}

        <div className="flex flex-row py-2 gap-x-7">
          <CustomButton
            label={`Transcript`}
            hint={"Download Copy of Worker’s Transcript"}
            secondary
            small
            icon={IconDownload}
            loading={workerTranscriptPdfLoading}
            onClick={async () => {
              if (workerData.id && projectId) {
                await getWorkerTranscriptPdf({
                  variables: {
                    input: {
                      projectId: projectId,
                      workerId: workerData.id,
                    },
                  },
                }).then((data) => {
                  if (data.data?.generateWorkerTranscript) {
                    downloadFromUrl(data.data?.generateWorkerTranscript);
                  }
                });
              }
            }}
          />
        </div>

        <AddWorkerCertModal
          workerId={workerData.id}
          byWorker={false}
          workerName={workerData.name}
          ref={addCertModalRef}
          // onInserted={refetch}
          onInserted={() => {
            console.log("the certificate added");
            updateData.refetchProfile();
          }}
        />
        <WorkerProfileInfoBlock
          title={
            workerData.certificates.length
              ? `Certifications (${workerData.certificates.length})`
              : `No certifications`
          }
          addButton={{
            onClick: () => {
              addCertModalRef.current?.open();
            },
          }}
        >
          {(!!workerData.processingCerts ||
            !!workerData.certificates.length) && (
            <div className="flex flex-col gap-1">
              {workerData.processingCerts > 0 && (
                <CertificatesProcessing count={workerData.processingCerts} />
              )}
              {!!workerData.certificates.length && (
                <div className="flex flex-col gap-1">
                  {workerData.certificates.map(
                    ({ name, expirationDate, imageUrl, id }) => (
                      <Certificate
                        key={id}
                        {...{
                          name,
                          expirationDate,
                          imageUrl,
                          onRemove: () => {
                            updateData.certificate.remove(id);
                          },
                        }}
                      />
                    ),
                  )}
                </div>
              )}
            </div>
          )}
          {
            <div>
              <div className="font-accent mb-0.5">Stats:</div>
              <div>
                On Project:
                <span className="font-accent">
                  {workerData.dailyWorkLog.days +
                    ` days, ` +
                    workerData.dailyWorkLog.hours +
                    ` hours`}
                </span>
              </div>
              <div>
                Last day on project:
                {!workerData.dailyWorkLog.lastDayOnProject ? (
                  " Not present on any day "
                ) : (
                  <span className="font-accent">
                    {" "}
                    {dayjs(workerData.dailyWorkLog.lastDayOnProject).format(
                      " MMM-DD-YYYY",
                    )}
                  </span>
                )}
              </div>

              <div>
                {" "}
                Number of{" "}
                <span className="inline-block">
                  <CustomButton
                    label="PTPs"
                    tiny
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/gce/projects/${projectId}/reports/pretaskplans/?worker=${workerData.projectWorkerId}`,
                        "_blank",
                      )
                    }
                  />
                </span>{" "}
                Included On:{" "}
                <span className="font-accent"> {workerData.reportsCount} </span>
              </div>
              <div>
                Number of Daily Reports Included On:
                <span className="font-accent ml-0.25">
                  {workerData.dailyWorkLog.days}
                </span>
              </div>
              <div>
                Number of{" "}
                <span className="inline-block">
                  <CustomButton
                    label="Permits"
                    tiny
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/gce/projects/${projectId}/reports/permits/?worker=${workerData.projectWorkerId}`,
                        "_blank",
                      )
                    }
                  />
                </span>{" "}
                Included On:{" "}
                <span className="font-accent">{workerData.permitsCount} </span>
              </div>

              <div className="mt-1">
                {tasksSigned.length == 0 ? (
                  <div className="font-accent">No Signed JHAs </div>
                ) : (
                  <div className="font-accent">JHAs Signed:</div>
                )}
                {tasksSigned.map((sign, ind) => (
                  <div
                    key={ind}
                    className="text-semantic-pending mt-0.5 cursor-pointer hover:underline"
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/gce/projects/${projectId}/jha/${subcontractorId}/task/${sign.id}?type=jha_log`,
                        "_blank",
                      )
                    }
                  >
                    {sign.description}
                  </div>
                ))}
              </div>
            </div>
          }
        </WorkerProfileInfoBlock>
        <AddWorkerDrugTestModal
          workerId={workerData.id}
          subcontractorId={subcontractorId}
          gcProps={drugtestGCProps}
          ref={addDrugTestModalRef}
          onInserted={() => {
            console.log("certicate added");
            addDrugTestModalRef.current?.close();
            updateData.refetchProfile();
          }}
        />
        <WorkerProfileInfoBlock
          title={
            workerData.drugTests.length
              ? `Drug tests (${workerData.drugTests.length})`
              : `Add drug test`
          }
          addButton={{
            onClick: () => {
              addDrugTestModalRef.current?.open();
            },
          }}
        >
          {workerData.drugTests.length &&
            workerData.drugTests.map((dt, i) => (
              <div className="flex flex-col gap-0.5" key={i}>
                <div
                  className={
                    dt.status === "positive"
                      ? "text-semantic-negative"
                      : dt.status === "non-negative"
                      ? ""
                      : "text-semantic-positive-green"
                  }
                >
                  {capitalize(dt.status)}
                </div>
                <div className="text-static-primary">{`Entered by ${dt.enteredBy}`}</div>
                <div className="text-static-secondary">{`From ${getDateStr(
                  dt.fromDate,
                )}`}</div>
              </div>
            ))}
        </WorkerProfileInfoBlock>
        <OrientationInviteModal
          visible={openModal}
          reset={() => setOpenModal(false)}
          projects={projectsToBeAddedTo}
          worker={{
            email: workerData.email,
            phone_number: workerData.phoneNumber,
            id: workerData.id,
            titleId: workerData.titleId,
            worker_role: workerData.roleId,
            subcontractorId: subcontractorId,
            refetch: () => updateData.refetchProfile(),
          }}
        />
        <WorkerProfileInfoBlock title="Orientations">
          {workerData.orientations.map((p) => (
            <div key={p.name}>
              {p.name}:{" "}
              <span className="text-grey">{p.date ?? "Not Complete"}</span>
            </div>
          ))}
        </WorkerProfileInfoBlock>

        {type !== "gc" && (
          <CustomButton
            label="Add to Projects +"
            fullWidth
            onClick={() => setOpenModal(true)}
          />
        )}
        <WorkerProfileInfoBlock
          title={
            workerData.projects.length
              ? type == "gc"
                ? ` ${gcName} Projects (${workerData.projects.length})`
                : `Projects (${workerData.projects.length})`
              : `No projects`
          }
        >
          {workerData.projects.map((projectProps) => (
            <Project {...projectProps} key={projectProps.id} />
          ))}
        </WorkerProfileInfoBlock>
        <Modal
          open={terminateWarningVisible}
          onOk={() => {
            if (isTerminated) {
              addBackTerminatedWorker();
            } else terminateWorker();
            hideTerminateModal();
          }}
          onCancel={hideTerminateModal}
          okText="Confirm"
          cancelText="Cancel"
        >
          <div>
            This workers is under another subcontractor, adding him back will
            remove him from his current subcontractor.
          </div>
        </Modal>
        {type == "sub" && (
          <CustomButton
            {...{
              onClick: () => {
                if (workerData.workerOfAnotherSub) {
                  openTerminateModal();
                  return;
                }
                if (isTerminated) {
                  addBackTerminatedWorker();
                  return;
                }
                terminateWorker();
              },
              loading: loading,
              icon: isTerminated ? IconUserPlus : IconUserMinus,
              fullWidth: true,
              label: isTerminated ? "Re-employed" : "No longer employed",
              secondary: true,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default withCustomSuspense(WorkerProfileUI);
