import React from "react";
import GCProjectTeamListItem, {
  GCProjectTeamListItemProps,
} from "./GCProjectTeamListItem";

const textData = {
  noUsers: `No team member was found`,
};

export interface GCProjectTeamListProps {
  gcUsers: Array<GCProjectTeamListItemProps>;
  subs?: Array<{ name: string; id: string }>;
  title?: string;
  onRemoveFromTeam?: (userId: string) => Promise<void>;
}

const GCProjectTeamList: React.FC<GCProjectTeamListProps> = (props) => {
  return (
    <div className={`flex flex-col gap-0.5`}>
      {props.title && <span className="block font-accent">{props.title}</span>}
      <div className="flex flex-col gap-px max-w-48">
        {!!props.gcUsers.length ? (
          props.gcUsers.map((gcTeamMember) => (
            <GCProjectTeamListItem
              {...gcTeamMember}
              onRemoveFromTeam={props.onRemoveFromTeam}
            />
          ))
        ) : (
          <></>
          // <p className="text-center">{textData.noUsers}</p>
        )}
      </div>
    </div>
  );
};

export default GCProjectTeamList;
