import { useParams } from "react-router-dom";
import { auth } from "src/common/functions/firebase";

export interface OfficeProps {
  officeId: string;
}

const withOfficeId =
  <Props extends object>(Component: React.FC<Props & OfficeProps>) =>
  (props: Props) => {
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error("Undeifned user id");
    const { officeId } = useParams();
    if (!officeId) throw new Error("officeId not found");
    return <Component officeId={officeId} {...props} />;
  };

export default withOfficeId;
