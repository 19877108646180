import React, { createContext } from "react";
import {
  OacUserType,
  SiteOrientationInPersonRegistrationOptionsType,
  UserType,
  WorkerType,
} from "./siteOrientationTypes";
import { OrientationType } from "src/common/types/manual/OrientationType";

export type SiteOrientationOrienteeContextType = {
  projectId: string;
  updateSelectedUserId: React.Dispatch<React.SetStateAction<string | null>>;
  updateOrientee: React.Dispatch<
    React.SetStateAction<OacUserType | WorkerType | UserType | null>
  >;
  orientee?: OacUserType | WorkerType | UserType;
  registrationOptions: SiteOrientationInPersonRegistrationOptionsType;
  type: OrientationType;
} | null;

const SiteOrientationOrienteeContext =
  createContext<SiteOrientationOrienteeContextType>(null);

export default SiteOrientationOrienteeContext;
