import { useParams } from "react-router-dom";
import { auth } from "src/common/functions/firebase";

export interface DivisionProps {
  divisionId: string;
}

const withDivisionId =
  <Props extends object>(Component: React.FC<Props & DivisionProps>) =>
  (props: Props) => {
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error("Undeifned user id");
    const { divisionId } = useParams();
    if (!divisionId) throw new Error("divisionId not found");
    return <Component divisionId={divisionId} {...props} />;
  };

export default withDivisionId;
