/**
 * @file environment.ts
 *
 * This file is responsible for determining the environment and build type of the application.
 *
 * @constant {string} appEnvironmentType - Holds the value of the `REACT_APP_ENV` environment variable.
 * It represents the environment type of the application.
 * It should be included in the `.env` file.
 * E.g. if you test locally or on the dev server, the value should be `dev`.
 * @constant {string} appBuildType - Holds the value of the `NODE_ENV` environment variable.
 * It represents the build type of the application.
 * It's added by React by default.
 * If you test locally using "npm start", the value should be `development`. Otherwise, it's "production".
 */

const environmentTypes = ["dev", "prod", "hs"] as const;
type AppEnvironmentType = (typeof environmentTypes)[number];

let appEnvTypeLocal: AppEnvironmentType | undefined = undefined;

const reactAppEnv = process.env.REACT_APP_ENV;
if (
  typeof reactAppEnv === "string" &&
  environmentTypes.some((envType) => envType === reactAppEnv)
) {
  appEnvTypeLocal = reactAppEnv as AppEnvironmentType;
}

// Environment Constants
const appEnvironmentType = appEnvTypeLocal;

export const appBuildType = process.env.NODE_ENV;

// Environment Checks
export const isProductionEnvironment = appEnvironmentType === "prod";
export const isDevelopmentEnvironment = appEnvironmentType === "dev";
export const isHighSecurityEnvironment = appEnvironmentType === "hs";

// Build Checks
export const isProductionBuild = appBuildType === "production";
export const isDevelopmentBuild = appBuildType === "development";
