import { graphql } from "babel-plugin-relay/macro";
import { useRef, useState } from "react";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { WorkAboveElevationFloor_FloorInsert_Mutation } from "src/common/types/generated/relay/WorkAboveElevationFloor_FloorInsert_Mutation.graphql";
import {
  work_above_floor_set_input,
  WorkAboveElevationFloor_FloorUpdate_Mutation,
} from "src/common/types/generated/relay/WorkAboveElevationFloor_FloorUpdate_Mutation.graphql";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import WorkerProfileContactLink from "src/root/routes/views/subcontractor/people/workers/worker-profile/WorkerProfileContactLink";
import { Button, Checkbox, Form, Input } from "antd";
import dayjs from "dayjs";
import { ConnectionHandler } from "relay-runtime";
import GetFullID from "src/common/functions/GetFullId";
import { useUpdateText } from "./EditWorkAboveElevationModal";
import { WorkAboveElevationType } from "../table/WorkAboveElevationTable";

type ListDragState = {
  id: string;
  from: number;
  to: number;
} | null;

// declare getSuffix to avoid depricated substr
export function getDeltaY(dragState: ListDragState, index: number, height: number) {
  if (dragState === null) return 0;
  if (index == dragState.from) return (dragState.to - dragState.from) * height;
  if (dragState.from < dragState.to) {
    return dragState.from < index && index <= dragState.to ? -height : 0;
  } else {
    return dragState.to <= index && index < dragState.from ? height : 0;
  }
}

type WorkAboveFloorType = WorkAboveElevationType["work_above_floors"][number];

const WorkAboveElevationFloor: React.FC<{
  allFloors: WorkAboveFloorType[];
  elevationId: string;
}> = ({ allFloors, elevationId }) => {
  const [updateFloor] =
    useAsyncMutation<WorkAboveElevationFloor_FloorUpdate_Mutation>(graphql`
      mutation WorkAboveElevationFloor_FloorUpdate_Mutation(
        $id: uuid!
        $_set: work_above_floor_set_input!
      ) {
        update_work_above_floor_by_pk(pk_columns: { id: $id }, _set: $_set) {
          ...WorkAboveFloorFrag @relay(mask: false)
        }
      }
    `);
  const [dragState, setDragState] = useState<ListDragState>(null);
  const itemHeight = useRef(22);
  const [updateText] = useUpdateText();
  const [insertFloor, insertingFloor] =
    useAsyncMutation<WorkAboveElevationFloor_FloorInsert_Mutation>(graphql`
      mutation WorkAboveElevationFloor_FloorInsert_Mutation(
        $object: work_above_floor_insert_input!
      ) {
        insert_work_above_floor_one(object: $object) {
          ...WorkAboveFloorFrag @relay(mask: false)
        }
      }
    `);
  const [loading, setLoading] = useState<Set<string>>(new Set());
  const floorOptimisticResponse = (
    floor: WorkAboveFloorType,
    set: work_above_floor_set_input,
  ) => ({
    id: floor.id,
    pk: floor.pk,
    name_id: floor.name_id,
    completed_at: floor.completed_at,
    order: floor.order,
    has_netting: floor.has_netting,
    name: { en: floor.name.en, id: floor.name.id },
    ...set,
  });

  const onOrderChange = async (
    from: number,
    to: number,
    newItems: WorkAboveFloorType[],
  ) => {
    const prev_order = to > 0 ? newItems[to - 1].order : 0;
    let p = to + 1;
    while (p < newItems.length && prev_order + p - to >= newItems[p].order) p++;
    const gaps = p - to + 1;
    const last_order =
      p < newItems.length ? newItems[p].order : prev_order + 100 * gaps;
    const queries: Array<Promise<any>> = [];
    const list = [];
    for (let i = to; i < p; i++) {
      const order =
        (prev_order + ((last_order - prev_order) * (i - to + 1)) / gaps) | 0;
      setLoading((p) => {
        const newSet = new Set(...p);
        newSet.add(newItems[i].pk);
        return newSet;
      });
      list.push([newItems[i], order]);
      queries.push(
        updateFloor({
          variables: {
            id: newItems[i].pk,
            _set: { order },
          },
          optimisticResponse: {
            update_work_above_floor_by_pk: floorOptimisticResponse(
              newItems[i],
              { order },
            ),
          },
        }),
      );
    }
    await Promise.all(queries);
    setLoading(() => new Set());
  };
  const moveItem = (from: number, to: number) => {
    if (from === to) return;
    const item = allFloors[from];
    const newItems = allFloors.filter((i) => i.pk !== item.pk);
    newItems.splice(to, 0, item);
    onOrderChange?.(from, to, newItems);
  };
  const onStart = (
    e: DraggableEvent,
    d: DraggableData,
    item: WorkAboveFloorType,
    index: number,
  ) => {
    const rect = d.node.getBoundingClientRect();

    itemHeight.current = rect.height || rect.bottom - rect.top;
    setDragState({ id: item.pk, from: index, to: index });
  };

  const onDrag = (e: DraggableEvent, d: DraggableData, index: number) => {
    const newIndex = Math.min(
      (Math.max(d.y + index * itemHeight.current, 0) / itemHeight.current) | 0,
      allFloors.length - 1,
    );
    console.log(newIndex);

    if (
      dragState !== null &&
      (dragState.from !== index || dragState.to !== newIndex)
    ) {
      setDragState({ id: dragState.id, from: index, to: newIndex });
    }
  };
  const onStop = (e: DraggableEvent) => {
    console.log("Stopped", dragState);
    if (dragState !== null) {
      setDragState({ ...dragState, id: "" });
      setTimeout(() => {
        setDragState(null);
        moveItem(dragState.from, dragState.to);
      }, 50);
    }
  };
  const currentFloors = allFloors.sort(
    (f1, f2) => f1.order - f2.order || f1.name.en.localeCompare(f2.name.en),
  );
  const [newFloor, setNewFloor] = useState(false);
  const [form] = Form.useForm();
  return (
    <div className="z-0">
      <div className="flex gap-1 font-accent">
        All Floors ( Bottom {"-->"} Top){" "}
        {!newFloor && (
          <Button
            type="primary"
            size="small"
            onClick={() => {
              setNewFloor(true);
            }}
          >
            + Add New Floors
          </Button>
        )}
      </div>

      {newFloor && (
        <div className="mt-0.5 flex justify-between">
          <Form
            form={form}
            layout="inline"
            onFinish={async (vals) => {
              await insertFloor({
                variables: {
                  object: {
                    name: {
                      data: {
                        original: vals.newFloorName,
                        en: vals.newFloorName,
                      },
                    },
                    work_above_elevation_id: elevationId,
                    has_netting: vals.newFloorProtection,
                    order:
                      (currentFloors[currentFloors.length - 1]?.order || 0) +
                      1000,
                  },
                },
                updater: (store) => {
                  const conn = ConnectionHandler.getConnection(
                    store.getRoot(),
                    "WorkAboveElevationTable_work_above_elevation_connection",
                  );
                  if (conn) {
                    const insertedFloor = store.getRootField(
                      "insert_work_above_floor_one",
                    );
                    const edge = (conn.getLinkedRecords("edges") || []).find(
                      (rec) =>
                        rec.getLinkedRecord("node")?.getDataID?.() ===
                        GetFullID("work_above_elevation", elevationId),
                    );
                    const node = edge
                      ? edge.getLinkedRecord("node")
                      : undefined;
                    if (node) {
                      const curFloorRecords =
                        node.getLinkedRecords("work_above_floors") || [];
                      node.setLinkedRecords(
                        [...curFloorRecords, insertedFloor],
                        "work_above_floors",
                      );
                    }
                  }
                },
              });
              form.resetFields();
              setNewFloor(false);
            }}
            initialValues={{ newFloorProtection: false }}
          >
            <Form.Item name={"newFloorName"} required>
              <Input placeholder="New Floor Name" disabled={insertingFloor} />
            </Form.Item>
            <div className="flex">
              <Form.Item
                valuePropName="checked"
                name="newFloorProtection"
                label={"Protection"}
              >
                <Checkbox disabled={insertingFloor} />
              </Form.Item>
              <div className="flex -mt-0.25">
                <Button
                  htmlType="submit"
                  disabled={insertingFloor}
                  type="text"
                  className="text-interactive-primary hover:text-interactive-primary text-1.25"
                >
                  <CheckCircleOutlined />
                </Button>
                <Button
                  type="text"
                  disabled={insertingFloor}
                  className="hover:text-semantic-negative text-semantic-negative text-1.25"
                  onClick={() => {
                    setNewFloor(false);
                    form.resetFields();
                  }}
                >
                  <CloseCircleOutlined />
                </Button>
              </div>
            </div>
          </Form>
        </div>
      )}
      {currentFloors.map((floor, index) => (
        <Draggable
          key={floor.id}
          position={{
            x: 0,
            y: getDeltaY(dragState, index, itemHeight.current),
          }}
          disabled={loading.has(floor.pk)}
          onStart={(e, d) => onStart(e, d, floor, index)}
          onDrag={(e, d) => onDrag(e, d, index)}
          onStop={onStop}
          handle=".anticon"
        >
          <div className="mt-0.5 flex justify-between">
            <div className="flex">
              <MenuOutlined className="flex mr-0.75 mt-0.75" />
              <WorkerProfileContactLink
                currentValue={floor.name.en}
                type="name"
                saveButtonDesign="primary"
                disabled={loading.has(floor.pk)}
                onUpdate={(text) => {
                  setLoading((p) => {
                    const newSet = new Set(...p);
                    newSet.add(floor.pk);
                    return newSet;
                  });
                  updateText({
                    variables: {
                      _set: { original: text, en: text },
                      id: floor.name_id,
                    },
                    optimisticResponse: {
                      update_text_translation_by_pk: {
                        id: floor.name.id,
                        pk: floor.name_id,
                        original: text,
                        en: text,
                      },
                    },
                  });
                  setLoading((p) => {
                    const newSet = new Set(...p);
                    newSet.delete(floor.pk);
                    return newSet;
                  });
                }}
              />
            </div>
            &nbsp;&nbsp;
            <div className="flex mt-0.25">
              <div className=" ml-3 flex">
                <Checkbox
                  checked={floor.has_netting}
                  disabled={loading.has(floor.pk)}
                  onChange={async (e) => {
                    setLoading((p) => {
                      const newSet = new Set(...p);
                      newSet.add(floor.pk);
                      return newSet;
                    });
                    await updateFloor({
                      variables: {
                        _set: {
                          has_netting: e.target.checked,
                        },
                        id: floor.pk,
                      },
                      optimisticResponse: {
                        update_work_above_floor_by_pk: floorOptimisticResponse(
                          floor,
                          {
                            has_netting: e.target.checked,
                          },
                        ),
                      },
                    });
                    setLoading((p) => {
                      const newSet = new Set(...p);
                      newSet.delete(floor.pk);
                      return newSet;
                    });
                  }}
                />
                &nbsp;&nbsp;
                <span className="mt-0.125">Protection</span>
              </div>
              &nbsp;&nbsp;&nbsp;
              <div className="ml-1 flex">
                <Checkbox
                  checked={!!floor.completed_at}
                  disabled={loading.has(floor.pk)}
                  onChange={async (e) => {
                    setLoading((p) => {
                      const newSet = new Set(...p);
                      newSet.add(floor.pk);
                      return newSet;
                    });
                    await updateFloor({
                      variables: {
                        _set: {
                          completed_at: e.target.checked
                            ? dayjs().toISOString()
                            : null,
                        },
                        id: floor.pk,
                      },
                      optimisticResponse: {
                        update_work_above_floor_by_pk: floorOptimisticResponse(
                          floor,
                          {
                            completed_at: e.target.checked
                              ? dayjs().toISOString()
                              : null,
                          },
                        ),
                      },
                    });
                    setLoading((p) => {
                      const newSet = new Set(...p);
                      newSet.delete(floor.pk);
                      return newSet;
                    });
                  }}
                />
                &nbsp;&nbsp;
                <span className="mt-0.125">Completed</span>
              </div>
            </div>
          </div>
        </Draggable>
      ))}
    </div>
  );
};
export default WorkAboveElevationFloor;
