import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useLazyLoadQuery, fetchQuery } from "react-relay/hooks";
import { IEnvironment } from "relay-runtime";
import { useGetUniversalOrientationDataQuery } from "src/common/types/generated/relay/useGetUniversalOrientationDataQuery.graphql";

export const query = graphql`
  query useGetUniversalOrientationDataQuery(
    $userId: uuid!
    $now: timestamptz
    $universalOrientationValidDate: timestamptz!
  ) {
    user_connection(where: { id: { _eq: $userId } }) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          name
          role
          lang
          email
          birth_date
          phone_number
          profile_picture {
            url
          }
          universal_orientations(
            where: {
              universal_orientated_at: { _gte: $universalOrientationValidDate }
            }
            order_by: { universal_orientated_at: desc }
            limit: 1
          ) {
            id
            universal_orientated_at
          }
          subcontractor_employee {
            subcontractor_id
            subcontractor {
              name
            }
          }
          #for now we're not doing OAC user universal orientation
          # employee {
          #  general_contractor_id
          # }
          worker {
            worker_title {
              translation {
                en
              }
            }
            labor_union_chapter_id
            labor_union_chapter {
              labor_union_id
            }
            subcontractor_id
            current_worker_role
            worker_title_id
            subcontractor {
              name
            }
            worker_certifications(
              where: {
                _or: [
                  { expires_on: { _is_null: true } }
                  { expires_on: { _gte: $now } }
                ]
              }
            ) {
              expires_on
              created_at
              issued_on
              certification_id
              id @__clientField(handle: "pk")
              images(order_by: { description: desc }, limit: 2) {
                id
              }
              certification {
                name
              }
            }
            pendingCertificates: certificates_to_verify(
              where: {
                document: { _eq: "certificate" }
                status: { _eq: "submitted" }
                verified_at: { _is_null: true }
              }
            ) {
              id
            }
            pendingProfile: certificates_to_verify(
              where: {
                document: { _eq: "profilePhoto" }
                status: { _eq: "submitted" }
                verified_at: { _is_null: true }
              }
              order_by: { created_at: desc }
              limit: 1
            ) {
              front_image {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const useGetUniversalOrientationData = (userId: string) => {
  const data = useLazyLoadQuery<useGetUniversalOrientationDataQuery>(query, {
    userId,
    now: dayjs().endOf("d").toISOString(),
    universalOrientationValidDate: dayjs()
      .subtract(11, "months")
      .startOf("d")
      .toISOString(),
  });

  return data;
};
export const fetchUniversalOrientationData = async (
  environment: IEnvironment,
  userId: string,
) => {
  const data = await fetchQuery<useGetUniversalOrientationDataQuery>(
    environment,
    query,
    {
      userId,
      now: dayjs().endOf("d").toISOString(),
      universalOrientationValidDate: dayjs()
        .subtract(11, "months")
        .startOf("d")
        .toISOString(),
    },
  ).toPromise();
  if (!data) {
    throw new Error("useGetUniversalOrientationDataQuery returned null");
  }
  return data;
};
export default useGetUniversalOrientationData;
