import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, Modal } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useState } from "react";
import FModal from "src/common/components/dialogs/FModal";
import GetFullID from "src/common/functions/GetFullId";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { GCHierarchyModalMutation } from "src/common/types/generated/relay/GCHierarchyModalMutation.graphql";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import withCorporateAdminWrapper from "../../withCorporateAdminWrapper";
interface GCHierarchyModalProps {
  open: boolean;
  onCancel: () => void;
  gcId: string;
  hierarchy_division_name?: string | null;
  hierarchy_business_unit_name?: string | null;
  hierarchy_office_name?: string | null;
}

const GcHierarchyModal: React.FC<GCHierarchyModalProps> = ({
  open,
  onCancel,
  gcId,
  ...props
}) => {
  const [divisionLevelName, setDivisionLevelName] = useState(
    props.hierarchy_division_name,
  );
  const [buLevelName, setBuLevelName] = useState(
    props.hierarchy_business_unit_name,
  );
  const [officeLevelName, setOfficeLevelName] = useState(
    props.hierarchy_office_name,
  );
  const { updateUserData } = useUserData();
  const [updateGCData, updating] =
    useAsyncMutation<GCHierarchyModalMutation>(graphql`
      mutation GCHierarchyModalMutation(
        $gcId: uuid!
        $_set: general_contractor_set_input!
      ) {
        update_general_contractor_by_pk(
          pk_columns: { id: $gcId }
          _set: $_set
        ) {
          id
          hierarchy_division_name
          hierarchy_business_unit_name
          hierarchy_office_name
        }
      }
    `);

  const [addDivisionLevel, setAddDivisionLevel] = useState(
    !!props.hierarchy_division_name?.trim(),
  );
  const [addBuLevel, setAddBuLevel] = useState(
    !!props.hierarchy_business_unit_name?.trim(),
  );
  const [addOfficeLevel, setAddOfficeLevel] = useState(
    !!props.hierarchy_office_name?.trim(),
  );
  const changed =
    props.hierarchy_business_unit_name?.trim() !== buLevelName?.trim() ||
    props.hierarchy_division_name?.trim() !== divisionLevelName?.trim() ||
    props.hierarchy_office_name?.trim() !== officeLevelName?.trim();
  return (
    <Modal
      open={open}
      destroyOnClose
      confirmLoading={updating}
      onCancel={onCancel}
      title="Company Hierarchy"
      okButtonProps={{
        title: changed ? "Save" : "Ok",
      }}
      onOk={async () => {
        if (!changed) {
          onCancel();
          return;
        }
        const changes = {
          hierarchy_division_name:
            addDivisionLevel && divisionLevelName?.trim()
              ? divisionLevelName?.trim()
              : null,
          hierarchy_business_unit_name:
            addBuLevel && buLevelName?.trim() ? buLevelName?.trim() : null,
          hierarchy_office_name:
            addOfficeLevel && officeLevelName?.trim()
              ? officeLevelName?.trim()
              : null,
        };
        console.log(changes);

        await updateGCData({
          variables: { gcId, _set: changes },
          optimisticResponse: {
            update_general_contractor_by_pk: {
              id: GetFullID("general_contractor", gcId),
              ...changes,
            },
          },
        });
        updateUserData((user) => ({
          ...user,
          employee: user?.employee
            ? {
                ...user.employee,
                general_contractor: {
                  ...user.employee.general_contractor,
                  ...changes,
                },
              }
            : undefined,
        }));
        onCancel();
      }}
    >
      <div>
        {
          "An organization can have 3 hierarchy levels between the Corporate Office and Projects. For example, Corporate Office > Regions > Business Units > Offices > Projects"
        }
      </div>
      <br />
      <div>Add each level of your organization</div>
      <br />
      {!(addDivisionLevel && addBuLevel && addOfficeLevel) && (
        <Button
          icon={<PlusOutlined />}
          shape="circle"
          size="small"
          onClick={() => {
            if (!addOfficeLevel) setAddOfficeLevel(true);
            else if (!addBuLevel) setAddBuLevel(true);
            else if (!addDivisionLevel) setAddDivisionLevel(true);
          }}
        />
      )}
      {addDivisionLevel && (
        <div className="flex gap-1 mb-1">
          Level 1 Name
          <Input
            value={divisionLevelName || ""}
            onChange={(e) => {
              console.log(e.target.value, e.currentTarget.value);
              setDivisionLevelName(e.target.value);
            }}
          />
          <Button
            icon={<MinusOutlined />}
            shape="circle"
            size="small"
            onClick={() => {
              setDivisionLevelName(null);
              setAddDivisionLevel(false);
            }}
          />
        </div>
      )}
      {addBuLevel && (
        <div className="flex gap-1 mb-1">
          Level 2 Name
          <Input
            value={buLevelName || ""}
            onChange={(e) => setBuLevelName(e.target.value)}
          />
          {!addDivisionLevel && (
            <Button
              icon={<MinusOutlined />}
              shape="circle"
              size="small"
              onClick={() => {
                setBuLevelName(null);
                setAddBuLevel(false);
              }}
            />
          )}
        </div>
      )}
      {addOfficeLevel && (
        <div className="flex gap-1 mb-1">
          Level 3 Name
          <Input
            value={officeLevelName || ""}
            onChange={(e) => setOfficeLevelName(e.target.value)}
          />
          {!addDivisionLevel && !addBuLevel && (
            <Button
              icon={<MinusOutlined />}
              shape="circle"
              size="small"
              onClick={() => {
                setOfficeLevelName(null);
                setAddOfficeLevel(false);
              }}
            />
          )}
        </div>
      )}
      <div className="w-32 flex flex-col justify-center items-center">
        <div>Corporate Office</div>
        {divisionLevelName?.trim() && (
          <>
            <div>|</div>
            <div>|</div>
            <div>{divisionLevelName.trim()}</div>
          </>
        )}
        {buLevelName?.trim() && (
          <>
            <div>|</div>
            <div>|</div>
            <div>{buLevelName.trim()}</div>
          </>
        )}
        {officeLevelName?.trim() && (
          <>
            <div>|</div>
            <div>|</div>
            <div>{officeLevelName.trim()}</div>
          </>
        )}
        <div>|</div>
        <div>|</div>
        <div>Projects</div>
      </div>
    </Modal>
  );
};
export default withCorporateAdminWrapper(GcHierarchyModal);
