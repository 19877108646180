import React, { useContext, useEffect } from "react";
import {
  CompanyType,
  OacUsersObjType,
  WorkersObjType,
} from "../../../../../../../utils/siteOrientationTypes";
import { Button } from "antd";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerRegistrationStepsWhoAreYouUIProps {
  workers: WorkersObjType;
  oacUsers: OacUsersObjType;
  onCompanySelect: (company: CompanyType) => void;
  selectedCompany: CompanyType | null;
  onUserSelect: (userId: string) => void;
  onWorkerIsNew: () => void;
}

const SiteOrientationInPersonWorkerRegistrationStepsWhoAreYouUI: React.FC<
  SiteOrientationInPersonWorkerRegistrationStepsWhoAreYouUIProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();

  const allUsers: {
    name: string;
    company: CompanyType;
    id: string;
  }[] = [];
  const langStrings = useCurrentLangStrings();

  const companies: CompanyType[] = [];
  Object.keys(props.workers).forEach((key) => {
    const worker = props.workers[key];
    worker.name &&
      worker.id &&
      worker.company &&
      worker.name.old &&
      allUsers.push({
        name: worker.name.old,
        company: worker.company,
        id: worker.id,
      });
  });
  Object.keys(props.oacUsers).forEach((key) => {
    const oacUser = props.oacUsers[key];
    oacUser.name &&
      oacUser.id &&
      oacUser.company &&
      oacUser.name.old &&
      allUsers.push({
        name: oacUser.name.old,
        id: oacUser.id,
        company: oacUser.company,
      });
  });

  allUsers.forEach((user) => {
    if (!companies.some((company) => company.id === user.company.id)) {
      companies.push(user.company);
    }
  });

  // sort companies array by title
  companies.sort((a, b) => {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    if (companies.length === 1) {
      selectCompany(companies[0]);
    }
  }, []);
  const selectCompany = (newCompany: CompanyType) => {
    props.onCompanySelect && props.onCompanySelect(newCompany);
    workerContext.updateOrientee((user) =>
      user
        ? {
            ...user,
            company: {
              title: newCompany.title,
              id: newCompany.id,
              companyWorkerTitles: [],
            },
          }
        : {
            company: {
              title: newCompany.title,
              id: newCompany.id,
              companyWorkerTitles: [],
            },
          },
    );
  };

  return (
    <>
      {!props.selectedCompany && companies.length > 1 ? (
        <div className={`flex flex-col gap-1.5`}>
          <p className={`font-accent text-2`}>
            {langStrings.strings.whoDoyYouWorkFor}
          </p>
          <div className={`flex flex-col gap-0.25`}>
            {companies.map((company) => (
              <Button
                size={`large`}
                onClick={() => {
                  selectCompany(company);
                }}
                key={company.id}
              >
                {company.title}
              </Button>
            ))}
          </div>

          <Button onClick={props.onWorkerIsNew}>
            {langStrings.strings.myEmployerIsNotInTheList}
          </Button>
        </div>
      ) : (
        <div className={`flex flex-col gap-1.5`}>
          <p className={`text-2 font-accent`}>
            {langStrings.strings.selectYourSelf}
          </p>
          <div className={`flex flex-col gap-0.25`}>
            {allUsers
              .filter((user) => user.company.id === props.selectedCompany?.id)
              .map((user) => {
                return (
                  <Button
                    key={user.id}
                    size={`large`}
                    onClick={() => {
                      props.onUserSelect(user.id);
                    }}
                  >
                    {user.name}
                  </Button>
                );
              })}
          </div>
          <Button onClick={props.onWorkerIsNew}>
            {langStrings.strings.imNotInTheList}
          </Button>
        </div>
      )}
    </>
  );
};

export default SiteOrientationInPersonWorkerRegistrationStepsWhoAreYouUI;
