import NestedRoute from "src/common/types/manual/NestedRoute";
import GCReports from "../../components/GCReports";
import { Outlet } from "react-router-dom";
import VisitorSettings from "src/domain-features/sitesafety/entryRoutes/gcProjectReports/routes/visitor/components/VisitorSettings";

const visitorsRoute: NestedRoute = {
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <GCReports reportType="visitors" />,
    },
    {
      path: "settings",
      element: <VisitorSettings />,
    },
  ],
};

export default visitorsRoute;
