import { useSuspenseQuery } from "@apollo/client";
import {
  GetGcOfficesDocument,
  GetGcOfficesQuery,
  GetGcOfficesQueryVariables,
  useInsertGcOfficeMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import { useParams } from "react-router-dom";
import GCAddNewLevelButton from "../../component/GCAddNewLevelButton";
import useAuthUser from "src/common/hooks/useAuthUser";
import OfficeTable from "../../component/OfficeTable";
import withBusinessUnitId, {
  BusinessUnitProps,
} from "../../utils/withBusinessUnitId";
const GCBuOffices: React.FC<BusinessUnitProps> = ({ businessUnitId }) => {
  const { userData } = useUserData();
  const emp = userData.employee;
  if (!emp) throw new Error("Employee not found");
  const gc = emp.general_contractor;
  const { data, refetch } = useSuspenseQuery<
    GetGcOfficesQuery,
    GetGcOfficesQueryVariables
  >(GetGcOfficesDocument, {
    variables: {
      divisionWhere: { id: { _is_null: true } },
      buWhere: { id: { _eq: businessUnitId } },
      officeWhere: { gc_business_unit_id: { _eq: businessUnitId } },
      includeTableData: true,
    },
  });
  const bu = data.gc_business_unit[0];
  if (!bu) throw new Error("Bu not found");
  const authUser = useAuthUser();
  const [insertOffice, { loading }] = useInsertGcOfficeMutation();

  return gc.hierarchy_office_name ? (
    <div className="bg-white h-full w-full p-1">
      <OfficeTable
        officeTitle={gc.hierarchy_office_name}
        offices={data.gc_office}
        addButton={
          <GCAddNewLevelButton
            {...{
              levelName: gc.hierarchy_office_name,
              inserting: loading,
              businessUnitId,
              divisionId: bu.gc_division_id,
              buSelectOptions: data.gc_business_unit,
              buLevelName: gc.hierarchy_business_unit_name,
              divisionLevelName: gc.hierarchy_division_name,
              divisionSelectOptions: data.gc_division,
              onInsert: async (vals) => {
                await insertOffice({
                  variables: {
                    object: {
                      name: vals.name,
                      gc_business_unit_id: businessUnitId,
                      gc_division_id: bu.gc_division_id,
                      general_contractor_id: emp.general_contractor_id,
                      created_by_uid: authUser.uid,
                      logo_url: vals.uploadedLogoUrl,
                      address: {
                        data: {
                          line_1: vals.addressLine1,
                          zip_code: vals.zip,
                          city: vals.city,
                          state_code: vals.stateCode,
                        },
                      },
                    },
                  },
                });
                refetch();
              },
            }}
          />
        }
      />
    </div>
  ) : null;
};
export default withBusinessUnitId(GCBuOffices);
