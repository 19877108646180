import { graphql } from "babel-plugin-relay/macro";

const insertSubcontractorsMutation = graphql`
  mutation InsertSubcontractorsMutation(
    $subcontractorObjects: [project_subcontractor_insert_input!]!
  ) {
    insert_project_subcontractor(objects: $subcontractorObjects) {
      affected_rows
      returning{
        subcontractor{
          id
          pk: id @__clientField(handle: "pk")
          name
        }
      }
    }
  }
`;

export default insertSubcontractorsMutation;