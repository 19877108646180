/**
 * @generated SignedSource<<1a414fce6a5692103110ee0a18f354a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProcoreProjectConfigModal_resetEverything_Mutation$variables = {
  project_id: string;
};
export type ProcoreProjectConfigModal_resetEverything_Mutation$data = {
  readonly delete_procore_subcontractor: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly delete_procore_trade: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type ProcoreProjectConfigModal_resetEverything_Mutation = {
  response: ProcoreProjectConfigModal_resetEverything_Mutation$data;
  variables: ProcoreProjectConfigModal_resetEverything_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "project_id"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "project_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "project_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "procore_subcontractor_mutation_response",
    "kind": "LinkedField",
    "name": "delete_procore_subcontractor",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "concreteType": "procore_trade_mutation_response",
    "kind": "LinkedField",
    "name": "delete_procore_trade",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProcoreProjectConfigModal_resetEverything_Mutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProcoreProjectConfigModal_resetEverything_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "826b98d2cd8bdff6acaa8f10caaed1ae",
    "id": null,
    "metadata": {},
    "name": "ProcoreProjectConfigModal_resetEverything_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProcoreProjectConfigModal_resetEverything_Mutation(\n  $project_id: uuid!\n) {\n  delete_procore_subcontractor(where: {project_id: {_eq: $project_id}}) {\n    affected_rows\n  }\n  delete_procore_trade(where: {project_id: {_eq: $project_id}}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "06927bdef5f9b40e7d82d6b4521ea229";

export default node;
