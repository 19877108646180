import { createContext } from "react";
import { VisitorType } from "./visitorOrientationTypes";
import { GetVisitorLogDataV2QueryResult } from "src/common/types/generated/apollo/graphQLTypes";
type QueryData = NonNullable<
  NonNullable<GetVisitorLogDataV2QueryResult["data"]>["getVisitorLogDataV2"]
>;
export type VisitorOrientationOrienteeContextType = {
  projectId: string;

  //   updateSelectedUserId: React.Dispatch<React.SetStateAction<string | null>>;
  updateVisitor: React.Dispatch<React.SetStateAction<VisitorType | null>>;

  updateShowWaiverText: React.Dispatch<React.SetStateAction<boolean>>;
  waiverText?: QueryData["waiverText"];
  showWaiverText?: QueryData["showWaiverText"];
  showTnc?: boolean;
  visitor?: VisitorType;
  companies: QueryData["companies"];
  skip?: boolean;
} | null;

const VisitorOrientationOrienteeContext =
  createContext<VisitorOrientationOrienteeContextType>(null);

export default VisitorOrientationOrienteeContext;
