import { FC, useState } from "react";
import StyledContent from "src/common/components/layouts/StyledContent";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import { Button } from "antd";
import dayjs from "dayjs";

import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { useNavigate } from "react-router-dom";
import { useSuspenseQuery } from "@apollo/client";
import { GetCrewSightAccountsDocument, GetCrewSightAccountsQuery, GetCrewSightAccountsQueryVariables } from "../../../../../../common/types/generated/apollo/graphQLTypes";

const CrewSightAccounts: FC = () => {
  const { data } = useSuspenseQuery<GetCrewSightAccountsQuery, GetCrewSightAccountsQueryVariables>(
    GetCrewSightAccountsDocument,
  );
  const navigate = useNavigate();
  return (
    <StyledContent padding backgroundColor="white">
      <BaseTable
        columns={[
          {
            title: "Username", key: "username", dataIndex: "username" 
          },
          {
            title: "Project",
            key: "project",
            dataIndex: ["project", "name"],
          },
          { title: "Active", key: "active", dataIndex: "active", render: value => (value ? "Yes": "No") },
          { title: 'Last sync status', key: 'last_sync_status', dataIndex: 'last_sync_status', render: value => (<span style={{color: ((value || "").endsWith("successfully")? 'green': 'red')}}>{value}</span>)},
          { title: 'Last sync time', key: 'last_sync_at', dataIndex: 'last_sync_at', render: value => (dayjs(value).format('YYYY-MM-DD HH:mm:ss'))},
          { title: '', key: 'setup_buttons', render: (row) => {
              return <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evently'}}>
                <Button style= {{display: 'flex'}} onClick={()=> navigate(`${row.project_id}/settings`)}>Setup mappings</Button>
                <Button style= {{display: 'flex'}} onClick={()=> navigate(`${row.id}/logs`)}>Logs</Button>
              </div>;
            },
          }
        ]}
        dataSource={data?.crewsight_integration_account || []}
      ></BaseTable>
    </StyledContent>
  );
};

export default withCustomSuspense(CrewSightAccounts);
