import React, { useState } from "react";
import AutoComplete from "src/common/components/general/AutoComplete";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import trimString from "src/common/functions/trimString";
import { SiteInspectionStepProps } from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import useSiteInspectionUser from "src/domain-features/siteinspection/utils/useSiteInspectionUser";

import { useCurrentLangStrings } from "../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteInspectionWorkerStepsFullNameProps
  extends SiteInspectionStepProps {
  workers: {
    id: string;
    name: string;
    trade?: string;
  }[];
}

const SiteInspectionWorkerStepsFullName: React.FC<
  SiteInspectionWorkerStepsFullNameProps
> = (props) => {
  const workerContext = useSiteInspectionUser();
  const langStrings = useCurrentLangStrings();
  const prevInsUserId = localStorage.getItem("inspectionUserId");
  const prevInsUserName = prevInsUserId
    ? undefined
    : localStorage.getItem("inspectionUserName");
  const [selectOptionVal, setSelectOptionVal] = useState(
    props.workers.find(
      (w) => w.id === (workerContext.user?.id || prevInsUserId),
    )?.id,
  );
  const handleNameSubmit = (newFullName: string) => {
    const foundUser = props.workers.find((w) => w.name === newFullName);
    if (foundUser) {
      onSelect(foundUser.id);
    } else {
      localStorage.setItem("inspectionUserName", newFullName);
      localStorage.removeItem("inspectionUserId");
      workerContext.updateUser((u) =>
        u
          ? { ...u, name: newFullName, id: undefined }
          : { name: newFullName, id: undefined },
      );
      props.onNext();
    }
  };
  const [fullName, setFullName] = useState(
    workerContext.user?.name || prevInsUserName || "",
  );

  const onEnter = () => {
    if (selectOptionVal) {
      onSelect(selectOptionVal);
    } else if (fullName) {
      setFullName(fullName);
      handleNameSubmit(fullName.replace(/[^a-zA-Z]+|\s+/g, " ").trim());
    }
  };
  const onSelect = (pickedId: string) => {
    localStorage.setItem("inspectionUserId", pickedId);
    localStorage.removeItem("inspectionUserName");
    workerContext.updateUser((u) =>
      u
        ? {
            ...u,
            id: pickedId,
            name: props.workers.find((s) => s.id === pickedId)?.name || "",
          }
        : {
            id: pickedId,
            name: props.workers.find((s) => s.id === pickedId)?.name || "",
          },
    );
    props.onNext();
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={
        (!fullName || trimString(fullName).split(" ").length < 2) &&
        !selectOptionVal
      }
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.whatIsYOurFullName}
      >
        <div className="text-grey text-1">
          {langStrings.strings.firstAndLastName}
        </div>
        <AutoComplete
          initialText={fullName}
          minTextLengthToShowOption={3}
          onTextChange={(text) => {
            setSelectOptionVal(undefined);
            setFullName(text);
          }}
          initialSelectedOptionValue={selectOptionVal}
          options={props.workers.map((p) => ({
            label: p.name + (p.trade ? `, ${p.trade}` : ""),
            value: p.id,
          }))}
          onSelect={(val) => {
            setSelectOptionVal(val);
            if (val) onSelect(val);
          }}
        />
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};
export default SiteInspectionWorkerStepsFullName;
