import { Button, Card, DatePicker, Form, Select, Spin } from "antd";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import PieChart from "src/common/components/charts/PieChart";
import randomHexColor from "src/common/functions/randomHexColor";
import { titleCase } from "src/common/functions/stringFormatters";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";
import { useGetObservationChartsDataQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { graphColors } from "../../utils/graphColors";
import { Column } from "@ant-design/charts";
import { CloseOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
const GCObservationCharts: React.FunctionComponent = () => {
  const projectId = useParams().projectId as string;

  const [subChartSelectedStatus, setSubSelectedStatus] = useState("all");
  const [barChartSelectedPerson, setBarChartSelectedPerson] =
    useState<string>();
  const { data, loading } = useGetObservationChartsDataQuery({
    variables: {
      projectId,
      obsWhere: {
        status: { _neq: "draft" },
        project_id: { _eq: projectId },
        created_by_user: { employee: {} },
      },
    },
  });
  const [severityList, userOptionsList] = useMemo(() => {
    const severityMap: {
      [key: string]: { name: string; color: string; value: number };
    } = {};
    const userOptionsMap: {
      [key: string]: { name: string; id: string };
    } = {};
    if (data)
      data.observation.forEach((ob) => {
        if (!userOptionsMap[ob.created_by_uid])
          userOptionsMap[ob.created_by_uid] = {
            name: ob.created_by_user.name,
            id: ob.created_by_uid,
          };
        if (ob.risk_level_value && ob.risk_level_value !== "safe") {
          if (severityMap[ob.risk_level_value]) {
            severityMap[ob.risk_level_value].value += ob.number_of_findings;
          } else {
            const risk = data.risk_level.find(
              (r) => r.value === ob.risk_level_value,
            )!;
            severityMap[ob.risk_level_value] = {
              value: ob.number_of_findings,
              name: risk.name,
              color: risk.color_hex,
            };
          }
        }
      });

    return [Object.values(severityMap), Object.values(userOptionsMap)] as const;
  }, [data?.observation]);
  const statusList = useMemo(() => {
    const statusMap = {
      safe: { name: "Safe", value: 0, color: graphColors.green },
      corrected: {
        name: "Unsafe Corrected",
        value: 0,
        color: graphColors.purple,
      },
      open: { name: "Unsafe - Open", value: 0, color: graphColors.red },
    };
    if (data)
      data.observation.forEach((ob) => {
        if (ob.risk_level_value) {
          const key =
            ob.risk_level_value === "safe"
              ? "safe"
              : ob.status === "open"
              ? "open"
              : ob.status === "closed"
              ? "corrected"
              : undefined;

          if (key) statusMap[key].value += ob.number_of_findings;
        }
      });

    return Object.values(statusMap);
  }, [data?.observation]);
  const subsList = useMemo(() => {
    const subsMap: {
      [key: string]: { name: string; color: string; value: number };
    } = {};
    if (data?.observation) {
      data.observation.forEach((ob) => {
        const filteredIn =
          subChartSelectedStatus === "all" ||
          (subChartSelectedStatus === "safe" &&
            ob.risk_level_value === "safe") ||
          (subChartSelectedStatus === "unsafe-closed" &&
            ob.risk_level_value !== "safe" &&
            ob.status === "closed") ||
          (subChartSelectedStatus === "unsafe-open" &&
            ob.risk_level_value !== "safe" &&
            ob.status === "open");

        if (ob.subcontractor_id && filteredIn) {
          if (subsMap[ob.subcontractor_id]) {
            subsMap[ob.subcontractor_id].value += ob.number_of_findings;
          } else {
            const sub = data.project_subcontractor.find(
              (ps) => ps.subcontractor_id === ob.subcontractor_id,
            );

            if (sub)
              subsMap[ob.subcontractor_id] = {
                color: randomHexColor(),
                name: sub.subcontractor.name,
                value: ob.number_of_findings,
              };
          }
        }
      });
    }
    return Object.values(subsMap);
  }, [data?.observation, subChartSelectedStatus]);
  const dateData = useMemo(() => {
    const dateMap: {
      [key: string]: {
        xAxis: string;
        date: string;
        yAxis: number;
        type: "safe" | "unsafe" | "not-defined";
        typeColor: string;
      };
    } = {};

    if (data)
      data.observation
        .filter(
          (ob) =>
            !barChartSelectedPerson ||
            ob.created_by_uid === barChartSelectedPerson,
        )
        .forEach((ob) => {
          const date = dayjs(ob.observation_date)
            .startOf("week")
            .format("YYYY-MM-DD");
          const eow = dayjs(ob.observation_date)
            .endOf("week")
            .format("YYYY-MM-DD");
          const risk =
            ob.risk_level_value === "safe"
              ? "safe"
              : ob.risk_level_value
              ? "unsafe"
              : "not-defined";
          if (dateMap[date + risk]) {
            dateMap[date + risk].yAxis += ob.number_of_findings;
          } else {
            dateMap[date + risk] = {
              xAxis: `${date} - ${eow}`,
              date,
              yAxis: ob.number_of_findings,
              type: risk,
              typeColor:
                ob.risk_level_value === "safe"
                  ? graphColors.green
                  : ob.risk_level_value
                  ? "red"
                  : "not defined",
            };
          }
        });
    console.log(dateMap);

    return Object.values(dateMap).sort(
      (a1, a2) =>
        dayjs(a1.date).valueOf() - dayjs(a2.date).valueOf() ||
        a1.type.localeCompare(a2.type),
    );
  }, [data?.observation, barChartSelectedPerson]);
  const { timeToSolve, total } = useMemo(() => {
    let timeToSolve = 0,
      total = 0;
    if (data)
      data.observation.forEach((ob) => {
        const unsafe = ob.unsafe_observation;
        if (
          ob.risk_level_value &&
          ob.risk_level_value !== "safe" &&
          ob.status === "closed" &&
          unsafe?.corrected_on &&
          unsafe.due_on
        ) {
          const correctedHoursDiff = unsafe?.corrected_on
            ? dayjs(unsafe.corrected_on).diff(
                dayjs(ob.observation_date),
                "hours",
              )
            : null;
          if (correctedHoursDiff && correctedHoursDiff > 2) {
            total += 1;
            timeToSolve += correctedHoursDiff;
          }
        }
      });
    return { timeToSolve, total };
  }, [data]);

  if (loading) return <Spin />;
  if (!data) throw new Error("Chart Data not found");
  console.log(timeToSolve, total);

  return (
    <div className="w-full h-full">
      <div className="flex space-between w-screen flex-row gap-1">
        <Card
          className="min-w-16"
          title={<div className="text-1.25">Total Observations</div>}
        >
          <div className="text-3">
            {data.observation.reduce(
              (sum, ob) => sum + ob.number_of_findings,
              0,
            )}
          </div>
        </Card>
        <Card
          className="min-w-16"
          title={
            <div className="text-1.25 text-semantic-negative">Total Open</div>
          }
        >
          <div className="text-3 text-semantic-negative">
            {data.observation
              .filter((ob) => ob.status === "open")
              .reduce((sum, ob) => sum + ob.number_of_findings, 0)}
          </div>
        </Card>
        <Card
          className="min-w-16"
          title={
            <div className="text-1.25 text-purple">Avg. Completion Time</div>
          }
        >
          <div className="text-3 text-purple">
            {total ? (timeToSolve / total).toFixed(1) : 0}
            <span className="text-0.75 font-normal">hour</span>
          </div>
        </Card>
      </div>
      <div>
        {/* <Select
          mode="multiple"
          placeholder="Select Subs To Filter(Current: All)"
          className="w-32"
          onSelect={(v) => handleSelectSub(v)}
          onDeselect={(v) => {
            handleSelectSub(v);
          }}
        >
          {data.project_subcontractor.map((r) => (
            <Select.Option value={r.subcontractor_id} key={r.subcontractor_id}>
              {r.subcontractor.name}
            </Select.Option>
          ))}
        </Select> */}
      </div>
      <div className="w-full flex flex-row">
        <div className="w-1/2" key={1}>
          <PieChart
            id="subsvsObs"
            title={
              <div className="flex gap-1">
                <div>Subcontractor</div>
                <Select
                  className="w-16"
                  value={subChartSelectedStatus}
                  onChange={(v) => {
                    setSubSelectedStatus(v);
                  }}
                >
                  <Select.Option key={"all"} value="all">
                    All
                  </Select.Option>
                  <Select.Option key={"safe"} value="safe">
                    <span className="text-semantic-positive-green">Safe</span>
                  </Select.Option>
                  <Select.Option key={"unsafe-closed"} value="unsafe-closed">
                    <span className="text-purple">Closed - Unsafe</span>
                  </Select.Option>
                  <Select.Option key={"unsafe-open"} value="unsafe-open">
                    <span className="text-semantic-negative">
                      Open - Unsafe
                    </span>
                  </Select.Option>
                </Select>
              </div>
            }
            singleMetric={false}
            label={[
              "name",
              (name: string) => ({
                content: (record: any) =>
                  `${titleCase(name)} - ${Math.round(100 * record.percent)}%`,
              }),
            ]}
            chartCfg={{
              autoFit: true,
              height: 300,
              // width: 350,
              padding: [10, 110, 10, 30],
              defaultInteractions: [],
            }}
            tooltip={{
              config: {
                showTitle: false,
                showMarkers: false,
              },
              displayAs: [
                "name*value",
                (name, value) => ({
                  name: titleCase(name),
                  value: `${value} Observation`,
                }),
              ],
            }}
            legend={{
              show: true,
              options: {
                position: "right",
                itemName: {
                  formatter(text: string) {
                    return titleCase(text);
                  },
                },
              },
            }}
            interactions={["tooltip", "element-active"]}
            color={{
              field: "name",
              colors: subsList.map((s) => s.color),
            }}
            dataSource={subsList}
          />
        </div>{" "}
        <div className="w-1/2" key={2}>
          <PieChart
            id="riskVsObs"
            title="Risk Level"
            singleMetric={false}
            label={[
              "name",
              (name: string) => ({
                content: (record: any) =>
                  `${titleCase(name)} - ${Math.round(100 * record.percent)}%`,
              }),
            ]}
            chartCfg={{
              autoFit: true,
              height: 300,
              // width: 350,
              padding: [10, 110, 10, 30],
              defaultInteractions: [],
            }}
            tooltip={{
              config: {
                showTitle: false,
                showMarkers: false,
              },
              displayAs: [
                "name*value",
                (name, value) => ({
                  name: titleCase(name),
                  value: `${value} Observation`,
                }),
              ],
            }}
            legend={{
              show: true,
              options: {
                position: "right",
                itemName: {
                  formatter(text: string) {
                    return titleCase(text);
                  },
                },
              },
            }}
            interactions={["tooltip", "element-active"]}
            color={{
              field: "name",
              colors: severityList.map((s) => s.color),
            }}
            dataSource={severityList}
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="w-1/2" key={2}>
          <PieChart
            id="stausObs"
            title="Status "
            singleMetric={false}
            label={[
              "name",
              (name: string) => ({
                content: (record: any) =>
                  `${titleCase(name)} - ${Math.round(100 * record.percent)}%`,
              }),
            ]}
            chartCfg={{
              autoFit: true,
              height: 300,
              // width: 350,
              padding: [10, 110, 10, 30],
              defaultInteractions: [],
            }}
            tooltip={{
              config: {
                showTitle: false,
                showMarkers: false,
              },
              displayAs: [
                "name*value",
                (name, value) => ({
                  name: titleCase(name),
                  value: `${value} Observation`,
                }),
              ],
            }}
            legend={{
              show: true,
              options: {
                position: "right",
                itemName: {
                  formatter(text: string) {
                    return titleCase(text);
                  },
                },
              },
            }}
            interactions={["tooltip", "element-active"]}
            color={{
              field: "name",
              colors: statusList.map((s) => s.color),
            }}
            dataSource={statusList}
          />
        </div>
      </div>
      <div className="flex gap-1 mt-1">
        <div>Assignees</div>
        <Select
          className="w-24"
          onChange={setBarChartSelectedPerson}
          value={barChartSelectedPerson}
        >
          {userOptionsList.map((user) => (
            <Select.Option value={user.id} key={user.id}>
              {user.name}
            </Select.Option>
          ))}
        </Select>
        {barChartSelectedPerson && (
          <Button
            shape="circle"
            icon={<CloseOutlined />}
            onClick={() => setBarChartSelectedPerson(undefined)}
          ></Button>
        )}
      </div>
      <Column
        {...{
          height: 500,
          data: dateData,
          xField: "xAxis",
          yField: "yAxis",
          label: {
            text: "yAxis",
            textBaseline: "bottom",
            position: "inside",
          },
          style: {
            fill: "typeColor",
          },
          colorField: "type",
          percent: false,
          stack: true,
          interaction: {
            tooltip: {
              shared: true,
              // render: (e, { title, items }) => {
              //   return (
              //     <div key={title}>
              //       <h4>{title}</h4>
              //       {items.map((item, i) => {
              //         const { name, value, typeColor } = item;

              //         return (
              //           <div key={i}>
              //             <div
              //               style={{
              //                 margin: 0,
              //                 display: "flex",
              //                 justifyContent: "space-between",
              //               }}
              //             >
              //               <div>
              //                 <span
              //                   style={{
              //                     display: "inline-block",
              //                     width: 6,
              //                     height: 6,
              //                     borderRadius: "50%",
              //                     backgroundColor: typeColor,
              //                     marginRight: 6,
              //                   }}
              //                 ></span>
              //                 <span>{name}</span>
              //               </div>
              //               <b>{value}</b>
              //             </div>
              //           </div>
              //         );
              //       })}
              //     </div>
              //   );
              // },
            },
          },
          // tooltip: { channel: "y0", valueFormatter: ".0%" },
        }}
      />
    </div>
  );
};
export default GCObservationCharts;
