import { Button, Progress } from "antd";
import React, { useRef, useState } from "react";
const VideoPlayerForAnyUrl: React.FC<{ url: string }> = ({ url }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const handleVideoPlay = () => {
    videoRef.current?.play();
    setIsPlaying(true);
  };
  const handleVideoPause = () => {
    videoRef.current?.pause();
    setIsPlaying(false);
  };
  const updateTime = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
      setDuration(videoRef.current.duration);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0",
    )}`;
  };
  const handleProgress = () => {
    if (videoRef.current) {
      const progress =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;
      // if (progress === 100) setIsPlaying(false);
      return Math.floor(progress);
    }
    return 0;
  };
  const handleSeek: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (videoRef.current) {
      const seekTime =
        //@ts-ignore, clientWidth DO exist
        (e.nativeEvent.offsetX / e.target.clientWidth) * duration;
      videoRef.current.currentTime = seekTime;
      setCurrentTime(seekTime);
      handleVideoPlay();
    }
  };
  return (
    <div className="w-32">
      <video
        className="videoPlayer"
        width="70%"
        ref={videoRef}
        src={url}
        onTimeUpdate={updateTime}
        onLoadedMetadata={updateTime}
      ></video>
      <div className="flex">
        {formatTime(currentTime)}
        <div style={{ cursor: "pointer", width: "90%" }} onClick={handleSeek}>
          <Progress percent={handleProgress()} showInfo={false} />
        </div>
        {formatTime(duration)}
      </div>
      <Button
        type="primary"
        onClick={() => {
          if (isPlaying) {
            handleVideoPause();
          } else handleVideoPlay();
        }}
      >
        {isPlaying ? "Pause" : "Play"}
      </Button>
    </div>
  );
};
export default VideoPlayerForAnyUrl;
