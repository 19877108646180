import * as React from "react";
import { forwardRef } from "react";
import { ProjectSubcontractorEmployeeTableQuery } from "src/common/types/generated/relay/ProjectSubcontractorEmployeeTableQuery.graphql";
import { ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee$data } from "src/common/types/generated/relay/ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee.graphql";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "../../../../common/components/tables/basic/DataScrollTable";
import { graphql } from "babel-plugin-relay/macro";

export type ColumnKeys =
  | "name"
  | "email"
  | "phoneNumber"
  | "emergencyContact"
  | "subcontractorEmployeeTitle"
  | "status";

const CONNECTION_NAME = "project_subcontractor_employee_connection";
type DConnection =
  ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  ProjectSubcontractorEmployeeTableQuery,
  {}
> & {
  onProjectSubcontractorEmployeeRowClick?: (
    projectSubcontractorEmployee: ProjectSubcontractorEmployee
  ) => void;
  searchString?: string;
  title: string;
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    ProjectSubcontractorEmployeeTableQuery
  >["columns"];
  loading: boolean;
};
export type ProjectSubcontractorEmployee =
  ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee$data["project_subcontractor_employee_connection"]["edges"][0]["node"];
const projectSubcontractorEmployeeTable = forwardRef<DataScrollTableRef, Props>(
  (
    {
      onProjectSubcontractorEmployeeRowClick,
      searchString,
      where,
      topBarButtons,
      title,
      headerComponent,
      extraColumns,
      loading,
      ...props
    },
    ref
  ) => {
    const getName = (fullName: string) => {
      const parts = fullName.trim().split(" ");
      if (parts.length <= 1 || parts[parts.length - 1].length === 0) {
        return fullName;
      }
      const lastNameInitial = parts[parts.length - 1][0].toUpperCase();
      parts[parts.length - 1] = lastNameInitial;
      return parts.join(" ");
    };
    return (
      <>
        <DataScrollTable<
          DConnection,
          ColumnKeys,
          ProjectSubcontractorEmployeeTableQuery
        >
          {...props}
          newCustomTableLook
          ref={ref}
          title={title}
          headerComponent={headerComponent}
          loading={loading}
          onRowClick={(projectSubcontractorEmployee) =>
            onProjectSubcontractorEmployeeRowClick &&
            onProjectSubcontractorEmployeeRowClick(projectSubcontractorEmployee)
          }
          where={where}
          topBarButtons={topBarButtons}
          connectionName={CONNECTION_NAME}
          totalCountConnectionName={"allProjectSubcontractorEmployeeConnection"}
          columns={[
            {
              title: "Name",
              dataIndex: ["subcontractor_employee", "user", "name"],
              key: "name",
              defaultSortOrder: "asc",
              searchDataIndex: ["subcontractor_employee", "user", "name"],
              sortable: true,
              size: "ml",
              render: (name, projectSubcontractorEmployee) => (
                <>
                  {projectSubcontractorEmployee.emergency_contact && (
                    <div className="text-0.75 p-0.25 bg-white text-semantic-negative border-0.125 border-semantic-negative rounded-2 mb-0.25">
                      Emergency Contact
                    </div>
                  )}
                  <div>{getName(name)}</div>
                </>
              ),
            },
            {
              title: "Phone #",
              dataIndex: ["subcontractor_employee", "user", "phone_number"],
              key: "phoneNumber",
              defaultSortOrder: "asc",
              searchDataIndex: [
                "subcontractor_employee",
                "user",
                "phone_number",
              ],
              sortable: true,
              size: "sm",
              render: (phoneNumber, _) => (
                <>
                  <div>{phoneNumber ?? ""}</div>
                </>
              ),
            },
            ...(extraColumns || []),
          ]}
          queryNode={graphql`
            query ProjectSubcontractorEmployeeTableQuery(
              $first: Int!
              $after: String
              $where: project_subcontractor_employee_bool_exp!
              $order_by: [project_subcontractor_employee_order_by!]!
            ) {
              ...ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee
                @arguments(
                  first: $first
                  after: $after
                  where: $where
                  order_by: $order_by
                )
              ...ProjectSubcontractorEmployeeTable_total
                @arguments(where: $where)
            }
          `}
          totalCountNode={graphql`
            fragment ProjectSubcontractorEmployeeTable_total on query_root
            @argumentDefinitions(
              where: { type: "project_subcontractor_employee_bool_exp!" }
            )
            @refetchable(
              queryName: "ProjectSubcontractorEmployeeTotalRefetchableQuery"
            ) {
              allproject_subcontractor_employeeConnection: project_subcontractor_employee_connection(
                where: $where
              ) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          `}
          paginationNode={graphql`
            fragment ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee on query_root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              where: { type: "project_subcontractor_employee_bool_exp!" }
              order_by: { type: "[project_subcontractor_employee_order_by!]!" }
            )
            @refetchable(
              queryName: "ProjectSubcontractorEmployeeTableRefetchableQuery"
            ) {
              project_subcontractor_employee_connection(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
                @connection(
                  key: "ProjectSubcontractorEmployeeTable_project_subcontractor_employee_connection"
                  filters: []
                ) {
                edges {
                  node {
                    ...ProjectSubcontractorEmployeeFrag @relay(mask: false)
                  }
                }
              }
            }
          `}
        />
      </>
    );
  }
);

export default projectSubcontractorEmployeeTable;
