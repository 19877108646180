import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";

const helper = (dataIndex: Array<string>, order: Order_By): Object => {
  if (dataIndex.length === 1) {
    return { [dataIndex[0]]: order };
  } else {
    const currIndex = dataIndex[0];
    dataIndex.splice(0, 1);

    // query value and order by paths do not match, this needed
    if (currIndex === "aggregate") {
      return { count: order };
    }

    return { [currIndex]: helper(dataIndex, order) };
  }
};

const interpretSorter = (sorter: any) => {
  if (sorter.order === undefined) {
    return undefined;
  }

  if (typeof sorter.field === "string") {
    console.error(
      `Error: column with dataIndex ${sorter.field} must be an array.`
    );
  }

  const order =
    sorter.order === "ascend" ? Order_By.AscNullsLast : Order_By.DescNullsLast;
  const sortObj = helper([...sorter.field], order);

  return sortObj;
};

export default interpretSorter;
