import React from "react";
import { IconCheckbox } from "@tabler/icons";
import { WorkerType } from "./SiteOrientationInPersonManagerViewWorkersResult";
import clsx from "clsx";
import Image from "../../../../../../../../../common/components/general/images/Image";
import { useBoolean } from "usehooks-ts";
import SiteOrientationInPersonCollapse from "../../../../../components/SiteOrientationInPersonCollapse";

export interface SiteOrientationInPersonManagerViewWorkersResultSublistProps {
  workers: WorkerType[];
  headline: React.ReactNode;
  status: "positive" | "negative" | "neutral";
}

const SiteOrientationInPersonManagerViewWorkersResultSublist: React.FC<
  SiteOrientationInPersonManagerViewWorkersResultSublistProps
> = (props) => {
  const { setTrue: show, setFalse: hide, value: expanded } = useBoolean(true);
  return (
    <div className={`border-b border-suplementary-3`}>
      <SiteOrientationInPersonCollapse
        headText={
          <p>
            <span className={`font-accent`}>{props.workers.length}</span>{" "}
            {props.headline}
          </p>
        }
        hide={hide}
        expand={show}
        expanded={expanded}
      >
        <div className={`flex flex-col gap-0.25`}>
          {props.workers.map((worker) => (
            <div
              className={clsx(
                `flex flex-row items-stretch p-px rounded-0.25`,
                (() => {
                  switch (props.status) {
                    case "negative":
                      return `bg-semantic-negative-light`;
                    case "positive":
                      return `bg-semantic-positive-light`;
                    case "neutral":
                      return `bg-semantic-pending-light`;
                  }
                })()
              )}
              key={worker.id}
            >
              <div
                className={`overflow-hidden bg-white w-3 min-h-4 h-auto rounded-0.25`}
              >
                <Image
                  transparentBackground
                  src={worker.imgSrc}
                  alt={worker.name}
                />
              </div>
              <div
                className={clsx(
                  `flex flex-row items-center justify-between p-0.5 gap-1 h-full flex-1`,
                  (() => {
                    switch (props.status) {
                      case "negative":
                        return `text-semantic-negative-dark`;
                      case "positive":
                        return `text-semantic-positive-dark`;
                      case "neutral":
                        return `text-semantic-pending-dark`;
                    }
                  })()
                )}
              >
                <div className={`flex flex-col gap-0 text-1`}>
                  <p className={`font-accent`}>{worker.name}</p>
                  <p>
                    {worker.hardHatNumber ? `#${worker.hardHatNumber}` : ""}
                  </p>
                  <p>{`${[worker.company.title, worker.trade].join(", ")}`}</p>
                </div>

                <div
                  className={clsx(
                    `flex flex-1 flex-col whitespace-nowrap text-right  items-end gap-0.25`,
                    worker.corporateOrientationPassed ||
                      worker.universalCompleted
                      ? `text-semantic-positive-dark`
                      : ``
                  )}
                >
                  <div className={`flex flex-row items-center gap-0.5`}>
                    <p className={`font-accent`}>
                      {/* {props.orientationValid ? `Valid` : "Required"} */}
                    </p>
                    {worker.corporateOrientationPassed && (
                      <>
                        <IconCheckbox />{" "}
                        <p className={`text-0.75`}>Company Orientation</p>
                      </>
                    )}
                    {worker.universalCompleted && (
                      <>
                        <IconCheckbox /> <p className={`text-0.75`}>CARE</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </SiteOrientationInPersonCollapse>
    </div>
  );
};

export default SiteOrientationInPersonManagerViewWorkersResultSublist;
