import { Button, message } from "antd";
import React, { useState } from "react";
import SubcontractorBillingDetailsModal from "src/common/components/modals/SubcontractorBillingDetailsModal";
import {
  useStripeCreatePortalSessionMutation,
  useStripeGetAccountInfoQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import { SubProjectMobilizationViewQuery$data } from "src/common/types/generated/relay/SubProjectMobilizationViewQuery.graphql";
import handleRequestError from "src/utility-features/error-handling/handleRequestError";

export interface SubProjectMobilizationStepBillingProps {
  projectId: string;
  subcontractorId: string;
  subcontractor: SubProjectMobilizationViewQuery$data["subcontractor_connection"]["edges"][0]["node"];
  onPrev: () => void;
  onNext: (isUploaded: boolean) => void;
}

const SubProjectMobilizationStepBilling: React.FC<
  SubProjectMobilizationStepBillingProps
> = (props) => {
  const [openBillingModal, setOpenBillingModal] = useState(false);
  const projectId = props.projectId;
  const subcontractorId = props.subcontractorId;

  const [createStripeSession] = useStripeCreatePortalSessionMutation();

  const { data: stripeAccountInfo } = useStripeGetAccountInfoQuery({
    variables: {
      projectId,
    },
  });

  const defaultBillingEmail =
    props.subcontractor.billing_settings?.default_billing_email;
  const projectSubcontractorStripeAccountId =
    props.subcontractor.subcontractor_projects &&
    props.subcontractor.subcontractor_projects.length > 0
      ? props.subcontractor.subcontractor_projects[0].billing_settings
          ?.account_id
      : null;

  const projectSubcontractorBillingEmail =
    props.subcontractor.subcontractor_projects &&
    props.subcontractor.subcontractor_projects.length > 0
      ? props.subcontractor.subcontractor_projects[0].billing_settings
          ?.stripe_account?.email
      : null;

  const stripeAccountHasCard = stripeAccountInfo?.stripeGetAccountInfo.hasCard;

  const openStripePortal = async () => {
    try {
      const res = await createStripeSession({
        variables: {
          return_url: document.location.href,
          project_id: projectId,
        },
      });
      const url = res.data?.stripeCreatePortalSession.url;
      if (url) {
        document.location.href = url;
      } else {
        handleRequestError(new Error("Unable to open stripe portal"));
      }
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <div>
      <SubcontractorBillingDetailsModal
        visible={openBillingModal}
        subcontractorId={subcontractorId}
        projectId={projectId}
        defaultEmail={defaultBillingEmail}
        onClose={() => setOpenBillingModal(false)}
        onSubmit={() => {
          setOpenBillingModal(false);
          props.onNext(true);
        }}
      />
      {(!projectSubcontractorBillingEmail ||
        !projectSubcontractorStripeAccountId) && (
        <div>Add email address to receive invoices</div>
      )}
      {projectSubcontractorBillingEmail &&
        projectSubcontractorStripeAccountId && (
          <div>
            Email Address Invoices are Sent to :{" "}
            {projectSubcontractorBillingEmail}
          </div>
        )}
      <div className="mt-1">
        <Button
          className="mr-1"
          onClick={() => {
            props.onPrev();
          }}
        >
          Back
        </Button>

        <Button
          className="mr-1"
          onClick={() => {
            props.onNext(false);
          }}
        >
          Next
        </Button>
        {(!projectSubcontractorStripeAccountId ||
          !projectSubcontractorBillingEmail) && (
          <Button
            className="mr-1"
            onClick={() => {
              setOpenBillingModal(true);
            }}
          >
            Add billing email{" "}
          </Button>
        )}
        {projectSubcontractorStripeAccountId &&
          projectSubcontractorBillingEmail &&
          !stripeAccountHasCard && (
            <Button className="mr-1" onClick={openStripePortal}>
              Add Credit Card
            </Button>
          )}
        {projectSubcontractorStripeAccountId &&
          projectSubcontractorBillingEmail && (
            <Button onClick={openStripePortal}>Change billing address</Button>
          )}
      </div>
    </div>
  );
};

export default SubProjectMobilizationStepBilling;
