import { Bar } from "@ant-design/plots";
import { Card } from "antd";
import { useMemo } from "react";
import { useGetOrientationDashboardDataQuery } from "src/common/types/generated/apollo/graphQLTypes";
import MapCoordinatePoints from "src/common/components/leaflet-maps/MapCoordinatePoints";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import clsx from "clsx";
import LargeTableSkeleton from "src/common/components/tables/basic/LargeTableSkeletion";
import randomHexColor from "src/common/functions/randomHexColor";
import getDashboardDataMaps, {
  MapType,
  StateMapDataType,
  ZipMapDataType,
} from "./utils/getDashboardDataMaps";
import DashboardChartSettings, {
  chartSettingsFields,
} from "./DashboardChartSettings";

//https://ant-design-charts.antgroup.com/en/examples/statistics/bar/#bar
const colorsList = [
  "#67B4FE",
  "#2353B6",
  "#142D60",
  "#000033",
  "#929DAE",
  "#D3E4F8",
  "#E9EFF6",
  "#FFAB77",
  "#FF6C6C",
  "#FFDE59",
];

const BarChartToShow: React.FC<{
  data: MapType;
  title: string;
  show?: boolean;
  full?: boolean;
  height?: number;
}> = ({ data, title, full, height, show }) => {
  const sortedData = Array.from(data.values())
    .sort((a, b) => b.count - a.count)
    .map((p, i) => ({ ...p, color: colorsList[i] || randomHexColor() }));
  if (!sortedData.length || !show) return null;
  return (
    <Card
      title={<span>{title}</span>}
      styles={{ header: { backgroundColor: "#F0F2F5" } }}
      className={clsx(
        full ? "w-full" : "w-1/2",
        // "h-[33vh] object-contain"
      )}
    >
      <Bar
        {...{
          data: sortedData,
          xField: "name",
          yField: "count",
          barWidthRatio: 0.9,
          autoFit: true,
          height: !height || height < 300 ? 300 : height,
          paddingRight: 5,
          style: { fill: "color" },
          // colorField: "type",
          label: {
            position: "right",
            style: {
              fill: "#000",
              textAlign: "start",
              fontSize: 17,
            },
          },
        }}
      />
    </Card>
  );
};

const GCProjectOrientationDashboard: React.FC<{ projectId: string }> = ({
  projectId,
}) => {
  const { data, refetch, loading, error } = useGetOrientationDashboardDataQuery(
    {
      variables: {
        projectId,
        where: {
          project_id: { _eq: projectId },
          is_last: { _eq: true },
          deleted_at: { _is_null: true },
        },
      },
    },
  );

  const project = data?.project_by_pk;
  const {
    roleMap,
    veteranTypeMap,
    ethnicityMap,
    genderMap,
    cityMap,
    zipMap,
    stateMap,
    yearsInConstructionMap,
    yearsWithEmployerMap,
  } = useMemo(() => getDashboardDataMaps(data), [data]);
  // const [componentHeight, setComponentHeight] = useState(
  //   (window.innerHeight * 4) / 5,
  // );

  // useEffect(() => {
  //   const handleResize = () => {
  //     setComponentHeight((window.innerHeight * 4) / 5);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   // Cleanup listener on component unmount
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // const barHeight = (componentHeight * 21) / 80;
  // const mapHeight = componentHeight - barHeight;
  if (loading) return <LargeTableSkeleton />;
  if (!data) throw error;
  if (!project) throw new Error("project not found");
  const chartSettings =
    project.project_setting?.orientation_chart_settings_json;

  return (
    <div
      className={`w-full relative h-full bg-white rounded-2 overflow-y-auto z-0`}
    >
      <DashboardChartSettings
        refetch={refetch}
        projectId={projectId}
        chartSettings={chartSettings}
      />
      <div className="flex flex-wrap">
        <div className="w-1/2">
          <BarChartToShow
            data={genderMap}
            full
            title="Gender"
            show={!chartSettings?.[chartSettingsFields.gender]}
          />
          <BarChartToShow
            data={ethnicityMap}
            full
            title="Ethnicity"
            height={ethnicityMap.size * 20}
            show={!chartSettings?.[chartSettingsFields.ethnicity]}
          />
        </div>
        {!!zipMap.size && (
          <Card
            title={<span>Residency Map</span>}
            styles={{ header: { backgroundColor: "#F0F2F5" } }}
            className={"w-1/2"}
          >
            {chartSettings?.residency_map === "state"
              ? !!stateMap.size && (
                  <MapCoordinatePoints<StateMapDataType>
                    data={Array.from(stateMap.values())}
                    height={700}
                    renderPopup={(state) =>
                      `State: ${state.name}, ${state.code} Count: ${state.count}`
                    }
                  />
                )
              : !!zipMap.size && (
                  <MapCoordinatePoints<ZipMapDataType>
                    data={Array.from(zipMap.values())}
                    height={700}
                    renderPopup={(czip) =>
                      `Zip Code: ${czip.zip_code}, City: ${czip.city.name}, Count: ${czip.count}`
                    }
                  />
                )}
          </Card>
        )}

        <BarChartToShow
          data={veteranTypeMap}
          title="Veteran"
          show={!chartSettings?.[chartSettingsFields.veteran]}
        />

        <BarChartToShow
          data={yearsInConstructionMap}
          title="Years in Construction"
          height={yearsInConstructionMap.size * 20}
          show={!chartSettings?.[chartSettingsFields.years_in_construction]}
        />
        <BarChartToShow
          data={yearsWithEmployerMap}
          height={yearsWithEmployerMap.size * 20}
          title="Years with Employer"
          show={!chartSettings?.[chartSettingsFields.years_with_employer]}
        />
        <BarChartToShow
          data={roleMap}
          title="Role"
          show
          height={roleMap.size * 20}
        />
        {chartSettings?.residency_map !== "state" && (
          <BarChartToShow
            show={!chartSettings?.[chartSettingsFields.residency]}
            data={cityMap}
            title={`Residency (${project.project_local_cities.length} Local Cities)`}
          />
        )}
        {chartSettings?.residency_map === "state" && (
          <BarChartToShow
            show={!chartSettings?.[chartSettingsFields.residency]}
            data={stateMap}
            height={stateMap.size * 20}
            title={`State`}
            full
          />
        )}
      </div>
    </div>
  );
};
export default withCustomSuspense(GCProjectOrientationDashboard);
