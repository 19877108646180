import React, { useState, useRef, useEffect } from "react";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import * as uuid from "uuid";
import { Button, Modal, Form, Input, Radio, Select } from "antd";
import getNormalOptionsFilter from "src/common/functions/getNormalOptionsFilter";

interface Values {
  user: Array<string>;
}

interface AddTaskAdminModalProps {
  visible: boolean;
  onCreate: (values: Values) => void;
  initialValue: Array<string>;
  taskAdmins: Array<{ id: string; name: string }>;
  taskAdminOptions: Array<{ id: string; name: string }>;
  onCancel: () => void;
}

const AddTaskModal: React.FC<AddTaskAdminModalProps> = ({
  visible,
  onCreate,
  initialValue,
  taskAdmins,
  taskAdminOptions,
  onCancel,
}) => {
  const modalRef = useRef<FModalRef<Values>>(null);
  const [form] = Form.useForm();
  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <FModal
      open={visible}
      ref={modalRef}
      title="JHA Lead"
      okText="Add"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values as Values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      destroyOnClose
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="user"
          label="Team Member responsible for this JHA"
          rules={[{ required: true, message: "Enter a task Admin" }]}
          initialValue={initialValue}
        >
          <Select
            placeholder={"Select person assigned to this JHA"}
            className="w-70"
            mode={"multiple"}
            showSearch
            filterOption={getNormalOptionsFilter}
            options={taskAdminOptions.map((option) => ({
              id: option.id,
              value: option.id,
              label: option.name,
              selected:
                taskAdmins.findIndex((admin) => admin.id == option.id) !== -1,
            }))}
          >
            {/* {taskAdminOptions.map((s) => (
              <Select.Option key={s.id} value={s.id} lable={s.name}  >
                {s.name}
              </Select.Option>
            ))} */}
          </Select>
        </Form.Item>
      </Form>
    </FModal>
  );
};

export default AddTaskModal;
