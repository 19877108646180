import * as React from "react";
import { forwardRef } from "react";
import { ProjectWorkerPocTableQuery } from "src/common/types/generated/relay/ProjectWorkerPocTableQuery.graphql";
import { ProjectWorkerPocTable_projectWorkerPoc$data } from "src/common/types/generated/relay/ProjectWorkerPocTable_projectWorkerPoc.graphql";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "../../../../common/components/tables/basic/DataScrollTable";
import { graphql } from "babel-plugin-relay/macro";

export type ColumnKeys = "title" | "name" | "phoneNumber";
const CONNECTION_NAME = "project_worker_poc_connection";
type DConnection =
  ProjectWorkerPocTable_projectWorkerPoc$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  ProjectWorkerPocTableQuery,
  {}
> & {
  onProjectWorkerPocRowClick?: (projectWorkerPoc: projectWorkerPoc) => void;
  searchString?: string;
  title: string;
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    ProjectWorkerPocTableQuery
  >["columns"];
  loading: boolean;
};
export type projectWorkerPoc =
  ProjectWorkerPocTable_projectWorkerPoc$data["project_worker_poc_connection"]["edges"][0]["node"];
const ProjectWorkerPocTable = forwardRef<DataScrollTableRef, Props>(
  (
    {
      onProjectWorkerPocRowClick,
      searchString,
      where,
      topBarButtons,
      title,
      extraColumns,
      loading,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <DataScrollTable<DConnection, ColumnKeys, ProjectWorkerPocTableQuery>
          {...props}
          newCustomTableLook
          ref={ref}
          title={title}
          loading={loading}
          onRowClick={(projectWorkerPoc) =>
            onProjectWorkerPocRowClick &&
            onProjectWorkerPocRowClick(projectWorkerPoc)
          }
          where={where}
          topBarButtons={topBarButtons}
          connectionName={CONNECTION_NAME}
          totalCountConnectionName={"allprojectWorkerPocConnection"}
          columns={[
            {
              title: "Name",
              dataIndex: ["name"],
              key: "name",
              defaultSortOrder: "asc",
              searchDataIndex: ["name"],
              sortable: true,
              size: "sm",
            },
            {
              title: "Title",
              dataIndex: ["title"],
              key: "title",
              defaultSortOrder: "asc",
              searchDataIndex: ["title"],
              sortable: true,
              size: "sm",
            },
            {
              title: "Phone #",
              dataIndex: ["phone_number"],
              key: "phoneNumber",
              defaultSortOrder: "asc",
              searchDataIndex: ["phone_number"],
              sortable: true,
              size: "sm",
            },

            ...(extraColumns || []),
          ]}
          queryNode={graphql`
            query ProjectWorkerPocTableQuery(
              $first: Int!
              $after: String
              $where: project_worker_poc_bool_exp!
              $order_by: [project_worker_poc_order_by!]!
            ) {
              ...ProjectWorkerPocTable_projectWorkerPoc
                @arguments(
                  first: $first
                  after: $after
                  where: $where
                  order_by: $order_by
                )
              ...ProjectWorkerPocTable_total @arguments(where: $where)
            }
          `}
          totalCountNode={graphql`
            fragment ProjectWorkerPocTable_total on query_root
            @argumentDefinitions(
              where: { type: "project_worker_poc_bool_exp!" }
            )
            @refetchable(queryName: "projectWorkerPocTotalRefetchableQuery") {
              allproject_worker_pocConnection: project_worker_poc_connection(
                where: $where
              ) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          `}
          paginationNode={graphql`
            fragment ProjectWorkerPocTable_projectWorkerPoc on query_root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              where: { type: "project_worker_poc_bool_exp!" }
              order_by: { type: "[project_worker_poc_order_by!]!" }
            )
            @refetchable(queryName: "ProjectWorkerPocTableRefetchableQuery") {
              project_worker_poc_connection(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
                @connection(
                  key: "ProjectWorkerPocTable_project_worker_poc_connection"
                  filters: ["where", "order_by"]
                ) {
                edges {
                  node {
                    ...ProjectWorkerPocFrag @relay(mask: false)
                  }
                }
              }
            }
          `}
        />
      </>
    );
  }
);

export default ProjectWorkerPocTable;
