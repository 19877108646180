import { Space, Button, Input } from "antd";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateProjectModal from "src/common/components/dialogs/CreateProjectModal";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import useAuthUser from "src/common/hooks/useAuthUser";
import useColumnOrderBy from "src/common/hooks/useColumnOrderBy";
import useColumnSearch from "src/common/hooks/useColumnSearch";
import usePaginatedQuery, {
  UsePaginatedQueryParams,
} from "src/common/hooks/usePaginatedQuery";
import {
  Project_Order_By,
  Order_By,
  Project,
  Project_Bool_Exp,
  GetProjectsForCsrDocument,
  useInsertProjectsMutation,
  GetProjectsForCsrQuery,
  useInsertCsrEditMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import * as uuid from "uuid";

const CsrProjects: React.FunctionComponent = () => {
  const { orderBy, setOrderBy } = useColumnOrderBy<Project_Order_By>({
    name: Order_By.Asc,
  });
  console.log("HSDa");
  const nameSearch = useColumnSearch();
  const queryVariables = useMemo(
    (): UsePaginatedQueryParams<Project_Bool_Exp> => ({
      where: {
        name: {
          _ilike: nameSearch.value,
        },
      },
      orderBy: orderBy,
      queryDoc: GetProjectsForCsrDocument,
      dataIndex: "project",
      aggregateCountIndex: "project_aggregate",
    }),
    [nameSearch.value, orderBy],
  );
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const [projects, { loading, page, setPage, count, pageSize, refetch }] =
    usePaginatedQuery<GetProjectsForCsrQuery["project"][number]>(
      queryVariables,
    );
  const columns = [
    {
      title: "Project Name",
      key: "name",
      dataIndex: ["name"],
    },
    {
      title: "General Contractor",
      key: "gc",
      dataIndex: ["general_contractor", "name"],
    },
  ];
  const [insertCsrEdit] = useInsertCsrEditMutation();
  const [createProjectOpen, setCreateProjectOpen] = useState(false);
  const [insertProject, { loading: creatingProject }] =
    useInsertProjectsMutation();
  return (
    <>
      <CreateProjectModal
        visible={createProjectOpen}
        onCancel={() => setCreateProjectOpen(false)}
        onCreate={async ({ values }) => {
          setCreateProjectOpen(false);
          const id = uuid.v4();
          const projObj = {
            id,
            orientation_project_id: id,
            name: values.name,
            union_labor: values.union,
            general_contractor_id: values.generalContractorId,
            general_contractors: {
              data: [
                {
                  general_contractor_id: values.generalContractorId,
                },
              ],
            },
            external_id: values.externalId,
            address: {
              data: {
                line_1: values.addressLine1,
                city: values.city,
                state_code: values.stateCode,
                zip_code: values.zip,
              },
            },
            start_date: values.startDate,
            end_date: values.endDate,
          };
          await insertProject({ variables: { objects: [projObj] } });
          refetch();

          await insertCsrEdit({
            variables: {
              csrEdits: {
                change_log: [projObj],
                csr_user_id: authUser.uid,
                edit_text: `inserted new project: ${values.name}`,
                entity_id: id,
                name: "project",
                operation_name: "insert",
              },
            },
          });
        }}
      />
      <BaseTable
        onRow={(project) => ({
          onClick: () => navigate(`/csr/tasks/projects/${project.id}`),
        })}
        loading={loading}
        columns={columns}
        sortColumns={{
          name: true,
          workers: true,
        }}
        onChange={(_: any, filters: any, sorter: any) => {
          setOrderBy(sorter);
        }}
        title={() => (
          <Space direction="vertical">
            <div className="flex gap-1">
              <h3>Projects</h3>
              <Button
                type="primary"
                onClick={() => {
                  setCreateProjectOpen(true);
                }}
                loading={creatingProject}
              >
                Add New Project
              </Button>
            </div>

            <Input
              type="primary"
              style={{ margin: "10px", width: "200px" }}
              placeholder={"Search"}
              onChange={(event) => {
                nameSearch.setter(event.target.value);
              }}
            ></Input>
          </Space>
        )}
        searchColumns={{ name: nameSearch.setter }}
        dataSource={projects}
        pagination={{
          current: page,
          pageSize,
          total: count,
          onChange: setPage,
        }}
      />
    </>
  );
};
export default CsrProjects;
