import { Form, Input, message, Modal, notification, Select } from "antd";
import React, { useState } from "react";
import { useRelayEnvironment } from "react-relay/hooks";
import { getSameEmailUser } from "src/common/functions/sameEmailUsers";
import { useGetSubcontractorEmployeeTitlesQuery } from "src/common/types/generated/apollo/graphQLTypes";
import ConfirmConvertWorkerToSubadminModal from "./ConfirmConvertWorkerToSubadminModal";

interface AddSubcontractorEmployeeValues {
  name: string;
  phoneNumber: string;
  email: string;
  titleId: string;
}

interface AddSubcontractorEmployeeModalProps {
  visible: boolean;
  onCreate: (values: AddSubcontractorEmployeeValues) => void;
  onCancel: () => void;
  requirement: boolean;
}

const AddSubcontractorEmployeeModal: React.FC<
  AddSubcontractorEmployeeModalProps
> = ({ visible, onCreate, onCancel, requirement }) => {
  const { data, loading } = useGetSubcontractorEmployeeTitlesQuery();
  // const { data, loading } = useGetEmployeeTitlesQuery();
  const [form] = Form.useForm();
  const environment = useRelayEnvironment();

  const handleClose = () => {
    setLoading(false);
    form.resetFields();
    onCancel();
  };

  const [
    showConvertWorkerToSubadminModal,
    setShowConvertWorkerToSubadminModal,
  ] = useState(false);


  const convertWorkerModalClose = () => {
    setShowConvertWorkerToSubadminModal(false);
  };
  const [load, setLoading] = useState(false);

  const [workerId, setWorkerId] = useState<string | null>(null);

  return (<>
    <Modal
      open={visible}
      title="Add Administrators to your company"
      okText="Add"
      cancelText="Cancel"
      confirmLoading={load}
      onCancel={handleClose}
      onOk={() => {
        form.validateFields().then(async (values) => {
          const email = values.email;
          let isSameEmailUserPresent;
          setLoading(true);
          try {
            isSameEmailUserPresent = await getSameEmailUser(
              email,
              environment
            );
          } finally {
            setLoading(false);
          }

          if (isSameEmailUserPresent.user_connection.edges[0]) {
            if (
              isSameEmailUserPresent.user_connection.edges[0].node.role ==
              "worker"
            ) {
              setWorkerId(
                isSameEmailUserPresent.user_connection.edges[0].node.pk
              );
              setShowConvertWorkerToSubadminModal(true);
            } else {
              notification.info({
                message:
                  "User registered as " +
                  isSameEmailUserPresent.user_connection.edges[0].node.role,
              });
            }
          } else {
            onCreate(values as AddSubcontractorEmployeeValues);
          }
          handleClose();
        });
      }}
    >
      <p>New Administrators will be sent an email to set up their profile.</p>
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="name"
          label="First and last name"
          rules={[{ required: true, message: "Enter a name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="Phone number"
          rules={[{ required: false, message: "Enter a phone number" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email address"
          rules={[{ required: true, message: "Enter an email address" }]}
        >
          <Input type="email" autoCorrect="off" autoCapitalize="none" />
        </Form.Item>
        <Form.Item
          name="titleId"
          label="Title"
          rules={[{ required: true, message: "Choose a title" }]}
        >
          <Select
            loading={loading}
            disabled={loading}
            style={{ width: "100%" }}
          >
            {data?.subcontractor_employee_title.map((title) => (
              <Select.Option key={title.id} value={title.id}>
                {title.name.en.toUpperCase()}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
    {workerId && <ConfirmConvertWorkerToSubadminModal
      modalClose={convertWorkerModalClose}
      modalVisible={showConvertWorkerToSubadminModal}
      workerId={workerId}
    />}
  </>);
};

export default AddSubcontractorEmployeeModal;
