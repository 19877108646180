import React from "react";
import * as Sentry from "@sentry/react";
import ErrorFallback from "./ErrorFallback";
import sendHotjarEvent from "src/utility-features/event-tracking/hotjar/sendHotjarEvent";

interface ErrorBoundaryProps {
  fallback?: Sentry.ErrorBoundaryProps["fallback"];
  children?: React.ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = (props) => {
  return (
    <Sentry.ErrorBoundary
      fallback={
        props.fallback ??
        (({ error, resetError }) => (
          <ErrorFallback onRetry={resetError} error={error} />
        ))
      }
      onError={() => {
        sendHotjarEvent("error");
      }}
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
