import React from "react";
import { Alert, Button } from "antd";

interface AudioPlayerErrorFallbackProps {
  error: any;
  resetError: () => void;
}

const AudioPlayerErrorFallback: React.FC<AudioPlayerErrorFallbackProps> = (
  props
) => {
  const message =
    props.error instanceof Error ? props.error.message : "An error occurred";

  return (
    <Alert
      message={message}
      showIcon
      type="error"
      action={
        <Button type={"primary"} onClick={props.resetError}>
          Try Again
        </Button>
      }
    />
  );
};

export default AudioPlayerErrorFallback;
