import { Form, Input, InputNumber, Modal, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConnectionHandler } from "relay-runtime";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { CreateNewOrientationModalInsertMutation } from "src/common/types/generated/relay/CreateNewOrientationModalInsertMutation.graphql";
import useAuthUser from "src/common/hooks/useAuthUser";
import { useInsertCsrEditMutation } from "src/common/types/generated/apollo/graphQLTypes";

const { Option } = Select;

interface CreateNewOrientationModalProps {
  visible: boolean;
  projectId?: string;
  gcProjectIds: Array<string>;
  gcId?: string;
  insertCsrHistory?: boolean;
  type?: "universal" | "corporate" | "site";
  onCancel?: () => void;
  onSuccess?: (oId: string) => void | Promise<void>;
}

const CreateNewOrientationModal: React.FC<CreateNewOrientationModalProps> = ({
  visible,
  projectId,
  gcId,
  insertCsrHistory,
  gcProjectIds,
  onCancel,
  type,
  onSuccess,
}) => {
  const [insertOrientation] =
    useAsyncMutation<CreateNewOrientationModalInsertMutation>(graphql`
      mutation CreateNewOrientationModalInsertMutation(
        $object: orientation_insert_input!
      ) {
        insert_orientation_one(object: $object) {
          pk: id @__clientField(handle: "pk")
          name
          created_at
        }
      }
    `);
  const [insertCsrEdit] = useInsertCsrEditMutation();
  const authUser = useAuthUser();
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const handleCancel = () => {
    form.resetFields();
    console.log("cancel clicked");
    if (onCancel) onCancel();
  };
  return (
    <Modal
      title="Create a New Orientation"
      open={visible}
      onCancel={handleCancel}
      confirmLoading={saving}
      forceRender={true}
      onOk={async () => {
        try {
          setSaving(true);
          const values = await form.validateFields();
          const toInsert = {
            name: values.oname,
            type: values.otype,
            duration_valid: values.ovalid,
            created_by_user_id: auth.currentUser?.uid,
            ...(values.otype === "universal"
              ? {}
              : {
                  general_contractor_id: gcId,
                  project_id: values.otype === "site" ? projectId : null,
                }),
            project_orientations: {
              data:
                values.otype === "corporate"
                  ? gcProjectIds.map((p) => ({
                      project_id: p,
                    }))
                  : values.otype === "universal"
                  ? [{ required_by_all_workers: true }]
                  : [{ project_id: projectId }],
            },
          };
          const res = await insertOrientation({
            variables: { object: toInsert },
            updater: (store) => {
              const node = store.getRootField("insert_orientation_one");
              // console.log('Node: ', node);
              const conn = store
                .getRoot()
                .getLinkedRecord("orientation_connection", {
                  //      where: {  project_id: { _eq: projectId } }
                });
              if (conn) {
                /*                      const edgeID = generateClientID(conn.getDataID(), "edge", 0);
                      let edge = store.get(edgeID);
                      if (!edge) {
                        edge = store.create(edgeID, "edge");
                        edge.setLinkedRecord(node, 'node');
                        ConnectionHandler.insertEdgeBefore(conn, edge);
                      }*/
                const edge = ConnectionHandler.createEdge(
                  store,
                  conn,
                  node,
                  "edge",
                );
                ConnectionHandler.insertEdgeBefore(conn, edge);
              }
            },
          });
          const orientationId = res?.insert_orientation_one?.pk;
          if (!orientationId)
            throw new Error(
              "No orientation id found for inserted orientation.",
            );

          // console.log("REs: ", res);

          if (onSuccess) onSuccess(orientationId);
          if (insertCsrHistory)
            await insertCsrEdit({
              variables: {
                csrEdits: [
                  {
                    change_log: [toInsert],
                    csr_user_id: authUser.uid,
                    edit_text: `added new project employee relation`,
                    entity_id: orientationId,
                    name: "project_employee",
                    operation_name: "insert",
                  },
                ],
              },
            });
        } finally {
          setSaving(false);
        }
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ otype: type ?? "site", ovalid: 12 }}
      >
        <Form.Item
          name="oname"
          label="Orientation Name"
          rules={[{ required: true, message: "Orientation name is required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="otype" label="Orientation Type">
          <Select disabled={!!type}>
            <Option value="corporate">Company</Option>
            <Option value="site">Site Specific</Option>
            {!projectId && <Option value="universal">Universal</Option>}
          </Select>
        </Form.Item>
        <Form.Item
          name="ovalid"
          label="Number of months Orientation is valid for workers"
          rules={[{ required: true, message: "Orientation name is required" }]}
        >
          <InputNumber min={1} disabled={type === "universal"} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateNewOrientationModal;
