import GCProjectIncidents from "./GCProjectIncidents";
import GcIncidentManagement from "./GcIncidentManagement";
import EnvironmentalIncident from "../../components/incident-types/EnvironmentalIncident";
import EquipmentIncident from "../../components/incident-types/EquipmentIncident";
import NearMissIncident from "../../components/incident-types/NearMissIncident";
import IncidentDetail from "../../components/IncidentDetail";
import PropertyDamageIncident from "../../components/incident-types/PropertyDamageIncident";
import VehicleIncident from "../../components/incident-types/VehicleIncident";
import UtilityIncident from "../../components/incident-types/UtilityIncident";
import TheftIncident from "../../components/incident-types/TheftIncident";
import IncidentManagementInjury from "../../components/IncidentManagementInjury";
import IncidentNotesTable from "../../components/table/IncidentNotesTable";
import IncidentProjectInfo from "../../components/IncidentProjectInfo";
import RootCauseAnalysis from "../../components/RootCauseAnalysis";
import WitnessDetail from "../../components/incident-users/WitnessDetail";
import IncidentLogsTable from "src/domain-features/incident-management/components/table/IncidentLogsTable";
import { Outlet } from "react-router-dom";

const gcIncidentRoute = {
  element: <Outlet />,
  children: [
    { path: "", element: <GCProjectIncidents /> },
    {
      path: ":incidentId",
      element: <GcIncidentManagement />,
      children: [
        {
          path: "project_info",
          element: <IncidentProjectInfo />,
        },
        {
          path: "incident_detail",
          element: <IncidentDetail />,
        },
        {
          path: "environmental",
          element: <EnvironmentalIncident />,
        },
        {
          path: "equipment",
          element: <EquipmentIncident />,
        },
        {
          path: "near_miss",
          element: <NearMissIncident />,
        },
        {
          path: "property_damage",
          element: <PropertyDamageIncident />,
        },
        {
          path: "vehicle",
          element: <VehicleIncident />,
        },
        {
          path: "utility",
          element: <UtilityIncident />,
        },
        {
          path: "theft",
          element: <TheftIncident />,
        },
        {
          path: "injured_user",
          element: <IncidentManagementInjury />,
        },
        {
          path: "witness",
          element: <WitnessDetail />,
        },
        {
          path: "root_cause",
          element: <RootCauseAnalysis />,
        },
        {
          path: "notes",
          element: <IncidentNotesTable />,
        },
        {
          path: "logs",
          element: <IncidentLogsTable />,
        },
      ],
    },
  ],
};

export default gcIncidentRoute;
