import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Space, Select, InputNumber, Button, DatePicker, Input } from "antd";
import Switcher from "src/common/components/general/Switcher";
import { IconCheck, IconX } from "@tabler/icons";
import capitalize from "src/common/functions/capitalize";
import {
  GetAdminProjectDocument,
  GetAdminProjectQuery,
  useDeleteSiteboardScreenMutation,
  useUpsertSiteboardScreenMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useApolloClient } from "@apollo/client";
import dayjs from "dayjs";
import SiteBoardScreenForm, {
  SiteBoardScreenFormRef,
} from "./SiteBoardScreenForm";

//type EditState = NonNullable<GetGeneralContractorByPkQuery["general_contractor_by_pk"]>["billing_settings"];

interface ProjectSettings {
  price: number;
  raspberyDeviceOneTimePrice: number;
  enabled?: boolean;
  priceType: string;
}
type ScreenDataType = NonNullable<
  GetAdminProjectQuery["project_by_pk"]
>["siteboard_screens"][number];
export interface SiteBoardPriceSelectorProps {
  title: string;
  settings: ProjectSettings;
  data?: GetAdminProjectQuery;
  editing: boolean;
  priceEditing?: boolean;
  saving: boolean;
  project?: {
    id: string;
    siteboardScreens: ScreenDataType[];
  };
  savePrice: () => void;
}

//type ProjectPrice = FreePrice | FixedPrice | UserPrice | StripePrice
export interface SiteBoardPriceSelectorRef {
  getEditedPrice: () => ProjectSettings;
}

const SiteBoardPriceSelector = forwardRef<
  SiteBoardPriceSelectorRef,
  SiteBoardPriceSelectorProps
>(
  (
    {
      editing,
      saving,
      settings,
      project,
      title,
      data,
      savePrice,
      priceEditing,
    },
    ref,
  ) => {
    const [upsertScreens, { loading: upserting }] =
      useUpsertSiteboardScreenMutation();
    const screensRef = useRef<SiteBoardScreenFormRef>(null);
    const [enabled, setEnabled] = useState(settings.enabled);
    const [priceType, setPriceType] = useState(settings.priceType);
    const [price, setPrice] = useState(settings.price / 100);
    const [raspberyPiPrice, setRaspberyPiPrice] = useState(
      settings.raspberyDeviceOneTimePrice / 100,
    );
    const client = useApolloClient();
    useImperativeHandle(ref, () => ({
      getEditedPrice: (): ProjectSettings => {
        screensRef.current && screensRef.current.onApply();
        if (isNaN(price) || isNaN(raspberyPiPrice)) {
          throw new Error("Please enter correct number");
        }
        return {
          enabled,
          priceType,
          price: price * 100,
          raspberyDeviceOneTimePrice: raspberyPiPrice * 100,
        };
      },
    }));
    return (
      <div style={{ margin: "20px" }}>
        {typeof settings.enabled !== "undefined" && (
          <div className="flex mt-1 items-center">
            SiteBoard Feature:
            <div className="ml-2">
              {priceEditing || saving ? (
                <Switcher
                  options={[
                    { icon: IconX, label: "OFF" },
                    { icon: IconCheck, label: "ON" },
                  ]}
                  initialSelectedOptionIndex={enabled ? 1 : 0}
                  onChange={async (index) => setEnabled(!!index)}
                />
              ) : (
                <div>{enabled ? "ON" : "OFF"}</div>
              )}
            </div>
          </div>
        )}
        <div className="flex mt-1 items-center">
          SiteBoard Feature Pricing:
          {editing ? (
            <Select
              className="w-8 ml-2"
              disabled={saving}
              options={[
                { value: "default", label: "Default" },
                { value: "free", label: "Free" },
              ]}
              value={priceType}
              onChange={async (val) => {
                console.log(val);
                setPriceType(val);
              }}
            />
          ) : (
            <div> &nbsp;{capitalize(priceType)}</div>
          )}
        </div>
        <span className="pt-1 inline-block text-right">{title}:</span>
        <div
          style={{
            margin: "20px",
            marginLeft: "60px",
            marginTop: "5px",
          }}
        >
          <div>
            <Space direction="horizontal">
              Siteboard Monthly Price:
              {editing ? (
                <InputNumber
                  disabled={saving}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") savePrice();
                  }}
                  style={{ width: "100px" }}
                  value={price}
                  onChange={(e) => (typeof e === "number" ? setPrice(e) : null)}
                ></InputNumber>
              ) : (
                price
              )}{" "}
              USD
            </Space>
          </div>
          <div>
            <Space direction="horizontal">
              Raspbery Pi One Time Price:
              {editing ? (
                <InputNumber
                  disabled={saving}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") savePrice();
                  }}
                  style={{ width: "100px" }}
                  value={raspberyPiPrice}
                  onChange={(e) =>
                    typeof e === "number" ? setRaspberyPiPrice(e) : null
                  }
                ></InputNumber>
              ) : (
                raspberyPiPrice
              )}{" "}
              USD
            </Space>
          </div>
          {project && data && (
            <div>
              {settings.enabled && (
                <Button
                  loading={upserting}
                  onClick={async () => {
                    const res = await upsertScreens({
                      variables: {
                        objects: [{ project_id: project.id }],
                      },
                    });
                    if (res.data?.insert_siteboard_screen)
                      client.writeQuery({
                        data: {
                          ...data,
                          project_by_pk: {
                            ...(data.project_by_pk || {}),
                            siteboard_screens: [
                              ...project.siteboardScreens,
                              {
                                __typename: "siteboard_screen",
                                id: res.data.insert_siteboard_screen
                                  .returning[0]?.id,
                                raspbery_count: 0,
                                screen_count: 1,
                                created_at: dayjs().toISOString(),
                              },
                            ],
                          },
                        },
                        query: GetAdminProjectDocument,
                        variables: { projectId: project.id },
                      });
                  }}
                >
                  Add New Screen
                </Button>
              )}
              <SiteBoardScreenForm
                ref={screensRef}
                enabled={settings.enabled}
                projectId={project.id}
                screens={project.siteboardScreens}
                editing={priceEditing}
              />
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default SiteBoardPriceSelector;
