import { Button, Form, Input, notification } from "antd";
import React from "react";
import StyledContent from "src/common/components/layouts/StyledContent";
import { auth } from "src/common/functions/firebase";
import useAuthUser from "src/common/hooks/useAuthUser";
import { useInsertFeedbackMutation } from "src/common/types/generated/apollo/graphQLTypes";

const { TextArea } = Input;

const Feedback: React.FunctionComponent = ({}) => {
  const [insertFeedback] = useInsertFeedbackMutation();
  const authUser = useAuthUser();
  const openNotification = () => {
    notification.open({
      message: "Your feedback was submitted!",
    });
  };
  type FormValType = { feedback: string };
  const [form] = Form.useForm<FormValType>();

  return (
    <div>
      <StyledContent>
        <h1>Feedback</h1>
        <Form
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={(values) => {
            openNotification();
            console.log("Success:", values);
            insertFeedback({
              variables: {
                objects: [{ feedback: values.feedback, uid: authUser.uid }],
              },
            });
            form.resetFields();
          }}
          onFinishFailed={(errorInfo) => {
            console.log("Failed:", errorInfo);
          }}
        >
          <Form.Item
            name="feedback"
            rules={[{ required: true, message: "Please input some feedback!" }]}
          >
            <TextArea
              rows={4}
              style={{ width: 1000 }}
              placeholder="Is there anything we can do to make your SiteForm experience better?"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </StyledContent>
    </div>
  );
};

export default Feedback;
