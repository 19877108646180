import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useProjectWorkerWorkerIdQuery } from "src/common/types/generated/relay/useProjectWorkerWorkerIdQuery.graphql";

const useProjectWorkerWorkerId = (projectWorkerId: string) => {
  const { project_worker_connection } =
    useLazyLoadQuery<useProjectWorkerWorkerIdQuery>(
      graphql`
        query useProjectWorkerWorkerIdQuery($projectWorkerId: uuid!) {
          project_worker_connection(where: { id: { _eq: $projectWorkerId } }) {
            edges {
              node {
                worker_id
              }
            }
          }
        }
      `,
      { projectWorkerId }
    );
  if (project_worker_connection.edges.length > 0)
    return project_worker_connection.edges[0].node.worker_id as string;
  else return null;
};

export default useProjectWorkerWorkerId;
