import React, { useEffect } from "react";
import { Button, Image, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

export interface SiteOrientationDocumentCameraEditModalProps {
  visible: boolean;
  onClose: () => void;
  images: { id: string; src: string }[];
  onRemoveImage: (imageId: string) => void;
}

const SiteOrientationDocumentCameraEditModal: React.FC<
  SiteOrientationDocumentCameraEditModalProps
> = (props) => {
  useEffect(() => {
    !props.images.length && props.visible && props.onClose();
  }, [props.images.length, props.visible]);
  return (
    (<Modal
      open={props.visible}
      onOk={props.onClose}
      onCancel={props.onClose}
    >
      <div className={`flex flex-row items-center justify-center`}>
        {props.images.map((image) => (
          <div className={`relative pr-1 pt-1`} key={image.id}>
            <div className={`absolute z-10 top-0 right-0`}>
              <Button
                shape={`circle`}
                type={`primary`}
                danger
                onClick={() => {
                  props.onRemoveImage(image.id);
                }}
                icon={<DeleteOutlined />}
              />
            </div>

            <Image src={image.src} className={`h-16 w-10 object-cover`} />
          </div>
        ))}
      </div>
    </Modal>)
  );
};

export default SiteOrientationDocumentCameraEditModal;
