/**
 * @generated SignedSource<<a28152743e263d687acaacace61d8b4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type project_setting_set_input = {
  access_ladder_max_duration?: string | null | undefined;
  allow_permit_activation_location_change?: boolean | null | undefined;
  delivery_stacking?: boolean | null | undefined;
  expired_daily_work_log_email_time?: string | null | undefined;
  expired_daily_work_log_mail_text?: string | null | undefined;
  expired_daily_work_log_not_closed_send_email?: boolean | null | undefined;
  expired_daily_work_log_send_schedule?: string | null | undefined;
  expired_daily_work_log_sync?: boolean | null | undefined;
  expired_permit_mail_text?: string | null | undefined;
  expired_permit_not_closed_send_email?: boolean | null | undefined;
  expired_permit_send_schedule?: string | null | undefined;
  expired_permit_sync?: boolean | null | undefined;
  expired_report_email_time?: string | null | undefined;
  expired_report_mail_text?: string | null | undefined;
  expired_report_not_closed_send_email?: boolean | null | undefined;
  expired_report_send_schedule?: string | null | undefined;
  expired_report_sync?: boolean | null | undefined;
  incident_enabled?: boolean | null | undefined;
  is_work_above_elevation?: boolean | null | undefined;
  observation_enabled?: boolean | null | undefined;
  only_submitter_sign_the_confined_space_permit?: boolean | null | undefined;
  only_submitter_sign_the_dig_excavation_permit?: boolean | null | undefined;
  only_submitter_sign_the_dig_permit?: boolean | null | undefined;
  only_submitter_sign_the_historical_access_permit?: boolean | null | undefined;
  only_submitter_sign_the_hot_work_permit?: boolean | null | undefined;
  only_submitter_sign_the_interior_penetration_permit?: boolean | null | undefined;
  only_submitter_sign_the_loto_permit?: boolean | null | undefined;
  only_submitter_sign_the_out_of_basket_permit?: boolean | null | undefined;
  only_submitter_sign_the_steel_erection_permit?: boolean | null | undefined;
  only_submitter_sign_the_work_above_permit?: boolean | null | undefined;
  orientation_chart_settings_json?: any | null | undefined;
  permit_setting_level?: string | null | undefined;
  prevent_confined_space_submission?: boolean | null | undefined;
  prevent_dig_submission?: boolean | null | undefined;
  prevent_hot_work_submission?: boolean | null | undefined;
  prevent_work_above_submission?: boolean | null | undefined;
  project_id?: string | null | undefined;
  require_gc_to_mark_observation_complete?: boolean | null | undefined;
  require_ladder_inspection_daily?: boolean | null | undefined;
  require_workers_to_sign_out_of_basket_permit?: boolean | null | undefined;
  send_weekly_email?: boolean | null | undefined;
  site_inspection_enabled?: boolean | null | undefined;
  worker_can_add_access_ladder_number?: boolean | null | undefined;
  worker_can_add_working_ladder_number?: boolean | null | undefined;
  working_ladder_max_duration?: string | null | undefined;
};
export type GCProjectReportsDownload_updateProjects_Mutation$variables = {
  _set: project_setting_set_input;
  id: string;
};
export type GCProjectReportsDownload_updateProjects_Mutation$data = {
  readonly update_project_setting_by_pk: {
    readonly id: string;
    readonly send_weekly_email: boolean | null | undefined;
  } | null | undefined;
};
export type GCProjectReportsDownload_updateProjects_Mutation = {
  response: GCProjectReportsDownload_updateProjects_Mutation$data;
  variables: GCProjectReportsDownload_updateProjects_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "project_id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "project_setting",
    "kind": "LinkedField",
    "name": "update_project_setting_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "send_weekly_email",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectReportsDownload_updateProjects_Mutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCProjectReportsDownload_updateProjects_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a6094ec064cf5cf2806025de59961102",
    "id": null,
    "metadata": {},
    "name": "GCProjectReportsDownload_updateProjects_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectReportsDownload_updateProjects_Mutation(\n  $id: uuid!\n  $_set: project_setting_set_input!\n) {\n  update_project_setting_by_pk(pk_columns: {project_id: $id}, _set: $_set) {\n    id\n    send_weekly_email\n  }\n}\n"
  }
};
})();

(node as any).hash = "4b0ffb966935a94150fba510acca6e7d";

export default node;
