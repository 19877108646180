import { Checkbox, Select, Space } from "antd";
import React, { useContext, useState } from "react";
import visitorOrientationOrienteeContext from "src/domain-features/sitesafety/entryRoutes/gcProjectReports/routes/visitor/utils/visitorOrientationOrienteeContext";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLangStrings } from "../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteVisitor from "../../utils/siteVisitorHook";

export interface VisitorOrientationStepsHoursProps
  extends SiteOrientationStepProps {
  gcName?: string;
}

const VisitorOrientationStepsHours: React.FC<
  VisitorOrientationStepsHoursProps
> = (props) => {
  const visitorContext = useSiteVisitor();

  const langStrings = useCurrentLangStrings();
  const [checked, setChecked] = useState(!!visitorContext.visitor?.hours);
  const [hours, setHours] = useState(visitorContext.visitor?.hours || 1);
  const [terms, setTerms] = useState(
    !visitorContext.showTnc || !!visitorContext.visitor?.hours,
  );

  const onEnter = () => {
    visitorContext.updateVisitor((u) =>
      u ? { ...u, hours: hours } : { hours },
    );
    visitorContext.updateShowWaiverText(false);
    props.onNext();
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!terms}
    >
      <SiteFeatureStepsInputLayout headline={langStrings.strings.hours}>
        <p className="text-grey text-1">
          {langStrings.strings.hoursYouArePlanningToStay}
        </p>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Select
            className="w-full"
            virtual={false}
            placeholder={langStrings.strings.selectYourCompany}
            onChange={async (v) => {
              setHours(v);
            }}
            value={hours}
          >
            {[...Array(24)].map((_, e) => (
              <Select.Option
                key={e + 1}
                value={e + 1}
                className="text-1 w-full"
              >
                {e + 1}
              </Select.Option>
            ))}
          </Select>
        </Space>
        {visitorContext?.showTnc ? (
          <div className="mt-2 flex justify-center absolute bottom-1.5	w-full">
            <div className="flex items-center justify-center">
              <Checkbox
                checked={checked}
                onChange={(e) => {
                  setTerms(e.target.checked);
                  setChecked(e.target.checked);
                }}
              ></Checkbox>
              <p className=" ml-1 ">
                I have reviewed and accept {" " + props.gcName}'s
                <h6
                  className="inline cursor-pointer text-semantic-pending "
                  onClick={() => {
                    visitorContext.updateShowWaiverText((u) => true);
                    visitorContext.updateVisitor((u) =>
                      u ? { ...u, hours: hours } : { hours },
                    );
                    props.onNext();
                  }}
                >
                  {" "}
                  Visitor Waiver
                </h6>
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default VisitorOrientationStepsHours;
