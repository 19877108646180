import React from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import {
  GetGcTeamDocument,
  GetGcTeamQuery,
  GetGcTeamQueryVariables,
  useUpdateGcEmployeeMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSuspenseQuery } from "@apollo/client";
import GCHierarchyTeam from "../../component/GCHierarchyTeam";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";

const GCCorporateTeam: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => {
  const [updateGcEmployee] = useUpdateGcEmployeeMutation();
  const { data, refetch } = useSuspenseQuery<
    GetGcTeamQuery,
    GetGcTeamQueryVariables
  >(GetGcTeamDocument, {
    variables: {
      projectsToInviteWhere: {},
      gcId: employee.general_contractor_id,
      gcEmployeeWhere: {
        is_corporate_admin: { _eq: true },
        general_contractor_id: { _eq: employee.general_contractor_id },
      },
    },
  });
  const gc = data.general_contractor_by_pk;
  if (!gc) throw new Error("GC not found for logged in user");
  const projectsToInvite = gc.gc_projects.map((p) => p.project.id);

  return (
    <BasicWrapper>
      <GCHierarchyTeam
        {...{
          projectsToInvite,
          title: "Corporate Team",
          onInviteSuccess: async (invitedUserIds) => {
            await updateGcEmployee({
              variables: {
                where: { uid: { _in: invitedUserIds } },
                _set: { is_corporate_admin: true },
              },
            });
            refetch();
          },
          onRemoveFromTeam: async (userId) => {
            await updateGcEmployee({
              variables: {
                where: { uid: { _eq: userId } },
                _set: { is_corporate_admin: false },
              },
            });
            refetch();
          },
          excludeEmployeeWhere: {
            is_corporate_admin: { _eq: true },
          },
          gcEmployees: data.general_contractor_employee,
        }}
      />
    </BasicWrapper>
  );
};

export default withCustomSuspense(withCorporateAdminWrapper(GCCorporateTeam));
