/**
 * @generated SignedSource<<3c4bd56f869beac41f364b14283f66b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useBlockOutDeliveryTimes_toggleBlockOutSwitch_Mutation$variables = {
  projectId: string;
  value: boolean;
};
export type useBlockOutDeliveryTimes_toggleBlockOutSwitch_Mutation$data = {
  readonly update_project: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly is_sitedelivery_block_out_active: boolean;
    }>;
  } | null | undefined;
};
export type useBlockOutDeliveryTimes_toggleBlockOutSwitch_Mutation = {
  response: useBlockOutDeliveryTimes_toggleBlockOutSwitch_Mutation$data;
  variables: useBlockOutDeliveryTimes_toggleBlockOutSwitch_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "value"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "is_sitedelivery_block_out_active",
            "variableName": "value"
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "projectId"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_mutation_response",
    "kind": "LinkedField",
    "name": "update_project",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "project",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "is_sitedelivery_block_out_active",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useBlockOutDeliveryTimes_toggleBlockOutSwitch_Mutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useBlockOutDeliveryTimes_toggleBlockOutSwitch_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5fc0cf933f13e9d84c83d444169506f1",
    "id": null,
    "metadata": {},
    "name": "useBlockOutDeliveryTimes_toggleBlockOutSwitch_Mutation",
    "operationKind": "mutation",
    "text": "mutation useBlockOutDeliveryTimes_toggleBlockOutSwitch_Mutation(\n  $projectId: uuid!\n  $value: Boolean!\n) {\n  update_project(where: {id: {_eq: $projectId}}, _set: {is_sitedelivery_block_out_active: $value}) {\n    returning {\n      id\n      is_sitedelivery_block_out_active\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "605b250c36601bc978cdf92616e70ee1";

export default node;
