import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useEffect, useState, useRef } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import dayjs from "dayjs";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { SiteBoardDeliveriesListQuery } from "src/common/types/generated/relay/SiteBoardDeliveriesListQuery.graphql";
import { SiteBoardDeliveriesItemType } from "../utilities/siteBoardDeliveriesTypes";
import { useParams } from "react-router-dom";
import SiteBoardDeliveriesUI, {
  SiteBoardDeliveryType,
} from "./SiteBoardDeliveriesUI";
import { SiteBoardDeliveryPillProps } from "./SiteBoardDelivery";

const query = graphql`
  query SiteBoardDeliveriesListQuery(
    $where: delivery_bool_exp!
    $projectId: uuid!
  ) {
    project_connection(where: {id: {_eq: $projectId }}) {
      edges {
        node {
          id
          timezone
        }
      }
    }
    delivery_connection(where: $where) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name {
            original
          }
          user {
            name
          }
          detail {
            original
          }
          start_at
          duration
          user {
            role
            employee {
              general_contractor {
                id
                name
              }
            }
            subcontractor_employee {
              subcontractor {
                id
                name
              }
            }
            worker {
              subcontractor {
                id
                name
              }
            }
          }
          calendars_connection(
            where: {
              calendar: { projects: { project_id: { _eq: $projectId } } }
            }
          ) {
            edges {
              node {
                calendar {
                  id
                  color_hex
                  name {
                    original
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface SiteBoardDeliveriesProps {
  projectId: string;
}

const SiteBoardDeliveriesListContent: FC<SiteBoardDeliveriesProps> = (
  props,
) => {
  const projectId = props.projectId;
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState({
    fetchKey: 0,
  });

  const data = useLazyLoadQuery<SiteBoardDeliveriesListQuery>(
    query,
    {
      where: {
        project: {
          id: {
            _eq: projectId,
          },
        },
        start_at: {
          _gte: dayjs().startOf("d").format(),
        },
        status: {
          _eq: "Approved",
        },
        // status: {
        //   _eq: "Approved",
        // },
        calendars: {
          calendar: {
            projects: {
              is_archive: {
                _eq: false,
              },
              project: {
                id: { _eq: projectId },
              },
            },
          },
        },
      },
      projectId,
    },
    {
      fetchPolicy: "store-and-network",
      ...refreshedQueryOptions,
    },
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
      }));
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const now = dayjs();
  const deliveries: SiteBoardDeliveryType[] = data.delivery_connection.edges
    .filter((edge) =>
      dayjs(edge.node.start_at)
        .add(edge.node.duration ?? 0, "h")
        .isAfter(now),
    )
    .map((d) => {
      const startTime = dayjs(d.node.start_at);
      return {
        key: d.node.pk,
        companyName:
          (d.node.user?.role === "employee"
            ? d.node.user.employee?.general_contractor.name
            : d.node.user?.role === "subcontractor_employee"
            ? d.node.user.subcontractor_employee?.subcontractor.name
            : d.node.user?.role === "worker"
            ? d.node.user.worker?.subcontractor?.name
            : "admin") ?? "",
        date: startTime.toDate(),
        deliveryName: d.node.name?.original ?? "",
        gateCalendars: d.node.calendars_connection.edges.map((c) => {
          const calendar = c.node.calendar;
          if (!calendar)
              return null;
          return {
            id: calendar.id,
            text: calendar.name?.original ?? "",
            colorCss: calendar.color_hex ?? "#1890ff",
          };
        }).filter((c): c is SiteBoardDeliveryPillProps => !!c),
        deliveryContent: d.node.detail?.original ?? "",
        endAt: startTime.add(d.node.duration ?? 0, "h").toDate(),
      };
    });
  return <SiteBoardDeliveriesUI data={deliveries} />;
};

const SiteBoardDeliveriesList: React.FC = () => {
  const params = useParams();
  const projectId = params.projectId;
  if (!projectId) {
    throw new Error("ProjectId param is missing");
  }
  return <SiteBoardDeliveriesListContent projectId={projectId} />;
};

export default withCustomSuspense(SiteBoardDeliveriesList);
