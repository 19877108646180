import { Radio } from "antd";
import React from "react";
export type Items = { id: string; label: string; selected: boolean }[];

export interface RadioListProps {
  items: Items;
  onClick: (id: string) => void;
}

const RadioList: React.FunctionComponent<RadioListProps> = ({
  items,
  onClick,
}) => {
  const handleClick = (id: string) => {
    onClick(id);
  };

  return (
    <div className="flex flex-col gap-0.5">
      {items.length > 0 && (
        <div className="block -m-0.25 w-full">
          {items.map(({ label, id, selected }) => (
            <div className="inline-block m-0.25" key={id}>
              <Radio
                onClick={() => {
                  handleClick(id);
                }}
                type="primary"
                className={`text-1 "mt-0.5"}`}
                checked={selected}
              >
                {label}
              </Radio>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RadioList;
