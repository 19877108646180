import { Table, Tag } from "antd";
import dayjs from "dayjs";
import React, { FC } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import BackButton from "src/common/components/general/BackButton";
import Button from "src/common/components/general/Button";
import Image from "src/common/components/general/images/Image";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import MobileViewWrapper from "src/common/components/layouts/MobileViewWrapper";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import { QrProjectSubcontractorTaskQuery } from "src/common/types/generated/relay/QrProjectSubcontractorTaskQuery.graphql";
import { graphql } from "babel-plugin-relay/macro";
import ProjectProps from "src/common/types/manual/ProjectProps";
import SubcontractorProps from "src/common/types/manual/SubcontractorProps";
import TaskProps from "src/common/types/manual/TaskProps";
import CardWrapper from "../../../components/CardWrapper";
import ProjectOverviewCard from "../../../components/ProjectOverviewCard";
import QrProjectTableWrapper from "../../../components/QrProjectTableWrapper";

interface CustomHeaderProps {
  header: string;
}

export const CustomHeader: FC<CustomHeaderProps> = ({ header }) => (
  <p>{header}</p>
);

export const query = graphql`
  query QrProjectSubcontractorTaskQuery($taskId: uuid!, $order: order_by!) {
    task_connection(where: { id: { _eq: $taskId } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          subcontractor_id
          project_id
          show_type
          archived_at
          task_creation_request {
            type
            attached_files(order_by: { name: asc }) {
              url
            }
          }
          project {
            name
            address {
              line_1
              line_2
              city
              state_code
              state {
                name
              }
              zip_code
            }
            general_contractor_id
            general_contractors {
              general_contractor {
                id
                name
              }
            }
          }
          created_at
          created_by_user {
            name
            role
          }
          description {
            en
            id
            pk: id @__clientField(handle: "pk")
          }
          task_steps {
            id
            pk: id @__clientField(handle: "pk")
            sort_index
            task_step_hazards {
              description_id
              control_id
              id
              pk: id @__clientField(handle: "pk")
              sort_index
              description {
                en
                original
              }
              control {
                en
                original
              }
              task_hazard_ecm_types {
                ecm_type_id
                ecm_type {
                  id
                  pk: id @__clientField(handle: "pk")
                  name {
                    en
                  }
                }
              }
            }
            description {
              id
              pk: id @__clientField(handle: "pk")
              en
            }
          }
          task_ppe_types {
            ppe_type {
              name {
                en
              }
              id
              pk: id @__clientField(handle: "pk")
            }
          }
          task_permit_types {
            permit_type {
              id
              pk: id @__clientField(handle: "pk")
              name {
                en
              }
            }
          }
          task_edit(order_by: { edited_at: desc }) {
            task_patch
            edited_at
          }
          subcontractor {
            name
          }
        }
      }
    }
    ecm_type_connection {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name {
            en
            es
            pt
            original
            id
          }
        }
      }
    }
    task_signature_connection(
      where: { task_id: { _eq: $taskId }, is_active: { _eq: true } }
      order_by: { created_at: $order }
    ) {
      edges {
        node {
          id
          created_at
          pk: id @__clientField(handle: "pk")
          signature_image_id
          image {
            url
          }
          user_id
          user {
            name
            role
            employee {
              general_contractor {
                name
              }
              employee_title {
                name {
                  en
                }
              }
            }
            subcontractor_employee {
              subcontractor {
                name
              }
              employee_title {
                name {
                  en
                }
              }
            }
            worker {
              current_worker_role
              worker_projects {
                hard_hat_number
                project_id
              }
              worker_title {
                translation {
                  en
                }
              }
              subcontractor {
                name
              }
            }
          }
          email_request_id
          email_request {
            email
            user_id
          }
        }
      }
    }
  }
`;

const QrProjectSubcontractorTask: FC<
  TaskProps & ProjectProps & SubcontractorProps
> = ({ taskId, projectId, subcontractorId }) => {
  const navigate = useNavigate();

  const data = useLazyLoadQuery<QrProjectSubcontractorTaskQuery>(
    query,
    {
      taskId,
      order: Order_By.Desc,
    },
    { fetchPolicy: "network-only" },
  );

  const taskData = data.task_connection.edges[0]?.node;
  const projectData = taskData.project;
  const address = projectData
    ? [
        projectData.address.line_1,
        projectData.address.line_2,
        projectData.address.city,
        projectData.address.state_code,
      ]
        .filter((str) => !!str?.length)
        .join(", ")
    : null;
  const date = taskData?.created_at;

  const taskDataSource = [
    {
      id: taskData.pk,
      key: taskData.pk,
      taskName: taskData.description.en,
      task_steps: taskData.task_steps?.length,
      task_ppe_types: taskData.task_ppe_types?.length,
      task_permit_types: taskData.task_permit_types?.length,
      task_steps_hazards: taskData.task_steps.reduce(
        (sum, h) => sum + h.task_step_hazards.length,
        0,
      ),
    },
  ];
  //console.log(dataSource);

  const taskColumns = [
    {
      title: "JHA (Job Hazard Analysis)",
      key: "taskName",
      dataIndex: "taskName",
      width: "35%",
    },
    {
      title: "Steps",
      key: "task_steps",
      dataIndex: "task_steps",
    },
    {
      title: "Hazards",
      key: "task_steps_hazards",
      dataIndex: "task_steps_hazards",
      width: "18%",
    },
    {
      title: "Permits",
      key: "task_permit_types",
      dataIndex: "task_permit_types",
      width: "17%",
    },
    {
      title: "PPE",
      key: "task_ppe_types",
      dataIndex: "task_ppe_types",
    },
  ];
  const workerSigns = data.task_signature_connection.edges.filter(
    (c) => c.node.user?.role == "worker",
  );
  const signatureDataSource = workerSigns.map((c) => {
    const user = c.node.user; 
    return {
      key: c.node.pk,
      date: dayjs(c.node.created_at).format("MMM DD, YYYY h:mm A"),

      sign: <img src={c.node?.image?.url}></img>,
      name: !!user?.worker
        ? [
          user.name, 
          user.worker.current_worker_role,
          user.worker.worker_title?.translation.en
        ].filter(v => !!v).join(", ")        
        : "",
    };
  });
  const gcId = taskData.project?.general_contractor_id;
  const turnerGCId = "96916668-c816-4c2a-9008-73a6116d4c00";
  const isNotTurnersProject = gcId && gcId !== turnerGCId;

  const signatureColumns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      width: "50%",
      defaultSordOrder: "ascend",
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      sorter: (a: any, b: any) => (!!a.date ? a.date.localeCompare(b.date) : 1),
    },
    {
      title: "Signature",
      key: "sign",
      dataIndex: "sign",
      width: "30%",
    },
  ];
  let x = 0;

  return (
    <MobileViewWrapper
      footer={
        <>
          <div className={`flex flex-row gap-1 items-center`}>
            <BackButton fullWidth />
            <Button
              onClick={() =>
                navigate(
                  `/qr/p/${projectId}/sub/${subcontractorId}/t/${taskId}/signature`,
                )
              }
              label={`Sign JHA`}
              fullWidth
            />
          </div>
        </>
      }
    >
      <div className="flex flex-col gap-1">
        <p className="text-1.5 font-accent">{`JHA: ${taskData.description.en}`}</p>
        {!!projectData && !!taskData && address && (
          <ProjectOverviewCard
            gcTitle={projectData.general_contractors
              .map((gc) => gc.general_contractor.name)
              .join(", ")}
            projectAddress={address}
            projectTitle={projectData.name}
            subcontractorTitle={taskData.subcontractor?.name}
            createdDate={dayjs(date).format("MMM DD,YYYY h:mm A")}
          />
        )}
        {taskData.show_type === "images" && taskData.task_creation_request ? (
          <QrProjectTableWrapper>
            {taskData.task_creation_request.attached_files.map((p, i) => (
              <div
                className="font-accent flex flex-col items-center justify-center"
                key={i}
              >
                <div className="tablet-landscape:w-4/12 w-10/12 mb-2">
                  <Image src={p.url} alt={"Image " + (i + 1)} preview />
                  <div className="text-center ">Page {i + 1}</div>
                </div>
              </div>
            ))}
          </QrProjectTableWrapper>
        ) : (
          <>
            <QrProjectTableWrapper>
              <Table
                columns={taskColumns}
                dataSource={taskDataSource}
                bordered
                size={"large"}
              />
            </QrProjectTableWrapper>
            <CardWrapper>
              <p className="text-1 font-accent">{`PPE (Personal Protective Equipment)`}</p>
              <div className="">
                {taskData.task_ppe_types.map((cur: any) => {
                  return (
                    <Tag
                      key={cur.ppe_type.id}
                      id={cur.ppe_type.id}
                      color="#e6f7ff"
                      className="text-interactive-primary mr-0.75 mt-0.75 text-0.865"
                    >
                      {cur.ppe_type.name.en}
                    </Tag>
                  );
                })}
              </div>
            </CardWrapper>
            <CardWrapper>
              <p className="font-accent">
                Permits or Tags Required to Perform this JHA
              </p>
              <div className="">
                {taskData.task_permit_types.map((cur: any) => {
                  return (
                    <Tag
                      key={cur.permit_type.id}
                      id={cur.permit_type.id}
                      color="#e6f7ff"
                      className="text-interactive-primary mr-0.75 mt-0.75 text-0.865"
                    >
                      {cur.permit_type.name.en}
                    </Tag>
                  );
                })}
              </div>
            </CardWrapper>
            <CardWrapper key={x++}>
              <CustomHeader
                header={`Steps (${taskDataSource[0]?.task_steps})`}
              />
              {taskData.task_steps.map((step, i) => (
                <div key={step.pk}>
                  <p className="pt-1 pl-2">
                    <span className="text-1 font-accent">Step {i + 1}.</span>{" "}
                    {step.description.en}
                  </p>

                  {step.task_step_hazards.length > 0 ? (
                    <p className="font-accent text-1 pl-2.5 pt-1">
                      {" "}
                      Hazards ({step.task_step_hazards.length})
                    </p>
                  ) : (
                    <div key={x++}></div>
                  )}

                  {step.task_step_hazards.map((h, i2: number) => {
                    const ecms = h.task_hazard_ecm_types;
                    return (
                      <div key={h.pk}>
                        <p className="pt-1 pl-3">
                          <span className="font-accent">{i2 + 1}.</span>{" "}
                          {h.description.en}
                        </p>
                        <p key={x++} className="pl-3 pt-0.125">
                          <span className="font-accent">Control:</span>{" "}
                          {h.control.en}
                          {isNotTurnersProject ? (
                            <></>
                          ) : (
                            <div>
                              {ecms.length > 0 || true ? (
                                <>
                                  <span className="font-accent">E,C,M: </span>
                                  {ecms.map((cur, ecmIndex) => (
                                    <Tag
                                      key={ecmIndex}
                                      color="#e6f7ff"
                                      className="text-interactive-primary mr-0.75 mt-0.75 text-0.865"
                                    >
                                      {cur.ecm_type?.name?.en ?? ""}
                                    </Tag>
                                  ))}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ))}
            </CardWrapper>
          </>
        )}
        <QrProjectTableWrapper>
          <p className="font-accent">{`Workers that have reviewed this JHA (${workerSigns.length})`}</p>
          <Table columns={signatureColumns} dataSource={signatureDataSource} />
        </QrProjectTableWrapper>
      </div>
    </MobileViewWrapper>
  );
};
export default withCustomSuspense(QrProjectSubcontractorTask);
