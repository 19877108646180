import React, { useState } from "react";
import withCustomErrorBoundaryFallback from "../../../utility-features/error-handling/components/withCstomErrorBoundaryFallback";
import { useCurrentLangStrings } from "../../../utility-features/i18n/context/languageHooks";
import withCustomSuspense from "../general/withCustomSuspense";
import AudioPlayerControls from "./AudioPlayerControls";
import AudioPlayerErrorFallback from "./AudioPlayerErrorFallback";
import useAudioPlayer from "./useAudioPlayer";
import AudioPlayerProgressBar from "./AudioPlayerProgressBar";

interface AudioPlayerProps {
  onAudioEnded?: () => void;
  autoPlay?: boolean;
  audioSrc: string;
  skipSeconds?: number;
  allowedControls?: ("rewind" | "skip")[];
  startButtonText?: string;
  onPlayPauseButtonClicked?: () => void;
  showProgressBar?: boolean;
}

const DEFAULT_SKIP_SECONDS = 10;

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  skipSeconds = DEFAULT_SKIP_SECONDS,
  showProgressBar = true,
  ...props
}) => {
  const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(
    null
  );
  const langStrings = useCurrentLangStrings();

  const {
    isPlaying,
    togglePlayPause,
    skipAudio,
    rewindAudio,
    duration,
    timeProgress,
    bufferedTime,
    loading,
  } = useAudioPlayer({
    audioElement: audioElement,
    allowRewind: props.allowedControls?.includes("rewind"),
    allowSkip: props.allowedControls?.includes("skip"),
    skipDelaySeconds: skipSeconds,
  });

  const onPlayPauseClicked = () => {
    togglePlayPause();
    if (props.onPlayPauseButtonClicked) {
      props.onPlayPauseButtonClicked();
    }
  };

  return (
    <div>
      <audio
        onEnded={props.onAudioEnded}
        translate={"no"}
        ref={setAudioElement}
        className="inline-block h-2 mr-1"
        preload={"auto"}
        autoPlay={props.autoPlay}
        src={props.audioSrc}
        key={props.audioSrc}
      >
        {langStrings.strings.yourBrowserDoesNotSupportAudioElement}
      </audio>

      <AudioPlayerControls
        onPlayPauseClick={onPlayPauseClicked}
        playing={props.autoPlay ?? isPlaying}
        skipTimeSec={skipSeconds}
        onRewindClick={rewindAudio}
        onSkipClick={skipAudio}
        startButtonText={props.startButtonText}
      />

      {showProgressBar && (
        <AudioPlayerProgressBar
          durationSec={duration}
          timeProgressSec={timeProgress}
          bufferedTime={bufferedTime}
          loading={loading}
        />
      )}
    </div>
  );
};

export default withCustomSuspense(
  withCustomErrorBoundaryFallback(AudioPlayer, ({ error, resetError }) => (
    <AudioPlayerErrorFallback error={error} resetError={resetError} />
  ))
);
