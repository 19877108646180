import React from "react";

export interface ProgressBarProps {
  value: number;
  total: number;
}

const ProgressBar: React.FunctionComponent<ProgressBarProps> = (props) => {
  const percentage = props.total
    ? Math.round((100 / props.total) * props.value)
    : undefined;

  return (
    <div className="p-0.5 border -my-px border-white bg-suplementary-1 relative rounded-0.5">
      <div
        className="absolute left-0 h-full top-0 rounded-0.5 bg-suplementary-2 -z-10"
        style={{ width: `${percentage ?? "0"}%` }}
      ></div>
      <p className="relative w-full text-center select-none whitespace-nowrap">
        {percentage !== undefined && (
          <span className="font-accent">{percentage}% </span>
        )}
        {`${props.value} of ${props.total}`}
      </p>
    </div>
  );
};

export default ProgressBar;
