import { graphql } from "babel-plugin-relay/macro";
import React, { FC } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import NavigationWrapper, {
  NavigationMenuProps,
} from "src/common/components/layouts/NavigationWrapper";

import { SCTasksNavigationQuery } from "src/common/types/generated/relay/SCTasksNavigationQuery.graphql";
const SCProjectsNavigation: FC = () => {
  const subId = useSubcontractorId();
  const navigate = useNavigate();
  const selectedTaskId = useParams().taskId;
  const data = useLazyLoadQuery<SCTasksNavigationQuery>(
    graphql`
      query SCTasksNavigationQuery($where: task_bool_exp!) {
        task_connection(where: $where, order_by: { description: { en: asc } }) {
          edges {
            node {
              pk: id @__clientField(handle: "pk")
              description {
                en
              }
            }
          }
        }
      }
    `,
    {
      where: subId
        ? { subcontractor_id: { _eq: subId }, project_id: { _is_null: true } }
        : { id: { _is_null: true } },
    },
    {
      fetchPolicy: "network-only",
    }
  );

  const tasks = data.task_connection.edges.map((p) => ({
    name: p.node.description.en,
    id: p.node.pk,
    selected: p.node.pk === selectedTaskId,
  }));
  const menu: NavigationMenuProps = {
    navigationItems: tasks.map((t) => {
      return {
        label: t.name,
        rootPath: "/sub/tasks/" + t.id,
        onClick: () => {
          navigate(`/sub/tasks/${t.id}`);
        },
      };
    }),
  };
  return (
    <NavigationWrapper menu={menu}>
      <Outlet />
    </NavigationWrapper>
  );
};
export default SCProjectsNavigation;
