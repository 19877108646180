/**
 * @generated SignedSource<<fedf4df074838c7cc0ba56b95f5c2e73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InspectionQRTable_inspection_qr_codes$data = {
  readonly inspection_qr_code_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly created_at: string;
        readonly created_by_uid: string;
        readonly created_by_user: {
          readonly name: string;
        };
        readonly id: string;
        readonly inspection_qr_code_instances: ReadonlyArray<{
          readonly id: string;
          readonly inspection_instance: {
            readonly deleted_at: string | null | undefined;
            readonly id: string;
            readonly inspection_results_aggregate: {
              readonly aggregate: {
                readonly count: number;
              } | null | undefined;
            };
            readonly name: {
              readonly en: string;
            };
            readonly pk: string;
          };
        }>;
        readonly inspection_results: ReadonlyArray<{
          readonly created_at: string;
          readonly id: string;
          readonly user: {
            readonly name: string;
          } | null | undefined;
          readonly user_name: string | null | undefined;
        }>;
        readonly name: string;
        readonly pk: string;
        readonly project_id: string;
      };
    }>;
  };
  readonly " $fragmentType": "InspectionQRTable_inspection_qr_codes";
};
export type InspectionQRTable_inspection_qr_codes$key = {
  readonly " $data"?: InspectionQRTable_inspection_qr_codes$data;
  readonly " $fragmentSpreads": FragmentRefs<"InspectionQRTable_inspection_qr_codes">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "inspection_qr_code_connection"
],
v1 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v5 = [
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./InspectionQRTableRefetchableQuery.graphql')
    }
  },
  "name": "InspectionQRTable_inspection_qr_codes",
  "selections": [
    {
      "alias": "inspection_qr_code_connection",
      "args": null,
      "concreteType": "inspection_qr_codeConnection",
      "kind": "LinkedField",
      "name": "__InspectionQRTable_inspection_qr_code_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "inspection_qr_codeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "inspection_qr_code",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_by_uid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "project_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user",
                  "kind": "LinkedField",
                  "name": "created_by_user",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "limit",
                      "value": 1
                    },
                    {
                      "kind": "Literal",
                      "name": "order_by",
                      "value": {
                        "created_at": "desc"
                      }
                    }
                  ],
                  "concreteType": "inspection_result",
                  "kind": "LinkedField",
                  "name": "inspection_results",
                  "plural": true,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "user_name",
                      "storageKey": null
                    },
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "user",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": "inspection_results(limit:1,order_by:{\"created_at\":\"desc\"})"
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "where",
                      "value": {
                        "inspection_instance": {
                          "deleted_at": {
                            "_is_null": true
                          }
                        }
                      }
                    }
                  ],
                  "concreteType": "inspection_instance_qr_code",
                  "kind": "LinkedField",
                  "name": "inspection_qr_code_instances",
                  "plural": true,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "inspection_instance",
                      "kind": "LinkedField",
                      "name": "inspection_instance",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "deleted_at",
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "inspection_result_aggregate",
                          "kind": "LinkedField",
                          "name": "inspection_results_aggregate",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "inspection_result_aggregate_fields",
                              "kind": "LinkedField",
                              "name": "aggregate",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "count",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "text_translation",
                          "kind": "LinkedField",
                          "name": "name",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "en",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "inspection_qr_code_instances(where:{\"inspection_instance\":{\"deleted_at\":{\"_is_null\":true}}})"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "ab37bf203109c11d10091bfb19ed1716";

export default node;
