import React from "react";
import BlockOutDeliveryTimesTableColumn from "./BlockOutDeliveryTimesTableColumn";
import { TimeBlockType, TimeRange } from "./type";

interface BlockOutDeliveryTimesTableProps {
  timesTable: TimeBlockType[][];
  removeTimeBlock: (timeBlockId: string) => void;
  addTimeBlock: (time: TimeRange, weekDayNumber: number) => void;
  loading?: boolean;
}

const BlockOutDeliveryTimesTable: React.FunctionComponent<
  BlockOutDeliveryTimesTableProps
> = (props) => {
  return (
    <div
      className={`flex flex-row gap-px ${props.loading ? "cursor-wait" : ""}`}
    >
      {props.timesTable.map((weekDayTimes, i) => (
        <div
          className={`flex flex-col flex-1 w-full py-1 border-t-px border-b-px border-suplementary-1 ${
            props.loading ? "pointer-events-none" : ""
          }`}
        >
          <BlockOutDeliveryTimesTableColumn
            weekDay={i}
            times={weekDayTimes.map((weekDayTimeBlock, j) => ({
              ...weekDayTimeBlock.time,
              onRemove: () => {
                props.removeTimeBlock(weekDayTimeBlock.id);
              },
            }))}
            onCreateNewTimeBlock={(time) => {
              props.addTimeBlock(time, i);
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default BlockOutDeliveryTimesTable;
