/**
 * @generated SignedSource<<31a6a6c87ad7c24ae99882344a480209>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SubAdminSendEmailModalQuery$variables = {
  subId: string;
};
export type SubAdminSendEmailModalQuery$data = {
  readonly subcontractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly subcontractor_projects: ReadonlyArray<{
          readonly project: {
            readonly id: string;
            readonly name: string;
            readonly pk: string;
          };
          readonly project_id: string;
        }>;
      };
    }>;
  };
};
export type SubAdminSendEmailModalQuery = {
  response: SubAdminSendEmailModalQuery$data;
  variables: SubAdminSendEmailModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "subId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "project": {
        "name": "asc"
      }
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubAdminSendEmailModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "subcontractorConnection",
        "kind": "LinkedField",
        "name": "subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "project_subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor_projects",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": "pk",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__id_pk",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "subcontractor_projects(order_by:{\"project\":{\"name\":\"asc\"}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubAdminSendEmailModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "subcontractorConnection",
        "kind": "LinkedField",
        "name": "subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "project_subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor_projects",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": "pk",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": "pk",
                            "args": null,
                            "filters": null,
                            "handle": "pk",
                            "key": "",
                            "kind": "ScalarHandle",
                            "name": "id"
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": "subcontractor_projects(order_by:{\"project\":{\"name\":\"asc\"}})"
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "392096d026fc07b607531adf0c718a79",
    "id": null,
    "metadata": {},
    "name": "SubAdminSendEmailModalQuery",
    "operationKind": "query",
    "text": "query SubAdminSendEmailModalQuery(\n  $subId: uuid!\n) {\n  subcontractor_connection(where: {id: {_eq: $subId}}) {\n    edges {\n      node {\n        subcontractor_projects(order_by: {project: {name: asc}}) {\n          project_id\n          project {\n            id\n            pk: id\n            name\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d98e3fbd4768ee6ab4263e5ff2ddcaca";

export default node;
