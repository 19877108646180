import { Select } from "antd";
import { memo } from "react";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import {
  useGetCrewSightTradesQuery,
  useGetWorkerTitleForCrewSightQuery,
  useUpsertCrewSightTradeMutation,
} from "src/common/types/generated/apollo/graphQLTypes";

const SelectCrewSightTrade: React.FC<{
  projectId: string;
  worker_title_id: string;
  value: string | undefined;
  options: Array<{ name: string; id: string }>;
}> = memo(({ projectId, worker_title_id, options, value }) => {
  const [upsertCrewSightTrade, { loading, error }] = useUpsertCrewSightTradeMutation();
  return (
    <>
      <Select
        className="min-w-24"
        loading={loading}
        options={options.map((o) => ({ label: o.name, value: o.name }))}
        showSearch
        value={value}
        filterOption={(input, option) => {
          return (
            (option &&
              option.label &&
              option.label
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase())) !== -1
          );
        }}
        onChange={(newValue) => {
          const option = options.find(o => o.name === newValue);
          const crewsight_trade_id = option?.id;
          const crewsight_trade_name = newValue;
          upsertCrewSightTrade({
            variables: {
              object: {
                project_id: projectId,
                worker_title_id: worker_title_id,
                crewsight_trade_id,
                crewsight_trade_name,
              },
            },
            optimisticResponse: {
              insert_crewsight_trade_worker_title_one: {
                project_id: projectId,
                worker_title_id: worker_title_id,
                crewsight_trade_id,
                crewsight_trade_name,
              },
            },
            update(cache) {
              cache.modify({
                id: cache.identify({id: worker_title_id, __typename: 'worker_title'}),
                fields: {
                  crewsight_trade_worker_titles(prevTrades) {
                    return [{__typename: 'crewsight_trade_worker_title', crewsight_trade_id, crewsight_trade_name}];
                  },
                },
              })
            }
          });
        }}
      />
      { error && <div>{error.message}</div> }
    </>
  );
});

const CrewsightProjectTradeSetup: React.FC<{ projectId: string }> = ({
  projectId,
}) => {
  // TODO: use single query with suspence
  const { data: workerTitleData, loading: tradeLoading } = useGetWorkerTitleForCrewSightQuery({
    variables: { projectId },
  });
  const { data: crewsightTradesData, loading: tradesLoading } = useGetCrewSightTradesQuery({
    variables: { input: { projectId } },
  });
  return (
    <BaseTable
      title={() => "Worker Titles"}
      loading={tradeLoading || tradesLoading}
      columns={[
        {
          title: "Worker Title",
          key: "title",
          dataIndex: ["translation", "en"],
        },
        {
          title: "CrewSight Trade",
          key: "crewsight_trade",
          dataIndex: ["crewsight_trade_worker_titles"],
          render: (v, r) => (
            <SelectCrewSightTrade
              projectId={projectId}
              worker_title_id={r.id}
              value={r.crewsight_trade_worker_titles[0]?.crewsight_trade_name}
              options={crewsightTradesData?.getCrewSightTrades || []}
            />
          ),
        },
      ]}
      dataSource={workerTitleData?.worker_title || []}
    ></BaseTable>
  );
};
export default CrewsightProjectTradeSetup;
