import { Comment } from "@ant-design/compatible";
import { Descriptions, Space, Tag, Tooltip } from "antd";
import Item from "antd/lib/descriptions/Item";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";

import React, { FC } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import ImageCarousel from "src/common/components/general/images/ImageCarousel";
import BSpace from "src/common/components/layouts/BSpace";
import StyledContent from "src/common/components/layouts/StyledContent";
import ScrollTable from "src/common/components/tables/basic/ScrollTable";
import getConnectionNodes from "src/common/functions/getConnectionNodes";
import { useGetUserRoleQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { DailyWorkLogQuery } from "src/common/types/generated/relay/DailyWorkLogQuery.graphql";
import ReportProps from "src/common/types/manual/ReportProps";

const query = graphql`
  query DailyWorkLogQuery($dailyWorkLogId: uuid!, $subId: uuid!) {
    daily_work_log_connection(
      where: { id: { _eq: $dailyWorkLogId }, subcontractor_id: { _eq: $subId } }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          submitted_at
          created_at
          worker_count
          worker_hours
          date
          daily_weather_observations {
            pk: id @__clientField(handle: "pk")
            created_at
            created_by_project_user {
              employee {
                user {
                  pk: id @__clientField(handle: "pk")
                  name
                }
              }
              project_worker {
                user {
                  pk: id @__clientField(handle: "pk")
                  name
                }
              }
            }
            description {
              en
            }
            daily_weather_observation_subs {
              subcontractor {
                name
              }
            }
            time_observed
            ground {
              name {
                en
              }
            }
            sky {
              name {
                en
              }
            }
            temp
            wind {
              name {
                en
              }
            }
            precipitation
          }
          subcontractor {
            name
            pk: id @__clientField(handle: "pk")
          }
          description {
            ...TextTranslationFrag @relay(mask: false)
          }
          images(order_by: { sort_index: asc }) {
            ...ImageFrag @relay(mask: false)
          }
          daily_work_log_tags {
            id
            pk: id @__clientField(handle: "pk")
            tag {
              ...TagFrag @relay(mask: false)
            }
          }
          daily_work_log_comments {
            created_at
            pk: id @__clientField(handle: "pk")
            text {
              en
            }
            daily_work_log_comment_emails {
              email
            }
            created_by_employee {
              user {
                name
              }
            }
          }
          daily_work_log_workers {
            hours
            id
            pk: id @__clientField(handle: "pk")
            project_worker {
              user {
                pk: id @__clientField(handle: "pk")
                id
                name
                role
              }
              title {
                pk: id @__clientField(handle: "pk")
                translation {
                  en
                }
              }
              worker_role
            }
          }
          created_by_project_user {
            project_worker {
              pk: id @__clientField(handle: "pk")
              subcontractor {
                name
              }
              user {
                name
              }
              title {
                translation {
                  en
                }
              }
            }
            employee {
              user {
                pk: id @__clientField(handle: "pk")
                name
              }
            }
          }
          checklist_items {
            pk: id @__clientField(handle: "pk")
            description {
              en
            }
            text_response {
              en
            }
            option_response
          }
          project {
            name
          }
        }
      }
    }
  }
`;
const DailyWorkLog: FC<ReportProps> = ({ reportId }) => {
  const subId = useSubcontractorId();
  const data = useLazyLoadQuery<DailyWorkLogQuery>(query, {
    dailyWorkLogId: reportId,
    subId,
  });
  const role = useGetUserRoleQuery().data?.role;
  const log = getConnectionNodes(data.daily_work_log_connection).map((l) => ({
    pk: l.pk,
    date: dayjs(l.date as string).format("ll"),
    project: l.project.name,
    submitter: (l.created_by_project_user.employee ??
      l.created_by_project_user.project_worker)!.user!.name,
    submitted_at: dayjs((l.submitted_at ?? l.created_at) as string)
      .tz("America/New_York")
      .format("MMMM Do YYYY, h:mm:ss A z"),
    title: l.created_by_project_user.project_worker?.title?.translation.en,
    subcontractor: l.subcontractor?.name || "", // subcontractor will exist as we're only calling those that maches with the subId
    description: l.description.en,
    workercount: typeof(l.worker_count) === "number"
      ? l.worker_count +
        (!!(l.created_by_project_user.project_worker)
          ? " (Adjusted)"
          : "")
      : l.daily_work_log_workers.length,
    manhours: typeof(l.worker_hours) === "number" && typeof(l.worker_count) === "number" 
      ? l.worker_hours * l.worker_count +
        (!!(l.created_by_project_user.project_worker)
          ? " (Adjusted)"
          : "")
      : l.daily_work_log_workers.reduce((sum, w) => sum + w.hours, 0),
    isWorkerLog: !!l.created_by_project_user.project_worker,
    isCommentsLog: role === "employee",
    worker: l.daily_work_log_workers.map(function (l1) {
      return {
        pk: l1.pk,
        workername: l1.project_worker.user?.name,
        hours: l1.hours,
        title: l1.project_worker.title?.translation.en,
        role: l1.project_worker.worker_role,
      };
    }),
    foremancount: l.daily_work_log_workers.reduce(
      (sum, w) =>
        sum +
        (w.project_worker.worker_role.toLocaleLowerCase() === "foreman"
          ? 1
          : 0),
      0,
    ),
    journeymancount: l.daily_work_log_workers.reduce(
      (sum, w) =>
        sum +
        (w.project_worker.worker_role.toLocaleLowerCase() === "journeyman"
          ? 1
          : 0),
      0,
    ),
    apprenticecount: l.daily_work_log_workers.reduce(
      (sum, w) =>
        sum +
        (w.project_worker.worker_role.toLocaleLowerCase() === "apprentice"
          ? 1
          : 0),
      0,
    ),
    tags: l.daily_work_log_tags.map(function (l1) {
      return (
        <Tag color={l1.tag.color_hex} key={l1.tag.id}>
          {l1.tag.name.en}
        </Tag>
      );
    }),
    image: l.images.map(function (l1) {
      return {
        url: l1.url,
        description: l1.description,
      };
    }),
    checklist: l.checklist_items.map(function (l1) {
      return {
        pk: l1.pk,
        description: l1.description.en,
        comment: l1.text_response.en,
        response: l1.option_response,
      };
    }),
    comment: l.daily_work_log_comments.map(function (l1) {
      return {
        pk: l1.pk,
        comment: (
          <Comment
            author={<h3>{l1.created_by_employee?.user.name}</h3>}
            content={<h3>{l1.text.en}</h3>}
            datetime={
              <Tooltip
                title={dayjs(l1.created_at as string)
                  .tz("America/New_York")
                  .format("MMMM Do YYYY, h:mm:ss A z")}
              >
                <span>{dayjs(l1.created_at as string).fromNow()}</span>
              </Tooltip>
            }
          />
        ),
        shared: l1.daily_work_log_comment_emails.map(function (l2) {
          return l2.email + " ";
        }),
      };
    }),
    weather: l.daily_weather_observations.map((l1) => ({
      pk: l1.pk,
      description: l1.description,
      time: dayjs(l1.time_observed as string).format("h:mm A"),
      affectedSubcontractor: l1.daily_weather_observation_subs.map(function (
        l2,
      ) {
        return (l2.subcontractor?.name || "") + "\n";
      }),
      creator:
        (l1.created_by_project_user.employee ??
          l1.created_by_project_user.project_worker)!.user!.name +
        ", " +
        dayjs(l.created_at as string)
          .tz("America/New_York")
          .format("MMMM Do YYYY, h:mm:ss A z"),
      weatherSituation: [
        !!l1.ground?.name.en ? l1.ground.name.en + " " : "",
        !!l1.sky?.name.en ? l1.sky.name.en + " " : "",
        !!l1.wind?.name.en ? l1.wind.name.en + " " : "",
      ],
    })),
  }));
  const report = log[0];
  return (
    <>
      <Space
        size="large"
        direction="vertical"
        style={{ width: "100%", minWidth: 850 }}
      >
        <StyledContent padding backgroundColor="white">
          <BSpace>
            <Descriptions title="Work Log" size="middle" layout="horizontal">
              <Descriptions.Item label="Date">{report?.date}</Descriptions.Item>
              <Descriptions.Item label="Project">
                {report?.project}
              </Descriptions.Item>
              <Descriptions.Item label="Subcontractor">
                {report?.subcontractor}
              </Descriptions.Item>
              <Descriptions.Item label="Tags">{report?.tags}</Descriptions.Item>
              <Descriptions.Item label="Submitter">
                {report?.submitter}, {report?.title}
              </Descriptions.Item>
              <Descriptions.Item label="Submitted at" style={{ minWidth: 150 }}>
                {report?.submitted_at}
              </Descriptions.Item>
            </Descriptions>
          </BSpace>
        </StyledContent>
        <StyledContent padding backgroundColor="white">
          <BSpace>
            <h3>JHA Performed</h3>
            {!!report.description && <h4>{report.description}</h4>}
            <Descriptions bordered size="middle">
              <Item label="Workers">{report.workercount}</Item>
              <Item label="Man hours">{report.manhours}</Item>
            </Descriptions>
          </BSpace>
        </StyledContent>

        {report.isWorkerLog && (
          <StyledContent padding backgroundColor="white">
            <ScrollTable
              title="Checklist"
              totalCount={report?.checklist.length}
              onChange={() => {}}
              dataSource={report.checklist}
              columns={[
                {
                  title: "Question",
                  key: "question",
                  size: "md",
                  dataIndex: ["description"],
                  defaultSortOrder: "asc",
                },
                {
                  title: "Response",
                  key: "response",
                  size: "md",
                  dataIndex: ["response"],
                },
                {
                  title: "Comment",
                  key: "comment",
                  size: "md",
                  dataIndex: ["comment"],
                },
              ]}
            />
          </StyledContent>
        )}
        {report.isWorkerLog && (
          <StyledContent padding backgroundColor="white">
            <ScrollTable
              title="Workers"
              totalCount={report?.worker.length}
              onChange={() => {}}
              dataSource={report?.worker}
              columns={[
                {
                  title: "Name",
                  key: "name",
                  size: "md",
                  dataIndex: ["workername"],
                  defaultSortOrder: "asc",
                },
                {
                  title: "Hours",
                  key: "hours",
                  size: "sm",
                  dataIndex: ["hours"],
                },
                {
                  title: "Title",
                  key: "title",
                  size: "md",
                  dataIndex: ["title"],
                },
                {
                  title: "Worker Role",
                  key: "role",
                  size: "md",
                  dataIndex: ["role"],
                },
              ]}
            />
            <Descriptions bordered size="middle">
              <Item label="Foreman Count">{report?.foremancount}</Item>
              <Item label="Journeyman Count">{report?.journeymancount}</Item>
              <Item label="Apprentice Count">{report?.apprenticecount}</Item>
            </Descriptions>
          </StyledContent>
        )}
        {report.image.length > 0 && (
          <StyledContent padding backgroundColor="white">
            <BSpace>
              <h3>Images</h3>
              <ImageCarousel images={report?.image} />
            </BSpace>
          </StyledContent>
        )}
        {report.isWorkerLog && (
          <StyledContent padding backgroundColor="white">
            <ScrollTable
              title="Weather Observations"
              totalCount={report?.weather.length}
              onChange={() => {}}
              dataSource={report?.weather}
              columns={[
                {
                  title: "Comments",
                  key: "comments",
                  size: "md",
                  dataIndex: ["description", "en"],
                },
                {
                  title: "Time observed",
                  key: "timeObserved",
                  size: "md",
                  dataIndex: ["time"],
                  defaultSortOrder: "asc",
                },
                {
                  title: "Affected Subcontractors",
                  key: "affectedSubs",
                  size: "md",
                  dataIndex: ["affectedSubcontractor"],
                },
                {
                  title: "Created by",
                  key: "createdBy",
                  size: "md",
                  dataIndex: ["creator"],
                },
                {
                  title: "Weather Situation",
                  key: "weatherSituation",
                  size: "md",
                  dataIndex: ["weatherSituation"],
                },
              ]}
            />
          </StyledContent>
        )}
        {report.isCommentsLog && report.isWorkerLog && (
          <StyledContent padding backgroundColor="white">
            <ScrollTable
              title="Comments"
              totalCount={report?.comment.length}
              onChange={() => {}}
              dataSource={report?.comment}
              columns={[
                {
                  title: "Comment",
                  key: "comment",
                  width: 300,
                  dataIndex: ["comment"],
                  defaultSortOrder: "asc",
                },
                {
                  title: "Shared With",
                  key: "sharedWith",
                  width: 300,
                  dataIndex: ["shared"],
                },
              ]}
            />
          </StyledContent>
        )}
      </Space>
    </>
  );
};
export default DailyWorkLog;
