import React from "react";
import NavigationProgressBarStep from "./NavigationProgressBarStep";
import clsx from "clsx";

type StepType = {
  title: string;
  id: string;
  disabled?: boolean;
};

export interface NavigationProgressBarProps {
  steps: StepType[];
  currentStepId: string;
}

const NavigationProgressBar: React.FC<NavigationProgressBarProps> = (props) => {
  const filteredSteps = props.steps.filter((step) => !step.disabled);
  const currentStepIndex = filteredSteps.findIndex(
    (step) => step.id === props.currentStepId
  );
  console.group("NavigationSteps");

  console.groupEnd();
  const stepToDot = (step: StepType, index: number) => {
    const isFirst = index === 0;
    const status = (() => {
      if (index < currentStepIndex) {
        return `done`;
      } else if (index > currentStepIndex) {
        return `to do`;
      }
      return `in progress`;
    })();
    return (
      <React.Fragment key={step.id}>
        {!isFirst && (
          <div
            className={clsx(
              `flex flex-1 h-px`,
              status === "done" || status === "in progress"
                ? "bg-interactive-primary"
                : "bg-suplementary-3"
            )}
          ></div>
        )}
        <NavigationProgressBarStep title={step.title} status={status} />
      </React.Fragment>
    );
  };
  if (filteredSteps.length < 1) return null;
  return (
    <div className={`w-full h-2.5 relative flex flex-row items-center gap-0`}>
      {filteredSteps.map(stepToDot)}
      <div className={`absolute w-full px-2 top-2.5`}>
        <div
          className={`relative transition-all top-0 text-center text-1 text-static-secondary`}
          style={{
            left: `${(100 / (filteredSteps.length - 1)) * currentStepIndex}%`,
          }}
        >
          <div
            className={`grid grid-cols-1 grid-rows-1 items-center justify-center`}
          >
            {filteredSteps.map(({ title, id }, index) => (
              <div
                key={id}
                className={clsx(
                  `flex h-0 w-0 items-center justify-center transition-opacity`,
                  props.currentStepId === id ? "opacity-100" : "opacity-0"
                )}
              >
                <p
                  className={`col-start-1 whitespace-nowrap text-center col-end-2 row-start-1 row-end-2`}
                >
                  {title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationProgressBar;
