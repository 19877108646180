import { IconPlus } from "@tabler/icons";
import { Switch, message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { nanoid } from "nanoid";
import React, { FC, useRef, useState } from "react";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import CreateGeneralContractorModal from "src/common/components/dialogs/CreateGeneralContractorModal";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import Button from "src/common/components/general/Button";
import CounterLabel from "src/common/components/general/CounterLabel";
import CustomTable from "src/common/components/tables/basic/CustomTable";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useCreateInviteMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { AGCEmployeeQuery$data } from "src/common/types/generated/relay/AGCEmployeeQuery.graphql";
import {
  AGCGeneralContractors_UpdateProject_Mutation,
  AGCGeneralContractors_UpdateProject_Mutation$data,
} from "src/common/types/generated/relay/AGCGeneralContractors_UpdateProject_Mutation.graphql";
import { AGCGeneralContractors_InsertGC_Mutation } from "src/common/types/generated/relay/AGCGeneralContractors_InsertGC_Mutation.graphql";
import * as uuid from "uuid";
import GetFullID from "src/common/functions/GetFullId";

const AGCGeneralContractors: FC<{
  data: AGCEmployeeQuery$data["general_contractor_connection"]["edges"];
}> = ({ data }) => {
  const [createGC, setCreateGC] = useState(false);
  const [insertGcs] = useAsyncMutation<AGCGeneralContractors_InsertGC_Mutation>(
    graphql`
      mutation AGCGeneralContractors_InsertGC_Mutation($gcName: String!) {
        insert_general_contractor_one(
          object: { name: $gcName, agc_universal_orientation: true }
        ) {
          id
          pk: id @__clientField(handle: "pk")
          name
          projects(where: { agc_universal_orientation: { _eq: true } }) {
            id
            completed
          }
        }
      }
    `,
  );
  const [gcInviteAdminModal, setGCInviteAdminModal] = useState<
    { name: string; id: string } | undefined
  >();
  const [updateProject] =
    useAsyncMutation<AGCGeneralContractors_UpdateProject_Mutation>(graphql`
      mutation AGCGeneralContractors_UpdateProject_Mutation(
        $gcId: uuid!
        $_set: project_set_input!
      ) {
        update_project(
          where: { general_contractor_id: { _eq: $gcId } }
          _set: $_set
        ) {
          affected_rows
          returning {
            id
            completed
          }
        }
      }
    `);

  const updater: (
    store: RecordSourceSelectorProxy<AGCGeneralContractors_UpdateProject_Mutation$data>,
    id: string,
    checked: boolean,
  ) => void = (store, id, checked) => {
    const returning = store
      .getRootField("update_project")
      .getLinkedRecords("returning");
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "AGCEmployeeQuery_general_contractor_connection",
    );
    if (conn) {
      const edge = (conn.getLinkedRecords("edges") || []).find(
        (r) => r.getLinkedRecord("node")?.getDataID() == id,
      );
      if (edge) {
        const node = edge?.getLinkedRecord("node");
        if (node) {
          const dataId = GetFullID("project", uuid.v4());
          const s = store.create(dataId, "projects");
          s.setValue(dataId, "id");

          if (checked)
            node.setLinkedRecords(returning, "projects", {
              where: {
                agc_universal_orientation: { _eq: true },
              },
            });
          else {
            node.setLinkedRecords([], "projects", {
              where: {
                agc_universal_orientation: { _eq: true },
              },
            });
          }
        }
      }
    }
  };

  const [createInvite, { loading }] = useCreateInviteMutation();
  const ref = useRef<FModalRef<{ email: string }>>(null);
  return (
    <>
      {gcInviteAdminModal && (
        <FModal
          title={"Invite new GC/CM Admin for " + gcInviteAdminModal.name}
          ref={ref}
          confirmLoading={loading}
          okText="Send"
          visible={!!gcInviteAdminModal.id}
          onOk={async () => {
            const vals = await ref.current?.form.validateFields();
            if (vals?.email) {
              await createInvite({
                variables: {
                  objects: {
                    joinGeneralContractorId: gcInviteAdminModal.id,
                    toEmail: vals.email,
                    joinRole: "gc-employee",
                    serverContact: true,

                    inviteId: nanoid(12),
                  },
                },
              });
              setGCInviteAdminModal(undefined);
            }
          }}
          onCancel={() => setGCInviteAdminModal(undefined)}
        >
          <FModal.Text
            required
            name="email"
            requiredMessage="Enter Email"
            label="Enter email of user you want to invite"
          />
        </FModal>
      )}

      <CreateGeneralContractorModal
        visible={createGC}
        onCreate={async (values) => {
          setCreateGC(false);
          await insertGcs({
            variables: { gcName: values.name },
            updater: (store) => {
              const node = store.getRootField("insert_general_contractor_one");
              const conn = ConnectionHandler.getConnection(
                store.getRoot(),
                "AGCEmployeeQuery_general_contractor_connection",
              );
              if (conn && node) {
                const edge = store.create(uuid.v4(), "edge");
                edge.setLinkedRecord(node, "node");
                ConnectionHandler.insertEdgeAfter(conn, edge);
              }
            },
          });
        }}
        onCancel={() => setCreateGC(false)}
      />
      <CustomTable
        dataSource={data.map((p) => p.node)}
        title="Participating GC/CMs"
        headerComponent={
          <Button
            label="Create New GC"
            onClick={() => {
              setCreateGC(true);
            }}
          />
        }
        columns={[
          { title: "Name", dataIndex: ["name"] },
          {
            title: "Completed Projects",
            dataIndex: [""],
            render: (val, rec) => (
              <CounterLabel
                count={
                  rec.projects.filter(
                    (p: { completed: boolean }) => p.completed,
                  ).length
                }
                label="Projects"
              />
            ),
          },
          {
            title: "Active Projects",
            dataIndex: ["projects"],
            render: (val) => (
              <CounterLabel
                count={
                  val.filter((p: { completed: boolean }) => !p.completed).length
                }
                label="Projects"
              />
            ),
          },
          {
            title: "Invite GC admins",
            dataIndex: ["id"],
            render: (val, rec) => {
              return (
                <Button
                  label="Invite"
                  icon={IconPlus}
                  onClick={() =>
                    setGCInviteAdminModal({ name: rec.name, id: rec.pk })
                  }
                />
              );
            },
          },
          {
            title: "Activated",
            dataIndex: ["pk"],
            render: (val, rec) => {
              return (
                <Switch
                  checked={!!rec.projects.length}
                  onChange={(checked) => {
                    updateProject({
                      variables: {
                        gcId: val,
                        _set: { agc_universal_orientation: checked },
                      },
                      optimisticResponse: {
                        update_project: {
                          affected_rows: 1,
                          returning: rec.projects.map((p: { id: string }) => ({
                            id: p.id,
                            completed: false,
                          })),
                        },
                      },
                      optimisticUpdater: (store) => {
                        updater(store, rec.id, checked);
                      },
                      updater: (store) => {
                        updater(store, rec.id, checked);
                      },
                    });
                  }}
                />
              );
            },
          },
        ]}
      />
    </>
  );
};
export default AGCGeneralContractors;
