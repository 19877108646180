
export const GCDefaultDescription = `SiteForm invoice for the project(s) and period listed below

General contractor: {gc_name}
Period: {from_date} - {to_date}

To see which of your employees are utilizing the mobile app on one or more projects, login to your profile on the SiteForm website and navigate to Billing in the left side menu. Select an invoice and click on "workers".
Email us at billing@siteform.io with any questions.`;

export const SubDefaultDescription = `SiteForm invoice for the project(s) and period listed below

Subcontractor: {sub_name}
Period: {from_date} - {to_date}

To see which of your employees are utilizing the mobile app on one or more projects, login to your profile on the SiteForm website and navigate to Billing in the left side menu. Select an invoice and click on "workers".
Email us at billing@siteform.io with any questions.`;

export const DefaultFooter = `Thank you for using SiteForm!
We work hard to make your life easier. Let us know what we can do better. Send product feedback to hello@siteform.io`;

export const DefaultAutomticCollection = true;