import { IconSettings } from "@tabler/icons";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import NavigationWrapper, {
  NavigationMenuProps,
} from "src/common/components/layouts/NavigationWrapper";
import BasicWrapper from "../../../../../common/components/layouts/BasicWrapper";

export interface GCProjectSitedeliveryProps {}

const GCProjectSitedelivery: React.FC<GCProjectSitedeliveryProps> = (props) => {
  const navigate = useNavigate();

  const navigationMenu: NavigationMenuProps = {
    navigationItems: [
      {
        label: "Deliveries",
        onClick: () => {
          navigate("deliveries");
        },
        rootPath: "deliveries",
      },
      {
        label: "SubBlocks",
        onClick: () => {
          navigate("subBlocks");
        },
        rootPath: "subBlocks",
      },
      {
        label: "Setings",
        icon: IconSettings,
        onClick: () => {
          navigate("settings");
        },
        rootPath: "settings",
      },
    ],
  };
  return (
    <NavigationWrapper menu={navigationMenu}>
      <BasicWrapper>
        <Outlet />
      </BasicWrapper>
    </NavigationWrapper>
  );
};

export default GCProjectSitedelivery;
