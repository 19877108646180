import dayjs from "dayjs";
import { DateRangeFilterState } from "../../ScrollTable";
import { DataRecord } from "../dataScrollTableTypes";
import getFieldValue from "../getFieldValue";

const arrayDateRangeFilter =
  (dataIndex: ReadonlyArray<string>, filter: DateRangeFilterState) =>
  (record: DataRecord) => {
    const value = getFieldValue(record, dataIndex);
    return typeof value === "string" || typeof value === "number"
      ? dayjs(value).isBetween(filter.from, filter.to)
      : false;
  };
export default arrayDateRangeFilter;
