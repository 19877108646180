const getColorAndCompleted = (
  isTypeAdded: boolean,
  reqFieldsComplete: boolean,
) => {
  if (!isTypeAdded) {
    return { color: "lightgrey", extraLabel: "" };
  } else if (reqFieldsComplete) {
    return { color: "semantic-positive-green", extraLabel: " (Complete)" };
  } else return { color: "semantic-negative", extraLabel: " (Incomplete)" };
};

export default getColorAndCompleted;
