import { Lang_Code_Enum } from "src/common/types/generated/apollo/graphQLTypes";

const langConditional = (
  toLang: Lang_Code_Enum | string,
  args: {
    en: string;
    es: string;
    pt: string;
    original: string;
  }
) => {
  const { en, es, pt, original } = args;

  return toLang === Lang_Code_Enum.Es
    ? es
    : toLang === Lang_Code_Enum.Pt
    ? pt
    : toLang === Lang_Code_Enum.En
    ? en
    : original;
};

export default langConditional;
