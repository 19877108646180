import { graphql } from "babel-plugin-relay/macro";
import React, { FC } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ReportsTabsGroup, {
  ReportsTabType,
} from "src/common/components/ReportsTabsGroup";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { auth } from "src/common/functions/firebase";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import { GCSubcontractorQuery } from "src/common/types/generated/relay/GCSubcontractorQuery.graphql";
import GCSubcontractorJHAs from "./jhas/GCSubcontractorJHAs";
import GCSubcontractorSDS from "./sds/GCSubcontractorSDS";
import GCSubcontractorDetail from "./sub-info/GCSubcontractorDetail";
import GCSubcontractorWorkersAndCrews from "src/domain-features/worker-crew/entry-routes/gc/GCSubcontractorWorkersAndCrews";
import TaskInfo from "src/domain-features/sitesafety/job-hazard-analysis/components/task-view/TaskInfo";
import GCSubcontractorSSSP from "./safety-plans/GCSubcontractorSSSP";

interface GCSubcontractorProps {
  projectId: string;
  subcontractorId: string;
  subview?: string;
  taskId?: string;
}
export const query = graphql`
  query GCSubcontractorQuery(
    $subId: uuid!
    $projectId: uuid!
    $userId: uuid!
    $order: order_by!
    $projectWorkerWhere: project_worker_bool_exp!
  ) {
    ...CrewProjectWorkerOptions_project_worker_connection_frag
      @arguments(projectWorkerWhere: $projectWorkerWhere)
    worker_title_connection(order_by: { translation: { en: asc } }) {
      edges {
        node {
          translation {
            en
            id
          }
          id
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    general_contractor_employee_connection(where: { uid: { _eq: $userId } }) {
      edges {
        node {
          employee_projects {
            project {
              pk: id @__clientField(handle: "pk")
              id
              name
            }
          }
        }
      }
    }

    subcontractor_connection(where: { id: { _eq: $subId } }, first: 1)
      @connection(
        key: "GCSubcontractorQuery_subcontractor_connection"
        filters: []
      ) {
      edges {
        node {
          id @__clientField(handle: "pk")
          name
          trade {
            id @__clientField(handle: "pk")
            name
          }
          address {
            id @__clientField(handle: "pk")
            line_1
            line_2
            city
            state {
              code
              name
            }
            zip_code
          }
          subcontractor_employees(
            where: {
              _not: {
                project_subcontractor_employees: {
                  project_id: { _eq: $projectId }
                }
              }
            }
            order_by: { user: { name: asc } }
          ) {
            user_id
            user {
              name
              email
              phone_number
              created_password
            }
            employee_title {
              name_text
            }
          }
          safety_data_sheets(
            where: {
              project_id: { _is_null: true }
              deleted_at: { _is_null: true }
            }
            order_by: { company_name: asc }
          ) {
            ...SdsFrag @relay(mask: false)
          }
          safety_plans(
            where: {
              project_id: { _is_null: true }
              deleted_at: { _is_null: true }
            }
            order_by: { title: asc }
          ) {
            ...SafetyPlanFrag @relay(mask: false)
          }
          subcontractor_project_worker_titles(
            where: { project_id: { _eq: $projectId } }
            order_by: { worker_title: { translation: { en: asc } } }
          ) {
            id
            project_workers_aggregate(
              where: { is_last: { _eq: true }, deleted_at: { _is_null: true } }
            ) {
              aggregate {
                count
              }
            }
            worker_title {
              id
              translation {
                en
                id
              }
            }
            worker_title_id
          }
        }
      }
    }
    user_connection(where: { id: { _eq: $userId } }) {
      edges {
        node {
          name
          pk: id @__clientField(handle: "pk")
          id
          employee {
            general_contractor {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
            employee_projects(where: { project_id: { _eq: $projectId } }) {
              project {
                id
                pk: id @__clientField(handle: "pk")
                name
              }
            }
          }
        }
      }
    }

    prevSubs: worker_previous_subcontractor_connection(
      where: { subcontractor_id: { _eq: $subId } }
      order_by: { worker: { user: { name: $order } } }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          worker {
            id
            pk: id @__clientField(handle: "pk")
            user {
              name
              email
              phone_number
            }
            current_worker_role
            worker_title {
              translation {
                en
              }
            }
            subcontractor_id
            subcontractor {
              name
            }
            worker_projects(
              where: {
                project_id: { _eq: $projectId }
                subcontractor_id: { _eq: $subId }
              }
            ) {
              id
              pk: id @__clientField(handle: "pk")
              project_id
              project {
                name
              }
              worker_role
              hard_hat_number
              title {
                translation {
                  en
                  es
                  original
                  pt
                }
              }
              orientation_signature_image {
                created_at
              }
            }
          }
        }
      }
    }
    projectSubWorker: project_worker_connection(
      where: {
        project_id: { _eq: $projectId }
        subcontractor_id: { _eq: $subId }
      }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          worker_role
          hard_hat_number
          created_at
          worker_id
          user {
            pk: id @__clientField(handle: "pk")
            created_password
            name
            email
            role
            phone_number
          }
          project {
            name
          }
          subcontractor {
            id
            pk: id @__clientField(handle: "pk")
            name
          }
          title {
            translation {
              ...TextTranslationFrag @relay(mask: false)
            }
          }
        }
      }
    }
  }
`;

const GCSubcontractor: FC<GCSubcontractorProps> = ({
  subcontractorId,
  projectId,
  subview,
  taskId,
}) => {
  const navigate = useNavigate();

  const data = useLazyLoadQuery<GCSubcontractorQuery>(query, {
    projectId,
    projectWorkerWhere: {
      subcontractor_id: { _eq: subcontractorId },
      project_id: { _eq: projectId },
      subcontractor_worker: {},
    },
    userId: auth.currentUser?.uid ?? "",
    subId: subcontractorId,
    order: Order_By.Asc,
  });

  const handleReportsTabClick = (tabId: string) => {
    navigate("../" + tabId);
  };
  const tabs: ReportsTabType[] = [
    { id: "sub-info", label: " POCs & Details" },
    { id: "workers-and-crews", label: "Workers and Crews" },
    { id: "jhas", label: "JHAs" },
    { id: "sds", label: "SDS" },
    { id: "sssp", label: "SSSP" },
  ];

  console.log("task", taskId, "sub", subcontractorId, "proj", projectId);
  return taskId && subcontractorId && projectId ? (
    <TaskInfo {...{ projectId, subcontractorId, taskId, type: "gce" }} />
  ) : (
    <div className="w-full">
      <ReportsTabsGroup
        onTabClick={handleReportsTabClick}
        tabs={tabs}
        tabsType="button"
        selectedTabId={subview}
      />
      {subview === "workers-and-crews" && (
        <GCSubcontractorWorkersAndCrews
          {...{ data: data, projectId, subcontractorId }}
        />
      )}
      {subview === "sub-info" && (
        <GCSubcontractorDetail {...{ data, projectId, subcontractorId }} />
      )}
      {subview === "jhas" && (
        <GCSubcontractorJHAs {...{ data, projectId, subcontractorId }} />
      )}
      {subview === "sds" && (
        <GCSubcontractorSDS {...{ data, projectId, subcontractorId }} />
      )}
      {subview === "sssp" && (
        <GCSubcontractorSSSP {...{ data, projectId, subcontractorId }} />
      )}
    </div>
  );
};
const GCSubcontractorWrapper: FC = () => {
  const { projectId, subcontractorId, subview, taskId } = useParams();
  return subcontractorId && projectId ? (
    <BasicWrapper scrollable>
      <GCSubcontractor {...{ projectId, subcontractorId, subview, taskId }} />
    </BasicWrapper>
  ) : (
    <Navigate to="gce" replace />
  );
};
export default GCSubcontractorWrapper;
