import React from "react";
import dayjs from "dayjs";

interface GCProjectReportsDailyMonthRangeProps {
  startMonth: dayjs.Dayjs;
  endMonth: dayjs.Dayjs;
}

const GCProjectReportsDailyMonthRange: React.FC<
  GCProjectReportsDailyMonthRangeProps
> = (props) => {
  return (
    <div className="text-1.5">{` ${
      !props.startMonth.isSame(props.endMonth)
        ? [props.startMonth, props.endMonth]
            .map((month) => month.format("MMMM YYYY"))
            .join(" → ")
        : props.startMonth.format("MMMM YYYY")
    }`}</div>
  );
};

export default GCProjectReportsDailyMonthRange;
