import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { useMemo, useState, useEffect } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useParams } from "react-router-dom";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { GCProjectCalendarSitedeliveryDeliveriesQuery } from "src/common/types/generated/relay/GCProjectCalendarSitedeliveryDeliveriesQuery.graphql";
import {
  GCProjectCalendarSitedeliveryDeliveries_deleteFilterCalendar_Mutation,
  GCProjectCalendarSitedeliveryDeliveries_deleteFilterCalendar_Mutation$data,
} from "src/common/types/generated/relay/GCProjectCalendarSitedeliveryDeliveries_deleteFilterCalendar_Mutation.graphql";
import {
  GCProjectCalendarSitedeliveryDeliveries_insertFilterCalendar_Mutation,
  GCProjectCalendarSitedeliveryDeliveries_insertFilterCalendar_Mutation$data,
} from "src/common/types/generated/relay/GCProjectCalendarSitedeliveryDeliveries_insertFilterCalendar_Mutation.graphql";
import { DatesRangeType } from "src/common/types/manual/DatesRange";
import * as uuid from "uuid";
import SitedeliveryAddNewDeliveryModal from "../components/SitedeliveryAddNewDeliveryModal";
import { CalendarBaseType, DeliveryType } from "../utilities/sitedeliveryTypes";
import GCProjectCalendarSitedeliveryDeliveriesUI from "./GCProjectCalendarSitedeliveryDeliveriesUI";
import { IconPlus } from "@tabler/icons";
import Button from "src/common/components/general/Button";
import { useSearchParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery } from "src/common/types/generated/relay/GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery.graphql";

const timezoneQuery = graphql`
  query GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery(
    $where: project_bool_exp!
  ) {
    project_connection(where: $where) {
      edges {
        node {
          timezone
        }
      }
    }
  }
`;

const query = graphql`
  query GCProjectCalendarSitedeliveryDeliveriesQuery(
    $delivery_where: delivery_bool_exp!
    $delivery_order_by: [delivery_order_by!]!
    $calendar_where: calendar_bool_exp!
    $filterCalendarWhere: user_project_filter_calendar_bool_exp!
    $projectId: uuid!
    $inclueLogisticsAndCalendarFilter: Boolean!
  ) {
    delivery_connection(
      where: $delivery_where
      first: 10000
      order_by: $delivery_order_by
    )
      @connection(
        key: "GCProjectCalendarSitedeliveryDeliveries_delivery_connection"
        filters: []
      ) {
      edges {
        node {
          ...DeliveryFrag @relay(mask: false)
        }
      }
    }
    calendar_connection(
      where: $calendar_where
      order_by: { id: asc }
      distinct_on: id
    ) {
      edges {
        node {
          ...CalendarFrag @relay(mask: false)
        }
      }
    }
    user_project_filter_calendar_connection(
      first: 10000
      where: $filterCalendarWhere
    )
      @include(if: $inclueLogisticsAndCalendarFilter)
      @connection(
        key: "GCProjectCalendarSitedeliveryDeliveries_user_project_filter_calendar_connection"
        filters: []
      ) {
      edges {
        node {
          id
          calendar {
            id
            pk: id @__clientField(handle: "pk")
            name {
              id
              en
            }
            color_hex
          }
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          logistic_plans(order_by: { created_at: desc }, limit: 1)
            @include(if: $inclueLogisticsAndCalendarFilter) {
            documents(order_by: { sort_index: asc }) {
              ...DocumentFrag @relay(mask: false)
            }
          }
          general_contractor {
            id
            pk: id @__clientField(handle: "pk")
            name
          }
          is_sitedelivery_approval_needed
          is_sitedelivery_block_out_active
          timezone
          project_subcontractors {
            subcontractor {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
          }
        }
      }
    }
    project_delivery_block_out_connection(
      where: { project_id: { _eq: $projectId } }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          start_time
          end_time
          weekday
        }
      }
    }

    project_delivery_sub_block_connection(
      where: { project_id: { _eq: $projectId } }
    ) {
      edges {
        node {
          id
          start_time
          end_time
          weekday
          calendars {
            calendar {
              ...CalendarFrag @relay(mask: false)
            }
          }
          subcontractors {
            subcontractor {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
          }
        }
      }
    }
  }
`;

const insertFilterCalendarMutation = graphql`
  mutation GCProjectCalendarSitedeliveryDeliveries_insertFilterCalendar_Mutation(
    $object: user_project_filter_calendar_insert_input!
  ) {
    insert_user_project_filter_calendar_one(
      object: $object
      on_conflict: {
        constraint: user_project_filter_calendar_user_id_project_id_calendar_id_fil
        update_columns: []
      }
    ) {
      id
      calendar {
        id
        name {
          id
          en
        }
        color_hex
      }
    }
  }
`;

const deleteFilterCalendarMutation = graphql`
  mutation GCProjectCalendarSitedeliveryDeliveries_deleteFilterCalendar_Mutation(
    $where: user_project_filter_calendar_bool_exp!
  ) {
    delete_user_project_filter_calendar(where: $where) {
      returning {
        id
      }
    }
  }
`;

const compareString = (a: string, b: string) => {
  return a > b ? 1 : a < b ? -1 : 0;
};

export interface GCProjectCalendarSitedeliveryDeliveriesProps {
  staticDate?: dayjs.Dayjs; // Static date means the date is not adjustable via UI
  deliveryContentShownOnCards?: boolean;
  keepTimelineInView?: boolean;
  subcontractorId?: string;
  isUserSubAdmin?: boolean;
}

const GCProjectCalendarSitedeliveryDeliveries: React.FC<
  GCProjectCalendarSitedeliveryDeliveriesProps
> = (props) => {
  const { projectId } = useParams();

  if (!projectId) {
    throw new Error("projectId param is missing");
  }

  const timezoneData =
    useLazyLoadQuery<GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery>(
      timezoneQuery,
      { where: { id: { _eq: projectId } } },
    );

  const projectZoneQuery = timezoneData.project_connection.edges[0];
  if (!projectZoneQuery) {
    throw new Error("Project timezone query: Project does not exists");
  }

  const projectTimezone = projectZoneQuery.node.timezone;

  const [searchParams, setSearchParams] = useSearchParams("dayDifference");
  const initialDateRangeFrom =
    props.staticDate?.tz(projectTimezone, true) ||
    dayjs().tz(projectTimezone, true).startOf("week").add(1, "d");

  const hour = dayjs().hour();
  const [timeRange, setTimeRange] = useState<{
    from: 0 | 6 | 17;
    to: 5 | 17 | 24;
  }>({
    from: hour < 5 ? 0 : hour < 17 ? 6 : 17,
    to: hour < 5 ? 5 : hour < 17 ? 17 : 24,
  });

  const [datesRange, setDatesRange] = useState<DatesRangeType>({
    from: initialDateRangeFrom,
    to: initialDateRangeFrom.add(6, "d").endOf("day"),
  });

  const [visible, setVisible] = useState(false);
  const userId = auth.currentUser?.uid;
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState({
    fetchKey: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = dayjs();

      console.log(
        "CURRENT TIME",
        currentTime,
        currentTime.tz(projectTimezone),
        currentTime.tz(projectTimezone, true),
      );

      if (props.staticDate && !searchParams.get("dayDifference")) {
        setDatesRange((prev) => ({
          from:
            prev.from.format("YYYY-MM-DD") !== currentTime.format("YYYY-MM-DD")
              ? prev.from.add(1, "d")
              : prev.from,
          to:
            prev.to.format("YYYY-MM-DD") !==
            currentTime.add(6, "d").format("YYYY-MM-DD")
              ? prev.to.add(1, "d")
              : prev.to,
        }));

        setTimeRange({
          from: currentTime.hour() < 5 ? 0 : currentTime.hour() < 17 ? 6 : 17,
          to: currentTime.hour() < 5 ? 5 : currentTime.hour() < 17 ? 17 : 24,
        });
      }

      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
      }));
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const data = useLazyLoadQuery<GCProjectCalendarSitedeliveryDeliveriesQuery>(
    query,
    {
      delivery_where: {
        project_id: { _eq: projectId },
        start_at: {
          _gte: dayjs(datesRange.from).subtract(1, "day").toISOString(),
          _lte: dayjs(datesRange.to).toISOString(),
        },
        status: { _in: ["Approved", "Pending"] },
      },
      delivery_order_by: [{ start_at: "desc" }],
      calendar_where: {
        projects: {
          project_id: { _eq: projectId },
          is_archive: { _eq: false },
        },
      },
      filterCalendarWhere: !!userId
        ? {
            filter_type: { _eq: "web-deliveries-hidden-calendar" },
            project_id: { _eq: projectId },
            user_id: { _eq: userId },
          }
        : {},
      projectId: projectId,
      inclueLogisticsAndCalendarFilter: !!userId,
    },
    {
      fetchPolicy: "store-and-network",
      ...refreshedQueryOptions,
    },
  );

  const project = data.project_connection.edges[0];
  if (!project) throw new Error("project does not exist");

  const subcontractorOptions = [
    ...[
      {
        id: project.node.general_contractor.pk,
        name: project.node.general_contractor.name,
      },
    ],
    ...project.node.project_subcontractors.map((projSub) => ({
      id: projSub.subcontractor.pk,
      name: projSub.subcontractor.name,
    })),
  ];

  const [loading, setLoading] = useState(false);

  const [insertFilterCalendar] =
    useAsyncMutation<GCProjectCalendarSitedeliveryDeliveries_insertFilterCalendar_Mutation>(
      insertFilterCalendarMutation,
    );

  const [deleteFilterCalendar] =
    useAsyncMutation<GCProjectCalendarSitedeliveryDeliveries_deleteFilterCalendar_Mutation>(
      deleteFilterCalendarMutation,
    );

  const calendarData = useMemo(
    () =>
      data.calendar_connection.edges
        .map((c) => c.node)
        .sort((c1, c2) => compareString(c1.pk, c2.pk)), // Why do we sort them by id?
    [data.calendar_connection.edges],
  );
  const filterCalendarsData = useMemo(
    () =>
      (data.user_project_filter_calendar_connection?.edges || [])
        .map((c) => c.node)
        .sort((fc1, fc2) =>
          compareString(fc1.calendar?.pk ?? "", fc2.calendar?.pk ?? ""),
        ),
    [data.user_project_filter_calendar_connection?.edges],
  );
  const deliveryData = data.delivery_connection?.edges ?? [];

  const gcData = project.node.general_contractor;

  const calendars = useMemo<CalendarBaseType[]>(
    () =>
      calendarData.map((c) => ({
        id: c.pk,
        title: c.name?.en ?? "",
        colorHex: c.color_hex,
        visible: !filterCalendarsData.find((fc) => fc.calendar?.pk === c.pk),
      })),
    [calendarData, filterCalendarsData],
  );

  const deliveries: DeliveryType[] = useMemo(() => {
    return deliveryData.map<DeliveryType>((delivery) => {
      const deliveryNode = delivery.node;
      const startTime = dayjs(deliveryNode.start_at).tz(projectTimezone);
      const endTime = startTime.add(deliveryNode.duration ?? 0, "hour");
      const subcontractor = deliveryNode.subcontractor;
      return {
        type: "delivery",
        title: deliveryNode.name?.en ?? "Delivery title",
        detail: deliveryNode.detail?.en,
        from: startTime,
        subcontractor: subcontractor
          ? {
              title: subcontractor.name,
              id: subcontractor.id,
            }
          : undefined,

        generalContractor: { id: gcData.id, title: gcData.name },
        id: deliveryNode.pk,
        to: endTime,
        createdAt: dayjs(deliveryNode.created_at),
        createdBy: deliveryNode.user?.name,
        reviewed:
          deliveryNode.approved_by &&
          deliveryNode.user?.pk !== deliveryNode.approved_by?.pk
            ? {
                by: deliveryNode.approved_by?.name ?? "",
                type: "approve",
                remark: deliveryNode.remark?.en,
                date: deliveryNode.approved_at
                  ? dayjs(deliveryNode.approved_at)
                  : undefined,
              }
            : undefined,
        storageLocation: deliveryNode.storage_location?.en,
        calendars: deliveryNode.calendars.map(({ calendar }) => ({
          colorHex: calendar?.color_hex ?? "FFF",
          id: calendar?.pk ?? "",
          title: calendar?.name?.en || "",
        })),
        pendingApproval: deliveryNode.status == "Pending",
      };
    });
  }, [deliveryData, calendars, datesRange]);

  const handleOnCalendarHideUpdater = (
    store: RecordSourceSelectorProxy<GCProjectCalendarSitedeliveryDeliveries_insertFilterCalendar_Mutation$data>,
  ) => {
    const insertCalendar = store.getRootField(
      "insert_user_project_filter_calendar_one",
    );
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "GCProjectCalendarSitedeliveryDeliveries_user_project_filter_calendar_connection",
    );
    if (conn && insertCalendar) {
      const edge = store.create(uuid.v4(), "edge");
      edge.setLinkedRecord(insertCalendar, "node");
      ConnectionHandler.insertEdgeAfter(conn, edge);
    }
  };
  const handleOnCalendarHide = async (calendar: CalendarBaseType) => {
    setLoading(true);
    try {
      const filterCalendarId = uuid.v4();
      const oldCalendar = calendarData.find((c) => c.pk === calendar.id);
      if (!oldCalendar) {
        throw new Error("Wrong calendar Id");
      }
      await insertFilterCalendar({
        variables: {
          object: {
            id: filterCalendarId,
            calendar_id: calendar.id,
            filter_type: "web-deliveries-hidden-calendar",
            project_id: projectId,
            user_id: userId,
          },
        },
        optimisticResponse: {
          insert_user_project_filter_calendar_one: {
            id: filterCalendarId,
            calendar: {
              id: calendar.id,
              name: {
                id: oldCalendar.name?.id, // why name is null?
                en: calendar.title,
              },
              color_hex: calendar.colorHex,
            },
          },
        },
        optimisticUpdater: handleOnCalendarHideUpdater,
        updater: handleOnCalendarHideUpdater,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOnCalendarShowUpdater = (
    store: RecordSourceSelectorProxy<GCProjectCalendarSitedeliveryDeliveries_deleteFilterCalendar_Mutation$data>,
  ) => {
    const deleteCalendars = store.getRootField(
      "delete_user_project_filter_calendar",
    );
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "GCProjectCalendarSitedeliveryDeliveries_user_project_filter_calendar_connection",
    );
    if (conn) {
      deleteCalendars.getLinkedRecords("returning").forEach((c) => {
        ConnectionHandler.deleteNode(conn, c.getDataID());
      });
    }
  };
  const handleOnCalendarShow = async (calendar: CalendarBaseType) => {
    setLoading(true);
    try {
      await deleteFilterCalendar({
        variables: {
          where: {
            calendar_id: { _eq: calendar.id },
            project_id: { _eq: projectId },
            user_id: { _eq: userId },
          },
        },
        optimisticResponse: {
          delete_user_project_filter_calendar: {
            returning: [
              {
                id: filterCalendarsData.find(
                  (c) => c.calendar!.pk === calendar.id,
                )!.id,
              },
            ],
          },
        },
        optimisticUpdater: handleOnCalendarShowUpdater,
        updater: handleOnCalendarShowUpdater,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <GCProjectCalendarSitedeliveryDeliveriesUI
        deliveries={deliveries}
        datesRange={datesRange}
        timezone={projectTimezone}
        logisticPlan={project.node.logistic_plans}
        keepTimelineInView={!props.staticDate}
        onDatesRangeChange={!props.staticDate ? setDatesRange : undefined}
        calendars={calendars.map((calendar) => ({
          ...calendar,
          visible: !filterCalendarsData.find(
            (fc) => fc.calendar?.pk === calendar.id,
          ),
        }))}
        toggleCalendarVisible={(calendar, visible) => {
          visible
            ? handleOnCalendarShow(calendar)
            : handleOnCalendarHide(calendar);
        }}
        deliveryContentShownOnCards={props.deliveryContentShownOnCards}
        hideLogistic={!!props.staticDate}
      />

      {!props.staticDate && (
        <div className="fixed bottom-0.5 left-0 right-0 flex justify-center align-middle">
          <Button
            icon={IconPlus}
            onClick={() => {
              setVisible(true);
            }}
            label={`Add Delivery`}
          />
          <SitedeliveryAddNewDeliveryModal
            modalClose={() => {
              setVisible(false);
            }}
            projectId={projectId}
            timezone={projectTimezone}
            gcId={gcData.pk}
            // calendars={calendars.map((calendar) => ({
            //   id: calendar.id,
            //   name: calendar.title,
            // }))}
            isUserGC={props.isUserSubAdmin || props.staticDate ? false : true}
            isApprovalNeeded={project.node.is_sitedelivery_approval_needed}
            modalVisible={visible}
            onSubmit={() => console.log("")}
            subcontractorData={
              props.isUserSubAdmin ? undefined : subcontractorOptions
            }
            subcontractorId={props.subcontractorId}
            deliveryData={data}
          />
        </div>
      )}
    </>
  );
};

export default GCProjectCalendarSitedeliveryDeliveries;
