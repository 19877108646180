import React from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import NestedRoute from "src/common/types/manual/NestedRoute";

const Redirect: React.FC<any> = () => {
    const {code} = useParams();
    const [searchParams] = useSearchParams();
    const su = searchParams.get('su');
    const l = searchParams.get('l');
    let params = [`ht=${code}`];
    if (su) {
      params.push(`su=${su}`);
    }
    if (l) {
      params.push(`lang=${l}`);
    }
    return <Navigate to={`/universal/orientation?${params.join('&')}`} />
}

const universalOrientationShortRoute: NestedRoute = {
  element: <Redirect/>,
};

export default universalOrientationShortRoute;
