/**
 * @generated SignedSource<<574e60f7ab403e8e7011793abaee3341>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type VisitorPosterUploadQuery$variables = Record<PropertyKey, never>;
export type VisitorPosterUploadQuery$data = {
  readonly general_contractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly visitor_log_poster_background_url: string | null | undefined;
      };
    }>;
  };
};
export type VisitorPosterUploadQuery = {
  response: VisitorPosterUploadQuery$data;
  variables: VisitorPosterUploadQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visitor_log_poster_background_url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "VisitorPosterUploadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "general_contractorConnection",
        "kind": "LinkedField",
        "name": "general_contractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "VisitorPosterUploadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "general_contractorConnection",
        "kind": "LinkedField",
        "name": "general_contractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c5cabdb1ae5bb2ae54db5620f5d9392",
    "id": null,
    "metadata": {},
    "name": "VisitorPosterUploadQuery",
    "operationKind": "query",
    "text": "query VisitorPosterUploadQuery {\n  general_contractor_connection {\n    edges {\n      node {\n        visitor_log_poster_background_url\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1dd87466bea895057fc9a6894c430583";

export default node;
