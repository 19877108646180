import NestedRoute from "../../../../common/types/manual/NestedRoute";
import managerRoute from "./routes/manager/managerRoute";
import React from "react";
import workerRoute from "./routes/worker/workerRoute";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import OrientationTVViewer from "./routes/tv/OrientationTVViewer";

const RedirectElement: React.FC = (props) => {
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const completionType = searchParams.get("completionType");
  return (
    <Navigate
      to={`/gce/orientation/${projectId}/manager/starting${
        completionType ? "?completionType=inpersonComplete" : ""
      }`}
    />
  );
};

const inPersonRoute: NestedRoute = {
  children: [
    {
      path: "inperson-screen/:projectId",
      element: <OrientationTVViewer />,
    },
    {
      path: ":projectId",
      children: [
        {
          path: "manager",
          ...managerRoute,
        },
        {
          path: "worker",
          ...workerRoute,
        },
        {
          path: "",
          element: <Navigate to={`manager`} replace />,
        },
      ],
    },

    // The following route ("r/:projectId") redirects from old routing to the new one
    {
      path: "r/:projectId",
      element: <RedirectElement />,
    },
  ],
};

export default inPersonRoute;
