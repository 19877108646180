import { Form, Button } from "antd";
import React, { FC, useState } from "react";
import { ConnectionHandler } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import AddCompanyModal from "src/common/components/dialogs/AddCompanyModal";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { GCUserOACCompanyTable_Delete_Project_Company_Mutation } from "src/common/types/generated/relay/GCUserOACCompanyTable_Delete_Project_Company_Mutation.graphql";
import { GCUserOACCompanyTable_Map_OAC_Company_To_Project_Mutation } from "src/common/types/generated/relay/GCUserOACCompanyTable_Map_OAC_Company_To_Project_Mutation.graphql";
import * as uuid from "uuid";

interface GCUserOACCompanyTableProps {
  projectId: string;
  gcId: string;
  allProjectOACCompanies: Array<{ name: string; id: string }>;
  currentProjectOACCompanies: Array<string>;
}

const GCUserOACCompanyTable: FC<GCUserOACCompanyTableProps> = ({
  gcId,
  currentProjectOACCompanies,
  allProjectOACCompanies,
  projectId,
}) => {
  const [showSelection, setShowSelection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [mapCompanyToProject] =
    useAsyncMutation<GCUserOACCompanyTable_Map_OAC_Company_To_Project_Mutation>(
      graphql`
        mutation GCUserOACCompanyTable_Map_OAC_Company_To_Project_Mutation(
          $objects: [project_oac_company_insert_input!]!
        ) {
          insert_project_oac_company(
            objects: $objects
            on_conflict: {
              constraint: project_oac_company_oac_company_id_project_id_key
              update_columns: []
            }
          ) {
            returning {
              project_id
              oac_company_id
              oac_company {
                name
              }
            }
          }
        }
      `
    );
  const [deleteProjectCompany] =
    useAsyncMutation<GCUserOACCompanyTable_Delete_Project_Company_Mutation>(
      graphql`
        mutation GCUserOACCompanyTable_Delete_Project_Company_Mutation(
          $where: project_oac_company_bool_exp!
        ) {
          delete_project_oac_company(where: $where) {
            returning {
              id
            }
          }
        }
      `
    );
  return (
    <div className="flex flex-col gap-1">
      <p className="text-2">Owner Architect Engineer companies</p>
      <p className="mb-1">
        Will anyone from your Client, Architect, Engineers, or Consultants
        complete your Orientation? Add their company below, but{" "}
        <span className="font-accent">DO NOT</span> add your company, it is
        listed automatically. <br />
        When they scan your Orientation QR code to register they will select
        "Architect, Engineer, Owner" from the list. Anyone from your office and
        field management team will select the same option.
        <br />
        *NOTE: only use this option if they are not submitting reports in
        SiteForm
      </p>
      <div className="-mx-0.25">
        {currentProjectOACCompanies.map((cur) => (
          <div
            className="mx-0.25 inline-block mt-1 text-0.75 px-0.5 py-0.25 rounded-2 bg-interactive-light text-interactive-primary"
            key={cur}
          >
            {allProjectOACCompanies.find((company) => company.id == cur)?.name}
          </div>
        ))}
      </div>
      {/* <Table columns={columns} dataSource={dataSource} bordered></Table> */}

      <div className="flex flex-row justify-start">
        {!showSelection && (
          <Button
            onClick={() => setShowSelection(true)}
            className="mb-2 font-accent rounded-2"
            loading={loading}
          >
            Add a new Company
          </Button>
        )}
        {
          <AddCompanyModal
            selectedCompanyIds={currentProjectOACCompanies}
            allCompanyIds={allProjectOACCompanies}
            projectId={projectId}
            visible={showSelection}
            onCancel={() => setShowSelection(false)}
            onDoneEditing={async (values) => {
              setShowSelection(false);
              const [toBeInsertedCompanyIds, toBeDeletedCompanyIds] =
                compareTwoLists(
                  values.selectedCompanyIds,
                  currentProjectOACCompanies
                );

              currentProjectOACCompanies = [...values.selectedCompanyIds];
              let deleteComplete = toBeDeletedCompanyIds.length == 0,
                insertComplete = toBeInsertedCompanyIds.length == 0;
              if (!deleteComplete || !insertComplete) {
                setLoading(true);
              }
              if (toBeDeletedCompanyIds.length > 0) {
                await deleteProjectCompany({
                  variables: {
                    where: {
                      project_id: { _eq: projectId },
                      oac_company_id: { _in: toBeDeletedCompanyIds },
                    },
                  },
                  updater: (store) => {
                    const deleteProjectOacCompany = store.getRootField(
                      "delete_project_oac_company"
                    );
                    const conn = ConnectionHandler.getConnection(
                      store.getRoot(),
                      "useSiteOrientationGcDashboardDataQuery_project_oac_company_connection"
                    );
                    if (conn) {
                      deleteProjectOacCompany
                        .getLinkedRecords("returning")
                        .forEach((s) => {
                          ConnectionHandler.deleteNode(conn, s.getDataID());
                        });
                    }
                  },
                })
                  .catch((e) => console.error(e))
                  .then(async (d) => {
                    deleteComplete = true;
                    if (insertComplete) {
                      setLoading(false);
                    }
                  });
              }
              if (toBeInsertedCompanyIds.length > 0) {
                await mapCompanyToProject({
                  variables: {
                    objects: toBeInsertedCompanyIds.map((companyId) => ({
                      id: uuid.v4(),
                      project_id: projectId,
                      oac_company_id: companyId,
                    })),
                  },
                  updater: (store) => {
                    const insertProjectOacCompany = store.getRootField(
                      "insert_project_oac_company"
                    );
                    const conn = ConnectionHandler.getConnection(
                      store.getRoot(),
                      "useSiteOrientationGcDashboardDataQuery_project_oac_company_connection"
                    );
                    if (conn) {
                      insertProjectOacCompany
                        .getLinkedRecords("returning")
                        .forEach((s) => {
                          const edge = store.create(uuid.v4(), "edge");
                          edge.setLinkedRecord(s, "node");
                          ConnectionHandler.insertEdgeAfter(conn, edge);
                        });
                    }
                  },
                })
                  .catch((e) => console.error(e))
                  .then(async (d) => {
                    insertComplete = true;
                    if (deleteComplete) {
                      setLoading(false);
                    }
                  });
              }
            }}
          />
        }
      </div>
    </div>
  );
};
export default GCUserOACCompanyTable;
