import { IconUserCircle } from "@tabler/icons";
import React from "react";
import Button from "src/common/components/general/Button";
import { IconType } from "src/common/components/general/Icon";
import Image, { ImageProps } from "src/common/components/general/images/Image";

export interface PeopleListItemSecondaryProps {
  name: string;
  image: ImageProps;
  onClick?: () => void;
  buttons?: {
    onClick: () => void;
    icon: IconType;
    loading?: boolean;
  }[];
}

const PeopleListItemSecondary: React.FC<PeopleListItemSecondaryProps> = (
  props
) => {
  const interactive = !!props.onClick;

  return (
    <div className="flex flex-row items-center gap-0.5">
      <div
        onClick={props.onClick}
        className={`flex flex-1 flex-row items-center justify-start border rounded-2  gap-0.5 ${
          interactive
            ? "border-interactive-secondary hover:border-interactive-primary cursor-pointer"
            : "border-suplementary-1"
        }`}
      >
        <div className="w-2 h-2 overflow-hidden rounded-2">
          <Image {...{ ...props.image, icon: IconUserCircle }} />
        </div>
        <span>{props.name} </span>
      </div>
      {props.buttons && (
        <div className="flex flex-row items-center gap-0.5">
          {props.buttons.map(({ onClick, icon, loading }, index) => (
            <Button
              key={index}
              {...{
                icon,
                onClick,
                secondary: true,
                small: true,
                loading,
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default PeopleListItemSecondary;
