import React, { FC } from "react";
import SiteOrientationGcQrCodeInPerson from "./SiteOrientationGcQrCodeInPerson";
import PosterDownload from "../PosterDownload";
import useUpdateProjectSettings, {
  useUpdateProjectSettingsMutationReturningValues,
} from "src/common/api/relay/mutationHooks/useUpdateProjectSettings";
import { useSiteOrientationGcDashboardDataQuery$data } from "src/common/types/generated/relay/useSiteOrientationGcDashboardDataQuery.graphql";
import { useGenerateInPersonOrientationQrCodePosterNonUoMutation, useGetOrienatationQrCodePosterMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { message } from "antd";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import SiteOrientationGcQrCodeInPersonException from "./SiteOrientationGcQrCodeInPersonException";

const InPersonQR: FC<{
  projectId: string;
  params: useUpdateProjectSettingsMutationReturningValues;
  data: useSiteOrientationGcDashboardDataQuery$data;
}> = (props) => {
  const [
    getOrientationQRCodePoster,
    { loading: orientationQRCodePosterLoading },
  ] = useGetOrienatationQrCodePosterMutation();
  const [
    getInPersonRegistrationQRCodePoster,
    { loading: inPersonRegistrationQRCodePosteLoading },
  ] = useGenerateInPersonOrientationQrCodePosterNonUoMutation();
  const [updateProjectSetting] = useUpdateProjectSettings({
    projectId: props.projectId,
    returningFieldsVal: props.params,
  });
  const secretCode = (props.data.in_person_code.edges || [])[0]?.node.pk;
  const projectData = props.data.project_connection.edges[0].node;
  const agcPosterBackgroundImageUrl =
    "https://storage.googleapis.com/siteform-3170b.appspot.com/StaticAssets/AGC%20MA%20UNIVERSAL%20ORIENTATION%20(1).png";
  const nonAgcPosterBackgroundImageUrl =
    "https://storage.googleapis.com/siteform-3170b.appspot.com/StaticAssets/TEMPLATE%20-%20QR%20Code%20Orientation%20(2).png";
  const secretCodeSecond = (props.data.complete_orientation_in_person_code
    .edges || [])[0]?.node.pk;
  return (
    <>
      {projectData.in_person_orientation ? (
        <div className="flex justify-center w-full">
          <SiteOrientationGcQrCodeInPerson
            workerIsScanning={props.params.worker_scan_in_person_qr_code}
            onWorkerScanQRSettingChange={async (newVal: boolean) => {
              await updateProjectSetting({
                worker_scan_in_person_qr_code: newVal,
              });
              newVal ? message.success("ON") : message.error("OFF");
            }}
            projectId={props.projectId}
            destinationUrl={
              secretCode &&
              `${document.location.origin}/singInLink/${secretCode}`
            }
          />
          {projectData.worker_scan_in_person_qr_code ?(

          projectData.agc_universal_orientation ? (
            // in future we're going to create poster for non agc projects also so we can use this same component there also with diff downloadFunc
            <PosterDownload
              imageUrl={agcPosterBackgroundImageUrl}
              header="Worker Inperson Orientation QR Code Poster"
              description="Poster for workers to scan and register with their own phone"
              loading={orientationQRCodePosterLoading}
              onDownload={async () => {
                await getOrientationQRCodePoster({
                  variables: {
                    input: {
                      projectId: props.projectId,
                      inperson: true,
                    },
                  },
                }).then((data) => {
                  if (data.data?.generateOrientationQRCodePoster) {
                    downloadFromUrl(data.data?.generateOrientationQRCodePoster);
                  }
                });
              }}
            />
          ):(  <PosterDownload
            imageUrl={nonAgcPosterBackgroundImageUrl}
            header="QR Code Poster to Register for In-Person Orientation"
            description="Poster for workers to scan and register with their own phone"
            loading={inPersonRegistrationQRCodePosteLoading}
            onDownload={async () => {
              await getInPersonRegistrationQRCodePoster({
                variables: {
                  input: {
                    projectId: props.projectId,
                    inperson: true,
                  },
                },
              }).then((data) => {
                if (data.data?.generateInPersonOrientationQRCodePosterNonUO) {
                  downloadFromUrl(data.data?.generateInPersonOrientationQRCodePosterNonUO);
                }
              });
            }}
          />)) : (
            <div className="ml-2 w-1/6"></div>
          )}
        </div>
      ) : (
        <div className="flex justify-center w-full">
          <SiteOrientationGcQrCodeInPersonException
            projectId={props.projectId}
            destinationUrl={
              secretCodeSecond &&
              `${document.location.origin}/singInLink/${secretCodeSecond}`
            }
          />
          <div className="ml-2 w-1/6"></div>
        </div>
      )}
    </>
  );
};
export default InPersonQR;
