import NestedRoute from "../../../../../common/types/manual/NestedRoute";
import React from "react";
import SiteBoardDeliveriesCalendar from "./calendar/SiteBoardDeliveriesCalendar";
import SiteBoardDeliveriesList from "./list/SiteBoardDeliveriesList";
import { Navigate } from "react-router-dom";

const deliveriesRoute: NestedRoute = {
  children: [
    {
      path: "calendar",
      element: <SiteBoardDeliveriesCalendar />,
    },
    {
      path: "list",
      element: <SiteBoardDeliveriesList />,
    },
    {
      path: "",
      element: <Navigate to={"list"} replace={true} />,
    },
  ],
};

export default deliveriesRoute;
