import React from "react";
import OpenQrCode, {
  OpenQrCodeProps,
} from "../../../../../../../../common/components/OpenQrCode";
import { Typography } from "antd";

export interface SiteOrientationGcQrCodeProps {
  title: React.ReactNode;
  body: React.ReactNode;
  qrCode: OpenQrCodeProps;
}

const SiteOrientationGcQrCode: React.FC<SiteOrientationGcQrCodeProps> = (
  props
) => {
  return (
    <div className={`p-2 rounded-1.5 w-full bg-suplementary-1 max-w-48`}>
      <div className={`flex flex-row gap-2`}>
        <div className={`flex-1 flex flex-col gap-1`}>
          <p className={`text-1.5 font-accent`}>{props.title}</p>
          <Typography className={`text-static-secondary`}>
            {props.body}
          </Typography>
        </div>
        <div className={`w-12 relative`}>
          <OpenQrCode {...props.qrCode} />
        </div>
      </div>
    </div>
  );
};

export default SiteOrientationGcQrCode;
