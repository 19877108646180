import keys from "src/common/constants/keys";

export interface UploadResult {
  blurhash: string;
  url: string;
  lg_url: string;
  md_url: string;
  sm_url: string;
}
const imageUploadUrl = `${keys.firebaseFunctionsUrl}/imageUpload`;

type ImageUploadPhase = "resize" | "connect" | "upload" | "download" | "done";
type UploadCallback = (
  phase: ImageUploadPhase,
  loaded: number,
  total: number
) => void;
interface CancellableTask<T> {
  cancel: () => void;
  promise: Promise<T>;
}
const uploadWithProgress = (
  url: string,
  data: string,
  cb: UploadCallback
): CancellableTask<string> => {
  let request: XMLHttpRequest | null = new XMLHttpRequest();
  const promise = new Promise<string>((resolve, reject) => {
    if (!request) {
      reject(new Error("timeout exceeded"));
      return;
    }
    cb("connect", 0, 0);
    request.open("POST", url, true);
    request.timeout = 60000;
    request.ontimeout = (ev) => {
      console.log("timeout exceed");
      reject(new Error("timeout exceeded"));
      request = null;
    };
    request.onreadystatechange = () => {
      //      console.log("on ready change ", request?.readyState);
      if (!request || request.readyState !== 4) return;
      const responseData = request.responseText;
      //    console.log(`response: `, responseData);
      resolve(responseData);
      cb("done", 0, 0);
      request = null;
    };
    request.onerror = (e) => {
      if (!request) return;
      reject(new Error("upload error, please check your internet connection"));
      request = null;
    };
    request.onprogress = (e) => {
      //cb("download", e.loaded, e.total);
      console.log("download progress " + e.loaded + " / " + e.total);
      if (!request) {
        return;
      }
    };
    if (request.upload) {
      //      console.log("upload supported");
      request.upload.onprogress = (e) => {
        cb("upload", e.loaded, e.total);
        console.log("upload progress " + e.loaded + " / " + e.total);
      };
    }
    request.setRequestHeader("Content-Type", "text/plain");
    //    console.log("uploading datat = " + data.slice(0, 50));
    request.send(data);
  });
  const cancel = () => {
    if (request) {
      request.abort();
    }
  };
  return { cancel, promise };
};

export class ImageResizeAndUploadTask {
  isCancelled: boolean;
  uploadTask?: CancellableTask<string>;
  base64: string;
  cb: UploadCallback;

  constructor(base64: string, cb: UploadCallback = () => {}) {
    this.isCancelled = false;
    this.uploadTask = undefined;
    this.base64 = base64;
    this.cb = cb;
  }

  // async resize(): Promise<string> {
  //   const base64 = this.base64;
  //   //console.log(`enter resize uri = ${uri.slice(0, 100)}`)
  //   //    console.log(`enter resize uri = ${uri.slice(0, 100)} size = ${size}`);
  //   //if (size && size > 3000000) {
  //   this.cb("resize", 0, 0);
  //   const compress_start = Date.now();

  //   const sompress_end = Date.now();
  //   console.log(
  //     "compress image data: " +
  //       base64?.slice(0, 40) +
  //       ` ${sompress_end - compress_start} ms len = ${base64?.length}`
  //   );
  //   if (!base64) throw new Error("Resize returned undefined image");
  //   return "data:image/jpeg;base64," + base64;
  //   //}
  // }

  async execute(): Promise<UploadResult> {
    const data = this.base64;

    const c = data.indexOf(",");
    const upload = uploadWithProgress(
      imageUploadUrl,
      data.slice(c + 1),
      this.cb
    );
    this.uploadTask = upload;
    const upload_start = Date.now();
    const response = await upload.promise
      .finally(() => {
        this.uploadTask = undefined;
      })
      .catch((error) => {
        console.log(`upload failed ${error.message}`);
        throw error;
      });
    const upload_end = Date.now();
    console.log("uploadAsync " + (upload_end - upload_start) + " ms");
    const res = JSON.parse(response);
    return {
      url: res.url,
      lg_url: res.lg_url,
      md_url: res.md_url,
      sm_url: res.sm_url,
      blurhash: res.blurhash,
    };
  }

  cancel() {
    if (!this.isCancelled) {
      this.isCancelled = true;
      if (this.uploadTask) this.uploadTask.cancel();
    }
  }
}

const uploadImage = async (
  base64: string,
  cb: UploadCallback = () => {}
): Promise<UploadResult> => {
  const task = new ImageResizeAndUploadTask(base64, cb);
  return await task.execute();
};

export default uploadImage;
