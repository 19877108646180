/**
 * @generated SignedSource<<092bd79760897f78f8d55f40a24194da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCCompanySettings_clearAuthData_Mutation$variables = {
  general_contractor_id: string;
};
export type GCCompanySettings_clearAuthData_Mutation$data = {
  readonly update_procore_auth_data_by_pk: {
    readonly access_token: string | null | undefined;
    readonly refresh_token: string | null | undefined;
    readonly service_account_client_id: string | null | undefined;
    readonly service_account_client_secret: string | null | undefined;
    readonly token_expires_at: string | null | undefined;
    readonly user_id: number | null | undefined;
    readonly user_login: string | null | undefined;
    readonly user_name: string | null | undefined;
  } | null | undefined;
};
export type GCCompanySettings_clearAuthData_Mutation = {
  response: GCCompanySettings_clearAuthData_Mutation$data;
  variables: GCCompanySettings_clearAuthData_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "general_contractor_id"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "_set",
    "value": {
      "access_token": null,
      "refresh_token": null,
      "service_account_client_id": null,
      "service_account_client_secret": null,
      "token_expires_at": null,
      "user_id": null,
      "user_login": null,
      "user_name": null
    }
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "general_contractor_id",
        "variableName": "general_contractor_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_login",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refresh_token",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "access_token",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token_expires_at",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "service_account_client_id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "service_account_client_secret",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCCompanySettings_clearAuthData_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "procore_auth_data",
        "kind": "LinkedField",
        "name": "update_procore_auth_data_by_pk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCCompanySettings_clearAuthData_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "procore_auth_data",
        "kind": "LinkedField",
        "name": "update_procore_auth_data_by_pk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7a2de6fdb0f97446e1520460c838ff0c",
    "id": null,
    "metadata": {},
    "name": "GCCompanySettings_clearAuthData_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCCompanySettings_clearAuthData_Mutation(\n  $general_contractor_id: uuid!\n) {\n  update_procore_auth_data_by_pk(pk_columns: {general_contractor_id: $general_contractor_id}, _set: {user_id: null, user_name: null, user_login: null, refresh_token: null, access_token: null, token_expires_at: null, service_account_client_id: null, service_account_client_secret: null}) {\n    user_id\n    user_name\n    user_login\n    refresh_token\n    access_token\n    token_expires_at\n    service_account_client_id\n    service_account_client_secret\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7fcbf6f8faf37783d9f270bc5964d144";

export default node;
