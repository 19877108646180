import React from "react";
import { useUpdateElevation } from "../table/WorkAboveElevationTable";
import { Checkbox, Modal } from "antd";
import { EditWorkAboveElevationModal_TextUpdate_Mutation } from "src/common/types/generated/relay/EditWorkAboveElevationModal_TextUpdate_Mutation.graphql";
import { EditWorkAboveElevationModal_work_above_elevation_frag$key } from "src/common/types/generated/relay/EditWorkAboveElevationModal_work_above_elevation_frag.graphql";
import WorkerProfileContactLink from "src/root/routes/views/subcontractor/people/workers/worker-profile/WorkerProfileContactLink";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { graphql } from "babel-plugin-relay/macro";
import WorkAboveElevationFloor from "./WorkAboveElevationFloor";
import dayjs from "dayjs";
import { useRefetchableFragment } from "react-relay/hooks";
import { WorkAboveElevationTableQuery } from "src/common/types/generated/relay/WorkAboveElevationTableQuery.graphql";

export const useUpdateText = () =>
  useAsyncMutation<EditWorkAboveElevationModal_TextUpdate_Mutation>(graphql`
    mutation EditWorkAboveElevationModal_TextUpdate_Mutation(
      $id: uuid!
      $_set: text_translation_set_input!
    ) {
      update_text_translation_by_pk(pk_columns: { id: $id }, _set: $_set) {
        id
        pk: id @__clientField(handle: "pk")
        en
        original
      }
    }
  `);
const EditWorkAboveElevationModal: React.FC<{
  visible: boolean;
  workAboveElevationDataKey: EditWorkAboveElevationModal_work_above_elevation_frag$key;
  onCancel: () => void;
}> = ({ workAboveElevationDataKey, visible, onCancel }) => {
  const [updateElevation, updatingElevation] = useUpdateElevation();
  const [updateText] = useUpdateText();
  const [elevationData] = useRefetchableFragment<
    WorkAboveElevationTableQuery,
    EditWorkAboveElevationModal_work_above_elevation_frag$key
  >(
    graphql`
      fragment EditWorkAboveElevationModal_work_above_elevation_frag on work_above_elevation
      @refetchable(
        queryName: "EditWorkAboveElevationModal_work_above_elevation_frag_refetch"
      ) {
        ...WorkAboveElevationFrag @relay(mask: false)
      }
    `,
    workAboveElevationDataKey,
  );

  return (
    <Modal
      title="Edit Location Details"
      open={visible}
      width={700}
      onCancel={onCancel}
      onOk={onCancel}
    >
      <div className="flex gap-1">
        <div className="w-10 mt-0.5 font-accent">Location Name:</div>
        <WorkerProfileContactLink
          currentValue={elevationData.name.en}
          saveButtonDesign="save"
          type="name"
          onUpdate={(text) => {
            updateText({
              variables: {
                _set: { original: text, en: text },
                id: elevationData.name_id,
              },
              optimisticResponse: {
                update_text_translation_by_pk: {
                  id: elevationData.name.id,
                  pk: elevationData.name_id,
                  original: text,
                  en: text,
                },
              },
            });
          }}
        />
      </div>
      <div className="font-accent">
        Completed: &nbsp;&nbsp;
        <Checkbox
          checked={!!elevationData.completed_at}
          onChange={(e) =>
            updateElevation({
              variables: {
                _set: {
                  completed_at: e.target.checked ? dayjs().toISOString() : null,
                },
                id: elevationData.pk,
              },
              optimisticResponse: {
                update_work_above_elevation_by_pk: {
                  ...elevationData,
                  completed_at: e.target.checked ? dayjs().toISOString() : null,
                },
              },
            })
          }
        />{" "}
      </div>
      <br />
      <WorkAboveElevationFloor
        elevationId={elevationData.pk}
        allFloors={[...elevationData.work_above_floors]}
      />
    </Modal>
  );
};
export default EditWorkAboveElevationModal;
