import React, { useState } from "react";
import trimString from "../../../../../common/functions/trimString";
import SiteFeatureStepsInputLayout from "../../../../../common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { Input } from "antd";
import onEnterDown from "../../../utils/onEnterDown";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import { SiteOrientationStepProps } from "../../../utils/siteOrientationTypes";
import { isNextForNameAllowed } from "src/domain-features/siteorientation/utils/isNextForNameAllowed";
import { CurrentLangStrings } from "../../../../../utility-features/i18n/language-utils/i18nTypes";

export interface SiteOrientationStepsUIFullNameProps
  extends Omit<SiteOrientationStepProps, "onNextButtonClick"> {
  defaultName?: string;
  onNameSubmit: (name: string) => void;
  langStrings: CurrentLangStrings;
}

const SiteOrientationStepsUIFullName: React.FC<
  SiteOrientationStepsUIFullNameProps
> = (props) => {
  const [fullName, setFullName] = useState(props.defaultName || "");

  const onEnter = () => {
    if (!fullName) return;
    props.onNameSubmit(trimString(fullName));
  };
  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!isNextForNameAllowed(fullName)}
    >
      <SiteFeatureStepsInputLayout
        headline={props.langStrings.strings.whatIsYOurFullName}
      >
        <div className="text-grey text-1">
          {props.langStrings.strings.nameAsShownOnYourID}
        </div>
        <Input
          value={fullName}
          placeholder={props.langStrings.strings.firstAndLastNameCapital}
          onKeyDown={(e) => {
            const input = e.target as HTMLInputElement;
            input.value = input.value.replace(/(\b[a-z](?!\s))/g, (c) =>
              c.toUpperCase(),
            );
            if (input.value.length > 1 && isNextForNameAllowed(input.value))
              onEnterDown(e, onEnter);

            // if (input.value) setNamePresent(() => input.value);
          }}
          enterKeyHint={"enter"}
          onChange={(e) => setFullName(e.target.value)}
        />
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationStepsUIFullName;
