import React from "react";
import { Skeleton } from "antd";

export interface FallbackSkeletonCenteredProps {}

const FallbackSkeletonCentered: React.FC<FallbackSkeletonCenteredProps> = (
  props
) => {
  return (
    <div className={`h-full w-full flex flex-col justify-center`}>
      <Skeleton active={true} />
    </div>
  );
};

export default FallbackSkeletonCentered;
