/**
 * @generated SignedSource<<755089cc155e6a81b71005fda6301ff7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ht_integration_account_set_input = {
  access_token?: string | null | undefined;
  active?: boolean | null | undefined;
  agc_license_type_id?: string | null | undefined;
  agc_license_type_name?: string | null | undefined;
  created_at?: string | null | undefined;
  id?: string | null | undefined;
  last_sync_at?: string | null | undefined;
  last_sync_status?: string | null | undefined;
  login?: string | null | undefined;
  password?: string | null | undefined;
  project_filter?: any | null | undefined;
  skip_labor_union?: boolean | null | undefined;
  tenant?: string | null | undefined;
  token_expires_at?: string | null | undefined;
};
export type HtProjectFilterModal_updateHtAccount_Mutation$variables = {
  _set: ht_integration_account_set_input;
  htAccountId: string;
};
export type HtProjectFilterModal_updateHtAccount_Mutation$data = {
  readonly update_ht_integration_account_by_pk: {
    readonly id: string;
    readonly project_filter: any | null | undefined;
  } | null | undefined;
};
export type HtProjectFilterModal_updateHtAccount_Mutation = {
  response: HtProjectFilterModal_updateHtAccount_Mutation$data;
  variables: HtProjectFilterModal_updateHtAccount_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "htAccountId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "htAccountId"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "ht_integration_account",
    "kind": "LinkedField",
    "name": "update_ht_integration_account_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "project_filter",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HtProjectFilterModal_updateHtAccount_Mutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HtProjectFilterModal_updateHtAccount_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d8f2901922da80a3ac64b3de131eaf19",
    "id": null,
    "metadata": {},
    "name": "HtProjectFilterModal_updateHtAccount_Mutation",
    "operationKind": "mutation",
    "text": "mutation HtProjectFilterModal_updateHtAccount_Mutation(\n  $htAccountId: uuid!\n  $_set: ht_integration_account_set_input!\n) {\n  update_ht_integration_account_by_pk(pk_columns: {id: $htAccountId}, _set: $_set) {\n    id\n    project_filter\n  }\n}\n"
  }
};
})();

(node as any).hash = "7a0a3502a37392d450e1775c6fb0d2e9";

export default node;
