import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useParams } from "react-router-dom";
import { ConnectionHandler } from "relay-runtime";
import compareTwoLists from "src/common/components/ComparingTwoLists";
// import Button from "src/common/components/general/Button";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { EmailProjectUserSettingsMutation } from "src/common/types/generated/relay/EmailProjectUserSettingsMutation.graphql";
import {
  EmailProjectUserSettingsQuery,
  project_employee_bool_exp,
} from "src/common/types/generated/relay/EmailProjectUserSettingsQuery.graphql";

import * as uuid from "uuid";
import SelectEmailUsersForActiveUsersModal from "./components/SelectEmailUsersForActiveUsersModal";
import { Button } from "antd";

const query = graphql`
  query EmailProjectUserSettingsQuery(
    $projectId: uuid!
    $projectEmployeeWhere: project_employee_bool_exp!
    $type: String!
  ) {
    project_employee_connection(
      order_by: { employee: { user: { name: asc } } }
      where: $projectEmployeeWhere
    ) {
      edges {
        node {
          employee {
            user {
              id
              pk: id @__clientField(handle: "pk")
              name
              email
            }
          }
        }
      }
    }
    email_project_user_connection(
      first: 10000
      where: { project_id: { _eq: $projectId }, type: { _eq: $type } }
      order_by: { user: { name: asc } }
    )
      @connection(
        key: "EmailProjectUserSettings_email_project_user_connection"
        filters: ["where"]
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          project_id
          type
          user {
            id
            pk: id @__clientField(handle: "pk")
            name
            email
          }
        }
      }
    }
  }
`;
const selectEmailUserMutation = graphql`
  mutation EmailProjectUserSettingsMutation(
    $objects: [email_project_user_insert_input!]!
    $deleteWhere: email_project_user_bool_exp!
  ) {
    insert_email_project_user(objects: $objects) {
      returning {
        id
        pk: id @__clientField(handle: "pk")
        project_id
        user {
          id
          pk: id @__clientField(handle: "pk")
          name
          email
        }
        type
      }
    }
    delete_email_project_user(where: $deleteWhere) {
      returning {
        id
      }
    }
  }
`;

const EmailProjectUserSettings: FC<{
  type: "active_users" | "incident_reporting" | "safety_directors";
  projectId: string;
  title: string;
  description: string;
  buttonTitle?: string;
  projectEmpFilter?: project_employee_bool_exp;
}> = ({
  type,
  projectId,
  projectEmpFilter,
  title,
  description,
  buttonTitle,
}) => {
  const [showSelectEmailUserModal, setShowSelectEmailUserModal] =
    useState(false);
  const [selectEmailUsers] = useAsyncMutation<EmailProjectUserSettingsMutation>(
    selectEmailUserMutation,
  );
  const data = useLazyLoadQuery<EmailProjectUserSettingsQuery>(query, {
    projectId,
    projectEmployeeWhere: {
      project_id: { _eq: projectId },
      ...(projectEmpFilter || {}),
    },
    type,
  });

  const selectedUsers = data.email_project_user_connection.edges;
  const modalOpen = () => {
    setShowSelectEmailUserModal(true);
  };

  const modalClose = () => {
    setShowSelectEmailUserModal(false);
  };
  return (
    <div className="flex flex-col gap-1">
      <p className="text-2">{title}</p>
      <p style={{ marginBottom: 9, fontWeight: 400 }}>{description}</p>
      <div className="-mx-0.25">
        {selectedUsers.map((user) => (
          <span
            className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-suplementary-1"
            key={user.node.user.id}
          >
            {user.node.user.name}
          </span>
        ))}
      </div>

      <div className="flex flex-row justify-start">
        <Button onClick={() => modalOpen()} loading={false} type="primary">
          {buttonTitle || "Select Team Member(s)"}
        </Button>
      </div>
      <SelectEmailUsersForActiveUsersModal
        projectId={projectId}
        userOptions={data.project_employee_connection.edges.map((u) => ({
          value: u.node.employee.user.pk,
          label: u.node.employee.user.name,
        }))}
        selectedUserIds={selectedUsers.map((user) => user.node.user.pk)}
        visible={showSelectEmailUserModal}
        onCancel={modalClose}
        onDoneEditing={async (values) => {
          modalClose();
          const [toBeInsertedUserIds, toBeDeletedUserIds] = compareTwoLists(
            values.usersToBeNotified,
            selectedUsers.map((user) => user.node.user.pk),
          );
          await selectEmailUsers({
            variables: {
              objects: toBeInsertedUserIds.map((userId) => {
                const insertEmailProjectUserId = uuid.v4();
                return {
                  id: insertEmailProjectUserId,
                  project_id: projectId,
                  user_id: userId,
                  type: type,
                };
              }),
              deleteWhere: {
                user_id: { _in: toBeDeletedUserIds },
                project_id: { _eq: projectId },
                type: { _eq: type },
              },
            },
            updater: (store) => {
              const insertedEmailProjectUsers = store.getRootField(
                "insert_email_project_user",
              );

              const deletedEmailProjectUser = store.getRootField(
                "delete_email_project_user",
              );

              const conn = ConnectionHandler.getConnection(
                store.getRoot(),
                "EmailProjectUserSettings_email_project_user_connection",
                {
                  where: {
                    project_id: { _eq: projectId },
                    type: { _eq: type },
                  },
                },
              );

              if (conn) {
                insertedEmailProjectUsers
                  .getLinkedRecords("returning")
                  .forEach((c) => {
                    const edge = store.create(uuid.v4(), "edge");
                    edge.setLinkedRecord(c, "node");
                    ConnectionHandler.insertEdgeAfter(conn, edge);
                  });

                deletedEmailProjectUser
                  .getLinkedRecords("returning")
                  .forEach((c) => {
                    ConnectionHandler.deleteNode(conn, c.getDataID());
                  });
              }
            },
          });
        }}
      />
    </div>
  );
};
export default EmailProjectUserSettings;
