import dayjs from "dayjs";
import React, { FC } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { PartialResultContentQuery } from "src/common/types/generated/relay/PartialResultContentQuery.graphql";
import { graphql } from "babel-plugin-relay/macro";

const PartialResultContent: FC<{
  workerId: string;

  orientationIds: Array<string>;
  notCompleted?: boolean;
}> = withCustomSuspense(({ workerId, orientationIds, notCompleted }) => {
  const data = useLazyLoadQuery<PartialResultContentQuery>(
    graphql`
      query PartialResultContentQuery(
        $workerId: uuid!
        $orientationIds: [uuid!]!
      ) {
        orientation_connection(
          order_by: [{ order: asc }, { name: asc }]
          where: { id: { _in: $orientationIds } }
        ) {
          edges {
            node {
              orientation_results(
                where: {
                  user_id: { _eq: $workerId }
                  _or: [
                    { status: { _eq: "completed" } }
                    { completed_at: { _is_null: false } }
                  ]
                }
              ) {
                completed_at
                created_at
                status
              }
              name
              id @__clientField(handle: "pk")
              slides(
                where: {
                  deleted_at: { _is_null: true }
                  archived_at: { _is_null: true }
                }
                order_by: { order: asc }
              ) {
                viewed_by(
                  where: { user_id: { _eq: $workerId } }
                  order_by: { created_at: desc }
                  limit: 1
                ) {
                  id
                  created_at
                }
              }
            }
          }
        }
      }
    `,
    { workerId, orientationIds },
  );
  const workerWatched = data.orientation_connection.edges.filter(
    (p) =>
      p.node.slides.some((s) => s.viewed_by.length > 0) ||
      p.node.orientation_results.length > 0,
  );
  const FindMaxDate: FC<{
    results: PartialResultContentQuery["response"]["orientation_connection"]["edges"][number]["node"]["orientation_results"];
  }> = ({ results }) => {
    const completionDates: Array<dayjs.Dayjs> = [];
    results.forEach((r) => {
      if (r.completed_at || r.created_at)
        completionDates.push(dayjs(r.completed_at || r.created_at));
    });
    return (
      <>
        {completionDates.length
          ? dayjs.max(completionDates)?.format("MM/DD/YYYY")
          : ""}
      </>
    );
  };
  return (
    <div>
      {workerWatched.length === 0 && orientationIds.length > 0 ? (
        <>
          {data.orientation_connection.edges.map((p) => (
            <div key={p.node.id}>
              <span className="">{p.node.name}:</span>{" "}
              <span className={`font-accent text-semantic-negative`}>
                0/{p.node.slides.length} slides
              </span>
            </div>
          ))}
        </>
      ) : (
        <>
          {data.orientation_connection.edges.map((p) => {
            const completedModule =
              p.node.orientation_results.length > 0 ||
              p.node.slides.filter((s) => s.viewed_by.length > 0).length ===
                p.node.slides.length;
            const lastViewHistoryDate =
              p.node.slides[0]?.viewed_by?.[0]?.created_at;
            return (
              <div key={p.node.id}>
                <span className="">{p.node.name}:</span>{" "}
                <span
                  className={`font-accent 
                ${
                  completedModule
                    ? "text-semantic-positive-green"
                    : "text-semantic-negative"
                }`}
                >
                  {completedModule
                    ? "COMPLETED "
                    : `${
                        p.node.slides.filter((s) => s.viewed_by.length > 0)
                          .length
                      }/${p.node.slides.length} slides`}
                  {p.node.orientation_results.length > 0 ? (
                    <FindMaxDate results={p.node.orientation_results} />
                  ) : completedModule && lastViewHistoryDate ? (
                    <>{dayjs(lastViewHistoryDate).format("MM/DD/YYYY")}</>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            );
          })}
        </>
      )}
      {notCompleted && (
        <span className="text-semantic-negative text-1">
          Other Modules and/or Signature: INCOMPLETE
        </span>
      )}
    </div>
  );
});
export default PartialResultContent;
