import { RouteObject } from "react-router-dom";
import gcCorporateRoute from "./corporate/gcCorporateRoute";
import gcDivisionsRoute from "./division/gcDivisionsRoute";
import gcBusinessUnitsRoute from "./business-unit/gcBusinessUnitsRoute";
import gcOfficesRoute from "./office/gcOfficesRoute";

const gcHierarchyRoutes: RouteObject[] = [
  {
    path: "corporate",
    ...gcCorporateRoute,
  },
  {
    path: "divisions",
    ...gcDivisionsRoute,
  },
  {
    path: "business-units",
    ...gcBusinessUnitsRoute,
  },
  {
    path: "offices",
    ...gcOfficesRoute,
  },
];
export default gcHierarchyRoutes;
