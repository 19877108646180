import {
  IconArrowBackUp,
  IconPhoto,
  IconTable,
  IconUser,
  IconX,
} from "@tabler/icons";
import {
  Divider,
  Form,
  Image,
  message,
  notification,
  Popconfirm,
  Radio,
} from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useLazyLoadQuery, useMutation } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import { SelectorStoreUpdater } from "relay-runtime";
import AddLogoModal, {
  AddLogoModalRef,
} from "src/common/components/dialogs/AddLogoModal";
import AddProcoreIntegrationModal, {
  AddProcoreIntegrationModalRef,
} from "src/common/components/dialogs/AddProcoreIntegrationModal";
import Button from "src/common/components/general/Button";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useProcoreSetUserAccountMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { GCCompanySettingsQuery } from "src/common/types/generated/relay/GCCompanySettingsQuery.graphql";
import { GCCompanySettings_clearAuthData_Mutation } from "src/common/types/generated/relay/GCCompanySettings_clearAuthData_Mutation.graphql";
import {
  GCCompanySettings_procoreSetIntegrationSwitch_Mutation,
  GCCompanySettings_procoreSetIntegrationSwitch_Mutation$data,
} from "src/common/types/generated/relay/GCCompanySettings_procoreSetIntegrationSwitch_Mutation.graphql";
import { GCCompanySettings_removeAuthData_Mutation } from "src/common/types/generated/relay/GCCompanySettings_removeAuthData_Mutation.graphql";
import { GCCompanySettings_UpdateGeneralContractorMutation } from "src/common/types/generated/relay/GCCompanySettings_UpdateGeneralContractorMutation.graphql";
import { GCCompanySettings_removeProjectData_Mutation } from "src/common/types/generated/relay/GCCompanySettings_removeProjectData_Mutation.graphql";
import GCCompleteCorporateEmailSelectModal from "./GCCompleteCorporateEmailSelectModal";
import GetFullID from "src/common/functions/GetFullId";
import { GCCompanySettings_updateGC_Mutation } from "src/common/types/generated/relay/GCCompanySettings_updateGC_Mutation.graphql";
import { GCCompanySettings_updateProjects_Mutation } from "src/common/types/generated/relay/GCCompanySettings_updateProjects_Mutation.graphql";
import { GCCompanySettings_UpdateWaiverText_Mutation } from "src/common/types/generated/relay/GCCompanySettings_UpdateWaiverText_Mutation.graphql";
import { SwitchWithText } from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/settings/components/SiteOrientationSettingSwitchCards";
import JoditEditor from "jodit-react";
import { JoditConfig } from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/slides/GCProjectOrientationDetail";
import useAuthUser from "src/common/hooks/useAuthUser";
import ConnectAsUserButton, {
  procoreCompanyRedirectUrl,
} from "src/common/components/ConnectProcoreUserButton";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import GcHierarchyModal from "./GCHierarchyModal";
import withCorporateAdminWrapper from "../../withCorporateAdminWrapper";

// const GetFullID = (table: string, id: string) =>
//   btoa(`[1, "public", "${table}", "${id}"]`);

const SettingsQuery = graphql`
  query GCCompanySettingsQuery($uid: uuid!) {
    email_general_contractor_user_connection(
      where: {
        type: { _eq: "corporate_completion_weekly_email" }
        general_contractor: { employees: { uid: { _eq: $uid } } }
      }
      first: 10000
    )
      @connection(
        key: "GCCompanySettingsQuery_email_general_contractor_user_connection"
        filters: []
      ) {
      edges {
        node {
          general_contractor_id
          user_id
          id
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    project_connection(
      where: { general_contractor: { employees: { uid: { _eq: $uid } } } }
      first: 10000
      order_by: { name: asc }
    )
      @connection(
        key: "GCCompanySettingsQuery_project_connection"
        filters: []
      ) {
      edges {
        node {
          name
          id
          send_corporate_video_completion_report
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    user_connection(where: { id: { _eq: $uid } }) {
      edges {
        node {
          employee {
            general_contractor_id
            is_corporate_admin
            general_contractor {
              logo_url
              require_phone
              hierarchy_division_name
              hierarchy_business_unit_name
              hierarchy_office_name
              require_dob
              send_weekly_email
              visitor_waiver_text
              employees(order_by: { user: { name: asc } }) {
                uid
                user {
                  name
                }
              }
              procore_auth_data {
                id
                service_account_client_id
                user_id
                user_name
                user_login
                integration_enabled
                sandbox
              }
              hot_work_permit_name
            }
          }
        }
      }
    }
  }
`;
const procoreSwitchUpdater: SelectorStoreUpdater<
  GCCompanySettings_procoreSetIntegrationSwitch_Mutation$data
> = (store, payload) => {
  if (!payload || !payload.insert_procore_auth_data_one) return;
  const gc_id = payload.insert_procore_auth_data_one
    .general_contractor_id as string;
  const value = payload.insert_procore_auth_data_one.integration_enabled;
  const parent_id = GetFullID("general_contractor", gc_id);
  const parent = store.get(parent_id);
  let record = parent?.getLinkedRecord("procore_auth_data");
  if (!record) {
    const record_id = GetFullID("procore_auth_data", gc_id);
    record = store.get(record_id);
    if (!record) record = store.create(record_id, "procore_auth_data");
    parent?.setLinkedRecord(record, "procore_auth_data");
  }
  record.setValue(value, "integration_enabled");
};

const GCCompanySettings: FC = () => {
  // Refresh
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState(Object);
  const queryOptions = refreshedQueryOptions ?? {};

  const navigate = useNavigate();

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev: any) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: "network-only",
    }));
  }, []);
  // Refresh ends
  const [upsertProcoreIntegrationEnabled] =
    useAsyncMutation<GCCompanySettings_procoreSetIntegrationSwitch_Mutation>(graphql`
      mutation GCCompanySettings_procoreSetIntegrationSwitch_Mutation(
        $switch: Boolean!
        $GCId: uuid!
      ) {
        insert_procore_auth_data_one(
          object: { general_contractor_id: $GCId, integration_enabled: $switch }
          on_conflict: {
            constraint: procore_auth_data_pkey
            update_columns: [integration_enabled]
          }
        ) {
          general_contractor_id
          integration_enabled
        }
      }
    `);

  const [updateGeneralContractor] =
    useAsyncMutation<GCCompanySettings_UpdateGeneralContractorMutation>(graphql`
      mutation GCCompanySettings_UpdateGeneralContractorMutation(
        $id: uuid!
        $_set: general_contractor_set_input!
      ) {
        update_general_contractor_by_pk(pk_columns: { id: $id }, _set: $_set) {
          id
        }
      }
    `);
  const authUser = useAuthUser();
  const addProcoreIntegrationModal =
    useRef<AddProcoreIntegrationModalRef>(null);
  const data = useLazyLoadQuery<GCCompanySettingsQuery>(
    SettingsQuery,
    { uid: authUser.uid },
    queryOptions,
  );

  const employee = data.user_connection.edges[0]?.node.employee;

  const general_contractor_id = employee?.general_contractor_id;
  if (!general_contractor_id)
    throw new Error("Genaral contractor isnot specified");

  const auth_data = employee?.general_contractor.procore_auth_data;

  const [hotWorkPermitName, setHotWorkPermitName] = useState<string>(
    employee?.general_contractor.hot_work_permit_name ?? "",
  );

  const sandbox_suffix = auth_data?.sandbox ? " [sandbox]" : "";
  const conection_state = auth_data?.user_login
    ? `Procore User Login: ${auth_data.user_login} ${
        auth_data.user_name || ""
      }${sandbox_suffix}`
    : auth_data?.service_account_client_id
    ? `Service Account Client Id: ${auth_data.service_account_client_id}${sandbox_suffix}`
    : "Please select authentication method";

  const has_auth_data = !!(
    auth_data?.user_login || auth_data?.service_account_client_id
  );

  const procoreUserButtonTitle = auth_data?.user_login // "Connect as Procore User";
    ? "Change Procore User"
    : "Connect as Procore User";

  const serviceAccountButtonTitle =
    !auth_data?.user_login && auth_data?.service_account_client_id
      ? "Change Service Account"
      : "Connect as Service Account";

  const [SetProcoreUserAccount] = useProcoreSetUserAccountMutation({
    onCompleted: (data) => {
      if (data.procoreSetUserAccount?.user_id !== auth_data?.user_id) {
        removeProjectData();
      }
      refresh();
    },
  });
  const integrationEnabled = auth_data?.integration_enabled || false;

  // Remove Client
  const [corporateCompletionModalOpen, setCorporateCompletionModalOpen] =
    useState(false);
  const [clearIntegrationSettings] =
    useAsyncMutation<GCCompanySettings_removeProjectData_Mutation>(
      graphql`
        mutation GCCompanySettings_removeProjectData_Mutation(
          $general_contractor_id: uuid!
        ) {
          delete_procore_subcontractor(
            where: {
              project: {
                general_contractor_id: { _eq: $general_contractor_id }
                procore_project_data: {
                  user_id: { _is_null: true }
                  service_account_client_id: { _is_null: true }
                }
              }
            }
          ) {
            affected_rows
            returning {
              id @deleteRecord
            }
          }
          delete_procore_project_data(
            where: {
              project: {
                general_contractor_id: { _eq: $general_contractor_id }
                procore_project_data: {
                  user_id: { _is_null: true }
                  service_account_client_id: { _is_null: true }
                }
              }
            }
          ) {
            affected_rows
            returning {
              id @deleteRecord
            }
          }
        }
      `,
    );
  const [clearAuthData] =
    useAsyncMutation<GCCompanySettings_clearAuthData_Mutation>(
      graphql`
        mutation GCCompanySettings_clearAuthData_Mutation(
          $general_contractor_id: uuid!
        ) {
          update_procore_auth_data_by_pk(
            pk_columns: { general_contractor_id: $general_contractor_id }
            _set: {
              user_id: null
              user_name: null
              user_login: null
              refresh_token: null
              access_token: null
              token_expires_at: null
              service_account_client_id: null
              service_account_client_secret: null
            }
          ) {
            user_id
            user_name
            user_login
            refresh_token
            access_token
            token_expires_at
            service_account_client_id
            service_account_client_secret
          }
        }
      `,
    );
  const [updateGCData] =
    useAsyncMutation<GCCompanySettings_updateGC_Mutation>(graphql`
      mutation GCCompanySettings_updateGC_Mutation(
        $gcId: uuid!
        $_set: general_contractor_set_input!
      ) {
        update_general_contractor_by_pk(
          pk_columns: { id: $gcId }
          _set: $_set
        ) {
          id
          require_phone
          send_weekly_email
          require_dob
        }
      }
    `);
  const [updateProject] =
    useAsyncMutation<GCCompanySettings_updateProjects_Mutation>(graphql`
      mutation GCCompanySettings_updateProjects_Mutation(
        $gcId: uuid!
        $_set: project_set_input!
      ) {
        update_project(
          where: { general_contractor_id: { _eq: $gcId } }
          _set: $_set
        ) {
          returning {
            id
            require_phone
            require_dob
          }
        }
      }
    `);
  const [removeAuthData] =
    useMutation<GCCompanySettings_removeAuthData_Mutation>(
      graphql`
        mutation GCCompanySettings_removeAuthData_Mutation(
          $general_contractor_id: uuid!
        ) {
          delete_procore_auth_data(
            where: { general_contractor_id: { _eq: $general_contractor_id } }
          ) {
            affected_rows
          }
        }
      `,
    );
  const [updateWaiverText] =
    useAsyncMutation<GCCompanySettings_UpdateWaiverText_Mutation>(graphql`
      mutation GCCompanySettings_UpdateWaiverText_Mutation(
        $gcId: uuid!
        $waiverText: String!
      ) {
        update_general_contractor(
          where: { id: { _eq: $gcId } }
          _set: { visitor_waiver_text: $waiverText }
        ) {
          affected_rows
        }
      }
    `);
  const removeLoginData = () => {
    removeAuthData({
      variables: {
        general_contractor_id,
      },
      onCompleted: () => {
        message.success("Done");
      },
      onError: (e) => {
        notification.error({
          message: "Error in Removing Procore Integration ConfiguationS",
          description: e.message,
          duration: null,
        });
      },
    });
  };
  const removeProjectData = () => {
    return clearIntegrationSettings({
      variables: {
        general_contractor_id,
      },
      // onCompleted: () => {
      //   message.success("Done Remove " + l.name + " data");
      // },
      // onError: (e) => {
      //   notification.error({
      //     message: "Error in Removing Project Data",
      //     description: e.message,
      //     duration: null,
      //   });
      // },
    });
  };

  const setIntegrationEnabeld = (enabled: boolean) => {
    return upsertProcoreIntegrationEnabled({
      variables: {
        GCId: general_contractor_id,
        switch: enabled,
      },
      optimisticResponse: {
        insert_procore_auth_data_one: {
          id: GetFullID("procore_auth_data", general_contractor_id),
          general_contractor_id,
          integration_enabled: enabled,
        },
      },
      optimisticUpdater: procoreSwitchUpdater,
      updater: procoreSwitchUpdater,
    });
  };

  useEffect(() => {
    const search = document.location.search;
    //console.log("search", search);
    const params = new URLSearchParams(search);
    if (params.has("error")) {
      notification.error({
        message: "Error: " + params.get("error"),
        description: params.get("error_description"),
      });
    }
    if (params.has("code")) {
      const sandbox = params.get("state") === "sbx";
      const code = params.get("code");
      const lastCode = sessionStorage.getItem("lastCode");
      if (code && code !== lastCode) {
        sessionStorage.setItem("lastCode", code);
        SetProcoreUserAccount({
          variables: {
            GCId: general_contractor_id,
            code,
            sandbox,
            redirect_url: procoreCompanyRedirectUrl,
          },
        });
      }
    }
  }, []);

  const [form] = Form.useForm();
  // Logo Modal
  const [openHierarchy, setOpenHierarchy] = useState(false);
  const addLogoModal = useRef<AddLogoModalRef>(null);
  const logoUrl = employee?.general_contractor.logo_url;
  const showLogo = logoUrl !== null && logoUrl !== undefined;
  const title = showLogo ? "Update Logo" : "Add Logo";
  return employee ? (
    <BasicWrapper scrollable>
      <GCCompleteCorporateEmailSelectModal
        visible={corporateCompletionModalOpen}
        gcId={employee?.general_contractor_id}
        onCancel={() => setCorporateCompletionModalOpen(false)}
        selectedProjects={data.project_connection.edges
          .filter((p) => p.node.send_corporate_video_completion_report)
          .map((p) => p.node.pk)}
        selectedUsers={data.email_general_contractor_user_connection.edges.map(
          (gcu) => gcu.node.user_id,
        )}
        projects={
          employee
            ? data.project_connection.edges.map((v) => ({
                name: v.node.name,
                id: v.node.pk,
              }))
            : []
        }
        gcUsers={
          employee
            ? employee.general_contractor.employees.map((v) => ({
                name: v.user.name,
                id: v.uid,
              }))
            : []
        }
      />
      {employee.is_corporate_admin && (
        <div className="mb-2">
          <GcHierarchyModal
            open={openHierarchy}
            gcId={employee.general_contractor_id}
            hierarchy_business_unit_name={
              employee.general_contractor.hierarchy_business_unit_name
            }
            hierarchy_division_name={
              employee.general_contractor.hierarchy_division_name
            }
            hierarchy_office_name={
              employee.general_contractor.hierarchy_office_name
            }
            onCancel={() => setOpenHierarchy(false)}
          />
          <Button
            icon={IconTable}
            label={"Add Hierarchy Levels"}
            onClick={() => setOpenHierarchy(true)}
          ></Button>
        </div>
      )}
      <div className="flex flex-col items-start gap-2">
        <div className="flex flex-col items-start gap-1 rounded-1">
          {showLogo && (
            <Image width={200} height={200} src={logoUrl as string} />
          )}
          <AddLogoModal
            ref={addLogoModal}
            isLogo={showLogo}
            id={general_contractor_id}
            onSubmit={() => {
              addLogoModal.current?.close();
            }}
          />
          <Button
            icon={IconPhoto}
            label={title}
            onClick={() => addLogoModal.current?.open()}
          ></Button>
        </div>
        <Button
          label={"Update Corporate Video Email Settings"}
          onClick={() => setCorporateCompletionModalOpen(true)}
        ></Button>
        <Radio.Group
          style={{ width: "100%" }}
          value={hotWorkPermitName}
          onChange={async (e) => {
            const newPermitName = e.target.value;
            setHotWorkPermitName(newPermitName);
            await updateGeneralContractor({
              variables: {
                id: general_contractor_id,
                _set: {
                  hot_work_permit_name: newPermitName,
                },
              },
            })
              .then(async (d) => {
                message.success("Permit Name Updated");
              })
              .catch((error) => {
                message.error("Failed to update permit name");
              });
          }}
        >
          <h2 className="text-1.5 mb-1">Select Name of Permit</h2>
          <Radio value="hot work">Hot Work</Radio>
          <Radio value="burn">Burn</Radio>
        </Radio.Group>
        <div>
          <div>
            <AddProcoreIntegrationModal
              ref={addProcoreIntegrationModal}
              has_auth_data={has_auth_data}
              GCId={employee?.general_contractor_id as string}
              onSubmit={() => {
                removeProjectData();
                setIntegrationEnabeld(true);
                //                  refresh();
              }}
            />
            <SwitchWithText
              //disabled={!has_auth_data}
              text="Turn ON weekly email for all Projects by default"
              onChange={(checked) => {
                updateGCData({
                  variables: {
                    gcId: general_contractor_id,
                    _set: { send_weekly_email: checked },
                  },
                  optimisticResponse: {
                    update_general_contractor_by_pk: {
                      id: GetFullID(
                        "general_contractor",
                        general_contractor_id,
                      ),
                      send_weekly_email: checked,
                      require_phone: employee.general_contractor.require_phone,
                      require_dob: employee.general_contractor.require_dob,
                    },
                  },
                })
                  .then(() => message.success(checked ? "ON" : "OFF"))
                  .catch((e) => {
                    notification.error({
                      message: "Error: Please try again",
                      description: e?.message,
                    });
                  });
              }}
              checked={!!employee.general_contractor.send_weekly_email}
            />
            <SwitchWithText
              //disabled={!has_auth_data}
              text="Require ALL workers on ALL projects to provide their phone number during registration"
              hoverText={
                "This will remove the requirement for workers to provide their Phone # on ALL projects. Are you sure?"
              }
              onChange={(checked) => {
                updateProject({
                  variables: {
                    gcId: general_contractor_id,
                    _set: { require_phone: checked },
                  },
                });
                updateGCData({
                  variables: {
                    gcId: general_contractor_id,
                    _set: { require_phone: checked },
                  },
                  optimisticResponse: {
                    update_general_contractor_by_pk: {
                      id: GetFullID(
                        "general_contractor",
                        general_contractor_id,
                      ),
                      require_phone: checked,
                      require_dob: employee.general_contractor.require_dob,
                    },
                  },
                })
                  .then(() => message.success(checked ? "ON" : "OFF"))
                  .catch((e) => {
                    notification.error({
                      message: "Error: Please try again",
                      description: e?.message,
                    });
                  });
              }}
              checked={employee.general_contractor.require_phone}
            />

            <SwitchWithText
              //disabled={!has_auth_data}
              hoverText={
                "This will remove the requirement for workers to provide their DOB on ALL projects. Are you sure?"
              }
              text="Require ALL workers on ALL projects to provide their date of birth during registration"
              onChange={(checked) => {
                updateProject({
                  variables: {
                    gcId: general_contractor_id,
                    _set: { require_dob: checked },
                  },
                });
                updateGCData({
                  variables: {
                    gcId: general_contractor_id,
                    _set: { require_dob: checked },
                  },
                  optimisticResponse: {
                    update_general_contractor_by_pk: {
                      id: GetFullID(
                        "general_contractor",
                        general_contractor_id,
                      ),
                      require_dob: checked,
                      require_phone: employee.general_contractor.require_phone,
                    },
                  },
                })
                  .then(() => message.success(checked ? "ON" : "OFF"))
                  .catch((e) => {
                    notification.error({
                      message: "Error: Please try again",
                      description: e?.message,
                    });
                  });
              }}
              checked={employee.general_contractor.require_dob}
            />
            <div className="mt-2 mb-3">
              <Form
                form={form}
                layout="vertical"
                initialValues={{ modifier: "public" }}
              >
                <Form.Item
                  name="waiverText"
                  label={
                    <div>
                      <strong>Visitor Waiver</strong>
                    </div>
                  }
                  initialValue={employee.general_contractor.visitor_waiver_text}
                >
                  <JoditEditor
                    value=""
                    config={{
                      ...JoditConfig,
                      readonly: false,
                    }}
                  />
                </Form.Item>
              </Form>
              <Button
                onClick={() => {
                  form.validateFields().then(async (values) => {
                    await updateWaiverText({
                      variables: {
                        gcId: employee.general_contractor_id,
                        waiverText: values.waiverText,
                      },
                    });
                    notification.success({
                      message: "Done",
                      duration: 2,
                    });
                  });
                }}
              >
                Save
              </Button>
            </div>
            <h2 className="text-1.5">
              <SwitchWithText
                text="Procore Integration"
                onChange={(checked) => {
                  setIntegrationEnabeld(checked).then(
                    (value) => {
                      checked ? message.success("On") : message.error("Off");
                    },
                    (e) => {
                      notification.error({
                        message: "Error: Please try again",
                        description: e?.message,
                      });
                    },
                  );
                }}
                checked={integrationEnabled}
              />
            </h2>

            {
              /*has_auth_data &&*/ <>
                <Divider />
                {conection_state}
                <Divider />
              </>
            }
            <div>
              <div className="flex flex-row items-start gap-1">
                <ConnectAsUserButton title={procoreUserButtonTitle} />
                <Button
                  secondary
                  icon={IconUser}
                  label={serviceAccountButtonTitle}
                  onClick={() => addProcoreIntegrationModal.current?.open()}
                ></Button>
                {has_auth_data && (
                  <>
                    <Popconfirm
                      title="Are you sure to clear procore integration configuation?"
                      onConfirm={async () => {
                        await Promise.all([
                          clearAuthData({
                            variables: {
                              general_contractor_id,
                            },
                          }),
                          removeProjectData(),
                        ]);
                        refresh();
                      }}
                      onCancel={() => {}}
                      okText="Confirm"
                      cancelText="No"
                    >
                      <Button
                        icon={IconX}
                        secondary
                        onClick={() => {}}
                        label={`Remove login data`}
                      ></Button>
                    </Popconfirm>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasicWrapper>
  ) : null;
};

export default withCustomSuspense(withCorporateAdminWrapper(GCCompanySettings));
