import { Form, Modal, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useParams } from "react-router-dom";
import { SelectProjectSubcontractorModalQuery } from "src/common/types/generated/relay/SelectProjectSubcontractorModalQuery.graphql";

const query = graphql`
  query SelectProjectSubcontractorModalQuery($projectId: uuid!) {
    project_subcontractor_connection(
      where: { project_id: { _eq: $projectId } }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          subcontractor {
            id
            pk: id @__clientField(handle: "pk")
            name
          }
          add_daily_work_log_during_ptp
        }
      }
    }
  }
`;

export interface SelectProjectSubcontractorModalValues {
  userIds: Array<string>;
}

interface SelectProjectSubcontractorFormValues {
  projectSubIds: Array<string>;
}

interface SelectProjectSubcontractorDefaultData {
  userId: string;
  emailProjectUserId: string;
}

interface SelectProjectSubcontractorModalProps {
  defaultProjectSubs?: Array<string>;
  modalClose: () => void;
  modalVisible: boolean;
  onSubmit: (projectSubIds: Array<string>) => any;
}

const SitedeliveryTimeBlockModal: React.FC<
  SelectProjectSubcontractorModalProps
> = (props) => {
  const [form] = Form.useForm();
  const { projectId } = useParams();
  const data = useLazyLoadQuery<SelectProjectSubcontractorModalQuery>(query, {
    projectId: projectId!,
  });
  const [loading, setLoading] = useState(false);

  const projectSubs = data.project_subcontractor_connection.edges;

  const SelectProjectSubcontractorModal = async () => {
    form
      .validateFields()
      .then(async (values: SelectProjectSubcontractorFormValues) => {
        setLoading(true);
        await props.onSubmit(values.projectSubIds);
        setLoading(false);
        form.resetFields();
        props.modalClose();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <Modal
        title={`Add or Remove Subcontractors`}
        open={props.modalVisible}
        onCancel={props.modalClose}
        onOk={SelectProjectSubcontractorModal}
        confirmLoading={loading}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: "public" }}
        >
          <Form.Item
            name="projectSubIds"
            label="Select Trade Partners that will complete their Daily Report at the
          same time as their Pre-Task Plan"
            initialValue={props.defaultProjectSubs?.map((sub) => sub)}
            rules={[{ required: true, message: "Select subcontractors" }]}
          >
            <Select
              listItemHeight={10}
              mode="multiple"
              style={{
                width: "100%",
              }}
              options={projectSubs.map((sub) => ({
                value: sub.node.pk,
                key: sub.node.pk,
                label: sub.node.subcontractor.name,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SitedeliveryTimeBlockModal;
