import React from "react";
import TextInput from "./general/TextInput";
import SortingTypePicker, { SortingTypePickerProps } from "./SortingTypePicker";

export interface SearchBarProps {
  sorting?: SortingTypePickerProps;
  onSearchSubmit: (searchQuery: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = (props) => {
  return (
    <div className="flex flex-row items-center gap-1">
      <div className={`flex-1`}>
        <TextInput
          onSubmit={(inputValue) => {
            props.onSearchSubmit(inputValue);
            return false;
          }}
          placeholder={"Search by name"}
          allowClear
        />
      </div>
      {!!props.sorting && (
        <div>
          <SortingTypePicker {...props.sorting} />
        </div>
      )}
    </div>
  );
};

export default SearchBar;
