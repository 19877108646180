import React, { useState } from "react";
import { Button, Modal, Form, Input } from "antd";

interface CreateGeneralContractorValues {
  name: string;
}

interface CreateGeneralContractorModalProps {
  visible: boolean;
  onCreate: (values: CreateGeneralContractorValues) => void;
  onCancel: () => void;
}

const CreateGeneralContractorModal: React.FC<
  CreateGeneralContractorModalProps
> = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    (<Modal
      open={visible}
      title="Create a General Contractor"
      okText="Create"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values as CreateGeneralContractorValues);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="name"
          label="General Contractor Name"
          rules={[{ required: true, message: "Enter a name" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>)
  );
};

export default CreateGeneralContractorModal;
