import { SlideRecord } from "../siteorientationTypes";
export const fixEnglish = (slide: SlideRecord) => {
  return {
    ...slide,
    voice_url:
      slide.voice_url ??
      slide.voice_url_es ??
      slide.voice_name_pt ??
      slide.video_url_it ??
      slide.voice_url_fr ??
      slide.voice_url_zh_hans,
  };
};
export default fixEnglish;
