import { Select } from "antd";
import React, { useRef } from "react";

// export type RangeType = [dayjs.Dayjs | null, dayjs.Dayjs | null];

export interface SelectFilterProps {
  items: Array<{ label: string; id: string; selected?: boolean }>;
  onChange: (newVals: string[]) => void;
  placeholder?: string;
  setSelectedValues?: boolean;
}

const SelectFilter: React.FC<SelectFilterProps> = (props) => {
  const selectRef = useRef<any>(null);

  return (
    <>
      {props.setSelectedValues && (
        <Select
          onChange={props.onChange}
          placeholder={props.placeholder}
          mode="multiple"
          value={props.items
            .filter((item) => item.selected)
            .map((item) => item.id)}
          className="w-12"
        >
          {props.items.map((p) => (
            <Select.Option key={p.id} value={p.id}>
              {p.label}
            </Select.Option>
          ))}
        </Select>
      )}

      {!props.setSelectedValues && (
        <Select
          onChange={props.onChange}
          placeholder={props.placeholder}
          mode="multiple"
          className="w-12"
        >
          {props.items.map((p) => (
            <Select.Option key={p.id} value={p.id}>
              {p.label}
            </Select.Option>
          ))}
        </Select>
      )}
    </>
  );
};

export default SelectFilter;
