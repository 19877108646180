import { Table } from "antd";
import dayjs from "dayjs";
import React, { FC } from "react";
import { ViewAndEditJhaQuery$data } from "src/common/types/generated/relay/ViewAndEditJhaQuery.graphql";

type TaskSubAdminApprovalDataType =
  ViewAndEditJhaQuery$data["task_signature_connection"]["edges"];

interface Props {
  subApprovalData: TaskSubAdminApprovalDataType;
}

const TaskApprovalsTable: FC<Props> = ({ subApprovalData }) => {
  let dataSource: Array<{
    key: string;
    date: string;
    name: string;
    title: string;
  }> = [];

  subApprovalData.forEach((c) => {
    if (!(c.node.signature_image_id)) {
      dataSource.push({
        key: c.node.pk,
        date:
          dayjs(c.node.created_at).format("MMM D, YYYY h:mm A") +
          (!!(c.node.email_request_id)
            ? " - Reviewed By Email"
            : ""),
        name:
          (!!c.node.user
            ? c.node.user.name
            : c.node.email_request?.email) ?? "",
        title:
          c.node.user?.subcontractor_employee?.employee_title?.name.en ?? "",
      });
    }
  });
  const columns = [
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
    },
  ];

  let x = 1;

  return (
    <>
      <Table columns={columns} dataSource={dataSource} bordered />
    </>
  );
};

export default TaskApprovalsTable;
