import { inspection_instance_insert_input } from "src/common/types/generated/relay/CreateInspectionQRModalMutation.graphql";
import { InspectionInstanceType } from "../components/tables/InspectionInstanceTable";
import { auth } from "src/common/functions/firebase";
import useInsertInspectionInstanceAndTemplate from "./useInsertInspectionInstanceAndTemplate";
import { ConnectionHandler } from "relay-runtime";
import * as uuid from "uuid";

const useDuplicateInspectionInstance = (projectId: string) => {
  const [add, isAdding] = useInsertInspectionInstanceAndTemplate();
  const duplicate = async (data: InspectionInstanceType) => {
    const typeMap: { [type: string]: string } = {};
    const object: inspection_instance_insert_input = {
      name: {
        data: {
          original: data.name.en + " _copy",
          en: data.name.en + " _copy",
        },
      },
      created_by_uid: auth.currentUser?.uid,
      image_required: data.image_required,
      model_number:
        data.model_number && data.model_number.trim()
          ? data.model_number.trim()
          : null,
      is_multi_inspection: data.is_multi_inspection,
      model_number_required: data.model_number_required,
      project_id: projectId,
      inspection_template_id: data.inspection_template_id,
      inspection_labels: {
        data: data.inspection_labels.map((lb) => ({ label_id: lb.label_id })),
      },
      inspection_instance_qr_codes: {
        data: data.inspection_instance_qr_codes.map((iqr) => ({
          inspection_qr_code_id: iqr.inspection_qr_code_id,
        })),
      },
      inspection_instance_checklist_items: {
        data: data.inspection_instance_checklist_items.map((item) => {
          return {
            correct_answer: item.correct_answer,
            description: {
              data: { original: item.description.en, en: item.description.en },
            },
            item_id: uuid.v4(),
            inspection_checklist_emails: {
              data: item.inspection_checklist_emails.map((itemEmails) => ({
                user_id: itemEmails.user_id,
              })),
            },
            notify_on_deficient: item.notify_on_deficient,
            sort_index: item.sort_index,
            show_na: item.show_na,
            inspection_template_checklist_item_id:
              item.inspection_template_checklist_item_id,
            ...(item.inspection_checklist_item_type
              ? typeMap[item.inspection_checklist_item_type.text.en]
                ? {
                    inspection_checklist_item_type_id:
                      typeMap[item.inspection_checklist_item_type.text.en],
                  }
                : {
                    inspection_checklist_item_type: {
                      data: {
                        id: typeMap[
                          item.inspection_checklist_item_type.text.en
                        ],
                        text: {
                          data: {
                            original:
                              item.inspection_checklist_item_type.text.en,
                            en: item.inspection_checklist_item_type.text.en,
                          },
                        },
                      },
                    },
                  }
              : {}),
          };
        }),
      },
    };
    const data2 = await add({
      variables: { instanceObjects: [object], templateObjects: [] },
      updater: (store) => {
        const conn = ConnectionHandler.getConnection(
          store.getRoot(),
          "InspectionInstanceTable_inspection_instance_connection",
        );
        const optionsConn = ConnectionHandler.getConnection(
          store.getRoot(),
          "GCInspectionsQuery_inspection_instance_connection",
        );
        const returning = store
          .getRootField("insert_inspection_instance")
          .getLinkedRecords("returning");
        returning.map((insp) => {
          if (conn) {
            const edge = ConnectionHandler.createEdge(
              store,
              conn,
              insp,
              "edge",
            );
            ConnectionHandler.insertEdgeBefore(conn, edge);
          }
          if (optionsConn) {
            const edge = ConnectionHandler.createEdge(
              store,
              optionsConn,
              insp,
              "edge",
            );
            ConnectionHandler.insertEdgeBefore(optionsConn, edge);
          }
        });
      },
    });
    return data2;
  };
  return [duplicate, isAdding] as const;
};
export default useDuplicateInspectionInstance;
