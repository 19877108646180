import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

interface LiveTimelineProps {
  keepTimelineInView?: boolean; // this will scroll to the timeline every minute
}

const LiveTimeline: React.FC<LiveTimelineProps> = (props) => {
  const [topPercentage, setTopPercentage] = useState<number>(0);
  const timelineElRef = React.createRef<HTMLDivElement>();

  const resetTimeline = () => {
    const now = dayjs();
    const hour = now.hour();
    const minutes = now.minute();
    const hoursAfterStart = hour < 5 ? hour : hour < 17 ? hour - 6 : hour - 17;
    const hourSpanLen = hour < 5 ? 5 : hour < 17 ? 11 : 7;
    props.keepTimelineInView
      ? setTopPercentage((100 * (hour * 60 + minutes)) / (60 * 24))
      : setTopPercentage(
          (100 * (hoursAfterStart * 60 + minutes)) / (60 * hourSpanLen),
        );
  };

  useEffect(() => {
    resetTimeline();
  }, []);

  useEffect(() => {
    if (props.keepTimelineInView) {
      timelineElRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [topPercentage]);

  return (
    <div className="absolute inset-0 z-10 w-full h-full pointer-events-none">
      <div
        ref={timelineElRef}
        className="absolute flex flex-row items-center justify-between w-full h-px bg-interactive-primary"
        style={{ top: `${topPercentage}%` }}
      ></div>
    </div>
  );
};

export default LiveTimeline;
