export const MS_PER_SECOND = 1000;
export const MS_PER_MINUTE = 60 * MS_PER_SECOND;
export const MS_PER_HOUR = 60 * MS_PER_MINUTE;
export const MS_PER_DAY = 24 * MS_PER_HOUR;
export const DAYS_PER_WEEK = 7;

export const parseTime = (time: string): number => {
  const comp = time.split(":");
  if (comp.length < 2) return NaN;

  let result =
    parseInt(comp[0]) * MS_PER_HOUR + parseInt(comp[1]) * MS_PER_MINUTE;
  if (comp.length > 2) result += parseInt(comp[2]) * MS_PER_SECOND;
  return result;
};

const twoDiggits = (value: number): string => ("00" + (value | 0)).slice(-2);

export const formatTime = (time: number): string =>
  `${twoDiggits(Math.floor(time / MS_PER_HOUR) % 24)}:${twoDiggits(
    Math.floor(time / MS_PER_MINUTE) % 60,
  )}`;

type TimeEvent = {
  time: number;
};

type EndEvent = {
  type: "end";
  time: number;
};

export const compareTimeEvents = (a: TimeEvent, b: TimeEvent) =>
  a.time - b.time;

export const eventTimeSeq = <T extends TimeEvent>(
  events: T[][],
  day: number,
  filter?: (value: T) => boolean,
): Iterable<T> => ({
  *[Symbol.iterator]() {
    let shift = 0;
    let hasEvents = false;
    while (true) {
      for (const event of events[day]) {
        if (filter && !filter(event)) continue;
        yield { ...event, time: event.time + shift };
        hasEvents = true;
      }
      shift += MS_PER_DAY;
      day = (day + 1) % events.length;
      if (!hasEvents && shift > MS_PER_DAY * events.length) {
        return;
      }
    }
  },
});

export const mergeTimeSeq = <T1 extends TimeEvent, T2 extends TimeEvent>(
  a: Iterable<T1>,
  b: Iterable<T2>,
): Iterable<T1 | T2 | EndEvent> => ({
  *[Symbol.iterator]() {
    const iterA = a[Symbol.iterator]();
    const iterB = b[Symbol.iterator]();
    let resA = iterA.next();
    let resB = iterB.next();
    while (!resA.done && !resB.done) {
      const cmp = compareTimeEvents(resA.value, resB.value);
      if (cmp <= 0) {
        yield resA.value;
        resA = iterA.next();
      } else {
        yield resB.value;
        resB = iterB.next();
      }
    }
    while (!resA.done) {
      yield resA.value;
      resA = iterA.next();
    }
    while (!resB.done) {
      yield resB.value;
      resB = iterB.next();
    }
    yield { type: "end", time: Infinity };
  },
});

const period = 1000;
export const filterTimeSeq = <T extends TimeEvent>(
  events: Iterable<T>,
  fn: (value: T) => boolean,
): Iterable<T> => ({
  *[Symbol.iterator]() {
    for (const event of events) {
      if (fn(event)) {
        yield event;
      }
    }
  },
});
