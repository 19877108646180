import React from "react";
import SiteOrientationGcQrCode from "./SiteOrientationGcQrCode";
import useInsertSecretCode from "../../utils/useInsertSecretCode";
import * as uuid from "uuid";
import buildSecretCodeUpdater from "../../utils/buildSecretCodeUpdater";
import { message } from "antd";
import useAuthUser from "src/common/hooks/useAuthUser";
export interface SiteOrientationGcQrCodeInPersonProps {
  destinationUrl: string | undefined;
  projectId: string;
}

const SiteOrientationQuizQRCode: React.FC<
  SiteOrientationGcQrCodeInPersonProps
> = ({ destinationUrl, projectId }) => {
  const insertSecretCode = useInsertSecretCode();
  const authUser = useAuthUser();
  const generateQrCodeUrl = async () => {
    const finalUrl = `/orientation/quiz`;
    const id = uuid.v4();

    await insertSecretCode({
      variables: {
        projectId,
        userId: authUser.uid,
        id,
        only_project_data: true,
        signInRole: "quiz_qr_user",
        finalUrl,
      },
      updater: buildSecretCodeUpdater("GCProjectOrientation_quiz_code"),
    }).catch((err) => {
      message.error("Error: " + err);
    });
  };
  return (
    <SiteOrientationGcQrCode
      title={
        <div className="font-accent">
          Quiz QR
          <br />
          Show this QR code at the end of your in-person orientation
        </div>
      }
      body={
        <ul className="list-disc">
          <li>
            Use this QR code if you have a Quiz that is required by everyone at
            the end of orientation
          </li>
          <li>
            The names of workers that RECENTLY attended orientation (last few
            hours) will show when this QR code is scanned
          </li>
          <li>
            Workers scan code on their device, select their name, and take the
            quiz
          </li>
          <li>
            All Workers that attend in-person orientation will receive credit
          </li>
        </ul>
      }
      qrCode={{
        generateQrCodeUrl,
        destinationUrl,
        alwaysVisible: true,
        showQR: true,
        downloadableFileName: "Quiz_QR",
      }}
    />
  );
};

export default SiteOrientationQuizQRCode;
