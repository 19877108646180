import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "react-relay/hooks";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { invitedUserEmailData_GetInvitedUserEmailDataQuery } from "src/common/types/generated/relay/invitedUserEmailData_GetInvitedUserEmailDataQuery.graphql";
import { invitedUserEmailData_GetInvitedUserEmailDataListQuery } from "src/common/types/generated/relay/invitedUserEmailData_GetInvitedUserEmailDataListQuery.graphql";

export const getInvitedUserData = async (
  email: string,
  environment: RelayModernEnvironment
) => {
  const response =
    fetchQuery<invitedUserEmailData_GetInvitedUserEmailDataQuery>(
      environment,
      graphql`
        query invitedUserEmailData_GetInvitedUserEmailDataQuery(
          $email: String!
        ) {
          user_connection(where: { email: { _eq: $email } }) {
            edges {
              node {
                pk: id @__clientField(handle: "pk")
                email
                name
                role
                created_password
                subcontractor_employee {
                  subcontractor_id
                }
                worker {
                  subcontractor {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
      { email }
    );

  const data = await response.toPromise();
  if (!data) throw new Error("data to compare email not found");
  return data;
};

export const getInvitedUserDataList = async (
  emails: string[],
  environment: RelayModernEnvironment
) => {
  const response =
    fetchQuery<invitedUserEmailData_GetInvitedUserEmailDataListQuery>(
      environment,
      graphql`
        query invitedUserEmailData_GetInvitedUserEmailDataListQuery(
          $emails: [String!]!
        ) {
          user_connection(where: { email: { _in: $emails } }) {
            edges {
              node {
                pk: id @__clientField(handle: "pk")
                email
                name
                role
                created_password
                subcontractor_employee {
                  subcontractor_id
                }
                worker {
                  subcontractor {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
      { emails }
    );

  const data = await response.toPromise();
  if (!data) throw new Error("data to compare email not found");
  return data;
};
