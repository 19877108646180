import React, { useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import ReportsTabsGroup, {
  ReportsTabType,
} from "src/common/components/ReportsTabsGroup";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import SdsTable from "src/domain-features/sitesafety/safety-data-sheets/components/SdsTable";
import { useLazyLoadQuery } from "react-relay/hooks";
import { graphql } from "babel-plugin-relay/macro";
import PdfViewer from "src/common/components/pdf/PdfViewer";
import { GCSdsQuery } from "src/common/types/generated/relay/GCSdsQuery.graphql";
import UploadSdsModal from "src/domain-features/sitesafety/safety-data-sheets/components/UploadSdsModal";
import UploadSDSToSiteFormModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/UploadSdsToSiteformModal";
import AddSdsFromCompanyLibraryModal from "src/domain-features/sitesafety/safety-data-sheets/components/AddSdsFromCompanyLibraryModal";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";

const GCSds: React.FunctionComponent = () => {
  const projectId = useParams().projectId as string;
  const subview = useParams().subview as string;
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfModal, setPdfModal] = useState(false);
  const projectSdsTableRef = useRef<DataScrollTableRef>(null);
  const [addSdsFromCompanyLibraryModal, setAddSdsFromCompanyLibraryModal] =
    useState(false);
  const [openSdsUploadModal, setOpenSdsUploadModal] = useState(false);
  const [openSdsUploadToSiteformModal, setOpenSdsUploadToSiteformModal] =
    useState(false);

  const navigate = useNavigate();
  const handleReportsTabClick = (tabId: string) => {
    navigate("../sds/" + tabId);
  };
  const tabs: ReportsTabType[] = [
    { id: "project", label: "Project Library" },
    { id: "all", label: "SDS Database" },
  ];

  const [selectedSubcontractor, setSelectedSubcontractor] = useState<
    Array<string>
  >([]);

  const [hintVisible, setHintVisible] = useState(true);

  const projectSubcontractorData = useLazyLoadQuery<GCSdsQuery>(
    graphql`
      query GCSdsQuery($projectId: uuid!) {
        project_subcontractor_connection(
          where: { project_id: { _eq: $projectId } }
          order_by: { subcontractor: { name: asc } }
        ) {
          edges {
            node {
              subcontractor_id
              subcontractor {
                name
                safety_data_sheets_aggregate(
                  where: { project_id: { _eq: $projectId } }
                ) {
                  aggregate {
                    count
                  }
                }
              }
            }
          }
        }

        safety_data_sheet_connection(
          where: {
            deleted_at: { _is_null: true }
            company_safety_data_sheet_id: { _is_null: true }
          }
          order_by: { product_name: asc }
        ) {
          edges {
            node {
              ...SdsFrag @relay(mask: false)
            }
          }
        }
      }
    `,
    { projectId: projectId },
  );

  const subcontractors =
    projectSubcontractorData.project_subcontractor_connection.edges
      .filter(
        (edge) =>
          edge.node.subcontractor.safety_data_sheets_aggregate.aggregate
            ?.count &&
          edge.node.subcontractor.safety_data_sheets_aggregate.aggregate
            ?.count > 0,
      )
      .map((sub) => ({
        id: sub.node.subcontractor_id,
        name: sub.node.subcontractor.name,
      }));

  const sdsData = projectSubcontractorData.safety_data_sheet_connection.edges;

  return (
    <BasicWrapper>
      <UploadSdsModal
        visible={openSdsUploadModal}
        subcontractorId={""}
        projectId={projectId}
        onClose={() => {
          setOpenSdsUploadModal(false);
          setHintVisible(true);
        }}
        onSubmit={() => {
          setOpenSdsUploadModal(false);
          projectSdsTableRef.current?.refetch();
          setHintVisible(true);
        }}
        projectSubcontractorData={projectSubcontractorData.project_subcontractor_connection.edges.map(
          (edge) => ({
            id: edge.node.subcontractor_id,
            name: edge.node.subcontractor.name,
          }),
        )}
      />

      <UploadSDSToSiteFormModal
        visible={openSdsUploadToSiteformModal}
        subcontractorId={""}
        projectId={projectId}
        onClose={() => {
          setOpenSdsUploadToSiteformModal(false);
          setHintVisible(true);
        }}
        onSubmit={() => {
          setOpenSdsUploadToSiteformModal(false);
          setHintVisible(true);
        }}
        projectSubcontractorData={projectSubcontractorData.project_subcontractor_connection.edges.map(
          (edge) => ({
            id: edge.node.subcontractor_id,
            name: edge.node.subcontractor.name,
          }),
        )}
      />

      <AddSdsFromCompanyLibraryModal
        projectId={projectId}
        visible={addSdsFromCompanyLibraryModal}
        closeModal={() => {
          setAddSdsFromCompanyLibraryModal(false);
          setHintVisible(true);
        }}
        refresh={() => {
          projectSdsTableRef.current?.refetch();
        }}
        projectSubcontractorData={projectSubcontractorData.project_subcontractor_connection.edges.map(
          (edge) => ({
            id: edge.node.subcontractor_id,
            name: edge.node.subcontractor.name,
          }),
        )}
        title={"Add SDS from Database"}
        tableWhere={{ deleted_at: { _is_null: true } }}
      />

      <ReportsTabsGroup
        onTabClick={handleReportsTabClick}
        tabs={tabs}
        tabsType="button"
        selectedTabId={subview}
      />
      <PdfViewer
        visible={pdfModal}
        onClose={() => {
          setPdfModal(false);
        }}
        pdfUrl={pdfUrl}
      />
      <div className={`flex-grow bg-white overflow-auto py-1 w-full`}>
        <div className="flex w-full h-full">
          {subview === "project" && (
            <SdsTable
              loadAll
              ref={projectSdsTableRef}
              where={{
                project_id: { _eq: projectId },
                deleted_at: { _is_null: true },
                subcontractor_id: selectedSubcontractor.length
                  ? { _in: selectedSubcontractor }
                  : {},
              }}
              loading={false}
              excludedKeys={["projectName", "addToProjects"]}
              title="SDS Project Library"
              topBarButtons={[
                {
                  label: "+ SDS",
                  secondary: true,
                  onClick: () => setHintVisible(true),
                  hint: hintVisible ? (
                    <div className="w-24">
                      <div
                        className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                        onClick={() => {
                          setOpenSdsUploadToSiteformModal(true);
                          setHintVisible(false);
                        }}
                      >
                        Easy Upload (not instant)
                      </div>
                      <div
                        className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                        onClick={() => {
                          setOpenSdsUploadModal(true);
                          setHintVisible(false);
                        }}
                      >
                        Manual Upload (instant)
                      </div>
                      <div
                        className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                        onClick={() => {
                          setAddSdsFromCompanyLibraryModal(true);
                          setHintVisible(false);
                        }}
                      >
                        Add from Database
                      </div>
                    </div>
                  ) : undefined,
                },
              ]}
              customFilters={[
                {
                  type: "checkbox",
                  title: "Subcontractors",
                  removeFilter: () => setSelectedSubcontractor([]),
                  options: subcontractors.map((sub) => ({
                    option: sub.name,
                    optionType: "checkbox",
                    onApply: () =>
                      setSelectedSubcontractor((prev) => [...prev, sub.id]),
                    onCancel: () =>
                      setSelectedSubcontractor((prev) =>
                        prev.filter((id) => id !== sub.id),
                      ),
                  })),
                },
              ]}
              onSdsRowClick={(sds) => {
                setPdfUrl(sds.url);
                setPdfModal(true);
              }}
            />
          )}
          {subview === "all" && (
            <SdsTable
              loadAll
              projectSubcontractors={projectSubcontractorData.project_subcontractor_connection.edges.map(
                (sub) => ({
                  id: sub.node.subcontractor_id,
                  name: sub.node.subcontractor.name,
                }),
              )}
              projectId={projectId}
              where={{
                deleted_at: { _is_null: true },
                company_safety_data_sheet_id: { _is_null: true },
                subcontractor_id: selectedSubcontractor.length
                  ? { _in: selectedSubcontractor }
                  : { _is_null: false },
              }}
              excludedKeys={[
                "projectName",
                "addedBy",
                "addedDate",
                "select",
                "subcontractorName",
              ]}
              loading={false}
              title="SDS Database"
              onSdsRowClick={(sds) => {
                setPdfUrl(sds.url);
                setPdfModal(true);
              }}
            />
          )}
        </div>
        <Outlet />
      </div>
    </BasicWrapper>
  );
};
export default GCSds;
