import * as React from "react";
import { Menu, message } from "antd";
import { useEmailUserAfterJhaCreationCompletionMutation } from "src/common/types/generated/apollo/graphQLTypes";

interface TaskResendForAcceptanceProps {
  requestId: string;
}

const TaskResendForAcceptance = (props: TaskResendForAcceptanceProps) => {
  const [emailToAcceptJHA] = useEmailUserAfterJhaCreationCompletionMutation();
  console.log("requestId", props.requestId);

  return (
    <div
      className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
      key="2"
      color="red"
      onClick={async (e) => {
        e.stopPropagation();

        await emailToAcceptJHA({
          variables: {
            input: {
              requestId: props.requestId,
            },
          },
        })
          .then(() => {
            message.success({
              content: "Email Sent",
            });
          })
          .catch((error) => {
            message.error(
              `ERROR: ${
                error instanceof Error ? error.message : JSON.stringify(error)
              }`
            );
          });
      }}
    >
      Resend For Acceptance
    </div>
  );
};

export default TaskResendForAcceptance;
