import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import IncidentSeverityMapping from "../../component/IncidentSeverityMapping";
import withDivisionId, { DivisionProps } from "../../utils/withDivisionId";

const GCDivisionIncident: React.FC<DivisionProps> = ({ divisionId }) => {
  const { userData } = useUserData();
  return (
    <IncidentSeverityMapping
      severityUserFilter={{ division_id: { _eq: divisionId } }}
      toInsertWith={{
        gc_id: userData.employee?.general_contractor_id,
        division_id: divisionId,
      }}
      gcEmployeeWhere={{
        gc_divisions: { gc_division_id: { _eq: divisionId } },
      }}
    />
  );
};
export default withDivisionId(GCDivisionIncident);
