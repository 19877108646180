import Hotjar from "@hotjar/browser";
import keys from "src/common/constants/keys";
import { isDevelopmentEnvironment } from "../../../common/constants/environment";

const hotjarInit = () => {
  if (!isDevelopmentEnvironment) {
    if (keys.hotjar) {
      Hotjar.init(keys.hotjar.siteId, keys.hotjar.hotjarVersion);
    } else {
      console.error("Hotjar keys not found");
    }
  }
};

export default hotjarInit;
