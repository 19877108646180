import { Input } from "antd";
import React, { useState } from "react";
import TextArea from "antd/es/input/TextArea";

interface IncidentInputFieldProps {
  text?: string | number;
  label: string;
  disabled?: boolean;
  onSave: (val: string | number) => void;
}

const IncidentInputField: React.FC<IncidentInputFieldProps> = ({
  text,
  label,
  disabled,
  onSave,
}) => {
  const [newText, setNewText] = useState(text);
  return (
    <div className="flex gap-1 mt-1 items-center">
      <div className="w-10">{label}</div>
      <TextArea
        className="w-full"
        value={newText}
        autoSize={{ minRows: 1, maxRows: 10 }}
        onChange={(val) => setNewText(val.target.value)}
        onBlur={() => {
          if (typeof newText !== "undefined") onSave(newText);
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default IncidentInputField;
