const checkStrongPassword = (s: string) => {
  // const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(s);
  const hasNumber = /\d/.test(s);
  const hasLower = /[a-z]/.test(s);
  const hasUpper = /[A-Z]/.test(s);

  return (
    s.length >= 8 &&
    // hasSpecial &&
    hasNumber &&
    hasLower &&
    hasUpper
  );
};
export default checkStrongPassword;
