export const getVideoFrameUrl = (
  url: string,
  autoPlay?: Boolean,
  disableControls?: Boolean
): string => {
  const parser: HTMLAnchorElement = document.createElement("a");

  parser.href = url;
  const protocol = parser.protocol || "";

  switch (parser.hostname) {
    case "www.vimeo.com":
    case "vimeo.com": {
      const pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
      return pattern.test(url)
        ? url.replace(pattern, protocol + "//player.vimeo.com/video/$1")
        : url;
    }
    case "youtube.com":
    case "www.youtube.com":
    case "youtu.be":
    case "www.youtu.be": {
      const params = new URLSearchParams(parser.search);
      const videoId: string = params.get("v") ?? parser.pathname.slice(1);
      params.delete("v");
      if (autoPlay) {
        params.set("autoplay", "1");
      }
      if (disableControls) {
        params.set("controls", "0");
      }
      params.set("rel", "0");
      params.set("enablejsapi", "1");
      params.append("modestbranding", "");
      return (
        protocol +
        "//www.youtube.com/embed/" +
        videoId +
        "?" +
        params.toString()
      );
    }
    default:
      return url;
  }
};

export default getVideoFrameUrl;
