import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useUpdateHHMutation } from "src/common/types/generated/relay/useUpdateHHMutation.graphql";

const useUpdateHH = () => {
  return useAsyncMutation<useUpdateHHMutation>(graphql`
    mutation useUpdateHHMutation(
      $projectId: uuid!
      $userId: uuid!
      $hardHat: String!
    ) {
      update_project_by_pk(
        pk_columns: { id: $projectId }
        _inc: { next_hard_hat_number: 1 }
      ) {
        id
        next_hard_hat_number
      }
      update_project_worker(
        where: {
          project: { orientation_project_id: { _eq: $projectId } }
          worker_id: { _eq: $userId }
        }
        _set: { hard_hat_number: $hardHat }
      ) {
        returning {
          id @__clientField(handle: "pk")
          hard_hat_number
        }
      }
      #userId will either match with employee_id or worker_id so only one of the table's data will be reset
      update_project_employee(
        where: {
          project: { orientation_project_id: { _eq: $projectId } }
          employee_id: { _eq: $userId }
        }
        _set: { hard_hat_number: $hardHat }
      ) {
        returning {
          id @__clientField(handle: "pk")
          hard_hat_number
        }
      }
    }
  `);
};
export default useUpdateHH;
