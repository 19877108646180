import { DatePicker } from "antd";
import React, { useState } from "react";
import dayjs from "dayjs";

interface IncidentDatepickerProps {
  label: string;
  value?: string;
  format?: string;
  showTime?: boolean;
  onChange: (date: string) => void;
}

const IncidentDatepicker: React.FC<IncidentDatepickerProps> = ({
  label,
  value,
  format,
  showTime,
  onChange,
}) => {
  const [newValue, setNewValue] = useState<string | undefined>(value);

  return (
    <div className="flex gap-1 mt-1 items-center">
      <div className="w-10">{label}</div>
      <DatePicker
        format={format ?? "YYYY-MM-DD"}
        showTime={showTime}
        maxDate={dayjs()}
        value={newValue ? dayjs(newValue) : value ? dayjs(value) : undefined}
        onChange={(time) => {
          setNewValue(time.format());
          onChange(time.format());
        }}
        className="w-full"
      />
    </div>
  );
};

export default IncidentDatepicker;
