import NestedRoute from "../../../../../../common/types/manual/NestedRoute";
import { Outlet } from "react-router-dom";
import GCReports from "../../components/GCReports";
import React from "react";
import GCProjectReportsPermitsSettings from "./components/settings/GCReportsPermitsSettings";

const permitsRoute: NestedRoute = {
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <GCReports reportType="permits" />,
    },
    {
      path: "settings",
      element: <GCProjectReportsPermitsSettings />,
    },
  ],
};

export default permitsRoute;
