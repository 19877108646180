import React, { FC } from "react";
import { PermitProps } from "src/common/types/manual/PermitProps";

import BackButton from "src/common/components/general/BackButton";
import MobileViewWrapper from "src/common/components/layouts/MobileViewWrapper";
import DateProps from "src/common/types/manual/DateProps";
import ProjectProps from "src/common/types/manual/ProjectProps";
import ProjectReportsPermitsDetail from "src/domain-features/sitesafety/components/ProjectReportsPermitsDetail";

const QrProjectPermitReport: FC<PermitProps & ProjectProps & DateProps> = ({
  permitId,
  permitType,
  projectId,
}) => {
  return (
    <MobileViewWrapper footer={<BackButton fullWidth />}>
      <div className="w-full min-h-full overflow-x-auto bg-white">
        <ProjectReportsPermitsDetail
          permitId={permitId}
          permitType={permitType}
          projectId={projectId}
        />
      </div>
    </MobileViewWrapper>
  );
};

export default QrProjectPermitReport;
