import Hotjar from "@hotjar/browser";
import { TrackingUserType } from "../identifyUserForTracking";

// Possible values:
// Numbers
// Strings
// Dates,	ISO-8601 date strings	From JavaScript, use the toISOString() function on a date object
// Booleans

const setHotjarUser = (userProperties: Omit<TrackingUserType, "email">) => {
  if (Hotjar.isReady()) {
    const { id, ...rest } = userProperties;
    Hotjar.identify(id, rest);
  }
};

export default setHotjarUser;
