import { SortOrder, SorterData } from "../../ScrollTable";
import buildFieldRef from "./buildFieldRef";

export type GraphQLSortOrder =
  | "asc"
  | "asc_nulls_first"
  | "asc_nulls_last"
  | "desc"
  | "desc_nulls_first"
  | "desc_nulls_last";

const sortOrderToGrpaphQL = (order: SortOrder): GraphQLSortOrder => {
  return order === "asc" ? "asc" : "desc";
};

const buildGraphQLSorter = (sorter: SorterData) => {
  const { dataIndex, order } = sorter;
  const sortOrder = sortOrderToGrpaphQL(order);

  return buildFieldRef(
    dataIndex.filter((v) => v !== "aggregate"),
    sortOrder,
  );
};
export default buildGraphQLSorter;
