import dayjs from "dayjs";
import { IconProps } from "src/common/components/general/Icon";
import { Certificate } from "src/root/routes/views/subcontractor/people/workers/SCWorkersUI";

export const getCertificateExpiryValue = (certificates: Certificate[]) => {
  let red = false,
    light_orange = false,
    dark_orange = false,
    green = false;
  let clockColor: IconProps["color"];
  for (const cert of certificates) {
    if (!cert.expiryDate) {
      continue;
    }
    const expiryMoment = dayjs(cert.expiryDate);
    const currentMoment = dayjs();
    const days = expiryMoment.diff(currentMoment, "days");
    if (days >= 90) {
      green = true;
    } else if (days < 90 && days >= 60) {
      light_orange = true;
    } else if (days < 60 && days >= 30) {
      dark_orange = true;
    } else if (days < 30) {
      red = true;
    }
  }
  if (red) {
    clockColor = "negative";
  } else if (light_orange) {
    clockColor = "low-severity";
  } else if (dark_orange) {
    clockColor = "high-severity";
  } else if (green) {
    clockColor = "positive";
  }
  return clockColor;
};

export const getCertificateExpiryValueFromDate = (date?: Date) => {
  let textColor = "";
  if (date) {
    const expiryMoment = dayjs(date);
    const currentMoment = dayjs();
    const days = expiryMoment.diff(currentMoment, "days");
    if (days < 90 && days >= 60) {
      textColor = "text-orange-light";
    } else if (days < 60 && days >= 30) {
      textColor = "text-orange-dark";
    } else if (days < 30) {
      textColor = "text-semantic-negative";
    }
  }
  return textColor;
};
