import { FC } from "react";
import {
  useInsertIncidentUserMutation,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  GetIncidentByPkDocument,
  useUpdateIncidentUserMutation,
  useUpdateIncidentEditByPkMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentUser from "./incident-users/IncidentUser";
import * as uuid from "uuid";
import { Button } from "antd";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import InjuryDetail from "./incident-users/InjuryDetail";
import InsuranceDetail from "./incident-users/InsuranceDetail";
import { useSuspenseQuery } from "@apollo/client";
import createIncidentPatch from "./../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";

const IncidentManagementInjury: FC = () => {
  const { projectId, incidentId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const authUser = useAuthUser();

  if (!projectId) throw new Error("projectId is missing");
  if (!incidentId) throw new Error("incidentId is missing");

  const { data: incidentData, refetch } = useSuspenseQuery<
    GetIncidentByPkQuery,
    GetIncidentByPkQueryVariables
  >(GetIncidentByPkDocument, {
    variables: {
      incidentId: incidentId,
    },
    fetchPolicy: "cache-first",
  });

  const incident = incidentData?.incident_by_pk;
  if (!incident) throw new Error("Incident is missing");
  const injuredUserId = searchParams.get("id");

  const [insertIncidentUser] = useInsertIncidentUserMutation();
  const [updateIncidentUser] = useUpdateIncidentUserMutation();
  const [updateIncidentEdit] = useUpdateIncidentEditByPkMutation();

  const insertInjuredUser = async (id: string, name: string, role: string) => {
    if (
      incident.injured_users.findIndex(
        (injuredUser) => injuredUser.user.id === id && !injuredUser.deleted_at,
      ) !== -1
    ) {
      return;
    }

    if (
      incident.injured_users.findIndex(
        (injuredUser) => injuredUser.user.id === id && !!injuredUser.deleted_at,
      ) !== -1
    ) {
      const injuredUserId = incident.injured_users.find(
        (witness) => witness.user.id === id && !!witness.deleted_at,
      )?.id;

      const updatedIncident = {
        ...incident,
        injured_users: incident.injured_users.map((injuredUser) => {
          if (injuredUser.id === injuredUserId)
            return {
              ...injuredUser,
              deleted_at: null,
            };
          else return injuredUser;
        }),
      };
      const patch = createIncidentPatch(updatedIncident, incident);
      const comment = `Added injured user "${name}"`;
      if (injuredUserId) {
        await updateIncidentUser({
          variables: {
            id: injuredUserId,
            set: { deleted_at: null },
            object: {
              patch: patch,
              comment: comment,
              edit_type: "injured-user-edit",
              incident_id: incidentId,
            },
          },
        });
        refetch();
        navigate(`?id=${injuredUserId}`);
      }
    }
    if (injuredUserId) {
      const prevInjuredUser = incident.injured_users.find(
        (injuredUser) => injuredUser.id === injuredUserId,
      )?.user.name;
      const comment = `Updated the injured user ${prevInjuredUser} to ${name}`;
      const { data: updatedIncidentUser } = await updateIncidentUser({
        variables: {
          id: injuredUserId,
          set: { user_id: id },
          object: {
            comment: comment,
            edit_type: "incident-user-update",
            edited_by_uid: authUser.uid,
            incident_id: incidentId,
          },
        },
      });

      const updatedIncident = {
        ...incident,
        injured_users: incident.injured_users.map((injuredUser) => {
          if (
            injuredUser.user.id === id &&
            updatedIncidentUser?.update_incident_user_by_pk
          ) {
            return updatedIncidentUser.update_incident_user_by_pk;
          }
          return injuredUser;
        }),
      };
      const patch = createIncidentPatch(updatedIncident, incident);
      const incidentEditId = updatedIncidentUser?.insert_incident_edit_one?.id;
      if (incidentEditId) {
        await updateIncidentEdit({
          variables: { id: incidentEditId, _set: { patch: patch } },
        });
      }

      refetch();
    } else {
      const injuredUserId = uuid.v4();
      let updatedIncident = incident;
      const comment = `Added injured user "${name}"`;
      const { data: insertedIncidentUserData } = await insertIncidentUser({
        variables: {
          object: {
            id: injuredUserId,
            user_id: id,
            project_id: projectId,
            type: "injured",
            incident_id: incidentId,
            injury_detail: {
              data: {
                insurance_claim: {
                  data: {},
                },
              },
            },
          },
          editObject: {
            comment: comment,
            edited_by_uid: authUser.uid,
            incident_id: incidentId,
            edit_type: "injured-user-added",
          },
        },
        update(cache, result) {
          const addedUser = result.data?.insert_incident_user_one;
          if (!incident.injured_users || !addedUser) return;
          updatedIncident = {
            ...incident,
            injured_users: [...incident.injured_users, addedUser],
          };
          cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>(
            {
              data: {
                __typename: "query_root",
                incident_by_pk: updatedIncident,
              },
              query: GetIncidentByPkDocument,
            },
          );
        },
      });
      const addedUserId =
        insertedIncidentUserData?.insert_incident_user_one?.id;
      navigate(`?id=${addedUserId}`);
      const incidentEditId =
        insertedIncidentUserData?.insert_incident_user_one?.id;

      if (incidentEditId) {
        await updateIncidentEdit({
          variables: {
            id: incidentEditId,
            _set: {
              patch: [],
            },
          },
        });
      }
    }
  };

  return (
    <>
      <div className="absolute left-24 top-2 text-2">
        Injured Person Details
      </div>
      <div className="w-full mt-6 pl-4 table-fixed overflow-y-auto">
        <IncidentUser
          key={injuredUserId}
          title=" Injured Person"
          projectId={projectId}
          incident={incident}
          incidentUserId={injuredUserId ?? undefined}
          onUpdateUser={async (id: string, name: string, role: string) => {
            insertInjuredUser(id, name, role);
          }}
          userType="injured-user"
        />

        {injuredUserId && (
          <>
            <InjuryDetail incident={incident} injuredUserId={injuredUserId} />
            <InsuranceDetail
              incident={incident}
              injuredUserId={injuredUserId}
            />
            <div className="flex w-4/5 justify-between mt-2">
              <Button
                className="w-6"
                type="primary"
                htmlType="submit"
                onClick={() => {
                  const index =
                    incident.injured_users.findIndex(
                      (injuredUser) => injuredUser.id === injuredUserId,
                    ) ?? 0;
                  if (index + 1 < (incident.injured_users.length ?? 0)) {
                    const next = incident.injured_users[index + 1].id;
                    if (next) {
                      navigate(
                        `/gce/projects/${projectId}/incidents/${incidentId}/injured_user?id=${next}`,
                      );
                    }
                  } else {
                    navigate(
                      `/gce/projects/${projectId}/incidents/${incidentId}/root_cause`,
                    );
                  }
                }}
              >
                Save
              </Button>

              <Button
                className="w-6"
                type="primary"
                htmlType="submit"
                onClick={() =>
                  navigate(
                    `/gce/projects/${projectId}/incidents/${incidentId}/injured_user`,
                  )
                }
              >
                Next
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default IncidentManagementInjury;
