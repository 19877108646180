import { FC, useState } from "react";
import StyledContent from "src/common/components/layouts/StyledContent";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import { HammerTechAccountsGetHtAccountsQuery } from 'src/common/types/generated/relay/HammerTechAccountsGetHtAccountsQuery.graphql';
import { Button } from "antd";
import HtLincenseTypesModal from "./HtLicenseTypesModal";
import HtProjectFilterModal from "./HtProjectFilterModal";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";


const accountQuery = graphql`
  query HammerTechAccountsGetHtAccountsQuery {
    ht_integration_account_connection {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          id
          tenant
          login
          active
          created_at
          last_sync_at
          last_sync_status
          skip_labor_union
          agc_license_type_id          
        }
      }
    }
  }
`;


const HammerTechAccounts: FC = () => {
    const accountData = useLazyLoadQuery<HammerTechAccountsGetHtAccountsQuery>(accountQuery, {});
    const nodes = accountData.ht_integration_account_connection.edges.map(e => e.node);
    console.log(accountData.ht_integration_account_connection.edges);
    const [licenseModalAccountId, setLicenseModalAccountId] = useState<string|null>(null);
    const [projectModalAccountId, setProjectModalAccountId] = useState<string|null>(null);

    return (<StyledContent padding backgroundColor="white"> 
        {licenseModalAccountId && 
          <HtLincenseTypesModal 
            htAccountId={licenseModalAccountId}
            visible={true}  
            onClose={() => setLicenseModalAccountId(null)}
            />}
        {projectModalAccountId &&
          <HtProjectFilterModal
            htAccountId={projectModalAccountId}
            visible={true}  
            onClose={() => setProjectModalAccountId(null)}
            />}

        <BaseTable columns={[
            {title: 'Tenant', key:'tentant', dataIndex: 'tenant'}, 
            {title: 'Login', key: 'login', dataIndex: 'login'},
            {title: 'Last sync status', key: 'last_sync_status', dataIndex: 'last_sync_status'},
            {title: 'Active', key: 'active', dataIndex: 'active', render: r => (r?"Yes": "No")},    
            {title: 'Ask Labor Union', key: 'skip_labor_union', dataIndex: 'skip_labor_union', render: r => (!r?"Yes": "No")},
            {title: '', key: 'setup_buttons', render: (row) => {
                return <>
                  <Button onClick={()=> setLicenseModalAccountId(row.pk)}>Lincences</Button>
                  <Button onClick={()=> setProjectModalAccountId(row.pk)}>Projects</Button>
                  </>;
              },
            }]}
          dataSource={nodes}>
        </BaseTable>
    </StyledContent>)

}

export default withCustomSuspense(HammerTechAccounts);