// language title is derived from lang enum value, so it should not be stored it state
// we should use function like this to translate lang to languge on render phase
import { Language } from "./i18nTypes";

const getLanguageTitle = (lang: Language): string => {
  switch (lang) {
    case "en":
      return "English";
    case "es":
      return "Español";
    case "pt":
      return "Português";
    case "it":
      return "Italiano";
    case "fr":
      return "Français";
    case "zh_hans":
      return "中国人";
    default:
      throw Error("unknown language");
  }
};

export default getLanguageTitle;
