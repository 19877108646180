const downloadFromUrl = (url: string, openInNewTab?: boolean) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = url;
  if (openInNewTab) a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default downloadFromUrl;
