import React, { FC } from "react";
import SiteOrientationInPersonWorkerRegistrationStepsCompletedHandAnimation from "src/domain-features/siteorientation/entryRoutes/inPerson/routes/worker/components/steps/completed/SiteOrientationInPersonWorkerRegistrationStepsCompletedHandAnimation";

interface VisitorOrientationDoneProps {}

// TODO add new item in table while editing
const VisitorOrientationDone: FC<VisitorOrientationDoneProps> = ({}) => {
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <SiteOrientationInPersonWorkerRegistrationStepsCompletedHandAnimation handType="thumb-up" />
      <h2 className="mt-2 text-2 pl-2 pr-1">
        Thank You for signing our Visitor Log
      </h2>
    </div>
  );
};

export default VisitorOrientationDone;
