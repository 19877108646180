/**
 * @generated SignedSource<<75ccf1414095bac62acc01a3304cc548>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UploadedToolboxTalkTable_uploaded_toolbox_talk$data = {
  readonly uploaded_toolbox_talk_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly added_to_community: boolean;
        readonly assign_to: string | null | undefined;
        readonly content: {
          readonly en: string;
        } | null | undefined;
        readonly created_at: string;
        readonly created_by_user: {
          readonly employee: {
            readonly general_contractor: {
              readonly name: string;
            };
          } | null | undefined;
          readonly name: string;
          readonly subcontractor_employee: {
            readonly subcontractor: {
              readonly name: string;
            };
          } | null | undefined;
        };
        readonly due_time: string | null | undefined;
        readonly general_contractor: {
          readonly name: string;
        } | null | undefined;
        readonly images: ReadonlyArray<{
          readonly url: string;
        }>;
        readonly name: string;
        readonly pk: string;
        readonly subcontractor: {
          readonly name: string;
        } | null | undefined;
        readonly subcontractor_id: string | null | undefined;
        readonly toolbox_talks: ReadonlyArray<{
          readonly project_id: string;
          readonly toolbox_talk_project_workers: ReadonlyArray<{
            readonly project_worker: {
              readonly worker_id: string;
            };
          }>;
        }>;
        readonly uploaded_toolbox_talk_projects: ReadonlyArray<{
          readonly project_id: string;
        }>;
        readonly uploaded_toolbox_talk_subcontractors: ReadonlyArray<{
          readonly subcontractor_id: string;
        }>;
      };
    }>;
  };
  readonly " $fragmentType": "UploadedToolboxTalkTable_uploaded_toolbox_talk";
};
export type UploadedToolboxTalkTable_uploaded_toolbox_talk$key = {
  readonly " $data"?: UploadedToolboxTalkTable_uploaded_toolbox_talk$data;
  readonly " $fragmentSpreads": FragmentRefs<"UploadedToolboxTalkTable_uploaded_toolbox_talk">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "uploaded_toolbox_talk_connection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v4 = [
  (v1/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "general_contractor",
  "kind": "LinkedField",
  "name": "general_contractor",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./UploadedToolboxTalkTableRefetchableQuery.graphql')
    }
  },
  "name": "UploadedToolboxTalkTable_uploaded_toolbox_talk",
  "selections": [
    {
      "alias": "uploaded_toolbox_talk_connection",
      "args": [
        {
          "kind": "Variable",
          "name": "order_by",
          "variableName": "order_by"
        },
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "uploaded_toolbox_talkConnection",
      "kind": "LinkedField",
      "name": "__UploadedToolboxTalkTable_uploaded_toolbox_talk_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "uploaded_toolbox_talkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "uploaded_toolbox_talk",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": "pk",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id_pk",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_at",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "due_time",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "assign_to",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "added_to_community",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "uploaded_toolbox_talk_project",
                  "kind": "LinkedField",
                  "name": "uploaded_toolbox_talk_projects",
                  "plural": true,
                  "selections": [
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "uploaded_toolbox_talk_subcontractor",
                  "kind": "LinkedField",
                  "name": "uploaded_toolbox_talk_subcontractors",
                  "plural": true,
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user",
                  "kind": "LinkedField",
                  "name": "created_by_user",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "general_contractor_employee",
                      "kind": "LinkedField",
                      "name": "employee",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "subcontractor_employee",
                      "kind": "LinkedField",
                      "name": "subcontractor_employee",
                      "plural": false,
                      "selections": [
                        (v6/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "text_translation",
                  "kind": "LinkedField",
                  "name": "content",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "en",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "image",
                  "kind": "LinkedField",
                  "name": "images",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "toolbox_talk",
                  "kind": "LinkedField",
                  "name": "toolbox_talks",
                  "plural": true,
                  "selections": [
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "toolbox_talk_project_worker",
                      "kind": "LinkedField",
                      "name": "toolbox_talk_project_workers",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "project_worker",
                          "kind": "LinkedField",
                          "name": "project_worker",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "worker_id",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "55121fa4e5b7512b4d5a83891aaf1033";

export default node;
