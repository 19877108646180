import { FC } from "react";
import {
  useUpdateIncidentMutation,
  useInsertDocumentMutation,
  Incident_Set_Input,
  Document_Insert_Input,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  GetIncidentByPkDocument,
  useDeleteDocumentByPkMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";
import IncidentTextField from "./basic/IncidentTextField";
import { Card } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import IncidentCommonUser from "./incident-users/IncidentCommonUser";
import IncidentDatepicker from "./basic/IncidentDatepicker";
import IncidentSelectField from "./basic/IncidentSelectField";
import IncidentBottomButtons from "./basic/IncidentBottomButtons";
import IncidentDocViewAndUpload from "./basic/IncidentDocViewAndUpload";
import IncidentWeatherObservation from "./IncidentWeatherObservation";
import IncidentTaskUploader from "./IncidentTaskUploader";
import { deletedDocument } from "./basic/IncidentDocViewer";
import { useSuspenseQuery } from "@apollo/client";
import createIncidentPatch from "../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import useUpdateIncidentCommonUser from "../utils/useUpdateIncidentCommonUser";
import useUpdateIncidentGeneralPerson from "../utils/useUpdateIncidentGeneralPerson";

const IncidentDetail: FC = () => {
  const { projectId, incidentId } = useParams();
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const [updateIncidentCommonUser] = useUpdateIncidentCommonUser();
  const [updateGeneralPerson] = useUpdateIncidentGeneralPerson();

  if (!projectId) throw new Error("projectId is missing");
  if (!incidentId) throw new Error("incidentId is missing");

  const { data: incidentData } = useSuspenseQuery<
    GetIncidentByPkQuery,
    GetIncidentByPkQueryVariables
  >(GetIncidentByPkDocument, {
    variables: {
      incidentId: incidentId,
    },
    fetchPolicy: "cache-first",
  });

  const incident = incidentData?.incident_by_pk;
  if (!incident) throw new Error("Incident is missing");

  const [deleteDocumentMutation] = useDeleteDocumentByPkMutation();
  const [insertDocument] = useInsertDocumentMutation();
  const [updateIncident] = useUpdateIncidentMutation();
  const updateIncidentFields = async (
    _set: Omit<
      Incident_Set_Input,
      "id" | "project_id" | "created_at" | "description_id"
    >,
    comment: string,
  ) => {
    const updatedIncident = { ...incident, ..._set };
    const patch = createIncidentPatch(updatedIncident, incident);

    await updateIncident({
      variables: {
        incidentId: incidentId,
        _set: _set,
        object: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incidentId,
          edit_type: "incident-detail-edit",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_incident_by_pk: {
          ...incident,
          ..._set,
          id: incidentId,
        },
      },
    });
  };

  const deleteDocument = async (doc: deletedDocument) => {
    const rem = incident.attached_files.filter((file) => file.id !== doc.id);
    const updatedIncident = { ...incident, attached_files: rem };
    const patch = createIncidentPatch(updatedIncident, incident);
    const comment = "Document deleted for Incident Detail";

    await deleteDocumentMutation({
      variables: {
        id: doc.id,
        editObject: {
          comment: comment,
          document_edit: [
            {
              action: "deleted",
              field: "Incident Detail",
              documents: [{ url: doc.url, name: doc.name }],
            },
          ],
          incident_id: incidentId,
          patch: patch,
          edited_by_uid: authUser.uid,
          edit_type: "updated-incident-detail",
        },
      },
      update(cache, result) {
        const docId = result.data?.delete_document_by_pk?.id;
        const rem = incident.attached_files.filter((file) => file.id !== docId);
        const updatedIncident = { ...incident, attached_files: rem };
        if (docId) {
          cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>(
            {
              data: {
                __typename: "query_root",
                incident_by_pk: updatedIncident,
              },
              query: GetIncidentByPkDocument,
            },
          );
        }
      },
    });
  };

  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    const insertedDocs: Array<{ url: string; name: string }> = [];

    const docsToInsert: {
      __typename?: "document";
      url: string;
      id: string;
      group_id?: string | null;
      document_type?: string | null;
      name?: string | null;
      created_at: string;
      image?: {
        __typename?: "image";
        id: string;
        url: string;
      } | null;
    }[] = [];

    objects.forEach((doc) => {
      if (doc.url && doc.id) {
        docsToInsert.push({
          __typename: "document" as const,
          ...doc,
          created_at: dayjs().format(),
          url: doc.url,
          id: doc.id,
          image:
            doc.document_type === "image" ? { url: doc.url, id: doc.id } : null,
        });

        insertedDocs.push({
          url: doc.url,
          name: doc.name ?? "document",
        });
      }
    });
    const updatedIncident = {
      ...incident,
      attached_files: [...incident.attached_files, ...docsToInsert],
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    const comment = "Documents added for Incident Detail";

    await insertDocument({
      variables: {
        objects: objects,
        editObject: {
          patch: patch,
          comment: comment,
          edit_type: "document-upload",
          incident_id: incidentId,
          document_edit: [
            {
              field: "Incident Deatl",
              action: "added",
              documents: insertedDocs,
            },
          ],
          edited_by_uid: authUser.uid,
        },
      },
      update(cache, result) {
        const insertedDocuments = result.data?.insert_document?.returning;
        if (!insertedDocuments) return;

        const updatedIncident = {
          ...incident,
          attached_files: [...incident.attached_files, ...insertedDocuments],
        };

        cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>({
          data: {
            __typename: "query_root",
            incident_by_pk: updatedIncident,
          },
          query: GetIncidentByPkDocument,
        });
      },
    });
  };
  const supervisor = incident.supervisor_user;
  const reportedByUser = incident.reported_by_user;
  const lightingConditions = [
    { key: "well_lit", value: "well_lit", label: "Well lit (no impact)" },
    { key: "dimly_lit", value: "dimly_lit", label: "Dimly lit (no impact)" },
    {
      key: "poor_lighting",
      value: "poor_lighting",
      label: "Poor lighting (impact)",
    },
  ];
  const saveEnabled =
    !!incident?.lighting_conditions &&
    !!incident.summary &&
    !!incident.description.en &&
    !!incident.incident_time &&
    ((incident.emergency_service_called && !!incident.emergency_service) ||
      !incident.emergency_service_called);
  return (
    <>
      <div className="absolute left-24 top-2 text-2">Incident Details</div>
      <div className="w-full mt-6 pl-4 table-fixed overflow-y-auto">
        <div className="flex flex-col flex-1">
          <Card className="w-4/5">
            <IncidentTextField
              label="* Incident title"
              text={incident.summary.en}
              textId={incident.summary.id}
              autoSize={{ minRows: 1, maxRows: 2 }}
              fieldTypeKey="incident_detail"
              field="summary"
              incident={incident}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentTextField
              label="* Full description and details of incident:"
              text={incident.description.en}
              textId={incident.description.id}
              autoSize={{ minRows: 5, maxRows: 10 }}
              fieldTypeKey="incident_detail"
              field="description"
              incident={incident}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentTextField
              label=" Location of Incident:"
              text={incident.location.en}
              textId={incident.location.id}
              fieldTypeKey="incident_detail"
              field="location"
              incident={incident}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentDatepicker
              format="YYYY-MM-DD h:mm A"
              label="* Date and Time of Incident"
              onChange={(val) => {
                updateIncidentFields(
                  { incident_time: val },
                  `Updated Incident Detail - "Time of incident" to "${val}"`,
                );
              }}
              showTime
              value={incident.incident_time ?? undefined}
            />
          </Card>
          <Card className="w-4/5">
            <IncidentDatepicker
              format="YYYY-MM-DD h:mm A"
              label="Date and Time Incident was Reported"
              onChange={(val) => {
                updateIncidentFields(
                  { reporting_time: val },
                  `Updated Incident Detail - "Reporting time" to "${val}"`,
                );
              }}
              showTime
              value={incident.reporting_time ?? undefined}
            />
          </Card>

          <IncidentCommonUser
            title="Who Reported the Incident"
            user={incident?.reported_by_user ?? undefined}
            onUpdateUser={async (id, name) => {
              updateIncidentFields(
                { reported_by_uid: id },
                `Updated Incident Detail - "Reported by user" to "${name}"`,
              );
            }}
            onUpdateUserInfo={async (id, key, val) => {
              if (!!reportedByUser) {
                const updatedIncident = {
                  ...incident,
                  reported_by_user: { ...reportedByUser, [key]: val },
                };
                const patch = createIncidentPatch(updatedIncident, incident);
                const label = key === "phone_number" ? "phone number" : "email";
                const comment = `Updated ${label} of the "Reported by user" to ${val}`;
                updateIncidentCommonUser(
                  id,
                  { [key]: val },
                  comment,
                  incidentId,
                  patch,
                );
              }
            }}
            onUpdateGeneralPerson={async (val) => {
              if (reportedByUser && reportedByUser.general_person) {
                const updatedIncident = {
                  ...incident,
                  reported_by_user: {
                    ...reportedByUser,
                    general_person: {
                      ...reportedByUser.general_person,
                      employer: val,
                    },
                  },
                };
                const patch = createIncidentPatch(updatedIncident, incident);
                const comment = `Updated employer of the "Reported by user" to ${val}`;
                updateGeneralPerson(
                  reportedByUser.id,
                  { employer: val },
                  comment,
                  incidentId,
                  patch,
                );
              }
            }}
            projectId={projectId}
          />

          <IncidentCommonUser
            title=" Supervisor of the Work when Incident occured   "
            user={supervisor ?? undefined}
            onUpdateUser={async (id, name) => {
              updateIncidentFields(
                { supervisor_user_id: id },
                `Updated Incident Detail - "Supervisor of Work" to "${name}"`,
              );
            }}
            onUpdateUserInfo={async (id, key, val) => {
              if (!!supervisor) {
                const updatedIncident = {
                  ...incident,
                  supervisor_user: { ...supervisor, [key]: val },
                };
                const patch = createIncidentPatch(updatedIncident, incident);
                const label = key === "phone_number" ? "phone number" : "email";
                const comment = `Updated ${label} of the "Supervisor of work" to ${val}`;
                updateIncidentCommonUser(
                  id,
                  { [key]: val },
                  comment,
                  incidentId,
                  patch,
                );
              }
            }}
            onUpdateGeneralPerson={async (val) => {
              if (supervisor && supervisor.general_person) {
                const updatedIncident = {
                  ...incident,
                  supervisor_user: {
                    ...supervisor,
                    general_person: {
                      ...supervisor.general_person,
                      employer: val,
                    },
                  },
                };
                const patch = createIncidentPatch(updatedIncident, incident);
                const comment = `Updated employer of the "Reported by user" to ${val}`;
                updateGeneralPerson(
                  supervisor.id,
                  { employer: val },
                  comment,
                  incidentId,
                  patch,
                );
              }
            }}
            projectId={projectId}
          />
          <Card className="w-4/5">
            <IncidentSelectField
              title={"Was a post incident drug & alcohol test performed"}
              options={[
                { label: "No", value: false },
                { label: "Yes", value: true },
              ]}
              value={incident.drug_test_performed ?? undefined}
              onChange={(option) => {
                if (typeof option.value === "boolean")
                  updateIncidentFields(
                    { drug_test_performed: option.value },
                    `Updated Incident Detail - "was drug test performed" to "${option.label}"`,
                  );
              }}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentSelectField
              title={"Lighting Conditions"}
              options={lightingConditions}
              value={incident.lighting_conditions ?? undefined}
              onChange={(option) => {
                if (typeof option.value === "string")
                  updateIncidentFields(
                    {
                      lighting_conditions: option.value,
                    },
                    `Updated Incident Detail -  "lighting conditions" to "${option.label}"`,
                  );
              }}
            />
          </Card>

          <IncidentTaskUploader incident={incident} projectId={projectId} />
          <IncidentWeatherObservation
            incident={incident}
            projectId={projectId}
          />
          <Card className="w-4/5">
            <IncidentSelectField
              title={
                "Was there any equipment that contributed to the cause of the incident?"
              }
              options={[
                { label: "No", value: false },
                { label: "Yes", value: true },
              ]}
              value={incident.equipment_contributed ?? undefined}
              onChange={(option) => {
                if (typeof option.value === "boolean")
                  updateIncidentFields(
                    { equipment_contributed: option.value },
                    `Updated Incident Detail -  "was equipment contributed" to "${option.label}"`,
                  );
              }}
            />
            {incident.equipment_contributed && (
              <IncidentTextField
                label="Equipment Contributed"
                text={incident.equipment_type.en}
                textId={incident.equipment_type.id}
                fieldTypeKey="incident_detail"
                field="equipment_type"
                incident={incident}
              />
            )}
          </Card>
          <Card className="w-4/5">
            <IncidentSelectField
              title={
                "Any project impacts or delays due to incident (monetary, time, deliveries, etc.)?"
              }
              options={[
                { label: "No", value: false },
                { label: "Yes", value: true },
              ]}
              value={incident.project_impacted ?? undefined}
              onChange={(option) => {
                if (typeof option.value === "boolean")
                  updateIncidentFields(
                    { project_impacted: option.value },
                    `Updated Incident Detail - "Projects Impacted" to ${option.label}`,
                  );
              }}
            />

            {incident.project_impacted && (
              <IncidentTextField
                label="Explain project impacts or delays"
                text={incident.project_impacts.en}
                textId={incident.project_impacts.id}
                fieldTypeKey="incident_detail"
                field="project_impacts"
                incident={incident}
              />
            )}
          </Card>

          <Card className="w-4/5">
            <IncidentSelectField
              title={"Emergency Services Called"}
              options={[
                { label: "No", value: false },
                { label: "Yes", value: true },
              ]}
              value={incident.emergency_service_called ?? undefined}
              onChange={(option) => {
                if (typeof option.value === "boolean")
                  updateIncidentFields(
                    { emergency_service_called: option.value },
                    `Updated Incident Detail - "Emergency Services Called" to ${option.label}`,
                  );
              }}
            />

            {incident.emergency_service_called && (
              <IncidentTextField
                label="* Who was called (EMS, Police, etc)?"
                text={incident.emergency_service.en}
                textId={incident.emergency_service.id}
                fieldTypeKey="incident_detail"
                field="emergency_service"
                incident={incident}
              />
            )}
          </Card>

          <Card className="w-4/5">
            <IncidentTextField
              label="If a road vehicle was involved, please provide: Driver(s) Name,
        License # and Company Vehicle:"
              text={incident.road_vehicle_info.en}
              textId={incident.road_vehicle_info.id}
              fieldTypeKey="incident_detail"
              field="road_vehicle_info"
              incident={incident}
            />
          </Card>

          <Card className="w-4/5">
            <IncidentTextField
              label="What was immediately done to rectify the situation and prevent the
        incident from occurring again?"
              text={incident.corrective_action.en}
              textId={incident.corrective_action.id}
              fieldTypeKey="incident_detail"
              field="corrective_action"
              incident={incident}
            />
          </Card>

          <IncidentDocViewAndUpload
            deleteDocument={deleteDocument}
            documents={incident.attached_files}
            groupId={incidentId}
            uploadDocument={uploadDocument}
            type="incident"
          />

          <IncidentBottomButtons
            onNextClick={() => {
              navigate(
                `/gce/projects/${projectId}/incidents/${incidentId}/injured_user`,
              );
            }}
            onSave={() => {
              updateIncidentFields(
                { detail_completed_at: dayjs().format() },
                `Incident Detail marked as Completed`,
              );
            }}
            saveDisabled={!saveEnabled}
          />
        </div>
      </div>
    </>
  );
};

export default IncidentDetail;
