import React from "react";
import { Button, Result, Typography } from "antd";
import contacts from "../../../common/constants/contacts";
import ErrorCodeBlock from "./ErrorCodeBlock";

const { Link, Paragraph, Text } = Typography;

interface ErrorFallbackProps {
  error: any;
  onRetry: () => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = (props) => {
  const errorMessage =
    props.error instanceof Error
      ? props.error.message
      : JSON.stringify(props.error);
  return (
    <Result
      status={"error"}
      title={`Oops! Something Went Wrong`}
      className={`max-w-48 mx-auto`}
      subTitle={
        <p>
          An error occurred. Our development team has been notified and is
          working on a fix. We apologize for the inconvenience.
          <br />
          Need immediate help?{" "}
          <Link
            href={`mailto:${contacts.supportEmail}?subject=Web App Issue`}
            target="_blank"
          >
            Contact us
          </Link>
          .
        </p>
      }
      extra={
        <div>
          <Button
            onClick={(e) => {
              e.preventDefault();
              props.onRetry();
            }}
            type={"primary"}
          >
            Retry
          </Button>
          <ErrorCodeBlock errorMessage={errorMessage} />,
        </div>
      }
    ></Result>
  );
};

export default ErrorFallback;
