import clsx from "clsx";
import React from "react";

export interface SiteFeatureStepsInputLayoutProps {
  headline?: React.ReactNode;
  heightPercent?: number;
  children: React.ReactNode;
}

const SiteFeatureStepsInputLayout: React.FC<
  SiteFeatureStepsInputLayoutProps
> = (props) => {
  return (
    <div
      className={clsx(
        props.heightPercent ? `min-h-[${props.heightPercent}%]` : "min-h-full",
        `flex flex-col justify-center`,
      )}
    >
      <div className={`flex flex-col gap-1`}>
        {props.headline && (
          <p className={`text-1.5 desktop:text-2`}>{props.headline}</p>
        )}
        {props.children}
      </div>
    </div>
  );
};

export default SiteFeatureStepsInputLayout;
