import { useSuspenseQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import AnonProjectWorkerTable from "src/common/components/tables/AnonProjectWorkerTable";
import {
  GetGcAdminWorkersQuery,
  GetGcAdminWorkersQueryVariables,
  GetGcAdminWorkersDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import withOfficeId, { OfficeProps } from "../../utils/withOfficeId";

const GCOfficeWorkers: React.FC<OfficeProps> = ({ officeId }) => {
  const { userData } = useUserData();
  if (!userData.employee) throw new Error("employee not found");
  const { data } = useSuspenseQuery<
    GetGcAdminWorkersQuery,
    GetGcAdminWorkersQueryVariables
  >(GetGcAdminWorkersDocument, {
    variables: {
      gcId: userData.employee.general_contractor_id,
      projWhere: {
        gc_office_id: { _eq: officeId },
      },
    },
  });
  return (
    <div className="bg-white h-full w-full">
      <AnonProjectWorkerTable
        excludedKeys={["crew", "corporateDate"]}
        where={{
          project: { gc_office_id: { _eq: officeId } },
          is_last: { _eq: true },
        }}
        projectsOptions={data.project}
      />
    </div>
  );
};
export default withOfficeId(GCOfficeWorkers);
