import React from "react";
import { Steps } from "antd";
import { SubProjectMobilizationStepType } from "../utilities/subProjectMobilizationTypes";

export interface SubProjectMobilizationStepsProps {
  currentStepId: string;
  onStepChange: (newStepIndex: string) => void;
  stepStatuses: {
    [key: string]: "complete" | "pending";
  };
  steps: SubProjectMobilizationStepType[];
}

const SubProjectMobilizationSteps: React.FC<
  SubProjectMobilizationStepsProps
> = (props) => {
  const current = props.steps.findIndex(
    (step) => step.id === props.currentStepId
  );

  const onStepNumberChange = (newStepIndex: number) => {
    props.onStepChange(props.steps[newStepIndex].id);
  };

  return (
    <Steps direction="vertical" onChange={onStepNumberChange} current={current}>
      {props.steps.map((step) => {
        return (
          <Steps.Step
            key={step.id}
            status={
              step.id === "complete"
                ? "finish"
                : step.id === props.currentStepId
                ? "process"
                : props.stepStatuses[step.id] === "complete"
                ? "finish"
                : "wait"
            }
            title={step.title}
            description={step.description}
          />
        );
      })}
    </Steps>
  );
};

export default SubProjectMobilizationSteps;