import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";

interface SitedeliveryCalendarTimelineProps {
  paddingTop?: string;
}

const SitedeliveryCalendarTimeline: React.FC<
  SitedeliveryCalendarTimelineProps
> = (props) => {
  return (
    <div
      style={{ paddingTop: props.paddingTop }}
      className="sticky top-0 left-0 z-40 items-center h-full flex-flex-col deubg"
    >
      <div className="flex flex-row h-full">
        <div className="h-full">
          <div className="w-px h-full bg-static-secondary"></div>
        </div>
        <div className="flex flex-col items-start justify-between w-px h-full ">
          {(() => {
            const lines: React.ReactNode[] = [];
            for (let i = 0; i < (24 * 60) / 15 + 1; i++) {
              const hour = i / 4;

              const timeToDisplay =
                hour === Math.floor(hour)
                  ? dayjs(`${hour}`, ["h"]).format("h:mm a")
                  : null;
              lines.push(
                <div
                  key={i}
                  className="h-px relative flex flex-row w-0.25 justify-start items-center"
                >
                  <div
                    className={clsx(
                      "h-px w-full",
                      !!timeToDisplay
                        ? "bg-static-primary"
                        : "bg-static-secondary",
                    )}
                  ></div>
                  {!!timeToDisplay && (
                    <div className="absolute top-0 left-0.5 right-0 flex flex-row items-center justify-start h-0">
                      <div>
                        <span className="whitespace-nowrap text-static-secondary text-0.75">
                          {timeToDisplay}
                        </span>
                      </div>
                    </div>
                  )}
                </div>,
              );
            }
            return lines;
          })()}
        </div>
      </div>
    </div>
  );
};

export default SitedeliveryCalendarTimeline;
