import React, { FC, useEffect, useMemo, useState } from "react";
import { Button, Input, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ColumnType, FilterDropdownProps } from "antd/lib/table/interface";
import OpenQrCode from "../OpenQrCode";
import { SCProjectsQuery$data } from "src/common/types/generated/relay/SCProjectsQuery.graphql";
import OrientationInviteModal from "src/root/routes/views/subcontractor/people/workers/components/OrientationInviteModal";

const SearchDropdown = ({ onSubmit }: { onSubmit: (str: string) => void }) => {
  const [inputValue, setInputValue] = useState("");

  const handleSearch = (str: string) => {
    onSubmit(str);
  };

  return (
    <div
      style={{
        padding: 8,
      }}
    >
      <Input
        placeholder="Search..."
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onPressEnter={() => {
          handleSearch(inputValue);
        }}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => {
            handleSearch(inputValue);
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            setInputValue("");
            handleSearch("");
          }}
          size="small"
          style={{ width: 90 }}
        >
          Clear
        </Button>
      </Space>
    </div>
  );
};

function contains(text: string, value?: string | null) {
  return !value || text.toLowerCase().indexOf(value.toLowerCase()) >= 0;
}

interface TableRow {
  id: string;
  project_name: string;
  general_contractor_name: string;
  worker_count: number;
  task_count: number;
  qrCode: any;
}

interface ProjectTableFilter {
  project_name?: string;
  general_contractor_name?: string;
}

interface SubcontractorProjectTableProps {
  data: SCProjectsQuery$data;
  subcontractorId: string;
  completedProjects?: boolean;
  currentProjects?: boolean;
  onProjectClick: (projectId: string) => void;
}

const SubAdminProjectSubcontracorsTable: FC<SubcontractorProjectTableProps> = ({
  subcontractorId,
  completedProjects,
  currentProjects,
  data,
  onProjectClick,
}) => {
  useEffect(() => {
    console.log("refetched Whole page");
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState<
    { id: string; name: string } | undefined
  >(undefined);
  const workers: {
    id: string;
    name: string;
    email?: string | null;
    phone_number?: string | null;
  }[] = [];
  data.worker_connection.edges.map((w) => {
    if (w.node.user.email || w.node.user.phone_number) {
      workers.push({
        id: w.node.user.pk,
        name: w.node.user.name,
        email: w.node.user.email,
        phone_number: w.node.user.phone_number,
      });
    }
  });

  let edges = data?.project_subcontractor_connection.edges || [];
  if (completedProjects) {
    edges = edges.filter((o) => o.node.project.completed);
  } else if (currentProjects) {
    edges = edges.filter((o) => !o.node.project.completed);
  }
  const rows = edges.map((edge): TableRow => {
    const cur = edge.node;

    let task_count = 0;
    for (let sub of cur.project.project_subcontractors) {
      task_count += sub?.tasks_aggregate?.aggregate?.count || 0;
    }

    return {
      id: cur.project.pk,
      project_name: cur.project.name,
      general_contractor_name: cur.project.general_contractors
        .map((gc) => gc.general_contractor.name)
        .join(", "),
      worker_count: cur.project_workers_aggregate?.aggregate?.count || 0,
      task_count,
      qrCode: "",
    };
  });
  const [filter, setFilter] = useState<ProjectTableFilter>({});

  const filteredRows = rows.filter(
    (obj) =>
      contains(obj.project_name, filter.project_name) &&
      contains(obj.general_contractor_name, filter.general_contractor_name),
  );

  const FilterData = (
    dataIndex: string,
  ): Pick<
    ColumnType<TableRow>,
    "filterMultiple" | "filterDropdown" | "filterIcon"
  > => ({
    filterMultiple: true,
    filterDropdown: ({ confirm }: FilterDropdownProps) => (
      <SearchDropdown
        onSubmit={(value) => {
          confirm();
          setFilter({ ...filter, [dataIndex]: value });
        }}
      />
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const columns = useMemo<Array<ColumnType<TableRow>>>(
    () => [
      {
        key: "index",
        width: "7%",
        render: (value, record, index) => <>{index + 1}</>,
      },
      {
        key: "project_name",
        dataIndex: "project_name",
        title: "Project",
        width: "25%",
        ...FilterData("project_name"),
      },
      {
        key: "general_contractor_name",
        dataIndex: "general_contractor_name",
        title: "General contractors",
        width: "24%",
        ...FilterData("general_contractor_name"),
      },
      {
        key: "worker_count",
        dataIndex: "worker_count",
        title: "Workers",
        width: "8%",
        sorter: (a, b) => a.worker_count - b.worker_count,
      },
      {
        key: "task_count",
        dataIndex: "task_count",
        title: "JHAs",
        width: "8%",
        sorter: (a, b) => a.task_count - b.task_count,
      },
      {
        key: "orientation_invite",
        title: "Orientation Invite",
        dataIndex: "id",
        width: "18%",
        render: (id, record) => (
          <>
            <Button
              className="rounded-2"
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedProject(() => {
                  const newVal = { id: id, name: record.project_name };
                  return { ...newVal };
                });
                setOpenModal(true);
              }}
            >
              Invite +
            </Button>
          </>
        ),
      },
      {
        title: "JHA QR Code",
        dataIndex: "qrCode",
        key: "qrCode",
        width: "17%",
        render: (a: any, record) => (
          <OpenQrCode
            onlyIcons
            destinationUrl={`${document.location.origin}/qr/p/${record.id}/sub/${subcontractorId}`}
          />
        ),
      },
    ],
    [],
  );

  const onGetRowProps = (current: TableRow) => ({
    onClick: () => {
      onProjectClick(current.id);
    },
  });

  return (
    <>
      <OrientationInviteModal
        workers={workers}
        visible={openModal}
        selectedProject={selectedProject}
        reset={() => setOpenModal(false)}
      />
      <Table<TableRow>
        dataSource={filteredRows}
        columns={columns}
        bordered
        pagination={false}
        onRow={onGetRowProps}
      />
    </>
  );
};

export default SubAdminProjectSubcontracorsTable;
