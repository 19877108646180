import React, { useEffect, useState } from "react";
import sendHotjarEvent from "../../../utility-features/event-tracking/hotjar/sendHotjarEvent";

type AudioPlayerHookProps = {
  audioElement: HTMLAudioElement | null;
  skipDelaySeconds?: number;
  allowRewind?: boolean;
  allowSkip?: boolean;
};

const useAudioPlayer: (props: AudioPlayerHookProps) => {
  isPlaying: boolean;
  togglePlayPause: () => void;
  rewindAudio?: () => void;
  skipAudio?: () => void;
  duration: number;
  timeProgress: number;
  bufferedTime: number;
  loading: boolean;
} = ({ audioElement, skipDelaySeconds, allowSkip, allowRewind }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = React.useState<number>(0);
  const [timeProgress, setTimeProgress] = React.useState<number>(0);
  const [bufferedTime, setBufferedTime] = React.useState<number>(0);
  const [errorToThrow, setErrorToThrow] = useState<(() => void) | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  // reloadTryCounts counts the reload tries in a row and should be cleared as the connection is established
  const [reloadTryCounts, setReloadTryCounts] = useState<number>(0);

  // Error Handling
  const throwRenderError = (errorText: string) => {
    setErrorToThrow(() => {
      throw new Error(errorText);
    });
  };

  // Audio player controls
  const togglePlayPause = () => {
    const player = audioElement;
    if (!player) return;
    if (isPlaying) player.pause();
    else
      player.play().catch((e) => {
        console.log(e);
        throwRenderError("Audio track cannot be played");
        sendHotjarEvent("error");
      });
  };

  const skipSecondsFunc: (delaySec: number) => () => void = (delaySec) => {
    return () => {
      if (!audioElement) {
        throw new Error("Audio element is broken");
      }
      audioElement.currentTime += delaySec;
    };
  };

  const rewindAudio = skipDelaySeconds
    ? skipSecondsFunc(-skipDelaySeconds)
    : null;
  const skipAudio = skipDelaySeconds ? skipSecondsFunc(skipDelaySeconds) : null;

  const tryReloading = (
    elementToReload: HTMLAudioElement,
    exceptionText: string,
  ) => {
    if (reloadTryCounts < 1) {
      setReloadTryCounts((prevState) => prevState + 1);
      elementToReload.load();
      sendHotjarEvent("audio_reloading");
    } else {
      throwRenderError(exceptionText);
    }
  };

  // Audio player event listeners

  useEffect(() => {
    if (audioElement) {
      setIsPlaying(!audioElement.paused && !audioElement.ended);

      const onSuspend = () => {
        // This event is called when the browser is intentionally not getting media data
        // This can happen when the media has enough data to play for a while and does not need to download more at the dayjs.
        sendHotjarEvent("audio_suspended");
        setLoading(false);
        setReloadTryCounts(0);
      };
      const onEnded = () => {
        sendHotjarEvent("audio_ended");
      };
      const onLoadedMetadata = () => {
        const seconds = audioElement.duration;
        setDuration(seconds);
      };
      const onPlay = () => {
        setIsPlaying(true);
      };
      const onPlaying = () => {
        // When the buffering is done, the audio will start playing automatically
        sendHotjarEvent("audio_playing");
        setLoading(false);
      };
      const onWaiting = () => {
        // The player is probably buffering
        sendHotjarEvent("audio_waiting");
        setLoading(true);
      };
      const onPaused = () => {
        setIsPlaying(false);
      };

      const onCanPlay = () => {
        setLoading(false);
        sendHotjarEvent("audio_can_play");
      };

      const onCanPlayThrough = () => {
        setLoading(false);
        sendHotjarEvent("audio_can_play_through");
      };

      const onError = () => {
        sendHotjarEvent("error");
        const error = audioElement.error;
        if (error) {
          console.log("Error code: ", error.code);
          switch (error.code) {
            case error.MEDIA_ERR_ABORTED:
              // Usr aborted the audio playback
              throwRenderError("Audio playback is aborted");
              break;
            case error.MEDIA_ERR_NETWORK:
              // Network error caused the audio download to fail
              throwRenderError("Network error");
              break;
            case error.MEDIA_ERR_DECODE:
              // The audio playback was aborted due to a corruption problem or because the media used features your browser did not support.
              tryReloading(audioElement, "The audio track is failed to load.");
              break;
            case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
              // The audio could not be loaded, either because the server or network failed or because the format is not supported
              throwRenderError(
                "This audio cannot load due to a poor internet connection.",
              );
              break;
            default:
              throwRenderError("An unexpected error occurred.");
          }
        } else {
          throwRenderError("An unknown error occurred.");
        }
      };
      const onProgress = () => {
        if (audioElement.buffered.length > 0) {
          setBufferedTime(
            audioElement.buffered.end(audioElement.buffered.length - 1),
          );
        }
        setLoading(false);
      };
      const onTimeUpdate = () => {
        setTimeProgress(audioElement.currentTime);
      };
      const onStalled = () => {
        // This even is fired when the browser is trying to get media data but it is not getting any
        setLoading(true);
        // TODO let user know there's an internet issue
        sendHotjarEvent("audio_stalled");
      };
      const eventHandlers: Parameters<HTMLAudioElement["addEventListener"]>[] =
        [
          ["suspend", onSuspend],
          ["ended", onEnded],
          ["loadedmetadata", onLoadedMetadata],
          ["play", onPlay],
          ["playing", onPlaying],
          ["waiting", onWaiting],
          ["pause", onPaused],
          ["error", onError],
          ["progress", onProgress],
          ["timeupdate", onTimeUpdate],
          ["stalled", onStalled],
          ["canplay", onCanPlay],
          ["canplaythrough", onCanPlayThrough],
        ];

      eventHandlers.forEach((eventHandlerProps) => {
        audioElement.addEventListener(...eventHandlerProps);
      });
      // Update audio progress if the reference is changed
      onProgress();
      onTimeUpdate();

      return () => {
        eventHandlers.forEach((eventHandlerProps) => {
          audioElement.removeEventListener(...eventHandlerProps);
        });
      };
    }
  }, [audioElement]);
  return {
    isPlaying,
    togglePlayPause,
    rewindAudio: allowRewind && rewindAudio ? rewindAudio : undefined,
    skipAudio: allowSkip && skipAudio ? skipAudio : undefined,
    duration,
    timeProgress,
    bufferedTime,
    loading,
  };
};

export default useAudioPlayer;
