import { Form, Select, Button } from "antd";
import React, { FC, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { ConnectionHandler } from "relay-runtime";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { NotifyUserSettings_Update_Email_Project_User_Mutation } from "src/common/types/generated/relay/NotifyUserSettings_Update_Email_Project_User_Mutation.graphql";
import { useSiteOrientationGcDashboardDataQuery$data } from "src/common/types/generated/relay/useSiteOrientationGcDashboardDataQuery.graphql";
import * as uuid from "uuid";
const mutationToChangeUsersToNotify = graphql`
  mutation NotifyUserSettings_Update_Email_Project_User_Mutation(
    $objects: [email_project_user_insert_input!]!
    $deleteWhere: email_project_user_bool_exp!
  ) {
    delete_email_project_user(where: $deleteWhere) {
      returning {
        type
        user_id
        id
        user {
          name
        }
      }
    }
    insert_email_project_user(objects: $objects) {
      returning {
        type
        user_id
        id
        user {
          name
        }
      }
    }
  }
`;
const NotifyUserSettings: FC<{
  projectId: string;
  title: string;
  type: "quiz_failure" | "worker_with_drug_test_arrival";
  allGCUsers: useSiteOrientationGcDashboardDataQuery$data["project_connection"]["edges"][0]["node"]["project_employees"];
  currentSelectedUsers: Array<{ id: string; name: string }>;
}> = ({ type, allGCUsers, currentSelectedUsers, projectId, title }) => {
  const [changeUsersToNotify] =
    useAsyncMutation<NotifyUserSettings_Update_Email_Project_User_Mutation>(
      mutationToChangeUsersToNotify
    );
  const currentSelectedUserIds = currentSelectedUsers.map((p) => p.id);
  const [loading, setLoading] = useState(false);
  const [form2] = Form.useForm();
  const [
    editUserForQuizFailureNotification,
    setEditUserForQuizFailureNotification,
  ] = useState(false);
  return (
    <div>
      {title}{" "}
      <div className="flex">
        {editUserForQuizFailureNotification ? (
          <Form
            form={form2}
            className="w-2/5"
            initialValues={{ selectedGCs: currentSelectedUserIds }}
          >
            <Form.Item name="selectedGCs">
              <Select mode="multiple" disabled={loading}>
                {allGCUsers.map((u) => (
                  <Select.Option value={u.employee.uid} key={u.employee.uid}>
                    {u.employee.user.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        ) : (
          <div>
            {currentSelectedUsers.map((u) => (
              <span
                className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-interactive-light text-interactive-primary"
                key={u.id}
              >
                {u.name}
              </span>
            ))}
          </div>
        )}
        <Button
          className="ml-1 rounded-2"
          size="small"
          type="primary"
          loading={loading}
          onClick={async () => {
            if (editUserForQuizFailureNotification) {
              const newVals = form2.getFieldValue("selectedGCs");

              const [toInsert, toDelete] = compareTwoLists(
                newVals,
                currentSelectedUserIds
              );
              setLoading(true);

              await changeUsersToNotify({
                variables: {
                  deleteWhere: {
                    project_id: { _eq: projectId },
                    type: { _eq: type },
                    user_id: { _in: toDelete },
                  },
                  objects: toInsert.map((u: string) => ({
                    user_id: u,
                    project_id: projectId,
                    type: type,
                  })),
                },
                updater: (store) => {
                  const deleteEdges = store.getRootField(
                    "delete_email_project_user"
                  );
                  const conn = ConnectionHandler.getConnection(
                    store.getRoot(),
                    type === "quiz_failure"
                      ? "useSiteOrientationGcDashboardDataQuery_quiz_failure_email_project_user"
                      : "useSiteOrientationGcDashboardDataQuery_worker_with_drug_test_arrival_email_project_user"
                  );
                  if (conn) {
                    deleteEdges.getLinkedRecords("returning").forEach((s) => {
                      ConnectionHandler.deleteNode(conn, s.getDataID());
                    });
                  }
                  const insertedEdges = store.getRootField(
                    "insert_email_project_user"
                  );

                  if (
                    conn &&
                    insertedEdges &&
                    insertedEdges.getLinkedRecords("returning")
                  ) {
                    insertedEdges
                      .getLinkedRecords("returning")
                      .forEach((val) => {
                        const edge = store.create(uuid.v4(), "edge");
                        edge.setLinkedRecord(val, "node");
                        ConnectionHandler.insertEdgeAfter(conn, edge);
                      });
                  }
                },
              }).catch(console.log);
              setLoading(false);
              setEditUserForQuizFailureNotification(false);
            } else {
              setEditUserForQuizFailureNotification(true);
            }
          }}
        >
          {editUserForQuizFailureNotification ? "Done" : "Edit Users"}
        </Button>
        {editUserForQuizFailureNotification && (
          <Button
            loading={loading}
            className="ml-1 rounded-2"
            size="small"
            onClick={() => setEditUserForQuizFailureNotification(false)}
          >
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
};
export default NotifyUserSettings;
