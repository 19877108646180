import React from "react";
import { SitedeliveryCalendarEventType } from "../../utilities/sitedeliveryTypes";
import SitedeliveryCalendarEventBlockOut from "./SitedeliveryCalendarEventBlockOut";
import SitedeliveryCalendarEventDelivery from "./SitedeliveryCalendarEventDelivery";
import SitedeliveryCalendarEventSubBlock from "./SitedeliveryCalendarEventSubBlock";
import BorderTime from "./SitedeliveryEventBorderTime";

type SitedeliveryCalendarEventProps = SitedeliveryCalendarEventType & {
  colorHex: string;
};

const SitedeliveryCalendarEvent: React.FC<SitedeliveryCalendarEventProps> = (
  props
) => {
  let child: React.ReactNode = null;
  let zIndex: number = 0;

  switch (props.type) {
    case "blocked out":
      child = <SitedeliveryCalendarEventBlockOut {...props} />;
      zIndex = 0;
      break;
    case "delivery":
      child = <SitedeliveryCalendarEventDelivery {...props} />;
      zIndex = 23;
      break;
    case "sub block":
      child = <SitedeliveryCalendarEventSubBlock {...props} />;
      zIndex = 22;
      break;
    default:
  }

  return (
    <div className="relative w-full h-full group" style={{ zIndex }}>
      <BorderTime top time={props.from} />
      {child}

      <BorderTime bottom time={props.to} />
    </div>
  );
};

export default SitedeliveryCalendarEvent;
