import { GetGcPermitChecklistItemsQuery } from "src/common/types/generated/apollo/graphQLTypes";

const buildNewProjData = (
  data: GetGcPermitChecklistItemsQuery,
  projectId: string,
  newProjData: GetGcPermitChecklistItemsQuery["project"][number],
): GetGcPermitChecklistItemsQuery => ({
  ...data,
  project: data.project.map((proj) =>
    proj.id !== projectId ? proj : newProjData,
  ),
});
export default buildNewProjData;
