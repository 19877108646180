import React from "react";
import dayjs from "dayjs";

interface TimeRangeSpanProps {
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
  className?: string;
}

const formatTime = (time: dayjs.Dayjs) => {
  return time.format(`h:mm a`);
};

const TimeRangeSpan: React.FC<TimeRangeSpanProps> = (props) => {
  return (
    <span
      className={props.className ?? "text-0.75 text-static-secondary"}
    >{`${formatTime(props.from)} → ${formatTime(props.to)}`}</span>
  );
};

export default TimeRangeSpan;
