import { DatePicker, Form, Input, Modal, Radio, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import getNormalOptionsFilter from "src/common/functions/getNormalOptionsFilter";
import {
  Order_By,
  useGetGeneralContractorsLazyQuery,
  useGetStatesQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import AddressFormItems from "src/root/routes/views/general-contractor/hierarchy/component/AddressFormItems";
import SelectLocalCity from "src/root/routes/views/general-contractor/projects/newProject/component/SelectLocalCity";

interface CreateProjectValues {
  name: string;
  generalContractorId: string;
  externalId: string;
  addressLine1: string;
  city: string;
  zip: string;
  stateCode: string;
  startDate: string;
  endDate: string;
  union?: boolean;
  local_cities: string[];
}

interface CreateProjectModalProps {
  visible: boolean;
  onCreate: (props: { values: CreateProjectValues }) => void;
  onCancel: () => void;
  disableGc?: boolean;
}

const CreateProjectModal: React.FC<CreateProjectModalProps> = ({
  visible,
  onCreate,
  disableGc,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };
  const [stateCode, setStateCode] = useState<string>();
  const [getGcs, { data, loading }] = useGetGeneralContractorsLazyQuery({
    variables: {
      order_by: [
        {
          name: Order_By.Asc,
        },
      ],
    },
    fetchPolicy: "network-only",
  });

  const { data: statesData, loading: loadingStates } = useGetStatesQuery();

  useEffect(() => {
    if (!disableGc) {
      getGcs();
    }
  }, [disableGc]);

  return (
    <Modal
      open={visible}
      title="Create a Project"
      okText="Create"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            if (disableGc) onCreate({ values: values as CreateProjectValues });
            else if (data?.general_contractor) {
              onCreate({ values: values as CreateProjectValues });
            }
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public", union: false }}
      >
        <Form.Item
          name="name"
          label="Project name"
          rules={[{ required: true, message: "Enter a name" }]}
        >
          <Input />
        </Form.Item>
        {!disableGc && (
          <Form.Item
            name="generalContractorId"
            label="General Contractor"
            rules={[{ required: true, message: "Pick a GC" }]}
          >
            <Select
              loading={loading}
              showSearch
              disabled={loading}
              style={{ width: "100%" }}
              filterOption={getNormalOptionsFilter}
              options={(data?.general_contractor || []).map((g) => ({
                label: g.name,
                value: g.id,
              }))}
            />
          </Form.Item>
        )}
        <Form.Item
          name="externalId"
          label="ID"
          rules={[{ required: false, message: "Enter an ID" }]}
        >
          <Input />
        </Form.Item>
        Address
        <AddressFormItems
          onStateChange={(v) => {
            setStateCode(v);
          }}
          states={statesData?.state || []}
        />
        <Form.Item
          label="Start Date"
          name="startDate"
          getValueFromEvent={(onChange) => dayjs(onChange).format("YYYY-MM-DD")}
          getValueProps={(i) => ({ value: dayjs(i) })}
        >
          <DatePicker format="YYYY-MM-DD" className="w-full" />
        </Form.Item>
        <Form.Item
          label="End Date"
          name="endDate"
          getValueFromEvent={(onChange) => dayjs(onChange).format("YYYY-MM-DD")}
          getValueProps={(i) => ({ value: dayjs(i) })}
        >
          <DatePicker
            format="YYYY-MM-DD"
            className="w-full"
            disabledDate={(current) =>
              current.isBefore(dayjs().subtract(1, "day"))
            }
          />
        </Form.Item>
        <Form.Item
          label="Will this project mainly use Union labor?"
          name="union"
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        {stateCode && (
          <SelectLocalCity
            label="Tracking Local Residents?  Select the City or Cities considered local"
            stateCode={stateCode}
          />
        )}
      </Form>
    </Modal>
  );
};

export default CreateProjectModal;
