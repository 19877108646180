import React, { useState, useRef } from "react";
import { message, Modal } from "antd";
import { useConvertWorkerToSubadminMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { useParams } from "react-router-dom";

interface ConfirmConvertWorkerToSubadminModalProps {
  modalClose: () => void;
  modalVisible: boolean;
  workerId: string;
}
const ConfirmConvertWorkerToSubadminModal: React.FC<
  ConfirmConvertWorkerToSubadminModalProps
> = ({ modalClose, modalVisible, workerId }) => {
  const [loading, setLoading] = useState(false);

  const [convertWorker] = useConvertWorkerToSubadminMutation();

  // const { projectId } = useParams();
  // if (!projectId) {
  //   throw new Error("ProjectId param is missing");
  // }

  return (
    <Modal
      open={modalVisible}
      title="Warning"
      confirmLoading={loading}
      okText="Confirm"
      cancelText="Cancel"
      cancelButtonProps={{ style: { display: "inline" } }}
      onCancel={modalClose}
      onOk={async () => {
        setLoading(true);
        try {
          await convertWorker({
            variables: {
              input: { workerId: workerId },
            },
          });
          message.success(
            "Success! Worker changed to Administrator. They will receive an email invite/confirmation.",
          );
        } catch (error) {
          console.log("Validate Failed", error);
          message.error("Unable to Convert Worker");
        }
        setLoading(false);
        modalClose();
      }}
    >
      <p>
        This email address is assigned to a Worker. Inviting them to SiteForm as
        an Administrator will increase their permissions and give them access to
        your Company’s website, which
        <strong> includes JHAs, Workers, and Reports.</strong> They will
        maintain the same access in the SiteForm mobile app.
      </p>
      <p>
        <strong>Are you sure?</strong>
      </p>
    </Modal>
  );
};

export default ConfirmConvertWorkerToSubadminModal;
