import { useContext } from "react";
import VisitorOrientationOrienteeContext from "./visitorOrientationOrienteeContext";

const useSiteVisitor = () => {
  const value = useContext(VisitorOrientationOrienteeContext);
  if (!value)
    throw new Error(
      "useSiteVisitor must be wrapped inside VisitorOrientationOrienteeContext",
    );
  return value;
};

export default useSiteVisitor;

export const useSiteVisitorUser = () => {
  const context = useSiteVisitor();
  return [context.visitor, context.updateVisitor] as const;
};
