import { useState } from "react";
import { SiteFeatureLayoutBodyProps } from "src/common/components/layouts/steps-navigation-layout/SiteFeatureLayoutBody";

/*

  Usage:

  1. Use the first hook to get navigation functions (useSiteFeatureStepsNavigationDirection)
  2. Declare the steps array. You're going to use the navigation functions from the step 1 in the steps declaration
  3. Use the second hook to modify the current step id and retrieve it (useSiteFeatureStepsNavigationEffect)

  Example:

  const {
    goToNextStep,
    goToPrevStep,
    nextStepDirection,
    clearStepDirection,
  } = useSiteFeatureStepsNavigationDirection();

  const steps = [
    {
      id: "step1",
      title: "Step 1",
      body: <StepComponent1
        onNextButtonClick={goToNextStep}
        onFinishClick={()=>{
          goToStepById("finish")
          }}
        />,
    }
  ]

  const {currentStepId, goToStepById} = useSiteFeatureStepsNavigationEffect(nextStepDirection, clearStepDirection, steps)


 */

export type SiteFeatureNavigationStepsDirectionType =
  | "forward"
  | "backward"
  | null;

export const useSiteFeatureStepsNavigationDirection = () => {
  const [nextStepDirection, setNextStepDirection] =
    useState<SiteFeatureNavigationStepsDirectionType>(null);
  const goToNextStep = () => {
    setNextStepDirection("forward");
  };
  const goToPrevStep = () => {
    setNextStepDirection("backward");
  };

  const clearStepDirection = () => {
    setNextStepDirection(null);
  };

  return {
    goToNextStep,
    goToPrevStep,
    nextStepDirection,
    clearStepDirection,
  } as const;
};

export const useSiteFeatureStepsNavigationEffect = (
  nextStepDirection: SiteFeatureNavigationStepsDirectionType,
  clearStepDirection: () => void,
  steps: SiteFeatureLayoutBodyProps["steps"],
) => {
  const [currentStepId, setCurrentStepId] = useState<string | null>(
    steps.length ? steps[0].id : null,
  );

  if (nextStepDirection !== null) {
    const currentStepIndex = steps.findIndex(
      (stepsItem) => stepsItem.id === currentStepId,
    );
    setCurrentStepId(() => {
      let newStepIndex = currentStepIndex;
      if (nextStepDirection === "forward") {
        for (let i = currentStepIndex + 1; i < steps.length; i++) {
          if (!steps[i].disabled) {
            newStepIndex = i;
            break;
          }
        }
      } else {
        for (let i = currentStepIndex - 1; i >= 0; i--) {
          if (!steps[i].disabled) {
            newStepIndex = i;
            break;
          }
        }
      }
      return steps[newStepIndex].id;
    });
    clearStepDirection();
  }

  const goToStepById = (stepId: string) => {
    if (steps.some((step) => step.id === stepId)) {
      setCurrentStepId(stepId);
    } else {
      setCurrentStepId(steps[0].id);
    }
    clearStepDirection();
  };

  return {
    currentStepId,
    goToStepById,
  };
};
