import { DownloadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  IconBrandTelegram,
  IconChecklist,
  IconCirclePlus,
  IconPlus,
} from "@tabler/icons";
import { Button, Popover, Space, Switch, Table, Tooltip } from "antd";
import { TableProps } from "antd/lib/table";
import { graphql } from "babel-plugin-relay/macro";
import React, { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import noPropagation from "src/common/functions/noPropagation";
import { useGenerateSsspMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { GCSubcontractorsQuery$data } from "src/common/types/generated/relay/GCSubcontractorsQuery.graphql";
import { ProjectSubcontractorTableNew_UpdateProjSubLocation_Mutation } from "src/common/types/generated/relay/ProjectSubcontractorTableNew_UpdateProjSubLocation_Mutation.graphql";
import { ProjectSubcontractorTableNew_InsertProjSubLocation_Mutation } from "src/common/types/generated/relay/ProjectSubcontractorTableNew_InsertProjSubLocation_Mutation.graphql";
import GCProjectSubAdminInvitationModal from "src/utility-features/invitations/invitations-to-subs/gc-project-subadmin-invitation/GCProjectSubAdminInvitationModal";
import GCSendInviteModal, {
  GCSendInviteModalRef,
} from "src/utility-features/invitations/GCSendInviteModal";
import SubTableActionPopover from "src/root/routes/views/general-contractor/projects/subcontractors/components/SubTableActionPopover";
import SearchBar from "../SearchBar";
import Icon from "../general/Icon";
import { IconFilter } from "@tabler/icons";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import * as uuid from "uuid";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import GetFullID from "src/common/functions/GetFullId";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";

//?? Do we really need to convert data to another structure?
// no we do not need to
export type GCSubcontractorsRecordType =
  GCSubcontractorsQuery$data["project_subcontractor_connection"]["edges"][number]["node"];
type ColumnsType = TableProps<GCSubcontractorsRecordType>["columns"];

interface ProjectSubcontractorTableNewProps {
  projectId: string;
  data: GCSubcontractorsQuery$data;
  fetchKey?: number;
  showsubadmininvite?: boolean;
  onclicked?: (r: GCSubcontractorsRecordType) => any;
}
const RenderProjSubLocationSwitch: React.FC<{
  row: GCSubcontractorsRecordType;
  locationId: string;
  projectId: string;
}> = ({ locationId, projectId, row }) => {
  const [insertProjSubLocation] =
    useAsyncMutation<ProjectSubcontractorTableNew_InsertProjSubLocation_Mutation>(graphql`
      mutation ProjectSubcontractorTableNew_InsertProjSubLocation_Mutation(
        $object: project_subcontractor_location_insert_input!
      ) {
        insert_project_subcontractor_location_one(
          object: $object
          on_conflict: {
            constraint: project_subcontractor_location_project_location_id_subcontracto
            update_columns: [is_sub_active]
          }
        ) {
          id
          project_location_id
          is_sub_active
          pk: id @__clientField(handle: "pk")
        }
      }
    `);
  const [updateProjSubLocation] =
    useAsyncMutation<ProjectSubcontractorTableNew_UpdateProjSubLocation_Mutation>(graphql`
      mutation ProjectSubcontractorTableNew_UpdateProjSubLocation_Mutation(
        $id: uuid!
        $is_sub_active: Boolean!
      ) {
        update_project_subcontractor_location_by_pk(
          pk_columns: { id: $id }
          _set: { is_sub_active: $is_sub_active }
        ) {
          id
          is_sub_active
        }
      }
    `);

  const insertProjSubLocationUpdater = (
    store: RecordSourceSelectorProxy<
      ProjectSubcontractorTableNew_InsertProjSubLocation_Mutation["response"]
    >,
  ) => {
    const insertRelation = store.getRootField(
      "insert_project_subcontractor_location_one",
    );
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "GCSubcontractorsQuery_project_subcontractor_connection",
    );
    if (conn && insertRelation) {
      const edges = conn.getLinkedRecords("edges") || [];
      const edge = edges.find((edge) => {
        const node = edge.getLinkedRecord("node");
        return node?.getDataID() === row.id;
      });
      if (edge) {
        const node = edge.getLinkedRecord("node");
        if (node) {
          const subLocationRecords =
            node.getLinkedRecords("project_subcontractor_locations") || [];
          node.setLinkedRecords(
            [...subLocationRecords, insertRelation],
            "project_subcontractor_locations",
          );
        }
      }
    }
  };

  const projSubLocation = row.project_subcontractor_locations.find(
    (p) => p.project_location_id === locationId,
  );
  return (
    <Switch
      checked={!!projSubLocation && projSubLocation.is_sub_active}
      onChange={(checked) => {
        console.log(row, ":");
        if (!projSubLocation) {
          const id = uuid.v4();
          insertProjSubLocation({
            variables: {
              object: {
                id,
                project_id: projectId,
                subcontractor_id: row.subcontractor_id,
                project_location_id: locationId,
                is_sub_active: checked,
              },
            },
            optimisticResponse: {
              insert_project_subcontractor_location_one: {
                id: GetFullID("project_subcontractor_location", id),
                project_location_id: locationId,
                is_sub_active: checked,
              },
            },
            optimisticUpdater: insertProjSubLocationUpdater,
            updater: insertProjSubLocationUpdater,
          });
        } else {
          updateProjSubLocation({
            variables: { id: projSubLocation.pk, is_sub_active: checked },
            optimisticResponse: {
              update_project_subcontractor_location_by_pk: {
                id: projSubLocation.id,
                is_sub_active: checked,
              },
            },
          });
        }
      }}
    />
  );
};

const ProjectSubcontractorTableNew: React.FunctionComponent<
  ProjectSubcontractorTableNewProps
> = ({ projectId, fetchKey, showsubadmininvite, data, onclicked }) => {
  const navigate = useNavigate();
  const dataSource = useMemo(
    () => data.project_subcontractor_connection.edges.map(({ node }) => node),
    [data],
  );

  const [subAdminInvitationModalVisible, setSubAdminInvitationModalVisible] =
    useState(false);
  const [subcontractorForInvite, setSubcontractorForInvite] = useState<
    string | null
  >(null);
  const [subcontractorForDocReq, setSubcontractorForDocReq] = useState<
    string | null
  >(null);

  const sendInviteRef = useRef<GCSendInviteModalRef>(null);

  const [generateSssp] = useGenerateSsspMutation();
  const handleDownloadSsspClick = async (subcontractorId: string) => {
    const { data: downloadedData } = await generateSssp({
      variables: {
        input: {
          projectId,
          subcontractorId,
          gcId: data.user_connection.edges[0].node.employee?.general_contractor
            .pk,
        },
      },
    });

    if (downloadedData?.generateSSSP) {
      downloadFromUrl(downloadedData?.generateSSSP);
    }
  };

  const showLocationColumns =
    data.project_location_connection.edges.length >= 2;
  const locationColumns: ColumnsType = showLocationColumns
    ? data.project_location_connection.edges.map((location) => ({
        title: location.node.name.en,
        dataIndex: ["project_subcontractor_locations", "0", "is_sub_active"],
        key: location.node.id,
        width: "12%",
        render: (_, row) => (
          <div onClick={(e) => e.stopPropagation()}>
            <RenderProjSubLocationSwitch
              row={row}
              locationId={location.node.pk}
              projectId={projectId}
            />
          </div>
        ),
      }))
    : [];

  const jhaColumns: ColumnsType = [
    {
      //title: "Tasks - by Foremen",
      title: "Project Library",
      dataIndex: ["subcontractor", "proj_tasks", "aggregate", "count"],
      key: "numberTaskByForemans",
      width: "12%",
      sorter: (a, b) =>
        (a.subcontractor.proj_tasks.aggregate?.count ?? 0) -
        (b.subcontractor.proj_tasks.aggregate?.count ?? 0),
    },
    {
      //title: "Tasks - Company Library",
      title: "Company Library",
      dataIndex: ["subcontractor", "lib_tasks", "aggregate", "count"],
      key: "numberTaskInCompanyLibrary",
      width: "12%",
      sorter: (a, b) =>
        (a.subcontractor.lib_tasks.aggregate?.count ?? 0) -
        (b.subcontractor.lib_tasks.aggregate?.count ?? 0),
    },
  ];

  if (showsubadmininvite) {
    jhaColumns.push({
      title: "Request JHAs",
      key: "actions",
      width: "20%",
      render: (_: any, record) => (
        <div className={`flex flex-row items-center`}>
          <Tooltip title={"Send an email requesting to upload a JHA"}>
            <Button
              type="text"
              size={"small"}
              onClick={noPropagation(() => {
                if (onclicked) onclicked(record);
              })}
              icon={<IconBrandTelegram />}
            />
          </Tooltip>
        </div>
      ),
    });
  }

  const content = (
    <div>
      <p>JHA QR Codes allow workers to ACCESS, REVIEW, and SIGN their JHAs.</p>
      <p>
        Download and give the JHA QR Code EXPLAINER to your foremen and crew
        leads to post on Huddle Boards or in work areas.
      </p>
    </div>
  );

  const [search, setSearch] = useState("");
  const columns: ColumnsType = [
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "9%",
      render: (value, record, index) => index + 1,
    },
    {
      title: "Subcontractor",
      dataIndex: ["subcontractor", "name"],
      key: "subcontractor",
      sorter: (a, b) =>
        a.subcontractor.name.localeCompare(b.subcontractor.name),
      width: "22%",
      // ...FilterData("subcontractor"),
    },
    {
      title: "Trade",
      dataIndex: ["subcontractor", "trade", "name"],
      key: "trade",
      width: "18%",
    },
    {
      title: "SubAdmins",
      dataIndex: [
        "subcontractor",
        "subcontractor_employees_aggregate",
        "aggregate",
        "count",
      ],
      key: "subadmins",
      width: "14%",
      sorter: (a, b) =>
        (a.subcontractor.subcontractor_employees_aggregate.aggregate?.count ??
          0) -
        (b.subcontractor.subcontractor_employees_aggregate.aggregate?.count ??
          0),
      render: (subAdmins, record) => (
        <div className={`flex flex-row items-center`}>
          {subAdmins}
          <Tooltip title={"Invite subadmin"}>
            <Button
              type="text"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setSubcontractorForInvite(record.subcontractor_id);
                sendInviteRef.current?.open();
              }}
              icon={<IconCirclePlus />}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Workers",
      dataIndex: ["project_workers_aggregate", "aggregate", "count"],
      key: "workers",
      width: "10%",
      sorter: (a, b) =>
        (a.project_workers_aggregate.aggregate?.count ?? 0) -
        (b.project_workers_aggregate.aggregate?.count ?? 0),
    },
    ...(showLocationColumns
      ? [
          {
            key: "locations",
            title: (
              <div className="flex">
                Project Locations
                <Popover
                  className="ml-1"
                  overlayStyle={{
                    width: "20%",
                  }}
                  placement="topRight"
                  content={
                    "Turn ON/OFF for subs working on each location\nNOTE: this will show during worker PTP if working on more than one."
                  }
                >
                  <InfoCircleOutlined />
                </Popover>
              </div>
            ),
            children: locationColumns,
          },
        ]
      : []),
    {
      title: (
        <div>
          Doc Req
          <Popover
            className="ml-1"
            overlayStyle={{
              width: "20%",
            }}
            placement="topRight"
            content={
              "Send an email requesting pre-mobilization and safety documents. "
            }
          >
            <InfoCircleOutlined />
          </Popover>
        </div>
      ),
      key: "docReq",
      width: "12%",
      render: (_, record) => (
        <div className={`flex flex-row items-center`}>
          <Tooltip
            title={
              "Request pre-mobilization and any missing documents from your Subcontractor."
            }
          >
            <Button
              type="text"
              size={"small"}
              onClick={(e) => {
                e.stopPropagation();
                setSubcontractorForDocReq(record.subcontractor_id);
                setSubAdminInvitationModalVisible(true);
              }}
              icon={<IconBrandTelegram />}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "SSSP",
      key: "sssp",
      width: "13%",
      render: (_, record) => (
        <div className={`flex flex-row items-center`}>
          <Tooltip title={"Download SSSP Pdf"}>
            <Button
              type="text"
              size={"small"}
              onClick={(e) => {
                e.stopPropagation();
                handleDownloadSsspClick(record.subcontractor_id);
              }}
              icon={<DownloadOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "SDS",
      children: [
        {
          title: "Project Library",
          dataIndex: ["subcontractor", "proj_sds", "aggregate", "count"],
          key: "numberSdsinProjectLibrary",
          width: "12%",
          sorter: (a, b) =>
            (a.subcontractor.proj_sds.aggregate?.count ?? 0) -
            (b.subcontractor.proj_sds.aggregate?.count ?? 0),
        },
        {
          title: "Company Library",
          dataIndex: ["subcontractor", "lib_sds", "aggregate", "count"],
          key: "numberSdsinCompanyLibrary",
          width: "12%",
          sorter: (a, b) =>
            (a.subcontractor.lib_sds.aggregate?.count ?? 0) -
            (b.subcontractor.lib_sds.aggregate?.count ?? 0),
        },
      ],
    },
    {
      title: "JHA",
      children: jhaColumns,
    },
    {
      title: "Safety Plan",
      children: [
        {
          title: "Project Library",
          dataIndex: ["subcontractor", "proj_sssp", "aggregate", "count"],
          key: "numberSsspinProjectLibrary",
          width: "12%",
          sorter: (a, b) =>
            (a.subcontractor.proj_sssp.aggregate?.count ?? 0) -
            (b.subcontractor.proj_sssp.aggregate?.count ?? 0),
        },
        {
          title: "Company Library",
          dataIndex: ["subcontractor", "lib_sssp", "aggregate", "count"],
          key: "numberSsspinCompanyLibrary",
          width: "12%",
          sorter: (a, b) =>
            (a.subcontractor.lib_sssp.aggregate?.count ?? 0) -
            (b.subcontractor.lib_sssp.aggregate?.count ?? 0),
        },
      ],
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      dataIndex: [],
      render: (_, row) => (
        <div onClick={(e) => e.stopPropagation()}>
          <SubTableActionPopover
            {...{ row, showsubadmininvite, onclicked, projectId }}
          />
        </div>
      ),
    },
  ];

  return dataSource ? (
    <div className="flex flex-col w-full">
      <SearchBar
        onSearchSubmit={(search) => {
          setSearch(search);
        }}
      />
      {subcontractorForInvite && (
        <GCSendInviteModal
          ref={sendInviteRef}
          projectId={projectId}
          subcontractorId={subcontractorForInvite}
        />
      )}
      {subcontractorForDocReq && (
        <GCProjectSubAdminInvitationModal
          visible={subAdminInvitationModalVisible}
          subcontractorId={subcontractorForDocReq}
          projectId={projectId}
          onCancel={() => {
            setSubAdminInvitationModalVisible(false);
          }}
          onConfirm={() => {
            setSubAdminInvitationModalVisible(false);
          }}
        />
      )}

      <Table
        rowKey="pk"
        rowClassName={() => "hover:cursor-pointer"}
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        title={() => (
          <div className="flex gap-1 text-1.5">
            <div>
              {"Subcontractors "}
              {`(${dataSource.length})`}
            </div>
            {search && <Icon icon={IconFilter} />}
          </div>
        )}
        bordered
        scroll={{ x: 1000, y: 60 * 10 }}
        onRow={(current) => ({
          onClick: () => {
            navigate(
              `/gce/projects/${projectId}/subcontractors/${current.subcontractor_id}`,
            );
          },
        })}
      />
    </div>
  ) : (
    <></>
  );
};

export default ProjectSubcontractorTableNew;
