import React from "react";
import NestedRoute from "src/common/types/manual/NestedRoute";

import { Navigate } from "react-router-dom";
import startingRoute from "./routes/starting/startingRoute";
import finishedRoute from "./routes/finished/finishedRoute";

const managerRoute: NestedRoute = {
  children: [
    {
      path: "starting",
      ...startingRoute,
    },
    {
      path: "finished",
      ...finishedRoute,
    },
    {
      path: "",
      element: <Navigate to={`starting`} replace />,
    },
  ],
};

export default managerRoute;
