import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import BlockOutDeliveryTimes from "src/root/routes/views/general-contractor/projects/settings/block-out-delivery-times/BlockOutDeliveryTimes";
import { GCProjectCalendarSettingsQuery } from "src/common/types/generated/relay/GCProjectCalendarSettingsQuery.graphql";
interface GCProjectCalendarSettingsProps {}
const query = graphql`
  query GCProjectCalendarSettingsQuery($projectId: uuid!) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          id
          is_sitedelivery_block_out_active
        }
      }
    }
  }
`;
const GCProjectCalendarSettings: React.FC<GCProjectCalendarSettingsProps> = (
  props,
) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error("ProjectId not found");
  const data = useLazyLoadQuery<GCProjectCalendarSettingsQuery>(query, {
    projectId,
  });
  const project = data.project_connection.edges[0];
  if (!project) throw new Error("Project not found for given projectId");
  return (
    <BasicWrapper scrollable>
      <BlockOutDeliveryTimes
        projectId={projectId!}
        featureOn={project.node.is_sitedelivery_block_out_active}
      />
    </BasicWrapper>
  );
};

export default GCProjectCalendarSettings;
