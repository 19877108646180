import { GetObservationOptionsQuery } from "src/common/types/generated/apollo/graphQLTypes";

const getGroupOptionsFromObservationOptionsData = (
  optionsData: GetObservationOptionsQuery,
  subcontractorId?: string,
) => {
  return [
    {
      id: "gc",
      label: "Team",
      options: optionsData.general_contractor_employee.map((gc) => ({
        key: gc.user.id,
        name: gc.user.name,
        label:
          gc.user.name +
          (gc.employee_title ? `, ${gc.employee_title.name.en}` : ""),
        value: gc.user.id,
      })),
    },
    {
      id: "workers",
      label: "Project Workers",
      options: optionsData.project_worker.map((projectWorker) => ({
        key: projectWorker.user!.id,
        name: projectWorker.user!.name,
        label: `${projectWorker.user!.name}, ${
          subcontractorId ? "" : `${projectWorker.subcontractor.name}`
        }${
          projectWorker.hard_hat_number
            ? `, ${projectWorker.hard_hat_number}`
            : ""
        }${
          projectWorker.title ? `, ${projectWorker.title.translation.en}` : ""
        }`,
        value: projectWorker.user!.id,
      })),
    },
  ];
};
export default getGroupOptionsFromObservationOptionsData;
