import { Descriptions, Space } from "antd";
import React, { FC } from "react";
import { GetProjectCovidConfigByPkQueryResult } from "src/common/types/generated/apollo/graphQLTypes";

const { Item } = Descriptions;

interface Props {
  projectCovidConfig: NonNullable<
    GetProjectCovidConfigByPkQueryResult["data"]
  >["project_covid_config_by_pk"];
}

const ProjectCovidDescriptions: FC<Props> = ({
  projectCovidConfig: config,
}) => {
  if (!config) return <></>;
  return (
    <Space
      direction="vertical"
      size="large"
      style={{ width: "100%", minWidth: 800 }}
    >
      <Descriptions size="middle" title="Project COVID-19 Settings" bordered>
        <Item label="Project">{config.project?.name}</Item>
        <Item label="Contact">
          {config.contact_project_employee?.employee.user.name}
        </Item>
        <Item label="Contact Email">
          {config.contact_project_employee?.employee.user.email}
        </Item>
        <Item label="Contact Phone">
          {config.contact_project_employee?.employee.user.phone_number}
        </Item>
        <Item label="Safety Restriction Length">
          {config.worker_restriction_length}
        </Item>
      </Descriptions>
      <h4>COVID-19 Requirements</h4>
      <p style={{ whiteSpace: "pre-line" }}>
        {/* {config.requirements?.clientText} */}
        {config.requirements_text}
      </p>
    </Space>
  );
};

export default ProjectCovidDescriptions;
