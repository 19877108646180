/**
 * @generated SignedSource<<bde73931b13aad101c096e60ce777e55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrientationProjectWorkerTable_project_worker$data = {
  readonly project_worker_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly archived_at: string | null | undefined;
        readonly archived_by_user: {
          readonly employee: {
            readonly employee_title: {
              readonly name: {
                readonly en: string;
              };
            } | null | undefined;
            readonly general_contractor: {
              readonly name: string;
            };
          } | null | undefined;
          readonly name: string;
        } | null | undefined;
        readonly created_at: string;
        readonly deleted_at: string | null | undefined;
        readonly hard_hat_number: string | null | undefined;
        readonly pk: string;
        readonly project: {
          readonly name: string;
        };
        readonly project_id: string;
        readonly project_workers?: ReadonlyArray<{
          readonly daily_work_log_workers: ReadonlyArray<{
            readonly id: string;
          }>;
          readonly permits: ReadonlyArray<{
            readonly id: string;
          }>;
          readonly report_injuries: ReadonlyArray<{
            readonly id: string;
          }>;
          readonly reports: ReadonlyArray<{
            readonly id: string;
          }>;
          readonly toolbox_talks: ReadonlyArray<{
            readonly id: string;
          }>;
        }>;
        readonly subcontractor: {
          readonly name: string;
        };
        readonly subcontractor_id: string;
        readonly title: {
          readonly translation: {
            readonly en: string;
          };
        } | null | undefined;
        readonly title_id: string | null | undefined;
        readonly user: {
          readonly email: string | null | undefined;
          readonly emergency_contact: string | null | undefined;
          readonly name: string;
          readonly phone_number: string | null | undefined;
          readonly pk: string;
          readonly role: string;
          readonly universal_orientations: ReadonlyArray<{
            readonly universal_orientated_at: string;
            readonly universal_orientation_results: ReadonlyArray<{
              readonly orientation_id: string;
              readonly quiz_results: ReadonlyArray<{
                readonly answer: string | null | undefined;
                readonly lang: string | null | undefined;
                readonly orientation_slide_id: string;
                readonly slide: {
                  readonly answer_items: string | null | undefined;
                  readonly answer_items_es: string | null | undefined;
                  readonly answer_items_fr: string | null | undefined;
                  readonly answer_items_it: string | null | undefined;
                  readonly answer_items_pt: string | null | undefined;
                  readonly answer_items_zh_hans: string | null | undefined;
                  readonly check_correct_answer: boolean;
                  readonly correct_answers: string | null | undefined;
                  readonly question_ui_element: string | null | undefined;
                  readonly title: string;
                };
              }>;
            }>;
          }>;
        } | null | undefined;
        readonly user_orientation: {
          readonly all_user_orientation_results: ReadonlyArray<{
            readonly completed_at: string | null | undefined;
            readonly orientation: {
              readonly name: string;
              readonly order: number;
              readonly type: string;
            };
            readonly orientation_id: string;
            readonly project: {
              readonly linked_orientation_projects: ReadonlyArray<{
                readonly pk: string;
              }>;
            } | null | undefined;
            readonly project_id: string | null | undefined;
            readonly quiz_results: ReadonlyArray<{
              readonly answer: string | null | undefined;
              readonly lang: string | null | undefined;
              readonly orientation_slide_id: string;
              readonly slide: {
                readonly answer_items: string | null | undefined;
                readonly answer_items_es: string | null | undefined;
                readonly answer_items_fr: string | null | undefined;
                readonly answer_items_it: string | null | undefined;
                readonly answer_items_pt: string | null | undefined;
                readonly answer_items_zh_hans: string | null | undefined;
                readonly check_correct_answer: boolean;
                readonly correct_answers: string | null | undefined;
                readonly question_ui_element: string | null | undefined;
                readonly title: string;
              };
            }>;
            readonly status: string;
          }>;
          readonly background_check_done: boolean | null | undefined;
          readonly background_check_done_by: {
            readonly name: string;
          } | null | undefined;
          readonly background_check_done_by_uid: string | null | undefined;
          readonly background_check_updated_at: string | null | undefined;
          readonly completed_at: string | null | undefined;
          readonly drug_test: {
            readonly drug_test_date: string;
            readonly status: string | null | undefined;
          } | null | undefined;
          readonly drug_test_id: string | null | undefined;
          readonly drug_test_updated_at: string | null | undefined;
          readonly in_person_orientated_at: string | null | undefined;
          readonly in_person_signature_id: string | null | undefined;
          readonly orientated_at: string | null | undefined;
          readonly orientation_provided_by_user_id: string | null | undefined;
          readonly signature_id: string | null | undefined;
        } | null | undefined;
        readonly worker: {
          readonly certificates_to_verify: ReadonlyArray<{
            readonly pk: string;
          }>;
          readonly subcontractor_id: string | null | undefined;
          readonly verified_at: string | null | undefined;
          readonly worker_certifications: ReadonlyArray<{
            readonly certification: {
              readonly name: string;
            };
            readonly expires_on: string | null | undefined;
            readonly pk: string;
          }>;
        };
        readonly worker_id: string;
        readonly worker_role: string;
      };
    }>;
  };
  readonly " $fragmentType": "OrientationProjectWorkerTable_project_worker";
};
export type OrientationProjectWorkerTable_project_worker$key = {
  readonly " $data"?: OrientationProjectWorkerTable_project_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrientationProjectWorkerTable_project_worker">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "project_worker_connection"
],
v1 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "en",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed_at",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orientation_id",
  "storageKey": null
},
v10 = [
  (v1/*: any*/)
],
v11 = {
  "order": "asc"
},
v12 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "order_by",
      "value": {
        "slide": (v11/*: any*/)
      }
    }
  ],
  "concreteType": "orientation_quiz_result",
  "kind": "LinkedField",
  "name": "quiz_results",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orientation_slide_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lang",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "orientation_slide",
      "kind": "LinkedField",
      "name": "slide",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "question_ui_element",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer_items",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer_items_es",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer_items_pt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer_items_it",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer_items_fr",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer_items_zh_hans",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "check_correct_answer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "correct_answers",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "quiz_results(order_by:{\"slide\":{\"order\":\"asc\"}})"
},
v13 = {
  "kind": "Literal",
  "name": "limit",
  "value": 1
},
v14 = {
  "created_at": "desc"
},
v15 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "_gte",
      "variableName": "startTime"
    }
  ],
  "kind": "ObjectValue",
  "name": "created_at"
},
v16 = [
  (v15/*: any*/)
],
v17 = [
  (v13/*: any*/),
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "report": (v14/*: any*/)
    }
  },
  {
    "fields": [
      {
        "fields": (v16/*: any*/),
        "kind": "ObjectValue",
        "name": "report"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v18 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v19 = {
  "_is_null": true
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeOnSite"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startTime"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./OrientationProjectWorkerTableRefetchableQuery.graphql')
    }
  },
  "name": "OrientationProjectWorkerTable_project_worker",
  "selections": [
    {
      "alias": "project_worker_connection",
      "args": [
        {
          "kind": "Variable",
          "name": "order_by",
          "variableName": "order_by"
        },
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "project_workerConnection",
      "kind": "LinkedField",
      "name": "__OrientationProjectWorkerTable_project_worker_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "project_workerEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "project_worker",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "worker_role",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title_id",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hard_hat_number",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "archived_at",
                  "storageKey": null
                },
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deleted_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "worker_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "project",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user",
                  "kind": "LinkedField",
                  "name": "archived_by_user",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "general_contractor_employee",
                      "kind": "LinkedField",
                      "name": "employee",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "general_contractor",
                          "kind": "LinkedField",
                          "name": "general_contractor",
                          "plural": false,
                          "selections": (v5/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "employee_title",
                          "kind": "LinkedField",
                          "name": "employee_title",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "text_translation",
                              "kind": "LinkedField",
                              "name": "name",
                              "plural": false,
                              "selections": (v6/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "subcontractor",
                  "kind": "LinkedField",
                  "name": "subcontractor",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "worker_title",
                  "kind": "LinkedField",
                  "name": "title",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "text_translation",
                      "kind": "LinkedField",
                      "name": "translation",
                      "plural": false,
                      "selections": (v6/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user_orientation",
                  "kind": "LinkedField",
                  "name": "user_orientation",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "orientated_at",
                      "storageKey": null
                    },
                    (v7/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "in_person_orientated_at",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "signature_id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "in_person_signature_id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "drug_test_updated_at",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "background_check_done",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "background_check_updated_at",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "background_check_done_by_uid",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "user",
                      "kind": "LinkedField",
                      "name": "background_check_done_by",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "orientation_provided_by_user_id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "drug_test_id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "worker_drug_test",
                      "kind": "LinkedField",
                      "name": "drug_test",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "drug_test_date",
                          "storageKey": null
                        },
                        (v8/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "order_by",
                          "value": [
                            {
                              "completed_at": "asc_nulls_last"
                            },
                            {
                              "created_at": "asc"
                            }
                          ]
                        },
                        {
                          "fields": [
                            {
                              "fields": [
                                {
                                  "fields": [
                                    {
                                      "fields": [
                                        {
                                          "fields": [
                                            {
                                              "kind": "Variable",
                                              "name": "_eq",
                                              "variableName": "projectId"
                                            }
                                          ],
                                          "kind": "ObjectValue",
                                          "name": "id"
                                        }
                                      ],
                                      "kind": "ObjectValue",
                                      "name": "projects"
                                    }
                                  ],
                                  "kind": "ObjectValue",
                                  "name": "general_contractor"
                                }
                              ],
                              "kind": "ObjectValue",
                              "name": "project"
                            }
                          ],
                          "kind": "ObjectValue",
                          "name": "where"
                        }
                      ],
                      "concreteType": "orientation_result",
                      "kind": "LinkedField",
                      "name": "all_user_orientation_results",
                      "plural": true,
                      "selections": [
                        (v7/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "orientation",
                          "kind": "LinkedField",
                          "name": "orientation",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "type",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "order",
                              "storageKey": null
                            },
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "project",
                          "kind": "LinkedField",
                          "name": "project",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "project",
                              "kind": "LinkedField",
                              "name": "linked_orientation_projects",
                              "plural": true,
                              "selections": (v10/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v12/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "condition": "includeOnSite",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "project_worker",
                      "kind": "LinkedField",
                      "name": "project_workers",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": (v17/*: any*/),
                          "concreteType": "report_project_worker",
                          "kind": "LinkedField",
                          "name": "reports",
                          "plural": true,
                          "selections": (v18/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": (v17/*: any*/),
                          "concreteType": "report_injury",
                          "kind": "LinkedField",
                          "name": "report_injuries",
                          "plural": true,
                          "selections": (v18/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": [
                            (v13/*: any*/),
                            {
                              "kind": "Literal",
                              "name": "order_by",
                              "value": {
                                "daily_work_log": {
                                  "date": "desc"
                                }
                              }
                            },
                            {
                              "fields": [
                                {
                                  "fields": [
                                    {
                                      "fields": [
                                        {
                                          "kind": "Variable",
                                          "name": "_gte",
                                          "variableName": "startDate"
                                        }
                                      ],
                                      "kind": "ObjectValue",
                                      "name": "date"
                                    }
                                  ],
                                  "kind": "ObjectValue",
                                  "name": "daily_work_log"
                                }
                              ],
                              "kind": "ObjectValue",
                              "name": "where"
                            }
                          ],
                          "concreteType": "daily_work_log_worker",
                          "kind": "LinkedField",
                          "name": "daily_work_log_workers",
                          "plural": true,
                          "selections": (v18/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": [
                            (v13/*: any*/),
                            {
                              "kind": "Literal",
                              "name": "order_by",
                              "value": {
                                "toolbox_talk": (v14/*: any*/)
                              }
                            },
                            {
                              "fields": [
                                {
                                  "fields": (v16/*: any*/),
                                  "kind": "ObjectValue",
                                  "name": "toolbox_talk"
                                }
                              ],
                              "kind": "ObjectValue",
                              "name": "where"
                            }
                          ],
                          "concreteType": "toolbox_talk_project_worker",
                          "kind": "LinkedField",
                          "name": "toolbox_talks",
                          "plural": true,
                          "selections": (v18/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": [
                            (v13/*: any*/),
                            {
                              "kind": "Literal",
                              "name": "order_by",
                              "value": {
                                "permit": (v14/*: any*/)
                              }
                            },
                            {
                              "fields": [
                                {
                                  "fields": [
                                    (v15/*: any*/),
                                    {
                                      "kind": "Literal",
                                      "name": "current_status",
                                      "value": {
                                        "_in": [
                                          "submitted",
                                          "resubmitted"
                                        ]
                                      }
                                    },
                                    {
                                      "kind": "Literal",
                                      "name": "is_historical",
                                      "value": {
                                        "_eq": true
                                      }
                                    },
                                    {
                                      "kind": "Literal",
                                      "name": "type",
                                      "value": {
                                        "_neq": "ladder"
                                      }
                                    }
                                  ],
                                  "kind": "ObjectValue",
                                  "name": "permit"
                                }
                              ],
                              "kind": "ObjectValue",
                              "name": "where"
                            }
                          ],
                          "concreteType": "permit_project_worker",
                          "kind": "LinkedField",
                          "name": "permits",
                          "plural": true,
                          "selections": (v18/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ]
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "phone_number",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "role",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "emergency_contact",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": [
                        (v13/*: any*/),
                        {
                          "kind": "Literal",
                          "name": "order_by",
                          "value": {
                            "universal_orientated_at": "desc"
                          }
                        },
                        {
                          "kind": "Literal",
                          "name": "where",
                          "value": {
                            "universal_orientated_at": {
                              "_is_null": false
                            }
                          }
                        }
                      ],
                      "concreteType": "user_universal_orientation",
                      "kind": "LinkedField",
                      "name": "universal_orientations",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "universal_orientated_at",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "order_by",
                              "value": [
                                {
                                  "orientation": (v11/*: any*/)
                                },
                                {
                                  "orientation": {
                                    "name": "asc"
                                  }
                                }
                              ]
                            },
                            {
                              "kind": "Literal",
                              "name": "where",
                              "value": {
                                "project_id": (v19/*: any*/),
                                "status": {
                                  "_eq": "completed"
                                }
                              }
                            }
                          ],
                          "concreteType": "orientation_result",
                          "kind": "LinkedField",
                          "name": "universal_orientation_results",
                          "plural": true,
                          "selections": [
                            (v9/*: any*/),
                            (v12/*: any*/)
                          ],
                          "storageKey": "universal_orientation_results(order_by:[{\"orientation\":{\"order\":\"asc\"}},{\"orientation\":{\"name\":\"asc\"}}],where:{\"project_id\":{\"_is_null\":true},\"status\":{\"_eq\":\"completed\"}})"
                        }
                      ],
                      "storageKey": "universal_orientations(limit:1,order_by:{\"universal_orientated_at\":\"desc\"},where:{\"universal_orientated_at\":{\"_is_null\":false}})"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "worker",
                  "kind": "LinkedField",
                  "name": "worker",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "verified_at",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "worker_certification",
                      "kind": "LinkedField",
                      "name": "worker_certifications",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "expires_on",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "certification",
                          "kind": "LinkedField",
                          "name": "certification",
                          "plural": false,
                          "selections": (v5/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "where",
                          "value": {
                            "document": {
                              "_eq": "certificate"
                            },
                            "status": {
                              "_eq": "submitted"
                            },
                            "verified_at": (v19/*: any*/)
                          }
                        }
                      ],
                      "concreteType": "certificates_to_verify",
                      "kind": "LinkedField",
                      "name": "certificates_to_verify",
                      "plural": true,
                      "selections": (v10/*: any*/),
                      "storageKey": "certificates_to_verify(where:{\"document\":{\"_eq\":\"certificate\"},\"status\":{\"_eq\":\"submitted\"},\"verified_at\":{\"_is_null\":true}})"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "476574d43b12b3e2601be6fd97cc5ab9";

export default node;
