import { ConfigProvider } from "antd";
import SiteFeatureLayoutWrapper from "./SiteFeatureLayoutWrapper";
import React from "react";

const withSiteFeatureNavigationWrapper = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const SiteFeatureNavigationWrapper: React.FC<P> = (props) => {
   console.log("First Wrapper");
   return  <SiteFeatureLayoutWrapper>
      <ConfigProvider componentSize="large">
        <WrappedComponent {...props} />
      </ConfigProvider>
    </SiteFeatureLayoutWrapper>
  };

  return SiteFeatureNavigationWrapper;
};

export default withSiteFeatureNavigationWrapper;
