import { useSuspenseQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import ReportsTabsGroup, {
  ReportsTabType,
} from "src/common/components/ReportsTabsGroup";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";

import {
  GetCsrProjectDataQueryVariables,
  GetCsrProjectDataDocument,
  GetCsrProjectDataQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import CsrProjectWorkers from "./CsrProjectWorkers";
import CsrProjectEmployees from "./CsrProjectEmployees";
import CsrProjectSubs from "./CsrProjectSubs";
import CsrProjectOrientations from "./CsrProjectOrientations";

const CsrProject: React.FunctionComponent<{
  projectId: string;
  subview: string;
}> = ({ projectId, subview }) => {
  const { data } = useSuspenseQuery<
    GetCsrProjectDataQuery,
    GetCsrProjectDataQueryVariables
  >(GetCsrProjectDataDocument, { variables: { projectId } });
  const tabs: ReportsTabType[] = [
    { id: "employees", label: "Employees" },
    { id: "workers", label: "Workers" },
    { id: "subs", label: "Subcontractors" },
    { id: "orientations", label: "Orientations" },
  ];
  const navigate = useNavigate();
  const project = data.project_by_pk;

  const handleReportsTabClick = (tabId: string) => {
    navigate("../" + tabId);
  };
  if (!project) throw new Error("Project not found");
  return (
    <div>
      <BasicWrapper>
        Project: {project.name}, {project.general_contractor.name}
        <ReportsTabsGroup
          onTabClick={handleReportsTabClick}
          tabs={tabs}
          tabsType="button"
          selectedTabId={subview}
        />
        <div className={`flex-grow bg-white overflow-auto py-1 w-full`}>
          <div className="flex w-full h-full">
            {subview === "subs" && (
              <CsrProjectSubs
                projectId={projectId}
                tradeOptions={data.subcontractor_trade.map((t) => ({
                  label: t.name,
                  value: t.id,
                }))}
              />
            )}
            {subview === "workers" && (
              <CsrProjectWorkers
                projectData={project}
                roleOptions={data.worker_role.map((t) => ({
                  label: t.translation.en,
                  value: t.value,
                }))}
                subTitles={data.subcontractor_employee_title.map((t) => ({
                  label: t.name.en,
                  value: t.id,
                }))}
                titleOptions={data.worker_title.map((t) => ({
                  label: t.translation.en,
                  value: t.id,
                }))}
              />
            )}
            {subview === "employees" && (
              <CsrProjectEmployees
                projectId={projectId}
                tradeOptions={data.employee_title.map((t) => ({
                  label: t.name.en,
                  value: t.id,
                }))}
              />
            )}
            {subview === "orientations" && data.project_by_pk && (
              <CsrProjectOrientations
                projectId={projectId}
                projectData={data.project_by_pk}
              />
            )}
          </div>
        </div>
      </BasicWrapper>
    </div>
  );
};
export default CsrProject;
