import React from "react";
import BaseTable, {
  BaseTableProps,
} from "src/common/components/tables/basic/BaseTable";
import { Subcontractor } from "src/common/types/generated/apollo/graphQLTypes";
import styled from "styled-components";

type Props = Omit<BaseTableProps<Partial<Subcontractor>>, "columns"> & {
  // subcontractors: GetSubcontractorsQuery['subcontractor'];
};

const SubcontractorsTable = styled(BaseTable).attrs(
  ({ pagination, ...props }: Props) => {
    const columns = [
      {
        title: "Subcontractor Name",
        key: "name",
        dataIndex: ["name"],
      },
      {
        title: "EIN (Employer Identification Number)",
        key: "ein",
        dataIndex: ["subcontractor_ein"],
      },
      {
        title: "Trade",
        key: "trade",
        dataIndex: ["trade", "name"],
      },
      {
        title: "Workers",
        key: "workers",
        dataIndex: ["workers_aggregate", "aggregate", "count"],
      },
      {
        title: "Projects",
        key: "projects",
        dataIndex: ["subcontractor_projects_aggregate", "aggregate", "count"],
      },
      {
        title: "Sub Admins",
        key: "subadmins",
        dataIndex: ["subcontractor_employees_aggregate", "aggregate", "count"],
      },
      {
        title: "Company JHAs",
        key: "tasks",
        dataIndex: ["tasks_aggregate", "aggregate", "count"],
      },
    ];

    return {
      ...props,
      columns,
      summary: () => (
        <tr>
          <th>Total</th>
          <td colSpan={columns.length - 1}>
            {!!pagination && pagination.total}
          </td>
        </tr>
      ),
      size: "middle",
    };
  }
)``;

export default SubcontractorsTable;
