import React from "react";
import BaseTable, {
  BaseTableProps,
} from "src/common/components/tables/basic/BaseTable";
import { Project } from "src/common/types/generated/apollo/graphQLTypes";
import styled, { StyledComponent } from "styled-components";

type Props = Omit<BaseTableProps<Partial<Project>>, "columns">;

type ProjectsTable = (p: Props) => JSX.Element;

/*
// MARK
export type StyledComponentProps<
    // The Component from whose props are derived
    C extends keyof JSX.IntrinsicElements | React.ComponentType<any>,
    // The Theme from the current context
    T extends object,
    // The other props added by the template
    O extends object,
    // The props that are made optional by .attrs
    A extends keyof any
> =
*/

const ProjectsTable: StyledComponent<ProjectsTable, any, Props, never> = styled(
  BaseTable,
).attrs(({ pagination, ...props }: Props) => {
  const columns = [
    {
      title: "Project Name",
      key: "name",
      dataIndex: ["name"],
    },
    {
      title: "General Contractor",
      key: "gc",
      dataIndex: ["general_contractors"],
      render: (value: Array<any>, record: any) => {
        return value.map((g) => g.general_contractor.name).join(", ");
      },
    },
    {
      title: "Subcontractors",
      key: "subcontractors",
      dataIndex: ["project_subcontractors_aggregate", "aggregate", "count"],
    },
    {
      title: "Workers",
      key: "workers",
      dataIndex: ["project_workers_aggregate", "aggregate", "count"],
    },
  ];

  return {
    ...props,
    columns,
    summary: () => (
      <tr>
        <th>Total</th>
        <td colSpan={columns.length - 1}>{!!pagination && pagination.total}</td>
      </tr>
    ),
    size: "middle",
  };
})``;

export default ProjectsTable;
