import { Button, Form, message, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import StyledContent from "src/common/components/layouts/StyledContent";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import {
  useCreateSubcontractorEmployeeMutation,
  useEmailSubAfterVerificationMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { VerifySubcontractorQuery } from "src/common/types/generated/relay/VerifySubcontractorQuery.graphql";
import { VerifySubcontractor_UpdateAndInsertMutation } from "src/common/types/generated/relay/VerifySubcontractor_UpdateAndInsertMutation.graphql";
import NewSubForm from "./components/NewSubForm";
import getNormalOptionsFilter from "src/common/functions/getNormalOptionsFilter";

interface VerifySubcontractorProps {
  workerId: string;
  loggedInUserId: string;
  certId: string;
}

const subRequestQuery = graphql`
  query VerifySubcontractorQuery($uid: uuid!) {
    add_subcontractor_request_connection(where: { id: { _eq: $uid } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          requested_by_user {
            name
          }
          requested_trade_id
          project_id
          project {
            name
            general_contractor_id
            general_contractor {
              name
            }
            address {
              city
              state {
                name
              }
            }
          }
          subcontractor_business_name
          subcontractor_ein
          subcontractor_trade {
            name
          }
          poc_name
          poc_email_address
          poc_phone_number
          poc_title_id
          subcontractor_employee_title {
            name_text
          }
        }
      }
    }

    project_subcontractor_connection(
      order_by: { subcontractor: { name: asc } }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          project_id
          subcontractor_id
          project {
            general_contractor_id
          }
          subcontractor {
            name
          }
        }
      }
    }
  }
`;

export const verifyMutation = graphql`
  mutation VerifySubcontractor_UpdateAndInsertMutation(
    $project_id: uuid!
    $subcontractor_id: uuid!
    $id: uuid!
    $_set: add_subcontractor_request_set_input
  ) {
    update_add_subcontractor_request_by_pk(
      pk_columns: { id: $id }
      _set: $_set
    ) {
      id
    }
    insert_project_subcontractor_one(
      object: { project_id: $project_id, subcontractor_id: $subcontractor_id }
      on_conflict: {
        constraint: project_subcontractor_subcontractor_id_project_id_key
        update_columns: []
      }
    ) {
      id
    }
  }
`;

const VerifySubcontractor: FC<VerifySubcontractorProps> = ({
  workerId,
  loggedInUserId,
  certId,
}) => {
  const subRequestQueryResult = useLazyLoadQuery<VerifySubcontractorQuery>(
    subRequestQuery,
    { uid: certId },
  );

  const queryData =
    subRequestQueryResult.add_subcontractor_request_connection.edges[0].node;

  const [emailVerification] = useEmailSubAfterVerificationMutation();
  const navigate = useNavigate();
  const [addToProject, isAdding] =
    useAsyncMutation<VerifySubcontractor_UpdateAndInsertMutation>(
      verifyMutation,
    );
  const [createSubEmployee, { loading: creatingSubEmployee }] =
    useCreateSubcontractorEmployeeMutation();

  const gcUserName = queryData.requested_by_user.name;
  const gcName = queryData.project.general_contractor.name;
  const gcId = queryData.project.general_contractor_id;
  const projectName = queryData.project.name;
  const projectId = queryData.project_id;
  const subcontractorName = queryData.subcontractor_business_name;
  const subcontractorTrade = queryData.subcontractor_trade.name;
  const subEin = queryData.subcontractor_ein;

  const [loading, setLoading] = useState(false);

  let sameProjectSubs = [];
  let sameGCSubs = [];
  let diffGCSubs = [];
  let prevSubs = new Set<string>();
  for (var currNode of subRequestQueryResult.project_subcontractor_connection
    .edges) {
    if (prevSubs.has(currNode.node.subcontractor_id)) {
      continue;
    }
    if (currNode.node.project_id === projectId) {
      sameProjectSubs.push(currNode.node);
      prevSubs.add(currNode.node.subcontractor_id);
    }
  }
  for (var currNode of subRequestQueryResult.project_subcontractor_connection
    .edges) {
    if (prevSubs.has(currNode.node.subcontractor_id)) {
      continue;
    }
    if (currNode.node.project.general_contractor_id == gcId) {
      sameGCSubs.push(currNode.node);
      prevSubs.add(currNode.node.subcontractor_id);
    }
  }
  for (var currNode of subRequestQueryResult.project_subcontractor_connection
    .edges) {
    if (prevSubs.has(currNode.node.subcontractor_id)) {
      continue;
    }
    diffGCSubs.push(currNode.node);
    prevSubs.add(currNode.node.subcontractor_id);
  }

  const onSuccess = async (subId: string, sameEmailPresent: boolean) => {
    setLoading(true);
    await addToProject({
      variables: {
        id: certId,
        project_id: projectId,
        subcontractor_id: subId,
        _set: {
          verified_at: dayjs().format(),
          verified_by_uid: auth.currentUser?.uid,
          subcontractor_id: subId,
        },
      },
    });
    if (!sameEmailPresent) {
      await createSubEmployee({
        variables: {
          input: {
            email: queryData.poc_email_address,
            employeeTitleId: queryData.poc_title_id,
            name: queryData.poc_name,
            subcontractorId: subId,
            phone: queryData.poc_phone_number,
            projectId: projectId,
          },
        },
      });
    }
    await emailVerification({
      variables: { input: { requestId: certId, isNewSub: true } },
    });
    setLoading(false);
  };
  const [formSubLinkedToGC] = Form.useForm();
  const [formSubNotLinkedToGC] = Form.useForm();
  const [formSubAlreadyAddedToProject] = Form.useForm();
  return (
    <StyledContent>
      <div className="container text-static-primary">
        <div className="row mx-auto w-10/12">
          <div className="row">
            <strong>{gcUserName}</strong> on <strong>{projectName}</strong> for{" "}
            <strong>{gcName}</strong> requested we add the following
            Subcontractor to SiteForm. We need to confirm if this company
            already exists. If they do not exist in our database we need to
            confirm if the name of their company is correct.
          </div>
          <div className="row pt-0.75 text-semantic-negative">
            * NOTE - YOU CAN ONLY TAKE ONE ACTION ON THIS PAGE
          </div>
          <div className="row pt-0.75 text-center text-1.5 font-normal">
            {subcontractorName}, {subcontractorTrade}, {subEin}
          </div>
        </div>

        <div className="flex flex-row content-center justify-center items-center pt-3">
          <div className="flex flex-col">
            <div>
              If the requested subcontractor is present in this list then ignore
              this request by clicking "
              <strong>Complete without verifying</strong>" at the bottom of the
              page.
            </div>
          </div>
          <div className="flex flex-col ml-2">
            <div className="text-center">
              List of subcontractors already added to this project
            </div>
            <div className="content-center justify-center items-center pt-1.5">
              <Form
                name="SubAlreadyAddedToProject"
                form={formSubAlreadyAddedToProject}
                title="Check Subcontractor Already Added To Project"
                layout="inline"
                onFinish={async () => {
                  const subId =
                    formSubAlreadyAddedToProject.getFieldValue(
                      "subAlreadyAdded",
                    );
                  try {
                    await addToProject({
                      variables: {
                        id: certId,
                        project_id: projectId,
                        subcontractor_id: subId,
                        _set: {
                          verified_at: dayjs().format(),
                          verified_by_uid: auth.currentUser?.uid,
                          subcontractor_id: subId,
                        },
                      },
                    });
                    message.success(`Sub Request Cleared`);
                    navigate(`/sfe/verify/subcontractors/`);
                    emailVerification({
                      variables: {
                        input: {
                          requestId: certId,
                          isNewSub: false,
                          subId: subId,
                        },
                      },
                    });
                  } catch (e) {
                    console.log(e);
                    message.error(
                      e instanceof Error ? e.message : JSON.stringify(e),
                    );
                  }
                }}
              >
                <div className="flex">
                  <div className="w-16">
                    <Form.Item
                      name="subAlreadyAdded"
                      rules={[
                        {
                          required: true,
                          message: "Suncontractor Name required",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder={
                          "Subcontractors already added to the current project"
                        }
                        filterOption={getNormalOptionsFilter}
                        options={sameProjectSubs.map((c) => {
                          return {
                            label: c.subcontractor.name,
                            value: c.subcontractor_id,
                          };
                        })}
                      />
                    </Form.Item>
                  </div>
                  <div className="w-12">
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isAdding}
                        className="w-12"
                      >
                        Sub is Already on Project
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>

        <div className="flex flex-col pt-3">
          <div className="flex">
            <div>
              Check 1 - Search this list to confirm the subcontractor is not
              already linked to the GC, if the Subcontractor is listed, select
              the company and select “<strong>Add to Project</strong>". If you
              cannot find the Subcontractor on this list then proceed to Check
              2.
            </div>
          </div>
          <div className="flex flex-row content-center justify-center items-center pt-1.5">
            <Form
              name="SubAlreadyLinkedToGCForm"
              form={formSubLinkedToGC}
              title="Add New Subcontractor when Sub already linked to GC"
              layout="inline"
              onFinish={async () => {
                const subId =
                  formSubLinkedToGC.getFieldValue("subLinkedToGCName");
                try {
                  await addToProject({
                    variables: {
                      id: certId,
                      project_id: projectId,
                      subcontractor_id: subId,
                      _set: {
                        verified_at: dayjs().format(),
                        verified_by_uid: auth.currentUser?.uid,
                        subcontractor_id: subId,
                      },
                    },
                  });
                  message.success(`Sub successfully added to the project`);
                  navigate(`/sfe/verify/subcontractors/`);
                  emailVerification({
                    variables: {
                      input: { requestId: certId, isNewSub: false },
                    },
                  });
                } catch (e) {
                  console.log(e);
                  message.error(
                    e instanceof Error ? e.message : JSON.stringify(e),
                  );
                }
              }}
            >
              <div className="w-20">
                <Form.Item
                  name="subLinkedToGCName"
                  rules={[
                    {
                      required: true,
                      message: "Suncontractor Name required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={"Subcontractors linked to GC"}
                    filterOption={getNormalOptionsFilter}
                    options={sameGCSubs.map((c) => {
                      return {
                        label: c.subcontractor.name,
                        value: c.subcontractor_id,
                      };
                    })}
                  />
                </Form.Item>
              </div>
              <Form.Item>
                <div className="w-16">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isAdding}
                    className="w-16"
                  >
                    Add to Project
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="flex flex-col pt-3">
          <div className="flex">
            <div>
              Check 2 - Search this list to confirm the subcontractor is not
              already in SiteForm. If the Subcontractor is listed, select the
              company and select "
              <strong>Add Subcontractor to GC and Project</strong>". If you
              cannot find the Subcontractor proceed to Check 3.
            </div>
          </div>
          <div className="flex flex-row content-center justify-center items-center pt-1.5">
            <Form
              name="SubNotAlreadyLinkedToGCForm"
              form={formSubNotLinkedToGC}
              title="Add New Subcontractor when Sub is not linked to GC"
              layout="inline"
              onFinish={async () => {
                const subId = formSubNotLinkedToGC.getFieldValue(
                  "subNotLinkedToGCName",
                );
                try {
                  await addToProject({
                    variables: {
                      id: certId,
                      project_id: projectId,
                      subcontractor_id: subId,
                      _set: {
                        verified_at: dayjs().format(),
                        verified_by_uid: auth.currentUser?.uid,
                        subcontractor_id: subId,
                      },
                    },
                  });
                  message.success(
                    `Sub successfully added to the project and linked to GC`,
                  );
                  navigate(`/sfe/verify/subcontractors/`);
                  emailVerification({
                    variables: { input: { requestId: certId, isNewSub: true } },
                  });
                } catch (e) {
                  console.log(e);
                  message.error(
                    e instanceof Error ? e.message : JSON.stringify(e),
                  );
                }
              }}
            >
              <div className="w-20">
                <Form.Item
                  name="subNotLinkedToGCName"
                  rules={[
                    {
                      required: true,
                      message: "Suncontractor Name required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={"All subcontractors"}
                    filterOption={getNormalOptionsFilter}
                    options={diffGCSubs.map((c) => ({
                      label: c.subcontractor.name,
                      value: c.subcontractor_id,
                    }))}
                  />
                </Form.Item>
              </div>
              <Form.Item>
                <div className="w-20">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isAdding}
                    className="w-20"
                  >
                    Add Subcontractor to GC and Project
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="flex flex-col pt-3">
          <div>
            Check 3 - The company name was provided. Search the internet and
            find the company’s website or something that confirms this is the
            correct business name. Confirm the Trade is correct. Use the domain
            of the email address below as well as the City and State to search
            google maps. When you confirm the name is correct, select "
            <strong>Create New Subcontractor</strong>". If you cannot find the
            information select “<strong>Complete without verifying</strong>”
            below.
          </div>
          <div className="flex flex-row justify-center mt-2">
            <div>
              <NewSubForm
                subRequestQueryResult={subRequestQueryResult}
                onSuccess={onSuccess}
              />
            </div>
          </div>
        </div>
        <div className="flex content-center justify-center items-center mt-4">
          <Button
            onClick={() => {
              navigate(`/sfe/verify/subcontractors/`);
            }}
            type="primary"
          >
            Complete without verifying
          </Button>
        </div>
      </div>
    </StyledContent>
  );
};
export default VerifySubcontractor;
