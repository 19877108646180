import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import IncidentSeverityMapping from "../../component/IncidentSeverityMapping";
import withOfficeId, { OfficeProps } from "../../utils/withOfficeId";

const GCOfficeIncident: React.FC<OfficeProps> = ({ officeId }) => {
  const { userData } = useUserData();
  return (
    <IncidentSeverityMapping
      severityUserFilter={{ office_id: { _eq: officeId } }}
      toInsertWith={{
        gc_id: userData.employee?.general_contractor_id,
        office_id: officeId,
      }}
      gcEmployeeWhere={{ gc_offices: { gc_office_id: { _eq: officeId } } }}
    />
  );
};
export default withOfficeId(GCOfficeIncident);
