import PdfModal from "src/common/components/modals/PdfModal";
import AddNewPlanDocModal from "./components/AddNewPlanDocModal";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { GCProjectSettingsOldQuery$data } from "src/common/types/generated/relay/GCProjectSettingsOldQuery.graphql";
import { LogisticPlan_DeleteDoc_Mutation } from "src/common/types/generated/relay/LogisticPlan_DeleteDoc_Mutation.graphql";
import { LogisticPlan_UpdateDoc_Mutation } from "src/common/types/generated/relay/LogisticPlan_UpdateDoc_Mutation.graphql";
import { MenuOutlined } from "@ant-design/icons";
import { Button, Checkbox } from "antd";
// import CustomButton from "src/common/components/general/Button";
import { useRef, useState } from "react";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";
import { getDeltaY } from "src/domain-features/sitesafety/entryRoutes/gcProjectReports/routes/permits/components/modal/WorkAboveElevationFloor";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { graphql } from "babel-plugin-relay/macro";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import { ConnectionHandler } from "relay-runtime";
type ListDragState = {
  id: string;
  from: number;
  to: number;
} | null;
type DocType =
  GCProjectSettingsOldQuery$data["project_connection"]["edges"][number]["node"]["logistic_plans"][number]["documents"][number];
const LogisticPlan: React.FC<{
  projectId: string;
  data: GCProjectSettingsOldQuery$data;
}> = ({ projectId, data }) => {
  const itemHeight = useRef(22);
  const [loading, handleLoading, setLoading] = useSelectedIndicies<string>();
  const [updateDoc] = useAsyncMutation<LogisticPlan_UpdateDoc_Mutation>(graphql`
    mutation LogisticPlan_UpdateDoc_Mutation($docId: uuid!, $newOrder: Int!) {
      update_document_by_pk(
        pk_columns: { id: $docId }
        _set: { sort_index: $newOrder }
      ) {
        ...DocumentFrag @relay(mask: false)
      }
    }
  `);
  const [deleteDoc, deleting] =
    useAsyncMutation<LogisticPlan_DeleteDoc_Mutation>(graphql`
      mutation LogisticPlan_DeleteDoc_Mutation($docId: uuid!) {
        delete_document_by_pk(id: $docId) {
          id
        }
      }
    `);
  const [dragState, setDragState] = useState<ListDragState>(null);
  const onOrderChange = async (
    from: number,
    to: number,
    newItems: DocType[],
  ) => {
    const prev_order = to > 0 ? newItems[to - 1].sort_index : 0;
    let p = to + 1;
    while (p < newItems.length && prev_order + p - to >= newItems[p].sort_index)
      p++;
    const gaps = p - to + 1;
    const last_order =
      p < newItems.length ? newItems[p].sort_index : prev_order + 100 * gaps;
    const queries: Array<Promise<any>> = [];
    const list = [];
    for (let i = to; i < p; i++) {
      const order =
        (prev_order + ((last_order - prev_order) * (i - to + 1)) / gaps) | 0;
      setLoading((p) => {
        const newSet = new Set(...p);
        newSet.add(newItems[i].pk);
        return newSet;
      });
      list.push([newItems[i], order]);
      console.log(list);
      queries.push(
        updateDoc({
          variables: { docId: newItems[i].pk, newOrder: order },
          optimisticResponse: {
            update_document_by_pk: {
              ...newItems[i],
              sort_index: order,
            },
          },
        }),
      );
    }
    await Promise.all(queries);
    setLoading(() => new Set());
  };
  const project = data.project_connection["edges"][0].node;
  const logisticPlan = project.logistic_plans[0];
  const documents = logisticPlan ? logisticPlan.documents : [];
  const moveItem = (from: number, to: number) => {
    if (from === to) return;
    const item = documents[from];
    const newItems = documents.filter((i) => i.pk !== item.pk);
    newItems.splice(to, 0, item);
    onOrderChange?.(from, to, newItems);
  };
  const onStart = (
    e: DraggableEvent,
    d: DraggableData,
    item: DocType,
    index: number,
  ) => {
    const rect = d.node.getBoundingClientRect();

    itemHeight.current = rect.height || rect.bottom - rect.top;
    setDragState({ id: item.pk, from: index, to: index });
  };

  const onDrag = (e: DraggableEvent, d: DraggableData, index: number) => {
    const newIndex = Math.min(
      (Math.max(d.y + index * itemHeight.current, 0) / itemHeight.current) | 0,
      documents.length - 1,
    );
    console.log(newIndex);

    if (
      dragState !== null &&
      (dragState.from !== index || dragState.to !== newIndex)
    ) {
      setDragState({ id: dragState.id, from: index, to: newIndex });
    }
  };
  const [planUpload, setPlanUpload] = useState(false);
  const [logisticPlanId, setLogisticPlanId] = useState<string>();
  const [pdfUrl, setPdfUrl] = useState<string>();
  const onStop = (e: DraggableEvent) => {
    console.log("Stopped", dragState);
    if (dragState !== null) {
      setDragState({ ...dragState, id: "" });
      setTimeout(() => {
        setDragState(null);
        moveItem(dragState.from, dragState.to);
      }, 50);
    }
  };
  const currentDocs =
    project.logistic_plans.length > 0
      ? [...project.logistic_plans[0].documents]
      : [];
  console.log(currentDocs.map((d) => [d.name, d.sort_index]));
  return (
    <>
      <AddNewPlanDocModal
        projectId={projectId}
        onCancel={() => {
          setPlanUpload(false);
          setLogisticPlanId(undefined);
        }}
        startSortIndex={
          project.logistic_plans.length == 0
            ? 0
            : project.logistic_plans[0].documents[
                project.logistic_plans[0].documents.length - 1
              ]?.sort_index || 0
        }
        planUpload={planUpload}
        logisticPlanId={logisticPlanId}
        onFinish={() => {
          setPlanUpload(false);
          setLogisticPlanId(undefined);
        }}
      />
      <p className="flex gap-1">
        <span className="text-2">SiteLogistics</span>{" "}
        {(project.logistic_plans.length === 0 ||
          project.logistic_plans[0].documents.length === 0) && (
          <Button
            type="primary"
            onClick={() => {
              setPlanUpload(true);
            }}
          >
            Add/Replace Plan
          </Button>
        )}
      </p>
      <PdfModal
        pdfUrl={pdfUrl}
        destroyOnClose
        visible={!!pdfUrl}
        onClose={() => {
          setPdfUrl(undefined);
        }}
      />
      {currentDocs.length > 0 && (
        <div className="mt-1">
          <Button
            type="primary"
            onClick={() => {
              setLogisticPlanId(project.logistic_plans[0].pk);
            }}
          >
            Add Additional Document
          </Button>
        </div>
      )}
      {currentDocs
        .sort((a, b) => a.sort_index - b.sort_index)
        .map((doc, index) => (
          <Draggable
            key={doc.id}
            position={{
              x: 0,
              y: getDeltaY(dragState, index, itemHeight.current),
            }}
            disabled={loading.has(doc.pk)}
            onStart={(e, d) => onStart(e, d, doc, index)}
            onDrag={(e, d) => onDrag(e, d, index)}
            onStop={onStop}
            handle=".anticon"
          >
            <div className="flex justify-between mt-1 ">
              <div className="flex w-4/6 border-px border-solid border-suplementary-3 px-0.25">
                <MenuOutlined className="flex mr-0.75 mt-0.75 " />
                <div className="mt-0.5">{doc.name || "No Name"}</div>
              </div>
              &nbsp;&nbsp;
              <div className="flex justify-center items-center">
                <Button onClick={() => setPdfUrl(doc.url)} type="primary">
                  View File
                </Button>
                {currentDocs.length > 1 && (
                  <BPopconfirm
                    title="Are you sure? This will permanently delete this file."
                    onConfirm={() =>
                      deleteDoc({
                        variables: { docId: doc.pk },
                        updater: (store) => {
                          const projConn = ConnectionHandler.getConnection(
                            store.getRoot(),
                            "GCProjectSettingsOldQuery_project_connection",
                          );

                          if (projConn) {
                            const edges =
                              projConn.getLinkedRecords("edges") || [];
                            const edge = edges[0];

                            if (!edge) return;
                            const node = edge.getLinkedRecord("node");
                            if (!node) return;
                            const logisticPlans =
                              node.getLinkedRecords("logistic_plans", {
                                order_by: { created_at: "desc" },
                                limit: 1,
                              }) || [];
                            const currentPlan = logisticPlans[0];
                            if (!currentPlan) return;
                            const currDocs =
                              currentPlan.getLinkedRecords("documents", {
                                order_by: { sort_index: "asc" },
                              }) || [];

                            currentPlan.setLinkedRecords(
                              [...currDocs].filter(
                                (cur) => cur.getDataID() !== doc.id,
                              ),
                              "documents",
                              {
                                order_by: { sort_index: "asc" },
                              },
                            );
                          }
                        },
                      })
                    }
                  >
                    <Button
                      type="primary"
                      loading={deleting}
                      className="bg-semantic-negative hover:bg-semantic-negative border-0 ml-1"
                    >
                      Delete File
                    </Button>
                  </BPopconfirm>
                )}
              </div>
            </div>
          </Draggable>
        ))}
    </>
  );
};
export default LogisticPlan;
