import {
  IconArrowsSort,
  IconChevronDown,
  IconSortAscending,
  IconSortDescending,
} from "@tabler/icons";
import { Popover } from "antd";
import React from "react";
import Icon from "src/common/components/general/Icon";
// import Popover from "src/common/views/general/Popover";

export type SortOrderType = "ascending" | "descending";
export type SortingTypeType = {
  id: string;
  title: string;
};

interface SortingSelectProps {
  onSortingTypeClick: (sortingType: string) => void;
  selectedSortingTypeId?: string;
  sortingTypes: SortingTypeType[];
}

const SortingSelect: React.FunctionComponent<SortingSelectProps> = ({
  selectedSortingTypeId,
  onSortingTypeClick,
  sortingTypes,
}) => {
  const sortingTypeList = sortingTypes.sort((a, b) =>
    !!a.title ? a.title.localeCompare(b.title) : 1,
  );
  return (
    <Popover
      placement="bottomLeft"
      content={
        <div className="flex flex-col gap-0.5">
          {sortingTypeList.map((sortingType) =>
            sortingType.id === selectedSortingTypeId ? null : (
              <span
                key={sortingType.id}
                className={`select-none hover:text-interactive-primary py-0.25 px-0.75 text-1 cursor-pointer hover:bg-white transition-all ${
                  sortingType.id === selectedSortingTypeId
                    ? "test-static-secondary"
                    : "cursor-pointer"
                }`}
                onClick={() => {
                  onSortingTypeClick(sortingType.id);
                }}
              >
                {sortingType.title}
              </span>
            ),
          )}
        </div>
      }
    >
      <div className="bg-suplementary-1 h-2 flex flex-row margin-gap-x-0.25 items-center px-0.75 rounded-l-1 cursor-pointer select-none">
        <Icon icon={IconChevronDown} color={"interactive"} />
        <div className="grid grid-cols-1 grid-rows-1">
          {sortingTypeList.map((sortingType) => (
            <span
              key={sortingType.id}
              className={`leading-fit truncate col-start-1 col-end-1 row-start-1 row-end-1 transition-opacity ${
                sortingType.id === selectedSortingTypeId
                  ? "opaicty-100"
                  : "opacity-0"
              }`}
            >
              {sortingType.title}
            </span>
          ))}
        </div>
      </div>
    </Popover>
  );
};

export interface SortingTypePickerProps {
  sortingTypes: SortingTypeType[];
  selectedSortingTypeId?: string;
  onSortingTypeClick: (sortingTypeId: string) => void;
  onOrderClick: (order: SortOrderType) => void;
  order: SortOrderType;
}

const SortingTypePicker: React.FunctionComponent<SortingTypePickerProps> = ({
  sortingTypes,
  selectedSortingTypeId,
  onSortingTypeClick,
  onOrderClick,
  order,
}) => {
  // console.log(`order: ${order.toString()}`);
  return (
    <div className="flex flex-row margin-gap-x-0.5 items-center">
      <span className="text-right text-static-secondary whitespace-nowrap">
        Sort by
      </span>

      <div className="flex flex-row margin-gap-x-px">
        <SortingSelect
          {...{ onSortingTypeClick, selectedSortingTypeId, sortingTypes }}
        />

        <div
          onClick={() => {
            if (order === "descending") {
              onOrderClick("ascending");
            }
            if (order === "ascending") {
              return onOrderClick("descending");
            }
            return order;
          }}
          className="bg-suplementary-1 h-2 flex flex-row margin-gap-x-0.25 items-center px-0.75 rounded-r-1 cursor-pointer border border-transparent transition-all hover:border-interactive-primary "
        >
          <Icon
            icon={
              order === "ascending"
                ? IconSortAscending
                : order === "descending"
                ? IconSortDescending
                : IconArrowsSort
            }
            color={"interactive"}
          />
        </div>
      </div>
    </div>
  );
};

export default SortingTypePicker;
