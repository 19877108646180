import { IconUserCircle } from "@tabler/icons";
import React from "react";
import Icon from "src/common/components/general/Icon";
import ProfileAvatar, {
  ProfileAvatarProps,
} from "src/root/routes/views/subcontractor/people/workers/worker-profile/ProfileAvatar";
import ContactLink from "./general/ContactLink";

interface BusinessCardProps {
  fullName: string;
  userName?: string;
  email?: string;
  hardHatNumber?: string | null;
  phoneNumber?: string;
  company?: string;
  position?: string;
  bio?: string;
  avatar?: ProfileAvatarProps;
}

const BusinessCard: React.FunctionComponent<BusinessCardProps> = (props) => {
  return (
    <div
      className={`flex flex-row p-4 gap-1 items-center justify-center min-w-48 rounded-2 bg-suplementary-1`}
    >
      {props.avatar && (
        <div className={`w-12 h-16 max-h-16 overflow-hidden rounded-2`}>
          <ProfileAvatar {...props.avatar} />
        </div>
      )}
      <div className={"flex flex-col gap-1"}>
        {props.userName && (
          <span className="inline-flex gap-0.5 items-center">
            <Icon icon={IconUserCircle} color={`static`} /> {props.userName}
          </span>
        )}
        <div className="flex items-end">
          <span className="text-3">
            {props.fullName}
            {props.hardHatNumber && (
              <span className="pb-3 text-2">
                &nbsp;&nbsp;&nbsp;#{props.hardHatNumber}
              </span>
            )}
          </span>
        </div>
        <div>
          {(props.company || props.position) && (
            <p className="inline-flex flex-row items-baseline gap-1">
              {props.position ? (
                <span className="text-1.5 font-accent">{props.position}</span>
              ) : (
                "Working "
              )}
              {props.company && (
                <>
                  at
                  <span className="text-1.5 font-accent">{props.company}</span>
                </>
              )}
            </p>
          )}
        </div>
        {props.bio && (
          <div>
            <p>{props.bio}</p>
          </div>
        )}

        <div className="flex flex-row items-center justify-start gap-1.5">
          {props.email && <ContactLink type="email" value={props.email} />}
          {props.phoneNumber && (
            <ContactLink type="phone-number" value={props.phoneNumber} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessCard;
