import React, { useEffect } from "react";
import { Button, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import NewSubAdminInput from "./GCProjectSubAdminInvitationModalNewSubAdminInput";

export interface NewSubAdminsProps {
  emailList: string[];
  onEmailListChange: (newEmailList: string[]) => void;
  existingSubAdminEmails: string[];
  visible: boolean;
}

const NewSubAdmins: React.FC<NewSubAdminsProps> = (props) => {
  const onEmailRemove = (emailToRemove: string) => {
    props.onEmailListChange(
      props.emailList.filter((emailItem) => emailItem !== emailToRemove)
    );
  };

  return (
    <div className={`flex flex-col gap-1`}>
      {!!props.emailList.length && (
        <div className={`flex flex-col`}>
          {props.emailList.map((emailItem) => (
            <div key={emailItem} className={`flex flex-row items-center`}>
              <div>{emailItem}</div>
              <Tooltip title={`Remove`}>
                <Button
                  onClick={() => onEmailRemove(emailItem)}
                  danger
                  shape={"circle"}
                  size={"small"}
                  type={"text"}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </div>
          ))}
        </div>
      )}
      <NewSubAdminInput
        visible={props.visible}
        onEmailAdd={(newEmail) => {
          props.onEmailListChange([...props.emailList, newEmail]);
        }}
        emailsToCompare={[...props.emailList, ...props.existingSubAdminEmails]}
      />
    </div>
  );
};

export default NewSubAdmins;