import React from "react";

export interface SiteOrientationSlidesViewerSlideTextProps {
  htmlText: string;
}

const SiteOrientationSlidesViewerSlideText: React.FC<
  SiteOrientationSlidesViewerSlideTextProps
> = (props) => {
  return (
    <div
      className="jodit w-full"
      dangerouslySetInnerHTML={{ __html: props.htmlText }}
    ></div>
  );
};

export default SiteOrientationSlidesViewerSlideText;
