/**
 * @generated SignedSource<<0e9ecc66a2c09a0fb8939d5babc5cae4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCCompleteCorporateEmailSelectModal_updat_project_Mutation$variables = {
  projectToSetFalse: ReadonlyArray<string>;
  projectToSetTrue: ReadonlyArray<string>;
};
export type GCCompleteCorporateEmailSelectModal_updat_project_Mutation$data = {
  readonly setFalse: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly send_corporate_video_completion_report: boolean;
    }>;
  } | null | undefined;
  readonly setTrue: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly send_corporate_video_completion_report: boolean;
    }>;
  } | null | undefined;
};
export type GCCompleteCorporateEmailSelectModal_updat_project_Mutation = {
  response: GCCompleteCorporateEmailSelectModal_updat_project_Mutation$data;
  variables: GCCompleteCorporateEmailSelectModal_updat_project_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectToSetFalse"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectToSetTrue"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "project",
    "kind": "LinkedField",
    "name": "returning",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "send_corporate_video_completion_report",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = [
  {
    "alias": "setTrue",
    "args": [
      {
        "kind": "Literal",
        "name": "_set",
        "value": {
          "send_corporate_video_completion_report": true
        }
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "projectToSetTrue"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_mutation_response",
    "kind": "LinkedField",
    "name": "update_project",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": "setFalse",
    "args": [
      {
        "kind": "Literal",
        "name": "_set",
        "value": {
          "send_corporate_video_completion_report": false
        }
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "projectToSetFalse"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_mutation_response",
    "kind": "LinkedField",
    "name": "update_project",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCCompleteCorporateEmailSelectModal_updat_project_Mutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCCompleteCorporateEmailSelectModal_updat_project_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "a6bd4cd4fb8eeb90fdc1e477e525c9d5",
    "id": null,
    "metadata": {},
    "name": "GCCompleteCorporateEmailSelectModal_updat_project_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCCompleteCorporateEmailSelectModal_updat_project_Mutation(\n  $projectToSetTrue: [uuid!]!\n  $projectToSetFalse: [uuid!]!\n) {\n  setTrue: update_project(where: {id: {_in: $projectToSetTrue}}, _set: {send_corporate_video_completion_report: true}) {\n    returning {\n      id\n      send_corporate_video_completion_report\n    }\n  }\n  setFalse: update_project(where: {id: {_in: $projectToSetFalse}}, _set: {send_corporate_video_completion_report: false}) {\n    returning {\n      id\n      send_corporate_video_completion_report\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c3d277f3e86f6848b0264b46a6609010";

export default node;
