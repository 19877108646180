import React, { useRef } from "react";
import { Modal } from "antd";
import GCAddNewSubModal, {
  GCAddNewSubModalRef,
} from "src/root/routes/views/general-contractor/projects/subcontractors/sub-info/GCAddNewSubModal";
import { useParams } from "react-router-dom";

interface SelectSubcontractorModalValues {
  subcontractorIds: Array<string>;
}

interface ConfirmAddSubcontractorModalProps {
  // lets decouple interal logic of modal from outside loigc
  // name of event should indetify action that happens inside of modal
  // and should not depend on possible logic that parent component may do
  // it may refetch data or may do something else...
  // we should give them freedom to decide...
  onSubcontractorInserted?: () => void;
  modalClose: () => void;
  modalVisible: boolean;
  onSubmit: () => void;
}

const ConfirmAddSubcontractorModal: React.FC<
  ConfirmAddSubcontractorModalProps
> = ({
  onSubcontractorInserted,
  modalClose,
  modalVisible,
  onSubmit,
}) => {
  const { projectId } = useParams();
  const addNewSub = useRef<GCAddNewSubModalRef>(null);

  return (<>
    <Modal
      open={modalVisible}
      title="Add a New Subcontractor or Trade Partner to SiteForm"
      okText="Continue"
      cancelText="Cancel"
      cancelButtonProps={{ style: { display: "inline" } }}
      onCancel={modalClose}
      onOk={() => {
        modalClose();
        addNewSub.current?.open();
      }}
    >
      <p>Add selected company(s) and create a New Company?</p>
    </Modal>
    <GCAddNewSubModal
      ref={addNewSub}
      projectId={projectId}
      onSubcontractorInserted={onSubcontractorInserted}
      onSubmit={onSubmit}
    />
  </>);
};

export default ConfirmAddSubcontractorModal;
