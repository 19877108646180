import { IconX } from "@tabler/icons";
import clsx from "clsx";
import React, { PropsWithChildren } from "react";
import useMeasure from "react-use-measure";
import Button from "src/common/components/general/Button";
import CustomSuspense from "src/common/components/general/CustomSuspense";

export interface GCProjectReportsDailyCollapseProps {
  head: React.ReactElement;
  hide: () => void;
  expand: () => void;
  expanded: boolean;
}

const GCProjectReportsDailyCollapse: React.FC<
  PropsWithChildren<GCProjectReportsDailyCollapseProps>
> = (props) => {
  const [bodyRef, bounds] = useMeasure();

  return (
    <div
      className={clsx(
        `rounded-1 border transition-colors p-1`,
        !!props.children
          ? clsx(
              "border-interactive-secondary",
              !props.expanded
                ? "hover:border-interactive-primary cursor-pointer"
                : "shadow",
            )
          : clsx("border-transparent bg-suplementary-1"),
      )}
      onClick={
        !!props.children
          ? () => {
              if (!props.expanded) {
                props.expand();
              }
            }
          : undefined
      }
    >
      <div
        className={clsx(
          "flex flex-col transition-all",
          props.expanded ? "gap-1" : "gap-0",
        )}
      >
        <div className="flex flex-row justify-between gap-1">
          {props.head}
          <div
            className={clsx(
              props.expanded ? "opacity-100" : "opacity-0 pointer-events-none",
              "transition-opacity",
            )}
          >
            <Button icon={IconX} small onClick={props.hide} />
          </div>
        </div>
        <div
          style={{ height: bounds.height }}
          className={`transition-all overflow-hidden`}
        >
          {props.expanded && props.children && (
            <div ref={bodyRef} className="w-full">
              <CustomSuspense>{props.children}</CustomSuspense>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GCProjectReportsDailyCollapse;
