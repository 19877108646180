import React, { createContext } from "react";
import { GCProjectReportsDailyBodyQuery$data } from "src/common/types/generated/relay/GCProjectReportsDailyBodyQuery.graphql";

export type GCDailyWorkLogContextType = {
  projectId: string;
  data: GCProjectReportsDailyBodyQuery$data;
} | null;

const GCProjectDailyWorkLogContext =
  createContext<GCDailyWorkLogContextType>(null);

export default GCProjectDailyWorkLogContext;
