import React, { FC, useState } from "react";
import CustomSignatureCanvas, {
  CustomSignatureCanvasProps,
} from "../../../../common/components/general/signature-canvas/CustomSignatureCanvas";
import { Button } from "antd";
import useLangStrings from "../../../../utility-features/i18n/context/languageHooks";

interface SiteOrientationSignatureUIProps {
  userName?: string;
  lang?: string;
  disclaimerIsProject?: boolean;
  signaturePadProps: CustomSignatureCanvasProps;
  isSignaturePadEmpty: boolean;
  nextButton?: React.ReactNode;
  onSign?: (imageUrl: string) => Promise<void> | void;
  uploadSignature?: () => Promise<string | null>;
}

const SiteOrientationSignatureUI: FC<SiteOrientationSignatureUIProps> = (
  props,
) => {
  const [loading, setLoading] = useState(false);
  const langStrings = useLangStrings(props.lang);

  return (
    <div className="flex flex-col gap-1">
      <p className="text-1.5">
        {langStrings.strings.signBelowToCompleteOrientation(
          props.userName ?? "",
        )}
      </p>
      <div className="flex flex-col gap-0.5">
        <CustomSignatureCanvas {...props.signaturePadProps} />
      </div>
      {!!props.onSign && !!props.uploadSignature && (
        <Button
          block
          disabled={props.isSignaturePadEmpty}
          loading={loading}
          size="large"
          type={`primary`}
          onClick={async () => {
            setLoading(true);
            try {
              const imageUrl = await props.uploadSignature?.();
              if (imageUrl && props.onSign) {
                await props.onSign(imageUrl);
              }
              setLoading(false);
            } catch (err) {
              setLoading(false);
            }
          }}
        >
          {langStrings.strings.save}
        </Button>
      )}
      <p className="text-0.75 text-static-secondary">
        {langStrings.strings.orientationSignatureDisclaimer(
          !!props.disclaimerIsProject,
        )}{" "}
        {langStrings.strings.andYouAgreeToSiteForm}{" "}
        <span className="text-interactive-primary">
          <a
            target="_blank"
            href="/https://www.termsfeed.com/privacy-policy/4797f669d31b548f81ec1bfb308928a1"
          >
            {langStrings.strings.privacyPolicy}
          </a>
        </span>{" "}
        {langStrings.strings.and}{" "}
        <span className="text-interactive-primary">
          <a
            target="blank"
            href="/https://www.termsfeed.com/terms-conditions/f73fc5afe6a821990e96daebc0b83d39"
          >
            {langStrings.strings.termsOfService}
          </a>
        </span>
      </p>
    </div>
  );
};

export default SiteOrientationSignatureUI;
