import React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import ReportsTabsGroup, {
  ReportsTabType,
} from "src/common/components/ReportsTabsGroup";

interface GCProjectPartnersProps {}

const GCProjectPartners: React.FunctionComponent<
  GCProjectPartnersProps
> = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  if (typeof projectId !== "string") {
    return <h1> Wrong url </h1>;
  }

  const tabs: ReportsTabType[] = [
    {
      id: "table",
      label: "Workers",
    },
    {
      id: "overview",
      label: "Overview",
    },
  ];

  return (
    <BasicWrapper>
      <div className="flex flex-col w-full h-full">
        <ReportsTabsGroup
          tabs={tabs}
          onTabClick={(id) => {
            navigate(id);
          }}
          tabsType={"link"}
        />
        <div className="flex w-full h-full">
          <Outlet />
        </div>
      </div>
    </BasicWrapper>
  );
};

export default GCProjectPartners;
