import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useUpsertAndDeletePreviousSubMutation } from "src/common/types/generated/relay/useUpsertAndDeletePreviousSubMutation.graphql";

const useUpsertAndDeletePreviousSub = () => {
  const [upsert] = useAsyncMutation<useUpsertAndDeletePreviousSubMutation>(
    graphql`
      mutation useUpsertAndDeletePreviousSubMutation(
        $objects: [worker_previous_subcontractor_insert_input!]!
        $deleteWhere: worker_previous_subcontractor_bool_exp!
      ) {
        delete_worker_previous_subcontractor(where: $deleteWhere) {
          affected_rows
        }
        insert_worker_previous_subcontractor(
          objects: $objects
          on_conflict: {
            constraint: worker_previous_subcontractor_subcontractor_id_worker_id_key
            update_columns: []
          }
        ) {
          affected_rows
        }
      }
    `
  );
  return upsert;
};
export default useUpsertAndDeletePreviousSub;
