import { useEmailFlaggedJhatoUnflagMutation } from "src/common/types/generated/apollo/graphQLTypes";
import * as React from "react";
import { message } from "antd";

interface TaskRequestToRemoveFlagProps {
  taskId: string;
}
const TaskRequestToRemoveFlag = (props: TaskRequestToRemoveFlagProps) => {
  const [unflagJHA] = useEmailFlaggedJhatoUnflagMutation();

  return (
    <div
      className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
      onClick={async () => {
        try {
          message.loading({
            content: "Request to Remove Flag in progress",
            duration: 0,
          });
          await unflagJHA({
            variables: { input: { taskId: props.taskId } },
          });
          message.destroy();
          message.success("Email Sent");
        } catch (e) {
          if (e instanceof Error) {
            message.error(e.message);
          }
        }
      }}
    >
      Request to Remove Flag
    </div>
  );
};

export default TaskRequestToRemoveFlag;
