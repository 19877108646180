import { message } from "antd";
import { NoticeType } from "antd/es/message/interface";
// Utility method

interface ExecuteWithMessageProps<T> {
  type: NoticeType;
  initialContent: string;
  successContent: string;
  asyncFunction: () => Promise<T>;
  successMessageDuration?: number;
  errorMessageDuration?: number;
}
export const executeWithMessage = async ({
  type,
  initialContent,
  successContent,
  asyncFunction,
  successMessageDuration = 2,
  errorMessageDuration = 5,
}: ExecuteWithMessageProps<any>) => {
  const key = "asyncLoadingMessage";

  message.open({
    key,
    type,
    content: initialContent,
    duration: 0,
  });

  try {
    await asyncFunction();

    message.open({
      key,
      type: "success",
      content: successContent,
      duration: successMessageDuration,
    });
  } catch (e) {
    let errorMessage = "Unexpected error occurred";
    if (e instanceof Error) {
      errorMessage = e.message;
    } else if (typeof e === "string") {
      errorMessage = e;
    }

    message.open({
      key,
      type: "error",
      content: errorMessage,
      duration: errorMessageDuration,
    });
  }
};
