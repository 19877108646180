import { TablerIcon } from "@tabler/icons";
import React from "react";
import Icon from "./Icon";

interface ReportsTabProps {
  label: string;
  onClick: () => void;
  isActive: boolean;
  icon?: TablerIcon;
}

const ReportsTab: React.FunctionComponent<ReportsTabProps> = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={`inline-flex rounded-tl-2 rounded-tr-0.5 px-2 py-0.5 font-accent border-b-0 select-none border transition-none ${
        !props.isActive
          ? "bg-interactive-primary border-transparent text-white cursor-pointer hover:text-white hover:pb-0.75 hover:-mt-0.75   transition-all"
          : "bg-white border-suplementary-3 text-static-primary hover:text-static-primary"
      }`}
    >
      <span>{props.label}</span>
      {props.icon && (
        <Icon icon={props.icon} color={!props.isActive ? "white" : undefined} />
      )}
    </div>
  );
};

export default ReportsTab;
