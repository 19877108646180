/**
 * @generated SignedSource<<fa7a644f32178dc26497c1bb4930eefa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GCAddNewSubModal_sub_Query$variables = Record<PropertyKey, never>;
export type GCAddNewSubModal_sub_Query$data = {
  readonly subcontractor_employee_title_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name_text: string;
        readonly pk: string;
      };
    }>;
  };
  readonly subcontractor_trade_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly pk: string;
      };
    }>;
  };
};
export type GCAddNewSubModal_sub_Query = {
  response: GCAddNewSubModal_sub_Query$data;
  variables: GCAddNewSubModal_sub_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "where",
  "value": {}
},
v1 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "name": "asc"
    }
  },
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "name_text": "asc"
    }
  },
  (v0/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name_text",
  "storageKey": null
},
v6 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCAddNewSubModal_sub_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "subcontractor_tradeConnection",
        "kind": "LinkedField",
        "name": "subcontractor_trade_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractor_tradeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor_trade",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "subcontractor_trade_connection(order_by:{\"name\":\"asc\"},where:{})"
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "subcontractor_employee_titleConnection",
        "kind": "LinkedField",
        "name": "subcontractor_employee_title_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractor_employee_titleEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor_employee_title",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "subcontractor_employee_title_connection(order_by:{\"name_text\":\"asc\"},where:{})"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GCAddNewSubModal_sub_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "subcontractor_tradeConnection",
        "kind": "LinkedField",
        "name": "subcontractor_trade_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractor_tradeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor_trade",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "subcontractor_trade_connection(order_by:{\"name\":\"asc\"},where:{})"
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "subcontractor_employee_titleConnection",
        "kind": "LinkedField",
        "name": "subcontractor_employee_title_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractor_employee_titleEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor_employee_title",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "subcontractor_employee_title_connection(order_by:{\"name_text\":\"asc\"},where:{})"
      }
    ]
  },
  "params": {
    "cacheID": "913ebd71afd57d20c859e665bdcf9d31",
    "id": null,
    "metadata": {},
    "name": "GCAddNewSubModal_sub_Query",
    "operationKind": "query",
    "text": "query GCAddNewSubModal_sub_Query {\n  subcontractor_trade_connection(order_by: {name: asc}, where: {}) {\n    edges {\n      node {\n        name\n        pk: id\n        id\n      }\n    }\n  }\n  subcontractor_employee_title_connection(order_by: {name_text: asc}, where: {}) {\n    edges {\n      node {\n        name_text\n        pk: id\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6fc6464d31a81f15d2102fdaf78207fa";

export default node;
