import React from "react";
import SiteOrientationGcQrCode from "./SiteOrientationGcQrCode";
import * as uuid from "uuid";
import { message } from "antd";
import { auth } from "../../../../../../../../common/functions/firebase";
import useInsertSecretCode from "../../utils/useInsertSecretCode";
import buildSecretCodeUpdater from "../../utils/buildSecretCodeUpdater";

export interface SiteOrientationGcQrCodeInPersonExceptionProps {
  destinationUrl?: string;
  projectId: string;
}

const SiteOrientationGcQrCodeInPersonException: React.FC<
  SiteOrientationGcQrCodeInPersonExceptionProps
> = ({ destinationUrl, projectId }) => {
  const userId = auth.currentUser?.uid;
  if (!userId) {
    throw new Error(`Orientation Settings user is not signed in`);
  }
  const insertSecretCode = useInsertSecretCode();

  const generateQrCodeUrl = async () => {
    const finalUrl = `/gce/orientation/${projectId}?completionType=inpersonComplete`;
    const id = uuid.v4();

    await insertSecretCode({
      variables: {
        projectId,
        userId,
        id,
        only_project_data: false,
        finalUrl,
      },
      updater: buildSecretCodeUpdater(
        "GCProjectOrientation_complete_orientation_in_person_code",
      ),
    }).catch((err) => {
      message.error("Error: " + err);
    });
  };
  return (
    <SiteOrientationGcQrCode
      title={`The Exception QR code for an In-Person Orientation
(Keep this QR code PRIVATE)`}
      body={
        <ul className="p-1 ml-1 list-disc text-static-secondary">
          <li>
            If workers normally complete all or some of your orientation slides
            with the first QR code (Registration & Orientation), but today you
            need to play the video or PowerPoint presentation in person, use
            this QR code.
          </li>
          <li>
            Similar to the 2nd QR code, this QR code starts an in-person
            orientation. New workers can register and sign. This completes the
            full orientation in person and workers will not watch the slides or
            video on SiteForm.
          </li>
          <li>
            Only use this QR code if you are showing the Slides and/or Video in
            person.
          </li>
          <li>
            Example: normally all of your workers are required to complete
            orientation by scanning the first QR code. But 25 concrete finishers
            just showed up and need to get to work ASAP. This QR code will
            bypass your slides and video so you can deliver the orientation in
            person. Each worker registers and receives credit for completing
            your full orientation.
          </li>
        </ul>
      }
      qrCode={{
        destinationUrl,
        generateQrCodeUrl: generateQrCodeUrl,
      }}
    />
  );
};

export default SiteOrientationGcQrCodeInPersonException;
