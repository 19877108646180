export const titleCase = (
  str: string = '',
  separator: string = ' ',
): string => {
  return str
    .toString()
    .split(separator)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
