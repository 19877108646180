import { graphql } from "babel-plugin-relay/macro";

const insertTaskApprovalMutation = graphql`
  mutation InsertTaskApprovalMutation(
    $taskSignObjects: [task_signature_insert_input!]!
    $taskEditObjects: [task_edit_insert_input!]!
  ) {
    insert_task_signature(objects: $taskSignObjects) {
      returning {
        id
        created_at
        pk: id @__clientField(handle: "pk")
        signature_image_id
        image {
          url
        }
        user_id
        user {
          name
          role
          subcontractor_employee {
            subcontractor {
              name
            }
            employee_title {
              name {
                en
              }
            }
          }
          worker {
            current_worker_role
          }
        }
        email_request_id
        email_request {
          email
          user_id
        }
      }
    }

    insert_task_edit(objects: $taskEditObjects) {
      returning {
        task_patch
        edited_at
        edit_type
        edited_by_user {
          name
          role
          employee {
            general_contractor {
              name
            }
            employee_title {
              name {
                en
              }
            }
          }
          subcontractor_employee {
            subcontractor {
              name
            }
            employee_title {
              name {
                en
              }
            }
          }
          worker {
            current_worker_role
            subcontractor {
              name
            }
          }
        }
      }
    }
  }
`;

export default insertTaskApprovalMutation;
