import { AntCloudOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Select,
  Form,
  Modal,
  Upload,
  Checkbox,
  Radio,
  notification,
  message,
  Input,
} from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useState } from "react";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { SdsTable_sds$data } from "src/common/types/generated/relay/SdsTable_sds.graphql";
import { SelectProjectForSdsModal_InsertSdsMutation, safety_data_sheet_insert_input } from "src/common/types/generated/relay/SelectProjectForSdsModal_InsertSdsMutation.graphql";
import * as uuid from "uuid";

interface SelectProjectForSdsModalProps {
  subcontractorId: string;
  onClose: () => void;
  onSubmit: () => void;
  visible: boolean;
  data: Array<{ id: string; name: string }>;
  sds?: SdsTable_sds$data["safety_data_sheet_connection"]["edges"][number]["node"];
}

type FormValues = {
  projectName: string;
};
const SelectProjectForSdsModal: FC<SelectProjectForSdsModalProps> = ({
  onClose,
  onSubmit,
  visible,
  data,
  subcontractorId,
  sds,
}) => {
  const [insertSds, loading] =
    useAsyncMutation<SelectProjectForSdsModal_InsertSdsMutation>(
      graphql`
        mutation SelectProjectForSdsModal_InsertSdsMutation(
          $object: safety_data_sheet_insert_input!
        ) {
          insert_safety_data_sheet_one(object: $object) {
            ...SdsFrag @relay(mask: false)
          }
        }
      `,
    );
  const [form] = Form.useForm();
  const addToProject = async (projectId: string) => {
    if (!sds) {
      return;
    }
    const sdsInsertInput: safety_data_sheet_insert_input = {
      created_by_uid: auth.currentUser?.uid,
      project_id: projectId,
      subcontractor_id: subcontractorId,
      company_name: sds.company_name,
      product_name: sds.product_name,
      url: sds.url,
      reference_number: sds.reference_number,
      issue_date: sds.issue_date,
      file_type: sds.file_type,
      id: uuid.v4(),
      company_safety_data_sheet_id: sds.pk,
    };
    await insertSds({
      variables: {
        object: { ...sdsInsertInput },
      },
    });
    message.success("Added to Project Library");
      
  };
  // const data = useLazyLoadQuery<SelectProjectForSdsModalQuery>(query, {
  //   subcontractorId: subcontractorId,
  // });
  // const projectList = data.project_subcontractor_connection.edges.map((itr) => {
  //   return { id: itr.node.project.pk, name: itr.node.project.name };
  // });

  return (
    <Modal
      open={visible}
      confirmLoading={loading}
      title={
        <div>
          Select Project Name
          <br />
        </div>
      }
      okText="ADD"
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={async () => {
        await form
          .validateFields()
          .then((v: FormValues) => {
            console.log(v);
            form.resetFields();
            addToProject(v.projectName);
            onSubmit();
          })
          .catch((e) => {
            console.log(e);
            message.error("Failed to fetch projectS");
          });
      }}
    >
      <Form form={form} layout="vertical" title="Upload Project">
        <Form.Item
          className="mt-2"
          label="Project Name"
          name="projectName"
          rules={[{ required: true, message: "Project name required" }]}
        >
          <Select placeholder="Select Project Name">
            {data.map((i) => (
              <Select.Option value={i.id} key={i.id}>
                {i.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default SelectProjectForSdsModal;
