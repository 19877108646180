import { message, notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs, { Dayjs } from "dayjs";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import {
  useInsertDTResult_InsertDrugTestMutation,
  worker_drug_test_insert_input,
  image_insert_input,
} from "src/common/types/generated/relay/useInsertDTResult_InsertDrugTestMutation.graphql";
import {
  useInsertDTResultMutation,
  user_orientation_insert_input,
} from "src/common/types/generated/relay/useInsertDTResultMutation.graphql";
import { DrugtestStatusType } from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import { useState } from "react";

const insertDrugtestMutation = graphql`
  mutation useInsertDTResult_InsertDrugTestMutation(
    $objects: [worker_drug_test_insert_input!]!
  ) {
    insert_worker_drug_test(
      objects: $objects
      on_conflict: {
        constraint: worker_drug_test_worker_id_name_drug_test_date_key
        update_columns: []
      }
    ) {
      returning {
        pk: id @__clientField(handle: "pk")
        worker_id
      }
    }
  }
`;
export const upsertUserOrientationDrugtestMutation = graphql`
  mutation useInsertDTResultMutation(
    $objects: [user_orientation_insert_input!]!
  ) {
    insert_user_orientation(
      objects: $objects
      on_conflict: {
        constraint: user_orientation_user_id_project_id_key
        update_columns: [
          drug_test_id
          drug_test_updated_at
          drug_test_updated_by_user_id
        ]
      }
    ) {
      returning {
        id @__clientField(handle: "pk")
        project_id
        user_id
        drug_test_id
        drug_test_updated_at
        drug_test_updated_by_user_id
      }
    }
  }
`;
export const useAcceptPrevDT = () => {
  const [saving, setSaving] = useState(false);
  const [upsertDTUserOrientation] = useAsyncMutation<useInsertDTResultMutation>(
    upsertUserOrientationDrugtestMutation,
  );
  const acceptPrevDT = async (
    projectIdsToInsert: string[],
    workerId: string,
    drugtestId: string,
  ) => {
    setSaving(true);
    try {
      await upsertDTUserOrientation({
        variables: {
          objects: projectIdsToInsert.map((projectId) => ({
            user_id: workerId,
            project_id: projectId,
            drug_test_id: drugtestId,
            drug_test_updated_at: dayjs().format(),
            drug_test_updated_by_user_id: auth.currentUser?.uid,
          })),
        },
      });
      setSaving(false);
    } catch (err) {
      throw err;
    }
  };
  return [acceptPrevDT, saving] as const;
};

const useInsertDTResult = () => {
  const [insertDrugtest, isInserting] =
    useAsyncMutation<useInsertDTResult_InsertDrugTestMutation>(
      insertDrugtestMutation,
    );
  const [upsertDTUserOrientation] = useAsyncMutation<useInsertDTResultMutation>(
    upsertUserOrientationDrugtestMutation,
  );
  const insertResult = async (props: {
    workerIds: Array<string>;
    resultsMap: {
      [key: string]: {
        result: DrugtestStatusType;
        date: Dayjs | undefined;
        subcontractorId?: string | null;
        projectIdsToInsert: Array<string>;
      };
    };
    onFinish: () => void;
    projectId?: string;
    subcontractorId?: string;
    images?: image_insert_input[];
  }) => {
    const drugtestToInsert: Array<worker_drug_test_insert_input> = [];
    props.workerIds.forEach(async (workerId) => {
      const d = props.resultsMap[workerId]?.date;
      const result = props.resultsMap[workerId]?.result;

      if (!result) {
        message.error("Enter Drug Test Result for this Worker");
        return;
      }
      if (d === undefined || d === null) {
        message.error("Enter Drug Test Date For this Worker");
        return;
      }
      if (!props.resultsMap[workerId]?.subcontractorId) {
        // Alex TDOD:  This worker ... - which one? Improve mmssage...
        message.error("This worker is not Under any Subcontractor right now");
        return;
      }
      const date = d.format();
      const subId = props.resultsMap[workerId].subcontractorId;
      drugtestToInsert.push({
        worker_id: workerId,
        project_id: props.projectId,
        subcontractor_id: subId,
        drug_test_date: date,
        status: result,
        entered_through_uid: auth.currentUser?.uid,
        ...(props.images ? { images: { data: props.images } } : {}),
      });
    });
    try {
      const d = await insertDrugtest({
        variables: { objects: drugtestToInsert },
      });

      if (!d.insert_worker_drug_test) return;
      const upsertList: Array<user_orientation_insert_input> = [];
      d.insert_worker_drug_test.returning.forEach((dt) => {
        const projectIdsToInsert =
          props.resultsMap[dt.worker_id].projectIdsToInsert;
        if (props.projectId && !projectIdsToInsert.includes(props.projectId)) {
          projectIdsToInsert.push(props.projectId);
        }
        const date = dayjs(props.resultsMap[dt.worker_id]?.date).format();
        const subId = props.resultsMap[dt.worker_id].subcontractorId;
        projectIdsToInsert.forEach((p) =>
          upsertList.push({
            user_id: dt.worker_id,
            project_id: p,
            drug_test_id: dt.pk,
            subcontractor_id: subId,
            drug_test_updated_at: date,
            drug_test_updated_by_user_id: auth.currentUser?.uid,
          }),
        );
      });
      await upsertDTUserOrientation({
        variables: {
          objects: upsertList,
        },
      });

      props.onFinish();
    } catch (e) {
      notification.error({
        message: "Add drug test error",
        description: e instanceof Error ? e.message : JSON.stringify(e),
      });
    }
  };
  return [insertResult] as const;
};
export default useInsertDTResult;
