import React from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { Button, Input } from "antd";
import onEnterDown from "../../../utils/onEnterDown";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import validateEmail from "../../../../../common/functions/validateEmail";

import { useCurrentLangStrings } from "../../../../../utility-features/i18n/context/languageHooks";

export interface SiteOrientationStepsUIEmailProps {
  loading: boolean;
  value: string;
  onValueChange: (newValue: string) => void;
  onEnter: () => void;
  onNoEmailPress: () => void;
  onBack?: () => void;
}

const SiteOrientationStepsUIEmail: React.FC<
  SiteOrientationStepsUIEmailProps
> = (props) => {
  const emailIsValid = validateEmail(props.value);
  const langStrings = useCurrentLangStrings();

  const onEnter = () => {
    if (!emailIsValid) {
      return;
    }
    props.onEnter();
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      loading={props.loading}
      nextButtonDisabled={!emailIsValid}
      onBackButtonClick={props.onBack}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.letsGetStarted}
      >
        <div className={`flex flex-col gap-0.5`}>
          <p className={`text-1.5`}>
            {
              langStrings.strings
                .enterYourEmailWeWillCheckWhetherYouNeedToCreateANewAccount
            }
          </p>
          <p className="text-static-secondary">
            {langStrings.strings.SiteFormIncIsCommitedToYourPrivacy}
          </p>
          <Input
            type={`email`}
            disabled={props.loading}
            onKeyDown={(e) => onEnterDown(e, onEnter)}
            placeholder="name@example.com"
            onChange={(e) => {
              props.onValueChange(e.target.value);
            }}
            value={props.value}
            enterKeyHint={"enter"}
          />
          <div className={`self-end`}>
            <Button
              disabled={props.loading}
              type={`text`}
              onClick={props.onNoEmailPress}
            >
              {langStrings.strings.iDontHaveEmailAddress}
            </Button>
          </div>
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationStepsUIEmail;
