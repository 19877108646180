import React, { useState } from "react";
import { IconInfoCircle } from "@tabler/icons";
import Icon from "src/common/components/general/Icon";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import useUniversalOrientationRegisterUser from "../../../newDesign/utils/useUniversalOrientationRegisterUser";
import validatePhoneNumber from "../../../../../../../common/functions/validatePhoneNumber";
import getDigitsString from "src/common/functions/getDigitsString";
import PhoneNumberInputUS from "src/common/components/phoneNumberInput/PhoneNumberInputUS";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import clsx from "clsx";
import SiteFeatureStepLayout from "../../../../../../../common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import SiteFeatureStepsInputLayout from "../../../../../../../common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import useSiteOrientationUniversal from "../../../newDesign/utils/siteOrientationUniversalHook";

import { useCurrentLangStrings } from "../../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteOrientationUniversalPhoneNumberProps
  extends SiteOrientationStepProps {
  onLoginSuccess?: (workerId: string) => Promise<void>;
}

const SiteOrientationUniversalPhoneNumber: React.FC<
  SiteOrientationUniversalPhoneNumberProps
> = ({ onLoginSuccess, ...props }) => {
  const workerContext = useSiteOrientationUniversal();
  const [phoneNumber, setPhoneNumber] = useState(
    workerContext.orientee?.phoneNumber || "",
  );
  const [registerUser, loading] = useUniversalOrientationRegisterUser({
    onLoginSuccess,
  });
  const phoneNumberIsValid = validatePhoneNumber(phoneNumber);

  const langStrings = useCurrentLangStrings();
  const onEnter = async () => {
    // TODO update user info
    const phoneNumberDigits = getDigitsString(phoneNumber);

    const registered = await registerUser(phoneNumberDigits);
    workerContext.updateOrientee((u) =>
      u
        ? { ...u, phoneNumber: phoneNumberDigits }
        : { phoneNumber: phoneNumberDigits },
    );

    if (registered) props.onNext();
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!phoneNumberIsValid}
      loading={loading}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.whatIsYourPhoneNumber}
      >
        <div className="text-grey text-1">
          {langStrings.strings.enter10DigitPhoneNumberWithoutSpace}
        </div>
        <PhoneNumberInputUS
          // disableCountryCode
          disabled={loading}
          value={phoneNumber}
          onChange={setPhoneNumber}
          onEnterPress={onEnter}
        />
        <div
          className={`flex flex-row items-center gap-0.5 text-static-secondary text-1`}
        >
          <SafetyCertificateOutlined />
          <p>
            {
              langStrings.strings
                .siteFormIsCommittedToYourPrivacyAndWillNotSellYourData
            }
          </p>
        </div>
        <div className="mb-2 flex text-grey text-1">
          <Icon icon={IconInfoCircle} color="static-secondary" />
          {langStrings.strings.yourPhoneNumberMayBeUsed}
        </div>
        <Popconfirm
          disabled={!!phoneNumber.length || loading}
          title={langStrings.strings.areYouSureYouMayMissLifeSavingMsgs}
          onConfirm={onEnter}
        >
          <Button
            disabled={!!phoneNumber.length || loading}
            type={`text`}
            className="self-end"
          >
            {langStrings.strings.continueWithoutPhoneNumber}
          </Button>
        </Popconfirm>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationUniversalPhoneNumber;
