import React, { FC } from "react";
import Image from "../general/images/Image";

const ShowMultipleImageLayout: FC<{
  images: Array<{ url: string; id: string }>;
}> = ({ images }) => {
  return (
    <div className="flex flex-row flex-wrap">
      {images.map((o, x) => (
        <div className="w-3/12 font-accent" key={o.id}>
          <Image src={o.url} alt={"Image " + (x + 1)} preview />
        </div>
      ))}
    </div>
  );
};
export default ShowMultipleImageLayout;
