import React from "react";
import { Navigate } from "react-router-dom";
import SCReportsNavigation from "./SCReportsNavigation";
import NestedRoute from "../../../../common/types/manual/NestedRoute";
import dailyRoute from "./routes/daily/dailyRoute";
import ptpRoute from "./routes/ptp/ptpRoute";
import scToolboxTalksRoute from "../scToolboxTalks/scToolboxTalksRoute";

const scReportsRoute: NestedRoute = {
  element: <SCReportsNavigation />,
  children: [
    {
      path: "ptp",
      ...ptpRoute,
    },
    {
      path: "daily",
      ...dailyRoute,
    },
    {
      path: "toolbox-talks",

      ...scToolboxTalksRoute,
    },
    {
      path: "",
      element: <Navigate to={`ptp`} replace />,
    },
  ],
};

export default scReportsRoute;
