import { Button, Image, message } from "antd";
import dayjs from "dayjs";
import React, { FC } from "react";
import { InsertImagesMutation } from "src/common/types/generated/relay/InsertImagesMutation.graphql";
import DrugtestProps from "src/common/types/manual/DrugtestProps";
import { RoleConvert } from "../tables/TaskEditsTable";
import insertImages from "src/common/api/relay/mutations/InsertImages";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import capitalize from "src/common/functions/capitalize";
import { DrugtestStatusType } from "src/domain-features/siteorientation/utils/siteOrientationTypes";

interface DTContentProps {
  drugtests: DrugtestProps[];
  onUse?: (drugtestId: string, result?: DrugtestStatusType | null) => void;
  refetch?: () => void;
  loading?: boolean;
  projectId?: string;
}

const DrugtestCardsLayout: FC<DTContentProps> = ({
  drugtests,
  onUse,
  projectId,
  loading,
}) => {
  if (!drugtests || !drugtests[0]) return <></>;
  return (
    <div className="max-h-32 overflow-scroll max-w-32">
      {drugtests.map((d, i) => {
        const enteredUserdata = d.entered_through_user;

        const [title, company] = RoleConvert(enteredUserdata);
        return (
          <div key={i}>
            {i !== 0 && (
              <div>
                <br />
                <hr />
                <br />
              </div>
            )}
            <div className={onUse ? "mb-1" : ""}>
              {d.status && (
                <div
                  className={`${
                    d.status === "negative" ||
                    d.status === "return-to-work-eligible"
                      ? "text-semantic-positive-green hover:text-semantic-positive-green"
                      : d.status === "positive"
                      ? "text-semantic-negative hover:text-semantic-negative"
                      : "text-grey hover:text-grey"
                  } text-1.25`}
                >
                  {d.status === "return-to-work-eligible"
                    ? "Return to work-Eligible"
                    : capitalize(d.status)}{" "}
                  {d.project_id !== projectId && (
                    <span>(test from another project)</span>
                  )}
                </div>
              )}
              <div>
                <div>
                  Date of Drug Test: {dayjs(d.drug_test_date).format("LL")}
                </div>
                <div>
                  Date added to SiteForm: {dayjs(d.created_at).format("LL")}
                </div>
                <div>Project: {d.project?.name ?? ""}</div>
                Entered By:{" "}
                {enteredUserdata
                  ? `${enteredUserdata?.name} ${
                      enteredUserdata?.role === "worker"
                        ? enteredUserdata.worker?.worker_title?.translation.en
                        : title
                    }, ${company}`
                  : d.name}
              </div>
            </div>
            {d.status && onUse && (
              <>
                {
                  <div
                    className={`font-accent ${
                      d.status === "negative"
                        ? "text-semantic-positive-green"
                        : d.status === "positive"
                        ? "text-semantic-negative"
                        : ""
                    }`}
                  >
                    {d.status === "negative" ? (
                      <div>
                        <div className="text-static-primary">
                          Use Previous Valid Drug Test?
                        </div>
                        This worker has a valid drug test from another project.
                        Do you want to apply it to this project?
                      </div>
                    ) : d.status === "positive" ? (
                      "Acknowledging will move this worker to the Positive list below, where you can update their status"
                    ) : (
                      ""
                    )}
                  </div>
                }{" "}
                <div className="text-right">
                  <Button
                    loading={loading}
                    type="primary"
                    className="text-1 font-accent rounded-full"
                    onClick={() =>
                      onUse(d.pk, d.status as DrugtestStatusType | null)
                    }
                  >
                    {d.status === "positive" ? "Acknowledge" : "Apply"}
                  </Button>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default DrugtestCardsLayout;
