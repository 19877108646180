import { Button } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useState, useRef } from "react";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import EditUsersForNotificationModal from "src/common/components/dialogs/EditUsersForNotificationModal";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { GCUsersToBeNotifiedForRejectedCerts_DeleteUsersNotificationMutation } from "src/common/types/generated/relay/GCUsersToBeNotifiedForRejectedCerts_DeleteUsersNotificationMutation.graphql";
import { GCUsersToBeNotifiedForRejectedCerts_InsertUsersNotificationMutation } from "src/common/types/generated/relay/GCUsersToBeNotifiedForRejectedCerts_InsertUsersNotificationMutation.graphql";

const deleteUsersNotificationMutation = graphql`
  mutation GCUsersToBeNotifiedForRejectedCerts_DeleteUsersNotificationMutation(
    $where: user_notification_bool_exp!
  ) {
    delete_user_notification(where: $where) {
      affected_rows
    }
  }
`;
const insertUsersNotificationMutation = graphql`
  mutation GCUsersToBeNotifiedForRejectedCerts_InsertUsersNotificationMutation(
    $objects: [user_notification_insert_input!]!
  ) {
    insert_user_notification(objects: $objects) {
      affected_rows
    }
  }
`;

interface GCUsersToBeNotifiedForRejectedCertsProps {
  projectId: string;
  userIdsToBeNotified: readonly {
    readonly node: {
      readonly user_id: string;
      readonly user: { readonly name: string };
    };
  }[];
  allGCUsers: readonly {
    readonly employee: {
      readonly uid: string;
      readonly user: { readonly name: string };
    };
  }[];
}

const GCUsersToBeNotifiedForRejectedCerts: FC<
  GCUsersToBeNotifiedForRejectedCertsProps
> = ({ userIdsToBeNotified, projectId, allGCUsers }) => {
  const [openModal, setOpenModal] = useState(false);
  const [deleteUsersToBeNotified, isDeleting] =
    useAsyncMutation<GCUsersToBeNotifiedForRejectedCerts_DeleteUsersNotificationMutation>(
      deleteUsersNotificationMutation
    );
  const [insertUsersToBeNotified, isInserting] =
    useAsyncMutation<GCUsersToBeNotifiedForRejectedCerts_InsertUsersNotificationMutation>(
      insertUsersNotificationMutation
    );
  const currentUserIds = useRef<string[]>(
    userIdsToBeNotified.map((u) => u.node.user_id)
  );

  return (
    <div className="flex flex-col gap-1">
      <p className="text-2">Automatic Emails</p>
      <p className="mb-1">
        Select Team members that will receive emails about Orientation specific
        information, such as a weekly email of workers onsite but have not
        completed orientation and when a training or certificate cannot be
        processed.
      </p>
      <div className="-mx-0.25">
        {allGCUsers
          .filter((u) => currentUserIds.current.includes(u.employee.uid))
          .map((cur) => (
            <span
              className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-interactive-light text-interactive-primary"
              key={cur.employee.uid}
            >
              {cur.employee.user.name}
            </span>
          ))}
      </div>
      {/* <Table columns={columns} dataSource={dataSource} bordered></Table> */}

      <div className="flex flex-row justify-start">
        <Button
          onClick={() => setOpenModal(true)}
          loading={isDeleting || isInserting}
          className="mb-2 font-accent rounded-2"
        >
          Select Team Member(s)
        </Button>
      </div>
      <EditUsersForNotificationModal
        selectedUserIds={currentUserIds.current}
        projectId={projectId}
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        onDoneEditing={async (values) => {
          setOpenModal(false);
          const [toBeInsertedUserIds, toBeDeletedUserIds] = compareTwoLists(
            values.usersToBeNotified,
            currentUserIds.current
          );
          currentUserIds.current = [...values.usersToBeNotified];
          if (toBeDeletedUserIds.length > 0)
            await deleteUsersToBeNotified({
              variables: {
                where: {
                  project_id: { _eq: projectId },
                  user_id: { _in: toBeDeletedUserIds },
                  event_type: { _eq: "for_rejected_certificate" },
                },
              },
            }).catch((e) => console.error(e));
          if (toBeInsertedUserIds.length > 0)
            await insertUsersToBeNotified({
              variables: {
                objects: toBeInsertedUserIds.map((userId) => ({
                  project_id: projectId,
                  user_id: userId,
                  event_type: "for_rejected_certificate",
                })),
              },
            }).catch((e) => console.error(e));
        }}
      />
    </div>
  );
};
export default GCUsersToBeNotifiedForRejectedCerts;
