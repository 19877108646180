import { Button, message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC } from "react";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { ProfilePicButton_UpdateProfileMutation } from "src/common/types/generated/relay/ProfilePicButton_UpdateProfileMutation.graphql";
import { VerifyMultipleCertsModal_UpdateMutation } from "src/common/types/generated/relay/VerifyMultipleCertsModal_UpdateMutation.graphql";
import { updateCertsToVerifyMutation } from "./VerifyMultipleCertsModal";
import GetFullID from "../../../../../common/functions/GetFullId";
import dayjs from "dayjs";

export const setProfilePicMutation = graphql`
  mutation ProfilePicButton_UpdateProfileMutation(
    $userId: uuid!
    $profilePicId: uuid
  ) {
    update_user(
      where: { id: { _eq: $userId } }
      _set: { profile_picture_id: $profilePicId }
    ) {
      affected_rows
    }
  }
`;

const ProfilPicButton: FC<{
  workerId: string;
  imageId: string | undefined | null;
  certId: string;
  loggedInUserId: string;
  onSuccess: () => void;
}> = ({ workerId, imageId, certId, loggedInUserId, onSuccess }) => {
  const [setProfilePic, isSetting] =
    useAsyncMutation<ProfilePicButton_UpdateProfileMutation>(
      setProfilePicMutation,
    );
  const [updateMutation, updatingStatus] =
    useAsyncMutation<VerifyMultipleCertsModal_UpdateMutation>(
      updateCertsToVerifyMutation,
    );
  return (
    <Button
      onClick={async () => {
        const time = dayjs().format();
        await setProfilePic({
          variables: { userId: workerId, profilePicId: imageId },
        }).catch((e) => console.log(e));
        await updateMutation({
          variables: {
            certId,
            _set: {
              verified_at: time,
              verified_by_uid: loggedInUserId,
              document: "profilePhoto",
            },
          },

          optimisticResponse: {
            update_certificates_to_verify_by_pk: {
              verified_at: time,
              id: GetFullID("certificates_to_verify", certId),
            },
          },
        }).catch((e) => console.log(e));
        message.success("Worker Profile Picture updated");
        onSuccess();
      }}
      loading={isSetting || updatingStatus}
      className="absolute z-50 float-right mt-1 border-0 rounded-2 opacity-80 bg-semantic-positive-dark text-interactive-primary"
    >
      Set as Profile Picture
    </Button>
  );
};
export default ProfilPicButton;
