import React, { ComponentType, FC, forwardRef } from "react";
import CustomSuspense, {
  CustomSuspenseProps,
} from "src/common/components/general/CustomSuspense";

const withCustomSuspense = <Props,>(
  Component: ComponentType<Props> | FC<Props>,
  options?: CustomSuspenseProps
) =>
  forwardRef((props: Props, ref) => {
    return (
      <CustomSuspense {...options}>
        <Component {...props} ref={ref} />
      </CustomSuspense>
    );
  });

export default withCustomSuspense;
