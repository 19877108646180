import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import NestedRoute from "../../../../common/types/manual/NestedRoute";
import slidesRoute from "./routes/slides/slidesRoute";
import GCProjectOrientation from "./GCProjectOrientation";
import searchParamsProvider from "../../../../common/functions/searchParamsProvider";
import { LanguageProvider } from "src/utility-features/i18n/context/languageContext";

const gcDashboardRoute: NestedRoute = {
  element: <Outlet />,
  children: [
    {
      path: ":subview",
      element: searchParamsProvider(GCProjectOrientation),
    },
    {
      path: "",
      element: <Navigate replace to="status" />,
    },
    {
      path: "slides/:orientationId",
      ...slidesRoute,
    },
  ],
};

export default gcDashboardRoute;
