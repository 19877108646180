import React, { MutableRefObject } from "react";
import Form, { FormInstance } from "antd/lib/form";
import { Input, Radio, Select, Space } from "antd";
import {
  OrientationPage,
  SlideAnswersMapType,
} from "../../../../siteorientationTypes";
import ButtonHuge from "../../../../../../common/components/general/ButtonHuge";
import translateCorrectAnswers from "src/domain-features/siteorientation/utils/translateCorrectAnswers";
import { CurrentLangStrings } from "../../../../../../utility-features/i18n/language-utils/i18nTypes";

export interface SiteOrientationSlidesViewerSlideQuestionProps {
  page: OrientationPage;
  form: FormInstance<any>;
  submitted: boolean;
  allAnswered: boolean;
  langStrings: CurrentLangStrings;
  notLastPage: boolean;
  onQuizSubmit: () => Promise<void>;
  slideAnswers: MutableRefObject<SlideAnswersMapType>;
}

const formElementClass = "text-1";
const SiteOrientationSlidesViewerSlideQuestion: React.FC<
  SiteOrientationSlidesViewerSlideQuestionProps
> = ({
  page,
  form,
  submitted,
  allAnswered,
  notLastPage,
  langStrings,
  slideAnswers,
  onQuizSubmit,
}) => {
  return (
    <div className={`w-full h-full`}>
      <Form
        className={`flex flex-col gap-0 h-full`}
        form={form}
        layout="vertical"
        onFinish={onQuizSubmit}
      >
        <div className={`flex-1 flex flex-col gap-0 justify-center`}>
          {page.slides.map((si, i) => {
            const id = si.pk;
            const el_type = si.question_ui_element || "";
            const items = (si.answer_items || "").split("\n").filter((v) => v);
            const initialValue = slideAnswers.current[si.pk]?.answer;
            return (
              <div key={id} className="w-full max-w-48 m-auto p-0.5">
                <Form.Item
                  key={id}
                  initialValue={initialValue}
                  name={`field_${id}`}
                  label={<span className="text-1">{si.title}</span>}
                  rules={[
                    {
                      required: si.answer_required,
                      message: langStrings.strings.answerIsRequired,
                    },
                    {
                      validator: (rule, value) => {
                        if (si.check_correct_answer && submitted) {
                          if (si.correct_answers !== value) {
                            rule.message = !initialValue ? (
                              <div className="text-semantic-negative pb-1 text-1">
                                <div className="font-accent">
                                  {langStrings.strings.incorrect}
                                </div>
                                <div>{si.correct_answer_note}</div>
                              </div>
                            ) : (
                              <div></div>
                            );
                            return Promise.reject("incorrect");
                          } else {
                            rule.message = !initialValue ? (
                              <div className="text-semantic-positive-green pb-1 text-1">
                                <div className="font-accent">
                                  {langStrings.strings.correct}
                                </div>
                                <div>{si.correct_answer_note}</div>
                              </div>
                            ) : (
                              <div></div>
                            );
                            return Promise.reject("correct");
                          }
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  {el_type === "radio" ? (
                    <Radio.Group
                      className={formElementClass}
                      options={items.map((a) => ({ label: a, value: a }))}
                      optionType="button"
                      disabled={submitted || !!(initialValue)}
                    />
                  ) : el_type === "checkboxes" ? (
                    <Radio.Group
                      className={formElementClass}
                      //                      options={items.map((a) => ({ label: a, value: a }))}
                      disabled={submitted || !!(initialValue)}
                    >
                      <Space direction="vertical">
                        {items.map((a, i) => (
                          <Radio
                            className="whitespace-normal items-start flex flex-row"
                            key={i}
                            value={a}
                          >
                            {a}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  ) : el_type === "dropdown" ? (
                    <Select
                      disabled={submitted || !!(initialValue)}
                      className={"w-full " + formElementClass}
                    >
                      {items.map((a, i) => (
                        <Select.Option key={i} value={a} className="text-1">
                          {a}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : el_type === "text" ? (
                    <Input
                      disabled={submitted || !!(initialValue)}
                      readOnly={submitted || !!(initialValue)}
                      className={formElementClass}
                    />
                  ) : null}
                </Form.Item>
                {initialValue && si.correct_answers && (
                  <div>
                    {translateCorrectAnswers(
                      si,
                      slideAnswers.current[si.pk]?.lang || page.pageLang
                    ) !== initialValue ? (
                      <div className="text-semantic-negative pb-1 text-1">
                        <div className="font-accent">
                          {langStrings.strings.incorrect}
                        </div>
                        <div>{si.correct_answer_note}</div>
                      </div>
                    ) : (
                      <div className="text-semantic-positive-green pb-1 text-1">
                        <div className="font-accent">
                          {langStrings.strings.correct}
                        </div>
                        <div>{si.correct_answer_note}</div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {!submitted && !allAnswered && notLastPage && (
          <ButtonHuge type="primary" htmlType="submit">
            {langStrings.strings.submit}
          </ButtonHuge>
        )}
      </Form>
    </div>
  );
};

export default SiteOrientationSlidesViewerSlideQuestion;
