import React from "react";
import { SiteOrientationStepProps } from "../../../../../utils/siteOrientationTypes";
import SiteOrientationRegistrationStepsHello from "../../../../../components/stepsUI/hello/SiteOrientationRegistrationStepsHello";
import { useCurrentLangStrings } from "../../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteOrientationUniversalStepHelloProps
  extends SiteOrientationStepProps {}

const SiteOrientationUniversalStepHello: React.FC<
  SiteOrientationUniversalStepHelloProps
> = (props) => {
  const langStrings = useCurrentLangStrings();

  return (
    <SiteOrientationRegistrationStepsHello onNext={props.onNext}>
      <p className="font-accent">
        {langStrings.strings.thisIsAgcUniversalOrientation}
      </p>
    </SiteOrientationRegistrationStepsHello>
  );
};

export default SiteOrientationUniversalStepHello;
