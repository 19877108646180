import React, { FC } from "react";
import PhoneNumberInputUI from "./PhoneNumberInputUI";
import { PhoneNumberInputCommonProps } from "./phoneNumberInputTypes";

const PhoneNumberInputUS: FC<PhoneNumberInputCommonProps> = (props) => {
  const formatPhoneNumber = (number: string) => {
    if (!number || !number.length) return "";
    const digits = number.replace(/\D/g, "");
    let formatted = `(${digits.slice(0, 3)}`;

    if (digits.length >= 4) {
      formatted += `) ${digits.slice(3, 6)}`;
    }

    if (digits.length >= 7) {
      formatted += `-${digits.slice(6, 10)}`;
    }

    return formatted;
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const truncatedInput = input.slice(0, 10); // Limit input to maximum of 10 characters
    const formattedInput = formatPhoneNumber(truncatedInput);
    props.onChange(formattedInput);
  };

  return (
    <PhoneNumberInputUI
      disabled={props.disabled}
      value={props.value}
      onChange={onInputChange}
      autoFocus={props.autoFocus}
    />
  );
};

export default PhoneNumberInputUS;
