const getPermitBackgroundColor = (status: string) => {
  let semanticColorClass = "";
  let semanticDarkColorClass = "";
  switch (status) {
    case "approved":
      semanticColorClass = "bg-semantic-positive";
      semanticDarkColorClass = "bg-semantic-positive-dark";
      break;
    case "closed":
      semanticColorClass = "bg-semantic-inactive";
      semanticDarkColorClass = "bg-semantic-inactive-dark";
      break;
    case "rejected":
      semanticColorClass = "bg-semantic-negative";
      semanticDarkColorClass = "bg-semantic-negative-dark";
      break;
    default:
      semanticColorClass = "bg-semantic-pending";
      semanticDarkColorClass = "bg-semantic-pending-dark";
      break;
  }
  return { semanticColorClass, semanticDarkColorClass };
};
export default getPermitBackgroundColor;
