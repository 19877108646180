import React, { createContext } from "react";

import {
  OacUserType,
  UserType,
  WorkerType,
} from "src/domain-features/siteorientation/utils/siteOrientationTypes";

export type UniversalOrientationUser =
  | UserType
  | (WorkerType & { htWorkerCode?: string })
  | OacUserType
  | null;
export type SiteOrientationUniversalContextType = {
  updateOrientee: React.Dispatch<
    React.SetStateAction<UniversalOrientationUser>
  >;
  type: "universal";
  orientee: UniversalOrientationUser;
} | null;

const SiteOrientationUniversalContext =
  createContext<SiteOrientationUniversalContextType>(null);
// TODO: do not use context directly
//  use SiteOrientationUniversalProvider or useSiteOrientationUniversal
export default SiteOrientationUniversalContext;
