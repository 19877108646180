import { useState, PropsWithChildren } from "react";
import { Popover, PopoverProps } from "antd";
import ActionButton, {
  ActionButtonProps,
} from "../../../domain-features/sitesafety/job-hazard-analysis/components/action-buttons/ActionButton";

interface ActionButtonsPopoverProps {
  actionButtons: ActionButtonProps[];
  popoverProps: PopoverProps;
}
const ActionButtonsPopover = ({
  actionButtons,
  popoverProps,
  children,
}: PropsWithChildren<ActionButtonsPopoverProps>) => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      {...popoverProps}
      open={open}
      onOpenChange={handleOpenChange}
      content={
        <div>
          {actionButtons.map((actionButtonProps) => {
            return (
              <ActionButton
                key={actionButtonProps.label}
                label={actionButtonProps.label}
                onClick={() => {
                  actionButtonProps.onClick();
                  hide();
                }}
              ></ActionButton>
            );
          })}
        </div>
      }
    >
      {children}
    </Popover>
  );
};

export default ActionButtonsPopover;
