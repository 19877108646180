import React, { useState } from "react";
import { Checkbox } from "antd";
import { IconCheck, IconUserCircle } from "@tabler/icons";
import SiteOrientationInPersonCollapse from "../../../../../components/SiteOrientationInPersonCollapse";
import Image, { ImageProps } from "src/common/components/general/images/Image";

export interface SiteOrientationInPersonManagerViewUsersSelectListProps {
  hiddenByDefault?: boolean;
  searchPrompt?: string;
  listTitle: string;
  list: {
    name: string;
    title?: string;
    companyName?: string;
    coporateValid?: boolean;
    universalValid?: boolean;
    profilePhotoUrl?: string;
    selected: boolean;
    id: string;
  }[];
  onSelectToggle: (id: string) => void;
}

const SiteOrientationInPersonManagerViewUsersSelectList: React.FC<
  SiteOrientationInPersonManagerViewUsersSelectListProps
> = (props) => {
  const [visible, setVisible] = useState(props.hiddenByDefault === undefined);

  const filteredList = !props.searchPrompt
    ? props.list
    : props.list.filter(
        (worker) =>
          !props.searchPrompt ||
          worker.name.toLowerCase().includes(props.searchPrompt.toLowerCase()),
      );
  filteredList.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <SiteOrientationInPersonCollapse
      headText={`${props.listTitle} (${filteredList.length})`}
      hide={() => setVisible(false)}
      expand={() => setVisible(true)}
      expanded={visible}
    >
      {!!filteredList.length && (
        <div className={`flex flex-col gap-0.25`}>
          {filteredList.map((item) => (
            <div
              key={item.id}
              className={`relative flex flex-row items-center gap-0.5 px-1 py-0.5 border border-interactive-secondary hover:border-interactive-primary cursor-pointer rounded-0.5`}
              onClick={(e) => {
                props.onSelectToggle(item.id);
              }}
            >
              <Checkbox checked={item.selected} />
              <div
                className={`flex flex-row flex-1 items-center gap-0.5 justify-between`}
              >
                <div className="inline-flex">
                  {item.name && (
                    <p className={`text-1 font-accent min-w-10`}>{item.name}</p>
                  )}
                  <Image
                    src={item.profilePhotoUrl}
                    icon={IconUserCircle}
                    height={70}
                    width={100}
                    preview
                    // className="ml-0.5 h-2 w-2"
                  />
                </div>
                <div className={`relative z-10 flex flex-col gap-0`}>
                  {item.title && (
                    <p className={`text-1 font-normal`}>{item.title}</p>
                  )}

                  {item.companyName && (
                    <p className={`text-static-secondary text-0.75`}>
                      {item.companyName}
                    </p>
                  )}
                </div>
                {item.coporateValid && (
                  <div
                    className={`relative w-0 h-0 flex flex-row items-center whitespace-nowrap`}
                  >
                    <div
                      className={`absolute flex flex-col items-end my-auto right-0 z-0`}
                    >
                      <IconCheck />
                      <p className={`text-0.75 text-static-secondary`}>
                        Company Orientation
                      </p>
                    </div>
                  </div>
                )}
                {item.universalValid && (
                  <div
                    className={`relative w-0 h-0 flex flex-row items-center whitespace-nowrap`}
                  >
                    <div
                      className={`absolute flex flex-col items-end my-auto right-0 z-0`}
                    >
                      <IconCheck />
                      <p className={`text-0.75 text-static-secondary`}>AGC </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </SiteOrientationInPersonCollapse>
  );
};

export default SiteOrientationInPersonManagerViewUsersSelectList;
