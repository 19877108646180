import dayjs from "dayjs";
import { FC, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataScrollTableProps, DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import { SCProjectQuery$data } from "src/common/types/generated/relay/SCProjectQuery.graphql";
import { task_bool_exp } from "src/common/types/generated/relay/SiteOrientationSlidesViewerQuery.graphql";
import { SubProjectMobilizationStepJhaQuery$data } from "src/common/types/generated/relay/SubProjectMobilizationStepJhaQuery.graphql";
import { TasksTableQuery } from "src/common/types/generated/relay/TasksTableQuery.graphql";
import AddTasksFromOtherProjectLibraryModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/AddTasksFromOtherJHALibraryModal";
import UploadTaskModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/UploadTaskModal";
import UploadTaskToSiteFormModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/UploadTaskToSiteformModal";
import TasksTable, { ColumnKeys, DConnection } from "src/domain-features/sitesafety/job-hazard-analysis/components/tables/TasksTable";
import topBarButtons from "src/domain-features/sitesafety/job-hazard-analysis/utils/topBarButtons";

interface ProjectSubcontractorTasksProps {
  subcontractorId: string;
  projectId: string;
  refresh: () => void;
  data: SCProjectQuery$data | SubProjectMobilizationStepJhaQuery$data;
  excludedColumns?: ColumnKeys[];
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    TasksTableQuery
  >["columns"];
}

const ProjectSubcontractorTasks: FC<ProjectSubcontractorTasksProps> = ({
  projectId,
  subcontractorId,
  data,
  excludedColumns,
  extraColumns,
  refresh,
}) => {
  const navigate = useNavigate();
  const [taskWhere, setTaskWhere] = useState<task_bool_exp | undefined>(
    undefined,
  );
  const [addJHAFromOtherProjectLibrary, setAddJHAFromOtherProjectLibraryOpen] =
    useState(false);
  const [isOpenUploadTaskModal, setIsOpenUploadTaskModal] = useState(false);
  const [isOpenUploadTaskToSiteformModal, setIsOpenUploadTaskToSiteformModal] =
    useState(false);

  const projectData = data.project_connection.edges[0].node;
  const projectsOfSub = data.project_subcontractor_connection.edges.map(
    (proj) => ({ id: proj.node.project.pk, name: proj.node.project.name }),
  );
  const ppeOptions = data.ppe_type_connection.edges;
  const permitOptions = data.permit_type_connection.edges;
  const turnerGCId = "96916668-c816-4c2a-9008-73a6116d4c00";
  const [start, setStart] = useState<dayjs.Dayjs | undefined>(undefined);
  const [statusFilter, setStatusFilter] = useState<task_bool_exp>({});
  const activeJhaWhere: task_bool_exp = {
    archived_at: { _is_null: true },
    is_pending_acceptance: { _eq: false },
    request_state: { _is_null: true },
  };
  const processingJhaWhere: task_bool_exp = {
    request_state: { _eq: "processing" },
  };

  const { approvedJhas, signedJhas } = useMemo(() => {
    const approvedJhas = new Set<string>();
    const signedJhas = new Set<string>();
    data.task_connection.edges.forEach((task) => {
      task.node.task_signatures.forEach((s) => {
        if (s.signature_image_id) {
          signedJhas.add(task.node.pk);
        } else {
          approvedJhas.add(task.node.pk);
        }
      });
    });

    return { approvedJhas, signedJhas };
  }, [data.task_connection]);

  const [selectedPpe, setSeletedPpe] = useState<Array<string>>([]);

  const isNotTurnersProject =
    projectData.general_contractor.pk !== turnerGCId;
  const jhaTableRef = useRef<DataScrollTableRef>(null);

  return (
    <>
      <UploadTaskToSiteFormModal
        visible={isOpenUploadTaskToSiteformModal}
        subcontractorId={subcontractorId}
        onClose={() => setIsOpenUploadTaskToSiteformModal(false)}
        onSubmit={() => {
          setIsOpenUploadTaskToSiteformModal(false);
          refresh();
        }}
        projectId={projectId}
      />

      <UploadTaskModal
        visible={isOpenUploadTaskModal}
        isNotTurnersProject={isNotTurnersProject}
        onCancel={() => setIsOpenUploadTaskModal(false)}
        refetch={() => {jhaTableRef.current?.refetch();
          refresh();
        }}
        ppeData={ppeOptions.map((p) => ({
          name: p.node.name.en,
          id: p.node.pk,
        }))}
        permitData={permitOptions.map((p) => ({
          name: p.node.name.en,
          id: p.node.pk,
        }))}
        projectId={projectId}
        subcontractorId={subcontractorId}
        setIsOpenUploadModal={setIsOpenUploadTaskModal}
      />

      <AddTasksFromOtherProjectLibraryModal
        closeModal={() => setTaskWhere(undefined)}
        refresh={() => {jhaTableRef.current?.refetch();
          refresh();
        }}
        projectId={projectId}
        subcontractorId={subcontractorId}
        visible={!!taskWhere}
        taskWhere={taskWhere}
        addToProjectOrCompany="project"
      />

      <TasksTable
        ref={jhaTableRef}
        subcontractorId={subcontractorId}
        excludedKeys={
          excludedColumns ?? [
            "subcontractor",
            "addToProject",
            "addToMulipleProjects",
            "delete",
            "internalReview",
            "ppe",
            "permit",
          ]
        }
        onRowItemClick={(row) => {
          if (row.is_pending_acceptance) {
            navigate(
              `requested-task?idFromSubProj=${
                row.pk
              }${`&requestId=${row.creation_request_id}`}`,
            );
          } else {
            navigate(`/sub/projects/${projectId}/task?id=${row.pk}`);
          }
        }}
        projectOfSub={projectsOfSub}
        topBarButtons={topBarButtons({
          onInstantTaskUploadClick: () => setIsOpenUploadTaskModal(true),
          onNonInstantTaskUploadClick: () =>
            setIsOpenUploadTaskToSiteformModal(true),
          onAddJHAFromCompanyLibraryClick: () =>
            setTaskWhere({
              subcontractor_id: { _eq: subcontractorId },
              project_id: { _is_null: true },
            }),
          onAddJHAFromOtherProjectClick: () =>
            setTaskWhere({
              subcontractor_id: { _eq: subcontractorId },
              project_id: { _neq: projectId },
            }),
        })}
        extraColumns={extraColumns}
        actionsAllowed={[
          "acceptJHA",
          "archiveOrActivate",
          "addToCompanyJHALibrary",
          "delete",
          "excelDownload",
          "pdfDownload",
          "viewFile",
        ]}
        isNotTurnersProject={isNotTurnersProject}
        projectId={projectId}
        title="JHAs"
        where={{
          deleted_at: { _is_null: true },
          project_id: { _eq: projectId },
          subcontractor_id: { _eq: subcontractorId },
          report_id: { _is_null: true },
          ...statusFilter,
          ...(start
            ? {
                report_tasks: {
                  report: {
                    created_at: { _gte: start.format("YYYY-MM-DD") },
                  },
                },
              }
            : {}),

          ...(selectedPpe.length
            ? {
                task_ppe_types: { ppe_type_id: { _in: selectedPpe } },
              }
            : {}),
        }}
        customFilters={[
          {
            type: "checkbox",
            title: "Status",
            removeFilter: () => setStatusFilter({}),
            options: [
              {
                option: "Active",
                onApply: () => {
                  setStatusFilter((prev) => {
                    const { _and, ...rest } = prev;
                    if (_and && _and.length > 0) {
                      return {
                        ...rest,
                        _and: [processingJhaWhere, activeJhaWhere],
                      };
                    } else {
                      return {
                        ...rest,
                        _and: [activeJhaWhere],
                      };
                    }
                  });
                },
                onCancel: () => {
                  setStatusFilter((prev) => {
                    const { _and, ...rest } = prev;
                    if (_and?.length === 2) {
                      return {
                        ...rest,
                        _and: [processingJhaWhere],
                      };
                    } else {
                      return rest;
                    }
                  });
                },
              },
              {
                option: "Approved",
                onApply: () => {
                  setStatusFilter((prev) => {
                    const { id, ...rest } = prev;
                    let tempJhas = prev.id?._in;
                    if (tempJhas)
                      tempJhas = tempJhas.concat(Array.from(approvedJhas));
                    else tempJhas = Array.from(approvedJhas);
                    return {
                      id: { _in: tempJhas },
                      ...rest,
                    };
                  });
                },
                onCancel: () => {
                  setStatusFilter((prev) => {
                    const { id, ...rest } = prev;
                    let tempJhas = prev.id?._in;
                    tempJhas = tempJhas?.filter((id) => !approvedJhas.has(id));

                    if (tempJhas && tempJhas.length > 0) {
                      return { id: { _in: tempJhas }, ...rest };
                    } else {
                      return rest;
                    }
                  });
                },
              },
              {
                option: "Archived",
                onApply: () => {
                  setStatusFilter((prev) => {
                    return { ...prev, archived_at: { _is_null: false } };
                  });
                },
                onCancel: () => {
                  setStatusFilter((prev) => {
                    const { archived_at, ...rest } = prev;
                    return rest;
                  });
                },
              },
              {
                option: "Pending Acceptance",
                onApply: () => {
                  setStatusFilter((prev) => {
                    return { ...prev, subcontractor_id: { _is_null: true } };
                  });
                },
                onCancel: () => {
                  setStatusFilter((prev) => {
                    const { subcontractor_id, ...rest } = prev;
                    return rest;
                  });
                },
              },
              {
                option: "Processing",
                onApply: () => {
                  setStatusFilter((prev) => {
                    const { _and, ...rest } = prev;
                    if (_and && _and.length > 0) {
                      return {
                        ...rest,
                        _and: [processingJhaWhere, activeJhaWhere],
                      };
                    } else {
                      return {
                        ...rest,
                        _and: [processingJhaWhere],
                      };
                    }
                  });
                },
                onCancel: () => {
                  setStatusFilter((prev) => {
                    const { _and, ...rest } = prev;
                    if (_and?.length === 2) {
                      return {
                        ...rest,
                        _and: [activeJhaWhere],
                      };
                    } else {
                      return rest;
                    }
                  });
                },
              },
              {
                option: "Requested",
                onApply: () => {
                  setStatusFilter((prev) => {
                    return {
                      ...prev,
                      request_state: { _eq: "requested" },
                    };
                  });
                },
                onCancel: () => {
                  setStatusFilter((prev) => {
                    const { request_state, ...rest } = prev;
                    return rest;
                  });
                },
              },
              {
                option: "Reviewed",
                onApply: () => {
                  setStatusFilter((prev) => {
                    return {
                      ...prev,
                      reviewed_at: { _is_null: false },
                    };
                  });
                },
                onCancel: () => {
                  setStatusFilter((prev) => {
                    const { reviewed_at, ...rest } = prev;
                    return rest;
                  });
                },
              },
              {
                option: "Signed",
                onApply: () => {
                  setStatusFilter((prev) => {
                    const { id, ...rest } = prev;
                    let tempJhas = prev.id?._in;
                    if (tempJhas)
                      tempJhas = tempJhas.concat(Array.from(signedJhas));
                    else tempJhas = Array.from(signedJhas);
                    return {
                      id: { _in: tempJhas },
                      ...rest,
                    };
                  });
                },
                onCancel: () => {
                  setStatusFilter((prev) => {
                    const { id, ...rest } = prev;
                    let tempJhas = prev.id?._in;
                    tempJhas = tempJhas?.filter((id) => !signedJhas.has(id));
                    if (tempJhas && tempJhas.length > 0)
                      return { id: { _in: tempJhas }, ...rest };
                    else {
                      return rest;
                    }
                  });
                },
              },
            ],
          },
          {
            type: "radio",
            title: "Last Used",
            removeFilter: () => setStart(undefined),
            options: [
              {
                option: "Used Today",
                onClick: () => {
                  setStart(dayjs().startOf("day"));
                },
              },
              {
                option: "Last 15 days",
                onClick: () =>
                  setStart(dayjs().startOf("day").subtract(15, "days")),
              },
              {
                option: "Last 30 days",
                onClick: () =>
                  setStart(dayjs().startOf("day").subtract(30, "days")),
              },
              {
                option: "Last 60 days",
                onClick: () =>
                  setStart(dayjs().startOf("day").subtract(60, "days")),
              },
              {
                option: "Last 90 days",
                onClick: () =>
                  setStart(dayjs().startOf("day").subtract(90, "days")),
              },
            ],
          },
          {
            type: "checkbox",
            title: "PPE",
            removeFilter: () => setSeletedPpe([]),
            options: ppeOptions.map((ppe) => ({
              option: ppe.node.name.en,
              optionType: "checkbox",
              onApply: () => setSeletedPpe((prev) => [...prev, ppe.node.pk]),
              onCancel: () =>
                setSeletedPpe(selectedPpe.filter((id) => id !== ppe.node.pk)),
            })),
          },
        ]}
      />
    </>
  );
};

export default ProjectSubcontractorTasks;
