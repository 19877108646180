import React, { useRef } from "react";
import TextInput from "src/common/components/general/TextInput";

// export type RangeType = [dayjs.Dayjs | null, dayjs.Dayjs | null];

export interface SearchFilterProps {
  newVal?: string;
  placeholder?: string;
  onChange: (newVal: string) => void | boolean;
}

const SearchFilter: React.FC<SearchFilterProps> = (props) => {
  const searchRef = useRef<any>(null);

  return (
    <TextInput
      placeholder={props.placeholder}
      //   inputOptions={}
      onSubmit={(val) => {
        return props.onChange(val) ?? false;
      }}
      darker
    />
  );
};

export default SearchFilter;
