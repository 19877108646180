/**
 * @generated SignedSource<<1cd7030cde771d37630857a49547cc8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ht_license_type_insert_input = {
  certification_id?: string | null | undefined;
  ht_account_id?: string | null | undefined;
  ht_license_type_id?: string | null | undefined;
  ht_license_type_name?: string | null | undefined;
  id?: string | null | undefined;
};
export type HtLicenseTypesModal_upsertLicenseType_Mutation$variables = {
  objects: ReadonlyArray<ht_license_type_insert_input>;
};
export type HtLicenseTypesModal_upsertLicenseType_Mutation$data = {
  readonly insert_ht_license_type: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type HtLicenseTypesModal_upsertLicenseType_Mutation = {
  response: HtLicenseTypesModal_upsertLicenseType_Mutation$data;
  variables: HtLicenseTypesModal_upsertLicenseType_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "ht_license_type_ht_account_id_certification_id_key",
          "update_columns": [
            "ht_license_type_id",
            "ht_license_type_name"
          ]
        }
      }
    ],
    "concreteType": "ht_license_type_mutation_response",
    "kind": "LinkedField",
    "name": "insert_ht_license_type",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HtLicenseTypesModal_upsertLicenseType_Mutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HtLicenseTypesModal_upsertLicenseType_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "25e0178e121073bebfa7fc6cd4c4d413",
    "id": null,
    "metadata": {},
    "name": "HtLicenseTypesModal_upsertLicenseType_Mutation",
    "operationKind": "mutation",
    "text": "mutation HtLicenseTypesModal_upsertLicenseType_Mutation(\n  $objects: [ht_license_type_insert_input!]!\n) {\n  insert_ht_license_type(objects: $objects, on_conflict: {constraint: ht_license_type_ht_account_id_certification_id_key, update_columns: [ht_license_type_id, ht_license_type_name]}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a70af3919df0b4e3b4b595464df5269";

export default node;
