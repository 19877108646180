import { GetIncidentByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";

const getIncidentDocuments = (
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
  type: string,
) => {
  const documents = incident.incident_types.find(
    (incidentType) => incidentType.type_value === type,
  )?.documents;

  return documents;
};

export default getIncidentDocuments;
