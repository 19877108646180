import { after } from "node:test";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useState } from "react";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";

type FormValues = {
  email: string;
};

export type SendEmailModalProps = {
  title: string;
  onSubmit: (values: FormValues) => void;
  afterClose?: () => void;
};

export type SendEmailModalRef = FModalRef<FormValues> | null;
const SendEmailModal = forwardRef<SendEmailModalRef, SendEmailModalProps>(
  ({ onSubmit, title, afterClose }, ref) => {
    const modal = useRef<FModalRef<FormValues>>(null);
    const [loading, setLoading] = useState(false);

    useImperativeHandle<SendEmailModalRef, SendEmailModalRef>(
      ref,
      () => modal.current,
    );

    const sendEmail = async () => {
      const form = modal.current?.form;
      if (!form) return;
      try {
        const formValues: FormValues = await form.validateFields();
        setLoading(true);
        onSubmit(formValues);
        form.resetFields();
        setLoading(false);
      } catch (e) {
        console.log("Validate Failed:", e);
      }
    };

    return (
      <FModal
        afterClose={afterClose}
        ref={modal}
        title={title}
        okText="Send"
        confirmLoading={loading}
        onOk={sendEmail}
      >
        <FModal.Text
          name="email"
          label="Email to"
          requiredMessage="Enter an email"
          rules={[{ type: "email", message: "Please enter a valid E-mail!" }]}
          props={{
            placeholder: "example@company.com",
            onKeyDown: (event) => {
              if (event.key === "Enter") {
                sendEmail();
              }
              return;
            },
          }}
        />
      </FModal>
    );
  },
);

export default SendEmailModal;
