import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "react-relay/hooks";
import { Environment } from "relay-runtime";
import { AddJHAToProjectOrCompanyQuery } from "src/common/types/generated/relay/AddJHAToProjectOrCompanyQuery.graphql";

const addJHAToProjectOrCompanyQuery = graphql`
  query AddJHAToProjectOrCompanyQuery($taskIds: [uuid!]!) {
    task_connection(where: { id: { _in: $taskIds } }) {
      edges {
        node {
          ...TaskFrag @relay(mask: false)
        }
      }
    }
  }
`;

export const getJHAData = async (
  environment: Environment,
  taskIds: Array<string>,
) => {
  const request = fetchQuery<AddJHAToProjectOrCompanyQuery>(
    environment,
    addJHAToProjectOrCompanyQuery,
    { taskIds },
    { fetchPolicy: "network-only" },
  );
  const data = await request.toPromise();

  return data?.task_connection.edges;
};

export default addJHAToProjectOrCompanyQuery;
