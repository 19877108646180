/**
 * @generated SignedSource<<46d83e19130248b4bbf21440538a3e96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectTable_projects$data = {
  readonly project_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly completed: boolean;
        readonly gc_business_unit: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        } | null | undefined;
        readonly gc_division: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        } | null | undefined;
        readonly gc_office: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        } | null | undefined;
        readonly general_contractor: {
          readonly name: string;
        };
        readonly name: string;
        readonly pk: string;
        readonly project_employees_aggregate: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
      };
    }>;
  };
  readonly " $fragmentType": "ProjectTable_projects";
};
export type ProjectTable_projects$key = {
  readonly " $data"?: ProjectTable_projects$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectTable_projects">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "project_connection"
],
v1 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./ProjectTableRefetchableQuery.graphql')
    }
  },
  "name": "ProjectTable_projects",
  "selections": [
    {
      "alias": "project_connection",
      "args": [
        {
          "items": [
            {
              "kind": "Literal",
              "name": "order_by.0",
              "value": {
                "completed": "asc"
              }
            },
            {
              "kind": "Variable",
              "name": "order_by.1",
              "variableName": "order_by"
            }
          ],
          "kind": "ListValue",
          "name": "order_by"
        },
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "projectConnection",
      "kind": "LinkedField",
      "name": "__ProjectTable_project_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "projectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "project",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "completed",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "where",
                      "value": {
                        "status": {
                          "_eq": "Active"
                        }
                      }
                    }
                  ],
                  "concreteType": "project_employee_aggregate",
                  "kind": "LinkedField",
                  "name": "project_employees_aggregate",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "project_employee_aggregate_fields",
                      "kind": "LinkedField",
                      "name": "aggregate",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "count",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "project_employees_aggregate(where:{\"status\":{\"_eq\":\"Active\"}})"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "gc_division",
                  "kind": "LinkedField",
                  "name": "gc_division",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "gc_business_unit",
                  "kind": "LinkedField",
                  "name": "gc_business_unit",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "gc_office",
                  "kind": "LinkedField",
                  "name": "gc_office",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "general_contractor",
                  "kind": "LinkedField",
                  "name": "general_contractor",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "c638334e801ef11727eeaf606b415499";

export default node;
