import { TablePaginationConfig } from "antd/lib/table";
import { ColumnsType } from "antd/lib/table/interface";
import React from "react";
import BaseTable, {
  BaseTableProps,
} from "src/common/components/tables/basic/BaseTable";
import { General_Contractor_Employee } from "src/common/types/generated/apollo/graphQLTypes";
import styled from "styled-components";

type DataProp = General_Contractor_Employee;
type Props = Omit<BaseTableProps<Partial<DataProp>>, "columns">;

// TODO fix with project_crew to find sub
const GeneralContractorEmployeesColumns = styled(BaseTable).attrs(
  ({ pagination }: Props) => {
    const columns: ColumnsType = [
      {
        title: "Name",
        key: "name",
        dataIndex: ["user", "name"],
      },
      {
        title: "Title",
        key: "title",
        dataIndex: ["employee_title", "name", "en"],
      },
      {
        title: "Email",
        key: "email",
        dataIndex: ["user", "email"],
      },
      {
        title: "Phone",
        key: "phone",
        dataIndex: ["user", "phone_number"],
      },
      {
        title: "Username",
        key: "username",
        dataIndex: ["user", "username"],
      },
    ];

    return {
      columns,
      rowKey: "id",
      summary: () => (
        <tr>
          <th>Total</th>
          <td colSpan={columns.length - 1}>
            {!!pagination && pagination.total}
          </td>
        </tr>
      ),
      pagination: {
        simple: true,
        size: "small",
        ...pagination,
      } as TablePaginationConfig,
    };
  },
)``;

export default GeneralContractorEmployeesColumns as (p: Props) => JSX.Element;
