import { Button, Input, Row } from "antd";
import { signInWithCustomToken } from "firebase/auth";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "src/common/functions/firebase";

const AToken = () => {
  const navigate = useNavigate();
  const [tok, setTok] = useState<string>();
  return (
    <Row style={{ height: "10vh" }} justify="space-around" align="middle">
      Login Token
      <Input
        onChange={(e) => {
          e.preventDefault();
          setTok(e.target.value);
        }}
      />
      <Button
        onClick={(e) => {
          e.preventDefault();
          if (tok) {
            signInWithCustomToken(auth, tok).then(() => {
              navigate("/");
            });
          }
        }}
        type="primary"
      >
        Done
      </Button>
    </Row>
  );
};

export default AToken;
