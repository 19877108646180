/**
 * @generated SignedSource<<ad2474b962f6a9dd826c00d2a8b22f33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GCAddNewProjectQuery$variables = {
  uid: string;
};
export type GCAddNewProjectQuery$data = {
  readonly general_contractor_employee_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly uid: string;
        readonly user: {
          readonly id: string;
          readonly name: string;
        };
      };
    }>;
  };
  readonly state_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly code: string;
        readonly name: string;
      };
    }>;
  };
  readonly subcontractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly pk: string;
      };
    }>;
  };
  readonly user_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly employee: {
          readonly general_contractor: {
            readonly agc_universal_orientation: boolean;
            readonly gc_orientations: ReadonlyArray<{
              readonly pk: string;
            }>;
            readonly require_dob: boolean;
            readonly require_phone: boolean;
          };
          readonly general_contractor_id: string;
        } | null | undefined;
      };
    }>;
  };
};
export type GCAddNewProjectQuery = {
  response: GCAddNewProjectQuery$data;
  variables: GCAddNewProjectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "uid"
  }
],
v2 = [
  {
    "fields": [
      {
        "fields": (v1/*: any*/),
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "general_contractor_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "require_dob",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "require_phone",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agc_universal_orientation",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "type": {
        "_eq": "corporate"
      }
    }
  }
],
v8 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v9 = {
  "name": "asc"
},
v10 = [
  {
    "fields": [
      {
        "fields": [
          {
            "fields": (v1/*: any*/),
            "kind": "ObjectValue",
            "name": "uid"
          }
        ],
        "kind": "ObjectValue",
        "name": "employees"
      }
    ],
    "kind": "ObjectValue",
    "name": "general_contractor"
  }
],
v11 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "user": (v9/*: any*/)
    }
  },
  {
    "fields": (v10/*: any*/),
    "kind": "ObjectValue",
    "name": "where"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "user",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v13/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "kind": "Literal",
  "name": "order_by",
  "value": (v9/*: any*/)
},
v17 = [
  (v16/*: any*/),
  {
    "fields": [
      {
        "fields": [
          {
            "fields": (v10/*: any*/),
            "kind": "ObjectValue",
            "name": "project"
          }
        ],
        "kind": "ObjectValue",
        "name": "subcontractor_projects"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v18 = [
  (v16/*: any*/)
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v20 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v21 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCAddNewProjectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor",
                        "kind": "LinkedField",
                        "name": "general_contractor",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": (v7/*: any*/),
                            "concreteType": "orientation",
                            "kind": "LinkedField",
                            "name": "gc_orientations",
                            "plural": true,
                            "selections": [
                              (v8/*: any*/)
                            ],
                            "storageKey": "gc_orientations(where:{\"type\":{\"_eq\":\"corporate\"}})"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "general_contractor_employeeConnection",
        "kind": "LinkedField",
        "name": "general_contractor_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractor_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v17/*: any*/),
        "concreteType": "subcontractorConnection",
        "kind": "LinkedField",
        "name": "subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v18/*: any*/),
        "concreteType": "stateConnection",
        "kind": "LinkedField",
        "name": "state_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "stateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "state",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "state_connection(order_by:{\"name\":\"asc\"})"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCAddNewProjectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor",
                        "kind": "LinkedField",
                        "name": "general_contractor",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": (v7/*: any*/),
                            "concreteType": "orientation",
                            "kind": "LinkedField",
                            "name": "gc_orientations",
                            "plural": true,
                            "selections": [
                              (v20/*: any*/),
                              (v21/*: any*/),
                              (v13/*: any*/)
                            ],
                            "storageKey": "gc_orientations(where:{\"type\":{\"_eq\":\"corporate\"}})"
                          },
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "general_contractor_employeeConnection",
        "kind": "LinkedField",
        "name": "general_contractor_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractor_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v15/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v17/*: any*/),
        "concreteType": "subcontractorConnection",
        "kind": "LinkedField",
        "name": "subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v14/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v18/*: any*/),
        "concreteType": "stateConnection",
        "kind": "LinkedField",
        "name": "state_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "stateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "state",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  (v19/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "state_connection(order_by:{\"name\":\"asc\"})"
      }
    ]
  },
  "params": {
    "cacheID": "d6e6db94ae651dbf3711432aa0a3cfc4",
    "id": null,
    "metadata": {},
    "name": "GCAddNewProjectQuery",
    "operationKind": "query",
    "text": "query GCAddNewProjectQuery(\n  $uid: uuid!\n) {\n  user_connection(where: {id: {_eq: $uid}}) {\n    edges {\n      node {\n        employee {\n          general_contractor_id\n          general_contractor {\n            require_dob\n            require_phone\n            agc_universal_orientation\n            gc_orientations(where: {type: {_eq: \"corporate\"}}) {\n              pk: id\n              id\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n  general_contractor_employee_connection(where: {general_contractor: {employees: {uid: {_eq: $uid}}}}, order_by: {user: {name: asc}}) {\n    edges {\n      node {\n        uid\n        user {\n          id\n          name\n        }\n        id\n      }\n    }\n  }\n  subcontractor_connection(where: {subcontractor_projects: {project: {general_contractor: {employees: {uid: {_eq: $uid}}}}}}, order_by: {name: asc}) {\n    edges {\n      node {\n        pk: id\n        name\n        id\n      }\n    }\n  }\n  state_connection(order_by: {name: asc}) {\n    edges {\n      node {\n        name\n        code\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "75173994997ae1e662cbda15a685c7e2";

export default node;
