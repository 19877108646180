import React, { useEffect, useRef, useState } from "react";

import { Image as ImageAntD } from "antd";

export interface ImageAutomaticSizeProps {
  perfectImageSidePx: number;
  imageProps: React.ComponentProps<typeof ImageAntD>;
}

const ImageAutomaticSize: React.FC<ImageAutomaticSizeProps> = (props) => {
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const imageSrc = props.imageProps.src;

  const [containerSize, setContainerSize] = useState({
    width: props.perfectImageSidePx,
    height: props.perfectImageSidePx,
  }); // Initial size

  useEffect(() => {
    // Function to calculate container size to maintain a fixed area
    const calculateContainerSize = () => {
      if (!imageSrc) return;

      const desiredArea = containerSize.width * containerSize.height;
      const image = new Image();
      image.src = imageSrc;

      image.onload = () => {
        const aspectRatio = image.naturalWidth / image.naturalHeight;
        const width = Math.sqrt(desiredArea * aspectRatio);
        const height = desiredArea / width;

        setContainerSize({ width, height });
      };
    };

    calculateContainerSize();

    // Recalculate container size if the window is resized
    window.addEventListener("resize", calculateContainerSize);
    return () => {
      window.removeEventListener("resize", calculateContainerSize);
    };
  }, [imageSrc]);

  return (
    <div
      style={{
        width: containerSize.width,
        height: containerSize.height,
      }}
      ref={imageContainerRef}
    >
      <ImageAntD {...props.imageProps} />
    </div>
  );
};

export default ImageAutomaticSize;
