import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  Observable,
  Operation,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "apollo-utilities";
import { auth } from "src/common/functions/firebase";
import keys from "src/common/constants/keys";
import cache from "./cache";
import {
  isDevelopmentBuild,
  isDevelopmentEnvironment,
} from "../../constants/environment";
import * as Sentry from "@sentry/react";

const httpLink = new HttpLink({
  uri: keys.hasuraUrl,
  credentials: "include",
});

const link = ApolloLink.from([
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    httpLink,
    httpLink,
  ),
]);

const request = async (operation: Operation) => {
  if (isDevelopmentEnvironment || isDevelopmentBuild) {
    console.log("op", operation.operationName, operation.variables);
  }

  const token = await auth.currentUser?.getIdToken();

  const headers: any = {};

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  operation.setContext({ headers });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: any;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

const apolloClient = new ApolloClient({
  connectToDevTools: true,
  cache,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation }) => {
      // Error tracking

      if (graphQLErrors && graphQLErrors.length > 0) {
        const errors: string[] = [];
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          const error = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
          errors.push(error);
          console.log(error, extensions);
        });

        if (process.env.NODE_ENV === "production") {
          Sentry.captureMessage(errors.join("\n"), {
            tags: {
              location: "apolloClient",
              operationName: operation.operationName,
            },
          });
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        if (process.env.NODE_ENV === "production") {
          Sentry.captureException(networkError, {
            tags: {
              location: "apolloClient",
              operationName: operation.operationName,
            },
          });
        }
      }
    }),
    requestLink,
    link,
  ]),
});

export default apolloClient;
