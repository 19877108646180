/**
 * @generated SignedSource<<dcf60cc10397e2e5b114519604b139fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectTeam_deleteProjectEmployee_Mutation$variables = {
  projectId: string;
  userId: string;
};
export type GCProjectTeam_deleteProjectEmployee_Mutation$data = {
  readonly delete_project_employee: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type GCProjectTeam_deleteProjectEmployee_Mutation = {
  response: GCProjectTeam_deleteProjectEmployee_Mutation$data;
  variables: GCProjectTeam_deleteProjectEmployee_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "userId"
              }
            ],
            "kind": "ObjectValue",
            "name": "employee_id"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "projectId"
              }
            ],
            "kind": "ObjectValue",
            "name": "project_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_employee_mutation_response",
    "kind": "LinkedField",
    "name": "delete_project_employee",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectTeam_deleteProjectEmployee_Mutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCProjectTeam_deleteProjectEmployee_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f8e10a8fd10d3b47e82369cb469a16d5",
    "id": null,
    "metadata": {},
    "name": "GCProjectTeam_deleteProjectEmployee_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectTeam_deleteProjectEmployee_Mutation(\n  $userId: uuid!\n  $projectId: uuid!\n) {\n  delete_project_employee(where: {employee_id: {_eq: $userId}, project_id: {_eq: $projectId}}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "901a770ff8dda47eeb0d5a7424ffcf79";

export default node;
