import React, { FC, useRef, useState } from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import ProjectWorkerTable from "src/common/components/tables/ProjectWorkerTable";

import { useSiteOrientationGcDashboardDataQuery$data } from "src/common/types/generated/relay/useSiteOrientationGcDashboardDataQuery.graphql";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import DrugtestActionTable from "./DrugtestActionTable";
import Button from "src/common/components/general/Button";

interface GCProjectOrientationTestProps {
  projectId: string;
  data: useSiteOrientationGcDashboardDataQuery$data;
}

const GCProjectOrientationDrugTest: FC<GCProjectOrientationTestProps> = ({
  projectId,
  data,
}) => {
  const [showHistory, setShowHistory] = useState(false);
  const orientations = (data?.orientation_connection.edges || []).map(
    (v) => v.node
  );
  const projectData = (data.project_connection.edges || [])[0]?.node;

  const allRequiredOrientations = orientations.filter(
    (o) =>
      o.type !== "universal" &&
      o.project_orientations[0] &&
      o.project_orientations[0].required_by_all_workers
  );
  const hasSlides = allRequiredOrientations.length > 0;
  const hasInPerson = projectData.in_person_orientation;
  const notClearedTableRef = useRef<DataScrollTableRef>(null);
  const positiveTableRef = useRef<DataScrollTableRef>(null);
  const nonNegativeTableRef = useRef<DataScrollTableRef>(null);
  const completedDrugtestRef = useRef<DataScrollTableRef>(null);
  if (!projectData) {
    throw new Error("Project id not found, ");
  }
  const refetchAll = () => {
    console.log(completedDrugtestRef);

    notClearedTableRef.current?.refetch();
    positiveTableRef.current?.refetch();
    nonNegativeTableRef.current?.refetch();
    completedDrugtestRef.current?.refetch();
    console.log("REFRCHXHJXSH");
  };
  return (
    <div className="w-full h-full overflow-y-auto">
      <DrugtestActionTable
        title="Not Yet Cleared To Work"
        ref={notClearedTableRef}
        onSave={refetchAll}
        data={data}
        where={{
          project_id: { _eq: projectId },
          deleted_at: { _is_null: true },
          subcontractor_worker: {},
          _or: [
            { _not: { user_orientation: {} } },
            {
              user_orientation: {
                _not: { drug_test: { status: { _is_null: false } } }, // optimized below one to this
                // _or: [
                //   { _not: { drug_test: {} } },
                //   { drug_test: { status: { _is_null: true } } },
                // ],
              },
            },
          ],
        }}
        projectId={projectId}
        positiveOption
        usePreviousDTs
        negativeOption
        nonNegativeOption
      />
      <DrugtestActionTable
        title="Non-Negative Results"
        ref={nonNegativeTableRef}
        showStatus
        onSave={refetchAll}
        data={data}
        where={{
          project_id: { _eq: projectId },
          deleted_at: { _is_null: true },
          subcontractor_worker: {},
          user_orientation: {
            drug_test: { status: { _eq: "non-negative" } },
          },
        }}
        usePreviousDTs
        projectId={projectId}
        positiveOption
        negativeOption
      />
      <DrugtestActionTable
        title="Positive Results"
        ref={positiveTableRef}
        showStatus
        resultColumnHeader="Update Drug Test Results"
        onSave={refetchAll}
        data={data}
        projectId={projectId}
        includePrevPositiveProjectDTs={showHistory}
        retest
        returnToWorkOption
        positiveOption
        headerComponent={
          <Button
            label={showHistory ? "Hide History" : "Show History"}
            onClick={() => setShowHistory((i) => !i)}
          />
        }
        negativeOption
        nonNegativeOption
        where={{
          project_id: { _eq: projectId },
          deleted_at: { _is_null: true },
          subcontractor_worker: {},

          ...(showHistory
            ? {
                _or: [
                  {
                    user_orientation: {
                      drug_test: {
                        status: { _neq: "non-negative", _is_null: false },
                      },
                    },
                    user: {
                      worker: {
                        worker_drug_tests: {
                          status: { _eq: "positive" },
                          project_id: { _eq: projectId },
                        },
                      },
                    },
                  },
                  {
                    user_orientation: {
                      drug_test: { status: { _eq: "positive" } },
                    },
                  },
                ],
              }
            : {
                user_orientation: {
                  drug_test: { status: { _eq: "positive" } },
                },
              }),
        }}
      />
      <ProjectWorkerTable
        ref={completedDrugtestRef}
        hideContactInfo
        loadAll
        projectId={projectId}
        // disableSuspense
        title="Negative Result"
        hasInPerson={hasInPerson}
        hasSlides={hasSlides}
        hasUniversal={
          projectData.agc_universal_orientation &&
          data.orientation_connection.edges.filter(
            (o) => o.node.type === "universal"
          ).length > 0
        }
        searchDataIndex={["user", "name"]}
        excludedKeys={[
          "trainingAndCertifications",
          "project",
          "title",
          "role",
          "inPersonOrientatedDate",
          "registeredDate",
          "status",
          "dateLogged",
          "universal_orientated_at",
        ]}
        includePrevPositiveProjectDTs
        defaultSortColumnKey="drugtestDate"
        where={{
          project_id: { _eq: projectId },
          // deleted_at: { _is_null: true },
          is_last: { _eq: true },
          user_orientation: {
            drug_test: {
              status: { _in: ["negative", "return-to-work-eligible"] },
            },
          },
        }}
      />
    </div>
  );
};
export default withCustomSuspense(GCProjectOrientationDrugTest);
