import React from "react";
import BaseTable, {
  BaseTableProps,
} from "src/common/components/tables/basic/BaseTable";
import styled from "styled-components";
import TableFooterTotal from "./TableFooterTotal";
import { GetProjectCovidConfigByPkQueryResult } from "src/common/types/generated/apollo/graphQLTypes";

// TODO add this to tables
type DataProp = NonNullable<
  NonNullable<
    GetProjectCovidConfigByPkQueryResult["data"]
  >["project_covid_config_by_pk"]
>["project_covid_notify_employees"][number]["project_employee"]["employee"];

type Props = Omit<BaseTableProps<Partial<DataProp>>, "columns"> & {
  onDelete?: (r: DataProp) => any;
};
type EmployeesTable = (p: Props) => JSX.Element;

const EmployeesTable = styled(BaseTable).attrs(
  ({ pagination, onDelete, ...props }: Props) => {
    const columns = [
      {
        title: "Name",
        key: "name",
        dataIndex: ["user", "name"],
      },
      {
        title: "Title",
        key: "title",
        dataIndex: ["employee_title", "name", "clientText"],
      },
      {
        title: "Email",
        key: "email",
        dataIndex: ["user", "email"],
      },
      {
        title: "Phone Number",
        key: "phone",
        dataIndex: ["user", "phone_number"],
      },
    ];

    return {
      ...props,
      columns,
      rowKey: (w: any) => w.id,
      summary: () =>
        pagination && (
          <TableFooterTotal cols={columns.length} total={pagination.total} />
        ),
    };
  }
)``;

export default EmployeesTable as EmployeesTable;
