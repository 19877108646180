import { Form, Modal, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";

interface SetupCovidValues {
  requirements: string;
  workerRestrictionDaysLength: number;
  contactProjectEmployeeId: string;
}

interface SetupCovidProps {
  projectEmployees: Array<{ id: string; name: string; title?: string | null }>;
  visible: boolean;
  onCreate: (values: SetupCovidValues) => void;
  onCancel: () => void;
}

const SetupCovidModal: React.FC<SetupCovidProps> = ({
  projectEmployees,
  visible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    (<Modal
      open={visible}
      title="Setup your project for COVID-19"
      okText="Done"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values as SetupCovidValues);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="requirements"
          label="Project Requirements"
          rules={[{ required: true, message: "Enter the Requirements" }]}
        >
          <TextArea style={{ minHeight: 200 }} />
        </Form.Item>
        <Form.Item
          name="workerRestrictionDaysLength"
          label="Worker Restriction Length"
          rules={[{ required: true, message: "Choose a Length" }]}
        >
          <Select style={{ width: "100%" }}>
            {Array.from({ length: 28 }).map((_, i) => (
              <Select.Option key={i + 1} value={i + 1}>
                {i + 1} Day(s)
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="contactProjectEmployeeId"
          label="Point of Contact Employee"
          rules={[{ required: true, message: "Choose an Employee" }]}
        >
          <Select style={{ width: "100%" }}>
            {projectEmployees.map((c) => (
              <Select.Option key={c.id} value={c.id}>
                {c.name}
                {c.title ? `, ${c.title}` : ""}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>)
  );
};

export default SetupCovidModal;
