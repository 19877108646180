import React, { FC } from "react";
import { RoleConvert } from "./tables/TaskEditsTable";
import dayjs from "dayjs";
import { Image } from "antd";
import DocumentToVerifyProps from "../types/manual/DocumentToVerifyProps";
import remToPx from "../functions/remToPx";
import ImageAutomaticSize from "./general/images/ImageAutomaticSize";

interface CreateProcessingContent {
  docsToVerify: readonly DocumentToVerifyProps[];
}

export const CreateProcessingContent: FC<CreateProcessingContent> = ({
  docsToVerify,
}) => (
  <div className="overflow-scroll max-h-32">
    {docsToVerify.map((docToVerify, i) => {
      const uploadedByUser = docToVerify?.uploaded_by_user;
      const [title, company] = RoleConvert(uploadedByUser);
      const isWorker = uploadedByUser?.role === "worker";
      return (
        <div key={docToVerify.pk}>
          {docToVerify.front_image ? (
            <div>
              {i !== 0 && (
                <div>
                  <br />
                  <hr />
                  <br />
                </div>
              )}
              <div className="font-bold text-grey text-1">Pending</div>
              <div>{dayjs(docToVerify.created_at).format("LL")}</div>
              {docToVerify.project?.name && (
                <div>
                  Project: {docToVerify.project?.name},{" "}
                  {docToVerify.project?.general_contractor.name}
                </div>
              )}
              <div>
                Uploaded By:{" "}
                {uploadedByUser
                  ? `${uploadedByUser?.name} ${
                      isWorker
                        ? uploadedByUser.worker?.worker_title?.translation.en
                        : title
                    }, ${company}`
                  : ""}
              </div>
              <div className="w-6/12 inline-block	">
                {docToVerify.front_image?.url && (
                  <span>
                    <ImageAutomaticSize
                      perfectImageSidePx={remToPx(6)}
                      imageProps={{
                        src: docToVerify.front_image.url,
                      }}
                    />
                    <br />
                    <span className="ml-1">Front</span>
                  </span>
                )}
              </div>
              <div className="w-6/12 inline-block">
                {docToVerify.back_image?.url && (
                  <span>
                    <ImageAutomaticSize
                      perfectImageSidePx={remToPx(6)}
                      imageProps={{
                        src: docToVerify.back_image.url,
                      }}
                    />
                    <br />
                    <span className="ml-1">Back</span>
                  </span>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    })}
  </div>
);
export default CreateProcessingContent;
