import React, { useContext } from "react";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import SiteOrientationStepsUIFullName from "../../../../../../../components/stepsUI/fullName/SiteOrientationStepsUIFullName";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsFullNameProps
  extends SiteOrientationStepProps {}

const SiteOrientationInPersonWorkerStepsFullName: React.FC<
  SiteOrientationInPersonWorkerStepsFullNameProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();
  const langStrings = useCurrentLangStrings();
  const handleNameSubmit = (newFullName: string) => {
    workerContext.updateOrientee((u) =>
      u
        ? {
            ...u,
            name: { old: u.name?.old, new: newFullName },
          }
        : { name: { new: newFullName }, userType: undefined },
    );
    props.onNext();
  };
  return (
    <SiteOrientationStepsUIFullName
      onNameSubmit={handleNameSubmit}
      langStrings={langStrings}
      defaultName={workerContext.orientee?.name?.new}
      onBack={props.onBack}
      onNext={props.onNext}
    />
  );
};

export default SiteOrientationInPersonWorkerStepsFullName;
