//TODO make it return days if the date difference is less than a month

const durationBetweenDates = (d1: Date, d2: Date) => {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0
    ? "less than a month"
    : `${months} month${months > 1 ? "s" : ""}`;
};
export default durationBetweenDates;
