/**
 * @generated SignedSource<<4398da309314042866d102c03fa4b48b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type checklist_item_type_enum = "gc_daily" | "gc_worker_daily_log" | "sub_worker_daily_log" | "%future added value";
export type checklist_item_set_input = {
  created_at?: string | null | undefined;
  data_type?: string | null | undefined;
  description_id?: string | null | undefined;
  did_notify?: boolean | null | undefined;
  id?: string | null | undefined;
  option_response?: string | null | undefined;
  parent_id?: string | null | undefined;
  project_id?: string | null | undefined;
  sort_index?: number | null | undefined;
  subcontractor_id?: string | null | undefined;
  text_response_id?: string | null | undefined;
  type?: checklist_item_type_enum | null | undefined;
  updated_at?: string | null | undefined;
};
export type GCProjectReportsDailySettingsUpdateItemMutation$variables = {
  _set?: checklist_item_set_input | null | undefined;
  id: string;
};
export type GCProjectReportsDailySettingsUpdateItemMutation$data = {
  readonly update_checklist_item_by_pk: {
    readonly id: string;
  } | null | undefined;
};
export type GCProjectReportsDailySettingsUpdateItemMutation = {
  response: GCProjectReportsDailySettingsUpdateItemMutation$data;
  variables: GCProjectReportsDailySettingsUpdateItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "checklist_item",
    "kind": "LinkedField",
    "name": "update_checklist_item_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectReportsDailySettingsUpdateItemMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCProjectReportsDailySettingsUpdateItemMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "6d4c108226596c5908276d87a6a9a22d",
    "id": null,
    "metadata": {},
    "name": "GCProjectReportsDailySettingsUpdateItemMutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectReportsDailySettingsUpdateItemMutation(\n  $id: uuid!\n  $_set: checklist_item_set_input\n) {\n  update_checklist_item_by_pk(pk_columns: {id: $id}, _set: $_set) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2186bf6f9d5a5816b15a4998c0c215fc";

export default node;
