import { Button, Input, Space } from "antd";
import StyledContent from "src/common/components/layouts/StyledContent";
import ProjectsTable from "src/common/components/tables/ProjectsTable";
import {
  GetProjectsDocument,
  Order_By,
  Project,
  Project_Bool_Exp,
  Project_Order_By,
  useInsertProjectsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";

import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateProjectModal from "src/common/components/dialogs/CreateProjectModal";
import useColumnOrderBy from "src/common/hooks/useColumnOrderBy";
import useColumnSearch from "src/common/hooks/useColumnSearch";
import usePaginatedQuery, {
  UsePaginatedQueryParams,
} from "src/common/hooks/usePaginatedQuery";
import * as uuid from "uuid";

const AdminProjects = () => {
  const [createProjectOpen, setCreateProjectOpen] = useState(false);
  const { orderBy, setOrderBy } = useColumnOrderBy<Project_Order_By>({
    name: Order_By.Asc,
  });
  const nameSearch = useColumnSearch();
  const [insertProject, { loading: creatingProject }] =
    useInsertProjectsMutation();

  const navigate = useNavigate();

  const queryVariables = useMemo(
    (): UsePaginatedQueryParams<Project_Bool_Exp> => ({
      where: {
        name: {
          _ilike: nameSearch.value,
        },
      },
      orderBy: orderBy,
      queryDoc: GetProjectsDocument,
      dataIndex: "project",
      aggregateCountIndex: "project_aggregate",
    }),
    [nameSearch.value, orderBy],
  );
  const [projects, { loading, page, setPage, count, pageSize, refetch }] =
    usePaginatedQuery<Partial<Project>>(queryVariables);

  return (
    <StyledContent padding backgroundColor="white">
      <CreateProjectModal
        visible={createProjectOpen}
        onCancel={() => setCreateProjectOpen(false)}
        onCreate={async ({ values }) => {
          setCreateProjectOpen(false);
          const id = uuid.v4();
          await insertProject({
            variables: {
              objects: [
                {
                  id,
                  orientation_project_id: id,
                  name: values.name,
                  union_labor: values.union,
                  general_contractor_id: values.generalContractorId,
                  general_contractors: {
                    data: [
                      {
                        general_contractor_id: values.generalContractorId,
                      },
                    ],
                  },
                  external_id: values.externalId,
                  address: {
                    data: {
                      line_1: values.addressLine1,
                      city: values.city,
                      state_code: values.stateCode,
                      zip_code: values.zip,
                    },
                  },
                  start_date: values.startDate,
                  end_date: values.endDate,
                },
              ],
            },
          });

          refetch();
        }}
      />
      <ProjectsTable
        onRow={(project) => ({
          onClick: () => navigate(`/admin/data/projects/${project.id}`),
        })}
        loading={loading || creatingProject}
        sortColumns={{
          name: true,
          workers: true,
        }}
        onChange={(_: any, filters: any, sorter: any) => {
          setOrderBy(sorter);
        }}
        title={() => (
          <Space direction="vertical">
            <h3>Projects</h3>
            <Button
              type="primary"
              loading={creatingProject}
              onClick={() => setCreateProjectOpen(true)}
            >
              Create Projects
            </Button>
            <Input
              type="primary"
              style={{ margin: "10px", width: "200px" }}
              placeholder={"Search"}
              onChange={(event) => {
                nameSearch.setter(event.target.value);
              }}
            ></Input>
          </Space>
        )}
        searchColumns={{ name: nameSearch.setter }}
        dataSource={projects}
        pagination={{
          current: page,
          pageSize,
          total: count,
          onChange: setPage,
        }}
      />
    </StyledContent>
  );
};

export default AdminProjects;
