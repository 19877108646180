import { Form, Input, message, Modal, notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useState } from "react";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { AddProjectWorkerPocModal_InsertMutation } from "src/common/types/generated/relay/AddProjectWorkerPocModal_InsertMutation.graphql";
import * as uuid from "uuid";

interface AddProjectWorkerPocModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  projectId?: string | null;
}

type FormValues = {
  title: string;
  name: string;
  phoneNumber: string;
};

const AddProjectWorkerPocModal: FC<AddProjectWorkerPocModalProps> = ({
  visible,
  onClose,
  onSubmit,
  projectId,
}) => {
  const [loading, setLoading] = useState(false);
  const [insertProjectWorkerPoc, isUploading] =
    useAsyncMutation<AddProjectWorkerPocModal_InsertMutation>(
      graphql`
        mutation AddProjectWorkerPocModal_InsertMutation(
          $object: project_worker_poc_insert_input!
        ) {
          insert_project_worker_poc_one(object: $object) {
            ...ProjectWorkerPocFrag @relay(mask: false)
          }
        }
      `
    );

  const [form] = Form.useForm();

  return (
    (<Modal
      open={visible}
      confirmLoading={loading}
      title={"Add Crew Leads"}
      okText={"ADD"}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={async () => {
        setLoading(true);
        await form
          .validateFields()
          .then(async (v: FormValues) => {
            console.log(v);

            await insertProjectWorkerPoc({
              variables: {
                object: {
                  id: uuid.v4(),
                  project_id: projectId,
                  title: v.title,
                  name: v.name,
                  phone_number: v.phoneNumber,
                },
              },
            }).then((d) => {
              if (d.insert_project_worker_poc_one?.pk) {
                message.success("Added To SiteForm");
                form.resetFields();
                onSubmit();
              } else {
                message.error("Failed to add");
              }
            });
          })
          .catch((e) => {
            notification.error({
              message: "Failed to add",
              description: e.message,
              duration: null,
            });
            console.log(e);
          });
        setLoading(false);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ numberOfSds: 1 }}
        title="Upload Form"
      >
        <Form.Item
          className="mt-2"
          label="Name"
          name="name"
          rules={[{ required: true, message: "Name required" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="mt-2"
          label="Title"
          name="title"
          rules={[{ required: true, message: "Title required" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="mt-2"
          label="Phone #"
          name="phoneNumber"
          rules={[{ required: true, message: "Phone Number required" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>)
  );
};
export default AddProjectWorkerPocModal;
