import { Button, Space, notification } from "antd";
import React, { FC, useState } from "react";
import AddSubcontractorEmployeeModal from "src/common/components/dialogs/AddSubcontractorEmployeeModal";
import LoadingContent from "src/common/components/general/LoadingContent";
import DataDetailDescriptions, {
  DataDetailItems,
} from "src/common/components/layouts/DataDetailDescriptions";
import StyledContent from "src/common/components/layouts/StyledContent";
import SubcontractorEmployeesTable from "src/common/components/tables/SubcontractorEmployeesTable";
import getAddressText from "src/common/functions/getAddressText";
import {
  GetSubcontractorEmployeesDocument,
  Order_By,
  useCreateSubcontractorEmployeeMutation,
  useDeleteSubcontractorEmployeeMutation,
  useGetSubcontractorEmployeesQuery,
  useGetSubcontractorQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import SubcontractorProps from "src/common/types/manual/SubcontractorProps";
import AdminSubcontractorBillingSection from "./AdminSubcontractorBillingSection";

const AdminSubcontractor: FC<SubcontractorProps> = ({ subcontractorId }) => {
  console.log("AdminSubcontractor", subcontractorId);
  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false);
  const { data: subcontractorData } = useGetSubcontractorQuery({
    variables: {
      subcontractorId,
    },
  });
  const [deleteSubEmployee, { loading: deletingSubEmployee }] =
    useDeleteSubcontractorEmployeeMutation();

  const [createSubEmployee, { loading: creatingSubEmployee }] =
    useCreateSubcontractorEmployeeMutation();

  const { data, loading } = useGetSubcontractorEmployeesQuery({
    variables: {
      where: {
        subcontractor_id: { _eq: subcontractorId },
      },
      order_by: [
        {
          user: {
            name: Order_By.Asc,
          },
        },
      ],
    },
    fetchPolicy: "cache-and-network",
  });

  if (!subcontractorData) {
    return <LoadingContent />;
  }
  const subcontractor = subcontractorData.subcontractor_by_pk;
  if (!subcontractor) {
    return (
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        Subcontractor does not exist.
      </Space>
    );
  }

  const subcontractorItems: DataDetailItems = [
    {
      label: "Name",
      value: subcontractor.name,
      span: 2,
    },
    {
      label: "Trade",
      value: subcontractor.trade?.name,
    },
    {
      label: "Address",
      value: getAddressText(subcontractor.address),
      span: 3,
    },
  ];
  const employeeEmails =
    data?.subcontractor_employee
      .filter((se) => se.user.email)
      .map((se) => ({
        name: se.user.name,
        email: se.user.email!,
      })) ?? [];
  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <StyledContent>
        <DataDetailDescriptions
          title="Subcontractor Info"
          items={subcontractorItems}
        />
      </StyledContent>
      <StyledContent>
        <AddSubcontractorEmployeeModal
          visible={addEmployeeOpen}
          onCancel={() => setAddEmployeeOpen(false)}
          onCreate={async (values) => {
            setAddEmployeeOpen(false);
            await createSubEmployee({
              awaitRefetchQueries: true,
              variables: {
                input: {
                  name: values.name,
                  email: values.email,
                  phone: values.phoneNumber,
                  employeeTitleId: values.titleId,
                  subcontractorId,
                  inviteType: "adminInvite",
                },
              },
              refetchQueries: [
                {
                  query: GetSubcontractorEmployeesDocument,
                  variables: {
                    where: {
                      subcontractor_id: { _eq: subcontractorId },
                    },
                    order_by: [
                      {
                        user: {
                          name: Order_By.Asc,
                        },
                      },
                    ],
                  },
                },
              ],
            });
            notification.open({
              message: `${values.name}'s account was created!`,
              description:
                "They will receive an email with details of how to log into their account.",
            });
          }}
          requirement={false}
        />
        <SubcontractorEmployeesTable
          // sortColumns={{
          //   name: true,
          //   title: true,
          //   phone: true,
          //   username: true,
          //   email: true,
          // }}
          // searchColumns={{
          //   name: nameSearch.setter,
          //   phone: phoneSearch.setter,
          //   username: usernameSearch.setter,
          //   email: emailSearch.setter,
          // }}
          // filterColumns={{ title: titleFilter.filterOptions }}
          // onChange={(_, filters, sorter) => {
          //   setOrderBy(sorter);
          //   titleFilter.setFilters(filters.title!);
          // }}
          title={() => (
            <Space direction="vertical">
              <h3>Employees</h3>
              <Space>
                <Button
                  type="primary"
                  loading={creatingSubEmployee}
                  onClick={() => setAddEmployeeOpen(true)}
                >
                  Add Employee
                </Button>
              </Space>
            </Space>
          )}
          onDelete={(employee) => {
            deleteSubEmployee({
              awaitRefetchQueries: true,
              variables: {
                userId: employee.user_id,
              },
              refetchQueries: [
                {
                  query: GetSubcontractorEmployeesDocument,
                  variables: {
                    where: {
                      subcontractor_id: { _eq: subcontractorId },
                    },
                    order_by: [
                      {
                        user: {
                          name: Order_By.Asc,
                        },
                      },
                    ],
                  },
                },
              ],
            });
          }}
          loading={creatingSubEmployee || loading || deletingSubEmployee}
          dataSource={(data?.subcontractor_employee as any) ?? []}
          pagination={{
            pageSize: 10,
            total: data?.subcontractor_employee.length ?? 0,
          }}
        />
      </StyledContent>
      <AdminSubcontractorBillingSection
        subcontractorId={subcontractorId}
        employeeEmails={employeeEmails}
      />
    </Space>
  );
};

export default AdminSubcontractor;
