import dayjs from "dayjs";
import React from "react";
import {
  SiteBoardDeliveriesCalendarPillType,
} from "../utilities/siteBoardDeliveriesTypes";

export interface SiteBoardDeliveryPillProps {
  id: string;
  text: string;
  colorCss: string;
}

const SiteBoardDeliveryPill: React.FunctionComponent<
  SiteBoardDeliveriesCalendarPillType
> = (props) => {
  return (
    <div
      style={{ backgroundColor: props.colorCss }}
      className={`max-w-full py-0.5 px-1 rounded-1 inline-flex whitespace-nowrap`}
    >
      <span className="text-white font-accent text-1.5 truncate ">
        {props.text}
      </span>
    </div>
  );
};

export interface SiteBoardDeliveryProps {
  gateCalendars: SiteBoardDeliveryPillProps[];
  deliveryContent?: string;
  deliveryName: string;
  companyName: string;
  date: Date;
  endAt: Date;
}

const SiteBoardDelivery: React.FunctionComponent<SiteBoardDeliveryProps> = (
  props,
) => {
  const momentDate = dayjs(props.date);

  const weekDay = momentDate.format("ddd");
  const monthDay = momentDate.format("MMM Do");
  const startTime = momentDate.format("h:mm");
  const startTimeMeridiem = momentDate.format("a");
  const endTime = dayjs(props.endAt).format("h:mm");
  const endTimeMeridien = dayjs(props.endAt).format("a");

  const dateIsToday = momentDate.isSame(dayjs(), "day");

  return (
    <div className="grid w-full grid-cols-10 gap-2 px-1 py-0.5 rounded-1 bg-semantic-inactive-dark text-white">
      <div className="flex flex-col gap-0.5 col-span-4 justify-center">
        <div className={`flex flec-row gap-1`}>
          {!dateIsToday && <p className={`text-3 font-accent`}>{weekDay}</p>}
          {!dateIsToday && <p className={`text-3 font-accent`}>{monthDay}</p>}
          <p className={`text-3`}>
            <span className="font-accent">{startTime}</span>
            <span className="text-2">{startTimeMeridiem}</span>

            <span className="text-2"> {`→`} </span>
            <span className="font-accent">{endTime}</span>

            <span className="text-2">{endTimeMeridien}</span>
          </p>
        </div>
        <p
          className={`flex flex-row items-baseline gap-1 text-1.5 font-normal`}
        >
          <span className={`font-normal whitespace-nowrap`}>created by</span>
          <span className="truncate text-2">{props.companyName}</span>
        </p>
      </div>
      <div className="flex flex-col justify-center col-span-3 ga-0.5">
        <p className={`text-3 truncate`}>{props.deliveryName}</p>
        {props.deliveryContent ? (
          <p className={`text-2 truncate`}>{props.deliveryContent}</p>
        ) : null}
      </div>
      <div
        className={`flex flex-row justify-end items-center w-full col-span-3`}
      >
        <div className="inline-flex max-w-full -m-0.25 flex-wrap justify-end">
          {props.gateCalendars.map((calendar) => (
            <div key={calendar.id} className="m-0.25 max-w-full">
              <SiteBoardDeliveryPill {...calendar} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SiteBoardDelivery;
