import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";
import LiveTimeline from "../components/SitedeliveryLiveTimeline";
import { DeliveryType } from "../utilities/sitedeliveryTypes";
import GCProjectCalendarSitedeliveryDeliveriesColumn from "./GCProjectCalendarSitedeliveryDeliveriesColumn";
import SitedeliveryCalendarTimeStamps from "../components/time-stamps/SitedeliveryCalendarTimeStamps";

export interface GCProjectCalendarSitedeliveryDeliveriesDayProps {
  date: dayjs.Dayjs;
  deliveries: DeliveryType[];
  deliveryContentShownOnCard?: boolean;
  keepTimelineInView?: boolean;
}

const GCProjectCalendarSitedeliveryDeliveriesDay: React.FC<
  GCProjectCalendarSitedeliveryDeliveriesDayProps
> = (props) => {
  const isToday = props.date.isSame(dayjs(), "day");

  return (
    <div
      className={
        "flex flex-col h-full rounded-t-1 bg-white border-l border-r border-b border-suplementary-3"
      }
    >
      <div
        className={
          "sticky rounded-t-1 flex flex-row top-0 z-50 bg-white h-2"
        }
      >
        <div
          className={clsx(
            "flex gap-0.5 py-0.5 pr-0.5 rounded-t-1 pl-1 border-t justify-between items-center w-full",
            !isToday
              ? "bg-white border-suplementary-3"
              : "bg-suplementary-1 border-suplementary-3",
          )}
        >
          <div className="flex flex-row items-center justify-center flex-1 w-full h-2rem">
            <span className="block w-full text-center truncate">
              <span className="font-accent text-0.75">{`${
                !isToday ? "" : "Today, "
              }`}</span>
              <span className="font-accent text-1">
                {`${props.date.format("dddd")}`}
              </span>{" "}
              <span className="text-1">{`${props.date.format("MMM Do")}`}</span>
            </span>
          </div>
        </div>
      </div>
      <div className="relative flex flex-col px-0.25 w-full h-full bg-white">
        <LiveTimeline keepTimelineInView={props.keepTimelineInView} />

        <GCProjectCalendarSitedeliveryDeliveriesColumn
          date={props.date}
          deliveries={props.deliveries}
          keepTimeLineInView={!!props.keepTimelineInView}
          // deliveryContentShownOnCard={props.deliveryContentShownOnCard}
        />
        <SitedeliveryCalendarTimeStamps
          type={"lines"}
          keepTimeLineInView={!!props.keepTimelineInView}
        />
      </div>
    </div>
  );
};

export default GCProjectCalendarSitedeliveryDeliveriesDay;
