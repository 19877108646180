import React from "react";
import { Navigate, useParams } from "react-router-dom";
import NestedRoute from "src/common/types/manual/NestedRoute";
import GCReports from "../../components/GCReports";
export const ReportManagementRedirect: React.FC = () => {
  const { projectId } = useParams();
  return <Navigate to={`/gce/projects/${projectId}/reports/management`} />;
};
const gcProjectReportManagementPerformanceRoute: NestedRoute = {
  element: <GCReports reportType="performance" />,
};

export default gcProjectReportManagementPerformanceRoute;
