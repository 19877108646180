import React, { useContext, useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import {
  SiteOrientationStepProps,
  VeteranType,
} from "../../../../../../../utils/siteOrientationTypes";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import { Select } from "antd";
import { veteranTypeOptions } from "src/domain-features/siteorientation/utils/demographicQuestionOptions";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsVeteranTypeProps
  extends SiteOrientationStepProps {}

const SiteOrientationInPersonWorkerStepsVeteranType: React.FC<
  SiteOrientationInPersonWorkerStepsVeteranTypeProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();
  const user = workerContext.orientee;
  let defaultVeteranType: null | VeteranType = null;
  if (user && user.userType === "worker" && user.veteranType) {
    defaultVeteranType = user.veteranType.new ?? user.veteranType.old;
  }
  const [veteranType, setVeteranType] =
    useState<VeteranType>(defaultVeteranType);
  const langStrings = useCurrentLangStrings();
  const onEnter = async (pickedVal?: string) => {
    workerContext.updateOrientee((u) =>
      u?.userType === "worker"
        ? {
            ...u,
            veteranType: {
              old: u.veteranType?.old,
              new: pickedVal ?? veteranType,
            },
          }
        : null,
    );

    props.onNext();
  };
  return (
    <SiteFeatureStepLayout
      onNextButtonClick={() => {
        !!veteranType && onEnter(veteranType);
      }}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!veteranType}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.areYouAVeteranOrDisabledVeteran}
      >
        <Select
          value={veteranType ?? undefined}
          onChange={(v) => {
            setVeteranType(v);
            onEnter(v);
          }}
          placeholder={langStrings.strings.selectFromTheList}
        >
          {veteranTypeOptions.map((vt) => (
            <Select.Option value={vt} key={vt}>
              {langStrings.options.veterranTypes[vt]}
            </Select.Option>
          ))}
        </Select>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerStepsVeteranType;
