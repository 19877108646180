import React from "react";
import PreTaskPlansTable from "src/common/components/tables/PreTaskPlansTable";
import { useSearchParams } from "react-router-dom";
import { useGetReportsDistinctSubContractorsByProjectIdQuery } from "src/common/types/generated/apollo/graphQLTypes";
import LoadingContent from "src/common/components/general/LoadingContent";
import { Subcontractor } from "src/common/components/tables/PreTaskPlansTable";
interface GCProjectReportsPTPsTableProps {
  projectId: string;
  openRow: (params: { reportId: string }) => void;
}

const GCProjectReportsPTPsTable: React.FunctionComponent<
  GCProjectReportsPTPsTableProps
> = (props) => {
  const [searchParams] = useSearchParams();
  const worker = searchParams.get("worker");
  const { data, loading, error } =
    useGetReportsDistinctSubContractorsByProjectIdQuery({
      variables: {
        projectId: props.projectId,
      },
    });
  if (loading) {
    return <LoadingContent />;
  }
  if (!data) {
    throw Error(error?.message ?? "An Error occurred");
  }

  const subContractorFilterOptions: Subcontractor[] =
    data.project_subcontractor.map(({ subcontractor }) => {
      return {
        id: subcontractor.id,
        name: subcontractor.name,
      };
    });

  return (
    <div className="flex flex-col items-start gap-0.5 h-full w-full">
      <div className="w-full h-full">
        <PreTaskPlansTable
          subContractorFilterOptions={subContractorFilterOptions}
          showDateRangeFilter={true}
          excludedKeys={["project"]}
          onRowClick={(pk) => {
            props.openRow({ reportId: pk });
          }}
          where={
            worker
              ? {
                  report_project_workers: {
                    project_worker_id: { _eq: worker },
                  },
                  project_id: {
                    _eq: props.projectId,
                  },
                }
              : {
                  project_id: {
                    _eq: props.projectId,
                  },
                }
          }
        />
      </div>
    </div>
  );
};

export default GCProjectReportsPTPsTable;
