import { Input } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BSpace from "src/common/components/layouts/BSpace";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import { SFRequestedSDSQuery } from "src/common/types/generated/relay/SFRequestedSDSQuery.graphql";
import { SFRequestedSDS_sds_request$data } from "src/common/types/generated/relay/SFRequestedSDS_sds_request.graphql";

export type ColumnKeys =
  | "subcontractor_name"
  | "gc_name"
  | "project_name"
  | "requested_by"
  | "requested_at"
  | "processed_at"
  | "processes_by"
  | "status";
const CONNECTION_NAME = "sds_request_connection";
type DConnection = SFRequestedSDS_sds_request$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  SFRequestedSDSQuery,
  { worker_id: string | unknown; project_id: string | unknown }
> & {
  onRowClickPK: (pk: string) => void;
  searchString?: string;
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    SFRequestedSDSQuery
  >["columns"];
};
const SFRequestedSDSTable = forwardRef<DataScrollTableRef, Props>(
  (
    {
      title = "Requests for New JHAs",
      onRowClickPK,
      searchString,
      where,
      ...props
    },
    ref,
  ) => {
    return (
      <DataScrollTable<DConnection, ColumnKeys, SFRequestedSDSQuery>
        {...props}
        ref={ref}
        title={title}
        onRowClick={({ pk }) => onRowClickPK && onRowClickPK(pk)}
        where={where}
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allSubcontractorsToAddConnection"}
        columns={[
          {
            title: "Project Name",
            key: "project_name",
            size: "md",
            dataIndex: ["project", "name"],
            searchDataIndex: ["project", "name"],
            searchValue: searchString,
            sortable: true,
            defaultSortOrder: "desc",
          },
          {
            title: "GC Name",
            key: "gc_name",
            size: "md",
            dataIndex: ["project", "general_contractor", "name"],
            searchDataIndex: ["project", "general_contractor", "name"],
          },
          {
            title: "Subcontractor Name",
            key: "subcontractor_name",
            size: "md",
            dataIndex: ["subcontractor", "name"],
            searchDataIndex: ["subcontractor", "name"],
          },
          {
            title: "Requested By",
            key: "requested_by",
            size: "md",
            dataIndex: ["requested_by_user", "name"],
            searchDataIndex: ["requested_by_user", "name"],
          },
          {
            title: "Requested on",
            key: "requested_at",
            size: "md",
            sortable: true,
            defaultSortOrder: "desc",
            dataIndex: ["requested_at"],
            searchDataIndex: ["requested_at"],
            render: (a, row) => dayjs(row.requested_at).format("LLL"),
          },
          {
            title: "Processed/Rejected on",
            key: "processed_at",
            size: "lg",
            sortable: true,
            defaultSortOrder: "desc",
            dataIndex: ["processed_at"],
            searchDataIndex: ["processed_at"],
            render: (a, row) =>
              dayjs(row.processed_at ?? row.rejected_at).format("LLL"),
          },
          {
            title: "Processed/Rejected By",
            key: "processes_by",
            size: "lg",
            dataIndex: [],
            render: (a, row) =>
              row.processed_by_user?.name ?? row.rejected_by_user?.name,
          },
          {
            title: "Status",
            key: "status",
            size: "md",
            dataIndex: [],
            sortable: true,
            defaultSortOrder: "desc",
            render: (a, row) => (row.rejected_at ? "Rejected" : "Processed"),
          },
        ]}
        queryNode={graphql`
          query SFRequestedSDSQuery(
            $first: Int!
            $after: String
            $where: sds_request_bool_exp!
            $order_by: [sds_request_order_by!]!
          ) {
            ...SFRequestedSDS_sds_request
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
            ...SFRequestedSDS_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment SFRequestedSDS_total on query_root
          @argumentDefinitions(where: { type: "sds_request_bool_exp!" })
          @refetchable(queryName: "SFRequestedSDSTotalRefetchableQuery") {
            allSDSToAddConnection: sds_request_connection(where: $where) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment SFRequestedSDS_sds_request on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "sds_request_bool_exp!" }
            order_by: { type: "[sds_request_order_by!]!" }
          )
          @refetchable(queryName: "SFRequestedSDSRefetchableQuery") {
            sds_request_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "SFRequestedSDS_sds_request_connection"
                filters: ["where", "order_by"]
              ) {
              edges {
                node {
                  pk: id @__clientField(handle: "pk")
                  subcontractor {
                    name
                  }
                  project {
                    name
                    general_contractor {
                      name
                    }
                  }
                  processed_by_user {
                    name
                  }
                  rejected_by_user {
                    name
                  }
                  requested_at
                  processed_at
                  rejected_at
                  requested_by_user {
                    name
                  }
                  attached_files {
                    id
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);

const SFRequestedSDS: FC = () => {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const onInputKeyDown = (event: any): any => {
    if (event.key === "Enter") {
      setInputValue(event.target.value);
    }
  };
  return (
    <>
      <BSpace>
        <div className="flex text-1.5 justify-center items-center m-1">
          SDS Creation Requests Table
        </div>
        <div className="flex justify-between float-right">
          <Input
            type="primary"
            onKeyDown={onInputKeyDown}
            className="w-18"
            placeholder="Search"
          />
        </div>
      </BSpace>
      <BSpace>
        <SFRequestedSDSTable
          title="Pending SDS Requests"
          where={{
            rejected_at: { _is_null: true },
            processed_at: { _is_null: true },
          }}
          excludedKeys={["processed_at", "processes_by", "status"]}
          searchString={inputValue}
          onRowClickPK={(pk) => navigate(`/sfe/verify/sds/${pk}`)}
        />

        <br />
        <br />
        <br />

        <SFRequestedSDSTable
          title="Processed and Rejected SDS Requests"
          where={{
            _or: [
              {
                processed_at: { _is_null: false },
              },
              {
                rejected_at: { _is_null: false },
              },
            ],
          }}
          searchString={inputValue}
          onRowClickPK={(pk) => navigate(`/sfe/verify/sds/${pk}`)}
        />
      </BSpace>
    </>
  );
};
export default SFRequestedSDS;
