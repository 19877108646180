import React, { FC } from "react";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import ProjectSubcontractorTasksTable from "src/common/components/tables/ProjectSubcontractorTasksTable";
import { auth } from "src/common/functions/firebase";
import { SCProjectQuery$data } from "src/common/types/generated/relay/SCProjectQuery.graphql";

interface SCProjectJHAsProps {
  projectId: string;
  subcontractorId: string;
  refresh: () => void;
  data: SCProjectQuery$data;
}
const SCProjectJHAs: FC<SCProjectJHAsProps> = ({
  projectId,
  refresh,
  subcontractorId,
  data,
}) => {
  const loggedInUserId = auth.currentUser?.uid;
  return loggedInUserId ? (
    <BasicWrapper scrollable>
      <ProjectSubcontractorTasksTable
        {...{ projectId, refresh, subcontractorId, data }}
      />
    </BasicWrapper>
  ) : (
    <div className="flex flex-col items-center justify-center mt-3">
      Authentication Failed
    </div>
  );
};
export default SCProjectJHAs;
