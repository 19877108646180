import React, { FC } from "react";

import { OrientationProjectWorkerTable_project_worker$data } from "src/common/types/generated/relay/OrientationProjectWorkerTable_project_worker.graphql";
import translateCorrectAnswers from "src/domain-features/siteorientation/utils/translateCorrectAnswers";

import { AVAILABLE_LANGUAGES } from "../../../../../../../utility-features/i18n/languages";
import stringToLanguage from "src/utility-features/i18n/language-utils/stringToLanguage";

export interface QuizResultProps {
  orientationResults: OrientationResultType[];
  emergencyContact?: string | null;
}

type OrientationResultType = NonNullable<
  OrientationProjectWorkerTable_project_worker$data["project_worker_connection"]["edges"][number]["node"]["user"]
>["universal_orientations"][number]["universal_orientation_results"][number];
type QuizResultType = OrientationResultType["quiz_results"][number];
export type QuizResultMapType = {
  [key: string]: OrientationResultType;
};
const quessCorrectAnswer = (
  slide: QuizResultType["slide"],
  answer: QuizResultType["answer"],
) => {
  const ans_en = (slide.answer_items || "").split("\n");
  const correct_en = (slide.correct_answers || "").split("\n");
  const correctAnswersList: string[] = [];
  AVAILABLE_LANGUAGES.map((lang) => {
    if (lang === "en") {
      slide.correct_answers && correctAnswersList.push(slide.correct_answers);
      return;
    }

    const ans = slide[`answer_items_${lang}`]?.split("\n");
    const correct_lang_ans: Array<string | undefined> = correct_en.map((v) =>
      ans ? ans[ans_en.indexOf(v)] : undefined,
    );
    if (correct_lang_ans) correctAnswersList.push(correct_lang_ans.join("\n"));
  });
  return !!answer && correctAnswersList.includes(answer);
};
export const QuizResult: FC<QuizResultProps> = ({
  orientationResults,
  emergencyContact,
}) => {
  return (
    <div>
      {emergencyContact && (
        <div style={{ marginBottom: "10px" }}>
          <div style={{ fontWeight: "bold" }}>
            {"Who is Your Emergency Contact?"}
          </div>

          <div>{emergencyContact}</div>
        </div>
      )}
      {orientationResults.map((doc, i) => (
        <div key={i}>
          {doc.quiz_results.map((res, j) => {
            const answerLang = stringToLanguage(res.lang);
            const correct = answerLang
              ? translateCorrectAnswers(
                  { ...res.slide, db_answer_items: res.slide.answer_items },
                  answerLang,
                ) === res.answer
              : quessCorrectAnswer(res.slide, res.answer);
            const color = res.slide.check_correct_answer
              ? correct
                ? "green"
                : "red"
              : undefined;
            return (
              <div style={{ marginBottom: "10px" }} key={j + "a"}>
                <div style={{ fontWeight: "bold" }}>{res.slide.title}</div>
                {res.answer?.split("\n").map((v, k) => (
                  <div style={{ color }} key={k + "b"}>
                    {v}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
export default QuizResult;
