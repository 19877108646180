/**
 * @generated SignedSource<<4d1004b9bcbdce8adb7925bc311c3056>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type procore_project_data_set_input = {
  access_token?: string | null | undefined;
  integration_enabled?: boolean | null | undefined;
  procore_company_id?: number | null | undefined;
  procore_company_name?: string | null | undefined;
  procore_project_id?: string | null | undefined;
  procore_project_name?: string | null | undefined;
  project_id?: string | null | undefined;
  refresh_token?: string | null | undefined;
  sandbox?: boolean | null | undefined;
  service_account_client_id?: string | null | undefined;
  service_account_client_secret?: string | null | undefined;
  token_expires_at?: string | null | undefined;
  upload_daily_construction_report_enabled?: boolean | null | undefined;
  upload_daily_reports_enabled?: boolean | null | undefined;
  upload_daily_reports_folder_id?: number | null | undefined;
  upload_delivery_enabled?: boolean | null | undefined;
  upload_inspection_report_enabled?: boolean | null | undefined;
  upload_inspection_report_folder_id?: number | null | undefined;
  upload_permits_enabled?: boolean | null | undefined;
  upload_permits_folder_id?: number | null | undefined;
  upload_safety_reports_enabled?: boolean | null | undefined;
  upload_safety_reports_folder_id?: number | null | undefined;
  upload_toolbox_talks_enabled?: boolean | null | undefined;
  upload_toolbox_talks_folder_id?: number | null | undefined;
  upload_worker_orientation_transcript_enabled?: boolean | null | undefined;
  upload_worker_orientation_transcript_folder_id?: number | null | undefined;
  use_dmsa?: boolean | null | undefined;
  user_id?: number | null | undefined;
  user_login?: string | null | undefined;
  user_name?: string | null | undefined;
};
export type ProcoreFolderConfigModal_updateProcorePorjectData_Mutation$variables = {
  _set: procore_project_data_set_input;
  project_id: string;
};
export type ProcoreFolderConfigModal_updateProcorePorjectData_Mutation$data = {
  readonly update_procore_project_data_by_pk: {
    readonly pk: string;
    readonly procore_project_id: string;
    readonly procore_project_name: string | null | undefined;
    readonly project_id: string;
    readonly upload_daily_reports_enabled: boolean;
    readonly upload_daily_reports_folder_id: number | null | undefined;
    readonly upload_inspection_report_enabled: boolean;
    readonly upload_inspection_report_folder_id: number | null | undefined;
    readonly upload_permits_enabled: boolean;
    readonly upload_permits_folder_id: number | null | undefined;
    readonly upload_safety_reports_enabled: boolean;
    readonly upload_safety_reports_folder_id: number | null | undefined;
    readonly upload_toolbox_talks_enabled: boolean;
    readonly upload_toolbox_talks_folder_id: number | null | undefined;
    readonly upload_worker_orientation_transcript_enabled: boolean;
    readonly upload_worker_orientation_transcript_folder_id: number | null | undefined;
  } | null | undefined;
};
export type ProcoreFolderConfigModal_updateProcorePorjectData_Mutation = {
  response: ProcoreFolderConfigModal_updateProcorePorjectData_Mutation$data;
  variables: ProcoreFolderConfigModal_updateProcorePorjectData_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "project_id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "project_id",
        "variableName": "project_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_project_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_project_name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_permits_enabled",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_permits_folder_id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_daily_reports_enabled",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_daily_reports_folder_id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_toolbox_talks_enabled",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_toolbox_talks_folder_id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_safety_reports_enabled",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_safety_reports_folder_id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_worker_orientation_transcript_enabled",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_worker_orientation_transcript_folder_id",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_inspection_report_enabled",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_inspection_report_folder_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProcoreFolderConfigModal_updateProcorePorjectData_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "procore_project_data",
        "kind": "LinkedField",
        "name": "update_procore_project_data_by_pk",
        "plural": false,
        "selections": [
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProcoreFolderConfigModal_updateProcorePorjectData_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "procore_project_data",
        "kind": "LinkedField",
        "name": "update_procore_project_data_by_pk",
        "plural": false,
        "selections": [
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9defcaeaac9327b45da4cc695475f1f9",
    "id": null,
    "metadata": {},
    "name": "ProcoreFolderConfigModal_updateProcorePorjectData_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProcoreFolderConfigModal_updateProcorePorjectData_Mutation(\n  $project_id: uuid!\n  $_set: procore_project_data_set_input!\n) {\n  update_procore_project_data_by_pk(pk_columns: {project_id: $project_id}, _set: $_set) {\n    pk: id\n    project_id\n    procore_project_id\n    procore_project_name\n    upload_permits_enabled\n    upload_permits_folder_id\n    upload_daily_reports_enabled\n    upload_daily_reports_folder_id\n    upload_toolbox_talks_enabled\n    upload_toolbox_talks_folder_id\n    upload_safety_reports_enabled\n    upload_safety_reports_folder_id\n    upload_worker_orientation_transcript_enabled\n    upload_worker_orientation_transcript_folder_id\n    upload_inspection_report_enabled\n    upload_inspection_report_folder_id\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0cd2229d96587b04747c2ab1bfeb4104";

export default node;
