/**
 * @generated SignedSource<<dc96d1955ec634e769b513739d08cb52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type task_set_input = {
  accepted_at_from_creation_request?: string | null | undefined;
  accepted_by_uid_from_creation_request?: string | null | undefined;
  archived_at?: string | null | undefined;
  archived_by_uid?: string | null | undefined;
  company_task_id?: string | null | undefined;
  created_at?: string | null | undefined;
  created_by_uid?: string | null | undefined;
  creation_request_id?: string | null | undefined;
  deleted_at?: string | null | undefined;
  description_id?: string | null | undefined;
  description_text?: string | null | undefined;
  document_id?: string | null | undefined;
  email_request_id?: string | null | undefined;
  flagged_at?: string | null | undefined;
  flagged_by_uid?: string | null | undefined;
  id?: string | null | undefined;
  incident_id?: string | null | undefined;
  is_pending_acceptance?: boolean | null | undefined;
  original_report_task_id?: string | null | undefined;
  project_id?: string | null | undefined;
  report_id?: string | null | undefined;
  request_state?: string | null | undefined;
  reviewed_at?: string | null | undefined;
  reviewed_by_uid?: string | null | undefined;
  show_type?: string | null | undefined;
  subcontractor_id?: string | null | undefined;
  test_project_was_null?: boolean | null | undefined;
  test_sub_was_null?: boolean | null | undefined;
  updated_at?: string | null | undefined;
  updated_by_uid?: string | null | undefined;
};
export type useFlagOrUnflagTaskMutation$variables = {
  _set: task_set_input;
  taskId: string;
};
export type useFlagOrUnflagTaskMutation$data = {
  readonly update_task_by_pk: {
    readonly flagged_at: string | null | undefined;
    readonly flagged_by_uid: string | null | undefined;
    readonly flagged_by_user: {
      readonly name: string;
    } | null | undefined;
    readonly id: string;
  } | null | undefined;
};
export type useFlagOrUnflagTaskMutation = {
  response: useFlagOrUnflagTaskMutation$data;
  variables: useFlagOrUnflagTaskMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "taskId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flagged_at",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flagged_by_uid",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useFlagOrUnflagTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "task",
        "kind": "LinkedField",
        "name": "update_task_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "flagged_by_user",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useFlagOrUnflagTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "task",
        "kind": "LinkedField",
        "name": "update_task_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "flagged_by_user",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d6d5c262d827159c62e993c0d947845c",
    "id": null,
    "metadata": {},
    "name": "useFlagOrUnflagTaskMutation",
    "operationKind": "mutation",
    "text": "mutation useFlagOrUnflagTaskMutation(\n  $taskId: uuid!\n  $_set: task_set_input!\n) {\n  update_task_by_pk(pk_columns: {id: $taskId}, _set: $_set) {\n    id\n    flagged_at\n    flagged_by_uid\n    flagged_by_user {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "26d80dd197a931d5229759f8fa753d63";

export default node;
