import React, { FC, useState } from "react";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes";

export interface VisitorOrientationStepsHelloProps
  extends SiteOrientationStepProps {
  showLangSelector?: boolean;
  projectName: string;
  gcName: string;
  logo?: string;
}
const VisitorOrientationStepsHello: FC<VisitorOrientationStepsHelloProps> = (
  props,
) => {
  return (
    <SiteFeatureStepLayout
      onNextButtonClick={props.onNext}
      nextButtonText={"Check In"}
    >
      <div className={`flex min-h-full flex-col justify-center`}>
        <div className={``}>
          <p>
            <span className={`text-4 font-accent block`}>{"Visitor Log"}</span>
            <span className={`text-2 font-accent block`}>
              {props.projectName}
            </span>
            {props.logo ? (
              <img className="w-1/3" src={props.logo} alt="" />
            ) : (
              <span className={`text-2 font-accent block`}>{props.gcName}</span>
            )}
          </p>
        </div>
      </div>
    </SiteFeatureStepLayout>
  );
};

export default VisitorOrientationStepsHello;
