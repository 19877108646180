import { Button, DatePicker } from "antd";
import dayjs from "dayjs";
import React, { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import PreTaskPlansTable from "src/common/components/tables/PreTaskPlansTable";

const { RangePicker } = DatePicker;

interface SCReportsProps {
  subcontractorId: string;
  projectId?: string;
}

const SCReports: FC<SCReportsProps> = ({ subcontractorId, projectId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get("start");
  const endDate = searchParams.get("end");
  const [start, setStart] = useState(startDate ? dayjs(startDate) : null);
  const [end, setEnd] = useState(endDate ? dayjs(endDate) : null);
  const handleRangeChange = (e: any) => {
    if (!e) return;
    setStart(dayjs(e[0]));
    setEnd(dayjs(e[1]));
  };
  const removeHandler = () => {
    setStart(() => null);
    setEnd(() => null);
  };
  return (
    <BasicWrapper>
      <div className="flex">
        <RangePicker
          style={{ width: "200px" }}
          onChange={handleRangeChange}
          value={start && end ? [start, end] : undefined}
          className="rounded-2"
        />
        <Button
          onClick={removeHandler}
          type="primary"
          className=" ml-1 rounded-2"
        >
          Clear
        </Button>
      </div>
      <PreTaskPlansTable
        title="Pre-Task Plans"
        excludedKeys={
          projectId ? ["subcontractor", "project"] : ["subcontractor"]
        }
        where={{
          subcontractor_id: {
            _eq: subcontractorId,
          },
          ...(projectId ? { project_id: { _eq: projectId } } : {}),
          created_at: { _lte: end?.format(), _gte: start?.format() },
        }}
        onRowClick={(pk) => {
          if (start && end)
            setSearchParams({
              reportId: pk,
              start: start.format("YYYY-MM-DD"),
              end: end.format("YYYY-MM-DD"),
            });
          else setSearchParams({ reportId: pk });
        }}
      />
    </BasicWrapper>
  );
};

export default SCReports;
