import { Outlet, useNavigate } from "react-router-dom";
import FloatingContactButton from "src/common/components/contact-button/FloatingContactButton";

import NavigationWrapper from "src/common/components/layouts/NavigationWrapper";
import { NavigationMenuProps } from "src/common/components/NavigationMenu";
import { getLevelsCommonNavigationItems } from "../utils/getLevelsCommonNavigationItems";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import withBusinessUnitId from "../utils/withBusinessUnitId";
const GCBusinessUnit: React.FC = () => {
  const navigate = useNavigate();
  const { userData } = useUserData();
  if (!userData.employee) throw new Error("employee not found");
  const emp = userData.employee;
  const gc = emp.general_contractor;
  const menu: NavigationMenuProps = {
    navigationItems: [
      ...(gc.hierarchy_office_name
        ? [
            {
              label: gc.hierarchy_office_name,
              rootPath: "offices",
              onClick: () => {
                navigate("offices");
              },
            },
          ]
        : []),
      ...getLevelsCommonNavigationItems(navigate, gc.incident_enabled),
    ],
  };

  return (
    <div className="w-full h-full py-0.5 pr-0.5 bg-suplementary-1 z-0">
      <NavigationWrapper menu={menu}>
        <Outlet />
      </NavigationWrapper>
      <FloatingContactButton />
    </div>
  );
};

export default withBusinessUnitId(GCBusinessUnit);
