import React, { FC, useEffect } from "react";
import { Row, Col, Input, Button, Form, Checkbox } from "antd";
import { useForgotPasswordMutation } from "src/common/types/generated/apollo/graphQLTypes";
import logoTextImage from "src/common/assets/logo-text.png";
import { useSearchParams } from "react-router-dom";

const ForgotPassword = () => {
  const [forgotPassword, { loading, error, data }] =
    useForgotPasswordMutation();
  const [searchParams] = useSearchParams();
  const expiredLink = searchParams.get("expiredLink");
  const onSubmit = async (data: any) => {
    await forgotPassword({
      variables: {
        input: {
          loginIdentifier: data.loginIdentifier,
        },
      },
    }).catch(console.log);
  };

  useEffect(() => {
    if (error) {
      alert(error);
    }
  }, [error, data]);

  return (
    <Row
      style={{ height: "100vh", flexDirection: "row" }}
      justify="space-around"
      align="middle"
    >
      {data?.forgotPassword ? (
        <h3>Check your email for a password reset link!</h3>
      ) : (
        <Form
          layout="vertical"
          name="normal_login"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          style={{ width: 250 }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 32,
            }}
          >
            <img src={logoTextImage} style={{ width: 150 }} />
          </div>
          {expiredLink ? (
            <div>
              The previous link is expired please enter your email to request
              new link
            </div>
          ) : (
            <h3>Forgot Password</h3>
          )}

          <Form.Item
            label="Your email address"
            name="loginIdentifier"
            rules={[
              {
                required: true,
                message: "Please enter your email.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ width: "100%" }}
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </Row>
  );
};

export default ForgotPassword;
