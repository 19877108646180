import dayjs from "dayjs";

export const dateFormat = {
  numeric: "YYYY-MM-DD",
  mmddyyyy: "MM/DD/YYYY",
  full: "MMMM Do, YYYY",
  numericTime: "YYYY-MM-DD, h:mm A z",
  fullTime: "MMMM Do YYYY, h:mm A z",
  mmddyyyTime: "MM/DD/YYYY, h:mm A z",
  mmddTime: "MM/DD, h:mm A z",
  NoYearFullTime: "MM-DD, h:mm A z",
  NoYearNumericTime: "MM-DD, h:mm A z",
  NoYearNumeric: "MM-DD",
  NoYearFull: "MMMM Do",
};

export type GetDateStrOptions = {
  format?: "numeric" | "full" | "mmddyyyy";
  withTime?: boolean;
  withTimeZone?: boolean;
  withYear?: boolean;
  withWeekday?: boolean;
};

const getDateStr: (
  date: Date | dayjs.Dayjs,
  options?: GetDateStrOptions,
) => string = (
  date,
  options = {
    format: "full",
    withTime: false,
    withTimeZone: false,
    withYear: true,
  },
) => {
  let dateStrFormat = dateFormat.full;
  if (options.withYear === undefined) {
    options.withYear = true;
  }

  if (options) {
    if (options.format === "numeric") {
      if (options.withTime) {
        if (options.withYear) {
          dateStrFormat = dateFormat.numericTime;
        } else {
          dateStrFormat = dateFormat.NoYearNumericTime;
        }
      } else {
        if (options.withYear) {
          dateStrFormat = dateFormat.numeric;
        } else {
          dateStrFormat = dateFormat.NoYearNumeric;
        }
      }
    } else if (options.format === "full") {
      if (options.withTime) {
        if (options.withYear) {
          dateStrFormat = dateFormat.fullTime;
        } else {
          dateStrFormat = dateFormat.NoYearFullTime;
        }
      } else {
        if (options.withYear) {
          dateStrFormat = dateFormat.full;
        } else {
          dateStrFormat = dateFormat.NoYearFull;
        }
      }
    } else if (options.format === "mmddyyyy") {
      if (options.withTime) {
        if (options.withYear) {
          dateStrFormat = dateFormat.mmddyyyy;
        } else {
          dateStrFormat = dateFormat.mmddyyyTime;
        }
      } else {
        if (options.withYear) {
          dateStrFormat = dateFormat.mmddyyyy;
        } else {
          dateStrFormat = dateFormat.mmddTime;
        }
      }
    }
    if (options.withWeekday) {
      dateStrFormat = "ddd, " + dateStrFormat;
    }
    if (options.withTimeZone) {
      return dayjs(date).tz("America/New_York").format(dateStrFormat);
    }
  }

  return dayjs(date).format(dateStrFormat);
};
export default getDateStr;
