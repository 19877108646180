import { TextTranslationFragFragment } from "src/common/types/generated/apollo/graphQLTypes";

const stripLocalTextTranslation = (
  translation: TextTranslationFragFragment,
) => {
  const { __typename, id, clientText, ...rest } = translation;
  return rest;
};

export default stripLocalTextTranslation;
