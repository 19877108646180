import { JSONObject } from "src/common/types/manual/JSONType";
import { SearchData } from "../../ScrollTable";
import buildFieldRef from "./buildFieldRef";

const buildGraphQLSearchFilter = (
  searchFilter?: SearchData,
  extraSearchDataIndexes?: Array<
    Array<{ index: string; extraConstraint?: JSONObject }>
  >,
) => {
  if (!searchFilter) return {};

  const searchList = searchFilter.fields.map(({ dataIndex }) =>
    buildFieldRef(dataIndex, {
      _ilike: `%${searchFilter.searchValue?.replace?.(/[\W_]+/g, "%")}%`,
    }),
  );
  if (extraSearchDataIndexes) {
    extraSearchDataIndexes.forEach((searchDataIndexes) => {
      let result: JSONObject = {
        _ilike: `%${searchFilter.searchValue?.replace?.(/[\W_]+/g, "%")}%`,
      };
      for (let i = searchDataIndexes.length - 1; i >= 0; i--) {
        const curr = searchDataIndexes[i];
        const name = searchDataIndexes[i].index;
        result = {
          [name]: {
            ...result,
            ...(curr.extraConstraint ? curr.extraConstraint : {}),
          },
        };
      }

      searchList.push(result);
    });
  }
  return { _or: searchList };
};
export default buildGraphQLSearchFilter;
