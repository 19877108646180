import React from "react";
import ReactCountryFlag, { ReactCountryFlagProps } from "react-country-flag";

const CountryFlag: React.FC<ReactCountryFlagProps> = (props) => {
  return (
    <ReactCountryFlag
      {...{
        svg: true,
        className: `rounded-0.5`,
        ...props,
      }}
    />
  );
};

export default CountryFlag;
