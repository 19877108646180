import React from "react";
import Separator from "../general/Separator";
import clsx from "clsx";

interface KeyValuePairDisplayCardProps {
  objectToDisplay: Record<string, React.ReactNode>;
}

const KeyValuePairDisplayCard: React.FC<KeyValuePairDisplayCardProps> = (
  props
) => {
  const objectEntries = Object.entries(props.objectToDisplay);
  return (
    <div className="flex flex-col gap-1">
      {objectEntries.map(([key, value], index) => {
        return (
          <React.Fragment key={key}>
            <div>
              <p className="text-1 text-static-secondary">{key}</p>
              {(() => {
                const Element = typeof value === "string" ? "p" : "div";
                return (
                  <Element className="text-1 font-accent">{value}</Element>
                );
              })()}
            </div>
            {index !== objectEntries.length - 1 && <Separator />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default KeyValuePairDisplayCard;
