import NestedRoute from "../../../../../../common/types/manual/NestedRoute";
import searchParamsProvider from "../../../../../../common/functions/searchParamsProvider";
import QrProjectPermits from "./components/QrProjectPermits";
import QrProjectPermitReport from "./components/QrProjectPermitReport";
import React from "react";
import { Outlet } from "react-router-dom";
import QrLotoPermitLocks from "./components/QrLotoPermitLocks";

const permitsRoute: NestedRoute = {
  element: <Outlet />,
  children: [
    {
      path: "date/:date",
      element: searchParamsProvider(QrProjectPermits),
    },
    {
      path: "loto",
      element: <QrLotoPermitLocks />,
    },
    {
      path: "date/:date/:permitType/:permitId",
      element: searchParamsProvider(QrProjectPermitReport),
    },
  ],
};

export default permitsRoute;
