import React from "react";
import { Tooltip } from "antd";

export interface SubProjectMobilizationProgressBarProps {
  documentMissing: number;
  documentsSubmitted: number;
  documentsOptional: number;
}

const SubProjectMobilizationProgressBar: React.FC<
  SubProjectMobilizationProgressBarProps
> = (props) => {
  const documentsTotal =
    props.documentsOptional + props.documentsSubmitted + props.documentMissing;

  const progressBars: {
    colorClassName: string;
    percentage: number;
    title: string;
  }[] = [
    {
      colorClassName: "bg-semantic-inactive",
      percentage: (100 / documentsTotal) * props.documentMissing,
      title: "documents are missing",
    },
    {
      colorClassName: "bg-interactive-primary",
      percentage: (100 / documentsTotal) * props.documentsSubmitted,
      title: "documents submitted",
    },
    {
      colorClassName: "bg-semantic-inactive",
      percentage: (100 / documentsTotal) * props.documentsOptional,
      title: "documents are optional",
    },
  ];

  let colorBarPercentageSum = 0;
  let tooltipsPercentageSum = 0;

  return (
    <div className={`relative h-0.5 w-full`}>
      {progressBars.map((progressBar, index, arr) => {
        colorBarPercentageSum += progressBar.percentage;
        return (
          <div
            key={index}
            style={{
              width: `${colorBarPercentageSum}%`,
              zIndex: arr.length - index,
            }}
            className={`absolute z-10 left-0 top-0 h-full ${progressBar.colorClassName} rounded-full border border-white`}
          ></div>
        );
      })}
      {progressBars.map((progressBar, index, arr) => {
        const returnElement = (
          <Tooltip
            key={index}
            title={`${progressBar.percentage}% ${progressBar.title}`}
          >
            <div
              style={{
                left: `${tooltipsPercentageSum}%`,
                width: `${progressBar.percentage}%`,
                zIndex: arr.length,
              }}
              className={`absolute h-[calc(100%+1rem)] -my-0.5`}
            ></div>
          </Tooltip>
        );
        tooltipsPercentageSum += progressBar.percentage;
        return returnElement;
      })}
    </div>
  );
};

export default SubProjectMobilizationProgressBar;