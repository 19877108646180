import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useInsertSecretCodeMutation } from "src/common/types/generated/relay/useInsertSecretCodeMutation.graphql";
import { graphql } from "babel-plugin-relay/macro";

const generateSecretCodeMutation = graphql`
  mutation useInsertSecretCodeMutation(
    $projectId: uuid!
    $userId: uuid!
    $id: uuid!
    $signInRole: String
    $expire_at: timestamptz
    $only_project_data: Boolean!
    $finalUrl: String!
  ) {
    insert_secret_login_link_one(
      object: {
        expire_at: $expire_at
        project_id: $projectId
        user_id: $userId
        only_project_data: $only_project_data
        id: $id
        sign_in_role: $signInRole
        final_url: $finalUrl
      }
    ) {
      id
      pk: id @__clientField(handle: "pk")
    }
  }
`;

const useInsertSecretCode = () => {
  return useAsyncMutation<useInsertSecretCodeMutation>(
    generateSecretCodeMutation,
  )[0];
};

export default useInsertSecretCode;
