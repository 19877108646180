import React from "react";
import { TablerIcon } from "@tabler/icons";
import remToPx from "src/common/functions/remToPx";
import { Popover } from "antd";

// Look for available icons on https://tabler-icons.io

export type IconType = TablerIcon;

export interface IconProps {
  size?: "small" | "regular" | "large" | "xl" | "full";
  icon: IconType;
  secondaryIcon?: IconType;
  color?:
    | "interactive"
    | "static"
    | "white"
    | "static-secondary"
    | "warn"
    | "negative"
    | "positive"
    | "suplementary-3"
    | "low-severity"
    | "high-severity";
  hoverContent?: {
    content?: JSX.Element | string;
    title?: string | React.ReactElement;
    enableOnClick?: boolean;
  };
  onClick?: () => void;
}

const Icon: React.FunctionComponent<IconProps> = (props) => {
  const sizePx = (() => {
    switch (props.size) {
      case "small" || undefined:
        return remToPx(1.25);
      case "regular":
        return remToPx(1.25);
      case "large":
        return remToPx(1.5);
      case "xl":
        return remToPx(2);
      case "full":
        return remToPx(20);
    }
  })();

  const className = (() => {
    switch (props.color) {
      case "interactive":
        return "text-interactive-primary";
      case "white":
        return "text-white";
      case "static":
        return "text-static";
      case "suplementary-3":
        return "text-suplementary-3";
      case "static-secondary":
        return "text-static-secondary";
      case "warn":
        return "text-warn";
      case "high-severity":
        return "text-orange-dark";
      case "low-severity":
        return "text-orange-light";
      case "negative":
        return "text-semantic-negative";
      case "positive":
        return "text-semantic-positive-green";
      default:
        return "text-static-primary";
    }
  })();

  const InnerIcon = props.icon;
  if (props.secondaryIcon) {
    const SecondaryIcon = props.secondaryIcon;
    return props.hoverContent ? (
      <Popover
        trigger={"hover"}
        content={props.hoverContent.content}
        title={props.hoverContent.title}
      >
        <div
          className={"flex"}
          onClick={props.hoverContent.enableOnClick ? props.onClick : undefined}
        >
          <InnerIcon size={sizePx} strokeWidth={2} />
          <SecondaryIcon
            size={remToPx(0.9)}
            className="top-0 right-0 cursor-pointer"
          />
        </div>
      </Popover>
    ) : (
      <div className={" flex"} onClick={props.onClick}>
        <InnerIcon size={sizePx} strokeWidth={2} />
        <SecondaryIcon
          size={remToPx(0.9)}
          className="top-0 right-0 cursor-pointer "
        />
      </div>
    );
  }

  return props.hoverContent ? (
    <Popover
      trigger={"hover"}
      content={props.hoverContent.content}
      title={props.hoverContent.title}
    >
      <div
        {...{
          className,
          onClick: props.hoverContent.enableOnClick ? props.onClick : undefined,
        }}
      >
        <InnerIcon size={sizePx} stroke={2} />
      </div>
    </Popover>
  ) : (
    <div {...{ className, onClick: props.onClick }}>
      <InnerIcon size={sizePx} stroke={2} />
    </div>
  );
};

export default Icon;
