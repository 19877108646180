import React, { useEffect, useRef } from "react";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import SignaturePad from "signature_pad";

// Use this component with `useSignaturePad` hook:
//
// const { signaturePadProps, isEmpty, uploadSignature, uploading } = useSignaturePad();
// <CustomSignatureCanvas {...signaturePadProps} />

export interface CustomSignatureCanvasProps {
  loading?: boolean;
  isEmptyState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  signaturePadState: [
    SignaturePad | null,
    React.Dispatch<React.SetStateAction<SignaturePad | null>>,
  ];
}

const CustomSignatureCanvas: React.FC<CustomSignatureCanvasProps> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [signaturePad, setSignaturePad] = props.signaturePadState;
  const [isEmpty, setIsEmpty] = props.isEmptyState;

  useEffect(() => {
    if (canvasRef.current) {
      const newSignPad = new SignaturePad(canvasRef.current, {});
      setSignaturePad(newSignPad);
    }
  }, [setSignaturePad]);

  const clearSignature = () => {
    signaturePad?.clear();
    setIsEmpty(true);
  };

  return (
    <div className={`flex flex-col gap-0.5`}>
      <div className={`pr-4 w-full`}>
        <div className="bg-suplementary-1 border-0.125 rounded-0.5 border-suplementary-3 w-full p-0.125">
          <canvas
            style={{
              width: `100%`,
              height: `100%`,
            }}
            width={canvasRef.current?.offsetWidth}
            height={canvasRef.current?.offsetHeight}
            ref={canvasRef}
          ></canvas>
        </div>
      </div>
      <Button
        block
        loading={props.loading}
        onClick={clearSignature}
        icon={<DeleteOutlined />}
        disabled={isEmpty}
      >
        Clear
      </Button>
    </div>
  );
};

export default CustomSignatureCanvas;
