// import Button from "src/common/components/general/Button";
import { Button, Dropdown, MenuProps, Tooltip } from "antd";
import React, { useRef, useState } from "react";
import AddSafetyReportModal, {
  AddSafetyReportModalRef,
} from "src/common/components/dialogs/AddSafetyReportModal";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";

export interface GCProjectReportsDailyDayAddButtonProps {
  projectId: string;
  day: dayjs.Dayjs;
}

const GCProjectReportsDailyDayAddButton: React.FC<
  GCProjectReportsDailyDayAddButtonProps
> = (props) => {
  const [selectedReportType, setSelectedReportType] = useState<string | null>(
    null,
  );
  const addSafetyReportModal = useRef<AddSafetyReportModalRef>(null);
  const menuRef = useRef(null);

  const menuItems: {
    title: string;
    key: string;
    disabled?: boolean;
  }[] = [
    {
      title: `Safety`,
      key: `safety`,
    },
    {
      title: `Work Log`,
      disabled: true,
      key: "work-log",
    },
    {
      title: `Note`,
      disabled: true,
      key: "note",
    },
    {
      title: `Schedule`,
      disabled: true,
      key: "schedule",
    },
    {
      title: `Weather`,
      disabled: true,
      key: "weather",
    },
  ];
  const menu: MenuProps = {
    items: menuItems.map((menuItem) =>
      !menuItem.disabled
        ? {
            key: menuItem.key,
            onClick: (e) => {
              e.domEvent.stopPropagation();
              setSelectedReportType(menuItem.key);
              addSafetyReportModal.current?.open();
            },
            label: <p>{menuItem.title}</p>,
          }
        : {
            key: menuItem.key,
            disabled: true,
            label: (
              <Tooltip placement="left" title={`Comming soon`}>
                <p>{menuItem.title}</p>
              </Tooltip>
            ),
          },
    ),
  };

  return (
    <>
      <AddSafetyReportModal
        ref={addSafetyReportModal}
        projectId={props.projectId}
        day={props.day}
        onInserted={() => {}}
      />
      <Dropdown menu={menu} trigger={["click"]}>
        <Button
          onClick={(e) => e.stopPropagation()}
          shape={"circle"}
          type={"default"}
          icon={<PlusOutlined />}
        />
      </Dropdown>
    </>
  );
};

export default GCProjectReportsDailyDayAddButton;
