import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { graphql } from "babel-plugin-relay/macro";
import GetFullID from "src/common/functions/GetFullId";

import { useArchiveOrUnarchiveJHAMutation } from "src/common/types/generated/relay/useArchiveOrUnarchiveJHAMutation.graphql";
import dayjs from "dayjs";
import { auth } from "src/common/functions/firebase";

const useArchiveOrUnarchiveJHA = () => {
  const [updateJha] =
    useAsyncMutation<useArchiveOrUnarchiveJHAMutation>(graphql`
      mutation useArchiveOrUnarchiveJHAMutation(
        $taskId: uuid!
        $_set: task_set_input!
      ) {
        update_task_by_pk(pk_columns: { id: $taskId }, _set: $_set) {
          id
          archived_at
          archived_by_uid
        }
      }
    `);

  const archiveOrUnarchiveJha = async (
    taskId: string,
    type: "archive" | "unarchive",
  ) => {
    await updateJha({
      variables: {
        taskId: taskId,
        _set: {
          archived_at: type === "archive" ? dayjs().format() : null,
          archived_by_uid: type === "archive" ? auth.currentUser?.uid : null,
        },
      },

      optimisticResponse: {
        update_task_by_pk: {
          id: GetFullID("task", taskId),
          archived_at: type === "archive" ? dayjs().format() : null,
          archived_by_uid: type === "archive" ? auth.currentUser?.uid : null,
        },
      },
    });
  };
  return [archiveOrUnarchiveJha] as const;
};

export default useArchiveOrUnarchiveJHA;
