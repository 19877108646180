import React from "react";
import { Outlet } from "react-router-dom";
import NestedRoute from "src/common/types/manual/NestedRoute";
import SiteOrientationUniversalOnly from "./newDesign/SiteOrientationUniversalOnly";

const universalOrientationRoute: NestedRoute = {
  element: <SiteOrientationUniversalOnly />,
};

export default universalOrientationRoute;
