import React, { useState } from "react";
import FModal from "src/common/components/dialogs/FModal";
import {
  InspectionInsertValues,
  OptionType,
} from "../utils/siteInspectionTypes";
import { IconPlus, IconX } from "@tabler/icons";
import { FormInstance } from "antd/lib/form";
import { Button } from "antd";
import Icon from "src/common/components/general/Icon";
import clsx from "clsx";

const EditInspectionFields: React.FC<{
  initialValues: InspectionInsertValues;
  form: FormInstance<InspectionInsertValues> | undefined;
  inspectionQROptions?: Array<OptionType>;
  template?: boolean;
  labelOptions: Array<OptionType>;
  showAddToCompany?: boolean;
  showAddToCommunity?: boolean;
}> = ({ initialValues, inspectionQROptions, labelOptions, form, ...props }) => {
  const [showCreateQR, setShowCreateQR] = useState(false);
  const [modelOptionSelected, setModelOptionSelected] = useState<
    "required" | "default" | undefined
  >(
    initialValues.model_number
      ? "default"
      : initialValues.model_number_required
      ? "required"
      : undefined,
  );
  const [isMulti, setIsMulti] = useState(initialValues.is_multi_inspection);
  return (
    <>
      <div className="text-1.25">Details</div>
      <FModal.Text
        label="Inspection Name"
        required
        initialValue={initialValues.name}
        name={"name"}
        requiredMessage="Please enter a valid Inspection name"
      />
      {(props.showAddToCommunity || props.showAddToCompany) && (
        <>
          <div className={clsx("flex", props.showAddToCompany ? "" : "hidden")}>
            <FModal.Checkbox
              name="company"
              valuePropName="checked"
              initialValue={initialValues.company}
              props={{ value: true }}
            />
            &nbsp;&nbsp;
            <span className="mt-0.25">
              <span className="font-accent">Company Library</span>: add this
              inspection to your Company Library so it can be used on other
              company projects
            </span>
          </div>
          <div
            className={clsx("flex", props.showAddToCommunity ? "" : "hidden")}
          >
            <FModal.Checkbox
              name="community"
              valuePropName="checked"
              props={{ value: true }}
              initialValue={initialValues.community}
            />
            &nbsp;&nbsp;
            <span className="mt-0.25">
              <span className="font-accent">Community Library</span>: support
              the community, add this inspection to the Community Library
            </span>
          </div>
        </>
      )}
      <div className="flex">
        <FModal.Checkbox
          initialValue={initialValues.is_multi_inspection}
          name="is_multi_inspection"
          valuePropName="checked"
          props={{
            value: true,
            onChange: (e) => {
              if (e.target.checked) {
                setIsMulti(true);
                form &&
                  form.setFieldsValue({
                    model_number: undefined,
                    model_number_required: true,
                  });
                setModelOptionSelected("required");
              } else setIsMulti(false);
            },
          }}
        />{" "}
        &nbsp;&nbsp;
        <span className="mt-0.25">
          <span className="font-accent">Multi-Inspect</span>: performing
          multiple inspection at one time
          <br />
          (Inspectors will select/add the ID/Model # for each item)
        </span>
      </div>
      <div className="flex">
        <FModal.Checkbox
          initialValue={initialValues.image_required}
          name="image_required"
          valuePropName="checked"
          props={{ value: true }}
        />{" "}
        &nbsp;&nbsp;
        <span className="mt-0.25">
          <span className="font-accent">Photos</span>: inspector is required to
          add photo(s) to the inspection
        </span>
      </div>
      <div className={isMulti ? "hidden" : ""}>
        <div className="font-accent mt-1 mb-0.25">
          Location, Model, Serial, or ID #
        </div>
        <div className="ml-0.5">
          If there is a Location or Number associated with this inspection,
          choose one:{" "}
          <div className="flex mt-0.25">
            <FModal.Checkbox
              initialValue={initialValues.model_number_required}
              name="model_number_required"
              valuePropName="checked"
              props={{
                disabled: isMulti || modelOptionSelected === "default",
                value: true,
                onChange: (e) => {
                  if (e.target.checked) {
                    form && form.setFieldsValue({ model_number: undefined });
                    setModelOptionSelected("required");
                  } else setModelOptionSelected(undefined);
                },
              }}
            />
            <span className="mt-0.25">
              &nbsp;&nbsp;Inspector will provide or select the
              ID#/model/location each time they perform the inspection (the same
              inspection is used for multiple items with different ID #’s){" "}
              <br />
              For example, a Fire Extinguisher inspection could have many ID #’s
              all on the same Inspection
            </span>
          </div>
          <div>
            <div className="mt-0.25 mb-0.25">- OR -</div>
            <FModal.Text
              initialValue={initialValues.model_number}
              label="A location, model, or ID# is assigned to the one item being inspected (one item per inspection)"
              name="model_number"
              props={{
                disabled: isMulti || modelOptionSelected === "required",
                placeholder:
                  "Enter the location, model or identification #, which is used to identify the item being inspected",
                onChange: (e) => {
                  if (e.target.value && e.target.value.trim()) {
                    form &&
                      form.setFieldsValue({ model_number_required: false });
                    setModelOptionSelected("default");
                  } else setModelOptionSelected(undefined);
                },
              }}
            />
          </div>
        </div>
      </div>

      <FModal.Select
        name={"labels"}
        initialValue={initialValues.labels}
        props={{
          options: labelOptions,
          mode: "multiple",
          placeholder:
            "Labels help when sorting and searching completed inspections (example labels: “Equipment” or “Scaffold”",
        }}
        label={<div className="font-accent">Label</div>}
      />
      {!props.template && (
        <>
          <div className="text-1.25">QR Code</div>
          {(inspectionQROptions || []).length > 0 && (
            <FModal.Select
              name={"inspection_qr_codes"}
              initialValue={initialValues.inspection_qr_codes}
              props={{ options: inspectionQROptions ?? [], mode: "multiple" }}
              label=" Add Inspection to an existing QR Code"
            />
          )}
          <div className={clsx("flex gap-1")}>
            <span className="mt-0.25">
              {(inspectionQROptions || []).length > 0 && <span>And/Or </span>}
              Create NEW QR Code
            </span>{" "}
            <Button
              type="primary"
              className="rounded-2 font-accent flex"
              onClick={() => {
                if (showCreateQR) {
                  form &&
                    form.setFieldsValue({
                      new_inspection_qr_code: undefined,
                    });
                  setShowCreateQR(false);
                } else {
                  setShowCreateQR(true);
                }
              }}
              icon={
                showCreateQR ? (
                  <Icon icon={IconX} color="white" />
                ) : (
                  <Icon icon={IconPlus} color="white" />
                )
              }
            >
              {showCreateQR ? "Cancel" : "Create"}
            </Button>
          </div>
          {showCreateQR ? (
            <FModal.Text
              name={"new_inspection_qr_code"}
              label="QR Code Name"
              required
              requiredMessage="Please enter a valid Name for QR"
              props={{
                placeholder:
                  "Name this QR code, for example “Fire Extinguishers” or you can be very specific, like “High Reach JLG 600AJ”",
              }}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
export default EditInspectionFields;
