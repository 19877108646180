import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import NavigationWrapper, {
  NavigationMenuProps,
} from "src/common/components/layouts/NavigationWrapper";

const SCPeople: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const navigationMenu: NavigationMenuProps = {
    navigationItems: [
      {
        label: "Workers",
        rootPath: "workers",
        onClick: () => {
          navigate(`workers`);
        },
      },
      {
        label: "Administrators",
        rootPath: "employees",
        onClick: () => {
          navigate("employees");
        },
      },
    ],
  };

  return (
    <NavigationWrapper menu={navigationMenu}>
      <Outlet />
    </NavigationWrapper>
  );
};

export default SCPeople;
