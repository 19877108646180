import { Form, Modal, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useParams } from "react-router-dom";
import { ConnectionHandler } from "relay-runtime";
import TimePicker from "src/common/components/general/TimePicker";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { SelectEmailUserModalMutation } from "src/common/types/generated/relay/SelectEmailUserModalMutation.graphql";
import { SelectEmailUserModalQuery } from "src/common/types/generated/relay/SelectEmailUserModalQuery.graphql";
import * as uuid from "uuid";

const query = graphql`
  query SelectEmailUserModalQuery($projectId: uuid!) {
    project_employee_connection(
      where: { project: { id: { _eq: $projectId } } }
      order_by: { employee: { user: { name: asc } } }
    ) {
      edges {
        node {
          employee {
            user {
              id
              pk: id @__clientField(handle: "pk")
              name
              email
            }
          }
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          id
          siteupdate_time
          timezone
        }
      }
    }
  }
`;

const selectEmailUserMutation = graphql`
  mutation SelectEmailUserModalMutation(
    $objects: [email_project_user_insert_input!]!
    $where: email_project_user_bool_exp!
    $projectId: uuid!
    $_set: project_set_input
  ) {
    insert_email_project_user(objects: $objects) {
      returning {
        id
        pk: id @__clientField(handle: "pk")
        project_id
        user {
          id
          pk: id @__clientField(handle: "pk")
          name
          email
        }
        type
      }
    }
    delete_email_project_user(where: $where) {
      returning {
        id
      }
    }
    update_project_by_pk(pk_columns: { id: $projectId }, _set: $_set) {
      siteupdate_time
      timezone
    }
  }
`;

export interface SelectEmailUserModalValues {
  userIds: Array<string>;
}

interface SelectEmailUserFormValues {
  userIds: Array<string>;
}

interface SelectEmailUserData {
  userId: string;
  emailProjectUserId: string;
}

interface SelectEmailUserModalProps {
  users?: Array<SelectEmailUserData>;
  modalClose: () => void;
  modalVisible: boolean;
}

const SelectEmailUserModal: React.FC<SelectEmailUserModalProps> = (props) => {
  const [form] = Form.useForm();
  const { projectId } = useParams();
  if (!projectId) {
    throw new Error("ProjectId param is not found");
  }
  const data = useLazyLoadQuery<SelectEmailUserModalQuery>(query, {
    projectId: projectId,
  });
  const [loading, setLoading] = useState(false);

  const [siteUpdateMutation] = useAsyncMutation<SelectEmailUserModalMutation>(
    selectEmailUserMutation,
  );

  const projectEmployees = data.project_employee_connection.edges;
  const projectData = data.project_connection.edges[0].node;

  // TODO: do NOT use state for form fields
  const [time, setTime] = useState(
    dayjs(dayjs().format("YYYY-MM-DD ") + projectData.siteupdate_time),
  );

  const onOkClicked = async () => {
    form
      .validateFields()
      .then(async (values: SelectEmailUserFormValues) => {
        setLoading(true);

        const insertEmailProjectUsers = values.userIds
          .filter(
            (uid) => !(props.users ?? []).find((user) => user.userId === uid),
          )
          .map((user) => {
            const insertEmailProjectUserId = uuid.v4();
            return {
              id: insertEmailProjectUserId,
              project_id: projectId!,
              user_id: user,
              type: "site_update",
            };
          });

        const deleteEmailProjectUsers = (props.users ?? []).filter(
          (user) =>
            !values.userIds.find((u) => {
              return u === user.userId;
            }),
        );

        await siteUpdateMutation({
          variables: {
            objects: insertEmailProjectUsers,
            projectId: projectId!,
            where: {
              project_id: { _eq: projectId },
              type: { _eq: "site_update" },
              user_id: {
                _in: deleteEmailProjectUsers.map((user) => user.userId),
              },
            },
            _set: {
              siteupdate_time: time.format("HH:mm"),
            },
          },
          updater: (store) => {
            const insertedEmailProjectUsers = store.getRootField(
              "insert_email_project_user",
            );

            const deletedEmailProjectUser = store.getRootField(
              "delete_email_project_user",
            );

            const conn = ConnectionHandler.getConnection(
              store.getRoot(),
              "SitedailyEmailSettings_email_project_user_connection",
            );

            if (conn) {
              insertedEmailProjectUsers
                .getLinkedRecords("returning")
                .forEach((c) => {
                  const edge = store.create(uuid.v4(), "edge");
                  edge.setLinkedRecord(c, "node");
                  ConnectionHandler.insertEdgeAfter(conn, edge);
                });

              deletedEmailProjectUser
                .getLinkedRecords("returning")
                .forEach((c) => {
                  ConnectionHandler.deleteNode(conn, c.getDataID());
                });
            }
          },
        });
        setLoading(false);
        form.resetFields();
        props.modalClose();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <Modal
        title={`SiteUpdate - Send Daily Email`}
        open={props.modalVisible}
        onCancel={props.modalClose}
        onOk={onOkClicked}
        confirmLoading={loading}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="userIds"
            label="Select users"
            initialValue={props.users?.map((user) => user.userId)}
            rules={[{ required: false, message: "Select users" }]}
          >
            <Select
              listItemHeight={10}
              mode="multiple"
              style={{
                width: "100%",
              }}
              options={projectEmployees.map((employee) => ({
                value: employee.node.employee.user.pk,
                key: employee.node.employee.user.pk,
                label: employee.node.employee.user.name ?? "UNKNOWN",
              }))}
            />
          </Form.Item>
          <Form.Item label="Set the time">
            <TimePicker
              onSelect={(time) => {
                if (time) setTime(time);
              }}
              value={time}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SelectEmailUserModal;
