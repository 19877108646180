import React from "react";

export interface SiteOrientationInPersonWorkerOrientationSlidesViewProps {}

const SiteOrientationInPersonWorkerOrientationSlidesView: React.FC<
  SiteOrientationInPersonWorkerOrientationSlidesViewProps
> = (props) => {
  return <div>I'm the orientation slides view</div>;
};

export default SiteOrientationInPersonWorkerOrientationSlidesView;
