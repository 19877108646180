import {
  ConnectionHandler,
  RecordProxy,
  RecordSourceSelectorProxy,
} from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation.graphql";
import { usePermanentReportRequireSettingsCrew_DRNotRequire_UpdateMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsCrew_DRNotRequire_UpdateMutation.graphql";
import { usePermanentReportRequireSettingsCrew_PTPNotRequire_UpdateMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsCrew_PTPNotRequire_UpdateMutation.graphql";
import { usePermanentReportRequireSettingsSub_TBTNotRequire_UpdateMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsSub_TBTNotRequire_UpdateMutation.graphql";
import { usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation.graphql";
import { usePermanentReportRequireSettingsSub_PTPNotRequire_UpdateMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsSub_PTPNotRequire_UpdateMutation.graphql";
import { usePermanentReportRequireSettingsCrew_TBTNotRequire_InsertMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsCrew_TBTNotRequire_InsertMutation.graphql";
import { usePermanentReportRequireSettingsCrew_DRNotRequire_InsertMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsCrew_DRNotRequire_InsertMutation.graphql";
import { usePermanentReportRequireSettingsCrew_PTPNotRequire_InsertMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsCrew_PTPNotRequire_InsertMutation.graphql";
import { usePermanentReportRequireSettingsSub_TBTNotRequire_InsertMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsSub_TBTNotRequire_InsertMutation.graphql";
import { usePermanentReportRequireSettingsSub_DRNotRequire_InsertMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsSub_DRNotRequire_InsertMutation.graphql";
import { usePermanentReportRequireSettingsSub_PTPNotRequire_InsertMutation } from "src/common/types/generated/relay/usePermanentReportRequireSettingsSub_PTPNotRequire_InsertMutation.graphql";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import GetFullID from "src/common/functions/GetFullId";
import dayjs from "dayjs";

const updateCrewTBTNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation(
    $currentTime: timestamptz!
    $where: project_subcontractor_crew_toolbox_talk_not_require_period_bool_exp!
  ) {
    update_project_subcontractor_crew_toolbox_talk_not_require_period(
      where: $where
      _set: { closed_at: $currentTime }
    ) {
      returning {
        id
      }
    }
  }
`;

const updateCrewDRNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsCrew_DRNotRequire_UpdateMutation(
    $currentTime: timestamptz!
    $where: project_subcontractor_crew_daily_report_not_require_period_bool_exp!
  ) {
    update_project_subcontractor_crew_daily_report_not_require_period(
      where: $where
      _set: { closed_at: $currentTime }
    ) {
      returning {
        id
      }
    }
  }
`;
const updateCrewPTPNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsCrew_PTPNotRequire_UpdateMutation(
    $currentTime: timestamptz!
    $where: project_subcontractor_crew_safety_report_not_require_period_bool_exp!
  ) {
    update_project_subcontractor_crew_safety_report_not_require_period(
      where: $where
      _set: { closed_at: $currentTime }
    ) {
      returning {
        id
      }
    }
  }
`;

const updateSubTBTNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsSub_TBTNotRequire_UpdateMutation(
    $currentTime: timestamptz!
    $where: project_subcontractor_toolbox_talk_not_require_period_bool_exp!
  ) {
    update_project_subcontractor_toolbox_talk_not_require_period(
      where: $where
      _set: { closed_at: $currentTime }
    ) {
      returning {
        id
      }
    }
  }
`;

const updateSubDRNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation(
    $currentTime: timestamptz!
    $where: project_subcontractor_daily_report_not_require_period_bool_exp!
  ) {
    update_project_subcontractor_daily_report_not_require_period(
      where: $where
      _set: { closed_at: $currentTime }
    ) {
      returning {
        id
      }
    }
  }
`;
const updateSubPTPNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsSub_PTPNotRequire_UpdateMutation(
    $currentTime: timestamptz!
    $where: project_subcontractor_safety_report_not_require_period_bool_exp!
  ) {
    update_project_subcontractor_safety_report_not_require_period(
      where: $where
      _set: { closed_at: $currentTime }
    ) {
      returning {
        id
      }
    }
  }
`;
const insertCrewTBTNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsCrew_TBTNotRequire_InsertMutation(
    $object: project_subcontractor_crew_toolbox_talk_not_require_period_insert_input!
  ) {
    insert_project_subcontractor_crew_toolbox_talk_not_require_period_one(
      object: $object
    ) {
      id
    }
  }
`;

const insertCrewDRNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsCrew_DRNotRequire_InsertMutation(
    $object: project_subcontractor_crew_daily_report_not_require_period_insert_input!
  ) {
    insert_project_subcontractor_crew_daily_report_not_require_period_one(
      object: $object
    ) {
      id
    }
  }
`;
const insertCrewPTPNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsCrew_PTPNotRequire_InsertMutation(
    $object: project_subcontractor_crew_safety_report_not_require_period_insert_input!
  ) {
    insert_project_subcontractor_crew_safety_report_not_require_period_one(
      object: $object
    ) {
      id
    }
  }
`;

const insertSubTBTNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsSub_TBTNotRequire_InsertMutation(
    $object: project_subcontractor_toolbox_talk_not_require_period_insert_input!
  ) {
    insert_project_subcontractor_toolbox_talk_not_require_period_one(
      object: $object
    ) {
      id
    }
  }
`;

const insertSubDRNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsSub_DRNotRequire_InsertMutation(
    $object: project_subcontractor_daily_report_not_require_period_insert_input!
  ) {
    insert_project_subcontractor_daily_report_not_require_period_one(
      object: $object
    ) {
      id
    }
  }
`;
const insertSubPTPNotRequireMutation = graphql`
  mutation usePermanentReportRequireSettingsSub_PTPNotRequire_InsertMutation(
    $object: project_subcontractor_safety_report_not_require_period_insert_input!
  ) {
    insert_project_subcontractor_safety_report_not_require_period_one(
      object: $object
    ) {
      id
    }
  }
`;

const usePermanentReportRequireSettings = () => {
  const [updateSubTBTNotRequire, loading1] =
    useAsyncMutation<usePermanentReportRequireSettingsSub_TBTNotRequire_UpdateMutation>(
      updateSubTBTNotRequireMutation,
    );
  const [updateSubDRNotRequire, loading2] =
    useAsyncMutation<usePermanentReportRequireSettingsSub_DRNotRequire_UpdateMutation>(
      updateSubDRNotRequireMutation,
    );
  const [updateSubPTPNotRequire, loading3] =
    useAsyncMutation<usePermanentReportRequireSettingsSub_PTPNotRequire_UpdateMutation>(
      updateSubPTPNotRequireMutation,
    );
  const [insertSubTBTNotRequire, loading4] =
    useAsyncMutation<usePermanentReportRequireSettingsSub_TBTNotRequire_InsertMutation>(
      insertSubTBTNotRequireMutation,
    );
  const [insertSubDRNotRequire, loading5] =
    useAsyncMutation<usePermanentReportRequireSettingsSub_DRNotRequire_InsertMutation>(
      insertSubDRNotRequireMutation,
    );
  const [insertSubPTPNotRequire, loading6] =
    useAsyncMutation<usePermanentReportRequireSettingsSub_PTPNotRequire_InsertMutation>(
      insertSubPTPNotRequireMutation,
    );
  const [updateCrewTBTNotRequire, loading7] =
    useAsyncMutation<usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation>(
      updateCrewTBTNotRequireMutation,
    );
  const [updateCrewDRNotRequire, loading8] =
    useAsyncMutation<usePermanentReportRequireSettingsCrew_DRNotRequire_UpdateMutation>(
      updateCrewDRNotRequireMutation,
    );
  const [updateCrewPTPNotRequire, loading9] =
    useAsyncMutation<usePermanentReportRequireSettingsCrew_PTPNotRequire_UpdateMutation>(
      updateCrewPTPNotRequireMutation,
    );
  const [insertCrewTBTNotRequire, loading10] =
    useAsyncMutation<usePermanentReportRequireSettingsCrew_TBTNotRequire_InsertMutation>(
      insertCrewTBTNotRequireMutation,
    );
  const [insertCrewDRNotRequire, loading11] =
    useAsyncMutation<usePermanentReportRequireSettingsCrew_DRNotRequire_InsertMutation>(
      insertCrewDRNotRequireMutation,
    );
  const [insertCrewPTPNotRequire, loading12] =
    useAsyncMutation<usePermanentReportRequireSettingsCrew_PTPNotRequire_InsertMutation>(
      insertCrewPTPNotRequireMutation,
    );
  const subRequireReportUpdater = (
    store: RecordSourceSelectorProxy,
    subId: string,
    type: "safety_report" | "daily_report" | "toolbox_talk",
  ) => {
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "GCProjectReportManagementQuery_project_connection",
    );
    if (conn) {
      const edge = (conn.getLinkedRecords("edges") ?? [])[0];
      if (edge) {
        const node = edge.getLinkedRecord("node");

        if (node) {
          const projectSubs =
            node.getLinkedRecords("project_subcontractors", {
              order_by: { subcontractor: { name: Order_By.Asc } },
            }) || [];

          const projSub = projectSubs.find((ps) => {
            const toReturn = ps.getValue("subcontractor_id") === subId;
            return toReturn;
          });

          if (projSub) {
            projSub.setLinkedRecords(
              [],
              `project_subcontractor_${type}_not_require_periods`,
              { where: { closed_at: { _is_null: true } } },
            );
          }
        }
      }
    }
  };
  const subNotRequireReportUpdater = (
    store: RecordSourceSelectorProxy,
    subId: string,
    enteredRecord: RecordProxy<{
      readonly id: string;
    }>,
    type: "safety_report" | "daily_report" | "toolbox_talk",
  ) => {
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "GCProjectReportManagementQuery_project_connection",
    );
    if (conn) {
      const edge = (conn.getLinkedRecords("edges") ?? [])[0];
      if (edge) {
        const node = edge.getLinkedRecord("node");

        if (node) {
          const projectSubs =
            node.getLinkedRecords("project_subcontractors", {
              order_by: { subcontractor: { name: Order_By.Asc } },
            }) || [];

          const projSub = projectSubs.find((ps) => {
            const toReturn = ps.getValue("subcontractor_id") === subId;
            return toReturn;
          });

          if (projSub) {
            projSub.setLinkedRecords(
              [enteredRecord],
              `project_subcontractor_${type}_not_require_periods`,
              { where: { closed_at: { _is_null: true } } },
            );
          }
        }
      }
    }
  };
  const crewRequireReportUpdater = (
    store: RecordSourceSelectorProxy,
    subId: string,
    crewId: string,
    type: "safety_report" | "daily_report" | "toolbox_talk",
  ) => {
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "GCProjectReportManagementQuery_project_connection",
    );
    if (conn) {
      const edge = (conn.getLinkedRecords("edges") ?? [])[0];
      if (edge) {
        const node = edge.getLinkedRecord("node");

        if (node) {
          const projectSubs =
            node.getLinkedRecords("project_subcontractors", {
              order_by: { subcontractor: { name: Order_By.Asc } },
            }) || [];

          const projSub = projectSubs.find((ps) => {
            const toReturn = ps.getValue("subcontractor_id") === subId;
            return toReturn;
          });

          if (projSub) {
            const projCrews =
              projSub.getLinkedRecords("project_crews", {
                where: { deleted_at: { _is_null: true } },
                order_by: { name: Order_By.Asc },
              }) || [];
            const projCrew = projCrews.find(
              (pc) => pc.getValue("id") == GetFullID("project_crew", crewId),
            );
            if (projCrew) {
              projCrew.setLinkedRecords(
                [],
                `project_subcontractor_crew_${type}_not_require_periods`,
                { where: { closed_at: { _is_null: true } } },
              );
            }
          }
        }
      }
    }
  };

  const crewNotRequireReportUpdater = (
    store: RecordSourceSelectorProxy,
    subId: string,
    crewId: string,
    enteredRecord: RecordProxy<{
      readonly id: string;
    }>,
    type: "safety_report" | "daily_report" | "toolbox_talk",
  ) => {
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "GCProjectReportManagementQuery_project_connection",
    );
    if (conn) {
      const edge = (conn.getLinkedRecords("edges") ?? [])[0];
      if (edge) {
        const node = edge.getLinkedRecord("node");

        if (node) {
          const projectSubs =
            node.getLinkedRecords("project_subcontractors", {
              order_by: { subcontractor: { name: Order_By.Asc } },
            }) || [];

          const projSub = projectSubs.find((ps) => {
            const toReturn = ps.getValue("subcontractor_id") === subId;
            return toReturn;
          });

          if (projSub) {
            const projCrews =
              projSub.getLinkedRecords("project_crews", {
                where: { deleted_at: { _is_null: true } },
                order_by: { name: Order_By.Asc },
              }) || [];
            const projCrew = projCrews.find(
              (pc) => pc.getValue("id") == GetFullID("project_crew", crewId),
            );
            if (projCrew) {
              projCrew.setLinkedRecords(
                [enteredRecord],
                `project_subcontractor_crew_${type}_not_require_periods`,
                { where: { closed_at: { _is_null: true } } },
              );
            }
          }
        }
      }
    }
  };

  const setSubRequire = async ({
    projectId,
    subId,
    type,
  }: {
    projectId: string;
    subId: string;
    type: "safety_report" | "daily_report" | "toolbox_talk";
  }) => {
    if (type === "toolbox_talk") {
      await updateSubTBTNotRequire({
        variables: {
          where: {
            project_id: { _eq: projectId },
            subcontractor_id: { _eq: subId },
          },
          currentTime: dayjs().toISOString(),
        },
        updater: (store) => {
          subRequireReportUpdater(store, subId, type);
        },
      });
    } else if (type === "daily_report") {
      await updateSubDRNotRequire({
        variables: {
          where: {
            project_id: { _eq: projectId },
            subcontractor_id: { _eq: subId },
          },
          currentTime: dayjs().toISOString(),
        },
        updater: (store) => {
          subRequireReportUpdater(store, subId, type);
        },
      });
    } else if (type === "safety_report") {
      await updateSubPTPNotRequire({
        variables: {
          where: {
            project_id: { _eq: projectId },
            subcontractor_id: { _eq: subId },
          },
          currentTime: dayjs().toISOString(),
        },
        updater: (store) => {
          subRequireReportUpdater(store, subId, type);
        },
      });
    }
  };
  const setSubNotRequire = async ({
    projectId,
    subId,
    type,
  }: {
    projectId: string;
    subId: string;
    type: "safety_report" | "daily_report" | "toolbox_talk";
  }) => {
    if (type === "toolbox_talk") {
      await insertSubTBTNotRequire({
        variables: {
          object: { project_id: projectId, subcontractor_id: subId },
        },
        updater: (store) => {
          const insertedVal = store.getRootField(
            "insert_project_subcontractor_toolbox_talk_not_require_period_one",
          );
          subNotRequireReportUpdater(store, subId, insertedVal, type);
        },
      });
    } else if (type === "daily_report") {
      await insertSubDRNotRequire({
        variables: {
          object: { project_id: projectId, subcontractor_id: subId },
        },
        updater: (store) => {
          const insertedVal = store.getRootField(
            "insert_project_subcontractor_daily_report_not_require_period_one",
          );
          subNotRequireReportUpdater(store, subId, insertedVal, type);
        },
      });
    } else if (type === "safety_report") {
      await insertSubPTPNotRequire({
        variables: {
          object: { project_id: projectId, subcontractor_id: subId },
        },
        updater: (store) => {
          const insertedVal = store.getRootField(
            "insert_project_subcontractor_safety_report_not_require_period_one",
          );
          subNotRequireReportUpdater(store, subId, insertedVal, type);
        },
      });
    }
  };
  const setCrewRequire = async ({
    crewId,
    subId,
    type,
  }: {
    crewId: string;
    subId: string;
    type: "safety_report" | "daily_report" | "toolbox_talk";
  }) => {
    if (type === "toolbox_talk") {
      await updateCrewTBTNotRequire({
        variables: {
          where: {
            project_crew_id: { _eq: crewId },
          },
          currentTime: dayjs().toISOString(),
        },
        updater: (store) => {
          crewRequireReportUpdater(store, subId, crewId, type);
        },
      });
    } else if (type === "daily_report") {
      await updateCrewDRNotRequire({
        variables: {
          where: {
            project_crew_id: { _eq: crewId },
          },
          currentTime: dayjs().toISOString(),
        },
        updater: (store) => {
          crewRequireReportUpdater(store, subId, crewId, type);
        },
      });
    } else if (type === "safety_report") {
      await updateCrewPTPNotRequire({
        variables: {
          where: {
            project_crew_id: { _eq: crewId },
          },
          currentTime: dayjs().toISOString(),
        },
        updater: (store) => {
          crewRequireReportUpdater(store, subId, crewId, type);
        },
      });
    }
  };
  const setCrewNotRequire = async ({
    crewId,
    subId,
    type,
  }: {
    crewId: string;
    subId: string;
    type: "safety_report" | "daily_report" | "toolbox_talk";
  }) => {
    if (type === "toolbox_talk") {
      await insertCrewTBTNotRequire({
        variables: {
          object: { project_crew_id: crewId },
        },
        updater: (store) => {
          const insertedVal = store.getRootField(
            "insert_project_subcontractor_crew_toolbox_talk_not_require_period_one",
          );
          crewNotRequireReportUpdater(store, subId, crewId, insertedVal, type);
        },
      });
    } else if (type === "daily_report") {
      await insertCrewDRNotRequire({
        variables: {
          object: { project_crew_id: crewId },
        },
        updater: (store) => {
          const insertedVal = store.getRootField(
            "insert_project_subcontractor_crew_daily_report_not_require_period_one",
          );
          crewNotRequireReportUpdater(store, subId, crewId, insertedVal, type);
        },
      });
    } else if (type === "safety_report") {
      await insertCrewPTPNotRequire({
        variables: {
          object: { project_crew_id: crewId },
        },
        updater: (store) => {
          const insertedVal = store.getRootField(
            "insert_project_subcontractor_crew_safety_report_not_require_period_one",
          );
          crewNotRequireReportUpdater(store, subId, crewId, insertedVal, type);
        },
      });
    }
  };
  return {
    setSubRequire,
    setSubNotRequire,
    setCrewRequire,
    setCrewNotRequire,
  };
};
export default usePermanentReportRequireSettings;
