import React from "react";
import SiteBoardOnsiteColumnBody, {
  SiteBoardOnsiteColumnBodyProps,
} from "./SiteBoardOnsiteColumnBody";

export interface SiteBoardOnsiteColumnProps {
  header: React.ReactNode;
  bodyRows: SiteBoardOnsiteColumnBodyProps["rows"];
}

const SiteBoardOnsiteColumn: React.FC<SiteBoardOnsiteColumnProps> = (props) => {
  return (
    <div className="flex flex-col gap-1">
      <div
        style={{ background: "black" }}
        className="z-20 sticky flex flex-col justify-end items-center top-0 gap-0.5 h-5"
      >
        {props.header}
      </div>
      <SiteBoardOnsiteColumnBody {...{ rows: props.bodyRows }} />
    </div>
  );
};

export default SiteBoardOnsiteColumn;
