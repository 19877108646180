import React, { createContext } from "react";
import {
  CurrentSessionInspectionDataType,
  UserType,
} from "./siteInspectionTypes";

export type SiteInspectionUserContextType = {
  qrCodeId: string;
  projectId: string;
  currentSessionInspectionsRequiredData: CurrentSessionInspectionDataType;
  setCurrentSessionInspectionsRequiredData: React.Dispatch<
    React.SetStateAction<CurrentSessionInspectionDataType>
  >;
  updateUser: React.Dispatch<React.SetStateAction<UserType | null>>;
  user?: UserType;
} | null;

const SiteInspectionUserContext =
  createContext<SiteInspectionUserContextType>(null);

export default SiteInspectionUserContext;
