import { QueryResult } from "@apollo/client";
import StyledContent from "src/common/components/layouts/StyledContent";
import {
  GetGcPermitChecklistItemsQuery,
  GetGcPermitChecklistItemsQueryVariables,
  useUpdatePermitChecklistItemMutation,
  useDeletePermitChecklistItemMutation,
  GetGcPermitChecklistItemsDocument,
  useInsertPermitChecklistItemMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import buildNewGCData from "../../../utils/buildNewGCData";
import PermitChecklistsTables from "./PermitChecklistsTables";
import { useState } from "react";
import AddPermitChecklistItemModal from "src/common/components/dialogs/AddPermitChecklistItemModal";
import buildNewProjData from "../../../utils/buildNewProjData";
import { AddingSettingPermitTypes } from "../../../utils/PermitSettingsTypes";

const ShowPermitChecklistsTables: React.FC<{
  data: GetGcPermitChecklistItemsQuery;
  gcPermitCheckListItems: QueryResult<
    GetGcPermitChecklistItemsQuery,
    GetGcPermitChecklistItemsQueryVariables
  >;
  queryVariables: GetGcPermitChecklistItemsQueryVariables;
  checklistItemsType: AddingSettingPermitTypes;
  project?: GetGcPermitChecklistItemsQuery["project"][number];
  checklistGc: GetGcPermitChecklistItemsQuery["general_contractor"][number];
}> = ({
  checklistGc,
  data,
  project,
  gcPermitCheckListItems,
  queryVariables,
  checklistItemsType,
}) => {
  const [deletePermitChecklistitem] = useDeletePermitChecklistItemMutation();
  const [updatePermitChecklistItem] = useUpdatePermitChecklistItemMutation();
  const [insertPermitChecklistItem, { loading: insertingPermitChecklistItem }] =
    useInsertPermitChecklistItemMutation();
  const [addingItemType, setAddingItemType] = useState<"permit" | "closing">();
  const settingUseLevel = project?.project_setting?.permit_setting_level;
  const allChecklistItems = (
    settingUseLevel === "project" && project ? project : checklistGc
  ).permit_checklist_items;
  const checklistItems = allChecklistItems.filter(
    (c) => c.item_type === "permit" && c.permit_type === checklistItemsType,
  );
  const closingChecklistItems = allChecklistItems.filter(
    (c) => c.item_type === "closing" && c.permit_type === checklistItemsType,
  );
  const updateChecklistItem = ({
    key,
    val,
    id,
  }: {
    key: string;
    val: boolean;
    id: string;
  }) => {
    updatePermitChecklistItem({
      variables: {
        _set: { [key]: val },
        where: { id: { _eq: id } },
      },
    }).catch(console.error);
    if (gcPermitCheckListItems.data) {
      const checklistNewDataList = allChecklistItems.map((item) => ({
        ...item,
        [key]: item.id !== id ? (item as any)[key] : val,
      }));

      const newData =
        settingUseLevel === "project" && project
          ? buildNewProjData(data, project.id, {
              ...project,
              permit_checklist_items: checklistNewDataList,
            })
          : buildNewGCData(data, checklistGc.id, {
              ...checklistGc,
              permit_checklist_items: checklistNewDataList,
            });
      gcPermitCheckListItems.client.writeQuery({
        query: GetGcPermitChecklistItemsDocument,
        variables: queryVariables,
        data: newData,
      });
    }
  };
  return (
    <StyledContent>
      <AddPermitChecklistItemModal
        visible={!!addingItemType}
        onCancel={() => setAddingItemType(undefined)}
        onCreate={async (values) => {
          let items =
            addingItemType === "closing"
              ? closingChecklistItems
              : checklistItems;
          setAddingItemType(undefined);

          // TODO confined space?

          await insertPermitChecklistItem({
            awaitRefetchQueries: true,
            variables: {
              objects: [
                {
                  description: {
                    data: {
                      original: values.description,
                    },
                  },
                  yes_enabled: values.selectedOptions.includes("yesEnabled"),
                  no_enabled: values.selectedOptions.includes("noEnabled"),
                  na_enabled: values.selectedOptions.includes("naEnabled"),
                  text_enabled: values.selectedOptions.includes("textEnabled"),
                  text_required:
                    values.selectedOptions.includes("textRequired"),
                  ...(settingUseLevel === "project" && project
                    ? { project_id: project.id }
                    : { general_contractor_id: checklistGc.id }),
                  sort_index: (items.length + 1) * 10000,
                  item_type: addingItemType,
                  permit_type: checklistItemsType,
                },
              ],
            },
            refetchQueries: [
              {
                query: GetGcPermitChecklistItemsDocument,
                variables: queryVariables,
              },
            ],
          });
        }}
      />
      <PermitChecklistsTables
        checklistItems={checklistItems}
        closingChecklistItems={closingChecklistItems}
        editDisable={!!project && settingUseLevel !== "project"}
        onReorderItem={({ itemId, newSortIndex }) => {
          updatePermitChecklistItem({
            variables: {
              where: { id: { _eq: itemId } },
              _set: { sort_index: newSortIndex },
            },
          }).catch(console.error);
          const checklistNewDataList = allChecklistItems
            .map((item) => ({
              ...item,
              sort_index: item.id !== itemId ? item.sort_index : newSortIndex,
            }))
            .sort((a, b) => (a.sort_index < b.sort_index ? -1 : 1));
          const newData =
            settingUseLevel === "project" && project
              ? buildNewProjData(data, project.id, {
                  ...project,
                  permit_checklist_items: checklistNewDataList,
                })
              : buildNewGCData(data, checklistGc.id, {
                  ...checklistGc,
                  permit_checklist_items: checklistNewDataList,
                });

          gcPermitCheckListItems.client.writeQuery({
            query: GetGcPermitChecklistItemsDocument,
            variables: queryVariables,
            data: newData,
          });
        }}
        projectLevel={settingUseLevel === "project" && !!project}
        onUpdateItem={updateChecklistItem}
        onDeleteItem={({ itemId }) => {
          deletePermitChecklistitem({
            variables: { where: { id: { _eq: itemId } } },
          }).catch(console.error);
          const checklistNewDataList = allChecklistItems.filter(
            (oldItem) => oldItem.id !== itemId,
          );
          const newData =
            settingUseLevel === "project" && project
              ? buildNewProjData(data, project.id, {
                  ...project,
                  permit_checklist_items: checklistNewDataList,
                })
              : buildNewGCData(data, checklistGc.id, {
                  ...checklistGc,
                  permit_checklist_items: checklistNewDataList,
                });

          gcPermitCheckListItems.client.writeQuery({
            query: GetGcPermitChecklistItemsDocument,
            variables: queryVariables,
            data: newData,
          });
        }}
        loading={insertingPermitChecklistItem}
        onAddItemPress={({ itemType }) => setAddingItemType(itemType)}
      />
    </StyledContent>
  );
};
export default ShowPermitChecklistsTables;
