import React from "react";
import { Button, Typography } from "antd";
import { useCopyToClipboard } from "usehooks-ts";

const { Paragraph, Text } = Typography;

interface ErrorCodeBlockProps {
  errorMessage: string;
  showCopyButton?: boolean;
}

const ErrorCodeBlock: React.FC<ErrorCodeBlockProps> = (props) => {
  const [_, copyToClipboard] = useCopyToClipboard();

  return (
    <Paragraph className={"text-0.75 text-left"}>
      <Text type={"secondary"}>
        <pre>
          <span>// Error Text:</span>
          <br />
          {props.errorMessage}
        </pre>
      </Text>
      {props.showCopyButton && (
        <Button
          onClick={() => {
            copyToClipboard(props.errorMessage);
          }}
        >
          Copy
        </Button>
      )}
    </Paragraph>
  );
};

export default ErrorCodeBlock;
