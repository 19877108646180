import { Popconfirm, Typography } from "antd";
import React, { FC } from "react";
import BaseTable, {
  BaseTableProps,
} from "src/common/components/tables/basic/BaseTable";
import { auth } from "src/common/functions/firebase";
import { Subcontractor_Employee } from "src/common/types/generated/apollo/graphQLTypes";
import styled, { StyledComponent } from "styled-components";

type Props = Omit<
  BaseTableProps<Partial<Subcontractor_Employee>>,
  "columns"
> & {
  onDelete?: (se: Subcontractor_Employee) => any;
};
type SubcontractorEmployeesTable = FC<Props>;

const SubcontractorEmployeesTable = styled(BaseTable).attrs(
  ({ pagination, skipKeys, onDelete, ...props }: Props) => {
    const columns = [
      {
        title: "Name",
        key: "name",
        dataIndex: ["user", "name"],
      },
      {
        title: "Title",
        key: "title",
        dataIndex: ["employee_title", "name", "clientText"],
      },
      {
        title: "Phone number",
        key: "phone",
        dataIndex: ["user", "phone_number"],
      },
      {
        title: "Username",
        key: "username",
        dataIndex: ["user", "username"],
      },
      {
        title: "Email",
        key: "email",
        dataIndex: ["user", "email"],
      },
      {
        title: "",
        key: "remove",
        render: (_: any, subEmployee: Subcontractor_Employee) =>
          auth.currentUser?.uid !== subEmployee.user.id && (
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => {
                if (onDelete) onDelete(subEmployee);
              }}
              okText="Yes"
              cancelText="Cancel"
            >
              <a>
                <Typography.Text type="danger">Remove</Typography.Text>
              </a>
            </Popconfirm>
          ),
      },
    ].filter(({ key }) => !(skipKeys ?? []).includes(key));

    return {
      ...props,
      columns,
      rowKey: (w: any) => w.id,
      summary: () => (
        <tr>
          <th>Total</th>
          <td colSpan={columns.length - 1}>
            {!!pagination && pagination.total}
          </td>
        </tr>
      ),
    };
  }
)``;

export default SubcontractorEmployeesTable as StyledComponent<
  SubcontractorEmployeesTable,
  any,
  Props,
  never
>;
