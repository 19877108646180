import { Button } from "antd";
import React from "react";
import { Language } from "../../../../../utility-features/i18n/language-utils/i18nTypes";
import getLanguageTitle from "../../../../../utility-features/i18n/language-utils/getLanguageTitle";
import { AVAILABLE_LANGUAGES } from "../../../../../utility-features/i18n/languages";
import { useCurrentLangStrings } from "../../../../../utility-features/i18n/context/languageHooks";

const SelectLanguage: React.FC<{
  onLanguageChange: (language: Language) => void;
  language: Language;
}> = (props) => {
  const langStrings = useCurrentLangStrings();
  return (
    <>
      <p className="font-accent text-center">
        {langStrings.strings.whatLanguage}
      </p>
      <div className={`flex flex-col gap-0.25`}>
        {AVAILABLE_LANGUAGES.map((lang) => (
          <Button key={lang} onClick={() => props.onLanguageChange(lang)}>
            {getLanguageTitle(lang)}
          </Button>
        ))}
      </div>
    </>
  );
};
export default SelectLanguage;
