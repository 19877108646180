import { useRef, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';
import usePrevious from './usePrevious';

// TODO save all previous variable iterations
const useCustomLazyLoadQuery: typeof useLazyLoadQuery = (
  query,
  variables,
  config,
) => {
  const varRef = useRef<any>({});
  //const prevVariables = usePrevious(variables);
  //const variablesAreNew =
  //  !!prevVariables &&
  //  JSON.stringify(prevVariables) !== JSON.stringify(variables);
  let variablesAreNew = false;
  //console.log("prevVariables", prevVariables);
  let vars =  varRef.current;
  if (JSON.stringify(varRef.current) !== JSON.stringify(variables)) {
    varRef.current = variables;
    vars = variables;
    variablesAreNew = true;
  }

  const res = useLazyLoadQuery(query, vars, {
    ...config,
    fetchPolicy: variablesAreNew ? 'network-only' : 'store-and-network',
  });

  return res;
};

export default useCustomLazyLoadQuery;
