import { IconDownload, IconSettings, IconUser } from "@tabler/icons";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { useRef, useState, useReducer } from "react";
import {
  ConnectionHandler,
  commitLocalUpdate,
  useLazyLoadQuery,
  useRelayEnvironment,
} from "react-relay/hooks";
import { useNavigate, useParams } from "react-router-dom";
import AddWorkerSpreadsheetModal, {
  AddWorkerSpreadsheetModalRef,
} from "src/common/components/dialogs/AddWorkerSpreadsheetModal";
import { CustomFilterType } from "src/common/components/filter/CustomFilterComponents/CustomFilterController";
import {
  project_worker_bool_exp,
  user_orientation_bool_exp,
  worker_certification_bool_exp,
} from "src/common/types/generated/relay/ProjectWorkerTableQuery.graphql";
import ProjectWorkerTable, {
  ColumnKeys,
} from "src/common/components/tables/ProjectWorkerTable";
import { GCProjectWorkersTableQuery } from "src/common/types/generated/relay/GCProjectWorkersTableQuery.graphql";
import GCCreateWorkerModal, { AddWorkerModalRef } from "../GCCreateWorkerModal";
import GCDownloadWorkersListModal, {
  GCDownloadWorkersListModalRef,
} from "../GCDownloadWorkersListModal";
import PerformWorkerFunctions from "src/common/components/modals/PerformWorkerFunctions";
import Button from "src/common/components/general/Button";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import WorkerProfile from "src/root/routes/views/subcontractor/people/workers/worker-profile/WorkerProfile";
import { ProjectWorkerTableType } from "src/common/components/tables/ProjectWorkerTable";
import clsx from "clsx";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import useAuthUser from "src/common/hooks/useAuthUser";
import GetFullID from "src/common/functions/GetFullId";

interface GCProjectWorkersTableProps {}

let selectedCertificateIds: Array<string> | null;
let selectedCertExpiryDays: number | null;

const GCProjectWorkersTable: React.FC<GCProjectWorkersTableProps> = () => {
  const [hideFilter, setHideFilter] = useState(false);
  const authUser = useAuthUser();
  const projectId = useParams()["projectId"] as string;
  const data = useLazyLoadQuery<GCProjectWorkersTableQuery>(
    graphql`
      query GCProjectWorkersTableQuery($projectId: uuid!, $uid: uuid!) {
        worker_title_connection(order_by: { translation: { en: asc } }) {
          edges {
            node {
              pk: id @__clientField(handle: "pk")
              translation {
                en
              }
            }
          }
        }

        general_contractor_employee_connection(where: { uid: { _eq: $uid } }) {
          edges {
            node {
              general_contractor {
                name
              }
            }
          }
        }

        project_subcontractor_connection(
          where: { project_id: { _eq: $projectId } }
          order_by: { subcontractor: { name: asc } }
        ) {
          edges {
            node {
              subcontractor {
                id
                pk: id @__clientField(handle: "pk")
                name
              }
            }
          }
        }

        project_worker_connection(
          where: { project_id: { _eq: $projectId }, is_last: { _eq: true } }
          order_by: { user: { name: asc } }
          first: 100000
        )
          @connection(
            key: "GCProjectWorkersTableQuery_project_worker_connection"
            filters: []
          ) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              hard_hat_number
              user {
                name
                pk: id @__clientField(handle: "pk")
                email
                created_password
                phone_number
              }
              subcontractor {
                id
                pk: id @__clientField(handle: "pk")
                name
              }
              project {
                name
              }
              title {
                translation {
                  en
                }
              }
            }
          }
        }
        certification_connection(
          where: { is_custom: { _eq: false } }
          order_by: { name: asc }
        ) {
          edges {
            node {
              name
              pk: id @__clientField(handle: "pk")
            }
          }
        }
        orientation_connection(
          where: {
            deleted_at: { _is_null: true }
            project_orientations: {
              required_by_all_workers: { _eq: true }
              project: {
                linked_orientation_projects: { id: { _eq: $projectId } }
              }
            }
            _or: [
              {
                project: {
                  linked_orientation_projects: { id: { _eq: $projectId } }
                }
              }
              {
                project_id: { _is_null: true }
                general_contractor: { projects: { id: { _eq: $projectId } } }
              }
            ]
          }
          first: 1
          order_by: [{ order: asc }, { name: asc }]
        ) {
          edges {
            node {
              id
            }
          }
        }
        universalRequired: orientation_connection(
          where: {
            deleted_at: { _is_null: true }
            project_orientations: {
              required_by_all_workers: { _eq: true }
              project_id: { _is_null: true }
            }
            type: { _eq: "universal" }
          }
          first: 1
          order_by: [{ order: asc }, { name: asc }]
        ) {
          edges {
            node {
              id
            }
          }
        }
        project_connection(where: { id: { _eq: $projectId } }) {
          edges {
            node {
              in_person_orientation
              assign_hard_hat
              perform_drugtest
              agc_universal_orientation
              require_drugtest_upload
              show_onsite_for_not_oriented_workers
              drugtest_validity_days
              project_subcontractors(
                order_by: { subcontractor: { name: asc } }
              ) {
                subcontractor_id
                subcontractor {
                  name
                }
              }
            }
          }
        }
      }
    `,
    {
      projectId: projectId,
      uid: authUser.uid,
    },
  );
  const relayEnv = useRelayEnvironment();

  const [selectedWorker, setSelectedWorker] = useState<{
    id: string;
    subId: string;
    projWorkerId: string;
  }>();
  // const [selectedWorkerId, setSelectedWorkerId] = useState<string>();
  // const [selectedSubId, setSelectedSubId] = useState<string>();
  // const [selectedProjWorkerId, setSelectedProjWorkerId] = useState<string>();
  const projectSubcontractors = data.project_subcontractor_connection.edges.map(
    (edge) => ({
      key: edge.node.subcontractor.pk,
      value: edge.node.subcontractor.pk,
      label: edge.node.subcontractor.name,
    }),
  );
  const gcName =
    data.general_contractor_employee_connection.edges[0].node.general_contractor
      .name;

  const [showFunctions, setShowFunctions] = useState(false);
  const projectData = data.project_connection.edges[0].node;
  const [start, setStart] = useState<dayjs.Dayjs | undefined>(undefined);
  const hasInPerson = projectData.in_person_orientation;
  const tableRef = useRef<DataScrollTableRef>(null);
  const hasSlides = data.orientation_connection.edges.length > 0;
  const [customFilterWhere, setcustomFilterWhere] =
    useState<project_worker_bool_exp>({});
  const [statusFilterWhere, setStatusFilterWhere] =
    useState<project_worker_bool_exp>({});
  const createWorker = useRef<AddWorkerModalRef>(null);
  const addWorkerSpreadsheetModal = useRef<AddWorkerSpreadsheetModalRef>(null);
  const downloadWorkersListModal = useRef<GCDownloadWorkersListModalRef>(null);
  const navigate = useNavigate();
  let registeredOrientationWhere: user_orientation_bool_exp = {};
  if (hasSlides && hasInPerson) {
    registeredOrientationWhere = {
      _or: [
        { orientated_at: { _is_null: true } },
        { in_person_orientated_at: { _is_null: true } },
      ],
    };
  } else if (hasSlides) {
    registeredOrientationWhere = { orientated_at: { _is_null: true } };
  } else if (hasInPerson) {
    registeredOrientationWhere = {
      in_person_orientated_at: { _is_null: true },
    };
  }
  const nonOrientedWorkerWhere: project_worker_bool_exp = {
    _or: [
      { _not: { user_orientation: {} } },
      {
        user_orientation: {
          ...registeredOrientationWhere,
          completed_at: { _is_null: true },
        },
      },
    ],
  };
  const orientedWorkerWhere: project_worker_bool_exp = {
    _and: [
      {
        user_orientation: {
          _or: [
            { completed_at: { _is_null: false } },
            {
              ...(hasSlides ? { orientated_at: { _is_null: false } } : {}),
              ...(projectData.in_person_orientation
                ? {
                    in_person_orientated_at: {
                      _is_null: false,
                    },
                  }
                : {}),
            },
          ],
        },
        ...(projectData.assign_hard_hat
          ? {
              _and: [
                { hard_hat_number: { _is_null: false } },
                // { hard_hat_number: { _neq: "" } },
              ],
            }
          : {}),
      },
    ],
  };

  const addWorkerHHWhere: project_worker_bool_exp = {
    user_orientation: {
      _or: [
        { completed_at: { _is_null: false } },
        {
          ...(hasSlides ? { orientated_at: { _is_null: false } } : {}),
          ...(projectData.in_person_orientation
            ? {
                in_person_orientated_at: {
                  _is_null: false,
                },
              }
            : {}),
        },
      ],
    },
    ...(projectData.assign_hard_hat
      ? {
          _or: [
            { hard_hat_number: { _is_null: true } },
            // { hard_hat_number: { _eq: "" } },
          ],
        }
      : {}),
  };
  const where = {
    project_id: {
      _eq: projectId,
    },
    ...statusFilterWhere,
    ...customFilterWhere,
    is_last: { _eq: true },
    ...(start
      ? {
          project_workers: {
            _or: [
              {
                daily_work_log_workers: {
                  daily_work_log: {
                    date: { _gte: start.format("YYYY-MM-DD") },
                  },
                },
              },
              {
                toolbox_talks: {
                  toolbox_talk: {
                    created_at: {
                      _gte: start.startOf("day").format(),
                    },
                  },
                },
              },
              {
                report_injuries: {
                  report: {
                    created_at: {
                      _gte: start.startOf("day").format(),
                    },
                  },
                },
              },
              {
                reports: {
                  report: {
                    created_at: {
                      _gte: start.startOf("day").format(),
                    },
                  },
                },
              },
              {
                permits: {
                  permit: {
                    created_at: {
                      _gte: start.startOf("day").format(),
                    },
                    type: { _neq: "ladder" },
                    current_status: {
                      _in: ["submitted", "resubmitted"],
                    },
                    is_historical: { _eq: true },
                  },
                },
              },
            ],
          },
        }
      : {}),
  };
  return (
    <div className="flex w-full h-full">
      <div
        className={clsx(
          `${selectedWorker ? `w-4/5` : `w-full`}  h-full bg-gray-300`,
        )}
      >
        <PerformWorkerFunctions
          visible={showFunctions}
          isGC
          relayQueryConnName="GCProjectWorkersTableQuery_project_worker_connection"
          data={data.project_worker_connection.edges.map((pw) => ({
            name: pw.node.user!.name,
            dataId: pw.node.id,
            id: pw.node.user!.pk,
            hh: pw.node.hard_hat_number,
            company: pw.node.subcontractor.name,
            trade: pw.node.title?.translation?.en,
            email: pw.node.user!.email,
            createdPassword: pw.node.user!.created_password,
            subcontractorId: pw.node.subcontractor.pk,
            subcontractorName: pw.node.subcontractor.name,
            phoneNumber: pw.node.user?.phone_number || undefined,
          }))}
          onClose={() => setShowFunctions(false)}
          refetch={() => tableRef.current?.refetch()}
        />
        <GCCreateWorkerModal ref={createWorker} projectId={projectId} />
        <AddWorkerSpreadsheetModal
          ref={addWorkerSpreadsheetModal}
          projectId={projectId}
          subcontractors={projectData.project_subcontractors.map((ps) => ({
            name: ps.subcontractor.name,
            id: ps.subcontractor_id,
          }))}
        />
        <GCDownloadWorkersListModal
          ref={downloadWorkersListModal}
          projectId={projectId}
        />

        <ProjectWorkerTable
          filterNotVisibleByDefault={!!selectedWorker}
          topBarButtons={[
            {
              onClick: () => {
                createWorker.current?.open();
              },
              label: "New Worker",
            },
            {
              onClick: () => addWorkerSpreadsheetModal.current?.open(),
              secondary: true,
              label: "Bulk Upload",
            },
            {
              onClick: () => downloadWorkersListModal.current?.open(),
              secondary: true,
              icon: IconDownload,
            },
          ]}
          headerComponent={
            <div className="mr-1 ml-2">
              <Button
                secondary
                onClick={() => setShowFunctions(true)}
                label=""
                icon={IconUser}
                large
                secondaryIcon={IconSettings}
              />
            </div>
          }
          showSubAdminTag
          loadAll
          allowEditHH
          projectId={projectId}
          subs={data.project_connection.edges[0].node.project_subcontractors}
          hasInPerson={hasInPerson}
          hasSlides={hasSlides}
          hasUniversal={
            projectData.agc_universal_orientation &&
            data.universalRequired.edges.length > 0
          }
          titles={data.worker_title_connection.edges}
          markDeleted={true}
          onRowItemClick={(row) => {
            setHideFilter(true);
            setSelectedWorker({
              id: row.worker_id,
              projWorkerId: row.pk,
              subId: row.subcontractor_id,
            });
          }}
          excludedKeys={[
            "project",
            "inPersonOrientatedDate",
            "status",
            "drugtestDate",
            "universal_orientated_at",
            "dateLogged",
          ]}
          ref={tableRef}
          where={where}
          showOnsiteTag={
            data.project_connection.edges[0].node
              .show_onsite_for_not_oriented_workers
          }
          startTime={
            data.project_connection.edges[0].node
              .show_onsite_for_not_oriented_workers
              ? dayjs().startOf("day").subtract(14, "days").format()
              : undefined
          }
          startDate={
            data.project_connection.edges[0].node
              .show_onsite_for_not_oriented_workers
              ? dayjs().startOf("day").subtract(14, "days").format("YYYY-MM-DD")
              : undefined
          }
          customFilters={[
            {
              type: "radio",
              title: "Onsite",
              removeFilter: () => setStart(undefined),
              options: [
                {
                  option: "Onsite Today",

                  onClick: () => {
                    setStart(dayjs().startOf("day"));
                  },
                },
                {
                  option: "Last 15 days",
                  onClick: () =>
                    setStart(dayjs().startOf("day").subtract(15, "days")),
                },
                {
                  option: "Last 30 days",
                  onClick: () =>
                    setStart(dayjs().startOf("day").subtract(30, "days")),
                },
                {
                  option: "Last 60 days",
                  onClick: () =>
                    setStart(dayjs().startOf("day").subtract(60, "days")),
                },
                {
                  option: "Last 90 days",
                  onClick: () =>
                    setStart(dayjs().startOf("day").subtract(90, "days")),
                },
              ],
            },
            {
              type: "checkbox",
              title: "Orientation",
              removeFilter: () => {
                setStatusFilterWhere((prev) => {
                  const newObj = { ...prev };
                  newObj._or && delete newObj._or;
                  return { ...newObj };
                });
              },
              options: [
                {
                  option: "Orientated",
                  onApply: () => {
                    setStatusFilterWhere((prev) => {
                      if (prev._or && prev._or.length === 2)
                        return {
                          ...prev,
                          _or: [
                            nonOrientedWorkerWhere,
                            orientedWorkerWhere,
                            addWorkerHHWhere,
                          ],
                        };
                      else if (prev._or && prev._or.length === 1)
                        return {
                          ...prev,
                          _or: [
                            prev._or[0]?.user_orientation
                              ? addWorkerHHWhere
                              : nonOrientedWorkerWhere,
                            orientedWorkerWhere,
                          ],
                        };
                      else
                        return {
                          ...prev,
                          _or: [orientedWorkerWhere],
                        };
                    });
                  },
                  onCancel: () => {
                    setStatusFilterWhere((prev) => {
                      if (prev._or && prev._or.length === 3)
                        return {
                          ...prev,
                          _or: [nonOrientedWorkerWhere, addWorkerHHWhere],
                        };
                      else if (prev._or && prev._or.length === 2)
                        return {
                          ...prev,
                          _or: [
                            prev._or[0]?.user_orientation ||
                            prev._or?.[1]?.user_orientation
                              ? addWorkerHHWhere
                              : nonOrientedWorkerWhere,
                          ],
                        };
                      else {
                        const newObj = { ...prev };
                        newObj._or && delete newObj._or;
                        return { ...newObj };
                      }
                    });
                  },
                },
                {
                  option: "Not Completed",
                  onApply: () => {
                    setStatusFilterWhere((prev) => {
                      if (prev._or && prev._or.length === 2)
                        return {
                          ...prev,
                          _or: [
                            nonOrientedWorkerWhere,
                            orientedWorkerWhere,
                            addWorkerHHWhere,
                          ],
                        };
                      else if (prev._or && prev._or.length === 1)
                        return {
                          ...prev,
                          _or: [
                            prev._or[0]?.user_orientation
                              ? addWorkerHHWhere
                              : orientedWorkerWhere,
                            nonOrientedWorkerWhere,
                          ],
                        };
                      else
                        return {
                          ...prev,
                          _or: [nonOrientedWorkerWhere],
                        };
                    });
                  },
                  onCancel: () => {
                    setStatusFilterWhere((prev) => {
                      if (prev._or && prev._or.length === 3)
                        return {
                          ...prev,
                          _or: [orientedWorkerWhere, addWorkerHHWhere],
                        };
                      else if (prev._or && prev._or.length === 2)
                        return {
                          ...prev,
                          _or: [
                            prev._or[0]?.user_orientation ||
                            prev._or?.[1]?.user_orientation
                              ? addWorkerHHWhere
                              : orientedWorkerWhere,
                          ],
                        };
                      else {
                        const newObj = { ...prev };
                        newObj._or && delete newObj._or;
                        return { ...newObj };
                      }
                    });
                  },
                },
                ...(projectData.assign_hard_hat
                  ? [
                      {
                        option: "No Hard Hat #",
                        onApply: () => {
                          setStatusFilterWhere((prev) => {
                            if (prev._or && prev._or.length === 2)
                              return {
                                ...prev,
                                _or: [
                                  nonOrientedWorkerWhere,
                                  orientedWorkerWhere,
                                  addWorkerHHWhere,
                                ],
                              };
                            else if (prev._or && prev._or.length === 1)
                              return {
                                ...prev,
                                _or: [
                                  prev._or[0]?._and?.[0]
                                    ? orientedWorkerWhere
                                    : nonOrientedWorkerWhere,
                                  addWorkerHHWhere,
                                ],
                              };
                            else
                              return {
                                ...prev,
                                _or: [addWorkerHHWhere],
                              };
                          });
                        },
                        onCancel: () => {
                          setStatusFilterWhere((prev) => {
                            if (prev._or && prev._or.length === 3)
                              return {
                                ...prev,
                                _or: [
                                  orientedWorkerWhere,
                                  nonOrientedWorkerWhere,
                                ],
                              };
                            else if (prev._or && prev._or.length === 2)
                              return {
                                ...prev,
                                _or: [
                                  prev._or[0]?._and || prev._or?.[1]?._and
                                    ? orientedWorkerWhere
                                    : nonOrientedWorkerWhere,
                                ],
                              };
                            else {
                              const newObj = { ...prev };
                              newObj._or && delete newObj._or;
                              return { ...newObj };
                            }
                          });
                        },
                      },
                    ]
                  : []),
              ],
            } as CustomFilterType,
            {
              type: "radio",
              title: "Certificates",
              removeFilter: () => {
                setcustomFilterWhere((prev) => {
                  selectedCertExpiryDays = null;
                  if (
                    selectedCertificateIds &&
                    selectedCertificateIds.length > 0
                  ) {
                    return {
                      ...prev,
                      worker: {
                        _and: selectedCertificateIds.map((id) => ({
                          worker_certifications: {
                            certification_id: { _eq: id },
                          },
                        })),
                      },
                    };
                  } else {
                    const { worker, ...rest } = prev;
                    return rest;
                  }
                });
              },
              options: [
                {
                  option: "Expiring in < 30 days",
                  onClick: () => {
                    const thirtyDaysAfter = dayjs()
                      .add(30, "days")
                      .endOf("day")
                      .format();
                    let queryCondition: worker_certification_bool_exp;
                    selectedCertExpiryDays = 30;
                    const thrityDaysAfter = dayjs()
                      .add(30, "days")
                      .endOf("day")
                      .format();
                    if (
                      selectedCertificateIds &&
                      selectedCertificateIds.length > 0
                    ) {
                      queryCondition = {
                        _and: [
                          {
                            certification_id: { _in: selectedCertificateIds },
                          },
                          { expires_on: { _lt: thirtyDaysAfter } },
                        ],
                      };
                    } else {
                      queryCondition = {
                        expires_on: { _lt: thirtyDaysAfter },
                      };
                    }
                    setcustomFilterWhere((prev) => ({
                      ...prev,
                      worker: {
                        _and: [{ worker_certifications: queryCondition }],
                      },
                    }));
                  },
                },
                {
                  option: "Expiring in < 60 days",
                  onClick: () => {
                    let queryCondition: worker_certification_bool_exp;
                    selectedCertExpiryDays = 60;
                    const sixtyDaysAfter = dayjs()
                      .add(60, "days")
                      .endOf("day")
                      .format();
                    if (
                      selectedCertificateIds &&
                      selectedCertificateIds.length > 0
                    ) {
                      queryCondition = {
                        _and: [
                          {
                            certification_id: { _in: selectedCertificateIds },
                          },
                          { expires_on: { _lt: sixtyDaysAfter } },
                        ],
                      };
                    } else {
                      queryCondition = {
                        expires_on: { _lt: sixtyDaysAfter },
                      };
                    }
                    setcustomFilterWhere((prev) => ({
                      ...prev,
                      worker: {
                        _and: [{ worker_certifications: queryCondition }],
                      },
                    }));
                  },
                },
                {
                  option: "Expiring in < 90 days",
                  onClick: () => {
                    let queryCondition: worker_certification_bool_exp;
                    selectedCertExpiryDays = 90;
                    const ninetyDaysAfter = dayjs()
                      .add(90, "days")
                      .endOf("day")
                      .format();
                    if (
                      selectedCertificateIds &&
                      selectedCertificateIds.length > 0
                    ) {
                      queryCondition = {
                        _and: [
                          {
                            certification_id: { _in: selectedCertificateIds },
                          },
                          { expires_on: { _lte: ninetyDaysAfter } },
                        ],
                      };
                    } else {
                      queryCondition = {
                        expires_on: { _lte: ninetyDaysAfter },
                      };
                    }
                    setcustomFilterWhere((prev) => ({
                      ...prev,
                      worker: {
                        _and: [{ worker_certifications: queryCondition }],
                      },
                    }));
                  },
                },
              ],
            },
            {
              type: "select",
              title: "Training Certifications: Have",
              placeholder: "Certification/Training",
              removeFilter: () => {
                const selectedDaysAfter = dayjs()
                  .add(selectedCertExpiryDays ?? 0, "days")
                  .endOf("day")
                  .format();
                setcustomFilterWhere((prev) => {
                  selectedCertificateIds = null;
                  const selectedDaysAfter = dayjs()
                    .add(selectedCertExpiryDays ?? 0, "days")
                    .endOf("day")
                    .format();
                  if (selectedCertExpiryDays) {
                    return {
                      ...prev,
                      worker: {
                        _and: [
                          {
                            worker_certifications: {
                              expires_on: { _lt: selectedDaysAfter },
                            },
                          },
                        ],
                      },
                    };
                  } else {
                    const { worker, ...rest } = prev;
                    return rest;
                  }
                });
              },
              selectOptions: data.certification_connection.edges.map((p) => ({
                name: p.node.name,
                id: p.node.pk,
              })),
              onOk: (ids: Array<string>) => {
                selectedCertificateIds = ids;
                const selectedDaysAfter = dayjs()
                  .add(selectedCertExpiryDays ?? 0, "days")
                  .endOf("day")
                  .format();
                if (selectedCertExpiryDays) {
                  if (ids.length == 0) {
                    setcustomFilterWhere((prev) => ({
                      ...prev,
                      worker: {
                        _and: [
                          {
                            worker_certifications: {
                              expires_on: { _lt: selectedDaysAfter },
                            },
                          },
                        ],
                      },
                    }));
                  } else {
                    setcustomFilterWhere((prev) => ({
                      ...prev,
                      worker: {
                        _and: ids.map((id) => ({
                          worker_certifications: {
                            expires_on: { _lt: selectedDaysAfter },
                            certification_id: { _eq: id },
                          },
                        })),
                      },
                    }));
                  }
                } else {
                  setcustomFilterWhere((prev) => ({
                    ...prev,
                    worker: {
                      _and: ids.map((id) => ({
                        worker_certifications: {
                          certification_id: { _eq: id },
                        },
                      })),
                    },
                  }));
                }
              },
            },
            {
              type: "select",
              title: "Training Certifications: NOT Have",
              placeholder: "Certification/Training",
              removeFilter: () => {
                setcustomFilterWhere((prev) => {
                  const newObj = { ...prev };
                  newObj.user && delete newObj.user;
                  return { ...newObj };
                });
              },
              selectOptions: data.certification_connection.edges.map((p) => ({
                name: p.node.name,
                id: p.node.pk,
              })),
              onOk: (ids: Array<string>) => {
                setcustomFilterWhere((prev) => ({
                  ...prev,
                  user: {
                    worker: {
                      _not: {
                        worker_certifications: {
                          certification_id: { _in: ids },
                        },
                      },
                    },
                  },
                }));
              },
            },
          ]}
        />
      </div>
      {selectedWorker?.id &&
        selectedWorker.subId &&
        selectedWorker.projWorkerId && (
          <div className="top-4 overflow-y-auto right-0 w-3/12 bg-gray-500 z-10 bg-suplementary-1">
            <WorkerProfile
              projectSubcontractors={projectSubcontractors}
              type="gc"
              projWorkerId={selectedWorker.projWorkerId}
              drugtestGCProps={{
                projectId: projectId,
                includeImages:
                  projectData.perform_drugtest &&
                  projectData.require_drugtest_upload,
                performingDrugtest: projectData.perform_drugtest,
                projectDrugtestValidityDays: projectData.drugtest_validity_days,
              }}
              onSubChangeSuccess={(newSubId) => {
                const newSub = projectSubcontractors.find(
                  (sub) => sub.value === newSubId,
                );
                if (newSub)
                  commitLocalUpdate(relayEnv, (store) => {
                    const conn = ConnectionHandler.getConnection(
                      store.getRoot(),
                      "ProjectWorkerTable_project_worker_connection",
                      { where },
                    );
                    if (conn) {
                      const edge = (conn.getLinkedRecords("edges") || []).find(
                        (ed) => {
                          const dataId = ed
                            .getLinkedRecord("node")
                            ?.getDataID();
                          console.log(dataId);

                          if (selectedWorker.id) {
                            return (
                              ed.getLinkedRecord("node")?.getDataID() ===
                              GetFullID("project_worker", selectedWorker.id)
                            );
                          }
                        },
                      );
                      if (edge) {
                        const node = edge.getLinkedRecord("node")!;
                        node.setValue(newSubId, "subcontractor_id");
                        const sub = node.getLinkedRecord("subcontractor");
                        if (sub) {
                          sub.setValue(newSub.label, "name");
                        }
                      }
                    }
                  });
              }}
              onCrossClick={() => setSelectedWorker(undefined)}
              projectId={projectId}
              subcontractorId={selectedWorker.subId}
              workerId={selectedWorker.id}
              refetchWorkersList={() => console.log("")}
              gcName={gcName}
              ref={tableRef}
            />
          </div>
        )}
    </div>
  );
};

export default withCustomSuspense(GCProjectWorkersTable);
