import {
  EyeInvisibleTwoTone,
  EyeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import logoTextImage from "src/common/assets/logo.png";
import { useNavigate } from "react-router-dom";
import { auth } from "src/common/functions/firebase";
import gqlErrorString from "src/common/functions/gqlErrorString";
import {
  AcceptInviteInput,
  useAcceptInviteMutation,
  useGetClientUserDataQuery,
  useGetInvitePageQuery,
  useGetUserRoleQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import styled from "styled-components";
import getMobileOS from "src/common/functions/getMobileOS";
import getOnAppStoreImage from "src/common/assets/get-on-app-store.png";
import getOnPlayStoreImage from "src/common/assets/get-on-play-store.png";
import checkStrongPassword from "../authorization/utils/checkStrongPassword";
import StrongPasswordInfo from "../authorization/utils/StrongPasswordInfo";
import { signInWithCustomToken } from "firebase/auth";
import NotifyUserException from "../error-handling/NotifyUserException";

enum SubCompanyRoles {
  Tradesperson = "tradesperson",
  Management = "management",
}

const MessageContainer: FC<PropsWithChildren> = ({ children }) => (
  <Row style={{ minHeight: "100vh" }} justify="space-around" align="middle">
    <Space direction="vertical" style={{ textAlign: "center" }}>
      <Logo style={{ width: 150 }} />
      {children}
    </Space>
  </Row>
);

const Logo = styled.img.attrs({
  src: logoTextImage,
})`
  width: 120px;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  align-items: center;
  justify-content: center;
`;

// auth.signOut();

// TODO once submitted prompt app if mobile and say sign in with your email etc

const Invite: FC<{ inviteId: string }> = ({ inviteId }) => {
  const navigate = useNavigate();
  const { data: roleData } = useGetUserRoleQuery();
  const { data: userData, error: er } = useGetClientUserDataQuery();
  const { data, loading } = useGetInvitePageQuery({
    variables: {
      inviteId,
    },
  });

  const mobileOS = getMobileOS();
  const iosLink = "https://apps.apple.com/us/app/siteform/id1447766282";
  const androidLink =
    "https://play.google.com/store/apps/details?id=net.siteform.app&hl=en_US";
  const osLink =
    mobileOS === "iOS" ? iosLink : mobileOS === "Android" ? androidLink : null;
  const showIos = mobileOS === "iOS" || mobileOS === "unknown";
  const shownAndroid = mobileOS === "Android" || mobileOS === "unknown";
  const [error, setError] = useState<string>();
  const [acceptInvite, { loading: acceptingInvite }] =
    useAcceptInviteMutation();
  const [companyRole, setCompanyRole] = useState<SubCompanyRoles>();
  const [accepting, setAccepting] = useState(false);
  const [displayPassword, setDisplayPassword] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const redirectToMobilization =
    urlParams.get("redirectToMobilization") ?? "false";

  useEffect(() => {
    const getRole = async () => {
      const user = await auth.currentUser?.getIdTokenResult();

      if (user && user.claims.role === "pseudo_worker") {
        auth.signOut();
      }
    };
    getRole();
  }, []);

  if (!data || !roleData || !userData) {
    return (
      <MessageContainer>
        <Spin />
      </MessageContainer>
    );
  }
  const inviteData = data.invite_by_pk;

  if (!inviteData) {
    throw new NotifyUserException("Invalid Invite!");
  }

  const doAcceptInvite = async (input: AcceptInviteInput) => {
    setAccepting(true);
    try {
      // console.log("Accept");
      const { data } = await acceptInvite({
        variables: {
          input,
        },
      });

      if (!data || !data.acceptInvite) {
        throw new Error("acceptInvite returned empty response");
      }
      await signInWithCustomToken(auth, data.acceptInvite);

      const fullUserName = input.name ?? inviteData?.to_user?.name;
      if (typeof fullUserName !== "string") {
        throw new Error("userName is missing");
      }
      const firstName = fullUserName.split(" ")[0];

      if (inviteData.project?.id) {
        navigate(
          `/im?name=${firstName}&email=${
            inviteData?.to_user?.email ?? input.email!
          }&projectId=${inviteData?.project?.id}&joinRole=${
            inviteData?.join_role
          }&redirectToMobilization=${redirectToMobilization}`,
        );
      } else {
        const email = inviteData?.to_user?.email ?? input.email;
        if (!email) {
          throw new Error("Email is not specified");
        }
        navigate(`/im?name=${firstName}&email=${email}`);
      }
    } finally {
      setAccepting(false);
    }
  };

  if (inviteData.accepted_at) {
    return (
      <>
        <MessageContainer>
          <h2>
            <b>Invite already accepted!</b>
          </h2>
          <br />
          {inviteData.to_user?.role == "worker" && (
            <>
              <h4 style={{ textAlign: "center" }}>
                Download the SiteForm mobile app from your phone's app store by
                selecting Apple or Android
              </h4>
              <br />
              <div style={{ height: 24 }} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {showIos && (
                  <a href={iosLink}>
                    <img
                      src={getOnAppStoreImage}
                      style={{ width: 134, marginRight: shownAndroid ? 24 : 0 }}
                    />
                  </a>
                )}
                {shownAndroid && (
                  <a href={androidLink}>
                    <img src={getOnPlayStoreImage} style={{ width: 150 }} />
                  </a>
                )}
              </div>
              <br />
              <br />
              <div>
                <h6>Trouble logging in? Reset your password</h6>
                <br />
                <br />
                <br />
                <Button
                  size="middle"
                  type="primary"
                  // className="text-black hover:text-gray-700 focus:outline-none transition-colors duration-300"
                  onClick={() =>
                    window.open(`${document.location.origin}/forgotPassword`)
                  }
                >
                  Reset Password
                </Button>
              </div>
            </>
          )}
          {inviteData.to_user?.role == "employee" && (
            <>
              <p>
                <Button
                  size="middle"
                  type="primary"
                  // className="text-black hover:text-gray-700 focus:outline-none transition-colors duration-300"
                  onClick={() => window.open(`${document.location.origin}`)}
                >
                  Login
                </Button>
                <br />
                <br />
                <br />
                Trouble logging in? Reset your password
                <br />
                <br />
                <br />
                <Button
                  size="middle"
                  type="primary"
                  // className="text-black hover:text-gray-700 focus:outline-none transition-colors duration-300"
                  onClick={() =>
                    window.open(`${document.location.origin}/forgotPassword`)
                  }
                >
                  Reset Password
                </Button>
                <br />
                <br />
              </p>
              <h4 style={{ textAlign: "center" }}>
                Download the SiteForm mobile app from your phone's app store by
                selecting Apple or Android
              </h4>
              <div style={{ height: 24 }} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {showIos && (
                  <a href={iosLink}>
                    <img
                      src={getOnAppStoreImage}
                      style={{ width: 134, marginRight: shownAndroid ? 24 : 0 }}
                    />
                  </a>
                )}
                {shownAndroid && (
                  <a href={androidLink}>
                    <img src={getOnPlayStoreImage} style={{ width: 150 }} />
                  </a>
                )}
              </div>
            </>
          )}
          {inviteData.to_user?.role == "subcontractor_employee" && (
            <>
              <div className="mb-4">
                <Button
                  size="middle"
                  type="primary"
                  // className="text-black hover:text-gray-700 focus:outline-none transition-colors duration-300"
                  onClick={() => window.open(`${document.location.origin}`)}
                >
                  Login
                </Button>
                <br />
                <br />
                <br />
                Trouble logging in? Reset your password
                <br />
                <br />
                <br />
                <Button
                  size="middle"
                  type="primary"
                  // className="text-black hover:text-gray-700 focus:outline-none transition-colors duration-300"
                  onClick={() =>
                    window.open(`${document.location.origin}/forgotPassword`)
                  }
                >
                  Reset Password
                </Button>
              </div>
            </>
          )}
        </MessageContainer>
      </>
    );
  }

  const uniquenessMessage = (
    <div className="py-4 text-center">
      <p className="text-red-500 font-semibold mb-2">
        This email already exists
      </p>
      <p className="mb-4">
        Login Using
        <br />
        <button
          className="text-black hover:text-gray-700 focus:outline-none transition-colors duration-300"
          onClick={() => window.open(`${document.location.origin}`)}
        >
          Login
        </button>
        <br />
        <br />
        Or, Reset password using
        <button
          className="text-black hover:text-gray-700 focus:outline-none transition-colors duration-300"
          onClick={() =>
            window.open(`${document.location.origin}/forgotPassword`)
          }
        >
          Password Reset
        </button>
      </p>
    </div>
  );
  const errorString = error
    ? error.includes("user_email_key")
      ? `That's another user's email!`
      : error.includes("email_upper")
      ? `That's another user's email!`
      : error.includes(
          "Uniqueness violation. duplicate key value violates unique constraint",
        )
      ? uniquenessMessage
      : gqlErrorString(error)
    : null;
  if (inviteData.to_user && inviteData.claiming_account) {
    if (inviteData.to_user.email && inviteData.to_user.created_password) {
      return (
        <MessageContainer>
          <h2>Your account is created!</h2>
          <h2>Login using ${inviteData.to_user.email}</h2>
        </MessageContainer>
      );
    }

    return (
      <Row justify="space-around" align="middle">
        <Form
          layout="vertical"
          name="invite_form"
          style={{ width: 250 }}
          onFinish={async (values) => {
            let res = await doAcceptInvite({
              inviteId,
              name: values.name,
              email: values.email ?? inviteData.to_email,
              password: values.password,
              claimedSubRole: values.companyRole,
              claimedIsForeman:
                values.companyRole === SubCompanyRoles.Tradesperson
                  ? values.isForeman
                  : undefined,
            });
          }}
        >
          <Logo />
          <h3>Hi {inviteData.to_user.name.split(" ")[0]}!</h3>
          {inviteData.project && inviteData.project.name && (
            <h2>Join {inviteData.project.name} on SiteForm</h2>
          )}
          {!(inviteData.project && inviteData.project.name) && (
            <h2>Join SiteForm</h2>
          )}

          <Divider />
          {!inviteData.to_user?.email && (
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Please enter your email." }]}
            >
              <Input
                type="email"
                autoCorrect="off"
                autoCapitalize="none"
                placeholder="Work email"
              />
            </Form.Item>
          )}
          <Form.Item
            name="password"
            label={<div className="flex gap-1">Create a password</div>}
            rules={[
              { required: true, message: "Please create a password." },
              {
                validator(rule, value) {
                  if (typeof value === "string" && checkStrongPassword(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Password is not strong enough.");
                },
              },
            ]}
          >
            <Input.Password visibilityToggle />
          </Form.Item>
          <StrongPasswordInfo />
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Passwords do not match.");
                },
              }),
            ]}
          >
            <Input.Password visibilityToggle />
          </Form.Item>
          <br />
          {errorString && <h4 style={{ color: "red" }}>{errorString}</h4>}
          <Button
            style={{ width: "100%" }}
            loading={loading || acceptingInvite || accepting}
            disabled={loading || acceptingInvite || accepting}
            type="primary"
            htmlType="submit"
          >
            Join
          </Button>
        </Form>
      </Row>
    );
  }

  const userName =
    roleData.role === "admin" ? "SiteForm Admin" : userData.userData?.name;

  if (
    ((inviteData.to_uid &&
      inviteData.project &&
      inviteData.to_user?.created_password) ||
      roleData?.role !== "none") &&
    !!userName
  ) {
    // TODO
    return (
      <Row justify="space-around" align="middle">
        <Form
          layout="vertical"
          name="invite_form"
          style={{ width: 250 }}
          onFinish={async () => {
            await doAcceptInvite({
              inviteId,
            });
          }}
        >
          <Logo />
          <br />
          <h3>
            Hi{" "}
            {roleData.role === "admin"
              ? "SiteForm Admin"
              : userName.split(" ")[0]}
            !
          </h3>
          <a
            href={`/?inviteId=${inviteId}`}
            onClick={(e) => {
              e.preventDefault();
              auth.signOut();
              window.location.reload();
            }}
          >
            Not {userName}? Click here
          </a>
          {inviteData.project && inviteData.project.name && (
            <h2 style={{ marginTop: 32 }}>
              Join {inviteData.project.name} on SiteForm
            </h2>
          )}
          {!(inviteData.project && inviteData.project.name) && (
            <h2 style={{ marginTop: 32 }}>Join SiteForm</h2>
          )}
          <Divider />
          <br />
          {errorString && <h4 style={{ color: "red" }}>{errorString}</h4>}
          <Button
            style={{ width: "100%" }}
            loading={loading || acceptingInvite || accepting}
            disabled={loading || acceptingInvite || accepting}
            type="primary"
            htmlType="submit"
          >
            Joins
          </Button>
        </Form>
      </Row>
    );
  }

  let joinMessage = "Accept Invite";

  if (inviteData.project) {
    joinMessage = `Join ${inviteData.project?.name} on SiteForm`;
  }

  if (inviteData.subcontractor) {
    joinMessage = `Join ${inviteData.subcontractor.name} on SiteForm`;
    if (inviteData.project?.id) {
      joinMessage = `Join ${inviteData.subcontractor.name} on ${inviteData.project.name} with SiteForm`;
    }
  }
  if (
    (inviteData.join_role === "sub" || !!inviteData.subcontractor) &&
    companyRole !== SubCompanyRoles.Tradesperson
  ) {
    setCompanyRole(SubCompanyRoles.Tradesperson);
  }

  return (
    <Row
      justify="space-around"
      align="middle"
      style={{ flexDirection: "column" }}
    >
      <Form
        layout="vertical"
        name="invite_form"
        initialValues={{ email: inviteData.to_email }}
        style={{ width: 250 }}
      >
        <Logo />
        <br />
        {inviteData.join_role === "sub-admin" && (
          <h2 style={{ marginTop: 32 }}>
            {`Hi. You have been invited to SiteForm as an Admin for your company! 🎉`}
          </h2>
        )}
        <h2 style={{ marginTop: 32 }}>{joinMessage}</h2>
        <Divider />
      </Form>
      <Form
        layout="vertical"
        name="invite_form"
        initialValues={{ email: inviteData.to_email }}
        style={{ width: 250 }}
        onFinish={async (values) => {
          await doAcceptInvite({
            inviteId,
            name: values.name,
            email: values.email ?? inviteData.to_email,
            password: values.password,
            claimedSubRole: SubCompanyRoles.Tradesperson,
            subcontractorName: values.subcontractorName,
            claimedIsForeman: values.isForeman,
            phoneNumber: values.phoneNumber,
            subEmployeeTitleId: values.subcontractor_employee_title,
          });
        }}
      >
        <h2 style={{ marginTop: 24 }}>Create your account</h2>
        <a href={`/?inviteId=${inviteId}`}>
          Already have an account? Click here
        </a>
        <br />
        <br />
        {!inviteData.to_email && (
          <Form.Item
            name="email"
            label="Work email"
            rules={[
              { required: true, message: "Please enter your work email." },
            ]}
          >
            <Input
              type="email"
              autoCorrect="off"
              autoCapitalize="none"
              placeholder="Work email"
            />
          </Form.Item>
        )}
        {!inviteData.to_uid && (
          <Form.Item
            name="name"
            label="First and last name"
            rules={[
              {
                required: true,
                message: "Please enter your first and last name.",
              },
            ]}
          >
            <Input placeholder="First and last name" />
          </Form.Item>
        )}
        {!inviteData.subcontractor &&
          (inviteData.join_role === "sub-foreman" ||
            inviteData.join_role === "sub-worker" ||
            inviteData.join_role === "sub-admin" ||
            inviteData.join_role === "sub") && (
            <Form.Item
              name="subcontractorName"
              label="Your company's name"
              rules={[
                {
                  required: true,
                  message: "Please enter your company's name.",
                },
              ]}
            >
              {/* <FloatLabel label="Your company's name"> */}
              <Input placeholder="Company name" />
              {/* </FloatLabel> */}
            </Form.Item>
          )}
        {/* {(inviteData.join_role === 'sub' || !!inviteData.subcontractor) && (
          <>
            <br />
            <p>
              A <strong>Tradeperson</strong> (or foreman) performs work and
              submits daily safety reports.
            </p>
            <p>
              <strong>Management</strong> is office management, general
              superintendent, or a safety manager. Admins do not submit daily
              safety reports, but can review the reports.
            </p>
            <Form.Item
              name="companyRole"
              label="Role"
              rules={[
                {
                  required: true,
                  message: 'Please choose your role',
                },
              ]}
            >
              <Select
                placeholder="Your role"
                style={{ width: '100%' }}
                onChange={setCompanyRole as any}
              >
                <Select.Option
                  key={SubCompanyRoles.Tradesperson}
                  value={SubCompanyRoles.Tradesperson}
                >
                  Tradesperson
                </Select.Option>
                <Select.Option
                  key={SubCompanyRoles.Management}
                  value={SubCompanyRoles.Management}
                >
                  Management
                </Select.Option>
              </Select>
            </Form.Item>
          </>
        )} */}
        {(!inviteData.join_role || inviteData.join_role === "sub") &&
          companyRole === SubCompanyRoles.Tradesperson && (
            <>
              <br />
              <Form.Item name="isForeman" label="Are you a foreman?">
                <Radio.Group defaultValue={false}>
                  <Radio value={false}>No</Radio>
                  <Radio value={true}>Yes</Radio>
                </Radio.Group>
              </Form.Item>
            </>
          )}
        <br />
        {/* <Form.Item
          name="password"
          label="Create a password"
          rules={[{ required: true, message: 'Please enter your password.' }]}
        >
        {inviteData.join_role === 'sub-admin' && (
          <Select style={{ width: '100%' }}>
            {[...(data.subcontractor_employee_title ?? [])].map((c) => (
              <Select.Option key={c.id} value={c.id}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        )}
        </Form.Item> */}
        {inviteData.to_user?.role === "subcontractor_employee" && (
          <Form.Item
            name="subcontractor_employee_title"
            label="Title"
            rules={[{ required: true, message: "Please choose a title name." }]}
          >
            <Select style={{ width: "100%" }}>
              {[...(data.subcontractor_employee_title ?? [])].map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.name.en.toUpperCase()}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {!inviteData.to_phone_number && (
          <Form.Item
            name="phoneNumber"
            label="Phone number"
            rules={[{ required: true, message: "Phone number is required" }]}
          >
            <Input placeholder="Phone Number" />
          </Form.Item>
        )}
        <Form.Item
          name="password"
          label={<div className="flex gap-1">Create a password</div>}
          rules={[
            { required: true, message: "Please create a password." },
            {
              validator(rule, value) {
                if (typeof value === "string" && checkStrongPassword(value)) {
                  return Promise.resolve();
                }
                return Promise.reject("Password is not strong enough.");
              },
            },
          ]}
        >
          <Input.Password visibilityToggle />
        </Form.Item>
        <StrongPasswordInfo />
        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Passwords do not match.");
              },
            }),
          ]}
        >
          <Input.Password visibilityToggle />
        </Form.Item>
        {errorString && <h4 style={{ color: "red" }}>{errorString}</h4>}
        <Form.Item>
          <Button
            style={{ width: "100%" }}
            loading={loading || acceptingInvite || accepting}
            disabled={loading || acceptingInvite || accepting}
            type="primary"
            htmlType="submit"
          >
            Done
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default Invite;
