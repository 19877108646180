import {
  User_Set_Input,
  GetIncidentByPkQuery,
  useUpdateUserByPkMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import createIncidentPatch from "./createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";

const useUpdateUser = () => {
  const [updateUser] = useUpdateUserByPkMutation();
  const authUser = useAuthUser();

  const updateUserInfo = async (
    userId: string,
    userKey: keyof User_Set_Input,
    value: string,
    comment: string,
    incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
    incidentUserId: string,
    userType: "witness" | "injured-user",
  ) => {
    const incidentUsers =
      userType === "witness" ? incident.witnesses : incident.injured_users;
    const incidentUser = incidentUsers.find(
      (incidentUser) => incidentUser.id === incidentUserId,
    );

    if (!incidentUser?.user) {
      throw new Error("user not found");
    }

    const updatedIncidentUsers = incidentUsers.map((incidentUser) => {
      if (incidentUser.id === incidentUserId) {
        return {
          ...incidentUser,
          user: { ...incidentUser.user, [userKey]: value },
        };
      } else {
        return incidentUser;
      }
    });

    const updatedIncident = {
      ...incident,
      injured_users:
        userType === "witness" ? incident.injured_users : updatedIncidentUsers,
      witnesses:
        userType === "injured-user" ? incident.witnesses : updatedIncidentUsers,
    };

    const patch = createIncidentPatch(updatedIncident, incident);

    await updateUser({
      variables: {
        userId: userId,
        _set: { [userKey]: value },
        object: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "user-details-edit",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_user_by_pk: {
          ...incidentUser.user,
          id: userId,
          [userKey]: value,
        },
      },
    });
  };

  return [updateUserInfo];
};

export default useUpdateUser;
