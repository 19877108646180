import { Button, message, notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs, { Dayjs } from "dayjs";
import React, { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import StyledContent from "src/common/components/layouts/StyledContent";
import ProjectWorkerTable, {
  ColumnKeys,
} from "src/common/components/tables/ProjectWorkerTable";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import isNullOrUndefined from "src/common/functions/isNullOrUndefined";
import { GCProjectOrientationBackgroundCheckMutation, user_orientation_insert_input } from "src/common/types/generated/relay/GCProjectOrientationBackgroundCheckMutation.graphql";
import { useSiteOrientationGcDashboardDataQuery$data } from "src/common/types/generated/relay/useSiteOrientationGcDashboardDataQuery.graphql";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";

interface GCProjectOrientationTestProps {
  projectId: string;
  data: useSiteOrientationGcDashboardDataQuery$data;
}

const GCProjectOrientationBackgroundCheck: FC<
  GCProjectOrientationTestProps
> = ({ projectId, data }) => {
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const table1Ref = useRef<DataScrollTableRef>(null);
  const table2Ref = useRef<DataScrollTableRef>(null);
  const table3Ref = useRef<DataScrollTableRef>(null);
  // const [saving2, setSaving2] = useState(false);
  const orientations = (data?.orientation_connection.edges || []).map(
    (v) => v.node,
  );
  const allRequiredOrientations = orientations.filter(
    (o) =>
      o.type !== "universal" &&
      o.project_orientations[0] &&
      o.project_orientations[0].required_by_all_workers,
  );
  const hasSlides = allRequiredOrientations.length > 0;
  const [resultsMap1, setResults1] = useState<{
    [key: string]: {
      result: boolean | undefined;
      date: Dayjs | undefined;
    };
  }>({});
  const [resultsMap2, setResults2] = useState<{
    [key: string]: {
      result: boolean | undefined;
      date: Dayjs | undefined;
    };
  }>({});
  const projectData = (data?.project_connection.edges || [])[0]?.node;
  const hasInPerson = projectData.in_person_orientation;
  const gCProjectOrientationBackgroundCheckMutation = graphql`
    mutation GCProjectOrientationBackgroundCheckMutation(
      $objects: [user_orientation_insert_input!]!
    ) {
      insert_user_orientation(
        objects: $objects
        on_conflict: {
          constraint: user_orientation_user_id_project_id_key
          update_columns: [
            background_check_done
            background_check_updated_at
            background_check_done_by_uid
          ]
        }
      ) {
        returning {
          background_check_done_by_uid
        }
      }
    }
  `;
  if (!projectData) {
    throw new Error("Project id not found, ");
  }
  const [gCProjectOrientationBackgroundCheck, isUpdating] =
    useAsyncMutation<GCProjectOrientationBackgroundCheckMutation>(
      gCProjectOrientationBackgroundCheckMutation,
    );
  const usermap: {
    [id: string]: string;
  } = {};
  const refetchAll = () => {
    table1Ref.current?.refetch();
    table2Ref.current?.refetch();
    table3Ref.current?.refetch();
  };
  const savingResultsMultiple = async (
    workerIds: Array<string>,
    refetch = true,
  ) => {
    const workersToUpdate: Array<user_orientation_insert_input> = [];
    workerIds.forEach(async (workerId) => {
      // const d = resultsMap1[workerId]?.date;
      // const result = resultsMap1[workerId]?.result;
      var result, d;
      if (resultsMap1[workerId]) {
        d = resultsMap1[workerId]?.date;
        result = resultsMap1[workerId]?.result;
      } else if (resultsMap2[workerId]) {
        d = resultsMap2[workerId]?.date;
        result = resultsMap2[workerId]?.result;
      }
      if (result === undefined || result === null) {
        message.error("Select Background Check for this worker");
        return;
      }
      if (d === undefined || d === null) {
        message.error("Enter Check Date for this worker");
        return;
      }
      const date = d.format();
      const curUserName = data.user_connection.edges?.[0].node;
      const user = auth.currentUser;
      if (user) {
        const id = user.uid.toString() || " ";
        usermap[id] = curUserName.name || "Name not Defined";
      }
      workersToUpdate.push({
        project_id: projectId,
        user_id: workerId,
        background_check_done: result,
        background_check_updated_at: date,
        background_check_done_by_uid: auth.currentUser?.uid,
      });
    });
    await gCProjectOrientationBackgroundCheck({
      variables: {
        objects: workersToUpdate,
      },
    })
    setResults1((prev) => {
      const newObj = { ...prev };
      workerIds.forEach(
        (workerId) => newObj[workerId] && delete newObj[workerId],
      );
      return { ...newObj };
    });
    message.success("Background Check Updated");
    if (refetch) {
      // refetchBoth();
    }
  };
  const excludeInPersonOrSlides: Array<ColumnKeys> = ["inPersonOrientatedDate"];
  const saveAllSelected = async (type: string) => {
    const workerIds: Array<string> = [];
    setSaving(true);
    if (type === "table1") {
      for (let workerId in resultsMap1) {
        if (
          !isNullOrUndefined(resultsMap1[workerId]?.result) &&
          !isNullOrUndefined(resultsMap1[workerId]?.date)
        )
          workerIds.push(workerId);
      }
    }
    if (type === "table2") {
      for (let workerId in resultsMap2) {
        if (
          !isNullOrUndefined(resultsMap2[workerId]?.result) &&
          !isNullOrUndefined(resultsMap2[workerId]?.date)
        )
          workerIds.push(workerId);
      }
    }
    try {
      await savingResultsMultiple(workerIds);
    } catch (e) {
      message.error("Their is some error while storing details");
    } finally {
      setSaving(false);
      refetchAll();
    }
    if (type === "table1") setResults1({});
    if (type === "table2") setResults2({});
  };

  return (
    <div className="w-full h-full overflow-y-auto">
      <StyledContent style={{ marginTop: 10 }} padding backgroundColor="white">
        <ProjectWorkerTable
          projectId={projectId}
          ref={table1Ref}
          topBarButtons={[
            {
              onClick: () => saveAllSelected("table1"),
              label: "Save All",
            },
          ]}
          loadAll
          hasInPerson={hasInPerson}
          hasSlides={hasSlides}
          hasUniversal={
            projectData.agc_universal_orientation &&
            data.orientation_connection.edges.filter(
              (o) => o.node.type === "universal",
            ).length > 0
          }
          title="Not Yet Cleared To Work"
          searchDataIndex={["user", "name"]}
          excludedKeys={[
            "trainingAndCertifications",
            "project",
            "title",
            "role",
            "drugtestDate",
            "drugTest",
            "status",
            "archivedAt",
            "dateLogged",
            "universal_orientated_at",
            ...excludeInPersonOrSlides,
          ]}
          filterNotVisibleByDefault={true}
          defaultSortColumnKey="registeredDate"
          showSubAdminTag={true}
          where={{
            project_id: { _eq: projectId },
            deleted_at: { _is_null: true },
            subcontractor_worker: {},
            _or: [
              { _not: { user_orientation: {} } },
              {
                user_orientation: { background_check_done: { _is_null: true } },
              },
            ],
          }}
          extraColumns={[
            {
              title: "Results",
              size: "ml",
              queryIncludeVarKey: "includeOrienation",
              key: "extra",
              dataIndex: ["worker_id"],
              render: (_, row) => {
                const workerId = row.worker_id;
                const subId = row.worker.subcontractor_id as string;
                //due to this subcontractor_worker:{} in where prop above we will only have those worker who are under any sub right now
                return (
                  <div className="flex">
                    <Button
                      size="middle"
                      loading={saving}
                      className={`text-1 font-accent ml-0.25 border-semantic-positive-green rounded-full hover:border-semantic-positive-green`}
                      style={{
                        color: `${
                          !isNullOrUndefined(resultsMap1[workerId]?.result) &&
                          resultsMap1[workerId]?.result
                            ? "white"
                            : "#08B639"
                        }`,
                        backgroundColor: `${
                          !isNullOrUndefined(resultsMap1[workerId]?.result) &&
                          resultsMap1[workerId]?.result
                            ? "#08B639"
                            : ""
                        }`,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        if (resultsMap1[workerId]) {
                          const prevVal = resultsMap1[workerId];
                          const newVal = {
                            result:
                              isNullOrUndefined(prevVal?.result) ||
                              !prevVal.result
                                ? true
                                : undefined,
                            date: prevVal?.date,
                          };
                          setResults1((prev) => ({
                            ...prev,
                            [workerId]: newVal,
                          }));
                        } else {
                          const newVal = {
                            result: true,
                            date: dayjs(),
                          };
                          setResults1((prev) => {
                            return { ...prev, [workerId]: newVal };
                          });
                        }
                      }}
                    >
                      Cleared
                    </Button>
                    <Button
                      size="middle"
                      loading={saving}
                      className="text-1 mr-0.25 font-accent border-semantic-negative rounded-full hover:border-semantic-negative"
                      style={{
                        color: `${
                          !isNullOrUndefined(resultsMap1[workerId]?.result) &&
                          !resultsMap1[workerId]?.result
                            ? "white"
                            : "red"
                        }`,
                        backgroundColor: `${
                          !isNullOrUndefined(resultsMap1[workerId]?.result) &&
                          !resultsMap1[workerId]?.result
                            ? "red"
                            : ""
                        }`,
                      }}
                      onClick={() => {
                        if (resultsMap1[workerId]) {
                          const prevVal = resultsMap1[workerId];
                          const newVal = {
                            result:
                              isNullOrUndefined(prevVal?.result) ||
                              prevVal.result
                                ? false
                                : undefined,
                            date: prevVal?.date,
                          };
                          setResults1((prev) => ({
                            ...prev,
                            [workerId]: newVal,
                          }));
                        } else {
                          const newVal = {
                            result: false,
                            date: dayjs(),
                          };
                          setResults1((prev) => ({
                            ...prev,
                            [workerId]: newVal,
                          }));
                        }
                      }}
                    >
                      Not Allowed
                    </Button>
                  </div>
                );
              },
            },
            {
              title: "Save",
              size: "md",
              key: "extra",
              queryIncludeVarKey: "includeOrienation",
              dataIndex: ["worker_id"],
              render: (_, row) => (
                <div className="text-center">
                  <Button
                    type="primary"
                    className="rounded-full text-1"
                    onClick={() => {
                      const workerId = row.worker_id;
                      setSaving(true);
                      savingResultsMultiple([workerId]).finally(() => {
                        setSaving(false);
                        refetchAll();
                      });
                    }}
                  >
                    Save
                  </Button>
                </div>
              ),
            },
          ]}
        />
      </StyledContent>
      <StyledContent style={{ marginTop: 10 }} padding backgroundColor="white">
        <ProjectWorkerTable
          loadAll
          projectId={projectId}
          topBarButtons={[
            {
              onClick: () => saveAllSelected("table2"),
              label: "Save All",
            },
          ]}
          title="Not Allowed to Work"
          searchDataIndex={["user", "name"]}
          ref={table2Ref}
          filterNotVisibleByDefault={true}
          hasInPerson={hasInPerson}
          hasSlides={hasSlides}
          hasUniversal={
            projectData.agc_universal_orientation &&
            data.orientation_connection.edges.filter(
              (o) => o.node.type === "universal",
            ).length > 0
          }
          showSubAdminTag={true}
          excludedKeys={[
            "trainingAndCertifications",
            "project",
            "title",
            "role",
            "drugtestDate",
            "drugTest",
            "status",
            "universal_orientated_at",
            "archivedAt",
            ...excludeInPersonOrSlides,
          ]}
          where={{
            project_id: { _eq: projectId },
            is_last: { _eq: true },
            user_orientation: { background_check_done: { _eq: false } },
          }}
          extraColumns={[
            {
              title: `Results`,
              key: "extra",
              size: "sm",
              queryIncludeVarKey: "includeOrienation",
              dataIndex: [""],
              render: (_, row) => {
                return (
                  <div className="text-center text-1">
                    <b>Not Allowed</b>
                  </div>
                );
              },
            },
            {
              title: "Logged By",
              size: "md",
              key: "extra",
              dataIndex: [
                "user_orientation",
                "background_check_done_by",
                "name",
              ],
              queryIncludeVarKey: "includeOrienation",
            },
            {
              title: "Action",
              size: "md",
              key: "extra",
              dataIndex: ["worker_id"],
              queryIncludeVarKey: "includeOrienation",
              render: (_, row) => {
                const workerId = row.worker_id;
                return (
                  <Button
                    size="middle"
                    loading={saving}
                    className={`text-1 font-accent ml-0.25 border-semantic-positive-green rounded-full hover:border-semantic-positive-green`}
                    style={{
                      color: `${
                        !isNullOrUndefined(resultsMap2[workerId]?.result) &&
                        resultsMap2[workerId]?.result
                          ? "white"
                          : "#08B639"
                      }`,
                      backgroundColor: `${
                        !isNullOrUndefined(resultsMap2[workerId]?.result) &&
                        resultsMap2[workerId]?.result
                          ? "#08B639"
                          : ""
                      }`,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      if (resultsMap2[workerId]) {
                        const prevVal = resultsMap2[workerId];
                        const newVal = {
                          result:
                            isNullOrUndefined(prevVal?.result) ||
                            !prevVal.result
                              ? true
                              : false,
                          date: dayjs(),
                        };
                        setResults2((prev) => ({
                          ...prev,
                          [workerId]: newVal,
                        }));
                      } else {
                        const newVal = {
                          result: true,
                          date: dayjs(),
                        };
                        setResults2((prev) => {
                          return { ...prev, [workerId]: newVal };
                        });
                      }
                    }}
                  >
                    Clear to Work
                  </Button>
                );
              },
            },
            {
              title: "Save",
              size: "md",
              key: "extra",
              queryIncludeVarKey: "includeOrienation",
              dataIndex: ["worker_id"],
              render: (_, row) => (
                <div className="text-center">
                  <Button
                    type="primary"
                    className="rounded-full text-1"
                    onClick={() => {
                      const workerId = row.worker_id;
                      setSaving(true);
                      savingResultsMultiple([workerId]).finally(() => {
                        setSaving(false);
                        refetchAll();
                      });
                    }}
                  >
                    Save
                  </Button>
                </div>
              ),
            },
          ]}
        />
      </StyledContent>
      <StyledContent style={{ marginTop: 10 }} padding backgroundColor="white">
        <ProjectWorkerTable
          loadAll
          projectId={projectId}
          title="Allowed to Work"
          searchDataIndex={["user", "name"]}
          filterNotVisibleByDefault={true}
          showSubAdminTag={true}
          ref={table3Ref}
          hasInPerson={hasInPerson}
          hasSlides={hasSlides}
          hasUniversal={
            projectData.agc_universal_orientation &&
            data.orientation_connection.edges.filter(
              (o) => o.node.type === "universal",
            ).length > 0
          }
          excludedKeys={[
            "trainingAndCertifications",
            "project",
            "title",
            "role",
            "drugtestDate",
            "drugTest",
            "status",
            "archivedAt",
            "universal_orientated_at",
            ...excludeInPersonOrSlides,
          ]}
          where={{
            project_id: { _eq: projectId },
            is_last: { _eq: true },
            user_orientation: { background_check_done: { _eq: true } },
          }}
          extraColumns={[
            {
              title: `Results`,
              key: "extra",
              size: "sm",
              queryIncludeVarKey: "includeOrienation",
              dataIndex: [""],
              render: (_, row) => {
                return (
                  <div className="text-center text-1">
                    <b>Cleared</b>
                  </div>
                );
              },
            },
            {
              title: "Logged By",
              size: "md",
              key: "extra",
              dataIndex: [
                "user_orientation",
                "background_check_done_by",
                "name",
              ],
              queryIncludeVarKey: "includeOrienation",
            },
          ]}
        />
      </StyledContent>
    </div>
  );
};
export default withCustomSuspense(GCProjectOrientationBackgroundCheck);
